import Grid from "@material-ui/core/Grid";
import React from "react";
import {Form} from "react-redux-form";

import formPath from "../form-path";
import handleNext from "../handle-next";
import handleLabel from "../handle-label";
import InputCountry from "./input-country";
import InputProvince from "./input-province";
import validate from "./validate";

const content = (
  <Form model={formPath} onChange={validate}>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <InputCountry />
      </Grid>
      <Grid item xs={6}>
        <InputProvince />
      </Grid>
    </Grid>
  </Form>
);

export default {
  content,
  handleNext: handleNext(validate),
  handleLabel,
  label: "Country",
};
