import get from "lodash/get";

import {PINNED_ORGANIZATION} from "constants/local-storage-keys";

export const getPinnedOrganization = userId => {
  const PinnedOrgAllUsers = JSON.parse(
    localStorage.getItem(PINNED_ORGANIZATION) || "{}",
  );
  const pinnedOrgThisuser = get(PinnedOrgAllUsers, userId, []);

  return pinnedOrgThisuser;
};
