import React from "react";
import Tab from "@material-ui/core/Tab";
import compose from "lodash/fp/compose";
import noop from "lodash/noop";
import {withStyles} from "@material-ui/core";
import {push} from "react-router-redux";
import {connect} from "react-redux";

const styles = {
  tab: {
    height: 48,
    color: "#504A40",
    minWidth: "unset",
  },
};

const mapDispatchToProps = dispatch => ({
  routeTo: value => dispatch(push(value)),
});

const enhance = compose(
  withStyles(styles),
  connect(
    null,
    mapDispatchToProps,
  ),
);

const SubheaderTab = ({value, title, classes, routeTo, onClick = noop}) => (
  <Tab
    value={value}
    classes={{
      root: classes.tab,
    }}
    label={title}
    onClick={() => {
      onClick();
      routeTo(value);
    }}
  />
);

export default enhance(SubheaderTab);
