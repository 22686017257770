import {types} from "./types";

export const inviteUser = options => {
  const {
    accessLevel,
    email,
    firstName,
    isInvitedUserApprover,
    lastName,
    organizationId,
    downholeLocations,
    wellIds,
  } = options;

  return {
    type: types.inviteUser,
    payload: {
      accessLevel,
      email,
      firstName,
      isInvitedUserApprover,
      lastName,
      organizationId,
      downholeLocations,
      wellIds,
    },
  };
};
