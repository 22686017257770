import {load, position} from "@ambyint/common/unit-converter";

export const maxAllowableLoad = unitOfMeasure => {
  return parseInt(
    load.convert({
      value: 65535,
      to: unitOfMeasure,
      from: "Imperial",
    }),
    10,
  );
};

export const maxAllowablePosition = unitOfMeasure => {
  return parseInt(
    position.convert({
      value: 655,
      to: unitOfMeasure,
      from: "Imperial",
    }),
    10,
  );
};

export const maxAllowableStrokes = () => 80;
