import applyMiddleware from "./apply-middleware";
import createSocket from "./create-socket";
import alertMiddleware from "./middleware/alerts";
import recommendationMiddleware from "./middleware/recommendations";
import wellMiddleware from "./middleware/wells";
import withStore from "./with-store";

let socket;

export const getSocket = () => {
  return socket;
};

export const disconnect = () => {
  if (socket) {
    socket.disconnect();
    socket.removeAllListeners();
    socket = undefined;
  }
};

export const initializeSocket = store => {
  if (socket) {
    return socket;
  }

  return (socket = createSocket(
    applyMiddleware(
      ...withStore(store)(
        wellMiddleware,
        alertMiddleware,
        recommendationMiddleware,
      ),
    ),
  ));
};

export default initializeSocket;
