import {stringify} from "query-string";
import moment from "moment/moment";
import mapper from "object-mapper";

const transforms = {
  comparisons: comparisons => (comparisons ? comparisons.join(",") : void 0),
  date: date => (date ? +moment(date) : void 0),
};

const analysisMappings = {
  "alerts.state": {key: "als"},
  "casingPressure.state": {key: "caps"},
  comparisons: {key: "comps", transform: transforms.comparisons},
  "current.state": {key: "cus"},
  date: {key: "da", transform: transforms.date},
  "deflection.state": {key: "des"},
  "dutyCycle.state": {key: "dcs"},
  "driveFault.state": {key: "drfs"},
  "driveMode.state": {key: "drms"},
  "effectiveSpm.state": {key: "ess"},
  "fillage.state": {key: "fis"},
  "ambyintFillage.state": {key: "afis"},
  "inferredFillage.state": {key: "ifis"},
  "inferredOilProduction.state": {key: "ioips"},
  "leakPressure.state": {key: "leps"},
  "load.state": {key: "los"},
  "oilProduction.state": {key: "oips"},
  "range.end": {key: "rae", transform: transforms.date},
  "range.start": {key: "ras", transform: transforms.date},
  "recommendations.state": {key: "res"},
  "rpm.state": {key: "rps"},
  "rrpm.state": {key: "rrs"},
  "runningStatus.state": {key: "russ"},
  "shutdownCards.state": {key: "scs"},
  "spm.state": {key: "sps"},
  "statusCodes.state": {key: "stcs"},
  "torque.state": {key: "tos"},
  "tubingPressure.state": {key: "tups"},
  "userActions.state": {key: "usra"},
};

export const mapAnalysisToQuery = state =>
  stringify(mapper(state, analysisMappings));
export default mapAnalysisToQuery;
