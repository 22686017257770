import {LOCATION_CHANGE} from "react-router-redux";
import {matchPath} from "react-router";
import merge from "lodash/merge";
import {parse} from "query-string";
import pick from "lodash/pick";

import {empty} from "actions";
import {mapQueryToAnalysis, queryMappings} from "utils/wells/analysis";
import {setAnalysis, updateAnalysis} from "actions/wells";

import {getExisting} from "./get-existing";

export const initializeAnalysis = (action$, store) => {
  return action$.ofType(LOCATION_CHANGE).switchMap(async ({payload}) => {
    const match = matchPath(payload.pathname, {
      path: "/well/:downholeLocation/analysis",
    });

    if (match && match.isExact) {
      const downholeLocation = decodeURIComponent(
        match.params.downholeLocation || "",
      );

      const existing = await getExisting(store, downholeLocation);
      const previousQueryString = parse(payload.search);
      const previousAnalysis = pick(
        previousQueryString,
        Object.keys(queryMappings),
      );
      const hasExistingAnalysisParams =
        Object.keys(previousAnalysis).length > 0;

      if (hasExistingAnalysisParams) {
        const analysis = merge(
          {},
          existing,
          mapQueryToAnalysis(payload.search),
        );

        return setAnalysis(downholeLocation, analysis);
      }

      return updateAnalysis(downholeLocation, existing);
    }

    return empty();
  });
};
