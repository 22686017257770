import {connect} from "react-redux";
import {reduxForm, formValueSelector} from "redux-form";
import get from "lodash/get";
import Grid from "@material-ui/core/Grid";
import isNil from "lodash/isNil";
import math from "mathjs";
import React from "react";

import {
  isLessThan,
  isGreaterThan,
} from "@ambyint/common/utils/compare-time-object";
import {hasControlIotBridge} from "@ambyint/common/utils/devices/control-iot-bridge";
import {hasControlForEpm} from "@ambyint/common/utils/devices/control-for-epm";

import {saveSafeOperatingParameters} from "actions/wells";
import DecimalTextField from "components/forms/decimal-text-field";
import FormHeader from "components/form-header";
import FormWarning from "components/form-warning";
import PaperContainer from "components/paper-container";
import PlainText from "components/forms/plain-text";
import SaveCancelButtons from "components/save-cancel-buttons";
import compose from "utils/compose";
import spreadIf from "utils/spread-if";
import TimeSelect from "components/time-select";

import validate from "./validate";

const selector = formValueSelector("safe-operating-parameters-edit-view");

const mapStateToProps = (state, props) => {
  const safeOperatingParameters = props.well.safeOperatingParameters;

  const downholeLocation = get(props.well, "downholeLocation");
  const maxSpeed = get(safeOperatingParameters, "maxSpeed");
  const minSpeed = get(safeOperatingParameters, "minSpeed");
  const maxDowntime = get(safeOperatingParameters, "maxDowntime");
  const minDowntime = get(safeOperatingParameters, "minDowntime");
  const minStoptime = get(safeOperatingParameters, "minStoptime");
  const maxStoptime = get(safeOperatingParameters, "maxStoptime");

  return {
    ...spreadIf(props.well, {
      initialValues: {
        downholeLocation,
        maxSpeed,
        minSpeed,
        maxDowntimeHours: maxDowntime.hours,
        maxDowntimeMinutes: maxDowntime.minutes,
        minDowntimeHours: minDowntime.hours,
        minDowntimeMinutes: minDowntime.minutes,
        minStoptime,
        maxStoptime,
      },
    }),
    currentValues: {
      maxSpeed: selector(state, "maxSpeed"),
      minSpeed: selector(state, "minSpeed"),
      maxDowntime: {
        hours: selector(state, "maxDowntimeHours"),
        minutes: selector(state, "maxDowntimeMinutes"),
      },
      minDowntime: {
        hours: selector(state, "minDowntimeHours"),
        minutes: selector(state, "minDowntimeMinutes"),
      },
      minStoptime: selector(state, "minStoptime"),
      maxStoptime: selector(state, "maxStoptime"),
    },
    user: state.auth.user, // *** This can be removed when the EPM SOP flag is removed ***
  };
};

class SafeOperatingParametersEditView extends React.Component {
  save = (values, func, props) => {
    if (Object.keys(props.syncErrors).length > 0) {
      return;
    }

    const maxDowntime = {
      hours: values.maxDowntimeHours,
      minutes: values.maxDowntimeMinutes,
    };

    const minDowntime = {
      hours: values.minDowntimeHours,
      minutes: values.minDowntimeMinutes,
    };

    this.props.dispatch(
      saveSafeOperatingParameters({
        ...values,
        minDowntime,
        maxDowntime,
        downholeLocation: this.props.well.downholeLocation,
        wellId: this.props.well.wellId,
      }),
    );

    this.props.onClose();
  };

  renderTargetSpeedUpdateWarning = () => {
    const {currentValues, well} = this.props;

    const operation = well.operation || {};

    if (!operation.targetSpeed || !currentValues) return null;

    const {maxSpeed, minSpeed} = currentValues;

    const isTargetBelowMin =
      !isNil(minSpeed) &&
      minSpeed.value !== "" &&
      operation.targetSpeed.speed.value < minSpeed.value;

    const isTargetAboveMax =
      !isNil(maxSpeed) &&
      maxSpeed.value !== "" &&
      operation.targetSpeed.speed.value > maxSpeed.value;

    return (
      (isTargetBelowMin || isTargetAboveMax) && (
        <FormWarning>
          Target Speed will be updated to be within safe operating parameters
        </FormWarning>
      )
    );
  };

  renderSpeedRangeUpdateWarning = () => {
    const {currentValues, well} = this.props;

    const operation = well.operation || {};

    if (!operation.speedRange || !currentValues) return null;

    const {maxSpeed, minSpeed} = currentValues;

    const isSpeedRangeBelowMin =
      !isNil(minSpeed) &&
      minSpeed.value !== "" &&
      operation.speedRange.min.value < minSpeed.value;

    const isSpeedRangeAboveMax =
      !isNil(maxSpeed) &&
      maxSpeed.value !== "" &&
      operation.speedRange.max.value > maxSpeed.value;

    return (
      (isSpeedRangeBelowMin || isSpeedRangeAboveMax) && (
        <FormWarning>
          Speed Range will be updated to be within safe operating parameters
        </FormWarning>
      )
    );
  };

  renderDownTimeUpdateWarning = () => {
    const {currentValues, well} = this.props;

    const scheduledDowntime = well.scheduledDowntime || {};

    const currentDowntime = {
      hours: scheduledDowntime.hours,
      minutes: scheduledDowntime.minutes,
    };

    if (!currentValues) return null;

    const {minDowntime, maxDowntime} = currentValues;

    const isAboveMaxDowntime = isGreaterThan(currentDowntime, maxDowntime);
    const isBelowMinDowntime = isLessThan(currentDowntime, minDowntime);

    return (
      (isAboveMaxDowntime || isBelowMinDowntime) && (
        <FormWarning>Existing downtime is outside new parameters</FormWarning>
      )
    );
  };

  render() {
    const {
      handleSubmit,
      invalid,
      onClose,
      pristine,
      submitting,
      reset,
      well,
    } = this.props;

    const operation = well.operation || {};
    const hasIotBridge = hasControlIotBridge(well.devices);
    const hasEPMControl = hasControlForEpm(well.devices);

    return (
      <PaperContainer extraPadded style={{maxWidth: "500px"}}>
        <form onSubmit={handleSubmit(this.save)}>
          <Grid container direction={"row"} style={{maxWidth: 776}} spacing={2}>
            <Grid item xs={12}>
              <FormHeader>Safe Operating Parameters</FormHeader>
            </Grid>

            {!hasEPMControl && (
              <>
                <Grid item xs={6}>
                  <DecimalTextField
                    fullWidth
                    name={"minSpeed"}
                    label={"Minimum Speed"}
                  />
                </Grid>

                <Grid item xs={6}>
                  <DecimalTextField
                    fullWidth
                    name={"maxSpeed"}
                    label={"Maximum Speed"}
                  />
                </Grid>

                <Grid item xs={12}>
                  <PlainText
                    name="currentSpeed"
                    label="Current Speed"
                    input={{
                      value: well.conditions.speed.label,
                    }}
                  />
                </Grid>
              </>
            )}
            {operation.targetSpeed && (
              <Grid item xs={12}>
                <PlainText
                  name="targetSpeed"
                  label="Target Speed"
                  input={{
                    value: operation.targetSpeed.speed.value,
                  }}
                />
              </Grid>
            )}
            {operation.speedRange && (
              <Grid item xs={12}>
                <PlainText
                  name="speedRange"
                  label="Speed Range"
                  input={{
                    value: `${math.round(
                      operation.speedRange.min.value,
                      2,
                    )} - ${math.round(operation.speedRange.max.value, 2)} ${
                      operation.speedRange.max.units
                    }`,
                  }}
                />
              </Grid>
            )}
          </Grid>

          {hasIotBridge && (
            <Grid
              container
              direction={"row"}
              style={{maxWidth: 776}}
              spacing={3}
            >
              <Grid item xs={6}>
                <PlainText name="minDowntimeLabel" label="Minimum Downtime" />
                <DecimalTextField name={"minDowntimeHours"} label={"Hours"} />
                <DecimalTextField
                  name={"minDowntimeMinutes"}
                  label={"Minutes"}
                />
              </Grid>
              <Grid item xs={6}>
                <PlainText name="maxDowntimeLabel" label="Maximum Downtime" />
                <DecimalTextField name={"maxDowntimeHours"} label={"Hours"} />
                <DecimalTextField
                  name={"maxDowntimeMinutes"}
                  label={"Minutes"}
                />
              </Grid>
            </Grid>
          )}

          {hasEPMControl && (
            <Grid
              container
              direction={"row"}
              style={{maxWidth: 776}}
              spacing={3}
            >
              <Grid item xs={6}>
                <PlainText
                  name="minStoptimeLabel"
                  label="Minimum Stop Time (HH:MM)"
                />
                <TimeSelect
                  style={{width: "100%"}}
                  name="minStoptime"
                  label="Min Stop Time"
                />
              </Grid>
              <Grid item xs={6}>
                <PlainText
                  name="maxStoptimeLabel"
                  label="Maximum Stop Time (HH:MM)"
                />
                <TimeSelect
                  style={{width: "100%"}}
                  name="maxStoptime"
                  label="Max Stop Time"
                />
              </Grid>
            </Grid>
          )}

          <Grid container direction={"row"} style={{maxWidth: 776}} spacing={3}>
            <Grid item xs={12}>
              {this.renderTargetSpeedUpdateWarning()}
              {this.renderSpeedRangeUpdateWarning()}
              {this.renderDownTimeUpdateWarning()}

              <SaveCancelButtons
                invalid={invalid}
                pristine={pristine}
                reset={compose(
                  onClose,
                  reset,
                )}
                submitting={submitting}
              />
            </Grid>
          </Grid>
        </form>
      </PaperContainer>
    );
  }
}

const Component = reduxForm({
  form: "safe-operating-parameters-edit-view",
  enableReinitialize: true,
  validate,
})(SafeOperatingParametersEditView);

export default connect(mapStateToProps)(Component);
