import React from "react";

import {setWellFeedFilter} from "actions/wells";
import EllipsisMenu from "components/ellipsis-menu";
import {TYPES} from "constants/well-feed-filters";
import store from "store";

export default () => {
  return (
    <div style={{marginLeft: "auto", marginRight: -8}}>
      <EllipsisMenu
        htmlId="well-event-filter-menu"
        onChange={value => store.dispatch(setWellFeedFilter(value))}
        options={Object.values(TYPES)}
      />
    </div>
  );
};
