import {createSwitchEpic} from "epics/create-epic";
import {getAnomalyWells} from "utils/wells/get-anomaly-wells";
import {getWells} from "utils/wells/get-wells";
import {getWellIds} from "utils/wells/get-well-ids";
import {setAnomalyWells, types} from "actions/wells";

export const setActiveAnomaly = createSwitchEpic(
  [types.setActiveAnomaly],
  async (action, store) => {
    const anomaly = action.payload.anomaly;
    const allWellIds = store.getState().wells.search.wellList;

    const anomalyWellIds = getAnomalyWells(allWellIds);

    const wells = getWells(anomalyWellIds);

    let wellIds = anomalyWellIds;

    if (anomaly) {
      const wellList = wells.filter(well => {
        return well.dailyAnomalySummaries.some(
          summary => summary.type === anomaly,
        );
      });

      wellIds = getWellIds(wellList);
    }

    return [setAnomalyWells(wellIds)];
  },
);
