import {connect} from "react-redux";
import {compose} from "recompose";
import get from "lodash/get";
import isNil from "lodash/isNil";
import LinearProgress from "@material-ui/core/LinearProgress";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {push} from "react-router-redux";

import {
  fetchAnalysis,
  fetchCards,
  fetchComparison,
  fetchLatestCards,
  fetchOne,
  fetchOnCall,
  fetchRecommendations,
  setActive,
} from "actions/wells";
import {show as showSnackbar} from "actions/snackbar";
import ClearErrorsDialog from "components/dialogs/clear-errors";
import findWell from "utils/find-well";
import getDownholeLocation from "utils/well/get-downhole-location";
import ResetFaultDialog from "components/dialogs/reset-fault";
import ResetMalfunctionsDialog from "components/dialogs/reset-malfunctions";
import SetDowntimeDialog from "components/dialogs/set-downtime";
import SetSpeedDialog from "components/dialogs/set-speed";
import SetSpeedRangeDialog from "components/dialogs/set-speed-range";
import startOfDay from "utils/start-of-day";
import StartWellDialog from "components/dialogs/start-well";
import StopWellDialog from "components/dialogs/stop-well";
import withHelmet from "components/with-helmet";

import Actions from "./actions";
import Tabs from "./tabs";

const EVENT_TYPES_SHOWN_IN_DASHBOARD = ["spm"];

const mapStateToProps = (state, props) => {
  return {
    analysis: state.wells.analysis[getDownholeLocation(props)],
    source: state.paging.source,
    well: findWell(state.wells, getDownholeLocation(props)),
    fetched: state.wells.fetched,
  };
};

const mapDispatchToProps = dispatch => ({
  wellNotFound: () => {
    dispatch(push("/"));
    dispatch(showSnackbar({message: "Could not find well."}));
  },
  fetchDashboardAveragesDataWithin: (
    timeAgoAmount,
    timeAgoUnits,
    downholeLocation,
  ) => {
    dispatch(
      fetchAnalysis({
        downholeLocation,
        start: startOfDay(
          moment().subtract(timeAgoAmount, timeAgoUnits),
        ).toISOString(),
        eventTypes: EVENT_TYPES_SHOWN_IN_DASHBOARD,
      }),
    );
  },

  fetchWellFeedDataWithin: (timeAgoAmount, timeAgoUnits, downholeLocation) => {
    dispatch(
      fetchAnalysis({
        downholeLocation,
        start: startOfDay(
          moment().subtract(timeAgoAmount, timeAgoUnits),
        ).toISOString(),
      }),
    );
  },

  fetchCardsWithin: (timeAgoAmount, timeAgoUnits, downholeLocation) => {
    dispatch(
      fetchCards({
        downholeLocation,
        start: moment()
          .subtract(timeAgoAmount, timeAgoUnits)
          .toISOString(),
      }),
    );
  },

  fetchWellData: downholeLocation => {
    dispatch(setActive({downholeLocation}));
    dispatch(fetchLatestCards({downholeLocation}));
    dispatch(fetchOne({downholeLocation}));
    dispatch(fetchOnCall({downholeLocation}));
    dispatch(fetchRecommendations({downholeLocation}));
  },

  fetchAnalysis: (downholeLocation, range) => {
    dispatch(
      fetchAnalysis({
        downholeLocation,
        ...range,
      }),
    );
  },

  fetchComparisons: (downholeLocation, analysis) => {
    (analysis.comparisons || []).forEach(correlationId => {
      dispatch(
        fetchComparison({
          downholeLocation,
          correlationId,
        }),
      );
    });
  },
});

const useDashboardData = props => {
  const {well, source, analysis} = props;
  const [prevProps, saveProps] = useState({match: {}});

  useEffect(
    () => {
      if (well && well.parsed) {
        const {
          fetchDashboardAveragesDataWithin,
          fetchAnalysis,
          fetchWellData,
          fetchCardsWithin,
          fetchComparisons,
          fetchWellFeedDataWithin,
          setTitle,
        } = props;

        const downholeLocation = getDownholeLocation(props);
        const existingAnalysis = prevProps.analysis;
        const nextAnalysis = props.analysis;

        if (props.match.url !== prevProps.match.url) {
          fetchWellData(downholeLocation);
          fetchWellFeedDataWithin(1, "day", downholeLocation);
          fetchCardsWithin(1, "day", downholeLocation);
          fetchDashboardAveragesDataWithin(7, "days", downholeLocation);
        }

        // when the analysis is initialized
        if (isNil(existingAnalysis) && !isNil(nextAnalysis)) {
          fetchAnalysis(downholeLocation, nextAnalysis.range);
          fetchComparisons(downholeLocation, nextAnalysis);
        }

        // when the range changes
        if (!isNil(existingAnalysis) && !isNil(nextAnalysis)) {
          if (
            JSON.stringify(existingAnalysis.range) !==
            JSON.stringify(nextAnalysis.range)
          ) {
            fetchAnalysis(downholeLocation, nextAnalysis.range);
          }
        }

        if (
          get(well, "isFetchingAnalysis", false) &&
          !get(prevProps.well, "isFetchingAnalysis", false)
        ) {
          setTitle(props.well.displayName);
        }
        saveProps(props);
      }
    },

    // I don't understand the need for an _exhaustive_ deps array if
    // I only want the effect to trigger when these deps change >:(
    // eslint-disable-next-line
    [well, source, analysis],
  );
};

const Well = props => {
  const {well, fetched, source, wellNotFound} = props;
  useDashboardData(props);
  useEffect(
    () => {
      if (fetched && !well) {
        wellNotFound();
      }
    },
    [well, fetched, wellNotFound],
  );

  if (!fetched || (well && !well.parsed)) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }

  if (fetched && !well) {
    return null;
  }

  return (
    <div>
      <Tabs well={well} source={source} />
      <ClearErrorsDialog />
      <SetDowntimeDialog />
      <SetSpeedDialog />
      <SetSpeedRangeDialog />
      <StartWellDialog />
      <StopWellDialog />
      <ResetFaultDialog />
      <ResetMalfunctionsDialog />
      <Actions well={well} />
    </div>
  );
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withHelmet(),
);

export default enhance(Well);
