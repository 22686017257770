import {fetchCardOverride, types} from "actions/wells";
import {createMergeEpic} from "epics/create-epic";
import fetch from "epics/async-fetch";

export const setCardOverride = createMergeEpic(
  [types.setCardOverride],
  async ({payload}) => {
    const {downholeLocation, cardId, overrideType} = payload;
    try {
      await fetch(
        `/wells/${encodeURIComponent(
          downholeLocation,
        )}/cards/${cardId}/classificationOverride`,
        {},
        {
          body: {
            classificationOverride: overrideType,
          },
          method: "PUT",
        },
      );
    } catch (err) {
      console.warn("Encountered error when setting card override", {
        downholeLocation,
        cardId,
        overrideType,
      });
    }
    return [fetchCardOverride({downholeLocation, cardId})];
  },
);
