import fetch from "epics/fetch";
import {empty} from "rxjs/observable/empty";
import {setRecommendations, types} from "actions/wells";
import parseRecommendation from "@ambyint/common/parsers/well/parse-recommendation";
import getUser from "utils/get-user";

export default action$ => {
  return action$.ofType(types.fetchRecommendations).switchMap(({payload}) => {
    return fetch(
      `/recommendation/well/${encodeURIComponent(
        payload.downholeLocation,
      )}/open`,
      payload,
    )
      .map(({response: data}) => {
        const {unitsOfMeasure} = getUser();

        return setRecommendations({
          downholeLocation: payload.downholeLocation,
          data: data.map(recommendation =>
            parseRecommendation(recommendation, unitsOfMeasure),
          ),
        });
      })
      .catch(err => {
        console.error(err);
        // NOTE: Currently fetchOne is called simultaneously, and if a 401 or 403 occur, we handle that there.
        //       We have to catch here, or otherwise unhandled exceptions were sometimes preventing rxjs from
        //       allowing fetchOne to catch its exception.
        return empty();
      });
  });
};
