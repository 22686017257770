import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import {formatNumber} from "utils/format-number";
import getUnits from "utils/get-units";

const styles = ({shape, shadows}) => ({
  hint: {
    padding: "12px 30px",
    color: "#fdfdfd",
    background: "#3a3a48",
    boxShadow: shadows[4],
    borderRadius: shape.borderRadius,
    whiteSpace: "nowrap",
    textAlign: "center",
    fontWeight: 300,
  },

  field: {
    fontSize: "0.7em",
    opacity: 0.7,
  },

  name: {
    margin: "3px 0 10px",
  },

  info: {
    fontSize: "1.1em",
    "& span": {
      "&:nth-child(1)": {
        marginRight: ".5rem",
      },
    },
  },

  infoUnit: {
    marginLeft: "2px",
    fontSize: "0.7em",
    opacity: 0.7,
  },
});

const HintBody = ({
  classes,
  field,
  name,
  absDeviation,
  percentChange,
  unit,
}) => (
  <div className={classes.hint}>
    <div className={classes.field}>{field}</div>
    <div className={classes.name}>{name}</div>
    <div className={classes.info}>
      {formatNumber(absDeviation)}
      <span className={classes.infoUnit}>{getUnits()[unit] || unit}</span>
      {formatNumber(percentChange)}
      <span className={classes.infoUnit}>%</span>
    </div>
  </div>
);

export default withStyles(styles)(HintBody);
