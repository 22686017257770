import React from "react";
import isEmpty from "lodash/isEmpty";

import FeedItem from "../feed-item";
import {lineStyle} from "../styles";

const renderStatusCode = (code, i) => {
  return (
    <span key={i} style={lineStyle}>
      {code.description}
    </span>
  );
};

export default props => {
  const {event} = props;

  if (isEmpty(event.value)) return null;

  return (
    <FeedItem event={event}>
      <span style={lineStyle}>
        <strong>Status codes</strong> updated.
      </span>
      {event.mappedValue.map(renderStatusCode)}
    </FeedItem>
  );
};
