import sortBy from "lodash/sortBy";

import {
  byDisplayName,
  byDownholeLocation,
  byDeviceSerialNumber,
} from "./search-properties";

/**
 * Search well names for a particular query
 *
 * Results are generated by matches in the query vs properties of the well.
 *
 * The set is then sorted based on the index that the query appears
 * in the well name.
 *
 * @returns {array} [{data: {object} well, index: {number} match location}]
 */
export const searchWells = (allWells, query) => {
  if (query === "") return [];

  // Search should only evaluate wells managed by Ambyint
  // This will filter out pumpwell wells
  const wells = allWells.filter(well => well.isAmbyintWell);

  const groupedResults = [];

  // Create an array of each "word" in the query. Numbers and symbols are considered words and are escaped.
  const queryGroups = query
    .toLowerCase()
    .trim()
    .replace(/[^a-zA-Z0-9 ]/g, og => ` \\${og} `)
    .split(" ");

  // Create a regex that can fuzzily match inputs
  // e.g. If any word or symbol in the query is contained within an input, it will match
  // e.g. If the query is "big well", it will match the string "big red well"
  const queryWordMatch = new RegExp(
    queryGroups.join(`[\\/\\-a-zA-Z0-9 ]*`),
    "g",
  );

  // Functions that match a well object property against the search query
  // These should return falsy if no match is found
  // If a match is found, return the output of a call to `createSearchResult()`
  const searchMethods = [
    byDisplayName,
    byDownholeLocation,
    byDeviceSerialNumber,
  ];

  // Filter each well based on object property searches
  for (let i = 0; i < wells.length; i++) {
    const well = wells[i];
    const helpers = {
      query: query.trim(),
      queryGroups,
      queryWordMatch,
    };

    // Check if this well matches the search criteria specified in each search method
    for (let i = 0; i < searchMethods.length; i++) {
      const method = searchMethods[i];

      const result = method(well, helpers);

      if (result) {
        groupedResults.push(result);
        break;
      }
    }
  }

  // Create a stable search by sorting alphabetically, and then by position of matched word
  const alphabeticalOrder = sortBy(groupedResults, ["data", "displayName"]);
  const indexOrder = sortBy(alphabeticalOrder, "index");

  return indexOrder;
};
