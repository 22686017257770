import {FlexibleXYPlot, PolygonSeries, XAxis, YAxis} from "react-vis";
import {withSize} from "react-sizeme";
import maxBy from "lodash/maxBy";
import minBy from "lodash/minBy";
import React from "react";

import colors from "theme/colors";

const Dynocard = props => {
  const {
    downholeData = [],
    hideAxis = false,
    margin = {
      left: hideAxis ? 20 : 40,
      right: 20,
      top: 20,
      bottom: hideAxis ? 20 : 40,
    },
    ratio = 2 / 3,
    size,
    surfaceData = [],
    width = false,
    overrideColor,
  } = props;

  const seriesStyle = {
    fill: "none",
    strokeOpacity: 1,
    strokeWidth: 2,
  };

  const axisStyle = {
    line: {stroke: "#E3E3E3", strokeWidth: 1},
    text: {stroke: "none", fill: "#D8D8D8", fontSize: 12},
    ticks: {stroke: "#E3E3E3"},
  };
  const yAxisStyle = {
    title: {
      transform: "translate(6px, 4px) rotate(-45deg)",
      fill: "#D8D8D8",
    },
  };

  const allData = [...surfaceData, ...downholeData];

  let yDomain = [];
  let xDomain = [];

  if (allData.length) {
    yDomain = [minBy(allData, "y").y - 1500, maxBy(allData, "y").y + 1500];

    xDomain = [
      minBy(allData, "x").x - maxBy(allData, "x").x * 0.1,
      maxBy(allData, "x").x + maxBy(allData, "x").x * 0.1,
    ];
  }

  const use1kSteps = yDomain[0] < -999 || yDomain[1] > 999;

  return (
    <FlexibleXYPlot
      width={width ? width : size.width}
      height={width ? width * ratio : size.width * ratio}
      margin={margin}
      yBaseValue={-2000}
      yDomain={yDomain}
      xDomain={xDomain}
    >
      {!hideAxis && (
        <XAxis
          tickSize={3}
          style={axisStyle}
          tickSizeInner={0}
          tickSizeOuter={5}
        />
      )}
      {!hideAxis && (
        <YAxis
          title={use1kSteps ? "Thousands" : ""}
          tickSize={3}
          style={{...axisStyle, ...yAxisStyle}}
          tickFormat={val => (use1kSteps ? (val / 1000).toFixed(0) : val)}
          tickSizeInner={0}
          tickSizeOuter={5}
        />
      )}
      {surfaceData.length > 1 && (
        <PolygonSeries
          style={{
            ...seriesStyle,
            stroke: overrideColor || colors.surfaceCard[400],
          }}
          data={surfaceData}
        />
      )}
      {downholeData.length > 1 && (
        <PolygonSeries
          style={{
            ...seriesStyle,
            stroke: overrideColor || colors.downholeCard[400],
          }}
          data={downholeData}
        />
      )}
    </FlexibleXYPlot>
  );
};

export default withSize()(Dynocard);
