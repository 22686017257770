import isArray from "lodash/isArray";
import PropTypes from "prop-types";
import some from "lodash/some";

function render(props) {
  if (typeof props.children === "function") {
    return props.children();
  }

  return props.children || null;
}

export const Then = props => {
  return render(props);
};

export const Else = props => {
  return render(props);
};

export const If = props => {
  const {condition} = props;

  if (!props.children) {
    return null;
  }

  const children = isArray(props.children)
    ? [...props.children]
    : [props.children];

  if (!some(children, c => c.type === Then || c.type === Else) && condition) {
    return children;
  }

  return children.find(c => (c.type === Else) ^ condition) || null;
};

If.propTypes = {
  condition: PropTypes.bool.isRequired,
};

export default If;
