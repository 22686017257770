import {useEffect} from "react";
import {push} from "react-router-redux";

import {set as setOrganization} from "actions/organization-view";
import {show as showSnackbar} from "actions/snackbar";

/**
 * Higher order route that will serve well components as a sub-route beneath
 * an organization ID. When navigating to this route, the user's organization will be set to
 * the organization in the route as long as they have permission for it.
 */
const Organization = props => {
  const {
    match: {
      params: {downholeLocation, organizationId},
    },
    location: {pathname, search},
    auth: {
      user: {assignedOrganizations},
    },
    dispatch,
  } = props;

  useEffect(
    () => {
      if (downholeLocation && organizationId) {
        // Check that user is assigned to organizationId
        const isAssigned = assignedOrganizations.find(
          ({organizationId: orgId}) => orgId === organizationId,
        );

        // Switch to organization, do not redirect to '/'
        if (isAssigned) {
          // remove /organization/{org_id} from the url, redirect to plain well url
          const redirectURI = `/${pathname
            .split("/")
            .slice(3)
            .join("/")}${search}`;

          dispatch(
            setOrganization({
              currentOrganization: organizationId,
              redirectTo: redirectURI,
            }),
          );
        } else {
          dispatch(push("/"));
          dispatch(
            showSnackbar({
              message:
                "You do not have permission to access that organization.",
            }),
          );
        }
      }
    },
    [
      downholeLocation,
      organizationId,
      dispatch,
      assignedOrganizations,
      pathname,
      search,
    ],
  );

  // We should never make it past the `useEffect` call above, render null
  return null;
};

export default Organization;
