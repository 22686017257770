import map from "lodash/map";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import TextField from "@material-ui/core/TextField";

export const Mapping = props => {
  const {label, mapping, onChange, options} = props;

  return (
    <TextField
      style={{verticalAlign: "unset"}}
      select
      label={label}
      value={mapping.type}
      onChange={onChange}
    >
      {map(options, item => {
        return (
          <MenuItem key={item.type} value={item.type}>
            {item.display}
          </MenuItem>
        );
      })}
    </TextField>
  );
};
