export const types = {
  add: "alerts:add",
  addTimelineEvent: "alert:timeline:add",
  acknowledge: "alerts:acknowledge",
  fetch: "alerts:fetch",
  fetchTimeline: "alerts:fetch:timeline",
  filter: "alerts:filter",
  hide: "alerts:hide",
  parse: "alerts:parse-one",
  refreshFilters: "alerts:refresh-filters",
  resolve: "alerts:resolve",
  set: "alerts:set",
  setOne: "alerts:set-one",
  setTimeline: "alerts:set:timeline",
  show: "alerts:show",
  sort: "alerts:sort",
  update: "alerts:update-one",
};

export const add = ({data}) => {
  return {
    type: types.add,
    payload: {
      ...data,
    },
  };
};

export const addTimelineEvent = ({data}) => {
  return {
    type: types.addTimelineEvent,
    payload: {
      ...data,
    },
  };
};

export const acknowledge = ({acknowledgeUntil, alertId}) => {
  return {
    type: types.acknowledge,
    payload: {
      acknowledgeUntil,
      alertId,
    },
  };
};

export const fetch = ({clientUserId}) => {
  return {
    type: types.fetch,
    payload: {
      clientUserId,
    },
  };
};

export const fetchTimeline = ({alertId}) => {
  return {
    type: types.fetchTimeline,
    payload: {
      alertId,
    },
  };
};

export const filter = ({filter}) => {
  return {
    type: types.filter,
    payload: {
      filter,
    },
  };
};

export const hide = () => {
  return {
    type: types.hide,
    payload: {},
  };
};

export const parse = ({data}) => {
  return {
    type: types.parse,
    payload: {
      ...data,
    },
  };
};

export const refreshFilters = () => {
  return {
    type: types.refreshFilters,
  };
};

export const resolve = ({comment = null, alertId}) => {
  return {
    type: types.resolve,
    payload: {
      comment,
      alertId,
    },
  };
};

export const set = ({data, user}) => {
  return {
    type: types.set,
    payload: {
      data,
      user,
    },
  };
};

export const setOne = ({data}) => {
  return {
    type: types.setOne,
    payload: {
      ...data,
    },
  };
};

export const setTimeline = ({alertId, timeline}) => {
  return {
    type: types.setTimeline,
    payload: {
      alertId,
      timeline,
    },
  };
};

export const sort = ({sortBy}) => {
  return {
    type: types.sort,
    payload: {
      sortBy,
    },
  };
};

export const show = ({alertId}) => {
  return {
    type: types.show,
    payload: {
      alertId,
    },
  };
};

export const update = ({data}) => {
  return {
    type: types.update,
    payload: {
      ...data,
    },
  };
};
