import {CustomSVGSeries, LabelSeries, LineSeries, XYPlot} from "react-vis/es";
import React from "react";

import {formatNumber} from "utils/format-number";
import getUnits from "utils/get-units";

const circle = {stroke: "grey", fill: "transparent", strokeDasharray: "2,1"};
const line = {stroke: "#ccc", strokeWidth: "1", strokeLinecap: "round"};
const label = {fontSize: 10, opacity: 0.87};

const SizeLegend = ({max = 0, min = 0, unit}) => {
  const circles = [
    {x: 2, y: 1.6, customComponent: "circle", size: 10},
    {x: 2, y: 1.9, customComponent: "circle", size: 20},
    {x: 2, y: 2.5, customComponent: "circle", size: 40},
  ];

  // If the min and max are ever the same, the legend will look funny because the middle circle is calculated
  // as a fraction of the max. So you would see 100, 25, 100. The check in min makes sure it would be 0, 25, 100.
  const labels = [
    {x: 4, y: 3.8, label: `${formatNumber(max, 0)}`},
    {x: 4, y: 2.9, label: `${formatNumber(max * 0.25, 0)}`},
    {x: 4, y: 1.4, label: `${formatNumber(min === max ? 0 : min, 0)}`},
  ];

  return (
    <div>
      <p>
        Circles are sized according to the magnitude of change in{" "}
        {getUnits()[unit] || unit}.
      </p>

      <XYPlot
        margin={{left: 0, right: 0, top: 0, bottom: 0}}
        width={100}
        height={50}
        xDomain={[1, 4]}
        yDomain={[1, 4]}
      >
        <CustomSVGSeries data={circles} style={circle} />
        <LineSeries data={[{x: 2.53, y: 3.3}, {x: 3, y: 3.3}]} style={line} />
        <LineSeries data={[{x: 2.25, y: 2.4}, {x: 3, y: 2.4}]} style={line} />
        <LineSeries data={[{x: 2.2, y: 1.5}, {x: 3, y: 1.5}]} style={line} />
        <LabelSeries data={labels} style={label} />
      </XYPlot>
    </div>
  );
};

export default SizeLegend;
