import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const Leaking = ({className}) => {
  return (
    <SvgIcon className={className} viewBox="0 0 230 139">
      <path
        d="M115 3.5c-32.112 0-58.22 5.837-77.193 18.816C15.482 37.59 3.5 62.525 3.5 99.01V121c0 8.008 6.492 14.5 14.5 14.5h194c8.008 0 14.5-6.492 14.5-14.5V99.01c0-36.593-11.977-61.54-34.305-76.783C173.245 9.29 147.183 3.5 115 3.5z"
        stroke="currentColor"
        strokeWidth={7}
        fill="none"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export default Leaking;
