import React, {useEffect, useCallback} from "react";

import MuiDialog from "@material-ui/core/Dialog";
import withWidth from "@material-ui/core/withWidth";

import noop from "utils/noop";

const Dialog = ({
  open,
  onBackdropClick,
  onClose,
  maxWidth = "sm",
  children,
  ...props
}) => {
  const closeHandler = useCallback(
    () => (onClose || onBackdropClick || noop)(),
    [onClose, onBackdropClick],
  );

  // Close the dialog if the user changes the page with browser buttons
  useEffect(
    () => {
      if (open) {
        window.addEventListener("beforeunload", closeHandler, {once: true});
        window.addEventListener("hashchange", closeHandler, {once: true});
      }

      return () => {
        window.removeEventListener("beforeunload", closeHandler);
        window.removeEventListener("hashchange", closeHandler);
      };
    },

    // Can't trust that every consumer of the dialog will pass in referentially
    // stable close handler
    // eslint-disable-next-line
    [open],
  );

  return (
    <MuiDialog
      open={open}
      maxWidth={maxWidth}
      onBackdropClick={onBackdropClick}
      onClose={onClose}
      {...props}
    >
      {children}
    </MuiDialog>
  );
};

export default withWidth()(Dialog);
