import escapeRegex from "utils/escape-regex";
import some from "lodash/some";

export default (items = [], query = null) => {
  if (!query) return items;

  query = query.trim();

  return items.filter(item =>
    some(item.internalTags || [], tag =>
      tag.match(new RegExp(escapeRegex(query), "i")),
    ),
  );
};
