import React from "react";
import {withStyles} from "@material-ui/core";

const styles = {
  count: {
    userSelect: "none",
    borderRadius: "50%",
    backgroundColor: "#857F72",
    color: "#fff",
    width: 25,
    height: 25,
    marginRight: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "&:focus, &:hover": {
      color: "#fff",
    },
  },
  selected: {
    backgroundColor: "rgb(79, 101, 176)",
  },
  disabled: {
    backgroundColor: "#A39E93",
  },
};

const enhance = withStyles(styles);

export const Count = enhance(({classes, disabled, selected, matches}) => {
  const selectedClass = selected ? classes.selected : "";
  const disabledClass = disabled ? classes.disabled : "";
  const className = `${classes.count} ${selectedClass} ${disabledClass}`;

  return <div className={className}>{matches}</div>;
});
