import {types} from "actions/wells";
import {types as taggingTypes} from "actions/tagging";
import {MAP_TYPES} from "constants/map-types";
import {TYPES as WELL_FEED_FILTER_TYPES} from "constants/well-feed-filters";
import {workflows} from "constants/well-workflows";
import {reducerFactory} from "reducers/reducer-factory";
import {LAST_VIEW_TYPE} from "constants/local-storage-keys";
import resetOnSignout from "utils/reducers/reset-on-signout";

import {setActive} from "./set-active";
import {setActiveAnomaly} from "./set-active-anomaly";
import setAllRecommendations from "./set-all-recommendations";
import {setAllRecommendationsFetched} from "./set-all-recommendations-fetched";
import {setMapType} from "./set-map-type";
import {setMapBounds} from "./set-map-bounds";
import {setActivePath} from "./workflows/set-active-path";
import {
  setAnomalyWells,
  setDisplayAllWells,
  setWorkflow,
  setCalibrateWells,
  setTriageWells,
} from "./workflows";
import {setSearchCriteria} from "./set-search-criteria";
import {setSearchResults} from "./set-search-results";
import {setViewType} from "./set-view-type";
import {setWellsFetched} from "./set-wells-fetched";
import fetchAnalysis from "./fetch-analysis";
import fetchAnalysisComplete from "./fetch-analysis-complete";
import fetchEvents from "./fetch-events";
import generateShareableAnalysisLink from "./generate-shareable-analysis-link";
import hide from "./hide";
import refreshFilters from "./refresh-filters";
import removeRecommendation from "./remove-recommendation";
import set from "./set";
import setAlerts from "./set-alerts";
import setAnalysis from "./set-analysis";
import setAssigned from "./set-assigned";
import setComparisons from "./set-comparisons";
import setEvents from "./set-events";
import setOnCall from "./set-on-call";
import setOne from "./set-one";
import setRecommendations from "./set-recommendations";
import setSetupError from "./set-setup-error";
import setShareableAnalysisLink from "./set-shareable-analysis-link";
import setWellFeedFilter from "./set-well-feed-filter";
import show from "./show";
import sort from "./sort";
import toggleComparisonsView from "./toggle-comparisons-view";
import updateRecommendation from "./update-recommendation";
import {updateWellTags} from "./update-well-tags";

const defaults = {
  activeDownholeLocation: null,
  allWellIds: [],
  assigned: [],
  analysis: {
    link: {
      originalUrl: null,
      url: null,
      isFetching: false,
    },
  },
  byDownholeLocation: {},
  byWellId: {},
  fetched: false,
  mapType: MAP_TYPES.roadmap,
  mapBounds: null,
  rollup: {
    fillage: [0],
    uptime: [0],
  },
  filteredByTag: [],
  selected: null,
  search: {
    criteria: "",
    wellList: [],
  },
  sorting: {
    sortAsc: false,
    sortBy: "displacement",
  },
  staleFilters: false,
  viewType: localStorage.getItem(LAST_VIEW_TYPE) || "list",
  wellFeedFilter: WELL_FEED_FILTER_TYPES.ALL,
  workflow: {
    selected: workflows.DISPLAY_ALL,
    anomaly: {
      activeAnomaly: undefined,
      wellList: [],
    },
    triage: {
      filtered: [],
      all: [],
    },
    calibrate: {
      filtered: [],
      all: [],
    },
    activePath: undefined,
    displayAll: {
      filtered: [],
      all: [],
    },
  },
};

const actionMap = {
  [types.fetchAnalysis]: fetchAnalysis,
  [types.fetchAnalysisComplete]: fetchAnalysisComplete,
  [types.fetchEvents]: fetchEvents,
  [types.generateShareableAnalysisLink]: generateShareableAnalysisLink,
  [types.hide]: hide,
  [types.refreshFilters]: refreshFilters,
  [types.removeRecommendation]: removeRecommendation,
  [types.set]: set,
  [types.setActive]: setActive,
  [types.setActiveAnomaly]: setActiveAnomaly,
  [types.setActivePath]: setActivePath,
  [types.setAlerts]: setAlerts,
  [types.setAllRecommendations]: setAllRecommendations,
  [types.setAllRecommendationsFetched]: setAllRecommendationsFetched,
  [types.setAnalysis]: setAnalysis,
  [types.setAnomalyWells]: setAnomalyWells,
  [types.setCalibrateWells]: setCalibrateWells,
  [types.setTriageWells]: setTriageWells,
  [types.setAssigned]: setAssigned,
  [types.setComparisons]: setComparisons,
  [types.setDisplayAllWells]: setDisplayAllWells,
  [types.setEvents]: setEvents,
  [types.setMapType]: setMapType,
  [types.setMapBounds]: setMapBounds,
  [types.setOnCall]: setOnCall,
  [types.setOne]: setOne,
  [types.setRecommendations]: setRecommendations,
  [types.setSearchCriteria]: setSearchCriteria,
  [types.setSearchResults]: setSearchResults,
  [types.setSetupError]: setSetupError,
  [types.setShareableAnalysisLink]: setShareableAnalysisLink,
  [types.setViewType]: setViewType,
  [types.setWellFeedFilter]: setWellFeedFilter,
  [types.setWorkflow]: setWorkflow,
  [types.show]: show,
  [types.sort]: sort,
  [types.setWellsFetched]: setWellsFetched,
  [types.toggleComparisonsView]: toggleComparisonsView,
  [types.updateRecommendation]: updateRecommendation,
  [taggingTypes.updateWellTags]: updateWellTags,
};

export default resetOnSignout(reducerFactory(actionMap, defaults));
