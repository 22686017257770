import LinearProgress from "@material-ui/core/LinearProgress";
import colors from "theme/colors";
import React from "react";

import Chart from "./d3-chart";
import Toolbar from "./toolbar";

const styles = {
  container: {
    backgroundColor: colors.white,
    border: `1px solid ${colors.grey[300]}`,
    paddingBottom: "16px",
    textTransform: "uppercase",
  },
};

class AnalysisChart extends React.Component {
  componentDidMount() {
    // TODO: On focus is only given once, so if there are values that change from the outer components, they will not be accessible. Make this better some how.
    this.chart = new Chart({node: this.svg, onFocus: this.props.focus});
    this.draw();
  }

  componentDidUpdate() {
    this.draw();
  }

  draw() {
    const {comparisons, config, data} = this.props;

    this.chart.draw({
      config,
      data,
      selections: comparisons,
    });
  }

  render() {
    const {
      addComparison,
      comparisons,
      config,
      correlationId,
      data,
      preset,
      removeComparison,
      width,
    } = this.props;

    return (
      <div style={styles.container}>
        <Toolbar
          addComparison={addComparison}
          comparisons={comparisons}
          correlationId={correlationId}
          date={config.date}
          preset={preset}
          removeComparison={removeComparison}
        />
        {!data ? <LinearProgress /> : null}
        <svg ref={node => (this.svg = node)} width={width} height={300} />
      </div>
    );
  }
}

export default AnalysisChart;
