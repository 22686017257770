import React from "react";
import Link from "react-router-dom/Link";
import withStyles from "@material-ui/core/styles/withStyles";

import {ReactComponent as NoData} from "./nodata.svg";

const styles = ({typography, spacing}) => ({
  noData: {
    display: "grid",
    gridTemplateRows: "3fr 1fr 1fr",
    padding: spacing(8),
    paddingTop: spacing(4),
    textAlign: "left",
  },

  noDataImg: {
    width: "100%",
    height: "430px",
  },

  noDataMessage: {
    ...typography.caption,
    margin: `${spacing(3)}px auto 0`,
  },

  link: {
    color: "inherit",
  },
});

const redirectTo = downholeLocation =>
  `/well/${encodeURIComponent(downholeLocation)}/setup/wellbore`;

const WellboreNoData = ({downholeLocation, classes}) => (
  <div className={classes.noData}>
    <div>
      <NoData className={classes.noDataImg} />
    </div>
    <div className={classes.noDataMessage}>
      Missing Information.
      <br />
      To view the Wellbore Survey complete your{" "}
      <Link to={redirectTo(downholeLocation)} className={classes.link}>
        well profile
      </Link>
      .
    </div>
  </div>
);

export default withStyles(styles)(WellboreNoData);
