import React, {useState} from "react";
import MapMarkerIcon from "mdi-material-ui/MapMarker";
import MenuDown from "mdi-material-ui/MenuDown";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import compose from "lodash/fp/compose";

import {eventAction, eventCategory} from "constants/analytics";
import {sendAnalyticsEvent} from "components/analytics";
import Tooltip from "components/material-tooltip";

import WellDetailPopover from "./well-detail-popover";

const styles = ({spacing, colors}) => ({
  caption: {
    color: colors.warmGrey[300],
    lineHeight: 1,
  },
  icon: {
    height: spacing(3),
    width: spacing(3),
    paddingRight: spacing(1),
    color: colors.warmGrey[300],
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  subhead: {
    display: "flex",
    alignItems: "center",
    color: colors.warmGrey[500],
  },
  subheadHover: {
    cursor: "pointer",
    color: colors.warmGrey[700],
    "& svg": {
      color: colors.warmGrey[500],
    },
  },
  IconRoot: {
    padding: 0,
  },
});

const WellDetailButton = ({onClick, classes}) => (
  <IconButton onClick={onClick} classes={{root: classes.IconRoot}}>
    <MenuDown />
  </IconButton>
);

const enhance = compose(withStyles(styles));

const Title = ({well, classes}) => {
  const [hovered, setHover] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const toggleOpen = event => {
    setAnchorEl(event.currentTarget.parentNode);

    setOpen(open => {
      const opening = !open;

      if (opening) {
        sendAnalyticsEvent(
          eventCategory.wellDashboard,
          eventAction.toggle,
          "Map Popout",
        );

        return opening;
      }
    });
  };

  return (
    <>
      <Tooltip title="Display details" placement="right">
        <span
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
          className={
            hovered || open
              ? clsx(classes.titleContainer, classes.subheadHover)
              : classes.titleContainer
          }
        >
          <MapMarkerIcon onClick={toggleOpen} className={classes.icon} />
          <Typography
            variant="subtitle1"
            className={
              hovered || open
                ? clsx(classes.subheadHover, classes.subhead)
                : classes.subhead
            }
            onClick={toggleOpen}
          >
            {well.displayName || well.downholeLocation}
          </Typography>
          <WellDetailButton
            onClick={toggleOpen}
            classes={{root: classes.IconRoot}}
          />
        </span>
      </Tooltip>
      <WellDetailPopover
        open={open}
        setOpen={setOpen}
        anchorEl={anchorEl}
        well={well}
      />
    </>
  );
};

export default enhance(Title);
