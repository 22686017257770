import {types} from "./types";

export const saveAutoAcceptRecommendations = ({
  autoAcceptRecommendationTypes,
  downholeLocation,
}) => {
  return {
    type: types.saveAutoAcceptRecommendations,
    payload: {
      autoAcceptRecommendationTypes,
      downholeLocation,
    },
  };
};
