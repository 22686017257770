import React from "react";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

import {ReactComponent as Icon} from "./icon.svg";

const styles = () => ({
  icon: {
    marginBottom: 10,
  },
  wrapper: {
    padding: 25,
    textAlign: "center",
  },
});

const NoWells = ({classes}) => {
  return (
    <div className={classes.wrapper}>
      <Icon className={classes.icon} />
      <Typography variant="h5">No wells found</Typography>
      <Typography variant="subtitle1">
        Try selecting another workflow
      </Typography>
    </div>
  );
};

export default withStyles(styles)(NoWells);
