const setDailySummaries = ({state, action}) => {
  const {liquidProduction, strokes, unitsOfMeasure} = action.payload;

  return {
    ...state,
    production: {
      fetched: true,
      liquidProduction,
      strokes,
      unitsOfMeasure,
    },
  };
};

export default setDailySummaries;
