/* eslint-disable jsx-a11y/anchor-is-valid */
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {connect} from "react-redux";

import colors from "theme/colors";
import {setFaultRecovery} from "actions/wells";
import FormHeader from "components/form-header";
import {Else, If, Then} from "components/if";
import PaperContainer from "components/paper-container";
import getPrivileges from "utils/get-privileges";
import privilegeTypes from "utils/privilege-types";

const styles = {
  link: {
    color: colors.ambyOrange[500],
    cursor: "pointer",
    textDecoration: "underline",
  },
};

class Component extends React.Component {
  render() {
    const {dispatch, well} = this.props;
    const faultRecoveryEnabled = !!well.faultRecoveryEnabled;

    const privileges = getPrivileges(well.organization.id);

    const faultRecoveryEnabledStyle = {
      color: faultRecoveryEnabled ? colors.green[500] : colors.red[500],
      textTransform: "uppercase",
    };

    const handleSetFaultRecovery = enabled => () => {
      return dispatch(
        setFaultRecovery({
          downholeLocation: well.downholeLocation,
          enabled,
          well,
        }),
      );
    };

    return (
      <PaperContainer extraPadded style={{maxWidth: "500px"}}>
        <Grid container>
          <Grid item xs={12} style={{marginTop: "0px", marginBottom: "0px"}}>
            <FormHeader>Fault Recovery</FormHeader>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Auto fault recovery is currently{" "}
              <span style={faultRecoveryEnabledStyle}>
                {faultRecoveryEnabled ? "enabled" : "disabled"}
              </span>
              .
            </Typography>
            {privileges.includes(privilegeTypes.well.configure) && (
              <Typography variant="body1" style={{marginTop: "8px"}}>
                <If condition={faultRecoveryEnabled}>
                  <Then>
                    ambyint is currently attempting to automatically reset
                    faults when they occur. If you would like to turn this off,{" "}
                    <a
                      style={styles.link}
                      onClick={handleSetFaultRecovery(false)}
                    >
                      disable auto fault recovery
                    </a>
                    .
                  </Then>
                  <Else>
                    <a
                      style={styles.link}
                      onClick={handleSetFaultRecovery(true)}
                    >
                      Enable auto fault recovery
                    </a>{" "}
                    to allow ambyint to attempt to automatically reset faults
                    when they occur.
                  </Else>
                </If>
              </Typography>
            )}
          </Grid>
        </Grid>
      </PaperContainer>
    );
  }
}

export default connect()(Component);
