import {push} from "react-router-redux";
import {of} from "rxjs/observable/of";

import {setAuth, types} from "actions/auth";
import fetchApi from "epics/fetch";

export default action$ => {
  return action$
    .ofType(types.requestPasswordResetEmail)
    .switchMap(({payload}) => {
      return fetchApi(
        `/client-experience/user/resetPassword/${encodeURIComponent(
          payload.email,
        )}`,
        {},
        {
          body: {userLogin: encodeURIComponent(payload.email)},
          method: "POST",
        },
      )
        .flatMap(res => {
          if (res.status >= 200 && res.status <= 300) {
            return of(
              push(
                `/password-reset-requested/${encodeURIComponent(
                  payload.email,
                )}`,
              ),
            );
          }

          return of(
            setAuth({
              isAuthenticated: false,
              isFetching: false,
              error:
                "An invalid response was received while resetting password. Please try again soon, or contact support if the issue continues.",
            }),
          );
        })
        .catch(err => {
          console.error(err);

          if (err.xhr.response.message === "Unknown user") {
            // Avoid making it easy to see if an email address exists in the system
            return of(
              push(
                `/password-reset-requested/${encodeURIComponent(
                  payload.email,
                )}`,
              ),
            );
          }

          let errorType =
            "An error occurred while resetting password. Please try again soon, or contact support if the issue continues.";

          if (err.xhr.response.message === "User is not verified") {
            errorType =
              "You have not verified your email, you'll need to check your email and complete the verification.";
          }

          if (
            err.xhr.response.message ===
            "Cannot reset password, has not been set"
          ) {
            errorType =
              "Unable to reset password.  If you're using a Google or Azure account to sign in, you'll have to visit them to reset your password.";
          }

          return of(
            setAuth({
              isAuthenticated: false,
              isFetching: false,
              error: errorType,
            }),
          );
        });
    });
};
