import Grid from "@material-ui/core/Grid";
import React from "react";

import FormButton from "components/form-button";
import FormHeader from "components/form-header";
import getPrivileges from "utils/get-privileges";
import PaperContainer from "components/paper-container";
import privilegeTypes from "utils/privilege-types";

import {cardLabelFactory, cardValueFactory} from "../cards";
import hasGeometry from "./has-geometry";
import {GeometryDetailsReadView} from "./geometry-details";
import PumpingUnitInformationEditView from "./pumping-unit-information-edit-view";

const CardLabel = cardLabelFactory(4);
const CardValue = cardValueFactory(8);

class PumpingUnitInformationReadView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {well} = this.props;
    const privileges = getPrivileges(well.organization.id);
    const pumpingUnit = well.pumpingUnit || {};

    if (this.state.showPumpingUnitInformationEditView) {
      return (
        <PumpingUnitInformationEditView
          onClose={() =>
            this.setState({showPumpingUnitInformationEditView: false})
          }
          well={well}
        />
      );
    }

    return (
      <PaperContainer extraPadded style={{maxWidth: "500px"}}>
        <Grid container>
          <Grid item xs={12} style={{marginTop: "0px", marginBottom: "0px"}}>
            <FormHeader>Pumping Unit Information</FormHeader>
          </Grid>

          <CardLabel label={"Pumping Unit"} />
          <CardValue
            value={
              pumpingUnit.pumpjack &&
              `${pumpingUnit.pumpjack.manufacturer} ${
                pumpingUnit.pumpjack.api
              } (${pumpingUnit.pumpjack.type})`
            }
          />

          {hasGeometry(pumpingUnit.pumpjack) && (
            <GeometryDetailsReadView pumpingUnit={pumpingUnit} />
          )}

          <Grid item xs={12}>
            {privileges.includes(privilegeTypes.well.configure) && (
              <FormButton
                onClick={() =>
                  this.setState({showPumpingUnitInformationEditView: true})
                }
                style={{marginTop: "8px"}}
              >
                Edit Pumping Unit Information
              </FormButton>
            )}
          </Grid>
        </Grid>
      </PaperContainer>
    );
  }
}

export default PumpingUnitInformationReadView;
