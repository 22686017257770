import React from "react";
import compose from "lodash/fp/compose";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

import KPIPanelCompression from "../kpi-panel-compression";
import CompressionGraphPlot from "./compression-graph-plot";
import CompressionGraphOverlay from "./compression-graph-overlay";
import Progress from "../progress";
import {useDomain} from "../../use-domain";

const MARGIN = {left: 60, right: 20, top: 24, bottom: 40};

const styles = ({typography, spacing, colors}) => ({
  subtitle: {
    ...typography.subtitle1,
    fontSize: typography.subtitle2.fontSize,
    color: colors.warmGrey[500],
  },

  grid: {
    display: "grid",
    gridTemplateColumns: "minmax(0, 1fr) 100px",
    gridTemplateRows: "auto 220px",
    gridTemplateAreas: '"title kpi" "graph graph"',
  },

  title: {
    gridArea: "title",
  },

  kpi: {
    gridArea: "kpi",
    alignSelf: "end",
  },

  graph: {
    gridArea: "graph",
    alignSelf: "end",
  },

  overlay: {
    gridArea: "graph",
    alignSelf: "end",
  },
});

const enhance = compose(withStyles(styles));

const CompressionGraph = ({
  width,
  fetched,
  title,
  series,
  medianSeries,
  seriesColor,
  medianSeriesColor,
  yLabel,
  showSetpointAxis,
  unitLabel,
  forceDomainZero,
  classes,
}) => {
  const domain = useDomain(series, 5, forceDomainZero);

  return (
    <div className={classes.grid}>
      <div className={classes.title}>
        <Typography variant="h6">{title}</Typography>
        <div className={classes.subtitle}>by day for 6 months</div>
      </div>
      {fetched ? (
        <>
          <div className={classes.kpi}>
            <KPIPanelCompression
              medianSeries={medianSeries}
              unitLabel={unitLabel}
            />
          </div>
          <div className={classes.graph}>
            <CompressionGraphPlot
              width={width}
              height={220}
              series={series}
              medianSeries={medianSeries}
              seriesColor={seriesColor}
              medianSeriesColor={medianSeriesColor}
              yLabel={yLabel}
              unitLabel={unitLabel}
              domain={domain}
              margin={MARGIN}
            />
          </div>
          <div className={classes.overlay}>
            <CompressionGraphOverlay
              width={width}
              height={220}
              medianSeries={medianSeries}
              domain={domain}
              margin={MARGIN}
              showSetpointAxis={showSetpointAxis}
            />
          </div>
        </>
      ) : (
        <Progress style={{gridArea: "graph"}} />
      )}
    </div>
  );
};

export default enhance(CompressionGraph);
