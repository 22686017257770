import process from "../process";

export const setActivePath = ({state, action}) => {
  return process({
    ...state,
    workflow: {
      ...state.workflow,
      activePath: action.payload.path,
    },
  });
};
