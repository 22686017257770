import {connect} from "react-redux";
import get from "lodash/get";
import Grid from "@material-ui/core/Grid";
import React from "react";
import {reduxForm} from "redux-form";

import {round} from "@ambyint/common/utils/round";

import getUnits from "utils/get-units";
import getDefault from "utils/get-default-value";
import SaveCancelButtons from "components/save-cancel-buttons";
import TextField from "components/forms/text-field";

import validate from "./validate";

const mapStateToProps = (state, props) => {
  const pumpoff = props.well.pumpOffSetting;
  const units = getUnits();
  const load = get(pumpoff, "load", getDefault(units.load));
  const position = get(pumpoff, "position", getDefault(units.position));

  return {
    initialValues: {
      load: {
        ...load,
        value: round(load.value),
      },
      position: {
        ...position,
        value: round(position.value),
      },
      strokes: pumpoff ? pumpoff.strokes : null,
    },
  };
};

const View = props => {
  const {handleSubmit, invalid, submitting, onSubmit, onCancel} = props;

  return (
    <div style={{maxWidth: "760px"}}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction={"row"}>
          <Grid item xs={12} sm={6} lg>
            <TextField fullWidth name={"load"} label={"Load"} required />
          </Grid>
          <Grid item xs={12} sm={6} lg>
            <TextField
              fullWidth
              name={"position"}
              label={"Position"}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} lg>
            <TextField
              fullWidth
              name={"strokes"}
              label={"Consecutive Pumpoff Strokes"}
              required
            />
          </Grid>
        </Grid>
        <SaveCancelButtons
          invalid={invalid}
          onClose={onCancel}
          submitting={submitting}
        />
      </form>
    </div>
  );
};

const Component = reduxForm({
  form: "set-pumpoff-surface-lufkin-settings-form",
  validate,
})(View);

export default connect(mapStateToProps)(Component);
