export const types = {
  fetchClassifications: "well-health::fetch-classifications",
  fetchCompressiveStress: "well-health::fetch-compressive-stress",
  fetchDailySummaries: "well-health::fetch-daily-summaries",
  fetchRankings: "well-health::fetch-rankings",
  fetchSetpointChanges: "well-health::fetch-setpoint-changes",
  setClassifications: "well-health::set-classifications",
  setDailySummaries: "well-health::set-daily-summaries",
  setCompressiveStress: "well-health::set-compressive-stress",
  setFocus: "well-health::set-focus",
  setRankings: "well-health::set-rankings",
  setpointChangesRetrieved: "well-health::setpoint-changes-retrieved",
  setWellboreSeries: "well-health::set-wellbore-series",
  setWellboreView: "well-health::set-wellbore-view",
  setWellId: "well-health::set-well-id",
};
