export default {
  ambyControlStopped: "Shutdown",
  ambySenseStopped: "Shutdown",
  deviceCommLoss: "Communication Loss",
  faultRecoveryFailed: "Fault Recovery Failed",
  lufkinStatusCode: "Status Code",
  powerLost: "Power Loss",
  maxCurrentViolation: "Max Current",
  maxTorqueViolation: "Max Torque",
  minCurrentViolation: "Min Current",
  minTorqueViolation: "Min Torque",
  stuffingBoxLeakPressure: "Stuffing Box Leak",
};
