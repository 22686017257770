export const types = {
  hideClearErrors: "dialogs::hide-clear-errors",
  hideResetFault: "dialogs::hide-reset-fault",
  hideResetMalfunctions: "dialogs::hide-reset-malfunctions",
  hideSetDowntime: "dialogs::hide-set-downtime",
  hideSetSpeed: "dialogs::hide-set-speed",
  hideSetSpeedRange: "dialogs::hide-set-speed-range",
  hideStartWell: "dialogs::hide-start-well",
  hideStopWell: "dialogs::hide-stop-well",
  showClearErrors: "dialogs::show-clear-errors",
  showResetFault: "dialogs::show-reset-fault",
  showResetMalfunctions: "dialogs::show-reset-malfunctions",
  showSetDowntime: "dialogs::show-set-downtime",
  showSetSpeed: "dialogs::show-set-speed",
  showSetSpeedRange: "dialogs::show-set-speed-range",
  showStartWell: "dialogs::show-start-well",
  showStopWell: "dialogs::show-stop-well",
};

export const hideClearErrors = () => {
  return {
    type: types.hideClearErrors,
  };
};

export const hideResetFault = () => {
  return {
    type: types.hideResetFault,
  };
};

export const hideResetMalfunctions = () => {
  return {
    type: types.hideResetMalfunctions,
  };
};

export const hideSetDowntime = () => {
  return {
    type: types.hideSetDowntime,
  };
};

export const hideSetSpeed = () => {
  return {
    type: types.hideSetSpeed,
  };
};

export const hideSetSpeedRange = () => {
  return {
    type: types.hideSetSpeedRange,
  };
};

export const hideStartWell = () => {
  return {
    type: types.hideStartWell,
  };
};

export const hideStopWell = () => {
  return {
    type: types.hideStopWell,
  };
};

export const showClearErrors = well => {
  return {
    type: types.showClearErrors,
    payload: {
      well,
    },
  };
};

export const showResetFault = well => {
  return {
    type: types.showResetFault,
    payload: {
      well,
    },
  };
};

export const showResetMalfunctions = well => {
  return {
    type: types.showResetMalfunctions,
    payload: {
      well,
    },
  };
};

export const showSetDowntime = well => {
  return {
    type: types.showSetDowntime,
    payload: {
      well,
    },
  };
};

export const showSetSpeed = well => {
  return {
    type: types.showSetSpeed,
    payload: {
      well,
    },
  };
};

export const showSetSpeedRange = well => {
  return {
    type: types.showSetSpeedRange,
    payload: {
      well,
    },
  };
};

export const showStartWell = well => {
  return {
    type: types.showStartWell,
    payload: {
      well,
    },
  };
};

export const showStopWell = well => {
  return {
    type: types.showStopWell,
    payload: {
      well,
    },
  };
};
