import get from "lodash/get";
import find from "lodash/find";

export const getCardOverride = (well, cardId) => {
  if (!cardId) return {};

  const classificationOverrides = get(
    well,
    "eventsByType.classificationOverrides",
    [],
  );
  return find(classificationOverrides, {cardId}) || {};
};
