import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(({colors}) => ({
  red: {
    fill: colors.red[400],
  },
  count: {
    fontSize: 10,
    fill: "#fff",
  },
}));

const IconWithCount = ({count, IconComponent, path, title}) => {
  const classes = useStyles();

  return count ? (
    <Tooltip title={`${count} ${title}`}>
      <div style={{position: "relative", width: 24, height: 24}}>
        <svg width="40" height="24">
          <defs>
            <mask id="theMask">
              <rect x="0" y="0" width="40" height="30" fill="#fff" />
              <circle cx="24" cy="8.5" r="10" />
            </mask>
          </defs>
          <path fill="currentColor" d={path} mask="url(#theMask)" />
          <circle className={classes.red} cx="24" cy="8.5" r="8" />

          {count >= 10 ? (
            <circle fill="white" cx="24" cy="8.5" r="2" />
          ) : (
            <text className={classes.count} textAnchor="middle" x="24" y="11.5">
              {count}
            </text>
          )}
        </svg>
      </div>
    </Tooltip>
  ) : (
    <Tooltip title={title}>
      <IconComponent />
    </Tooltip>
  );
};

export default IconWithCount;
