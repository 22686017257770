import Tooltip from "@material-ui/core/Tooltip";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  tooltip: {
    fontSize: theme.typography.caption.fontSize,
    lineHeight: theme.typography.caption.lineHeight,
  },
});

Tooltip.defaultProps = {
  placement: "top",
};

export default withStyles(styles)(Tooltip);
