import valueOf from "utils/value-of";
import isNil from "lodash/isNil";

/**
 * Generate an error if value <= min
 *
 * @param {number} value the value to test
 * @param {number} min the minimum value to test against
 *
 * @return {string|boolean} If the value is not greater than min, return an error string.
 *  If the value is greater than min, return false (for no error string)
 */
export default (value, min = 0) =>
  parseFloat(valueOf(value)) <= parseFloat(valueOf(min)) || isNil(value)
    ? `Must be greater than ${min}`
    : false;
