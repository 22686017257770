import {replace} from "react-router-redux";
import get from "lodash/get";
import isNil from "lodash/isNil";
import round from "lodash/round";

import {
  dailyProduction,
  dailyGasProduction,
} from "@ambyint/common/unit-converter";

import {createSwitchEpic} from "epics/create-epic";
import {setHistory, types} from "actions/wells/well-surveillance";
import fetch from "epics/async-fetch";

export const fetchHistory = createSwitchEpic(
  [types.fetchHistory],
  async ({payload}, store) => {
    const {downholeLocation, wellId} = payload;
    const unitsOfMeasure = get(
      store.getState(),
      "auth.user.unitsOfMeasure",
      "metric",
    );

    const gasMetrics =
      unitsOfMeasure.toLowerCase() === "metric" ? "e3m3/d" : "Mcf/d";

    try {
      const dailySummaries = await fetch(
        `/daily-summaries/${encodeURIComponent(downholeLocation)}/all`,
        {
          metrics: "oilProduction,gasProduction,waterProduction",
        },
      );

      const data = {
        oil: [],
        gas: [],
        water: [],
      };
      for (let i = 0; i < dailySummaries.length; i++) {
        const current = dailySummaries[i];
        const date = current.date.start;
        const dateObj = new Date(date);

        if (
          !isNil(current.oilProduction) &&
          !isNil(current.gasProduction) &&
          !isNil(current.waterProduction)
        ) {
          const oilProduction = round(
            dailyProduction.convert({
              value: current.oilProduction,
              from: "metric", // mongo stores values in metric, we can assume values will be metric
              to: unitsOfMeasure,
            }),
            2,
          );
          const gasProduction = round(
            dailyGasProduction.convert({
              value: current.gasProduction,
              from: "e3m3/d", // mongo stores values in metric, we can assume values will be metric
              to: gasMetrics,
            }),
            2,
          );
          const waterProduction = round(
            dailyProduction.convert({
              value: current.waterProduction,
              from: "metric", // mongo stores values in metric, we can assume values will be metric
              to: unitsOfMeasure,
            }),
            2,
          );

          data.oil.push({x: dateObj, y: oilProduction});
          data.gas.push({x: dateObj, y: gasProduction});
          data.water.push({x: dateObj, y: waterProduction});
        } else {
          const nullVal = {x: dateObj, y: null};

          data.oil.push(nullVal);
          data.gas.push(nullVal);
          data.water.push(nullVal);
        }
      }

      return [setHistory(wellId, data)];
    } catch (err) {
      return [replace(`/error?code=${err.status}`)];
    }
  },
);
