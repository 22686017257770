export const types = {
  fetch: "cranks:fetch",
  set: "cranks:set",
};

export const fetch = () => {
  return {
    type: types.fetch,
    payload: {},
  };
};

export const set = ({data}) => {
  return {
    type: types.set,
    payload: {
      data,
    },
  };
};
