import React from "react";

import getDisplayName from "utils/user/get-display-name";

import FeedItem from "../feed-item";
import {lineStyle} from "../styles";

const renderComment = event => {
  return (
    event.value.dismissedReason && (
      <span style={lineStyle}>
        {"With the comment: "}
        {event.value.dismissedReason}
      </span>
    )
  );
};

export default props => {
  const {event} = props;

  return (
    <FeedItem event={event}>
      <span style={lineStyle}>
        {getDisplayName(event)}
        {" declined the recommendation "}
      </span>
      <span style={lineStyle}>{event.value.description}</span>
      {renderComment(event)}
    </FeedItem>
  );
};
