import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import React from "react";
import withUnits from "utils/with-units";
import {Field} from "redux-form";

import {OutlinedInput} from "components/outlined-input";

import formatLabel from "./format-label";
import getValue from "./get-value";
import DisplayUnits from "./display-units";

export const TextField = props => {
  const {
    meta,
    fullWidth,
    input,
    disabled,
    showError,
    style,
    inputRef,
    helperText,
    classes,
    outlined = false,
    formattedLabel = true,
    ...otherProps
  } = props;

  const hasError =
    ((showError === undefined && (!!meta.dirty || !!meta.submitFailed)) ||
      showError) &&
    !!meta.error;

  const errorMessage = hasError && (
    <FormHelperText error>{meta.error}</FormHelperText>
  );

  const helperMessage = helperText && (
    <FormHelperText>{helperText}</FormHelperText>
  );

  const InputVariant = outlined ? OutlinedInput : Input;

  return (
    <FormControl
      fullWidth={fullWidth}
      style={{
        minHeight: 68,
        ...style,
      }}
      error={hasError}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
        }}
      >
        {!outlined && (
          <InputLabel>
            {formattedLabel ? formatLabel(props) : props.label || props.name}
          </InputLabel>
        )}
        <InputVariant
          value={getValue(props)}
          onChange={event => {
            const value = input.value.units
              ? withUnits(event.target.value, input.value.units)
              : event.target.value;
            input.onChange(value);
          }}
          disabled={disabled}
          fullWidth={fullWidth}
          inputRef={inputRef}
          {...otherProps}
        />
        <DisplayUnits input={input} />
      </div>
      {helperMessage}
      {errorMessage}
    </FormControl>
  );
};

export default props => {
  return <Field {...props} component={TextField} />;
};
