import isNull from "lodash/isNull";
import moment from "moment";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import getUnits from "utils/units";

const styles = theme => ({
  tooltip: {
    background: theme.palette.secondary.dark,
    borderRadius: 4,
    color: "#fff",
    padding: 15,
    position: "relative",
    width: 130,
    zIndex: 999,
  },

  tooltipDate: {
    display: "block",
    marginBottom: 15,
    width: "100%",
  },

  tooltipSpan: {
    display: "block",
  },

  tooltipSpanLeft: {
    display: "inline-block",
    textAlign: "left",
    width: "30%",
  },

  tooltipSpanRight: {
    display: "inline-block",
    fontWeight: 700,
    textAlign: "right",
    width: "70%",
  },
});

const CrosshairContent = ({activeSeries, classes, values, unitsOfMeasure}) => {
  const units = getUnits(unitsOfMeasure.toLowerCase());

  return (
    <div className={classes.tooltip}>
      <span className={classes.tooltipDate}>
        {moment(values[0].x).format("MMMM D, YYYY")}
      </span>
      {activeSeries.oil && (
        <span className={classes.tooltipSpan}>
          <span className={classes.tooltipSpanLeft}>Oil</span>
          <span className={classes.tooltipSpanRight}>
            {isNull(values[0].y)
              ? "n/a"
              : `${values[0].y.toFixed(2)} ${units.dailyProduction}`}
          </span>
        </span>
      )}
      {activeSeries.gas && (
        <span className={classes.tooltipSpan}>
          <span className={classes.tooltipSpanLeft}>Gas</span>
          <span className={classes.tooltipSpanRight}>
            {isNull(values[1].y)
              ? "n/a"
              : `${values[1].y.toFixed(2)} ${units.dailyGasProduction}`}
          </span>
        </span>
      )}
      {activeSeries.water && (
        <span className={classes.tooltipSpan}>
          <span className={classes.tooltipSpanLeft}>Water</span>
          <span className={classes.tooltipSpanRight}>
            {isNull(values[2].y)
              ? "n/a"
              : `${values[2].y.toFixed(2)} ${units.dailyProduction}`}
          </span>
        </span>
      )}
    </div>
  );
};

export default withStyles(styles)(CrosshairContent);
