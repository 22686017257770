import React from "react";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

import MaskedTextFieldComponent from "components/masked-text-field";
import addDecimals from "utils/add-decimals";
import subtractDecimals from "utils/subtract-decimals";
import valueOf from "utils/value-of";
import withUnits from "utils/with-units";
import colors from "theme/colors";

const styles = {
  button: {
    marginTop: 8,
    color: colors.ambyOrange[500],
  },
};

const DecimalPicker = props => {
  const increment = props.increment || 1;
  const value = valueOf(props.input.value) || 0;

  return (
    <div style={{display: "flex"}}>
      <MaskedTextFieldComponent
        value={value}
        label={props.label}
        inputMask={"Decimal"}
        outputMask={"Decimal"}
        fullWidth
        disabled={props.disabled}
        onChange={(event, newValue) =>
          props.input.onChange(
            props.input.value.units !== undefined
              ? withUnits(newValue, props.input.value.units)
              : newValue,
          )
        }
      />
      <IconButton
        style={{...styles.button}}
        onClick={() => {
          props.input.onChange(
            props.input.value.units !== undefined
              ? withUnits(
                  subtractDecimals(value, increment),
                  props.input.value.units,
                )
              : subtractDecimals(value, increment),
          );
        }}
      >
        <RemoveCircleOutlineIcon />
      </IconButton>
      <IconButton
        style={{...styles.button}}
        onClick={() => {
          const newValue =
            props.input.value.units !== undefined
              ? withUnits(
                  addDecimals(value, increment),
                  props.input.value.units,
                )
              : addDecimals(value, increment);
          props.input.onChange(newValue);
        }}
      >
        <AddCircleOutlineIcon />
      </IconButton>
    </div>
  );
};

export default DecimalPicker;
