import {show} from "actions/snackbar";
import {types} from "actions/wells";
import fetch from "epics/async-fetch";

export default action$ => {
  return action$.ofType(types.requestCard).switchMap(async ({payload}) => {
    const {downholeLocation} = payload;

    try {
      await fetch(
        `/wells/${encodeURIComponent(downholeLocation)}/requestSurfaceCard`,
        {},
        {method: "PUT"},
      );

      return show({
        message:
          "Card has been successfully requested! It could take some time.",
      });
    } catch (err) {
      console.error(err.status);

      return show({
        message:
          err.status === 429
            ? "Card requests can only be made once every 5 minutes."
            : "An unexpected error has occurred.",
      });
    }
  });
};
