/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {connect} from "react-redux";

import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import FormButton from "components/form-button";
import FormError from "components/form-error";

import styles from "routes/login/styles";

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

const TermsOfService = props => {
  const {auth, handleAccept, handleDecline} = props;

  return [
    <form key={0} style={styles.loginContainer}>
      <Grid container direction={"row"} spacing={2}>
        <Grid item xs={12}>
          <Typography style={{...styles.typography, fontWeight: "600"}}>
            Terms of Service
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography style={styles.typography}>
            By choosing “I AGREE” below you agree to Ambyint’s{" "}
            <a href="http://ambyint.com/terms" style={styles.link}>
              Terms of Service
            </a>{" "}
            and{" "}
            <a href="http://ambyint.com/privacy" style={styles.link}>
              Privacy Policy
            </a>
            , which include:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <ul style={styles.ul}>
            <li style={styles.bullet}>
              <Typography style={styles.bulletText}>
                Your obligations to comply with our terms and policies and the
                maintenance of your Ambyint account, and how your account and
                services may be terminated;
              </Typography>
            </li>
            <li style={styles.bullet}>
              <Typography style={styles.bulletText}>
                How we are always in the process of changing and improving our
                services, and how we may make changes to existing services
                including changing functionality or ceasing a service without
                notice;
              </Typography>
            </li>
            <li style={styles.bullet}>
              <Typography style={styles.bulletText}>
                How we use your data to help make modifications to, or develop
                new, products and services;
              </Typography>
            </li>
            <li style={styles.bullet}>
              <Typography style={styles.bulletText}>
                How we handle your personal information; and
              </Typography>
            </li>
            <li style={styles.bullet}>
              <Typography style={styles.bulletText}>
                Our limitations of liability and disclaimers of representations,
                conditions, and warranties.
              </Typography>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography style={styles.typography}>
            Choosing “I AGREE” signifies that you have read and agreed to the{" "}
            <a href="http://ambyint.com/terms" style={styles.link}>
              Terms of Service
            </a>{" "}
            and{" "}
            <a href="http://ambyint.com/privacy" style={styles.link}>
              Privacy Policy
            </a>{" "}
            and confirms that you have been given notice thereof.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormButton
            variant={"contained"}
            center
            fullWidth
            style={{color: "white", height: "40px"}}
            onClick={handleAccept}
          >
            I Agree
          </FormButton>
        </Grid>
        {auth.error && (
          <Grid item xs={12}>
            <FormError solid>{auth.error}</FormError>
          </Grid>
        )}
      </Grid>
    </form>,
    <footer key={1} style={styles.footer}>
      Don&apos;t agree?{" "}
      <a style={styles.link} onClick={handleDecline}>
        Return to sign in
      </a>
    </footer>,
  ];
};

export default connect(mapStateToProps)(TermsOfService);
