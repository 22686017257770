import socketIO from "socket.io-client";

export default applyMiddleware => {
  const socket = socketIO(process.env.REACT_APP_WS, {
    forceNew: true,
    transports: ["websocket", "flashsocket", "xhr-polling"],
  });

  return applyMiddleware(socket);
};
