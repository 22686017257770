import compose from "recompose/compose";
import {connect} from "react-redux";
import findKey from "lodash/fp/findKey";
import FormControl from "@material-ui/core/FormControl";
import map from "lodash/fp/map";
import React from "react";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";

import {DEVIATION_METRICS} from "constants/deviation";
import {eventAction, eventCategory} from "constants/analytics";
import {sendAnalyticsEvent} from "components/analytics";
import {setDeviationFilter} from "actions/deviation";

import CustomMenuItem from "./custom-menu-item";

const styles = ({palette, spacing}) => ({
  formControl: {
    marginLeft: spacing(2),
    minWidth: 120,
    verticalAlign: "baseline",
  },
  select: {
    fontWeight: 400,
    color: palette.text.secondary,
    "&:hover": {
      color: palette.text.primary,
    },
  },
});

const mapToMenuItems = map(key => {
  const d = DEVIATION_METRICS[key];

  return (
    <CustomMenuItem key={d.displayValue} value={key}>
      {d.displayValue}
    </CustomMenuItem>
  );
});

const mapStateToProps = ({deviationReport: {deviationFilter}}) => ({
  deviationFilter,
});

const mapDispatchToProps = dispatch => ({
  handleChange: e => {
    sendAnalyticsEvent(
      eventCategory.deviation,
      eventAction.filter,
      DEVIATION_METRICS[e.target.value].type,
    );
    dispatch(setDeviationFilter(DEVIATION_METRICS[e.target.value]));
  },
});

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
);

const DeviationFilter = ({classes, deviationFilter, handleChange}) => (
  <FormControl className={classes.formControl}>
    <Select
      className={classes.select}
      disableUnderline
      onChange={handleChange}
      value={findKey(deviationFilter, DEVIATION_METRICS)}
    >
      {mapToMenuItems(Object.keys(DEVIATION_METRICS))}
    </Select>
  </FormControl>
);

export default enhance(DeviationFilter);
