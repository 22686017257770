export default ({values, reverse}) => {
  return values.sort((a, b) => {
    let aV = a.fieldName.toLowerCase();
    let bV = b.fieldName.toLowerCase();

    if (aV === bV) {
      aV = a.displayName.toLowerCase();
      bV = b.displayName.toLowerCase();
    }

    return reverse * ((aV > bV) - (bV > aV));
  });
};
