import get from "lodash/get";

import {createSearchResult} from "./create-result";

/**
 * Search for a well based on its downhole location
 *
 * @param {object} well parsed well object
 * @param {object} helpers properties to perform search with
 */
export const byDownholeLocation = (well, helpers) => {
  const {query} = helpers;

  const dl = get(well, "downholeLocation", "").toLowerCase();

  if (dl.includes(query.toLowerCase())) {
    return createSearchResult(well);
  }

  return null;
};
