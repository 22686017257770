import {connect} from "react-redux";
import {push} from "react-router-redux";
import React from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import Typography from "@material-ui/core/Typography";

import colors from "theme/colors";
import formatDateTime from "@ambyint/common/utils/format/format-date-time";
import {hasControlIotBridge} from "@ambyint/common/utils/devices/control-iot-bridge";
import {hasControlForEpm} from "@ambyint/common/utils/devices/control-for-epm";

import Tooltip from "components/material/tooltip";
import PaperContainer from "components/paper-container";
import CollapsibleButton from "components/collapse-button";

import {
  defaultContainerStyle,
  defaultGridContainer,
  defaultTitleStyle,
  defaultMainStyle,
} from "../dashboard-styles";
import {DisplayRecent} from "./display-recent";

const actionsContainerStyle = {
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
};

const mapDispatchToProps = dispatch => ({
  viewAnalysis: ({well, range}) => {
    const start = new Date(range.start).getTime();
    const end = new Date(range.end).getTime();

    dispatch(
      push(
        `/well/${encodeURIComponent(
          well.downholeLocation,
        )}/analysis?rae=${end}&ras=${start}`,
      ),
    );
  },
});

class Component extends React.Component {
  state = {expanded: false};

  handleExpandClick = () => {
    this.setState({expanded: !this.state.expanded});
  };

  render() {
    const {well, viewAnalysis} = this.props;
    const classification = get(well, "classifications.optimization", {});
    const showPoc = hasControlIotBridge(well.devices);
    const showEpm = hasControlForEpm(well.devices);

    if (isEmpty(classification) || (!showEpm && !showPoc)) return null;

    const {latest, recent} = classification;

    return (
      <PaperContainer style={{...defaultContainerStyle}}>
        <Grid container style={{...defaultGridContainer, paddingBottom: 0}}>
          <Grid item xs={12}>
            <Typography variant="body1" style={defaultTitleStyle}>
              Updated on {formatDateTime(latest.classifiedOn, {timezone: true})}
            </Typography>
            <Typography variant="h6" style={defaultMainStyle}>
              {latest.label}
            </Typography>
            <Typography variant="body1" style={defaultTitleStyle}>
              Optimization Classification
            </Typography>
          </Grid>
        </Grid>
        <CardActions style={actionsContainerStyle}>
          <Tooltip title="Classification Range" placement="right">
            <IconButton
              onClick={() =>
                viewAnalysis({
                  well,
                  range: latest.range,
                })
              }
              style={{
                color: colors.ambyOrange[500],
              }}
            >
              <ShowChartIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="More Details" placement="right">
            <div>
              <CollapsibleButton onClick={this.handleExpandClick} />
            </div>
          </Tooltip>
        </CardActions>
        <Collapse in={this.state.expanded}>
          <Divider />
          <Grid container style={defaultGridContainer}>
            {recent.map((value, index) => (
              <DisplayRecent
                {...value}
                key={`${index}-recent-classifications`}
              />
            ))}
          </Grid>
        </Collapse>
      </PaperContainer>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(Component);
