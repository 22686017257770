const setpointChangesRetrieved = ({state, action}) => {
  const {changes} = action.payload;

  return {
    ...state,
    setpointChanges: {
      fetched: true,
      changes,
    },
  };
};

export default setpointChangesRetrieved;
