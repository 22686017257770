import {dailyProduction, density, dailyGasProduction} from "unit-converter";
import {getUnits} from "@ambyint/common/utils/get-units";

import omitDeepUndefined from "utils/omit-deep-undefined";
import valueOf from "utils/value-of";

import math from "mathjs";

const SI_PASCALS = "Pa";

function convertFromFlowPerDay(value) {
  if (value === undefined || value === null) {
    return value;
  }

  // converts m3/d to m3/s
  return value ? math.round(value * (1 / 86400), 9) : value;
}

function parseDecimals(value, decimals = 2) {
  if (value === undefined || value === null) {
    return value;
  }

  return value ? math.round(value, decimals) : value;
}

function convertPressure({value, to = SI_PASCALS, from = SI_PASCALS}) {
  if (value === undefined || value === null) {
    return value;
  }

  return parseDecimals(math.unit(value, from).toNumber(to), 0);
}

function parseSaveReservoirInformation(reservoir, unitsOfMeasure) {
  const units = getUnits(unitsOfMeasure);

  return omitDeepUndefined({
    driveType: reservoir.driveType,
    downholeLocation: reservoir.downholeLocation,
    staticReservoirPressure: convertPressure({
      value: valueOf(reservoir.staticReservoirPressure),
      from: units.pressure,
    }),
    bubblePointPressure: convertPressure({
      value: valueOf(reservoir.bubblePointPressure),
      from: units.pressure,
    }),
    oilDensity: density.convert({
      value: valueOf(reservoir.oilDensity),
      from: unitsOfMeasure,
    }),
    waterDensity: density.convert({
      value: valueOf(reservoir.waterDensity),
      from: unitsOfMeasure,
    }),
    gasDensity: density.convert({
      value: valueOf(reservoir.gasDensity),
      from: unitsOfMeasure,
    }),
    referenceProduction: {
      oilProduction: convertFromFlowPerDay(
        dailyProduction.convert({
          value: valueOf(reservoir.referenceProduction.oilProduction),
          from: unitsOfMeasure,
        }),
      ),
      waterProduction: convertFromFlowPerDay(
        dailyProduction.convert({
          value: valueOf(reservoir.referenceProduction.waterProduction),
          from: unitsOfMeasure,
        }),
      ),
      gasProduction: convertFromFlowPerDay(
        dailyGasProduction.convert({
          value: valueOf(reservoir.referenceProduction.gasProduction),
          from: units.dailyGasProduction,
        }),
      ),
      referencePBHP: convertPressure({
        value: valueOf(reservoir.referenceProduction.referencePBHP),
        from: units.pressure,
      }),
    },
  });
}

export default parseSaveReservoirInformation;
