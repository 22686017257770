import React from "react";

import SettingsUpdated from "../settings-updated";

export default props => {
  return (
    <SettingsUpdated
      {...props}
      changesRoute="/setup/pumpoff-settings"
      settingsName="pumpoff settings"
    />
  );
};
