export const getNotificationOptions = mobilePhone => {
  const hasPhone = mobilePhone !== null && mobilePhone !== "";

  const withPhoneOptions = hasPhone
    ? [
        {
          value: "Text",
          text: "Text",
        },
        {
          value: "Email & Text",
          text: "Email & Text",
        },
      ]
    : [];

  return [
    {
      value: "None",
      text: "Not Enabled",
    },
    {
      value: "Email",
      text: "Email",
    },
    ...withPhoneOptions,
  ];
};
