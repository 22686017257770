import React from "react";
import NeutralIcon from "@material-ui/icons/SentimentDissatisfied";

import EmptyState from "components/empty-state";
import PageContainer from "components/page-container";

const EmptyHome = () => (
  <PageContainer>
    <div>
      <EmptyState
        icon={NeutralIcon}
        mainText={"Uh oh, you have no wells assigned to you"}
        subText={
          "You'll have to contact an approver in your organization to gain access to some wells."
        }
      />
    </div>
  </PageContainer>
);

export default EmptyHome;
