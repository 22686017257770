import {compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";

import RecommendationBase from "./recommendation-base";
import withPropMappingByType from "./with-prop-mapping-by-type";
import recommendationStyle from "./recommendation-style";

const enhance = compose(
  withPropMappingByType,
  withStyles(recommendationStyle),
);

export default enhance(RecommendationBase);
