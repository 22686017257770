import get from "lodash/get";
import isNil from "lodash/isNil";

const searchObject = (object, searchPhrase, fields) => {
  return (searchPhrase || "").split(" ").reduce((previousWordsMatch, word) => {
    return (
      previousWordsMatch &&
      fields.reduce((previousFieldMatches, field) => {
        const value = get(object, field, null) || null;

        const anyFieldMatchesSoFar =
          previousFieldMatches ||
          (!isNil(value) &&
            value
              .toString()
              .toLowerCase()
              .includes((word || "").toLowerCase()));

        return anyFieldMatchesSoFar;
      }, false)
    );
  }, true);
};

export default searchObject;
