import {compose} from "recompose";
import Grid from "@material-ui/core/Grid";
import isEmpty from "lodash/isEmpty";
import noop from "lodash/noop";
import React from "react";

import FormButton from "components/form-button";
import FormHeader from "components/form-header";
import PaperContainer from "components/paper-container";
import withFeatureFlag from "components/with-feature-flag";

import getDisplayedSetting from "../get-displayed-setting";
import RodStringSectionReadView from "./rod-string-section-read-view";
import StatusIndicator from "../status-indicator";
import {Unconfigured} from "../unconfigured";
import withSettingStatus from "../with-setting-status";

const RodStringReadView = ({
  canEdit = false,
  onEdit = noop,
  styles = {},
  isStatusVisible,
  settings,
}) => {
  const setting = getDisplayedSetting(settings);

  const statusIndicator = (
    <StatusIndicator
      settings={settings}
      mappings={{
        interval: "",
        rodDimensions: "diameter",
        rodType: "label",
      }}
    />
  );

  return (
    <PaperContainer extraPadded style={styles.container}>
      <Grid container>
        <Grid item xs={12}>
          <FormHeader>
            Rod String
            {isStatusVisible && statusIndicator}
          </FormHeader>
        </Grid>

        {!isEmpty(setting) &&
          setting.map((section, idx) => {
            return (
              <RodStringSectionReadView
                key={`rod-string-section-read-view-${idx}`}
                section={section}
                idx={idx}
              />
            );
          })}

        {isEmpty(setting) && <Unconfigured />}

        {canEdit && (
          <FormButton onClick={onEdit}>Edit Rod String Sections</FormButton>
        )}
      </Grid>
    </PaperContainer>
  );
};

export default compose(
  withSettingStatus,
  withFeatureFlag("set-lufkin-rod-string"),
)(RodStringReadView);
