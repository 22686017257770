import React from "react";
import {connect} from "react-redux";
import startCase from "lodash/startCase";
import get from "lodash/get";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Checkbox from "components/forms/checkbox";
import FormError from "components/form-error";
import FormHeader from "components/form-header";
import PaperContainer from "components/paper-container";
import SearchInputWithSuggestions from "components/forms/search-input-with-suggestions";
import TextArea from "components/forms/text-area";

import {reduxForm, formValueSelector} from "redux-form";

import {saveWellboreInformation} from "actions/wells";
import {fetch, filter} from "actions/casing-specs";

import DecimalTextField from "components/forms/decimal-text-field";
import SaveCancelButtons from "components/save-cancel-buttons";

import colors from "theme/colors";
import compose from "utils/compose";
import getUnits from "utils/get-units";
import getUser from "utils/get-user";
import spreadIf from "utils/spread-if";
import unitsOf from "utils/units-of";
import valueOf from "utils/value-of";

import parseWellbore from "@ambyint/common/parsers/well/parse-wellbore";
import {stringifyWellboreSurvey} from "@ambyint/common/parsers/well/parse-wellbore-survey";

import validate from "./validate";

const selector = formValueSelector("wellbore-information-edit-view");
const mapStateToProps = (state, props) => {
  const wellbore =
    props.well.wellbore ||
    parseWellbore({wellbore: {}}, getUnits(), getUser().unitsOfMeasure);

  const casing = get(wellbore, "casing");
  const casingDescription = get(wellbore, "casing.description");
  const casingPerforationBottomMeasuredDepth = get(
    wellbore,
    "casingPerforationBottomMeasuredDepth",
  );
  const casingPerforationTopMeasuredDepth = get(
    wellbore,
    "casingPerforationTopMeasuredDepth",
  );
  const isVerticalWell = get(wellbore, "isVerticalWell");
  const trueVerticalDepth = get(wellbore, "trueVerticalDepth");
  const wellDepth = get(wellbore, "wellDepth");
  const wellboreSurvey = stringifyWellboreSurvey(
    get(wellbore, "wellboreSurvey", []),
  );

  return {
    casingSpecs: state.casingSpecs,
    ...spreadIf(props.well, {
      initialValues: {
        casing,
        casingDescription,
        casingPerforationBottomMeasuredDepth,
        casingPerforationTopMeasuredDepth,
        isVerticalWell,
        trueVerticalDepth,
        wellDepth,
        wellboreSurvey,
      },
    }),
    currentValues: {
      isVerticalWell: selector(state, "isVerticalWell"),
      wellDepth: selector(state, "wellDepth"),
      wellboreSurvey: selector(state, "wellboreSurvey"),
    },
  };
};

class WellboreInformationEditView extends React.Component {
  save = (values, func, props) => {
    if (Object.keys(props.syncErrors).length > 0) {
      return;
    }

    this.props.dispatch(
      saveWellboreInformation({
        ...values,
        downholeLocation: this.props.well.downholeLocation,
        wellId: this.props.well.wellId,
      }),
    );

    this.props.onClose();
  };

  componentWillMount() {
    this.props.dispatch(fetch());
  }

  render() {
    const {handleSubmit, onClose, pristine, submitting, reset} = this.props;
    const {
      currentValues: {isVerticalWell, wellboreSurvey, wellDepth},
    } = this.props;

    const decimalField = (name, label, xs = 6, onChange = undefined) => (
      <Grid item xs={xs}>
        <DecimalTextField
          fullWidth
          name={name}
          label={label || startCase(name.split(/(?=[A-Z])/).join(" "))}
          required
          onChange={onChange}
        />
      </Grid>
    );

    return (
      <PaperContainer extraPadded style={{maxWidth: "750px"}}>
        <form onSubmit={handleSubmit(this.save)}>
          <Grid container direction={"row"} spacing={2} style={{maxWidth: 776}}>
            <Grid item xs={12} style={{marginTop: "0px", marginBottom: "0px"}}>
              <FormHeader>Wellbore</FormHeader>
            </Grid>

            {decimalField("wellDepth", null, 3, (event, value) => {
              if (isVerticalWell) {
                this.props.change(
                  "wellboreSurvey",
                  `0,0,0\n${valueOf(value)},0,0`,
                );
              }
            })}

            <Grid item xs={9}>
              <SearchInputWithSuggestions
                name="casingDescription"
                label="Casing Outside Diameter"
                placeholder="Choose casing type"
                onSearch={event => {
                  event.value !== undefined &&
                    this.props.dispatch(filter({phrase: event.value}));
                }}
                throttle={1500}
                suggestions={
                  (this.props.casingSpecs && this.props.casingSpecs.filtered) ||
                  []
                }
                getSuggestionText={casing => casing.description}
                getSuggestionValue={casing => ({
                  text: casing.description,
                  casing,
                })}
                onSuggestionClick={data => {
                  this.props.change("casing", data.casing);
                }}
              />
            </Grid>

            {decimalField(
              "casingPerforationTopMeasuredDepth",
              "Perforation Top Depth",
            )}
            {decimalField(
              "casingPerforationBottomMeasuredDepth",
              "Perforation Bottom Depth",
            )}

            <Grid item xs={12} style={{marginTop: "32px", marginBottom: "0px"}}>
              <FormHeader>Wellbore Deviation Survey</FormHeader>
            </Grid>

            {decimalField("trueVerticalDepth")}

            <Grid item xs={12}>
              <Checkbox
                name="isVerticalWell"
                header="Survey Data"
                label="This is a vertical well"
                tooltip="Selecting this option will change the survey data to be vertical,<br/>
                  Measured depth will be set to current well depth, with no azimuth nor inclination."
                onChange={(event, value) => {
                  if (value) {
                    this.previousWellboreSurveyValue = wellboreSurvey;
                    this.props.change(
                      "wellboreSurvey",
                      `0,0,0\n${valueOf(wellDepth)},0,0`,
                    );
                  } else if (this.previousWellboreSurveyValue) {
                    this.props.change(
                      "wellboreSurvey",
                      this.previousWellboreSurveyValue,
                    );
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                style={{
                  display: "block",
                  ...spreadIf(isVerticalWell, {
                    color: colors.grey[400],
                  }),
                  margin: "8px 0",
                }}
              >{`Measured Depth (${unitsOf(
                wellDepth,
              )}), Inclinations (deg), Azimuth (deg)`}</Typography>

              <Typography
                variant="body2"
                style={{
                  display: "block",
                  ...spreadIf(isVerticalWell, {
                    color: colors.grey[400],
                  }),
                  margin: "8px 0",
                }}
              >{`Pasted column order must be: Measured Depth,
                  Inclination and Azimuth. Separate columns with commas,
                  spaces or tabs. Header information, extra columns to the right and
                    spaces between rows will be ignored.`}</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextArea
                disabled={!!isVerticalWell}
                name="wellboreSurvey"
                rows={15}
                fullWidth
                required
              />
            </Grid>

            {this.props.well.setupError && (
              <Grid item xs={12}>
                <FormError>{this.props.well.setupError}</FormError>
              </Grid>
            )}
          </Grid>

          <SaveCancelButtons
            pristine={pristine}
            reset={compose(
              onClose,
              reset,
            )}
            submitting={submitting}
          />
        </form>
      </PaperContainer>
    );
  }
}

const Component = reduxForm({
  form: "wellbore-information-edit-view",
  enableReinitialize: true,
  validate,
})(WellboreInformationEditView);

export default connect(mapStateToProps)(Component);
