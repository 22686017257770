import React from "react";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

const useStyles = makeStyles(({colors, spacing, typography}) => ({
  flexStart: {
    display: "flex",
    justifyContent: "flex-start",
  },
  countContainer: {
    padding: [[6, 4]],
  },
  header: {
    paddingTop: 40,
    paddingBottom: 20,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  tabs: {
    "& > *": {
      height: 40,
    },
    "& div": {
      overflowY: "hidden",
    },
    height: 40,
  },
  tab: {
    "& *": {
      padding: 0,
      lineHeight: 0,
    },
    color: colors.warmGrey[600],
    paddingLeft: 6,
    paddingRight: 6,
    minWidth: 120,
    height: 50,
  },
  indicator: {
    backgroundColor: colors.warmGrey[700],
  },
  subtitle1: {
    ...typography.subtitle1,
    textTransform: "unset",
  },
}));

const findTabProps = index => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const Header = ({
  secondaryCountComponent,
  onClick,
  text,
  handleTabChange,
  topHeader,
  tabIndex,
  countComponent,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <div className={classes.flexStart}>
        <Button className={classes.subtitle1} onClick={onClick}>
          {text}
        </Button>
        <span className={classes.countContainer}>
          {countComponent} {secondaryCountComponent}
        </span>
      </div>
      <div>
        {topHeader && (
          <Tabs
            className={classes.tabs}
            value={tabIndex}
            onChange={handleTabChange}
            classes={{indicator: classes.indicator}}
          >
            <Tab
              label="Speed Range"
              className={classes.tab}
              {...findTabProps(0)}
            />
            <Tab
              label="Idle Time"
              className={classes.tab}
              style={{padding: 6}}
              {...findTabProps(1)}
              disabled
            />
          </Tabs>
        )}
      </div>
    </div>
  );
};

export default Header;
