import Grid from "@material-ui/core/Grid";
import isEmpty from "lodash/isEmpty";
import React from "react";
import Typography from "@material-ui/core/Typography";

import Item from "../item";

const renderClassification = classification => {
  const {confidence, label, type} = classification;

  return <Item key={type} title={label} value={`${confidence}%`} />;
};

const renderClassifications = classifications => {
  return classifications.map(renderClassification);
};

export const Section = props => {
  const {classifications, label, xs = 12} = props;

  if (isEmpty(classifications)) {
    return null;
  }

  return (
    <Grid item style={{marginBottom: "16px"}} xs={xs}>
      {label && (
        <Typography
          style={{
            borderBottom: "1px solid rgb(54, 70, 80)",
            display: "inline",
          }}
          variant="subtitle2"
        >
          {label}
        </Typography>
      )}
      {renderClassifications(classifications)}
    </Grid>
  );
};
