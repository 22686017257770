import {useState, useEffect} from "react";
import nice from "utils/nice";
import {isNil, partition} from "lodash";

import mapToDomain from "./map-to-domain";
import trendLine from "./trendline";

const INIT_STATE = {
  series: [[], []],
  trendLine: {points: []},
  xDomain: null,
  yDomain: null,
};

export const usePreparedChartData = (
  wells,
  classification,
  toSeries,
  unitsOfMeasure,
) => {
  const [chartState, setChartState] = useState(INIT_STATE);

  useEffect(
    () => {
      const data = wells
        .map(well => toSeries(well, unitsOfMeasure))
        .filter(well => !isNil(well.x) && !isNil(well.y));

      const [xDomain, yDomain] = mapToDomain(data);
      const ticks = nice(xDomain, 2).ticks(5);

      setChartState({
        series: partition(data, d => d.classification === classification),
        trendLine: trendLine(data, [ticks[0], ticks[ticks.length - 1]]),
        xDomain: nice(xDomain, 2),
        yDomain: nice(yDomain, 2),
      });
    },
    [classification, wells, toSeries, unitsOfMeasure],
  );

  return chartState;
};
