import {MAP_TYPES} from "constants/map-types";

import roadmapRunningMarkerURL from "./roadmap-running-marker.png";
import roadmapShutdownMarkerURL from "./roadmap-shutdown-marker.png";
import roadmapUnknownMarkerURL from "./roadmap-unknown-marker.png";
import satelliteRunningMarkerURL from "./satellite-running-marker.png";
import satelliteShutdownMarkerURL from "./satellite-shutdown-marker.png";
import satelliteUnknownMarkerURL from "./satellite-unknown-marker.png";

export const markerIcons = {
  [MAP_TYPES.roadmap]: {
    running: roadmapRunningMarkerURL,
    shutdown: roadmapShutdownMarkerURL,
    unknown: roadmapUnknownMarkerURL,
  },
  [MAP_TYPES.satellite]: {
    running: satelliteRunningMarkerURL,
    shutdown: satelliteShutdownMarkerURL,
    unknown: satelliteUnknownMarkerURL,
  },
};
