import Aux from "components/aux";
import Typography from "@material-ui/core/Typography";
import React from "react";

import getDisplayName from "utils/user/get-display-name";

export default props => {
  const {event} = props;

  return (
    <Aux>
      <Typography variant="body1">
        {"The well"}
        <strong>{` ${event.data}`}</strong>
        {" was added by"}
        <strong>{` ${getDisplayName(event.performedBy)}`}</strong>
      </Typography>
    </Aux>
  );
};
