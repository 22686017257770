import {RECOMMENDATION_CREATED} from "@ambyint/common/constants/notifications";

export const notificationTypes = [
  {
    type: RECOMMENDATION_CREATED,
    primary: "Recommendation Received",
    secondary: "Get notified by email when you receive a new recommendation",
    options: [
      {
        value: false,
        text: "Not Enabled",
      },
      {
        value: true,
        text: "Enabled",
      },
    ],
  },
];
