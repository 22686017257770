import React from "react";

import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";

import pluralize from "@ambyint/common/utils/format/format-pluralize";

import PaperContainer from "components/paper-container";
import ALERT_STATUS_STATES from "constants/alert-status-states";

import {
  defaultContainerStyle,
  defaultGridContainer,
  defaultTitleStyle,
} from "../dashboard-styles";
import Alert from "./alert";

const ActiveAlerts = props => {
  const {alerts} = props;

  const unresolvedAlerts = alerts.filter(
    alert =>
      alert.status === ALERT_STATUS_STATES.ACKNOWLEDGED ||
      alert.status === ALERT_STATUS_STATES.UNACKNOWLEDGED,
  );

  const scrollExcessAlerts = {
    maxHeight: "40%",
    minHeight: 100,
    overflowY: "auto",
  };

  return (
    <PaperContainer style={{...defaultContainerStyle, ...scrollExcessAlerts}}>
      <div
        style={{...defaultGridContainer, ...{padding: "16px 16px 8px 16px"}}}
      >
        <Typography variant="body1" style={{...defaultTitleStyle}}>
          {unresolvedAlerts ? unresolvedAlerts.length : 0} Active Alert
          {pluralize(unresolvedAlerts)}
        </Typography>
      </div>
      <List dense>
        {unresolvedAlerts.map((alert, key) => (
          <Alert key={key} alert={alert} />
        ))}
      </List>
    </PaperContainer>
  );
};

export default ActiveAlerts;
