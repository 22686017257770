export const types = {
  hide: "well-summary-dialog:hide",
  show: "well-summary-dialog:show",
};

export const hide = () => {
  return {
    type: types.hide,
    payload: {
      isOpen: false,
    },
  };
};

export const show = ({downholeLocation}) => {
  return {
    type: types.show,
    payload: {
      downholeLocation,
      isOpen: true,
    },
  };
};
