import React from "react";
import compose from "lodash/fp/compose";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import CircleIcon from "mdi-material-ui/CircleMedium";
import clsx from "clsx";

import Map from "components/map";
import {MAP_TYPES} from "constants/map-types";
import {colors} from "utils/well/status-colors";

const styles = ({spacing, colors}) => ({
  detailsContainer: {
    padding: "0 16px",
    marginTop: 32,
    display: "flex",
    justifyContent: "space-between",
    flexGrow: 1,
  },
  grey: {
    color: colors.warmGrey[400],
  },
  field: {
    fontSize: 16,
  },
  organization: {
    marginTop: 16,
    color: colors.warmGrey[500],
  },
  status: {
    display: "flex",
  },
  map: {
    width: "100%",
    height: 200,
    overflow: "hidden",
    borderRadius: spacing(1) / 2,
  },
  title: {
    color: colors.warmGrey[700],
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  tooltip: {
    zIndex: 100,
    position: "absolute",
    top: spacing(13),
    left: spacing(3),
  },
  tooltipContainer: {
    margin: [[spacing(1), spacing(2)]],
    display: "flex",
    flexDirection: "column",
    height: 420,
    width: 315,
  },
  metaContainer: {
    backgroundColor: colors.warmGrey[100],
    padding: "16px 16px 16px 8px",
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
  },
});

const WellDetailPopover = ({anchorEl, open, setOpen, classes, well}) => {
  const togglePopover = () => setOpen(open => !open);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      className={clsx(classes.tooltip)}
      open={open}
      style={open ? {} : {pointerEvents: "none"}}
      onClose={togglePopover}
      elevation={5}
      classes={{paper: classes.tooltipContainer}}
    >
      <div className={classes.map}>
        <Map
          wells={[well]}
          switcherDisabled={true}
          overrides={{
            draggable: false,
            draggableCursor: "default",
            mapType: MAP_TYPES.satellite,
          }}
        />
      </div>
      <div className={classes.detailsContainer}>
        <div>
          <Typography variant="caption">
            {well.displayName || well.downholeLocation}
          </Typography>
          <Typography variant="h6">{well.fieldName}</Typography>
          <Typography className={classes.organization} variant="body2">
            {well.organization.name}
          </Typography>
        </div>
      </div>
      <div className={classes.metaContainer}>
        <Typography className={classes.status} variant="body1">
          <CircleIcon style={{color: colors[well.status.state.name][300]}} />
          {well.status.displayName}
        </Typography>
      </div>
    </Popover>
  );
};

const enhance = compose(withStyles(styles));

export default enhance(WellDetailPopover);
