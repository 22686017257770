import math from "mathjs";
import withUnits from "utils/with-units";
import spreadIf from "utils/spread-if";
import valueOf from "utils/value-of";

// TODO: Add this to unit-converter, similar to:
// https://github.com/Pumpwell/unit-converter/commit/d2f0af30427add3056192c8e6f6b217f63bcf2a2
const types = Object.freeze({
  imperial: "imperial",
  metric: "metric",
});
const mass = {
  convert: ({from = types.metric, to = types.metric, value = 0}) => {
    const conversion = 2.2046226218488;
    from = from.toLocaleLowerCase();
    to = to.toLocaleLowerCase();

    if (from === to) return value;
    if (from === types.metric && to === types.imperial) {
      return value * conversion;
    }
    if (from === types.imperial && to === types.metric) {
      return value / conversion;
    }

    throw new Error(`Conversion from ${from} to ${to} unknown`);
  },
};

const convertMass = ({
  value,
  fromUnitsOfMeasure = "metric",
  toUnitsOfMeasure = "metric",
  units,
  decimals = 4,
}) => {
  if (valueOf(value) === null || valueOf(value) === undefined) {
    return withUnits(null, typeof units === "object" ? units.mass : units);
  }

  const converted = withUnits(
    math.round(
      mass.convert({
        ...spreadIf(fromUnitsOfMeasure, {from: fromUnitsOfMeasure}),
        ...spreadIf(toUnitsOfMeasure, {to: toUnitsOfMeasure}),
        value,
      }),
      decimals,
    ),
    typeof units === "object" ? units.mass : units,
  );

  return converted;
};

export default convertMass;
