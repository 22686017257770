import parseRodType from "@ambyint/common/parsers/well/parse-rod-type";

import {set, types} from "actions/rods";
import getUser from "utils/get-user";
import performFetch from "epics/fetch";

export const fetch = action$ => {
  return action$.ofType(types.fetch).switchMap(() => {
    const unitsOfMeasure = getUser().unitsOfMeasure;

    return performFetch("/optimization/rods").map(({response: data}) =>
      set({
        data: data.map(item => parseRodType(item, unitsOfMeasure)),
      }),
    );
  });
};
