import React from "react";
import isNil from "lodash/isNil";
import round from "lodash/round";
import {dailyProduction} from "@ambyint/common/unit-converter";

import TableCell from "components/table-cell";
import Codes from "routes/home/codes";
import {getProductionValues} from "utils/get-production-values";

import {TagCheckbox, TableRow} from "../well-list-common";
import Status from "./status";

export const renderWellRow = ({
  handleWellRowClick,
  wells,
  isSelectionMode,
  currentHoverId,
  setCurrentHoverId,
  unitsOfMeasure,
}) => (index, key) => {
  const {
    displayName,
    status,
    displacement,
    wellId,
    downholeLocation,
    dailySummaries,
  } = wells[index];

  const production = getProductionValues(dailySummaries, "totalLiquid").value;
  const productionToDisplay =
    !production || isNaN(production)
      ? "--"
      : round(
          dailyProduction.convert({
            value: production,
            from: "metric",
            to: unitsOfMeasure,
          }),
        );

  const displacementToDisplay =
    isNil(displacement) || isNil(displacement.value)
      ? "--"
      : round(displacement.value);

  const unsetHoverId = () => setCurrentHoverId(null);
  const setCurrentWell = () => setCurrentHoverId(wellId);
  const isHoverOnRow = currentHoverId === wellId;

  return (
    <TableRow
      key={key}
      wellId={wellId}
      onClick={handleWellRowClick({
        isSelectionMode,
        downholeLocation,
        wellId,
      })}
      onMouseEnter={setCurrentWell}
      onMouseLeave={unsetHoverId}
    >
      <TableCell>
        <TagCheckbox wellIds={[wellId]} isSelectionMode={isSelectionMode} />
      </TableCell>
      <TableCell underline={!isSelectionMode && isHoverOnRow}>
        {displayName}
      </TableCell>
      <TableCell>
        <Status displayName={status.displayName} type={status.type.name} />
      </TableCell>
      <TableCell>
        <Codes statuses={status.statuses} />
      </TableCell>
      <TableCell align="right">{productionToDisplay}</TableCell>
      <TableCell align="right">{displacementToDisplay}</TableCell>
      <TableCell style={{width: "5%"}} />
    </TableRow>
  );
};
