import Grid from "@material-ui/core/Grid";
import math from "mathjs";
import React from "react";

import {roundWithUnits} from "utils/round-with-units";

import Analysis from "./analysis";
import Item from "./item";

const renderGenerated = data => {
  const {
    cards,
    inferredProductionAnalysis,
    fluidLevelAnalysis,
    rodStringWeightAnalysis,
  } = data;

  if (!cards && !inferredProductionAnalysis && !fluidLevelAnalysis) {
    return null;
  }

  const inferredDailyProduction =
    inferredProductionAnalysis &&
    (inferredProductionAnalysis.inferredDailyLiquidVolumetricProductionAtStockTank ||
      inferredProductionAnalysis.inferredDailyLiquidVolumetricProductionAtPump);

  return (
    <Analysis title="Dynamometer Card Analysis">
      <Grid container>
        <Grid item xs={6}>
          <Item
            title="Inferred Production"
            value={
              inferredDailyProduction
                ? `${math.round(inferredDailyProduction.value, 2)} ${
                    inferredDailyProduction.units
                  }`
                : "--"
            }
          />
          <Item
            title="Fillage"
            value={
              inferredProductionAnalysis &&
              inferredProductionAnalysis.pumpLiquidFillage
                ? `${math.round(
                    inferredProductionAnalysis.pumpLiquidFillage.value,
                    2,
                  )}${inferredProductionAnalysis.pumpLiquidFillage.units}`
                : "--"
            }
          />
          <Item
            title="Leakage"
            value={
              inferredProductionAnalysis &&
              inferredProductionAnalysis.plungerLeakageFraction
                ? `${math.round(
                    inferredProductionAnalysis.plungerLeakageFraction.value,
                    2,
                  )}${inferredProductionAnalysis.plungerLeakageFraction.units}`
                : "--"
            }
          />
          <Item
            title="Stroke Period"
            value={
              cards && cards.strokePeriod
                ? `${math.round(cards.strokePeriod.value, 2)} ${
                    cards.strokePeriod.units
                  }${
                    cards.spm
                      ? ` (${math.round(cards.spm.value, 2)} ${
                          cards.spm.units
                        })`
                      : ""
                  }`
                : "--"
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Item
            title="FoMax"
            value={
              fluidLevelAnalysis && fluidLevelAnalysis.fluidLoadMax
                ? `${math.round(fluidLevelAnalysis.fluidLoadMax.value, 0)} ${
                    fluidLevelAnalysis.fluidLoadMax.units
                  }`
                : "--"
            }
          />
          <Item
            title="Fo"
            value={
              fluidLevelAnalysis && fluidLevelAnalysis.fluidLoad
                ? `${math.round(fluidLevelAnalysis.fluidLoad.value, 0)} ${
                    fluidLevelAnalysis.fluidLoad.units
                  }`
                : "--"
            }
          />
          <Item
            title="TV Load"
            value={
              fluidLevelAnalysis && fluidLevelAnalysis.travelingValveLoad
                ? `${math.round(
                    fluidLevelAnalysis.travelingValveLoad.value,
                    0,
                  )} ${fluidLevelAnalysis.travelingValveLoad.units}`
                : "--"
            }
          />
          <Item
            title="SV Load"
            value={
              fluidLevelAnalysis && fluidLevelAnalysis.standingValveLoad
                ? `${math.round(
                    fluidLevelAnalysis.standingValveLoad.value,
                    0,
                  )} ${fluidLevelAnalysis.standingValveLoad.units}`
                : "--"
            }
          />
          <Item
            title="Surface Fo"
            value={
              fluidLevelAnalysis &&
              fluidLevelAnalysis.fluidLoad &&
              rodStringWeightAnalysis &&
              rodStringWeightAnalysis.weightInFluidFilledTubing
                ? `${math.round(
                    fluidLevelAnalysis.fluidLoad.value +
                      rodStringWeightAnalysis.weightInFluidFilledTubing.value,
                    0,
                  )} ${fluidLevelAnalysis.fluidLoad.units}`
                : "--"
            }
          />
        </Grid>
      </Grid>
    </Analysis>
  );
};

const renderRaw = data => {
  const {cards} = data;

  const grossStrokeRounded = roundWithUnits(cards.rawInfo.grossStroke, 2);
  const netStrokeRounded = roundWithUnits(cards.rawInfo.netStroke, 2);

  return cards ? (
    <Analysis title="Dynamometer Card Analysis">
      <Grid container>
        <Grid item xs={6}>
          {cards.rawInfo && cards.rawInfo.fluidLoad && (
            <Item
              title="Fo"
              value={
                cards.rawInfo && cards.rawInfo.fluidLoad
                  ? `${math.round(cards.rawInfo.fluidLoad.value, 2)} ${
                      cards.rawInfo.fluidLoad.units
                    }`
                  : "--"
              }
            />
          )}
          <Item
            title="Stroke Period"
            value={
              cards.strokePeriod
                ? `${math.round(cards.strokePeriod.value, 2)} ${
                    cards.strokePeriod.units
                  }`
                : "--"
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Item
            title="Fillage"
            value={
              cards.rawInfo && cards.rawInfo.pumpFillage
                ? `${math.round(cards.rawInfo.pumpFillage.value, 2)} ${
                    cards.rawInfo.pumpFillage.units
                  }`
                : "--"
            }
          />
          {cards && cards.rawInfo.netStroke && (
            <Item
              title="Net Stroke"
              value={`${netStrokeRounded.value} ${netStrokeRounded.units}`}
            />
          )}
          {cards && cards.rawInfo.grossStroke && (
            <Item
              title="Gross Stroke"
              value={`${grossStrokeRounded.value} ${grossStrokeRounded.units}`}
            />
          )}
        </Grid>
      </Grid>
    </Analysis>
  ) : null;
};

export default ({data}) => {
  const {generatedCards, rawCards, showRawComparisons} = data;

  return showRawComparisons
    ? renderRaw({
        ...data,
        cards: rawCards,
      })
    : renderGenerated({
        ...data,
        cards: generatedCards,
      });
};
