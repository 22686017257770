import React from "react";
import ReactList from "react-list";
import isNil from "lodash/isNil";
import get from "lodash/get";
import {withStyles} from "@material-ui/core";

import PaperContainer from "components/paper-container";

import RowHeader from "./row-header";
import RowItem from "./row";
import sort from "./sort";

const styles = ({palette: {background}}) => ({
  selectable: {
    cursor: "pointer",
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: background.default,
    },
    "&:hover": {
      backgroundColor: "#DDDDDD",
    },
    "& td": {
      paddingTop: 22,
      paddingBottom: 22,
    },
  },
  table: {
    tableLayout: "fixed",
    "& th": {
      paddingTop: 22,
      paddingBottom: 22,
    },
  },
});

class ListView extends React.Component {
  constructor(props) {
    super(props);

    const sortBy =
      isNil(props.sortBy) && !isNil(get(props, "columns.0.sortBy"))
        ? props.columns[0].sortBy
        : null;

    this.state = {
      sortBy,
      sortDirection: sortBy ? "asc" : null,
    };
  }

  render() {
    const {
      columns,
      data,
      footer,
      header,
      onRowClick,
      sortIsHandledExternally,
      containerStyle,
      classes,
    } = this.props;
    const {sortBy, sortDirection} = this.state;

    const sortIfEnabled = () => {
      if (!sortIsHandledExternally && !isNil(sortBy)) {
        return data.sort(sort(sortBy, sortDirection));
      }

      return data;
    };

    const sortedData = sortIfEnabled(data);

    const renderItem = (index, key) => {
      const item = sortedData[index];

      if (!item) {
        return null;
      }

      return (
        <RowItem
          key={key}
          data={item}
          square={false}
          columns={columns}
          className={`${classes.row}${
            onRowClick ? ` ${classes.selectable}` : ""
          }`}
          {...(onRowClick
            ? {
                onClick: () => onRowClick(item),
              }
            : {})}
        />
      );
    };

    return (
      <PaperContainer style={{...containerStyle, minHeight: "unset"}}>
        {header}

        <RowHeader
          columns={columns}
          sortBy={sortBy}
          sortDirection={sortDirection}
          sortIsHandledExternally={sortIsHandledExternally}
          onSort={(newSortBy, newSortDirection) => {
            this.setState({sortBy: newSortBy, sortDirection: newSortDirection});
          }}
        />
        <div style={{overflow: "auto"}}>
          <ReactList
            itemRenderer={renderItem}
            length={sortedData.length}
            type="simple"
          />
        </div>
        {footer}
      </PaperContainer>
    );
  }
}

export default withStyles(styles)(ListView);
