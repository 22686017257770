import {types} from "./types";

/**
 * Toggle tag drawer open state, or, set it if `opened` is provided
 *
 * @param {bool} opened
 */
export const toggleTagDrawer = opened => ({
  type: types.toggleTagDrawer,
  payload: opened,
});
