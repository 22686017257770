import React from "react";
import compose from "lodash/fp/compose";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";

import useWellboreGraphConfig from "./use-wellbore-graph-config";
import Wellbore3d from "./wellbore-3d";
import WellboreLegend from "../wellbore-legend";

const styles = () => ({
  graph: {
    display: "grid",
    gridTemplateColumns: "500px",
    gridTemplateRows: "500px 64px",
    justifyContent: "center",
  },
});

const mapStateToProps = ({wellHealth}) => ({
  view: wellHealth.wellbore.view,
});

const enhance = compose(
  withStyles(styles),
  connect(mapStateToProps),
);

const WellboreGraph = ({
  surveySeries,
  rodSeries,
  plungerSeries,
  view,
  classes,
}) => {
  const {legend, data, layout, config} = useWellboreGraphConfig(
    view,
    surveySeries,
    rodSeries,
    plungerSeries,
  );

  return (
    <div className={classes.graph}>
      <Wellbore3d view={view} data={data} layout={layout} config={config} />
      <WellboreLegend items={legend} />
    </div>
  );
};

export default enhance(WellboreGraph);
