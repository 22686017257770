import React from "react";
import {SizeMe} from "react-sizeme";

const withAspectRatio = (
  ratio,
  defaultWidth = 0,
  sizeMeConfig = {},
) => WrappedComponent => props => (
  <SizeMe {...sizeMeConfig}>
    {({size: {width = defaultWidth}}) => (
      <WrappedComponent {...props} width={width} height={width * ratio} />
    )}
  </SizeMe>
);

export default withAspectRatio;
