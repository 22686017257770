import Grid from "@material-ui/core/Grid";
import isEqual from "lodash/isEqual";
import moment from "moment";
import React, {useState} from "react";

import Container from "components/container";

import DateRange from "./components/date-range";
import DeclineAnalysis from "./decline-analysis";
import ProductionRatios from "./production-ratios";
import WellPressure from "./well-pressure";

const WellSurveillance = ({oil, gas, water, unitsOfMeasure}) => {
  const defaultDate = {
    from: moment(oil[0].x).format("MMMM D, YYYY"),
    to: moment(oil[oil.length - 1].x).format("MMMM D, YYYY"),
  };

  const [currentDate, setCurrentDate] = useState(defaultDate);
  const [lastDrawLocation, setLastDrawLocation] = useState(null);
  const [dateDiff, setDateDiff] = useState(false);

  const resetDateRange = () => {
    setDateDiff(false);
    setLastDrawLocation(null);
    setCurrentDate(defaultDate);
  };

  const updateDragState = area => {
    if (!area) return;

    const start = moment(area.left);
    const end = moment(area.right);
    const diff = end.diff(start, "days");

    if (diff >= 7) {
      setCurrentDate({
        from: start.format("MMMM DD, YYYY"),
        to: end.format("MMMM DD, YYYY"),
      });
      setDateDiff(diff);
      setLastDrawLocation(area);
    }
  };

  return (
    <Container style={{marginTop: 50}}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DateRange
            from={currentDate.from}
            to={currentDate.to}
            isFullRange={isEqual(currentDate, defaultDate)}
            resetDateRange={resetDateRange}
          />
        </Grid>
        <Grid item xs={12}>
          <DeclineAnalysis
            dateDiff={dateDiff}
            gas={gas}
            lastDrawLocation={lastDrawLocation}
            oil={oil}
            resetDateRange={resetDateRange}
            updateDragState={updateDragState}
            water={water}
            unitsOfMeasure={unitsOfMeasure}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <ProductionRatios />
        </Grid>
        <Grid item xs={12} lg={6}>
          <WellPressure />
        </Grid>
      </Grid>
    </Container>
  );
};

export default WellSurveillance;
