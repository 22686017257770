import React from "react";
import CardViewIcon from "@material-ui/icons/ViewModule";
import Button from "@material-ui/core/Button";
import ListViewIcon from "@material-ui/icons/ViewList";
import withStyles from "@material-ui/core/styles/withStyles";

import {LAST_VIEW_TYPE} from "constants/local-storage-keys";
import {ClickEvent} from "components/analytics";
import {eventCategory, eventAction} from "constants/analytics";
import Tooltip from "components/material/tooltip";

const doSetViewType = (viewType, setViewType) => {
  localStorage.setItem(LAST_VIEW_TYPE, viewType);
  setViewType(viewType);
};

const handleToggleClick = (isList, setViewType) => () =>
  isList
    ? doSetViewType("card", setViewType)
    : doSetViewType("list", setViewType);

const styles = ({colors}) => ({
  icon: {
    color: colors.warmGrey[600],
  },
  button: {
    height: "100%",
  },
});

const ToggleButton = ({isList, setViewType, classes}) => (
  <ClickEvent
    category={eventCategory.allWellsWorkflow}
    action={eventAction.view}
    label={isList ? "card" : "list"}
  >
    <Tooltip title={isList ? "Card View" : "List View"} enterDelay={500}>
      <Button
        className={classes.button}
        variant="outlined"
        onClick={handleToggleClick(isList, setViewType)}
      >
        {isList ? (
          <CardViewIcon className={classes.icon} />
        ) : (
          <ListViewIcon className={classes.icon} />
        )}
      </Button>
    </Tooltip>
  </ClickEvent>
);

export default withStyles(styles)(ToggleButton);
