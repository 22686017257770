/*
 * This file contains constants for Google Analytics events. Before creating a new custom event, you
 * should first confirm that an approriate constant doesn't already exist as it's likely used
 * already in a custom report if so
 */

export const eventCategory = {
  wellsDashboard: "Wells Dashboard", // For anything done on the main wells dashboard page (homepage)
  allWellsWorkflow: "All Wells Workflow", // For anything done on the main "Display all" well workflow on the homepage
  anomalyWorkflow: "Anomaly Workflow", // For anything done on anomaly cards on the homepage
  calibrateWorkflow: "Calibrate Workflow", // For anything done on the calibrate workflow on the homepage
  triageWorkflow: "Triage Workflow", // For anything done on the triage workflow on the homepage
  deviation: "Deviation", // For anything done in the deviation report
  fieldOptimization: "Field Optimization", // For anything done in the field optimization page
  recommendationsWorkflow: "Recommendation Workflow", // For anything on the recommendation page
  setpointAutomationWorkflow: "Setpoint Automation Workflow", // For anything on the recommendation page
  search: "Search", // For anything done with the search bar
  searchDialog: "Search Dialog",
  appBar: "App Bar", // For general app bar events not fitting in other categories
  sidebar: "Sidebar", // For anything done in the sidebar
  wellDashboard: "Well Dashboard", // For anything done on the well dashboard page
  tags: "tags", // For anything tag-related
  userPopout: "User Popout", // For anything done on the user popout
  classificationOverride: "Well Card Classification Override", // For anything related to overriding well card classifications
};

export const eventAction = {
  accept: "Accept", // For acceptance of anything
  added: "Added", // When something is added. (i.e. a tag)
  automate: "Automate", // For the automation of anything
  cancel: "Cancel", // For the canceling of anything
  changeMapType: "Change Map Type", // Any actions relating to changing a map type
  changeWorkflow: "Change Workflow", // Any actions relating to changing workflows
  click: "Click", // Any general click event that doesn't fit into another action type
  dismiss: "Dismiss", // For dismissing anything
  filter: "Filter", // When a filter is applied. (i.e. a tag is selected)
  focus: "Focus", // Any actions related to input focus
  manageView: "Manage View", // Any actions in the manage view
  mobileSubheaderTab: "Mobile Subheader Tab", // Any actions on the mobile subheader tabs
  navigate: "Navigate", // When navigation is changed
  subheaderTab: "Subheader Tab", // Selecting a subheader tab
  submit: "Submit", // Any actions related to form submission
  sunburst: "Sunburst", // Any actions on the sunburst component
  sort: "Change Sort", // Any actions relating to changing a sort
  timePeriod: "Time Period", // Any actions relating to changing the time period
  toggle: "Toggle", // Any actions relating to toggling something (i.e. the sidebar)
  treemap: "Treemap", // Any actions on the treemap component
  view: "Change View", // Any actions relating to changing a view (i.e. card to list view)
  wellList: "Well List", // Any actions on the well list table
  wellMenu: "Well Menu", // Any actions on the well menu
  expandContainer: "Expand Container", // Any action that opens with an expandable section
  collapseContainer: "Collapse Container", // Any action that closes with an expandable section
  openDialog: "Open Dialog", // Any action that opens a dialog
  closeDialog: "Close Dialog", // Any action that closes a dialog
};
