import React from "react";
import {connect} from "react-redux";
import get from "lodash/get";

import Grid from "@material-ui/core/Grid";

import FormHeader from "components/form-header";
import PaperContainer from "components/paper-container";
import PlainText from "components/forms/plain-text";
import SaveCancelButtons from "components/save-cancel-buttons";

import calculateTotalReductionRatio from "@ambyint/common/parsers/well/parse-operation/total-reduction-ratio";

import {Field, reduxForm, formValueSelector} from "redux-form";

import {saveTotalReductionRatio} from "actions/wells";

import compose from "utils/compose";
import forms from "utils/forms";
import getUnits from "utils/get-units";
import getUser from "utils/get-user";
import spreadIf from "utils/spread-if";

import parseOperation from "@ambyint/common/parsers/well/parse-operation";

import validate from "./validate";

const selector = formValueSelector("total-reduction-ratio-edit-view");
const mapStateToProps = (state, props) => {
  const operation =
    props.well.operation ||
    parseOperation({operation: {}}, getUnits(), getUser().unitsOfMeasure);

  const downholeLocation = get(props.well, "downholeLocation");
  const gearboxRatio = get(operation, "gearboxRatio");
  const gearboxSheave = get(operation, "gearboxSheave");
  const hasJackshaft = get(operation, "hasJackshaft");
  const jackshaftGearboxSheave = get(operation, "jackshaftGearboxSheave");
  const jackshaftMotorSheave = get(operation, "jackshaftMotorSheave");
  const motorSheave = get(operation, "motorSheave");
  const totalReductionRatio = get(operation, "totalReductionRatio");

  return {
    ...spreadIf(props.well, {
      initialValues: {
        downholeLocation,
        gearboxRatio,
        gearboxSheave,
        hasJackshaft,
        jackshaftGearboxSheave,
        jackshaftMotorSheave,
        motorSheave,
        totalReductionRatio,
      },
    }),
    currentValues: selector(
      state,
      "hasJackshaft",
      "gearboxRatio",
      "gearboxSheave",
      "jackshaftGearboxSheave",
      "jackshaftMotorSheave",
      "motorSheave",
    ),
  };
};

class TotalReductionRatioEditView extends React.Component {
  save = (values, func, props) => {
    if (Object.keys(props.syncErrors).length > 0) {
      return;
    }

    this.props.dispatch(
      saveTotalReductionRatio({
        ...values,
        downholeLocation: this.props.well.downholeLocation,
        wellId: this.props.well.wellId,
      }),
    );

    this.props.onClose();
  };

  render() {
    try {
      const {handleSubmit, onClose, pristine, submitting, reset} = this.props;

      return (
        <PaperContainer extraPadded style={{maxWidth: "500px"}}>
          <form onSubmit={handleSubmit(this.save)}>
            <Grid
              container
              direction={"row"}
              style={{maxWidth: 776}}
              spacing={2}
            >
              <Grid item xs={12}>
                <FormHeader style={{marginTop: "0px", marginBottom: "0px"}}>
                  Total Reduction Ratio
                </FormHeader>
              </Grid>

              <Grid item xs={12}>
                <Field
                  component={forms.renderCheckbox}
                  name="hasJackshaft"
                  label="Has Jackshaft"
                  style={{width: "100%"}}
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <Field
                  component={forms.renderMaskedTextField}
                  name="gearboxRatio"
                  label="Gearbox Ratio"
                  maskPattern="Decimal"
                  required
                  style={{width: "100%"}}
                />
              </Grid>

              <Grid item xs={6}>
                <Field
                  component={forms.renderMaskedTextField}
                  name="gearboxSheave"
                  label="Gearbox Sheave"
                  maskPattern="Decimal"
                  required
                  style={{width: "100%"}}
                />
              </Grid>

              <Grid item xs={6}>
                <Field
                  component={forms.renderMaskedTextField}
                  name="motorSheave"
                  label="Motor Sheave"
                  maskPattern="Decimal"
                  required
                  style={{width: "100%"}}
                />
              </Grid>

              <Grid item xs={12}>
                {this.props.currentValues.hasJackshaft && (
                  <Field
                    component={forms.renderMaskedTextField}
                    name="jackshaftGearboxSheave"
                    label="Jackshaft Gearbox Sheave"
                    maskPattern="Decimal"
                    style={{width: "100%"}}
                    required
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                {this.props.currentValues.hasJackshaft && (
                  <Field
                    component={forms.renderMaskedTextField}
                    name="jackshaftMotorSheave"
                    label="Jackshaft Motor Sheave"
                    maskPattern="Decimal"
                    required
                    style={{width: "100%"}}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                <PlainText
                  name="totalReductionRatio"
                  label="Total Reduction Ratio"
                  input={{
                    value: calculateTotalReductionRatio(
                      this.props.currentValues,
                    ),
                  }}
                />
              </Grid>
            </Grid>

            <SaveCancelButtons
              pristine={pristine}
              reset={compose(
                onClose,
                reset,
              )}
              submitting={submitting}
            />
          </form>
        </PaperContainer>
      );
    } catch (ex) {
      console.error(ex);
      return null;
    }
  }
}

const Component = reduxForm({
  form: "total-reduction-ratio-edit-view",
  enableReinitialize: true,
  validate,
})(TotalReductionRatioEditView);

export default connect(mapStateToProps)(Component);
