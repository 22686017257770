import isEmpty from "lodash/isEmpty";

import addEndDataPoints from "./add-end-data-points";
import clearShutdownPeriods from "./clear-shutdown-periods";
import getShutdownPeriods from "./get-shutdown-periods";

const filter = ({data, shutdownPeriods, shutdownValue = 0, type}) => {
  const cleared = clearShutdownPeriods({data, shutdownPeriods});

  return addEndDataPoints({
    data: cleared,
    shutdownPeriods,
    shutdownValue,
    type,
  });
};

const TO_FILTER = ["current", "rrpm", "rpm", "spm", "torque"];

export default eventsByType => {
  const shutdownPeriods = getShutdownPeriods({
    runningStatusData: eventsByType.runningStatus,
  });

  return {
    ...eventsByType,
    ...TO_FILTER.reduce((filtered, type) => {
      if (!eventsByType[type] || isEmpty(eventsByType[type])) return filtered;

      filtered[type] = filter({
        data: eventsByType[type],
        shutdownPeriods,
        type,
      });

      return filtered;
    }, {}),
  };
};
