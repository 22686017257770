import ALERT_STATUS_STATES from "constants/alert-status-states";

const UNACKNOWLEDGED = 11;
const ACKNOWLEDGED = 21;
const RESOLVED = 31;

const statusValue = status => {
  if (status === ALERT_STATUS_STATES.UNACKNOWLEDGED) return UNACKNOWLEDGED;
  if (status === ALERT_STATUS_STATES.ACKNOWLEDGED) return ACKNOWLEDGED;
  if (status === ALERT_STATUS_STATES.RESOLVED) return RESOLVED;
  return 0;
};

export default ({values, reverse}) => {
  return values.sort((a, b) => {
    let aV = statusValue(a.status);
    let bV = statusValue(b.status);

    if (aV === bV) {
      aV = a.displayName.toLowerCase();
      bV = b.displayName.toLowerCase();
    }

    return reverse * ((aV > bV) - (bV > aV));
  });
};
