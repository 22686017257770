import {types} from "./types";

export const setWellId = wellId => {
  return {
    type: types.setWellId,
    payload: {
      wellId,
    },
  };
};
