import React from "react";
import {select} from "d3-selection";

import colors from "theme/colors";
import diamond from "utils/shapes/diamond";

const DEFAULT_COLOR = "#000000";
const DEFAULT_SIZE = 4;
const DEFAULT_STROKE_WIDTH = 3;

export default class extends React.Component {
  componentDidMount() {
    this.renderIcon();
  }

  renderIcon = () => {
    const {
      color = DEFAULT_COLOR,
      size = DEFAULT_SIZE,
      strokeWidth = DEFAULT_STROKE_WIDTH,
    } = this.props;

    select(this.container)
      .append("polygon")
      .style("stroke", color)
      .style("fill", colors.transparent)
      .style("stroke-width", strokeWidth)
      .attr(
        "points",
        diamond(
          (size * 2) / 2 + strokeWidth,
          (size * 3) / 2 + strokeWidth,
          size,
        ),
      );
  };

  render() {
    const {
      size = DEFAULT_SIZE,
      strokeWidth = DEFAULT_STROKE_WIDTH,
    } = this.props;

    return (
      <svg
        height={size * 3 + strokeWidth * 2}
        width={size * 2 + strokeWidth * 2}
        ref={container => (this.container = container)}
      />
    );
  }
}
