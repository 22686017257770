import React from "react";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";

const FormButton = ({
  center,
  children,
  color,
  formControlClassName,
  fullWidth,
  label,
  onClick,
  variant,
  style,
  ...custom
}) => {
  return (
    <FormControl className={formControlClassName} fullWidth={fullWidth}>
      <Button
        color={color || "primary"}
        style={{
          ...(center
            ? {}
            : {
                marginLeft: -16,
                marginRight: 16,
              }),
          justifyContent: "center",
          ...(variant === "contained" ? {color: "white"} : {}),
          ...style,
        }} // Allow buttons to be left aligned with text
        onClick={onClick}
        variant={variant}
        {...custom}
      >
        {children}
      </Button>
    </FormControl>
  );
};

export default FormButton;
