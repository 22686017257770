import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

import {getMiniCardById} from "utils/well/get-mini-card-by-id";

import WellCardDate from "./well-card-date";

const useStyles = makeStyles(({colors, spacing}) => ({
  container: {
    display: "flex",
    padding: spacing(0.3, 1, 0.6),
    flexDirection: "column",
    alignItems: "baseline",
    borderRadius: 5,
  },
  divider: {
    height: 1,
    backgroundColor: colors.warmGrey[300],
    marginTop: spacing(1),
    marginBottom: spacing(1),
    width: "25%",
    borderRadius: 5,
  },
}));

const WellCardTooltipContent = ({
  cardIds,
  well,
  currentLabel = "Current Card",
}) => {
  const classes = useStyles();

  const [currentCard, lastRegularCard] = cardIds.map(cardId =>
    getMiniCardById(well, cardId),
  );
  const showTooltip = currentCard || lastRegularCard;

  return showTooltip ? (
    <div className={classes.container}>
      {currentCard && (
        <WellCardDate
          label={currentLabel}
          date={new Date(currentCard.createdOn)}
        />
      )}
      {lastRegularCard && (
        <>
          <div className={classes.divider} />
          <WellCardDate
            label="Last Regular Card"
            date={new Date(lastRegularCard.createdOn)}
          />
        </>
      )}
    </div>
  ) : null;
};

export default WellCardTooltipContent;
