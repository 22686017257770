import groupBy from "lodash/groupBy";

export default ({state, action}) => {
  const {recommendations} = action.payload;
  const {allWellIds, byWellId} = state;

  const groupedByWell = groupBy(recommendations, "downholeLocation");

  allWellIds.forEach(id => {
    const recommendations = groupedByWell[byWellId[id].downholeLocation] || [];

    byWellId[id] = {
      ...byWellId[id],
      recommendations,
    };
  });

  return {
    ...state,
    byWellId,
  };
};
