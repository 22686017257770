import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

import Tooltip from "components/material/tooltip";

import OverrideTooltipContents from "./override-tooltip-contents";

const useStyles = makeStyles(({spacing}) => ({
  tooltipPlacementTop: {
    margin: spacing(0),
  },
}));

const OverrideTooltip = ({hasSubmitted, override, children}) => {
  const classes = useStyles();

  const title = hasSubmitted ? (
    <OverrideTooltipContents override={override} />
  ) : (
    "Reclassify Card"
  );

  return (
    <Tooltip title={title} placement="top" classes={classes}>
      <div>{children}</div>
    </Tooltip>
  );
};

export default OverrideTooltip;
