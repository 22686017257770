import first from "lodash/first";
import last from "lodash/last";
import moment from "moment";
import React from "react";
import {scaleLinear, scaleTime} from "d3-scale";

import withResize from "components/with-resize";

import Axes from "./axes";
import DataLine from "./data-line";

const defaultMargin = {
  top: 5,
  right: 5,
  bottom: 20,
  left: 25,
};

const xScale = props => {
  return scaleTime()
    .domain(
      props.domain || [
        moment(first(props.data).x).toDate(),
        moment(last(props.data).x).toDate(),
      ],
    )
    .range([
      props.margin.left,
      props.width - props.margin.left - props.margin.right,
    ]);
};

const yScale = props => {
  const max = props.max || Math.max(...props.data.map(d => d.y));

  return scaleLinear()
    .domain([0, max < 10 ? 10 : max])
    .range([props.height - props.margin.bottom, props.margin.top]);
};

const Chart = props => {
  const {data, height = 150, style = {}} = props;

  if (!data || data.length === 0) return null;

  const margin = {...defaultMargin, ...(props.margin || {})};

  const scales = {
    xScale: xScale({...props, height, margin, data}),
    yScale: yScale({...props, height, margin, data}),
  };

  return (
    <svg width={"100%"} height={height}>
      <Axes {...props} {...scales} height={height} margin={margin} />
      <DataLine data={data} style={style} {...scales} />
    </svg>
  );
};

export default withResize(Chart);
