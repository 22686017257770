export const types = {
  get: "oncall:get",
  fetchFullSchedule: "oncall:fetch-full-schedule",
  schedule: "oncall::schedule",
  set: "oncall:set",
  unschedule: "oncall:unschedule",
};

export const get = () => {
  return {
    type: types.get,
    payload: {},
  };
};

export const fetchFullSchedule = ({clientUserId}) => {
  return {
    type: types.fetchFullSchedule,
    payload: {
      clientUserId,
    },
  };
};

export const schedule = ({
  allDay,
  clientUserId,
  startDate,
  startTime,
  endDate,
  endTime,
  daysOfWeek,
  wells,
}) => {
  return {
    type: types.schedule,
    payload: {
      allDay,
      clientUserId,
      startDate,
      startTime,
      endDate,
      endTime,
      daysOfWeek,
      wells,
    },
  };
};

export const set = ({schedule}) => {
  return {
    type: types.set,
    payload: {
      schedule,
    },
  };
};

export const unschedule = ({clientUserId}) => {
  return {
    type: types.unschedule,
    payload: {
      clientUserId,
    },
  };
};
