import {createMergeEpic} from "epics/create-epic";
import {setEvents, types} from "actions/wells";

import fetch from "../async-fetch";

export const fetchLatestMiniCard = createMergeEpic(
  [types.fetchLatestMiniCard],
  async ({payload}) => {
    try {
      const card = await fetch(
        `/wells/${encodeURIComponent(payload.downholeLocation)}/cards/latest`,
      );

      return [
        setEvents({
          downholeLocation: payload.downholeLocation,
          data: [{...card, type: "miniCards"}],
        }),
      ];
    } catch (err) {
      return [];
    }
  },
);
