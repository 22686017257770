import {PINNED_ORGANIZATION} from "constants/local-storage-keys";

export const setPinnedOrganization = (userId, pinnedOrgId) => {
  const pinnedOrgAllUsers = JSON.parse(
    localStorage.getItem(PINNED_ORGANIZATION) || "{}",
  );

  pinnedOrgAllUsers[userId] = pinnedOrgId;

  localStorage.setItem(PINNED_ORGANIZATION, JSON.stringify(pinnedOrgAllUsers));
};
