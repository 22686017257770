import {area, line, off, on} from "constants/analysis-states";
import wellEventsUserActionTypes from "constants/well-events-user-action-types";

const types = {
  alerts: ["alertCreated", "alertAcknowledged", "alertResolved"],
  recommendations: [
    "newRecommendation",
    "recommendationAccepted",
    "recommendationDismissed",
  ],
  shutdownCards: ["miniCards"],
  userActions: Object.values(wellEventsUserActionTypes),
};

const maxStates = {
  alerts: on,
  casingPressure: area,
  current: area,
  deflection: line,
  dutyCycle: line,
  driveFault: on,
  driveMode: on,
  effectiveSpm: line,
  fillage: line,
  inferredFillage: line,
  ambyintFillage: line,
  leakPressure: line,
  load: area,
  oilProduction: line,
  inferredOilProduction: line,
  recommendations: on,
  rpm: area,
  rrpm: line,
  runningStatus: on,
  shutdownCards: on,
  spm: line,
  statusCodes: on,
  torque: area,
  tubingPressure: area,
  userActions: on,
};

const factory = state => (name, setting) => {
  return {
    [name]: {
      ...state[name],
      maxState: maxStates[name],
      state: setting,
      ...(types[name] ? {types: types[name]} : {}),
    },
  };
};

export const names = {
  all: "all",
  none: "none",
  operations: "operations",
  production: "production",
  status: "status",
};

const presets = {
  [names.all]: (state = {}) => {
    const create = factory(state);

    return {
      ...create("alerts", on),
      ...create("casingPressure", line),
      ...create("current", area),
      ...create("deflection", line),
      ...create("driveFault", on),
      ...create("driveMode", on),
      ...create("dutyCycle", line),
      ...create("effectiveSpm", off),
      ...create("fillage", off),
      ...create("inferredFillage", line),
      ...create("ambyintFillage", line),
      ...create("leakPressure", line),
      ...create("load", area),
      ...create("oilProduction", off),
      ...create("inferredOilProduction", line),
      ...create("recommendations", on),
      ...create("rpm", line),
      ...create("runningStatus", on),
      ...create("shutdownCards", on),
      ...create("spm", line),
      ...create("statusCodes", on),
      ...create("torque", area),
      ...create("tubingPressure", line),
      ...create("userActions", on),
    };
  },
  [names.none]: (state = {}) => {
    const create = factory(state);

    return {
      ...create("alerts", off),
      ...create("casingPressure", off),
      ...create("current", off),
      ...create("deflection", off),
      ...create("driveFault", off),
      ...create("driveMode", off),
      ...create("dutyCycle", off),
      ...create("effectiveSpm", off),
      ...create("fillage", off),
      ...create("inferredFillage", off),
      ...create("ambyintFillage", off),
      ...create("leakPressure", off),
      ...create("load", off),
      ...create("oilProduction", off),
      ...create("inferredOilProduction", off),
      ...create("recommendations", off),
      ...create("rpm", off),
      ...create("runningStatus", off),
      ...create("shutdownCards", off),
      ...create("spm", off),
      ...create("statusCodes", off),
      ...create("torque", off),
      ...create("tubingPressure", off),
      ...create("userActions", off),
    };
  },
  [names.operations]: (state = {}) => {
    const create = factory(state);

    return {
      ...create("alerts", off),
      ...create("casingPressure", line),
      ...create("current", off),
      ...create("deflection", line),
      ...create("driveFault", off),
      ...create("driveMode", off),
      ...create("dutyCycle", off),
      ...create("effectiveSpm", off),
      ...create("fillage", off),
      ...create("inferredFillage", on),
      ...create("ambyintFillage", on),
      ...create("leakPressure", line),
      ...create("load", area),
      ...create("oilProduction", off),
      ...create("inferredOilProduction", off),
      ...create("recommendations", off),
      ...create("rpm", line),
      ...create("runningStatus", off),
      ...create("shutdownCards", on),
      ...create("spm", line),
      ...create("statusCodes", off),
      ...create("torque", off),
      ...create("tubingPressure", line),
      ...create("userActions", off),
    };
  },
  [names.production]: (state = {}) => {
    const create = factory(state);

    return {
      ...create("alerts", off),
      ...create("casingPressure", off),
      ...create("current", off),
      ...create("deflection", off),
      ...create("driveFault", off),
      ...create("driveMode", off),
      ...create("dutyCycle", off),
      ...create("effectiveSpm", off),
      ...create("fillage", off),
      ...create("inferredFillage", line),
      ...create("ambyintFillage", line),
      ...create("leakPressure", off),
      ...create("load", off),
      ...create("oilProduction", off),
      ...create("inferredOilProduction", line),
      ...create("recommendations", off),
      ...create("rpm", off),
      ...create("runningStatus", off),
      ...create("shutdownCards", on),
      ...create("spm", off),
      ...create("statusCodes", off),
      ...create("torque", off),
      ...create("tubingPressure", off),
      ...create("userActions", off),
    };
  },
  [names.status]: (state = {}) => {
    const create = factory(state);

    return {
      ...create("alerts", on),
      ...create("casingPressure", off),
      ...create("current", off),
      ...create("deflection", off),
      ...create("driveFault", on),
      ...create("driveMode", on),
      ...create("dutyCycle", off),
      ...create("effectiveSpm", off),
      ...create("fillage", off),
      ...create("inferredFillage", off),
      ...create("ambyintFillage", off),
      ...create("leakPressure", off),
      ...create("load", off),
      ...create("oilProduction", off),
      ...create("inferredOilProduction", off),
      ...create("recommendations", on),
      ...create("rpm", off),
      ...create("runningStatus", on),
      ...create("shutdownCards", on),
      ...create("spm", off),
      ...create("statusCodes", on),
      ...create("torque", off),
      ...create("tubingPressure", off),
      ...create("userActions", on),
    };
  },
};

presets.default = presets[names.operations];

export {presets};
export default presets;
