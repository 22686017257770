import Grid from "@material-ui/core/Grid";
import React from "react";

import Aux from "components/aux";
import FormButton from "components/form-button";
import getPrivileges from "utils/get-privileges";
import privilegeTypes from "utils/privilege-types";

import recommendationTypes from "./recommendation-types";
import {cardLabelFactory, cardValueFactory} from "../cards";

const CardLabel = cardLabelFactory(7);
const CardValue = cardValueFactory(5);

const renderType = props => type => {
  const {well} = props;

  return (
    <Aux key={type}>
      <CardLabel label={recommendationTypes(well)[type]} />
      <CardValue
        value={
          well.autoAcceptRecommendationTypes.includes(type)
            ? "Enabled"
            : "Disabled"
        }
      />
    </Aux>
  );
};

const renderTypes = props => {
  const {well} = props;

  return Object.keys(recommendationTypes(well)).map(renderType(props));
};

export default props => {
  const {well} = props;

  const privileges = getPrivileges(well.organization.id);

  return (
    <Aux>
      <Grid container spacing={2}>
        {renderTypes(props)}
        <Grid item style={{marginTop: "0px", marginBottom: "0px"}} xs={12}>
          {privileges.includes(privilegeTypes.well.configure) && (
            <FormButton onClick={props.toggleEdit}>Edit Information</FormButton>
          )}
        </Grid>
      </Grid>
    </Aux>
  );
};
