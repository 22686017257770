import React from "react";
import find from "lodash/find";
import {connect} from "react-redux";
import {reduxForm, formValueSelector} from "redux-form";
import {withRouter} from "react-router";

import Breadcrumbs from "components/breadcrumbs";

import {
  fetchOrganizationUser,
  fetchOrganizationWells,
  reinviteUser,
  removeUserFromOrganization,
  saveAccess,
  saveAssignedWells,
  selectOrganization,
} from "actions/organizations";

import getDisplayName from "utils/user/get-display-name";

import Footer from "./footer";
import Header from "./header";
import createListView from "./list-view";

import AccessControls from "./access-controls";
import PageHeader from "./page-header";
import ReinviteUser from "./reinvite-user";
import RemoveUserFromOrganization from "./remove-user-from-organization";
import mapWellsToAssignment from "./map-wells-to-assignment";
import selectOrganizationUserDetails from "./select-organization-user-details";

const ListView = createListView("manage-user");

const selector = formValueSelector("manage-user");
const mapStateToProps = state => {
  const {organizations, organizationManageUser, organizationsWells} = state;

  const {organizationName, organizationWells} = selectOrganizationUserDetails(
    organizations,
    organizationsWells,
  );

  return {
    organizationName,
    organizationManageUser,
    organizationWells,
    organizations: state.organizations,
    wells: state.wells,
    currentValues: {
      wells: selector(state, "wells"),
    },
    initialValues: {
      wells: mapWellsToAssignment(
        organizationWells,
        organizationManageUser.assignedWells,
      ),
    },
  };
};

class ManageUser extends React.Component {
  state = {
    searchPhrase: "",
  };

  componentWillMount() {
    const {
      dispatch,
      match: {
        params: {clientUserId, organizationId},
      },
    } = this.props;

    dispatch(selectOrganization({organizationId}));
    dispatch(fetchOrganizationUser({organizationId, clientUserId}));
    dispatch(fetchOrganizationWells({organizationId, clientUserId}));
  }

  removeUserFromOrganization = () => {
    const {
      dispatch,
      match: {
        params: {organizationId, clientUserId},
      },
    } = this.props;

    dispatch(
      removeUserFromOrganization({
        clientUserId,
        organizationId,
      }),
    );
  };

  render() {
    const {
      currentValues,
      dispatch,
      handleSubmit,
      match: {
        params: {organizationId, clientUserId},
      },
      organizationName,
      organizationManageUser,
      organizationWells,
      organizations,
      reset,
    } = this.props;

    const userFullName = getDisplayName(organizationManageUser);

    return (
      <form
        onSubmit={handleSubmit(values => {
          const mapWellIdToDownholeLocation = wellId => {
            const well = find(organizationWells, {wellId});
            return well.downholeLocation;
          };

          const wellsWhereAssignedIs = isAssigned =>
            Object.keys(values.wells)
              .filter(wellId => values.wells[wellId] === isAssigned)
              .map(mapWellIdToDownholeLocation);

          dispatch(
            saveAssignedWells({
              assignedWells: wellsWhereAssignedIs(true),
              clientUserId,
              organizationId,
              unassignedWells: wellsWhereAssignedIs(false),
            }),
          );
        })}
        style={{paddingBottom: "12px"}}
      >
        <div style={{marginBottom: 16}}>
          <Breadcrumbs
            crumbs={[
              {
                label: organizationName,
                pathname: "/profile/organizations",
              },
              {
                label: "Manage Users",
                pathname: `/profile/organization/${
                  organizations.selectedOrganizationId
                }/users`,
              },
              {
                label: userFullName,
              },
            ]}
          />
        </div>

        <PageHeader
          title={userFullName}
          subtitle={
            userFullName !== organizationManageUser.userLogin
              ? organizationManageUser.userLogin
              : ""
          }
        />

        {!organizationManageUser || organizationManageUser.isVerified ? null : (
          <ReinviteUser
            onClick={event => {
              event.preventDefault();

              dispatch(
                reinviteUser({
                  selectedOrganizationId: organizations.selectedOrganizationId,
                  clientUserId: organizationManageUser.clientUserId,
                }),
              );
            }}
          />
        )}

        <ListView
          header={filteredWells => (
            <Header
              countRows={filteredWells.length}
              onChange={event => {
                this.setState({searchPhrase: event.target.value});
              }}
              placeholder="Search for a well"
            />
          )}
          containerStyle={{
            maxHeight: 580,
            marginTop: 8,
          }}
          organizationWells={organizationWells || {}}
          assignedWells={currentValues.wells || {}}
          reset={reset}
          searchPhrase={this.state.searchPhrase}
          footer={wellsSelected => (
            <Footer
              disabled={this.props.pristine}
              reset={reset}
              wellsSelected={wellsSelected}
            />
          )}
        />

        <AccessControls
          accessLevel={organizationManageUser.accessLevel}
          clientUserId={clientUserId}
          dispatch={dispatch}
          isInvitedUserApprover={organizationManageUser.isInvitedUserApprover}
          organizationId={organizationId}
          onAccessChanged={({
            clientUserId,
            organizationId,
            accessLevel,
            isInvitedUserApprover,
          }) => {
            dispatch(
              saveAccess({
                clientUserId,
                organizationId,
                accessLevel,
                isInvitedUserApprover,
              }),
            );
          }}
        />

        <RemoveUserFromOrganization
          organizationName={organizationName}
          userFullName={userFullName}
          onConfirm={this.removeUserFromOrganization}
        />
      </form>
    );
  }
}

let Component = reduxForm({
  form: "manage-user",
  enableReinitialize: true,
})(ManageUser);

Component = connect(mapStateToProps)(Component);
Component = withRouter(Component);

export default Component;
