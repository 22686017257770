import {connect} from "react-redux";
import React from "react";
import Typography from "@material-ui/core/Typography/Typography";
import isNil from "lodash/isNil";

import ListStyleMenu from "components/list-style-menu";
import {save, addNotificationType, removeNotificationType} from "actions/auth";

import {getNotificationOptions} from "./get-notification-options";
import {notificationTypes} from "./notification-types";

const mapStateToProps = state => {
  return {
    user: state.auth.user,
  };
};

class ProfileNotifications extends React.Component {
  render() {
    const {user} = this.props;
    const {mobilePhone} = user;
    const notifications = isNil(user.notifications) ? [] : user.notifications;

    return (
      <div style={{maxWidth: 760}}>
        <Typography variant="subtitle1" style={{marginTop: "8px"}}>
          Alert Notifications
        </Typography>
        <ListStyleMenu
          primary={"Alert Received"}
          secondary={
            "When you are on call and a well experiences an alert, how should you be notified"
          }
          options={getNotificationOptions(mobilePhone)}
          value={user.notificationMethods}
          onChange={value => {
            const {dispatch, user} = this.props;

            dispatch(
              save({
                clientUserId: user.clientUserId,
                data: {
                  ...user,
                  notificationMethods: value,
                },
              }),
            );
          }}
          containerStyle={{margin: "16px 0 0"}}
        />

        <Typography variant="subtitle1" style={{marginTop: "16px"}}>
          Recommendation Notifications
        </Typography>
        {notificationTypes.map((n, i) => {
          return (
            <ListStyleMenu
              key={i}
              primary={n.primary}
              secondary={n.secondary}
              options={n.options}
              value={notifications.includes(n.type)}
              onChange={value => {
                const {dispatch} = this.props;
                const payload = {
                  user,
                  type: n.type,
                };

                if (value) {
                  dispatch(addNotificationType(payload));
                } else {
                  dispatch(removeNotificationType(payload));
                }
              }}
              containerStyle={{margin: "16px 0 0"}}
            />
          );
        })}
      </div>
    );
  }
}

export default connect(mapStateToProps)(ProfileNotifications);
