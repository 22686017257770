import React from "react";

import {cardLabelFactory, cardValueFactory} from "../../cards";
import getCrankWeightLabel from "./get-crank-weight-label";
import getCrankLabel from "./get-crank-label";

const CardLabel = cardLabelFactory(4);
const CardValue = cardValueFactory(8);

const getCrankWeightInformation = (pumpingUnit = {crankWeights: []}) =>
  pumpingUnit.crankWeights && pumpingUnit.crankWeights.length > 0
    ? pumpingUnit.crankWeights
    : [];

const GeometryDetailsReadView = ({pumpingUnit}) => {
  const crankWeights = getCrankWeightInformation(pumpingUnit);

  return (
    <>
      <CardLabel label={"Crank"} />
      <CardValue value={getCrankLabel(pumpingUnit.crank)} />

      {crankWeights.map((weight, i) => {
        return [
          <CardLabel
            key={`${i}-weight-label`}
            label={i === 0 ? "Crank Weights" : ""}
          />,
          <CardValue
            key={`${i}-weight-value`}
            value={getCrankWeightLabel(weight)}
          />,
        ];
      })}

      <CardLabel label={"Crank Pin Position"} />
      <CardValue value={pumpingUnit.crankPinPosition} />

      <CardLabel label={"Rotation Direction"} />
      <CardValue value={pumpingUnit.rotationDirection} />
    </>
  );
};

export default GeometryDetailsReadView;
