import React from "react";
import colors from "theme/colors";
import AmbyintLogo from "components/logo/full";
import Button from "@material-ui/core/Button";
import ErrorIcon from "@material-ui/icons/Error";
import Typography from "@material-ui/core/Typography";

const styles = {
  container: {
    margin: "auto",
  },
  errorContainer: {
    borderRadius: "0.5em",
    padding: 24,
    background: colors.grey[200],
  },
  innerContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    width: "91%",
    height: "100%",
    paddingTop: 32,
    margin: "auto",
  },
  bar: {
    backgroundColor: colors.ambyBlue[500],
    padding: "8px 16px",
    zIndex: "1000",
    width: "100%",
    height: 40,
    display: "flex",
    alignItems: "center",
  },
  logo: {
    verticalAlign: "middle",
  },
  chromelessButton: {
    display: "flex",
    border: "none",
    background: "none",
    cursor: "pointer",
    alignItems: "center",
    height: 36,
    paddingLeft: 0,
  },
  text: {
    color: "rgba(0, 0, 0, 0.54)",
  },
  link: {
    color: colors.ambyOrange[500],
  },
  helper: {
    color: "rgba(0, 0, 0, 0.54)",
    marginTop: 16,
    marginBottom: 16,
  },
};

const getTableFlip = () => {
  const tableFlips = [
    "(┛◉Д◉)┛彡┻━┻",
    "(ﾉಥ益ಥ）ﾉ ︵﻿ ┻━┻",
    "༼ノಠل͟ಠ༽ノ ︵ ┻━┻",
    "(ノಠ益ಠ)ノ彡┻━┻",
    "ʕノ•ᴥ•ʔノ ︵ ┻━┻",
    "(╯°□°)╯︵ ┻━┻",
    "(╯ಠ益ಠ)╯彡┻━┻",
    "(╯ ͡° ͜ʖ ͡°)╯ 彡 ┻━┻",
    "( ︶︿︶)ノ ︵ ┻━┻",
    "(っ◕‿◕)っ ︵ ┻━┻",
    "(ﾉ^ヮ^)ﾉ 彡 ┻━┻",
  ];

  return tableFlips[Math.floor(Math.random() * tableFlips.length)];
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      error: null,
      info: null,
      isErrorExpanded: false,
    };
  }

  componentDidCatch(error, info) {
    this.setState({hasError: true, error, info});
  }

  renderStack() {
    try {
      const renderLine = text => {
        return <Typography variant="body1">{text}</Typography>;
      };

      return (
        this.state.info &&
        this.state.info.componentStack &&
        this.state.info.componentStack.split("\n").map(renderLine)
      );
    } catch (ex) {
      return null;
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            ...styles.container,
            ...this.props.style,
          }}
        >
          {this.props.showAppBar && (
            <div style={styles.bar}>
              <a href={"/"}>
                <AmbyintLogo width={100} style={styles.logo} />
              </a>
            </div>
          )}
          <div
            style={{
              ...styles.innerContainer,
              ...(this.props.showAppBar ? {top: 60} : {top: 0}),
              ...this.props.style,
            }}
          >
            <Typography variant="h4" gutterBottom>
              {getTableFlip()}
            </Typography>
            <Typography variant="h3">Something went wrong.</Typography>
            <Typography variant="subtitle1" gutterBottom style={styles.text}>
              This is probably Brads fault. We're just as disappointed as you.
            </Typography>

            <Typography variant="subtitle1" style={styles.helper}>
              You can{" "}
              <a href="/" style={styles.lint}>
                head home
              </a>{" "}
              or get in touch with our customer success team{" "}
              <a
                style={styles.link}
                href="mailto:support@ambyint.com?Subject=Encountered an Error"
                target="_top"
              >
                support@ambyint.com
              </a>
              .
            </Typography>

            <div style={styles.errorContainer}>
              <Button
                style={{marginLeft: -16}}
                onClick={() =>
                  this.setState({isErrorExpanded: !this.state.isErrorExpanded})
                }
              >
                <ErrorIcon />
                <div style={{marginLeft: 8}}>
                  {this.state.isErrorExpanded
                    ? "Hide Additional Information"
                    : "Display Additional Information"}
                </div>
              </Button>

              {this.state.isErrorExpanded && (
                <div>
                  <h2>{this.state.error && this.state.error.message}</h2>
                  <h3>{this.renderStack()}</h3>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
