import React from "react";
import {connect} from "react-redux";
import get from "lodash/get";

import getPrivileges from "utils/get-privileges";
import privilegeTypes from "utils/privilege-types";
import PUMPOFF_SETTING_MODES from "constants/pumpoff-setting-modes";

import DeadbandEditView from "./settings/deadband-edit-view";
import DeadbandReadView from "./settings/deadband-read-view";
import FillBaseEditView from "./settings/fill-base-edit-view";
import FillBaseReadView from "./settings/fill-base-read-view";
import MalfunctionEditView from "./settings/malfunction-edit-view";
import MalfunctionReadView from "./settings/malfunction-read-view";
import RodStringEditView from "./settings/rod-string-edit-view";
import RodStringReadView from "./settings/rod-string-read-view";
import StructuralLoadEditView from "./settings/structural-load-edit-view";
import StructuralLoadReadView from "./settings/structural-load-read-view";

const mapStateToProps = state => ({
  user: state.auth.user,
});

const styleSheet = {
  container: {
    maxWidth: "500px",
    marginBottom: "8px",
  },
};

const isModeVFD = well => {
  const pumpoffSettingMode = get(well, "pumpOffSetting.mode.value");
  const {VFD_SURFACE, VFD_DOWNHOLE} = PUMPOFF_SETTING_MODES;

  return [VFD_SURFACE, VFD_DOWNHOLE].includes(pumpoffSettingMode);
};

class LufkinSettingsView extends React.Component {
  state = {
    showDeadbandEditView: false,
    showFillBaseEditView: false,
    showMalfunctionEditView: false,
    showRodStringEditView: false,
    showStructuralLoadEditView: false,
  };

  render() {
    const {well} = this.props;
    const privileges = getPrivileges(well.organization.id);
    const canEdit = privileges.includes(privilegeTypes.well.configure);

    if (this.state.showDeadbandEditView) {
      return (
        <DeadbandEditView
          onClose={() => this.setState({showDeadbandEditView: false})}
          well={well}
        />
      );
    }

    if (this.state.showFillBaseEditView) {
      return (
        <FillBaseEditView
          onClose={() => this.setState({showFillBaseEditView: false})}
          well={well}
        />
      );
    }

    if (this.state.showRodStringEditView) {
      return (
        <RodStringEditView
          canEdit={canEdit}
          onClose={() => this.setState({showRodStringEditView: false})}
          well={well}
        />
      );
    }

    if (this.state.showMalfunctionEditView) {
      return (
        <MalfunctionEditView
          onClose={() => this.setState({showMalfunctionEditView: false})}
          well={well}
        />
      );
    }

    if (this.state.showStructuralLoadEditView) {
      return (
        <StructuralLoadEditView
          onClose={() => this.setState({showStructuralLoadEditView: false})}
          well={well}
        />
      );
    }

    return [
      isModeVFD(well) && (
        <DeadbandReadView
          key={"deadband-read"}
          canEdit={canEdit}
          onEdit={() => {
            this.setState({showDeadbandEditView: true});
          }}
          settings={get(well, "lufkinSettings.deadband", {})}
          styles={styleSheet}
        />
      ),
      <FillBaseReadView
        key={"fill-base-read"}
        canEdit={canEdit}
        onEdit={() => {
          this.setState({showFillBaseEditView: true});
        }}
        settings={get(well, "lufkinSettings.fillBase", {})}
        styles={styleSheet}
      />,
      <MalfunctionReadView
        key={"malfunction-read"}
        settings={get(well, "lufkinSettings.malfunction", {})}
        canEdit={canEdit}
        onEdit={() => {
          this.setState({showMalfunctionEditView: true});
        }}
        styles={styleSheet}
      />,
      <RodStringReadView
        key={"rod-string-read"}
        settings={get(well, "lufkinSettings.rodString", [])}
        canEdit={canEdit}
        onEdit={() => {
          this.setState({showRodStringEditView: true});
        }}
        styles={styleSheet}
      />,
      <StructuralLoadReadView
        key={"structural-load-read"}
        settings={get(well, "lufkinSettings.structuralLoad", {})}
        canEdit={canEdit}
        onEdit={() => {
          this.setState({showStructuralLoadEditView: true});
        }}
        styles={styleSheet}
      />,
    ];
  }
}

export default connect(mapStateToProps)(LufkinSettingsView);
