import parseEvents from "@ambyint/common/parsers/well/events";

import {setEvents, types as actionTypes} from "actions/wells";
import fetch from "epics/async-fetch";
import getUser from "utils/get-user";

export default action$ => {
  return action$
    .ofType(actionTypes.fetchLatestCards)
    .mergeMap(async ({payload}) => {
      const {unitsOfMeasure} = getUser();

      let parsed = [];

      try {
        const cards = await fetch(
          `/wells/${encodeURIComponent(payload.downholeLocation)}/cards/latest`,
          payload,
        );

        parsed = parseEvents(unitsOfMeasure)([cards]);
      } catch (e) {
        console.error(e);
      }

      return setEvents({
        downholeLocation: payload.downholeLocation,
        data: parsed,
      });
    });
};
