import React from "react";
import {connect} from "react-redux";
import {push} from "react-router-redux";

import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Typography from "@material-ui/core/Typography";

import colors from "theme/colors";

let Breadcrumbs = props => {
  const {crumbs, dispatch, fontStyle} = props;

  if (!crumbs || crumbs.length === 0 || !crumbs[0].label) {
    return (
      <div
        style={{
          display: "block",
          minHeight: 24,
        }}
      />
    );
  }

  return (
    <div
      style={{
        display: "flex",
        textTransform: "uppercase",
        minHeight: 24,
        alignItems: "center",
      }}
    >
      {crumbs.map((crumb, index) => {
        const elements = [
          <Typography
            key={1 * (index + 1)}
            variant="body1"
            role="button"
            tabIndex={0}
            style={{
              ...(fontStyle || {}),
              ...(crumb.pathname
                ? {
                    cursor: "pointer",
                    userSelect: "none",
                  }
                : {}),
              fontWeight: "bold",
              fontSize: "1em",
              outline: "none",
              color: colors.grey[700],
            }}
            onClick={() => crumb.pathname && dispatch(push(crumb.pathname))}
          >
            {crumb.label}
          </Typography>,
        ];

        if (index !== crumbs.length - 1) {
          elements.push(
            <KeyboardArrowRight
              key={2 * (index + 1)}
              style={{color: colors.grey[500]}}
            />,
          );
        }

        return elements;
      })}
    </div>
  );
};

Breadcrumbs = connect()(Breadcrumbs);

export default Breadcrumbs;
