import {types} from "actions/field-optimization";

import {CLASSIFICATION, CLASSIFICATION_SORT} from "constants/classification";
import {reducerFactory} from "reducers/reducer-factory";

import {classificationSuccess} from "./classification-success";
import {setActive} from "./set-active";
import {setClassification} from "./set-classification";
import {setClassificationSort} from "./set-classification-sort";
import {setField} from "./set-field";

const defaults = {
  active: null,
  classification: CLASSIFICATION.DIALED_IN,
  classifications: [],
  field: "",
  sort: CLASSIFICATION_SORT.TOTAL_LIQUID,
};

const actionMap = {
  [types.getClassificationSuccess]: classificationSuccess,
  [types.setActive]: setActive,
  [types.setClassification]: setClassification,
  [types.setClassificationSort]: setClassificationSort,
  [types.setField]: setField,
};

export default reducerFactory(actionMap, defaults);
