import {connect} from "react-redux";

const mapStateToProps = state => {
  const {organizations} = state;

  const organization =
    organizations.all[organizations.selectedOrganizationId] || {};

  return {
    organizationName: organization.organizationName || "",
  };
};

export default connect(mapStateToProps);
