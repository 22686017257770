import React, {useMemo} from "react";
import compose from "lodash/fp/compose";
import {connect} from "react-redux";
import {XYPlot, LineSeries, LineSeriesCanvas, XAxis} from "react-vis";

import {setFocus} from "actions/wells/well-health";
import {warmGrey} from "theme/updated-colors";

import DateHoverAxis from "../date-hover-axis";
import SetPointAxis from "../set-point-axis";

const mapStateToProps = ({wellHealth}) => ({
  focus: wellHealth.compressiveStress.focus,
  days: wellHealth.date.days,
});

const mapDispatchToProps = {
  setFocus,
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const CompressionGraphOverlay = ({
  width,
  height,
  medianSeries,
  domain,
  margin,
  showSetpointAxis,
  focus,
  setFocus,
  days,
}) => {
  // Generate a series to track each day.
  // This series won't change so its memoized to prevent being recalculated on every hover event.
  const daysSeries = useMemo(
    () =>
      days.map(day => ({
        x: day,
        y: 0,
      })),
    [days],
  );

  return (
    <XYPlot
      key={width}
      height={height}
      width={width}
      margin={margin}
      yDomain={domain}
      xType="ordinal"
      xDomain={days}
      onMouseLeave={() => {
        setFocus("compressiveStress", null);
      }}
    >
      <DateHoverAxis unit="days" focus={focus} />

      {showSetpointAxis ? (
        <SetPointAxis unit={"days"} />
      ) : (
        <XAxis tickValues={[]} />
      )}

      {/*Invisible series line to track the nearest day to the mouse*/}
      <LineSeries
        data={daysSeries}
        color="rgba(0,0,0,0)"
        onNearestX={(value, event) => {
          setFocus("compressiveStress", event.index);
        }}
      />

      {/*Vertical line series from top to bottom, positioned at the focused day*/}
      <LineSeriesCanvas
        data={[
          {
            x: days[focus],
            y: domain[0],
          },
          {
            x: days[focus],
            y: domain[1],
          },
        ]}
        color={warmGrey[200]}
        strokeDasharray={[6, 2]}
        strokeWidth={1}
      />
    </XYPlot>
  );
};

export default enhance(CompressionGraphOverlay);
