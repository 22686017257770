export const setSetting = ({state, action}) => {
  const {type, name, value} = action.payload;

  return {
    ...state,
    [type]: {
      ...state[type],
      [name]: value,
    },
  };
};
