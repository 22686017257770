import {connect} from "react-redux";
import React from "react";
import get from "lodash/get";
import Grid from "@material-ui/core/Grid";

import formatWithUnits from "@ambyint/common/utils/format/format-with-units";
import pluralize from "@ambyint/common/utils/format/format-pluralize";

import {hasControlIotBridge} from "@ambyint/common/utils/devices/control-iot-bridge";
import {hasControlForEpm} from "@ambyint/common/utils/devices/control-for-epm";
import FormButton from "components/form-button";
import FormHeader from "components/form-header";
import getPrivileges from "utils/get-privileges";
import PaperContainer from "components/paper-container";
import privilegeTypes from "utils/privilege-types";

import SafeOperatingParametersEditView from "./edit-view";
import {cardLabelFactory, cardValueFactory} from "../cards";

// *** This can be removed once EPM Safe operating flag is removed ***
const mapStateToProps = ({auth}) => ({
  user: auth.user,
});

class SafeOperatingParametersReadView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {showSafeOperatingParametersEditView: false};
  }

  render() {
    const {well} = this.props;

    const privileges = getPrivileges(well.organization.id);
    const hasIotBridge = hasControlIotBridge(well.devices);
    const hasEPMControl = hasControlForEpm(well.devices);

    const maxSpeed = get(well, "safeOperatingParameters.maxSpeed");
    const minSpeed = get(well, "safeOperatingParameters.minSpeed");
    const maxDowntime = get(well, "safeOperatingParameters.maxDowntime");
    const minDowntime = get(well, "safeOperatingParameters.minDowntime");
    const minStoptime = get(well, "safeOperatingParameters.minStoptime", false);
    const maxStoptime = get(well, "safeOperatingParameters.maxStoptime", false);
    const CardLabel = cardLabelFactory(6);
    const CardValue = cardValueFactory(6);

    if (this.state.showSafeOperatingParametersEditView) {
      return (
        <SafeOperatingParametersEditView
          onClose={() =>
            this.setState({showSafeOperatingParametersEditView: false})
          }
          well={well}
        />
      );
    }

    return (
      <PaperContainer extraPadded style={{maxWidth: "500px"}}>
        <Grid container>
          <Grid item xs={12}>
            <FormHeader>Safe Operating Parameters</FormHeader>
          </Grid>
          {!hasEPMControl && (
            <>
              <CardLabel label={"Minimum Speed"} />
              <CardValue value={formatWithUnits(minSpeed)} />

              <CardLabel label={"Maximum Speed"} />
              <CardValue value={formatWithUnits(maxSpeed)} />
            </>
          )}
          {hasIotBridge && (minDowntime || maxDowntime) && (
            <React.Fragment>
              <CardLabel label={"Minimum Downtime"} />
              <CardValue
                value={`${minDowntime.hours} hour${pluralize(
                  minDowntime.hours,
                )} ${minDowntime.minutes} minute${pluralize(
                  minDowntime.minutes,
                )}`}
              />

              <CardLabel label={"Maximum Downtime"} />
              <CardValue
                value={`${maxDowntime.hours} hour${pluralize(
                  maxDowntime.hours,
                )} ${maxDowntime.minutes} minute${pluralize(
                  maxDowntime.minutes,
                )}`}
              />
            </React.Fragment>
          )}
          {hasEPMControl && (minStoptime || maxStoptime) && (
            <>
              <CardLabel label={"Minimum Stop Time"} />
              <CardValue
                value={`${minStoptime.hours} hour${pluralize(
                  minStoptime.hours,
                )} ${minStoptime.minutes} minute${pluralize(
                  minStoptime.minutes,
                )}`}
              />
              <CardLabel label={"Maximum Stop Time"} />
              <CardValue
                value={`${maxStoptime.hours} hour${pluralize(
                  maxStoptime.hours,
                )} ${maxStoptime.minutes} minute${pluralize(
                  maxStoptime.minutes,
                )}`}
              />
            </>
          )}
          {privileges.includes(privilegeTypes.well.configure) && (
            <Grid item xs={12}>
              <FormButton
                onClick={() =>
                  this.setState({showSafeOperatingParametersEditView: true})
                }
                style={{marginTop: "8px"}}
              >
                Edit Safe Operating Parameters
              </FormButton>
            </Grid>
          )}
        </Grid>
      </PaperContainer>
    );
  }
}

export default connect(mapStateToProps)(SafeOperatingParametersReadView);
