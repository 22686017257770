import Grid from "@material-ui/core/Grid";

import React from "react";

import formatPluralize from "@ambyint/common/utils/format/format-pluralize";

import FormButton from "components/form-button";
import FormHeader from "components/form-header";
import PaperContainer from "components/paper-container";

import getPrivileges from "utils/get-privileges";
import mapMinutesToHours from "utils/map-minutes-to-hours";
import noop from "utils/noop";
import privilegeTypes from "utils/privilege-types";

import {cardLabelFactory, cardValueFactory} from "../cards";

const RunTimerReadView = props => {
  const {well, onEdit = noop} = props;

  const {enabled, runTime, stopTime} = well.runTimer;
  const privileges = getPrivileges(well.organization.id);
  const CardLabel = cardLabelFactory(7);
  const CardValue = cardValueFactory(5);

  return (
    <PaperContainer extraPadded style={{maxWidth: "500px"}}>
      <Grid container>
        <Grid item xs={12}>
          <FormHeader>Run Timer Settings</FormHeader>
        </Grid>

        <CardLabel key="enabled" label={"Enabled"} />
        <CardValue key="enabled-value" value={enabled ? "Yes" : "No"} />

        {enabled && [
          <CardLabel key="run-time" label={"Run Time"} />,
          <CardValue
            key="run-time-value"
            value={`${runTime} minute${formatPluralize(
              runTime,
            )} (${mapMinutesToHours(runTime)})`}
          />,
        ]}

        {enabled && [
          <CardLabel key="stop-time" label={"Stop Time"} />,
          <CardValue
            key="stop-time-value"
            value={`${stopTime} minute${formatPluralize(
              stopTime,
            )} (${mapMinutesToHours(stopTime)})`}
          />,
        ]}

        {privileges.includes(privilegeTypes.well.configure) && (
          <Grid item xs={12}>
            <FormButton onClick={() => onEdit()}>
              Edit Run Timer Settings
            </FormButton>
          </Grid>
        )}
      </Grid>
    </PaperContainer>
  );
};

export default RunTimerReadView;
