import {getModeParser} from "@ambyint/common/parsers/well/parse-pumpoff-setting/parse-mode";
import {load, position} from "unit-converter";
import {of} from "rxjs/observable/of";

import {setOne, setSetupError, types} from "actions/wells";
import {show} from "actions/snackbar";
import fetch from "epics/fetch";
import getUser from "utils/get-user";
import valueOf from "utils/value-of";
import getUnits from "utils/get-units";

const defaultPumpOffSetting = {
  load: 0,
  position: 0,
  strokes: 0,
  primaryFillage: 1,
  secondaryFillage: 0,
};

const parsePayloadBody = data => {
  const units = getUnits();

  const convertPosition = data => {
    const value = parseFloat(valueOf(data));
    return data.units === "%"
      ? value
      : position.convert({from: units.position, to: "m", value});
  };

  const convertLoad = data => {
    const value = parseFloat(valueOf(data));
    return data.units === "%"
      ? value
      : load.convert({from: units.load, to: "N", value});
  };

  return {
    pumpOffSetting: {
      ...defaultPumpOffSetting,
      mode: parseInt(data.mode, 10),
      ...(data.load ? {load: convertLoad(data.load)} : {}),
      ...(data.position ? {position: convertPosition(data.position)} : {}),
      ...(data.strokes ? {strokes: parseFloat(valueOf(data.strokes))} : {}),
      ...(data.primaryFillage
        ? {primaryFillage: parseFloat(valueOf(data.primaryFillage))}
        : {}),
      ...(data.secondaryFillageEnabled
        ? {secondaryFillage: parseFloat(valueOf(data.secondaryFillage))}
        : {}),
      secondaryFillageEnabled: !!data.secondaryFillageEnabled,
    },
  };
};

export default action$ =>
  action$.ofType(types.savePumpoffSettings).switchMap(({payload}) => {
    const {unitsOfMeasure} = getUser();
    const parseMode = getModeParser(unitsOfMeasure)(payload.data.mode);

    return fetch(
      `/optimization/well/${encodeURIComponent(
        payload.downholeLocation,
      )}/pumpOff`,
      {},
      {
        body: parsePayloadBody(payload.data),
        method: "PUT",
      },
    )
      .flatMap(() => [
        setOne({
          wellId: payload.wellId,
          data: {
            pumpOffSetting: {
              ...payload.data,
              mode: {
                label: parseMode.label,
                value: parseMode.value,
              },
            },
          },
        }),
        setSetupError({
          downholeLocation: payload.downholeLocation,
          error: "",
        }),
        show({
          message: "You have successfully updated the pumpoff settings.",
        }),
      ])
      .catch(error =>
        of(
          setSetupError({
            downholeLocation: payload.downholeLocation,
            error: error.xhr.response,
          }),
          show({
            message: "Unable to save pumpoff settings.",
          }),
        ),
      );
  });
