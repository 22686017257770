import contains from "utils/contains";
import findIndex from "lodash/findIndex";

export default (items = [], query = "") => {
  if (!query) return items;

  return items.filter(item => {
    return (
      findIndex(item.tags, tag => {
        return contains(tag, query);
      }) >= 0
    );
  });
};
