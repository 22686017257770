import isNil from "lodash/isNil";
import reduce from "lodash/reduce";

import mergeDevices from "./merge-devices";

const filterNil = o =>
  reduce(
    o,
    (current, value, index) => {
      return {
        ...current,
        ...(!isNil(value) ? {[index]: value} : {}),
      };
    },
    {},
  );

export default (existingWell, newWell) => ({
  ...existingWell,
  ...filterNil(newWell),
  devices: mergeDevices(existingWell.devices, newWell.devices),
});
