import formatWithUnits from "@ambyint/common/utils/format/format-with-units";

const formatTubingDescription = tubing =>
  tubing &&
  `OD: ${formatWithUnits(
    tubing.outsideDiameter,
    3,
  )}, Nominal Weight: ${formatWithUnits(
    tubing.nominalWeight,
    2,
  )}, Grade: ${tubing.grade &&
    tubing.grade.toUpperCase()}, ID: ${formatWithUnits(
    tubing.insideDiameter,
    3,
  )}`;

export default formatTubingDescription;
