import React, {useState, useCallback} from "react";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import DropdownIcon from "@material-ui/icons/ArrowDropDown";
import Paper from "@material-ui/core/Paper";
import compose from "lodash/fp/compose";
import withStyles from "@material-ui/core/styles/withStyles";
import get from "lodash/get";
import {connect} from "react-redux";

import Dialog from "components/dialog";
import ResultsList from "components/results-list";
import {toggleOrganizationSelectionDialog} from "actions/organization-view";

const styles = ({colors, palette}) => ({
  paper: {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    border: `1px solid threedface`,
    borderTop: "unset",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 12,
    height: 80,
  },
  closeButton: {
    color: colors.warmGrey[50],
    backgroundColor: colors.warmGrey[600],
    "&:hover": {
      backgroundColor: colors.warmGrey[500],
    },
    margin: 0,
  },
  title: {
    color: colors.warmGrey[900],
    display: "flex",
    alignItems: "center",
  },
  content: {
    padding: "12px 24px",
    paddingTop: 24,
    height: 280,
    overflow: "hidden",
    borderTop: `1px solid ${colors.warmGrey[200]}`,
    borderBottom: `1px solid ${colors.warmGrey[200]}`,
    backgroundColor: colors.warmGrey[50],
  },
  actions: {
    height: 64,
    paddingRight: 24,
  },
  listPadding: {paddingTop: 0, paddingBottom: 0},
  focusedTextField: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderColor: `${palette.primary.main} !important`,
  },
  textField: {
    backgroundColor: "white",
    paddingRight: 0,
    marginTop: 16,
  },
  focusedLabel: {
    color: `${palette.primary.main} !important`,
  },
});

const mapStateToProps = ({
  organizationView: {organizationSelectionDialogOpen, currentOrganization},
  auth,
}) => {
  const assignedOrganizations = get(auth, "user.assignedOrganizations", []);

  return {
    open: organizationSelectionDialogOpen,
    assignedOrganizations,
    currentOrganization,
  };
};

const mapDispatchToProps = dispatch => ({
  closeDialog: () => dispatch(toggleOrganizationSelectionDialog(false)),
});

const OrganizationSelectionDialog = ({
  classes,
  open,
  closeDialog,
  assignedOrganizations,
  currentOrganization,
}) => {
  const [error, setError] = useState(false);
  const [focused, setFocus] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const onChange = ({target: {value}}) => setInputValue(value);

  const onClose = () => {
    setError(false);
    setFocus(false);
    setInputValue("");
    closeDialog();
  };

  // Close the results list when you click anywhere in the dialog besides the input
  // The results list prevents event propagation itself when items are clicked
  const onDialogClick = useCallback(
    e => {
      if (e.target.id !== "organization-input") setFocus(false);
    },
    [setFocus],
  );

  const organizations = assignedOrganizations
    // Mutate organization data into the format accepted by ResultsList
    .map(org => ({
      id: org.organizationId,
      label: org.organizationName,
    }))
    .filter(({id}) => id !== currentOrganization);

  const validate = () => {
    if (inputValue === "") return {error: "You must select an organization"};

    const organization = organizations.find(
      ({label}) => label.toLowerCase() === inputValue.toLowerCase(),
    );

    if (!organization) return {error: "Enter a valid organization"};

    return {id: organization.id};
  };

  const onSelect = ({id}) => {
    onClose();
    setFocus(false);
    setInputValue("");
    window.open(`/#/?oid=${id}`, "_blank");
  };

  const onSubmit = () => {
    const result = validate();

    if (result.error) {
      setError(result.error);
      setFocus(false);
    } else {
      setError(false);
      onSelect(result);
    }
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}
      onClick={onDialogClick}
    >
      <div className={classes.header}>
        <DialogTitle classes={{root: classes.title}} disableTypography>
          <Typography variant="h6">Select Organization</Typography>
        </DialogTitle>
        <span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </span>
      </div>
      <DialogContent className={classes.content}>
        <InputLabel
          htmlFor="organization-input"
          className={focused ? classes.focusedLabel : ""}
          classes={{root: classes.textFieldLabel}}
        >
          Organizations
          <TextField
            autoFocus
            id="organization-input"
            type="search"
            variant="outlined"
            fullWidth
            value={inputValue}
            onChange={onChange}
            InputProps={{
              classes: {
                notchedOutline: focused ? classes.focusedTextField : "",
                root: classes.textField,
              },
              endAdornment: (
                <InputAdornment>
                  <DropdownIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            autoComplete="off"
            onFocus={() => {
              setFocus(true);
              setError(false);
            }}
            error={!!error}
            onKeyDown={e => {
              if (e.key === "Enter") {
                e.target.blur();
                onSubmit();
              }
            }}
            helperText={error}
          />
        </InputLabel>

        {focused && (
          <Paper square classes={{root: classes.paper}} elevation={0}>
            <ResultsList
              results={organizations}
              query={inputValue}
              onClick={onSelect}
              paperProps={{
                classes: {root: classes.paper},
              }}
              listProps={{
                style: {maxHeight: 180, height: "auto"},
                classes: {padding: classes.listPadding},
              }}
              emptyTextProps={{style: {opacity: 0.5}}}
            />
          </Paper>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          className={classes.closeButton}
          variant="contained"
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
);

export default enhance(OrganizationSelectionDialog);
