import {scaleLinear as d3scaleLinear} from "d3-scale";
import {max as d3max} from "d3-array";
import moment from "moment";
import addLine from "utils/charts/add-line";

function mapData(d) {
  const rrpm = d.rrpm || [];
  const lastRrpm = rrpm[rrpm.length - 1];

  if (lastRrpm && moment(lastRrpm.createdOn).isBefore(d.maxDate)) {
    rrpm.push({
      createdOn: d.maxDate,
      type: "rrpm",
      value: lastRrpm.value,
    });
  }

  return rrpm;
}

function mapTime(d) {
  return new Date(d.createdOn);
}

function mapRrpm(d) {
  return d.value;
}

export default ({clipPathId, selection, register, scales}) => {
  const rrpmScale = d3scaleLinear();
  const options = {
    clipPathId,
    mapData,
    mapX: mapTime,
    mapY: mapRrpm,
    selection,
    xScale: scales.time,
    yScale: rrpmScale,
  };

  const line = addLine(options);

  return register({
    draw(props) {
      const {config, data} = props;
      const maxRrpm = d3max(mapData(data), mapRrpm);
      rrpmScale.domain([0, maxRrpm * 1.1]).range([props.height, 0]);

      line.hide();
      if (config.rrpm && config.rrpm.state > 0) {
        line.show();
      }

      line.draw(props);
    },

    zoom(props) {
      line.zoom(props);
    },
  });
};
