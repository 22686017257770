import React from "react";

import Dynocard from "components/dynocard";
import withResize from "components/with-resize";

const defaultCard = {
  points: [],
};

const mapData = data =>
  data.map(ele => ({
    x: ele.position.value,
    y: ele.load.value,
  }));

const Chart = props => {
  const {downholeCard = defaultCard, surfaceCard = defaultCard, width} = props;

  return (
    <Dynocard
      width={width}
      surfaceData={mapData(surfaceCard)}
      downholeData={mapData(downholeCard)}
    />
  );
};

export default withResize(Chart);
