import {getShutdownInfo} from "@ambyint/common/well-status/get-shutdown-info";

export default card => {
  const {driveType, modelNumber, shutdown, shutdownCode} = card;

  if (!shutdown) return;

  const shutdownInfo = getShutdownInfo({
    code: shutdownCode,
    driveType,
    modelNumber,
  });

  return shutdownInfo && shutdownInfo.description;
};
