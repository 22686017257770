import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";

import noTags from "images/no-tags.svg";

const useStyles = makeStyles(({colors, typography, spacing}) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "auto",
    marginBottom: spacing(1),
  },
  image: {
    height: 140,
    width: 140,
    marginTop: spacing(6),
    marginBottom: spacing(4),
  },
  heading: {
    ...typography.h5,
    color: colors.warmGrey[400],
    paddingBottom: spacing(1),
  },
  caption: {
    ...typography.caption,
    color: colors.warmGrey[300],
  },
}));

const EmptyState = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img className={classes.image} src={noTags} alt="" />
      <span className={classes.heading}>No Tags Yet</span>
      <span className={classes.caption}>
        Once added, tags will be displayed here
      </span>
    </div>
  );
};

export default EmptyState;
