import React, {useEffect, useState, memo} from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import compose from "lodash/fp/compose";
import withStyles from "@material-ui/core/styles/withStyles";

// This delay provides a short amount of time where the progress will not appear.
// This prevents the progress display from flashing on if the content loads very quickly (under 1.5s).
// If the content takes longer, the progress spinner will appear to inform the user that we ware waiting.
const DELAY = 1500;

const styles = ({colors}) => ({
  progress: {
    alignSelf: "center",
    justifySelf: "center",
  },

  circular: {
    color: colors.blue[500],
  },
});

const enhance = compose(
  memo,
  withStyles(styles),
);

const Progress = ({classes, style}) => {
  const [timerExpired, setTimerExpired] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => {
      setTimerExpired(true);
    }, DELAY);

    return function cleanup() {
      clearTimeout(id);
    };
  }, []);

  return (
    <div className={classes.progress} style={style}>
      {timerExpired ? <CircularProgress className={classes.circular} /> : null}
    </div>
  );
};

export default enhance(Progress);
