import Grid from "@material-ui/core/Grid";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import React from "react";
import Typography from "@material-ui/core/Typography";

import formatDateTime from "@ambyint/common/utils/format/format-date-time";

import Aux from "components/aux";
import Breadcrumbs from "components/breadcrumbs";
import DateRangeField from "components/forms/date-range-field";
import FilterableSelect from "components/filterable-select";
import {
  filterEventsByDateRange,
  filterEventsByPerformedBy,
  filterEventsByPerformedOn,
  filterEventsByType,
  selectOrganization,
} from "actions/organizations";
import {options as organizationEventTypes} from "constants/organization-event-types";
import PaperContainer from "components/paper-container";
import TextFieldListMenu from "components/text-field-list-menu";

import types from "./types";
import WellAssignmentListDialog from "./well-assignment-list-dialog";

const renderEvents = events => {
  return events.map(event => {
    const Type = types[event.type];

    return (
      <div key={event._id} style={{marginBottom: "16px"}}>
        <Typography variant="subtitle1">
          {formatDateTime(event.createdOn)}
        </Typography>
        <Type event={event} />
      </div>
    );
  });
};

export default class extends React.Component {
  componentWillMount() {
    const {
      dispatch,
      match: {
        params: {organizationId},
      },
    } = this.props;

    dispatch(selectOrganization({organizationId}));
    dispatch(
      filterEventsByDateRange({
        organizationId,
        range: {
          start: moment()
            .subtract(30, "days")
            .startOf("day")
            .toDate(),
          end: moment()
            .endOf("day")
            .toDate(),
        },
      }),
    );
  }

  render() {
    const {
      dispatch,
      filteredEvents,
      loading,
      organizationId,
      organizationName,
      performedOn,
      performedOnItems,
      performedBy,
      performedByItems,
      range,
      type,
    } = this.props;

    return (
      !loading && (
        <Aux>
          <div style={{marginBottom: 16}}>
            <Breadcrumbs
              crumbs={[
                {
                  label: organizationName,
                  pathname: "/profile/organizations",
                },
                {
                  label: "Event Feed",
                },
              ]}
            />
          </div>
          <div style={{marginBottom: 16}}>
            <DateRangeField
              height={400}
              maxRange={{
                length: 2,
                unitOfTime: "month",
              }}
              onChange={value => {
                dispatch(
                  filterEventsByDateRange({
                    organizationId,
                    range: {
                      start: moment(value.start)
                        .startOf("day")
                        .toDate(),
                      end: moment(value.end)
                        .endOf("day")
                        .toDate(),
                    },
                  }),
                );
              }}
              start={range.start}
              startLabel="From"
              end={range.end}
              endLabel="To"
            />
          </div>
          <PaperContainer style={{marginBottom: 16, padding: 16}}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FilterableSelect
                  fullWidth
                  id="performed-on-filter"
                  items={performedOnItems}
                  label="Performed On"
                  mapLabel={({text}) => text}
                  mapValue={({value}) => value}
                  maxDisplayCount={100}
                  onSelect={option => {
                    dispatch(
                      filterEventsByPerformedOn({
                        organizationId,
                        performedOn: option && option.value,
                      }),
                    );
                  }}
                  value={performedOnItems.find(
                    user => user.value === performedOn,
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <FilterableSelect
                  fullWidth
                  id="Performed By"
                  items={performedByItems}
                  label="Performed By"
                  mapLabel={({text}) => text}
                  mapValue={({value}) => value}
                  maxDisplayCount={100}
                  onSelect={option => {
                    dispatch(
                      filterEventsByPerformedBy({
                        organizationId,
                        performedBy: option && option.value,
                      }),
                    );
                  }}
                  value={performedByItems.find(
                    user => user.value === performedBy,
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <TextFieldListMenu
                  label="Event Type"
                  getValue={({value}) => value}
                  hideEmptyOption
                  onChange={value =>
                    dispatch(
                      filterEventsByType({
                        organizationId,
                        type: value,
                      }),
                    )
                  }
                  options={[
                    {text: "All", value: null},
                    ...organizationEventTypes,
                  ]}
                  value={type}
                />
              </Grid>
            </Grid>
          </PaperContainer>

          <PaperContainer
            style={{
              marginBottom: 16,
              maxHeight: "calc(100vh - 322px)",
              overflow: "auto",
              padding: 16,
            }}
          >
            <div>
              {isEmpty(filteredEvents) ? (
                <Typography variant="subtitle1">
                  There are no events for the given filters
                </Typography>
              ) : (
                renderEvents(filteredEvents)
              )}
            </div>
          </PaperContainer>
          <WellAssignmentListDialog />
        </Aux>
      )
    );
  }
}
