import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import MaskedTextFieldComponent from "components/masked-text-field";
import React from "react";
import withUnits from "utils/with-units";
import {Field} from "redux-form";

import formatLabel from "./format-label";
import getValue from "./get-value";
import DisplayUnits from "./display-units";

export const MaskedTextField = props => {
  const hasError =
    (!!props.meta.dirty || !!props.meta.submitFailed) && !!props.meta.error;

  return (
    <FormControl
      fullWidth={props.fullWidth}
      error={hasError}
      style={{
        ...props.style,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
        }}
      >
        <MaskedTextFieldComponent
          autoFocus={props.autoFocus}
          disabled={props.disabled}
          fullWidth
          helperText={props.helperText}
          inputMask={props.maskPattern.inputMask || props.maskPattern}
          InputProps={{
            ...props.InputProps,
            inputProps: props.inputProps,
            endAdornment: props.endAdornment,
          }}
          label={formatLabel(props)}
          maskOnEnter={props.maskOnEnter}
          onChange={(event, value) =>
            props.input.onChange(
              props.input.value.units !== undefined
                ? withUnits(value, props.input.value.units)
                : value,
            )
          }
          outputMask={props.maskPattern.outputMask || props.maskPattern}
          prefix={props.maskPattern.prefix}
          value={getValue(props)}
          type={props.type}
        />
        {!props.hideUnits && <DisplayUnits input={props.input} />}
      </div>
      {hasError && <FormHelperText error>{props.meta.error}</FormHelperText>}
    </FormControl>
  );
};

export default props => {
  return <Field {...props} component={MaskedTextField} />;
};
