import React from "react";
import {hasControlForEpm} from "@ambyint/common/utils/devices/control-for-epm";
import get from "lodash/get";
import math from "mathjs";

import colors from "theme/colors";
import getUser from "utils/get-user";
import spreadIf from "utils/spread-if";
import unitsOf from "utils/units-of";
import valueOf from "utils/value-of";

import Chart from "components/d3-line-chart/chart";

const DriveDataChart = ({
  data,
  devices,
  height = 250,
  margin,
  showGuidelines,
  width,
}) => {
  if (!data.driveData) {
    return null;
  }

  const user = getUser();
  const isEpm = hasControlForEpm(devices);
  const leftAxisUnits =
    isEpm && user.epmPreference ? user.epmPreference : "Torque";

  const motorViolations =
    data.driveData &&
    data.driveData.motorViolations &&
    data.driveData.motorViolations[
      `${leftAxisUnits.toLocaleLowerCase()}ViolationSetpoint`
    ];

  return (
    <Chart
      data={data.driveData.value}
      width={width}
      height={height}
      margin={margin}
      y={{
        left: {
          label:
            unitsOf(
              get(
                data,
                `driveData.value[0][${leftAxisUnits.toLocaleLowerCase()}]`,
              ),
            ) || `${leftAxisUnits}`,
          map: d => valueOf(d[leftAxisUnits.toLocaleLowerCase()]),
          color: colors[leftAxisUnits.toLocaleLowerCase()][400],
          marginPercent: 20,
          guidelines: showGuidelines &&
            motorViolations && {
              ...(motorViolations.min && motorViolations.min.value
                ? {
                    min: {
                      map: d => motorViolations.min.value,
                      color: colors.red[300],
                    },
                  }
                : {}),
              ...(motorViolations.max && motorViolations.max.value
                ? {
                    max: {
                      map: d => motorViolations.max.value,
                      color: colors.red[300],
                    },
                  }
                : {}),
            },
        },
        ...spreadIf(!isEpm, {
          right: {
            label: "RPM",
            map: d => d.rpm && math.round(d.rpm, 2),
            color: colors.rpm[400],
            marginPercent: 20,
            scaleMinimum: 50,
          },
        }),
      }}
      x={{label: "Seconds", map: d => d.timestamp / 1000, marginPercent: 0.0}}
    />
  );
};

export default DriveDataChart;
