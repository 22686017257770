import React from "react";
import {connect} from "react-redux";

import {hasControlForEpm} from "@ambyint/common/utils/devices/control-for-epm";
import isEmpty from "lodash/isEmpty";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import moment from "moment";

import PaperContainer from "components/paper-container";
import * as EVENT_TYPES from "constants/event-types";
import {FILTER_GROUPS, TYPES} from "constants/well-feed-filters";
import getEpmPreference from "utils/get-epm-preference";
import hasGeneratedCardsEvent from "utils/well/has-generated-cards-event";

import feedItemTypes from "./feed-item/types";
import Filters from "./filters";
import {
  defaultContainerStyle,
  defaultGridContainer,
  defaultTitleStyle,
  scrollableContainer,
} from "../dashboard-styles";

const styles = {
  container: {
    margin: "0px",
  },
  outerContainer: {
    ...scrollableContainer,
  },
};

const mapStateToProps = state => {
  return {
    wellFeedFilter: state.wells.wellFeedFilter,
  };
};

const renderEvent = well => event => {
  const FeedItem = feedItemTypes[event.type];

  return <FeedItem key={event._id} event={event} well={well} />;
};

const filterEvents = (well, wellFeedFilter) => {
  const events = well.events || [];

  return events.filter(event => {
    return (
      feedItemTypes[event.type] &&
      moment(event.createdOn).isSameOrAfter(moment().subtract(1, "day")) &&
      (event.type !== "rawCards" ||
        !hasGeneratedCardsEvent(event, well.eventsByType)) &&
      (hasControlForEpm(well.devices) && getEpmPreference() === "Current"
        ? event.type !== EVENT_TYPES.WELL_TORQUE_VIOLATION
        : event.type !== EVENT_TYPES.WELL_CURRENT_VIOLATION) &&
      FILTER_GROUPS[wellFeedFilter].includes(event.type)
    );
  });
};

const sortEvents = (a, b) => {
  return new Date(b.createdOn) - new Date(a.createdOn);
};

const renderEvents = props => {
  const {well, wellFeedFilter} = props;

  const filtered = filterEvents(well, wellFeedFilter);

  if (isEmpty(filtered))
    return (
      <div style={defaultGridContainer}>
        <Typography variant="subtitle1">No new activity in 24 hours</Typography>
      </div>
    );

  return (
    <List style={{padding: "0px"}} dense>
      {filtered.sort(sortEvents).map(renderEvent(well))}
    </List>
  );
};

const WellFeed = props => {
  const {wellFeedFilter} = props;

  return (
    <PaperContainer style={styles.outerContainer}>
      <div style={{...defaultContainerStyle, ...styles.container}}>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            padding: "8px 16px 0px",
          }}
        >
          <div>
            <Typography variant="body1" style={defaultTitleStyle}>
              Well Feed
            </Typography>
            {wellFeedFilter && wellFeedFilter !== TYPES.ALL && (
              <Typography variant="caption">
                filter: {wellFeedFilter}
              </Typography>
            )}
          </div>
          <Filters />
        </div>
        {renderEvents(props)}
      </div>
    </PaperContainer>
  );
};

export default connect(mapStateToProps)(WellFeed);
