import moment from "moment";

export const secondsSince12am = momentObj => {
  return (
    Number(momentObj.format("X")) -
    Number(
      moment()
        .startOf("day")
        .format("X"),
    )
  );
};
