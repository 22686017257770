import React, {Component} from "react";
import PropTypes from "prop-types";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import {withStyles} from "@material-ui/core/styles";

const styleSheet = theme => ({
  root: {
    position: "relative",
  },
  textFieldContainer: {},
  textField: {
    cursor: "pointer",
  },
  errorText: {
    position: "absolute",
    bottom: -14,
    left: 0,
  },
});

class TextFieldListMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: undefined,
      open: false,
    };
  }

  button = undefined;

  handleClickListItem = event => {
    if (!this.props.disabled) {
      this.setState({open: true, anchorEl: event.currentTarget});
    }
  };

  handleMenuItemClick = (event, index, option) => {
    this.setState({open: false});

    let value = option;
    if (this.props.getValue) {
      value = this.props.getValue(value);
    }

    this.props.onChange(value);
  };

  handleRequestClose = () => {
    this.setState({open: false});
  };

  render() {
    const classes = this.props.classes;

    const options = [...(this.props.options || [])];
    if (!this.props.hideEmptyOption) {
      if (options.length > 0 && options[0].value !== undefined) {
        options.unshift({value: "", text: ""});
      } else {
        options.unshift("");
      }
    }

    const value = Math.max(
      0,
      options.findIndex(option =>
        option.value !== undefined
          ? option.value === this.props.value
          : option === this.props.value,
      ),
    );

    const formatDisplay = this.props.formatDisplay || (value => value);
    const selectedOption = options[value];
    const selectedText = selectedOption
      ? selectedOption.text === undefined
        ? selectedOption
        : selectedOption.text
      : "";

    return (
      <div className={classes.root} style={this.props.containerStyle}>
        <div style={{display: "flex"}} className={classes.textFieldContainer}>
          <TextField
            className={classes.textField}
            label={this.props.label}
            value={formatDisplay(selectedText)}
            onClick={this.handleClickListItem}
            style={{flexGrow: 1}}
            disabled={this.props.disabled}
          />
          {!this.props.disabled && (
            <ArrowDropDownIcon
              style={{
                cursor: "pointer",
                marginTop: 8,
                position: "absolute",
                right: 0,
              }}
              onClick={this.handleClickListItem}
            />
          )}
        </div>

        <Menu
          id="lock-menu"
          anchorEl={this.state.anchorEl}
          open={!this.props.disabled && this.state.open}
          onClose={this.handleRequestClose}
        >
          {options.map((option, index) => {
            return (
              <MenuItem
                key={option.value === undefined ? option : option.value}
                selected={index === this.props.value}
                onClick={event =>
                  this.handleMenuItemClick(event, index, option)
                }
              >
                {option.text === undefined
                  ? formatDisplay(option)
                  : formatDisplay(option.text)}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    );
  }
}

TextFieldListMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styleSheet)(TextFieldListMenu);
