import {types as wellTypes} from "@ambyint/common/actions/wells";

import {types} from "./types";
export {create} from "./create";
export {fetchAllRecommendations} from "./fetch-all-recommendations";
export {requestCard} from "./request-card";
export {saveSlowSpeedOffTime} from "./save-slow-speed-off-time";
export {
  saveAutoAcceptRecommendations,
} from "./save-auto-accept-recommendations";
export {saveNominalRpm} from "./save-nominal-rpm";
export {setActive} from "./set-active";
export {setAllRecommendationsFetched} from "./set-all-recommendations-fetched";
export {setAllRecommendations} from "./set-all-recommendations";
export {setLufkinDeadband} from "./set-lufkin-deadband";
export {setLufkinFillBase} from "./set-lufkin-fill-base";
export {setLufkinMalfunction} from "./set-lufkin-malfunction";
export {setLufkinRodString} from "./set-lufkin-rod-string";
export {setLufkinStructuralLoad} from "./set-lufkin-structural-load";
export {setMapType} from "./set-map-type";
export {setMapBounds} from "./set-map-bounds";
export {setWellsFetched} from "./set-wells-fetched";
export {types};
export {setRecommendationType} from "./set-recommendation-type";
export {updateRecommendation} from "./update-recommendation";
export {
  setAnomalyWells,
  setDisplayAllWells,
  setCalibrateWells,
  setTriageWells,
  setWorkflow,
} from "./workflows";

export const acceptRecommendation = payload => {
  return {
    type: types.acceptRecommendation,
    payload,
  };
};

/**
 * Action creator for adding a comparison.
 * @param {{downholeLocation: "string", correlationId: "string"}} props
 */
export const addComparison = props => {
  const {correlationId, downholeLocation} = props;

  return {
    type: types.addComparison,
    payload: {
      correlationId,
      downholeLocation,
    },
  };
};

export const deleteGracePeriod = ({data, well}) => {
  return {
    type: types.deleteGracePeriod,
    payload: {
      data,
      well,
    },
  };
};

export const dismissRecommendation = payload => {
  return {
    type: types.dismissRecommendation,
    payload,
  };
};

export const fetchAnalysis = ({downholeLocation, end, eventTypes, start}) => {
  return {
    type: types.fetchAnalysis,
    payload: {
      downholeLocation,
      end,
      eventTypes,
      start,
    },
  };
};

export const fetchAnalysisComplete = ({downholeLocation}) => {
  return {
    type: types.fetchAnalysisComplete,
    payload: {
      downholeLocation,
    },
  };
};

/**
 * Action creator for fetching a comparison.
 * @param {{downholeLocation: "string", correlationId: "string"}} props
 */
export const fetchComparison = props => {
  const {correlationId, downholeLocation} = props;

  return {
    type: types.fetchComparison,
    payload: {
      correlationId,
      downholeLocation,
      types: [
        "annulusVolumeAnalysis",
        "driveData",
        "fluidLevelAnalysis",
        "generatedCards",
        "goodmanAnalysis",
        "inferredProductionAnalysis",
        "pumpjackAnalysis",
        "rawCards",
        "rodStringWeightAnalysis",
        "rodStringWeightAnalysis",
      ],
    },
  };
};

export const fetchEvents = query => {
  return {
    type: types.fetchEvents,
    payload: query,
  };
};

export const fetch = ({clientUserId}) => {
  return {
    type: types.fetch,
    payload: {
      clientUserId,
    },
  };
};

export const fetchAlerts = ({downholeLocation}) => {
  return {
    type: types.fetchAlerts,
    payload: {
      downholeLocation,
    },
  };
};

export const fetchCards = ({downholeLocation, end, start}) => {
  return {
    type: types.fetchCards,
    payload: {
      downholeLocation,
      end,
      start,
    },
  };
};

export const fetchLatestCards = ({downholeLocation}) => {
  return {
    type: types.fetchLatestCards,
    payload: {
      downholeLocation,
    },
  };
};

export {fetchMiniCardById} from "./fetch-mini-card-by-id";
export {fetchLatestMiniCard} from "./fetch-latest-mini-card";
export {fetchCardOverride} from "./fetch-card-override";
export {setCardOverride} from "./set-card-override";

export const fetchOnCall = ({downholeLocation}) => {
  return {
    type: types.fetchOnCall,
    payload: {
      downholeLocation,
    },
  };
};

export const fetchOne = ({downholeLocation}) => {
  return {
    type: types.fetchOne,
    payload: {
      downholeLocation,
    },
  };
};

export const fetchUptimeOne = ({downholeLocation}) => {
  return {
    type: types.fetchUptimeOne,
    payload: {
      downholeLocation,
    },
  };
};

export const fetchRecommendations = ({downholeLocation}) => {
  return {
    type: types.fetchRecommendations,
    payload: {
      downholeLocation,
    },
  };
};

export const search = ({criteria, withRouting}) => {
  return {
    type: types.search,
    payload: {
      criteria,
      withRouting,
    },
  };
};

export const generateShareableAnalysisLink = url => {
  return {
    type: types.generateShareableAnalysisLink,
    payload: {
      url,
    },
  };
};

export const hide = () => {
  return {
    type: types.hide,
    payload: {},
  };
};

export const parseWell = ({data}) => {
  return {
    type: types.parseWell,
    payload: {
      ...data,
    },
  };
};

export const refreshFilters = () => {
  return {
    type: types.refreshFilters,
  };
};

export const saveBasicInformation = ({
  alias,
  coords,
  country,
  downholeLocation,
  wellId,
  fieldName,
  provinceState,
  type,
  surfaceLocation,
}) => {
  return {
    type: types.saveBasicInformation,
    payload: {
      downholeLocation,
      wellId,
      data: {
        alias,
        coords,
        country,
        fieldName,
        provinceState,
        type,
        surfaceLocation,
      },
    },
  };
};

export const saveMotorDetails = ({
  current,
  downholeLocation,
  wellId,
  efficiency,
  horsepower,
  powerSupplyPhases,
  rpm,
  serviceFactor,
  voltage,
}) => {
  return {
    type: types.saveMotorDetails,
    payload: {
      downholeLocation,
      wellId,
      data: {
        current,
        efficiency,
        horsepower,
        powerSupplyPhases,
        rpm,
        serviceFactor,
        voltage,
      },
    },
  };
};

export const saveMotorViolations = ({well, data}) => {
  return {
    type: types.saveMotorViolations,
    payload: {
      well,
      data,
    },
  };
};

export const savePumpingUnitInformation = ({
  crank,
  crankWeights,
  crankPinPosition,
  downholeLocation,
  wellId,
  pumpjack,
  rotationDirection,
  type,
}) => {
  return {
    type: types.savePumpingUnitInformation,
    payload: {
      downholeLocation,
      wellId,
      data: {
        crank,
        crankWeights,
        crankPinPosition,
        downholeLocation,
        pumpjack,
        rotationDirection,
        type,
      },
    },
  };
};

export const savePumpoffSettings = ({downholeLocation, data, wellId}) => {
  return {
    type: types.savePumpoffSettings,
    payload: {
      wellId,
      downholeLocation,
      data,
    },
  };
};

export const saveReservoirInformation = ({
  driveType,
  downholeLocation,
  staticReservoirPressure,
  bubblePointPressure,
  oilDensity,
  waterDensity,
  gasDensity,
  referenceProduction: {
    oilProduction,
    waterProduction,
    gasProduction,
    referencePBHP,
  },
}) => {
  return {
    type: types.saveReservoirInformation,
    payload: {
      downholeLocation,
      data: {
        driveType,
        downholeLocation,
        staticReservoirPressure,
        bubblePointPressure,
        oilDensity,
        waterDensity,
        gasDensity,
        referenceProduction: {
          oilProduction,
          waterProduction,
          gasProduction,
          referencePBHP,
        },
      },
    },
  };
};

export const saveResistance = ({
  dampingCoefficient,
  downholeLocation,
  wellId,
  enableStuffingBoxFrictionAutoCorrection,
  frictionCoefficient,
}) => {
  return {
    type: types.saveResistance,
    payload: {
      downholeLocation,
      wellId,
      data: {
        dampingCoefficient,
        downholeLocation,
        enableStuffingBoxFrictionAutoCorrection,
        frictionCoefficient,
      },
    },
  };
};

export const saveSafeOperatingParameters = ({
  downholeLocation,
  wellId,
  maxSpeed,
  minSpeed,
  maxDowntime,
  minDowntime,
  minStoptime,
  maxStoptime,
}) => {
  return {
    type: types.saveSafeOperatingParameters,
    payload: {
      downholeLocation,
      wellId,
      data: {
        downholeLocation,
        maxSpeed,
        minSpeed,
        maxDowntime,
        minDowntime,
        minStoptime,
        maxStoptime,
      },
    },
  };
};

export const saveDownholeInformation = ({
  casing,
  downholeLocation,
  wellId,
  plunger,
  sections,
  serviceFactor,
  tubing,
}) => {
  return {
    type: types.saveDownholeInformation,
    payload: {
      downholeLocation,
      wellId,
      data: {
        casing,
        plunger,
        sections,
        serviceFactor,
        tubing,
      },
    },
  };
};

export const saveGracePeriod = ({data, well}) => {
  return {
    type: types.saveGracePeriod,
    payload: {
      data,
      well,
    },
  };
};

export const show = ({downholeLocation}) => {
  return {
    type: types.show,
    payload: {
      downholeLocation,
    },
  };
};

export const setReservoirInformation = ({downholeLocation, data, wellId}) => {
  return {
    type: types.setReservoirInformation,
    payload: {
      wellId,
      downholeLocation,
      data,
    },
  };
};

export const saveTotalReductionRatio = ({
  hasJackshaft,
  gearboxRatio,
  gearboxSheave,
  jackshaftGearboxSheave,
  jackshaftMotorSheave,
  motorSheave,
  downholeLocation,
  wellId,
}) => {
  return {
    type: types.saveTotalReductionRatio,
    payload: {
      downholeLocation,
      wellId,
      data: {
        hasJackshaft,
        gearboxRatio,
        gearboxSheave,
        jackshaftGearboxSheave,
        jackshaftMotorSheave,
        motorSheave,
      },
    },
  };
};

export const saveWellboreInformation = ({
  casing,
  casingPerforationBottomMeasuredDepth,
  casingPerforationTopMeasuredDepth,
  downholeLocation,
  wellId,
  trueVerticalDepth,
  wellDepth,
  wellboreSurvey,
}) => {
  return {
    type: types.saveWellboreInformation,
    payload: {
      downholeLocation,
      wellId,
      data: {
        casing,
        casingPerforationBottomMeasuredDepth,
        casingPerforationTopMeasuredDepth,
        trueVerticalDepth,
        wellDepth,
        wellboreSurvey,
      },
    },
  };
};

/**
 * Action creator for removing a comparison.
 * @param {{downholeLocation: "string", correlationId: "string"}} props
 */
export const removeComparison = props => {
  const {correlationId, downholeLocation} = props;

  return {
    type: types.removeComparison,
    payload: {
      correlationId,
      downholeLocation,
    },
  };
};

export const removeRecommendation = payload => {
  return {
    type: types.removeRecommendation,
    payload,
  };
};

export const resetFault = well => {
  return {
    type: wellTypes.RESET_FAULT,
    payload: {
      downholeLocation: well.downholeLocation,
      well,
    },
  };
};

export const setEvents = ({downholeLocation, data}) => {
  return {
    type: types.setEvents,
    payload: {
      downholeLocation,
      data,
    },
  };
};

export const set = payload => {
  return {
    type: types.set,
    payload,
  };
};

export {setActiveAnomaly} from "./set-active-anomaly";
export {setActivePath} from "./set-active-path";

export const setAlerts = ({downholeLocation, data}) => {
  return {
    type: types.setAlerts,
    payload: {
      downholeLocation,
      data,
    },
  };
};

export const setAnalogInputConfiguration = ({
  downholeLocation,
  analogInputs,
  wellId,
}) => {
  return {
    type: types.setAnalogInputConfiguration,
    payload: {
      downholeLocation,
      wellId,
      analogInputs,
    },
  };
};

export const setAnalysis = (downholeLocation, analysis) => {
  return {
    type: types.setAnalysis,
    payload: {
      downholeLocation,
      analysis,
    },
  };
};

export const setAssigned = ({data}) => {
  return {
    type: types.setAssigned,
    payload: {
      data,
    },
  };
};

export const setClearErrors = ({downholeLocation}) => {
  return {
    type: types.setClearErrors,
    payload: {
      downholeLocation,
    },
  };
};

/**
 * Action creator for setting comparisons on a well.
 * @param {{downholeLocation: "string", comparisons: Object[]}} props
 */
export const setComparisons = props => {
  const {comparisons, downholeLocation} = props;

  return {
    type: types.setComparisons,
    payload: {
      downholeLocation,
      comparisons,
    },
  };
};

export const setDowntime = ({downholeLocation, data}) => {
  return {
    type: types.setDowntime,
    payload: {
      downholeLocation,
      data,
    },
  };
};

export const setFaultRecovery = ({downholeLocation, enabled, well}) => {
  return {
    type: types.setFaultRecovery,
    payload: {
      downholeLocation,
      enabled,
      well,
    },
  };
};

export const setOnCall = ({downholeLocation, data}) => {
  return {
    type: types.setOnCall,
    payload: {
      downholeLocation,
      data,
    },
  };
};

export const setOne = ({wellId, data}) => {
  return {
    type: types.setOne,
    payload: {
      wellId,
      data,
    },
  };
};

export const setUptimeOne = ({downholeLocation, data}) => {
  return {
    type: types.setUptimeOne,
    payload: {
      downholeLocation,
      data,
    },
  };
};

export const setRecommendations = ({downholeLocation, data}) => {
  return {
    type: types.setRecommendations,
    payload: {
      downholeLocation,
      data,
    },
  };
};

export const setResetMalfunctions = ({downholeLocation}) => {
  return {
    type: types.setResetMalfunctions,
    payload: {
      downholeLocation,
    },
  };
};

export const setRunTimer = ({well, data}) => {
  return {
    type: types.setRunTimer,
    payload: {
      data,
      well,
    },
  };
};

export {setSearchCriteria} from "./set-search-criteria";
export {setSearchResults} from "./set-search-results";

export const setSetupError = ({downholeLocation, error}) => {
  return {
    type: types.setSetupError,
    payload: {
      downholeLocation,
      error,
    },
  };
};

export const setShareableAnalysisLink = (originalUrl, url) => {
  return {
    type: types.setShareableAnalysisLink,
    payload: {
      originalUrl,
      url,
    },
  };
};

export const setSpeed = ({downholeLocation, speed, well}) => {
  return {
    type: types.setSpeed,
    payload: {
      downholeLocation,
      speed,
      well,
    },
  };
};

export const setSpeedRange = ({downholeLocation, max, min, well}) => {
  return {
    type: types.setSpeedRange,
    payload: {
      downholeLocation,
      max,
      min,
      well,
    },
  };
};

export {setViewType} from "./set-view-type";

export const setWellFeedFilter = wellFeedFilter => {
  return {
    type: types.setWellFeedFilter,
    payload: {wellFeedFilter},
  };
};

export const sort = ({sortAsc, sortBy}) => {
  return {
    type: types.sort,
    payload: {
      sortAsc,
      sortBy,
    },
  };
};

export const startWell = ({displayName, downholeLocation}) => {
  return {
    type: types.start,
    payload: {
      displayName,
      downholeLocation,
    },
  };
};

export const stopWell = ({displayName, downholeLocation}) => {
  return {
    type: types.stop,
    payload: {
      displayName,
      downholeLocation,
    },
  };
};

export const toggleComparisonsView = ({downholeLocation}) => {
  return {
    type: types.toggleComparisonsView,
    payload: {
      downholeLocation,
    },
  };
};

export const saveTag = ({remove, tag, well}) => {
  return {
    type: types.saveTag,
    payload: {
      downholeLocation: well.downholeLocation,
      well,
      remove,
      tag,
    },
  };
};

export const updateAnalysis = (downholeLocation, analysis) => {
  return {
    type: types.updateAnalysis,
    payload: {
      downholeLocation,
      analysis,
    },
  };
};

export const viewFeedComparison = payload => {
  return {
    type: types.viewFeedComparison,
    payload,
  };
};
