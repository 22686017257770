import {parse} from "querystring";

import {createSwitchEpic} from "epics/create-epic";
import {set as setCurrentOrganization} from "actions/organization-view";
import {types as authTypes} from "actions/auth";
import {
  getRecentOrganizations,
  getSessionOrganization,
  getPinnedOrganization,
} from "utils/recent-organizations";

/**
 * On auth set, determine which organization should be loaded for the user.
 *
 * This organization will be used to load wells from  Additionally, organization
 * preferences can be changed per organization that the user has appropriate permissions for.
 */
export default createSwitchEpic([authTypes.set], async ({payload}, store) => {
  if (!payload.user || !payload.isAuthenticated) {
    // This epic triggers on logout as well, do nothing in this case
    return [];
  }

  const userId = payload.user.clientUserId;
  const routing = store.getState().routing;
  const organizations =
    (payload.user && payload.user.assignedOrganizations) || [];
  const organizationMap = organizations.reduce((map, org) => {
    map[org.organizationId] = true;
    return map;
  }, {});

  // Sources of organization
  const recentOrganizations = getRecentOrganizations(userId);
  const lastSavedOrganization = recentOrganizations[0];
  const params = parse(routing.location.search);
  const URIOrganization = params["?oid"] || params.oid;
  const sessionOrganization = getSessionOrganization(userId);
  const pinnedOrganizationId = getPinnedOrganization(userId);

  let organizationId;

  /**
   * We load an organization based on the following prioritization:
   * 1 Organization ID exists as a param in the URI (From opening org in new tab)
   * 2 Last used organization in the current session (Persists the org after refresh of a tab)
   * 3 Organization that is pinned by the user
   * 4 Most recently used organization
   * 5 First organization the user has assigned to them (alphabetical)
   */
  if (URIOrganization && organizationMap[URIOrganization]) {
    organizationId = URIOrganization;
  } else if (sessionOrganization && organizationMap[sessionOrganization]) {
    organizationId = sessionOrganization;
  } else if (pinnedOrganizationId && organizationMap[pinnedOrganizationId]) {
    organizationId = pinnedOrganizationId;
  } else if (
    lastSavedOrganization &&
    organizationMap[lastSavedOrganization] &&
    organizations.length > 1
  ) {
    // Load the last used organization
    organizationId = lastSavedOrganization;
  } else {
    // The user does not have a saved org, use the first one available to them
    organizationId =
      organizations.length >= 1 ? organizations[0].organizationId : null;
  }

  return [
    setCurrentOrganization({
      currentOrganization: organizationId,
      shouldReset: false,
    }),
  ];
});
