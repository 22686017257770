import React from "react";
import Typography from "@material-ui/core/Typography/Typography";

import blankCanvas from "images/blank-canvas.svg";

export const EmptyState = props => {
  return (
    <div {...props}>
      <Typography style={{padding: 20}} variant="h4">
        I guess we have to start somewhere.
      </Typography>
      <img src={blankCanvas} alt="" />
    </div>
  );
};
