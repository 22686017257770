import {defaults} from "./defaults";

export const resetSettings = ({state, action}) => {
  const {type} = action.payload;

  return {
    ...state,
    [type]: {
      ...defaults[type],
    },
  };
};
