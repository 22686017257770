import Grid from "@material-ui/core/Grid";
import DialogContent from "@material-ui/core/DialogContent";

import React from "react";

import getWellSpeedUnits from "@ambyint/common/utils/wells/get-well-speed-units";
import FormWarning from "components/form-warning";
import DecimalTextField from "components/forms/decimal-text-field";
import PlainText from "components/forms/plain-text";
import TextField from "components/forms/text-field";

const SpeedRangeRecommendation = props => {
  const {well} = props;
  const label = getWellSpeedUnits(well);

  return (
    <DialogContent>
      <Grid container>
        <Grid item xs={12}>
          <PlainText name="description" />
        </Grid>

        <Grid item xs={12}>
          <TextField name="comment" fullWidth label="Comment" />
        </Grid>

        <Grid item xs={6}>
          <DecimalTextField
            name="acceptedData.min"
            fullWidth
            label={`Minimum ${label}`}
          />
        </Grid>

        <Grid item xs={6}>
          <DecimalTextField
            name="acceptedData.max"
            fullWidth
            label={`Maximum ${label}`}
          />
        </Grid>

        <Grid item xs={12}>
          <FormWarning style={{marginTop: 8}}>
            Speed range will be automatically updated if this recommendation is
            accepted.
          </FormWarning>
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default SpeedRangeRecommendation;
