import {types} from "../types";

/**
 * Creates an action that sets the anomaly wells.
 * @param {Array} wellList
 * @returns {{type: string, payload: {path: string}}}
 */
export const setAnomalyWells = wellList => {
  return {
    type: types.setAnomalyWells,
    payload: {
      wellList,
    },
  };
};
