import React from "react";
import Grid from "@material-ui/core/Grid";
import isFinite from "lodash/isFinite";
import isNil from "lodash/isNil";
import Lens from "@material-ui/icons/Lens";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import RadioButtonChecked from "@material-ui/icons/RadioButtonChecked";

import theme from "theme";
import colors from "theme/colors";
import abbreviate from "utils/abbreviate-number";

const styles = {
  container: {
    ...theme.typography.body1,
    backgroundColor: colors.white,
    border: `1px solid ${colors.grey[300]}`,
    color: colors.grey[600],
    cursor: "pointer",
    fontSize: "12px",
    padding: "8px",
    width: "150px",
    height: 35,
  },
  icon: {
    height: "16px",
    width: "16px",
    verticalAlign: "text-bottom",
  },
  label: {
    display: "inline-block",
    marginLeft: "5px",
  },
};

const getValue = value => (!isNil(value) ? `${abbreviate(value, 2)}` : "");

const parseConfig = props => {
  if (props.oilProduction.state > 0 && props.inferredOilProduction.state > 0) {
    return {
      ...props.oilProduction,
      color: colors.oilProduction[700],
      label: "Measured Oil Prod.",
      state: 2,
    };
  }
  return {
    ...props.inferredOilProduction,
    color: colors.oilProduction[200],
    label: "Inferred Oil Prod.",
  };
};

export default props => {
  const {oilProduction = {}, inferredOilProduction = {}, onChange} = props;

  const {color, label, show, state, value: {value, units} = {}} = parseConfig(
    props,
  );

  const getIcon = () => {
    switch (state) {
      case 0:
        return <NotInterestedIcon style={{...styles.icon, color}} />;
      case 1:
        return <RadioButtonChecked style={{...styles.icon, color}} />;
      default:
        return <Lens style={{...styles.icon, color}} />;
    }
  };

  const toggleOilProd = () => {
    if (oilProduction.state === 0 && inferredOilProduction.state === 1) {
      return onChange("oilProduction");
    }
    if (oilProduction.state === 1 && inferredOilProduction.state === 0) {
      return onChange("inferredOilProduction");
    }
    if (oilProduction.state === 1 && inferredOilProduction.state === 1) {
      return onChange(["oilProduction", "inferredOilProduction"]);
    }
    if (oilProduction.state === 0 && inferredOilProduction.state === 0) {
      return onChange("inferredOilProduction");
    }
  };

  return (
    <Grid
      item
      className="transparent"
      style={{
        display: show ? "inline-block" : "none",
        padding: "0 5px 10px",
        height: "fit-content",
      }}
      onClick={toggleOilProd}
    >
      <div style={styles.container}>
        <div>
          {getIcon()}
          <span style={styles.label}>{label}</span>
          {isFinite(value) && (
            <span style={styles.label}>
              {getValue(value)} {units}
            </span>
          )}
        </div>
      </div>
    </Grid>
  );
};
