import {ANY} from "constants/tag-selection-types";
import {reducerFactory} from "reducers/reducer-factory";
import {types} from "actions/tagging";

import {clearSelectedWells} from "./clear-selected-wells";
import {clearSelectedTags} from "./clear-selected-tags";
import {toggleTagDrawer} from "./toggle-tag-drawer";
import {toggleManageTagsDialog} from "./toggle-manage-tags-dialog";
import {selectTag} from "./select-tag";
import {setFilteredByTags} from "./set-filtered-by-tags";
import {setTagSelectionType} from "./set-tag-selection-type";
import {toggleWellSelected} from "./toggle-well-selected";
import {deselectTag} from "./deselect-tag";
import {disableUselessTags} from "./disable-useless-tags";
import {replaceTags} from "./replace-tags";

const defaults = {
  tagDrawerOpen: false,
  manageTagsDialogOpen: false,
  selectedTags: [],
  selectedWells: {},
  tags: [],
  filteredByTags: [],
  selectionType: ANY,
};

const actionMap = {
  [types.clearSelectedWells]: clearSelectedWells,
  [types.clearSelectedTags]: clearSelectedTags,
  [types.toggleTagDrawer]: toggleTagDrawer,
  [types.toggleManageTagsDialog]: toggleManageTagsDialog,
  [types.selectTag]: selectTag,
  [types.setFilteredByTags]: setFilteredByTags,
  [types.toggleWellSelected]: toggleWellSelected,
  [types.deselectTag]: deselectTag,
  [types.disableUselessTags]: disableUselessTags,
  [types.replaceTags]: replaceTags,
  [types.setTagSelectionType]: setTagSelectionType,
};

export default reducerFactory(actionMap, defaults);
