import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import React from "react";
import colors from "theme/colors";
import withUnits from "utils/with-units";
import {Field} from "redux-form";
import {withStyles} from "@material-ui/core/styles";
import ReactTooltip from "react-tooltip";

import formatLabel from "./format-label";
import getValue from "./get-value";

const styleSheet = {
  label: {
    top: "-8px",
    display: "block",
    transform: "translate(0, 1.5px) scale(0.75)",
    transformOrigin: "top left",
  },
};

export const TextArea = withStyles(styleSheet, {
  name: "DialogOverrides",
})(props => {
  const hasError =
    !!(props.meta.dirty || props.meta.submitFailed) && !!props.meta.error;

  const label = formatLabel(props);

  return (
    <FormControl
      fullWidth={props.fullWidth}
      style={props.style}
      error={hasError}
    >
      {props.tooltip && (
        <ReactTooltip
          multiline={true}
          place="top"
          effect="solid"
          event="click"
          globalEventOff="click"
        />
      )}

      {label && (
        <InputLabel className={props.classes.label}>{label}</InputLabel>
      )}
      <Input
        data-tip={props.tooltip}
        multiline
        disableUnderline
        rows={props.rows || 2}
        value={getValue(props)}
        style={{
          border: `thin solid ${colors.grey[500]}`,
          borderRadius: "0.2em",
          padding: 16,
        }}
        disabled={props.disabled}
        onChange={event => {
          const value = props.input.value.units
            ? withUnits(event.target.value, props.input.value.units)
            : event.target.value;
          props.input.onChange(value);
        }}
      />
      {hasError && <FormHelperText error>{props.meta.error}</FormHelperText>}
    </FormControl>
  );
});

export default props => {
  return <Field {...props} component={TextArea} />;
};
