import React from "react";

import Typography from "@material-ui/core/Typography";

import colors from "theme/colors";
import pluralize from "@ambyint/common/utils/format/format-pluralize";

import FormButton from "components/form-button";

const Footer = ({disabled, reset, wellsSelected}) => {
  return (
    <div
      style={{
        alignItems: "baseline",
        borderTop: "1px solid rgb(224, 224, 224)",
        display: "flex",
        flexBasis: "51px",
        flexShrink: "0",
        justifyContent: "space-between",
      }}
    >
      <Typography
        variant="body2"
        style={{
          color: colors.grey[600],
          fontWeight: "bold",
          margin: 16,
        }}
      >{`${wellsSelected.length} well${pluralize(
        wellsSelected,
      )} selected`}</Typography>
      <div style={{display: "flex", justifyContent: "flex-end"}}>
        <FormButton disabled={disabled} variant="contained" type="submit">
          Save Assignments
        </FormButton>
      </div>
    </div>
  );
};

export default Footer;
