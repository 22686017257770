import {connect} from "react-redux";
import {push} from "react-router-redux";
import React, {useState} from "react";
import Paper from "@material-ui/core/Paper";
import ReactList from "react-list";

import {eventCategory, eventAction} from "constants/analytics";
import {toggleWellSelected} from "actions/tagging";
import {sendAnalyticsEvent} from "components/analytics";

import {renderWellTable} from "./render-well-table";
import {renderWellRow} from "./render-well-row";
import {isSelectionMode} from "../../is-selection-mode";

const selectWell = ({dispatch, wellId}) => {
  dispatch(toggleWellSelected([wellId]));
};

const navigateToWell = ({dispatch, downholeLocation}) => {
  sendAnalyticsEvent(
    eventCategory.anomalyWorkflow,
    eventAction.wellList,
    "View Well",
  );
  dispatch(push(`/well/${encodeURIComponent(downholeLocation)}/analysis`));
};

const mapStateToProps = state => ({
  isSelectionMode: isSelectionMode(state),
});

const mapDispatchToProps = dispatch => ({
  handleWellRowClick: ({isSelectionMode, downholeLocation, wellId}) => () =>
    isSelectionMode
      ? selectWell({dispatch, wellId})
      : navigateToWell({dispatch, downholeLocation}),
});

const WellList = ({isSelectionMode, wells, handleWellRowClick}) => {
  const [currentHoverId, setCurrentHoverId] = useState(null);

  const itemsRenderer = renderWellTable({
    wellIds: wells.map(({wellId}) => wellId),
    isSelectionMode,
  });

  const itemRenderer = renderWellRow({
    handleWellRowClick: handleWellRowClick,
    isSelectionMode,
    wells,
    currentHoverId,
    setCurrentHoverId,
  });

  return (
    <Paper>
      <ReactList
        itemRenderer={itemRenderer}
        itemsRenderer={itemsRenderer}
        length={wells.length}
        type="simple"
      />
    </Paper>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WellList);
