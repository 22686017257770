import {compose} from "recompose";
import Grid from "@material-ui/core/Grid";
import Link from "react-router-dom/Link";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
  main: {
    padding: "0 15px 16px",
    fontWeight: 600,
    color: "#444",
  },

  fieldName: {
    color: "#666666",
    fontSize: "14px",
    fontWeight: "normal",
  },

  link: {
    color: "inherit",
    "&:hover": {
      textDecoration: "underline",
    },
  },

  title: {
    display: "flex",
    justifyContent: "space-between",
  },

  warning: {
    color: "rgb(255, 138, 101)",
    fontWeight: "normal",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
  },

  icon: {
    paddingRight: "5px",
  },
};

const redirectTo = downholeLocation =>
  `/well/${encodeURIComponent(downholeLocation)}/analysis`;

const enhance = compose(withStyles(styles));

const WellHeader = ({classes, wellName, fieldName, downholeLocation}) => (
  <Grid container direction="column" className={classes.main} spacing={1}>
    <Grid item className={classes.title}>
      <Link to={redirectTo(downholeLocation)} className={classes.link}>
        {wellName}
      </Link>
    </Grid>
    <Grid item className={classes.fieldName}>
      {fieldName}
    </Grid>
  </Grid>
);

export default enhance(WellHeader);
