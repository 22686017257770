import React from "react";
import clsx from "clsx";
import compose from "lodash/fp/compose";
import mapProps from "recompose/mapProps";
import withStyles from "@material-ui/core/styles/withStyles";

const SIZE_LIMIT = 1000;

const styles = theme => ({
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "60%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },

  title: {
    lineHeight: "1.5em",
    fontSize: "0.775rem",
    color: theme.palette.grey[700],

    [theme.breakpoints.up("xl")]: {
      fontSize: "0.875rem",
    },
  },

  size: {
    position: "relative",
    letterSpacing: "-.03125em",
    lineHeight: "1em",
    fontSize: "3.5rem",
    color: theme.palette.grey[600],

    [theme.breakpoints.up("xl")]: {
      fontSize: "4.5rem",
    },
  },

  sizeAdjustment: {
    fontSize: "3.75rem",
  },

  caption: {
    position: "absolute",
    top: "1rem",
    display: "inline-block",
    marginLeft: ".25rem",
    textAlign: "left",
    letterSpacing: "normal",
    lineHeight: "1.5em",
    fontSize: ".65rem",

    [theme.breakpoints.up("xl")]: {
      fontSize: ".75rem",
    },
  },
});

const toLabel = ({path, tree}) => {
  if (path) {
    return {
      title: path.titles.join(" / "),
      size: path.value,
      caption: ["out of", tree.value],
    };
  }

  return {
    title: tree.title,
    size: tree.value,
    caption: ["wells"],
  };
};

const enhance = compose(
  mapProps(toLabel),
  withStyles(styles),
);

export default enhance(({caption, classes, size, title}) => (
  <div className={classes.container}>
    <div className={classes.title}>{title}</div>
    <div
      className={clsx(classes.size, {
        [classes.sizeAdjustment]: size >= SIZE_LIMIT,
      })}
    >
      {size}
      <div className={classes.caption}>
        <em>
          {caption.reduce((acc, value, key) => [acc, <br key={key} />, value])}
        </em>
      </div>
    </div>
  </div>
));
