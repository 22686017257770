import {max as d3max} from "d3-array";
import {scaleLinear as d3scaleLinear} from "d3-scale";
import addArea from "utils/charts/add-area";
import addLine from "utils/charts/add-line";
import colors from "theme/colors";

function mapData(d) {
  return d.load || [];
}

function mapTime(d) {
  return new Date(d.createdOn);
}

function mapLoad(d) {
  return d.value;
}

function mapMaxLoad(d) {
  if (!d.max) {
    return mapLoad(d);
  }

  return d.max;
}

function mapMinLoad(d) {
  if (!d.min) {
    return mapLoad(d);
  }

  return d.min;
}

const styles = {
  area: {
    fill: colors.load[50],
  },

  line: {
    stroke: colors.load[400],
  },
};

export default ({clipPathId, selection, register, scales}) => {
  const loadScale = d3scaleLinear();
  const options = {
    clipPathId,
    mapData,
    mapX: mapTime,
    mapY: mapLoad,
    mapMaxY: mapMaxLoad,
    mapMinY: mapMinLoad,
    selection,
    xScale: scales.time,
    yScale: loadScale,
  };

  const area = addArea({...options, styles: styles.area});
  const line = addLine({...options, styles: styles.line});

  return register({
    draw(props) {
      const {config, data} = props;
      const maxLoad = d3max(mapData(data), mapMaxLoad);

      loadScale.domain([0, maxLoad * 1.1]).range([props.height, 0]);

      area.hide();
      line.hide();

      if (config.load && config.load.state > 0) {
        line.show();
      }

      if (config.load && config.load.state === 2) {
        area.show();
      }

      area.draw(props);
      line.draw(props);
    },

    zoom(props) {
      area.zoom(props);
      line.zoom(props);
    },
  });
};
