import all from "utils/validation/all";
import required from "utils/validation/required";
import copyValuesIfSet from "utils/validation/copy-values-if-set";
import hasGreaterEqualityError from "utils/validation/hasGreaterEqualityError";

export default values => {
  const requiredFields = [
    "plungerDiameter",
    "plungerLength",
    "plungerMass",
    "serviceFactor",
    {
      tubing: ["description"],
    },
  ];

  let errors = all(required, values, requiredFields);

  const lengthValidError = hasGreaterEqualityError(values.plungerLength, 0);
  if (lengthValidError && !errors["plungerLength"]) {
    errors.plungerLength = lengthValidError;
  }
  const massValidError = hasGreaterEqualityError(values.plungerMass, 0);
  if (massValidError && !errors["plungerMass"]) {
    errors.plungerMass = massValidError;
  }
  const diameterValidError = hasGreaterEqualityError(values.plungerDiameter, 0);
  if (diameterValidError && !errors["plungerDiameter"]) {
    errors.plungerDiameter = diameterValidError;
  }

  errors = copyValuesIfSet(errors, [{tubing: "tubingDescription"}]);

  return errors;
};
