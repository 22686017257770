import React from "react";

import FeedItem from "../feed-item";

export default props => {
  const {event} = props;

  return (
    <FeedItem event={event}>
      The ambyControl device is{" "}
      <strong>{event.data.disconnected ? "disconnected" : "connected"}</strong>.
    </FeedItem>
  );
};
