import React, {useEffect} from "react";
import clsx from "clsx";
import withStyles from "@material-ui/core/styles/withStyles";
import {getMiniCardById} from "utils/well/get-mini-card-by-id";

import Dynocard from "components/dynocard";
import {warmGrey} from "theme/updated-colors";

import {useCardData} from "./use-card-data";

const styles = () => ({
  hide: {
    opacity: 0,
  },
});

const HoverDynocard = ({
  isHovering,
  lastRegularCard,
  fetchMiniCardById,
  well,
  classes,
  className,
}) => {
  const card = getMiniCardById(well, lastRegularCard);
  useEffect(
    () => {
      if (!card) {
        fetchMiniCardById(well.downholeLocation, lastRegularCard);
      }
    },
    [lastRegularCard, fetchMiniCardById, card, well.downholeLocation],
  );
  const {surfaceData, downholeData} = useCardData(card);

  return card ? (
    <div className={clsx(className, !isHovering && classes.hide)}>
      <Dynocard
        surfaceData={surfaceData}
        downholeData={downholeData}
        hideAxis
        overrideColor={warmGrey[400]}
      />
    </div>
  ) : null;
};

export default withStyles(styles)(HoverDynocard);
