import compose from "recompose/compose";
import {connect} from "react-redux";
import Paper from "@material-ui/core/Paper";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import {ClickEvent} from "components/analytics";
import {eventAction, eventCategory} from "constants/analytics";
import {MAP_TYPES} from "constants/map-types";

import Map from "./map-container";

const styles = {
  paper: {
    position: "absolute",
    zIndex: 40,
    margin: 10,
    marginTop: 16,
    right: 0,
    padding: 2,
    backgroundColor: "white",
  },
  mapContainer: {
    width: 60,
    height: 60,
    overflow: "hidden",
    borderRadius: 2,
  },
  overlay: {
    position: "absolute",
    zIndex: 40,
    cursor: "pointer",
  },
};

const oppositeMapIndex = {
  [MAP_TYPES.roadmap]: MAP_TYPES.satellite,
  [MAP_TYPES.satellite]: MAP_TYPES.roadmap,
};

const MapTypeButtonGroup = ({mapType, onClick, classes, mapBounds}) => {
  const oppositeMap = oppositeMapIndex[mapType];
  return (
    <ClickEvent
      category={eventCategory.wellsDashboard}
      action={eventAction.changeMapType}
      label={oppositeMap}
    >
      <Paper
        className={classes.paper}
        onClick={() => onClick({mapType: oppositeMap})}
      >
        <div className={`${classes.mapContainer} ${classes.overlay}`} />
        <div className={classes.mapContainer}>
          <Map
            compact
            wells={[]}
            overrides={{
              mapBounds,
              disableDefaultUI: true,
              draggable: false,
              mapType: oppositeMap,
              withoutMarkers: true,
            }}
          />
        </div>
      </Paper>
    </ClickEvent>
  );
};

const mapStateToProps = ({wells}) => {
  return {mapBounds: wells.mapBounds};
};

const enhance = compose(
  connect(mapStateToProps),
  withStyles(styles),
);

export default enhance(MapTypeButtonGroup);
