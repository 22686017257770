const selectOrganizationUserDetails = (organizations, organizationsWells) => {
  const selectedOrganization =
    organizations.all[organizations.selectedOrganizationId];

  const organizationName = selectedOrganization
    ? selectedOrganization.organizationName
    : "";

  const organizationWells = selectedOrganization
    ? organizationsWells.perOrganization[organizations.selectedOrganizationId]
    : [];

  return {
    organizationName,
    organizationWells,
  };
};

export default selectOrganizationUserDetails;
