import get from "lodash/get";
import trim from "lodash/trim";
import upperFirst from "lodash/upperFirst";

const format = string => trim(upperFirst(string));
const formatInitial = string => string && `${format(string).charAt(0)}.`;

export default user => {
  user = get(user, "user", user) || {};

  let {firstName, lastName} = user;

  if (!firstName) return "Ambyint";

  firstName = format(firstName);
  lastName = formatInitial(lastName);

  return `${firstName}${lastName ? ` ${lastName}` : ""} (Ambyint)`;
};
