import {depth} from "@ambyint/common/unit-converter";
import getUser from "utils/get-user";

const processRodSections = (rodSections, surveyData) => {
  return rodSections.map(
    (
      {rodLength, rodQuantity, rodDimensions, rodType, guideQuantity},
      index,
      mappedRodSections,
    ) => {
      const depthReducer = (totals, {rodLength, rodQuantity}, index, src) => {
        const length = rodLength.value * rodQuantity;
        if (index < src.length - 1) totals.startMeasuredDepth += length;
        totals.endMeasuredDepth += length;
        return totals;
      };

      const interpolate = (p1, p2, percentage) => p1 + (p2 - p1) * percentage;
      const percentageIncrease = (p1, p2, pX) => (pX - p1) / (p2 - p1);
      const interpolatePoint = (points, measuredDepth) => {
        for (let i = 1; i < points.length; i++) {
          const p1 = points[i - 1];
          const p2 = points[i];

          const p1Depth = p1.measuredDepth;
          const p2Depth = p2.measuredDepth;

          if (measuredDepth >= p1Depth && measuredDepth <= p2Depth) {
            const percentage = percentageIncrease(
              p1Depth,
              p2Depth,
              measuredDepth,
            );
            return {
              x: interpolate(p1.x, p2.x, percentage),
              y: interpolate(p1.y, p2.y, percentage),
              z: interpolate(p1.z, p2.z, percentage),
              inclinationDegrees: interpolate(
                p1.inclinationDegrees,
                p2.inclinationDegrees,
                percentage,
              ),
              doglegSeverityDeg30m: interpolate(
                p1.doglegSeverityDeg30m,
                p2.doglegSeverityDeg30m,
                percentage,
              ),
              measuredDepth,
              trueVerticalDepth: interpolate(
                p1.trueVerticalDepth,
                p2.trueVerticalDepth,
                percentage,
              ),
            };
          }
        }
      };

      const {startMeasuredDepth, endMeasuredDepth} = mappedRodSections
        .slice(0, index + 1)
        .reduce(depthReducer, {startMeasuredDepth: 0, endMeasuredDepth: 0});

      const startPoint = interpolatePoint(surveyData, startMeasuredDepth);
      const endPoint = interpolatePoint(surveyData, endMeasuredDepth);

      const standardRodLength = depth.convert({
        value: 7.62,
        to: getUser().unitsOfMeasure,
        from: "m",
      });

      // hideSection is used to determine if we want to show this section on the wellbore survey view.
      // If the section is not Sinker bars and is the Polished rod or is a pony rod (less than 25ft long rods) then we want to hide this section.
      return {
        length: rodLength.value * rodQuantity,
        rodQuantity: parseInt(rodQuantity),
        rodLength: rodLength.value,
        rodType: rodType.type,
        diameter: rodDimensions.diameter.value,
        ...startPoint,
        endPoint,
        guideQuantity: parseInt(guideQuantity),
        hideSection:
          rodType.type !== "Sinker" &&
          (rodType.type === "Polished" || rodLength.value < standardRodLength),
      };
    },
  );
};

export default processRodSections;
