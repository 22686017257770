import {connect} from "react-redux";
import get from "lodash/get";
import Grid from "@material-ui/core/Grid";
import isEmpty from "lodash/isEmpty";
import React from "react";
import {reduxForm} from "redux-form";

import compose from "utils/compose";
import DecimalTextField from "components/forms/decimal-text-field";
import FormHeader from "components/form-header";
import getDefault from "utils/get-default-value";
import PaperContainer from "components/paper-container";
import SaveCancelButtons from "components/save-cancel-buttons";
import {setLufkinFillBase} from "actions/wells";
import validate from "utils/wells/lufkin-settings/validate-fill-base";

import getDisplayedSetting from "../get-displayed-setting";

const mapDispatchToProps = dispatch => ({
  save: (values, well, onClose) => {
    dispatch(
      setLufkinFillBase({
        values,
        well,
      }),
    );
    onClose();
  },
});

const mapStateToProps = (state, props) => {
  const {well} = props;

  const rootSetting = get(well, "lufkinSettings.fillBase");
  const setting = getDisplayedSetting(rootSetting);

  return {
    initialValues: {
      fillBase: isEmpty(setting) ? getDefault("%") : setting,
    },
  };
};

const FillBaseEditView = props => {
  const {
    handleSubmit,
    invalid,
    onClose,
    pristine,
    submitting,
    reset,
    save,
    well,
  } = props;

  return (
    <PaperContainer extraPadded style={{maxWidth: "750px"}}>
      <form onSubmit={handleSubmit(values => save(values, well, onClose))}>
        <Grid
          container
          direction={"row"}
          style={{minWidth: "400px"}}
          spacing={2}
        >
          <Grid item xs={12} style={{marginTop: "0px", marginBottom: "0px"}}>
            <FormHeader>Fill Base</FormHeader>
          </Grid>

          <Grid item xs={6} style={{padding: "8px"}}>
            <DecimalTextField
              fullWidth
              name={"fillBase"}
              label={"Fill Base"}
              required
            />
          </Grid>
        </Grid>

        <div style={{marginLeft: "8px"}}>
          <SaveCancelButtons
            invalid={invalid}
            pristine={pristine}
            reset={compose(
              onClose,
              reset,
            )}
            submitting={submitting}
          />
        </div>
      </form>
    </PaperContainer>
  );
};

const FormComponent = reduxForm({
  form: "lufkin-fill-base-edit-view",
  enableReinitialize: true,
  validate,
})(FillBaseEditView);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FormComponent);
