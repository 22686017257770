import {replace} from "react-router-redux";

import {createSwitchEpic} from "epics/create-epic";
import {types as organizationViewTypes} from "actions/organization-view";
import {setOrganization, types} from "actions/organizations";
import {show} from "actions/snackbar";
import fetch from "epics/async-fetch";

export default createSwitchEpic(
  // this is fetched twice for users with approver access
  [types.fetchOrganization, organizationViewTypes.set],
  async ({payload}) => {
    const organizationId =
      payload.organizationId || payload.currentOrganization;
    try {
      const data = await fetch(
        `/organization/${encodeURIComponent(organizationId)}`,
      );

      return [
        setOrganization({
          data,
          organizationId,
        }),
      ];
    } catch (err) {
      if (err.status === 401 || err.status === 403) {
        return [
          replace("/"),
          show({
            message: "Unauthorized to view organization",
          }),
        ];
      }
      return [replace(`/error?code=${err.status}`)];
    }
  },
);
