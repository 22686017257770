import difference from "lodash/difference";
import isEmpty from "lodash/isEmpty";
import Typography from "@material-ui/core/Typography";
import React from "react";

import getDisplayName from "utils/user/get-display-name";

import WellText from "../well-text";

const mapAssigned = ({previous, updated}) => difference(updated, previous);
const mapRemoved = ({previous, updated}) => difference(previous, updated);

const mapConjunctionText = ({data}) =>
  !isEmpty(mapAssigned(data)) && !isEmpty(mapRemoved(data)) && " and";

export default props => {
  const {event} = props;
  const {data, performedBy, performedOn} = event;

  return (
    <Typography variant="body1">
      <strong>{` ${getDisplayName(performedOn)}`}</strong>
      {" was"}
      <WellText event={event} type={"assigned"} wells={mapAssigned(data)} />
      {mapConjunctionText(event)}
      <WellText event={event} type={"removed"} wells={mapRemoved(data)} />
      {` by ${getDisplayName(performedBy)}`}
    </Typography>
  );
};
