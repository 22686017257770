import {types} from "./types";

export const fetchCardOverride = ({downholeLocation, cardId}) => {
  return {
    type: types.fetchCardOverride,
    payload: {
      downholeLocation,
      cardId,
    },
  };
};
