import React from "react";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

const MenuButton = ({className, onClick}) => (
  <IconButton className={className} color="inherit" onClick={onClick}>
    <MenuIcon />
  </IconButton>
);

export default MenuButton;
