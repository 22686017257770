import React from "react";
import {connect} from "react-redux";

import {hide} from "actions/well-summary-dialog";
import Dialog from "components/dialog";
import SelectedWell from "components/selected-well";
import findWell from "utils/find-well";

const modalBodyStyle = {
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  paddingBottom: "16px",
};

const mapStateToProps = state => {
  return {
    downholeLocation: state.wellSummaryDialog.downholeLocation,
    isOpen: state.wellSummaryDialog.isOpen,
    well: findWell(state.wells, state.wellSummaryDialog.downholeLocation),
  };
};

class WellSummaryDialog extends React.Component {
  componentWillReceiveProps(nextProps) {
    const {downholeLocation} = nextProps;

    if (!downholeLocation) return false;
  }

  render() {
    const {dispatch, isOpen, well} = this.props;

    if (!well || !well.parsed) return null;

    const hideDialog = () => dispatch(hide());

    return (
      <Dialog
        open={isOpen}
        onBackdropClick={hideDialog}
        maxWidth="md"
        fullWidth={true}
      >
        <div style={modalBodyStyle}>
          <SelectedWell hide={hideDialog} well={well} />
        </div>
      </Dialog>
    );
  }
}

export default connect(mapStateToProps)(WellSummaryDialog);
