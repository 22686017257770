import {useReducer} from "react";
import {sortBy, partition} from "lodash";
import {STATUS} from "constants/tagging";

const reducer = (state, action) => {
  const {type, payload} = action;
  const {tags} = state;

  // remove the existing tag from the tag collection used.
  const tagPartition = partition(tags, ({name}) => name === payload);
  const [[existingTag], unalteredTags] = tagPartition;

  switch (type) {
    case "add":
      if (existingTag) {
        return {
          ...state,
          tags: [
            ...unalteredTags,
            {...existingTag, name: payload, status: STATUS.newTag},
          ],
        };
      } else {
        return {
          ...state,
          tags: [...unalteredTags, {name: payload, status: STATUS.newTag}],
        };
      }
    case "remove": {
      // We cannot find target tag, abort
      if (!existingTag) return state;

      const additionWasPermanent =
        existingTag.permanent && existingTag.status === STATUS.newTag;
      const tagWasAlreadyRemoved = existingTag.status === STATUS.removedTag;

      if (additionWasPermanent || tagWasAlreadyRemoved) {
        // Toggle status back to 'currentTag' and push back into tags
        return {
          ...state,
          tags: [...unalteredTags, {...existingTag, status: STATUS.currentTag}],
        };
      } else if (existingTag.permanent) {
        // Toggle status to 'removedTag' and push back into tags
        return {
          ...state,
          tags: [...unalteredTags, {...existingTag, status: STATUS.removedTag}],
        };
      }

      // The tag was newly added and not permanent, keep it removed
      return {
        ...state,
        tags: unalteredTags,
      };
    }
    default:
      return state;
  }
};

/**
 * Manage a _local_ array of tags
 *
 * Actions are as follows:
 * - Queue a new tag to be added to the current tags
 * - Queue removal of a current or new tag from the list of tags
 *
 * This hook will track these actions as statuses that are appended
 * to this local collection of tags.
 *
 * Further actions taken as a result of these statuses should be
 * managed by the component using this hook.
 *
 * e.x. create a post request for all tags of status == newTag
 *
 * @param {array} currentTags a list of tags that are to be managed
 */
export const useTagsManagement = (currentTags = []) => {
  const [state, dispatch] = useReducer(reducer, {
    tags: currentTags.map(tag => ({
      ...tag,
      status: STATUS.currentTag,
      permanent: true,
    })),
  });

  const queueNewTag = tagName => dispatch({type: "add", payload: tagName});
  const removeTag = tagName => dispatch({type: "remove", payload: tagName});

  return {
    queueNewTag,
    tags: sortBy(
      state.tags.map(tag => ({
        ...tag,
        onDelete: () => removeTag(tag.name),
      })),
      "name",
    ),
  };
};
