import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import SvgIcon from "@material-ui/core/SvgIcon";
import withStyles from "@material-ui/core/styles/withStyles";
import colors from "@ambyint/common/colors";

const styles = () => ({
  root: {
    width: 100,
    height: "auto",
  },
  dark: {
    color: colors.darkGrey,
  },
  light: {
    color: "#fff",
  },
});

const AmbyintLogo = ({classes, className, variant, ...other}) => {
  return (
    <SvgIcon
      className={clsx(classes.root, classes[variant], className)}
      viewBox="0 0 637.57 129.88"
      {...other}
    >
      <g>
        <path
          fill="#F26822"
          d="M38.32,17.65a17.65,17.65,0,1,1,23.36,16.7C32.4,45.85,34.71,58.94,35.3,83A17.65,17.65,0,1,1,11.67,66.42C35.25,58,41.24,43.33,38.32,17.65"
        />
        <path d="M88,65.34A17.67,17.67,0,1,1,70.31,83,17.67,17.67,0,0,1,88,65.34" />
        <path d="M608.82,9h11.95V30.81h14.52V41.43H620.77V80.68c0,7.22,6.3,12.44,16.79,12.44q-.08,5.32-.16,10.65c-14.92.23-28.57-7.78-28.57-23.09,0-24.32,0-47.4,0-71.72" />
        <path d="M224.15,100.68h12V55.28c0-5.5,5.21-18.62,19.46-18.62,10.71,0,13.67,6.77,13.67,16.22v47.8h12V55.28c0-11.28,7.47-18.62,19-18.62s14.1,7.2,14.1,16.22v47.8h12V47.24c0-15.09-9.73-21.15-24.25-21.15A27,27,0,0,0,279.29,38.5c-3-8.89-11.15-12.41-20-12.41-10,0-18.19,4.23-23.55,12.41h-.28V27.78H224.15Z" />
        <path d="M344.86,100.68h12V91h.28c5.36,8.6,16.08,11.42,23.55,11.42C403,102.38,414,85,414,64.44s-11.14-38.35-33.56-38.35c-10,0-19.32,3.52-23.27,11.56h-.28V0h-12Zm56.4-37.08c0,14.24-5.49,28.2-21.85,28.2s-23-13.25-23-27.64c0-13.68,6.2-27.5,22.28-27.5,15.51,0,22.56,13.26,22.56,26.94" />
        <path d="M488.41,27.78H475.72L455.27,87.43H455L433.7,27.78H420.16l28.91,72.48-4.94,12.41c-2.25,4.23-4.94,6.63-9.87,6.63-2.4,0-4.79-1-7.05-1.69v11a24,24,0,0,0,8.32,1.27c10.71,0,15.65-5.22,21.15-19.46Z" />
        <rect x="499.27" y="27.78" width="11.99" height="72.9" />
        <path d="M529.87,100.68h12V59.51c0-13.11,7-22.85,21.58-22.85,9.16,0,14.81,5.79,14.81,14.67v49.35h12V52.74c0-15.65-5.92-26.65-25.1-26.65-10.43,0-18.61,4.23-23.69,13.25h-.28V27.78H529.87Z" />
        <path
          fill="#F26822"
          d="M504.8.39A8.57,8.57,0,1,1,496.23,9,8.57,8.57,0,0,1,504.8.39"
        />
        <path d="M148.16,70.44l-10.63,30.24H123.85L159.76,0h16.58l35.35,100.68H197.33L187,70.44Zm19.75-56.63L151.33,59.67h32Z" />
      </g>
    </SvgIcon>
  );
};

AmbyintLogo.propTypes = {
  variant: PropTypes.oneOf(["dark", "light"]),
};

AmbyintLogo.defaultProps = {
  variant: "light",
};

export default withStyles(styles)(AmbyintLogo);
