import {connect} from "react-redux";
import React from "react";
import compose from "recompose/compose";
import get from "lodash/get";
import last from "lodash/last";
import sortBy from "lodash/sortBy";
import Chip from "@material-ui/core/Chip";
import CloseCircleIcon from "mdi-material-ui/CloseCircle";
import withStyles from "@material-ui/core/styles/withStyles";

import {capitalizeEachWord} from "utils/capitalize-each-word";
import {ClickEvent} from "components/analytics";
import {colors} from "utils/well/status-colors";
import {deselectTag} from "actions/tagging";
import {eventCategory, eventAction} from "constants/analytics";
import {setActivePath} from "actions/wells";

const styles = ({spacing}) => ({
  root: {
    marginLeft: spacing(1) / 2,
    marginTop: spacing(1) / 2,
    marginBottom: spacing(1) / 2,
    fontSize: 14,
    height: spacing(3),
    "&:first-child": {
      marginLeft: spacing(1),
    },
    "& > span:last-child": {
      marginTop: 2,
    },
  },
  label: {
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
  },
  deleteIcon: {
    color: "inherit",
    opacity: 0.75,
    height: 20,
    width: 20,
    paddingLeft: spacing(1) / 2,
    "&:hover": {
      color: "inherit",
      opacity: 1,
    },
  },
});

const mapDispatchToProps = dispatch => {
  return {
    onDelete: filter => () => {
      switch (filter.type) {
        case "tag":
          return dispatch(deselectTag(filter.name));
        case "activePath":
          return dispatch(setActivePath());
        default:
          return;
      }
    },
  };
};

const DeleteIcon = ({onClick, className, label}) => {
  return (
    <ClickEvent
      category={eventCategory.tags}
      action={eventAction.filter}
      label={label}
    >
      <CloseCircleIcon className={className} onClick={onClick} />
    </ClickEvent>
  );
};

const withLabeling = tag => ({
  name: tag.name,
  label: capitalizeEachWord(tag.name),
  type: "tag",
  style: {
    color: "#6277BF",
    backgroundColor: "#E0E8F8",
  },
});

const mapStateToProps = ({wells, tags}) => {
  const titles = get(wells, ["workflow", "activePath", "titles"], []);

  const activePathColor = colors[titles[0]];

  const selectedTags = sortBy(tags.selectedTags.map(withLabeling), "name");
  const activePath = !!titles.length && {
    label: last(titles),
    type: "activePath",
    style: {
      color: activePathColor[700],
      backgroundColor: activePathColor[100],
    },
  };

  return {activePath, selectedTags};
};

const enhance = compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const ActiveFilters = ({classes, activePath, selectedTags, onDelete}) => {
  return (
    <div>
      {selectedTags.map(filter => (
        <Chip
          {...filter}
          key={filter.label}
          classes={classes}
          onDelete={onDelete(filter)}
          deleteIcon={<DeleteIcon onClick={onDelete} label={filter.label} />}
        />
      ))}
      {activePath && (
        <Chip
          {...activePath}
          key={activePath.label}
          classes={classes}
          onDelete={onDelete(activePath)}
        />
      )}
    </div>
  );
};

export default enhance(ActiveFilters);
