import React from "react";

export default WrappedComponent => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
      this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
      window.addEventListener("resize", this.handleChange);
      this.handleChange();
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.handleChange);
    }

    handleChange() {
      if (this.node.offsetWidth) {
        this.setState({
          width: this.node.offsetWidth,
        });
      }
    }

    render() {
      return (
        <div
          ref={node => {
            this.node = node;
          }}
        >
          {!!this.state.width && (
            <WrappedComponent {...this.props} {...this.state} />
          )}
        </div>
      );
    }
  };
};
