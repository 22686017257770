import {push} from "react-router-redux";
import {parse, stringify} from "query-string";
import merge from "lodash/merge";
import without from "lodash/without";

import {fetchComparison, types} from "actions/wells";
import {getDefaults, mapAnalysisToQuery} from "utils/wells/analysis";

export const viewFeedComparison = (action$, store) => {
  return action$
    .ofType(types.viewFeedComparison)
    .switchMap(({payload: {correlationId, well}}) => {
      const {downholeLocation} = well;
      const state = store.getState();

      const analysis = state.wells.analysis;

      const isInitialized = !!analysis[downholeLocation];

      const existing = analysis[downholeLocation] || getDefaults(well);

      const updated = {
        ...existing,
        comparisons: [
          ...without(existing.comparisons || [], correlationId),
          correlationId,
        ],
      };

      const hasComparison = (well.comparisons || []).some(
        comparison => comparison.correlationId === correlationId,
      );

      const analysisQueryString = mapAnalysisToQuery(updated);
      const combinedParams = stringify(
        merge(parse(state.routing.location.search), parse(analysisQueryString)),
      );

      return [
        push(
          `/well/${encodeURIComponent(
            downholeLocation,
          )}/analysis?${combinedParams}`,
        ),
        ...(isInitialized && !hasComparison
          ? [
              fetchComparison({
                downholeLocation: well.downholeLocation,
                correlationId,
              }),
            ]
          : []),
      ];
    });
};

export default viewFeedComparison;
