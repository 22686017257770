import React from "react";
import {connect} from "react-redux";
import {onlyUpdateForKeys, compose} from "recompose";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

import {FormatDateTime} from "components/format/date";

import {show} from "actions/recommendation-action-dialog";
import PaperContainer from "components/paper-container";

import getPrivileges from "utils/get-privileges";
import privilegeTypes from "utils/privilege-types";
import noop from "lodash/noop";

import {
  defaultContainerStyle,
  defaultGridContainer,
  defaultTitleStyle,
} from "./dashboard-styles";

const enhance = compose(
  connect(),
  onlyUpdateForKeys(["well"]),
);

const recommendationList = ({dispatch, well}, recommendations) => {
  if (!recommendations) return null;

  const privileges = well ? getPrivileges(well.organization.id) : [];
  const hasControl = privileges.includes(privilegeTypes.well.control);

  return (
    <List dense>
      {recommendations
        .sort((a, b) => a.createdOn < b.createdOn)
        .map(d => (
          <ListItem
            key={d.recommendationId}
            button={hasControl}
            onClick={
              hasControl
                ? () => dispatch(show({well, recommendation: d}))
                : noop
            }
          >
            <ListItemText
              primary={<FormatDateTime date={d.createdOn} />}
              secondary={d.description}
            />
          </ListItem>
        ))}
    </List>
  );
};

const activeRecommendationsDisplay = props => {
  const {well} = props;

  if (!well.recommendations || well.recommendations.length === 0) return null;

  const openRecommendations = well.recommendations.filter(
    ({status}) => status === "open",
  );

  if (openRecommendations.length === 0) return null;

  return (
    <PaperContainer style={defaultContainerStyle}>
      <div
        style={{...defaultGridContainer, ...{padding: "16px 16px 8px 16px"}}}
      >
        <Typography variant="body1" style={{...defaultTitleStyle}}>
          {well.recommendations ? well.recommendations.length : 0}{" "}
          Recommendations
        </Typography>
      </div>
      {recommendationList(props, openRecommendations)}
    </PaperContainer>
  );
};

export default enhance(activeRecommendationsDisplay);
