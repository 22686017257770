import MAX_DISPLAY_LENGTH from "constants/max-display-length";
import fetch from "epics/async-fetch";
import isWillyNilly from "utils/well/is-willy-nilly";

import required from "./required";
import willyNillyLocation from "./willy-nilly-location";

const doesExist = async downholeLocation => {
  const {exists} = await fetch(
    `/optimization/well/${encodeURIComponent(downholeLocation.trim())}/exists`,
  );

  return exists;
};

const validateUWIFormat = value => {
  return !/^1\d{2}\/\d{2}-\d{2}-\d{3}-\d{2}W\d\/\d{2}$|^2\d{2}\/[A-Z]-\d{3}-[A-Z]\/\d{3}-[A-Z]-\d{2}\/\d{2}$/.test(
    value ? value.toUpperCase() : "",
  );
};

const validateAPIFormat = value => {
  return !/\d{2}-\d{3}-\d{5}-\d{2}-\d{2}$/.test(value);
};

export default (country, bypassExists) => async value => {
  if (required(value)) return required(value);

  if (isWillyNilly(country) && willyNillyLocation(value))
    return willyNillyLocation(value);

  if (country === "canada" && validateUWIFormat(value.trim()))
    return "Must be in UWI format";

  if (country === "unitedstates" && validateAPIFormat(value.trim()))
    return "Must be in API format";

  if (value.length > MAX_DISPLAY_LENGTH)
    return `Downhole Location cannot exceed ${MAX_DISPLAY_LENGTH} characters`;

  if (!bypassExists && (await doesExist(value)))
    return "Downhole Location is already in use.";
};
