import {getUnits} from "@ambyint/common/utils/get-units";
import get from "lodash/get";

import convertToMetric from "utils/conversion/convert-to-metric";
import convertDensity from "utils/conversion/convert-density";
import convertHeight from "utils/conversion/convert-height";
import convertLength from "utils/conversion/convert-length";
import convertMass from "utils/conversion/convert-mass";
import omitDeepUndefined from "utils/omit-deep-undefined";
import spreadIf from "utils/spread-if";
import valueOf from "utils/value-of";

import parseSaveRodSection from "./parse-save-rod-section";

function parseSaveDownholeInformation(downholeInformation, unitsOfMeasure) {
  const units = getUnits(unitsOfMeasure);
  const toMetric = convertToMetric(units, unitsOfMeasure);
  const toMetricHeight = toMetric(convertHeight, {to: "m"});
  const toMetricLength = toMetric(convertLength, {to: "m"});
  const toMetricDensity = toMetric(convertDensity, {decimals: 2});
  const toMetricMass = toMetric(convertMass);

  return omitDeepUndefined({
    plunger: {
      diameter: valueOf(
        toMetricLength(get(downholeInformation, "plunger.diameter")),
      ),
      diameter_mm: valueOf(
        toMetricLength(get(downholeInformation, "plunger.diameter")),
      ),
      length: valueOf(
        toMetricHeight(get(downholeInformation, "plunger.length")),
      ),
      mass: valueOf(toMetricMass(get(downholeInformation, "plunger.mass"))),
    },
    ...spreadIf(downholeInformation.sections, {
      sections:
        downholeInformation.sections &&
        downholeInformation.sections.map(section =>
          parseSaveRodSection(section, unitsOfMeasure),
        ),
    }),
    serviceFactor: valueOf(get(downholeInformation, "serviceFactor")),
    tubing: {
      capacityM31000m: valueOf(
        get(downholeInformation, "tubing.capacityM31000m"),
      ),
      capacityMM3: valueOf(get(downholeInformation, "tubing.capacityMM3")),
      collapseMPA: valueOf(get(downholeInformation, "tubing.collapseMPA")),
      description: valueOf(get(downholeInformation, "tubing.description")),
      driftDiameter: valueOf(
        toMetricLength(get(downholeInformation, "tubing.driftDiameter")),
      ),
      grade: valueOf(get(downholeInformation, "tubing.grade")),
      insideDiameter: valueOf(
        toMetricLength(get(downholeInformation, "tubing.insideDiameter")),
      ),
      internalYieldPressure: valueOf(
        get(downholeInformation, "tubing.internalYieldPressure"),
      ),
      isPreferred: valueOf(get(downholeInformation, "tubing.isPreferred")),
      key: valueOf(get(downholeInformation, "tubing.key")),
      nominalWeight: valueOf(
        toMetricDensity(get(downholeInformation, "tubing.nominalWeight")),
      ),
      outsideDiameter: valueOf(
        toMetricLength(get(downholeInformation, "tubing.outsideDiameter")),
      ),
    },
  });
}

export default parseSaveDownholeInformation;
