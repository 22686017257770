/* eslint-disable jsx-a11y/anchor-is-valid */
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import React from "react";
import {reduxForm, formValueSelector} from "redux-form";
import Typography from "@material-ui/core/Typography";

import FormError from "components/form-error";
import FormButton from "components/form-button";
import LoginContainer from "routes/login/login-container";
import {navigateToLogin, requestPasswordResetEmail} from "actions/auth";
import styles from "routes/login/styles";
import TextField from "components/forms/text-field";

import validate from "./validate-password-reset";

const selector = formValueSelector("password-reset");
const mapStateToProps = (state, props) => {
  const {email} = props;

  return {
    auth: state.auth,
    initialValues: {
      email,
    },
    currentValues: {
      email: selector(state, "email"),
    },
  };
};

const PasswordReset = props => {
  const {auth, dirty, dispatch, handleSubmit, submitFailed} = props;

  const resetPassword = () =>
    handleSubmit(values => {
      dispatch(requestPasswordResetEmail({email: values.email}));
    })();

  return (
    <LoginContainer>
      <form style={styles.loginContainer}>
        <Grid container direction={"row"} spacing={2}>
          <Grid item xs={12}>
            <Typography style={{...styles.typography, fontWeight: "600"}}>
              Reset Password
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{...styles.typography}}>
              You&apos;ve forgotten your password? No problem, we&apos;ll send
              you an email with a link to reset it.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="email"
              autoComplete="email"
              autoFocus
              fullWidth
              showError={dirty && submitFailed}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  resetPassword();
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormButton
              variant={"contained"}
              center
              fullWidth
              style={{color: "white", height: "40px"}}
              onClick={resetPassword}
            >
              Send Password Reset Email
            </FormButton>
          </Grid>
          {auth.error && (
            <Grid item xs={12}>
              <FormError solid>{auth.error}</FormError>
            </Grid>
          )}
        </Grid>
      </form>
      <footer style={styles.footer}>
        Remember your password?{" "}
        <a style={styles.link} onClick={() => dispatch(navigateToLogin())}>
          Sign In
        </a>
      </footer>
    </LoginContainer>
  );
};

const Component = reduxForm({
  form: "password-reset",
  enableReinitialize: true,
  validate,
})(PasswordReset);

export default connect(mapStateToProps)(Component);
