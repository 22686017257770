import {compose} from "recompose";
import {connect} from "react-redux";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import {setViewType} from "actions/wells";

import SortOrderIconToggle from "./sort-order-icon-toggle";
import ViewToggleButton from "./view-toggle-button";
import SortColumnDropdown from "./sort-column-dropdown";

export const mapDispatchToProps = dispatch => ({
  setViewType: type => dispatch(setViewType(type)),
});

export const mapStateToProps = state => ({
  viewType: state.wells.viewType,
  auth: state.auth,
});

const styles = ({spacing}) => ({
  container: {
    display: "flex",
    minHeight: 45,
  },
  padding: {
    paddingRight: spacing(1),
  },
  scaledDown: {
    transform: "scale(0.8)",
    transformOrigin: "center right",
  },
});

export const ViewControls = ({classes, viewType, setViewType}) => {
  const isCard = viewType === "card";

  return (
    <div className={classes.scaledDown}>
      <span className={classes.container}>
        <SortColumnDropdown show={isCard} />
        <div className={classes.padding} />
        <SortOrderIconToggle show={isCard} />
        <div className={classes.padding} />
        <ViewToggleButton isList={!isCard} setViewType={setViewType} />
      </span>
    </div>
  );
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
)(ViewControls);
