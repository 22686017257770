import identity from "lodash/identity";

export const createMergeEpic = (types, handler) => (action$, store) => {
  return action$
    .ofType(...types)
    .mergeMap(action => handler(action, store))
    .switchMap(identity);
};

export const createSwitchEpic = (types, handler) => (action$, store) => {
  return action$
    .ofType(...types)
    .switchMap(action => handler(action, store))
    .switchMap(identity);
};
