import {
  validateLoad,
  validatePrimaryFillage,
  validatePosition,
  validateStrokes,
} from "./pumpoff-validations";

export default values => {
  return {
    ...validateLoad(values.acceptedData.load),
    ...validatePosition(values.acceptedData.position),
    ...validatePrimaryFillage(values.acceptedData.primaryFillage),
    ...validateStrokes(values.acceptedData.strokes),
  };
};
