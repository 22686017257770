import compose from "lodash/fp/compose";
import groupBy from "lodash/fp/groupBy";
import moment from "moment";
import orderBy from "lodash/fp/orderBy";
import reduce from "lodash/fp/reduce";
import toPairs from "lodash/fp/toPairs";
import uniqBy from "lodash/fp/uniqBy";

import {dailyProduction, height, load} from "@ambyint/common/unit-converter";

import {getProductionValues} from "utils/get-production-values";
import {createMergeEpic} from "epics/create-epic";
import {dailySummariesRetrieved, types} from "actions/deviation";
import {getWells} from "utils/wells/get-wells";
import {replace} from "actions/history/replace";

import fetch from "../async-fetch";

// If the value is null or undefined we need to send it back as null.
// The convert functions end up returning 0 which converts a missing value to a real value.
const convert = (value, convertFunc, to, from) => {
  return value === null || value === undefined
    ? null
    : convertFunc({value, to, from});
};

const convertMinutesToHours = ({value}) => value / 60;

// Pick out the properties we want from each summary and, if required, convert their values to user's preferred units.
const convertMetrics = unitsOfMeasure => summaries =>
  summaries.map(summary => {
    const {
      liquidProduction,
      runtime,
      calculatedFap,
      peakLoad,
      ...rest
    } = summary;

    return {
      ...rest,
      peakLoad: convert(peakLoad, load.convert, unitsOfMeasure, "n"),
      runtime: convert(runtime, convertMinutesToHours, unitsOfMeasure),
      calculatedFap: convert(
        calculatedFap,
        height.convert,
        unitsOfMeasure,
        types.metric,
      ),
      liquidProduction: convert(
        getProductionValues(summary, "totalLiquid").value,
        dailyProduction.convert,
        unitsOfMeasure,
        types.metric,
      ),
    };
  });

// Given a start and end date, go through the provided summary objects and find which days are missing a summary.
// For each missing day, insert a "blank" summary as a placeholder.
const fillSummaryRange = (start, end) => summariesForWell => {
  const mStart = moment(start)
    .utc()
    .startOf("day");
  const mEnd = moment(end)
    .utc()
    .startOf("day");
  const numDays = mEnd.diff(mStart, "days") + 1;
  const filled = Array(numDays).fill(undefined);

  // Place each summary we have in the right day;s location in the array.
  // When done there will be an undefined for any days we are missing summaries for.
  summariesForWell.forEach(summary => {
    const index = moment(summary.date.start)
      .utc()
      .startOf("day")
      .diff(mStart, "days");
    filled[index] = summary;
  });

  // Fill the array's empty spots with placeholder summaries for the day.
  return filled.map((el, index) => {
    if (el) {
      return el;
    } else {
      const dayToProcess = mStart.clone().add(index, "days");
      return {
        downholeLocation: summariesForWell[0].downholeLocation,
        wellId: summariesForWell[0].wellId,
        date: {
          start: dayToProcess.startOf("day").format(),
          end: dayToProcess.endOf("day").format(),
          formatted: dayToProcess.format("MMM DD, YYYY"),
        },
        peakLoad: null,
        runtime: null,
        calculatedFap: null,
        liquidProduction: null,
      };
    }
  });
};

// Given a list of well id's, create an object with each well id as a key and an empty array as a value.
const generateDefaultResponse = wellIdList =>
  reduce(
    (response, wellId) => ({
      ...response,
      [wellId]: [],
    }),
    {},
    wellIdList,
  );

export const fetchDailySummaries = createMergeEpic(
  [types.fetchDailySummaries],
  async ({payload}, store) => {
    const {wellList, start, end, metrics} = payload;
    const downholeList = getWells(wellList).map(
      ({downholeLocation}) => downholeLocation,
    );

    try {
      const dailySummaries = await fetch(
        "/daily-summaries",
        {},
        {
          method: "post",
          body: {
            start,
            end,
            wells: downholeList,
            metrics,
          },
        },
      );

      const prepareSummariesForWell = compose(
        fillSummaryRange(start, end),
        uniqBy("date.start"),
        orderBy(["date.start"], ["asc"]),
        convertMetrics(store.getState().auth.user.unitsOfMeasure),
      );

      // We need a summary for every day in the range, sorted from oldest to newest.
      // This creates a slot for each day, then either inserts a returned summary or a blank placeholder.
      const processSummaries = compose(
        reduce((result, [wellId, summariesForWell]) => {
          result[wellId] = prepareSummariesForWell(summariesForWell);
          return result;
        }, generateDefaultResponse(wellList)),
        toPairs,
        groupBy("wellId"),
      );

      return [dailySummariesRetrieved(processSummaries(dailySummaries))];
    } catch (err) {
      return [replace(`/error?code=${err.status}`)];
    }
  },
);
