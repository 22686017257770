import React from "react";
import {Field} from "redux-form";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

import TextFieldListMenu from "components/text-field-list-menu";
import withUnits from "utils/with-units";

import formatLabel from "./format-label";
import getValue from "./get-value";

export const ListMenu = props => {
  const hasError =
    (!!props.meta.dirty || !!props.meta.submitFailed) && !!props.meta.error;

  return (
    <FormControl
      fullWidth={props.fullWidth}
      style={props.style}
      error={hasError}
    >
      <TextFieldListMenu
        value={getValue(props)}
        label={formatLabel(props)}
        options={props.options}
        getValue={
          props.getValue ||
          (value => (value.value === undefined ? value : value.value))
        }
        disabled={props.disabled}
        onChange={value =>
          props.input.onChange(
            props.input.value.units
              ? withUnits(value, props.input.value.units)
              : value,
          )
        }
        touched={props.meta.touched}
        error={props.meta.error}
        formatDisplay={props.formatDisplay}
        hideEmptyOption={props.hideEmptyOption}
      />
      {hasError && <FormHelperText error>{props.meta.error}</FormHelperText>}
    </FormControl>
  );
};

export default props => {
  return <Field {...props} component={ListMenu} />;
};
