import {types} from "@ambyint/common/actions/wells";

import {show} from "actions/snackbar";
import fetch from "epics/fetch";

export default action$ => {
  return action$.ofType(types.RESET_FAULT).switchMap(({payload}) => {
    return fetch(
      `/optimization/well/${encodeURIComponent(
        payload.downholeLocation,
      )}/fault/reset`,
      {},
      {
        method: "POST",
        body: {
          downholeLocation: payload.downholeLocation,
        },
      },
    ).map(() => {
      return show({
        message: `Your request to reset faults for ${
          payload.well.displayName
        } submitted successfully!`,
      });
    });
  });
};
