import FilterableSelect from "components/filterable-select";
import Field from "components/react-redux-form/field";
import TextField from "components/react-redux-form/text-field";
import React from "react";
import {connect} from "react-redux";

import getModelPath from "../get-model-path";

const LABEL = "Field Name";

const mapFieldsToProps = state => {
  return {
    fieldNames: state.fieldNames.values,
  };
};

let InputFieldSelect = props => {
  const {fieldNames, onChange, value} = props;

  return (
    <FilterableSelect
      fullWidth
      id="field-name-select"
      items={fieldNames}
      label={LABEL}
      onSelect={onChange}
      required
      value={value}
    />
  );
};

InputFieldSelect = connect(mapFieldsToProps)(InputFieldSelect);

const InputFieldText = props => {
  return <TextField {...props} fullWidth label={LABEL} required />;
};

export default props => {
  const {shouldSelectFieldName} = props;

  const Input = shouldSelectFieldName ? InputFieldSelect : InputFieldText;

  return <Field component={Input} modelPath={getModelPath("fieldName")} />;
};
