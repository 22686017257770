import React from "react";
import {reduxForm} from "redux-form";
import {connect} from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import {changePassword as changePasswordAction} from "actions/auth";

import FormButton from "components/form-button";
import FormError from "components/form-error";
import PasswordTextField from "components/forms/password-text-field";

import LoginContainer from "routes/login/login-container";

import Footer from "routes/login/footer";
import styles from "routes/login/styles";

import validate from "./validate-password-reset-change-password";

const mapStateToProps = state => ({
  auth: state.auth,
});

const PasswordResetChangePassword = props => {
  const {
    auth,
    dirty,
    dispatch,
    handleSubmit,
    match: {
      params: {passwordResetCode},
    },
    submitFailed,
  } = props;

  const changePassword = () =>
    handleSubmit(values => {
      dispatch(
        changePasswordAction({passwordResetCode, password: values.password}),
      );
    })();

  return (
    <LoginContainer>
      <form style={styles.loginContainer}>
        <Grid container direction={"row"} spacing={2}>
          <Grid item xs={12}>
            <Typography style={{...styles.typography, fontWeight: "600"}}>
              Create Password
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{...styles.typography}}>
              Your password needs to be at least 8 characters. Longer passwords
              are much more secure, you can also add numeral and special
              characters to help with security.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <PasswordTextField
              name="password"
              label="New Password"
              fullWidth
              autoFocus
              showError={dirty && submitFailed}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  changePassword();
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordTextField
              name="confirmPassword"
              label="Confirm New Password"
              fullWidth
              showError={dirty && submitFailed}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  changePassword();
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormButton
              variant={"contained"}
              center
              fullWidth
              style={{height: "40px", color: "white"}}
              onClick={changePassword}
            >
              Update Password
            </FormButton>
          </Grid>
          {auth.error && (
            <Grid item xs={12}>
              <FormError solid>{auth.error}</FormError>
            </Grid>
          )}
        </Grid>
      </form>
      <Footer />
    </LoginContainer>
  );
};

const Component = reduxForm({
  form: "password-reset-change-password",
  enableReinitialize: true,
  validate,
})(PasswordResetChangePassword);

export default connect(mapStateToProps)(Component);
