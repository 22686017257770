import isFinite from "lodash/isFinite";

export default ({values, reverse}) => {
  return values.sort(function(a, b) {
    let aV =
      a.conditions.speed && isFinite(a.conditions.speed.value)
        ? a.conditions.speed.value
        : -1;
    let bV =
      b.conditions.speed && isFinite(b.conditions.speed.value)
        ? b.conditions.speed.value
        : -1;

    if (aV === bV) {
      aV = a.displayName.toLowerCase();
      bV = b.displayName.toLowerCase();
    }

    return reverse * ((aV > bV) - (bV > aV));
  });
};
