import addLine from "utils/charts/add-line";
import colors from "theme/colors";

function mapData(d) {
  return d.leakPressure || [];
}

function mapTime(d) {
  return new Date(d.createdOn);
}

function mapLeak(d) {
  return d.value;
}

export default ({clipPathId, selection, register, scales}) => {
  const options = {
    clipPathId,
    mapData,
    mapX: mapTime,
    mapY: mapLeak,
    selection,
    styles: {
      stroke: colors.leakPressure[400],
    },
    xScale: scales.time,
    yScale: scales.kpa,
  };

  const line = addLine(options);

  return register({
    draw(props) {
      const {config} = props;

      line.hide();
      if (config.leakPressure && config.leakPressure.state > 0) {
        line.show();
      }

      line.draw(props);
    },

    zoom(props) {
      line.zoom(props);
    },
  });
};
