import Grid from "@material-ui/core/Grid";
import React from "react";
import Typography from "@material-ui/core/Typography";

export const Header = props => {
  const {label} = props;

  return (
    <Grid item style={{marginBottom: "16px"}} xs={12}>
      <Typography variant="subtitle1">{label}</Typography>
    </Grid>
  );
};
