/**
 * Performs a deep immutable update on an object.
 * Returns a new object, preserving state of old object that is outside the path.
 * Designed to make Redux state changes easier without mutating unchanged parts of state.
 *
 * This is an alternative to: https://github.com/kolodny/immutability-helper
 * It has less features currently, but the array-based paths and the ability to do more concise filtering in paths can be very useful.
 *
 * @example
 *
 *    const state = {
 *      organization: {
 *        user: {
 *          accessLevel: "Full",
 *          isUserApprover: false,
 *        },
 *      },
 *    };
 *
 *    updateDeep(state, ["organization", "user"], user => ({
 *      ...user,
 *      isUserApprover: true,
 *    }));
 *
 * @param {Object} object - A plain JSON object to perform an immutable update on. Typically a Redux state object.
 * @param {String,Array} path - A set of indices or filter functions that describe how to access part of the object tree. Period-delimited strings can be used for simple indices, or an array can be used for doing filter commands.
 * @param {Func,String,Boolean,Number} update - A primite or an update function that updates the node in the object that is accessed through the path variable.
 */

const updateSelectedObject = (object, path, update) => {
  if (update.constructor === Function) {
    return update(object);
  }

  return update;
};

const splitPath = path => {
  let currentPath;
  let restOfPath;

  if (path.constructor === String) {
    [currentPath, ...restOfPath] = path.split(".");
    restOfPath = restOfPath.join(".");
  } else {
    [currentPath, ...restOfPath] = path;
  }

  return [currentPath, restOfPath];
};

const drillDeeper = (object, path, update) => {
  const [currentPath, restOfPath] = splitPath(path);

  if (currentPath.constructor === Function) {
    // Functions in path are only meant for arrays or other mappable objects
    if (object && object.map) {
      return object.map(item =>
        currentPath(item) ? updateDeep(item, restOfPath, update) : item,
      );
    }
  } else if (currentPath.constructor === String) {
    const result = object[currentPath];
    return {
      ...object,
      [currentPath]: updateDeep(result, restOfPath, update),
    };
  }

  console.error(
    "Unable to use path in object: unsupported object and path type",
    currentPath,
    object,
  );
  return object;
};

const updateDeep = (object, path, update) => {
  if (object === null || object === undefined) {
    return object;
  }

  if (!path || path.length === 0 || path === "") {
    return updateSelectedObject(object, path, update);
  }

  return drillDeeper(object, path, update);
};

export default updateDeep;
