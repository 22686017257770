import orderBy from "lodash/orderBy";

const filter = (alerts, downholeLocation) =>
  alerts.filter(alert => {
    if (!alert.information || !alert.information.downholeLocation) return false;
    return alert.information.downholeLocation === downholeLocation;
  });

const sort = alerts =>
  orderBy(alerts, [alert => new Date(alert.createdOn)], ["desc", "asc"]);

export default (alerts, downholeLocation) => {
  if (!alerts) return [];

  return sort(filter(alerts, downholeLocation));
};
