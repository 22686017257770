import defaults from "./well-health-defaults";

const setWellId = ({state, action}) => {
  const {wellId} = action.payload;

  // Do not reset the quality as it's data only needs to be re-fetched when changing orgs.
  return {
    ...defaults,
    dataForWellId: wellId,
    quality: {
      ...state.quality,
    },
  };
};

export default setWellId;
