import updateAndMergeAlert from "./update-and-merge-alert";

export default ({state, action}) => {
  const {timeline} = action.payload;
  const all = updateAndMergeAlert(state, action, alert => ({
    ...alert,
    timeline,
  }));

  return {
    ...state,
    all,
  };
};
