import {types} from "./types";

export const fetchRankings = downholeLocations => {
  return {
    type: types.fetchRankings,
    payload: {
      downholeLocations,
    },
  };
};
