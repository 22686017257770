import Grid from "@material-ui/core/Grid";
import math from "mathjs";
import React from "react";

import Analysis from "./analysis";
import Item from "./item";

export default ({data}) => {
  return (
    <Analysis title="Fluid Level Analysis">
      <Grid container>
        <Grid item xs={6}>
          <Item
            title="Fluid Level MD"
            value={`${math.round(data.deadOilLevelMeasuredDepth.value, 2)} ${
              data.deadOilLevelMeasuredDepth.units
            }`}
          />
          <Item
            title="Fluid Above Pump"
            value={`${math.round(data.deadOilHeightAbovePump.value, 2)} ${
              data.deadOilHeightAbovePump.units
            }`}
          />
        </Grid>
        <Grid item xs={6}>
          <Item
            title="PIP"
            value={`${math.round(data.pumpIntakePressure.value)} ${
              data.pumpIntakePressure.units
            }`}
          />
          <Item
            title="PBHP"
            value={`${math.round(data.producingBottomholePressure.value)} ${
              data.producingBottomholePressure.units
            }`}
          />
        </Grid>
      </Grid>
    </Analysis>
  );
};
