export const types = {
  hide: "resolve-alert-dialog:hide",
  show: "resolve-alert-dialog:show",
};

export const hide = () => {
  return {
    type: types.hide,
    payload: {
      isOpen: false,
    },
  };
};

export const show = ({alert}) => {
  return {
    type: types.show,
    payload: {
      alert,
      isOpen: true,
    },
  };
};
