import React from "react";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import {connect} from "react-redux";

import {toggleSearchDialog} from "actions/search";
import {eventAction, eventCategory} from "constants/analytics";
import {ClickEvent} from "components/analytics";

const mapDispatchToProps = dispatch => ({
  onClick: () => dispatch(toggleSearchDialog()),
});

const SearchButton = ({onClick}) => (
  <ClickEvent
    category={eventCategory.appBar}
    action={eventAction.click}
    label="Search Button"
  >
    <IconButton onClick={onClick}>
      <SearchIcon style={{color: "#fff"}} />
    </IconButton>
  </ClickEvent>
);

export default connect(
  null,
  mapDispatchToProps,
)(SearchButton);
