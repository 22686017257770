export default ({state, action}) => {
  const wellId = state.byDownholeLocation[action.payload.downholeLocation];

  const existing = state.byWellId[wellId];

  const updated = {
    ...existing,
    showRawComparisons: !existing.showRawComparisons,
  };

  const byWellId = {
    ...state.byWellId,
    [wellId]: updated,
  };

  return {
    ...state,
    byWellId,
  };
};
