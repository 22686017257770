import {connect} from "react-redux";
import clsx from "clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import startCase from "lodash/startCase";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import {deselectTag, selectTag} from "actions/tagging";

const useStyles = makeStyles(({colors, spacing}) => ({
  tag: {
    backgroundColor: colors.warmGrey[50],
    color: colors.warmGrey[600],
    width: "fit-content",
    height: "fit-content",
    margin: spacing(0, 1, 2, 0),
    borderRadius: 20,
    padding: [[6, 12]],
    cursor: "default",
    "&.selected": {
      backgroundColor: colors.blue[50],
      color: colors.blue[900],
    },
    "&:hover": {
      backgroundColor: colors.blue[100],
      color: colors.blue[900],
    },
    "&:active": {
      backgroundColor: colors.blue[200],
    },
  },
}));

const mapDispatchToProps = dispatch => {
  return {
    onClick: (name, clicked) => e => {
      e.preventDefault();
      if (clicked) {
        dispatch(deselectTag(name));
      } else {
        dispatch(selectTag(name));
      }
    },
  };
};

const Tag = ({matches, name, selected, onClick}) => {
  const classes = useStyles();

  return (
    <Tooltip
      placement="bottom"
      title={`Linked to ${matches} ${matches === 1 ? "well" : "wells"}`}
    >
      <div
        onClick={onClick(name, selected)}
        className={clsx(classes.tag, selected && "selected")}
      >
        <Typography variant="body2">{startCase(name.toLowerCase())}</Typography>
      </div>
    </Tooltip>
  );
};

export default connect(
  null,
  mapDispatchToProps,
)(Tag);
