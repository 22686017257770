import {types} from "actions/app-bar";
import {SEARCH_TYPES} from "constants/search-types";
import {reducerFactory} from "reducers/reducer-factory";

import {setSearchComponent} from "./set-search-component";

const defaults = {
  searchComponent: SEARCH_TYPES.none,
  ignoreReset: true,
};

const actionMap = {
  [types.setSearchComponent]: setSearchComponent,
};

export default reducerFactory(actionMap, defaults);
