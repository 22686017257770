import {createMergeEpic} from "epics/create-epic";
import fetch from "epics/async-fetch";
import getUser from "utils/get-user";
import {types} from "actions/wells";
import {show} from "actions/snackbar";

import onUpdateSuccess from "../on-update-success";
import {parse} from "./parse";

export const setLufkinRodString = createMergeEpic(
  [types.setLufkinRodString],
  async ({payload}) => {
    try {
      const {unitsOfMeasure} = getUser();
      const {values, well} = payload;

      await fetch(
        `/wells/${encodeURIComponent(
          well.downholeLocation,
        )}/lufkin/settings/rod-string`,
        {},
        {
          method: "POST",
          body: {sections: parse({sections: values, unitsOfMeasure})},
        },
      );

      return onUpdateSuccess({
        well,
        updatePath: "rodString.request",
        payload: values,
      });
    } catch (err) {
      return [
        show({
          message:
            "An error occurred while attempting to update the Lufkin device.",
        }),
      ];
    }
  },
);

export default setLufkinRodString;
