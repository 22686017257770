import FilterableSelect from "components/filterable-select";
import Field from "components/react-redux-form/field";
import countries from "constants/countries";
import provinces from "constants/states-provinces";
import get from "lodash/get";
import React from "react";
import {connect} from "react-redux";

import getModelPath from "../get-model-path";

const DEFAULT_PROVINCE_LABEL = "State/Province";

const mapStateToProps = state => {
  const wellCountry = get(state, getModelPath("wellCountry"));

  const countryDetails = countries.find(
    country => country.value === wellCountry,
  );

  return {
    label: countryDetails
      ? countryDetails.divisionLabel
      : DEFAULT_PROVINCE_LABEL,
    provinces: provinces[wellCountry] || [],
  };
};

const Input = props => {
  const {label, onChange, provinces, value} = props;

  return (
    <FilterableSelect
      fullWidth
      id="state-province-select"
      items={provinces}
      label={label}
      mapLabel={d => d.text}
      mapValue={d => d.value}
      onSelect={value => onChange(value && value.value)}
      required
      value={provinces.find(province => province.value === value)}
    />
  );
};

export default () => {
  return (
    <Field
      component={connect(mapStateToProps)(Input)}
      modelPath={getModelPath("wellProvinceState")}
    />
  );
};
