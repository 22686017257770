import assign from "lodash/assign";

/**
 * For each key in object, duplicate the value for every mapping found
 * in copyFromAndToKeys.
 *
 * The main use case for this is to duplicate errors for multiple field
 * names. For example, if you have a tubing object that is invalid, but in the UI the tubing is selected through a search box that has the key "tubingDescription", you can use this method to copy the "tubing" errors to "tubingDescription" as well, to show the error in the appropriate UI control.
 *
 * @param {Object} object - The object to duplicate values from for mapped keys. E.g. { "tubing": { ... } }
 * @param {Array} copyFromAndToKeys - An array of objects, where each object has one key. The key is a key expected normally in the object. The value is the key you would like to duplicate the original key's value to. It is an array of objects in case you want to duplicate the same key multiple times. E.g. [{ "tubing": "tubingDescription" }]
 */
const copyValueIfSet = (object, copyFromAndToKeys) => {
  const keysThatAreSet = copyFromAndToKeys.filter(keyPair => {
    const key = Object.keys(keyPair)[0];
    return object[key] !== undefined && object[key] !== null;
  });

  const copiedKeys = assign.apply(
    this,
    keysThatAreSet.map(keyPair => {
      const key = Object.keys(keyPair)[0];
      return {[keyPair[key]]: object[key]};
    }),
  );

  const result = {
    ...object,
    ...copiedKeys,
  };

  return result;
};

export default copyValueIfSet;
