import {types} from "./types";

export const fetchSetpointChanges = (
  wellId,
  downholeLocation,
  start = undefined,
  end = undefined,
  events = undefined,
) => {
  return {
    type: types.fetchSetpointChanges,
    payload: {
      wellId,
      downholeLocation,
      start,
      end,
      events,
    },
  };
};
