import {types} from "./types";

export const setFocus = (graph, focus) => {
  return {
    type: types.setFocus,
    payload: {
      graph,
      focus,
    },
  };
};
