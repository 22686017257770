import {axisBottom as d3axisBottom} from "d3-axis";
import {timeDay as d3timeDay} from "d3-time";

import {multiFormat} from "./time-format";

export default ({selection, register, scales}) => {
  const g = selection.append("g");

  const axis = d3axisBottom()
    .scale(scales.time)
    .ticks(d3timeDay.every(1))
    .tickFormat(multiFormat);

  register({
    draw({height, width}) {
      axis.ticks(Math.floor(width / (80 * 2)));
      g.attr("transform", `translate(0, ${height})`);
      g.call(axis);
    },

    zoom({xScale}) {
      axis.scale(xScale);
      g.call(axis);
    },
  });
};
