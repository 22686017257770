import {empty} from "rxjs/observable/empty";
import {of} from "rxjs/observable/of";

import {completedSignIn, types} from "actions/auth";

export default (action$, store) => {
  return action$.ofType(types.set).mergeMap(() => {
    const auth = store.getState().auth;
    if (auth.isCompletingSignIn && !auth.completedSignIn) {
      return of(completedSignIn());
    }

    return empty();
  });
};
