import Grid from "@material-ui/core/Grid";
import React from "react";
import {connect} from "react-redux";
import {Form} from "react-redux-form";

import formPath from "../form-path";
import handleBack from "../handle-back";
import handleLabel from "../handle-label";
import handleNext from "../handle-next";
import InputFieldName from "./input-field-name";
import InputLatitude from "./input-latitude";
import InputLongitude from "./input-longitude";
import InputSurfaceLocation from "./input-surface-location";
import validate from "./validate";

const mapStateToProps = state => {
  return {
    inCanada: state.forms.addWell.wellCountry === "canada",
  };
};

let Location = props => {
  const {inCanada} = props;

  return (
    <Form model={formPath} onChange={validate}>
      <Grid container direction="row" spacing={2}>
        {inCanada && (
          <Grid item xs={6}>
            <InputSurfaceLocation />
          </Grid>
        )}
        <Grid item xs={6}>
          <InputFieldName shouldSelectFieldName={inCanada} />
        </Grid>
        <Grid item xs={6}>
          <InputLatitude />
        </Grid>
        <Grid item xs={6}>
          <InputLongitude />
        </Grid>
      </Grid>
    </Form>
  );
};

Location = connect(mapStateToProps)(Location);

export default {
  content: <Location />,
  handleBack,
  handleLabel,
  handleNext: handleNext(validate),
  label: "Location",
};
