import colors from "theme/colors";
import {USER_SHAPE} from "constants/icon-paths";

const dimensions = {width: 16, height: 16};

function mapData(d) {
  return [
    ...(d.allowedToOperate || []),
    ...(d.deviceAssigned || []),
    ...(d.deviceRemoved || []),
    ...(d.downholeInformation || []),
    ...(d.manualFaultReset || []),
    ...(d.motorDetails || []),
    ...(d.pumpingUnit || []),
    ...(d.reservoir || []),
    ...(d.resistance || []),
    ...(d.scheduledDowntime || []),
    ...(d.speedProfile || []),
    ...(d.speedRange || []),
    ...(d.totalReductionRatio || []),
    ...(d.wellAnalogInputChanged || []),
    ...(d.wellCurrentViolation || []),
    ...(d.wellPumpOffSettingChanged || []),
    ...(d.wellResetMalfunctions || []),
    ...(d.wellRunTimer || []),
    ...(d.wellTorqueViolation || []),
    ...(d.wellbore || []),
  ];
}

function mapTime(d) {
  return new Date(d.createdOn);
}

export default ({selection, register, scales}) => {
  const group = selection.append("g");

  const setPositionAndSize = scaleX => d => {
    const x = scaleX(mapTime(d)) - Math.floor(dimensions.width / 2) - 3;
    const y = scales.status(6) - Math.floor(dimensions.height / 2);
    return `translate(${x}, ${y})`;
  };

  return register({
    draw(props) {
      const visibility =
        props.config.userActions && props.config.userActions.state === 0
          ? "hidden"
          : "visible";

      const paths = group.selectAll("path").data(mapData(props.data));
      paths.exit().remove();
      paths.merge(paths).style("visibility", visibility);

      paths
        .enter()
        .append("path")
        .style("visibility", visibility)
        .style("stroke", colors.purple[400])
        .style("fill", colors.transparent)
        .style("stroke-width", 3)
        .attr("transform", setPositionAndSize(scales.time))
        .attr("d", USER_SHAPE);
    },

    zoom({transform}) {
      group
        .selectAll("path")
        .style("stroke", colors.purple[400])
        .attr("transform", setPositionAndSize(transform.rescaleX(scales.time)))
        .attr("d", USER_SHAPE);
    },
  });
};
