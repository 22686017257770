import React from "react";
import Typography from "@material-ui/core/Typography/Typography";
import withstyles from "@material-ui/core/styles/withStyles";

const styles = ({colors}) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: colors.warmGrey[500],
    margin: 20,
  },
});

const EmptyStateTwo = ({classes}) => {
  return (
    <div className={classes.container}>
      <Typography color="inherit" variant="subtitle2">
        WooHoo! Looks like everything has been taken care of.
      </Typography>
    </div>
  );
};

export default withstyles(styles)(EmptyStateTwo);
