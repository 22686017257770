import Contrast from "mdi-material-ui/Contrast";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import Title from "components/typography/title";
import TooltipChip from "components/tooltip-chip";

import SortMenu from "../sort-menu";

const styles = ({shape, spacing, typography}) => ({
  root: {
    marginTop: "30px",
    marginBottom: spacing(2),
    display: "flex",
    alignItems: "center",
  },
  title: {
    marginLeft: spacing(2),
    marginRight: spacing(1),
    display: "flex",
    alignItems: "center",
  },
  count: {
    marginRight: "auto",
  },
  chip: {
    height: 24,
    borderRadius: shape.borderRadius * 2,
    fontSize: typography.caption.fontSize,
  },
  icon: {
    height: "16px",
    verticalAlign: "middle",
  },
  percent: {
    fontSize: "0.8em",
    marginLeft: "2px",
  },
  note: {
    fontSize: "0.7em",
    color: "#666",
    marginLeft: "8px",
  },
});

const WellReportHeader = ({classes, title, count, showSort, threshold}) => (
  <div className={classes.root}>
    {threshold !== undefined ? (
      <Title className={classes.title}>
        {title}
        <div className={classes.note}>
          (greater than or equal to
          <Contrast className={classes.icon} />
          {threshold}
          <span className={classes.percent}>%</span>)
        </div>
      </Title>
    ) : (
      <Title className={classes.title}>{title}</Title>
    )}
    <TooltipChip tooltip="Filtered count" label={count} />
    {showSort ? <SortMenu /> : null}
  </div>
);

export default withStyles(styles)(WellReportHeader);
