import get from "lodash/get";

const dataToPoint = (data, keys, index) => {
  return keys.reduce((point, key) => {
    return {
      ...point,
      [key]: get(data[key], index, null),
    };
  }, {});
};

export default dataToPoint;
