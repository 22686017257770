import Aux from "components/aux";
import isEmpty from "lodash/isEmpty";
import lowercase from "lodash/lowerCase";
import Typography from "@material-ui/core/Typography";
import React from "react";

import getDisplayName from "utils/user/get-display-name";

import WellText from "../well-text";

export default props => {
  const {event} = props;
  const {accessLevel, assignedWells, isInvitedUserApprover} = event.performedOn;

  return (
    <Aux>
      <Typography variant="body1">
        <strong>
          {getDisplayName(event.performedOn)} ({event.performedOn.userLogin})
        </strong>
        {" was invited by"}
        {` ${getDisplayName(event.performedBy)}`}
        {!isEmpty(assignedWells) && " and"}
        {!isEmpty(assignedWells) && (
          <WellText event={event} type={"assigned"} wells={assignedWells} />
        )}
        {` with ${lowercase(accessLevel)} well control access`}
        {` and ${isInvitedUserApprover ? "full" : "no"} approver status`}
      </Typography>
    </Aux>
  );
};
