import React from "react";
import {connect} from "react-redux";

import {hasLufkin2} from "@ambyint/common/utils/devices/has-lufkin-2";
import hasDriveType from "@ambyint/common/utils/devices/has-drive-type";
import hasLufkin from "@ambyint/common/utils/devices/has-lufkin";
import hasSmarten from "@ambyint/common/utils/devices/has-smarten";
import hasWellPilot from "@ambyint/common/utils/devices/has-well-pilot";
import CardContent from "@material-ui/core/CardContent";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";

import {savePumpoffSettings} from "actions/wells";

import FormHeader from "components/form-header";
import PaperContainer from "components/paper-container";

import noop from "utils/noop";
import PUMPOFF_SETTING_MODES from "constants/pumpoff-setting-modes";

import DownholeGenericView from "./downhole-generic";
import SmartenView from "./smarten";
import SurfaceLufkinView from "./surface-lufkin";
import WellPilotView from "./wellpilot";

const standardModes = [
  {
    label: "Surface",
    value: PUMPOFF_SETTING_MODES.STANDARD_SURFACE,
  },
  {
    label: "Downhole",
    value: PUMPOFF_SETTING_MODES.STANDARD_DOWNHOLE,
  },
];

const lufkinSpecific = [
  {
    label: "VFD Surface",
    value: PUMPOFF_SETTING_MODES.VFD_SURFACE,
  },
  {
    label: "VFD Downhole",
    value: PUMPOFF_SETTING_MODES.VFD_DOWNHOLE,
  },
];

const getView = props => {
  const {mode, well} = props;

  if (hasLufkin(well.devices) || hasLufkin2(well.devices)) {
    if (
      mode === PUMPOFF_SETTING_MODES.STANDARD_SURFACE ||
      mode === PUMPOFF_SETTING_MODES.VFD_SURFACE
    ) {
      return <SurfaceLufkinView {...props} />;
    }

    if (
      mode === PUMPOFF_SETTING_MODES.STANDARD_DOWNHOLE ||
      mode === PUMPOFF_SETTING_MODES.VFD_DOWNHOLE
    ) {
      return <DownholeGenericView {...props} />;
    }
  }

  if (hasWellPilot(well.devices)) return <WellPilotView {...props} />;

  return null;
};

class Component extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: props.well.pumpOffSetting
        ? props.well.pumpOffSetting.mode.value
        : PUMPOFF_SETTING_MODES.STANDARD_SURFACE,
    };
  }

  render() {
    const {well, dispatch, onDone = noop} = this.props;

    let modes = [
      ...standardModes,
      ...(hasLufkin(well.devices) || hasLufkin2(well.devices)
        ? lufkinSpecific
        : []),
    ];

    const renderMode = () => {
      return (
        !hasDriveType(well.devices, "WELLPILOT_EPIC") && (
          <FormControl component="fieldset">
            <FormLabel component="legend">Mode</FormLabel>
            <RadioGroup
              aria-label="Mode"
              name="Mode"
              value={this.state.mode}
              onChange={(e, v) => this.setState({mode: v})}
              row
            >
              {modes.map(mode => {
                return (
                  <FormControlLabel
                    key={mode.value}
                    label={mode.label}
                    value={mode.value}
                    control={<Radio />}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        )
      );
    };

    const updatePumpoffSettings = settings => {
      console.log("ahhhhhh", typeof this.state.mode);
      dispatch(
        savePumpoffSettings({
          downholeLocation: well.downholeLocation,
          wellId: well.wellId,
          data: {
            ...settings,
            mode: this.state.mode,
          },
        }),
      );

      return onDone();
    };

    if (hasSmarten(well.devices)) {
      return <SmartenView {...this.props} />;
    }

    return (
      <PaperContainer padded style={{maxWidth: "700px"}}>
        <FormHeader style={{marginLeft: 16, marginTop: 16}}>
          Pumpoff Settings
        </FormHeader>
        <CardContent>
          {renderMode()}
          {getView({
            ...this.props,
            onCancel: onDone,
            onSubmit: updatePumpoffSettings,
            mode: this.state.mode,
          })}
        </CardContent>
      </PaperContainer>
    );
  }
}

export default connect()(Component);
