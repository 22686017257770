export default (value, decimals = 0) => {
  const fixed = value.toFixed(decimals);

  const abbreviations = [
    {value: 1e24, symbol: "S"},
    {value: 1e21, symbol: "s"},
    {value: 1e18, symbol: "Q"},
    {value: 1e15, symbol: "q"},
    {value: 1e12, symbol: "t"},
    {value: 1e9, symbol: "B"},
    {value: 1e6, symbol: "M"},
    {value: 1e3, symbol: "K"},
  ];

  if (fixed < 1e3) {
    return fixed;
  }

  for (let i = 0; i < abbreviations.length; i++) {
    if (Math.round(fixed) >= abbreviations[i].value) {
      return (
        (fixed / abbreviations[i].value).toFixed(decimals) +
        abbreviations[i].symbol
      );
    }
  }

  return fixed;
};
