import all from "utils/validation/all";
import required from "utils/validation/required";
import integer from "utils/validation/integer";

export default values => {
  const requiredFields = [
    "powerSupplyPhases",
    "efficiency",
    "horsepower",
    "voltage",
    "current",
    "rpm",
    "serviceFactor",
  ];

  const integerFields = [
    "powerSupplyPhases",
    "horsepower",
    "voltage",
    "current",
    "rpm",
  ];

  return {
    ...all(required, values, requiredFields),
    ...all(integer, values, integerFields),
  };
};
