import React from "react";

import {COLORS, LABELS} from "constants/well-health";
import getUnits from "utils/get-units";
import round from "utils/round";

import BaseWellboreGraph from "./base-wellbore-graph";
import dataToPoint from "./data-to-point";

const TooltipRod = ({tooltipData: {data, pointNumber}, setColor}) => {
  const {
    inclinationDegrees,
    doglegSeverityDeg30m,
    length,
    trueVerticalDepth,
    rodQuantity,
    rodType,
    diameter,
    guideQuantity,
  } = dataToPoint(
    data,
    [
      "inclinationDegrees",
      "doglegSeverityDeg30m",
      "length",
      "trueVerticalDepth",
      "rodQuantity",
      "rodType",
      "diameter",
      "guideQuantity",
    ],
    pointNumber,
  );

  setColor(COLORS.ROD);

  const typeDisplay = rodType === "Sinker" ? "Sinkers" : "Rods";
  const guideDisplay =
    guideQuantity > 0 ? `${guideQuantity} guides` : "Unguided";

  return (
    <BaseWellboreGraph
      title={`${LABELS.ROD} #${pointNumber + 1}`}
      subtitle={`${round(rodQuantity)} ${typeDisplay} / ${guideDisplay}`}
      primaryContentList={[
        {label: "Inclination:", value: `${round(inclinationDegrees)}°`},
        {label: "DLS:", value: `${round(doglegSeverityDeg30m)}°`},
        {
          label: "TVD:",
          value: `${round(trueVerticalDepth, 0)}${getUnits().depth}`,
        },
      ]}
      secondaryContentList={[
        {
          label: "Section Length:",
          value: `${round(length)}${getUnits().height}`,
        },
        {label: "Diameter:", value: `${round(diameter)}${getUnits().length}`},
      ]}
    />
  );
};

export default TooltipRod;
