import isNil from "lodash/isNil";

import getDefaultDowntimeMin from "@ambyint/common/utils/wells/get-default-downtime-min";
import getDefaultDowntimeMax from "@ambyint/common/utils/wells/get-default-downtime-max";
import getDefaultSpeedMax from "@ambyint/common/utils/wells/get-default-speed-max";
import getDefaultSpeedMin from "@ambyint/common/utils/wells/get-default-speed-min";
import {
  isLessThan,
  isGreaterThan,
} from "@ambyint/common/utils/compare-time-object";
import pluralize from "@ambyint/common/utils/format/format-pluralize";

import decimalPrecision from "utils/validation/decimal-precision";
import max from "utils/validation/max";
import min from "utils/validation/min";
import integer from "utils/validation/integer";
import number from "utils/validation/number";
import required from "utils/validation/required";
import validateFieldsOf from "utils/validation/validate-fields-of";

const validate = (values, props) => {
  let errors = {};

  if (values.maxSpeed && values.minSpeed) {
    const validateField = validateFieldsOf(values);

    const minSpeed = getDefaultSpeedMin(props.well);
    const maxSpeed = getDefaultSpeedMax(props.well);

    errors = {
      ...validateField("minSpeed", x => required(x)),
      ...validateField("minSpeed", x => number(x)),
      ...validateField("minSpeed", x => min(x, minSpeed)),
      ...validateField("minSpeed", x => max(x, maxSpeed)),
      ...validateField("minSpeed", x => max(x, values.maxSpeed.value)),
      ...validateField("minSpeed", x => decimalPrecision(x, 1)),

      ...validateField("maxSpeed", x => required(x)),
      ...validateField("maxSpeed", x => number(x)),
      ...validateField("maxSpeed", x => min(x, values.minSpeed.value)),
      ...validateField("maxSpeed", x => min(x, minSpeed)),
      ...validateField("maxSpeed", x => max(x, maxSpeed)),
      ...validateField("maxSpeed", x => decimalPrecision(x, 1)),
    };
  }

  const {
    maxDowntimeHours,
    maxDowntimeMinutes,
    minDowntimeHours,
    minDowntimeMinutes,
  } = values;

  const currentMinDowntime = {
    hours: minDowntimeHours,
    minutes: minDowntimeMinutes,
  };

  const currentMaxDowntime = {
    hours: maxDowntimeHours,
    minutes: maxDowntimeMinutes,
  };

  const minDowntime = getDefaultDowntimeMin(props.well);
  const maxDowntime = getDefaultDowntimeMax(props.well);

  if (
    !isNil(maxDowntimeHours) &&
    !isNil(maxDowntimeMinutes) &&
    !isNil(minDowntimeHours) &&
    !isNil(minDowntimeMinutes)
  ) {
    const validateField = validateFieldsOf(values);

    const minLessThanDefault = () => {
      return isLessThan(currentMinDowntime, minDowntime)
        ? `Cannot be less than ${minDowntime.hours} hour${pluralize(
            minDowntime.hours,
          )}, ${minDowntime.minutes} minute${pluralize(minDowntime.minutes)}`
        : undefined;
    };

    const maxMoreThanDefault = () => {
      return isGreaterThan(currentMaxDowntime, maxDowntime)
        ? `Cannot be more than ${maxDowntime.hours} hour${pluralize(
            maxDowntime.hours,
          )}, ${maxDowntime.minutes} minute${pluralize(maxDowntime.minutes)}`
        : undefined;
    };

    const maxLessThanMin = () => {
      return isLessThan(currentMaxDowntime, currentMinDowntime)
        ? "Cannot be less than minimum downtime"
        : undefined;
    };

    const minDowntimeZero = () => {
      return currentMinDowntime.hours + currentMinDowntime.minutes === 0
        ? "Downtime cannot be zero"
        : undefined;
    };

    const maxDowntimeZero = () => {
      return currentMaxDowntime.hours + currentMaxDowntime.minutes === 0
        ? "Downtime cannot be zero"
        : undefined;
    };

    errors = {
      ...errors,
      ...validateField("minDowntimeHours", x => required(x)),
      ...validateField("minDowntimeHours", x => number(x)),
      ...validateField("minDowntimeHours", x => integer(x)),
      ...validateField("minDowntimeHours", () => minDowntimeZero()),
      ...validateField("minDowntimeHours", () => minLessThanDefault()),
      ...validateField("minDowntimeHours", x => min(x, 0)),
      ...validateField("minDowntimeHours", x => max(x, maxDowntime.hours)),

      ...validateField("minDowntimeMinutes", x => required(x)),
      ...validateField("minDowntimeMinutes", x => number(x)),
      ...validateField("minDowntimeMinutes", x => integer(x)),
      ...validateField("minDowntimeMinutes", () => minDowntimeZero()),
      ...validateField("minDowntimeMinutes", x => min(x, 0)),
      ...validateField("minDowntimeMinutes", x => max(x, 59)),

      ...validateField("maxDowntimeHours", x => required(x)),
      ...validateField("maxDowntimeHours", x => number(x)),
      ...validateField("maxDowntimeHours", x => integer(x)),
      ...validateField("maxDowntimeHours", () => maxDowntimeZero()),
      ...validateField("maxDowntimeHours", () => maxMoreThanDefault()),
      ...validateField("maxDowntimeHours", x => min(x, 0)),
      ...validateField("maxDowntimeHours", () => maxLessThanMin()),
      ...validateField("maxDowntimeHours", x => max(x, maxDowntime.hours)),

      ...validateField("maxDowntimeMinutes", x => required(x)),
      ...validateField("maxDowntimeMinutes", x => number(x)),
      ...validateField("maxDowntimeMinutes", x => integer(x)),
      ...validateField("maxDowntimeMinutes", () => maxDowntimeZero()),
      ...validateField("maxDowntimeMinutes", x => min(x, 0)),
      ...validateField("maxDowntimeMinutes", () => maxLessThanMin()),
      ...validateField("maxDowntimeMinutes", x => max(x, maxDowntime.minutes)),
    };
  }

  const currentMinStoptime = values.minStoptime;
  const currentMaxStoptime = values.maxStoptime;
  if (currentMinStoptime && currentMaxStoptime) {
    const validateField = validateFieldsOf(values);

    const minMoreThanMax = () => {
      return isGreaterThan(currentMinStoptime, currentMaxStoptime)
        ? "Cannot be more than maximum stop time"
        : undefined;
    };

    errors = {
      ...errors,
      ...validateField("minStoptime", x => required(x.hours)),
      ...validateField("minStoptime", x => required(x.minutes)),
      ...validateField("minStoptime", x => number(x.hours)),
      ...validateField("minStoptime", x => number(x.minutes)),
      ...validateField("minStoptime", x => integer(x.hours)),
      ...validateField("minStoptime", x => integer(x.minutes)),
      ...validateField("minStoptime", x => min(x.hours, 0)),
      ...validateField("minStoptime", x => min(x.minutes, 0)),
      ...validateField("minStoptime", x => max(x.hours, 23)),
      ...validateField("minStoptime", x => max(x.minutes, 59)),
      ...validateField("minStoptime", () => minMoreThanMax()),

      ...validateField("maxStoptime", x => required(x.hours)),
      ...validateField("maxStoptime", x => required(x.minutes)),
      ...validateField("maxStoptime", x => number(x.hours)),
      ...validateField("maxStoptime", x => number(x.minutes)),
      ...validateField("maxStoptime", x => integer(x.hours)),
      ...validateField("maxStoptime", x => integer(x.minutes)),
      ...validateField("maxStoptime", x => min(x.hours, 0)),
      ...validateField("maxStoptime", x => min(x.minutes, 0)),
      ...validateField("maxStoptime", x => min(x.minutes, 0)),
      ...validateField("maxStoptime", x => max(x.hours, 23)),
      ...validateField("maxStoptime", x => max(x.minutes, 59)),
    };
  }

  return Object.keys(errors).length > 0 ? errors : null;
};

export default validate;
