export default ({state, action}) => {
  if (!action.payload.downholeLocation) {
    return state;
  }

  return {
    ...state,
    selected: action.payload.downholeLocation,
  };
};
