import React from "react";
import CarIcon from "mdi-material-ui/Car";
import clsx from "clsx";
import makeStyles from "@material-ui/styles/makeStyles";
import Modal from "@material-ui/core/Modal";
import PowerIcon from "mdi-material-ui/Power";
import Container from "components/container";

import {findColors} from "../automation-button-group/find-colors";
import useAutomationStyles from "../automation-button-group/use-automation-styles";
import useButtonTheme from "../automation-button-group/use-button-theme";

const useStyles = makeStyles(({colors, spacing, typography}) => ({
  root: {
    top: "auto !important",
    backgroundColor: "#fff",
    boxSizing: "border-box",
    padding: spacing(3, 4),
    width: "100%",
    borderRadius: spacing(0, 0, 0.5, 0.5),
    borderTop: [["solid", colors.warmGrey[100], 1]],
    "& *:focus": {
      outline: "none",
    },
  },
  container: {
    display: "flex",
  },
  subContainer: {
    display: "flex",
    "&:first-child": {
      paddingLeft: 25,
      marginRight: spacing(8),
    },
  },
  labelContainer: {
    cursor: "default",
    display: "flex",
    alignItems: "center",
    "&:not(:last-child)": {
      paddingRight: spacing(2),
    },
  },
  label: {
    ...typography.caption,
    color: colors.warmGrey[700],
  },
  iconContainer: {
    cursor: "default",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
}));

const Legend = () => {
  const automationClasses = useAutomationStyles();
  const classes = useStyles();
  const offColorClasses = useButtonTheme({colors: findColors({on: false})});
  const onColorClasses = useButtonTheme({colors: findColors({on: true})});
  const automatedColorClasses = useButtonTheme({
    colors: findColors({on: true, autoAccept: true}),
  });
  const metBoundaryColorClasses = useButtonTheme({
    colors: findColors({on: true, metBoundary: true}),
  });

  return (
    <Modal open disableScrollLock hideBackdrop className={classes.root}>
      <div>
        <Container className={classes.container}>
          <div className={classes.subContainer}>
            <div className={classes.labelContainer}>
              <div
                className={clsx(
                  classes.iconContainer,
                  offColorClasses.power,
                  automationClasses.button,
                )}
              >
                <PowerIcon />
              </div>
              <span className={classes.label}>Off</span>
            </div>
            <div className={classes.labelContainer}>
              <div
                className={clsx(
                  classes.iconContainer,
                  onColorClasses.power,
                  automationClasses.button,
                )}
              >
                <PowerIcon />
              </div>
              <span className={classes.label}>On</span>
            </div>
            <div className={classes.labelContainer}>
              <div
                className={clsx(
                  classes.iconContainer,
                  automatedColorClasses.automate,
                  automationClasses.button,
                )}
              >
                <CarIcon />
              </div>
              <span className={classes.label}>Fully Automated</span>
            </div>
            <div className={classes.labelContainer}>
              <div
                className={clsx(
                  classes.iconContainer,
                  onColorClasses.automate,
                  automationClasses.button,
                )}
              >
                <CarIcon />
              </div>
              <span className={classes.label}>Recommendations Only</span>
            </div>
          </div>
          <div className={classes.subContainer}>
            <div className={classes.labelContainer}>
              <div
                className={clsx(
                  classes.iconContainer,
                  metBoundaryColorClasses.power,
                  automationClasses.button,
                )}
              >
                <PowerIcon />
              </div>
              <span className={classes.label}>Boundary Condition</span>
            </div>
          </div>
        </Container>
      </div>
    </Modal>
  );
};

export default Legend;
