import React from "react";
import {Field} from "redux-form";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

import DecimalPickerComponent from "components/decimal-picker";

import formatLabel from "./format-label";

const DecimalPicker = props => {
  const hasError =
    (!!props.meta.dirty || !!props.meta.submitFailed) && !!props.meta.error;

  return (
    <FormControl
      fullWidth={props.fullWidth}
      error={hasError}
      style={{
        ...props.style,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
        }}
      >
        <DecimalPickerComponent {...props} />
      </div>
      {hasError && <FormHelperText error>{props.meta.error}</FormHelperText>}
    </FormControl>
  );
};

export default props => {
  return (
    <Field {...props} label={formatLabel(props)} component={DecimalPicker} />
  );
};
