import {show} from "actions/snackbar";
import {types} from "actions/wells";
import fetch from "epics/fetch";

export default action$ => {
  return action$.ofType(types.setRunTimer).switchMap(({payload}) => {
    const {data} = payload;
    return fetch(
      `/wells/${encodeURIComponent(payload.well.downholeLocation)}/runTimer`,
      {},
      {
        method: "PUT",
        body: {
          downholeLocation: payload.well.downholeLocation,
          runTimer: {
            enabled: data.enabled,
            runTime: parseInt(data.runTime, 10),
            stopTime: parseInt(data.stopTime, 10),
          },
        },
      },
    ).map(() => {
      return show({
        message: `Your request to set the run timer for ${
          payload.well.displayName
        } was submitted successfully!`,
      });
    });
  });
};
