import flatMap from "lodash/flatMap";
import max from "lodash/max";
import min from "lodash/min";

export const calculateMapCoords = wells => {
  const wellsWithLocations = wells.filter(well => well.location);

  const lats = flatMap(wellsWithLocations, well => well.location.lat);
  const longs = flatMap(wellsWithLocations, well => well.location.lng);

  return {
    lat: (parseFloat(max(lats)) + parseFloat(min(lats))) / 2,
    lng: (parseFloat(max(longs)) + parseFloat(min(longs))) / 2,
  };
};
