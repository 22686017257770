import React from "react";
import clsx from "clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Close from "mdi-material-ui/Close";
import {Dialog, Typography, IconButton} from "@material-ui/core";

import OverrideOptions from "./override-options";
import WellCardOverrideDialogActions from "./well-card-override-dialog-actions";

const useStyles = makeStyles(({spacing, colors, breakpoints}) => ({
  container: {
    padding: spacing(0),
    display: "grid",
    gridTemplateColumns: "auto auto",
    gridTemplateRows: "auto 1fr auto",
    gridTemplateAreas: "'header header' 'left right' '. footer'",
    maxHeight: "98vh",
  },
  headerBar: {
    padding: spacing(4, 3, 2, 4),
    gridArea: "header",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [breakpoints.down("md")]: {
      padding: spacing(2, 3, 1, 4),
    },
  },
  body: {
    backgroundColor: colors.warmGrey[50],
  },
  bodyLeft: {
    gridArea: "left",
  },
  bodyRight: {
    gridArea: "right",
  },
  actionBar: {
    gridArea: "footer",
    justifySelf: "end",
  },
  cardPreview: {
    minWidth: 230,
    padding: spacing(4),
    [breakpoints.down("md")]: {
      padding: spacing(2, 4, 2, 4),
    },
  },
  paperOverride: {
    "@media (max-height: 885px)": {
      maxWidth: 690,
      maxHeight: 660,
      [breakpoints.down("md")]: {
        maxWidth: 507,
        minWidth: 507,
        maxHeight: 700,
      },
      "& $container": {
        transform: "scale(0.8)",
        transformOrigin: "top left",
      },
    },
    "@media (min-height: 885px)": {
      maxHeight: "fit-content",
    },
  },
}));

const WellCardOverrideDialog = ({
  open,
  onCancel,
  onSubmit,
  onOverrideSelected,
  overrideType,
  cardToRender,
  currentType,
}) => {
  const classes = useStyles();
  const cannotReclassify =
    overrideType === currentType || overrideType === null;

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      maxWidth={false}
      PaperProps={{classes: {root: classes.paperOverride}}}
    >
      <div className={classes.container}>
        <div className={classes.headerBar}>
          <Typography variant="h6">Reclassify Card</Typography>
          <IconButton onClick={onCancel}>
            <Close />
          </IconButton>
        </div>
        <div className={clsx(classes.body, classes.bodyLeft)}>
          <div className={classes.cardPreview}>{cardToRender}</div>
        </div>
        <div className={clsx(classes.body, classes.bodyRight)}>
          <OverrideOptions
            onClick={onOverrideSelected}
            currentType={currentType}
            overrideType={overrideType}
          />
        </div>
        <WellCardOverrideDialogActions
          className={classes.actionBar}
          onCancel={onCancel}
          onSubmit={onSubmit}
          disabled={cannotReclassify}
        />
      </div>
    </Dialog>
  );
};

export default WellCardOverrideDialog;
