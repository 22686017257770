import {types} from "./types";

export const setDailySummaries = (
  {liquidProduction, strokes},
  unitsOfMeasure,
) => {
  return {
    type: types.setDailySummaries,
    payload: {
      liquidProduction,
      strokes,
      unitsOfMeasure,
    },
  };
};
