import React from "react";
import clsx from "clsx";
import compose from "lodash/fp/compose";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";

import {blue, warmGrey} from "theme/updated-colors";

const styles = ({typography, spacing, colors}) => ({
  label: {
    fontSize: 11,
    fill: colors.warmGrey[500],
  },

  right: {
    textAnchor: "end",
  },

  center: {
    textAnchor: "middle",
  },
});

const mapStateToProps = ({wells, wellHealth}) => {
  return {
    ...wellHealth.quality,
    activeDownholeLocation: wells.activeDownholeLocation,
  };
};

const enhance = compose(
  connect(mapStateToProps),
  withStyles(styles),
);

const generateGraphData = (width, minPercent, maxPercent, meanPercent) => {
  // Use a minWidth of 100.
  // During loading sizeMe may report some weird values.
  if (!width || width === 0) width = 100;

  // Set the heights of each element
  const outerHeight = 24;
  const innerHeight = 16;
  const dotHeight = 12;

  // Set the radius of each element
  const outerRadius = outerHeight / 2;
  const innerRadius = innerHeight / 2;
  const dotRadius = dotHeight / 2;

  // Calculate the space between the outer and inner elements
  const innerMargin = (outerHeight - innerHeight) / 2;

  // Determine where left side of our range exists and the width of the range.
  // This is calculated without the rounded caps which are factored in later.
  const minX = outerRadius;
  const range = width - outerRadius * 2;

  // Determine the x and width of the outer rect including its rounded caps.
  const outerX = 0;
  const outerY = 5;
  const outerWidth = width;

  // Determine the x and width of the inner rect (confidence) including its rounded caps.
  // First figure out where the left and right bounds of the confidence rect are _without_ caps.
  // This ensures the band is aligned with the outer band.
  // IMPORTANT: Because the percentages are inverted to flip the graph, the left side X value is based on the max, not the min!
  // Its also why the width is min - max instead max - min.
  const capSize = innerMargin * 0.75 + dotRadius;
  const innerMin = minX + range * minPercent;
  const innerMax = minX + range * maxPercent;
  const innerX = innerMax - capSize; // Subtract the size of the left cap from the left X position
  const innerY = outerY + innerMargin;
  const innerWidth = innerMin - innerMax + capSize * 2; // Find the width without caps then add space for the left and right caps.

  // Determine the position of the dot.
  // The dot is positioned based on it's center.
  // It is a circle but is essentially a zero-width rect with rounded caps added on both ends.
  const dotX = minX + range * meanPercent;
  const dotY = outerY + outerHeight / 2;

  return {
    outerX,
    outerY,
    outerWidth,
    outerHeight,
    outerRadius,
    innerX,
    innerY,
    innerWidth,
    innerHeight,
    innerRadius,
    dotX,
    dotY,
    dotRadius,
  };
};

const WellboreQualityGraph = ({
  width,
  rankings,
  activeDownholeLocation,
  classes,
}) => {
  const {minPercent, maxPercent, meanPercent} = rankings[
    activeDownholeLocation
  ];

  const {
    outerX,
    outerY,
    outerWidth,
    outerHeight,
    outerRadius,
    innerX,
    innerY,
    innerWidth,
    innerHeight,
    innerRadius,
    dotX,
    dotY,
    dotRadius,
  } = generateGraphData(width, minPercent, maxPercent, meanPercent);

  return (
    <div width={width}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={outerHeight + 32}
        width={width}
      >
        <g>
          <line
            x1={outerWidth / 2 - 0.5}
            y1={0}
            x2={outerWidth / 2 - 0.5}
            y2={outerHeight + outerY * 2}
            strokeWidth={1}
            stroke={warmGrey[500]}
          />

          <rect
            x={outerX}
            y={outerY}
            height={outerHeight}
            width={outerWidth}
            rx={outerRadius}
            fill="#F5FBFF"
          />

          <>
            <rect
              x={innerX}
              y={innerY}
              height={innerHeight}
              width={innerWidth}
              rx={innerRadius}
              fill={blue[100]}
            />

            <circle cx={dotX} cy={dotY} r={dotRadius} fill={blue[600]} />
          </>

          <rect
            x={outerX + 1}
            y={outerY + 1}
            height={outerHeight - 2}
            width={outerWidth - 2}
            rx={outerRadius}
            fill="none"
            stroke="#EDEDF3"
            strokeWidth={1}
          />
        </g>

        <g transform={`translate(0 ${outerHeight + 24})`}>
          <text x={10} y={0} className={classes.label}>
            Decreasing Risk
          </text>
          <text
            x={outerWidth / 2}
            y={0}
            className={clsx(classes.label, classes.center)}
          >
            Neutral
          </text>
          <text
            x={outerWidth - 10}
            y={0}
            className={clsx(classes.label, classes.right)}
          >
            Increasing Risk
          </text>
        </g>
      </svg>
    </div>
  );
};

export default enhance(WellboreQualityGraph);
