import {withRouter} from "react-router";
import {compose} from "redux";

import EventFeed from "./event-feed";
import withState from "./with-state";

export default compose(
  withRouter,
  withState,
)(EventFeed);
