import React, {useState} from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import Dialog from "components/dialog";

import {DISMISS_VALUES} from "./dismiss-values";
import Header from "./header";
import Body from "./body";
import Footer from "./footer";

const styles = ({colors}) => ({
  root: {
    color: colors.warmGrey[700],
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: 500,
  },
});

const DismissDialog = ({classes, open, close, dismiss}) => {
  const [reason, setReason] = useState("");
  const [customReason, setCustomReason] = useState("");
  const chosenOther = reason === DISMISS_VALUES.OTHER;
  const disabled = chosenOther ? !customReason.trim() : !reason;

  const handleClose = () => {
    setCustomReason("");
    setReason("");
    close();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <div className={classes.root}>
        <Header handleClose={handleClose} />
        <Body
          reason={reason}
          setReason={setReason}
          setCustomReason={setCustomReason}
          customReason={customReason}
          chosenOther={chosenOther}
        />
        <Footer
          dismiss={dismiss}
          disabled={disabled}
          handleClose={handleClose}
          reason={chosenOther ? customReason.trim() : reason}
        />
      </div>
    </Dialog>
  );
};

export default withStyles(styles)(DismissDialog);
