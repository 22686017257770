import React from "react";
import noop from "lodash/noop";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";

import {useHover} from "hooks/use-hover";
import TagCheckbox from "./tag-checkbox";

const useStyles = makeStyles(({spacing}) => ({
  headerDefault: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: spacing(2),
    transition: "all .2s ease-in-out",
  },
  titleBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  captionDefault: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

const getStateStyles = ({isHoverOnCard, selected, classes}) => {
  if (selected) {
    if (isHoverOnCard) {
      return {
        headerStyle: classes.selectedHoverBG,
        titleStyle: classes.selectedHoverTitle,
        captionStyle: classes.selectedHoverCaption,
      };
    }
    return {
      headerStyle: classes.selectedIdleBG,
      titleStyle: classes.selectedIdleTitle,
      captionStyle: classes.selectedIdleCaption,
    };
  }

  // Otherwise it is unselected
  if (isHoverOnCard) {
    return {
      headerStyle: classes.unselectedHoverBG,
      titleStyle: classes.unselectedHoverTitle,
      captionStyle: classes.unselectedHoverCaption,
    };
  }
  return {
    headerStyle: classes.unselectedIdleBG,
    titleStyle: classes.unselectedIdleTitle,
    captionStyle: classes.unselectedIdleCaption,
  };
};

const WellCardHeader = ({
  classes,
  wellName,
  classification,
  selected,
  isSelectionMode,
  isHoverOnCard,
  handleHeaderClick,
  variant,
  readonly,
}) => {
  const {isHovering, enableHover, disableHover} = useHover();
  const defaultClasses = useStyles();
  const {headerStyle, titleStyle, captionStyle} = getStateStyles({
    selected,
    isHoverOnCard,
    classes,
  });

  return (
    <div
      className={clsx(defaultClasses.headerDefault, headerStyle)}
      onMouseEnter={readonly ? noop : enableHover}
      onMouseLeave={readonly ? noop : disableHover}
      onClick={handleHeaderClick}
    >
      <div className={defaultClasses.titleBar}>
        <Typography variant="subtitle2" className={titleStyle}>
          {classification}
        </Typography>
        <TagCheckbox
          selected={selected}
          isHoverOnCard={isHoverOnCard}
          isHoverOnHeader={isHovering}
          variant={variant}
          isSelectionMode={isSelectionMode}
        />
      </div>
      <Typography
        variant="caption"
        className={clsx(defaultClasses.captionDefault, captionStyle)}
      >
        {wellName}
      </Typography>
    </div>
  );
};

export default WellCardHeader;
