import React from "react";
import {connect} from "react-redux";

import Fab from "@material-ui/core/Fab";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import {hasSmarten} from "@ambyint/common/utils/devices/has-smarten";
import {types as wellActionTypes} from "@ambyint/common/actions/wells";

import AmbyintWhiteIcon from "components/logo/icon-white";
import {eventCategory, eventAction} from "constants/analytics";
import {requestCard, types} from "actions/wells";
import {sendAnalyticsEvent} from "components/analytics";
import {
  showClearErrors,
  showSetDowntime,
  showResetFault,
  showResetMalfunctions,
  showSetSpeed,
  showSetSpeedRange,
  showStartWell,
  showStopWell,
} from "actions/dialogs";
import getPrivileges from "utils/get-privileges";
import privilegeTypes from "utils/privilege-types";
import withFeatureFlag from "components/with-feature-flag";

const fabStyle = {
  position: "absolute",
  bottom: 25,
  right: 25,
};

const mapStateToProps = ({auth: {user}}) => {
  return {user};
};

class WellMenu extends React.Component {
  state = {
    anchorEl: undefined,
    open: false,
  };

  close = () => {
    this.setState({open: false});
  };

  open = event => {
    this.setState({open: true, anchorEl: event.currentTarget});
  };

  requestCard = () => {
    sendAnalyticsEvent(
      eventCategory.wellDashboard,
      eventAction.wellMenu,
      "Request Card",
    );
    this.props.dispatch(requestCard(this.props.well));
    this.close();
  };

  resetFault = () => {
    sendAnalyticsEvent(
      eventCategory.wellDashboard,
      eventAction.wellMenu,
      "Reset Fault",
    );
    this.props.dispatch(showResetFault(this.props.well));
    this.close();
  };

  setClearErrors = () => {
    sendAnalyticsEvent(
      eventCategory.wellDashboard,
      eventAction.wellMenu,
      "Clear Errors",
    );
    this.props.dispatch(showClearErrors(this.props.well));
    this.close();
  };

  setDowntime = () => {
    sendAnalyticsEvent(
      eventCategory.wellDashboard,
      eventAction.wellMenu,
      "Set Downtime",
    );
    this.props.dispatch(showSetDowntime(this.props.well));
    this.close();
  };

  setResetMalfunctions = () => {
    sendAnalyticsEvent(
      eventCategory.wellDashboard,
      eventAction.wellMenu,
      "Reset Malfunctions",
    );
    this.props.dispatch(showResetMalfunctions(this.props.well));
    this.close();
  };

  setSpeed = () => {
    sendAnalyticsEvent(
      eventCategory.wellDashboard,
      eventAction.wellMenu,
      "Set Speed",
    );
    this.props.dispatch(showSetSpeed(this.props.well));
    this.close();
  };

  setSpeedRange = () => {
    sendAnalyticsEvent(
      eventCategory.wellDashboard,
      eventAction.wellMenu,
      "Set Speed Range",
    );
    this.props.dispatch(showSetSpeedRange(this.props.well));
    this.close();
  };

  startWell = () => {
    sendAnalyticsEvent(
      eventCategory.wellDashboard,
      eventAction.wellMenu,
      "Start Well",
    );
    this.props.dispatch(showStartWell(this.props.well));
    this.close();
  };

  stopWell = () => {
    sendAnalyticsEvent(
      eventCategory.wellDashboard,
      eventAction.wellMenu,
      "Stop Well",
    );
    this.props.dispatch(showStopWell(this.props.well));
    this.close();
  };

  render() {
    const well = this.props.well;
    const privileges = getPrivileges(well.organization.id);
    const hasControl = privileges.includes(privilegeTypes.well.control);
    const isSmarten = hasSmarten(well.devices);

    const RequestCardButton = withFeatureFlag("request-card")(({key}) => {
      return (
        <MenuItem key={key} onClick={this.requestCard}>
          Request Card
        </MenuItem>
      );
    });

    if (!hasControl) return null;

    const controlItems = compact([
      well.actions.includes(types.start) && (
        <MenuItem key={types.start} onClick={this.startWell}>
          Start Well
        </MenuItem>
      ),
      well.actions.includes(types.stop) && (
        <MenuItem key={types.stop} onClick={this.stopWell}>
          Stop Well
        </MenuItem>
      ),
      !isSmarten && well.actions.includes(types.setSpeed) && (
        <MenuItem key={types.setSpeed} onClick={this.setSpeed}>
          Set Speed
        </MenuItem>
      ),
      well.actions.includes(types.setSpeedRange) && (
        <MenuItem key={types.setSpeedRange} onClick={this.setSpeedRange}>
          Set Speed Range
        </MenuItem>
      ),
      well.actions.includes(types.setDowntime) && (
        <MenuItem key={types.setDowntime} onClick={this.setDowntime}>
          Set Downtime
        </MenuItem>
      ),
      well.actions.includes(wellActionTypes.RESET_FAULT) && (
        <MenuItem key={wellActionTypes.RESET_FAULT} onClick={this.resetFault}>
          Reset Fault
        </MenuItem>
      ),
      well.actions.includes(types.setResetMalfunctions) && (
        <MenuItem
          key={types.setResetMalfunctions}
          onClick={this.setResetMalfunctions}
        >
          Reset Malfunctions
        </MenuItem>
      ),
      well.actions.includes(types.setClearErrors) && (
        <MenuItem key={types.setClearErrors} onClick={this.setClearErrors}>
          Clear Errors
        </MenuItem>
      ),
      well.actions.includes(types.requestCard) && (
        <RequestCardButton key={types.requestCard} />
      ),
    ]);

    if (isEmpty(controlItems)) return null;

    return (
      <div>
        <Fab
          color="primary"
          aria-owns="well-detail-menu"
          aria-haspopup="true"
          onClick={this.open}
          style={fabStyle}
        >
          <AmbyintWhiteIcon
            width={35}
            height={35}
            style={{marginLeft: "-2px", marginTop: "-2px"}}
          />
        </Fab>
        <Menu
          id="well-detail-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.close}
        >
          <MenuItem key="placeholder" style={{display: "none"}} />
          {controlItems}
        </Menu>
      </div>
    );
  }
}

export default connect(mapStateToProps)(WellMenu);
