import React from "react";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import clsx from "clsx";

const styles = ({colors}) => ({
  text: {
    color: colors.warmGrey[400],
  },
});

const FractionalCount = ({
  classes,
  className,
  style,
  numerator,
  denominator,
}) => (
  <Typography
    variant="caption"
    className={clsx(classes.text, className)}
    style={style}
  >
    {numerator} of {denominator}
  </Typography>
);

export default withStyles(styles)(FractionalCount);
