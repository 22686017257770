import {types} from "./types";

export const setUserAddedToOrganization = options => {
  const {clientUser, organizationId} = options;

  return {
    type: types.setUserAddedToOrganization,
    payload: {
      clientUser,
      organizationId,
    },
  };
};
