import React from "react";

import FormHeader from "components/form-header";
import PaperContainer from "components/paper-container";

import Edit from "./edit";
import Read from "./read";

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
    };
  }

  toggleEdit = () => {
    this.setState({editing: !this.state.editing});
  };

  render() {
    const {well} = this.props;

    const View = this.state.editing ? Edit : Read;

    return (
      <PaperContainer extraPadded style={{maxWidth: "500px"}}>
        <div style={{marginBottom: 32}}>
          <FormHeader>Auto Accept Recommendations</FormHeader>
        </div>
        <View toggleEdit={this.toggleEdit} well={well} />
      </PaperContainer>
    );
  }
}
