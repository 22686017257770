import {types} from "./types";

export const setCalculatedDeviations = (
  dailySummaries,
  fetched,
  wells,
  deviationFilter,
  timePeriod,
) => {
  return {
    type: types.setCalculatedDeviations,
    payload: {
      wells,
      dailySummaries,
      fetched,
      deviationFilter,
      timePeriod,
    },
  };
};
