import {max as d3max} from "d3-array";
import {scaleLinear as d3scaleLinear} from "d3-scale";
import addArea from "utils/charts/add-area";
import addLine from "utils/charts/add-line";
import colors from "theme/colors";

function mapData(d) {
  return d.current || [];
}

function mapTime(d) {
  return new Date(d.createdOn);
}

function mapCurrent(d) {
  return d.value;
}

function mapMaxCurrent(d) {
  if (!d.max) {
    return mapCurrent(d);
  }

  return d.max;
}

function mapMinCurrent(d) {
  if (!d.min) {
    return mapCurrent(d);
  }

  return d.min;
}

const styles = {
  area: {
    fill: colors.current[50],
  },

  line: {
    stroke: colors.current[400],
  },
};

export default ({clipPathId, selection, register, scales}) => {
  const currentScale = d3scaleLinear();
  const options = {
    clipPathId,
    mapData,
    mapX: mapTime,
    mapY: mapCurrent,
    mapMaxY: mapMaxCurrent,
    mapMinY: mapMinCurrent,
    selection,
    xScale: scales.time,
    yScale: currentScale,
  };

  const area = addArea({...options, styles: styles.area});
  const line = addLine({...options, styles: styles.line});

  return register({
    draw(props) {
      const {config, data} = props;
      const maxCurrent = d3max(mapData(data), mapMaxCurrent);

      currentScale.domain([0, maxCurrent * 1.1]).range([props.height, 0]);

      area.hide();
      line.hide();

      if (config.current && config.current.state > 0) {
        line.show();
      }

      if (config.current && config.current.state === 2) {
        area.show();
      }

      area.draw(props);
      line.draw(props);
    },

    zoom(props) {
      area.zoom(props);
      line.zoom(props);
    },
  });
};
