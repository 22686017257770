import all from "utils/validation/all";
import getUser from "utils/get-user";
import isNumber from "utils/validation/number";
import isInteger from "utils/validation/integer";
import isPositive from "utils/validation/positive";
import isMax from "utils/validation/max";
import required from "utils/validation/required";

import {maxAllowableStrokes, maxAllowableLoad} from "./max-values";

export default values => {
  const {unitsOfMeasure} = getUser();

  const maxLoad = maxAllowableLoad(unitsOfMeasure);
  const maxStrokes = maxAllowableStrokes();

  const requiredFields = [
    "minLoad",
    "peakLoad",
    "consecutiveMinLoad",
    "consecutivePeakLoad",
  ];

  const needed = all(required, values, requiredFields);
  const numbers = all(isNumber, values, requiredFields);
  const positive = all(isPositive, values, requiredFields);
  const integers = all(isInteger, values, [
    "consecutiveMinLoad",
    "consecutivePeakLoad",
  ]);

  const big = {
    ...(isMax(values.minLoad, maxLoad)
      ? {minLoad: isMax(values.minLoad, maxLoad)}
      : {}),
    ...(isMax(values.peakLoad, maxLoad)
      ? {peakLoad: isMax(values.peakLoad, maxLoad)}
      : {}),
    ...(isMax(values.consecutiveMinLoad, maxStrokes)
      ? {
          consecutiveMinLoad: isMax(values.consecutiveMinLoad, maxStrokes),
        }
      : {}),
    ...(isMax(values.consecutivePeakLoad, maxStrokes)
      ? {
          consecutivePeakLoad: isMax(values.consecutivePeakLoad, maxStrokes),
        }
      : {}),
  };

  return {
    ...needed,
    ...numbers,
    ...positive,
    ...integers,
    ...big,
  };
};
