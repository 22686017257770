import Field from "components/react-redux-form/field";
import TextField from "components/react-redux-form/text-field";
import noop from "lodash/noop";
import React from "react";
import {connect} from "react-redux";

import getModelPath from "../get-model-path";

const mapStateToProps = state => {
  const {wellCountry} = state.forms.addWell;

  return {
    wellCountry,
  };
};

const mapUWIHelperText = value => {
  if (value.charAt(0) === "1") return "199/99-99-999-99W9/99";
  if (value.charAt(0) === "2") return "299/A-999-A/999-A-99/99";

  return "Must start with a 1 or 2";
};

const mapAPIHelperText = () => {
  return "99-999-99999-99-99";
};

const mapHelperText = country => {
  if (country === "canada") return mapUWIHelperText;
  if (country === "unitedstates") return mapAPIHelperText;

  return noop;
};

let Input = props => {
  const {onChange, value, wellCountry} = props;

  return (
    <TextField
      fullWidth
      helperText={mapHelperText(wellCountry)(value)}
      label="Downhole Location"
      onChange={onChange}
      required
      value={value}
    />
  );
};

Input = connect(mapStateToProps)(Input);

export default () => {
  return (
    <Field component={Input} modelPath={getModelPath("downholeLocation")} />
  );
};
