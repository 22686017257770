import filterAndSort from "./filter-and-sort";

export default ({state, action}) => {
  const {data, organizationId} = action.payload;

  const current = state[organizationId];

  const organization = {
    ...current,
    events: data,
  };

  return {
    ...state,
    [organizationId]: {
      ...organization,
      filteredEvents: filterAndSort(organization),
    },
  };
};
