import cloneDeep from "lodash/cloneDeep";

function performOmitDeepUndefined(clone) {
  if (clone === null || clone === undefined) {
    return undefined;
  }

  for (const prop of Object.keys(clone)) {
    if (clone[prop] === undefined) {
      delete clone[prop];
    } else if (typeof clone[prop] === "object") {
      performOmitDeepUndefined(clone[prop]);
      if (clone[prop] === undefined || JSON.stringify(clone[prop]) === "{}") {
        delete clone[prop];
      }
    }
  }
}

function omitDeepUndefined(object) {
  const clone = cloneDeep(object);

  performOmitDeepUndefined(clone);

  return clone;
}

export default omitDeepUndefined;
