import {types, tagsHydrated, setFilteredByTags} from "actions/tagging";
import {createMergeEpic} from "epics/create-epic";
import {types as wellTypes, setActivePath} from "actions/wells";
import {getWells} from "utils/wells/get-wells";
import {getWellIds} from "utils/wells/get-well-ids";
import {getAnomalyWells} from "utils/wells/get-anomaly-wells";
import {
  setDisplayAllWells,
  setCalibrateWells,
  setTriageWells,
  setAnomalyWells,
} from "actions/wells";
import {workflows} from "constants/well-workflows";
import {getWorkflowWells} from "utils/wells/get-workflow-wells";
import {tagSearchEnhanced} from "./tag-search-enhanced";

const WORKFLOW_ACTION_MAP = {
  [workflows.ANOMALY]: wellIds => {
    return setAnomalyWells(getAnomalyWells(wellIds));
  },

  [workflows.DISPLAY_ALL]: wellIds => {
    return setDisplayAllWells({all: wellIds});
  },

  [workflows.CALIBRATE]: wellIds => {
    return setCalibrateWells({
      all: getWorkflowWells(wellIds, workflows.CALIBRATE),
    });
  },

  [workflows.TRIAGE]: wellIds => {
    return setTriageWells({all: getWorkflowWells(wellIds, workflows.TRIAGE)});
  },
};

export const filterWellsByTags = createMergeEpic(
  [
    types.setTagSelectionType,
    types.selectTag,
    types.deselectTag,
    types.filterWellsByTag,
    wellTypes.setSearchResults,
    wellTypes.setWorkflow,
  ],
  async ({payload = {}}, store) => {
    const {wells, tags} = store.getState();
    const {callbacks} = payload;
    const selectedWorkflow = wells.workflow.selected;

    // tags filter searched wells until search is redone.
    const wellList = getWells(wells.search.wellList);
    const filteredByTags = wellList.filter(
      tagSearchEnhanced(tags.selectedTags, tags.selectionType),
    );
    const filteredByTagIds = getWellIds(filteredByTags);

    return [
      setFilteredByTags(filteredByTagIds),
      WORKFLOW_ACTION_MAP[selectedWorkflow](filteredByTagIds),
      setActivePath(null),
      tagsHydrated(),
      ...(Array.isArray(callbacks) ? callbacks : []),
    ];
  },
);
