import {of} from "rxjs/observable/of";
import {push} from "react-router-redux";

import fetchApi from "epics/fetch";
import removeCookie from "utils/remove-cookie";
import setToken from "utils/set-token";
import LOCAL_STORAGE_KEYS from "constants/local-storage-keys";
import {isCompletingSignIn, setAuth, types} from "actions/auth";

export default (action$, store) => {
  return action$.ofType(types.signInWithEmail).switchMap(({payload}) => {
    const {email, password} = payload;
    const auth = store.getState().auth;

    removeCookie("id_token");
    localStorage.setItem(LOCAL_STORAGE_KEYS.LAST_SIGN_IN_EMAIL_ADDRESS, email);

    return fetchApi(
      "/authenticate",
      {},
      {
        body: {username: email, password},
        method: "POST",
      },
    )
      .flatMap(async res => {
        if (res.status === 200) {
          const callbackToken = res.response.token;
          const user = res.response;
          const {clientUserId} = user;

          setToken(callbackToken);

          if (
            !user.termsOfServiceAccepted &&
            !auth.termsOfServiceAcceptedThisSession
          ) {
            return push(`/verify-oauth/${clientUserId}/${callbackToken}`);
          }

          return isCompletingSignIn();
        }

        return of(
          setAuth({
            isAuthenticated: false,
            isFetching: true,
          }),
        );
      })
      .catch(err => {
        console.error(err);

        let message =
          "Sign in failed. An unhandled error occurred. If the issue continues, please contact support.";

        if (err.status === 401) {
          message = "Sign in failed. Please verify your email and password.";
        }

        if (err.status === 403) {
          message =
            "Sign in failed. You have not verified your email, you'll need to check your email and complete the verification.";
        }

        return of(
          setAuth({
            isAuthenticated: false,
            isFetching: false,
            error: message,
          }),
        );
      });
  });
};
