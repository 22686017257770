export default (alert, user) => {
  if (
    !alert ||
    !alert.information ||
    !alert.information.downholeLocation ||
    !user ||
    !user.wells
  )
    return false;
  return !!user.wells.find(well => well === alert.information.downholeLocation);
};
