import colors from "theme/colors";
import {FormatDateTime} from "components/format/date";
import IconButton from "@material-ui/core/IconButton";
import React from "react";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import theme from "theme";

import Presets from "./presets";

const margin = 16;

const styles = {
  container: {
    display: "flex",
    height: "auto",
    borderBottom: `1px solid ${colors.grey[300]}`,
  },

  title: {
    ...theme.typography.body1,
    flex: 1,
    fontSize: "12px",
    color: colors.grey[600],
    lineHeight: "48px",
    padding: `0 ${margin}px`,
  },
};

const Toolbar = props => {
  const {
    addComparison,
    comparisons = [],
    correlationId,
    date,
    preset,
    removeComparison,
  } = props;

  const comparison = comparisons.find(
    comparison => comparison.correlationId === correlationId,
  );

  const handleClick = () => {
    if (correlationId && correlationId !== -1) {
      if (comparison) {
        return removeComparison(correlationId);
      } else {
        return addComparison(correlationId);
      }
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.title}>
        <FormatDateTime date={date} />
      </div>
      <IconButton onClick={handleClick} disableRipple>
        {comparison ? (
          <StarIcon style={{color: comparison.color}} />
        ) : (
          <StarBorderIcon />
        )}
      </IconButton>
      <Presets onChange={preset} />
    </div>
  );
};

export default Toolbar;
