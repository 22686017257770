import pick from "lodash/pick";
import store from "store";

import trimDetails from "utils/well/trim-details";

export default () => {
  const state = store.getState();

  const form = {...state.forms.addWell};
  const user = state.auth.user;
  const organization =
    state.organizations.all[state.organizations.selectedOrganizationId];

  // Edge case to stop non canadian wells from receiving a surface location.
  if (form.wellCountry !== "Canada") {
    form.surfaceLocation = "";
  }

  return {
    ...form,
    client: pick(organization, ["organizationId", "organizationName"]),
    clientRequester: pick(user, [
      "clientUserId",
      "email",
      "firstName",
      "lastName",
      "mobilePhone",
    ]),
    ...trimDetails(form),
  };
};
