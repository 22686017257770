import isNil from "lodash/isNil";
import math from "mathjs";
import unzip from "lodash/unzip";

const clean = values => values.filter(d => !isNil(d));

export default (filter, map) => items => {
  const filtered = items.filter(filter);

  if (filtered.length === 0) return [0];

  const grouped = unzip(filtered.map(map));
  return grouped.map(values => math.mean(clean(values)));
};
