import {useMemo} from "react";
import get from "lodash/get";

import {mapData} from "./map-data";

export const useCardData = card => {
  const surfaceCard = get(card, "surfaceCard", []);
  const downholeCard = get(card, "downholeCard", []);

  const surfaceData = useMemo(() => mapData(surfaceCard), [surfaceCard]);
  const downholeData = useMemo(() => mapData(downholeCard), [downholeCard]);

  return {surfaceData, downholeData};
};
