import {connect} from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import React from "react";

const mapStateToProps = (state, props) => {
  return {
    analysis: state.wells.analysis[props.downholeLocation],
  };
};

export const withAnalysis = Component =>
  connect(mapStateToProps)(props => {
    if (!props.analysis) {
      return <LinearProgress />;
    }

    return <Component {...props} />;
  });

export default withAnalysis;
