import React from "react";

import colors from "theme/colors";

const ICON_PATH =
  "M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z";

export default props => {
  const {
    backgroundColor = colors.ambyOrange[400],
    fillColor = colors.white,
    size = 20,
    style = {},
  } = props;

  return (
    <svg
      height={size}
      width={size}
      viewBox={"0 0 488 512"}
      style={{
        backgroundColor,
        borderRadius: "2em",
        padding: "4px",
        ...style,
      }}
    >
      <path
        d={ICON_PATH}
        fill={fillColor}
        style={{transform: "scale(0.75) translate(17.5%, 12.5%)"}}
      />
    </svg>
  );
};
