import React from "react";
import Card from "@material-ui/core/Card";
import makeStyles from "@material-ui/core/styles/makeStyles";

import WellCardHeader from "../well-card-header";
import WellCardCaption from "../well-card/well-card-caption";
import WellMiniCard from "../well-mini-card";

const useStyles = makeStyles(({spacing}) => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  captionBar: {
    display: "flex",
    flexDirection: "row",
    padding: spacing(2, 1, 2, 2),
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const WellCardSimple = ({
  isAnomaly,
  well,
  card,
  headerVariant,
  wellStatus,
  caption,
  latestConfidence,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.container}>
      <WellCardHeader
        wellName={well.displayName}
        variant={headerVariant}
        classification={isAnomaly ? "Anomaly" : wellStatus}
        isSelectionMode={false}
        selected={false}
        isHoverOnCard={false}
        readonly
      />
      <WellMiniCard
        well={well}
        isAnomaly={isAnomaly}
        isHovering={true}
        card={card}
      />
      <div className={classes.captionBar}>
        <WellCardCaption caption={caption} confidence={latestConfidence} />
      </div>
    </Card>
  );
};

export default WellCardSimple;
