import React, {useEffect} from "react";
import compose from "lodash/fp/compose";
import {connect} from "react-redux";
import Paper from "@material-ui/core/Paper/Paper";
import {SizeMe} from "react-sizeme";
import withStyles from "@material-ui/core/styles/withStyles";

import {fetchCompressiveStress} from "actions/wells/well-health";
import getUser from "utils/get-user";
import {STRESS_COLORS} from "constants/well-health";
import unitTypes from "utils/unit-types";

import CompressionGraph from "../compression-graph";

const styles = ({typography, spacing, colors}) => ({
  panel: {
    padding: spacing(3, 4),
    height: "100%",
    boxSizing: "border-box",
  },

  subtitle: {
    ...typography.subtitle1,
    fontSize: typography.subtitle2.fontSize,
    color: colors.warmGrey[500],
  },

  grid: {
    display: "grid",
    gridTemplateColumns: "auto 100px",
    gridTemplateRows: "auto 220px",
    gridTemplateAreas: '"title kpi" "graph graph"',
  },

  title: {
    gridArea: "title",
  },

  kpi: {
    gridArea: "kpi",
  },

  graph: {
    gridArea: "graph",
    alignSelf: "end",
  },
});

const mapStateToProps = ({wells, wellHealth}) => {
  return {
    ...wellHealth.compressiveStress,
    ...wellHealth.date,
  };
};

const mapDispatchToProps = {
  fetchCompressiveStress,
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
);

const CompressiveStressPanel = ({
  well,
  classes,
  fetchCompressiveStress,
  fetched,
  startDate,
  endDate,
  stressSeries,
  medianStressSeries,
  compressedRodsSeries,
  medianCompressedRodsSeries,
}) => {
  const {downholeLocation, wellId} = well;

  useEffect(
    () => {
      if (!fetched) {
        fetchCompressiveStress(
          wellId,
          downholeLocation,
          startDate.format("YYYY-MM-DD"),
          endDate.format("YYYY-MM-DD"),
        );
      }
    },
    [
      fetched,
      fetchCompressiveStress,
      wellId,
      downholeLocation,
      startDate,
      endDate,
    ],
  );

  const unitLabel =
    getUser().unitsOfMeasure.toLowerCase() === unitTypes.imperial
      ? "kPsi"
      : "MPa";

  return (
    <Paper className={classes.panel}>
      {/*
      Important! Size me is used here because of an outstanding bug with react-vis and canvases series like the ones
      used in the CompressionGraph component. Due to the bug, we have to manually handle finding and setting the
      width and controlling when to redraw the component.
      */}
      <SizeMe>
        {({size}) => (
          <>
            <CompressionGraph
              title="Compressive Stress"
              width={size.width}
              series={stressSeries}
              medianSeries={medianStressSeries}
              yLabel={`Compression (${unitLabel})`}
              seriesColor={STRESS_COLORS.STRESS}
              medianSeriesColor={STRESS_COLORS.STRESS_MEDIAN}
              showSetpointAxis={false}
              unitLabel={unitLabel}
              forceDomainZero={true}
              fetched={fetched}
            />

            <CompressionGraph
              title="Rods in Compression"
              width={size.width}
              series={compressedRodsSeries}
              medianSeries={medianCompressedRodsSeries}
              yLabel="Rods"
              seriesColor={STRESS_COLORS.RODS}
              medianSeriesColor={STRESS_COLORS.RODS_MEDIAN}
              showSetpointAxis={true}
              unitLabel="Rods"
              forceDomainZero={false}
              fetched={fetched}
            />
          </>
        )}
      </SizeMe>
    </Paper>
  );
};

export default enhance(CompressiveStressPanel);
