export const STANDARD_SURFACE = "0";
export const STANDARD_DOWNHOLE = "1";
export const VFD_SURFACE = "3";
export const VFD_DOWNHOLE = "4";

export default {
  STANDARD_SURFACE,
  STANDARD_DOWNHOLE,
  VFD_SURFACE,
  VFD_DOWNHOLE,
};
