import React, {useState} from "react";
import ReactDOM from "react-dom";
import {connect} from "react-redux";
import findLastIndex from "lodash/findLastIndex";
import moment from "moment";
import {XAxis} from "react-vis";

import noop from "utils/noop";
import {VIEW} from "constants/well-health";

import SetPointMarker from "../set-point-marker";
import Tooltip, {TooltipScheduledDowntime, TooltipSpeedRange} from "../tooltip";

const defaultProps = {
  tickSize: 0,
  setFocusedSetpoint: noop,
  unit: "weeks",
};

const tooltipComponents = {
  [VIEW.SPEED_RANGE]: TooltipSpeedRange,
  [VIEW.SCHEDULED_DOWNTIME]: TooltipScheduledDowntime,
};

const mapStateToProps = ({wellHealth}) => ({
  ...wellHealth.setpointChanges,
  ...wellHealth.date,
});

const dayFormatter = (value, index, changes, days) => {
  // Based on the day number, check if the first of the month is in this week.
  const start = moment(days[index], "YYYY-MM-DD")
    .utc()
    .startOf("day");
  const dayIsFirstOfMonth = start.date() === 1;
  const changeIndex = findLastIndex(changes, {day: start.dayOfYear()});

  return {
    label: dayIsFirstOfMonth ? start.format("MMM") : undefined,
    change: changes[changeIndex],
  };
};

const weekFormatter = (value, index, changes, weeks) => {
  // Based on the week number, check if the first of the month is in this week.
  const start = moment(weeks[index], "YYYY-W")
    .utc()
    .startOf("day");
  const end = start.clone().add(1, "weeks");
  const weekContainsFirstOfMonth = start.month() !== end.month();
  const changeIndex = findLastIndex(changes, {week: start.isoWeek()});

  return {
    label: weekContainsFirstOfMonth ? end.format("MMM") : undefined,
    change: changes[changeIndex],
  };
};

function SetPointAxis({unit, days, weeks, changes, ...props}) {
  const [focusedSetpoint, setFocusedSetpoint] = useState();

  const data = unit === "days" ? days : weeks;
  const formatter = unit === "days" ? dayFormatter : weekFormatter;

  return (
    <>
      <XAxis
        {...props}
        tickValues={data}
        tickFormat={(value, index) => {
          const {change, label} = formatter(value, index, changes, data);

          return (
            <SetPointMarker
              change={change}
              label={label}
              setFocusedSetpoint={setFocusedSetpoint || noop}
            />
          );
        }}
      />

      {// Create a portal to the body if the tooltip should be shown.
      // The X-Axis above is rendered into the react-vis graph which is not where we want to try and insert a tooltip.
      focusedSetpoint !== undefined
        ? ReactDOM.createPortal(
            <Tooltip
              component={tooltipComponents[focusedSetpoint.type]}
              data={focusedSetpoint}
            />,
            document.body,
          )
        : null}
    </>
  );
}

SetPointAxis.defaultProps = defaultProps;
SetPointAxis.requiresSVG = true;

export default connect(mapStateToProps)(SetPointAxis);
