import React from "react";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = ({colors}) => ({
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    backgroundColor: "rgb(255, 255, 255)",
    display: "flex",
    alignItems: "center",
    color: colors.warmGrey[300],
  },
  metaIcon: {
    height: 24,
    width: 24,
  },
  grey: {
    color: "rgba(0, 0, 0, 0.38)",
  },
});

/**
 * Container for IconButtons to be displayed on the right hand side of the subheader
 *
 * Children will be passed the `parentButtonGroupClasses` prop that contains styles
 * to help them fit into this container properly
 */
const SubheaderButtons = ({children, classes}) => (
  <>
    <Grid item style={{flexGrow: 1}} />
    <Grid item>
      <div className={classes.buttonGroup}>
        {React.Children.map(children, Child =>
          React.cloneElement(Child, {parentButtonGroupClasses: classes}),
        )}
      </div>
    </Grid>
  </>
);

export default withStyles(styles)(SubheaderButtons);
