import React from "react";

import SettingsUpdated from "../settings-updated";

export default props => {
  return (
    <SettingsUpdated
      {...props}
      changesRoute="/setup/safe-operating-parameters"
      settingsName="safe operating parameters"
    />
  );
};
