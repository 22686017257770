import React from "react";
import {connect} from "react-redux";

import capitalize from "lodash/capitalize";
import startCase from "lodash/startCase";

import Grid from "@material-ui/core/Grid";

import {fetch} from "actions/field-names";

import FormButton from "components/form-button";
import FormHeader from "components/form-header";
import PaperContainer from "components/paper-container";

import statesProvinces from "constants/states-provinces";

import getPrivileges from "utils/get-privileges";
import privilegeTypes from "utils/privilege-types";

import BasicInformationEditView from "./basic-information-edit-view";
import getStateLabel from "./get-state-label";
import {cardLabelFactory, cardValueFactory} from "../cards";

const mapProvinceState = well => {
  const {country, provinceState} = well;

  const countryProvinceStates = statesProvinces[country] || [];

  return (
    countryProvinceStates.find(s => s.value === provinceState) || {
      text: "",
    }
  ).text;
};

class BasicInformationReadView extends React.Component {
  componentWillMount() {
    const {dispatch} = this.props;

    dispatch(fetch());
  }

  constructor(props) {
    super(props);

    this.state = {showBasicInformationEditView: false};
  }

  render() {
    const {well} = this.props;

    const privileges = getPrivileges(well.organization.id);

    if (this.state.showBasicInformationEditView) {
      return (
        <BasicInformationEditView
          onClose={() => this.setState({showBasicInformationEditView: false})}
          well={well}
        />
      );
    }

    const CardLabel = cardLabelFactory(7);
    const CardValue = cardValueFactory(5);

    return (
      <PaperContainer extraPadded style={{maxWidth: "500px"}}>
        <Grid container>
          <Grid item xs={12}>
            <FormHeader>Basic Information</FormHeader>
          </Grid>

          <CardLabel label={"Display Name"} />
          <CardValue value={well.displayName} />

          <CardLabel label={"Country"} />
          <CardValue
            value={
              well.country &&
              startCase(
                well.country.toLowerCase() === "unitedstates"
                  ? "United States"
                  : well.country,
              )
            }
          />

          <CardLabel label={getStateLabel(well.country)} />
          <CardValue value={mapProvinceState(well)} />

          <CardLabel label={"Surface Location"} />
          <CardValue value={capitalize(well.surfaceLocation)} />

          <CardLabel label={"Surface Location Latitude"} />
          <CardValue value={well.coords && well.coords[0]} />

          <CardLabel label={"Surface Location Longitude"} />
          <CardValue value={well.coords && well.coords[1]} />

          <CardLabel label={"Downhole Location"} />
          <CardValue value={well.downholeLocation} />

          <CardLabel label={"Field"} />
          <CardValue value={capitalize(well.fieldName)} />

          <CardLabel label={"Pumping Unit"} />
          <CardValue value={capitalize(well.pumpingUnitType)} />

          <Grid item xs={12}>
            {privileges.includes(privilegeTypes.well.configure) && (
              <FormButton
                onClick={() =>
                  this.setState({showBasicInformationEditView: true})
                }
                style={{marginTop: "8px"}}
              >
                Edit Basic Information
              </FormButton>
            )}
          </Grid>
        </Grid>
      </PaperContainer>
    );
  }
}

export default connect()(BasicInformationReadView);
