import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {reduxForm} from "redux-form";

import min from "lodash/min";
import get from "lodash/get";

import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";

import Checkbox from "components/forms/checkbox";
import ListView from "components/list-view";

import searchObject from "utils/search-object";

const HEADER_FOOTER_HEIGHT = 204;
const ITEM_SIZE = 49;
const MIN_VISIBLE_ITEMS = 5;

const mapMinHeight = items => {
  return (
    min([items.length * ITEM_SIZE, MIN_VISIBLE_ITEMS * ITEM_SIZE]) +
    HEADER_FOOTER_HEIGHT
  );
};

const UserListView = props => {
  const {
    assignedWells,
    change,
    containerStyle,
    footer,
    organizationWells,
    searchPhrase,
    formData,
  } = props;

  const filteredWells = Object.values(organizationWells || {}).filter(well =>
    searchObject(well, searchPhrase, [
      "alias",
      "downholeLocation",
      "fieldName",
      "tagsText",
    ]),
  );

  const wellsSelected = Object.keys(assignedWells).filter(
    wellId => assignedWells[wellId],
  );

  const renderSelectAllCheckBox = () => {
    const renderIcon = Icon => <div>{Icon}</div>;
    const countFilteredWells = Object.values(filteredWells || {}).length;
    const countFilteredWellsSelected = Object.values(filteredWells).filter(
      w => assignedWells[w.wellId],
    ).length;

    const toggleSelectedWells = checked => () => {
      (filteredWells || []).forEach(well => {
        change(`wells.${well.wellId}`, checked);
      });
    };

    if (countFilteredWellsSelected === countFilteredWells) {
      return renderIcon(
        <CheckBoxIcon
          style={{cursor: "pointer"}}
          onClick={toggleSelectedWells(false)}
        />,
      );
    } else if (countFilteredWellsSelected === 0) {
      return renderIcon(
        <CheckBoxOutlineBlankIcon
          style={{cursor: "pointer"}}
          onClick={toggleSelectedWells(true)}
        />,
      );
    }

    return renderIcon(
      <IndeterminateCheckBoxIcon
        style={{cursor: "pointer"}}
        onClick={toggleSelectedWells(
          countFilteredWellsSelected > Math.floor(countFilteredWells / 2),
        )}
      />,
    );
  };

  return (
    <ListView
      containerStyle={{
        display: "flex",
        flexDirection: "column",
        minHeight: `${mapMinHeight(filteredWells)}px`,
        overflow: "auto",
        ...containerStyle,
      }}
      data={filteredWells}
      onRowClick={well =>
        change(
          `wells.${well.wellId}`,
          !get(formData, ["values", "wells", well.wellId], false),
        )
      }
      columns={[
        {
          label: renderSelectAllCheckBox,
          labelStyle: {},
          style: {},
          render: well => {
            return (
              <Checkbox
                name={`wells.${well.wellId}`}
                containerStyle={{justifySelf: "flex-start"}}
                style={{width: 24, padding: 0}}
              />
            );
          },
          xs: 1,
        },
        {
          label: "Well",
          render: well => well.displayName,
          sortBy: "displayName",
          xs: 5,
        },
        {
          label: "Field",
          render: well => well.fieldName,
          sortBy: "fieldName",
          xs: 6,
        },
      ]}
      footer={footer(wellsSelected)}
    />
  );
};

const createListView = form => {
  const mapStateToProps = state => ({
    formData: state.form[form],
  });

  let Component = reduxForm({
    form,
    enableReinitialize: true,
  })(UserListView);

  Component = connect(mapStateToProps)(Component);
  Component = withRouter(Component);

  return Component;
};

export default createListView;
