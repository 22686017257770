import {
  load as convertLoad,
  position as convertPosition,
} from "@ambyint/common/unit-converter";

export default acceptedData => {
  const {load, position} = acceptedData;

  return {
    ...acceptedData,
    load: convertLoad.convert({
      from: load.units,
      to: "N",
      value: Number(load.value),
    }),
    position: convertPosition.convert({
      from: position.units,
      to: "m",
      value: Number(position.value),
    }),
  };
};
