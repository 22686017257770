import compose from "lodash/fp/compose";
import constant from "lodash/fp/constant";
import keys from "lodash/fp/keys";
import pick from "lodash/fp/pick";
import values from "lodash/fp/values";

const shades = [200, 300, 400, 500, 600, 700, 800, 900];
const [primary, ...secondary] = shades;

const shadesOf = compose(
  values,
  pick(secondary),
);

const withTheme = theme => factory => {
  return keys(theme).reduce((memo, key) => {
    const palette = theme[key];
    const generator = factory(shadesOf(palette));

    memo[key] = {
      primary: constant(palette[primary]),
      secondary: () => generator.next().value,
    };

    return memo;
  }, {});
};

export default withTheme;
