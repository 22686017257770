import {compose} from "recompose";

import React from "react";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

import Chart from "./chart";

const styles = ({palette}) => ({
  container: {
    position: "relative",
  },
  title: {
    marginLeft: 45,
    fontWeight: 400,
  },
  left: {
    position: "absolute",
    width: "2.5rem",
  },
  right: {
    position: "relative",
    left: "2.5rem",
    width: "calc(100% - 2.5rem)",
  },
  xAxis: {
    marginRight: "5rem",
    textAlign: "right",
    color: "#999",
  },
  yAxisWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  yAxis: {
    display: "block",
    transform: "rotate(270deg) translateX(-80%)",
    whiteSpace: "nowrap",
    color: "#999",
  },
});

const AvgLiquidProdVsSpm = ({classes, unitsOfMeasure}) => (
  <div className={classes.container}>
    <Typography className={classes.title} variant="h6">
      Total Daily Liquid Production vs Running SPM
    </Typography>

    <div className={classes.left}>
      <div className={classes.yAxisWrapper}>
        <Typography variant="body2" className={classes.yAxis}>
          Total Daily Liquid Production{" "}
          {unitsOfMeasure.toLowerCase() === "metric" ? "m3/d" : "bbl/d"}
        </Typography>
      </div>
    </div>
    <div className={classes.right}>
      <Chart />
      <Typography variant="body2" className={classes.xAxis}>
        Running SPM
      </Typography>
    </div>
  </div>
);

export default compose(withStyles(styles))(AvgLiquidProdVsSpm);
