import memoize from "lodash/memoize";
import groupEventsByType from "utils/group-events-by-type";

import filterShutdownPeriods from "./filter-shutdown-periods";
import filterEventsByDateRange from "./filter-events-by-date-range";

export default memoize((events, start, end) =>
  filterShutdownPeriods(
    groupEventsByType(filterEventsByDateRange(events, start, end)),
  ),
);
