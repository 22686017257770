import addAuthHeaders from "epics/add-auth-headers";
import querystring from "query-string";

export default async (url, queryOptions = {}, options = {}) => {
  const query = querystring.stringify(queryOptions);

  const response = await fetch(
    `${process.env.REACT_APP_API}${url}${query ? `?${query}` : ""}`,
    addAuthHeaders({
      ...options,
      ...(options && options.body && options.body.constructor === Object
        ? {body: JSON.stringify(options.body)}
        : {}),
    }),
  );

  if (response.status === 204 || response.status === 202) {
    return Promise.resolve();
  }

  if (response.status >= 300) {
    throw response;
  }

  return response.json();
};
