import integer from "utils/validation/integer";
import max from "utils/validation/max";
import min from "utils/validation/min";
import number from "utils/validation/number";
import required from "utils/validation/required";
import validateFieldsOf from "utils/validation/validate-fields-of";

const MAX_MINUTES = 60 * 24;
const MIN_MINUTES = 5;

export default values => {
  const validateRecommendedData = validateFieldsOf(values.acceptedData);

  const validate = field => ({
    ...validateRecommendedData(field, x => min(x, MIN_MINUTES)),
    ...validateRecommendedData(field, x => max(x, MAX_MINUTES)),
    ...validateRecommendedData(field, x => integer(x)),
    ...validateRecommendedData(field, x => number(x)),
    ...validateRecommendedData(field, x => required(x)),
  });

  return {
    ...validate("runTime"),
    ...validate("stopTime"),
  };
};
