import get from "lodash/get";

import {createSwitchEpic} from "epics/create-epic";
import {replace} from "actions/history";
import {types} from "actions/organization-view";
import {fetch} from "actions/wells";
import {setWellsFetched} from "actions/wells";
import {resetState} from "actions";
import {
  saveRecentOrganization,
  saveSessionOrganization,
} from "utils/recent-organizations";
import {selectOrganization as selectConfigurationOrganization} from "actions/organizations";

export const setOrganizationView = createSwitchEpic(
  [types.set],
  async ({payload}, store) => {
    const user = get(store.getState(), "auth.user");
    const userId = user.clientUserId;
    const organizations = user.assignedOrganizations;
    const organizationId = payload.currentOrganization;

    if (organizationId) {
      saveRecentOrganization(userId, organizationId);
      saveSessionOrganization(userId, organizationId);
    }

    // If the user has elevated permissions, fetch organization meta information
    // for later configuration
    const hasElevatedOrganizationPermissions =
      organizationId &&
      organizations.find(
        org =>
          org.organizationId === organizationId && org.isInvitedUserApprover,
      );

    return [
      setWellsFetched(false),
      ...(payload.redirectTo ? [replace({path: payload.redirectTo})] : []),
      ...(payload.shouldReset ? [resetState()] : []),
      ...(hasElevatedOrganizationPermissions
        ? [
            selectConfigurationOrganization({
              organizationId,
            }),
          ]
        : []),
      fetch({}),
    ];
  },
);
