import createTheme from "@material-ui/core/styles/createTheme";
import createPalette, {light} from "@material-ui/core/styles/createPalette";
import {fade} from "@material-ui/core/styles/colorManipulator";

import {muiPickersOverrides} from "./mui-pickers-overrides";
import * as updatedColors from "./updated-colors";
import colors from "./colors";

const palette = {
  ...createPalette({
    primary: {
      light: colors.ambyOrange[300],
      main: colors.ambyOrange[500],
      dark: colors.ambyOrange[700],
    },
    secondary: {
      light: colors.blueGrey[300],
      main: colors.blueGrey[500],
      dark: colors.blueGrey[700],
    },
  }),
  text: {
    ...light.text,
  },
};

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  colors: updatedColors,
  overrides: {
    ...muiPickersOverrides,
    MuiLinearProgress: {
      root: {
        height: "3px",
      },
    },
    MuiStepPositionIcon: {
      text: {
        fill: "white",
      },
    },
    MuiTab: {
      wrapper: {
        fontSize: "14px !important",
      },
      root: {
        height: 55,
      },
    },
    MuiTableRow: {
      head: {
        height: "auto",
      },
      root: {
        height: "auto",
      },
    },
    MuiOutlinedInput: {
      root: {
        "&$focused $notchedOutline": {
          // Default for this component is to flash the primary theme color when selected.
          // A UI usecase in the home page well view controls required us to stop this effect for a better UX
          borderColor: colors.border,
          borderWidth: 1,
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          // Default for this component during hover is to set the border color and no background color update
          // A UI use case in the home page well view controls requires this component to have the same hover appearance as other button controls
          borderColor: colors.border,
          backgroundColor: fade(
            palette.text.primary,
            palette.action.hoverOpacity,
          ),
        },
      },
    },
  },
  palette,
  typography: {
    useNextVariants: true,
  },
});

export default theme;
