import PAGING_SOURCES from "constants/paging-sources";

export default data => {
  const {existingQuerystring, newQuerystring, source, state} = data;
  const {hasInitialized} = state.paging;
  const hasNewOrUpdatedQuerystring =
    newQuerystring.source != null &&
    (!hasInitialized || existingQuerystring.filter !== newQuerystring.filter);
  const sourceHasChanged = !!hasInitialized && source !== state.paging.source;

  let filter;

  if (hasNewOrUpdatedQuerystring) {
    filter = decodeURIComponent(newQuerystring.filter);
  } else if (sourceHasChanged) {
    filter =
      source === PAGING_SOURCES.inbox
        ? state.wells.search.criteria
        : state.alerts.activeFilter;
  } else {
    filter = state.paging.filter;
  }

  return {
    filter,
    ...data,
  };
};
