import {types} from "./types";

export const setSearchResults = ({criteria, results}) => {
  return {
    type: types.setSearchResults,
    payload: {
      criteria,
      results,
    },
  };
};
