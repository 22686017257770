import get from "lodash/get";

import RESPONSE_STATUSES from "constants/response-statuses";

export const getDisplayedSetting = settings => {
  const requestStatus = get(settings, "request.status");

  return requestStatus === RESPONSE_STATUSES.PENDING
    ? get(settings, "request.payload")
    : get(settings, "setting", {});
};

export default getDisplayedSetting;
