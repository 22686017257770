import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import React from "react";

import formatDate from "@ambyint/common/utils/format/format-date";
import formatDuration from "@ambyint/common/utils/format/format-duration";

import AlertActionButtons from "components/alert-action-buttons";
import determineIndefiniteArticle from "utils/determine-indefinite-article";
import findAlert from "utils/alerts/find-alert";
import getDisplayName from "utils/user/get-display-name";

import FeedItem from "../feed-item";
import getEventAlertType from "../get-event-alert-type";

const getDurationText = acknowledged => {
  const on = moment(acknowledged.on).startOf("day");
  const until = moment(acknowledged.until).startOf("day");

  return formatDuration(on, until);
};

const getDays = event => {
  const {acknowledged} = event.value;

  if (!acknowledged || !acknowledged.on || !acknowledged.until) return "";

  return ` for ${getDurationText(acknowledged)} (until ${formatDate(
    event.value.acknowledged.until,
  )})`;
};

const mapStateToProps = (state, props) => ({
  alert: findAlert(state.alerts.all, props.event.value.alertId),
});

const Component = props => {
  const {alert, event} = props;

  const actions =
    alert && alert.status !== "resolved"
      ? {
          actions: (
            <Grid container justify="flex-end" spacing={0}>
              <AlertActionButtons alert={alert} />
            </Grid>
          ),
        }
      : {};

  const alertType = getEventAlertType(event);

  return (
    <FeedItem {...actions} event={event}>
      {getDisplayName(event)}
      {` has acknowledged ${determineIndefiniteArticle(alertType)} `}
      <strong>{alertType}</strong>
      {` ${getDays(event)}.`}
    </FeedItem>
  );
};

export default connect(mapStateToProps)(Component);
