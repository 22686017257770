import React from "react";
import {connect} from "react-redux";

import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Typography from "@material-ui/core/Typography";

import {deleteGracePeriod} from "actions/wells";
import Aux from "components/aux";
import {LABEL_MAP} from "constants/grace-periods";

const containerStyle = {
  alignItems: "center",
  display: "flex",
  width: "100%",
};

const controlsStyle = {
  marginLeft: "auto",
};

const labelStyle = {
  marginRight: "15px",
  flexGrow: 1,
};

const valueStyle = {
  flexGrow: 1,
};

const Component = props => {
  const {canEdit, dispatch, editing, gracePeriod, onEdit, well} = props;

  return (
    <div style={containerStyle}>
      <div style={labelStyle}>
        <Typography variant="body1">{LABEL_MAP[gracePeriod.type]}</Typography>
      </div>
      <div style={valueStyle}>
        <Typography variant="subtitle1">{`${
          gracePeriod.value
        } Minutes`}</Typography>
      </div>
      {canEdit && (
        <Aux>
          <IconButton
            disabled={editing}
            onClick={() => onEdit(gracePeriod.type)}
            style={controlsStyle}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            disabled={editing}
            onClick={() => {
              dispatch(
                deleteGracePeriod({
                  well,
                  data: {type: gracePeriod.type},
                }),
              );
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Aux>
      )}
    </div>
  );
};

export default connect()(Component);
