export const types = {
  fetch: "couplings:fetch",
  filter: "couplings:filter",
  set: "couplings:set",
};

export const fetch = ({pin}) => {
  return {
    type: types.fetch,
    payload: {pin},
  };
};

export const filter = ({phrase}) => {
  return {
    type: types.filter,
    payload: {
      phrase,
    },
  };
};

export const set = ({data}) => {
  return {
    type: types.set,
    payload: {
      data,
    },
  };
};
