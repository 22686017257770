import get from "lodash/get";
import moment from "moment";

import * as organizationEventTypes from "constants/organization-event-types";

const sortByDate = events =>
  events.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));

const isPerformedOnAmbyint = event => {
  return get(event, "performedOn.email", "").includes("@ambyint.com");
};

export default organization => {
  const {events = [], performedOn, performedBy, range, type} = organization;

  const isPerformedOn = event =>
    event.performedOn && event.performedOn.clientUserId === performedOn;

  const isPerformedBy = event =>
    event.performedBy && event.performedBy.clientUserId === performedBy;

  const isInRange = event =>
    moment(event.createdOn).isBetween(range.start, range.end, null, "[]");

  const isType = event =>
    (!type && Object.values(organizationEventTypes).includes(event.type)) ||
    event.type === type;

  let filtered = [...events];

  filtered = filtered.filter(event => {
    return (
      !isPerformedOnAmbyint(event) &&
      (!performedBy || isPerformedBy(event)) &&
      (!performedOn || isPerformedOn(event)) &&
      isInRange(event) &&
      isType(event)
    );
  });

  return sortByDate(filtered);
};
