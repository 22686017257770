import {show} from "actions/snackbar";
import {types} from "actions/wells";
import fetch from "epics/async-fetch";

export default action$ => {
  return action$.ofType(types.setFaultRecovery).switchMap(async ({payload}) => {
    const {downholeLocation, enabled, well} = payload;

    try {
      await fetch(
        `/optimization/well/${encodeURIComponent(
          downholeLocation,
        )}/fault/recovery`,
        {},
        {
          method: "PUT",
          body: JSON.stringify({
            downholeLocation,
            enabled,
          }),
        },
      );

      return show({
        message: `Your request to ${
          enabled ? "enable" : "disable"
        } auto fault recovery for ${
          well.displayName
        } was submitted successfully!`,
      });
    } catch (err) {
      console.warn(err);

      return show({
        message: `Your request to ${
          enabled ? "enable" : "disable"
        } auto fault recovery for ${
          well.displayName
        } failed. Please try again.`,
      });
    }
  });
};
