import {number} from "./number";

export const max = max => value => {
  number(value);

  if (value > max) {
    throw new Error(
      `Expected value to be a \`number less than or equal to ${max}\` but received \`${value}\``,
    );
  }
};
