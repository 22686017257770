import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import {connect} from "react-redux";
import Divider from "@material-ui/core/Divider";
import {fetchAnalysis, fetchLatestCards} from "actions/wells";
import Grid from "@material-ui/core/Grid";
import HEADER_HEIGHT from "constants/header-height";
import IconButton from "@material-ui/core/IconButton";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import {push} from "react-router-redux";
import React from "react";
import Typography from "@material-ui/core/Typography";

import colors from "theme/colors";
import DisplayCards from "components/d3-cards";
import formatDateTime from "@ambyint/common/utils/format/format-date-time";
import getLatestCard from "utils/well/get-latest-card";
import getWellAlerts from "utils/alerts/get-well-alerts";
import WellTags from "components/well-tags";

import Alerts from "./alerts";
import SPMSummary from "./spm-summary";
import UptimeSummary from "./uptime-summary";
import WellStatus from "./well-status";

const styles = {
  a: {
    color: colors.ambyBlue[500],
  },
  h3: {
    margin: 0,
  },
  padding: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    width: "100%",
  },
};

const captionStyle = {
  color: colors.grey[600],
  textTransform: "uppercase",
  fontSize: "12px",
  marginBottom: "5px",
};

const headerRowStyle = {
  height: `${HEADER_HEIGHT}`,
  padding: "10px 16px",
};

const rowStyle = {
  padding: "16px",
};

const mapStateToProps = (state, props) => ({
  alerts: getWellAlerts(state.alerts.all, props.well.downholeLocation),
  isDialog: state.wellSummaryDialog.isOpen,
});

export default connect(mapStateToProps)(
  class extends React.Component {
    componentWillMount() {
      const {dispatch, well} = this.props;
      const {downholeLocation} = well;

      this.viewWellAnalysis = this.viewWellAnalysis.bind(this);
      this.viewWellDashboard = this.viewWellDashboard.bind(this);

      dispatch(fetchLatestCards({downholeLocation}));
      dispatch(
        fetchAnalysis({
          downholeLocation,
          start: moment()
            .subtract(7, "day")
            .toISOString(),
        }),
      );
    }

    viewWellAnalysis() {
      const {dispatch, hide, isDialog, well} = this.props;

      if (isDialog && hide) hide();
      dispatch(
        push(`/well/${encodeURIComponent(well.downholeLocation)}/analysis`),
      );
    }

    viewWellDashboard() {
      const {dispatch, hide, isDialog, well} = this.props;

      if (isDialog && hide) hide();
      dispatch(
        push(`/well/${encodeURIComponent(well.downholeLocation)}/dashboard`),
      );
    }

    renderLatestCard() {
      const {well} = this.props;

      const card = getLatestCard(well);

      if (!card) return null;

      return (
        <Grid item style={{maxWidth: "480px"}} xs>
          <Typography variant="caption" style={captionStyle}>
            {`${
              card.shutdown ? "Shutdown " : ""
            }Card occurred on ${formatDateTime(card.createdOn, {
              timezone: true,
            })}`}
          </Typography>
          <DisplayCards {...card} margin={{top: 10, right: 5}} width={480} />
        </Grid>
      );
    }

    renderLoading() {
      return this.isFetching() ? (
        <div style={{marginLeft: "16px"}}>
          <CircularProgress size={20} />
        </div>
      ) : null;
    }

    isFetching() {
      const {well} = this.props;

      return !well || !well.eventsByType || well.eventsByType.isFetching;
    }

    shouldRenderCard() {
      const {well} = this.props;

      if (this.isFetching() || !getLatestCard(well)) return false;

      return true;
    }

    shouldRenderSpmSummary() {
      if (this.isFetching()) return false;

      const {well} = this.props;

      return [
        ...(well.eventsByType.rrpm || []),
        ...(well.eventsByType.spm || []),
      ].some(event =>
        moment(event.createdOn).isSameOrAfter(moment().subtract(24, "hours")),
      );
    }

    render() {
      const {alerts, hide, well} = this.props;

      const hasEvents = well && well.events && well.events.length;

      return (
        <div>
          <Grid
            alignItems="center"
            container
            spacing={0}
            style={headerRowStyle}
          >
            <Grid alignItems="center" container item spacing={0} xs={6}>
              <h3 style={styles.h3}>
                <a
                  style={styles.a}
                  href={`#/well/${encodeURIComponent(well.downholeLocation)}`}
                >
                  {well.displayName}
                </a>
              </h3>
              {this.renderLoading()}
            </Grid>
            <Grid
              alignItems="center"
              justify="flex-end"
              container
              item
              spacing={0}
              xs={6}
            >
              <WellTags well={well} />
              <Button color={"primary"} onClick={this.viewWellDashboard}>
                Dashboard
              </Button>
              {hasEvents ? (
                <Button color={"primary"} onClick={this.viewWellAnalysis}>
                  Analysis
                </Button>
              ) : null}
              <IconButton aria-label="Close Well" onClick={hide}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Divider />
          <Grid container style={rowStyle}>
            <Grid item xs={6}>
              <WellStatus well={well} />
            </Grid>
            <Grid item xs={6}>
              <UptimeSummary well={well} />
            </Grid>
          </Grid>
          {this.shouldRenderSpmSummary() || this.shouldRenderCard() ? (
            <Grid container style={rowStyle}>
              {this.renderLatestCard()}
              <Grid item xs>
                <SPMSummary well={well} />
              </Grid>
            </Grid>
          ) : null}
          {!isEmpty(alerts) ? (
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Alerts alerts={alerts} />
              </Grid>
            </Grid>
          ) : null}
        </div>
      );
    }
  },
);
