import {of} from "rxjs/observable/of";
import {types as authTypes} from "actions/auth";
import {set} from "actions/on-call";
import fetch from "epics/fetch";
import getCookie from "utils/get-cookie";

export default (action$, store) => {
  return action$.ofType(authTypes.set).switchMap(({payload}) => {
    const token = getCookie("id_token");

    if (token && payload.user && payload.user.clientUserId) {
      const url = `/client-experience/user/${encodeURIComponent(
        payload.user.clientUserId,
      )}/calloutSchedulePeriod/current`;

      return fetch(url).map(res => {
        if (res.status === 200) {
          return set({
            schedule: res.response ? [res.response] : [],
          });
        } else {
          return set({
            schedule: null,
          });
        }
      });
    } else {
      return of(
        set({
          schedule: null,
        }),
      );
    }
  });
};
