import {replace} from "react-router-redux";

import {createSwitchEpic} from "epics/create-epic";
import {setAccess, types} from "actions/organizations";
import {show} from "actions/snackbar";
import fetch from "epics/async-fetch";

export default createSwitchEpic([types.saveAccess], async ({payload}) => {
  try {
    const {
      accessLevel,
      clientUserId,
      isInvitedUserApprover,
      organizationId,
    } = payload;

    await fetch(
      `/organization/${encodeURIComponent(
        payload.organizationId,
      )}/user/${encodeURIComponent(payload.clientUserId)}/access`,
      {},
      {
        method: "PUT",
        body: JSON.stringify({
          accessLevel,
          clientUserId,
          isInvitedUserApprover,
          organizationId,
        }),
      },
    );

    return [
      show({
        message: "Your request to update well access submitted successfully!",
      }),
      setAccess({
        accessLevel,
        clientUserId,
        isInvitedUserApprover,
        organizationId,
      }),
    ];
  } catch (err) {
    if (err.status === 401 || err.status === 403) {
      return [
        replace("/"),
        show({
          message: "Unauthorized to update well access",
        }),
      ];
    }
    return [replace(`/error?code=${err.status}`)];
  }
});
