import React, {useMemo} from "react";
import get from "lodash/get";
import {connect} from "react-redux";

import {features} from "./features";

const mapFeatures = features =>
  features.reduce((acc, {key, active}) => {
    acc[key] = active;

    return acc;
  }, {});

const mapStateToProps = ({auth}) => ({auth});

const useEnabledFeatureFlags = auth => {
  const userFeatures = get(auth, "user.features", []);

  return useMemo(() => mapFeatures(userFeatures), [userFeatures]);
};

export const WithFeatures = connect(mapStateToProps)(({children, auth}) => (
  <features.Provider value={useEnabledFeatureFlags(auth)}>
    {children}
  </features.Provider>
));
