import {compose} from "recompose";
import Grid from "@material-ui/core/Grid";
import isEmpty from "lodash/isEmpty";
import noop from "lodash/noop";
import React from "react";

import FormButton from "components/form-button";
import FormHeader from "components/form-header";
import PaperContainer from "components/paper-container";
import {roundWithUnits} from "utils/round-with-units";
import withFeatureFlag from "components/with-feature-flag";

import {cardLabelFactory, cardValueFactory} from "../../cards";
import getDisplayedSetting from "../get-displayed-setting";
import StatusIndicator from "../status-indicator";
import {Unconfigured} from "../unconfigured";
import withSettingStatus from "../with-setting-status";

const StructuralLoadReadView = ({
  canEdit = false,
  onEdit = noop,
  styles = {},
  isStatusVisible,
  settings,
}) => {
  const setting = getDisplayedSetting(settings);

  const {consecutiveMinLoad, consecutivePeakLoad, minLoad, peakLoad} = setting;

  const CardLabel = cardLabelFactory(6);
  const CardValue = cardValueFactory(6);

  return (
    <PaperContainer extraPadded style={styles.container}>
      <Grid container>
        <Grid item xs={12}>
          <FormHeader>
            Structural Load Setpoint
            {isStatusVisible && <StatusIndicator settings={settings} />}
          </FormHeader>
        </Grid>
        {isEmpty(setting) && <Unconfigured />}

        {!isEmpty(setting) && (
          <>
            <CardLabel label={"Min load"} />
            <CardValue value={roundWithUnits(minLoad, 2)} />

            <CardLabel label={"Peak load"} />
            <CardValue value={roundWithUnits(peakLoad, 2)} />

            <CardLabel label={"Consecutive min load"} />
            <CardValue value={consecutiveMinLoad} />

            <CardLabel label={"Consecutive peak load"} />
            <CardValue value={consecutivePeakLoad} />
          </>
        )}
      </Grid>

      {canEdit && (
        <Grid item xs={12}>
          <FormButton onClick={onEdit}>
            Edit Structural Load Setpoint
          </FormButton>
        </Grid>
      )}
    </PaperContainer>
  );
};

export default compose(
  withSettingStatus,
  withFeatureFlag("set-lufkin-structural-load"),
)(StructuralLoadReadView);
