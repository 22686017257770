import moment from "moment";

export default ({calloutPeriods}) => {
  return calloutPeriods.find(p =>
    moment().isBetween(
      p.calloutSchedulePeriodStartDate,
      p.calloutSchedulePeriodEndDate,
    ),
  );
};
