import isEmpty from "lodash/isEmpty";
import Grid from "@material-ui/core/Grid";

import React from "react";

import {Header} from "./header";
import {Section} from "./section";

export const PumpHealth = props => {
  const {pumpHealth} = props.classifications;

  if (isEmpty(pumpHealth)) return null;

  return (
    <Grid item xs={6}>
      <Header label={"Pump Health"} />
      <Section classifications={pumpHealth} />
    </Grid>
  );
};
