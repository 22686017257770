import math from "mathjs";

function parseSaveResistance(resistance) {
  return {
    dampingCoefficient: math.round(resistance.dampingCoefficient, 2),
    enableStuffingBoxFrictionAutoCorrection: !!resistance.enableStuffingBoxFrictionAutoCorrection,
    frictionCoefficient: math.round(resistance.frictionCoefficient, 2),
  };
}

export default parseSaveResistance;
