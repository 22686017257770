import React from "react";
import {connect} from "react-redux";
import {reduxForm} from "redux-form";
import hasIn from "lodash/hasIn";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";

import getSafeOperatingSpeedMax from "@ambyint/common/utils/wells/get-safe-operating-speed-max";
import getSafeOperatingSpeedMin from "@ambyint/common/utils/wells/get-safe-operating-speed-min";
import getWellSpeedUnits from "@ambyint/common/utils/wells/get-well-speed-units";

import {hideSetSpeed} from "actions/dialogs";
import {setSpeed} from "actions/wells";
import Aux from "components/aux";
import Dialog from "components/dialog";
import TextField from "components/forms/text-field";

import validate from "./validate";

const mapStateToProps = state => {
  const {isOpen, well} = state.dialogs.setSpeed;

  return {
    ...(well
      ? {
          initialValues: {
            speed: well.operation.targetSpeed
              ? well.operation.targetSpeed.speed.value
              : null,
          },
        }
      : {}),
    isOpen,
    well,
  };
};

const set = (dispatch, well) => {
  return values => {
    dispatch(
      setSpeed({
        downholeLocation: well.downholeLocation,
        speed: values.speed,
        well,
      }),
    );
    close(dispatch)();
  };
};

const close = dispatch => {
  return () => {
    dispatch(hideSetSpeed());
  };
};

const ContentText = props => {
  const well = props.well;

  if (hasIn(well, "operation.targetSpeed.speed")) {
    return (
      <DialogContentText>
        The well is currently running at {well.conditions.speed.label} with a
        target of {well.operation.targetSpeed.speed.value}{" "}
        {well.operation.targetSpeed.speed.units}
      </DialogContentText>
    );
  }

  return (
    <DialogContentText>A target speed is not currently set.</DialogContentText>
  );
};

const SafeOperatingParametersText = props => {
  const {well} = props;

  const maxSpeed = getSafeOperatingSpeedMax(well);
  const minSpeed = getSafeOperatingSpeedMin(well);

  const speedUnits = getWellSpeedUnits(well);

  return (
    <Aux>
      <DialogContentText>
        Minimum safe operating speed for this well is{" "}
        {`${minSpeed} ${speedUnits}`}.
      </DialogContentText>
      <DialogContentText>
        Maximum safe operating speed for this well is{" "}
        {`${maxSpeed} ${speedUnits}`}.
      </DialogContentText>
    </Aux>
  );
};

let Component = props => {
  const {dispatch, handleSubmit, isOpen, pristine, submitting, well} = props;

  if (!well) {
    return null;
  }

  return (
    <Dialog open={isOpen} onClose={close(dispatch)}>
      <form onSubmit={handleSubmit(set(dispatch, well))}>
        <DialogTitle>{`Set speed for ${well.displayName}`}</DialogTitle>
        <DialogContent>
          <ContentText well={well} />
          <SafeOperatingParametersText well={well} />
          <Grid container style={{padding: "16px 0 0 0"}} spacing={2}>
            <Grid item xs>
              <TextField
                name="speed"
                label={getWellSpeedUnits(well)}
                fullWidth
                required
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close(dispatch)} color="primary">
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            disabled={submitting || pristine}
          >
            Set Speed
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

Component = reduxForm({
  enableReinitialize: true,
  form: "set-speed-form",
  validate,
})(Component);

export default connect(mapStateToProps)(Component);
