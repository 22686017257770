import math from "mathjs";
import withUnits from "utils/with-units";
import {length} from "unit-converter";
import spreadIf from "utils/spread-if";
import valueOf from "utils/value-of";

const convertLength = ({value, from, to, units, decimals = 6}) => {
  if (valueOf(value) === null || valueOf(value) === undefined) {
    return withUnits(null, typeof units === "object" ? units.length : units);
  }

  const converted = withUnits(
    math.round(
      length.convert(
        {
          ...spreadIf(from, {
            from: (from && (typeof from === "object" && from.length)) || from,
          }),
          ...spreadIf(to, {
            to: (to && (typeof to === "object" && to.length)) || to,
          }),
          value,
        },
        to && to.length,
      ),
      decimals,
    ),
    typeof units === "object" ? units.length : units,
  );

  return converted;
};

export default convertLength;
