import {connect} from "react-redux";
import {compose} from "recompose";
import eq from "lodash/fp/eq";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import ButtonGroup from "components/button-group";
import ButtonOutline from "components/button-outline";
import {eventAction, eventCategory} from "constants/analytics";
import TIME_PERIODS from "constants/time-periods";
import {setTimePeriod} from "actions/deviation";
import sendAnalyticsEvent from "components/analytics/send-analytics-event";

const {SEVEN_DAY, THIRTY_DAY} = TIME_PERIODS;
const isSevenDay = eq(SEVEN_DAY);
const isThirtyDay = eq(THIRTY_DAY);

const styles = {
  filter: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "16px",
  },
};

const mapStateToProps = ({deviationReport: {timePeriod}}) => ({timePeriod});
const mapDispatchToProps = {
  setTimePeriod,
};

const TimePeriodFilter = ({classes, className, setTimePeriod, timePeriod}) => {
  return (
    <ButtonGroup className={classes.filter}>
      <ButtonOutline
        isActive={isSevenDay(timePeriod)}
        onClick={() => {
          sendAnalyticsEvent(
            eventCategory.deviation,
            eventAction.timePeriod,
            SEVEN_DAY.toString(),
          );
          setTimePeriod(SEVEN_DAY);
        }}
      >
        {SEVEN_DAY} day
      </ButtonOutline>
      <ButtonOutline
        isActive={isThirtyDay(timePeriod)}
        onClick={() => {
          sendAnalyticsEvent(
            eventCategory.deviation,
            eventAction.timePeriod,
            THIRTY_DAY.toString(),
          );
          setTimePeriod(THIRTY_DAY);
        }}
      >
        {THIRTY_DAY} day
      </ButtonOutline>
    </ButtonGroup>
  );
};

const enhance = compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(TimePeriodFilter);
