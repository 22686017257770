import {connect} from "react-redux";
import get from "lodash/get";
import React, {Component} from "react";
import {countBy, isMatch} from "lodash";

import colors from "theme/colors";

import {withPageview} from "components/analytics";
import findWell from "utils/find-well";
import getWellAlerts from "utils/alerts/get-well-alerts";

import DisplayStatus from "./display-status";
import DisplayActiveAlerts from "./display-active-alerts";
import DisplayActiveRecommendations from "./display-active-recommendations";
import DisplayAlertStatistics from "./display-alert-statistics";
import DisplayOptimizationClassification from "./display-optimization-classification";
import DisplayOnCallUsers from "./display-oncall-users";
import DisplayLatestCard from "./display-latest-card";
import DisplayLatestDriveData from "./display-latest-drive-data";
import DisplayLeakStatus from "./display-leak-status";
import DisplaySpmSummary from "./display-spm-summary";
import DisplayUptimeSummary from "./display-uptime-summary";
import DisplayWellFeed from "./display-well-feed";

const scrollable = {
  overflow: "auto",
  height: "100%",
};

const left = {
  ...scrollable,
  flexShrink: 0,
  paddingRight: "8px",
  width: 350,
};

const right = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  marginLeft: "8px",
  minWidth: "0px",
};

const splitInner = {
  display: "inline-block",
};

const splitInnerLeft = {
  ...scrollable,
  ...splitInner,
  flexGrow: "1",
  paddingRight: "16px",
};

const splitInnerRight = {
  ...splitInner,
  flexShrink: 0,
  width: 350,
};

const container = {
  display: "flex",
  overflow: "hidden",
  padding: "16px 8px 16px 16px",
  position: "absolute",
  top: "0",
  bottom: "0",
  right: "0",
  left: "0",
  backfaceVisibility: "hidden",
  willChange: "overflow",
  background: colors.grey[100],
};

class Tab extends Component {
  shouldComponentUpdate(nextProps) {
    const wellStatus = get(this.props, "well.status.displayName", false);
    const nextWellStatus = get(nextProps, "well.status.displayName", false);
    const wellEvents = get(this.props, "well.events", []);
    const nextWellEvents = get(nextProps, "well.events", []);
    const wellAlerts = this.props.alerts || [];
    const nextWellAlerts = nextProps.alerts || [];
    const wellRecommendations = get(this.props, "well.recommendations", []);
    const nextWellRecommendations = get(nextProps, "well.recommendations", []);

    // Rerender conditions: new status, events added, alerts added, alert statuses change, recommendations added, or new downholeLocation
    return (
      this.props.downholeLocation !== nextProps.downholeLocation ||
      wellStatus !== nextWellStatus ||
      wellAlerts.length !== nextWellAlerts.length ||
      wellEvents.length !== nextWellEvents.length ||
      wellRecommendations.length !== nextWellRecommendations.length ||
      // Check if the same number of alerts by status exists
      !isMatch(
        countBy(wellAlerts, "status"),
        countBy(nextWellAlerts, "status"),
      ) ||
      wellAlerts !== nextWellAlerts ||
      wellEvents !== nextWellEvents ||
      wellRecommendations !== nextWellRecommendations
    );
  }

  render() {
    const {alerts, well} = this.props;

    return (
      <div style={container}>
        <div className="space-vertical-list" style={left}>
          <DisplayLeakStatus well={well} />
          <DisplayStatus well={well} />
          <DisplayOptimizationClassification well={well} />
          <DisplayLatestCard well={well} />
          <DisplayLatestDriveData well={well} />
          <DisplayActiveRecommendations well={well} />
          <DisplayOnCallUsers well={well} />
        </div>
        <div className="space-vertical-list" style={right}>
          <DisplayActiveAlerts alerts={alerts} />
          <div style={{display: "flex", flex: "1", height: "100%"}}>
            <div className="space-vertical-list" style={splitInnerLeft}>
              <DisplayUptimeSummary well={well} />
              <DisplaySpmSummary well={well} />
              <DisplayAlertStatistics well={well} alerts={alerts} />
            </div>
            <div style={splitInnerRight}>
              <DisplayWellFeed well={well} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    alerts: getWellAlerts(state.alerts.all, props.downholeLocation),
    well: findWell(state.wells, props.downholeLocation),
  };
};

export default withPageview()(connect(mapStateToProps)(Tab));
