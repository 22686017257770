import {connect} from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";

import EmptyState from "./empty-state";
import Tag from "./tag";

const useStyles = makeStyles(({colors, spacing}) => ({
  root: {
    height: 250,
    margin: spacing(1),
    padding: spacing(3),
    display: "flex",
    flexWrap: "wrap",
    overflow: "scroll",
    alignContent: "flex-start",
  },
}));

const mapStateToProps = ({tags}) => {
  return {
    tags: tags.tags,
    selectedTags: tags.selectedTags,
  };
};

const TagArea = ({tags, selectedTags}) => {
  const classes = useStyles();

  if (!tags.length) return <EmptyState />;

  return (
    <div className={classes.root}>
      {tags.map(tag => (
        <Tag
          key={tag.name}
          selected={selectedTags.find(selected => selected.name === tag.name)}
          {...tag}
        />
      ))}
    </div>
  );
};

export default connect(mapStateToProps)(TagArea);
