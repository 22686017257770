import capitalize from "lodash/capitalize";
import clsx from "clsx";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";

import tagSelectionTypes, {ANY, ALL, NONE} from "constants/tag-selection-types";

import SelectionVisual from "./selection-visual";

const useStyles = makeStyles(({spacing, colors, typography}) => ({
  root: {
    pointerEvents: "none",
  },
  header: {
    padding: spacing(3, 4),
    backgroundColor: colors.warmGrey[100],
    borderRadius: [[5, 5, 0, 0]],
    color: colors.warmGrey[600],
    position: "relative",
  },
  paper: {
    transform: "translate(-42px, 20px) !important",
    borderRadius: 5,
    overflow: "visible",
    "&:after": {
      position: "absolute",
      transform: "translateY(-100%)",
      content: '""',
      top: 0,
      left: "60%",
      width: 0,
      height: 0,
      borderLeft: "11px solid transparent",
      borderRight: "11px solid transparent",
      borderBottom: [["15px solid", colors.warmGrey[100]]],
    },
  },
  body: {
    margin: 30,
    marginBottom: 50,
    display: "grid",
    gridRowGap: 20,
    alignItems: "center",
  },
  caption: {
    ...typography.caption,
    color: colors.warmGrey[500],
    marginTop: 10,
  },
  overline: {
    ...typography.overline,
    fontSize: 10,
    letterSpacing: "0.15em",
  },
  condensed: {
    lineHeight: 1,
  },
  row: {
    display: "grid",
    gridTemplateColumns: [[50, 178]],
    gridColumnGap: 15,
  },
}));

const helpMessages = {
  [ANY]: "The union of one or more tags.",
  [ALL]: "The intersection of one or more tags.",
  [NONE]: "The complement of one or more tags.",
};

const HelpPopover = ({anchorEl}) => {
  const classes = useStyles();

  return (
    <Popover
      className={classes.root}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      PaperProps={{className: classes.paper}}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <div className={classes.header}>
        <div className={classes.overline}>supported operations:</div>
      </div>
      <div className={classes.body}>
        {tagSelectionTypes.map(type => (
          <div key={type} className={classes.row}>
            <SelectionVisual type={type} />
            <div>
              <Typography variant="subtitle2" className={classes.condensed}>
                {capitalize(type)}
              </Typography>
              <div className={clsx(classes.condensed, classes.caption)}>
                {helpMessages[type]}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Popover>
  );
};

export default HelpPopover;
