const setFocus = ({state, action}) => {
  const {graph, focus} = action.payload;

  return {
    ...state,
    [graph]: {
      ...state[graph],
      focus,
    },
  };
};

export default setFocus;
