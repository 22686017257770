import React from "react";

import Button from "@material-ui/core/Button";
import colors from "theme/colors";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import getCrankWeightLabel from "./get-crank-weight-label";

const ViewCrankWeight = props => {
  const {
    crankWeight,
    edit,
    copy,
    remove,
    index,
    containerStyle,
    isDisabled = false,
    isCopyDisabled = false,
  } = props;

  return [
    <Grid
      container
      direction={"row"}
      style={{
        ...containerStyle,
        borderBottom: `1px solid ${colors.grey[500]}`,
        marginBottom: "8px",
      }}
      justify={"space-between"}
      key={`crank-weight-read-${index}`}
    >
      <Grid item xs={7}>
        <Typography
          variant="body1"
          style={{fontSize: "16px", paddingTop: "4px"}}
        >
          {getCrankWeightLabel(crankWeight)}
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <Grid container justify={"flex-end"}>
          <Grid item xs>
            <Button
              color={"primary"}
              onClick={() => edit(index)}
              disabled={isDisabled}
            >
              Edit
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              color={"primary"}
              onClick={() => copy(index)}
              disabled={isCopyDisabled}
            >
              Copy
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              color={"primary"}
              onClick={() => remove(index)}
              disabled={isDisabled}
            >
              Remove
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>,
  ];
};

export default ViewCrankWeight;
