import {getUnits} from "@ambyint/common/utils/get-units";
import {load, position} from "@ambyint/common/unit-converter";
import valueOf from "@ambyint/common/utils/value-of";

export const parse = ({values, unitsOfMeasure}) => {
  const units = getUnits(unitsOfMeasure);

  return {
    ...values,
    load: load.convert({
      from: units.load,
      to: "N",
      value: valueOf(values.load),
    }),
    position: position.convert({
      from: units.position,
      to: "m",
      value: valueOf(values.position),
    }),
  };
};

export default parse;
