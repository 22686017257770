import moment from "moment";

import formatDateTime from "@ambyint/common/utils/format/format-date-time";
import {show} from "actions/snackbar";
import {types} from "actions/wells";
import fetch from "epics/fetch";

export default action$ => {
  return action$.ofType(types.setDowntime).switchMap(({payload}) => {
    const message = formatDateTime(
      moment()
        .add(payload.data.hours, "hours")
        .add(payload.data.minutes, "minutes"),
      {timezone: true},
    );
    return fetch(
      `/optimization/well/${encodeURIComponent(
        payload.downholeLocation,
      )}/downtime`,
      {},
      {
        method: "PUT",
        body: {
          downholeLocation: payload.downholeLocation,
          downtime: {
            hours: payload.data.hours,
            minutes: payload.data.minutes,
          },
        },
      },
    ).map(() => {
      return show({
        message: `You've scheduled downtime until ${message}.`,
      });
    });
  });
};
