export const zoomToMarkers = map => {
  if (!map) return;

  const wellsWithLocations = map.props.children.filter(
    well =>
      well.props.location &&
      well.props.location.lat > -85 &&
      well.props.location.lat < 85,
  );

  const bounds = new window.google.maps.LatLngBounds();

  wellsWithLocations.forEach(well => {
    bounds.extend(
      new window.google.maps.LatLng(
        well.props.location.lat,
        well.props.location.lng,
      ),
    );
  });

  map.fitBounds(bounds, 0);
};
