import React from "react";
import {connect} from "react-redux";
import {reduxForm} from "redux-form";

import Grid from "@material-ui/core/Grid";

import getDefault from "utils/get-default-value";
import SaveCancelButtons from "components/save-cancel-buttons";
import Switch from "components/forms/switch";
import TextField from "components/forms/text-field";
import validate from "./validate";

const mapStateToProps = (state, props) => {
  const pumpoff = props.well.pumpOffSetting;

  return {
    initialValues: {
      primaryFillage:
        pumpoff && pumpoff.primaryFillage
          ? pumpoff.primaryFillage
          : getDefault("%"),
      secondaryFillage:
        pumpoff && pumpoff.secondaryFillage
          ? pumpoff.secondaryFillage
          : getDefault("%"),
      secondaryFillageEnabled: pumpoff
        ? !!pumpoff.secondaryFillageEnabled
        : false,
      strokes: pumpoff ? pumpoff.strokes : null,
    },
  };
};

class View extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: props.initialValues,
    };
  }

  render() {
    const {
      handleSubmit,
      invalid,
      submitting,
      onSubmit,
      onCancel,
      hideSecondaryFillage,
    } = this.props;

    return (
      <div style={{maxWidth: "760px"}}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction={"row"}>
            <Grid item xs={12} sm={6} lg>
              <TextField
                fullWidth
                name={"primaryFillage"}
                label={"Primary Fillage"}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} lg>
              <TextField
                fullWidth
                name={"strokes"}
                label={"Consecutive Pumpoff Strokes"}
                required
              />
            </Grid>
          </Grid>
          {!hideSecondaryFillage && (
            <>
              <Grid container direction={"row"}>
                <Grid item xs={12} sm={6} lg>
                  <Switch
                    name="secondaryFillageEnabled"
                    label="Secondary Fillage"
                    externalOnChange={(e, v) => {
                      this.setState({
                        initialValues: {
                          secondaryFillageEnabled: v,
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg>
                  <TextField
                    fullWidth
                    name={"secondaryFillage"}
                    label={"Secondary Fillage"}
                    required={this.state.initialValues.secondaryFillageEnabled}
                    disabled={!this.state.initialValues.secondaryFillageEnabled}
                  />
                </Grid>
              </Grid>
            </>
          )}
          <SaveCancelButtons
            invalid={invalid}
            onClose={onCancel}
            submitting={submitting}
          />
        </form>
      </div>
    );
  }
}

const Component = reduxForm({
  form: "set-pumpoff-downhole-lufkin-settings-form",
  validate,
})(View);

export default connect(mapStateToProps)(Component);
