import {area as d3area, curveStepAfter as d3curveStepAfter} from "d3-shape";
import colors from "theme/colors";

export default props => {
  const {
    clipPathId,
    curve = d3curveStepAfter,
    mapData,
    mapX,
    mapMaxY,
    mapMinY,
    selection,
    styles: {
      fill = colors.black,
      stroke = colors.transparent,
      strokeWidth = 0,
    } = {},
    visibility = "visible",
    xScale,
    yScale,
  } = props;

  const area = d3area().curve(curve);

  const path = selection
    .append("path")
    .attr("clip-path", `url(#${clipPathId})`)
    .attr("fill", fill)
    .attr("stroke", stroke)
    .attr("stroke-width", strokeWidth)
    .attr("visibility", visibility);

  return {
    node: path.node(),
    selection: path,

    draw({data}) {
      area
        .x(d => xScale(mapX(d)))
        .y0(d => yScale(mapMaxY(d)))
        .y1(d => yScale(mapMinY(d)));

      path.attr("d", area(mapData(data)));
    },

    hide() {
      path.attr("visibility", "hidden");
    },

    show() {
      path.attr("visibility", "visible");
    },

    zoom({data, xScale}) {
      area.x(d => xScale(mapX(d)));
      path.attr("d", area(mapData(data)));
    },
  };
};
