import isFinite from "lodash/isFinite";
import {round} from "@ambyint/common/utils/round";

export const roundWithUnits = (obj, decimals) => {
  if (!obj) return obj;
  if (!obj.value || !obj.units) return obj;
  if (!isFinite(decimals)) return obj;

  return {value: round(obj.value, decimals), units: obj.units};
};
