import {useMemo} from "react";
import {extent} from "d3-array";
import get from "lodash/fp/get";
import isNil from "lodash/isNil";

import nice from "utils/nice";

export const useDomain = (series, niceCount = 5, forceDomainZero = false) => {
  return useMemo(
    () => {
      const seriesExtent = extent(series, get("y"));
      if (isNil(seriesExtent[0])) seriesExtent[0] = 0; // If the min is nil, make it 0
      if (isNil(seriesExtent[1]) || seriesExtent[1] === 0) seriesExtent[1] = 10; // If max is nil or 0, we need some non-zero number to create a domain larger than [0,0].
      const seriesDomain = nice(seriesExtent, niceCount).domain();
      const domain = forceDomainZero ? [0, seriesDomain[1]] : seriesDomain;
      return domain;
    },
    [series, niceCount, forceDomainZero],
  );
};
