import React from "react";

import ReadView from "./read";
import WriteView from "./write";

class View extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
    };
  }

  render() {
    if (this.state.edit)
      return (
        <WriteView
          {...this.props}
          onClose={() => this.setState({edit: false})}
        />
      );
    else
      return (
        <ReadView {...this.props} onEdit={() => this.setState({edit: true})} />
      );
  }
}

export default View;
