import React from "react";

import formatDateTime from "@ambyint/common/utils/format/format-date-time";

import Created from "./created";
import Generic from "./generic";

const DisplayAlertTimelineEvent = props => {
  const {alert = null, event = null, style = {}} = props;

  if (event.type === "acknowledged") {
    return (
      <Generic
        title={"Acknowledged"}
        message={`${event.data.by.firstName} ${
          event.data.by.lastName
        } acknowledged the
            alert until ${formatDateTime(event.data.until, {timezone: true})}`}
        timestamp={event.data.on}
        style={style}
      />
    );
  }

  if (event.type === "acknowledgementExpired") {
    return (
      <Generic
        title={"Acknowledgement Expired"}
        message={""}
        timestamp={event.createdOn}
        style={style}
      />
    );
  }

  if (event.type === "commentAdded") {
    return (
      <Generic
        title={`${event.data.by.firstName} ${
          event.data.by.lastName
        } added a comment`}
        message={event.data.comment}
        timestamp={event.createdOn}
        style={style}
      />
    );
  }

  if (event.type === "created") {
    return <Created event={event} alert={alert} style={style} />;
  }

  if (event.type === "resolved") {
    return (
      <Generic
        title={"Resolved"}
        message={`${event.data.by.firstName} ${
          event.data.by.lastName
        } resolved the alert${
          event.data.comment ? ` with the comment: ${event.data.comment}` : ""
        }`}
        timestamp={event.data.on}
        style={style}
      />
    );
  }

  if (event.type === "userAdded") {
    return (
      <Generic
        title={`${event.data.firstName} ${
          event.data.lastName
        } was added to the alert`}
        message={""}
        timestamp={event.createdOn}
        style={style}
      />
    );
  }

  return null;
};

export default DisplayAlertTimelineEvent;
