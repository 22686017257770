export const getPumpingUnitLabel = pumpjack => {
  if (!pumpjack) {
    return "";
  }

  const {api, manufacturer, model, type} = pumpjack;
  return `${manufacturer} ${api} ${model} (${type})`;
};

export default getPumpingUnitLabel;
