import isEmpty from "lodash/isEmpty";
import Grid from "@material-ui/core/Grid";

import React from "react";

import {Header} from "./header";
import {Section} from "./section";

export const PumpHealthV2 = props => {
  const {pumpHealthV2} = props.classifications;

  if (isEmpty(pumpHealthV2)) return null;

  return (
    <Grid item xs={6}>
      <Header label={"Pump Health V2"} />
      <Section classifications={pumpHealthV2} />
    </Grid>
  );
};
