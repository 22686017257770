import {replace} from "react-router-redux";

import {createSwitchEpic} from "epics/create-epic";
import {setOrganizationUsers, types} from "actions/organizations";
import {show} from "actions/snackbar";
import fetch from "epics/async-fetch";

export default createSwitchEpic(
  [types.searchOrganizationUsers],
  async ({payload}) => {
    try {
      const allUserTypes = await fetch(
        `/organization/${encodeURIComponent(
          payload.organizationId,
        )}/user/search/${encodeURIComponent(payload.search)}`,
      );

      const data = allUserTypes.filter(user => user.type !== "serviceAccount");

      return [
        setOrganizationUsers({
          data,
          organizationId: payload.organizationId,
        }),
      ];
    } catch (err) {
      if (err.status === 401 || err.status === 403) {
        return [
          replace("/"),
          show({
            message: "Unauthorized to view organization users",
          }),
        ];
      }
      return [replace(`/error?code=${err.status}`)];
    }
  },
);
