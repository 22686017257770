import React from "react";
import RootRef from "@material-ui/core/RootRef";

export const withRef = ref => Component => {
  if (ref) {
    return <RootRef rootRef={ref}>{Component}</RootRef>;
  }

  return Component;
};
