import {RECENT_ORGANIZATIONS} from "constants/local-storage-keys";

export const setRecentOrganizations = (userId, recentOrgs) => {
  const recentOrgsAllUsers = JSON.parse(
    localStorage.getItem(RECENT_ORGANIZATIONS) || "{}",
  );

  recentOrgsAllUsers[userId] = recentOrgs;

  localStorage.setItem(
    RECENT_ORGANIZATIONS,
    JSON.stringify(recentOrgsAllUsers),
  );
};
