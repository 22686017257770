import {replace} from "react-router-redux";
import fetch from "epics/async-fetch";

import {createSwitchEpic} from "epics/create-epic";
import {setWebhookSaved, types} from "actions/organizations";
import {show} from "actions/snackbar";

export default createSwitchEpic([types.saveWebhook], async ({payload}) => {
  try {
    const {clientUserId, organizationId, webhookId, webhook} = payload;

    const {endpoint, method} = webhookId
      ? {endpoint: `${encodeURIComponent(webhookId)}`, method: "PUT"}
      : {endpoint: "create", method: "POST"};

    const response = await fetch(
      `/organization/${encodeURIComponent(
        organizationId,
      )}/serviceAccount/${encodeURIComponent(
        clientUserId,
      )}/webhook/${endpoint}`,
      {},
      {
        method,
        body: {
          ...webhook,
          clientUserId,
          webhookId,
          // NOTE: hard-coded for now until we decide on granularity of event types.
          events: ["well::event::added"],
        },
      },
    );

    return [
      show({
        message: "Your request to save a webhook submitted successfully!",
      }),
      setWebhookSaved({
        clientUserId,
        webhookId: response.webhookId,
        webhook: {
          ...webhook,
          webhookId: response.webhookId,
          events: ["well::event::added"],
        },
      }),
    ];
  } catch (err) {
    if (err.status === 401 || err.status === 403) {
      return [
        replace("/"),
        show({
          message: "Unauthorized to save webhooks",
        }),
      ];
    }
    return [replace(`/error?code=${err.status}`)];
  }
});
