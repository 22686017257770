import max from "utils/validation/max";
import min from "utils/validation/min";
import number from "utils/validation/number";
import required from "utils/validation/required";
import valueOf from "utils/value-of";

const validateSecondaryFillage = values => {
  if (!values.secondaryFillageEnabled) return {};

  const secondaryFillage = valueOf(values.secondaryFillage);
  const primaryFillage = valueOf(values.primaryFillage);

  return {
    ...(secondaryFillage >= primaryFillage
      ? {secondaryFillage: "Secondary fillage must be less than fillage"}
      : {}),
    ...(min(secondaryFillage) ? {secondaryFillage: min(secondaryFillage)} : {}),
    ...(number(secondaryFillage)
      ? {secondaryFillage: number(secondaryFillage)}
      : {}),
    ...(required(secondaryFillage)
      ? {secondaryFillage: required(secondaryFillage)}
      : {}),
  };
};

const validatePrimaryFillage = values => {
  const primaryFillage = valueOf(values.primaryFillage);

  return {
    ...(required(primaryFillage)
      ? {primaryFillage: required(primaryFillage)}
      : {}),
    ...(number(primaryFillage) ? {primaryFillage: number(primaryFillage)} : {}),
    ...(min(primaryFillage) ? {primaryFillage: min(primaryFillage)} : {}),
    ...(max(primaryFillage, 100)
      ? {primaryFillage: max(primaryFillage, 100)}
      : {}),
  };
};

const validateStrokes = values => {
  const strokes = valueOf(values.strokes);

  return {
    ...(required(strokes) ? {strokes: required(strokes)} : {}),
    ...(number(strokes) ? {strokes: number(strokes)} : {}),
    ...(min(strokes) ? {strokes: min(strokes)} : {}),
  };
};

export default values => {
  return {
    ...validateStrokes(values),
    ...validatePrimaryFillage(values),
    ...validateSecondaryFillage(values),
  };
};
