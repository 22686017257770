import React from "react";

import Typography from "@material-ui/core/Typography";

import {defaultMainStyle, defaultTitleStyle} from "./dashboard-styles";

export default props => {
  const {well} = props;

  if (!well.status.mode) return null;

  return (
    <div>
      <Typography variant="body1" align="right" style={{...defaultTitleStyle}}>
        Mode
      </Typography>
      <Typography variant="h6" align="right" style={{...defaultMainStyle}}>
        {well.status.mode}
      </Typography>
    </div>
  );
};
