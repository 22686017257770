import makeStyles from "@material-ui/styles/makeStyles";

export default makeStyles({
  power: ({colors, limitedAccess}) => ({
    opacity: limitedAccess ? 0.26 : 1,
    cursor: limitedAccess ? "default" : "pointer",
    backgroundColor: colors[0][0],
    color: colors[0][1],
    border: `solid 1px ${colors[0][2]}`,
    "&:hover": {
      backgroundColor: colors[0][0],
      color: colors[0][1],
    },
  }),
  automate: ({disabled, colors, limitedAccess}) => ({
    opacity: disabled || limitedAccess ? 0.26 : 1,
    cursor: disabled || limitedAccess ? "default" : "pointer",
    backgroundColor: colors[1][0],
    color: colors[1][1],
    border: `solid 1px ${colors[1][2]}`,
    "& *": {
      fontSize: 20,
    },
    "&:hover": {
      backgroundColor: colors[1][0],
      color: colors[1][1],
    },
  }),
});
