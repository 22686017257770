import React from "react";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import RootRef from "@material-ui/core/RootRef";
import TableHead from "@material-ui/core/TableHead";

import TableCell from "components/table-cell";

import {TableHeaderCell, TableHeaderCheckbox, Table} from "../well-list-common";

export const renderWellTable = ({wellIds, isSelectionMode, unitsOfMeasure}) => (
  items,
  ref,
) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableHeaderCheckbox
          wellIds={wellIds}
          isSelectionMode={isSelectionMode}
        />
        <TableHeaderCell width="25%" label="Well Name" field="displayName" />
        <TableHeaderCell width="15%" label="Status" field="status" />
        <TableHeaderCell width="20%" label="Description" field="description" />
        <TableHeaderCell
          width="20%"
          label="Total Daily Liquid Production"
          align="right"
          field="production"
          tooltip={unitsOfMeasure.toLowerCase() === "metric" ? "m3/d" : "bbl/d"}
        />
        <TableHeaderCell
          width="15%"
          label="Displacement"
          align="right"
          field="displacement"
          tooltip={unitsOfMeasure.toLowerCase() === "metric" ? "m3/d" : "bbl/d"}
        />
        <TableCell style={{width: "5%"}} />
      </TableRow>
    </TableHead>
    <RootRef rootRef={ref}>
      <TableBody>{items}</TableBody>
    </RootRef>
  </Table>
);
