import MAX_DISPLAY_LENGTH from "constants/max-display-length";
import isEmpty from "lodash/isEmpty";
import store from "store";

import required from "./required";

export default country => value => {
  if (required(value)) return required(value);

  if (country === "canada") {
    const fieldNames = store.getState().fieldNames.values;

    if (isEmpty(fieldNames))
      return "Field names list must be loaded to validate against";
    if (!fieldNames.includes(value)) return "Field name must exist in the list";
  }

  if (value.length > MAX_DISPLAY_LENGTH)
    return `Field Name cannot exceed ${MAX_DISPLAY_LENGTH} characters`;
};
