import React from "react";
import {connect} from "react-redux";
import {push} from "react-router-redux";

import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import Typography from "@material-ui/core/Typography";

import Breadcrumbs from "components/breadcrumbs";
import EmptyState from "components/empty-state";
import hasFlag from "utils/has-feature-flag";
import ListStyleButton from "components/list-style-button";

import styles from "routes/profile/organization/styles";

const mapStateToProps = state => ({
  auth: state.auth,
  organizations: state.organizations,
  organizationUsers: state.organizationUsers,
});

const Organization = props => {
  const {auth, dispatch, organizations} = props;

  const selectedOrganization =
    organizations.all[organizations.selectedOrganizationId];

  const {addressLine1, addressLine2, city, province, organizationName} =
    selectedOrganization || {};

  const withComma = text => (text ? `${text}, ` : "");
  const OrganizationUsersListMenu = (
    <div style={{marginBottom: 16, display: "flex", alignItems: "baseline"}}>
      <Breadcrumbs
        crumbs={[
          {
            label: organizationName,
            pathname: "/profile/organizations",
          },
        ]}
      />
      <Typography
        variant="subtitle1"
        style={{...styles.subheader, marginLeft: 16}}
      >{`${withComma(addressLine1)}${withComma(addressLine2)}${withComma(
        city,
      )}${province || ""}`}</Typography>
    </div>
  );

  if (
    !organizations.selectedOrganizationId ||
    !organizations.all[organizations.selectedOrganizationId]
  ) {
    if (
      auth.user &&
      auth.user.assignedOrganizations &&
      auth.user.assignedOrganizations.filter(
        organization => organization.isInvitedUserApprover,
      ).length === 0
    ) {
      return (
        <div style={styles.pageContainer}>
          <EmptyState
            icon={SentimentDissatisfiedIcon}
            mainText={
              "Uh oh, you have no organizations assigned for you to manage"
            }
            subText={
              "You'll have to contact an approver in your organization to gain added to some organizations."
            }
          />
        </div>
      );
    }

    return <div style={styles.pageContainer}>{OrganizationUsersListMenu}</div>;
  }

  return (
    <div style={styles.pageContainer}>
      {OrganizationUsersListMenu}

      <ListStyleButton
        primary={"Invite New User"}
        secondary={
          "Invite users to join your organization within ambyint, you can set their well assignments"
        }
        onClick={() => {
          dispatch(
            push(
              `/profile/organization/${
                organizations.selectedOrganizationId
              }/user/invite`,
            ),
          );
        }}
      />

      <ListStyleButton
        primary={"Manage Users"}
        secondary={"Update user permissions and well access"}
        onClick={() => {
          dispatch(
            push(
              `/profile/organization/${
                organizations.selectedOrganizationId
              }/users`,
            ),
          );
        }}
      />

      <ListStyleButton
        primary={"Manage Service Accounts"}
        secondary={
          "Update service accounts that provide webhooks to push data to external services"
        }
        onClick={() => {
          dispatch(
            push(
              `/profile/organization/${
                organizations.selectedOrganizationId
              }/serviceaccounts`,
            ),
          );
        }}
      />

      <Typography gutterBottom />

      <ListStyleButton
        primary={"Add Well"}
        secondary={"Add a well to your organization to manage through ambyint"}
        onClick={() => {
          dispatch(
            push(
              `/profile/organization/${
                organizations.selectedOrganizationId
              }/well/add`,
            ),
          );
        }}
      />

      <ListStyleButton
        primary={"Event Feed"}
        secondary={"View a list of events on this organization's activities"}
        onClick={() => {
          dispatch(
            push(
              `/profile/organization/${
                organizations.selectedOrganizationId
              }/event-feed`,
            ),
          );
        }}
      />

      {hasFlag("upload-production-data", auth.user) && (
        <ListStyleButton
          primary={"Upload Production Data"}
          secondary={
            "Upload a .csv file containing production data for various wells"
          }
          onClick={() => {
            dispatch(
              push(
                `/profile/organization/${
                  organizations.selectedOrganizationId
                }/upload-production-data`,
              ),
            );
          }}
        />
      )}
    </div>
  );
};

export default connect(mapStateToProps)(Organization);
