import {show} from "actions/snackbar";
import {setOne, types} from "actions/wells";

import getUser from "utils/get-user";

import fetch from "epics/fetch";

import parseSaveReservoirInformation from "./parse-save-reservoir-information";

export default action$ =>
  action$.ofType(types.saveReservoirInformation).switchMap(({payload}) =>
    fetch(
      `/optimization/well/${encodeURIComponent(
        payload.downholeLocation,
      )}/reservoir`,
      {},
      {
        body: parseSaveReservoirInformation(
          payload.data,
          getUser().unitsOfMeasure,
        ),
        method: "PUT",
      },
    ).flatMap(() => [
      setOne({
        wellId: payload.wellId,
        data: {
          reservoir: payload.data,
        },
      }),
      show({
        message: "You have successfully updated the Reservoir Information",
      }),
    ]),
  );
