import {push} from "react-router-redux";
import reduce from "lodash/reduce";
import uniqueBy from "lodash/unionBy";

import {setAuth, signOut, types} from "actions/auth";
import parseUser from "parsers/user/parse-user";
import getCookie from "utils/get-cookie";
import socket from "socket";

import {createSwitchEpic} from "../create-epic";

const mapFeatures = features =>
  reduce(
    features,
    (features, feature) => [
      ...features,
      {key: feature.name, active: feature.enabled},
    ],
    [],
  );

export const get = createSwitchEpic(
  [types.get, types.isCompletingSignIn],
  async (action, store) => {
    const token = getCookie("id_token");

    if (token) {
      try {
        const [userRes, featuresRes] = await Promise.all([
          fetch(`${process.env.REACT_APP_API}/currentUser`, {
            headers: {
              Authorization: `Basic ${token}`,
            },
          }),
          fetch(`${process.env.REACT_APP_API}/features`, {
            headers: {
              Authorization: `Basic ${token}`,
            },
          }),
        ]);

        if (userRes.ok && featuresRes.ok) {
          const user = await userRes.json();
          const features = await featuresRes.json();

          user.features = uniqueBy(
            [...mapFeatures(features), ...user.features],
            "key",
          );

          socket(store);

          // Check if they should be redirected
          const redirectTo = sessionStorage.getItem("redirect_to") || false;
          sessionStorage.removeItem("redirect_to");

          return [
            setAuth({
              token,
              isAuthenticated: true,
              user: parseUser(user),
            }),
            ...(redirectTo ? [push(redirectTo)] : []),
          ];
        }
      } catch (err) {
        console.error(err);

        return [
          signOut({
            snackbarMessage:
              "An error occurred while getting user details. Please try signing in again, or contact support if the issue continues.",
          }),
        ];
      }
    }

    return [
      setAuth({
        isAuthenticated: false,
      }),
    ];
  },
);
