import React from "react";

import SettingsUpdated from "../settings-updated";

export default props => {
  return (
    <SettingsUpdated
      {...props}
      changesRoute="/setup/pumping-unit"
      settingsName="pumping unit information"
    />
  );
};
