import React from "react";
import {connect} from "react-redux";
import {push} from "react-router-redux";

import List from "@material-ui/core/List";

import HomeIcon from "@material-ui/icons/Home";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ExitIcon from "@material-ui/icons/ExitToApp";

import MenuItem from "./menu-item";

const Menu = props => {
  const {dispatch} = props;

  return (
    <List>
      <MenuItem
        text={"Home"}
        icon={<HomeIcon />}
        action={() => dispatch(push(`/`))}
      />
      <MenuItem
        text={"Alerts"}
        icon={<NotificationsIcon />}
        action={() => dispatch(push(`/alerts`))}
      />
      <MenuItem
        text={"Sign Out"}
        icon={<ExitIcon />}
        action={() =>
          (window.location = `${process.env.REACT_APP_WEB_LEGACY}/#/sign-out`)
        }
      />
    </List>
  );
};

export default connect()(Menu);
