import React, {useState} from "react";
import List from "@material-ui/core/List";
import compose from "lodash/fp/compose";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import PinOutlineIcon from "mdi-material-ui/PinOutline";
import PinIcon from "mdi-material-ui/Pin";
import Tooltip from "@material-ui/core/Tooltip";
import clsx from "clsx";
import {connect} from "react-redux";

import {PINNED_ORGANIZATION} from "constants/local-storage-keys";
import {
  getPinnedOrganization,
  setPinnedOrganization,
} from "utils/recent-organizations";
import {set} from "actions/organization-view";
import {ClickEvent} from "components/analytics";
import {eventAction, eventCategory} from "constants/analytics";

import "./recent-orgs-list.css";

const styles = ({colors}) => ({
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    cursor: "pointer",
    maxWidth: 300,
    whiteSpace: "wrap",
  },
  item: {
    paddingRight: 0,
    display: "flex",
    justifyContent: "space-between",
    color: "inherit",
    "&:hover": {
      backgroundColor: colors.warmGrey[50],
    },
  },
  selectedItem: {
    paddingRight: 0,
    display: "flex",
    justifyContent: "space-between",
    "&:hover": {
      cursor: "auto",
    },
  },
  selectedItemIcon: {
    marginRight: 4,
  },
});

const mapDispatchToProps = dispatch => ({
  setCurrentOrganization: organizationId =>
    dispatch(set({currentOrganization: organizationId, redirectTo: "/"})),
});

const enhance = compose(
  withStyles(styles),
  connect(
    null,
    mapDispatchToProps,
  ),
);

const SelectedOrganization = ({
  organizationName: name,
  organizationId,
  classes,
  userId,
  canMakePrimary,
}) => {
  const [hoverAnimation, setHoverAnimation] = useState("");
  const [pinnedOrganizationId, setPinnedOrganizationIdState] = useState(
    getPinnedOrganization(userId),
  );

  const savePinnedOrganizationId = () => {
    setPinnedOrganization(userId, organizationId);
    setPinnedOrganizationIdState(organizationId);
  };
  const removePinnedOrganizationId = () => {
    localStorage.removeItem(PINNED_ORGANIZATION);
    setPinnedOrganizationIdState(null);
  };

  // Determine checkmark state and appropriate click handler to use
  const pinned = organizationId === pinnedOrganizationId;
  const onClick = pinned
    ? () => {
        removePinnedOrganizationId();
        // Keep the outlined pin right side up until mouse leave
        setHoverAnimation("default-up");
      }
    : savePinnedOrganizationId;

  return (
    <ListItem className={classes.selectedItem}>
      <ListItemText
        primaryTypographyProps={{
          variant: "subtitle2",
        }}
        primary={name}
      />
      {canMakePrimary && (
        <ClickEvent
          category={eventCategory.userPopout}
          action={eventAction.toggle}
          label="Pin Organization Button"
        >
          <Tooltip title="Pin organization" placement="left">
            <ListItemIcon
              onClick={onClick}
              style={{cursor: "pointer"}}
              classes={{root: classes.selectedItemIcon}}
              onMouseEnter={() => {
                if (!pinned) setHoverAnimation("rotate-up");
              }}
              onMouseLeave={() => {
                if (!pinned) setHoverAnimation("rotate-down");
              }}
            >
              <IconButton color={pinned ? "secondary" : "default"} size="small">
                {pinned ? (
                  <PinIcon fontSize="small" />
                ) : (
                  <PinOutlineIcon
                    className={clsx("default-left", hoverAnimation)}
                    fontSize="small"
                  />
                )}
              </IconButton>
            </ListItemIcon>
          </Tooltip>
        </ClickEvent>
      )}
    </ListItem>
  );
};

const UnselectedOrganization = ({
  organizationName: name,
  organizationId: id,
  classes,
  onClose,
}) => {
  return (
    <ClickEvent
      category={eventCategory.userPopout}
      action={eventAction.navigate}
      label="Open New Organization"
    >
      <ListItem
        className={classes.item}
        onClick={onClose}
        component="a"
        target="_blank"
        href={`/#/?oid=${id}`}
      >
        <ListItemText
          primaryTypographyProps={{variant: "body2"}}
          primary={name}
        />
      </ListItem>
    </ClickEvent>
  );
};

const RecentOrgsList = ({
  organizations,
  onClose,
  classes,
  currentOrganization,
  totalAssignedOrgs,
  userId,
}) => {
  const canMakePrimary = totalAssignedOrgs > 1;

  return (
    <List className={classes.list}>
      <SelectedOrganization
        {...currentOrganization}
        classes={classes}
        userId={userId}
        canMakePrimary={canMakePrimary}
      />
      {organizations.map(org => (
        <UnselectedOrganization
          {...org}
          key={org.organizationId}
          onClose={onClose}
          classes={classes}
        />
      ))}
    </List>
  );
};

export default enhance(RecentOrgsList);
