import React from "react";
import {select} from "d3-selection";

import colors from "theme/colors";
import square from "utils/shapes/square";

const DEFAULT_COLOR = "#000000";
const DEFAULT_SIZE = 6;
const DEFAULT_STROKE_WIDTH = 3;

export default class extends React.Component {
  componentDidMount() {
    this.renderIcon();
  }

  renderIcon = () => {
    const {
      color = DEFAULT_COLOR,
      size = DEFAULT_SIZE,
      strokeWidth = DEFAULT_STROKE_WIDTH,
    } = this.props;

    select(this.container)
      .append("polygon")
      .style("stroke", color)
      .style("fill", colors.transparent)
      .style("stroke-width", strokeWidth)
      .attr("points", square(strokeWidth, strokeWidth, size));
  };

  render() {
    const {
      size = DEFAULT_SIZE,
      strokeWidth = DEFAULT_STROKE_WIDTH,
    } = this.props;

    const sideLength = size + strokeWidth * 2;

    return (
      <svg
        height={sideLength}
        width={sideLength}
        ref={container => (this.container = container)}
      />
    );
  }
}
