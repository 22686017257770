import Aux from "components/aux";
import Typography from "@material-ui/core/Typography";
import React from "react";
import pluralize from "@ambyint/common/utils/format/format-pluralize";

import getDisplayName from "utils/user/get-display-name";

export default props => {
  const {
    event: {
      data: {tags},
      type,
      performedBy,
    },
  } = props;

  return (
    <Aux>
      <Typography variant="body1">
        {`The tag${pluralize(tags)}`}
        <strong>{` ${tags.map(tag => tag.name).join(", ")} `}</strong>
        {`${pluralize(tags) === "s" ? "were" : "was"} ${
          type === "tagsAdded" ? "added" : "removed"
        } by`}
        <strong>{` ${getDisplayName(performedBy)}`}</strong>
      </Typography>
    </Aux>
  );
};
