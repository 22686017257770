import min from "utils/validation/min";
import number from "utils/validation/number";
import required from "utils/validation/required";
import valueOf from "utils/value-of";

const validateField = (values, field) => {
  const value = valueOf(values[field]);

  return {
    ...(required(value) ? {[field]: required(value)} : {}),
    ...(number(value) ? {[field]: number(value)} : {}),
    ...(min(value) ? {[field]: min(value)} : {}),
  };
};

export default values => {
  return {
    ...validateField(values, "load"),
    ...validateField(values, "position"),
    ...validateField(values, "strokes"),
  };
};
