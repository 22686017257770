import React from "react";
import {connect} from "react-redux";
import {reduxForm} from "redux-form";
import hasIn from "lodash/hasIn";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import get from "lodash/get";
import Grid from "@material-ui/core/Grid";
import math from "mathjs";

import {hasSmarten} from "@ambyint/common/utils/devices/has-smarten";
import getSafeOperatingSpeedMax from "@ambyint/common/utils/wells/get-safe-operating-speed-max";
import getSafeOperatingSpeedMin from "@ambyint/common/utils/wells/get-safe-operating-speed-min";
import getWellSpeedUnits from "@ambyint/common/utils/wells/get-well-speed-units";

import {hideSetSpeedRange} from "actions/dialogs";
import {setSpeedRange} from "actions/wells";
import Aux from "components/aux";
import Dialog from "components/dialog";
import TextField from "components/forms/text-field";

import validate from "./validate";

const mapStateToProps = state => {
  const {isOpen, well} = state.dialogs.setSpeedRange;

  return {
    ...(well
      ? {
          initialValues: {
            min: well.operation.speedRange
              ? math.round(well.operation.speedRange.min.value, 2)
              : null,
            max: well.operation.speedRange
              ? math.round(well.operation.speedRange.max.value, 2)
              : null,
          },
        }
      : {}),
    isOpen,
    well,
  };
};

const set = (dispatch, well) => {
  return values => {
    dispatch(
      setSpeedRange({
        downholeLocation: well.downholeLocation,
        min: values.min,
        max: values.max,
        well,
      }),
    );
    close(dispatch)();
  };
};

const close = dispatch => {
  return () => {
    dispatch(hideSetSpeedRange());
  };
};

const ContentText = props => {
  const well = props.well;

  if (props.missingRpm) {
    return (
      <DialogContentText>
        Please configure the motor details before setting the speed range.
      </DialogContentText>
    );
  }

  if (hasIn(well, "operation.speedRange")) {
    return (
      <DialogContentText>
        The well is currently running at {well.conditions.speed.label} with a
        speed range of {math.round(well.operation.speedRange.min.value, 2)} to{" "}
        {math.round(well.operation.speedRange.max.value, 2)}{" "}
        {well.operation.speedRange.max.units}
      </DialogContentText>
    );
  }

  return (
    <DialogContentText>A speed range is not currently set.</DialogContentText>
  );
};

const SafeOperatingParametersText = props => {
  const well = props.well;

  const maxSpeed = getSafeOperatingSpeedMax(well);
  const minSpeed = getSafeOperatingSpeedMin(well);

  const speedUnits = getWellSpeedUnits(well);

  return (
    <Aux>
      <DialogContentText>
        Minimum safe operating speed for this well is{" "}
        {`${minSpeed} ${speedUnits}`}.
      </DialogContentText>
      {maxSpeed && (
        <DialogContentText>
          Maximum safe operating speed for this well is{" "}
          {`${maxSpeed} ${speedUnits}`}.
        </DialogContentText>
      )}
    </Aux>
  );
};

let Component = props => {
  const {dispatch, handleSubmit, isOpen, pristine, submitting, well} = props;

  if (!well) return null;
  const missingRpm =
    hasSmarten(get(well, "devices")) && !get(well, "motorDetails.rpm");

  return (
    <Dialog open={isOpen} onClose={close(dispatch)}>
      <form onSubmit={handleSubmit(set(dispatch, well))}>
        <DialogTitle>{`Set speed range for ${well.displayName}`}</DialogTitle>
        <DialogContent>
          <ContentText well={well} missingRpm={missingRpm} />
          <SafeOperatingParametersText well={well} />
          <Grid container style={{padding: "16px 0 0 0"}} spacing={2}>
            <Grid item xs>
              <TextField
                name="min"
                label="Min"
                fullWidth
                required
                showError={props.dirty}
              />
            </Grid>
            <Grid item xs>
              <TextField
                name="max"
                label="Max"
                fullWidth
                required
                showError={props.dirty}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close(dispatch)} color="primary">
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            disabled={submitting || pristine || missingRpm}
          >
            Set Speed Range
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

Component = reduxForm({
  enableReinitialize: true,
  form: "set-speed-range-form",
  validate,
})(Component);

export default connect(mapStateToProps)(Component);
