import {types} from "actions/organizations";

import resetOnSignout from "utils/reducers/reset-on-signout";

import selectOrganization from "./select-organization";
import setOrganization from "./set-organization";
import setUserAddedToOrganization from "./set-user-added-to-organization";
import setUserRemovedFromOrganization from "./set-user-removed-from-organization";

const defaults = {
  all: {},
  fetched: false,
};

const actionMap = {
  [types.selectOrganization]: selectOrganization,
  [types.setOrganization]: setOrganization,
  [types.setUserAddedToOrganization]: setUserAddedToOrganization,
  [types.setUserRemovedFromOrganization]: setUserRemovedFromOrganization,
};

const organizations = (state = defaults, action) => {
  const doAction = actionMap[action.type];
  return doAction ? doAction({state, action}) : state;
};

export default resetOnSignout(organizations);
