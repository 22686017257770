import valueOf from "utils/value-of";

const stripPeriod = text => text.replace(/\.+$/, "");

const all = (func, values, list, prefix = "") => {
  let errors = {};

  list.forEach(field => {
    if (typeof field === "string") {
      if (!values) {
        errors[`${prefix}${field}`] = func(null);

        // Include parent field. Sometimes the child fields might be missing,
        // but the error might need to be for the parent if the parent is what
        // is displayed in a control.
        if (prefix) {
          errors[stripPeriod(prefix)] = func(null);
        }
      } else {
        const error = func(valueOf(values[field]));
        if (error) {
          errors[`${prefix}${field}`] = error;

          if (prefix) {
            errors[stripPeriod(prefix)] = error;
          }
        }
      }
    } else if (Array.isArray(field)) {
      // Shouldn't run into an array, unless you maybe merged incorrectly without a spread operator. It is treated as flattened.
      errors = {...errors, ...all(func, values, list, prefix)};
    } else if (typeof field === "object") {
      errors = {
        ...errors,
        ...Object.keys(field)
          .map(key =>
            all(func, values && values[key], field[key], prefix + `${key}.`),
          )
          .reduce((combinedErrors, errorsForField) => ({
            ...combinedErrors,
            ...errorsForField,
          })),
      };
    }
  });

  return errors;
};

export default all;
