import {connect} from "react-redux";
import {createSelector} from "reselect";
import BuildIcon from "@material-ui/icons/Build";
import BusinessIcon from "@material-ui/icons/Business";
import NotificationsIcon from "@material-ui/icons/Notifications";
import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";
import React from "react";
import get from "lodash/get";
import compose from "lodash/fp/compose";

import SideMenu from "components/side-menu";
import withHelmet from "components/with-helmet";

import {UploadProductionData} from "./organization/upload-production-data";
import ProfileGeneral from "./general";
import ProfileNotifications from "./notifications";
import ProfileOnCall from "./on-call";
import ProfilePreferences from "./preferences";
import Organization, {
  AddWell,
  CreateServiceAccount,
  EventFeed,
  InviteUser,
  ManageServiceAccount,
  ManageServiceAccounts,
  ManageUsers,
  ManageUser,
} from "./organization";

const currentOrgIdSelector = state =>
  get(state, "organizationView.currentOrganization");
const authorizedOrgsSelector = state =>
  get(state, "auth.user.assignedOrganizations", []);
const allOrganizationObjectsSelector = state => get(state, "organizations.all");

// Derive whether or not the user has admin priveleges to the current organization or not
const isOrgAdminSelector = createSelector(
  currentOrgIdSelector,
  authorizedOrgsSelector,
  (currentId, authorizedOrgs) =>
    authorizedOrgs.find(({organizationId}) => organizationId === currentId),
);

// Derive organization admin information from state if it exists
const organizationObjectSelector = createSelector(
  currentOrgIdSelector,
  allOrganizationObjectsSelector,
  (currentId, organizations) => get(organizations, currentId, null),
);

const mapStateToProps = state => {
  const isAdmin = isOrgAdminSelector(state);
  const adminOrganization = organizationObjectSelector(state);

  return {
    authenticated: state.organizations.fetched,
    showOrganizationAdminMenu: isAdmin && adminOrganization,
  };
};

const enhance = compose(
  connect(mapStateToProps),
  withHelmet({title: "Profile"}),
);

const Profile = ({dispatch, showOrganizationAdminMenu, authenticated}) => {
  const menuItems = [
    {
      label: "General",
      pathname: "/general",
      icon: PersonIcon,
      component: () => <ProfileGeneral />,
    },
    {
      label: "Notifications",
      pathname: "/notifications",
      icon: NotificationsIcon,
      component: () => <ProfileNotifications />,
    },
    {
      label: "Preferences",
      pathname: "/preferences",
      icon: BuildIcon,
      component: () => <ProfilePreferences />,
    },
    {
      label: "On Call",
      pathname: "/on-call",
      icon: PhoneIcon,
      component: () => <ProfileOnCall />,
    },
  ];

  if (showOrganizationAdminMenu)
    menuItems.push({
      label: "Organization",
      pathname: "/organizations",
      icon: BusinessIcon,
      component: () => <Organization />,
      routes: [
        {
          pathname: "/organization/:organizationId/event-feed",
          component: () => <EventFeed />,
        },
        {
          pathname: "/organization/:organizationId/users",
          component: () => <ManageUsers />,
        },
        {
          pathname: "/organization/:organizationId/user/invite",
          component: () => <InviteUser />,
        },
        {
          pathname: "/organization/:organizationId/user/:clientUserId",
          component: () => <ManageUser />,
        },
        {
          pathname: "/organization/:organizationId/well/add",
          component: () => <AddWell />,
        },
        {
          pathname: "/organization/:organizationId/serviceaccounts",
          component: () => <ManageServiceAccounts />,
        },
        {
          pathname: "/organization/:organizationId/serviceaccount/create",
          component: () => <CreateServiceAccount />,
        },
        {
          pathname:
            "/organization/:organizationId/serviceaccount/:clientUserId",
          component: () => <ManageServiceAccount />,
        },
        {
          pathname: "/organization/:organizationId/upload-production-data",
          component: () => <UploadProductionData />,
        },
      ],
    });

  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: 56,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <SideMenu
          useUrlRouting
          redirectPath={authenticated && "/error"}
          dispatch={dispatch}
          menuItems={menuItems}
          exactPathPrefix={"/profile"}
          style={{height: "calc(100vh - 56px)"}}
          childStyle={{maxWidth: 1200, paddingBottom: 24}}
        />
      </div>
    </div>
  );
};

export default enhance(Profile);
