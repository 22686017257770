import Aux from "components/aux";
import Typography from "@material-ui/core/Typography";
import React from "react";

import getDisplayName from "utils/user/get-display-name";

export default props => {
  const {event} = props;

  return (
    <Aux>
      <Typography variant="body1">
        <strong>{getDisplayName(event.performedOn)}</strong>
        {" was removed from the organization by"}
        {` ${getDisplayName(event.performedBy)}`}
      </Typography>
    </Aux>
  );
};
