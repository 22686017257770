import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const HighLoadStuckPlunger = ({className}) => {
  return (
    <SvgIcon className={className} viewBox="0 50 249 150">
      <path
        d="M22.746 163.343a14.5 14.5 0 00-3.77 4.87c-5.14 10.79-4.168 20.07 2.9 28.494 7.07 8.425 16.04 10.994 27.558 7.804a14.5 14.5 0 005.451-2.866L226.028 58.039a14.5 14.5 0 003.488-4.31c5.906-11.13 5.167-20.698-2.165-29.436-7.331-8.737-16.625-11.127-28.612-7.243a14.5 14.5 0 00-4.85 2.686L22.746 163.343z"
        stroke="currentColor"
        strokeWidth={7}
        fill="none"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export default HighLoadStuckPlunger;
