import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Grid from "@material-ui/core/Grid";
import InfiniteCalendar, {Calendar, withRange} from "react-infinite-calendar";
import moment from "moment";
import noop from "lodash/noop";
import React from "react";
import TextField from "@material-ui/core/TextField";
import {withStyles} from "@material-ui/core/styles";

import Dialog from "components/dialog";

import "react-infinite-calendar/styles.css";

const DATE_FORMAT = "MMM DD YYYY";

const defaults = {
  maxRange: {
    length: 31,
    unitOfTime: "day",
  },
};

const styles = ({spacing}) => ({
  clickable: {
    cursor: "pointer",
  },
  action: {
    display: "flex",
    justifyContent: "space-between",
  },
  dialog: {
    maxHeight: "100vh",
  },
  dialogActionButtons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  dialogActionText: {
    margin: spacing(0, 0, 0, 0.5),
  },
});

const formatMaxRange = ({length, unitOfTime}) => {
  return `Limited to ${length} ${unitOfTime}${length === 1 ? "" : "s"}`;
};

class Component extends React.Component {
  state = {
    isOpen: false,
  };

  close() {
    this.setState({
      isOpen: false,
    });
  }

  isValid() {
    const {end, start} = this.state;

    const {
      maxRange: {
        length = defaults.maxRange.length,
        unitOfTime = defaults.maxRange.unitOfTime,
      } = defaults.maxRange,
    } = this.props;

    if (!end || !start) {
      return false;
    }

    return moment(end).diff(start, unitOfTime, true) <= length;
  }

  open() {
    this.setState({
      isOpen: true,
    });
  }

  reset() {
    this.setState({
      end: void 0,
      start: void 0,
    });
  }

  save() {
    const {onChange = noop} = this.props;
    const {end, start} = this.state;

    onChange({
      start: start ? start : this.props.start,
      end: end ? end : this.props.end,
    });

    this.close();
    this.reset();
  }

  update({end, eventType, start}) {
    if (eventType === 3) {
      this.setState({
        end,
        start,
      });
    }
  }

  render() {
    const {
      classes,
      end,
      endLabel = "End",
      height,
      max,
      maxDate,
      min,
      start,
      startLabel = "Start",
    } = this.props;
    const {isOpen} = this.state;

    return (
      <div>
        <Dialog
          open={isOpen}
          onClose={this.close.bind(this)}
          classes={{paper: classes.dialog}}
        >
          <InfiniteCalendar
            Component={withRange(Calendar)}
            height={height}
            locale={{
              headerFormat: "MMM Do",
            }}
            max={max}
            maxDate={maxDate}
            min={min}
            onSelect={this.update.bind(this)}
            selected={{
              start: this.state.start || new Date(start),
              end: this.state.end || new Date(end),
            }}
          />
          <DialogActions classes={{root: classes.action}}>
            <DialogContentText classes={{root: classes.dialogActionText}}>
              {formatMaxRange(this.props.maxRange || defaults.maxRange)}
            </DialogContentText>
            <div style={classes.dialogActionsButtons}>
              <Button onClick={this.close.bind(this)} color="primary">
                Cancel
              </Button>
              <Button
                disabled={!this.isValid()}
                onClick={this.save.bind(this)}
                color="primary"
              >
                Ok
              </Button>
            </div>
          </DialogActions>
        </Dialog>
        <Grid container>
          <Grid item>
            <TextField
              inputProps={{className: classes.clickable}}
              onClick={this.open.bind(this)}
              label={startLabel}
              value={moment(start).format(DATE_FORMAT)}
              disabled
            />
          </Grid>
          <Grid item>
            <TextField
              inputProps={{className: classes.clickable}}
              onClick={this.open.bind(this)}
              label={endLabel}
              value={moment(end).format(DATE_FORMAT)}
              disabled
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Component);
