import {compose} from "recompose";
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import difference from "lodash/difference";
import includes from "lodash/fp/includes";
import isEmpty from "lodash/isEmpty";
import keys from "lodash/fp/keys";
import moment from "moment";
import Paper from "@material-ui/core/Paper";
import React, {useEffect, useState} from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import BoxLoading from "components/box-loading";
import {fetchDailySummaries} from "actions/deviation";
import TimePeriods from "constants/time-periods";
import {withPageview} from "components/analytics";
import PageContainer from "components/page-container";
import Container from "components/container";
import Subheader, {SubheaderButtons} from "components/subheader";
import TagsButton from "components/tags-button";
import {HomeTabs} from "components/subheader/subheader-tabs";
import {withWellSearch} from "components/app-bar/with-search";
import withHelmet from "components/with-helmet";

import DeviationFilter from "./components/deviation-filter";
import FieldDeviationPlot from "./components/field-deviation-plot";
import Legend from "./components/legend";
import Report from "./components/report";
import TimePeriodFilter from "./components/time-period-filter";

const styles = ({breakpoints, typography}) => ({
  title: {
    ...typography.h4,
    color: "#4b586b",
    fontWeight: 600,
    marginLeft: 16,
    marginBottom: "1.25rem",
  },
  paper: {
    position: "relative",
    marginBottom: "1.5rem",
  },
  sidePane: {
    backgroundColor: "#FAF9F7",
    display: "flex",
    flexDirection: "column",
  },
  mainPane: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const mapStateToProps = ({
  wells,
  deviationReport,
  auth: {user},
  tags: {filteredByTags},
}) => {
  const wellSet = filteredByTags;
  const summariesAreStale =
    wells.fetched &&
    !isEmpty(difference(wellSet, keys(deviationReport.dailySummaries)));
  const noWells = wells.fetched && isEmpty(wellSet);
  const reportReady =
    wells.fetched && deviationReport.fetched && !summariesAreStale;

  return {
    wellsLoaded: wells.fetched,
    summariesLoaded: deviationReport.fetched,
    wellList: wellSet,
    wellsWithDeviations: deviationReport.wells.filter(({wellId}) =>
      includes(wellId, wellSet),
    ),
    shouldFetchSummaries: noWells || summariesAreStale,
    reportReady,
  };
};

const mapDispatchToProps = {
  fetchDailySummaries,
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
  withPageview("/deviation"),
  withHelmet({title: "Deviation"}),
  withWellSearch,
);

const DeviationReport = ({
  wellList,
  fetchDailySummaries,
  classes,
  reportReady,
  wellsWithDeviations,
  shouldFetchSummaries,
}) => {
  const [isFetchingSummaries, setIsFetchingSummaries] = useState(false);

  useEffect(
    () => {
      if (shouldFetchSummaries && !isFetchingSummaries) {
        setIsFetchingSummaries(true);

        const start = moment()
          .utc()
          .subtract(TimePeriods.THIRTY_DAY, "days")
          .startOf("day")
          .format("YYYY-MM-DD");

        const end = moment()
          .utc()
          .subtract(1, "days")
          .endOf("day")
          .format("YYYY-MM-DD");

        // Must ask for both the old "liquidProduction" value as well as the replacement "production" value.
        const metrics = [
          "calculatedFap",
          "runtime",
          "peakLoad",
          "liquidProduction",
          "production",
        ];

        fetchDailySummaries(wellList, start, end, metrics);
        setIsFetchingSummaries(false);
      }
    },

    // eslint-disable-next-line
    [shouldFetchSummaries, isFetchingSummaries],
  );

  if (!reportReady) return <BoxLoading />;

  return (
    <>
      <Subheader>
        <HomeTabs />
        <SubheaderButtons>
          <TagsButton />
        </SubheaderButtons>
      </Subheader>
      <PageContainer>
        <Container>
          <h4 className={classes.title}>
            Deviation Report
            <DeviationFilter />
          </h4>

          <div>
            <Paper className={classes.paper} elevation={1}>
              <Grid container>
                <Grid item xs={9} className={classes.mainPane}>
                  <FieldDeviationPlot wells={wellsWithDeviations} />
                </Grid>
                <Grid item xs={3} className={classes.sidePane}>
                  <Legend disabled={wellsWithDeviations.length === 0} />
                  <TimePeriodFilter />
                </Grid>
              </Grid>
            </Paper>

            <Report wells={wellsWithDeviations} />
          </div>
        </Container>
      </PageContainer>
    </>
  );
};

export default enhance(DeviationReport);
