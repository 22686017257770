import {connect} from "react-redux";
import {compose} from "recompose";
import React from "react";

import {getWells} from "utils/wells/get-wells";
import {HomeTabs} from "components/subheader/subheader-tabs";
import {setActivePath} from "actions/wells";
import {withPageview} from "components/analytics";
import {withWellSearch} from "components/app-bar/with-search";
import {workflows} from "constants/well-workflows";
import Subheader, {SubheaderButtons} from "components/subheader";
import BoxLoading from "components/box-loading";
import Container from "components/container";
import ManageTagsDrawer from "components/manage-tags-drawer";
import PageContainer from "components/page-container";
import TagsButton from "components/tags-button";
import withHelmet from "components/with-helmet";

import WellHeader from "./well-header";
import EmptyHome from "./empty-home";
import DrillDownPanel from "./drill-down-panel";
import WellCards from "./well-cards";
import WellList from "./well-list";

const mapDispatchToProps = dispatch => ({
  setActivePath: path => dispatch(setActivePath(path)),
});

const mapStateToProps = state => ({
  wells: state.wells,
  workflow: state.wells.workflow,
  isTagDrawerOpen: state.tags.tagDrawerOpen,
});

const getWellsForWorkflow = workflow => {
  const workflowSelected = workflow.selected;

  const isAnomalyWorkflow = workflowSelected === workflows.ANOMALY;
  if (isAnomalyWorkflow) {
    return [workflow.anomaly.wellList, workflow.anomaly.wellList];
  }

  const isCalibrateWorkflow = workflowSelected === workflows.CALIBRATE;
  if (isCalibrateWorkflow) {
    return [workflow.calibrate.filtered, workflow.calibrate.all];
  }

  const isTriageWorkflow = workflowSelected === workflows.TRIAGE;
  if (isTriageWorkflow) {
    return [workflow.triage.filtered, workflow.triage.all];
  }

  return [workflow.displayAll.filtered, workflow.displayAll.all];
};

const Home = ({setActivePath, wells, isTagDrawerOpen, workflow}) => {
  const isLoadingWells = !wells.fetched;
  if (isLoadingWells) return <BoxLoading />;

  const noWells = !wells.allWellIds.length;
  if (noWells) return <EmptyHome />;

  const workflowSelected = workflow.selected;
  const isAnomalyWorkflow = workflowSelected === workflows.ANOMALY;

  const [wellsToRender, allWellsList] = getWellsForWorkflow(workflow);
  const allWells = getWells(allWellsList);
  const workflowWells = getWells(wellsToRender);

  const isCardView = wells.viewType === "card";

  return (
    <>
      <Subheader>
        <HomeTabs />
        <SubheaderButtons>
          <TagsButton />
        </SubheaderButtons>
      </Subheader>
      <PageContainer>
        <DrillDownPanel
          setActivePath={setActivePath}
          isTagDrawerOpen={isTagDrawerOpen}
          allWorkflowWells={allWells}
          workflowWells={workflowWells}
          workflow={workflow}
        />
        <Container>
          <WellHeader count={workflowWells.length} />
          {isCardView ? (
            <WellCards wells={workflowWells} />
          ) : (
            <WellList
              wells={workflowWells}
              isAnomalyWorkflow={isAnomalyWorkflow}
            />
          )}
        </Container>
        <ManageTagsDrawer />
      </PageContainer>
    </>
  );
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withHelmet({title: "Status"}),
  withWellSearch,
  withPageview(),
)(Home);
