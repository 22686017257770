import clsx from "clsx";
import get from "lodash/get";
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import {withStyles} from "@material-ui/core/styles";

import RESPONSE_STATUSES from "constants/response-statuses";

import StatusIndicatorTooltipContent from "./status-indicator-tooltip-content";

const styleSheet = ({colors}) => ({
  container: {
    cursor: "default",
    display: "inline-block",
    float: "right",
  },
  pill: {
    backgroundColor: colors.blue[50],
    color: colors.blue[500],
    borderRadius: "20px",
    padding: "5px 10px 3px 10px",
    fontSize: "12px",
  },
  pillRejected: {
    color: colors.red[500],
    backgroundColor: colors.red[50],
  },
  tooltip: {
    minWidth: "350px",
    maxWidth: "none",
  },
});

const StatusIndicator = ({settings, classes, standalone, mappings}) => {
  const status = get(settings, "request.status");
  const isRejected = status === RESPONSE_STATUSES.REJECTED;

  return (
    <div className={classes.container}>
      <Tooltip
        title={
          <StatusIndicatorTooltipContent
            isRejected={isRejected}
            currentSettings={get(settings, "setting", {})}
            newSettings={get(settings, "request.payload", {})}
            standalone={standalone}
            mappings={mappings}
          />
        }
        classes={{tooltip: classes.tooltip}}
        placement="bottom"
        interactive
      >
        <div className={clsx(classes.pill, isRejected && classes.pillRejected)}>
          {isRejected ? "Update Failed" : "Updating Device"}
        </div>
      </Tooltip>
    </div>
  );
};

export default withStyles(styleSheet)(StatusIndicator);
