import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import React from "react";
import sortBy from "lodash/sortBy";
import Typography from "@material-ui/core/Typography";

import FormHeader from "components/form-header";
import getPrivileges from "utils/get-privileges";
import {OPTIONS, LABEL_MAP} from "constants/grace-periods";
import PaperContainer from "components/paper-container";
import privilegeTypes from "utils/privilege-types";

import AddGracePeriod from "./add-grace-period";
import ReadRow from "./read-row";
import WriteRow from "./write-row";

const Component = class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: null,
      newGracePeriodType: null,
      newGracePeriodValue: "",
    };
  }

  canEdit() {
    const privileges = getPrivileges(this.props.well.organization.id);
    return privileges.includes(privilegeTypes.well.configure);
  }

  onEdit = type => {
    this.setState({editing: type});
  };

  closeEditView = () => {
    this.setState({editing: null});
  };

  getGracePeriodsOptions() {
    const existingGracePeriods = this.props.well.gracePeriods || [];

    return OPTIONS.filter(gracePeriodOption => {
      return !existingGracePeriods.some(
        gracePeriod => gracePeriod.type === gracePeriodOption.value,
      );
    });
  }

  renderGracePeriodSelect() {
    const options = this.getGracePeriodsOptions();

    return (
      this.canEdit() &&
      (options.length > 0 && (
        <AddGracePeriod options={options} well={this.props.well} />
      ))
    );
  }

  renderGracePeriods() {
    const gracePeriods = sortBy(
      [...(this.props.well.gracePeriods || [])],
      data => LABEL_MAP[data.type],
    );

    if (gracePeriods.length === 0)
      return (
        <Typography variant="subtitle1">
          No alert grace periods have yet been configured.
        </Typography>
      );

    return (
      <Grid container spacing={0}>
        {gracePeriods.map(gracePeriod =>
          gracePeriod.type === this.state.editing ? (
            <WriteRow
              key={gracePeriod.type}
              gracePeriod={gracePeriod}
              well={this.props.well}
              close={this.closeEditView}
            />
          ) : (
            <ReadRow
              canEdit={this.canEdit()}
              editing={!!this.state.editing}
              key={gracePeriod.type}
              gracePeriod={gracePeriod}
              well={this.props.well}
              onEdit={this.onEdit}
            />
          ),
        )}
      </Grid>
    );
  }

  render() {
    return (
      <PaperContainer padded style={{maxWidth: "750px"}}>
        <CardContent>
          <FormHeader style={{display: "block", marginBottom: 16}}>
            Alert Grace Periods
          </FormHeader>

          {this.renderGracePeriodSelect()}
          {this.renderGracePeriods()}
        </CardContent>
      </PaperContainer>
    );
  }
};

export default Component;
