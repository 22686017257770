import React from "react";
import Grid from "@material-ui/core/Grid";
import some from "lodash/some";

import colors from "theme/colors";
import valueOf from "utils/value-of";
import Chart from "components/d3-line-chart/chart";

const styles = {
  container: {
    backgroundColor: colors.white,
    border: `1px solid ${colors.grey[300]}`,
    boxSizing: "border-box",
    marginBottom: 10,
    marginRight: 5,
  },
};

const DriveDataAnalysis = ({
  color,
  data,
  leftAxisUnits,
  scaleMinimum = null,
  width,
  height,
}) => {
  const yAxisKey = leftAxisUnits.toLowerCase();

  if (
    !data ||
    !data.value ||
    !data.value.value ||
    !some(data.value.value, `${yAxisKey}.value`)
  ) {
    return null;
  }

  return (
    <Grid item>
      <div
        style={{
          ...styles.container,
          width: `${width}px`,
          height: `${height}px`,
          display: "block",
        }}
      >
        <Chart
          data={data.value && data.value.value}
          width={width}
          height={height}
          margin={{
            top: 16,
            right: 16,
            bottom: 16,
            left: 16,
          }}
          y={{
            left: {
              map: d => valueOf(d[yAxisKey]),
              color,
              hideAxisLabels: true,
              ...(scaleMinimum != null ? {scaleMinimum} : {}),
            },
          }}
          x={{
            map: d => d.timestamp / 1000,
            hideAxisLabels: true,
          }}
        />
      </div>
    </Grid>
  );
};

export default DriveDataAnalysis;
