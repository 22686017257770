export default ({values, reverse}) => {
  return values.sort((wellA, wellB) => {
    let aV = wellA.status.displayName || -1;
    let bV = wellB.status.displayName || -1;

    if (aV === bV) {
      aV = wellA.displayName.toLowerCase();
      bV = wellB.displayName.toLowerCase();
    }

    return reverse * ((aV > bV) - (bV > aV));
  });
};
