import {connect} from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import Grid from "@material-ui/core/Grid";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import React from "react";
import {reduxForm} from "redux-form";

import colors from "theme/colors";
import ConfirmationDialog from "components/confirmation-dialog";
import DecimalTextField from "components/forms/decimal-text-field";
import {fetch as fetchRods, filter as filterRods} from "actions/rods";
import {
  fetch as fetchRodDimensions,
  filter as filterRodDimensions,
} from "actions/rod-dimensions";
import formatWithUnits from "@ambyint/common/utils/format/format-with-units";
import FormButton from "components/form-button";
import FormHeader from "components/form-header";
import getUnits from "utils/get-units";
import SearchInputWithSuggestions from "components/forms/search-input-with-suggestions";
import validate from "utils/wells/lufkin-settings/validate-rod-string";

const mapDispatchToProps = dispatch => ({
  searchRods: phrase => dispatch(filterRods({phrase})),
  getRods: () => dispatch(fetchRods()),
  getRodDimensions: rodType => dispatch(fetchRodDimensions(rodType)),
  searchRodDimensions: phrase => dispatch(filterRodDimensions(phrase)),
});

const mapStateToProps = (state, props) => {
  const {rods, rodDimensions} = state;
  const {section} = props;
  const units = getUnits();

  const initialValues = {};

  if (!isEmpty(section)) {
    initialValues["rodType"] = {
      text: section.rodType.label,
      ...section.rodType,
    };
    initialValues["rodTypeLabel"] = section.rodType.label;
    initialValues["interval"] = section.interval;
    initialValues["diameter"] = formatWithUnits(
      section.rodDimensions.diameter,
      3,
    );

    initialValues["rodDimensions"] = {
      text: section.rodDimensions.label,
      ...section.rodDimensions,
    };
  } else {
    initialValues["interval"] = {value: null, units: units.height};
  }

  return {
    rods,
    rodDimensions,
    initialValues,
  };
};

class RodStringSectionEditView extends React.Component {
  state = {
    showConfirmationDialog: false,
  };

  componentDidMount() {
    const {getRods, getRodDimensions, section} = this.props;

    getRods();

    if (section && section.rodType) {
      getRodDimensions({rodType: section.rodType.type});
    }
  }

  render() {
    const {
      rods,
      rodDimensions,
      getRodDimensions,
      searchRods,
      searchRodDimensions,
      idx,
      change,
      handleSubmit,
      invalid,
      pristine,
      submitting,
      onCancel,
      onSaveSection,
      onRemoveSection,
    } = this.props;

    const header = `Rod Section (${idx + 1})`;

    return (
      <section
        key={idx}
        style={{
          padding: "8px",
          border: `thin solid ${colors.grey[500]}`,
        }}
      >
        <ConfirmationDialog
          title={"Confirm Delete"}
          cancelButtonText={"Cancel"}
          confirmationButtonText={"Delete Rod Section"}
          isOpen={this.state.showConfirmationDialog}
          onCancel={() => {
            this.setState({showConfirmationDialog: false});
          }}
          onConfirm={() => {
            onRemoveSection();
            this.setState({showConfirmationDialog: false});
          }}
          body={"You are about to delete a rod section. This cannot be undone."}
        />
        <Grid container>
          <Grid item xs={12} style={{marginTop: "0px", marginBottom: "8px"}}>
            <FormButton
              fullWidth
              style={{position: "absolute", right: 0, top: 0}}
              onClick={() =>
                this.setState({
                  showConfirmationDialog: true,
                })
              }
            >
              <DeleteIcon />
              Delete Section
            </FormButton>
            <FormHeader>{header}</FormHeader>
          </Grid>
          <Grid item xs={6} style={{padding: "8px"}}>
            <SearchInputWithSuggestions
              name={"rodTypeLabel"}
              label="Type"
              placeholder="Choose rod type"
              onSearch={event => {
                !isNil(event.value) && searchRods(event.value);
              }}
              throttle={500}
              suggestions={(rods && rods.filtered) || []}
              getSuggestionText={rodType => rodType.label}
              getSuggestionValue={rodType => ({
                text: rodType.label,
                rodType,
              })}
              onSuggestionClick={data => {
                change("rodTypeLabel", data.rodType && data.rodType.label);
                change("rodType", data.rodType);
                change("diameter", null);

                if (data.rodType) {
                  getRodDimensions({rodType: data.rodType.type});
                }
              }}
              showDropdownArrow
              required
            />
          </Grid>
          <Grid item xs={6} style={{padding: "8px"}}>
            <SearchInputWithSuggestions
              name={"diameter"}
              label="Diameter"
              placeholder="Choose diameter"
              onSearch={event => {
                !isNil(event.value) &&
                  searchRodDimensions({phrase: event.value});
              }}
              throttle={500}
              suggestions={(rodDimensions && rodDimensions.filtered) || []}
              getSuggestionText={rodDimensions =>
                formatWithUnits(rodDimensions.diameter, 3)
              }
              getSuggestionValue={rodDimensions => ({
                text: formatWithUnits(rodDimensions.diameter, 3),
                rodDimensions,
              })}
              onSuggestionClick={data => {
                change(
                  "diameter",
                  data.rodDimensions &&
                    formatWithUnits(data.rodDimensions.diameter, 3),
                );
                change("rodDimensions", data.rodDimensions);
              }}
              showDropdownArrow
              disabled={!rodDimensions.all || rodDimensions.all.length === 0}
              required
            />
          </Grid>

          <Grid item xs={6} style={{padding: "8px"}}>
            <DecimalTextField
              fullWidth
              name={"interval"}
              label={"Interval"}
              required
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container direction={"row"}>
            <Grid
              item
              style={{
                padding: "8px 8px 8px 24px",
              }}
            >
              <FormButton onClick={onCancel}>cancel</FormButton>
              <FormButton
                disabled={pristine || submitting || invalid}
                variant="contained"
                onClick={handleSubmit(onSaveSection)}
                style={{
                  marginLeft: "8px",
                }}
              >
                Update Section
              </FormButton>
            </Grid>
          </Grid>
        </Grid>
      </section>
    );
  }
}

const Component = reduxForm({
  form: "lufkin-rodstring-section-edit-view",
  enableReinitialize: true,
  validate,
})(RodStringSectionEditView);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);
