import Field from "components/react-redux-form/field";
import TextField from "components/react-redux-form/text-field";
import React from "react";
import {connect} from "react-redux";
import getSurfaceLocationHelper from "utils/well/get-surface-location-hint";

import getModelPath from "../get-model-path";

const mapStateToProps = state => {
  const {wellCountry} = state.forms.addWell;

  return {
    isCanada: wellCountry === "canada",
  };
};

let Input = props => {
  const {isCanada, onChange, value} = props;

  return (
    <TextField
      fullWidth
      helperText={isCanada && getSurfaceLocationHelper(value)}
      label="Surface Location"
      onChange={onChange}
      required={isCanada}
      value={value}
    />
  );
};

Input = connect(mapStateToProps)(Input);

export default () => {
  return (
    <Field component={Input} modelPath={getModelPath("surfaceLocation")} />
  );
};
