import React from "react";

import getDisplayName from "utils/user/get-display-name";

import FeedItem from "../feed-item";

export default props => {
  const {event} = props;

  return (
    <FeedItem event={event}>
      <strong>{getDisplayName(event)}</strong>
      {" attempted to change the speed to "}
      <strong>
        {event.value.speed}
        {event.value.units ? ` ${event.value.units}` : ""}
      </strong>
      {"."}
    </FeedItem>
  );
};
