import Button from "@material-ui/core/Button";
import Close from "mdi-material-ui/Close";
import compose from "recompose/compose";
import {connect} from "react-redux";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import React, {useState} from "react";
import {some, identity, noop, escapeRegExp, get} from "lodash";
import TagMultiple from "mdi-material-ui/TagMultiple";
import withStyles from "@material-ui/core/styles/withStyles";

import {ClickEvent} from "components/analytics";
import {eventCategory, eventAction} from "constants/analytics";
import {filterAndOrganizeTagData} from "utils/tagging/filter-and-organize-tag-data";
import {findWellTags} from "utils/tagging/find-well-tags";
import {STATUS} from "constants/tagging";
import {toggleManageTagsDialog, updateWellTags} from "actions/tagging";
import SearchInputWithSuggestions from "components/search-input-with-suggestions";
import Title from "components/typography/title";
import Dialog from "components/dialog";

import {EmptyState} from "./empty-state";
import {useTagsManagement} from "./use-tags-management";
import Tag from "./tag";

const styles = ({spacing}) => ({
  actionGroup: {
    color: "#504A40",
    padding: 8,
  },
  paper: {
    maxHeight: "100vh",
    "& > *": {
      paddingTop: spacing(2),
      paddingBottom: spacing(4),
      paddingRight: spacing(5),
      paddingLeft: spacing(5),
    },
    "& > *:first-child": {
      padding: spacing(3),
    },
    "& > *:last-child": {
      padding: `${spacing(3)}px ${spacing(5)}px`,
    },
  },
  spaceBetween: {
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    lineHeight: 2,
    "& > *": {
      color: "#504A40",
    },
    height: 80,
  },
  flex: {
    display: "flex",
    "& > *:last-child": {
      paddingLeft: spacing(1),
      paddingTop: spacing(1) / 2,
    },
  },
  title: {
    lineHeight: 1.5,
  },
  button: {
    alignSelf: "flex-end",
    color: "#FAF9F7",
    backgroundColor: "#504A40",
    marginLeft: spacing(1),
    "&:hover": {
      backgroundColor: "#625D52",
    },
  },
  body: {
    backgroundColor: "#FAF9F7",
  },
  addTagSection: {
    display: "flex",
    marginBottom: spacing(3),
    height: 60,
    alignItems: "baseline",
  },
  labelContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    transform: "scale(1.33333)",
    transformOrigin: "top left",
    width: "100%",
    paddingRight: 5,
    boxSizing: "border-box",
    fontSize: 14,
    "& > *:last-child": {
      fontSize: 10,
      color: "#504A40",
    },
  },
  input: {
    minWidth: 375,
  },
  tagArea: {
    background: "#D3CEC4",
    display: "flex",
    height: 339,
    borderRadius: spacing(1) / 2,
    padding: spacing(3),
    flexWrap: "wrap",
    alignContent: "flex-start",
    overflow: "auto",
  },
  searchInputRoot: {
    position: "relative",
    width: 400,
    marginTop: 0,
  },
});

const toName = ({name}) => name;

const byQuery = query => tag => {
  return tag.search(escapeRegExp(query.toLowerCase())) !== -1;
};

const mapStateToProps = ({tags, wells}) => {
  // Grab tags for all currently selected wells
  const wellIds = Object.keys(tags.selectedWells).filter(id => {
    return tags.selectedWells[id].selected;
  });

  const selectedWells = wellIds.map(id => wells.byWellId[id]);

  const allTags = findWellTags(wells.allWellIds);
  const selectedTags = findWellTags(wellIds);
  const partiallyUsedTags = allTags.filter(tag => {
    return !wellIds.every(id =>
      get(wells, ["byWellId", id, "tags"], []).find(
        ({name}) => name === tag.name,
      ),
    );
  });

  return {
    isOpen: tags.manageTagsDialogOpen,
    selectedTags,
    selectedWells,
    partiallyUsedTags,

    // Generate a message reminding user of which/how many wells are going to be modified
    wellCount: wellIds.length,
  };
};

const mapDispatchToProps = {toggleManageTagsDialog, updateWellTags};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
);

export const ManageTagsDialog = enhance(
  ({
    classes,
    partiallyUsedTags,
    selectedWells,
    isOpen,
    toggleManageTagsDialog,
    selectedTags,
    updateWellTags,
    wellCount,
  }) => {
    if (!isOpen) return null;

    // Initialize hooks, 1. new tag input and 2. tags managed by this dialog
    const [inputValue, setInputValue] = useState("");
    const [warningMessage, setWarningMessage] = useState(false);

    const {tags, queueNewTag} = useTagsManagement(selectedTags);
    const omitAddedTags = tag => {
      return !tags.find(t => t.status === STATUS.newTag && t.name === tag.name);
    };

    const safeInputValue = inputValue
      .replace(/\s+/g, " ")
      .trim()
      .toLowerCase();

    const dropDownTags = partiallyUsedTags
      .filter(omitAddedTags)
      .map(toName)
      .filter(byQuery(safeInputValue));

    /**
     * Queue up a new tag to be added to all selected wells
     */
    const addTag = () => {
      queueNewTag(safeInputValue);
      setInputValue("");
    };

    /**
     * Validate input field for adding a new tag
     *
     * Input is valid if:
     * - There is input in the field
     * - The input in the field is unique from existing tags
     */
    const addTagValidation = safeInputValue.length !== 0;

    /**
     * Validate the 'ok' button
     *
     * Input is valid if:
     * - There is some new tag status (a tag will be added or removed)
     */
    const okButtonValidation = !some(
      tags,
      ({status}) => status !== STATUS.currentTag,
    );

    const safelyCloseDialog = () => {
      !okButtonValidation || inputValue.length
        ? setWarningMessage(true)
        : toggleManageTagsDialog();
    };

    return (
      <React.Fragment>
        <Dialog
          classes={{paper: classes.paper}}
          open={isOpen}
          onClose={safelyCloseDialog}
          maxWidth="md"
          fullWidth
        >
          <div className={classes.spaceBetween}>
            <div className={classes.flex}>
              <Title className={classes.title}>Manage Tags</Title>
              <TagMultiple />
            </div>
            <ClickEvent
              category={eventCategory.tags}
              action={eventAction.manageView}
              label="Exit"
            >
              <IconButton onClick={safelyCloseDialog}>
                <Close />
              </IconButton>
            </ClickEvent>
          </div>
          <div className={classes.body}>
            <div className={classes.addTagSection}>
              <SearchInputWithSuggestions
                outlined
                name="addNewTag"
                label={
                  <div className={classes.labelContainer}>
                    <span>Add tag</span>
                    <div>{inputValue && `${inputValue.length}/32`}</div>
                  </div>
                }
                customClasses={{
                  input: classes.input,
                  root: classes.searchInputRoot,
                }}
                throttle={0}
                value={inputValue}
                suggestions={dropDownTags}
                onSearch={noop}
                getSuggestionText={identity}
                getSuggestionValue={identity}
                onKeyPress={e =>
                  e.key === "Enter" && addTagValidation && addTag()
                }
                onChange={(e, {newValue}) =>
                  newValue.length <= 32 && setInputValue(newValue)
                }
                onClick={noop}
                showDropdownArrow
              />
              <span style={{alignSelf: "flex-end"}}>
                <ClickEvent
                  category={eventCategory.tags}
                  action={eventAction.added}
                  label={inputValue}
                >
                  <Button
                    variant="contained"
                    disabled={!addTagValidation}
                    className={classes.button}
                    onClick={addTag}
                  >
                    add
                  </Button>
                </ClickEvent>
              </span>
            </div>
            <div className={classes.tagArea}>
              {tags.length ? (
                tags.map(tag => <Tag key={tag.name} {...tag} />)
              ) : (
                <EmptyState
                  style={{alignItems: "end"}}
                  className={classes.spaceBetween}
                />
              )}
            </div>
          </div>
          <div className={classes.spaceBetween}>
            <span>
              {wellCount > 1 &&
                `Changes will only be applied to the ${wellCount} selected wells.`}
            </span>
            <div>
              <ClickEvent
                category={eventCategory.tags}
                action={eventAction.manageView}
                label="Exit"
              >
                <Button onClick={safelyCloseDialog}>cancel</Button>
              </ClickEvent>
              <ClickEvent
                category={eventCategory.tags}
                action={eventAction.manageView}
                label="Complete Action"
              >
                <Button
                  onClick={() =>
                    updateWellTags(
                      filterAndOrganizeTagData(selectedWells, tags),
                    )
                  }
                  variant="contained"
                  className={classes.button}
                  disabled={okButtonValidation}
                >
                  update tags
                </Button>
              </ClickEvent>
            </div>
          </div>
        </Dialog>

        <Dialog open={warningMessage} onClose={() => setWarningMessage(false)}>
          <DialogTitle>Are you sure you want to leave?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You have changes that have not been saved.
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.actionGroup}>
            <Button onClick={() => setWarningMessage(false)}>No</Button>
            <Button
              onClick={() => {
                setWarningMessage(false);
                toggleManageTagsDialog();
              }}
              className={classes.button}
              variant="contained"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  },
);
