import "babel-polyfill";
import "raf/polyfill";
// https://github.com/redux-observable/redux-observable/blob/master/docs/Troubleshooting.md
import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
import "rxjs/add/operator/mergeMap";
import "rxjs/add/operator/switchMap";

import {Provider} from "react-redux";
import React from "react";
import {render} from "react-dom";
import {PersistGate} from "redux-persist/integration/react";

import App from "./app";
import store, {persistor} from "./store";

import "./interrupt-route-on-dirty-form";

import "./fonts/roboto/index.css";
import "./fonts/weather/index.css";
import "./index.css";

if (process.env.REACT_APP_AUTH_TOKEN) {
  document.cookie = `id_token=${process.env.REACT_APP_AUTH_TOKEN}`;
}

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root"),
);
