import React, {useEffect} from "react";
import compose from "lodash/fp/compose";
import {connect} from "react-redux";
import Paper from "@material-ui/core/Paper/Paper";
import Typography from "@material-ui/core/Typography/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

import {fetchDailySummaries} from "actions/wells/well-health";
import getUser from "utils/get-user";

import KPIPanelStrokes from "../kpi-panel-strokes";
import Progress from "../progress";
import StrokesVsProductionGraph from "./strokes-vs-production-graph";

const styles = ({colors, spacing}) => ({
  panel: {
    padding: spacing(3, 4),
    height: "100%",
    boxSizing: "border-box",
  },

  container: {
    display: "grid",
    gridTemplateRows: "auto 310px",
    gridTemplateColumns: "minmax(0, 1fr)",
    height: "100%",
  },

  graphs: {
    display: "grid",
    gridTemplateRows: "40px 270px",
    gridTemplateColumns: "minmax(0, 1fr)",
    gridTemplateAreas: '"title" "graph"',
    gridColumnGap: spacing(2),
  },

  title: {
    gridArea: "title",
    color: colors.warmGrey[900],
    marginBottom: spacing(2),
  },

  graph: {
    gridArea: "graph",
    alignSelf: "self-end",
  },
});

const mapStateToProps = ({wells, wellHealth}) => {
  return {
    wellsLoaded: wells.fetched,
    ...wellHealth.date,
    productionFetched: wellHealth.production.fetched,
    unitsOfMeasure: wellHealth.production.unitsOfMeasure,
  };
};

const mapDispatchToProps = {
  fetchDailySummaries,
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
);

const StrokesVsProductionPanel = ({
  well,
  classes,
  fetchDailySummaries,
  productionFetched,
  unitsOfMeasure,
  startDate,
  endDate,
}) => {
  const {downholeLocation, wellId} = well;

  useEffect(
    () => {
      // If we don't have production or the user has changed units of measure since the data was fetched, then we have to fetch.
      // The conversion of units is done in the epic so we need to re-request it to get it back in the store in the proper unit.
      if (
        !productionFetched ||
        (productionFetched && unitsOfMeasure !== getUser().unitsOfMeasure)
      ) {
        fetchDailySummaries(
          wellId,
          downholeLocation,
          getUser().unitsOfMeasure,
          startDate.format("YYYY-MM-DD"),
          endDate.format("YYYY-MM-DD"),
          ["liquidProduction", "production", "strokes"],
        );
      }
    },
    [
      productionFetched,
      fetchDailySummaries,
      wellId,
      downholeLocation,
      startDate,
      endDate,
      unitsOfMeasure,
    ],
  );

  return (
    <Paper className={classes.panel}>
      <div className={classes.container}>
        <div>
          <Typography variant="h6">Strokes Vs Production</Typography>
          {productionFetched ? <KPIPanelStrokes /> : null}
        </div>

        {productionFetched ? (
          <div className={classes.graphs}>
            <Typography variant="subtitle1" className={classes.title}>
              Weekly for 6 months
            </Typography>
            <div className={classes.graph}>
              <StrokesVsProductionGraph />
            </div>
          </div>
        ) : (
          <Progress />
        )}
      </div>
    </Paper>
  );
};

export default enhance(StrokesVsProductionPanel);
