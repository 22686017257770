import Slide from "@material-ui/core/Slide";
import {compose, branch, renderNothing, renderComponent} from "recompose";

const enhance = compose(
  /**
   * When the 'in' prop to <Slide /> evaluates to true, render this component.
   * Render nothing otherwise, allowing the menu height to only be effected by rendered Slides
   * when more than one exist in the menu structure
   */
  branch(
    ({in: transition}) => !!transition,
    renderComponent(Slide),
    renderNothing,
  ),
);

export default enhance(Slide);
