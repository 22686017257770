import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";

import {hideResetMalfunctions} from "actions/dialogs";
import {setResetMalfunctions} from "actions/wells";
import Dialog from "components/dialog";

const mapStateToProps = state => {
  return state.dialogs.resetMalfunctions;
};

const reset = (dispatch, well) => {
  return () => {
    dispatch(setResetMalfunctions(well));
    close(dispatch)();
  };
};

const close = dispatch => {
  return () => {
    dispatch(hideResetMalfunctions());
  };
};

const Component = ({dispatch, isOpen, well}) => {
  if (!well) return null;

  return (
    <Dialog open={isOpen} onClose={close(dispatch)}>
      <DialogTitle>{`Reset Malfunctions on ${well.displayName}`}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You are about to clear all current malfunctions on the Lufkin so it
          can attempt to start. This may take a moment.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close(dispatch)} color="primary">
          Cancel
        </Button>
        <Button onClick={reset(dispatch, well)} color="primary">
          Reset Malfunctions
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps)(Component);
