import React from "react";
import {Chip, withStyles} from "@material-ui/core";
import {noop} from "lodash";
import {compose} from "recompose";
import clsx from "clsx";

import {eventCategory, eventAction} from "constants/analytics";
import {ClickEvent} from "components/analytics";
import {capitalizeEachWord} from "utils/capitalize-each-word";

import {Count} from "./count";

const style = () => ({
  tag: {
    backgroundColor: "rgb(255, 255, 255)",
    boxShadow: "0 2px 2px hsla(0, 0%, 0%, 0.2)",
    margin: 8,
    height: 25,
    "&:focus, &:hover": {
      backgroundColor: "rgb(255, 255, 255)",
    },
  },
  selected: {
    color: "rgb(82, 99, 181)",
    backgroundColor: "rgb(225, 232, 249)",
    boxShadow: "none",
    "&:focus, &:hover": {
      color: "rgb(82, 99, 181)",
      backgroundColor: "rgb(225, 232, 249)",
      boxShadow: "none",
    },
  },
  disabled: {
    color: "rgba(0, 0, 0, 0.8)",
    backgroundColor: "rgb(233, 230, 225)",
    boxShadow: "none",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    cursor: "auto",
    "&:focus, &:hover": {
      color: "rgba(0, 0, 0, 0.8)",
      backgroundColor: "rgb(233, 230, 225) !important",
      boxShadow: "none",
      border: "1px solid rgba(0, 0, 0, 0.23)",
      cursor: "auto",
    },
  },
  deletable: {
    "&:focus, &:hover": {
      color: "inherit",
    },
  },
});

const enhance = compose(withStyles(style));

const TagComponent = ({tag, classes, hideIcon = false}) => {
  const {
    name,
    className,
    onClick,
    disabled,
    matches,
    clicked,
    onDelete = noop,
  } = tag;

  return (
    <ClickEvent
      category={eventCategory.tags}
      action={eventAction.filter}
      label={name}
    >
      <Chip
        label={capitalizeEachWord(name)}
        clickable={false}
        className={clsx(
          classes.tag,
          className,
          disabled && classes.disabled,
          clicked && classes.selected,
        )}
        onClick={!disabled ? onClick : noop}
        disabled={disabled}
        variant={disabled ? "outlined" : "default"}
        onDelete={!hideIcon ? onDelete : undefined}
        deleteIcon={
          matches ? (
            <Count disabled={disabled} selected={clicked} matches={matches} />
          ) : null
        }
      />
    </ClickEvent>
  );
};

export const Tag = enhance(TagComponent);
