import {bisect} from "d3-array";
import merge from "lodash/merge";

const nearest = (data, date) => {
  return data[bisect(data.map(d => new Date(d.createdOn)), date) - 1];
};

const applyEvents = (name, options, typeCondition = () => true) => {
  const {analysis, date, events} = options;
  const types = (analysis[name] || {}).types;

  const group = (types || [name])
    .reduce((prev, type) => prev.concat(events[type] || []), [])
    .filter(typeCondition);

  return {
    [name]: {
      show: group.length > 0,
      value: nearest(group, date),
    },
  };
};

export default options => {
  const {analysis, date} = options;

  return merge({}, analysis, {
    date,
    ...applyEvents("alerts", options),
    ...applyEvents("casingPressure", options),
    ...applyEvents("current", options),
    ...applyEvents("deflection", options),
    ...applyEvents("dutyCycle", options),
    ...applyEvents("driveData", options),
    ...applyEvents("driveFault", options),
    ...applyEvents("driveMode", options),
    ...applyEvents("effectiveSpm", options),
    ...applyEvents("fillage", options),
    ...applyEvents("inferredFillage", options),
    ...applyEvents("leakPressure", options),
    ...applyEvents("load", options),
    ...applyEvents("miniCards", options),
    ...applyEvents("oilProduction", options),
    ...applyEvents("inferredOilProduction", options),
    ...applyEvents("ambyintFillage", options),
    ...applyEvents("recommendations", options),
    ...applyEvents("rpm", options),
    ...applyEvents("rrpm", options),
    ...applyEvents("runningStatus", options),
    ...applyEvents("shutdownCards", options, minicard => minicard.shutdown),
    ...applyEvents("spm", options),
    ...applyEvents("statusCodes", options),
    ...applyEvents("torque", options),
    ...applyEvents("tubingPressure", options),
    ...applyEvents("userActions", options),
  });
};
