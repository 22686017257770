import {types} from "actions/organization-view";
import {reducerFactory} from "reducers/reducer-factory";

import {set} from "./set";
import {toggleOrganizationSelectionDialog} from "./toggle-organization-selection-dialog";

const defaults = {
  currentOrganization: null,
  organizationSelectionDialogOpen: false,
  ignoreReset: true,
};

const actionMap = {
  [types.set]: set,
  [types.toggleOrganizationSelectionDialog]: toggleOrganizationSelectionDialog,
};

export default reducerFactory(actionMap, defaults);
