import colors from "theme/colors";
import {connect} from "react-redux";
import get from "lodash/get";
import Grid from "@material-ui/core/Grid";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import React from "react";
import {reduxForm} from "redux-form";

import FormButton from "components/form-button";
import FormHeader from "components/form-header";
import PaperContainer from "components/paper-container";
import {setLufkinRodString} from "actions/wells";

import getDisplayedSetting from "../get-displayed-setting";
import RodStringSectionEditView from "./rod-string-section-edit-view";
import RodStringSectionReadView from "./rod-string-section-read-view";

const mapDispatchToProps = dispatch => ({
  updateRodString: ({values, well}) => {
    dispatch(
      setLufkinRodString({
        values,
        well,
      }),
    );
  },
});

class LufkinRodStringEditView extends React.Component {
  constructor(props) {
    super(props);

    const rootSetting = get(props.well, "lufkinSettings.rodString");
    const setting = getDisplayedSetting(rootSetting);

    this.state = {
      sections: isEmpty(setting) ? [] : setting,
      editSection: null,
    };
  }

  render() {
    const {
      onClose,
      well,
      change,
      canEdit,
      pristine,
      updateRodString,
    } = this.props;

    const {sections, editSection} = this.state;

    return (
      <PaperContainer extraPadded style={{maxWidth: "750px"}}>
        <Grid container direction={"row"} style={{maxWidth: 776}} spacing={1}>
          <FormHeader>Rod String</FormHeader>

          {sections.map((section, idx) => {
            return (
              <Grid
                key={`rod-string-section-${idx}`}
                style={{
                  borderBottom:
                    idx === sections.length - 1
                      ? "none"
                      : `thin solid ${colors.grey[500]}`,
                }}
                item
                xs={12}
              >
                {editSection === idx && (
                  <RodStringSectionEditView
                    well={well}
                    section={section}
                    idx={idx}
                    change={change}
                    onSaveSection={section => {
                      const sections = [...this.state.sections];
                      sections.splice(idx, 1, section);

                      this.setState({
                        sections,
                        editSection: null,
                      });

                      this.props.change("sections", sections);
                    }}
                    onCancel={() => {
                      this.setState({
                        sections: this.state.sections.filter(
                          section => !isEmpty(section),
                        ),
                        editSection: null,
                      });
                    }}
                    onRemoveSection={() => {
                      const sections = [...this.state.sections];
                      sections.splice(idx, 1);

                      this.setState({
                        sections,
                        editSection: null,
                      });

                      this.props.change("sections", sections);
                    }}
                  />
                )}

                {editSection !== idx && (
                  <RodStringSectionReadView
                    section={section}
                    idx={idx}
                    canEdit={canEdit}
                    hasContainer={true}
                    editSection={editSection}
                    onEdit={idx => {
                      this.setState({editSection: idx});
                    }}
                  />
                )}
              </Grid>
            );
          })}
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            paddingTop: "16px",
            textAlign: "center",
          }}
        >
          <FormButton
            variant={"contained"}
            style={{color: "white", width: "400px"}}
            disabled={!isNil(editSection) || sections.length >= 6}
            onClick={() => {
              const sections = [...this.state.sections, {}];
              this.setState({
                sections,
                editSection: sections.length - 1,
              });
            }}
          >
            + Add New Section
          </FormButton>
        </Grid>

        <Grid item xs={12} style={{marginTop: "8px", marginLeft: "8px"}}>
          <Grid container direction={"row"}>
            <Grid item>
              <FormButton onClick={onClose}>cancel</FormButton>
              <FormButton
                style={{backgroundColor: "white"}}
                disabled={pristine}
                onClick={() => {
                  updateRodString({
                    values: this.state.sections,
                    well,
                  });
                  onClose();
                }}
              >
                Save
              </FormButton>
            </Grid>
          </Grid>
        </Grid>
      </PaperContainer>
    );
  }
}

const Component = reduxForm({
  form: "lufkin-rod-string-edit-view",
  enableReinitialize: true,
})(LufkinRodStringEditView);

export default connect(
  null,
  mapDispatchToProps,
)(Component);
