import parseAlert from "models/alert";
import process from "./process";

export default ({state, action}) => {
  if (!action.payload.data)
    return {
      ...state,
      all: [],
      filtered: [],
      selected: null,
    };

  const user = action.payload.user;
  const selected =
    state.selected && state.all.find(a => a.alertId === state.selected);

  const all = action.payload.data.map(alert => {
    if (selected && selected.alertId === alert.alertId)
      return {
        ...parseAlert(alert, user),
        timeline: selected.timeline,
      };

    return parseAlert(alert, user);
  });

  return process({
    ...state,
    all,
  });
};
