import {CALL_HISTORY_METHOD, push} from "react-router-redux";

import {empty} from "actions/empty";
import interruptActionOnDirtyForm from "utils/interrupt-action-on-dirty-form";

const getPendingAction = action => {
  if (!action.payload.args) return empty();

  return push(`${action.payload.args[0]}`);
};

export default store => next => action => {
  if (action.type === CALL_HISTORY_METHOD) {
    return next(interruptActionOnDirtyForm(getPendingAction(action)));
  }

  next(action);
};
