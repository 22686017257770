import {show} from "actions/snackbar";
import {types} from "actions/wells";
import fetch from "epics/async-fetch";
import {createSwitchEpic} from "epics/create-epic";

export default createSwitchEpic(
  [types.setRecommendationType],
  async ({payload}) => {
    const {downholeLocation, recommendationType} = payload;

    await fetch(
      `/wells/${encodeURIComponent(downholeLocation)}/recommendation-type`,
      {},
      {
        body: JSON.stringify({
          recommendationType,
        }),
        method: "PUT",
      },
    );

    return [
      show({
        message: "Setpoint automation updated.",
      }),
    ];
  },
);
