import isArray from "lodash/isArray";
import uniq from "lodash/uniq";

export default items => {
  if (!items) return [];
  if (!isArray(items)) items = [items];

  return uniq(
    items.reduce(
      (tags, item) => [...tags, ...(item.tags ? item.tags : [])],
      [],
    ),
  );
};
