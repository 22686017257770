export const LAST_SIGN_IN_EMAIL_ADDRESS = "lastSignInEmailAddress"; // Last email attempted to sign in with, to prefill login screen
export const LAST_VIEW_TYPE = "viewType"; // Last view mode chosen by the user
export const LAST_SELECTED_ORG = "lastOrganizationByUser"; // Last organization chosen by a user
export const RECENT_ORGANIZATIONS = "recentOrganizations"; // Last x organizations chosen by a user
export const PINNED_ORGANIZATION = "pinnedOrganizationId"; // User's pinned / default organization to load
export const SESSION_ORGANIZATION = "sessionOrganizationId"; // User's session organization (used when opening orgs in new tabs to maintain organization)
export const RECENTLY_VISITED_WELLS = "recentlyVisitedWells"; // User's last visited well IDs from search
export const ANOMALIES_PANEL = "anomaliesPanel"; // Last preference on status page anomalies container
export const OTHERS_PANEL = "othersPanel"; // Last preference on status page everything else container

export default {
  LAST_SIGN_IN_EMAIL_ADDRESS,
  LAST_VIEW_TYPE,
  LAST_SELECTED_ORG,
  RECENT_ORGANIZATIONS,
  PINNED_ORGANIZATION,
  RECENTLY_VISITED_WELLS,
  ANOMALIES_PANEL,
  OTHERS_PANEL,
};
