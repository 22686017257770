import {findDOMNode} from "react-dom";
import PropTypes from "prop-types";
import React from "react";

import Anchor from "./anchor";
import Popover from "./popover";

const defaultMapLabel = d => d;
const defaultMapValue = defaultMapLabel;

class Component extends React.Component {
  state = {
    anchorEl: null,
    criteria: "",
    open: false,
  };

  hide = () => {
    this.setState({open: false}, () => {
      setTimeout(() => this.setState({criteria: ""}), 150);
    });
  };

  onCriteriaChange = e => this.setState({criteria: e.target.value});

  show = anchorNode =>
    this.setState({anchorEl: findDOMNode(anchorNode), open: true});

  render() {
    const {
      first = false,
      id,
      items,
      mapLabel = defaultMapLabel,
      mapValue = defaultMapValue,
      maxDisplayCount = 20,
      maxWidth = false,
      noEmpty = false,
      onSelect,
      value,
      ...rootProps
    } = this.props;

    const {anchorEl, criteria, open} = this.state;

    return [
      <Anchor
        key={id}
        onClick={this.show}
        value={value ? mapLabel(value) : ""}
        {...rootProps}
      />,
      <Popover
        anchorEl={anchorEl}
        criteria={criteria}
        first={first}
        items={items}
        key={`${id}-popover`}
        noEmpty={noEmpty}
        mapLabel={mapLabel}
        mapValue={mapValue}
        maxDisplayCount={maxDisplayCount}
        maxWidth={maxWidth}
        onClose={this.hide}
        onCriteriaChange={this.onCriteriaChange}
        onSelect={onSelect}
        open={open}
        value={value}
      />,
    ];
  }
}

Component.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  label: PropTypes.string,
  mapLabel: PropTypes.func,
  mapValue: PropTypes.func,
  maxDisplayCount: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.any,
};

export default Component;
