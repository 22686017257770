export default {
  australia: [
    {
      value: "Australian Antarctic Territory",
      text: "Australian Antarctic Territory",
    },
    {
      value: "Australian Capital Territory",
      text: "Australian Capital Territory",
    },
    {value: "Ashmore and Cartier Islands", text: "Ashmore and Cartier Islands"},
    {value: "Christmas Island", text: "Christmas Island"},
    {value: "Cocos (Keeling) Islands", text: "Cocos (Keeling) Islands"},
    {value: "Coral Sea Islands", text: "Coral Sea Islands"},
    {
      value: "Heard Island and McDonald Islands",
      text: "Heard Island and McDonald Islands",
    },
    {value: "Jervis Bay Territory", text: "Jervis Bay Territory"},
    {value: "New South Whales", text: "New South Whales"},
    {value: "Norfolk Island", text: "Norfolk Island"},
    {value: "Northern Territory", text: "Norther Territory"},
    {value: "Queensland", text: "Queensland"},
    {value: "South Australia", text: "South Australia"},
    {value: "Tasmania", text: "Tasmania"},
    {value: "Victoria", text: "Victoria"},
    {value: "Western Australia", text: "Western Australia"},
  ],
  canada: [
    {value: "AB", text: "Alberta"},
    {value: "BC", text: "British Columbia"},
    {value: "MB", text: "Manitoba"},
    {value: "NB", text: "New Brunswick"},
    {value: "NL", text: "Newfoundland and Labrador"},
    {value: "NS", text: "Nova Scotia"},
    {value: "NT", text: "Northwest Territories"},
    {value: "NU", text: "Nunavut"},
    {value: "ON", text: "Ontario"},
    {value: "PE", text: "Prince Edward Island"},
    {value: "QC", text: "Quebec"},
    {value: "SK", text: "Saskatchenwan"},
    {value: "YT", text: "Yukon"},
  ],
  chile: [
    {value: "Antartica Chilena", text: "Antartica Chilena"},
    {value: "Antofagasta", text: "Antofagasta"},
    {value: "Arauco", text: "Arauco"},
    {value: "Arica", text: "Arica"},
    {value: "Aysen", text: "Aysen"},
    {value: "Biobio", text: "Biobio"},
    {value: "Cachapoal", text: "Cachapoal"},
    {value: "Capitan Prat", text: "Capitan Prat"},
    {value: "Cardenal Caro", text: "Cardenal Caro"},
    {value: "Cauquenes", text: "Cauquenes"},
    {value: "Cautin", text: "Cautin"},
    {value: "Chacabuco", text: "Chacabuco"},
    {value: "Chanaral", text: "Chanaral"},
    {value: "Chiloe", text: "Chiloe"},
    {value: "Choapa", text: "Choapa"},
    {value: "Coihaique", text: "Coihaique"},
    {value: "Colchagua", text: "Colchagua"},
    {value: "Concepcion", text: "Concepcion"},
    {value: "Copiapo", text: "Copiapo"},
    {value: "Cordillera", text: "Cordillera"},
    {value: "Curico", text: "Curico"},
    {value: "El Loa", text: "El Loa"},
    {value: "Elqui", text: "Elqui"},
    {value: "General Carrera", text: "General Carrera"},
    {value: "Huasco", text: "Huasco"},
    {value: "Iquique", text: "Iquique"},
    {value: "Isla de Pascua", text: "Isla de Pascua"},
    {value: "Limari", text: "Limari"},
    {value: "Linares", text: "Linares"},
    {value: "Llanquihue", text: "Llanquihue"},
    {value: "Los Andes", text: "Los Andes"},
    {value: "Magallanes", text: "Magallanes"},
    {value: "Maipo", text: "Maipo"},
    {value: "Malleco", text: "Malleco"},
    {value: "Marga Marga", text: "Marga Marga"},
    {value: "Melipilla", text: "Melipilla"},
    {value: "Nuble", text: "Nuble"},
    {value: "Osorno", text: "Osorno"},
    {value: "Palena", text: "Palena"},
    {value: "Parinacota", text: "Parinacota"},
    {value: "Petorca", text: "Petorca"},
    {value: "Quillota", text: "Quillota"},
    {value: "Ranco", text: "Ranco"},
    {value: "San Antonio", text: "San Antonio"},
    {value: "San Felipe de Aconcagua", text: "San Felipe de Aconcagua"},
    {value: "Santiago", text: "Santiago"},
    {value: "Talagante", text: "Talagante"},
    {value: "Talca", text: "Talca"},
    {value: "Tamarugal", text: "Tamarugal"},
    {value: "Tierra del Fuego", text: "Tierra del Fuego"},
    {value: "Tocopilla", text: "Tocopilla"},
    {value: "Ultima Esperanza", text: "Ultima Esperanza"},
    {value: "Valdivia", text: "Valdivia"},
    {value: "Valparaiso", text: "Valparaiso"},
  ],
  egypt: [
    {value: "Alexandria", text: "Alexandria"},
    {value: "Aswan", text: "Aswan"},
    {value: "Asyut", text: "Asyut"},
    {value: "Beheira", text: "Beheira"},
    {value: "Beni", text: "Beni"},
    {value: "Cairo", text: "Cairo"},
    {value: "Dakahlia", text: "Dakahlia"},
    {value: "Damietta", text: "Damietta"},
    {value: "Faiyum", text: "Faiyum"},
    {value: "Gharbia", text: "Gharbia"},
    {value: "Giza", text: "Giza"},
    {value: "Ismailia", text: "Ismailia"},
    {value: "Kafr El Sheikh", text: "Kafr El Sheikh"},
    {value: "Luxor", text: "Luxor"},
    {value: "Matruh", text: "Matruh"},
    {value: "Minya", text: "Minya"},
    {value: "Monufia", text: "Monufia"},
    {value: "New Valley", text: "New Valley"},
    {value: "North Sinai", text: "North Sinai"},
    {value: "Port Said", text: "Port Said"},
    {value: "Qalyubia", text: "Qalyubia"},
    {value: "Qena", text: "Qena"},
    {value: "Red Sea", text: "Red Sea"},
    {value: "Sharqia", text: "Sharqia"},
    {value: "Sohag", text: "Sohag"},
    {value: "South Sinai", text: "South Sinai"},
    {value: "Suez", text: "Suez"},
  ],
  india: [
    {value: "Andhra Pradesh", text: "Andhra Pradesh"},
    {value: "Arunachal Pradesh", text: "Arunachal Pradesh"},
    {value: "Assam", text: "Assam"},
    {value: "Bihar", text: "Bihar"},
    {value: "Chhattisgarh", text: "Chhattisgarh"},
    {value: "Goa", text: "Goa"},
    {value: "Gujarat", text: "Gujarat"},
    {value: "Haryana", text: "Haryana"},
    {value: "Himachal Pradesh", text: "Himachal Pradesh"},
    {value: "Jammu and Kashmir", text: "Jammu and Kashmir"},
    {value: "Jharkhand", text: "Jharkhand"},
    {value: "Karnataka", text: "Karnataka"},
    {value: "Kerala", text: "Kerala"},
    {value: "Madhya Pradesh", text: "Madhya Pradesh"},
    {value: "Maharashtra", text: "Maharashtra"},
    {value: "Manipur", text: "Manipur"},
    {value: "Meghalaya", text: "Meghalaya"},
    {value: "Mizoram", text: "Mizoram"},
    {value: "Nagaland", text: "Nagaland"},
    {value: "Odisha", text: "Odisha"},
    {value: "Punjab", text: "Punjab"},
    {value: "Rajasthan", text: "Rajasthan"},
    {value: "Sikkim", text: "Sikkim"},
    {value: "Tamil Nadu", text: "Tamil Nadu"},
    {value: "Telangana", text: "Telangana"},
    {value: "Tripura", text: "Tripura"},
    {value: "Uttar Pradesh", text: "Uttar Pradesh"},
    {value: "Uttarakhand", text: "Uttarakhand"},
    {value: "West Bengal", text: "West Bengal"},
  ],
  mexico: [
    {value: "DIF", text: "Distrito Federal"},
    {value: "AGS", text: "Aguascalientes"},
    {value: "BCN", text: "Baja California"},
    {value: "BCS", text: "Baja California Sur"},
    {value: "CAM", text: "Campeche"},
    {value: "CHP", text: "Chiapas"},
    {value: "CHI", text: "Chihuahua"},
    {value: "COA", text: "Coahuila"},
    {value: "COL", text: "Colima"},
    {value: "DUR", text: "Durango"},
    {value: "GTO", text: "Guanajuato"},
    {value: "GRO", text: "Guerrero"},
    {value: "HGO", text: "Hidalgo"},
    {value: "JAL", text: "Jalisco"},
    {value: "MEX", text: "México"},
    {value: "MIC", text: "Michoacán"},
    {value: "MOR", text: "Morelos"},
    {value: "NAY", text: "Nayarit"},
    {value: "NLE", text: "Nuevo León"},
    {value: "OAX", text: "Oaxaca"},
    {value: "PUE", text: "Puebla"},
    {value: "QRO", text: "Querétaro"},
    {value: "ROO", text: "Quintana Roo"},
    {value: "SLP", text: "San Luis Potosí"},
    {value: "SIN", text: "Sinaloa"},
    {value: "SON", text: "Sonora"},
    {value: "TAB", text: "Tabasco"},
    {value: "TAM", text: "Tamaulipas"},
    {value: "TLX", text: "Tlaxcala"},
    {value: "VER", text: "Veracruz"},
    {value: "YUC", text: "Yucatán"},
    {value: "ZAC", text: "Zacatecas"},
  ],
  unitedstates: [
    {value: "AL", text: "Alabama"},
    {value: "AK", text: "Alaska"},
    {value: "AZ", text: "Arizona"},
    {value: "AR", text: "Arkansas"},
    {value: "CA", text: "California"},
    {value: "CO", text: "Colorado"},
    {value: "CT", text: "Connecticut"},
    {value: "DE", text: "Delaware"},
    {value: "FL", text: "Florida"},
    {value: "GA", text: "Georgia"},
    {value: "HI", text: "Hawaii"},
    {value: "ID", text: "Idaho"},
    {value: "IL", text: "Illinois"},
    {value: "IN", text: "Indiana"},
    {value: "IA", text: "Iowa"},
    {value: "KS", text: "Kansas"},
    {value: "KY", text: "Kentucky"},
    {value: "LA", text: "Louisiana"},
    {value: "ME", text: "Maine"},
    {value: "MD", text: "Maryland"},
    {value: "MA", text: "Massachusetts"},
    {value: "MI", text: "Michigan"},
    {value: "MN", text: "Minnesota"},
    {value: "MS", text: "Mississippi"},
    {value: "MO", text: "Missouri"},
    {value: "MT", text: "Montana"},
    {value: "NE", text: "Nebraska"},
    {value: "NV", text: "Nevada"},
    {value: "NH", text: "New Hampshire"},
    {value: "NJ", text: "New Jersey"},
    {value: "NM", text: "New Mexico"},
    {value: "NY", text: "New York"},
    {value: "NC", text: "North Carolina"},
    {value: "ND", text: "North Dakota"},
    {value: "OH", text: "Ohio"},
    {value: "OK", text: "Oklahoma"},
    {value: "OR", text: "Oregon"},
    {value: "PA", text: "Pennsylvania"},
    {value: "RI", text: "Rhode Island"},
    {value: "SC", text: "South Carolina"},
    {value: "SD", text: "South Dakota"},
    {value: "TN", text: "Tennessee"},
    {value: "TX", text: "Texas"},
    {value: "UT", text: "Utah"},
    {value: "VT", text: "Vermont"},
    {value: "VA", text: "Virgina"},
    {value: "WA", text: "Washington"},
    {value: "WV", text: "West Virgina"},
    {value: "WI", text: "Wisconsin"},
    {value: "WY", text: "Wyoming"},
  ],
};
