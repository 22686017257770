import {types} from "actions/organizations";

import resetOnSignout from "utils/reducers/reset-on-signout";

import selectOrganization from "./select-organization";
import setOrganization from "./set-organization";
import setOrganizationServiceAccounts from "./set-organization-service-accounts";
import setUserAddedToOrganization from "./set-user-added-to-organization";
import setUserRemovedFromOrganization from "./set-user-removed-from-organization";
import sortServiceAccounts from "./sort-service-accounts";

const defaults = {
  filtered: {},
  sortServiceAccounts: {
    sortBy: "lastName",
    sortDirection: "asc",
  },
};

const actionMap = {
  [types.selectOrganization]: selectOrganization,
  [types.setOrganization]: setOrganization,
  [types.setOrganizationServiceAccounts]: setOrganizationServiceAccounts,
  [types.setUserAddedToOrganization]: setUserAddedToOrganization,
  [types.setUserRemovedFromOrganization]: setUserRemovedFromOrganization,
  [types.sortServiceAccounts]: sortServiceAccounts,
};

export default resetOnSignout((state = defaults, action) => {
  const doAction = actionMap[action.type];
  return doAction ? doAction({state, action}) : state;
});
