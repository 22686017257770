import {types} from "./types";

/**
 * Trigger an epic to filter wells based on the currently selected tags
 *
 * @param callbacks {array} an array of actions to be dispatched after wells are filtered
 */
export const filterWellsByTag = (callbacks = []) => ({
  type: types.filterWellsByTag,
  payload: {callbacks},
});
