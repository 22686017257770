import {scaleLinear as d3scaleLinear} from "d3-scale";
import {max as d3max} from "d3-array";
import moment from "moment";
import addLine from "utils/charts/add-line";
import colors from "theme/colors";

function mapData(d) {
  const spm = d.spm || [];
  const lastSpm = spm[spm.length - 1];

  if (lastSpm && moment(lastSpm.createdOn).isBefore(d.maxDate)) {
    spm.push({
      createdOn: d.maxDate,
      type: "spm",
      value: lastSpm.value,
    });
  }

  return spm;
}

function mapTime(d) {
  return new Date(d.createdOn);
}

function mapSpm(d) {
  return d.value;
}

export default ({clipPathId, selection, register, scales}) => {
  const spmScale = d3scaleLinear();
  const options = {
    clipPathId,
    mapData,
    mapX: mapTime,
    mapY: mapSpm,
    selection,
    styles: {
      stroke: colors.spmPrimary,
    },
    xScale: scales.time,
    yScale: spmScale,
  };

  const line = addLine(options);

  return register({
    draw(props) {
      const {config, data} = props;
      const maxSpm = d3max(mapData(data), mapSpm);
      spmScale
        .domain([0, maxSpm >= 13 ? maxSpm * 1.1 : 13])
        .range([props.height, 0]);

      line.hide();
      if (config.spm && config.spm.state > 0) {
        line.show();
      }

      line.draw(props);
    },

    zoom(props) {
      line.zoom(props);
    },
  });
};
