import React from "react";
import {scaleBand, scaleLinear} from "d3-scale";
import withResize from "components/with-resize";

import DataLine from "./data-line";
import ScaleLine from "./scale-line";

class Chart extends React.Component {
  render() {
    const {
      data,
      dataLineStyle = {},
      scaleLineStyle = {},
      min = 0,
      max = 100,
      height = 60,
    } = this.props;
    const margins = {top: 5, right: 0, bottom: 5, left: 0};
    const dimensions = {
      width: Math.max(this.props.width, 30),
    };

    const minY = Math.min(...data.map(d => d.y), min);
    const maxY = Math.max(...data.map(d => d.y), max);

    const xScale = scaleBand()
      .padding(0.5)
      .domain(data.map(d => d.x))
      .range([margins.left, dimensions.width - margins.right]);

    const yScale = scaleLinear()
      .domain([minY, maxY])
      .range([height - margins.bottom, margins.top]);

    return (
      <svg width={"100%"} height={height}>
        <ScaleLine
          value={maxY}
          style={scaleLineStyle}
          yScale={yScale}
          width={dimensions.width}
        />
        <ScaleLine
          value={maxY / 2}
          style={scaleLineStyle}
          yScale={yScale}
          width={dimensions.width}
        />
        <ScaleLine
          value={minY}
          style={scaleLineStyle}
          yScale={yScale}
          width={dimensions.width}
        />
        <DataLine
          data={data}
          style={dataLineStyle}
          xScale={xScale}
          yScale={yScale}
        />
      </svg>
    );
  }
}

export default withResize(Chart);
