import React from "react";
import pure from "recompose/pure";

import Axis from "utils/charts/add-axis";

const Axes = props => {
  const {height, width, margins, xScale, yScale, maxX} = props;

  const xProps = {
    orient: "bottom",
    scale: xScale,
    translate: `translate(0, ${height - margins.bottom})`,
    tickSize: width - margins.left - margins.right,
    ticks: maxX >= 1 ? Math.min(Math.ceil(maxX), 10) : 10,
  };
  const yProps = {
    orient: "left",
    scale: yScale,
    translate: `translate(${margins.left}, 0)`,
  };

  return (
    <g>
      <Axis {...xProps} />
      <Axis {...yProps} />
    </g>
  );
};

export default pure(Axes);
