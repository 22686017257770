import parseEvents from "@ambyint/common/parsers/well/events";

import {setEvents, types} from "actions/wells";
import fetch from "epics/async-fetch";
import getUser from "utils/get-user";

export default action$ => {
  return action$.ofType(types.fetchEvents).mergeMap(async ({payload}) => {
    const {unitsOfMeasure} = getUser();
    let parsed = [];

    try {
      const events = await fetch(
        `/wells/${encodeURIComponent(payload.downholeLocation)}/events`,
        payload,
      );

      parsed = parseEvents(unitsOfMeasure)(events);
    } catch (e) {
      console.error(e);
    }

    return setEvents({
      downholeLocation: payload.downholeLocation,
      data: parsed,
    });
  });
};
