import {useState} from "react";
import isEqual from "lodash/isEqual";

export const useSorting = () => {
  const [sortOptions, setSortOptions] = useState([]);

  const sortWells = field => () => {
    const newSortOptions = isEqual([field, "desc"], sortOptions)
      ? [field, "asc"]
      : [field, "desc"];

    setSortOptions(newSortOptions);
  };

  return {sortWells, sortOptions};
};
