/**
 * Lodash.get _does_ not set default values on null, only undefined
 *
 * Wrap a lodash get with this if you want to replace null as well
 *
 * @param {any} value
 * @param {any} replacement
 */
export const replaceNull = (value, replacement) =>
  value == null ? replacement : value;
