import {connect} from "react-redux";
import {findDOMNode} from "react-dom";
import {saveTag} from "actions/wells";
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import escapeRegex from "utils/escape-regex";
import mapTags from "utils/map-tags";
import Popover from "@material-ui/core/Popover";
import PropTypes from "prop-types";
import React from "react";
import some from "lodash/some";
import sortBy from "lodash/sortBy";
import TagIcon from "@material-ui/icons/LocalOffer";

import Search from "./search";
import List from "./list";

const styles = theme => {
  const margin = theme.spacing(2);

  return {
    actionContainer: {
      padding: `${margin / 2}px ${margin}px ${margin}px ${margin}px`,
    },
    actionButtonRoot: {
      alignItems: "flex-start",
      width: "100%",
    },
    buttonRoot: {
      minWidth: "auto",
    },
    contentContainer: {
      maxWidth: "300px",
      width: "300px",
    },
    searchContainer: {
      padding: `${margin}px ${margin}px ${margin / 2}px ${margin}px`,
    },
  };
};

const filter = (items, criteria) => {
  return items.filter(item =>
    item.match(new RegExp(escapeRegex(criteria), "i")),
  );
};

const select = (items, selected) => {
  return items.map(item => ({
    value: item,
    selected: selected.includes(item),
  }));
};

const sort = items => {
  return sortBy(items, item => `${item.selected ? "!" : "#"}${item.value}`);
};

const mapStateToProps = state => {
  return {
    tags: mapTags(state.wells.all),
  };
};

class Component extends React.Component {
  state = {
    anchorEl: null,
    criteria: "",
    open: false,
  };

  open = () => {
    this.setState({
      open: true,
      anchorEl: findDOMNode(this.button),
    });
  };

  close = () => {
    this.setState(
      {
        open: false,
      },
      () => {
        // delay resetting the criteria so it doesn't filter while hiding
        setTimeout(() => this.setState({criteria: ""}), 300);
      },
    );
  };

  onAdd = tag => {
    const {dispatch, well} = this.props;

    dispatch(saveTag({well, tag}));
  };

  onFilter = e => {
    this.setState({
      criteria: e.target.value,
    });
  };

  onSelect = e => {
    const {dispatch, well} = this.props;

    dispatch(saveTag({well, tag: e.target.value, remove: e.target.selected}));
  };

  button = null;

  render() {
    const {classes, tags, well} = this.props;
    const {anchorEl, criteria, open} = this.state;

    const filteredTags = sort(select(filter(tags, criteria), mapTags(well)));
    const createEnabled =
      criteria.trim().length > 0 &&
      !some(
        tags,
        tag => tag.toLocaleLowerCase() === criteria.toLocaleLowerCase(),
      );

    return (
      <div style={{textAlign: "center"}}>
        <Button
          classes={{root: classes.buttonRoot}}
          color={"primary"}
          ref={node => (this.button = node)}
          onClick={this.open}
        >
          <TagIcon style={{width: 20, height: 20}} />
        </Button>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={this.close}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div className={classes.contentContainer}>
            <div className={classes.searchContainer}>
              <Search onChange={this.onFilter} value={criteria} />
            </div>
            <List items={filteredTags} onSelect={this.onSelect} />
            <div className={classes.actionContainer}>
              <Button
                classes={{root: classes.actionButtonRoot}}
                color={"primary"}
                disabled={!createEnabled}
                onClick={() => this.onAdd(criteria)}
              >
                <div style={{marginRight: "8px"}}>Create</div>
                {criteria && (
                  <strong style={{textAlign: "left"}}>"{criteria}"</strong>
                )}
              </Button>
            </div>
          </div>
        </Popover>
      </div>
    );
  }
}

Component.propTypes = {
  well: PropTypes.shape({
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
};

export default connect(mapStateToProps)(withStyles(styles)(Component));
