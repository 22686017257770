import {of} from "rxjs/observable/of";

import {setAuth, types} from "actions/auth";
import removeCookie from "utils/remove-cookie";

export default action$ => {
  return action$.ofType(types.signInWithGoogle).switchMap(() => {
    removeCookie("id_token");

    window.location.href = `${process.env.REACT_APP_API}/ambyint/login/google`;

    return of(
      setAuth({
        isAuthenticated: false,
        isFetching: true,
      }),
    );
  });
};
