import {types} from "./types";

export const setCardOverride = ({downholeLocation, cardId, overrideType}) => {
  return {
    type: types.setCardOverride,
    payload: {
      downholeLocation,
      cardId,
      overrideType,
    },
  };
};
