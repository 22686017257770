import ALERT_TYPES from "constants/alert-types";

import parseAcknowledged from "./parse-acknowledged";
import parseCodes from "./parse-codes";
import parseDescription from "./parse-description";
import parseResolved from "./parse-resolved";
import parseTags from "./parse-tags";
import parseType from "./parse-type";

export default ({data, user}) => {
  const parsed = {
    alias: data.information.alias,
    displayName: data.information.alias || data.information.downholeLocation,
    alertId: data.alertId,
    codes: parseCodes(data),
    createdOn: data.createdOn,
    description: parseDescription(data, user),
    downholeLocation: data.information.downholeLocation,
    timeline: data.timeline || [],
    type: ALERT_TYPES[alert.type] || alert.type,
    typeLabel: parseType(data),
    users: data.users,
    status: data.status,
    information: data.information,
    acknowledged: parseAcknowledged(data),
    resolved: parseResolved(data),
  };

  parsed.tags = parseTags(parsed);
  return parsed;
};
