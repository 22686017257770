import Grid from "@material-ui/core/Grid";
import math from "mathjs";
import React from "react";

import Analysis from "./analysis";
import Item from "./item";

export default ({data}) => {
  return (
    <Analysis title="Pumpjack Analysis">
      <Grid container>
        <Grid item xs={6}>
          <Item
            title="Structural Loading"
            value={`${math.round(data.structuralLoading.value, 2)}${
              data.structuralLoading.units
            }`}
          />
        </Grid>
        {data.gearboxLoading && (
          <Grid item xs={6}>
            <Item
              title="Gearbox Loading"
              value={`${math.round(data.gearboxLoading.value, 2)}${
                data.gearboxLoading.units
              }`}
            />
          </Grid>
        )}
      </Grid>
    </Analysis>
  );
};
