import endOfDay from "utils/end-of-day";

export default (events, start, end) => {
  end = endOfDay(end);

  const filtered = (events || []).filter(event => {
    const createdOn = new Date(event.createdOn);
    return createdOn >= new Date(start) && createdOn <= new Date(end);
  });

  return filtered;
};
