import {stringify, parse} from "querystring";
import merge from "lodash/merge";

const parseQuerystring = query => {
  if (!query) {
    return "";
  }

  if (typeof query === "string") {
    return parse((query || "").replace(/^\?/, ""));
  }

  if (query.routing && query.routing.location) {
    // Assume redux state with location search value
    return parseQuerystring(query.routing.location.search);
  }

  return query;
};

export default (leftQuery, rightQuery) => {
  const combinedParams = stringify(
    merge(parseQuerystring(leftQuery), parseQuerystring(rightQuery)),
  );

  return combinedParams ? `?${combinedParams}` : "";
};
