import _isNaN from "lodash/isNaN";
import moment from "moment";
import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";

import colors from "theme/colors";

import Chart from "./chart";
import DeclineEmpty from "../components/decline-empty";
import Legend from "../components/legend";
import PaperWrapper from "../components/paper-wrapper";
import ProductionComparison from "./production-comparison";

const legendItems = [
  {color: colors.green[300], key: "oil", value: "Oil"},
  {color: colors.red[300], key: "gas", value: "Gas"},
  {color: colors.blue[300], key: "water", value: "Water"},
];

const calculateSummaryAverage = data => {
  let result = 0;

  if (data.length) {
    for (let i = 0; i < data.length; i++) {
      result += data[i].y;
    }

    return result / data.length;
  }

  return result;
};

const calculateSummaries = (oil, gas, water, lastDrawLocation, dateDiff) => {
  let oilData = oil;
  let gasData = gas;
  let waterData = water;
  let prevOilData = false;
  let prevGasData = false;
  let prevWaterData = false;
  let flagged = false;

  // Get only the subset of data needed
  if (lastDrawLocation) {
    const selectionStartTime = lastDrawLocation.left.getTime();
    const selectionEndTime = lastDrawLocation.right.getTime();

    const selectionStart = moment(lastDrawLocation.left);
    const overallStart = moment(oilData[0].x);

    const diffStart = selectionStart.diff(overallStart, "days");

    const prevStart = moment(lastDrawLocation.left)
      .subtract(dateDiff, "days")
      .startOf("day");
    const prevEnd = moment(lastDrawLocation.left).subtract(1, "days");
    const prevStartTime = prevStart.valueOf();
    const prevEndTime = prevEnd.valueOf();

    // Set flag, we don't have enough historical data before this current selection
    if (diffStart <= dateDiff) {
      flagged = true;
    }

    // Get current date range data
    const currentDataMapper = data => {
      return data.x >= selectionStartTime && data.x <= selectionEndTime;
    };
    oilData = oil.filter(currentDataMapper);
    gasData = gas.filter(currentDataMapper);
    waterData = water.filter(currentDataMapper);

    // Get previous date range data
    const previousDataMapper = data => {
      return data.x >= prevStartTime && data.x <= prevEndTime;
    };
    prevOilData = oil.filter(previousDataMapper);
    prevGasData = gas.filter(previousDataMapper);
    prevWaterData = water.filter(previousDataMapper);
  }

  const oilSummary = calculateSummaryAverage(oilData);
  const gasSummary = calculateSummaryAverage(gasData);
  const waterSummary = calculateSummaryAverage(waterData);

  const prevOilSummary = calculateSummaryAverage(prevOilData);
  const prevGasSummary = calculateSummaryAverage(prevGasData);
  const prevWaterSummary = calculateSummaryAverage(prevWaterData);

  return {
    oil: oilSummary.toFixed(2),
    gas: gasSummary.toFixed(2),
    water: waterSummary.toFixed(2),
    prevOil: _isNaN(prevOilSummary) ? false : prevOilSummary.toFixed(2),
    prevGas: _isNaN(prevGasSummary) ? false : prevGasSummary.toFixed(2),
    prevWater: _isNaN(prevWaterSummary) ? false : prevWaterSummary.toFixed(2),
    flagged,
  };
};

const DeclineAnalysis = ({
  dateDiff,
  gas = [],
  lastDrawLocation,
  oil = [],
  resetDateRange,
  updateDragState,
  water = [],
  unitsOfMeasure,
}) => {
  const [activeSeries, setActiveSeries] = useState({
    gas: true,
    oil: true,
    water: true,
  });

  const handleLegendClick = value => {
    // Don't let all series be disabled at the same time
    if (activeSeries[value]) {
      const {[value]: current, ...other} = activeSeries;
      const anyEnabled = Object.keys(other).some(key => activeSeries[key]);
      if (!anyEnabled) return;
    }

    setActiveSeries({
      ...activeSeries,
      [value]: !activeSeries[value],
    });
  };

  // Check if minimum 30 days of data
  if (oil.length < 30 || gas.length < 30 || water.length < 30) {
    return (
      <PaperWrapper>
        <DeclineEmpty />
      </PaperWrapper>
    );
  }

  const productionSummaries = calculateSummaries(
    oil,
    gas,
    water,
    lastDrawLocation,
    dateDiff,
  );

  return (
    <PaperWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={8}>
          <Chart
            activeSeries={activeSeries}
            gas={gas}
            oil={oil}
            water={water}
            unitsOfMeasure={unitsOfMeasure}
            lastDrawLocation={lastDrawLocation}
            resetDateRange={resetDateRange}
            updateDragState={updateDragState}
          />
          <Legend
            active={activeSeries}
            items={legendItems}
            onClick={handleLegendClick}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <ProductionComparison
            dateDiff={dateDiff}
            productionComparison={productionSummaries}
            unitsOfMeasure={unitsOfMeasure}
          />
        </Grid>
      </Grid>
    </PaperWrapper>
  );
};

export default DeclineAnalysis;
