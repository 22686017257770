import {connect} from "react-redux";

import hasFlag from "utils/has-feature-flag";

const FeatureFlag = props =>
  hasFlag(props.flag, props.user) ? {...props.children} : null;

export default connect(({auth}) => {
  return {
    user: auth.user,
  };
})(FeatureFlag);
