import React from "react";
import Fab from "@material-ui/core/Fab";
import PowerIcon from "mdi-material-ui/Power";
import CarIcon from "mdi-material-ui/Car";
import clsx from "clsx";
import Tooltip from "@material-ui/core/Tooltip";

import TableCell from "components/table-cell";

import {findColors} from "./find-colors";
import useAutomationStyles from "./use-automation-styles";
import useButtonTheme from "./use-button-theme";

const AutomationButtonGroup = ({
  automateOnClick,
  disabled,
  limitedAccess,
  metBoundary,
  powerOnClick,
  recommendationType,
  tooltipTitleAutomate,
  tooltipTitlePower,
}) => {
  const colors = findColors({...recommendationType, metBoundary});
  const classes = useAutomationStyles();
  const buttonTheme = useButtonTheme({disabled, colors, limitedAccess});

  return (
    <TableCell className={classes.automationCell}>
      <div className={classes.buttonContainer}>
        <Tooltip title={tooltipTitlePower}>
          <Fab
            className={clsx(classes.button, buttonTheme.power)}
            size="small"
            onClick={powerOnClick}
          >
            <PowerIcon />
          </Fab>
        </Tooltip>
        <Tooltip title={tooltipTitleAutomate}>
          <Fab
            className={clsx(classes.button, buttonTheme.automate)}
            size="small"
            onClick={automateOnClick}
          >
            <CarIcon />
          </Fab>
        </Tooltip>
      </div>
    </TableCell>
  );
};

export default AutomationButtonGroup;
