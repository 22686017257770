import {getUnits} from "@ambyint/common/utils/get-units";
import {densityLinear} from "@ambyint/common/unit-converter";

import get from "lodash/get";
import valueOf from "utils/value-of";

import convertDensity from "utils/conversion/convert-density";
import convertHeight from "utils/conversion/convert-height";
import convertLength from "utils/conversion/convert-length";
import convertToMetric from "utils/conversion/convert-to-metric";

function parseSaveRodSection(section, unitsOfMeasure) {
  const units = getUnits(unitsOfMeasure);
  const toMetric = convertToMetric(units, unitsOfMeasure);
  const fromDensity = toMetric(convertDensity);
  const toMetricHeight = toMetric(convertHeight, {to: "m"});
  const toMetricLength = toMetric(convertLength, {to: "m"});

  return {
    couplingType: {
      derating: valueOf(get(section, "couplingType.derating")),
      diameter: valueOf(toMetricLength(get(section, "couplingType.diameter"))),
      key: valueOf(get(section, "couplingType.key")),
      label: valueOf(get(section, "couplingType.label")),
      materialGrade: valueOf(get(section, "couplingType.materialGrade")),
      materialGradeLabel: valueOf(
        get(section, "couplingType.materialGradeLabel"),
      ),
      minimumTubingDiameter: valueOf(
        toMetricLength(get(section, "couplingType.minimumTubingDiameter")),
      ),
      pin: valueOf(toMetricLength(get(section, "couplingType.pin"))),
      type: valueOf(get(section, "couplingType.type")),
      weight: valueOf(get(section, "couplingType.weight")),
    },
    guideQuantity: valueOf(get(section, "guideQuantity")),
    guideMaterial: {
      type: valueOf(get(section, "guideMaterial.type")),
      name: valueOf(get(section, "guideMaterial.name")),
      density: valueOf(fromDensity(get(section, "guideMaterial.density"))),
      color: valueOf(get(section, "guideMaterial.color")),
      key: valueOf(get(section, "guideMaterial.key")),
      label: valueOf(get(section, "guideMaterial.label")),
    },
    guideType: {
      type: valueOf(get(section, "guideType.type")),
      manufacturer: valueOf(get(section, "guideType.manufacturer")),
      tubingDiameter: valueOf(
        toMetricLength(get(section, "guideType.tubingDiameter")),
      ),
      rodDiameter: valueOf(
        toMetricLength(get(section, "guideType.rodDiameter")),
      ),
      volume: valueOf(get(section, "guideType.volume")),
      key: valueOf(get(section, "guideType.key")),
      label: valueOf(get(section, "guideType.label")),
    },
    rodDimensions: {
      density: densityLinear.convert({
        from: unitsOfMeasure,
        value: valueOf(get(section, "rodDimensions.density")),
      }),
      diameter: valueOf(toMetricLength(get(section, "rodDimensions.diameter"))),
      key: valueOf(get(section, "rodDimensions.key")),
      label: valueOf(get(section, "rodDimensions.label")),
      minCrossSectionDiamaterDesc: valueOf(
        get(section, "rodDimensions.minCrossSectionDiamaterDesc"),
      ),
      minCrossSectionDiameter: valueOf(
        toMetricLength(get(section, "rodDimensions.minCrossSectionDiameter")),
      ),
      pin: valueOf(toMetricLength(get(section, "rodDimensions.pin"))),
      type: valueOf(get(section, "rodDimensions.type")),
    },
    rodLength: valueOf(toMetricHeight(get(section, "rodLength"))),
    rodQuantity: valueOf(get(section, "rodQuantity")),
    rodType: {
      CO2: valueOf(get(section, "rodType.CO2")),
      H2S: valueOf(get(section, "rodType.H2S")),
      corrosive: valueOf(get(section, "rodType.corrosive")),
      goodmanId: valueOf(get(section, "rodType.goodmanId")),
      grade: valueOf(get(section, "rodType.grade")),
      key: valueOf(get(section, "rodType.key")),
      label: valueOf(get(section, "rodType.label")),
      material: valueOf(get(section, "rodType.material")),
      matlabId: valueOf(get(section, "rodType.matlabId")),
      name: valueOf(get(section, "rodType.name")),
      saltWater: valueOf(get(section, "rodType.saltWater")),
      tensileStrength: valueOf(get(section, "rodType.tensileStrength")),
      type: valueOf(get(section, "rodType.type")),
      youngsModulus: valueOf(get(section, "rodType.youngsModulus")),
    },
    summary: valueOf(get(section, "summary")),
  };
}

export default parseSaveRodSection;
