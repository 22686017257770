import compose from "lodash/fp/compose";
import filter from "lodash/fp/filter";
import map from "lodash/fp/map";
import toPairs from "lodash/fp/toPairs";

import {
  DEVIATION_METRICS,
  DEVIATION_PERCENT_THRESHOLD,
} from "constants/deviation";
import {types, setCalculatedDeviations} from "actions/deviation";

import {createMergeEpic} from "../../create-epic";
import inDayRange from "./in-day-range";
import withDeviations from "./with-deviations";

export const calculateDeviations = createMergeEpic(
  [
    types.dailySummariesRetrieved,
    types.setDeviationFilter,
    types.setTimePeriod,
  ],
  async ({payload}, store) => {
    const state = store.getState().deviationReport;

    // Check the payload for new values, otherwise use existing from state.
    // This is done because this epic catches 3 different actions, each which modify a
    // piece of this information. Deviations must be recalculated when anything changes.
    const dailySummaries = payload.dailySummaries || state.dailySummaries;
    const fetched = !!payload.dailySummaries || state.fetched;
    const deviationFilter = payload.deviationFilter || state.deviationFilter;
    const timePeriod = payload.timePeriod || state.timePeriod;

    const process = compose(
      filter(well => {
        const absPercent = well.deviations[deviationFilter.type].absPercent;
        return !isNaN(absPercent) && absPercent > DEVIATION_PERCENT_THRESHOLD;
      }),
      map(withDeviations(DEVIATION_METRICS)),
      map(inDayRange(timePeriod, 1, "date.start")),
      toPairs,
    );

    try {
      return [
        setCalculatedDeviations(
          dailySummaries,
          fetched,
          process(dailySummaries),
          deviationFilter,
          timePeriod,
        ),
      ];
    } catch (err) {
      return [];
    }
  },
);
