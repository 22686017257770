import MAX_DISPLAY_LENGTH from "constants/max-display-length";
import isWillyNilly from "utils/well/is-willy-nilly";

import required from "./required";
import willyNillyLocation from "./willy-nilly-location";

const format = value => {
  return !/^\d{2}-\d{2}-\d{3}-\d{2}W\d$|^[A-Z]-\d{3}-[A-Z]\/\d{3}-[A-Z]-\d{2}$/.test(
    value.trim().toUpperCase(),
  );
};

export default country => value => {
  if (country === "canada" && required(value)) return required(value);

  if (!value) return undefined;

  if (isWillyNilly(country) && willyNillyLocation(value))
    return willyNillyLocation(value);

  if (country === "canada" && format(value))
    return "Must be in DLS or NTS format";

  if (value.length > MAX_DISPLAY_LENGTH)
    return `Surface Location cannot exceed ${MAX_DISPLAY_LENGTH} characters`;
};
