import all from "utils/validation/all";
import required from "utils/validation/required";

export default values => {
  const requiredFields = [
    "gearboxRatio",
    "gearboxSheave",
    "motorSheave",
    ...(values.hasJackshaft
      ? ["jackshaftGearboxSheave", "jackshaftMotorSheave"]
      : []),
  ];

  return all(required, values, requiredFields);
};
