import {compose} from "recompose";
import {connect} from "react-redux";
import {DEVIATION_SORT, DEVIATION_OUTLIER_THRESHOLD} from "constants/deviation";
import Grid from "@material-ui/core/Grid";
import orderBy from "lodash/fp/orderBy";
import partition from "lodash/fp/partition";
import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";

import mergeWellData from "../../merge-well-data";
import WellReportGroup from "../well-report-group";

const styles = {
  report: {
    marginBottom: "5rem",
  },
};

const {MAGNITUDE, FIELD, NAME, PERCENT} = DEVIATION_SORT;

const sortWith = (sortType, deviationType) => {
  const sortFuncs = {
    [MAGNITUDE]: orderBy(`deviations.${deviationType}.absDeviation`, "desc"),
    [PERCENT]: orderBy(`deviations.${deviationType}.absPercent`, "desc"),
    [FIELD]: orderBy("fieldName", "asc"),
    [NAME]: orderBy("displayName", "asc"),
  };

  return sortFuncs[sortType];
};

const mapStateToProps = ({deviationReport, wells: storeWells}, {wells}) => {
  const {sort, timePeriod, deviationFilter} = deviationReport;

  const wellsWithField = mergeWellData(wells, storeWells.byWellId);

  const resultSet = sortWith(sort, deviationFilter.type)(wellsWithField);

  const [wellsWithOutlier, wellsWithNoOutlier] = partition(
    well =>
      well.deviations[deviationFilter.type].absPercent >=
      DEVIATION_OUTLIER_THRESHOLD,
    resultSet,
  );

  return {
    wellsWithOutlier,
    wellsWithNoOutlier,
    timePeriod,
    deviationType: deviationFilter.type,
    deviationLabel: deviationFilter.displayValue,
  };
};

const enhance = compose(
  connect(mapStateToProps),
  withStyles(styles),
);

class Report extends React.Component {
  constructor(props) {
    super(props);

    // Set the initial state.
    // If the list has wells with outliers, hide the everything else list until all the rows of
    // the list of outliers have displayed. If there are no outliers, the everything else list can
    // be shown. Note that if it is empty it will not show even if this is true.
    this.state = {
      outlierReportsDisplayed: 0,
    };
  }

  // Handler for the well report.
  // Allows this class to figure out when to show the second list (everything else).
  // Without this, you keep seeing "everything else" at the bottom of the page
  // but react list keeps loading more things in the first list and pushing it
  // off screen again. Leads to a really jerky experience.
  onReportDisplayed = rowId => {
    this.setState({
      outlierReportsDisplayed: rowId + 1,
    });
  };

  render() {
    const {
      classes,
      wellsWithOutlier,
      wellsWithNoOutlier,
      timePeriod,
      deviationType,
      deviationLabel,
    } = this.props;

    // If both the reports are empty, don't show anything.
    // If one of the two is empty, it will be handled in the WellReportGroup.
    if (wellsWithOutlier.length === 0 && wellsWithNoOutlier.length === 0)
      return null;

    return (
      // Key is important here. By changing the key based on the combination of report type and time period,
      // we force react to re-instantiate the Grid and its children. This is important for correctly counting the number
      // of rows as they are rendered. Without this, the WellReportGroup is re-used and it's underlying react-list
      // may re-use row components and thus not call onReportDisplayed for a new list, leading to bugs with showing
      // the "Everything Else" report section. If the report criteria is changing, we need to toss the old list and start over.
      <Grid
        container
        direction="column"
        spacing={5}
        className={classes.report}
        key={`${deviationType}_${timePeriod}`}
      >
        <WellReportGroup
          title={`${deviationLabel} Deviation`}
          wells={wellsWithOutlier}
          timePeriod={timePeriod}
          showSort={true}
          hideWhenEmpty={false}
          threshold={DEVIATION_OUTLIER_THRESHOLD}
          onReportDisplayed={this.onReportDisplayed}
        />
        {this.state.outlierReportsDisplayed ===
          this.props.wellsWithOutlier.length ||
        this.props.wellsWithOutlier.length === 0 ? (
          <WellReportGroup
            title="Everything Else"
            wells={wellsWithNoOutlier}
            timePeriod={timePeriod}
            showSort={false}
            hideWhenEmpty={true}
          />
        ) : null}
      </Grid>
    );
  }
}

export default enhance(Report);
