import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";

const useStyles = makeStyles(({spacing, colors, typography}) => ({
  root: {
    margin: spacing(4),
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    color: colors.warmGrey[600],
  },
  subtext: {
    ...typography.body2,
    color: colors.warmGrey[400],
  },
  button: {
    marginLeft: spacing(2),
    backgroundColor: colors.blue[400],
    color: "white",
    "&:hover": {
      backgroundColor: colors.blue[500],
    },
  },
}));

const mapStateToProps = ({tags}) => ({
  wellCount: tags.filteredByTags.length,
});

const Footer = ({toggleTagDrawer, wellCount}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <span className={classes.subtext}>
        {wellCount} {wellCount === 1 ? "well" : "wells"} selected
      </span>
      <Button
        className={classes.button}
        onClick={toggleTagDrawer}
        variant="contained"
      >
        close
      </Button>
    </div>
  );
};

export default connect(mapStateToProps)(Footer);
