import store from "store";
import {removeRecommendation} from "actions/wells";
import findWell from "utils/find-well";

export default payload => {
  const wells = store.getState().wells || null;

  if (wells && findWell(wells, payload.downholeLocation)) {
    return store.dispatch(removeRecommendation(payload));
  }
};
