import VMasker from "vanilla-masker";
import React from "react";
import TextField from "@material-ui/core/TextField";
import valueOf from "utils/value-of";

// https://github.com/vanilla-masker/vanilla-masker/blob/359fa2c6f2fb384d98c9e1dd03fd7a164d39e7c7/lib/vanilla-masker.js
const vmaskerMasks = ["Number", "Money", "AlphaNumeric"];

const stripExtraPeriods = input => {
  var output = input.split(".");
  return output.shift() + (output.length ? "." + output.join("") : "");
};

const stripNonNumeric = input => input.toString().replace(/[^0-9-.]/g, "");

function mask(value, patternOrName) {
  if (value === undefined || value === null) {
    return value;
  }

  if (patternOrName === "Decimal") {
    const formattedValue = stripExtraPeriods(stripNonNumeric(value));
    return formattedValue.endsWith(".") ||
      (formattedValue.endsWith("0") && formattedValue.indexOf(".") >= 0) ||
      formattedValue === ""
      ? formattedValue // Don't strip period while typing by parsing
      : parseFloat(formattedValue);
  } else if (vmaskerMasks.includes(patternOrName)) {
    return VMasker[`to${patternOrName}`](value);
  } else if (typeof patternOrName === "function") {
    return VMasker.toPattern(value, patternOrName(value));
  } else {
    return VMasker.toPattern(value, patternOrName);
  }
}

class MaskedTextField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}; // set initial value from default value in props
  }

  updateValue = (event, value) => {
    let inputMaskedValue = mask(value, this.props.inputMask);

    if (this.props.prefix && value.length <= this.props.prefix.length) {
      inputMaskedValue = VMasker.toPattern(
        this.props.prefix + value,
        this.props.inputMask,
      );
    }

    this.setState({value: inputMaskedValue});

    const outputMaskedValue = mask(value, this.props.outputMask);

    if (this.props.onChange) {
      // redux-form tries to read straight from the event.
      if (event) event.target.value = outputMaskedValue;

      this.props.onChange(event, outputMaskedValue, inputMaskedValue);
    }
  };

  onChange = event => {
    this.updateValue(event, event.target.value);
  };

  isPartialLength = value => {
    let maskedValue = mask(value, this.props.inputMask);
    let maskedValueWithPlaceholders = mask(value, {
      pattern: this.props.inputMask,
      placeholder: "`",
    });

    return maskedValue !== maskedValueWithPlaceholders;
  };

  resetInput = () => {
    this.setState({
      value: mask(this.props.prefix || "", this.props.inputMask),
    });
  };

  onBlur = event => {
    if (
      this.props.resetOnPartial &&
      this.isPartialLength(this.state.value || this.props.value)
    ) {
      this.resetInput();
    }
  };

  resetTextIfPrefixOrMaskChanges = newProps => {
    if (
      this.props.prefix !== newProps.prefix ||
      this.props.inputMask !== newProps.inputMask
    ) {
      const value = mask(
        this.state.value || this.props.value,
        newProps.inputMask,
      );

      this.updateValue(null, value);

      this.setState({
        value: value,
      });
    }
  };

  componentWillReceiveProps(newProps) {
    if (newProps.value !== this.props.value) {
      this.setState({value: newProps.value});
    }

    this.resetTextIfPrefixOrMaskChanges(newProps);
  }

  render() {
    const {inputMask, outputMask, maskOnEnter, ...other} = this.props;

    delete other.defaultValue; // remove default value from TextField input (see link below)
    delete other.resetOnPartial;

    other.onChange = this.onChange;
    other.onBlur = this.onBlur;

    const stateValue = valueOf(this.state.value);
    const propsValue = valueOf(this.props.value);

    if (stateValue !== undefined) {
      other.value = maskOnEnter ? mask(stateValue, inputMask) : stateValue;
    } else {
      other.value = mask(propsValue, inputMask);
    }

    if (other.value === undefined || other.value === null) {
      other.value = "";
    }

    return <TextField {...other} />;
  }
}

export default MaskedTextField;
