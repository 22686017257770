import Grid from "@material-ui/core/Grid";
import DialogContent from "@material-ui/core/DialogContent";

import React from "react";

import FormWarning from "components/form-warning";
import DecimalTextField from "components/forms/decimal-text-field";
import PlainText from "components/forms/plain-text";
import TextField from "components/forms/text-field";

const IdleTimeRecommendation = () => {
  return (
    <DialogContent>
      <Grid container>
        <Grid item xs={12}>
          <PlainText name="description" />
        </Grid>

        <Grid item xs={12}>
          <TextField name="comment" fullWidth label="Comment" />
        </Grid>

        <Grid item xs={6}>
          <DecimalTextField name="acceptedData.hours" fullWidth label="Hours" />
        </Grid>

        <Grid item xs={6}>
          <DecimalTextField
            name="acceptedData.minutes"
            fullWidth
            label="Minutes"
          />
        </Grid>

        <Grid item xs={12}>
          <FormWarning style={{marginTop: "22px"}}>
            Downtime settings will automatically update if this recommendation
            is accepted.
          </FormWarning>
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default IdleTimeRecommendation;
