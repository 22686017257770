import {compose} from "recompose";
import clsx from "clsx";
import {extent} from "d3-array";
import IconDown from "@material-ui/icons/ArrowDownwardRounded";
import IconUp from "@material-ui/icons/ArrowUpwardRounded";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import findLast from "lodash/fp/findLast";

import {formatSignedNumber, formatNumber} from "utils/format-number";

import Plot from "./plot";

const styles = ({breakpoints}) => ({
  root: {
    padding: "10px 16px",
    margin: "0 10px",
    [breakpoints.down(900)]: {
      margin: 0,
    },
  },

  title: {
    color: "#666666",
    marginBottom: "5px",
    fontSize: "14px",
  },

  primaryLabel: {
    color: "#282F34",
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: "4px",
    marginTop: "12px",
    whiteSpace: "nowrap",
    [breakpoints.down(900)]: {
      fontSize: "20px",
    },
  },

  primaryUnit: {
    fontSize: "20px",
    marginLeft: "3px",
  },

  secondaryLabel: {
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: 600,
    whiteSpace: "nowrap",
    color: "#999999",
    marginBottom: "12px",
    [breakpoints.down(1000)]: {
      flexWrap: "wrap",
    },
    [breakpoints.down(900)]: {
      fontSize: "14px",
    },
  },

  noData: {
    marginTop: "12px",
    fontSize: "16px",
    fontWeight: 600,
    whiteSpace: "nowrap",
    color: "#999999",
  },

  deviation: {
    marginRight: "4px",
    [breakpoints.down(1000)]: {
      width: "100%",
      marginBottom: "4px",
    },
  },

  unit: {
    fontSize: "0.8em",
    marginLeft: "3px",
  },

  warning: {
    color: "#B33A3A",
  },

  icon: {
    verticalAlign: "bottom",
    fontSize: "22px",
    marginLeft: "8px",
  },

  active: {
    borderRadius: "5px",
    borderLeft: "5px solid #4b586b",
    boxShadow: "0 6px 18px rgba(0,0,0,0.15)",
    backgroundColor: "#FFF",
  },
});

const enhance = compose(withStyles(styles));

const generateYDomain = data => {
  const [domainMin, domainMax] = extent(data, point => point.y);
  const domainDiff = domainMax - domainMin;
  const padding = domainDiff * 0.1 || 1;

  // Return the domain.
  // In the case of the minimum, we never want it be negative.
  // In a few edge cases, particularly if drawing a line of zeros, the domain minimum will
  // be below zero once the padding is applied.
  return [Math.max(domainMin - padding, 0), domainMax + padding];
};

const getMark = data => [findLast(p => p.y !== null, data)];

const icon = (deviation, classes) => {
  if (deviation > 0) {
    return <IconUp className={classes.icon} />;
  } else if (deviation < 0) {
    return <IconDown className={classes.icon} />;
  }
};

const DeviationPlot = ({
  classes,
  data,
  avgData,
  title,
  value,
  unit,
  deviation,
  deviationPercent,
  outlier,
  active,
  noData,
}) =>
  // It is possible that this graph has no data for the entire time range but other metrics may be fine.
  // In this case, we don't want to render the labels and graph as NaN's and nulls will occur and create errors.
  noData ? (
    <div className={clsx(classes.root, {[classes.active]: active})}>
      <div>
        <div className={classes.title}>{title}</div>
        <div className={classes.noData}>No Data</div>
      </div>
    </div>
  ) : (
    <div className={clsx(classes.root, {[classes.active]: active})}>
      <div>
        <div className={classes.title}>{title}</div>

        <div className={classes.primaryLabel}>
          {formatNumber(value)}
          <span className={classes.unit}>{unit}</span>
        </div>

        <div
          className={clsx(classes.secondaryLabel, {
            [classes.warning]: outlier,
          })}
        >
          <div className={classes.deviation}>
            {formatSignedNumber(deviation)}
            <span className={classes.unit}>{unit}</span>
          </div>
          <div>
            ({formatNumber(deviationPercent)}
            <span className={classes.unit}>%</span>)
          </div>
          <div>{icon(deviation, classes)}</div>
        </div>
      </div>
      <div>
        <Plot
          data={data}
          avgData={avgData}
          markData={getMark(data)}
          yDomain={generateYDomain(data)}
          outlier={outlier}
        />
      </div>
    </div>
  );

export default enhance(DeviationPlot);
