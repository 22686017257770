import {parseWell} from "actions/wells";
import store from "store";

import {getWell} from "./get-well";

export const wellUpdated = data => {
  const well = getWell(data.downholeLocation);

  if (well) {
    return store.dispatch(parseWell({data}));
  }
};
