import fetch from "epics/async-fetch";

export default async (alias, organizationId, downholeLocation) => {
  const {exists} = await fetch("/optimization/wells/check-alias", {
    alias: alias.trim(),
    downholeLocation,
    organizationId,
  });

  return exists;
};
