import Aux from "components/aux";
import React from "react";
import {Control} from "react-redux-form";

import Errors from "./errors";

export default props => {
  const {component, errorMessages, errors, modelPath} = props;

  return (
    <Aux>
      <Control component={component} errors={errors} model={modelPath} />
      <Errors errorMessages={errorMessages} modelPath={modelPath} />
    </Aux>
  );
};
