import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import makeStyles from "@material-ui/styles/makeStyles";
import clsx from "clsx";

import {useHover} from "hooks/use-hover";

import Title from "./title";
import {MAX} from "../range-slider-constants";

const useStyles = makeStyles(({colors}) => ({
  maxDot: ({maxSPM, color}) => {
    return {
      zIndex: 100,
      position: "absolute",
      backgroundColor: color,
      borderRadius: "50%",
      width: 10,
      height: 10,
      left: `${(maxSPM / MAX) * 100}%`,
      top: -5,
    };
  },
  minDot: ({minSPM}) => {
    return {
      position: "absolute",
      backgroundColor: colors.warmGrey[400],
      borderRadius: "50%",
      width: 10,
      height: 10,
      left: `${(minSPM / MAX) * 100}%`,
      top: -5,
    };
  },
  displayNone: {
    display: "none",
  },
}));

const Dots = ({minSPM, maxSPM, color}) => {
  const {isHovering, enableHover, disableHover} = useHover();

  const hideMin = !isHovering || minSPM >= 15;
  const hideMax = maxSPM >= 15;

  const classes = useStyles({minSPM, maxSPM, color});

  return (
    <>
      <div className={clsx(classes.minDot, hideMin && classes.displayNone)} />
      <Tooltip title={<Title minSPM={minSPM} maxSPM={maxSPM} />}>
        <div
          onMouseOver={enableHover}
          onMouseLeave={disableHover}
          className={clsx(classes.maxDot, hideMax && classes.displayNone)}
        />
      </Tooltip>
    </>
  );
};

export default Dots;
