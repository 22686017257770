import {createMergeEpic} from "epics/create-epic";
import fetch from "epics/async-fetch";
import getUser from "utils/get-user";
import {types} from "actions/wells";
import {show} from "actions/snackbar";

import onUpdateSuccess from "../on-update-success";
import {parse} from "./parse";

export const setLufkinMalfunction = createMergeEpic(
  [types.setLufkinMalfunction],
  async ({payload}) => {
    try {
      const {unitsOfMeasure} = getUser();
      const {values, well} = payload;

      await fetch(
        `/wells/${encodeURIComponent(
          well.downholeLocation,
        )}/lufkin/settings/malfunction`,
        {},
        {
          method: "PUT",
          body: parse({values, unitsOfMeasure}),
        },
      );

      return onUpdateSuccess({
        well,
        updatePath: "malfunction.request",
        payload: values,
      });
    } catch (err) {
      return [
        show({
          message:
            "An error occurred while attempting to update the malfunction setting on the Lufkin device.",
        }),
      ];
    }
  },
);

export default setLufkinMalfunction;
