import React, {useState, useRef, useCallback} from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import ArrowExpandUpIcon from "mdi-material-ui/ArrowExpandUp";
import ArrowExpandDownIcon from "mdi-material-ui/ArrowExpandDown";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "lodash/fp/compose";
import flatMap from "lodash/flatMap";
import debounce from "lodash/debounce";
import throttle from "lodash/throttle";
import get from "lodash/get";
import Typography from "@material-ui/core/Typography";
import {push} from "react-router-redux";
import {connect} from "react-redux";

import Dialog from "components/dialog";
import {saveRecentlyVisitedWell} from "utils/recently-visited-wells";
import {toggleSearchDialog} from "actions/search";

import RecentlyVisited from "./recently-visited";
import SearchResults from "./search-results";
import {searchWells} from "./search-wells";

const styles = ({colors}) => ({
  input: {
    width: 500,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `1px solid ${colors.warmGrey[200]}`,
  },
  content: {
    height: 500,
    overflowY: "auto",
    padding: 0,
    paddingBottom: 24,
  },
  instructionGroup: {
    display: "flex",
    alignItems: "center",
    marginLeft: 38,
  },
  instructionIcon: {
    fontSize: 14,
    backgroundColor: "rgba(216, 216, 215, 0.50)",
    border: "1px solid #979797",
    borderRadius: 4,
    padding: 4,
    margin: "4px 0",
    marginRight: 4,
  },
  instructionText: {
    marginLeft: 4,
  },
  footer: {
    height: 60,
    borderTop: `1px solid ${colors.warmGrey[200]}`,
  },
  footerRoot: {
    margin: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
});

const mapStateToProps = ({
  search: {searchDialogOpen: open},
  wells: {byWellId: wells},
  auth,
  organizationView: {currentOrganization},
}) => ({
  open,
  wells: flatMap(wells),
  userId: get(auth, "user.clientUserId"),
  currentOrganization,
});

const mapDispatchToProps = dispatch => ({
  closeDialog: () => dispatch(toggleSearchDialog(false)),
  routeToWell: downholeLocation =>
    dispatch(push(`/well/${encodeURIComponent(downholeLocation)}/analysis`)),
});

const SearchDialog = ({
  classes,
  open,
  closeDialog,
  wells,
  routeToWell,
  userId,
  currentOrganization,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [wellResults, setWellResults] = useState([]);
  const debouncedWellSearch = useRef(
    debounce((wells, value) => setWellResults(searchWells(wells, value)), 450),
  );
  const throttledWellSearch = useRef(
    throttle((wells, value) => setWellResults(searchWells(wells, value)), 250),
  );

  // Interesting method of debouncing and throttling search to give a responsive
  // yet performant search experience
  // https://www.peterbe.com/plog/how-to-throttle-and-debounce-an-autocomplete-input-in-react
  const onInputChange = e => {
    const value = e.target.value;
    setInputValue(value);

    if (value.length < 5) {
      throttledWellSearch.current(wells, value);
    } else {
      debouncedWellSearch.current(wells, value);
    }
  };

  const onClose = useCallback(
    () => {
      setWellResults([]);
      setInputValue("");
      closeDialog();
    },
    [setWellResults, setInputValue, closeDialog],
  );

  const onResultClick = ({downholeLocation, wellId}) => {
    saveRecentlyVisitedWell(userId, currentOrganization, wellId);
    routeToWell(downholeLocation);
    onClose();
  };

  return (
    <Dialog open={open} fullWidth onClose={onClose} maxWidth="lg">
      {open && (
        <>
          <DialogTitle disableTypography className={classes.header}>
            <TextField
              autoFocus
              className={classes.input}
              placeholder="Search wells"
              variant="outlined"
              onChange={onInputChange}
              value={inputValue}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <span>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </span>
          </DialogTitle>
          <DialogContent classes={{root: classes.content}}>
            {inputValue.length > 0 ? (
              <SearchResults results={wellResults} onClick={onResultClick} />
            ) : (
              <RecentlyVisited onClose={onClose} />
            )}
          </DialogContent>
          <DialogActions
            className={classes.footer}
            classes={{root: classes.footerRoot}}
          >
            <div className={classes.instructionGroup}>
              <ArrowExpandUpIcon className={classes.instructionIcon} />
              <ArrowExpandDownIcon className={classes.instructionIcon} />
              <Typography className={classes.instructionText} variant="caption">
                to navigate
              </Typography>
            </div>
            <div className={classes.instructionGroup}>
              <KeyboardReturnIcon className={classes.instructionIcon} />
              <Typography className={classes.instructionText} variant="caption">
                to select
              </Typography>
            </div>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

const enhance = compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(SearchDialog);
