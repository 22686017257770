import isNil from "lodash/isNil";

import process from "./process";

export default ({state, action}) => {
  let sortAsc = action.payload.sortAsc;

  if (isNil(sortAsc)) {
    sortAsc =
      state.sortBy === action.payload.sortBy ? !state.sortAsc : state.sortAsc;
  }

  return process({
    ...state,
    sorting: {
      sortAsc,
      sortBy: action.payload.sortBy,
    },
  });
};
