import process from "../process";

export const setWorkflow = ({state, action}) => {
  return process({
    ...state,
    workflow: {
      ...state.workflow,
      selected: action.payload.workflow,
    },
  });
};
