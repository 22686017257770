import getSafeOperatingSpeedMax from "@ambyint/common/utils/wells/get-safe-operating-speed-max";
import getSafeOperatingSpeedMin from "@ambyint/common/utils/wells/get-safe-operating-speed-min";

import decimalPrecision from "utils/validation/decimal-precision";
import max from "utils/validation/max";
import min from "utils/validation/min";
import number from "utils/validation/number";
import required from "utils/validation/required";
import validateFieldsOf from "utils/validation/validate-fields-of";

export default (values, props) => {
  const validateField = validateFieldsOf(values);

  const {well} = props;

  if (!well) return undefined;

  const maxSpeed = getSafeOperatingSpeedMax(well);
  const minSpeed = getSafeOperatingSpeedMin(well);

  return {
    ...(Number(values.max) < Number(values.min)
      ? {max: "Max cannot be less than min."}
      : {}),

    ...validateField("min", x => required(x)),
    ...validateField("min", x => number(x)),
    ...validateField("min", x => min(x, minSpeed)),
    ...validateField("min", x => max(x, maxSpeed)),
    ...validateField("min", x => max(x, values.max)),
    ...validateField("min", x => decimalPrecision(x, 1)),

    ...validateField("max", x => required(x)),
    ...validateField("max", x => number(x)),
    ...validateField("max", x => min(x, values.min)),
    ...validateField("max", x => min(x, minSpeed)),
    ...validateField("max", x => max(x, maxSpeed)),
    ...validateField("max", x => decimalPrecision(x, 1)),
  };
};
