import {connect} from "react-redux";
import React from "react";

import {resolve} from "actions/alerts";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "components/dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import {hide} from "actions/resolve-alert-dialog";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";

const mapStateToProps = state => {
  return {
    alert: state.resolveAlertDialog.alert,
    isOpen: state.resolveAlertDialog.isOpen,
  };
};

class ResolveAlertDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
    };
  }

  updateForm = value => {
    this.setState({
      form: {
        ...this.state.form,
        ...value,
      },
    });
  };

  render() {
    const {alert, isOpen, dispatch} = this.props;
    if (!alert) return null;

    const closeDialog = () => dispatch(hide());
    const resolveAlert = () => {
      dispatch(
        resolve({
          ...this.state.form,
          alertId: alert.alertId,
        }),
      );
    };

    return (
      <Dialog open={isOpen} onClose={closeDialog}>
        <DialogTitle>
          <Grid
            container
            spacing={0}
            justify={"space-between"}
            alignItems={"center"}
          >
            <Grid item xs>
              {"Resolve Alert"}
            </Grid>
            <Grid item xs={2} sm={1} style={{textAlign: "right"}}>
              <IconButton onClick={closeDialog}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            When resolving an alert, on call users will be notified that the
            alert is now resolved. You may add notes to provide additional
            context.
          </DialogContentText>
          <TextField
            id="resolveAlert-comment"
            label="Notes"
            InputProps={{
              placeholder: "Add notes regarding this alert resolution",
            }}
            helperText="Optional"
            fullWidth
            margin="normal"
            onChange={e => this.updateForm({comment: e.target.value})}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={resolveAlert} color="primary">
            Resolve Alert
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
export default connect(mapStateToProps)(ResolveAlertDialog);
