import Aux from "components/aux";
import lowercase from "lodash/lowerCase";
import Typography from "@material-ui/core/Typography";
import React from "react";

import getDisplayName from "utils/user/get-display-name";

const mapPermissionChange = event => {
  const {previous, updated} = event.data;

  if (previous.accessLevel !== updated.accessLevel) {
    return (
      <Aux>
        {" changed"}
        <strong>{` ${getDisplayName(event.performedOn)}'s`}</strong>
        {` well control access level to ${lowercase(updated.accessLevel)}`}
      </Aux>
    );
  }

  return (
    <Aux>
      {` ${updated.isInvitedUserApprover ? "granted" : "revoked"}`}
      <strong>
        {` ${getDisplayName(event.performedOn)}`}
        {updated.isInvitedUserApprover ? "" : "'s"}
      </strong>
      {" approver status"}
    </Aux>
  );
};

export default props => {
  const {event} = props;

  return (
    <Aux>
      <Typography variant="body1">
        {getDisplayName(event.performedBy)}
        {mapPermissionChange(event)}
      </Typography>
    </Aux>
  );
};
