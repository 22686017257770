import {set, types} from "actions/cranks";
import fetch from "epics/fetch";

export const search = (action$, store) => {
  return action$.ofType(types.fetch).switchMap(() => {
    const state = store.getState().cranks;
    if (state.fetched) return [];
    return fetch(`/cranks`).map(({response: data}) => set({data}));
  });
};
