import {connect} from "react-redux";
import React from "react";

import FilterableSelect from "components/filterable-select";

import {set} from "actions/organization-view";

const mapStateToProps = state => ({
  currentOrganization: state.organizationView.currentOrganization,
  organizations: state.auth.user.assignedOrganizations,
});

const mapDispatchToProps = dispatch => ({
  setCurrentOrganization: val =>
    dispatch(set({currentOrganization: val.organizationId, redirectTo: "/"})),
});

const getCurrentValue = (organizations, currentOrganization) =>
  organizations.find(org => org.organizationId === currentOrganization);

const OrganizationView = ({
  currentOrganization,
  organizations,
  setCurrentOrganization,
}) => {
  if (organizations.length < 2) {
    return null;
  }

  return (
    <div style={{width: "100%"}}>
      <FilterableSelect
        fullWidth
        noEmpty
        id="organization-view"
        mapLabel={d => d.organizationName}
        mapValue={d => d.organizationId}
        maxWidth="268px"
        items={organizations}
        maxDisplayCount={200}
        onSelect={setCurrentOrganization}
        value={getCurrentValue(organizations, currentOrganization)}
      />
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrganizationView);
