import {setSearch, types} from "actions/pumpjacks";
import fetch from "epics/fetch";

export const search = (action$, store) => {
  return action$.ofType(types.search).switchMap(({payload}) => {
    return fetch(
      `/optimization/pumpjacks/search?term=${payload.searchTerm}`,
    ).map(({response: data}) => setSearch({data}));
  });
};
