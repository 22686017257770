import {types} from "./types";

export const setOrganizationServiceAccount = options => {
  const {organizationId, data} = options;

  return {
    type: types.setOrganizationServiceAccount,
    payload: {
      organizationId,
      data,
    },
  };
};
