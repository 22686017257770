import groupBy from "lodash/groupBy";
import partition from "lodash/partition";
import max from "lodash/max";
import min from "lodash/min";
import sortBy from "lodash/sortBy";
import map from "lodash/map";
import get from "lodash/get";

export default (events = []) => {
  const grouped = groupBy(sortBy(events, "createdOn"), "type");

  const [inferredOilProduction, oilProduction] = partition(
    grouped.oilProduction,
    ({category}) => category === "conditionUpdated",
  );

  const ambyintFillage = map(grouped.inferredProductionAnalysis, e => ({
    ...e,
    value: get(e, "pumpLiquidFillage.value"),
    units: get(e, "pumpLiquidFillage.units"),
  }));

  grouped.dates = events.map(event => new Date(event.createdOn));
  grouped.maxDate =
    grouped.dates && grouped.dates.length > 0
      ? new Date(max(grouped.dates, "createdOn"))
      : null;
  grouped.minDate =
    grouped.dates && grouped.dates.length > 0
      ? new Date(min(grouped.dates, "createdOn"))
      : null;

  return {
    ...grouped,
    oilProduction,
    inferredOilProduction,
    ambyintFillage,
  };
};
