import all from "utils/validation/all";
import required from "utils/validation/required";
import isPositive from "utils/validation/positive";

export default values => {
  const requiredFields = ["rodType", "rodDimensions", "interval", "diameter"];
  const needed = all(required, values, requiredFields);
  const positive = all(isPositive, values, ["interval"]);

  return {
    ...needed,
    ...positive,
  };
};
