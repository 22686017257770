import get from "lodash/get";

export const toggleWellSelected = ({state, action}) => {
  const {wellIds} = action.payload;

  const selectedWells = {...state.selectedWells};

  wellIds.forEach(wellId => {
    const selected = !get(selectedWells, [wellId, "selected"], false);
    selectedWells[wellId] = {selected};
  });

  return {...state, selectedWells};
};
