import React from "react";
import capitalize from "lodash/capitalize";

import TextFieldListMenu from "components/forms/text-field-list-menu";

const RotationDirectionField = field => {
  const rotationDirectionOptions = ["clockwise", "counter-clockwise"];

  return (
    <TextFieldListMenu
      name={field.input.name}
      label={field.label}
      options={rotationDirectionOptions}
      value={field.input.value}
      formatDisplay={option => capitalize(option)}
      onChange={field.input.onChange}
      error={field.meta.touched && field.meta.error}
      fullWidth
    />
  );
};

export default RotationDirectionField;
