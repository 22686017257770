import {connect} from "react-redux";
import React from "react";

import {search as searchWells} from "actions/wells";

import Search from "../search";

const mapDispatchToProps = dispatch => {
  return {
    search: criteria => dispatch(searchWells({criteria})),
  };
};

const mapStateToProps = state => {
  return {
    value: state.wells.search.criteria,
  };
};

const WellSearch = props => {
  const {search, value} = props;

  return <Search id={"well-search"} onSearch={search} initialValue={value} />;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WellSearch);
