import {ajax} from "rxjs/observable/dom/ajax";
import addAuthHeaders from "epics/add-auth-headers";
import querystring from "query-string";
import store from "store";

export default (url, queryOptions = {}, options = {}) => {
  const query = querystring.stringify(queryOptions);

  return ajax(
    addAuthHeaders(
      {
        ...options,
        url: `${process.env.REACT_APP_API}${url}${query ? `?${query}` : ""}`,
        responseType: "json",
      },
      store,
    ),
  );
};
