import React, {useState} from "react";
import {connect} from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import TagMultipleIcon from "mdi-material-ui/TagMultiple";
import clsx from "clsx";

import {ClickEvent} from "components/analytics";
import {eventCategory, eventAction} from "constants/analytics";
import {toggleTagDrawer} from "actions/tagging";
import Tooltip from "components/material-tooltip";

const mapDispatchToProps = dispatch => ({
  handleToggleTagDrawer: () => dispatch(toggleTagDrawer()),
});

const TagsButton = props => {
  const {handleToggleTagDrawer, parentButtonGroupClasses} = props;

  const [tagTooltipOpen, setTagTooltipOpen] = useState(false);

  return (
    <ClickEvent
      category={eventCategory.tags}
      action={eventAction.toggle}
      label={"Tag Filter Menu"}
    >
      <Tooltip title="Select Tags" PopperProps={{open: tagTooltipOpen}}>
        <span className={parentButtonGroupClasses.grey}>
          <IconButton
            onClick={e => {
              handleToggleTagDrawer();
              setTagTooltipOpen(false);
            }}
            className={clsx(parentButtonGroupClasses.button)}
            variant="contained"
            onMouseOver={() => setTagTooltipOpen(true)}
            onMouseOut={() => setTagTooltipOpen(false)}
            size="small"
          >
            <TagMultipleIcon />
          </IconButton>
        </span>
      </Tooltip>
    </ClickEvent>
  );
};

export default connect(
  null,
  mapDispatchToProps,
)(TagsButton);
