import clsx from "clsx";
import {connect} from "react-redux";
import {compose} from "recompose";
import min from "lodash/fp/min";
import max from "lodash/fp/max";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import ColorLegend from "./color-legend";
import SizeLegend from "./size-legend";

const styles = ({breakpoints, typography}) => ({
  legend: {
    ...typography.body1,
    flex: 2,
    minHeight: "340px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "0 16px",
    [breakpoints.down(800)]: {
      lineHeight: "1.4",
    },
  },

  disabled: {
    opacity: 0.25,
    filter: "grayscale(100%)",
  },
});

const mapStateToProps = ({deviationReport}) => {
  const {deviationFilter, wells} = deviationReport;
  const deviations = wells.map(
    well => well.deviations[deviationFilter.type].absDeviation,
  );

  return {
    max: max(deviations),
    min: min(deviations),
    unit: deviationFilter.unit,
  };
};

const enhance = compose(
  withStyles(styles),
  connect(mapStateToProps),
);

const Legend = ({classes, disabled, ...rest}) => {
  return (
    <div className={clsx(classes.legend, {[classes.disabled]: disabled})}>
      <SizeLegend {...rest} />
      <ColorLegend />
    </div>
  );
};

export default enhance(Legend);
