import max from "utils/validation/max";
import min from "utils/validation/min";
import number from "utils/validation/number";
import required from "utils/validation/required";
import validateFieldsOf from "utils/validation/validate-fields-of";

const validate = values => {
  const validateField = validateFieldsOf(values);

  const errors = {
    ...validateField("dampingCoefficient", x => required(x)),
    ...validateField("dampingCoefficient", x => number(x)),
    ...validateField("dampingCoefficient", x => min(x, 0)),
    ...validateField("dampingCoefficient", x => max(x, 0.5)),

    ...validateField("frictionCoefficient", x => required(x)),
    ...validateField("frictionCoefficient", x => number(x)),
    ...validateField("frictionCoefficient", x => min(x, 0)),
    ...validateField("frictionCoefficient", x => max(x, 0.5)),
  };

  return errors;
};

export default validate;
