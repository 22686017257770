import {types} from "./types";

export const searchOrganizationServiceAccounts = options => {
  const {organizationId, search} = options;

  return {
    type: types.searchOrganizationServiceAccounts,
    payload: {
      organizationId,
      search,
    },
  };
};
