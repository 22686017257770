import all from "utils/validation/all";
import isEmpty from "lodash/isEmpty";
import required from "utils/validation/required";

import getPumpingUnitLabel from "./get-pumping-unit-label";
import hasGeometry from "./has-geometry";

export default values => {
  let errors = {};

  const requiredFields = ["pumpjackSearch"];

  if (hasGeometry(values.pumpjack)) {
    requiredFields.push("crankPinPosition", "rotationDirection");
  }

  errors = all(required, values, requiredFields);

  if (!values.pumpjackSearch || values.pumpjackSearch.length < 3) {
    errors.pumpjackSearch =
      "Required, must provide at least 3 characters to search";
  } else if (
    !values.pumpjack ||
    values.pumpjackSearch !== getPumpingUnitLabel(values.pumpjack)
  ) {
    errors.pumpjackSearch = isEmpty(values.pumpjackSearch)
      ? "Required"
      : "No matching pumpjack found";
  }

  return errors;
};
