import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import colorScale from "../../color-scale";

const styles = ({typography}) => ({
  swatches: {
    ...typography.caption,
    listStyle: "none",
    paddingLeft: "0",
    fontSize: 10,
  },

  swatch: {
    display: "inline-block",
    textAlign: "center",
    marginRight: 2,

    "& > div": {
      height: 10,
      width: 28,
      marginBottom: 5,
    },
  },

  root: {
    marginBottom: "auto",
    marginTop: "8px",
  },
});

const ColorLegend = ({classes}) => {
  return (
    <div className={classes.root}>
      <p>Color shows the percentage change.</p>

      <ul className={classes.swatches}>
        {[-25, -10, 0, 10, 25].map(n => (
          <li className={classes.swatch} key={n}>
            <div style={{background: colorScale(n)}} />
            <span>{`${n}%`}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default withStyles(styles)(ColorLegend);
