export default ({values, reverse}) => {
  return values.sort(function(a, b) {
    let aV =
      a.rollup.uptime && a.rollup.uptime.length >= 1
        ? a.rollup.uptime[0].runningPercent
        : -1;
    let bV =
      b.rollup.uptime && b.rollup.uptime.length >= 1
        ? b.rollup.uptime[0].runningPercent
        : -1;

    if (aV === bV) {
      aV = a.displayName.toLowerCase();
      bV = b.displayName.toLowerCase();
    }

    return reverse * ((aV > bV) - (bV > aV));
  });
};
