import React from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import isEmpty from "lodash/isEmpty";

import {FormatDateTime} from "components/format/date";

const displayNotifiedUsers = users => {
  if (isEmpty(users)) {
    return (
      <Typography variant="body2">
        No available on call users were notified
      </Typography>
    );
  }

  return users.map(d => {
    return (
      <Typography variant="body2" key={d.email}>
        {`${d.firstName} ${d.lastName}`}
      </Typography>
    );
  });
};

export default props => {
  const {alert, style} = props;

  return (
    <div style={style}>
      <Grid container justify={"space-between"}>
        <Grid item xs>
          <Typography variant="caption">Alert Created</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="caption" style={{textAlign: "right"}}>
            <FormatDateTime date={alert.createdOn} />
          </Typography>
        </Grid>
      </Grid>
      <Grid container justify={"space-between"}>
        <Grid item xs>
          <Typography variant="body2">{alert.description}</Typography>
        </Grid>
      </Grid>
      <Grid container justify={"space-between"}>
        <Grid item xs>
          <Typography variant="caption">Users Notified</Typography>
        </Grid>
        <Grid item xs />
      </Grid>
      <Grid container justify={"space-between"}>
        <Grid item xs>
          {displayNotifiedUsers(alert.users)}
        </Grid>
      </Grid>
    </div>
  );
};
