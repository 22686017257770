import React from "react";
import theme from "theme";
import {hasControlForEpm} from "@ambyint/common/utils/devices/control-for-epm";
import {hasControlForVfd} from "@ambyint/common/utils/devices/control-for-vfd";
import Paper from "@material-ui/core/Paper";

import Cards from "./cards";
import {DownholeCardClassifications} from "./downhole-card-classifications";
import DriveDataAnalysis from "./drive-data-analysis";
import DriveDataChart from "./drive-data-chart";
import DynamometerCardAnalysis from "./dynamometer-card-analysis";
import FluidLevelAnalysis from "./fluid-level-analysis";
import PumpjackAnalysis from "./pumpjack-analysis";
import RodStringAnalysis from "./rod-string-analysis";
import Summary from "./summary";
import Toolbar from "./toolbar";

const width = 480;

const styles = {
  container: {
    ...theme.typography.body1,
    margin: "0 16px 16px 0",
    width: `${width}px`,
  },
  info: {
    item: {
      ...theme.typography.body1,
      flex: 1,
      padding: "16px",
    },
  },
};

export default ({
  comparison,
  devices,
  goToSetup,
  remove,
  showRawComparisons,
  toggleComparisonsView,
}) => {
  const {
    color,
    correlationId,
    createdOn,
    downholeCardClassifications,
    driveData,
    fluidLevelAnalysis,
    generatedCards,
    goodmanAnalysis,
    inferredProductionAnalysis,
    pumpjackAnalysis,
    rawCards,
    rodStringWeightAnalysis,
  } = comparison;

  const isEpm = hasControlForEpm(devices);
  const isVfd = hasControlForVfd(devices);

  return (
    <Paper style={styles.container}>
      <Toolbar
        {...comparison}
        id={correlationId}
        date={createdOn}
        style={{display: "none"}}
        remove={remove}
      />
      <div style={{borderBottom: `3px solid ${color}`}} />
      {!isEpm && !isVfd && (
        <Cards
          generatedCards={generatedCards}
          goToSetup={goToSetup}
          rawCards={rawCards}
          showRawComparisons={showRawComparisons}
          toggleComparisonsView={toggleComparisonsView}
          width={width}
        />
      )}
      {(isEpm || isVfd) && [
        <DriveDataChart
          key={"drive-data-chart"}
          data={{driveData}}
          devices={devices}
          width={width}
          showGuidelines
        />,
        <DriveDataAnalysis
          key={"drive-data-analysis"}
          data={{driveData}}
          devices={devices}
        />,
      ]}
      <Summary data={comparison} />
      {downholeCardClassifications && (
        <DownholeCardClassifications data={{downholeCardClassifications}} />
      )}
      <DynamometerCardAnalysis
        data={{
          fluidLevelAnalysis,
          generatedCards,
          inferredProductionAnalysis,
          rawCards,
          showRawComparisons,
          rodStringWeightAnalysis,
        }}
      />
      {!showRawComparisons && pumpjackAnalysis && (
        <PumpjackAnalysis data={pumpjackAnalysis} />
      )}
      {!showRawComparisons && goodmanAnalysis && rodStringWeightAnalysis && (
        <RodStringAnalysis
          data={{...goodmanAnalysis, ...rodStringWeightAnalysis}}
        />
      )}
      {!showRawComparisons && fluidLevelAnalysis && (
        <FluidLevelAnalysis data={fluidLevelAnalysis} />
      )}
    </Paper>
  );
};
