import moment from "moment";

const dateIsYesterday = date =>
  moment()
    .startOf("day")
    .diff(moment(date).startOf("day"), "days") === 1;

const dateIsToday = date => moment().format("DD") === moment(date).format("DD");

const dateIsWithinTheHour = date => moment().diff(moment(date), "minutes") < 60;

/**
 * Given a date object, this function will output a string of the relative time to that date
 * When the card day is -1 from today: Yesterday
 * When the card day is earlier today: # hours ago
 * Everything else is: ___day, MMM Do ex: Wed, Sept 25th
 * @param Date
 */
export const getRelativeTime = inputDate => {
  if (!(inputDate instanceof Date)) {
    console.warn(
      "Attempted to get relative time ago from invalid date input type",
      {type: typeof inputDate, value: inputDate},
    );
    return "Invalid date";
  }

  if (dateIsWithinTheHour(inputDate)) {
    const diff = moment().diff(moment(inputDate), "minutes");
    return `${diff} minutes ago`;
  } else if (dateIsYesterday(inputDate)) {
    return "Yesterday";
  } else if (dateIsToday(inputDate)) {
    const diff = moment().diff(moment(inputDate), "hours");
    return `${diff} hours ago`;
  }

  return moment(inputDate).format("ddd, MMM Do");
};
