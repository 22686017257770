import {createSwitchEpic} from "epics/create-epic";
import {setShareableAnalysisLink, types} from "actions/wells";

export const generateShareableAnalysisLink = createSwitchEpic(
  [types.generateShareableAnalysisLink],
  async (action, store) => {
    // This doesn't require an epic however it's only a temporary hotfix and
    // the new short URL will be generated here so the structure was kept in tact
    return [setShareableAnalysisLink(action.payload.url, action.payload.url)];
  },
);
