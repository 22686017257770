import required from "utils/validation/required";
import validateFieldsOf from "utils/validation/validate-fields-of";

export default values => {
  const validateField = validateFieldsOf(values);

  return {
    ...validateField("firstName", x => required(x)),
    ...validateField("lastName", x => required(x)),
  };
};
