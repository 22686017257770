import {Field} from "redux-form";
import React from "react";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Tooltip from "components/tooltip";
import Switch from "@material-ui/core/Switch";
import noop from "utils/noop";

const Component = props => {
  const {
    classes = {},
    input,
    label,
    tooltip,
    header,
    externalOnChange = noop,
  } = props;

  return (
    <div style={{padding: "8px"}}>
      {props.header && (
        <InputLabel className={classes.label}>{header}</InputLabel>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: "0px",
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={input.value}
              label={label}
              onChange={(e, v) => {
                input.onChange(e, v);
                externalOnChange(e, v);
              }}
            />
          }
          label={label}
        />
        {tooltip && <Tooltip text={tooltip} />}
      </div>
    </div>
  );
};

export default props => <Field {...props} component={Component} />;
