import React from "react";
import {Form} from "react-redux-form";

import formPath from "../form-path";
import handleBack from "../handle-back";
import handleLabel from "../handle-label";
import handleNext from "../handle-next";
import InputType from "./input-type";
import validate from "./validate";

const content = (
  <Form model={formPath} onChange={validate}>
    <InputType />
  </Form>
);

export default {
  content,
  handleBack,
  handleLabel,
  handleNext: handleNext(validate),
  label: "Unit Type",
};
