import remove from "lodash/remove";

import {getWellIds} from "utils/wells/get-well-ids";

import filterAmbyintOnly from "./filter-ambyint-only";
import filterDownholeLocation from "./filter-downhole-location";
import filterFieldName from "./filter-field-name";
import filterTags from "./filter-tags";
import parseFilters from "./parse-filters";
import removeFilters from "./remove-filters";

const filterFuncs = [
  {name: "downholeLocation:", execute: filterDownholeLocation},
  {name: "fieldName:", execute: filterFieldName},
];

export const search = (allWellIds = [], byWellId = {}, criteria = null) => {
  let filtered = filterAmbyintOnly(allWellIds, byWellId);

  if (criteria) {
    criteria = criteria.toLowerCase();

    const filters = parseFilters(criteria);

    const tags = removeFilters(criteria, filters);

    filterFuncs.forEach(filterFunc => {
      const filter = remove(filters, filter =>
        filter.startsWith(filterFunc.name.toLowerCase()),
      )[0];

      if (filter) {
        filtered = filterFunc.execute(filtered, filter);
      }
    });

    if (tags.length > 0) {
      filtered = filterTags(filtered, tags);
    }
  }

  const results = getWellIds(filtered);

  return results;
};
