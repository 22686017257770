import React from "react";
import Button from "@material-ui/core/Button";

import {viewFeedComparison} from "actions/wells";
import DriveDataChart from "routes/well/tabs/analysis/comparisons/comparison/drive-data-chart";
import store from "store";
import getMotorDataType from "utils/get-motor-data-type";
import FeedItem from "../feed-item";

const actionsContainerStyle = {
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
};

const cardContainerStyle = {width: "100%"};

export default props => {
  const {event, well} = props;

  const onCompare = () => {
    store.dispatch(
      viewFeedComparison({
        well,
        correlationId: event.correlationId,
      }),
    );
  };

  const actions = event.correlationId
    ? {
        actions: (
          <div style={actionsContainerStyle}>
            <Button color="primary" onClick={onCompare}>
              COMPARE
            </Button>
          </div>
        ),
      }
    : {};

  const chart = (
    <div style={cardContainerStyle}>
      <DriveDataChart
        data={{driveData: event}}
        devices={well.devices}
        width={320}
        height={175}
        margin={{
          top: 35,
          right: 50,
          bottom: 25,
          left: 35,
        }}
      />
    </div>
  );

  return (
    <FeedItem {...actions} chart={chart} event={event}>
      <strong>{`${getMotorDataType(well.devices)} Data`}</strong> received.
    </FeedItem>
  );
};
