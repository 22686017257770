import forEach from "lodash/forEach";

import {mappingTypes} from "constants/well-event-import-mapping-types";

import {hasColumn} from "./has-column";
import {getOption} from "../options";

export const requiredColumns = mappings => {
  const required = [mappingTypes.CREATED_ON, mappingTypes.DOWNHOLE_LOCATION];
  const errors = [];

  forEach(required, type => {
    if (!hasColumn(mappings)(type)) {
      errors.push(`Expected row to have column: ${getOption(type).display}`);
    }
  });

  return errors;
};
