import colors from "theme/colors";

function mapData(d) {
  return d.driveMode || [];
}

function mapTime(d) {
  return new Date(d.createdOn);
}

function mapValue(d) {
  return d.value;
}

export default ({clipPathId, selection, register, scales}) => {
  const gDriveFault = selection.append("g");

  return register({
    draw(props) {
      const visibility =
        props.config.driveMode && props.config.driveMode.state === 0
          ? "hidden"
          : "visible";
      const circles = gDriveFault.selectAll("circle").data(mapData(props.data));

      circles.exit().remove();
      circles.merge(circles).style("visibility", visibility);

      circles
        .enter()
        .append("circle")
        .style("visibility", visibility)
        .style("stroke", d =>
          mapValue(d) === "active" ? colors.brown[400] : colors.blueGrey[400],
        )
        .style("fill", colors.transparent)
        .style("stroke-width", 3)
        .attr("clip-path", `url(#${clipPathId})`)
        .attr("r", 3)
        .attr("cx", d => scales.time(mapTime(d)))
        .attr("cy", scales.status(1));
    },

    zoom({transform}) {
      const scale = transform.rescaleX(scales.time);

      gDriveFault.selectAll("circle").attr("cx", d => scale(mapTime(d)));
    },
  });
};
