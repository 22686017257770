import parseEvents from "@ambyint/common/parsers/well/events";

import {setEvents, types} from "actions/wells";
import fetchCards from "epics/fetch-cards";
import getUser from "utils/get-user";

export default action$ => {
  return action$.ofType(types.fetchCards).mergeMap(async ({payload}) => {
    const {unitsOfMeasure} = getUser();
    const cards = await fetchCards(payload);
    const parsedCards = parseEvents(unitsOfMeasure)(cards);

    return setEvents({
      downholeLocation: payload.downholeLocation,
      data: parsedCards,
    });
  });
};
