import React from "react";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

import {getRelativeTime} from "utils/time/get-relative-time";

const useStyles = makeStyles(({colors}) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    color: colors.warmGrey[100],
    textShadow: "0 1px 3px rgb(0,0,0,0.1)",
  },
  time: {
    lineHeight: 1.2,
    color: "white",
    textShadow: "0 1px 2px rgb(0,0,0,0.2)",
  },
}));

const WellCardDate = ({label, date}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography className={classes.label} variant="caption">
        {label}
      </Typography>
      <Typography className={classes.time} variant="subtitle2">
        {getRelativeTime(date)}
      </Typography>
    </div>
  );
};

export default WellCardDate;
