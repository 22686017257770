import {connect} from "react-redux";
import React, {useEffect} from "react";

import {fetchHistory} from "actions/wells/well-surveillance";
import BoxLoading from "components/box-loading";

import WellSurveillance from "./well-surveillance";

const mapStateToProps = ({auth, wellSurveillance}) => ({
  unitsOfMeasure: auth.user.unitsOfMeasure,
  wellSurveillance,
});

const mapDispatchToProps = dispatch => ({
  fetchHistory: (wellId, downholeLocation) =>
    dispatch(fetchHistory(wellId, downholeLocation)),
});

const SurveillanceTab = ({
  well,
  wellSurveillance,
  fetchHistory,
  unitsOfMeasure,
}) => {
  const {downholeLocation, wellId} = well;

  useEffect(
    () => {
      if (wellSurveillance.activeWellId !== wellId) {
        fetchHistory(wellId, downholeLocation);
      }
    },
    [downholeLocation, wellId, wellSurveillance, fetchHistory],
  );

  if (wellSurveillance.isFetching || wellSurveillance.activeWellId !== wellId) {
    return <BoxLoading />;
  }

  const {oil, gas, water} = wellSurveillance.data;

  return (
    <WellSurveillance
      oil={oil}
      gas={gas}
      water={water}
      unitsOfMeasure={unitsOfMeasure}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SurveillanceTab);
