export const DEVIATION_METRICS = {
  TOTAL_LIQUID: {
    unit: "dailyProduction",
    type: "liquidProduction",
    displayValue: "Total Liquid",
  },
  RUNTIME: {
    unit: "hrs",
    type: "runtime",
    displayValue: "Runtime",
  },
  CALCULATED_FAP: {
    unit: "height",
    type: "calculatedFap",
    displayValue: "Calculated FAP",
  },
  PEAK_LOAD: {
    unit: "load",
    type: "peakLoad",
    displayValue: "Peak Load",
  },
};

export const DEVIATION_SORT = {
  NAME: "by name",
  FIELD: "by field name",
  MAGNITUDE: "by magnitude change",
  PERCENT: "by percentage change",
};

export const DEVIATION_OUTLIER_THRESHOLD = 10;
export const DEVIATION_PERCENT_THRESHOLD = 2;
