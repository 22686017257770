const setDeviationSortBy = ({state, action}) => {
  const {sort} = action.payload;

  return {
    ...state,
    sort,
  };
};

export default setDeviationSortBy;
