import {Field} from "redux-form";
import Grid from "@material-ui/core/Grid";
import React from "react";

import FilterableSelectField from "components/forms/filterable-select";

import getCrankLabel from "./get-crank-label";
import RotationDirectionField from "./rotation-direction-field";
import CrankPinPositionField from "./crank-pin-position-field";

const GeometryDetailsEditView = ({pumpingUnit, onSelect, cranks}) => {
  return (
    <>
      <Grid item xs={6}>
        <div>
          <Field
            name="rotationDirection"
            label="Rotation Direction*"
            component={RotationDirectionField}
          />
        </div>
      </Grid>
      <Grid item xs={6}>
        <div style={{marginBottom: 8}}>
          <Field
            name="crankPinPosition"
            label="Crank Pin Position*"
            required
            component={CrankPinPositionField(pumpingUnit)}
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <FilterableSelectField
          fullWidth
          id={"set-crank-filterable-select"}
          name={"crank"}
          items={cranks.values}
          label={"Crank"}
          mapLabel={c => getCrankLabel(c)}
          mapValue={w => w.crankId}
          onSelect={onSelect}
        />
      </Grid>
    </>
  );
};

export default GeometryDetailsEditView;
