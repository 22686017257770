import React, {Fragment} from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import Tooltip from "components/material-tooltip";

import Pill from "./pill";

const styles = () => ({
  tooltip: {
    marginLeft: ".25rem",
  },
  list: {
    listStyle: "none",
    paddingLeft: 0,
    marginTop: 0,
    marginBottom: 0,
  },
});

const List = ({className, list}) => (
  <ul className={className}>
    {list.map((item, i) => (
      <li key={i}>{`${item.description}`}</li>
    ))}
  </ul>
);

const Codes = ({classes, statuses}) => {
  const [firstCode, ...otherCodes] = statuses;

  if (statuses.length === 0) {
    return <Fragment>--</Fragment>;
  }

  return (
    <Fragment>
      {`${firstCode.description}`}
      {otherCodes.length > 0 && (
        <Tooltip title={<List className={classes.list} list={otherCodes} />}>
          <span className={classes.tooltip}>
            <Pill content={`+${otherCodes.length}`} />
          </span>
        </Tooltip>
      )}
    </Fragment>
  );
};

export default withStyles(styles)(Codes);
