import {actions} from "react-redux-form";
import store from "store";
import getForm from "utils/react-redux-form/get-form";

import formPath from "./form-path";

export default validate => async next => {
  let form = getForm(formPath);

  if (!form.pending) {
    store.dispatch(actions.setPending(formPath, true));

    if (validate) await validate();

    form = getForm(formPath);

    if (form.valid) {
      store.dispatch(actions.setInitial(formPath));

      next();
    } else {
      store.dispatch(actions.setSubmitFailed(formPath, true));
    }
  }
};
