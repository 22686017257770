import React from "react";
import {connect} from "react-redux";

import CloseIcon from "@material-ui/icons/Close";
import colors from "theme/colors";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

import {hide} from "actions/alerts";

import AlertActionButtons from "components/alert-action-buttons";
import DisplayAlertTimelineEvent from "components/display-alert-timeline-event";
import PaperContainer from "components/paper-container";

import findAlert from "utils/alerts/find-alert";

const styles = {
  a: {
    color: colors.ambyBlue[500],
  },
  h3: {
    margin: 0,
  },
  padding: {
    padding: 16,
  },
};

const mapStateToProps = state => {
  return {
    alert: findAlert(state.alerts.all, state.alerts.selected),
  };
};

const DisplaySelectedAlert = props => {
  const {alert = null, dispatch} = props;
  if (!alert) return null;

  return (
    <PaperContainer
      style={{
        margin: 16,
      }}
    >
      <Grid container alignItems={"center"} style={styles.padding}>
        <Grid item xs={3}>
          <h3 style={styles.h3}>
            <a
              style={styles.a}
              href={`#/well/${encodeURIComponent(alert.downholeLocation)}`}
            >
              {alert.displayName}
            </a>
          </h3>
        </Grid>
        <Grid item xs>
          {alert.typeLabel}
        </Grid>
        <Grid item xs={3}>
          <Grid container justify={"flex-end"} alignItems={"center"}>
            <AlertActionButtons alert={alert} />
            <IconButton onClick={() => dispatch(hide())} color={"primary"}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Grid container style={styles.padding}>
        <Grid item xs={3} />
        <Grid item xs={6} style={{paddingTop: 16}}>
          {alert.timeline.map(event => (
            <DisplayAlertTimelineEvent
              key={event._id}
              event={event}
              alert={alert}
            />
          ))}
        </Grid>
        <Grid item xs />
      </Grid>
    </PaperContainer>
  );
};

export default connect(mapStateToProps)(DisplaySelectedAlert);
