import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import {hideResetFault} from "actions/dialogs";
import {resetFault} from "actions/wells";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "components/dialog";
import React from "react";

const mapStateToProps = state => {
  return state.dialogs.resetFault;
};

const reset = (dispatch, well) => {
  return () => {
    dispatch(resetFault(well));
    close(dispatch)();
  };
};

const close = dispatch => {
  return () => {
    dispatch(hideResetFault());
  };
};

const Component = ({dispatch, isOpen, well}) => {
  if (!well) {
    return null;
  }

  return (
    <Dialog open={isOpen} onClose={close(dispatch)}>
      <DialogTitle>{`Reset drive fault for ${well.displayName}`}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You are about to attempt to reset all current faults for this well.
          This may take a few moments to apply.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close(dispatch)} color="primary">
          Cancel
        </Button>
        <Button onClick={reset(dispatch, well)} color="primary">
          Reset Drive Fault
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps)(Component);
