import withStyles from "@material-ui/core/styles/withStyles";

const styles = ({colors}) => ({
  selectedHoverBG: {
    backgroundColor: colors.yellowVivid[800],
  },
  selectedIdleBG: {
    backgroundColor: colors.yellowVivid[900],
  },
  unselectedHoverBG: {
    backgroundColor: colors.yellowVivid[600],
  },
  unselectedIdleBG: {
    backgroundColor: colors.yellowVivid[700],
  },

  selectedHoverTitle: {
    color: "white",
  },
  selectedIdleTitle: {
    color: "white",
  },
  unselectedHoverTitle: {
    color: "white",
  },
  unselectedIdleTitle: {
    color: "white",
  },

  selectedHoverCaption: {
    color: colors.warmGrey[100],
  },
  selectedIdleCaption: {
    color: colors.warmGrey[100],
  },
  unselectedHoverCaption: {
    color: colors.warmGrey[100],
  },
  unselectedIdleCaption: {
    color: colors.warmGrey[100],
  },
});

export default withStyles(styles);
