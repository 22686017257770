import {show} from "actions/snackbar";
import {types} from "actions/wells";
import fetch from "epics/async-fetch";

export default action$ => {
  return action$
    .ofType(types.saveAutoAcceptRecommendations)
    .switchMap(async ({payload}) => {
      const {autoAcceptRecommendationTypes, downholeLocation} = payload;

      await fetch(
        `/wells/${encodeURIComponent(
          downholeLocation,
        )}/auto-accept-recommendations`,
        {},
        {
          body: JSON.stringify({
            autoAcceptRecommendationTypes,
          }),
          method: "PUT",
        },
      );

      return show({
        message:
          "You have successfully updated the auto accepted recommendations.",
      });
    });
};
