import React from "react";
import get from "lodash/get";
import math from "mathjs";
import startCase from "lodash/startCase";
import {connect} from "react-redux";
import {reduxForm, formValueSelector} from "redux-form";
import DeleteIcon from "@material-ui/icons/Delete";
import Grid from "@material-ui/core/Grid";
import {withStyles} from "@material-ui/core/styles";

import colors from "theme/colors";
import formatWithUnits from "@ambyint/common/utils/format/format-with-units";

import ConfirmationDialog from "components/confirmation-dialog";
import DecimalTextField from "components/forms/decimal-text-field";
import FormButton from "components/form-button";
import FormError from "components/form-error";
import FormHeader from "components/form-header";
import SearchInputWithSuggestions from "components/forms/search-input-with-suggestions";
import spreadIf from "utils/spread-if";
import valueOf from "utils/value-of";

import {
  fetch as fetchCouplings,
  filter as filterCouplings,
} from "actions/couplings";
import {fetch as fetchGuides, filter as filterGuides} from "actions/guides";
import {
  fetch as fetchGuideMaterials,
  filter as filterGuideMaterials,
} from "actions/guide-materials";
import {fetch as fetchRods, filter as filterRods} from "actions/rods";
import {
  fetch as fetchRodDimensions,
  filter as filterRodDimensions,
} from "actions/rod-dimensions";
import {roundWithUnits} from "utils/round-with-units";

import formatCouplingTypeLabel from "./format-coupling-type-label";
import formatGuideTypeKey from "./format-guide-type-key";
import formatRodSectionSummary from "./format-rod-section-summary";
import validate from "./validate-build-rod-section.js";

const styleSheet = {
  actionsContainer: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    margin: "auto",
    display: "inline-table",
  },
  bold: {
    fontWeight: "bold",
  },
  detailsContainer: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 120,
    margin: "auto",
    display: "inline-table",
  },
  section: {
    position: "relative",
    border: `thin solid ${colors.grey[500]}`,
    padding: "32px",
    width: "100%",
    margin: "12px",
  },
  saveButton: {color: "white"},
};

class BuildRodSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {showConfirmationDialog: false};
  }

  cancel = () => {
    const {actionType, deleteRodSection} = this.props;
    actionType === "create" && deleteRodSection();
    this.props.onClose();
  };

  save = (values, func, props) => {
    if (Object.keys(props.syncErrors).length > 0) {
      return;
    }

    const noGuidesAvailable =
      !values.guideQuantity !== 0 &&
      this.props.guides.all &&
      this.props.guides.all.length === 0;

    const guideType = noGuidesAvailable ? null : values.guideType;
    const guideMaterial = !guideType ? null : values.guideMaterial;
    const guideQuantity = !guideType ? 0 : values.guideQuantity;

    const section = {
      ...this.props.section,
      couplingType: values.couplingType,
      guideMaterial,
      guideQuantity,
      guideType,
      rodDimensions: values.rodDimensions,
      rodLength: values.rodLength,
      rodQuantity: values.rodQuantity,
      rodType: values.rodType,
    };

    section.summary = formatRodSectionSummary(section);

    this.props.saveRodSection(section);

    this.props.onClose();
  };

  componentWillMount() {
    this.props.dispatch(fetchRods());
    this.props.dispatch(fetchGuideMaterials());

    if (this.props.section.rodType) {
      this.props.dispatch(
        fetchRodDimensions({rodType: this.props.section.rodType.type}),
      );
    }

    if (
      this.props.section.rodDimensions &&
      this.props.section.rodDimensions.pin
    ) {
      this.props.dispatch(
        fetchCouplings({
          pin: this.props.section.rodDimensions.pin,
        }),
      );

      if (this.props.tubing && this.props.tubing.outsideDiameter) {
        this.props.dispatch(
          fetchGuides({
            rodDiameter: this.props.section.rodDimensions.diameter,
            tubingDiameter: this.props.tubing.outsideDiameter,
          }),
        );
      }
    }
  }

  render() {
    const {actionType, classes, deleteRodSection, handleSubmit} = this.props;

    const isTubingSmallerThanCouplingMinimum =
      this.props.tubing &&
      this.props.tubing.outsideDiameter &&
      this.props.currentValues.couplingType &&
      math.round(
        valueOf(this.props.tubing && this.props.tubing.outsideDiameter),
        1,
      ) <
        math.round(
          valueOf(
            this.props.currentValues.couplingType &&
              this.props.currentValues.couplingType.minimumTubingDiameter,
          ),
          1,
        );

    return (
      <section className={classes.section}>
        <ConfirmationDialog
          title={"Confirm Delete"}
          cancelButtonText={"Cancel"}
          confirmationButtonText={"Delete Rod Section"}
          isOpen={this.state.showConfirmationDialog}
          onCancel={() => {
            this.setState({showConfirmationDialog: false});
          }}
          onConfirm={() => {
            deleteRodSection();
            this.setState({showConfirmationDialog: false});
          }}
          body={"You are about to delete a rod section. This cannot be undone."}
        />
        <div>
          <Grid container spacing={2}>
            {actionType !== "create" && (
              <FormButton
                fullWidth
                style={{position: "absolute", right: 0}}
                onClick={() =>
                  this.setState({
                    showConfirmationDialog: true,
                  })
                }
              >
                <DeleteIcon />
                Delete Section
              </FormButton>
            )}

            <Grid item xs={12} style={{marginTop: "0px", marginBottom: "8px"}}>
              <FormHeader>Build Rod Section</FormHeader>
            </Grid>

            <Grid item xs={6} style={{padding: "8px"}}>
              <SearchInputWithSuggestions
                name="rodTypeLabel"
                label="Type"
                placeholder="Choose rod type"
                onSearch={event => {
                  event.value !== undefined &&
                    this.props.dispatch(filterRods({phrase: event.value}));
                }}
                throttle={1500}
                suggestions={
                  (this.props.rods && this.props.rods.filtered) || []
                }
                getSuggestionText={rodType => rodType.label}
                getSuggestionValue={rodType => ({
                  text: rodType.label,
                  rodType,
                })}
                onSuggestionClick={data => {
                  this.props.change("rodType", data.rodType);
                  this.props.dispatch(
                    fetchRodDimensions({rodType: data.rodType.type}),
                  );
                }}
                showDropdownArrow
                required
              />
            </Grid>
            <Grid item xs={6}>
              <SearchInputWithSuggestions
                name="diameterLabel"
                label="Diameter"
                placeholder="Choose diameter"
                onClick={(event, value) => {
                  this.props.dispatch(filterRodDimensions({phrase: value}));
                }}
                style={{padding: "8px"}}
                onSearch={event => {
                  event.value !== undefined &&
                    this.props.dispatch(
                      filterRodDimensions({phrase: event.value}),
                    );
                }}
                throttle={500}
                suggestions={
                  (this.props.rodDimensions &&
                    this.props.rodDimensions.filtered) ||
                  []
                }
                getSuggestionText={rodDimensions =>
                  formatWithUnits(rodDimensions.diameter, 3)
                }
                getSuggestionValue={rodDimensions => ({
                  text: formatWithUnits(rodDimensions.diameter, 3),
                  rodDimensions,
                })}
                onSuggestionClick={data => {
                  this.props.change(
                    "diameter",
                    data.rodDimensions && data.rodDimensions.diameter,
                  );
                  this.props.change("rodDimensions", data.rodDimensions);

                  // Clear parts that are dependant on dimensions
                  this.props.change("couplingTypeLabel", null);
                  this.props.change("couplingType", null);
                  this.props.change("guideTypeKey", null);
                  this.props.change("guideType", null);

                  this.props.dispatch(
                    fetchCouplings({
                      pin: data.rodDimensions && data.rodDimensions.pin,
                    }),
                  );
                  this.props.dispatch(
                    fetchGuides({
                      rodDiameter:
                        data.rodDimensions && data.rodDimensions.diameter,
                      tubingDiameter:
                        this.props.tubing && this.props.tubing.outsideDiameter,
                    }),
                  );
                }}
                showDropdownArrow
                disabled={
                  !this.props.rodDimensions.all ||
                  this.props.rodDimensions.all.length === 0
                }
                required
              />
            </Grid>

            <Grid item xs={6}>
              <SearchInputWithSuggestions
                name="couplingTypeLabel"
                label="Coupling"
                placeholder="Choose coupling type"
                onSearch={event => {
                  event.value !== undefined &&
                    this.props.dispatch(filterCouplings({phrase: event.value}));
                }}
                throttle={500}
                suggestions={
                  (this.props.couplings && this.props.couplings.filtered) || []
                }
                getSuggestionText={formatCouplingTypeLabel}
                getSuggestionValue={couplingType => ({
                  text: formatCouplingTypeLabel(couplingType),
                  couplingType,
                })}
                onSuggestionClick={data => {
                  this.props.change("couplingType", data.couplingType);
                }}
                showDropdownArrow
                disabled={
                  !this.props.couplings.all ||
                  this.props.couplings.all.length === 0
                }
                required
              />
            </Grid>
            <Grid item xs={3}>
              <DecimalTextField
                fullWidth
                name={"rodLength"}
                label={"Length"}
                required
                min={0}
                step={0.5}
              />
            </Grid>
            <Grid item xs={3}>
              <DecimalTextField
                fullWidth
                name={"rodQuantity"}
                label={"Quantity"}
                required
                min={0}
              />
            </Grid>

            <Grid item xs={12} style={{marginTop: "0px", marginBottom: "0px"}}>
              <FormHeader>Build Guide</FormHeader>
            </Grid>

            <Grid item xs={6}>
              <SearchInputWithSuggestions
                name="guideTypeKey"
                label="Guide Type"
                placeholder="Choose guide type"
                onSearch={event => {
                  event.value !== undefined &&
                    this.props.dispatch(filterGuides({phrase: event.value}));
                }}
                throttle={1500}
                suggestions={
                  (this.props.guides && this.props.guides.filtered) || []
                }
                getSuggestionText={formatGuideTypeKey}
                getSuggestionValue={guideType => ({
                  text: formatGuideTypeKey(guideType),
                  guideType,
                })}
                onSuggestionClick={data => {
                  this.props.change("guideType", data.guideType);
                }}
                showDropdownArrow
                disabled={
                  !this.props.guides.all || this.props.guides.all.length === 0
                }
              />
            </Grid>
            <Grid item xs={6}>
              <SearchInputWithSuggestions
                name="guideMaterialType"
                label="Guide Material"
                placeholder="Choose guide material"
                onSearch={event => {
                  event.value !== undefined &&
                    this.props.dispatch(
                      filterGuideMaterials({phrase: event.value}),
                    );
                }}
                throttle={1500}
                suggestions={
                  (this.props.guideMaterials &&
                    this.props.guideMaterials.filtered) ||
                  []
                }
                getSuggestionText={guideMaterial => guideMaterial.type}
                getSuggestionValue={guideMaterial => ({
                  text: guideMaterial.type,
                  guideMaterial,
                })}
                onSuggestionClick={data => {
                  this.props.change("guideMaterial", data.guideMaterial);
                }}
                showDropdownArrow
                disabled={
                  !this.props.guides.all || this.props.guides.all.length === 0
                }
              />
            </Grid>
            <Grid item xs={6}>
              <DecimalTextField
                fullWidth
                name={"guideQuantity"}
                label={"Quantity Per Rod"}
                required
                disabled={
                  !this.props.guides.all || this.props.guides.all.length === 0
                }
              />
            </Grid>

            <Grid item xs={12}>
              <FormError>
                {isTubingSmallerThanCouplingMinimum &&
                  `This rod setup doesn't appear to be an ideal fit for the selected tubing size. Minimum tubing diameter for this coupling: ${formatWithUnits(
                    this.props.currentValues.couplingType.minimumTubingDiameter,
                    3,
                  )}. Current tubing diameter: ${formatWithUnits(
                    this.props.tubing.outsideDiameter,
                    3,
                  )}.`}
              </FormError>
            </Grid>

            <Grid item xs={12}>
              <Grid container direction={"row"}>
                <Grid item>
                  <FormButton onClick={this.cancel}>cancel</FormButton>
                  <FormButton
                    variant="contained"
                    className={classes.saveButton}
                    onClick={handleSubmit(this.save)}
                  >
                    {startCase(actionType)} Section
                  </FormButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </section>
    );
  }
}

const forms = {};

export default key => {
  if (forms.hasOwnProperty(key)) {
    return forms[key];
  }

  const selector = formValueSelector(`build-rod-section-${key}`);
  const mapStateToProps = (state, props) => {
    const couplingType = get(props.section, "couplingType");
    const couplingTypeLabel = formatCouplingTypeLabel(
      get(props.section, "couplingType"),
    );
    const diameter = get(props.section, "rodDimensions.diameter");
    const guideMaterial = get(props.section, "guideMaterial");
    const guideMaterialType = get(props.section, "guideMaterial.type");
    const guideQuantity = get(props.section, "guideQuantity");
    const guideType = get(props.section, "guideType");
    const guideTypeKey = formatGuideTypeKey(get(props.section, "guideType"));
    const rodLength = roundWithUnits(get(props.section, "rodLength"), 3);
    const rodDimensions = get(props.section, "rodDimensions");
    const rodQuantity = get(props.section, "rodQuantity");
    const rodType = get(props.section, "rodType");
    const rodTypeLabel = get(props.section, "rodType.label");
    const tubingDiameter = get(props.tubing, "outsideDiameter");

    const diameterLabel = valueOf(diameter) ? formatWithUnits(diameter, 3) : "";

    return {
      couplings: state.couplings,
      guides: state.guides,
      guideMaterials: state.guideMaterials,
      rodDimensions: state.rodDimensions,
      rods: state.rods,
      ...spreadIf(props.section, {
        initialValues: {
          couplingType,
          couplingTypeLabel,
          diameter,
          diameterLabel,
          guideMaterial,
          guideMaterialType,
          guideQuantity,
          guideType,
          guideTypeKey,
          rodDimensions,
          rodLength,
          rodQuantity,
          rodType,
          rodTypeLabel,
          tubingDiameter,
        },
      }),
      currentValues: {
        couplingType: selector(state, "couplingType"),
      },
    };
  };

  const Component = reduxForm({
    form: `build-rod-section-${key}`,
    enableReinitialize: true,
    validate,
  })(BuildRodSection);

  const wrappedComponent = withStyles(styleSheet, {name: "DialogOverrides"})(
    connect(mapStateToProps)(Component),
  );

  forms[key] = wrappedComponent;

  return wrappedComponent;
};
