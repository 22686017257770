import {show} from "actions/snackbar";
import {types} from "actions/wells";
import fetch from "epics/fetch";

export default action$ => {
  return action$.ofType(types.stop).switchMap(({payload}) => {
    return fetch(
      `/optimization/well/${encodeURIComponent(
        payload.downholeLocation,
      )}/operation`,
      {},
      {
        method: "PUT",
        body: {
          downholeLocation: payload.downholeLocation,
          allowedToOperate: false,
        },
      },
    ).map(() => {
      return show({
        message: `Your request to stop well ${
          payload.displayName
        } submitted successfully!`,
      });
    });
  });
};
