import {useEffect, useCallback, useMemo} from "react";
import get from "lodash/get";
import compact from "lodash/compact";

import {getAnomalyCardSummary} from "utils/well/get-anomaly-card-summary";
import {getLatestMiniCard} from "utils/well/get-latest-mini-card";
import {getMiniCardById} from "utils/well/get-mini-card-by-id";
import {useHover} from "hooks/use-hover";

export const useWellCardDetails = ({
  well,
  isAnomaly,
  fetchMiniCardById,
  fetchLatestMiniCard,
  handleCardClick,
  handleHeaderClick,
  isSelectionMode,
}) => {
  const {lastRegularCard, downholeLocation} = well;
  const {isHovering, enableHover, disableHover} = useHover();
  const {type: anomalyType, latestConfidence, latest} =
    getAnomalyCardSummary(get(well, "dailyAnomalySummaries", [])) || {};
  const card = useMemo(
    () => (isAnomaly ? getMiniCardById(well, latest) : getLatestMiniCard(well)),
    [isAnomaly, well, latest],
  );
  const currentCardId = get(card, "correlationId");

  useEffect(
    () => {
      if (!currentCardId) {
        if (isAnomaly) {
          fetchMiniCardById(downholeLocation, latest);
        } else {
          fetchLatestMiniCard(downholeLocation);
        }
      }
    },
    [
      downholeLocation,
      fetchMiniCardById,
      fetchLatestMiniCard,
      currentCardId,
      isAnomaly,
      latest,
    ],
  );

  const cardIds = compact([currentCardId, lastRegularCard]);

  const onCardClick = useCallback(
    handleCardClick({
      well,
      isSelectionMode,
      cardIds,
    }),
    [well, isSelectionMode, cardIds],
  );

  const onHeaderClick = useCallback(handleHeaderClick(well), [well]);
  const wellStatus = get(well, "status.type.name", "No Status Available");

  return {
    type: anomalyType || wellStatus.toLowerCase(),
    wellStatus,
    onHeaderClick,
    onCardClick,
    latestConfidence,
    isHovering,
    enableHover,
    disableHover,
    currentCardId,
    card,
    cardIds,
  };
};
