import Grid from "@material-ui/core/Grid";
import React from "react";
import Typography from "@material-ui/core/Typography";
import {connect} from "react-redux";
import {goBack} from "react-router-redux";

import {withQuery} from "components/with-query";
import {navigateToLogin} from "actions/auth";

import getTableFlip from "./get-table-flip";
import Generic from "./generic";
import UserNotFound from "./user-not-found";

const position = {
  marginTop: "100px",
};

const margin = {
  margin: "16px",
};

const textAlignCenter = {textAlign: "center"};

const Error = props => {
  const {query} = props;

  const login = () => props.dispatch(navigateToLogin());

  const errors = {
    "401": (
      <Generic title="An authentication error has occurred." onClick={login} />
    ),
    "403": (
      <Generic title="An authorization error has occurred." onClick={login} />
    ),
    "404": (
      <Generic
        title="We could not find what you were looking for."
        onClick={() => props.dispatch(goBack())}
      />
    ),
    "user-not-found": <UserNotFound />,
    "user-no-permissions": (
      <Generic
        title="Unfortunately you have no permissions in ambyint, so we're going to stop you there."
        onClick={login}
      />
    ),
  };

  const defaultError = (
    <Generic
      title="An unhandled error has occurred."
      message="If you reach out to our customer success team, we'll get you sorted out in no time."
      onClick={login}
    />
  );

  const message = errors[query.code] || defaultError;

  return (
    <div>
      <div style={position}>
        <Grid container spacing={3} justify="center" style={margin}>
          <Grid item xs={12}>
            <Typography variant="h3" style={textAlignCenter}>
              {getTableFlip()}
            </Typography>
          </Grid>
        </Grid>
        {message}
      </div>
    </div>
  );
};

export default connect()(withQuery(Error));
