import {scaleLinear} from "d3-scale";
import {pressure} from "unit-converter";
import getUnits from "utils/get-units";

const convert = (value, to) => {
  return pressure.convert({from: "kPa", to, value});
};

export default () => {
  const units = getUnits();

  return scaleLinear().domain([
    convert(0, units.pressure),
    convert(4000, units.pressure),
  ]);
};
