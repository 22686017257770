import moment from "moment/moment";

import presets from "constants/analysis-presets";

export const getDefaults = () => {
  return {
    range: {
      start: moment()
        .startOf("day")
        .subtract(7, "days")
        .toDate(),
      end: moment()
        .endOf("day")
        .toDate(),
    },
    ...presets.default(),
  };
};
