import {replace} from "react-router-redux";
import reduce from "lodash/reduce";
import size from "lodash/size";

import {createMergeEpic} from "epics/create-epic";
import fetch from "epics/async-fetch";
import {setRankings, types} from "actions/wells/well-health";

export const fetchRankings = createMergeEpic(
  [types.fetchRankings],
  async ({payload}) => {
    const {downholeLocations} = payload;

    try {
      const {rankings} = await fetch(
        "/optimization/well/failure-analysis/wellbore/rankings",
        {},
        {
          method: "post",
          body: {
            downholeLocations,
          },
        },
      );

      // Special Case:
      // If there's only one well, it's rank is neutral within a range of 1st and 1st.
      // The server will correctly return that at best it's 1st, at worst it's 1st and on average it's 1st.
      // The processing below really needs 2 wells to work properly.
      // In a case with one well, the confidence is the entire range with the mean percent in the middle.
      if (size(rankings) === 1) {
        return [
          setRankings({
            [Object.keys(rankings)[0]]: {
              minPercent: 1,
              maxPercent: 0,
              meanPercent: 0.5,
            },
          }),
        ];
      }

      const zeroBasedTotal = size(rankings) - 1;

      // For every well requested, calculate the percentage values needed to draw the graph.
      // Note that every well requested needs to be represented in the final data but the number of wells returned is
      // used to calculate the percentages. This is because some wells that are requested may not be able to be ranked
      // and will be excluded from the response. Each well is ranked within the group of well ratings that could be
      // calculated by the model so the percentages are based on the response set.
      // We need every well requested to be in the model to determine if we need to re-fetch rankings when changing wells,
      // and we also need to know that the rankings for any well not able to be rated are null so that the "no data"
      // state is populated correctly.
      const processedRankings = reduce(
        downholeLocations,
        (accumulator, downholeLocation) => {
          try {
            // To calculate the percentages of each well properly, we need to make the rank values zero-based (0 instead of 1 to N-1 instead of N)
            const min = rankings[downholeLocation].low - 1;
            const max = rankings[downholeLocation].high - 1;
            const mean = rankings[downholeLocation].mean - 1;

            // Subtract 1 from all values to make them zero-based which makes calculating the percentage easier.
            // IMPORTANT: In the data, a rank of 1 is the worst well with the highest increasing risk.
            // Rank N is the best well with the most decreasing risk.
            // However, we want to display the increasing risk on the right end of the graph as people usually associate
            // increasing as left to right with left being the lowest.
            // Because of this we have to invert the percentages AND remember that "max" is now on the left side when drawing.
            // This inversion is reason for subtracting all values from 1.
            accumulator[downholeLocation] = {
              minPercent: 1 - min / zeroBasedTotal,
              maxPercent: 1 - max / zeroBasedTotal,
              meanPercent: 1 - mean / zeroBasedTotal,
            };
          } catch (err) {
            accumulator[downholeLocation] = {
              minPercent: null,
              maxPercent: null,
              meanPercent: null,
            };
          }

          return accumulator;
        },
        {},
      );

      return [setRankings(processedRankings)];
    } catch (err) {
      return [replace(`/error?code=${err.status}`)];
    }
  },
);
