import parseEvents from "@ambyint/common/parsers/well/events";

export default unitsOfMeasure => (
  events = [],
  {downholeLocation, correlationId},
) => {
  const parsedEvents = parseEvents(unitsOfMeasure)(events);

  return parsedEvents.reduce(
    (current, event) => {
      current.createdOn = event.createdOn;
      current[event.type] = event;
      return current;
    },
    {
      downholeLocation,
      correlationId,
    },
  );
};
