import {DEVIATION_METRICS, DEVIATION_SORT} from "constants/deviation";
import TIME_PERIODS from "constants/time-periods";

import {reducerFactory} from "reducers/reducer-factory";
import {types} from "actions/deviation";

import setDeviationSortBy from "./set-deviation-sort-by";
import setCalculatedDeviations from "./set-calculated-deviations";

const defaults = {
  fetched: false,
  deviationFilter: DEVIATION_METRICS.TOTAL_LIQUID,
  wells: [],
  sort: DEVIATION_SORT.MAGNITUDE,
  timePeriod: TIME_PERIODS.THIRTY_DAY,
};

const actionMap = {
  [types.setDeviationSortBy]: setDeviationSortBy,
  [types.setCalculatedDeviations]: setCalculatedDeviations,
};

export default reducerFactory(actionMap, defaults);
