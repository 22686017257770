import set from "lodash/set";

import {setOne} from "actions/wells";
import {show} from "actions/snackbar";

import RESPONSE_STATUSES from "constants/response-statuses";

export const onUpdateSuccess = ({well, updatePath, payload}) => [
  setOne({
    wellId: well.wellId,
    data: set({...well}, `lufkinSettings.${updatePath}`, {
      payload,
      status: RESPONSE_STATUSES.PENDING,
    }),
  }),
  show({
    message: "Your changes have been successfully submitted.",
  }),
];

export default onUpdateSuccess;
