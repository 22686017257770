import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import RootRef from "@material-ui/core/RootRef";

import TableHeader from "./table-header";

export const renderWellTable = ({
  className,
  unitsOfMeasure,
  onClickColumn,
  sortAsc,
  sortBy,
}) => (items, ref) => (
  <Table className={className}>
    <TableHeader
      unitsOfMeasure={unitsOfMeasure}
      onClickColumn={onClickColumn}
      sortAsc={sortAsc}
      sortBy={sortBy}
    />
    <RootRef rootRef={ref}>
      <TableBody>{items}</TableBody>
    </RootRef>
  </Table>
);
