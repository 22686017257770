import React from "react";
import {connect} from "react-redux";
import get from "lodash/get";

import Grid from "@material-ui/core/Grid";

import Checkbox from "components/forms/checkbox";
import DecimalPicker from "components/forms/decimal-picker";
import FormHeader from "components/form-header";
import PaperContainer from "components/paper-container";
import SaveCancelButtons from "components/save-cancel-buttons";

import {reduxForm} from "redux-form";

import {saveResistance} from "actions/wells";

import compose from "utils/compose";
import spreadIf from "utils/spread-if";

import validate from "./validate";

const mapStateToProps = (state, props) => {
  const resistance = props.well.resistance;
  const downholeLocation = get(props.well, "downholeLocation");

  const dampingCoefficient = get(resistance, "dampingCoefficient");
  const enableStuffingBoxFrictionAutoCorrection = get(
    resistance,
    "enableStuffingBoxFrictionAutoCorrection",
    false,
  );
  const frictionCoefficient = get(resistance, "frictionCoefficient");

  return {
    ...spreadIf(props.well, {
      initialValues: {
        downholeLocation,

        dampingCoefficient,
        enableStuffingBoxFrictionAutoCorrection,
        frictionCoefficient,
      },
    }),
    currentValues: {},
  };
};

class ResistanceEditView extends React.Component {
  save = (values, func, props) => {
    if (Object.keys(props.syncErrors).length > 0) {
      return;
    }

    this.props.dispatch(
      saveResistance({
        ...values,
        downholeLocation: this.props.well.downholeLocation,
        wellId: this.props.well.wellId,
      }),
    );

    this.props.onClose();
  };

  render() {
    const {handleSubmit, onClose, pristine, submitting, reset} = this.props;

    return (
      <PaperContainer extraPadded style={{maxWidth: "500px"}}>
        <form onSubmit={handleSubmit(this.save)}>
          <Grid container direction={"row"} style={{maxWidth: 776}} spacing={2}>
            <Grid item xs={12}>
              <FormHeader>Resistance Parameters</FormHeader>
            </Grid>

            <Grid item xs={12}>
              <DecimalPicker
                fullWidth
                name={"dampingCoefficient"}
                increment={0.05}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <DecimalPicker
                fullWidth
                name={"frictionCoefficient"}
                increment={0.05}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <Checkbox
                name="enableStuffingBoxFrictionAutoCorrection"
                label="Stuffing Box Friction Auto Correction"
              />
            </Grid>
          </Grid>

          <SaveCancelButtons
            pristine={pristine}
            reset={compose(
              onClose,
              reset,
            )}
            submitting={submitting}
          />
        </form>
      </PaperContainer>
    );
  }
}

const Component = reduxForm({
  form: "resistance-edit-view",
  enableReinitialize: true,
  validate,
})(ResistanceEditView);

export default connect(mapStateToProps)(Component);
