import React from "react";

import getDisplayName from "utils/user/get-display-name";

import {LABEL_MAP} from "constants/grace-periods";
import FeedItem from "../feed-item";

export default props => {
  const {
    event,
    event: {
      value: {type, value},
    },
  } = props;

  return (
    <FeedItem event={event}>
      <strong>{getDisplayName(event)}</strong>
      {" set the "}
      <strong>{LABEL_MAP[type]}</strong>
      {" grace period to "}
      <strong>{value}</strong>
      {"."}
    </FeedItem>
  );
};
