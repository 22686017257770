import moment from "moment";

const DATE_PICKER_FORMAT = "YYYY-MM-DD";

const parseHours = (hours = "") => {
  const time = hours.split(":");
  return time.length === 2 ? time : [0, 0];
};

const dayOfWeekId = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};

const generateOnCallSchedule = ({
  allDay,
  startDate,
  startTime,
  endDate,
  endTime,
  daysOfWeek,
  ...restOfPayload
}) => {
  const initalOccurrence = moment(startDate, DATE_PICKER_FORMAT)
    .startOf("day")
    .toDate();
  const finalOccurrence = moment(endDate, DATE_PICKER_FORMAT)
    .endOf("day")
    .toDate();
  const totalScheduledPeriods =
    moment(finalOccurrence).diff(initalOccurrence, "days") + 1;
  const parsedStartTime = parseHours(startTime);
  const parsedEndTime = parseHours(endTime);

  const scheduledDaysOfWeek = Object.keys(daysOfWeek)
    .filter(key => daysOfWeek[key])
    .map(dayName => dayOfWeekId[dayName]);

  const calloutPeriods = [];

  for (let i = 0; i < totalScheduledPeriods; i++) {
    const occurrence = moment(initalOccurrence).add(i, "days");
    let start;
    let end;

    if (allDay) {
      start = moment(occurrence)
        .startOf("day")
        .toDate();
      end = moment(occurrence)
        .endOf("day")
        .toDate();
    } else {
      start = moment(occurrence)
        .hour(parsedStartTime[0])
        .minute(parsedStartTime[1])
        .toDate();
      end = moment(occurrence)
        .hour(parsedEndTime[0])
        .minute(parsedEndTime[1])
        .toDate();
    }

    if (scheduledDaysOfWeek.indexOf(occurrence.weekday()) >= 0) {
      calloutPeriods.push({
        calloutSchedulePeriodStartDate: start.toISOString(),
        calloutSchedulePeriodEndDate: end.toISOString(),
      });
    }
  }

  return {
    calloutPeriods,
    ...restOfPayload,
  };
};

export default generateOnCallSchedule;
