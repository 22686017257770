import React from "react";
import {connect} from "react-redux";

import {SEARCH_TYPES} from "constants/search-types";
import {
  setWellSearchComponent,
  setAlertSearchComponent,
  clearSearchComponent,
} from "actions/app-bar";

const actionMap = {
  [SEARCH_TYPES.well]: setWellSearchComponent,
  [SEARCH_TYPES.alerts]: setAlertSearchComponent,
  [SEARCH_TYPES.none]: clearSearchComponent,
};

const mapStateToProps = ({appBar: searchType}) => ({
  currentSearchType: searchType,
});

class WithSearchDispatcher extends React.Component {
  componentDidMount = () => {
    const {currentSearchType, searchType, dispatch} = this.props;

    if (currentSearchType !== searchType) {
      dispatch(actionMap[searchType]);
    }
  };

  render = () => this.props.children;
}

WithSearchDispatcher = connect(mapStateToProps)(WithSearchDispatcher);

export const withSearch = searchType => Component => props => (
  <WithSearchDispatcher searchType={searchType}>
    <Component {...props} />
  </WithSearchDispatcher>
);

export const withWellSearch = withSearch(SEARCH_TYPES.well);
export const withAlertSearch = withSearch(SEARCH_TYPES.alerts);
