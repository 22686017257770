import FilterableSelect from "components/filterable-select";
import Field from "components/react-redux-form/field";
import countries from "constants/countries";
import React from "react";
import {connect} from "react-redux";
import {actions} from "react-redux-form";

import getModelPath from "../get-model-path";

const onChange = props => {
  const {dispatch, onChange, value} = props;

  return newValue => {
    newValue = newValue && newValue.value;

    if (newValue !== value) {
      dispatch(
        actions.change(getModelPath("wellProvinceState"), "", {silent: true}),
      );

      onChange(newValue);
    }
  };
};

let Input = props => {
  const {value} = props;

  return (
    <FilterableSelect
      fullWidth
      id="country-select"
      items={countries}
      label="Country"
      mapLabel={d => d.text}
      mapValue={d => d.value}
      onSelect={onChange(props)}
      required
      value={countries.find(country => country.value === value)}
    />
  );
};

Input = connect()(Input);

export default () => {
  return <Field component={Input} modelPath={getModelPath("wellCountry")} />;
};
