import {types} from "./types";

/**
 * Creates a resetSettings action.
 * @param {string} type - The type of import
 * @returns {{type: string, payload: {}}}
 */
export const resetSettings = ({type}) => {
  return {
    type: types.RESET_SETTINGS,
    payload: {
      type,
    },
  };
};
