import Typography from "@material-ui/core/Typography";
import React from "react";
import {connect} from "react-redux";
import {Form} from "react-redux-form";

import formPath from "../form-path";
import handleBack from "../handle-back";
import handleLabel from "../handle-label";
import handleNext from "../handle-next";
import InputAlias from "./input-alias";
import validate from "./validate";

const mapStateToProps = state => {
  return {
    downholeLocation: state.forms.addWell.downholeLocation,
  };
};

let DisplayName = props => {
  const {downholeLocation} = props;

  return (
    <Form
      model={formPath}
      onChange={formModel => validate({bypassExists: true})}
    >
      <Typography variant="body2">
        {"Provide an optional display name (alias) to override the default "}
        <strong>{downholeLocation}</strong>
        {" that will be displayed to identify this well throughout Ambyint."}
      </Typography>
      <InputAlias />
    </Form>
  );
};

DisplayName = connect(mapStateToProps)(DisplayName);

export default {
  content: <DisplayName />,
  handleBack,
  handleLabel,
  handleNext: handleNext(validate),
  label: "Display Name",
};
