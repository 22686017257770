import React from "react";

import colors from "theme/colors";
import formatDateTime from "@ambyint/common/utils/format/format-date-time";

import PaperContainer from "components/paper-container";
import Typography from "@material-ui/core/Typography";

import {
  defaultContainerStyle,
  defaultGridContainer,
  defaultTitleStyle,
} from "./dashboard-styles";

export default props => {
  const {well} = props;
  const leakState = well.status.leakState;

  if (!leakState || !leakState.hasLeak) return null;

  return (
    <PaperContainer style={defaultContainerStyle}>
      <div style={defaultGridContainer}>
        <Typography variant="body1" style={{...defaultTitleStyle}}>
          {`Updated on
          ${formatDateTime(leakState.updatedOn, {timezone: true})}`}
        </Typography>
        <Typography variant="h5" style={{color: colors.red[400]}}>
          STUFFING BOX LEAK
        </Typography>
      </div>
    </PaperContainer>
  );
};
