import React, {useEffect} from "react";
import compose from "lodash/fp/compose";
import {connect} from "react-redux";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/fp/filter";
import last from "lodash/last";
import maxBy from "lodash/fp/maxBy";
import Paper from "@material-ui/core/Paper/Paper";
import Typography from "@material-ui/core/Typography/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

import {setWellboreSeries} from "actions/wells/well-health";
import {VIEW} from "constants/well-health";

import KPIPanelWellbore from "../kpi-panel-wellbore";
import processWellboreSurvey from "./process-wellbore-survey";
import processRodSections from "./process-rod-sections";
import WellboreGraph from "../wellbore-graph";
import WellboreNoData from "../wellbore-no-data";
import WellboreViewControl from "../wellbore-view-control";
import {reduceRodSeries, reduceSurveySeries} from "./reduce-series";

const styles = ({typography, spacing}) => ({
  main: {
    padding: spacing(3, 4),
  },

  grid: {
    display: "grid",
    gridTemplateRows: "auto auto 564px auto",
  },
});

const pumpDepthReducer = (total, {rodLength, rodQuantity}) =>
  (total += rodLength.value * rodQuantity);

const generateSeries = (sections, plunger, surveyData, pumpDepth) => {
  const result = {
    surveySeries: null,
    rodSeries: null,
    plungerSeries: null,
  };

  const maxDls = compose(
    maxBy("doglegSeverityDeg30m"),
    filter(p => p.measuredDepth <= pumpDepth),
  );

  // Generate the wellbore series from the survey data.
  try {
    result.surveySeries = {
      ...reduceSurveySeries(surveyData),
      maxDls: pumpDepth ? maxDls(surveyData) : null,
    };
  } catch (err) {
    console.error(err);
    result.surveySeries = null;
  }

  if (sections && plunger) {
    const rodData = processRodSections(sections, surveyData);

    // Create a copy of the rod data with any hidden sections removed.
    // This is used to create the series or rod sections we want to show on the wellbore graph.
    const filteredRodData = rodData.filter(s => !s.hideSection);

    // Generate the rod section series from the rod data.
    try {
      result.rodSeries = reduceRodSeries(filteredRodData);
    } catch (err) {
      console.error(err);
      result.rodSeries = null;
    }

    // Generate the plunger series from the plunger data and the last rod section point
    const endPointOfRodString = last(rodData).endPoint;
    try {
      result.plungerSeries = {
        x: [endPointOfRodString.x],
        y: [endPointOfRodString.y],
        z: [endPointOfRodString.z],
        inclinationDegrees: [endPointOfRodString.inclinationDegrees],
        doglegSeverityDeg30m: [endPointOfRodString.doglegSeverityDeg30m],
        measuredDepth: [endPointOfRodString.measuredDepth],
        trueVerticalDepth: [endPointOfRodString.trueVerticalDepth],
        diameter: [plunger.diameter.value],
      };
    } catch (err) {
      console.error(err);
      result.plungerSeries = null;
    }
  }

  return result;
};

const mapStateToProps = ({wells, wellHealth}) => ({
  ...wellHealth.wellbore,
  activeDownholeLocation: wells.activeDownholeLocation,
});

const mapDispatchToProps = dispatch => ({
  setWellboreSeries: (surveySeries, rodSeries, plungerSeries) =>
    dispatch(setWellboreSeries(surveySeries, rodSeries, plungerSeries)),
});

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
);

const WellborePanel = ({
  well,
  ready,
  surveySeries,
  rodSeries,
  plungerSeries,
  setWellboreSeries,
  view,
  activeDownholeLocation,
  classes,
}) => {
  const {wellbore, downholeInformation} = well;

  useEffect(
    () => {
      // If there is no wellbore, wellboreSurvey or the survey is less than 2 points, we can't do anything and will just show the no-data state.
      if (get(wellbore, "wellboreSurvey", []).length >= 2) {
        // Try and get the sections and plunger if they exist.
        const sections = get(downholeInformation, "sections", null);
        const plunger = get(downholeInformation, "plunger", null);

        const pumpDepth = sections
          ? sections.reduce(pumpDepthReducer, 0)
          : null;

        const surveyData = processWellboreSurvey(
          wellbore.wellboreSurvey,
          pumpDepth,
        );

        const {surveySeries, rodSeries, plungerSeries} = generateSeries(
          sections,
          plunger,
          surveyData,
          pumpDepth,
        );

        setWellboreSeries(surveySeries, rodSeries, plungerSeries);
      }
    },
    [wellbore, downholeInformation, setWellboreSeries],
  );

  const hasRequiredData = (view, surveySeries, rodSeries, plungerSeries) => {
    switch (view) {
      case VIEW.WELLBORE:
        return !isEmpty(surveySeries);
      case VIEW.EQUIPMENT:
      case VIEW.DOGLEG:
        return (
          !isEmpty(surveySeries) &&
          !isEmpty(rodSeries) &&
          !isEmpty(plungerSeries)
        );
      default:
        return false;
    }
  };

  return (
    <Paper className={classes.main}>
      <div className={classes.grid}>
        <Typography variant="h6">Wellbore Survey</Typography>
        <KPIPanelWellbore
          surveySeries={surveySeries}
          plungerSeries={plungerSeries}
        />
        {ready &&
        hasRequiredData(view, surveySeries, rodSeries, plungerSeries) ? (
          <WellboreGraph
            surveySeries={surveySeries}
            rodSeries={rodSeries}
            plungerSeries={plungerSeries}
          />
        ) : (
          <WellboreNoData downholeLocation={activeDownholeLocation} />
        )}
        <WellboreViewControl />
      </div>
    </Paper>
  );
};

export default enhance(WellborePanel);
