export default (unitsOfMeasure = "metric") => {
  const isImperial = unitsOfMeasure.toLocaleLowerCase() === "imperial";

  return {
    current: "A",
    dailyGasProduction: isImperial ? "Mcf/d" : "e3m3/d",
    dailyProduction: isImperial ? "bbl/d" : "m3/d",
    production: isImperial ? "bbl" : "m3",
    deflection: "degrees",
    density: isImperial ? "lb/ft3" : "kg/m3",
    depth: isImperial ? "ft" : "m",
    height: isImperial ? "ft" : "m",
    kb: isImperial ? "ftKB" : "mKB",
    length: isImperial ? "in" : "mm",
    load: isImperial ? "lbf" : "daN",
    mass: isImperial ? "lb" : "kg",
    position: isImperial ? "in" : "cm",
    pressure: isImperial ? "psi" : "kPa",
    speed: isImperial ? "mph" : "km/h",
    temperature: isImperial ? "f" : "c",
    torque: isImperial ? "ft-lb" : "Nm",
    tvd: isImperial ? "ftTVD" : "mTVD",
    voltage: "V",
    volume: isImperial ? "cuft" : "m3",
  };
};
