/* eslint-disable react/jsx-no-duplicate-props */ //This is because of InputProps and inputProps
import withStyles from "@material-ui/core/styles/withStyles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import React from "react";
import TextField from "@material-ui/core/TextField";

const classes = theme => {
  return {
    arrowDropDown: {
      top: `${theme.spacing(1) / 2}px`,
      right: 0,
      color: "rgba(0, 0, 0, 0.54)",
      position: "absolute",
      pointerEvents: "none",
    },
    input: {
      cursor: "pointer",
      fontSize: 14,
    },
  };
};

class Component extends React.Component {
  render() {
    const {classes, onClick, value, ...rootProps} = this.props;

    return (
      <TextField
        InputProps={{
          inputProps: {
            className: classes.input,
          },
          readOnly: true,
          endAdornment: <ArrowDropDownIcon className={classes.arrowDropDown} />,
        }}
        inputRef={node => (this.node = node)}
        onClick={() => onClick(this.node)}
        value={value}
        {...rootProps}
      />
    );
  }
}

export default withStyles(classes)(Component);
