import {Field} from "redux-form";
import React from "react";
import SimpleListMenu from "components/simple-list-menu";
import withUnits from "utils/with-units";

import formatLabel from "./format-label";
import getValue from "./get-value";

export const ListMenu = props => {
  const hasError =
    (!!props.meta.dirty || !!props.meta.submitFailed) && !!props.meta.error;

  return (
    <SimpleListMenu
      value={getValue(props)}
      label={formatLabel(props)}
      options={props.options}
      disabled={props.disabled}
      onChange={value => {
        const newValue = props.input.value.units
          ? withUnits(value, props.input.value.units)
          : value;

        props.input.onChange(newValue);
      }}
      error={hasError && props.meta.error}
      {...props}
    />
  );
};

export default props => {
  return <Field {...props} component={ListMenu} />;
};
