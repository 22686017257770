import {types} from "./types";

export const fetchMiniCardById = ({downholeLocation, cardId}) => {
  return {
    type: types.fetchMiniCardById,
    payload: {
      downholeLocation,
      cardId,
    },
  };
};
