import React from "react";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

import colors from "theme/colors";
import getUnits from "utils/units";

import Bar from "./bar";
import Empty from "../../components/comparison-empty";

const styles = () => ({
  barSection: {
    marginTop: 25,
  },
  contentWrapper: {
    padding: 25,
  },
  heading: {
    fontSize: 20,
    fontWeight: 700,
    margin: 0,
  },
  sectionHeading: {
    fontSize: 16,
    fontWeight: 700,
    margin: 0,
  },
  sectionValues: {
    marginBottom: 15,
  },
  warn: {
    color: colors.red[500],
    fontSize: 12,
    margin: 0,
  },
  wrapper: {
    background: "#f5f5f5",
    borderRadius: 4,
    height: "100%",
  },
});

const calculatePercentages = (productionComparison, maxProd) => {
  return {
    oil: (productionComparison.oil / maxProd) * 100,
    gas: (productionComparison.gas / maxProd) * 100,
    water: (productionComparison.water / maxProd) * 100,
    prevOil: (productionComparison.prevOil / maxProd) * 100,
    prevGas: (productionComparison.prevGas / maxProd) * 100,
    prevWater: (productionComparison.prevWater / maxProd) * 100,
  };
};

const ProductionComparison = ({
  classes,
  dateDiff,
  productionComparison,
  unitsOfMeasure,
}) => {
  if (!dateDiff) {
    return (
      <div className={classes.wrapper}>
        <Empty />
      </div>
    );
  }

  const maxProd = Math.max(
    productionComparison.oil,
    productionComparison.gas,
    productionComparison.water,
    productionComparison.prevOil,
    productionComparison.prevGas,
    productionComparison.prevWater,
  );

  const percentages = calculatePercentages(productionComparison, maxProd);

  const units = getUnits(unitsOfMeasure.toLowerCase());

  return (
    <div className={classes.wrapper}>
      <div className={classes.contentWrapper}>
        <Typography variant="h3" className={classes.heading}>
          Production Comparison
        </Typography>
        {productionComparison.flagged ? (
          <p className={classes.warn}>
            Warning: Insufficient historical data to compare to selected time
            range, try a smaller date range.
          </p>
        ) : (
          <Typography variant="body2">
            Current {dateDiff}d vs. Previous {dateDiff}d
          </Typography>
        )}
        <div className={classes.barSection}>
          <Typography variant="h4" className={classes.sectionHeading}>
            Oil
          </Typography>
          <Typography variant="caption" className={classes.sectionValues}>
            {productionComparison.oil} {units.dailyProduction} vs.{" "}
            {productionComparison.prevOil} {units.dailyProduction}
          </Typography>
          <Bar background={colors.green[400]} width={percentages.oil} />
          <Bar
            background={colors.green[200]}
            width={percentages.prevOil}
            isCurrent={false}
          />
        </div>
        <div className={classes.barSection}>
          <Typography variant="h4" className={classes.sectionHeading}>
            Gas
          </Typography>
          <Typography variant="caption" className={classes.sectionValues}>
            {productionComparison.gas} {units.dailyGasProduction} vs.{" "}
            {productionComparison.prevGas} {units.dailyGasProduction}
          </Typography>
          <Bar background={colors.red[400]} width={percentages.gas} />
          <Bar
            background={colors.red[200]}
            width={percentages.prevGas}
            isCurrent={false}
          />
        </div>
        <div className={classes.barSection}>
          <Typography variant="h4" className={classes.sectionHeading}>
            Water
          </Typography>
          <Typography variant="caption" className={classes.sectionValues}>
            {productionComparison.water} {units.dailyProduction} vs.{" "}
            {productionComparison.prevWater} {units.dailyProduction}
          </Typography>
          <Bar background={colors.blue[400]} width={percentages.water} />
          <Bar
            background={colors.blue[200]}
            width={percentages.prevWater}
            isCurrent={false}
          />
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(ProductionComparison);
