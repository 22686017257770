import {connect} from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {useEffect} from "react";

import {rehydrateTags} from "actions/tagging";
import {toggleTagDrawer} from "actions/tagging";

import Footer from "./footer";
import Header from "./header";
import Selection from "./selection";
import TagArea from "./tag-area";

const useStyles = makeStyles({
  root: {userSelect: "none"},
});

const mapStateToProps = ({tags: {tagDrawerOpen}}) => ({
  isOpen: tagDrawerOpen,
});

const mapDispatchToProps = dispatch => ({
  toggleTagDrawer: () => dispatch(toggleTagDrawer()),
  rehydrateTags: () => dispatch(rehydrateTags()),
});

const TagsDialog = ({isOpen, toggleTagDrawer, rehydrateTags}) => {
  const classes = useStyles();
  useEffect(
    () => {
      if (isOpen) {
        rehydrateTags();
      }
    },
    [isOpen, rehydrateTags],
  );

  return (
    isOpen && (
      <Dialog
        open={true}
        onClose={toggleTagDrawer}
        maxWidth="md"
        className={classes.root}
        fullWidth
      >
        <Header />
        <Selection />
        <TagArea />
        <Footer toggleTagDrawer={toggleTagDrawer} />
      </Dialog>
    )
  );
};

const enhance = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default enhance(TagsDialog);
