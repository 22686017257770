import filter from "lodash/filter";
import React from "react";

import colors from "theme/colors";
import Dynocard from "components/dynocard";

const styles = {
  container: {
    backgroundColor: colors.white,
    border: `1px solid ${colors.grey[300]}`,
  },
};

const mapData = data =>
  data.map(ele => ({
    x: ele.position,
    y: ele.load,
  }));

const chartMargin = {
  left: 10,
  right: 10,
  top: 10,
  bottom: 10,
};

export default ({downholeData, surfaceData, width}) => {
  const downholeFiltered = filter(
    downholeData || [],
    obj => obj.load && obj.position,
  );
  const surfaceFiltered = filter(
    surfaceData || [],
    obj => obj.load && obj.position,
  );

  if (downholeFiltered.length === 0 || surfaceFiltered.length === 0)
    return null;

  return (
    <div
      style={{
        ...styles.container,
        display:
          downholeFiltered.length && surfaceFiltered.length ? "block" : "none",
        marginBottom: 10,
        marginRight: 5,
        width,
      }}
    >
      <Dynocard
        width={width}
        downholeData={mapData(downholeFiltered)}
        surfaceData={mapData(surfaceFiltered)}
        margin={chartMargin}
        hideAxis
      />
    </div>
  );
};
