import each from "lodash/fp/each";
import meanBy from "lodash/fp/meanBy";
import findLast from "lodash/fp/findLast";
import every from "lodash/fp/every";
import get from "lodash/fp/get";
import filter from "lodash/fp/filter";

const withDeviations = metrics => ([wellId, dailySummaries]) => {
  // Construct the initial object to be returned.
  // We'll add the deviations for each deviation type to it before returning it.
  const result = {
    wellId,
    downholeLocation: get("downholeLocation", dailySummaries[0]),
    deviations: {},
    dailySummaries,
  };

  // Calculate the deviations and store them based on their type in the deviations object.
  // Grouping them under an object makes it simpler to filter the complete list later based on deviation data.
  each(({unit, type, displayValue}) => {
    const avg = meanBy(type, dailySummaries.filter(s => s[type] !== null));
    const mostRecentSummaryWithValueForType = findLast(
      summary => get(type, summary) !== null,
      dailySummaries,
    );
    const recentValue = get(type, mostRecentSummaryWithValueForType);
    const deviation = recentValue - avg;
    const percent = avg === 0 ? 0 : (deviation / avg) * 100; // Divide by 0 is infinity which results in NaN.
    const missingDataCount = filter(s => get(type, s) === null, dailySummaries)
      .length;
    const missingAllData = every(s => get(type, s) === null, dailySummaries);

    result.deviations[type] = {
      avg,
      last: recentValue,
      deviation,
      absDeviation: Math.abs(deviation),
      percent,
      absPercent: Math.abs(percent),
      type,
      unit,
      label: displayValue,
      missingDataCount,
      missingAllData,
    };
  }, metrics);

  return result;
};

export default withDeviations;
