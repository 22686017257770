import {connect} from "react-redux";
import find from "lodash/find";
import {get} from "lodash";
import {InputLabel} from "@material-ui/core";
import React from "react";
import {reduxForm, formValueSelector} from "redux-form";
import {withRouter} from "react-router";

import Breadcrumbs from "components/breadcrumbs";
import BoxLoading from "components/box-loading";
import {
  deleteWebhook,
  fetchOrganizationServiceAccount,
  fetchOrganizationWells,
  removeServiceAccountFromOrganization,
  saveAssignedWells,
  saveWebhook,
  selectOrganization,
  setOrganizationServiceAccount,
} from "actions/organizations";
import styles from "routes/profile/organization/styles";

import createListView from "./list-view";
import Footer from "./footer";
import ManageWebhooks from "./manage-webhooks";
import mapWellsToAssignment from "./map-wells-to-assignment";
import PageHeader from "./page-header";
import RemoveServiceAccountFromOrganization from "./remove-service-account-from-organization";
import selectOrganizationUserDetails from "./select-organization-user-details";

const ListView = createListView("manage-service-account");

const selector = formValueSelector("manage-service-account");

const mapStateToProps = state => {
  const {
    organizations,
    organizationManageServiceAccount,
    organizationsWells,
  } = state;

  const {organizationName, organizationWells} = selectOrganizationUserDetails(
    organizations,
    organizationsWells,
  );

  return {
    organizationName,
    organizationManageServiceAccount,
    organizationWells,
    organizations: state.organizations,
    wells: state.wells,
    currentValues: {
      wells: selector(state, "wells"),
    },
    initialValues: {
      wells: mapWellsToAssignment(
        organizationWells,
        get(organizationManageServiceAccount, "assignedWells", []),
      ),
    },
  };
};

const mapDispatchToProps = {
  setOrganizationServiceAccount,
  selectOrganization,
  fetchOrganizationServiceAccount,
  fetchOrganizationWells,
  removeServiceAccountFromOrganization,
  saveAssignedWells,
  deleteWebhook,
  saveWebhook,
};

class ManageServiceAccount extends React.Component {
  state = {
    searchPhrase: "",
  };

  componentDidMount() {
    const {
      match: {
        params: {clientUserId, organizationId},
      },
      selectOrganization,
      fetchOrganizationServiceAccount,
      fetchOrganizationWells,
    } = this.props;

    selectOrganization({organizationId});
    fetchOrganizationServiceAccount({organizationId, clientUserId});
    fetchOrganizationWells({organizationId, clientUserId});
  }

  componentWillUnmount() {
    this.props.setOrganizationServiceAccount({
      data: null,
      organizationId: null,
    });
  }

  removeServiceAccountFromOrganization = () => {
    const {
      match: {
        params: {organizationId, clientUserId},
      },
      removeServiceAccountFromOrganization,
    } = this.props;

    removeServiceAccountFromOrganization({
      clientUserId,
      organizationId,
    });
  };

  render() {
    const {
      currentValues,
      handleSubmit,
      match: {
        params: {organizationId, clientUserId},
      },
      organizationName,
      organizationManageServiceAccount,
      organizationWells,
      organizations,
      reset,
      saveAssignedWells,
      deleteWebhook,
      saveWebhook,
    } = this.props;

    const serviceName = (organizationManageServiceAccount || {}).serviceName;

    if (
      !organizationManageServiceAccount ||
      !organizationManageServiceAccount.clientUserId
    ) {
      return <BoxLoading />;
    }

    return (
      <div>
        <form
          autoComplete="nope"
          onSubmit={handleSubmit(values => {
            const mapWellIdToDownholeLocation = wellId => {
              const well = find(organizationWells, {wellId});
              return well.downholeLocation;
            };

            const wellsWhereAssignedIs = isAssigned =>
              Object.keys(values.wells)
                .filter(wellId => values.wells[wellId] === isAssigned)
                .map(mapWellIdToDownholeLocation);

            saveAssignedWells({
              assignedWells: wellsWhereAssignedIs(true),
              clientUserId,
              organizationId,
              unassignedWells: wellsWhereAssignedIs(false),
            });
          })}
          style={styles.pageContainer}
        >
          <div style={{marginBottom: 16}}>
            <Breadcrumbs
              crumbs={[
                {
                  label: organizationName,
                  pathname: "/profile/organizations",
                },
                {
                  label: "Manage Service Accounts",
                  pathname: `/profile/organization/${
                    organizations.selectedOrganizationId
                  }/serviceaccounts`,
                },
                {
                  label: serviceName,
                },
              ]}
            />
          </div>

          <PageHeader
            title={serviceName}
            subtitle={`Adjust well assignments and add or remove webhooks from this service account.`}
            uppercase
          />

          <div>
            <InputLabel>Wells</InputLabel>
            <ListView
              containerStyle={{
                marginTop: 8,
                maxHeight: 580,
              }}
              organizationWells={organizationWells || {}}
              assignedWells={currentValues.wells || {}}
              reset={reset}
              searchPhrase={this.state.searchPhrase}
              footer={wellsSelected => (
                <Footer reset={reset} wellsSelected={wellsSelected} />
              )}
            />
          </div>
        </form>

        <div>
          <InputLabel>Webhooks</InputLabel>
          <ManageWebhooks
            webhooks={organizationManageServiceAccount.webhooks}
            onSave={webhook => {
              saveWebhook({
                clientUserId,
                organizationId,
                webhookId: webhook.webhookId,
                webhook,
              });
            }}
            onDelete={webhook => {
              deleteWebhook({
                clientUserId,
                organizationId,
                webhookId: webhook.webhookId,
              });
            }}
          />
        </div>

        <RemoveServiceAccountFromOrganization
          organizationName={organizationName}
          serviceName={serviceName}
          onConfirm={this.removeServiceAccountFromOrganization}
        />
      </div>
    );
  }
}

let Component = reduxForm({
  form: "manage-service-account",
  enableReinitialize: true,
})(ManageServiceAccount);

Component = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);
Component = withRouter(Component);

export default Component;
