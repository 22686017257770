import Field from "components/react-redux-form/field";
import TextField from "components/react-redux-form/text-field";
import React from "react";

import getModelPath from "../get-model-path";

const Input = props => {
  return <TextField {...props} fullWidth label="Alias" />;
};

export default () => {
  return <Field component={Input} modelPath={getModelPath("alias")} />;
};
