import React from "react";
import get from "lodash/get";
import round from "lodash/round";
import moment from "moment";

import getDisplayName from "utils/user/get-display-name";
import {RECOMMENDATION_TYPES} from "constants/recommendations";

const titles = {
  alignment: "Alignment",
  overpumping: "Overpumping",
  underpumping: "Underpumping",
};

const statusTitles = {
  accepted: "Accepted",
  dismissed: "Dismissed",
  open: "Open",
};

const formatSPM = (min, max) => `${round(min, 1) || 0}-${round(max, 1) || 0}`;

const ensure2Digits = number => {
  if (!number) return "00";
  return number.toString().length === 1 ? `0${number}` : number;
};
const formatTime = (hours, minutes) => {
  return `${ensure2Digits(hours)}:${ensure2Digits(minutes)}`;
};

const formatTimer = min => {
  if (!min) return "N/A";

  const hours = Math.floor(min / 60);
  const minutes = min % 60;

  return formatTime(hours, minutes);
};

const daysAgoMapping = {
  "0": "today",
  "-1": "yesterday",
  "-2": "two days ago",
  "-3": "three days ago",
  "-4": "four days ago",
  "-5": "five days ago",
  "-6": "six days ago",
  "-7": "seven days ago",
};

const withGeneralData = props => {
  const {acceptedOn, acceptedBy, createdOn, status} = props;
  const dayDiff = moment(acceptedOn)
    .startOf("day")
    .diff(moment().startOf("day"), "days");
  return {
    acceptedOn: {
      daysAgo: daysAgoMapping[dayDiff],
      full: moment(acceptedOn).format("MMMM Do"),
    },
    acceptedBy: getDisplayName(acceptedBy),
    createdOn: {
      month: moment(createdOn).format("MMM"),
      day: moment(createdOn).format("DD"),
    },
    status: statusTitles[status],
  };
};

const withPOCVFDSetpoints = props => {
  const {pumpOffSetting} = props.supportingData;
  return {
    setPointOne: ["Fillage", round(pumpOffSetting.primaryFillage, 1), "%"],
    setPointTwo: ["Strokes", round(pumpOffSetting.strokes, 1), "x"],
  };
};

const withEPMSetpoints = props => {
  const {runTime} = get(props, ["supportingData", "runTimer"], false);
  return {
    setPointOne: ["Run Time", formatTimer(runTime)],
  };
};

const withPOCProps = props => {
  const {hours, minutes} = props.supportingData.scheduledDowntime;
  return {
    setPointThree: ["Idle Time", formatTime(hours, minutes)],
  };
};

const withVFDProps = props => {
  const {min, max} = get(
    props,
    ["supportingData", "operation", "speedRange"],
    {},
  );
  return {
    setPointThree: ["SPM", formatSPM(min, max)],
  };
};

const withEPMProps = props => {
  const {stopTime} = get(props, ["supportingData", "runTimer"], false);
  return {
    setPointThree: ["Stop Time", formatTimer(stopTime)],
  };
};

const withAlignmentProps = ({recommendedData}) => {
  return {
    title: titles.alignment,
    action: ["Fillage", round(recommendedData.primaryFillage, 1), "%"],
    actionTwo: ["Strokes", round(recommendedData.strokes, 1), "x"],
  };
};

const withClassificationProps = ({supportingData}) => {
  return {
    title: titles[supportingData.classification.toLowerCase()],
    automation: true,
  };
};

const withAlignmentPOCProps = props => {
  return {
    ...withGeneralData(props),
    ...withPOCVFDSetpoints(props),
    ...withAlignmentProps(props),
    ...withPOCProps(props),
  };
};

const withAlignmentVFDProps = props => {
  return {
    ...withGeneralData(props),
    ...withPOCVFDSetpoints(props),
    ...withVFDProps(props),
    ...withAlignmentProps(props),
  };
};

const withIdleTimeProps = props => {
  const {hours, minutes} = props.recommendedData;
  return {
    ...withGeneralData(props),
    ...withPOCVFDSetpoints(props),
    ...withPOCProps(props),
    ...withClassificationProps(props),
    action: ["Idle Time", formatTime(hours, minutes)],
  };
};

const withSpeedRangeProps = props => {
  const {min, max} = props.recommendedData;
  return {
    ...withGeneralData(props),
    ...withPOCVFDSetpoints(props),
    ...withVFDProps(props),
    ...withClassificationProps(props),
    action: ["SPM", formatSPM(min, max)],
  };
};

const withTimerProps = props => {
  const {stopTime} = props.recommendedData;
  return {
    ...withGeneralData(props),
    ...withEPMSetpoints(props),
    ...withEPMProps(props),
    ...withClassificationProps(props),
    action: ["Stop Time", formatTimer(stopTime)],
  };
};

const propMappingByType = {
  [RECOMMENDATION_TYPES.IDLE_TIME]: withIdleTimeProps,
  [RECOMMENDATION_TYPES.LUFKIN_DOWNHOLE]: withAlignmentPOCProps,
  [RECOMMENDATION_TYPES.LUFKIN_DOWNHOLE_SECONDARY]: withAlignmentPOCProps,
  [RECOMMENDATION_TYPES.LUFKIN_DOWNHOLE_VFD]: withAlignmentVFDProps,
  [RECOMMENDATION_TYPES.LUFKIN_DOWNHOLE_VFD_SECONDARY]: withAlignmentVFDProps,
  [RECOMMENDATION_TYPES.SPEED_RANGE]: withSpeedRangeProps,
  [RECOMMENDATION_TYPES.TIMER]: withTimerProps,
};

const withPropMappingByType = Recommendation => {
  return props => {
    return (
      <Recommendation {...props} {...propMappingByType[props.type](props)} />
    );
  };
};

export default withPropMappingByType;
