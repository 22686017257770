export default (allWellIds = [], byWellId = {}) =>
  allWellIds.reduce((wells, wellId) => {
    const well = byWellId[wellId];

    if (well.isAmbyintWell) {
      return [...wells, well];
    }

    return wells;
  }, []);
