import {empty} from "rxjs/observable/empty";
import {of} from "rxjs/observable/of";
import parseOnCallUsers from "@ambyint/common/parsers/well/on-call-users";

import fetch from "epics/fetch";
import {setOnCall, types} from "actions/wells";

export default action$ => {
  return action$.ofType(types.fetchOnCall).switchMap(({payload}) => {
    return fetch(
      `/optimization/well/${encodeURIComponent(
        payload.downholeLocation,
      )}/oncall`,
      payload,
    )
      .flatMap(({response: data}) => {
        return of(
          setOnCall({
            downholeLocation: payload.downholeLocation,
            data: parseOnCallUsers(data),
          }),
        );
      })
      .catch(err => {
        console.error(err);
        // NOTE: Currently fetchOne is called simultaneously, and if a 401 or 403 occur, we handle that there.
        //       We have to catch here, or otherwise unhandled exceptions were sometimes preventing rxjs from
        //       allowing fetchOne to catch its exception.
        return empty();
      });
  });
};
