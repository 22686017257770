import {types} from "./types";

export {addNotificationType} from "./add-notification-type";
export {removeNotificationType} from "./remove-notification-type";
export {types};

export const verifyEmail = ({
  firstName,
  inviteKey,
  lastName,
  mobilePhone,
  notificationMethods,
  password,
  type,
  unitsOfMeasure,
}) => {
  return {
    type: types.verifyEmail,
    payload: {
      firstName,
      inviteKey,
      lastName,
      mobilePhone,
      notificationMethods,
      password,
      type,
      unitsOfMeasure,
    },
  };
};

export const verifyOAuth = ({callbackToken, clientUserId}) => {
  return {
    type: types.verifyOAuth,
    payload: {callbackToken, clientUserId},
  };
};

export const getAuth = () => {
  return {
    type: types.get,
    payload: {},
  };
};

export const getInviteDetails = ({inviteKey}) => {
  return {
    type: types.getInviteDetails,
    payload: {inviteKey},
  };
};

export const setInviteDetails = ({
  availableUserTypes,
  firstName,
  isInvalidInviteKey,
  lastName,
  mobilePhone,
  notificationMethods,
  type,
  unitsOfMeasure,
  userLogin,
}) => {
  return {
    type: types.setInviteDetails,
    payload: {
      availableUserTypes,
      firstName,
      isInvalidInviteKey,
      lastName,
      mobilePhone,
      notificationMethods,
      type,
      unitsOfMeasure,
      userLogin,
    },
  };
};

export const navigateToLogin = () => {
  return {
    type: types.navigateToLogin,
    payload: {},
  };
};

export const setAuth = ({error, token, isAuthenticated, isFetching, user}) => {
  return {
    type: types.set,
    payload: {
      error,
      token,
      isAuthenticated,
      isFetching,
      user,
    },
  };
};

export const save = ({clientUserId, data}) => {
  return {
    type: types.save,
    payload: {
      clientUserId,
      data,
    },
  };
};

export const savePreferences = ({clientUserId, data, user}) => {
  return {
    type: types.savePreferences,
    payload: {
      clientUserId,
      data,
      user,
    },
  };
};

export const signOut = ({snackbarMessage = null} = {}) => {
  return {
    type: types.signOut,
    payload: {
      snackbarMessage,
    },
  };
};

export const signInWithEmail = ({email, password}) => {
  return {
    type: types.signInWithEmail,
    payload: {email, password},
  };
};

export const signInWithGoogle = () => {
  return {
    type: types.signInWithGoogle,
    payload: {},
  };
};

export const signInWithAzure = () => {
  return {
    type: types.signInWithAzure,
    payload: {},
  };
};

export const signUpWithAzure = email => {
  return {
    type: types.signUpWithAzure,
    payload: {email},
  };
};

export const signUpWithGoogle = email => {
  return {
    type: types.signUpWithGoogle,
    payload: {email},
  };
};

export const completeSignInWithOAuth = ({clientUserId, callbackToken}) => {
  return {
    type: types.completeSignInWithOAuth,
    payload: {
      clientUserId,
      callbackToken,
    },
  };
};

export const isCompletingSignIn = () => {
  return {
    type: types.isCompletingSignIn,
    payload: {},
  };
};

export const completedSignIn = () => {
  return {
    type: types.completedSignIn,
    payload: {},
  };
};

export const requestPasswordResetEmail = ({email}) => {
  return {
    type: types.requestPasswordResetEmail,
    payload: {email},
  };
};

export const changePassword = ({password, passwordResetCode}) => {
  return {
    type: types.changePassword,
    payload: {password, passwordResetCode},
  };
};
