import {types} from "./types";

export const fetchOrganizationServiceAccount = options => {
  const {organizationId, clientUserId} = options;

  return {
    type: types.fetchOrganizationServiceAccount,
    payload: {
      organizationId,
      clientUserId,
    },
  };
};
