import parseWell from "@ambyint/common/parsers/well";

import {createSwitchEpic} from "epics/create-epic";
import {setOne, types} from "actions/wells";
import getUser from "utils/get-user";

import {getWell} from "./get-well";

export const parseOne = createSwitchEpic(
  [types.parseWell],
  async ({payload}, store) => {
    const {unitsOfMeasure} = getUser();
    const parse = parseWell(unitsOfMeasure);

    return [
      setOne({
        wellId: payload.wellId,
        data: parse(payload, getWell(store, payload.wellId)),
      }),
    ];
  },
);
