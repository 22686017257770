import {connect} from "react-redux";
import {hideStopWell} from "actions/dialogs";
import {stopWell} from "actions/wells";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "components/dialog";
import React from "react";
import semver from "semver";

import {isControlIotBridge} from "@ambyint/common/utils/devices/control-iot-bridge";
import pluralize from "@ambyint/common/utils/format/format-pluralize";

const mapStateToProps = state => {
  return state.dialogs.stopWell;
};

const stop = (dispatch, well) => {
  return () => {
    dispatch(stopWell(well));
    close(dispatch)();
  };
};

const close = dispatch => {
  return () => dispatch(hideStopWell());
};

const Component = ({dispatch, isOpen, well}) => {
  if (!well) {
    return null;
  }

  const softwareVersionCutoff =
    well.devices.some(device => device.softwareVersion) &&
    well.devices.some(
      device =>
        isControlIotBridge(device) &&
        semver.lt(device.softwareVersion, "1.71.0"),
    );

  const message = softwareVersionCutoff
    ? well.scheduledDowntime
      ? `This action will issue a downtime command. Currently downtime is set to ${
          well.scheduledDowntime.hours
        } hour${pluralize(well.scheduledDowntime.hours)} and ${
          well.scheduledDowntime.minutes
        } minute${pluralize(
          well.scheduledDowntime.minutes,
        )}. Your equipment will restart once downtime has elapsed.`
      : "This action will issue a stop command."
    : "This action will issue a stop command. This may take a few moments to apply.";

  return (
    <Dialog open={isOpen} onClose={close(dispatch)}>
      <DialogTitle>{`Stop well ${well.displayName}`}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
          <br />
          <br />
          {softwareVersionCutoff &&
            "Note: Newer releases of the Ambyint device software will support a complete shutdown."}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close(dispatch)} color="primary">
          Cancel
        </Button>
        <Button onClick={stop(dispatch, well)} color="primary">
          Stop Well
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps)(Component);
