import {useState} from "react";

/**
 * In order to use this, add the following props to the component:
 *    onMouseEnter={enableHover}
 *    onMouseLeave={disableHover}
 */
export const useHover = () => {
  const [isHovering, setIsHovering] = useState(false);
  const [item, setItem] = useState(null);

  /**
   * Enables hover state, optionally saves hovered item descriptor
   *
   * @param {string|number} item (optional) identifier describing hovered item
   */
  const enableHover = item => {
    setIsHovering(true);
    if (item) setItem(item);
  };

  const disableHover = () => {
    setIsHovering(false);
    setItem(null);
  };

  return {isHovering, hoveredItem: item, enableHover, disableHover};
};
