import {getUnits} from "@ambyint/common/utils/get-units";
import {load} from "@ambyint/common/unit-converter";
import valueOf from "@ambyint/common/utils/value-of";

export const parse = ({values, unitsOfMeasure}) => {
  const units = getUnits(unitsOfMeasure);

  const convert = value =>
    load.convert({from: units.load, to: "N", value: valueOf(value)});

  return {
    ...values,
    minLoad: convert(values.minLoad),
    peakLoad: convert(values.peakLoad),
  };
};

export default parse;
