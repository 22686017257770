import {set, types} from "actions/crank-weights";
import fetch from "epics/fetch";

export const search = (action$, store) => {
  return action$.ofType(types.fetch).switchMap(({payload}) => {
    const state = store.getState().crankWeights;
    if (state.fetched) return [];
    return fetch("/crankWeights").map(({response: data}) => set({data}));
  });
};
