import getUser from "./get-user";

export default organizationId => {
  const user = getUser();
  const privileges = user.privileges || [];

  return (
    (
      privileges.find(
        organization => organization.organizationId === organizationId,
      ) || {}
    ).privileges || []
  );
};
