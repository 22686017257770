import React from "react";

import Typography from "@material-ui/core/Typography";

import colors from "theme/colors";

import ConfirmationDialog from "components/confirmation-dialog";
import ListStyleButton from "components/list-style-button";

class RemoveServiceAccountFromOrganization extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showConfirmationDialog: false,
    };
  }

  render() {
    const {onConfirm, organizationName, serviceName} = this.props;

    return [
      <ListStyleButton
        key={0}
        primary={
          <div style={{color: colors.red[600]}}>
            Remove Service Account From Organization
          </div>
        }
        secondary={
          "The service account will be removed from the organization and have all well assignments revoked and webhooks removed"
        }
        containerStyle={{
          marginTop: 16,
        }}
        onClick={() => {
          this.setState({showConfirmationDialog: true});
        }}
      />,
      <ConfirmationDialog
        key={1}
        title={`Remove Service Account from ${organizationName}`}
        cancelButtonText="Cancel"
        confirmationButtonText="Remove Service Account"
        isOpen={this.state.showConfirmationDialog}
        onCancel={() => {
          this.setState({showConfirmationDialog: false});
        }}
        onConfirm={() => {
          this.setState({showConfirmationDialog: false});

          onConfirm();
        }}
        body={
          <Typography variant="body2">
            You will remove the service account from the organization. All well
            assignments will be revoked and webhooks will be removed. Enter the
            service account&apos;s full name <strong>{serviceName}</strong> to
            confirm that you want to remove this service account.
          </Typography>
        }
        confirmationLabel={`Type ${serviceName} to confirm`}
        expectedConfirmationText={serviceName}
      />,
    ];
  }
}

export default RemoveServiceAccountFromOrganization;
