import {warmGrey, blue, yellowVivid} from "theme/updated-colors";

export const findColors = ({on, autoAccept, metBoundary}) => {
  let automatedColors;
  let powerColors;

  if (on) {
    powerColors = metBoundary
      ? [yellowVivid[500], "white", yellowVivid[600]]
      : [blue[700], "white", blue[700]];
    if (autoAccept) {
      automatedColors = metBoundary
        ? [yellowVivid[500], "white", yellowVivid[500]]
        : [blue[700], "white", blue[700]];
    } else {
      automatedColors = metBoundary
        ? ["white", yellowVivid[600], yellowVivid[600]]
        : ["white", blue[700], blue[700]];
    }
  } else {
    powerColors = [warmGrey[500], "white", warmGrey[500]];
    if (autoAccept) {
      automatedColors = [warmGrey[500], "white", warmGrey[500]];
    } else {
      automatedColors = [warmGrey[100], warmGrey[500], warmGrey[500]];
    }
  }

  return [powerColors, automatedColors];
};
