import React from "react";
import {withRouter} from "react-router";

/**
 * Scroll to top of page if the location changes.
 *
 * React Router doesn't always scroll-to-top on route change gracefully,
 * though they claim browsers are starting to support it natively.
 *
 * This use case was failing without this:
 * User Profile => Organization => Manage User => Remove User From Organization
 * Removing user is at bottom of page. Removing your own user takes you
 * back to the base Organization page, which has Organization selection at the top.
 * Without this, it was keeping scroll location at bottom of page like on Manage User page,
 * such that the organization name / selection may not be visible, jarring to the user.
 *
 * This is what is recommended in React Router's documentation, here:
 * https://reacttraining.com/react-router/web/guides/scroll-restoration
 *
 * This should only be needed as one instance in app.js,
 * unless we find use cases where custom scrolling behavior is desired.
 */
class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
