import {combineEpics} from "redux-observable";
import * as alerts from "./alerts";
import * as auth from "./auth";
import * as casingSpecs from "./casing-specs";
import * as couplings from "./couplings";
import * as cranks from "./cranks";
import * as crankWeights from "./crank-weights";
import * as deviation from "./deviation";
import * as fieldNames from "./field-names";
import * as guides from "./guides";
import * as guideMaterials from "./guide-materials";
import * as history from "./history";
import * as onCall from "./on-call";
import * as organizations from "./organizations";
import * as organizationView from "./organization-view";
import * as paging from "./paging";
import * as pumpjacks from "./pumpjacks";
import * as rods from "./rods";
import * as rodDimensions from "./rod-dimensions";
import * as tags from "./tagging";
import * as tubing from "./tubing";
import * as wellHealth from "./wells/well-health";
import * as wells from "./wells";
import * as wellSurveillance from "./wells/well-surveillance";
import * as wellTickets from "./well-tickets";
import gainsightIdentify from "./gainsight-identify";

export default combineEpics(
  gainsightIdentify,
  alerts.acknowledgeAlert,
  alerts.addAlert,
  alerts.fetch,
  alerts.fetchTimeline,
  alerts.parseOne,
  alerts.resolveAlert,
  alerts.updateAlert,
  auth.addNotificationType,
  auth.changePassword,
  auth.completeSignInWithOAuth,
  auth.completedSignIn,
  auth.get,
  auth.getInviteDetails,
  auth.navigateToLogin,
  auth.removeNotificationType,
  auth.requestPasswordResetEmail,
  auth.save,
  auth.savePreferences,
  auth.set,
  auth.signInWithAzure,
  auth.signUpWithAzure,
  auth.signInWithEmail,
  auth.signInWithGoogle,
  auth.signUpWithGoogle,
  auth.signOut,
  auth.verifyEmail,
  auth.verifyOAuth,
  casingSpecs.fetch,
  couplings.fetch,
  crankWeights.search,
  cranks.search,
  deviation.fetchDailySummaries,
  deviation.calculateDeviations,
  fieldNames.search,
  guideMaterials.fetch,
  guides.fetch,
  history.replace,
  onCall.getCurrentCalloutSchedule,
  onCall.getFullOnCallSchedule,
  onCall.schedule,
  onCall.unschedule,
  organizations.createServiceAccount,
  organizations.deleteWebhook,
  organizations.fetchOrganization,
  organizations.fetchOrganizationServiceAccount,
  organizations.fetchOrganizationUser,
  organizations.fetchOrganizationWells,
  organizations.filterEventsByDateRange,
  organizations.inviteUser,
  organizations.reinviteUser,
  organizations.removeServiceAccountFromOrganization,
  organizations.removeUserFromOrganization,
  organizations.saveAccess,
  organizations.saveAssignedWells,
  organizations.saveWebhook,
  organizations.searchOrganizationServiceAccounts,
  organizations.searchOrganizationUsers,
  organizations.selectOrganization,
  organizations.selectOrganizationOnAuthSet,
  organizationView.setOrganizationView,
  paging.updateFilterAlerts,
  paging.updateFilterLocationChange,
  paging.updateFilterWells,
  pumpjacks.search,
  rodDimensions.fetch,
  rods.fetch,
  tags.filterWellsByTags,
  tags.updateWellTags,
  tags.rehydrateTags,
  tags.disableUselessTags,
  tags.filterSelectedWells,
  tubing.fetch,
  wellHealth.fetchClassifications,
  wellHealth.fetchCompressiveStress,
  wellHealth.fetchDailySummaries,
  wellHealth.fetchRankings,
  wellHealth.fetchSetpointChanges,
  wells.acceptRecommendation,
  wells.addComparison,
  wells.create,
  wells.deleteGracePeriod,
  wells.dismissRecommendation,
  wells.fetchAlerts,
  wells.fetchAnalysis,
  wells.fetchAssigned,
  wells.fetchCards,
  wells.fetchComparison,
  wells.fetchEvents,
  wells.fetchLatestCards,
  wells.fetchOnCall,
  wells.fetchOne,
  wells.fetchRecommendations,
  wells.fetchAllRecommendations,
  wells.fetchMiniCardById,
  wells.fetchCardOverride,
  wells.fetchLatestMiniCard,
  wells.fetchUptimeOne,
  wells.fetchWells,
  wells.generateShareableAnalysisLink,
  wells.initializeAnalysis,
  wells.parseOne,
  wells.removeComparison,
  wells.requestCard,
  wells.resetFault,
  wells.saveAutoAcceptRecommendations,
  wells.saveBasicInformation,
  wells.saveDownholeInformation,
  wells.saveGracePeriod,
  wells.saveMotorDetails,
  wells.saveMotorViolations,
  wells.saveNominalRpm,
  wells.savePumpingUnitInformation,
  wells.savePumpoffSettings,
  wells.saveReservoirInformation,
  wells.saveResistance,
  wells.saveSafeOperatingParameters,
  wells.saveSlowSpeedOffTime,
  wells.saveTag,
  wells.saveTotalReductionRatio,
  wells.saveWellboreInformation,
  wells.searchWells,
  wells.setActiveAnomaly,
  wells.setActivePath,
  wells.setAnalogInputConfiguration,
  wells.setCardOverride,
  wells.setDowntime,
  wells.setFaultRecovery,
  wells.setLufkinDeadband,
  wells.setLufkinFillBase,
  wells.setLufkinMalfunction,
  wells.setLufkinRodString,
  wells.setLufkinStructuralLoad,
  wells.setRecommendationType,
  wells.setResetMalfunctions,
  wells.setRunTimer,
  wells.setSpeed,
  wells.setSpeedRange,
  wells.startWell,
  wells.stopWell,
  wells.updateAnalysis,
  wells.viewFeedComparison,
  wellSurveillance.fetchHistory,
  wellTickets.prefillForm,
);
