import React from "react";
import {hasControlForEpm} from "@ambyint/common/utils/devices/control-for-epm";
import Grid from "@material-ui/core/Grid";
import minBy from "lodash/minBy";
import maxBy from "lodash/maxBy";
import math from "mathjs";

import getMotorDataType from "utils/get-motor-data-type";
import getUser from "utils/get-user";
import unitsOf from "utils/units-of";
import valueOf from "utils/value-of";

import Analysis from "./analysis";
import Item from "./item";

const DriveDataAnalysis = ({data: {driveData} = {}, devices}) => {
  const user = getUser();
  const isEpm = hasControlForEpm(devices);
  const currentOrTorque =
    isEpm && user.epmPreference ? user.epmPreference : "Torque";

  const min = (driveData, keyName) =>
    valueOf(
      (minBy(driveData, d => math.round(valueOf(d[keyName]))) || {})[keyName],
    );
  const max = (driveData, keyName) =>
    valueOf(
      (maxBy(driveData, d => math.round(valueOf(d[keyName]))) || {})[keyName],
    );

  const leftAxisUnits =
    isEpm && user.epmPreference ? user.epmPreference : "Torque";

  const motorViolations =
    driveData &&
    driveData.motorViolations &&
    driveData.motorViolations[
      `${leftAxisUnits.toLocaleLowerCase()}ViolationSetpoint`
    ];

  const range = keyName => {
    const minValue = math.round(min(driveData.value, keyName.toLowerCase()), 3);
    const maxValue = math.round(max(driveData.value, keyName.toLowerCase()), 3);

    return minValue || maxValue
      ? [
          <Grid item xs={6} key={`${keyName}-min`}>
            <Item
              title={`Min ${keyName}`}
              value={`${minValue} ${unitsOf(
                driveData.value[0][keyName.toLowerCase()],
              )}`}
            />
          </Grid>,
          <Grid item xs={6} key={`${keyName}-max`}>
            <Item
              title={`Max ${keyName}`}
              value={`${maxValue} ${unitsOf(
                driveData.value[0][keyName.toLowerCase()],
              )}`}
            />
          </Grid>,
        ]
      : null;
  };

  return driveData ? (
    <Analysis title={`${getMotorDataType(devices)} Data Analysis`}>
      <Grid container>
        {range(currentOrTorque)}
        {range("RPM")}

        {motorViolations && (
          <Grid item xs={6}>
            <Item
              title={"Min Setpoint"}
              value={
                valueOf(motorViolations.min)
                  ? `${valueOf(motorViolations.min)} ${unitsOf(
                      motorViolations.max,
                    )}`
                  : "--"
              }
            />
          </Grid>
        )}
        {motorViolations && (
          <Grid item xs={6}>
            <Item
              title={"Max Setpoint"}
              value={
                valueOf(motorViolations.max)
                  ? `${valueOf(motorViolations.max)} ${unitsOf(
                      motorViolations.max,
                    )}`
                  : "--"
              }
            />
          </Grid>
        )}
      </Grid>
    </Analysis>
  ) : null;
};

export default DriveDataAnalysis;
