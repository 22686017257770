import get from "lodash/get";

import {isYoungerThan} from "utils/time/is-younger-than";
import {hasIotDevices} from "utils/well/has-iot-devices";

const hasRelevantSummaries = well => {
  const summaries = get(well, "dailyAnomalySummaries", []);
  const hasSummaries = summaries.length > 0;

  const summariesAreRelevant = summaries.some(({range}) =>
    isYoungerThan(range.end, 24, "hours"),
  );

  return hasSummaries && summariesAreRelevant;
};

export const isAnomalyWell = well => {
  return hasIotDevices(well) && hasRelevantSummaries(well);
};
