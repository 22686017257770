import {of} from "rxjs/observable/of";
import {types, set} from "actions/on-call";
import fetch from "epics/fetch";
import getCookie from "utils/get-cookie";

export default (action$, store) => {
  return action$.ofType(types.fetchFullSchedule).switchMap(({payload}) => {
    const token = getCookie("id_token");

    if (token) {
      const url = `/client-experience/user/${encodeURIComponent(
        payload.clientUserId,
      )}/calloutSchedulePeriod`;

      return fetch(url).map(res => {
        if (res.status === 200) {
          return set({
            schedule: res.response,
          });
        } else {
          return set({
            schedule: null,
          });
        }
      });
    } else {
      return of(
        set({
          schedule: null,
        }),
      );
    }
  });
};
