const mergeWellData = (wells, allWells) =>
  wells.map(well => {
    const {displayName, fieldName} = allWells[well.wellId];
    return {
      ...well,
      displayName,
      fieldName,
    };
  });

export default mergeWellData;
