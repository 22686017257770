import React, {useState} from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import get from "lodash/get";

import PUMPOFF_SETTING_MODES from "constants/pumpoff-setting-modes";

import VariableSpeed from "./modes/variable-speed";
import PumpFillage from "./modes/pump-fillage";

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 600,
  },
  header: {
    backgroundColor: theme.colors.warmGrey[50],
    padding: theme.spacing(2, 4, 0),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tab: {
    padding: 0,
    minWidth: 120,
    height: 40,
  },
  tabSelected: {
    color: theme.colors.warmGrey[600],
  },
}));

const Smarten = props => {
  const classes = useStyles();
  const [mode, setMode] = useState(
    get(props, "well.pumpOffSetting.mode.value"),
  );

  const handleChange = (event, newValue) => {
    setMode(newValue);
  };

  return (
    <Paper className={classes.container}>
      <div className={classes.header}>
        <Typography variant="h6">Pumpoff Settings</Typography>
        <Tabs
          value={mode}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab
            classes={{root: classes.tab, selected: classes.tabSelected}}
            label="pump fillage"
            value={PUMPOFF_SETTING_MODES.STANDARD_DOWNHOLE}
          />
          <Tab
            classes={{root: classes.tab, selected: classes.tabSelected}}
            label="variable speed"
            value={PUMPOFF_SETTING_MODES.VFD_DOWNHOLE}
          />
        </Tabs>
      </div>
      {mode === PUMPOFF_SETTING_MODES.VFD_DOWNHOLE && (
        <VariableSpeed {...props} />
      )}
      {mode === PUMPOFF_SETTING_MODES.STANDARD_DOWNHOLE && (
        <PumpFillage {...props} />
      )}
    </Paper>
  );
};

export default Smarten;
