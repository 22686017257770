import React from "react";
import {select} from "d3-selection";

import colors from "theme/colors";

const DEFAULT_COLOR = "#000000";
const DEFAULT_RADIUS = 3;
const DEFAULT_STROKE_WIDTH = 3;

export default class extends React.Component {
  componentDidMount() {
    this.renderIcon();
  }

  renderIcon = () => {
    const {
      color = DEFAULT_COLOR,
      radius = DEFAULT_RADIUS,
      strokeWidth = DEFAULT_STROKE_WIDTH,
    } = this.props;

    select(this.container)
      .append("circle")
      .style("stroke", color)
      .style("fill", colors.transparent)
      .style("stroke-width", strokeWidth)
      .style("r", radius)
      .attr("cx", radius + strokeWidth)
      .attr("cy", radius + strokeWidth);
  };

  render() {
    const {
      radius = DEFAULT_RADIUS,
      strokeWidth = DEFAULT_STROKE_WIDTH,
    } = this.props;

    return (
      <svg
        height={radius * 2 + strokeWidth * 2}
        width={radius * 2 + strokeWidth * 2}
        ref={container => (this.container = container)}
      />
    );
  }
}
