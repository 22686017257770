import filter from "lodash/filter";
import find from "lodash/find";
import map from "lodash/map";
import without from "lodash/without";

import {mappingTypes} from "constants/well-event-import-mapping-types";

export const getOption = criteria => {
  const options = getOptions();

  return (
    find(options, option =>
      new RegExp(`^(${option.type}|${option.display})`, "i").test(criteria),
    ) || options[0]
  );
};

export const getOptions = () => {
  return [
    {
      display: "Unassigned",
      type: mappingTypes.IGNORE,
      availableUnits: [],
    },
    {
      display: "Date",
      type: mappingTypes.CREATED_ON,
      availableUnits: [],
    },
    {
      display: "Downhole Location",
      type: mappingTypes.DOWNHOLE_LOCATION,
      availableUnits: [],
    },
    {
      display: "Casing Pressure",
      type: mappingTypes.CASING_PRESSURE,
      availableUnits: [
        {type: "metric", value: "kPa"},
        {type: "imperial", value: "psi"},
      ],
    },
    {
      display: "Gas Production",
      type: mappingTypes.GAS_PRODUCTION,
      availableUnits: [
        {type: "metric", value: "e3m3/d"},
        {type: "imperial", value: "Mcf/d"},
      ],
    },
    {
      display: "Oil Production",
      type: mappingTypes.OIL_PRODUCTION,
      availableUnits: [
        {type: "metric", value: "m3/d"},
        {type: "imperial", value: "bbl/d"},
      ],
    },
    // TODO: This can be removed if we don't decide to use it in 3 months from today (2018-06-12).
    // {
    //   display: "SPM",
    //   type: mappingTypes.SPM,
    //   availableUnits: [],
    // },
    {
      display: "Tubing Pressure",
      type: mappingTypes.TUBING_PRESSURE,
      availableUnits: [
        {type: "metric", value: "kPa"},
        {type: "imperial", value: "psi"},
      ],
    },
    {
      display: "Water Production",
      type: mappingTypes.WATER_PRODUCTION,
      availableUnits: [
        {type: "metric", value: "m3/d"},
        {type: "imperial", value: "bbl/d"},
      ],
    },
  ];
};

export const filterOptions = (mappings, selected) => {
  const usedTypes = without(
    map(mappings, "type"),
    selected.type,
    mappingTypes.IGNORE,
  );

  return filter(getOptions(), option => !usedTypes.includes(option.type));
};
