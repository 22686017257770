const mapDate = d => new Date(d.createdOn);

export default ({clipPathId, register, scales, selection}) => {
  const selections = selection.append("g");

  return register({
    draw(props) {
      const lines = selections
        .selectAll("line")
        .data(
          (props.selections || []).filter(selection => !selection.isFetching),
        );

      lines.exit().remove();
      lines.merge(lines).style("stroke", d => d.color);

      lines
        .enter()
        .append("line")
        .style("stroke", d => d.color)
        .style("stroke-width", 3)
        .attr("clip-path", `url(#${clipPathId})`)
        .attr("x1", d => scales.time(mapDate(d)))
        .attr("x2", d => scales.time(mapDate(d)))
        .attr("y1", 0)
        .attr("y2", props.height);
    },

    zoom({transform}) {
      const scale = transform.rescaleX(scales.time);

      selections
        .selectAll("line")
        .attr("x1", d => scale(mapDate(d)))
        .attr("x2", d => scale(mapDate(d)));
    },
  });
};
