import {connect} from "react-redux";
import {reduxForm} from "redux-form";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import get from "lodash/get";
import Grid from "@material-ui/core/Grid";
import React from "react";

import hasSmarten from "@ambyint/common/utils/devices/has-smarten";

import {setDowntime, saveSlowSpeedOffTime} from "actions/wells";
import {hideSetDowntime} from "actions/dialogs";
import Dialog from "components/dialog";
import PUMPOFF_SETTING_MODES from "constants/pumpoff-setting-modes";
import TextField from "components/forms/text-field";

import validate from "./validate";

const shouldDisplaySlowSpeed = well => {
  const isSmarten = hasSmarten(well.devices);
  const isDownholeVFD =
    get(well, "pumpOffSetting.mode.value") ===
    PUMPOFF_SETTING_MODES.VFD_DOWNHOLE;

  return isSmarten && isDownholeVFD;
};

const findInitialValues = (well, showSlowSpeed) => {
  const defaultValue = {
    hours: null,
    minutes: null,
  };
  const offTime = get(well, "operation.slowSpeedOffTime.offTime");
  if (well) {
    if (showSlowSpeed) {
      if (!offTime) {
        return defaultValue;
      }
      return {
        hours: Math.floor(offTime / 3600),
        minutes: (offTime % 3600) / 60,
      };
    }

    return well.scheduledDowntime;
  }

  return defaultValue;
};

const mapStateToProps = state => {
  const {isOpen, well} = state.dialogs.setDowntime;
  const showSlowSpeed = well && shouldDisplaySlowSpeed(well);
  const initialValues = findInitialValues(well, showSlowSpeed);

  return {
    initialValues,
    isOpen,
    well,
    showSlowSpeed,
  };
};

const set = (dispatch, well, showSlowSpeed) => {
  const {downholeLocation} = well;

  return values => {
    const {hours, minutes} = values;

    if (showSlowSpeed) {
      dispatch(
        saveSlowSpeedOffTime({
          downholeLocation,
          offTime: hours * 3600 + minutes * 60,
          enabled: true,
        }),
      );
    } else {
      dispatch(
        setDowntime({
          downholeLocation,
          data: {
            hours: parseInt(hours, 10),
            minutes: parseInt(minutes, 10),
          },
        }),
      );
    }

    close(dispatch)();
  };
};

const close = dispatch => {
  return () => {
    dispatch(hideSetDowntime());
  };
};

let Component = props => {
  const {
    dispatch,
    dirty,
    handleSubmit,
    invalid,
    isOpen,
    submitting,
    well,
  } = props;

  if (!well) return null;

  const SafeOperatingParametersText = props => {
    const well = props.well;

    const {maxDowntime, minDowntime} = well.safeOperatingParameters;

    return (
      <React.Fragment>
        <DialogContentText>
          Minimum safe downtime settings for this well are{" "}
          {`${minDowntime.hours} hours and ${minDowntime.minutes} minutes`}.
        </DialogContentText>
        <DialogContentText>
          Maximum safe downtime settings for this well are{" "}
          {`${maxDowntime.hours} hours and ${maxDowntime.minutes} minutes`}.
        </DialogContentText>
      </React.Fragment>
    );
  };

  return (
    <Dialog open={isOpen} onClose={close(dispatch)}>
      <form onSubmit={handleSubmit(set(dispatch, well, props.showSlowSpeed))}>
        <DialogTitle>{`Add Downtime for ${well.displayName}`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            How long should the downtime last?
          </DialogContentText>
          {well.safeOperatingParameters && (
            <SafeOperatingParametersText well={well} />
          )}
          <Grid container style={{padding: "16px 0 0 0"}} spacing={2}>
            <Grid item xs>
              <TextField
                name="hours"
                label="Hours"
                fullWidth
                required
                showError={dirty}
              />
            </Grid>
            <Grid item xs>
              <TextField
                name="minutes"
                label="Minutes"
                fullWidth
                required
                showError={dirty}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close(dispatch)} color="primary">
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            disabled={submitting || invalid}
          >
            Add Downtime
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

Component = reduxForm({
  enableReinitialize: true,
  form: "set-downtime-form",
  validate,
})(Component);

export default connect(mapStateToProps)(Component);
