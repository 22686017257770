import React from "react";
import CloseIcon from "mdi-material-ui/Close";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography/Typography";

export default ({classes, ...props}) => {
  return (
    <div className={classes.header}>
      <div className={classes.date}>
        <div>{props.createdOn.month}</div>
        <div>{props.createdOn.day}</div>
      </div>
      <div className={classes.headerText}>
        <Typography variant="subtitle2" classes={{subtitle2: classes.title}}>
          {props.title}
        </Typography>
        <Tooltip title={props.displayName} placement="bottom">
          <Typography
            variant="caption"
            classes={{caption: classes.displayName}}
          >
            {props.displayName}
          </Typography>
        </Tooltip>
      </div>
      {props.isOpen && (
        <IconButton
          className={classes.closeIcon}
          disabled={props.disabled}
          onClick={props.dismiss}
        >
          <Tooltip title="Dismiss" placement="bottom">
            <CloseIcon />
          </Tooltip>
        </IconButton>
      )}
    </div>
  );
};
