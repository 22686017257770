import React from "react";
import {connect} from "react-redux";
import get from "lodash/get";

import Grid from "@material-ui/core/Grid";

import FormHeader from "components/form-header";
import SaveCancelButtons from "components/save-cancel-buttons";
import PaperContainer from "components/paper-container";
import RadioGroup from "components/forms/radio-group";

import {Field, reduxForm, formValueSelector} from "redux-form";

import {saveReservoirInformation} from "actions/wells";

import parseReservoir from "@ambyint/common/parsers/well/parse-reservoir";

import compose from "utils/compose";
import forms from "utils/forms";
import getUser from "utils/get-user";
import spreadIf from "utils/spread-if";

import validate from "./validate";

const selector = formValueSelector("reservoir-information-edit-view");
const mapStateToProps = (state, props) => {
  const reservoir =
    props.well.reservoir ||
    parseReservoir({reservoir: {}}, getUser().unitsOfMeasure);

  const downholeLocation = get(props.well, "downholeLocation");
  const driveType = get(reservoir, "driveType");
  const staticReservoirPressure = get(reservoir, "staticReservoirPressure");
  const bubblePointPressure = get(reservoir, "bubblePointPressure");
  const oilDensity = get(reservoir, "oilDensity");
  const waterDensity = get(reservoir, "waterDensity");
  const gasDensity = get(reservoir, "gasDensity");
  const oilProduction = get(reservoir, "referenceProduction.oilProduction");
  const waterProduction = get(reservoir, "referenceProduction.waterProduction");
  const gasProduction = get(reservoir, "referenceProduction.gasProduction");
  const referencePBHP = get(reservoir, "referenceProduction.referencePBHP");

  return {
    ...spreadIf(props.well, {
      initialValues: {
        driveType,
        downholeLocation,
        staticReservoirPressure,
        bubblePointPressure,
        oilDensity,
        waterDensity,
        gasDensity,
        referenceProduction: {
          oilProduction,
          waterProduction,
          gasProduction,
          referencePBHP,
        },
      },
    }),
    currentValues: {
      driveType: selector(state, "driveType"),
    },
  };
};

class ReservoirInformationEditView extends React.Component {
  save = (values, func, props) => {
    if (Object.keys(props.syncErrors).length > 0) {
      return;
    }

    if (values.driveType !== "unknown") {
      delete values.bubblePointPressure;
    }

    this.props.dispatch(
      saveReservoirInformation({
        ...values,
        wellId: this.props.well.wellId,
        downholeLocation: this.props.well.downholeLocation,
      }),
    );

    this.props.onClose();
  };

  render() {
    const {handleSubmit, onClose, pristine, submitting, reset} = this.props;

    return (
      <PaperContainer extraPadded style={{maxWidth: "600px"}}>
        <form onSubmit={handleSubmit(this.save)}>
          <Grid container direction={"row"} style={{maxWidth: 776}} spacing={2}>
            <Grid item xs={12}>
              <FormHeader>Reservoir</FormHeader>
            </Grid>

            <Grid item xs={12} style={{padding: "0 24px 0 8px"}}>
              <RadioGroup
                name="driveType"
                label="Drive Type"
                options={[
                  {value: "water_drive", label: "Water Drive"},
                  {value: "solution_gas_drive", label: "Solution Gas Drive"},
                  {value: "unknown", label: "Unknown"},
                ]}
                style={{
                  flexDirection: "row",
                }}
                itemStyle={{
                  marginRight: 48,
                }}
              />
            </Grid>

            <Grid
              item
              xs={this.props.currentValues.driveType === "unknown" ? 6 : 12}
              style={{paddingRight: 24}}
            >
              <Field
                name="staticReservoirPressure"
                label={"Static Reservoir Pressure"}
                required
                component={forms.renderMaskedTextField}
                maskPattern="Decimal"
                fullWidth
              />
            </Grid>

            {this.props.currentValues.driveType === "unknown" && (
              <Grid item xs={6} style={{paddingRight: 24}}>
                <Field
                  name="bubblePointPressure"
                  label={"Bubble Point Pressure"}
                  required
                  component={forms.renderMaskedTextField}
                  maskPattern="Decimal"
                  fullWidth
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <FormHeader>Density</FormHeader>
            </Grid>

            <Grid item xs={6} style={{paddingRight: 24}}>
              <Field
                name="gasDensity"
                label={"Gas Density"}
                required
                component={forms.renderMaskedTextField}
                maskPattern="Decimal"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} style={{paddingRight: 24}}>
              <Field
                name="oilDensity"
                label={"Oil Density"}
                required
                component={forms.renderMaskedTextField}
                maskPattern="Decimal"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} style={{paddingRight: 24}}>
              <Field
                name="waterDensity"
                label={"Water Density"}
                required
                component={forms.renderMaskedTextField}
                maskPattern="Decimal"
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <FormHeader>Reference Production</FormHeader>
            </Grid>

            <Grid item xs={6} style={{paddingRight: 24}}>
              <Field
                name="referenceProduction.gasProduction"
                label={"Gas Production"}
                required
                component={forms.renderMaskedTextField}
                maskPattern="Decimal"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} style={{paddingRight: 24}}>
              <Field
                name="referenceProduction.oilProduction"
                label={"Oil Production"}
                required
                component={forms.renderMaskedTextField}
                maskPattern="Decimal"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} style={{paddingRight: 24}}>
              <Field
                name="referenceProduction.waterProduction"
                label={"Water Production"}
                required
                component={forms.renderMaskedTextField}
                maskPattern="Decimal"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} style={{paddingRight: 24}}>
              <Field
                name="referenceProduction.referencePBHP"
                label={"Reference PBHP"}
                required
                component={forms.renderMaskedTextField}
                maskPattern="Decimal"
                fullWidth
              />
            </Grid>
          </Grid>

          <SaveCancelButtons
            pristine={pristine}
            reset={compose(
              onClose,
              reset,
            )}
            submitting={submitting}
          />
        </form>
      </PaperContainer>
    );
  }
}

const Component = reduxForm({
  form: "reservoir-information-edit-view",
  enableReinitialize: true,
  validate,
})(ReservoirInformationEditView);

export default connect(mapStateToProps)(Component);
