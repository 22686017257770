import last from "lodash/last";

const getPending = shutdownPeriods => {
  const lastPeriod = last(shutdownPeriods);

  return lastPeriod && !lastPeriod.end ? lastPeriod : null;
};

export default ({runningStatusData = []}) =>
  runningStatusData.reduce((shutdownPeriods, runningStatus) => {
    const pending = getPending(shutdownPeriods);
    const isShutdownStatus = !runningStatus.value;

    if (isShutdownStatus && !pending) {
      shutdownPeriods.push({
        start: runningStatus.createdOn,
        end: null,
      });
    } else if (!isShutdownStatus && pending) {
      pending.end = runningStatus.createdOn;
    }

    return shutdownPeriods;
  }, []);
