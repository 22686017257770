import {isAnomalyWell} from "utils/well/is-anomaly-well";

export const splitWells = wells => {
  const anomalyWells = [];
  const otherWells = [];
  wells.forEach(well =>
    isAnomalyWell(well) ? anomalyWells.push(well) : otherWells.push(well),
  );
  return {anomalyWells, otherWells};
};
