import parseEvent from "@ambyint/common/parsers/well/events/parse-event";

import {setEvents} from "actions/wells";
import getUser from "utils/get-user";
import store from "store";

import {getWell} from "./get-well";

export const wellEventAdded = event => {
  const {activeDownholeLocation} = store.getState().wells;

  if (activeDownholeLocation !== event.downholeLocation) {
    return;
  }

  const well = getWell(event.downholeLocation);

  if (well) {
    const {unitsOfMeasure} = getUser();
    const parsed = parseEvent(unitsOfMeasure)(event);

    if (parsed) {
      store.dispatch(
        setEvents({
          downholeLocation: event.downholeLocation,
          data: [parsed],
        }),
      );
    }
  }
};
