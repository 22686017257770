import "react-vis/dist/style.css";
import {connect} from "react-redux";
import {Treemap} from "react-vis";
import {SizeMe} from "react-sizeme";
import React from "react";
import sortBy from "lodash/sortBy";

import colors from "@ambyint/common/colors";

import {eventCategory, eventAction} from "constants/analytics";
import {anomalyDisplayName} from "parsers/anomaly-display-name";
import {sendAnalyticsEvent} from "components/analytics";
import {setActiveAnomaly} from "actions/wells";

const colorArr = ["#27355C", "#304171", "#394D85", "#42599A", "#4A64AE"];

const getAnomalyCount = wells => {
  let data = [];

  for (let i = 0; i < wells.length; i++) {
    if (wells[i].dailyAnomalySummaries.length) {
      for (let n = 0; n < wells[i].dailyAnomalySummaries.length; n++) {
        let anomalyType = wells[i].dailyAnomalySummaries[n].type;

        const exists = data.find(type => type.title === anomalyType);

        if (!exists) {
          data.push({
            title: anomalyType,
            size: 1,
          });
        } else {
          exists.size++;
        }
      }
    }
  }

  return data;
};

const prepareData = (anomalyCount, activeAnomaly) => {
  const sorted = sortBy(anomalyCount, "size").reverse();

  const result = {
    children: [],
  };

  const colorMap = {};

  for (let i = 0; i < sorted.length; i++) {
    const current = sorted[i];

    if (!colorMap[current.size]) {
      colorMap[current.size] = colorArr[Object.keys(colorMap).length];
    }

    const anomaly = {
      title: anomalyDisplayName(current.title),
      type: current.title,
      color:
        current.title === activeAnomaly
          ? colors.ambyOrange[500]
          : colorMap[current.size],
      size: current.size,
      style: {cursor: "pointer"},
    };

    result.children.push(anomaly);
  }

  return result;
};

const mapStateToProps = ({wells}) => ({
  activeAnomaly: wells.activeAnomaly,
});

const mapDispatchToProps = dispatch => ({
  setAnomaly: anomaly => dispatch(setActiveAnomaly({anomaly})),
});

const handleClick = (leafNode, activeAnomaly, setAnomaly) => {
  const selectedAnomaly = leafNode.data.type;

  sendAnalyticsEvent(
    eventCategory.anomalyWorkflow,
    eventAction.treemap,
    selectedAnomaly,
  );

  if (selectedAnomaly === activeAnomaly) {
    setAnomaly(null);
  } else {
    setAnomaly(selectedAnomaly);
  }
};

const TreemapChart = ({wells, activeAnomaly, setAnomaly}) => {
  const anomalyCount = getAnomalyCount(wells);

  const preparedData = prepareData(anomalyCount, activeAnomaly);

  return (
    <SizeMe>
      {({size}) => {
        return (
          <div>
            <Treemap
              hideRootNode={true}
              colorType="literal"
              height={size.width || 0}
              width={size.width || 0}
              padding={10}
              mode="circlePack"
              renderMode="DOM"
              onLeafClick={leafNode =>
                handleClick(leafNode, activeAnomaly, setAnomaly)
              }
              data={preparedData}
              style={{textTransform: "capitalize"}}
            />
          </div>
        );
      }}
    </SizeMe>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TreemapChart);
