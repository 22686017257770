import React from "react";
import {connect} from "react-redux";
import {push} from "react-router-redux";
import {reduxForm} from "redux-form";

import Grid from "@material-ui/core/Grid";

import Divider from "components/divider";
import FormButton from "components/form-button";
import FormError from "components/form-error";
import PasswordTextField from "components/forms/password-text-field";
import TextField from "components/forms/text-field";
import withHelmet from "components/with-helmet";

import LoginContainer from "routes/login/login-container";
import {signInWithAzure, signInWithEmail, signInWithGoogle} from "actions/auth";

import LOCAL_STORAGE_KEYS from "constants/local-storage-keys";

import styles from "routes/login/styles";
import validate from "routes/login/validate";

const mapStateToProps = state => {
  const email = localStorage.getItem(
    LOCAL_STORAGE_KEYS.LAST_SIGN_IN_EMAIL_ADDRESS,
  );

  return {
    auth: state.auth,
    initialValues: {
      email,
    },
  };
};

const Login = props => {
  const {auth, dispatch, handleSubmit} = props;

  const submitSignInWithEmail = handleSubmit(values => {
    dispatch(signInWithEmail(values));
  });

  return (
    <LoginContainer>
      <form style={styles.loginContainer}>
        <Grid container direction={"row"} spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="email"
              autoComplete="email"
              autoFocus
              fullWidth
              onKeyDown={e => {
                if (e.key === "Enter") {
                  submitSignInWithEmail();
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordTextField
              name="password"
              fullWidth
              onKeyDown={e => {
                if (e.key === "Enter") {
                  submitSignInWithEmail();
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormButton
              variant={"contained"}
              center
              fullWidth
              color="primary"
              style={{color: "white", height: "40px"}}
              onClick={submitSignInWithEmail}
            >
              Sign In
            </FormButton>
          </Grid>
          <Grid item xs={12}>
            <FormButton
              center
              fullWidth
              style={{height: "40px"}}
              onClick={() => dispatch(push("/password-reset"))}
            >
              Forgot Password?
            </FormButton>
          </Grid>
          <Grid item xs={12}>
            <Divider text="or" />
          </Grid>
          <Grid item xs={12}>
            <FormButton
              center
              fullWidth
              style={{height: "40px"}}
              onClick={() => dispatch(signInWithGoogle())}
            >
              Sign In With Google
            </FormButton>
          </Grid>
          <Grid item xs={12}>
            <FormButton
              center
              fullWidth
              style={{height: "40px"}}
              onClick={() => dispatch(signInWithAzure())}
            >
              Sign In With Azure
            </FormButton>
          </Grid>
          {auth.error && (
            <Grid item xs={12}>
              <FormError solid>{auth.error}</FormError>
            </Grid>
          )}
        </Grid>
      </form>
      <footer style={styles.footer}>
        Need an invite or having trouble?{" "}
        <a
          style={styles.link}
          href={"mailto:support@ambyint.com?Subject=Login Assistance"}
        >
          Get in touch with us
        </a>
      </footer>
    </LoginContainer>
  );
};

const Component = reduxForm({
  form: "login",
  enableReinitialize: true,
  validate,
})(Login);

export default withHelmet({title: "Login"})(
  connect(mapStateToProps)(Component),
);
