import React from "react";
import Axis from "utils/charts/add-axis";

export default props => {
  const {height, width, margins, xScale, yScale} = props;

  const xProps = {
    orient: "bottom",
    scale: xScale,
    translate: `translate(0, ${height - margins.bottom})`,
    tickSize: 5,
  };

  const yProps = {
    orient: "left",
    scale: yScale,
    translate: `translate(${margins.left}, 0)`,
    tickSize: width - margins.left - margins.right,
  };

  return (
    <g>
      <Axis {...xProps} />
      <Axis {...yProps} />
    </g>
  );
};
