import React from "react";
import clsx from "clsx";

import styles from "./container.module.css";

export default ({children, noPadding, className: classNameProps, ...rest}) => (
  <div
    className={clsx(
      styles.container,
      {
        [styles["no-padding"]]: noPadding,
      },
      classNameProps,
    )}
    {...rest}
  >
    {children}
  </div>
);
