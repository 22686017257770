import React from "react";
import Typography from "@material-ui/core/Typography";
import noop from "lodash/noop";

import {useKeyboardNavigation} from "hooks/use-keyboard-navigation";

import SearchResult from "./search-result";

const SearchResults = ({
  results,
  onClick,
  isRecentlyVisited,
  hideTags,
  preventDefaultSelection = false,
}) => {
  const {
    selectedResult,
    scrollStart,
    setSelectedResults,
  } = useKeyboardNavigation(results, onClick, preventDefaultSelection);

  if (results.length) {
    return (
      <div>
        {results.map(({data}, i) => (
          <SearchResult
            key={data.displayName}
            displayName={data.displayName}
            tags={data.tags}
            hideTags={hideTags}
            onClick={() => onClick(data)}
            isRecentlyVisited={isRecentlyVisited}
            selected={i === selectedResult}
            scrollStart={scrollStart}
            onHover={i !== selectedResult ? () => setSelectedResults(i) : noop}
          />
        ))}
      </div>
    );
  }

  return (
    <Typography variant="body2" style={{paddingTop: 15, paddingLeft: 24}}>
      There are no results matching your search
    </Typography>
  );
};

export default SearchResults;
