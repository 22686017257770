import integer from "utils/validation/integer";
import max from "utils/validation/max";
import min from "utils/validation/min";
import number from "utils/validation/number";

const MAX_MINUTES_DEFAULT = 60 * 24;
const MIN_MINUTES_DEFAULT = 5;

const validateMinutes = (
  minutes,
  maxMinutes = MAX_MINUTES_DEFAULT,
  minMinutes = MIN_MINUTES_DEFAULT,
) => {
  if (number(minutes)) return number(minutes);
  if (min(minutes, minMinutes)) return min(minutes, minMinutes);
  if (max(minutes, maxMinutes)) return max(minutes, maxMinutes);
  if (integer(minutes)) return integer(minutes);

  return null;
};

export default values => {
  const {enabled, maxStoptime, minStoptime, runTime, stopTime} = values;

  if (!enabled) return {};

  return {
    ...(validateMinutes(runTime) ? {runTime: validateMinutes(runTime)} : {}),
    ...(validateMinutes(stopTime, maxStoptime, minStoptime)
      ? {stopTime: validateMinutes(stopTime, maxStoptime, minStoptime)}
      : {}),
  };
};
