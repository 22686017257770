export const types = {
  toggle: "app-drawer:toggle",
};

export const toggle = ({isOpen}) => {
  return {
    type: types.toggle,
    payload: {
      isOpen,
    },
  };
};
