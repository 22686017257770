import React from "react";
import Paper from "@material-ui/core/Paper";

import colors from "theme/colors";

const PaperContainer = props => {
  const {children, extraPadded, padded, style, ...otherProps} = props;

  return (
    <Paper
      elevation={0}
      style={{
        border: `1px solid ${colors.grey[300]}`,
        ...(padded ? {padding: 16} : {}),
        ...(extraPadded ? {padding: 32} : {}),
        ...style,
      }}
      {...otherProps}
    >
      {children}
    </Paper>
  );
};

export default PaperContainer;
