import {
  types,
  disableUselessTags as disableUselessTagsAction,
} from "actions/tagging";
import {createSwitchEpic} from "epics/create-epic";

export const disableUselessTags = createSwitchEpic(
  [types.tagsHydrated],
  async (_, store) => {
    const {
      wells: {filteredByTag},
    } = store.getState();

    return [disableUselessTagsAction(filteredByTag)];
  },
);
