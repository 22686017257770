import React from "react";
import {connect} from "react-redux";
import {push} from "react-router-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

import {completeSignInWithOAuth} from "actions/auth";

import styles from "routes/login/styles";

class Login extends React.Component {
  componentWillMount() {
    try {
      const {
        match: {
          params: {clientUserId, callbackToken},
        },
      } = this.props;

      this.props.dispatch(
        completeSignInWithOAuth({
          clientUserId,
          callbackToken,
        }),
      );
    } catch (err) {
      console.error(err);

      this.props.dispatch(
        push(`/error?next=${this.props.location.pathname}&code=403`),
      );
    }
  }

  render() {
    return (
      <div style={styles.pageContainer}>
        <CircularProgress style={styles.progress} size={15} />
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps)(Login);
