import {types} from "actions/well-tickets";

const defaultState = {
  lastDownholeLocation: null,
};

export default (state = defaultState, action) => {
  if (action.type === types.prefillForm) {
    return {
      ...state,
      lastDownholeLocation: action.payload.well.downholeLocation,
    };
  }

  return state;
};
