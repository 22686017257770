import React from "react";

const Thumb = ({enableHover, disableHover}) => props => {
  return (
    <span onMouseOver={enableHover} onMouseLeave={disableHover}>
      <span {...props} />
    </span>
  );
};

export default Thumb;
