import {connect} from "react-redux";
import {sort} from "actions/wells";

export const mapDispatchToProps = dispatch => ({
  doSort: (sortBy, sortAsc) => dispatch(sort({sortBy, sortAsc})),
});

export const mapStateToProps = ({wells}) => ({
  sortAsc: wells.sorting.sortAsc,
  sortBy: wells.sorting.sortBy,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
);
