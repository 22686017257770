import React from "react";
import {connect} from "react-redux";
import {reduxForm} from "redux-form";

import hasWellPilot from "@ambyint/common/utils/devices/has-well-pilot";
import Grid from "@material-ui/core/Grid";

import {setAnalogInputConfiguration} from "actions/wells";
import FormHeader from "components/form-header";
import PaperContainer from "components/paper-container";
import SaveCancelButtons from "components/save-cancel-buttons";
import TextFieldListMenu from "components/forms/text-field-list-menu";
import validate from "./validate";

const availableInputs = well => {
  const maxInput = hasWellPilot(well.devices) ? 8 : 16;
  const inputs = [
    {
      value: 0,
      text: "Not Used",
    },
  ];

  for (let i = 1; i < maxInput + 1; i++) {
    inputs.push({
      value: i,
      text: `AI${i}`,
    });
  }

  return inputs;
};

const mapStateToProps = (state, props) => {
  const {well} = props;

  return {
    initialValues: {
      casing: well.analogInput ? well.analogInput.casing : 0,
      tubing: well.analogInput ? well.analogInput.tubing : 0,
    },
  };
};

const EditView = props => {
  const {dispatch, handleSubmit, onDone, submitting, well} = props;

  const save = values => {
    dispatch(
      setAnalogInputConfiguration({
        analogInputs: values,
        downholeLocation: well.downholeLocation,
        wellId: well.wellId,
      }),
    );

    return onDone();
  };

  return (
    <PaperContainer extraPadded style={{maxWidth: "500px"}}>
      <form onSubmit={handleSubmit(save)}>
        <Grid
          container
          direction={"column"}
          style={{minWidth: 400, maxWidth: 720}}
        >
          <Grid item xs={12}>
            <FormHeader style={{marginTop: "0px", marginBottom: "0px"}}>
              Analog Input Configuration
            </FormHeader>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextFieldListMenu
              name={"casing"}
              label={"Casing Analog Input"}
              options={availableInputs(well)}
              fullWidth
              hideEmptyOption
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldListMenu
              name={"tubing"}
              label={"Tubing Analog Input"}
              options={availableInputs(well)}
              fullWidth
              hideEmptyOption
            />
          </Grid>
        </Grid>
        <SaveCancelButtons onClose={onDone} submitting={submitting} />
      </form>
    </PaperContainer>
  );
};

const Component = reduxForm({
  form: "analog-input-configuration-form",
  enableReinitialize: true,
  validate,
})(EditView);

export default connect(mapStateToProps)(Component);
