import {
  moveRecAnimation,
  recommendationKeyframes,
} from "./recommendation-movement";

export default theme => ({
  body: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
    cursor: "pointer",
  },
  buttonContained: {
    alignSelf: "center",
    color: "#fff",
    backgroundColor: theme.colors.blue[600],
    transition: "300ms background-color",
    padding: [[4, 10]],
    "&:hover": {
      backgroundColor: theme.colors.blue[500],
    },
  },
  buttonGroup: {
    marginLeft: -theme.spacing(1),
    display: "flex",
    alignItems: "center",
    "& > *": {
      padding: theme.spacing(1),
      color: theme.colors.warmGrey[500],
      "&:hover": {
        backgroundColor: theme.colors.warmGrey[100],
      },
    },
  },
  displayName: {
    color: "inherit",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "block",
  },
  closeIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    padding: theme.spacing(1),
    margin: theme.spacing(1) / 2,
    color: "inherit",
    "& svg": {
      height: theme.spacing(2),
      width: theme.spacing(2),
    },
  },
  date: {
    backgroundColor: "inherit",
    height: 40,
    width: 40,
    borderRadius: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 8,
    "& > *:first-child": {
      fontSize: 8,
      textTransform: "uppercase",
    },
    "& > *:last-child": {
      fontWeight: 700,
      lineHeight: 1,
    },
  },
  divider: {
    height: 2,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  flexStart: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end",
  },
  footer: {
    position: "relative",
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  footerOverlayDown: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    boxSizing: "border-box",
    top: "100%",
    width: "100%",
    height: "100%",
    position: "absolute",
    transition: [["all", ".3s", "ease-out"]],
    backgroundColor: theme.colors.warmGrey[50],
    borderRadius: theme.spacing(2, 2, 0, 0),
    padding: theme.spacing(0, 2),
    margin: -theme.spacing(2),
    marginTop: 0,
    textAlign: "right",
    "& > *": {
      color: theme.colors.warmGrey[700],
      padding: 4,
      marginTop: "100%",
      transition: [["all", "1s", "ease-out"]],
    },
  },
  footerOverlayUp: {
    top: 0,
    "& > *": {
      marginTop: 0,
    },
  },
  infoIcon: {
    padding: 0,
  },
  header: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    padding: 12,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    transition: [["300ms", "background-color"]],
  },
  headerText: {
    width: "calc(100% - 48px)",
  },
  title: {
    color: "white",
  },
  alignmentColors: {
    "& > $header": {
      backgroundColor: theme.colors.warmGrey[100],
      color: theme.colors.warmGrey[600],
    },
    "&:hover > $header": {
      backgroundColor: theme.colors.warmGrey[50],
    },
    "& $date": {
      backgroundColor: theme.colors.warmGrey[500],
      color: "#fff",
    },
    "& $title": {
      color: theme.colors.warmGrey[900],
    },
    "& $closeIcon": {
      color: theme.colors.warmGrey[500],
    },
  },
  classificationColors: {
    "& > $header": {
      backgroundColor: theme.colors.blue[600],
    },
    "&:hover > $header": {
      backgroundColor: theme.colors.blue[500],
    },
    color: theme.colors.blue[50],
    "& $date": {
      backgroundColor: "#fff",
      color: theme.colors.blue[600],
    },
    "& $title": {
      color: "#fff",
    },
  },
  grey: {
    color: theme.colors.warmGrey[700],
  },
  lightGrey: {
    color: theme.colors.warmGrey[400],
  },
  rec: {
    position: "relative",
    transition: [
      ["box-shadow", "300ms"],
      ["opacity", "300ms"],
      ["transform", "300ms"],
    ],
    willChange: "transform",
    "&:hover": {
      boxShadow: theme.shadows[8],
    },
  },
  ...recommendationKeyframes,
  moveRec: {
    ...moveRecAnimation(5),
    [theme.breakpoints.only("md")]: moveRecAnimation(3),
    [theme.breakpoints.only("lg")]: moveRecAnimation(4),
  },
  unitContainer: {
    "&:not(:last-child)": {
      paddingRight: theme.spacing(1),
    },
    "& .unit": {
      fontSize: 14,
    },
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  unitName: {
    color: theme.colors.warmGrey[400],
    fontWeight: 600,
  },
  withAutomation: {
    margin: 0,
    marginLeft: theme.spacing(1),
    color: theme.colors.warmGrey[700],
    "& > *": {
      padding: 0,
      paddingRight: theme.spacing(1) / 2,
    },
  },
});
