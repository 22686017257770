import {FlexibleXYPlot} from "react-vis/dist/make-vis-flexible";
import AreaSeries from "react-vis/dist/plot/series/area-series";
import LineSeries from "react-vis/dist/plot/series/line-series";
import React from "react";

import colors from "theme/colors";

const OverviewChart = ({activeSeries, gas, lastDrawLocation, oil, water}) => {
  return (
    <div
      style={{
        borderRadius: 4,
        background: "#f5f5f5",
        display: "inline-block",
        padding: 5,
        opacity: 0.8,
      }}
    >
      <FlexibleXYPlot
        animation
        xType="time"
        height={100}
        width={200}
        margin={5}
      >
        {activeSeries.gas && (
          <LineSeries
            data={gas}
            colorType="literal"
            color={colors.red[400]}
            getNull={d => d.y !== null}
          />
        )}

        {activeSeries.water && (
          <LineSeries
            data={water}
            colorType="literal"
            color={colors.blue[400]}
            getNull={d => d.y !== null}
          />
        )}

        {activeSeries.oil && (
          <LineSeries
            data={oil}
            colorType="literal"
            color={colors.green[400]}
            getNull={d => d.y !== null}
          />
        )}

        {lastDrawLocation && (
          <AreaSeries
            color="rgba(80, 88, 232, 0.3)"
            data={[
              {x: lastDrawLocation.left, y: lastDrawLocation.top},
              {x: lastDrawLocation.right, y: lastDrawLocation.top},
              {x: lastDrawLocation.right, y: lastDrawLocation.bottom},
              {x: lastDrawLocation.left, y: lastDrawLocation.bottom},
            ]}
          />
        )}
      </FlexibleXYPlot>
    </div>
  );
};

export default OverviewChart;
