import makeStyles from "@material-ui/core/styles/makeStyles";

export const useModeStyles = makeStyles(theme => ({
  body: {
    padding: theme.spacing(4),
    whiteSpace: "nowrap",
    color: theme.colors.warmGrey[500],
    display: "grid",
    rowGap: `${theme.spacing(3)}px`,
  },
  variableSpeed: {
    gridTemplateColumns: "20px 110px 130px auto",
    gridTemplateRows: "90px 90px 20px 100px",
  },
  pumpFillage: {
    gridTemplateColumns: "20px 110px auto auto",
    gridTemplateRows: "90px 20px 100px",
  },
  noPadding: {
    padding: 0,
  },
  blurb: {
    whiteSpace: "break-spaces",
    width: 200,
    display: "inline-block",
    paddingLeft: theme.spacing(2),
  },
  checkbox: {
    verticalAlign: "top",
    marginLeft: -theme.spacing(1),
  },
  chip: {
    float: "unset",
    padding: theme.spacing(1),
  },
  input: {
    width: 120,
  },
  timeInput: {
    width: 140,
  },
  inputPadding: {
    padding: [[10, 14]],
  },
  spanTwo: {
    gridColumn: "1 / span 2",
  },
  spanThree: {
    gridColumn: "1 / span 3",
  },
  spanFour: {
    gridColumn: "1 / span 4",
  },
  tab: {
    padding: 0,
    minWidth: 120,
    height: 40,
  },
  tabSelected: {
    color: theme.colors.warmGrey[600],
  },
  label: {
    padding: theme.spacing(0.5),
    paddingLeft: 0,
    "&:last-child": {
      marginLeft: 14,
    },
  },
  alignToCheckbox: {
    paddingLeft: 42,
  },
  alignToBottom: {
    marginTop: "auto",
  },
  save: {
    width: 80,
    color: "white",
  },
  helperText: {
    whiteSpace: "nowrap",
  },
  cancel: {
    width: 80,
    color: theme.colors.warmGrey[600],
  },
  buttonGroup: {
    gridRowStart: 4,
    gridRowEnd: 5,
    gridColumnStart: 1,
    gridColumnEnd: 4,
  },
  required: {
    marginTop: "auto",
  },
}));
