import {connect} from "react-redux";
import React from "react";
import TableRow from "components/table-row";
import {bySelectedWells} from "utils/tagging/by-selected-wells";

const WellTableRow = ({
  wellId, // Consuming with destructure so it does not spread into MUI TableRow
  dispatch, // Consuming with destructure so it does not spread into MUI TableRow
  ...props
}) => <TableRow {...props} />;

const mapStateToProps = ({tags}, {wellId}) => ({
  isSelected: bySelectedWells(tags.selectedWells)(wellId),
});

export default connect(mapStateToProps)(WellTableRow);
