import {of} from "rxjs/observable/of";

import {navigateToLogin, setAuth, types} from "actions/auth";
import {show} from "actions/snackbar";
import addAuthHeaders from "epics/add-auth-headers";
import fetchApi from "epics/fetch";

export default action$ => {
  return action$.ofType(types.verifyEmail).switchMap(({payload}) => {
    const {
      firstName,
      inviteKey,
      lastName,
      mobilePhone,
      notificationMethods,
      password,
      type,
      unitsOfMeasure,
    } = payload;

    return fetchApi(
      `/client-experience/user/verify/${encodeURIComponent(inviteKey)}`,
      {},
      addAuthHeaders({
        body: {
          firstName,
          inviteKey,
          lastName,
          mobilePhone,
          notificationMethods,
          password,
          type,
          unitsOfMeasure,
        },
        method: "PUT",
      }),
    )
      .flatMap(res => {
        if (res.status >= 200 && res.status <= 300) {
          return [
            show({
              message:
                "Your account has been created! Please sign in with your email address.",
            }),
            navigateToLogin({}),
          ];
        }

        return of(
          setAuth({
            isAuthenticated: false,
            isFetching: false,
          }),
        );
      })
      .catch(() => {
        return of(
          setAuth({
            isAuthenticated: false,
            isFetching: false,
            error:
              "Accepting terms failed. Please try again, or contact support if the issue continues.",
          }),
        );
      });
  });
};
