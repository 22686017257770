import {getStatuses} from "@ambyint/common/well-status";
import ALERT_TYPES from "constants/alert-types";

export default alert => {
  const statuses = getStatuses(alert.information.statusCodes);

  if (alert.type === "lufkinStatusCode") {
    const status = statuses[0];
    return status.code > -1 ? [status.code] : [status.description];
  }

  if (statuses && statuses.length > 0) {
    return statuses.map(status => status.description);
  }

  return [ALERT_TYPES[alert.type] || alert.type];
};
