import isEqual from "lodash/isEqual";

import {filter as filterAlerts} from "actions/alerts";
import {replace} from "actions/history";
import {setSearchCriteria} from "actions/wells";
import {updateFilter} from "actions/paging";
import mergeQuerystrings from "utils/merge-querystrings";
import PAGING_SOURCES from "constants/paging-sources";

const updateQuerystringInUrl = ({
  filter,
  hasInitialized,
  existingQuerystring,
  newQuerystring,
  source,
  state,
}) => {
  const sourceHasChangedFromInitial =
    hasInitialized &&
    newQuerystring.source != null &&
    state.paging.source !== newQuerystring.source;
  const querystringHasChanged = !isEqual(existingQuerystring, newQuerystring);

  if (
    !sourceHasChangedFromInitial &&
    querystringHasChanged &&
    (newQuerystring.source == null || newQuerystring.filter == null)
  ) {
    const path = mergeQuerystrings(state, {
      filter,
      source: source || PAGING_SOURCES.inbox,
    });

    return replace({path});
  }
};

const applyFilterToSource = ({
  existingQuerystring,
  filter,
  newQuerystring,
  source,
  state,
}) => {
  if (
    source === PAGING_SOURCES.alerts &&
    state.alerts.activeFilter !== filter
  ) {
    return filterAlerts({filter});
  } else if (source === PAGING_SOURCES.inbox && !state.wells.search.criteria) {
    return setSearchCriteria({criteria: filter});
  }
};

const saveFilterToReducer = ({filter, source, state}) => {
  if (
    source != null &&
    (state.paging.source !== source || state.paging.filter !== filter)
  ) {
    return updateFilter({filter, source});
  }
};

export default data => {
  return [
    updateQuerystringInUrl(data),
    applyFilterToSource(data),
    saveFilterToReducer(data),
  ].filter(action => !!action);
};
