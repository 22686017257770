import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import compose from "lodash/fp/compose";
import {connect} from "react-redux";

const styles = () => ({
  container: {
    marginTop: "9em",
    marginBottom: "7em",
    marginLeft: "calc(100vw - 100%)", // Fix windows scrollbar jumping
  },
});

const mapStateToProps = ({auth: {user}}) => ({user});

const enhance = compose(
  connect(mapStateToProps),
  withStyles(styles),
);

const PageContainer = ({children, user, classes, style, className = ""}) => (
  <div style={style} className={clsx(classes.container, className)}>
    {children}
  </div>
);

export default enhance(PageContainer);
