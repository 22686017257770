import React, {useState} from "react";
import {connect} from "react-redux";
import {KeyboardTimePicker} from "@material-ui/pickers";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import get from "lodash/get";
import InputAdornment from "@material-ui/core/InputAdornment";
import isNumber from "lodash/isNumber";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

import {
  savePumpoffSettings,
  saveSlowSpeedOffTime,
  setSpeedRange,
} from "actions/wells";
import {secondsSince12am} from "utils/time/seconds-since-12am";
import PUMPOFF_SETTING_MODES from "constants/pumpoff-setting-modes";
import StatusIndicatorChip from "components/status-indicator-chip";

import {validateSpeedRange, validateDowntime} from "./validate";
import {useModeStyles} from "./use-mode-styles";
import {useDowntime} from "./use-downtime";

const VariableSpeed = props => {
  const classes = useModeStyles();
  const {
    downtime,
    downtimeEnabled,
    downtimeEnabledHandler,
    downtimeRef,
    pending,
    setDowntime,
  } = useDowntime(props.well);
  const [fillage, setFillage] = useState(
    get(props, "well.pumpOffSetting.primaryFillage.value"),
  );
  const [max, setMax] = useState(
    get(props, "well.operation.speedRange.max.value"),
  );
  const [min, setMin] = useState(
    get(props, "well.operation.speedRange.min.value"),
  );

  const genericHandler = handler => ({target: {value}}) => {
    if (value === "") return handler(value);
    if (value >= 0 && value <= 100) return handler(Number(value));
    value > 100 && handler(100);
  };
  const speedRangeErr = validateSpeedRange(
    min,
    max,
    props.well.safeOperatingParameters,
  );
  const downtimeErr = validateDowntime(
    downtime,
    props.well.safeOperatingParameters,
  );
  const missingValue =
    !isNumber(fillage) || !isNumber(min) || !isNumber(max) || !downtime;
  const saveDisabled = !!speedRangeErr || !!downtimeErr || missingValue;

  const save = () => {
    const {downholeLocation, pumpOffSetting} = props.well;

    props.saveSlowSpeedOffTime({
      downholeLocation,
      offTime: secondsSince12am(downtime),
      enabled: downtimeEnabled,
    });
    props.setSpeedRange({
      downholeLocation,
      max,
      min,
      well: props.well,
    });
    props.savePumpoffSettings({
      downholeLocation,
      wellId: props.well.wellId,
      data: {
        ...(pumpOffSetting || {}),
        primaryFillage: {
          ...(get(pumpOffSetting, "primaryFillage") || {}),
          value: fillage,
        },
        mode: PUMPOFF_SETTING_MODES.VFD_DOWNHOLE,
      },
    });

    props.onDone();
  };

  return (
    <div className={clsx(classes.body, classes.variableSpeed)}>
      <div className={clsx(classes.spanTwo, classes.alignToBottom)}>
        <Typography variant="body2" className={classes.label}>
          Fillage*
        </Typography>
        <div>
          <TextField
            value={fillage}
            onChange={genericHandler(setFillage)}
            variant="outlined"
            error={!isNumber(fillage)}
            helperText=" "
            className={classes.input}
            FormHelperTextProps={{
              classes: {root: classes.helperText},
            }}
            InputProps={{
              classes: {input: classes.inputPadding},
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </div>
      </div>
      <div className={classes.alignToBottom}>
        <Typography variant="body2" className={classes.label}>
          Speed Range*
        </Typography>
        <div>
          <TextField
            className={classes.input}
            value={min}
            helperText={speedRangeErr || "Min"}
            error={!!speedRangeErr}
            onChange={genericHandler(setMin)}
            variant="outlined"
            InputProps={{
              classes: {input: classes.inputPadding},
              endAdornment: <InputAdornment position="end">spm</InputAdornment>,
            }}
          />
        </div>
      </div>
      <div className={classes.alignToBottom}>
        <div>
          <TextField
            className={classes.input}
            value={max}
            error={!!speedRangeErr}
            helperText={speedRangeErr ? " " : "Max"}
            onChange={genericHandler(setMax)}
            variant="outlined"
            InputProps={{
              classes: {input: classes.inputPadding},
              endAdornment: <InputAdornment position="end">spm</InputAdornment>,
            }}
          />
        </div>
      </div>
      <div className={clsx(classes.alignToBottom, classes.spanFour)}>
        <Typography
          variant="body2"
          className={clsx(classes.label, classes.alignToCheckbox)}
        >
          Downtime Duration
        </Typography>
        <div>
          <Checkbox
            color="primary"
            checked={downtimeEnabled}
            disabled={pending}
            onChange={downtimeEnabledHandler}
            className={classes.checkbox}
          />
          <KeyboardTimePicker
            ampm={false}
            autoOk
            className={classes.timeInput}
            openTo="minutes"
            disabled={!downtimeEnabled || pending}
            error={!!downtimeErr}
            helperText={downtimeErr || "hh:mm"}
            inputVariant="outlined"
            keyboardIcon={<AccessTimeIcon />}
            InputProps={{
              inputRef: downtimeRef,
              classes: {
                input: classes.inputPadding,
                adornedEnd: classes.noPadding,
              },
            }}
            PopoverProps={{
              anchorEl: downtimeRef.current,
              anchorOrigin: {horizontal: 155, vertical: "bottom"},
            }}
            onChange={val => setDowntime(val)}
            readOnly={!downtimeEnabled}
            value={downtime}
            variant="inline"
          />
          {pending ? (
            <StatusIndicatorChip
              request={get(props, "well.operation.slowSpeedOffTime")}
              className={classes.chip}
            />
          ) : (
            <span className={classes.blurb}>
              <Typography variant="body2">
                Communicating Downtime to POC can take up to 60 min.
              </Typography>
            </span>
          )}
        </div>
      </div>
      <Typography className={classes.required} variant="caption">
        *Required
      </Typography>
      <div className={clsx(classes.alignToBottom, classes.buttonGroup)}>
        <Button
          onClick={save}
          disabled={saveDisabled}
          className={clsx(classes.save, classes.spanTwo)}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
        <Button onClick={props.onDone} className={classes.cancel}>
          cancel
        </Button>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  saveSlowSpeedOffTime,
  setSpeedRange,
  savePumpoffSettings,
};

export default connect(
  null,
  mapDispatchToProps,
)(VariableSpeed);
