import React from "react";
import capitalize from "lodash/capitalize";

import Grid from "@material-ui/core/Grid";

import FormButton from "components/form-button";
import FormHeader from "components/form-header";
import PaperContainer from "components/paper-container";

import getPrivileges from "utils/get-privileges";
import privilegeTypes from "utils/privilege-types";

import ReservoirInformationEditView from "./reservoir-information-edit-view";

import {cardLabelFactory, cardValueFactory} from "../cards";

class ReservoirInformationReadView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {well} = this.props;

    const privileges = getPrivileges(well.organization.id);

    const reservoir = well.reservoir || {};

    if (this.state.showReservoirInformationEditView) {
      return (
        <ReservoirInformationEditView
          onClose={() =>
            this.setState({showReservoirInformationEditView: false})
          }
          well={well}
        />
      );
    }

    const CardLabel = cardLabelFactory(7);
    const CardValue = cardValueFactory(5);

    return (
      <PaperContainer extraPadded style={{maxWidth: "500px"}}>
        <Grid container>
          <Grid item xs={12} style={{marginTop: "0px", marginBottom: "0px"}}>
            <FormHeader>Reservoir Information</FormHeader>
          </Grid>

          <CardLabel label="Drive Type" />
          <CardValue
            value={
              reservoir.driveType &&
              reservoir.driveType
                .split("_")
                .map(capitalize)
                .join(" ")
            }
          />

          <CardLabel label={"Static Reservoir Pressure"} />
          <CardValue value={reservoir.staticReservoirPressure} />

          {reservoir.driveType === "unknown" && (
            <CardLabel label={"Bubble Point Pressure"} />
          )}
          {reservoir.driveType === "unknown" && (
            <CardValue value={reservoir.bubblePointPressure} />
          )}

          <CardLabel label={"Gas Density"} />
          <CardValue value={reservoir.gasDensity} />

          <CardLabel label={"Oil Density"} />
          <CardValue value={reservoir.oilDensity} />

          <CardLabel label={"Water Density"} />
          <CardValue value={reservoir.waterDensity} />

          <CardLabel label={"Reference Gas Production"} />
          <CardValue
            value={
              reservoir.referenceProduction &&
              reservoir.referenceProduction.gasProduction
            }
          />

          <CardLabel label={"Reference Oil Production"} />
          <CardValue
            value={
              reservoir.referenceProduction &&
              reservoir.referenceProduction.oilProduction
            }
          />

          <CardLabel label={"Reference Water Production"} />
          <CardValue
            value={
              reservoir.referenceProduction &&
              reservoir.referenceProduction.waterProduction
            }
          />

          <CardLabel label={"Reference PBHP"} />
          <CardValue
            value={
              reservoir.referenceProduction &&
              reservoir.referenceProduction.referencePBHP
            }
          />

          <Grid item xs={12}>
            {privileges.includes(privilegeTypes.well.configure) && (
              <FormButton
                onClick={() =>
                  this.setState({showReservoirInformationEditView: true})
                }
                style={{marginTop: "8px"}}
              >
                Edit Reservoir Information
              </FormButton>
            )}
          </Grid>
        </Grid>
      </PaperContainer>
    );
  }
}

export default ReservoirInformationReadView;
