import {updateAnalysis} from "actions/wells";
import moment from "moment";

export default props => range => {
  const {dispatch, well} = props;

  dispatch(
    updateAnalysis(well.downholeLocation, {
      range: {
        start: moment(range.start)
          .startOf("day")
          .toDate(),
        end: moment(range.end)
          .endOf("day")
          .toDate(),
      },
    }),
  );
};
