import math from "mathjs";
import valueOf from "utils/value-of";

const calculateTotalRodSectionsLength = sections =>
  sections &&
  math.round(
    sections
      .filter(section => valueOf(section.rodLength))
      .reduce(
        (prev, section) =>
          prev + parseFloat(valueOf(section.rodLength) * section.rodQuantity),
        0,
      ),
    2,
  );

export default calculateTotalRodSectionsLength;
