import {show} from "actions/snackbar";
import {types} from "actions/wells";
import fetch from "epics/fetch";

export default action$ => {
  return action$
    .ofType(types.setResetMalfunctions, types.setClearErrors)
    .switchMap(({type, payload}) => {
      const message =
        type === types.setResetMalfunctions
          ? `Issued a reset malfunctions command for ${
              payload.downholeLocation
            }.`
          : `Issued a clear errors command for ${payload.downholeLocation}.`;

      return fetch(
        `/optimization/well/${encodeURIComponent(
          payload.downholeLocation,
        )}/resetMalfunctions`,
        {},
        {
          method: "PUT",
          body: {
            downholeLocation: payload.downholeLocation,
          },
        },
      ).map(() => {
        return show({message});
      });
    });
};
