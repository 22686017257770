import {replace} from "react-router-redux";
import fetch from "epics/async-fetch";

import {createSwitchEpic} from "epics/create-epic";
import {setWebhookDeleted, types} from "actions/organizations";
import {show} from "actions/snackbar";

export default createSwitchEpic([types.deleteWebhook], async ({payload}) => {
  try {
    const {clientUserId, organizationId, webhookId} = payload;

    await fetch(
      `/organization/${encodeURIComponent(
        organizationId,
      )}/serviceAccount/${encodeURIComponent(
        clientUserId,
      )}/webhook/${encodeURIComponent(webhookId)}`,
      {},
      {
        method: "DELETE",
      },
    );

    return [
      show({
        message: "Your request to delete a webhook submitted successfully!",
      }),
      setWebhookDeleted({clientUserId, webhookId}),
    ];
  } catch (err) {
    if (err.status === 401 || err.status === 403) {
      return [
        replace("/"),
        show({
          message: "Unauthorized to delete webhooks",
        }),
      ];
    }
    return [replace(`/error?code=${err.status}`)];
  }
});
