const STATE = "State";
const PROVINCE = "Province";
const GOVERNORATE = "Governorate";

// A willy nilly country is a country with no standardization around well locations

export default [
  {
    value: "australia",
    text: "Australia",
    divisionLabel: STATE,
  },
  {
    value: "canada",
    text: "Canada",
    divisionLabel: PROVINCE,
    isWillyNilly: false,
  },
  {value: "chile", text: "Chile", divisionLabel: PROVINCE},
  {value: "egypt", text: "Egypt", divisionLabel: GOVERNORATE},
  {value: "india", text: "India", divisionLabel: STATE},
  {value: "mexico", text: "Mexico", divisionLabel: STATE},
  {
    value: "unitedstates",
    text: "United States",
    divisionLabel: STATE,
    isWillyNilly: false,
  },
];
