import React from "react";
import pure from "recompose/pure";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import formatDateTime from "@ambyint/common/utils/format/format-date-time";

import DisplayCards from "components/d3-cards";
import PaperContainer from "components/paper-container";
import getLatestCard from "utils/well/get-latest-card";
import getShutdownCardReason from "utils/well/get-shutdown-card-reason";

import {
  defaultContainerStyle,
  defaultGridContainer,
  defaultMainStyle,
  defaultTitleStyle,
} from "./dashboard-styles";

const LatestCardDisplay = props => {
  const {well} = props;
  const card = getLatestCard(well);

  if (!card) return null;
  const shutdownReason = getShutdownCardReason(card);

  return (
    <PaperContainer style={defaultContainerStyle}>
      <div style={{...defaultGridContainer, padding: "16px"}}>
        <Typography
          variant="body1"
          style={{...defaultTitleStyle, marginBottom: "16px"}}
        >
          {`Card occurred on ${formatDateTime(card.createdOn, {
            timezone: true,
          })}`}
        </Typography>
        <DisplayCards
          {...card}
          margin={{
            top: 10,
            right: 5,
          }}
        />
      </div>
      {shutdownReason && <Divider />}
      {shutdownReason && (
        <div style={{...defaultGridContainer, padding: "16px"}}>
          <Typography variant="body1" style={defaultTitleStyle}>
            Shutdown Reason
          </Typography>
          <Typography variant="body1" style={defaultMainStyle}>
            {shutdownReason}
          </Typography>
        </div>
      )}
    </PaperContainer>
  );
};

export default pure(LatestCardDisplay);
