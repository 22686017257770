export default ({state, action}) => {
  if (!action.payload) return state;

  return {
    ...state,
    sortUsers: {
      sortBy: action.payload.sortBy,
      sortDirection: action.payload.sortDirection,
    },
  };
};
