import {scaleLinear} from "d3-scale";
import {dailyProduction} from "unit-converter";
import getUnits from "utils/get-units";

const convert = (value, to) => {
  return dailyProduction.convert({to, value});
};

export default () => {
  const units = getUnits();

  return scaleLinear().domain([
    convert(0, units.dailyProduction),
    convert(10, units.dailyProduction),
  ]);
};
