import get from "lodash/get";

import getUser from "utils/get-user";
import integer from "utils/validation/integer";
import max from "utils/validation/max";
import min from "utils/validation/min";
import required from "utils/validation/required";
import valueOf from "utils/value-of";

import {maxAllowablePosition} from "./max-values";

export default parameters => {
  const {unitsOfMeasure} = getUser();

  const deadbandValue = valueOf(parameters.deadband);
  const deadbandUnits = get(parameters, "deadband.units");
  const strokeDelay = valueOf(parameters.speedChangeStrokeDelay);

  // Deadband's accepted values change depending on whether the well is in VFD-Downhole Mode or
  // VFD-Surface Mode. Deadband isn't configurable in Standard (Non-VFD) Modes.
  //
  // • When in VFD-Downhole Mode, we accept a percentage
  // • When in VFD-Surface Mode, we accept a positional measurement.
  const maxDeadbandValue =
    deadbandUnits === "%" ? 100 : maxAllowablePosition(unitsOfMeasure);

  const deadband = {
    ...(required(deadbandValue) ? {deadband: required(deadbandValue)} : {}),
    ...(min(deadbandValue) ? {deadband: min(deadbandValue)} : {}),
    ...(max(deadbandValue, maxDeadbandValue)
      ? {deadband: max(deadbandValue, maxDeadbandValue)}
      : {}),
    ...(integer(deadbandValue) ? {deadband: integer(deadbandValue)} : {}),
  };

  const speedChangeStrokeDelay = {
    ...(required(strokeDelay)
      ? {speedChangeStrokeDelay: required(strokeDelay)}
      : {}),
    ...(min(strokeDelay, 1)
      ? {speedChangeStrokeDelay: min(strokeDelay, 1)}
      : {}),
    ...(max(strokeDelay)
      ? {speedChangeStrokeDelay: max(strokeDelay, 999)}
      : {}),
    ...(integer(strokeDelay)
      ? {speedChangeStrokeDelay: integer(strokeDelay)}
      : {}),
  };

  return {
    ...deadband,
    ...speedChangeStrokeDelay,
  };
};
