import compose from "lodash/fp/compose";
import {withRouter} from "react-router";
import {push} from "react-router-redux";
import get from "lodash/get";
import Grid from "@material-ui/core/Grid";
import noop from "lodash/noop";
import React, {Component} from "react";
import ShowChartIcon from "@material-ui/icons/ShowChart";

import {
  addComparison,
  removeComparison,
  toggleComparisonsView,
  updateAnalysis,
} from "actions/wells";
import {withBoxLoading} from "components/box-loading";
import {withPageview} from "components/analytics";
import DateRangeField from "components/forms/date-range-field";
import EmptyState from "components/empty-state";
import presets from "constants/analysis-presets";
import withResize from "components/with-resize";

import {withAnalysis} from "./with-analysis";
import {withWell} from "./with-well";
import ActivityFeed from "./activity-feed";
import applyEvents from "./apply-events";
import Chart from "./chart";
import Comparisons from "./comparisons";
import getCorrelationId from "./get-correlation-id";
import getDate from "./get-date";
import getEvents from "./get-events";
import Legend from "./legend";
import rangeChange from "./range-change";
import toggle from "./toggle";

const styles = {
  activity: {
    width: 350,
  },
  chart: {
    flex: 1,
    marginRight: "16px",
  },
  container: {
    display: "flex",
    margin: "8px 16px",
  },
};

class AnalysisTab extends Component {
  shouldComponentUpdate(nextProps) {
    const showRawComparisons = get(this.props, "well.showRawComparisons");
    const nextShowRawComparisons = get(nextProps, "well.showRawComparisons");
    const width = get(this.props, "width", false);
    const nextWidth = get(nextProps, "width", false);
    const wellComparisons = get(this.props, "well.comparisons", []);
    const nextWellComparisons = get(nextProps, "well.comparisons", []);
    const wellStatus = get(this.props, "well.status.displayName", false);
    const nextWellStatus = get(nextProps, "well.status.displayName", false);
    const wellEvents = get(this.props, "well.events", []);
    const nextWellEvents = get(nextProps, "well.events", []);
    const wellAlerts = this.props.alerts || [];
    const nextWellAlerts = nextProps.alerts || [];
    const wellAnalysis = this.props.analysis || null;
    const nextWellAnalysis = nextProps.analysis || null;

    // Rerender conditions: showRawComparisons change, width change, new comparisons, new status, events added, alerts added, shallow comparison fails for analysis
    return (
      showRawComparisons !== nextShowRawComparisons ||
      width !== nextWidth ||
      wellStatus !== nextWellStatus ||
      wellEvents.length !== nextWellEvents.length ||
      wellAlerts.length !== nextWellAlerts.length ||
      wellAnalysis !== nextWellAnalysis ||
      wellComparisons !== nextWellComparisons
    );
  }

  render() {
    const {analysis, dispatch, match, well, width} = this.props;
    const events = getEvents(this.props);
    const chartWidth = width ? width - styles.activity.width - 3 * 16 : 0;

    if (!events.dates.length) {
      return (
        <Grid
          container
          direction={"column"}
          justify={"center"}
          alignItems={"center"}
        >
          <Grid item>
            <EmptyState
              icon={ShowChartIcon}
              mainText={"No well updates for the selected period."}
              subText={
                "You can adjust the date range by selecting the input at the top left of the page."
              }
            />
          </Grid>
          <Grid item>
            <DateRangeField
              start={analysis.range.start}
              startLabel="From"
              end={analysis.range.end}
              endLabel="To"
              height={400}
              min={new Date(2017, 7, 1)}
              max={new Date()}
              maxDate={new Date()}
              onChange={rangeChange(this.props)}
            />
          </Grid>
        </Grid>
      );
    }

    const date = getDate(analysis.date || events.maxDate, analysis.range);

    const state = applyEvents({
      analysis,
      date,
      events,
    });

    const correlationId = getCorrelationId(state);

    return (
      <div>
        <div style={styles.container}>
          <Grid container>
            <Grid item xs={6}>
              <DateRangeField
                start={state.range.start}
                startLabel="From"
                end={state.range.end}
                endLabel="To"
                height={400}
                min={new Date(2017, 7, 1)}
                max={new Date()}
                maxDate={new Date()}
                onChange={rangeChange(this.props)}
              />
            </Grid>
          </Grid>
        </div>
        <div style={styles.container}>
          <Legend
            {...state}
            devices={well.devices}
            onChange={toggle(this.props)}
          />
        </div>
        <div style={styles.container}>
          <div style={styles.chart}>
            <Chart
              addComparison={correlationId => {
                dispatch(
                  addComparison({
                    downholeLocation: well.downholeLocation,
                    correlationId,
                  }),
                );
              }}
              removeComparison={correlationId => {
                dispatch(
                  removeComparison({
                    downholeLocation: well.downholeLocation,
                    correlationId,
                  }),
                );
              }}
              comparisons={well.comparisons}
              config={state}
              correlationId={correlationId}
              data={events}
              focus={date => {
                // TODO: The chart only gets this once, so if props change, the closure and props in here wont change to reflect.
                const {dispatch, well} = this.props;

                dispatch(
                  updateAnalysis(well.downholeLocation, {
                    date,
                  }),
                );
              }}
              preset={name => {
                // TODO: Same issue as focus
                const preset = presets[name] || noop;

                dispatch(
                  updateAnalysis(well.downholeLocation, preset(analysis)),
                );
              }}
              width={chartWidth}
            />
          </div>
          <div style={styles.activity}>
            <ActivityFeed
              config={analysis}
              data={events}
              well={well}
              date={date}
            />
          </div>
        </div>
        <Comparisons
          comparisons={well.comparisons}
          devices={well.devices}
          goToSetup={() => dispatch(push(`${match.url}/setup/basic`))}
          remove={correlationId => {
            dispatch(
              removeComparison({
                downholeLocation: well.downholeLocation,
                correlationId,
              }),
            );
          }}
          showRawComparisons={well.showRawComparisons}
          toggleComparisonsView={() =>
            dispatch(
              toggleComparisonsView({
                downholeLocation: well.downholeLocation,
              }),
            )
          }
        />
      </div>
    );
  }
}

const enhance = compose(
  withPageview(),
  withRouter,
  withResize,
  withWell,
  withBoxLoading,
  withAnalysis,
);

export default enhance(AnalysisTab);
