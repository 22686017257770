import {torque as converter} from "unit-converter";
import getUnits from "utils/units";

export default ({alert, user}) => {
  const {maxMeasuredTorque, torqueViolationSetpoint} = alert.information;

  if (!maxMeasuredTorque || !torqueViolationSetpoint) {
    return "An alert has been issued because the torque fell below the configured min torque setpoint.";
  }

  const unitsOfMeasure = user.unitsOfMeasure;
  const units = getUnits(user.unitsOfMeasure);

  const convertedMaxTorque = converter.convert({
    value: parseFloat(maxMeasuredTorque),
    to: unitsOfMeasure,
  });

  const minTorque = converter.convert({
    value: parseFloat(torqueViolationSetpoint.min),
    to: unitsOfMeasure,
  });

  return `An alert has been issued because the torque ${convertedMaxTorque.toFixed(
    2,
  )} ${units.torque} fell below the min torque setpoint ${minTorque.toFixed(
    2,
  )} ${units.torque}.`;
};
