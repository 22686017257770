import {normalize} from "normalizr";
import get from "lodash/get";

import parseWell from "@ambyint/common/parsers/well";

import {createSwitchEpic} from "epics/create-epic";
import {schemas} from "schemas";
import {filterWellsByTag} from "actions/tagging";
import {
  set,
  setDisplayAllWells,
  setSearchResults,
  types,
  setWellsFetched,
} from "actions/wells";
import fetch from "epics/async-fetch";
import getUser from "utils/get-user";

import {getWell} from "./get-well";

export const fetchWells = createSwitchEpic(
  [types.fetch],
  async (action, store) => {
    const state = store.getState();
    const criteria = get(state, "wells.search.criteria", "");
    const organizationId = state.organizationView.currentOrganization;

    if (!state.auth.token) {
      return [];
    }

    const {unitsOfMeasure} = getUser();
    const parse = parseWell(unitsOfMeasure);
    const wells = await fetch(`/wells?organizationId=${organizationId}`);
    let parsed = wells.map(well => parse(well, getWell(store, well.wellId)));

    if (organizationId) {
      parsed = parsed.filter(well => well.organization.id === organizationId);
    }

    const normalized = normalize(parsed, schemas.arrayOfWells);
    const results = normalized.result;

    return [
      setWellsFetched(false),
      set({data: normalized}),
      setDisplayAllWells({filtered: results}),
      setSearchResults({criteria, results}),

      // Note that well fetch status action is sent as a callback to be run after filtering wells by tag
      // If this is not a callback then some pages will not load with the correct wells if you refresh the page
      filterWellsByTag([setWellsFetched(true)]),
    ];
  },
);
