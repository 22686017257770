export const hasFullAccess = (
  assignedOrganizations,
  selectedOrganizationId,
) => {
  return assignedOrganizations.some(assignedOrganization => {
    return (
      assignedOrganization.organizationId === selectedOrganizationId &&
      assignedOrganization.accessLevel === "Full"
    );
  });
};

export default {
  hasFullAccess,
};
