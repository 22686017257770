import hasWellPilot from "@ambyint/common/utils/devices/has-well-pilot";

import store from "store";

const isWorkflowWell = ({status}, workflow) =>
  status.workflows.find(wellWorkflow => wellWorkflow === workflow);

export const getWorkflowWells = (wellList = [], workflow) => {
  const state = store.getState();
  const wellObj = state.wells.byWellId;

  const wells = [];
  for (let i = 0; i < wellList.length; i++) {
    const currentWell = wellObj[wellList[i]];

    if (hasWellPilot(currentWell.devices)) {
      continue;
    }

    if (isWorkflowWell(currentWell, workflow)) {
      wells.push(currentWell.wellId);
    }
  }

  return wells;
};
