import React from "react";

import colors from "theme/colors";
import {FormatDateTime} from "components/format/date";

import Bell from "components/d3-icons/bell";
import Circle from "components/d3-icons/circle";
import Diamond from "components/d3-icons/diamond";
import Square from "components/d3-icons/square";
import User from "components/d3-icons/user";
import * as eventTypes from "constants/event-types";

const containerStyle = {
  alignItems: "center",
  display: "flex",
};

const ALERT_EVENTS = {
  [eventTypes.ALERT_ACKNOWLEDGED]: () => <Bell color={colors.blue[400]} />,
  [eventTypes.ALERT_CREATED]: () => <Bell color={colors.red[400]} />,
  [eventTypes.ALERT_RESOLVED]: () => <Bell color={colors.green[400]} />,
};

const RECOMMENDATION_EVENTS = {
  [eventTypes.RECOMMENDATION_ACCEPTED]: () => (
    <Diamond color={colors.green[400]} />
  ),
  [eventTypes.RECOMMENDATION_DISMISSED]: () => (
    <Diamond color={colors.red[400]} />
  ),
  [eventTypes.NEW_RECOMMENDATION]: () => <Diamond color={colors.blue[400]} />,
};

const userActionComponent = () => <User color={colors.purple[400]} />;

const USER_ACTION_EVENTS = {
  [eventTypes.ALLOWED_TO_OPERATE]: userActionComponent,
  [eventTypes.AUTO_ACCEPTED_RECOMMENDATIONS]: userActionComponent,
  [eventTypes.DEVICE_ASSIGNED]: userActionComponent,
  [eventTypes.DEVICE_REMOVED]: userActionComponent,
  [eventTypes.DOWNHOLE_INFORMATION]: userActionComponent,
  [eventTypes.GRACE_PERIOD_SET]: userActionComponent,
  [eventTypes.GRACE_PERIOD_UNSET]: userActionComponent,
  [eventTypes.MANUAL_FAULT_RESET]: userActionComponent,
  [eventTypes.MOTOR_DETAILS]: userActionComponent,
  [eventTypes.PUMPING_UNIT]: userActionComponent,
  [eventTypes.RESERVOIR]: userActionComponent,
  [eventTypes.RESISTANCE]: userActionComponent,
  [eventTypes.SAFE_OPERATING_PARAMETERS]: userActionComponent,
  [eventTypes.SCHEDULED_DOWNTIME]: userActionComponent,
  [eventTypes.SET_BASIC_WELL_DETAILS]: userActionComponent,
  [eventTypes.SET_FAULT_RECOVERY]: userActionComponent,
  [eventTypes.SPEED_PROFILE]: userActionComponent,
  [eventTypes.SPEED_RANGE]: userActionComponent,
  [eventTypes.SURFACE_CARD_REQUESTED]: userActionComponent,
  [eventTypes.SURFACE_CARD_REQUEST_FULFILLED]: userActionComponent,
  [eventTypes.TOTAL_REDUCTION_RATIO]: userActionComponent,
  [eventTypes.WELL_ANALOG_INPUT_CHANGED]: userActionComponent,
  [eventTypes.WELL_CURRENT_VIOLATION]: userActionComponent,
  [eventTypes.WELL_PUMP_OFF_SETTING_CHANGED]: userActionComponent,
  [eventTypes.WELL_RESET_MALFUNCTIONS]: userActionComponent,
  [eventTypes.WELL_RUN_TIMER]: userActionComponent,
  [eventTypes.WELL_TORQUE_VIOLATION]: userActionComponent,
  [eventTypes.WELLBORE]: userActionComponent,
};

const shutdownCards = {
  test: event => event.shutdown,
  component: () => <Square color={colors.red[400]} />,
};

const ICON_MAP = {
  ...ALERT_EVENTS,
  ...RECOMMENDATION_EVENTS,
  ...USER_ACTION_EVENTS,
  [eventTypes.DRIVE_FAULT]: () => <Circle color={colors.driveFault[400]} />,
  [eventTypes.RUNNING_STATUS]: ({event}) => (
    <Circle color={event.value ? colors.green[400] : colors.red[400]} />
  ),
  [eventTypes.STATUS_CODES]: () => <Circle color={colors.blue[400]} />,
  [eventTypes.CARDS]: shutdownCards,
  [eventTypes.MINI_CARDS]: shutdownCards,
};

export default ({event}) => {
  const date = <FormatDateTime date={event.createdOn} />;

  let Icon = ICON_MAP[event.type];
  if (Icon && Icon.test) Icon = Icon.test(event) ? Icon.component : null;

  return Icon ? (
    <div style={containerStyle}>
      <Icon event={event} />
      <div style={{marginLeft: "5px"}}>{date}</div>
    </div>
  ) : (
    date
  );
};
