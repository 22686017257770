import {types as wellTypes} from "actions/wells";
import {updateFilter} from "actions/paging";
import {replace} from "react-router-redux";
import mergeQuerystrings from "utils/merge-querystrings";
import PAGING_SOURCES from "constants/paging-sources";

export default (action$, store) => {
  return action$.ofType(wellTypes.search).switchMap(({payload}) => {
    const {criteria} = payload;
    const state = store.getState();

    if (criteria !== state.paging.filter) {
      const updatedParams = {
        filter: criteria,
        source: PAGING_SOURCES.inbox,
      };

      return [
        replace(mergeQuerystrings(state, updatedParams)),
        updateFilter(updatedParams),
      ];
    }

    return [];
  });
};
