import React from "react";
import {reduxForm} from "redux-form";
import debounce from "lodash/debounce";

import Grid from "@material-ui/core/Grid";

import TextField from "components/forms/text-field";

import styles from "routes/profile/organization/styles";

class Header extends React.Component {
  componentWillMount() {
    const {setOrganizationUsers} = this.props;
    setOrganizationUsers("");
  }

  debouncedPerformSearch(search) {
    const {setOrganizationUsers} = this.props;
    debounce(setOrganizationUsers, 100, {maxWait: 800})(search);
  }

  render() {
    const {users} = this.props;

    return (
      <div>
        <Grid
          alignItems="center"
          container
          justify="space-around"
          spacing={0}
          style={styles.header}
        >
          <Grid item xs={6}>
            <div style={{fontWeight: "bold"}}>
              Displaying {users.length} Users
            </div>
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="search"
              label="Search for a user"
              fullWidth
              onChange={(event, value) => {
                this.debouncedPerformSearch(value);
              }}
              onKeyPress={event => {
                if (event.key === "Enter") {
                  event.preventDefault();
                }
              }}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const Component = reduxForm({
  enableReinitialize: true,
  form: "organization-user-search",
})(Header);

export default Component;
