import {show} from "actions/discard-form-dialog";
import getResetDirtyFormAction from "utils/get-reset-dirty-form-action";

export default pendingAction => {
  const resetDirtyFormAction = getResetDirtyFormAction();

  if (resetDirtyFormAction) {
    return show({
      pendingAction,
      resetDirtyFormAction,
    });
  }

  return pendingAction;
};
