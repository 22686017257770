import React from "react";
import Typography from "@material-ui/core/Typography";

import colors from "theme/colors";

import styles from "routes/profile/organization/styles";

const PageHeader = props => {
  const {title, subtitle, uppercase} = props;

  return (
    <div
      style={{
        alignItems: "center",
        marginBottom: 64,
        marginTop: 32,
      }}
    >
      {title ? (
        <Typography
          variant="h4"
          style={{
            color: colors.grey[700],
            fontWeight: "bold",
            ...(uppercase ? {textTransform: "uppercase"} : {}),
          }}
        >
          {title}
        </Typography>
      ) : null}

      {subtitle ? (
        <Typography
          variant="subtitle1"
          style={{...styles.subheader, fontSize: "1.1em", maxWidth: "60%"}}
        >
          {subtitle}
        </Typography>
      ) : null}
    </div>
  );
};

export default PageHeader;
