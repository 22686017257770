export default ({state, action}) => {
  return {
    ...state,
    analysis: {
      ...state.analysis,
      link: {
        originalUrl: action.payload.url,
        url: null,
        isFetching: true,
      },
    },
  };
};
