import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const HoleInBarrel = ({className}) => {
  return (
    <SvgIcon className={className} viewBox="0 0 227 139">
      <path
        d="M223.258 122.844h-76.562c-18.373 0-30.533-7.858-38-22.781-5.46-10.913-7.67-21.568-11.082-47.026l-.04-.296c-2.902-21.65-4.689-30.988-8.522-40.544C86.546 5.953 80.837 3.5 68.889 3.5c-5.462 0-22.453.068-50.958.204C9.95 3.742 3.5 10.223 3.5 18.204v102.624c0 8.009 6.492 14.5 14.5 14.5h174.4c.949 0 1.39.011 3.939.088 4.963.148 7.863.121 11.057-.229 9.371-1.026 14.701-4.549 15.862-12.343z"
        stroke="currentColor"
        strokeWidth={7}
        fill="none"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export default HoleInBarrel;
