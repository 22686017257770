import {types} from "./types";

export const prefillForm = ({form, well}) => {
  return {
    type: types.prefillForm,
    payload: {
      form,
      well,
    },
  };
};
