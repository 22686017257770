import process from "./process";

export default ({state, action}) => {
  const sortAsc =
    state.sortBy === action.payload.sortBy ? !state.sortAsc : state.sortAsc;

  return process({
    ...state,
    selected: null,
    sortAsc,
    sortBy: action.payload.sortBy,
  });
};
