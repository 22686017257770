import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React from "react";
import ReactList from "react-list";
import withStyles from "@material-ui/core/styles/withStyles";

import WellReport from "../well-report";
import WellReportHeader from "../well-report-header";

const styles = {
  paper: {
    padding: "10px 0",
  },

  noWells: {
    textAlign: "center",
    color: "#999",
    minHeight: "50px",
    lineHeight: "50px",
    fontSize: "14px",
  },
};

const WellReportGroup = ({
  classes,
  title,
  wells,
  timePeriod,
  showSort,
  threshold,
  onReportDisplayed,
  hideWhenEmpty,
}) => {
  if (hideWhenEmpty && wells.length === 0) return null;

  return (
    <Grid item>
      <WellReportHeader
        title={title}
        count={wells.length}
        threshold={threshold}
        showSort={showSort}
      />
      <Paper className={classes.paper}>
        {wells.length > 0 ? (
          <ReactList
            itemRenderer={index => {
              const well = wells[index];
              return (
                <WellReport
                  key={well.wellId}
                  well={well}
                  timePeriod={timePeriod}
                  rowId={index}
                  onReportDisplayed={onReportDisplayed}
                />
              );
            }}
            length={wells.length}
            type="simple"
            pageSize={2}
          />
        ) : (
          <div className={classes.noWells}>
            All wells are within normal range
          </div>
        )}
      </Paper>
    </Grid>
  );
};

export default withStyles(styles)(WellReportGroup);
