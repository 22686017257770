import get from "lodash/get";

export default ({values, reverse}) => {
  return values.sort((a, b) => {
    const aV = get(a, "status.statuses[0].description", "");
    const bV = get(b, "status.statuses[0].description", "");

    return reverse * ((aV > bV) - (bV > aV));
  });
};
