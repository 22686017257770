import compose from "lodash/fp/compose";
import isNil from "lodash/isNil";
import moment from "moment";
import orderBy from "lodash/fp/orderBy";
import uniqBy from "lodash/fp/uniqBy";
import times from "lodash/times";
import map from "lodash/map";
import {replace} from "react-router-redux";

import {createMergeEpic} from "epics/create-epic";
import {dailyProduction} from "@ambyint/common/unit-converter";
import {getProductionValues} from "utils/get-production-values";
import fetch from "epics/async-fetch";
import {setDailySummaries, types} from "actions/wells/well-health";

// If the value is null or undefined we need to send it back as 0.
const convert = (value, convertFunc, to, from) => {
  return isNil(value) ? 0 : convertFunc({value, to, from});
};

// Pick out the properties we want from each summary and, if required, convert their values to user's preferred units.
const convertMetrics = unitsOfMeasure => summaries =>
  summaries.map(summary => {
    const {strokes, ...rest} = summary;
    return {
      ...rest,
      liquidProduction: convert(
        getProductionValues(summary, "totalLiquid").value,
        dailyProduction.convert,
        unitsOfMeasure,
        "metric",
      ),
      strokes,
    };
  });

// Given a start and end date, go through the provided summary objects and sort them into weeks.
const fillSummaryRange = start => summariesForWell => {
  const mStart = moment(start)
    .utc()
    .startOf("day");

  const result = summariesForWell.reduce((accumulator, summary) => {
    const dateKey = moment(summary.date.start)
      .utc()
      .format("GGGG-W");

    if (!isNil(summary.liquidProduction)) {
      accumulator.liquidProduction[dateKey] += summary.liquidProduction;
    }

    if (!isNil(summary.strokes)) {
      accumulator.strokes[dateKey] += summary.strokes;
    }

    return accumulator;
  }, getEmptyResult(mStart));

  return {
    liquidProduction: map(result.liquidProduction, (y, x) => ({x, y})),
    strokes: map(result.strokes, (y, x) => ({x, y})),
  };
};

const getEmptyResult = startDate => {
  const liquidProduction = {};
  const strokes = {};

  times(26, i => {
    const dateKey = startDate
      .clone()
      .add(i, "week")
      .format("GGGG-W");

    if (!liquidProduction[dateKey]) liquidProduction[dateKey] = null;
    if (!strokes[dateKey]) strokes[dateKey] = null;
  });

  return {
    liquidProduction,
    strokes,
  };
};

export const fetchDailySummaries = createMergeEpic(
  [types.fetchDailySummaries],
  async ({payload}, store) => {
    const {
      wellId,
      downholeLocation,
      unitsOfMeasure,
      start,
      end,
      metrics,
    } = payload;
    try {
      const dailySummaries = await fetch(
        `/daily-summaries/${encodeURIComponent(downholeLocation)}`,
        {
          downholeLocation,
          wellId,
          start,
          end,
          metrics: metrics.join(","),
        },
      );

      const prepareSummariesForWell = compose(
        fillSummaryRange(start),
        uniqBy("date.start"),
        orderBy(["date.start"], ["asc"]),
        convertMetrics(unitsOfMeasure),
      );

      return [
        setDailySummaries(
          prepareSummariesForWell(dailySummaries),
          unitsOfMeasure,
        ),
      ];
    } catch (err) {
      return [replace(`/error?code=${err.status}`)];
    }
  },
);
