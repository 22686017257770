import React from "react";

import Grid from "@material-ui/core/Grid";
import isEmpty from "lodash/isEmpty";
import Typography from "@material-ui/core/Typography";

import colors from "theme/colors";
import formatSince from "@ambyint/common/utils/format/format-since";
import getStatusColor from "@ambyint/common/utils/wells/get-status-color";
import hasStatusCodes from "utils/well/has-status-codes";

const containerStyle = {
  minWidth: "150px",
  textTransform: "uppercase",
};

const defaultMainStyle = {
  fontWeight: 500,
  color: colors.ambyBlue[500],
};

const defaultTitleStyle = {
  color: colors.grey[600],
  textTransform: "uppercase",
  fontSize: "12px",
};

export default props => {
  const {well} = props;

  return (
    <Grid container justify="flex-start" spacing={5} style={containerStyle}>
      <Grid item>
        <Typography variant="body1" style={{...defaultTitleStyle}}>
          since {formatSince(well.status.communicatedOn)}
        </Typography>
        <Typography
          variant="h5"
          style={{
            ...defaultMainStyle,
            color: getStatusColor(well.status),
          }}
        >
          {well.status.displayName}
        </Typography>
      </Grid>
      {!isEmpty(well.conditions.speed) && (
        <Grid item>
          <Typography variant="body1" style={{...defaultTitleStyle}}>
            Speed
          </Typography>
          <Typography variant="h6" style={{...defaultMainStyle}}>
            {well.conditions.speed.label || "--"}
          </Typography>
        </Grid>
      )}
      <Grid item>
        {well.status.mode && (
          <Grid item xs>
            <Typography variant="body1" style={{...defaultTitleStyle}}>
              Mode
            </Typography>
            <Typography variant="h6" style={{...defaultMainStyle}}>
              {well.status.mode}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item>
        {hasStatusCodes(well) && (
          <Grid item xs>
            <Typography variant="body1" style={{...defaultTitleStyle}}>
              {well.status.statuses.length > 1 ? "Codes" : "Code"}
            </Typography>
            {well.status.statuses.map((code, i) => (
              <Typography
                className="status-description"
                key={i}
                variant="body1"
                style={{...defaultMainStyle}}
              >
                {code.description}
              </Typography>
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
