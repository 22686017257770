import React from "react";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";

import formatDateTime from "@ambyint/common/utils/format/format-date-time";
import {defaultMainStyle, defaultTitleStyle} from "../dashboard-styles";

export const DisplayRecent = props => {
  const {classifiedOn, label} = props;

  if (!label) return null;

  return [
    <Grid
      item
      xs={12}
      style={{paddingTop: 4, paddingBottom: 0}}
      key={`recent-${classifiedOn}`}
    >
      <Typography
        variant="body1"
        style={{...defaultTitleStyle, paddingBottom: 0}}
      >
        {formatDateTime(classifiedOn, {timezone: true})}
      </Typography>
    </Grid>,
    <Grid
      item
      xs={12}
      style={{paddingTop: 0, paddingBottom: 4}}
      key={`recent-${classifiedOn}-label`}
    >
      <Typography variant="body1" style={defaultMainStyle}>
        {label}
      </Typography>
    </Grid>,
  ];
};
