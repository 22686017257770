import get from "lodash/get";
import round from "lodash/round";
import React from "react";
import {dailyProduction} from "unit-converter";

import {CLASSIFICATION_SORT} from "constants/classification";
import {getProductionValues} from "utils/get-production-values";
import TableCell from "components/table-cell";
import TableRow from "components/table-row";

const {TOTAL_LIQUID, CYCLES, FILLAGE, SPM} = CLASSIFICATION_SORT;

const getTableCell = (well, path, unitsOfMeasure, options = {}) => {
  // We have to be deliberate with type checking, 0 is still a valid value!
  const {converter = null, precision = 1} = options;

  // Ignore precision parameter if it is null
  // This will round the value as an Integer
  const roundFn =
    precision === null
      ? value => round(value)
      : (value, precision) => round(value, precision);

  // Check if value is liquid production, handle it using new production method if so
  let value;
  if (path === TOTAL_LIQUID) {
    value = getProductionValues(get(well, "dailySummaries"), "totalLiquid")
      .value;
  } else {
    // Process daily summary value
    value = get(well, path);
  }

  if (typeof value !== "number") {
    return "--";
  }

  // Convert and re-round if a conversion fn is specified
  if (converter) {
    return roundFn(
      converter.convert({
        value,
        from: "metric", // mongo stores values in metric, we can assume values will be metric
        to: unitsOfMeasure,
      }),
      2,
    );
  }

  return roundFn(value, precision);
};

export const renderWellRow = ({
  wells,
  onClick,
  unitsOfMeasure,
  currentHoverId,
  setCurrentHoverId,
}) => (index, key) => {
  const {displayName, fieldName, wellId, ...wellRest} = wells[index];
  const unsetHoverId = () => setCurrentHoverId(null);
  const setCurrentWell = () => setCurrentHoverId(wellId);
  const isHoverOnRow = currentHoverId === wellId;

  return (
    <TableRow
      key={key}
      onClick={onClick(index)}
      onMouseEnter={setCurrentWell}
      onMouseLeave={unsetHoverId}
    >
      <TableCell underline={isHoverOnRow}>{displayName}</TableCell>
      <TableCell align="right">
        {getTableCell(wellRest, TOTAL_LIQUID, unitsOfMeasure, {
          converter: dailyProduction,
          precision: null,
        })}
      </TableCell>
      <TableCell align="right">
        {getTableCell(wellRest, SPM, unitsOfMeasure)}
      </TableCell>
      <TableCell align="right">
        {getTableCell(wellRest, CYCLES, unitsOfMeasure)}
      </TableCell>
      <TableCell align="right">
        {getTableCell(wellRest, FILLAGE, unitsOfMeasure, {precision: null})}
      </TableCell>
      <TableCell style={{width: "5%"}} />
    </TableRow>
  );
};
