/**
 * Return JSON to include in outer JSON,
 * only if predicate is true
 * @param {function,bool} predicate - Return empty object if false, else json
 * @param {Object} json - Object to return if predicate is true
 */

const spreadIf = (predicate, json) => {
  const shouldReturnObject =
    typeof predicate === "function" ? predicate() : !!predicate;

  return shouldReturnObject ? json : {};
};

export default spreadIf;
