import {types} from "./types";

export const setWellboreView = view => {
  return {
    type: types.setWellboreView,
    payload: {
      view,
    },
  };
};
