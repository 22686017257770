import {actions} from "react-redux-form";
import store from "store";
import required from "utils/validation/required";

import formPath from "../form-path";

export default () => {
  store.dispatch(
    actions.validateFieldsErrors(formPath, {
      wellCountry: value => ({
        required: required(value),
      }),
      wellProvinceState: value => ({
        required: required(value),
      }),
    }),
  );
};
