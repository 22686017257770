import manual from "./manual";
import speedRange from "./speed-range";
import timer from "./timer";
import idleTime from "./idle-time";
import lufkinDownholeSecondary from "./pumpoff/lufkin-downhole/secondary";
import lufkinDownholeVFDSecondary from "./pumpoff/lufkin-downhole/vfd-secondary";
import lufkinDownholeVFD from "./pumpoff/lufkin-downhole/vfd";
import lufkinDownhole from "./pumpoff/lufkin-downhole/downhole";
import lufkinSurfaceVFD from "./pumpoff/lufkin-surface/vfd";
import lufkinSurface from "./pumpoff/lufkin-surface/surface";
import wellPilotDownhole from "./pumpoff/well-pilot-downhole";
import wellPilotSurface from "./pumpoff/well-pilot-surface";

export default {
  manual,
  speedRange,
  timer,
  idleTime,
  lufkinDownholeSecondary,
  lufkinDownholeVFDSecondary,
  lufkinDownholeVFD,
  lufkinDownhole,
  lufkinSurfaceVFD,
  lufkinSurface,
  wellPilotDownhole,
  wellPilotSurface,
};
