import getSafeOperatingSpeedMax from "@ambyint/common/utils/wells/get-safe-operating-speed-max";
import getSafeOperatingSpeedMin from "@ambyint/common/utils/wells/get-safe-operating-speed-min";
import decimalPrecision from "utils/validation/decimal-precision";
import max from "utils/validation/max";
import min from "utils/validation/min";
import number from "utils/validation/number";
import required from "utils/validation/required";
import validateFieldsOf from "utils/validation/validate-fields-of";

export default (values, well) => {
  const validateRecommendedData = validateFieldsOf(values.acceptedData);

  const maxSpeed = getSafeOperatingSpeedMax(well);
  const minSpeed = getSafeOperatingSpeedMin(well);

  return {
    ...validateRecommendedData("min", x => required(x)),
    ...validateRecommendedData("min", x => number(x)),
    ...validateRecommendedData("min", x => min(x, minSpeed)),
    ...validateRecommendedData("min", x => max(x, maxSpeed)),
    ...validateRecommendedData("min", x =>
      max(x, values.acceptedData && values.acceptedData.max),
    ),
    ...validateRecommendedData("min", x => decimalPrecision(x, 1)),

    ...validateRecommendedData("max", x => required(x)),
    ...validateRecommendedData("max", x => number(x)),
    ...validateRecommendedData("max", x =>
      min(x, values.acceptedData && values.acceptedData.min),
    ),
    ...validateRecommendedData("max", x => min(x, minSpeed)),
    ...validateRecommendedData("max", x => max(x, maxSpeed)),
    ...validateRecommendedData("max", x => decimalPrecision(x, 1)),
  };
};
