import compose from "lodash/fp/compose";
import countBy from "lodash/fp/countBy";

import colors from "@ambyint/common/colors";

import {unfold} from "utils/fp";

import calculateSize from "./calculate-size";

const theme = {
  "Dialed In": colors.blueGrey[200],
  Overpumping: colors.indigo[200],
  Underpumping: colors.blue[200],
  Unknown: colors.deepOrange[200],
};

const countByClassification = compose(
  unfold(["title", "size"]),
  countBy("classification"),
);

const buildTree = (theme, data) => {
  let id = 0;

  return {
    title: "Filter by classification",
    children: countByClassification(data).map(classification => ({
      ...classification,
      color: theme[classification.title],
      id: ++id,
    })),
  };
};

export default data => calculateSize(buildTree(theme, data));
