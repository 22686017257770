import {
  LOW_LOAD,
  HIGH_LOAD,
  DTV,
  HOLE_IN_BARREL,
  LEAKING,
  NORMAL,
  PUMP_OFF,
  GAS_INTERFERENCE,
} from "@ambyint/ai-models/lib/constants/outputs";

const HIGH_LOAD_STUCK_PLUNGER = `${HIGH_LOAD}_stuckPlunger`;
const HIGH_LOAD_FRICTION = `${HIGH_LOAD}_friction`;
const IDEAL = NORMAL;

export {
  LOW_LOAD,
  HIGH_LOAD,
  HIGH_LOAD_STUCK_PLUNGER,
  HIGH_LOAD_FRICTION,
  IDEAL,
  DTV,
  HOLE_IN_BARREL,
  LEAKING,
  PUMP_OFF,
  GAS_INTERFERENCE,
};
