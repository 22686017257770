import {drag as d3drag} from "d3-drag";
import {event as d3event} from "d3-selection";
import colors from "theme/colors";

const updateLine = (line, x) => {
  line.attr("x1", x).attr("x2", x);
};

const updateTarget = (target, x) => {
  target.attr("cx", x);
};

export default ({onFocus, register, scales, selection}) => {
  let width = 0;
  let transform;

  const focus = selection.append("g").style("display", "none");

  const line = focus
    .append("line")
    .style("stroke", colors.black)
    .style("stroke-width", 3)
    .attr("y1", 0);

  const target = focus
    .append("circle")
    .style("stroke", colors.black)
    .style("stroke-width", 3)
    .style("fill", colors.white)
    .attr("r", 15);

  const update = x => {
    updateLine(line, x);
    updateTarget(target, x);
  };

  const doFocus = x => {
    onFocus(transform.rescaleX(scales.time).invert(x));
  };

  target.call(
    d3drag().on("drag", () => {
      let x = d3event.x;

      x = x < 1 ? 1 : x;
      x = x > width ? width : x;

      doFocus(x);
    }),
  );

  return register({
    click(props) {
      doFocus(props.point[0]);
    },

    draw(props) {
      width = props.width;

      if (props.config.date) {
        focus.style("display", "block");

        target.attr("cy", props.height / 2);
        line.attr("y2", props.height);
        update(scales.time(props.config.date));
      }
    },

    zoom(props) {
      transform = props.transform;

      update(transform.rescaleX(scales.time)(props.config.date));
    },
  });
};
