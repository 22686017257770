import curry from "lodash/fp/curry";

const map = (fn, {children, ...node}) =>
  fn(
    children
      ? {...node, children: [...children.map(child => map(fn, child))]}
      : {...node},
  );

export default curry(map);
