import {connect} from "react-redux";
import {withRouter} from "react-router";
import React from "react";

import Breadcrumbs from "components/breadcrumbs";
import withFeatureFlag from "components/with-feature-flag";

import {CsvImport} from "./csv-import";
import organizationStyles from "../styles";

const styles = {
  container: {},
};

const mapStateToProps = state => {
  const {organizations} = state;
  const {selectedOrganizationId} = organizations;

  return {
    organization: organizations.all[selectedOrganizationId],
  };
};

class UploadProductionData extends React.Component {
  render() {
    const {organization} = this.props;

    if (!organization) {
      return null;
    }

    const containerStyle = {
      ...organizationStyles.pageContainer,
      ...styles.container,
    };

    return (
      <div style={containerStyle}>
        <div style={{paddingBottom: 16}}>
          <Breadcrumbs
            crumbs={[
              {
                label: organization.organizationName,
                pathname: "/profile/organizations",
              },
              {
                label: "Upload Production Data",
              },
            ]}
          />
        </div>
        <CsvImport containerStyle={{minHeight: "calc(100vh - 162px)"}} />
      </div>
    );
  }
}

UploadProductionData = connect(mapStateToProps)(UploadProductionData);
UploadProductionData = withRouter(UploadProductionData);
UploadProductionData = withFeatureFlag("upload-production-data")(
  UploadProductionData,
);

export {UploadProductionData};
