import get from "lodash/get";
import uniqBy from "lodash/uniqBy";

import updateAndMergeWell from "./update-and-merge-well";

export default ({state, action}) => {
  const byWellId = updateAndMergeWell(state, action, well => {
    const wellRecs = get(well, "recommendations", []);
    const fetchedRecs = get(action, ["payload", "data"], []);

    // fetchedRecs has to be first so the uniqBy keeps the fetched recommendation over the current.
    const recommendations = uniqBy(
      [...fetchedRecs, ...wellRecs],
      "recommendationId",
    );

    return {...well, recommendations};
  });

  return {
    ...state,
    byWellId,
  };
};
