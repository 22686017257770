import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MaterialTableCell from "@material-ui/core/TableCell";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  tableCell: {
    padding: "24px 0px 24px 24px",
    verticalAlign: "middle",
    wordWrap: "break-word",
    "&:last-child": {
      paddingRight: 24,
    },
    [theme.breakpoints.down("lg")]: {
      padding: 12,
      "&:last-child": {
        paddingRight: 12,
      },
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "0.75em",
    },
  },
  firstCellSpacing: {
    "&:first-child": {
      paddingLeft: 20,
    },
  },
  underline: {
    textDecoration: "underline",
  },
}));

const TableCell = ({
  underline,
  className,
  children,
  firstCellSpacing,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <MaterialTableCell
      className={clsx(
        classes.tableCell,
        underline && classes.underline,
        firstCellSpacing && classes.firstCellSpacing,
        className,
      )}
      {...rest}
    >
      {children}
    </MaterialTableCell>
  );
};

export default TableCell;
