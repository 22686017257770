import all from "utils/validation/all";
import getUser from "utils/get-user";
import isInteger from "utils/validation/integer";
import isNumber from "utils/validation/number";
import isPositive from "utils/validation/positive";
import max from "utils/validation/max";
import required from "utils/validation/required";

import {
  maxAllowableStrokes,
  maxAllowableLoad,
  maxAllowablePosition,
} from "./max-values";

export default values => {
  const {unitsOfMeasure} = getUser();

  const maxLoad = maxAllowableLoad(unitsOfMeasure);
  const maxPosition = maxAllowablePosition(unitsOfMeasure);
  const maxStrokes = maxAllowableStrokes();

  const requiredFields = [
    "load",
    "position",
    "consecutiveStrokes",
    "consecutiveViolations",
  ];

  const needed = all(required, values, requiredFields);
  const numbers = all(isNumber, values, requiredFields);
  const positive = all(isPositive, values, requiredFields);
  const integers = all(isInteger, values, [
    "consecutiveStrokes",
    "consecutiveViolations",
  ]);

  const big = {
    ...(max(values.load, maxLoad) ? {load: max(values.load, maxLoad)} : {}),
    ...(max(values.position, maxPosition)
      ? {position: max(values.position, maxPosition)}
      : {}),
    ...(max(values.consecutiveStrokes, maxStrokes)
      ? {
          consecutiveStrokes: max(values.consecutiveStrokes, maxStrokes),
        }
      : {}),
    ...(max(values.consecutiveViolations, maxStrokes)
      ? {
          consecutiveViolations: max(values.consecutiveViolations, maxStrokes),
        }
      : {}),
  };

  return {...needed, ...numbers, ...positive, ...integers, ...big};
};
