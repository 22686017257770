import calculateTotalReductionRatio from "@ambyint/common/parsers/well/parse-operation/total-reduction-ratio";
import fetch from "epics/fetch";
import getUser from "utils/get-user";
import omitDeepUndefined from "utils/omit-deep-undefined";
import valueOf from "utils/value-of";
import {length} from "unit-converter";
import {setOne, types} from "actions/wells";
import {show} from "actions/snackbar";

function convertLength(value, from) {
  if (!value) {
    return value;
  }

  return length.convert({value, from});
}

function parseSave({
  hasJackshaft,
  gearboxRatio,
  gearboxSheave,
  jackshaftGearboxSheave,
  jackshaftMotorSheave,
  motorSheave,
}) {
  const unitsOfMeasure = getUser().unitsOfMeasure;
  const getLength = value => convertLength(valueOf(value), unitsOfMeasure);

  const results = omitDeepUndefined({
    hasJackshaft,
    gearboxRatio: valueOf(gearboxRatio),
    gearboxSheave: getLength(gearboxSheave),
    jackshaftGearboxSheave: getLength(jackshaftGearboxSheave),
    jackshaftMotorSheave: getLength(jackshaftMotorSheave),
    motorSheave: getLength(motorSheave),
  });

  results.totalReductionRatio = calculateTotalReductionRatio(results);

  return results;
}

export default action$ => {
  let parsedData = null;

  return action$
    .ofType(types.saveTotalReductionRatio)
    .switchMap(({payload}) => {
      parsedData = parseSave(payload.data);

      return fetch(
        `/optimization/well/${encodeURIComponent(
          payload.downholeLocation,
        )}/operation/totalReductionRatio`,
        {},
        {
          body: parsedData,
          method: "PUT",
        },
      ).flatMap(() => {
        return [
          setOne({
            wellId: payload.wellId,
            data: {
              operation: {
                ...payload.data,
                totalReductionRatio: parsedData.totalReductionRatio,
              },
            },
          }),
          show({
            message: "You have successfully updated Total Reduction Ratio",
          }),
        ];
      });
    });
};
