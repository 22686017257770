import map from "lodash/map";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import TextField from "@material-ui/core/TextField";

export const Units = props => {
  const {mapping, onChange, units} = props;

  if (units.length === 0) {
    return null;
  }

  return (
    <TextField
      style={{verticalAlign: "unset"}}
      select
      value={mapping.units || ""}
      onChange={onChange}
    >
      {map(units, item => {
        return (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        );
      })}
    </TextField>
  );
};
