import compose from "recompose/compose";
import map from "lodash/fp/map";
import reduce from "lodash/fp/reduce";

const minMax = array => [Math.min(...array), Math.max(...array)];

const splitCoordinates = reduce(
  ([x, y], point) => [[...x, point.x], [...y, point.y]],
  [[], []],
);

const mapToDomain = compose(
  map(minMax),
  splitCoordinates,
);

export default mapToDomain;
