export const RECOMMENDATION_TYPES = {
  AUTO_ACCEPT_IDLE_TIME: "autoAcceptIdleTime",
  AUTO_ACCEPT_SPEED_RANGE: "autoAcceptSpeedRange",
  AUTO_ACCEPT_TIMER: "autoAcceptTimer",
  IDLE_TIME: "idleTime",
  LUFKIN_DOWNHOLE: "lufkinDownhole",
  LUFKIN_DOWNHOLE_SECONDARY: "lufkinDownholeSecondary",
  LUFKIN_DOWNHOLE_VFD: "lufkinDownholeVFD",
  LUFKIN_DOWNHOLE_VFD_SECONDARY: "lufkinDownholeVFDSecondary",
  LUFKIN_SURFACE: "lufkinSurface",
  LUFKIN_SURFACE_VFD: "lufkinSurfaceVFD",
  MANUAL: "manual",
  SPEED_RANGE: "speedRange",
  TIMER: "timer",
  WELL_PILOT_DOWNHOLE: "wellPilotDownhole",
  WELL_PILOT_SURFACE: "wellPilotSurface",
};

export default {RECOMMENDATION_TYPES};
