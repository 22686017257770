import addArea from "utils/charts/add-area";
import addLine from "utils/charts/add-line";
import colors from "theme/colors";
import isNil from "lodash/isNil";

function mapData(d) {
  return d.casingPressure || [];
}

function mapTime(d) {
  return new Date(d.createdOn);
}

function mapCasing(d) {
  return d.value;
}

function mapMaxCasing(d) {
  if (isNil(d.max)) {
    return mapCasing(d);
  }

  return d.max;
}

function mapMinCasing(d) {
  if (isNil(d.min)) {
    return mapCasing(d);
  }

  return d.min;
}

const styles = {
  area: {
    fill: colors.casingPressure[100],
  },

  line: {
    stroke: colors.casingPressure[400],
  },
};

export default ({clipPathId, selection, register, scales}) => {
  const options = {
    clipPathId,
    mapData,
    mapX: mapTime,
    mapY: mapCasing,
    mapMaxY: mapMaxCasing,
    mapMinY: mapMinCasing,
    selection,
    xScale: scales.time,
    yScale: scales.kpa,
  };

  const area = addArea({...options, styles: styles.area});
  const line = addLine({...options, styles: styles.line});

  return register({
    draw(props) {
      const {config} = props;

      area.hide();
      line.hide();

      if (config.casingPressure && config.casingPressure.state > 0) {
        line.show();
      }

      if (config.casingPressure && config.casingPressure.state === 2) {
        area.show();
      }

      area.draw(props);
      line.draw(props);
    },

    zoom(props) {
      area.zoom(props);
      line.zoom(props);
    },
  });
};
