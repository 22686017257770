import React, {Fragment} from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import {anomalyDisplayName} from "parsers/anomaly-display-name";
import Tooltip from "components/material-tooltip";

import Pill from "./pill";

const styles = () => ({
  tooltip: {
    marginLeft: ".25rem",
  },
  list: {
    listStyle: "none",
    paddingLeft: 0,
    marginTop: 0,
    marginBottom: 0,
  },
});

const List = ({className, list}) => (
  <ul className={className}>
    {list.map(({type}, i) => (
      <li key={i} style={{textTransform: "capitalize"}}>
        {anomalyDisplayName(type)}
      </li>
    ))}
  </ul>
);

const Anomalies = ({classes, list}) => {
  const [firstAnomaly, ...otherAnomalies] = list;

  if (list.length === 0) {
    return <Fragment>--</Fragment>;
  }

  return (
    <Fragment>
      {anomalyDisplayName(firstAnomaly.type)}
      {otherAnomalies.length > 0 && (
        <Tooltip
          title={<List className={classes.list} list={otherAnomalies} />}
        >
          <span className={classes.tooltip}>
            <Pill content={`+${otherAnomalies.length}`} />
          </span>
        </Tooltip>
      )}
    </Fragment>
  );
};

export default withStyles(styles)(Anomalies);
