import {depth} from "@ambyint/common/unit-converter";
import getUser from "utils/get-user";
import valueOf from "utils/value-of";

import {exceedsThresholdAtDepth} from "../../dls-threshold";

const processWellboreSurvey = (inputSurvey, pumpDepth) => {
  const wellboreData = inputSurvey.map(point => ({
    ...point,
    x: valueOf(point.x),
    y: valueOf(point.y),
    z: valueOf(point.z),
    trueVerticalDepth: -valueOf(point.z),
    measuredDepth: valueOf(point.measuredDepth),
    dlsMap: exceedsThresholdAtDepth(
      point.doglegSeverityDeg30m,
      depth.convert({
        value: valueOf(point.measuredDepth),
        to: "m",
        from: getUser().unitsOfMeasure,
      }),
      depth.convert({
        value: pumpDepth,
        to: "m",
        from: getUser().unitsOfMeasure,
      }),
    )
      ? 1
      : 0,
  }));

  return wellboreData;
};

export default processWellboreSurvey;
