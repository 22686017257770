import React from "react";
import {push} from "react-router-redux";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {reduxForm, formValueSelector} from "redux-form";
import find from "lodash/find";
import fromPairs from "lodash/fromPairs";
import trim from "lodash/trim";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import colors from "theme/colors";
import pluralize from "@ambyint/common/utils/format/format-pluralize";

import Breadcrumbs from "components/breadcrumbs";
import FormButton from "components/form-button";
import PaperContainer from "components/paper-container";
import TextField from "components/forms/text-field";

import {
  fetchOrganizationWells,
  inviteUser,
  selectOrganization,
} from "actions/organizations";

import Header from "./header";
import createListView from "./list-view";

import AccessControls from "./access-controls";
import PageHeader from "./page-header";
import selectOrganizationUserDetails from "./select-organization-user-details";
import validate from "./validate-invite-user";

const ListView = createListView("invite-user");

const selector = formValueSelector("invite-user");
const mapStateToProps = state => {
  const {organizations, organizationsWells} = state;

  const {organizationName, organizationWells} = selectOrganizationUserDetails(
    organizations,
    organizationsWells,
  );

  return {
    organizationName,
    organizationWells,
    organizations: state.organizations,
    wells: state.wells,
    currentValues: {
      accessLevel: selector(state, "accessLevel"),
      isInvitedUserApprover: selector(state, "isInvitedUserApprover"),
      wells: selector(state, "wells"),
    },
    initialValues: {
      accessLevel: "Limited",
      email: "",
      firstName: "",
      isInvitedUserApprover: false,
      lastName: "",
      wells: {},
    },
  };
};

class InviteUser extends React.Component {
  state = {
    searchPhrase: "",
  };

  componentWillMount() {
    const {
      dispatch,
      match: {
        params: {organizationId},
      },
    } = this.props;

    dispatch(selectOrganization({organizationId}));
    dispatch(fetchOrganizationWells({organizationId}));
  }

  render() {
    const {
      change,
      currentValues,
      dispatch,
      handleSubmit,
      match: {
        params: {organizationId},
      },
      organizationName,
      organizationWells,
      organizations,
      reset,
      submitFailed,
      submitting,
    } = this.props;

    return (
      <form
        onSubmit={handleSubmit(values => {
          const {
            accessLevel,
            email,
            firstName,
            isInvitedUserApprover,
            lastName,
            wells,
          } = values;

          const mapWellIdToDownholeLocation = wellId => {
            const well = find(organizationWells, {wellId});
            const isAssigned = wells[wellId];
            return [well.downholeLocation, isAssigned];
          };

          const downholeLocations = fromPairs(
            Object.keys(wells).map(mapWellIdToDownholeLocation),
          );

          dispatch(
            inviteUser({
              accessLevel,
              email: trim(email),
              firstName: trim(firstName),
              isInvitedUserApprover,
              lastName: trim(lastName),
              organizationId,
              downholeLocations,
              wellIds: wells,
            }),
          );

          this.emailTextField.focus();
        })}
      >
        <div style={{marginBottom: 16}}>
          <Breadcrumbs
            crumbs={[
              {
                label: organizationName,
                pathname: "/profile/organizations",
              },
              {
                label: "Manage Users",
                pathname: `/profile/organization/${
                  organizations.selectedOrganizationId
                }/users`,
              },
              {
                label: "Invite User",
              },
            ]}
          />
        </div>

        <PageHeader
          title={"INVITE USER"}
          subtitle={
            "Invite a set of users with the following permissions and well assignments, they'll get an email to complete their invitation."
          }
        />

        <PaperContainer padded style={{marginBottom: 16}}>
          <Grid container spacing={2}>
            <Grid item xs>
              <TextField
                autoFocus
                name="email"
                fullWidth
                required
                showError={submitFailed}
                inputRef={ref => (this.emailTextField = ref)}
              />
            </Grid>
            <Grid item xs>
              <TextField
                name="firstName"
                fullWidth
                required
                showError={submitFailed}
              />
            </Grid>
            <Grid item xs>
              <TextField
                name="lastName"
                fullWidth
                required
                showError={submitFailed}
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <FormButton
              center
              disabled={submitting}
              onClick={() => {
                reset();
                dispatch(push(`/profile/organization/${organizationId}/users`));
              }}
            >
              Cancel
            </FormButton>
            <FormButton center disabled={submitting} type="submit">
              Send Invitation
            </FormButton>
          </div>
        </PaperContainer>

        <ListView
          header={filteredWells => (
            <Header
              countRows={filteredWells.length}
              onChange={event => {
                this.setState({searchPhrase: event.target.value});
              }}
              placeholder="Search for wells"
            />
          )}
          containerStyle={{
            maxHeight: 580,
            marginTop: 8,
          }}
          organizationWells={organizationWells || {}}
          assignedWells={currentValues.wells || {}}
          reset={reset}
          searchPhrase={this.state.searchPhrase}
          footer={wellsSelected => (
            <div
              style={{
                alignItems: "baseline",
                borderTop: "1px solid rgb(224, 224, 224)",
                display: "flex",
                flexBasis: "51px",
                flexShrink: "0",
                justifyContent: "flex-end",
              }}
            >
              <Typography
                variant="body2"
                style={{
                  color: colors.grey[600],
                  fontWeight: "bold",
                  margin: 16,
                }}
              >{`${wellsSelected.length} well${pluralize(
                wellsSelected,
              )} will be assigned to the invited user`}</Typography>
            </div>
          )}
        />

        <AccessControls
          accessLevel={currentValues.accessLevel}
          isInvitedUserApprover={currentValues.isInvitedUserApprover}
          organizationId={organizationId}
          onAccessChanged={({accessLevel, isInvitedUserApprover}) => {
            change("accessLevel", accessLevel);
            change("isInvitedUserApprover", isInvitedUserApprover);
          }}
        />
      </form>
    );
  }
}

let Component = reduxForm({
  form: "invite-user",
  validate,
})(InviteUser);

Component = connect(mapStateToProps)(Component);
Component = withRouter(Component);

export default Component;
