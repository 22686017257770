import {compose} from "recompose";
import {connect} from "react-redux";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import React from "react";
import takeRight from "lodash/fp/takeRight";
import map from "lodash/fp/map";
import get from "lodash/fp/get";
import withStyles from "@material-ui/core/styles/withStyles";

import {DEVIATION_OUTLIER_THRESHOLD} from "constants/deviation";
import getUnits from "utils/get-units";

import DeviationPlot from "../deviation-plot";
import WellHeader from "../well-header";

const styles = {
  main: {
    padding: "20px",
  },

  stripe: {
    backgroundColor: "#faf9f7",
  },
};

const mapStateToProps = ({deviationReport}) => ({
  deviationFilter: deviationReport.deviationFilter.type,
});

const enhance = compose(
  connect(mapStateToProps),
  withStyles(styles),
);

const generateSparkLineData = (deviationType, wellData, timePeriod) =>
  takeRight(timePeriod, wellData).map((dataPoint, index) => ({
    x: index,
    y: get(deviationType, dataPoint),
  }));

const generateAvgData = (wellData, timePeriod, avg) =>
  takeRight(timePeriod, wellData).map((dataPoint, index) => ({
    x: index,
    y: avg,
  }));

class WellReport extends React.Component {
  componentDidMount() {
    if (this.props.onReportDisplayed)
      this.props.onReportDisplayed(this.props.rowId);
  }

  render() {
    const {classes, well, timePeriod, rowId, deviationFilter} = this.props;

    return (
      <div className={clsx(classes.main, {[classes.stripe]: rowId % 2})}>
        <WellHeader
          rowNum={rowId + 1}
          wellName={well.displayName}
          fieldName={well.fieldName}
          downholeLocation={well.downholeLocation}
        />
        <Grid container>
          {map(
            report => (
              <Grid
                key={`${well.displayName}_${report.type}`}
                item
                xs={3}
                className={clsx({
                  [classes.active]: report.type === deviationFilter,
                })}
              >
                <DeviationPlot
                  data={generateSparkLineData(
                    report.type,
                    well.dailySummaries,
                    timePeriod,
                  )}
                  avgData={generateAvgData(
                    well.dailySummaries,
                    timePeriod,
                    report.avg,
                  )}
                  title={report.label}
                  value={report.last}
                  unit={getUnits()[report.unit] || report.unit}
                  deviation={report.deviation}
                  deviationPercent={report.absPercent}
                  outlier={report.absPercent >= DEVIATION_OUTLIER_THRESHOLD}
                  active={report.type === deviationFilter}
                  noData={report.missingAllData}
                />
              </Grid>
            ),
            well.deviations,
          )}
        </Grid>
      </div>
    );
  }
}

export default enhance(WellReport);
