import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import Typography from "@material-ui/core/Typography";
import TagMultiple from "mdi-material-ui/TagMultiple";

const useStyles = makeStyles(({spacing, colors}) => ({
  header: {
    margin: spacing(4),
    display: "flex",
    alignItems: "center",
    color: colors.warmGrey[600],
  },
  icon: {
    marginRight: spacing(1),
  },
}));

const Header = () => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <TagMultiple className={classes.icon} />
      <Typography variant="h6">TAGS</Typography>
    </div>
  );
};

export default Header;
