import get from "lodash/get";
import last from "lodash/last";

export const getLatestMiniCard = well => {
  return last(
    get(well, "eventsByType.miniCards", []).sort(
      (a, b) => new Date(a.createdOn) - new Date(b.createdOn),
    ),
  );
};
