import {get, isNil} from "lodash";
import {push} from "react-router-redux";
import {withStyles} from "@material-ui/core/styles";
import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import ReactList from "react-list";

import {eventCategory, eventAction} from "constants/analytics";
import {CLASSIFICATION_SORT} from "constants/classification";
import {sendAnalyticsEvent} from "components/analytics";
import {getProductionValues} from "utils/get-production-values";

import {renderWellRow} from "./render-well-row";
import {renderWellTable} from "./render-well-table";

const styles = ({palette: {background}}) => ({
  row: {
    cursor: "pointer",
    "&:nth-of-type(odd)": {
      backgroundColor: background.default,
    },
  },
  table: {
    tableLayout: "fixed",
  },
});

const getWellByClassification = (well, classification, fallback) => {
  if (classification !== CLASSIFICATION_SORT.TOTAL_LIQUID) {
    return get(well, classification, fallback);
  }

  return getProductionValues(get(well, "dailySummaries"), "totalLiquid").value;
};

const sortWellsByClassification = (wells, classification, sortAsc) =>
  Array.isArray(wells)
    ? wells.sort((a, b) => {
        const fallBackVal = sortAsc
          ? Number.POSITIVE_INFINITY
          : Number.NEGATIVE_INFINITY;
        const aValueRaw = getWellByClassification(
          a,
          classification,
          fallBackVal,
        );
        const bValueRaw = getWellByClassification(
          b,
          classification,
          fallBackVal,
        );

        // This additional checks is for the case where null or undefined is the actual value for the object
        const aVal = isNil(aValueRaw) ? fallBackVal : aValueRaw;
        const bVal = isNil(bValueRaw) ? fallBackVal : bValueRaw;

        const eitherValIsString =
          typeof aVal === "string" || typeof bVal === "string";
        const reverse = sortAsc ? 1 : -1;
        return eitherValIsString
          ? reverse * aVal.localeCompare(bVal)
          : reverse * ((aVal > bVal) - (bVal > aVal));
      })
    : wells;

const WellList = ({
  unsortedWells,
  unitsOfMeasure,
  classes,
  dispatch,
  sortBy,
  doSort,
}) => {
  const [sortAsc, setSortAsc] = useState(false);
  const [currentHoverId, setCurrentHoverId] = useState(null);

  const wells = sortWellsByClassification(unsortedWells, sortBy, sortAsc);

  const onClickWellRow = index => () =>
    dispatch(
      push(
        `/well/${encodeURIComponent(wells[index].downholeLocation)}/analysis`,
      ),
    );

  const onClickColumn = field => () => {
    sendAnalyticsEvent(
      eventCategory.fieldOptimization,
      eventAction.sort,
      `${field}:${sortAsc ? "asc" : "desc"}`,
    );
    setSortAsc(!sortAsc);
    doSort(field);
  };

  const itemRenderer = renderWellRow({
    className: classes.row,
    onClick: onClickWellRow,
    wells,
    unitsOfMeasure,
    currentHoverId,
    setCurrentHoverId,
  });

  const itemsRenderer = renderWellTable({
    className: classes.table,
    unitsOfMeasure,
    onClickColumn,
    sortAsc,
    sortBy,
  });

  return (
    <Paper className={classes.paper}>
      <Grid container className={classes.container}>
        <Grid item sm={12}>
          <ReactList
            itemRenderer={itemRenderer}
            itemsRenderer={itemsRenderer}
            length={wells.length}
            type="simple"
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default withStyles(styles)(WellList);
