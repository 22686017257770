import {types} from "./types";

export const removeNotificationType = ({user, type}) => {
  return {
    type: types.removeNotificationType,
    payload: {
      user,
      type,
    },
  };
};
