import React, {useState} from "react";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";

import {sendAnalyticsEvent} from "components/analytics";
import {eventCategory, eventAction} from "constants/analytics";
import FractionalCount from "components/fractional-count";

import EmptyStateTwo from "./empty-state-two";
import Recommendation from "./recommendation";

const styles = theme => ({
  root: {
    background: "rgba(0, 0, 0, 0)",
    margin: 0,
  },
  header: {
    display: "flex",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    alignItems: "center",
    justifyContent: "space-between",
  },
  recommendationContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
    gridRowGap: "16px",
    gridColumnGap: "16px",
  },
  title: {
    color: theme.colors.warmGrey[600],
    fontWeight: 400,
    paddingRight: theme.spacing(1),
  },
  button: {
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.colors.warmGrey[100],
    },
    "& div": {
      cursor: "pointer",
    },
  },
  count: {
    marginRight: theme.spacing(1),
  },
  divider: {
    borderTop: `1px solid ${theme.colors.warmGrey[200]}`,
    visibility: "visible",
  },
});

function RecommendationsContainer(props) {
  const {
    accept,
    animate,
    automate,
    classes,
    dismiss,
    lastRemovedRec,
    recommendations,
    totalRecommendationsCount,
    redirect,
    resetLastRemovedRecIndex,
    title,
  } = props;
  const [open, setOpen] = useState(true);
  const toggleOpen = () => {
    sendAnalyticsEvent(
      eventCategory.recommendationsWorkflow,
      open ? eventAction.collapseContainer : eventAction.expandContainer,
      title,
    );
    setOpen(!open);
  };

  return (
    <>
      <div className={classes.header}>
        <Button className={classes.button} onClick={toggleOpen}>
          <Typography variant="subtitle1" className={classes.title}>
            {title}
          </Typography>
        </Button>
        <FractionalCount
          denominator={totalRecommendationsCount}
          numerator={recommendations.length}
          className={classes.count}
        />
      </div>
      <Collapse in={open} classes={{hidden: classes.divider}}>
        {!recommendations.length && <EmptyStateTwo />}
        <TransitionGroup className={classes.recommendationContainer}>
          {recommendations.map((rec, i) => (
            <CSSTransition
              unmountOnExit
              onExited={animate && resetLastRemovedRecIndex}
              timeout={1150}
              key={rec.recommendationId}
              clsx="fade"
            >
              <Recommendation
                moveRec={
                  animate &&
                  lastRemovedRec.id !== rec.recommendationId &&
                  i >= lastRemovedRec.index
                }
                redirect={redirect(rec)}
                accept={accept && accept(rec)}
                dismiss={dismiss && dismiss(rec, i)}
                automate={automate && automate(rec)}
                {...rec}
              />
            </CSSTransition>
          ))}
        </TransitionGroup>
      </Collapse>
    </>
  );
}

export default withStyles(styles)(RecommendationsContainer);
