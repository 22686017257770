import React from "react";
import {connect} from "react-redux";
import {push} from "react-router-redux";
import {withRouter} from "react-router";

import styles from "routes/profile/organization/styles";

import Breadcrumbs from "components/breadcrumbs";

import {set as selectOrganization} from "actions/organization-view";
import CreateServiceAccount from "./create-service-account";
import ListView from "./list-view";

const mapStateToProps = state => {
  return {
    organizations: state.organizations,
    organizationServiceAccounts: state.organizationServiceAccounts,
    serviceAccounts:
      state.organizationServiceAccounts.filtered[
        state.organizationServiceAccounts.selectedOrganizationId
      ],
  };
};

class ManageServiceAccounts extends React.Component {
  componentWillMount() {
    const {
      dispatch,
      match: {
        params: {organizationId},
      },
    } = this.props;
    dispatch(selectOrganization({currentOrganization: organizationId}));
  }

  render() {
    const {
      dispatch,
      organizations,
      serviceAccounts,
      match: {
        params: {organizationId},
      },
    } = this.props;

    const selectedOrganization =
      organizations.all[organizations.selectedOrganizationId];
    const allServiceAccounts = selectedOrganization
      ? selectedOrganization.users.filter(
          user => user.type === "serviceAccount",
        )
      : [];
    const organizationName = selectedOrganization
      ? selectedOrganization.organizationName
      : "";

    if (
      !organizations.selectedOrganizationId ||
      !organizations.all[organizations.selectedOrganizationId]
    ) {
      return <div style={styles.pageContainer} />;
    }

    return (
      <div style={styles.pageContainer}>
        <div style={{position: "relative", marginBottom: 16}}>
          <div style={{marginBottom: 16}}>
            <Breadcrumbs
              crumbs={[
                {
                  label: organizationName,
                  pathname: "/profile/organizations",
                },
                {
                  label: "Manage Service Accounts",
                },
              ]}
            />
          </div>
          <CreateServiceAccount
            allServiceAccounts={allServiceAccounts}
            onClick={() => {
              dispatch(
                push(
                  `/profile/organization/${
                    organizations.selectedOrganizationId
                  }/serviceaccount/create`,
                ),
              );
            }}
          />

          <ListView
            organizationId={organizationId}
            serviceAccounts={serviceAccounts}
            onRowClick={serviceAccount => {
              dispatch(
                push(
                  `/profile/organization/${
                    organizations.selectedOrganizationId
                  }/serviceaccount/${serviceAccount.clientUserId}`,
                ),
              );
            }}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(ManageServiceAccounts));
