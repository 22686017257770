/*
 * This file is used to keep track of all new feature / pilot flags to be used. If a new one is needed,
 * add it to the object below with a brief comment on it's purpose
 */
export const internalExportAnalysis = "internal-export-analysis";

/**
 * Allows users to access the well surveillance page
 */
export const wellSurveillance = "well-surveillance";

export default {
  internalExportAnalysis,
  wellSurveillance,
};
