import ReactGA from "react-ga";

const sendAnalyticsEvent = (category, action, label = false) => {
  ReactGA.event({
    category,
    action,
    ...(label && {label}),
  });
};

export default sendAnalyticsEvent;
