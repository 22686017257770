/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const linkStyle = {
  color: "#fc6621",
  cursor: "pointer",
};

const textAlignCenter = {textAlign: "center"};

export default props => {
  return (
    <Grid item xs={12}>
      <Typography variant="subtitle1" style={textAlignCenter}>
        Let's get you back to{" "}
        <a style={linkStyle} onClick={props.onClick}>
          safety
        </a>{" "}
        or get in touch with our customer success team{" "}
        <a
          style={linkStyle}
          href="mailto:support@ambyint.com?Subject=Encountered an Error"
          target="_top"
        >
          support@ambyint.com
        </a>
        .
      </Typography>
    </Grid>
  );
};
