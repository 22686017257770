import {Field} from "redux-form";
import React from "react";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Tooltip from "components/tooltip";
import MuiCheckbox from "@material-ui/core/Checkbox";
import {withStyles} from "@material-ui/core/styles";

const styleSheet = {
  label: {
    top: "-8px",
    display: "block",
    transform: "translate(0, 1.5px) scale(0.75)",
    transformOrigin: "top left",
  },
};

export const Checkbox = withStyles(styleSheet, {
  name: "DialogOverrides",
})(props => {
  const {header, classes, input, label, tooltip, style = {}} = props;

  let checkbox = (
    <MuiCheckbox
      checked={input.value}
      label={label}
      onChange={input.onChange}
      style={style}
      disableRipple
    />
  );

  if (label) {
    checkbox = <FormControlLabel control={checkbox} label={label} />;
  }

  return (
    <div>
      {header && <InputLabel className={classes.label}>{header}</InputLabel>}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: "0px",
        }}
      >
        {checkbox}

        {tooltip && <Tooltip text={tooltip} />}
      </div>
    </div>
  );
});

export default props => <Field {...props} component={Checkbox} />;
