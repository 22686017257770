import {getProductionValues} from "utils/get-production-values";

const sortProduction = ({values, reverse}) =>
  values.sort((a, b) => {
    let aV =
      getProductionValues(a.dailySummaries, "totalLiquid").value ||
      Infinity * reverse;
    let bV =
      getProductionValues(b.dailySummaries, "totalLiquid").value ||
      Infinity * reverse;

    if (aV === bV) {
      aV = a.displayName.toLowerCase();
      bV = b.displayName.toLowerCase();
    }

    return reverse * ((aV > bV) - (bV > aV));
  });

export default sortProduction;
