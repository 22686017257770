import {types} from "./types";

export const setWebhookDeleted = options => {
  const {clientUserId, webhookId} = options;

  return {
    type: types.setWebhookDeleted,
    payload: {
      clientUserId,
      webhookId,
    },
  };
};
