import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";

import React from "react";

import PlainText from "components/forms/plain-text";
import TextField from "components/forms/text-field";

const ManualRecommendation = () => {
  return (
    <DialogContent>
      <Grid container>
        <Grid item xs={12}>
          <PlainText name="description" />
        </Grid>

        <Grid item xs={12}>
          <TextField name="comment" fullWidth label="Comment" />
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default ManualRecommendation;
