import {actions} from "react-redux-form";
import store from "store";
import validateDownholeLocation from "utils/validation/downhole-location";

import formPath from "../form-path";

const getDownholeLocationError = bypassExists => {
  const {downholeLocation, wellCountry} = store.getState().forms.addWell;

  return validateDownholeLocation(wellCountry, bypassExists)(downholeLocation);
};

export default async ({bypassExists = false} = {}) => {
  store.dispatch(
    actions.setFieldsErrors(formPath, {
      downholeLocation: await getDownholeLocationError(bypassExists),
    }),
  );
};
