import React, {useState} from "react";
import {connect} from "react-redux";
import {KeyboardTimePicker} from "@material-ui/pickers";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import get from "lodash/get";
import InputAdornment from "@material-ui/core/InputAdornment";
import isNumber from "lodash/isNumber";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import {savePumpoffSettings, setDowntime} from "actions/wells";
import PUMPOFF_SETTING_MODES from "constants/pumpoff-setting-modes";

import {validateDowntime} from "./validate";
import {useModeStyles} from "./use-mode-styles";

const PumpFillage = props => {
  const classes = useModeStyles();
  const [downtime, setDowntime] = useState(
    moment()
      .startOf("day")
      .add(get(props, "well.scheduledDowntime.minutes", 1), "minutes")
      .add(get(props, "well.scheduledDowntime.hours", 0), "hours"),
  );
  const [fillage, setFillage] = useState(
    get(props, "well.pumpOffSetting.primaryFillage.value"),
  );
  const [strokes, setStrokes] = useState(
    get(props, "well.pumpOffSetting.strokes"),
  );

  const onChangeHandler = handler => ({target: {value}}) => {
    if (value === "") return handler(value);
    if (value >= 0 && value <= 100) return handler(Number(value));
    value > 100 && handler(100);
  };

  const save = () => {
    const {downholeLocation, pumpOffSetting} = props.well;

    props.setDowntime({
      downholeLocation,
      data: {
        hours: Number(downtime.format("H")),
        minutes: Number(downtime.format("m")),
      },
    });

    props.savePumpoffSettings({
      downholeLocation,
      wellId: props.well.wellId,
      data: {
        ...(pumpOffSetting || {}),
        primaryFillage: {
          ...(get(pumpOffSetting, "primaryFillage") || {}),
          value: fillage,
        },
        strokes,
        mode: PUMPOFF_SETTING_MODES.STANDARD_DOWNHOLE,
      },
    });

    props.onDone();
  };
  const downtimeErr = validateDowntime(
    downtime,
    props.well.safeOperatingParameters,
  );

  const missingValue = !isNumber(fillage) || !isNumber(strokes) || !downtime;
  const saveDisabled = !!downtimeErr || missingValue;

  return (
    <div className={clsx(classes.body, classes.pumpFillage)}>
      <div className={clsx(classes.spanTwo, classes.alignToBottom)}>
        <Typography variant="body2" className={classes.label}>
          Fillage*
        </Typography>
        <div>
          <TextField
            value={fillage}
            onChange={onChangeHandler(setFillage)}
            error={!isNumber(fillage)}
            variant="outlined"
            className={classes.input}
            InputProps={{
              classes: {input: classes.inputPadding},
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </div>
        <Typography variant="caption" className={classes.label} />
      </div>
      <div className={classes.alignToBottom}>
        <Typography variant="body2" className={classes.label}>
          Consecutive Strokes*
        </Typography>
        <div>
          <TextField
            className={classes.input}
            value={strokes}
            error={!isNumber(strokes)}
            onChange={onChangeHandler(setStrokes)}
            variant="outlined"
            InputProps={{
              classes: {input: classes.inputPadding},
            }}
          />
        </div>
        <Typography variant="caption" className={classes.label} />
      </div>
      <div className={clsx(classes.alignToBottom)}>
        <Typography variant="body2" className={classes.label}>
          Downtime Duration*
        </Typography>
        <div>
          <KeyboardTimePicker
            ampm={false}
            autoOk
            openTo="minutes"
            className={classes.timeInput}
            error={!!downtimeErr}
            keyboardIcon={<AccessTimeIcon />}
            helperText={downtimeErr || "hh:mm"}
            inputVariant="outlined"
            PopoverProps={{
              anchorOrigin: {horizontal: 155, vertical: "bottom"},
            }}
            InputProps={{
              classes: {
                input: classes.inputPadding,
                adornedEnd: classes.noPadding,
              },
            }}
            onChange={val => setDowntime(val)}
            value={downtime}
            variant="inline"
          />
        </div>
      </div>
      <Typography className={classes.required} variant="caption">
        *Required
      </Typography>
      <div className={clsx(classes.alignToBottom, classes.buttonGroup)}>
        <Button
          onClick={save}
          disabled={saveDisabled}
          className={clsx(classes.save, classes.spanTwo)}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
        <Button onClick={props.onDone} className={classes.cancel}>
          cancel
        </Button>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  savePumpoffSettings,
  setDowntime,
};

export default connect(
  null,
  mapDispatchToProps,
)(PumpFillage);
