const initialState = {
  alias: "",
  downholeLocation: "",
  fieldName: "",
  pumpingUnitType: "pumpjack",
  surfaceLocation: "",
  surfaceLocationLatitude: "",
  surfaceLocationLongitude: "",
  wellCountry: "",
  wellProvinceState: "",
};

export default initialState;
