import {push} from "react-router-redux";
import {replace} from "actions/history";
import {initialize} from "redux-form";
import fetch from "epics/async-fetch";

import {createSwitchEpic} from "epics/create-epic";
import {
  setUserAddedToOrganization,
  setOrganizationServiceAccount,
  types,
} from "actions/organizations";
import {show} from "actions/snackbar";

export default createSwitchEpic(
  [types.createServiceAccount],
  async ({payload}) => {
    try {
      const {
        downholeLocations,
        organizationId,
        serviceName,
        webhooks,
      } = payload;

      const webhooksWithHardcodedEvent = webhooks.map(webhook => ({
        ...webhook,
        // NOTE: hard-coded for now until we decide on granularity of event types.
        events: ["well::event::added"],
      }));

      const serviceAccount = await fetch(
        `/organization/${encodeURIComponent(
          organizationId,
        )}/serviceAccount/create`,
        {},
        {
          method: "POST",
          body: JSON.stringify({
            assignedWells: Object.keys(downholeLocations).filter(
              downholeLocation => downholeLocations[downholeLocation] === true,
            ),
            organizationId,
            serviceName,
            webhooks: webhooksWithHardcodedEvent,
          }),
        },
      );

      return [
        show({
          message:
            "Your request to create a service account submitted successfully!",
        }),
        setUserAddedToOrganization({
          clientUser: {...serviceAccount, type: "serviceAccount"},
          organizationId,
        }),
        initialize("create-service-account", {
          serviceName: "",
          webhooks: [],
          wells: {},
        }),
        setOrganizationServiceAccount({data: serviceAccount, organizationId}),
        push(
          `/profile/organization/${organizationId}/serviceaccount/${
            serviceAccount.clientUserId
          }`,
        ),
      ];
    } catch (err) {
      if (err.status === 401 || err.status === 403) {
        return [
          replace("/"),
          show({
            message: "Unauthorized to create service account for organization",
          }),
        ];
      }
      return [replace(`/error?code=${err.status}`)];
    }
  },
);
