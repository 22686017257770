import {connect} from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import find from "lodash/find";
import map from "lodash/map";
import Paper from "@material-ui/core/Paper";
import React from "react";
import Typography from "@material-ui/core/Typography";

import {wait} from "@ambyint/common/utils/wait";
import colors from "@ambyint/colors";

import {importTypes} from "constants/import-types";
import {resetSettings, setMappings} from "actions/import-settings";
import fetch from "epics/async-fetch";

import {getOption} from "./options";
import {Mappings} from "./mappings";
import {DragDrop} from "./drag-drop";
import {validateRow} from "./validate-row";
import {FileUpload} from "./file-upload";

const MAX_ROW_LIMIT = 30000;

const styles = {
  content: {
    display: "flex",
    justifyContent: "center",
    padding: 16,
    position: "absolute",
    top: 56,
    bottom: 0,
    left: 0,
    right: 0,
  },
  header: {
    padding: 16,
    maxWidth: "60%",
  },
  spaced: {
    marginBottom: 10,
  },
  paper: {
    border: `thin solid ${colors.grey[300]}`,
    borderRadius: 0,
  },
};

const validate = (data, mappings) => {
  const errors = [];

  for (let i = 0, l = data.length; i < l; i++) {
    errors.push(validateRow(mappings)(data[i]));
  }

  return errors;
};

const mapStateToProps = state => {
  return {
    settings: state.importSettings.wellEvent,
    user: state.auth.user,
  };
};

const defaultState = {data: undefined, errors: undefined, headers: undefined};

class CsvImport extends React.Component {
  state = {...defaultState};

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.data) {
      return {
        ...prevState,
        errors: validate(prevState.data, nextProps.settings.mappings),
      };
    }

    return prevState;
  }

  onError = err => {
    this.setState({error: err});
  };

  onRemoveErrors = () => {
    setTimeout(() => {
      const data = [];
      const errors = [];

      for (let i = 0, length = this.state.data.length; i < length; i++) {
        if (!this.state.errors[i].length) {
          data.push(this.state.data[i]);
          errors.push([]);
        }
      }

      this.setState({
        data,
        errors,
      });
    });
  };

  onRead = async parsed => {
    const {settings, user} = this.props;
    const {headers, data} = parsed;

    this.setState({complete: undefined, error: undefined, loading: true});

    await wait(100);

    const existing =
      settings.mappings && settings.mappings.length === headers.length;

    const mappings = existing
      ? settings.mappings
      : map(headers, header => {
          const option = getOption(header);

          const units =
            find(option.availableUnits, units =>
              new RegExp(units.type, "i").test(user.unitsOfMeasure),
            ) || {};

          return {
            ...option,
            units: units.value,
          };
        });

    if (data.length > MAX_ROW_LIMIT) {
      this.onError(new Error(`Import data exceeds ${MAX_ROW_LIMIT} rows.`));
    }

    const errors = validate(data, mappings);

    if (!existing) {
      this.setState({...defaultState}, async () => {
        this.props.dispatch(resetSettings({type: importTypes.WELL_EVENT}));
        this.props.dispatch(
          setMappings({
            type: importTypes.WELL_EVENT,
            mappings,
          }),
        );

        await wait(100);

        this.setState({...parsed, errors, loading: false});
      });
    } else {
      this.setState({...parsed, errors, loading: false});
    }
  };

  onUpload = async () => {
    const {settings} = this.props;
    const {data} = this.state;

    try {
      this.setState({loading: true});

      await fetch(
        "/imports",
        {},
        {
          method: "POST",
          body: {
            type: "wellEvent",
            data,
            mappings: map(settings.mappings, ({type, units}) => ({
              type,
              units,
            })),
          },
        },
      );

      this.setState({complete: true});
    } catch (res) {
      const json = await res.json();
      this.onError(new Error(json.message));
    }

    this.setState({loading: false});
  };

  render() {
    const {containerStyle = {}, settings} = this.props;
    const {complete, data, error, errors, headers, loading} = this.state;

    return (
      <Paper style={{...styles.paper, ...containerStyle}} elevation={0}>
        <DragDrop onRead={this.onRead} onError={this.onError}>
          {loading && <LinearProgress />}
          {!loading && error && (
            <div style={styles.header}>
              <Typography
                variant={"h4"}
                style={{...styles.spaced, color: colors.red[300]}}
              >
                Upload Error
              </Typography>
              <Typography variant={"subtitle1"} style={styles.spaced}>
                There was an issue with the file you attempted to upload:{" "}
                <i>{error.message}</i>
              </Typography>
              <Typography variant={"subtitle1"}>
                Drop or{" "}
                <FileUpload onError={this.onError} onRead={this.onRead}>
                  select
                </FileUpload>{" "}
                another file to continue.
              </Typography>
            </div>
          )}
          {!loading && !error && complete && (
            <div style={styles.header}>
              <Typography
                variant={"h4"}
                style={{...styles.spaced, color: colors.green[300]}}
              >
                Upload Success
              </Typography>
              <Typography variant={"subtitle1"}>
                Your file has been uploaded for processing successfully. To{" "}
                <FileUpload onError={this.onError} onRead={this.onRead}>
                  upload
                </FileUpload>{" "}
                another file, drop it here.
              </Typography>
            </div>
          )}
          {!loading && !error && !complete && !data && (
            <div style={styles.header}>
              <Typography variant={"h4"} style={styles.spaced}>
                Upload Production Data
              </Typography>
              <Typography variant={"subtitle1"}>
                Drag and drop or{" "}
                <FileUpload onError={this.onError} onRead={this.onRead}>
                  select
                </FileUpload>{" "}
                a file you wish to upload.
              </Typography>
            </div>
          )}
          {!error && !complete && data && (
            <Mappings
              headers={headers}
              loading={loading}
              data={data}
              errors={errors}
              onError={this.onError}
              onRead={this.onRead}
              onRemoveErrors={this.onRemoveErrors}
              onUpload={this.onUpload}
              settings={settings}
            />
          )}
        </DragDrop>
      </Paper>
    );
  }
}

CsvImport = connect(mapStateToProps)(CsvImport);

export {CsvImport};
