import React from "react";
import {ListItem, ListItemIcon, ListItemText} from "@material-ui/core";

import colors from "theme/colors";
import noop from "utils/noop";

const getDefaultStyle = () => {
  return {
    color: colors.ambyBlue[50],
  };
};

export default props => {
  const {style = {}, action = noop} = props;

  return (
    <ListItem button onClick={action}>
      <ListItemIcon style={{...getDefaultStyle(props), ...style}}>
        {props.icon}
      </ListItemIcon>
      <ListItemText
        primary={props.text}
        disableTypography
        style={{...getDefaultStyle(props), ...style}}
      />
    </ListItem>
  );
};
