import React, {useState} from "react";
import Collapse from "@material-ui/core/Collapse";
import withStyles from "@material-ui/core/styles/withStyles";

import {splitWells} from "utils/well/split-wells";
import {sendAnalyticsEvent} from "components/analytics";
import {workflows} from "constants/well-workflows";
import {eventCategory, eventAction} from "constants/analytics";
import {ANOMALIES_PANEL, OTHERS_PANEL} from "constants/local-storage-keys";

import WellGroupingHeader from "./well-grouping-header";

const styles = ({colors}) => ({
  divider: {
    borderTop: `2px solid ${colors.warmGrey[100]}`,
    visibility: "visible",
    borderRadius: 5,
  },
  container: {
    marginBottom: 24,
  },
});

const getPanelState = type => {
  const value = localStorage.getItem(type);
  return value !== "closed";
};

const WellStatus = ({classes, wells, WellListComponent}) => {
  const [isAnomaliesOpen, setIsAnomaliesOpen] = useState(
    getPanelState(ANOMALIES_PANEL),
  );
  const [isOthersOpen, setIsOthersOpen] = useState(getPanelState(OTHERS_PANEL));
  const toggleAnomalies = () => {
    sendAnalyticsEvent(
      eventCategory.anomalyWorkflow,
      isAnomaliesOpen
        ? eventAction.collapseContainer
        : eventAction.expandContainer,
    );
    localStorage.setItem(ANOMALIES_PANEL, isAnomaliesOpen ? "closed" : "open");
    setIsAnomaliesOpen(!isAnomaliesOpen);
  };
  const toggleOthers = () => {
    sendAnalyticsEvent(
      eventCategory.wellsDashboard,
      isOthersOpen
        ? eventAction.collapseContainer
        : eventAction.expandContainer,
    );
    localStorage.setItem(OTHERS_PANEL, isOthersOpen ? "closed" : "open");
    setIsOthersOpen(!isOthersOpen);
  };

  const {anomalyWells, otherWells} = splitWells(wells);
  const hasAnomalyWells = anomalyWells.length > 0;
  const hasOtherWells = otherWells.length > 0;
  const isAnomaliesCollapsed = !!(isAnomaliesOpen && anomalyWells.length);
  const isOthersCollapsed = !!(isOthersOpen && otherWells.length);

  return (
    <>
      <WellGroupingHeader
        title="Anomalies"
        count={anomalyWells.length}
        totalCount={wells.length}
        toggleCollapse={toggleAnomalies}
      />

      <Collapse
        in={isAnomaliesCollapsed}
        className={classes.container}
        classes={{
          hidden: classes.divider,
        }}
      >
        {hasAnomalyWells && (
          <WellListComponent
            wells={anomalyWells}
            workflow={workflows.ANOMALY}
          />
        )}
      </Collapse>

      <WellGroupingHeader
        title="Everything Else"
        count={otherWells.length}
        totalCount={wells.length}
        toggleCollapse={toggleOthers}
      />

      <Collapse
        in={isOthersCollapsed}
        className={classes.container}
        classes={{
          hidden: classes.divider,
        }}
      >
        {hasOtherWells && <WellListComponent wells={otherWells} />}
      </Collapse>
    </>
  );
};

export default withStyles(styles)(WellStatus);
