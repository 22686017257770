import max from "utils/validation/max";
import min from "utils/validation/min";
import isInteger from "utils/validation/integer";
import isNumber from "utils/validation/number";

const validTypes = ["ambyControlStopped", "fake"];

export default values => {
  const {type, value} = values;

  if (!validTypes.includes(type)) return {type: "Type is invalid"};

  return {
    ...(max(value, 720) ? {value: max(value, 720)} : {}),
    ...(min(value, 5) ? {value: min(value, 5)} : {}),
    ...(isInteger(value) ? {value: isInteger(value)} : {}),
    ...(isNumber(value) ? {ambyControlStopped: isNumber(value)} : {}),
  };
};
