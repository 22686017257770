import React, {useState, useEffect} from "react";
import get from "lodash/get";
import Typography from "@material-ui/core/Typography";
import {push} from "react-router-redux";
import {connect} from "react-redux";

import {getRecentlyVisitedWells} from "utils/recently-visited-wells";

import SearchResults from "./search-results";

const mapStateToProps = ({
  auth,
  wells: {byWellId},
  organizationView: {currentOrganization},
}) => ({
  userId: get(auth, "user.clientUserId", null),
  byWellId,
  currentOrganization,
});

const mapDispatchToProps = dispatch => ({
  routeToWell: ({downholeLocation}) =>
    dispatch(push(`/well/${encodeURIComponent(downholeLocation)}/analysis`)),
});

const RecentlyVisited = ({
  userId,
  byWellId,
  onClose,
  routeToWell,
  currentOrganization,
}) => {
  const [recentWells, setRecentWells] = useState([]);

  useEffect(
    () => {
      const recentWellIds = getRecentlyVisitedWells(
        userId,
        currentOrganization,
      );
      const recents = recentWellIds
        .map(id => ({data: byWellId[id]}))
        .filter(data => data.data);

      setRecentWells(recents);
    },
    [userId, byWellId, currentOrganization],
  );

  if (recentWells.length) {
    return (
      <div>
        <Typography variant="body2" style={{paddingTop: 15, paddingLeft: 24}}>
          Recently visited wells
        </Typography>
        <SearchResults
          results={recentWells}
          isRecentlyVisited
          hideTags
          preventDefaultSelection
          onClick={data => {
            onClose();
            routeToWell(data);
          }}
        />
      </div>
    );
  }

  return null;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecentlyVisited);
