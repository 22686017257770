import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const GasInterference = ({className}) => {
  return (
    <SvgIcon className={className} viewBox="0 0 227 133">
      <path
        d="M223.25 15.442c-12.905.917-26.683 6.514-41.52 16.286-17.126 11.28-34.722 27.276-56.413 50.11-7.27 7.654-31.16 33.686-35.558 38.332-7.166 7.567-10.754 9.33-20.87 9.33-5.462 0-22.451-.065-50.955-.195-7.982-.037-14.434-6.518-14.434-14.5v-96.64c0-8.009 6.492-14.5 14.5-14.5h174.4c.947 0 1.388-.011 3.934-.084 4.97-.143 7.875-.116 11.078.22 9.352.98 14.661 4.32 15.837 11.64z"
        stroke="currentColor"
        strokeWidth={7}
        fill="none"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export default GasInterference;
