import React from "react";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

const Row = props => {
  const {columns, onClick, square, data, className = ""} = props;

  return (
    <Paper
      elevation={0}
      style={{
        borderBottom: "1px solid rgb(224, 224, 224)",
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: "14px",
        height: "48px",
        padding: "0px 20px",
      }}
      className={className}
      square={square}
      onClick={onClick}
    >
      <Grid
        container
        alignItems="center"
        justify="space-around"
        spacing={0}
        style={{
          height: "100%",
          ...(onClick ? {cursor: "pointer", userSelect: "none"} : {}),
        }}
      >
        {columns.map((column, index) => {
          return (
            <Grid
              item
              key={index}
              xs={column.xs || Math.floor(12 / columns.length)}
              style={{
                fontWeight: 300,
                overflow: "hidden",
                textOverflow: "ellipsis",
                alignItems: "baseline",
                ...(index < columns.length - 1 ? {paddingRight: 16} : {}),
                ...(column.style || {}),
              }}
            >
              {column.render(data)}
            </Grid>
          );
        })}
      </Grid>
    </Paper>
  );
};

export default Row;
