import required from "utils/validation/required";
import validateFieldsOf from "utils/validation/validate-fields-of";
import email from "utils/validation/email";

export default values => {
  const validateField = validateFieldsOf(values);

  return {
    ...validateField("email", x => required(x)),
    ...validateField("email", x => email(x)),
    ...validateField("firstName", x => required(x)),
    ...validateField("lastName", x => required(x)),
  };
};
