/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {connect} from "react-redux";

import AmbyintLogo from "components/logo/ambyint-svg-logo";

import styles from "routes/login/styles";

const Login = props => {
  const {children} = props;

  return (
    <div style={styles.pageContainer}>
      <header style={styles.logoContainer}>
        <a
          role="button"
          tabIndex={0}
          style={{cursor: "pointer"}}
          onClick={() => {
            window.location.href = "/";
          }}
        >
          <AmbyintLogo width={100} style={styles.logo} />
        </a>
      </header>
      {children}
    </div>
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps)(Login);
