import React from "react";

import Tooltip from "components/material-tooltip";

const Status = ({displayName, type}) => (
  <Tooltip title={displayName}>
    <span>{type}</span>
  </Tooltip>
);

export default Status;
