import {connect} from "react-redux";
import {memo} from "react";
import {useEffect} from "react";
import get from "lodash/get";
import ReactGA from "react-ga";

import {getUserGroup} from "utils/analytics/get-user-group";
import {setInitialized} from "actions/analytics";

const mapDispatchToProps = dispatch => ({
  setInitialized: initialized => dispatch(setInitialized(initialized)),
});

const mapStateToProps = ({analytics, auth}) => ({
  analytics,
  auth,
});

const Analytics = memo(({analytics, auth, debug = false, setInitialized}) => {
  const user = get(auth, "user", false);

  useEffect(() => {
    if (user && !analytics.initialized) {
      const userGroup = getUserGroup(user);
      ReactGA.initialize(analytics.trackingId, {
        debug,
      });
      ReactGA.set({dimension1: userGroup});
      setInitialized(true);
    }
  });

  return null;
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Analytics);
