import {show} from "actions/snackbar";
import {types} from "actions/wells";
import fetch from "epics/async-fetch";

export default action$ => {
  return action$.ofType(types.saveNominalRpm).switchMap(async ({payload}) => {
    const {nominalRpm, downholeLocation} = payload;

    await fetch(
      `/optimization/well/${encodeURIComponent(downholeLocation)}/nominalRpm`,
      {},
      {
        body: JSON.stringify({
          nominalRpm,
        }),
        method: "PUT",
      },
    );

    return show({
      message: "You have successfully updated the nominal rpm.",
    });
  });
};
