import React from "react";
import isNil from "lodash/isNil";

import Codes from "routes/home/codes";
import TableCell from "components/table-cell";

import {TagCheckbox, TableRow} from "../well-list-common";
import Anomalies from "./anomalies";

export const renderWellRow = ({
  handleWellRowClick,
  wells,
  isSelectionMode,
  currentHoverId,
  setCurrentHoverId,
}) => (index, key) => {
  const {
    displayName,
    fieldName,
    status,
    displacement,
    wellId,
    downholeLocation,
    dailyAnomalySummaries,
  } = wells[index];

  const displacementToDisplay =
    isNil(displacement) || isNil(displacement.value)
      ? "--"
      : parseInt(displacement.value);

  const unsetHoverId = () => setCurrentHoverId(null);
  const setCurrentWell = () => setCurrentHoverId(wellId);
  const isHoverOnRow = currentHoverId === wellId;

  return (
    <TableRow
      key={key}
      wellId={wellId}
      onClick={handleWellRowClick({
        isSelectionMode,
        downholeLocation: downholeLocation,
        wellId: wellId,
      })}
      onMouseEnter={setCurrentWell}
      onMouseLeave={unsetHoverId}
    >
      <TableCell>
        <TagCheckbox wellIds={[wellId]} isSelectionMode={isSelectionMode} />
      </TableCell>
      <TableCell underline={!isSelectionMode && isHoverOnRow}>
        {displayName}
      </TableCell>
      <TableCell>{fieldName}</TableCell>
      <TableCell style={{textTransform: "capitalize"}}>
        <Anomalies list={dailyAnomalySummaries} />
      </TableCell>
      <TableCell>
        <Codes statuses={status.statuses} />
      </TableCell>
      <TableCell align="right">{displacementToDisplay}</TableCell>
      <TableCell style={{width: "5%"}} />
    </TableRow>
  );
};
