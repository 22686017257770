import React from "react";

import getPrivileges from "utils/get-privileges";
import privilegeTypes from "utils/privilege-types";
import getDisplayName from "utils/user/get-display-name";
import {show} from "actions/recommendation-action-dialog";
import store from "store";

import FeedItem from "../feed-item";
import {lineStyle} from "../styles";

const getOpenRecommendation = props => {
  const {event, well} = props;

  return (well.recommendations || []).find(
    recommendation =>
      recommendation.recommendationId === event.value.recommendationId &&
      recommendation.status === "open",
  );
};

export default props => {
  const {event, well} = props;

  const openRecommendation = getOpenRecommendation(props);
  const privileges = well ? getPrivileges(well.organization.id) : [];
  const hasControl = privileges.includes(privilegeTypes.well.control);

  const actionProps =
    !!openRecommendation && hasControl
      ? {
          onClick: () => {
            store.dispatch(show({well, recommendation: openRecommendation}));
          },
        }
      : {};

  return (
    <FeedItem event={event} {...actionProps}>
      <span style={lineStyle}>
        {getDisplayName(event)}
        {" has made a "} <strong>recommendation</strong>:
      </span>
      <span style={{display: "block"}}>{event.value.description}</span>
    </FeedItem>
  );
};
