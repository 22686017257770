import {getStatuses} from "@ambyint/common/well-status";

export default ({alert}) => {
  const codes = getStatuses(alert.information.statusCodes);
  let codeString = "";

  codes.forEach((c, i) => {
    if (i === 0) return (codeString = c.description);
    if (i === codes.length - 1)
      return (codeString = `${codeString} and ${c.description}.`);

    return (codeString = `${codeString}, ${c.description}`);
  });

  return `An alert has been issued with the following status codes ${codeString}`;
};
