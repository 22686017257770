import React from "react";

import Grid from "@material-ui/core/Grid";
import TableSortLabel from "@material-ui/core/TableSortLabel";

const getHeaderStyle = ({column, sortBy, staleFilters}) => ({
  alignItems: "center",
  display: "flex",
  fontSize: "12px",
  fontWeight: 600,
  overflow: "hidden",
  whiteSpace: "nowrap",
  ...(staleFilters && sortBy === column.sortBy
    ? {color: "rgba(0, 0, 0, 0.3)"}
    : {}),
});

export default props => {
  const {
    columns,
    onSort,
    sortBy,
    sortDirection,
    sortIsHandledExternally,
  } = props;

  return (
    <Grid
      alignItems="center"
      container
      justify="space-around"
      spacing={0}
      style={{
        borderBottom: "1px solid rgb(224, 224, 224)",
        color: "rgba(0, 0, 0, 0.54)",
        flexBasis: "64px",
        flexShrink: "0",
        fontSize: "12px",
        height: "64px",
        padding: "0px 20px",
      }}
    >
      {columns.map((column, index) => {
        let contents = (
          <div style={{textOverflow: "ellipsis"}}>
            {column.label.constructor === Function
              ? column.label()
              : column.label}
          </div>
        );

        if (column.sortBy) {
          contents = (
            <TableSortLabel
              active={sortBy === column.sortBy}
              direction={sortDirection === "desc" ? "asc" : "desc"}
              onClick={() => {
                let newSortDirection;

                if (sortBy !== column.sortBy) {
                  newSortDirection = "asc";
                } else {
                  newSortDirection = sortDirection === "desc" ? "asc" : "desc";
                }

                if (!sortIsHandledExternally && column.sortBy) {
                  onSort(column.sortBy, newSortDirection);
                }

                return (
                  column.onHeaderClick &&
                  column.onHeaderClick(column.sortBy, newSortDirection)
                );
              }}
              style={{...getHeaderStyle(props)}}
            >
              {contents}
            </TableSortLabel>
          );
        }

        return (
          <Grid
            item
            key={index}
            xs={column.xs || Math.floor(12 / columns.length)}
            style={{
              fontWeight: 300,
              overflow: "hidden",
              textOverflow: "ellipsis",
              alignItems: "baseline",
              ...(column.labelStyle || {}),
            }}
          >
            {contents}
          </Grid>
        );
      })}
    </Grid>
  );
};
