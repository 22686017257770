import process from "./process";

export default ({state, action}) => {
  const wellId = state.byDownholeLocation[action.payload.downholeLocation];

  const well = state.byWellId[wellId] || {
    downholeLocation: action.payload.downholeLocation,
  };

  const byWellId = {
    ...state.byWellId,
    [wellId]: {
      ...well,
      eventsByType: {
        ...(well.eventsByType || {}),
        isFetching: true,
      },
    },
  };

  return process({
    ...state,
    byWellId,
  });
};
