import {connect} from "react-redux";
import Drawer from "@material-ui/core/Drawer";
import React from "react";

import AmbyintLogo from "components/logo/full";
import colors from "theme/colors";
import {toggle} from "actions/app-drawer";

import Divider from "./divider";
import Menu from "./menu";
import Profile from "./profile";

const minWidth = "320px";

const container = {
  backgroundColor: colors.ambyBlue[500],
  height: "100%",
  minWidth,
};

const footer = {
  container: {
    position: "fixed",
    bottom: 16,
    left: 0,
    width: "100%",
    minWidth,
  },
  inner: {
    display: "block",
    margin: "0 auto",
  },
};

const mapStateToProps = state => {
  return {
    isOpen: state.appDrawer.isOpen,
    user: state.auth.user,
  };
};

const AppDrawer = props => {
  const {user, isOpen, dispatch} = props;

  const toggleDrawer = () => {
    dispatch(
      toggle({
        isOpen: !props.isOpen,
      }),
    );
  };

  return (
    <div>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        onClick={toggleDrawer}
        id="app-drawer"
      >
        <div style={container}>
          <Profile user={user} />
          <Divider />
          <Menu />
          <Divider />

          <div style={footer.container}>
            <AmbyintLogo width={220} style={footer.inner} />
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default connect(mapStateToProps)(AppDrawer);
