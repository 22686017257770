import React from "react";
import {connect} from "react-redux";
import ChartArcIcon from "mdi-material-ui/ChartArc";
import ChartBubbleIcon from "mdi-material-ui/ChartBubble";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import PharmacyIcon from "@material-ui/icons/LocalPharmacy";
import AndroidStudioIcon from "mdi-material-ui/AndroidStudio";

import {ClickEvent} from "components/analytics";
import {eventAction, eventCategory} from "constants/analytics";
import {setWorkflow} from "actions/wells";
import {workflows} from "constants/well-workflows";
import Tooltip from "components/material-tooltip";

const mapStateToProps = state => ({
  workflow: state.wells.workflow.selected,
});

const mapDispatchToProps = dispatch => ({
  handleChangeWorkflow: workflow => workflow && dispatch(setWorkflow(workflow)),
});

const MainWorkflow = ({handleChangeWorkflow, workflow, classes}) => {
  return (
    <ToggleButtonGroup
      className={classes.buttonGroup}
      value={workflow}
      size="small"
      exclusive
      onChange={(e, workflow) => handleChangeWorkflow(workflow)}
    >
      <ToggleButton value={workflows.DISPLAY_ALL}>
        <ClickEvent
          category={eventCategory.wellsDashboard}
          action={eventAction.changeWorkflow}
          label="Display All"
        >
          <Tooltip title="Display all wells">
            <ChartArcIcon />
          </Tooltip>
        </ClickEvent>
      </ToggleButton>
      <ToggleButton value={workflows.TRIAGE}>
        <ClickEvent
          category={eventCategory.wellsDashboard}
          action={eventAction.changeWorkflow}
          label="Triage"
        >
          <Tooltip title="Triage wells">
            <PharmacyIcon />
          </Tooltip>
        </ClickEvent>
      </ToggleButton>
      <ToggleButton value={workflows.CALIBRATE}>
        <ClickEvent
          category={eventCategory.wellsDashboard}
          action={eventAction.changeWorkflow}
          label="Calibrate"
        >
          <Tooltip title="Calibrate wells">
            <AndroidStudioIcon />
          </Tooltip>
        </ClickEvent>
      </ToggleButton>
      <ToggleButton value={workflows.ANOMALY}>
        <ClickEvent
          category={eventCategory.wellsDashboard}
          action={eventAction.changeWorkflow}
          label="Anomaly"
        >
          <Tooltip title="View anomalies">
            <ChartBubbleIcon />
          </Tooltip>
        </ClickEvent>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainWorkflow);
