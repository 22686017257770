import {useState, useEffect, useMemo} from "react";

import {eventAction, eventCategory} from "constants/analytics";
import {sendAnalyticsEvent} from "components/analytics";
import {getCardOverride} from "utils/well/get-card-override";

export const useCardOverrideState = ({
  initCardType,
  fetchCardOverride,
  well,
  currentCardId,
  setCardOverride,
  hasFullAccess,
}) => {
  const [overrideReady, setOverrideReady] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [override, setOverride] = useState({
    classificationOverride: initCardType,
  });
  const setOverrideByType = classificationOverride =>
    setOverride({classificationOverride});

  const cardOverride = useMemo(() => getCardOverride(well, currentCardId), [
    well,
    currentCardId,
  ]);
  const {classificationOverride, cardId} = cardOverride;
  const overrideFetched = !!cardId;

  useEffect(
    () => {
      const shouldFetchCardOverride = !overrideFetched && currentCardId;
      if (shouldFetchCardOverride) {
        fetchCardOverride(well.downholeLocation, currentCardId);
      }
    },
    [well.downholeLocation, currentCardId, fetchCardOverride, overrideFetched],
  );

  useEffect(
    () => {
      const overrideExists = overrideFetched && !!classificationOverride;
      if (overrideExists) {
        setOverride(cardOverride);
        setHasSubmitted(true);
      } else {
        setOverrideReady(hasFullAccess && overrideFetched);
      }
    },
    [
      overrideFetched,
      classificationOverride,
      cardOverride,
      setOverrideReady,
      hasFullAccess,
    ],
  );

  const setDialogOpenWithAnalytics = open => {
    setDialogOpen(open);
    sendAnalyticsEvent(
      eventCategory.classificationOverride,
      open ? eventAction.openDialog : eventAction.closeDialog,
    );
  };

  const closeDialog = e => {
    e.stopPropagation();
    setDialogOpenWithAnalytics(false);
  };

  const onCancel = e => {
    setOverrideByType(initCardType);
    sendAnalyticsEvent(
      eventCategory.classificationOverride,
      eventAction.cancel,
    );
    closeDialog(e);
  };

  const toggleDialog = e => {
    e.stopPropagation();
    setDialogOpenWithAnalytics(!isDialogOpen);
  };

  const onSubmit = e => {
    e.stopPropagation();
    setHasSubmitted(true);
    closeDialog(e);
    setCardOverride(
      well.downholeLocation,
      currentCardId,
      override.classificationOverride,
    );
    sendAnalyticsEvent(
      eventCategory.classificationOverride,
      eventAction.submit,
      currentCardId,
    );
  };

  const onOverrideSelected = newOverrideType => () => {
    const sameAsCurrent =
      !!newOverrideType &&
      newOverrideType.toLowerCase() ===
        override.classificationOverride.toLowerCase();
    setOverrideByType(sameAsCurrent ? initCardType : newOverrideType);
  };

  return {
    toggleDialog,
    onSubmit,
    onCancel,
    override,
    isDialogOpen,
    hasSubmitted,
    onOverrideSelected,
    overrideReady,
  };
};
