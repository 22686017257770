import isEmpty from "lodash/isEmpty";
import process from "./process";

const cleanCriteria = criteria =>
  isEmpty((criteria || "").trim()) ? "" : criteria;

export const setSearchCriteria = ({state, action}) => {
  return process({
    ...state,
    search: {
      criteria: cleanCriteria(action.payload.criteria),
      wellList: state.search.wellList,
    },
  });
};
