import React from "react";
import pure from "recompose/pure";

import colors from "theme/colors";

const Bars = props => {
  const {margins, data, xScale, yScale} = props;

  const bars = data.map((d, i) => {
    return (
      <rect
        key={d.label + i.toString()}
        x={margins.left}
        y={yScale(d.label)}
        height={yScale.bandwidth()}
        width={xScale(d.value) - margins.left}
        style={{
          fill: colors.assortment[i % colors.assortment.length][500],
        }}
      />
    );
  });

  return <g>{bars}</g>;
};

export default pure(Bars);
