import merge from "./merge-well";
import process from "./process";

export default ({state, action}) => {
  const wellId = action.payload.wellId;

  const existing = state.byWellId[wellId];

  const updated = merge(existing || {}, action.payload.data);

  const byWellId = {
    ...state.byWellId,
    [wellId]: updated,
  };

  return process({
    ...state,
    byWellId,
  });
};
