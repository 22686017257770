import {push} from "react-router-redux";

import {createSwitchEpic} from "epics/create-epic";
import {show} from "actions/snackbar";
import {types} from "actions/wells";
import fetch from "epics/async-fetch";

export default createSwitchEpic([types.create], async ({payload}) => {
  try {
    await fetch(
      `/optimization/well/`,
      {},
      {
        body: payload.data,
        method: "PUT",
      },
    );

    return [
      show({
        message: "Request to create well has been successfully submitted!",
      }),
      payload.onSuccess,
      push("/profile/organizations"),
    ];
  } catch (error) {
    console.error(error);

    return [
      show({
        duration: 5000,
        message:
          "Something went wrong when requesting to create a well. Please try again and contact us if the error persists.",
      }),
    ];
  }
});
