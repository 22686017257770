import colors from "theme/colors";
import square from "utils/shapes/square";

const size = 10;

function mapData(d) {
  return (d.miniCards || []).filter(card => card.shutdown);
}

function mapTime(d) {
  return new Date(d.createdOn);
}

export default ({selection, register, scales}) => {
  const group = selection.append("g");

  return register({
    draw({config, data}) {
      const visibility =
        config.shutdownCards && config.shutdownCards.state === 0
          ? "hidden"
          : "visible";

      const polygons = group.selectAll("polygon").data(mapData(data));
      polygons.exit().remove();
      polygons.merge(polygons).style("visibility", visibility);

      polygons
        .enter()
        .append("polygon")
        .style("visibility", visibility)
        .style("stroke", colors.red[400])
        .style("fill", colors.transparent)
        .style("stroke-width", 3)
        .attr("points", d =>
          square(scales.time(mapTime(d)) - size / 2, scales.status(4), size),
        );
    },

    zoom({transform}) {
      const scale = transform.rescaleX(scales.time);

      group
        .selectAll("polygon")
        .style("stroke", colors.red[400])
        .attr("points", d =>
          square(scale(mapTime(d)) - size / 2, scales.status(4), size),
        );
    },
  });
};
