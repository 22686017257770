import withStyles from "@material-ui/core/styles/withStyles";
import Slider from "@material-ui/core/Slider";

export default withStyles(({colors}) => ({
  root: {
    color: colors.warmGrey[400],
    height: 8,
  },
  thumb: {
    height: 18,
    width: 24,
    border: `1px solid ${colors.warmGrey[400]}`,
    color: colors.warmGrey[400],
    background: "linear-gradient(to bottom,#fdfdfd,#f0f2f2)",
    borderRadius: 3,
    marginTop: -2,
    "&:focus,&:hover,&$active": {
      boxShadow: "inherit",
    },
    "&:before": {
      content: '""',
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
    "&:after": {
      content: '""',
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
      top: "unset",
      bottom: "unset",
      right: "unset",
      left: "unset",
      position: "relative",
    },
  },
  // this empty style is just to override the default.
  active: {},
  valueLabel: {
    left: "calc(-50% + 5px)",
    top: -20,
    "& *": {
      background: "transparent",
      color: colors.warmGrey[500],
    },
  },
  track: {
    height: 14,
    borderRadius: 5,
    borderColor: "#8B8B8B #B7B7B7 #CCC",
  },
  rail: {
    height: 14,
    borderRadius: 5,
    borderColor: "#8B8B8B #B7B7B7 #CCC",
    color: colors.warmGrey[400],
  },
}))(Slider);
