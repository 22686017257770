export default ({state, action}) => {
  const exists = state.all.some(a => a.alertId === action.payload.alertId);

  const mapUpdatedAlert = a => {
    if (a.alertId !== action.payload.alertId) return a;

    return {
      ...action.payload,
      timeline: a.timeline,
    };
  };

  const shouldUpdateFiltered =
    exists &&
    state.filtered.findIndex(a => a.alertId === action.payload.alertId) >= 0;

  const updateFiltered = () => ({
    filtered: state.filtered.map(mapUpdatedAlert),
  });

  const all = exists
    ? state.all.map(mapUpdatedAlert)
    : [...state.all, action.payload];

  return {
    ...state,
    all,
    ...(shouldUpdateFiltered ? updateFiltered() : {}),
    staleFilters: true,
  };
};
