import React from "react";
import compose from "lodash/fp/compose";
import {connect} from "react-redux";
import isNil from "lodash/isNil";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = ({typography, spacing, colors}) => ({
  label: {
    ...typography.caption,
    marginTop: spacing(1) / 2,
    color: colors.warmGrey[500],
  },

  noData: {
    ...typography.h5,
    fontSize: "1.1rem",
    alignSelf: "flex-end",
  },

  value: {
    ...typography.h4,
    lineHeight: "initial",
  },

  valueUnit: {
    ...typography.body2,
    color: colors.warmGrey[500],
    margin: "0 2px",
  },

  kpiValue: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    minHeight: 40,
  },
});

const mapStateToProps = ({wellHealth}) => ({
  focus: wellHealth.compressiveStress.focus,
});

const enhance = compose(
  connect(mapStateToProps),
  withStyles(styles),
);

const KPIPanelCompression = ({medianSeries, unitLabel, focus, classes}) => {
  const index = !isNil(focus) ? focus : medianSeries.length - 1;
  const medianForDay = medianSeries[index].y;

  return (
    <div className={classes.kpiValue}>
      {isNil(medianForDay) ? (
        <span className={classes.noData}>No Data</span>
      ) : (
        <>
          <span className={classes.value}>{medianForDay}</span>
          <span className={classes.valueUnit}>{unitLabel}</span>
        </>
      )}
    </div>
  );
};

export default enhance(KPIPanelCompression);
