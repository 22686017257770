import all from "utils/validation/all";
import required from "utils/validation/required";
import valueOf from "utils/value-of";
import {parseWellboreSurveyFromString} from "@ambyint/common/parsers/well/parse-wellbore-survey";

export default values => {
  const requiredFields = [
    "casingDescription",
    "casingPerforationBottomMeasuredDepth",
    "casingPerforationTopMeasuredDepth",
    "trueVerticalDepth",
    "wellDepth",
    "wellboreSurvey",
  ];

  const errors = all(required, values, requiredFields);

  if (required(values.casing)) {
    errors.casingDescription = required(values.casing);
  }

  if (values.wellboreSurvey) {
    const surveyLines = parseWellboreSurveyFromString(values.wellboreSurvey);
    const lastSurveyLine = surveyLines[surveyLines.length - 1];
    const measuredDepth = lastSurveyLine.measuredDepth;

    if (
      measuredDepth &&
      valueOf(values.wellDepth) &&
      valueOf(measuredDepth).toString() !== valueOf(values.wellDepth).toString()
    ) {
      errors.wellboreSurvey =
        "The well depth must equal the last measured depth in the wellbore survey";
    }
  }

  return errors;
};
