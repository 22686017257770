import {actions} from "react-redux-form";
import store from "store";

import formPath from "./form-path";

export default goToPreviousStep => {
  store.dispatch(actions.setInitial(formPath));

  goToPreviousStep();
};
