import React from "react";

import getDisplayName from "utils/user/get-display-name";

import FeedItem from "../feed-item";
import {lineStyle} from "../styles";

const renderComment = event => {
  return (
    event.value.acceptedComment && (
      <span style={lineStyle}>
        {"With the comment: "}
        {event.value.acceptedComment}
      </span>
    )
  );
};

export default props => {
  const {event} = props;

  return (
    <FeedItem event={event}>
      <span style={lineStyle}>
        {getDisplayName(event)}
        {event.user.email === "support@ambyint.com" ? " automatically" : ""}
        {" accepted the recommendation:"}
      </span>
      <span style={lineStyle}>{event.value.description}</span>
      {renderComment(event)}
    </FeedItem>
  );
};
