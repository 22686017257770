/***
 * Willy nilly locations are found in countries without any standardization, however we
 * would still like to ensure that they only contain characters that we expect.
 * @param {string} string
 * @returns {(string|undefined)}
 */

export default value => {
  if (!value) return undefined;

  return !/^[\w\-. /]+$/.test(value)
    ? "Can only contain alpha numeric - _ . / and space characters"
    : undefined;
};
