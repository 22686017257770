import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";

import React from "react";
import {formValueSelector, reduxForm} from "redux-form";
import {connect} from "react-redux";

import {setRunTimer} from "actions/wells";

import DecimalTextField from "components/forms/decimal-text-field";
import FormHeader from "components/form-header";
import Switch from "components/forms/switch";
import PaperContainer from "components/paper-container";
import SaveCancelButtons from "components/save-cancel-buttons";

import compose from "utils/compose";
import forms from "utils/forms";
import mapMinutesToHours from "utils/map-minutes-to-hours";

import validate from "./validate";

const FORM_NAME = "run-timer-configuration-form";
const selector = formValueSelector(FORM_NAME);

const endAdornment = <InputAdornment position="end">minutes</InputAdornment>;

const mapStateToProps = (state, props) => {
  const {well} = props;

  const minStoptime = well.safeOperatingParameters.minStoptime;
  const minStoptimeMinutes = minStoptime.hours * 60 + minStoptime.minutes;
  const maxStoptime = well.safeOperatingParameters.maxStoptime;
  const maxStoptimeMinutes = maxStoptime.hours * 60 + maxStoptime.minutes;

  return {
    initialValues: {
      enabled: well.runTimer.enabled,
      runTime: well.runTimer.runTime,
      stopTime: well.runTimer.stopTime,
      minStoptime: minStoptimeMinutes,
      maxStoptime: maxStoptimeMinutes,
    },
    currentValues: {
      enabled: selector(state, "enabled"),
      runTime: selector(state, "runTime"),
      stopTime: selector(state, "stopTime"),
      minStoptime: minStoptimeMinutes,
      maxStoptime: maxStoptimeMinutes,
    },
  };
};

let Component = props => {
  const {
    currentValues,
    dirty,
    dispatch,
    handleSubmit,
    invalid,
    onClose,
    pristine,
    reset,
    submitting,
    well,
  } = props;

  const {enabled, runTime, stopTime} = currentValues;

  const save = values => {
    dispatch(
      setRunTimer({
        data: values,
        well,
      }),
    );

    onClose();
  };

  return (
    <PaperContainer extraPadded style={{maxWidth: "500px"}}>
      <form onSubmit={handleSubmit(save)}>
        <Grid container direction="row" style={{maxWidth: 776}}>
          <Grid item xs={12}>
            <FormHeader>Run Timer Settings</FormHeader>
          </Grid>
          <Grid container direction={"row"}>
            <Grid item xs={12} sm={6} lg>
              <Switch name="enabled" label="Enabled" />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <DecimalTextField
              component={forms.renderMaskedTextField}
              disabled={!enabled}
              endAdornment={endAdornment}
              helperText={mapMinutesToHours(runTime)}
              label="Run Time"
              name="runTime"
              showError={dirty}
              style={{marginRight: 24}}
            />
            <DecimalTextField
              component={forms.renderMaskedTextField}
              disabled={!enabled}
              endAdornment={endAdornment}
              helperText={mapMinutesToHours(stopTime)}
              label="Stop Time"
              name="stopTime"
              showError={dirty}
            />
          </Grid>
        </Grid>

        <SaveCancelButtons
          invalid={invalid}
          pristine={pristine}
          reset={compose(
            onClose,
            reset,
          )}
          submitting={submitting}
        />
      </form>
    </PaperContainer>
  );
};

Component = reduxForm({
  enableReinitialize: true,
  form: FORM_NAME,
  validate,
})(Component);

Component = connect(mapStateToProps)(Component);

export default Component;
