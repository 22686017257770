import {getUser} from "utils/user/get-user";
import {types} from "actions/organizations";

import {createSwitchEpic} from "./create-epic";

export default createSwitchEpic([types.setOrganization], ({payload}) => {
  const user = getUser();
  const organization = payload.data;

  window.aptrinsic(
    "identify",
    {
      id: user.clientUserId,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
    },
    {
      id: organization.organizationId,
      name: organization.organizationName,
      city: organization.city,
      countryName: organization.country,
      stateCode: organization.province,
      postalCode: organization.postalCode,
      street: organization.addressLine1,
    },
  );

  return [];
});
