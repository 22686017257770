import {compose, withStateHandlers} from "recompose";
import {connect} from "react-redux";
import {Marker} from "react-google-maps";
import {push} from "react-router-redux";
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
import get from "lodash/get";
import React from "react";
import noop from "lodash/noop";

import {markerIcons} from "./marker-icons";

const generateIcon = (mapType, status) => {
  let state = get(status, "state.id", "unknown");
  let markerImg = markerIcons[mapType][state];

  return {
    anchor: new window.google.maps.Point(0, 20),
    url: markerImg,
  };
};

const WellMarker = compose(
  withStateHandlers(
    () => ({
      isOpen: false,
    }),
    {
      onMouse: ({isOpen}) => () => ({
        isOpen: !isOpen,
      }),
    },
  ),
  connect(),
)(props => {
  const {
    dispatch,
    displayName,
    downholeLocation,
    isOpen,
    location,
    mapType,
    onMouse,
    status,
    compact,
    wellId,
  } = props;

  if (!location) return null;

  const handleClick = () => {
    dispatch(push(`/well/${encodeURIComponent(downholeLocation)}/analysis`));
  };

  return (
    <Marker
      key={wellId}
      position={{...location}}
      icon={generateIcon(mapType, status)}
      onClick={compact ? noop : handleClick}
      onMouseOver={onMouse}
      onMouseOut={onMouse}
      cursor={compact ? "default" : "pointer"}
    >
      {isOpen && !compact && (
        <InfoBox
          options={{
            closeBoxURL: ``,
            pixelOffset: new window.google.maps.Size(25, -20),
          }}
          defaultZIndex={-1}
        >
          <div
            style={{
              background: "#fff",
              border: "1px solid rgba(0, 0, 0, 0.3)",
              padding: "5px",
              whiteSpace: "nowrap",
            }}
          >
            {displayName}
          </div>
        </InfoBox>
      )}
    </Marker>
  );
});

export default WellMarker;
