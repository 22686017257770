export const setMaxZoom = (map, maxZoom, cb) => {
  if (map) {
    const MaxZoomService = new window.google.maps.MaxZoomService();
    const LatLng = map.getCenter();
    const zoom = map.getZoom();

    MaxZoomService.getMaxZoomAtLatLng(LatLng, ({zoom: newMax, status}) => {
      const zoomPermitted = maxZoom < newMax && maxZoom === zoom;
      const zoomProhibited = maxZoom > newMax && zoom >= newMax;

      if (status === "OK" && (zoomPermitted || zoomProhibited)) {
        cb(newMax);
      }
    });
  }
};
