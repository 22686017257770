import React from "react";
import compose from "lodash/fp/compose";
import {connect} from "react-redux";
import isNil from "lodash/isNil";
import withStyles from "@material-ui/core/styles/withStyles";

import {
  CCR_COLORS,
  CLASSIFICATION_LABELS,
  CLASSIFICATIONS,
} from "constants/well-health";
import round from "utils/round";

const styles = ({typography, colors, spacing}) => ({
  totalCards: {
    textAlign: "center",
    paddingBottom: spacing(1),
  },

  container: {
    display: "grid",
    gridTemplateColumns: "20px auto 1fr",
    gridTemplateRows: "18px auto",
    gridTemplateAreas: '"swatch value unit" "swatch label label"',
    alignItems: "end",
    justifyItems: "left",
    padding: `${spacing(1) / 2}px 0`,
  },

  swatch: {
    width: 10,
    height: 28,
    filter: "drop-shadow( 2px 2px 2px rgba(0, 0, 0, .1))",
    gridArea: "swatch",
    alignSelf: "center",
  },

  value: {
    ...typography.subtitle1,
    lineHeight: "1rem",
    gridArea: "value",
  },

  valueUnit: {
    ...typography.subtitle1,
    gridArea: "unit",
    fontSize: "0.7em",
  },

  label: {
    ...typography.subtitle1,
    fontSize: 10,
    lineHeight: "1rem",
    color: colors.warmGrey[500],
    gridArea: "label",
  },

  totalValue: {
    ...typography.h5,
    minHeight: 32,
  },

  noData: {
    ...typography.subtitle1,
    minHeight: 32,
  },
});

const mapStateToProps = ({wellHealth}) => ({
  ...wellHealth.classifications,
});

const enhance = compose(
  withStyles(styles),
  connect(mapStateToProps),
);

const KPIPanelCCR = ({classes, series, counts, focus}) => {
  const index = !isNil(focus) ? focus : counts.length - 1;
  const dataForWeek = counts[index];

  return (
    <div>
      <div className={classes.totalCards}>
        {isNil(dataForWeek.total) ? (
          <div className={classes.noData}>No Data</div>
        ) : (
          <div className={classes.totalValue}>{dataForWeek.total}</div>
        )}
        <div className={classes.label}>Total Cards</div>
      </div>
      {CLASSIFICATIONS.slice()
        .reverse()
        .map(type => {
          return (
            <div key={type} className={classes.container}>
              <svg
                className={classes.swatch}
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0"
                  width="10"
                  height="28"
                  rx="3"
                  fill={CCR_COLORS[type]}
                />
              </svg>
              <div className={classes.label}>{CLASSIFICATION_LABELS[type]}</div>
              {isNil(dataForWeek[type].percent) ? (
                <div className={classes.value}> -</div>
              ) : (
                <>
                  <div className={classes.value}>
                    {round(dataForWeek[type].percent * 100, 1)}
                  </div>
                  <div className={classes.valueUnit}>%</div>
                </>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default enhance(KPIPanelCCR);
