import React, {Component} from "react";
import {connect} from "react-redux";
import {compose} from "recompose";
import {GoogleMap, withGoogleMap, withScriptjs} from "react-google-maps";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import debounce from "lodash/debounce";

import {setMapBounds} from "actions/wells/set-map-bounds";

import {mapOptions} from "./map-options";
import MapMarker from "./well-marker";
import {setMaxZoom} from "./set-max-zoom";
import {calculateMapCoords} from "./calculate-map-coords";
import {zoomToMarkers} from "./zoom-to-markers";

class InitialMap extends Component {
  constructor(props) {
    super(props);
    this.map = React.createRef();

    this.state = {
      maxZoom: 16,
    };
  }

  componentDidMount() {
    const {overrides = {}} = this.props;
    if (!overrides.withoutMarkers) {
      zoomToMarkers(this.map.current);
    }
    if (overrides.mapBounds) {
      this.map.current.fitBounds(overrides.mapBounds, 0);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const overrides = get(this, ["props", "overrides"], {});
    const nextOverrides = get(nextProps, ["overrides"], {});
    const {mapType, wells} = this.props;
    const {maxZoom} = this.state;
    const byStatusId = (well, i) => {
      return (
        get(nextProps.wells[i], "status.state.id") !==
        get(well, "status.state.id")
      );
    };

    return (
      nextProps.mapType !== mapType ||
      !isEqual(nextOverrides.mapBounds, overrides.mapBounds) ||
      nextState.maxZoom !== maxZoom ||
      nextProps.wells.length !== wells.length ||
      wells.some(byStatusId) ||
      !isEqual(nextProps.wells, wells)
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const {overrides = {}} = this.props;
    if (prevProps.mapType === this.props.mapType) {
      if (prevState.maxZoom === this.state.maxZoom) {
        if (!overrides.withoutMarkers) {
          zoomToMarkers(this.map.current);
        }
      }
    }
    if (overrides.mapBounds) {
      this.map.current.fitBounds(overrides.mapBounds, 0);
    }
  }

  setMapBounds = debounce(this.props.setMapBounds, 400);

  onBoundsChanged = () => {
    if (this.props.compact) return;

    const cb = maxZoom => this.setState({maxZoom});
    setMaxZoom(this.map.current, this.state.maxZoom, cb);

    const mapBounds = this.map.current.getBounds();
    this.setMapBounds({mapBounds});
  };

  render() {
    const {wells, mapType, overrides = {}, compact} = this.props;
    const {maxZoom} = this.state;

    return (
      <GoogleMap
        defaultZoom={6}
        defaultCenter={calculateMapCoords(wells)}
        options={{...mapOptions, maxZoom, ...overrides}}
        mapTypeId={overrides.mapType || mapType}
        onBoundsChanged={this.onBoundsChanged}
        ref={this.map}
      >
        {!overrides.withoutMarkers &&
          wells.map(well => (
            <MapMarker
              mapType={overrides.mapType || mapType}
              compact={compact}
              key={well.wellId}
              {...well}
            />
          ))}
      </GoogleMap>
    );
  }
}

const mapStateToProps = null;
const mapDispatchToProps = {setMapBounds};

const Map = compose(
  withScriptjs,
  withGoogleMap,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(InitialMap);

export default Map;
