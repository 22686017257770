import {types} from "./types";

export const addNotificationType = ({user, type}) => {
  return {
    type: types.addNotificationType,
    payload: {
      user,
      type,
    },
  };
};
