import React from "react";

import logo from "images/icon-white.svg";

export default props => {
  const {width = 50, height = 50, style = {}} = props;

  return (
    <img src={logo} alt="ambyint" width={width} height={height} style={style} />
  );
};
