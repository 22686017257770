import isEmpty from "lodash/isEmpty";

import React from "react";

import {Header} from "./header";
import {Section} from "./section";

export const Anomalies = props => {
  const {
    badLoad,
    highLoad,
    highLoadV2,
    intrastroke,
    lowLoadDL,
    lowLoadML,
    surfaceCcr,
  } = props.classifications;

  const showHighLoad = !isEmpty(highLoad);
  const showHighLoadV2 = !isEmpty(highLoadV2);
  const showBadLoad = !isEmpty(badLoad);
  const showSurfaceCcr = !isEmpty(surfaceCcr);
  const showIntrastroke = !isEmpty(intrastroke);
  const showLowLoadDL = !isEmpty(lowLoadDL);
  const showLowLoadML = !isEmpty(lowLoadML);

  if (!showHighLoad && !showIntrastroke && !showLowLoadDL && !showLowLoadML) {
    return null;
  }

  return (
    <>
      <Header label={"Anomalies"} />
      <Section
        classifications={[...(showSurfaceCcr ? surfaceCcr : [])]}
        label={"Surface CCR"}
        xs={6}
      />
      <Section
        classifications={[...(showBadLoad ? badLoad : [])]}
        label={"Bad Load Cell"}
        xs={6}
      />
      <Section
        classifications={[
          ...(showLowLoadML ? lowLoadML : []),
          ...(showLowLoadDL ? lowLoadDL : []),
        ]}
        label={"Low Load"}
        xs={6}
      />
      <Section
        classifications={[...(showHighLoad ? highLoad : [])]}
        label="High Load"
        xs={6}
      />
      <Section
        classifications={[...(showHighLoadV2 ? highLoadV2 : [])]}
        label="High Load V2"
        xs={6}
      />
      {showIntrastroke && (
        <Section
          classifications={intrastroke}
          label={"Intrastroke Load"}
          xs={6}
        />
      )}
    </>
  );
};
