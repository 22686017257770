import reduce from "lodash/fp/reduce";
import round from "lodash/round";
import map from "lodash/map";
import connect from "react-redux/es/connect/connect";

import {setClassification} from "actions/field-optimization";

import countClassification from "./count-classification";
import ClassificationFilter from "./classification-filter";
import {fieldOptimizationWellsSelector} from "../../field-optimization-wells-selector";

// Very targeted solution to get these values to always equal 100%
// https://revs.runtime-revolution.com/getting-100-with-rounded-percentages-273ffa70252b
// https://en.wikipedia.org/wiki/Largest_remainder_method
const roundTo100 = values => {
  const roundedValues = reduce.convert({cap: false})(
    (t, v, l) => ({
      ...t,
      [l]: {...v, percentage: Math.floor(v.percentage)},
    }),
    {},
    values,
  );
  const diff =
    100 -
    reduce.convert({cap: false})((t, v) => t + v.percentage, 0, roundedValues);
  const sortedByDecimal = map(values, v => v).sort(
    (a, b) =>
      b.percentage -
      Math.floor(b.percentage) -
      (a.percentage - Math.floor(a.percentage)),
  );
  const distributedDifference = sortedByDecimal.reduce(
    (t, v, i) => ({
      ...t,
      [v.label]: {
        ...v,
        percentage:
          i < diff ? Math.floor(v.percentage) + 1 : Math.floor(v.percentage),
      },
    }),
    {},
  );

  return distributedDifference;
};

const breakdown = wells => {
  const total = wells.length;

  const breakdown = reduce.convert({cap: false})(
    (acc, count, label) => ({
      ...acc,
      [label]: {
        count,
        label,
        total,
        percentage: round((count * 100) / total, 1),
      },
    }),
    {},
    countClassification(wells),
  );

  return roundTo100(breakdown);
};

const mapStateToProps = state => {
  const {
    fieldOptimization: {classification},
  } = state;

  return {
    classification,
    data: breakdown(fieldOptimizationWellsSelector(state, true)),
  };
};

const mapDispatchToProps = dispatch => ({
  setClassification: classification => () =>
    dispatch(setClassification(classification)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClassificationFilter);
