export const ALERT_ACKNOWLEDGED = "alertAcknowledged";
export const ALERT_CREATED = "alertCreated";
export const ALERT_RESOLVED = "alertResolved";
export const ALLOWED_TO_OPERATE = "allowedToOperate";
export const AUTO_ACCEPTED_RECOMMENDATIONS = "autoAcceptedRecommendations";
export const CARDS = "cards";
export const CLEAR_ERRORS = "clearErrors";
export const DEVICE_ASSIGNED = "deviceAssigned";
export const DEVICE_REMOVED = "deviceRemoved";
export const DOWNHOLE_CARD_CLASSIFICATIONS = "downholeCardClassifications";
export const DOWNHOLE_INFORMATION = "downholeInformation";
export const DRIVE_CONNECTION_STATUS = "driveConnectionStatus";
export const DRIVE_DATA = "driveData";
export const DRIVE_FAULT = "driveFault";
export const FAULT_RECOVERY = "faultRecovery";
export const GENERATED_CARDS = "generatedCards";
export const GRACE_PERIOD_SET = "gracePeriodSet";
export const GRACE_PERIOD_UNSET = "gracePeriodUnset";
export const MANUAL_FAULT_RESET = "manualFaultReset";
export const MINI_CARDS = "miniCards";
export const MOTOR_DETAILS = "motorDetails";
export const NEW_RECOMMENDATION = "newRecommendation";
export const POWER_STATUS = "powerStatus";
export const PUMPING_UNIT = "pumpingUnit";
export const RAW_CARDS = "rawCards";
export const RECOMMENDATION_ACCEPTED = "recommendationAccepted";
export const RECOMMENDATION_DISMISSED = "recommendationDismissed";
export const RESERVOIR = "reservoir";
export const RESISTANCE = "resistance";
export const RUNNING_STATUS = "runningStatus";
export const SAFE_OPERATING_PARAMETERS = "safeOperatingParameters";
export const SCHEDULED_DOWNTIME = "scheduledDowntime";
export const SET_BASIC_WELL_DETAILS = "setBasicWellDetails";
export const SET_FAULT_RECOVERY = "setFaultRecovery";
export const SPEED_PROFILE = "speedProfile";
export const SPEED_RANGE = "speedRange";
export const STATUS_CODES = "statusCodes";
export const SURFACE_CARD_REQUESTED = "surfaceCardRequested";
export const SURFACE_CARD_REQUEST_FULFILLED = "surfaceCardRequestFulfilled";
export const TOTAL_REDUCTION_RATIO = "totalReductionRatio";
export const WELL_ANALOG_INPUT_CHANGED = "wellAnalogInputChanged";
export const WELL_CURRENT_VIOLATION = "wellCurrentViolation";
export const WELL_PUMP_OFF_SETTING_CHANGED = "wellPumpOffSettingChanged";
export const WELL_RESET_MALFUNCTIONS = "wellResetMalfunctions";
export const WELL_RUN_TIMER = "wellRunTimer";
export const WELL_TORQUE_VIOLATION = "wellTorqueViolation";
export const WELLBORE = "wellbore";
