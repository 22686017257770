import {types} from "actions/auth";

export default action$ => {
  return action$.ofType(types.signUpWithAzure).switchMap(({payload}) => {
    window.location.href = `${process.env.REACT_APP_API}/signup/azure/${
      payload.email
    }`;

    return [];
  });
};
