import {types} from "./types";

/**
 * Creates a setMappings action.
 * @param {string} type - The type of import
 * @param {[string]} mappings - The mappings, duh
 * @returns {{type: string, payload: {mappings: *, type: *}}}
 */
export const setMappings = ({type, mappings}) => {
  return {
    type: types.SET_MAPPINGS,
    payload: {
      mappings,
      type,
    },
  };
};
