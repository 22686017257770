import React from "react";
import colors from "theme/colors";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import InfoIcon from "@material-ui/icons/Info";

const defaultStyles = {
  button: {
    marginLeft: "16px",
    marginRight: "16px",
  },
  icon: {
    height: "64px",
    width: "64px",
  },
  text: {
    color: "rgba(0, 0, 0, 0.54)",
  },
  link: {
    color: colors.ambyOrange[500],
  },
};

const EmptyState = props => {
  const {actions = [], icon, mainText, subText, styles = {}} = props;

  const Icon = icon || InfoIcon;
  return (
    <Grid
      container
      direction={"column"}
      justify={"center"}
      alignItems={"center"}
      style={{
        marginTop: "30px",
      }}
    >
      <Grid item>
        <Typography variant="h4">
          <Icon style={{...defaultStyles.icon, ...styles.icon}} />
        </Typography>
      </Grid>
      {mainText && (
        <Grid item>
          <Typography
            variant="h6"
            style={{...defaultStyles.text, ...styles.text}}
          >
            {mainText}
          </Typography>
        </Grid>
      )}
      {subText && (
        <Grid item>
          <Typography
            variant="subtitle1"
            style={{...defaultStyles.text, ...styles.text}}
          >
            {subText}
          </Typography>
        </Grid>
      )}
      <Grid item>
        {actions.map((action, i) => {
          return (
            <Button
              key={`blank-action-${i}`}
              color="primary"
              style={{...styles.button}}
              onClick={() => action.onClick()}
            >
              {action.label}
            </Button>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default EmptyState;
