/*
 * Contains a list of everyone's emails on the tech team that can be used
 * to filter users for any reason. For example, this list is used to filter
 * internal tech users within Google analytics.
 *
 * This is helpful when you want to partition beyond just internal users
 * (i.e. isPumpwellEmployee) but also want to partition internal users.
 */
export const techTeam = [
  "alex.savchenko@ambyint.com",
  "micheal.parent@ambyint.com",
  "matthew.schmidt@ambyint.com",
  "eugene.so@ambyint.com",
  "donnovan.lewis@ambyint.com",
  "shawn.wan@ambyint.com",
  "brad.abel@ambyint.com",
  "jesse.filipi@ambyint.com",
  "burke.pond@ambyint.com",
  "waseem.alkhayer@ambyint.com",
  "ha.nguyen@ambyint.com",
  "jason.banh@ambyint.com",
  "phillip.cho@ambyint.com",
  "don.campbell@ambyint.com",
  "josh.horwitz@ambyint.com",
  "jeff.taylor@ambyint.com",
  "ryan.fitzgerald@ambyint.com",
  "william.sim@ambyint.com",
  "anthony.powell@ambyint.com",
  "fernando.lujan@ambyint.com",
  "jason.saelhof@ambyint.com",
  "jason.bahn@ambyint.com",
  "mattison.greff@ambyint.com",
  "wentao.li@ambyint.com",
  "gordon.bird@ambyint.com",
  "tim.watkins@ambyint.com",
  "peter.chwiecko@ambyint.com",
  "albert.chu@ambyint.com",
  "colin.thompson@ambyint.com",
];
