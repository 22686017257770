import withStyles from "@material-ui/core/styles/withStyles";
import colors from "theme/colors";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import PropTypes from "prop-types";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";

const styles = theme => ({
  adornmentRoot: {
    alignSelf: "flex-end",
    color: colors.grey[500],
  },
  inputRoot: {
    fontSize: "15px",
  },
});

const Component = props => {
  const {classes, onChange, value} = props;
  return (
    <Input
      autoFocus
      classes={{root: classes.inputRoot}}
      endAdornment={
        <InputAdornment
          classes={{
            root: classes.adornmentRoot,
          }}
          position="end"
        >
          <SearchIcon />
        </InputAdornment>
      }
      fullWidth
      onChange={onChange}
      value={value}
    />
  );
};

Component.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default withStyles(styles)(Component);
