import {types} from "./types";

export const fetchClassifications = (
  wellId,
  downholeLocation,
  start = undefined,
  end = undefined,
) => {
  return {
    type: types.fetchClassifications,
    payload: {
      wellId,
      downholeLocation,
      start,
      end,
    },
  };
};
