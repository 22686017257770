import {connect} from "react-redux";
import {
  change,
  reduxForm,
  formValueSelector,
  SubmissionError,
} from "redux-form";
import capitalize from "lodash/capitalize";
import get from "lodash/get";
import Grid from "@material-ui/core/Grid";
import React from "react";

import compose from "utils/compose";
import countries from "constants/countries";
import doesAliasExist from "utils/well/does-alias-exist";
import FilterableSelect from "components/forms/filterable-select";
import FormHeader from "components/form-header";
import getSurfaceLocationHelper from "utils/well/get-surface-location-hint";
import PaperContainer from "components/paper-container";
import SaveCancelButtons from "components/save-cancel-buttons";
import {saveBasicInformation} from "actions/wells";
import spreadIf from "utils/spread-if";
import statesProvinces from "constants/states-provinces";
import TextField from "components/forms/text-field";
import TextFieldListMenu from "components/forms/text-field-list-menu";
import trimDetails from "utils/well/trim-details";

import getStateLabel from "./get-state-label";
import validate from "./validate";

const selector = formValueSelector("basic-information-edit-view");
const mapStateToProps = (state, props) => {
  const alias = get(props.well, "alias");
  const country = get(props.well, "country");
  const provinceState = get(props.well, "provinceState");
  const surfaceLocation = get(props.well, "surfaceLocation");
  const latitude = get(props.well, "coords[0]");
  const longitude = get(props.well, "coords[1]");
  const fieldName = get(props.well, "fieldName");
  const type = get(props.well, "pumpingUnitType");

  return {
    ...spreadIf(props.well, {
      initialValues: {
        alias,
        country: (country && country.toLowerCase()) || "canada", // Old wells don't seem to have country, and old UI defaults to "Canada" in the dialog, so we will do the same
        fieldName: capitalize(fieldName),
        latitude,
        longitude,
        provinceState: provinceState || "",
        surfaceLocation: capitalize(surfaceLocation),
        type: capitalize(type),
      },
    }),
    currentValues: {
      country: selector(state, "country"),
      surfaceLocation: selector(state, "surfaceLocation"),
    },
    fieldNames: state.fieldNames.values,
  };
};

class BasicInformationEditView extends React.Component {
  save = async (values, func, props) => {
    if (Object.keys(props.syncErrors).length > 0) {
      return;
    }

    const {downholeLocation, organization, wellId} = this.props.well;

    if (
      values.alias &&
      (await doesAliasExist(values.alias, organization.id, downholeLocation))
    ) {
      throw new SubmissionError({
        alias: "Alias is already in use.",
      });
    }

    this.props.dispatch(
      saveBasicInformation({
        ...values,
        coords: [values.latitude, values.longitude],
        downholeLocation,
        wellId,
        ...trimDetails(values),
      }),
    );

    this.props.onClose();
  };

  shouldComponentUpdate(nextProps, nextState) {
    // TODO: This is in here because of an infinite loop bug when using formValueSelector:
    //       Remove this when it is fixed
    //       https://github.com/erikras/redux-form/issues/2629
    return (
      JSON.stringify(this.props) !== JSON.stringify(nextProps) ||
      JSON.stringify(this.state) !== JSON.stringify(nextState)
    );
  }

  render() {
    const {
      fieldNames,
      onClose,
      handleSubmit,
      pristine,
      submitting,
      reset,
      currentValues: {country, surfaceLocation},
    } = this.props;

    return (
      <PaperContainer extraPadded style={{maxWidth: "750px"}}>
        <form onSubmit={handleSubmit(this.save)}>
          <Grid container direction={"row"} style={{maxWidth: 776}} spacing={2}>
            <Grid item xs={12}>
              <FormHeader>Basic Information</FormHeader>
            </Grid>

            <Grid item xs={6}>
              <TextField name="alias" label="Display Name" fullWidth />
            </Grid>
            <Grid item xs={6}>
              {country === "canada" ? (
                <FilterableSelect
                  id="field-name-select"
                  fullWidth
                  items={fieldNames}
                  label="Field Name"
                  name="fieldName"
                  required
                />
              ) : (
                <TextField
                  name="fieldName"
                  label="Field Name"
                  fullWidth
                  required
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <TextFieldListMenu
                name="type"
                label="Unit Type"
                options={["Pumpjack", "Pcp"]}
                formatDisplay={value => (value === "Pcp" ? "PCP" : value)}
                parse={value => value.toLowerCase()}
                disabled={true}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={3}>
              <TextFieldListMenu
                name="country"
                label={"Country"}
                options={countries}
                getValue={option => option.value}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={9}>
              <TextFieldListMenu
                name="provinceState"
                label={getStateLabel(country)}
                options={statesProvinces[country]}
                getValue={option => option.value}
                fullWidth
                required
              />
            </Grid>

            {country === "canada" && (
              <Grid item xs={4}>
                <TextField
                  helperText={getSurfaceLocationHelper(surfaceLocation)}
                  name="surfaceLocation"
                  label="Surface Location"
                  required
                  fullWidth
                />
              </Grid>
            )}

            <Grid item xs={country === "canada" ? 4 : 6}>
              <TextField
                name="latitude"
                label="Surface Latitude"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={country === "canada" ? 4 : 6}>
              <TextField
                name="longitude"
                label="Surface Longitude"
                fullWidth
                required
              />
            </Grid>
          </Grid>

          <SaveCancelButtons
            pristine={pristine}
            reset={compose(
              onClose,
              reset,
            )}
            submitting={submitting}
          />
        </form>
      </PaperContainer>
    );
  }
  catch(ex) {
    console.error(ex);
    return null;
  }
}

const Component = reduxForm({
  enableReinitialize: true,
  form: "basic-information-edit-view",
  onChange: (values, dispatch, props, previousValues) => {
    if (values.country !== previousValues.country) {
      dispatch(change("basic-information-edit-view", "provinceState", ""));
    }
  },
  validate,
})(BasicInformationEditView);

export default connect(mapStateToProps)(Component);
