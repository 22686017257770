import {connect} from "react-redux";
import {push} from "react-router-redux";
import Button from "@material-ui/core/Button";
import ExitAppIcon from "@material-ui/icons/ExitToApp";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import OffCallIcon from "@material-ui/icons/RadioButtonUnchecked";
import OnCallIcon from "@material-ui/icons/FiberManualRecord";
import Popover from "@material-ui/core/Popover";
import React from "react";
import Typography from "@material-ui/core/Typography";
import get from "lodash/get";
import Tooltip from "@material-ui/core/Tooltip";

import commonColors from "@ambyint/common/colors";

import Avatar from "components/avatar";
import colors from "theme/colors";
import {getVersion} from "utils/get-version";
import {signOut} from "actions/auth";
import interruptActionOnDirtyForm from "utils/interrupt-action-on-dirty-form";
import OrganizationView from "components/organization-view";

const mapStateToProps = (state, {user}) => {
  const currentOrganizationId = state.organizationView.currentOrganization;
  const currentOrganization =
    user &&
    user.assignedOrganizations &&
    user.assignedOrganizations.find(
      org => org.organizationId === currentOrganizationId,
    );

  return {
    onCall: state.onCall,
    currentOrganization: get(currentOrganization, "organizationName"),
  };
};

const styles = {
  accountButton: {
    color: colors.grey[50],
    marginTop: 16,
  },
  avatar: {
    width: 32,
    height: 32,
    color: commonColors.darkGrey,
    backgroundColor: "#fff",
    fontSize: ".85rem",
    fontWeight: "600",
    cursor: "pointer",
    margin: 0,
  },
  content: {
    width: 300,
  },
  email: {
    color: colors.grey[500],
  },
  name: {
    color: colors.grey[50],
    fontSize: 14,
  },
  onCall: {
    color: colors.onCall[500],
  },
  popover: {
    avatar: {
      marginRight: 16,
      color: commonColors.darkGrey,
      backgroundColor: "#fff",
    },
    container: {
      marginTop: 26,
      overflow: "hidden",
    },
    header: {
      backgroundColor: colors.ambyBlue[500],
      display: "flex",
      padding: 16,
    },
  },
  version: {
    borderTop: `1px solid ${colors.grey[200]}`,
    color: colors.grey[500],
    fontSize: "0.5rem",
    marginBottom: 8,
    paddingTop: 8,
    textAlign: "center",
  },
};

const AvatarTooltip = ({organization, user}) => (
  <div>
    <b>Ambyint Account</b>
    <br />
    {user.name}
    <br />
    {user.email}
    <br />
    <div style={{marginTop: 7}}>{organization}</div>
  </div>
);

class AvatarPopover extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.anchorEl = React.createRef();
  }

  close() {
    this.setState({open: false});
  }

  open(e) {
    this.setState({open: true});
  }

  navigateViaHref(path) {
    return () => {
      window.location.href = path;
      this.close();
    };
  }

  navigateViaRedux(path) {
    return () => {
      this.props.dispatch(push(path));
      this.close();
    };
  }

  render() {
    const {dispatch, onCall, user, currentOrganization} = this.props;
    const {open} = this.state;

    const isOnCall = onCall && onCall.isOnCall;

    if (!user) return null;

    return (
      <div data-test-id="app-bar-avatar">
        <Tooltip
          title={
            <AvatarTooltip organization={currentOrganization} user={user} />
          }
        >
          <Avatar
            user={user}
            open={open}
            style={styles.avatar}
            onClick={this.open.bind(this)}
            rootRef={this.anchorEl}
          />
        </Tooltip>
        <Popover
          open={open}
          anchorEl={this.anchorEl.current}
          onClose={this.close.bind(this)}
          anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          style={styles.popover.container}
          disableEnforceFocus
        >
          <div style={styles.content}>
            <div style={styles.popover.header}>
              <Avatar style={styles.popover.avatar} size={60} user={user} />
              <div>
                <Typography style={styles.name} data-test-id="popover-name">
                  {user.firstName} {user.lastName}
                </Typography>
                <Typography style={styles.email} data-test-id="popover-email">
                  {user.email}
                </Typography>
                <Button
                  style={styles.accountButton}
                  variant={"contained"}
                  color="primary"
                  onClick={this.navigateViaRedux("/profile/general")}
                >
                  My Account
                </Button>
              </div>
            </div>
            <List>
              <ListItem>
                <OrganizationView />
              </ListItem>
              <ListItem
                button
                onClick={this.navigateViaRedux("/profile/on-call")}
                data-test-id="popover-on-call"
              >
                <ListItemIcon>
                  {isOnCall ? (
                    <OnCallIcon style={styles.onCall} />
                  ) : (
                    <OffCallIcon />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={isOnCall ? "You are on call" : "You are not on call"}
                />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  this.close();

                  dispatch(interruptActionOnDirtyForm(signOut()));
                }}
              >
                <ListItemIcon>
                  <ExitAppIcon />
                </ListItemIcon>
                <ListItemText primary="Sign Out" />
              </ListItem>
            </List>
            <Typography variant={"caption"} style={styles.version}>
              {getVersion()}
            </Typography>
          </div>
        </Popover>
      </div>
    );
  }
}

export default connect(mapStateToProps)(AvatarPopover);
