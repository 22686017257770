import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import React from "react";
import {reduxForm} from "redux-form";

import getDefault from "utils/get-default-value";
import PUMPOFF_SETTING_MODES from "constants/pumpoff-setting-modes";
import SaveCancelButtons from "components/save-cancel-buttons";
import TextField from "components/forms/text-field";

import validate from "./validate";

const mapStateToProps = (state, props) => {
  const pumpoff = props.well.pumpOffSetting;

  const initialValues = {
    position: pumpoff && pumpoff.position ? pumpoff.position : getDefault("%"),
    primaryFillage:
      pumpoff && pumpoff.primaryFillage
        ? pumpoff.primaryFillage
        : getDefault("%"),
    strokes: pumpoff ? pumpoff.strokes : null,
  };

  if (props.mode === PUMPOFF_SETTING_MODES.STANDARD_SURFACE) {
    initialValues.load =
      pumpoff && pumpoff.load ? pumpoff.load : getDefault("%");
  }

  return {initialValues};
};

const View = props => {
  const {handleSubmit, invalid, submitting, onSubmit, onCancel} = props;

  return (
    <div style={{maxWidth: "760px"}}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction={"row"}>
          {props.mode === PUMPOFF_SETTING_MODES.STANDARD_SURFACE && (
            <Grid item xs={12} sm={6} lg>
              <TextField fullWidth name={"load"} label={"Load"} required />
            </Grid>
          )}
          <Grid item xs={12} sm={6} lg>
            <TextField
              fullWidth
              name={"position"}
              label={"Primary Fillage"}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} lg>
            <TextField
              fullWidth
              name={"primaryFillage"}
              label={"Secondary Pump Fillage"}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} lg>
            <TextField
              fullWidth
              name={"strokes"}
              label={"Consecutive Pumpoff Strokes"}
              required
            />
          </Grid>
        </Grid>
        <SaveCancelButtons
          invalid={invalid}
          onClose={onCancel}
          submitting={submitting}
        />
      </form>
    </div>
  );
};

const Component = reduxForm({
  enableReinitialize: true,
  form: "set-pumpoff-wellpilot-settings-form",
  validate,
})(View);

export default connect(mapStateToProps)(Component);
