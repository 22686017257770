import {connect} from "react-redux";
import {compose} from "recompose";
import get from "lodash/get";
import {withRouter} from "react-router";
import {push} from "react-router-redux";
import ErrorBoundary from "components/error-boundary";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import IconButton from "@material-ui/core/IconButton";
import MoreIcon from "@material-ui/icons/MoreVert";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import {eventAction, eventCategory} from "constants/analytics";
import {sendAnalyticsEvent} from "components/analytics";
import {withWellSearch} from "components/app-bar/with-search";
import FeatureFlag from "components/feature";
import Subheader from "components/subheader";
import flags from "constants/feature-flags";
import colors from "theme/colors";
import hasFlag from "utils/has-feature-flag";

import Title from "./title";
import {ShareableLink} from "./shareable-link";
import ManageTags from "./manage-tags";
import DownloadButton from "./download-button";
import AnalysisTab from "./analysis";
import DashboardTab from "./dashboard";
import SetupTab from "./setup";
import WellHealthTab from "./well-health";
import WellSurveillanceTab from "./well-surveillance";
import MobileMenu from "./mobile-menu";

const styles = ({spacing, breakpoints}) => ({
  root: {
    minWidth: "unset",
    height: 48,
    "&:hover": {
      cursor: "pointer",
      color: "rgb(252, 102, 33)",
    },
  },
  container: {
    backgroundColor: colors.white,
  },
  gridContainer: {
    padding: 0,
    display: "flex",
    alignItems: "center",
    height: "100%",
    minWidth: 0,
  },
  paper: {
    position: "absolute",
    zIndex: 100,
    width: "100%",
    height: 48,
  },
  progress: {
    position: "absolute",
    width: "15px",
    height: "15px",
    marginLeft: "5px",
  },
  actionItems: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-end",
    maxWidth: "100%",
  },
  tabGroup: {
    paddingRight: 32,
    [breakpoints.down("lg")]: {
      display: "none",
    },
  },
  tabGroupMobile: {
    [breakpoints.up("xl")]: {
      display: "none",
    },
  },
  tabContainer: {
    position: "absolute",
    top: 120,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: "auto",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
    flexBasis: "auto",
    [breakpoints.down("md")]: {
      display: "none",
    },
  },
  buttonGroupMobile: {
    [breakpoints.up("lg")]: {
      display: "none",
    },
    flexBasis: "unset",
  },
  buttonsMenu: {
    [breakpoints.up("lg")]: {
      display: "none",
    },
  },
  tabsMenu: {
    [breakpoints.up("xl")]: {
      display: "none",
    },
  },
  barContainer: {
    padding: "0 5px",
    [breakpoints.up("lg")]: {
      padding: "0 16px",
    },
  },
});

export const TABS = {
  ANALYSIS: "analysis",
  DASHBOARD: "dashboard",
  SETUP: "setup",
  WELL_HEALTH: "wellhealth",
  WELL_SURVEILLANCE: "surveillance",
};

const TAB_LABELS = {
  ANALYSIS: "Analysis",
  WELL_HEALTH: "Health",
  DASHBOARD: "Dashboard",
  SETUP: "Setup",
  WELL_SURVEILLANCE: "Surveillance",
};

const PATHS = {
  [TABS.ANALYSIS]: TABS.ANALYSIS,
  [TABS.DASHBOARD]: TABS.DASHBOARD,
  [TABS.SETUP]: `${TABS.SETUP}/basic`,
  [TABS.WELL_HEALTH]: TABS.WELL_HEALTH,
  [TABS.WELL_SURVEILLANCE]: TABS.WELL_SURVEILLANCE,
};

const getTab = ({location}) => {
  const tab = location.pathname.split("/")[3];

  return Object.values(TABS).includes(tab) ? tab : TABS.DASHBOARD;
};

const handleChange = ({dispatch, match, source}) => (event, value) => {
  dispatch(push(`${match.url}/${PATHS[value]}`));
};

const isFetching = well =>
  well && well.eventsByType && well.eventsByType.isFetching;

const mapStateToProps = (state, props) => {
  return {
    analysis: state.wells.analysis[props.well.downholeLocation],
    user: get(state.auth, "user", null),
  };
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps),
  withWellSearch,
  withStyles(styles),
);

class FullWidthTabs extends React.Component {
  state = {
    mobileMenuAnchor: null,
  };

  shouldComponentUpdate(nextProps, nextState) {
    const previousWell = this.props.well;
    const nextWell = nextProps.well;

    if (previousWell.downholeLocation !== nextWell.downholeLocation)
      return true;
    if (isFetching(previousWell) !== isFetching(nextWell)) return true;
    if (this.props.wells !== nextProps.wells) return true;
    if (this.props.analysis !== nextProps.analysis) return true;
    if (nextState.mobileMenuAnchor !== this.state.mobileMenuAnchor) return true;

    return getTab(this.props) !== getTab(nextProps);
  }

  openMobileMenu = e => {
    e.persist();
    this.setState({mobileMenuAnchor: e.currentTarget});
  };

  closeMobileMenu = () => {
    this.setState({mobileMenuAnchor: null});
  };

  render() {
    const {classes, well, user} = this.props;
    const {mobileMenuAnchor} = this.state;

    const tabClasses = {
      root: classes.root,
    };

    const eventsIsFetching =
      well && well.eventsByType && well.eventsByType.isFetching;

    const tab = getTab(this.props);

    const renderMobileTabs = (
      <Select
        value={tab}
        onChange={e => {
          handleChange(this.props)(e, e.target.value);
          sendAnalyticsEvent(
            eventCategory.wellDashboard,
            eventAction.mobileSubheaderTab,
            e.target.value,
          );
        }}
        displayEmpty
        className={classes.tabGroupMobile}
        name="location"
        MenuProps={{classes: {paper: classes.tabsMenu}}}
      >
        <MenuItem value={TABS.ANALYSIS}>{TAB_LABELS.ANALYSIS}</MenuItem>
        <MenuItem value={TABS.WELL_HEALTH}>{TAB_LABELS.WELL_HEALTH}</MenuItem>
        {hasFlag(flags.wellSurveillance, user) && (
          <MenuItem value={TABS.WELL_SURVEILLANCE}>
            {TAB_LABELS.WELL_SURVEILLANCE}
          </MenuItem>
        )}
        <MenuItem value={TABS.DASHBOARD}>{TAB_LABELS.DASHBOARD}</MenuItem>
        <MenuItem value={TABS.SETUP}>{TAB_LABELS.SETUP}</MenuItem>
      </Select>
    );

    return (
      <div className={classes.container}>
        <Subheader
          ignoreFeatureFlag
          hasContainer={false}
          className={classes.barContainer}
        >
          <Grid item xs={6} md={5} className={classes.gridContainer}>
            <Title well={well} />
          </Grid>
          <Grid
            container
            item
            xs={6}
            md={7}
            style={{justifyContent: "flex-end"}}
          >
            <Grid item xs={10} lg={9} className={classes.actionItems}>
              <Tabs
                value={tab}
                onChange={handleChange(this.props)}
                indicatorColor="primary"
                textColor="primary"
                className={classes.tabGroup}
              >
                <Tab
                  classes={tabClasses}
                  onClick={() =>
                    sendAnalyticsEvent(
                      eventCategory.wellDashboard,
                      eventAction.subheaderTab,
                      "Analysis",
                    )
                  }
                  label={
                    <span>
                      {TAB_LABELS.ANALYSIS}{" "}
                      {eventsIsFetching && (
                        <CircularProgress
                          className={classes.progress}
                          size={15}
                        />
                      )}
                    </span>
                  }
                  value={TABS.ANALYSIS}
                />
                <Tab
                  classes={tabClasses}
                  onClick={() =>
                    sendAnalyticsEvent(
                      eventCategory.wellDashboard,
                      eventAction.subheaderTab,
                      "Well Health",
                    )
                  }
                  label={TAB_LABELS.WELL_HEALTH}
                  value={TABS.WELL_HEALTH}
                />

                {/* Well surveillance flag to be removed */}
                {hasFlag(flags.wellSurveillance, user) && (
                  <Tab
                    classes={tabClasses}
                    label={TAB_LABELS.WELL_SURVEILLANCE}
                    value={TABS.WELL_SURVEILLANCE}
                  />
                )}

                <Tab
                  classes={tabClasses}
                  onClick={() =>
                    sendAnalyticsEvent(
                      eventCategory.wellDashboard,
                      eventAction.subheaderTab,
                      "Dashboard",
                    )
                  }
                  label={TAB_LABELS.DASHBOARD}
                  value={TABS.DASHBOARD}
                />
                <Tab
                  classes={tabClasses}
                  onClick={() =>
                    sendAnalyticsEvent(
                      eventCategory.wellDashboard,
                      eventAction.subheaderTab,
                      "Setup",
                    )
                  }
                  label={TAB_LABELS.SETUP}
                  value={TABS.SETUP}
                />
              </Tabs>
              {renderMobileTabs}
            </Grid>
            <Grid item lg={3} className={classes.buttonGroup}>
              <ManageTags wellId={this.props.well.wellId} />
              <ShareableLink />
              <DownloadButton
                disabled={!(tab === TABS.ANALYSIS && this.props.analysis)}
                analysis={this.props.analysis}
                well={well}
              />
            </Grid>
            <Grid item xs={2} className={classes.buttonGroupMobile}>
              <IconButton
                aria-haspopup="true"
                color="secondary"
                onClick={this.openMobileMenu}
              >
                <MoreIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Subheader>

        <div className={classes.tabContainer}>
          {tab === TABS.DASHBOARD && (
            <ErrorBoundary>
              <DashboardTab downholeLocation={well.downholeLocation} />
            </ErrorBoundary>
          )}
          {tab === TABS.ANALYSIS && (
            <ErrorBoundary>
              <AnalysisTab downholeLocation={well.downholeLocation} />
            </ErrorBoundary>
          )}
          {tab === TABS.SETUP && (
            <ErrorBoundary>
              <SetupTab downholeLocation={well.downholeLocation} />
            </ErrorBoundary>
          )}
          {tab === TABS.WELL_HEALTH && (
            <ErrorBoundary>
              <WellHealthTab downholeLocation={well.downholeLocation} />
            </ErrorBoundary>
          )}
          {tab === TABS.WELL_SURVEILLANCE && (
            <FeatureFlag flag={flags.wellSurveillance}>
              <ErrorBoundary>
                <WellSurveillanceTab well={well} />
              </ErrorBoundary>
            </FeatureFlag>
          )}
        </div>
        <MobileMenu
          menuAnchor={mobileMenuAnchor}
          well={well}
          classes={classes}
          tab={tab}
          analysis={this.props.analysis}
          onClose={this.closeMobileMenu.bind(this)}
        />
      </div>
    );
  }
}

export default enhance(FullWidthTabs);
