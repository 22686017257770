import {getUnits} from "@ambyint/common/utils/get-units";
import {length, densityLinear, height} from "@ambyint/common/unit-converter";
import valueOf from "@ambyint/common/utils/value-of";

import {round} from "@ambyint/common/utils/round";

export const parse = ({sections, unitsOfMeasure}) => {
  const units = getUnits(unitsOfMeasure);

  const convertLength = value =>
    round(
      length.convert({from: units.length, to: "m", value: valueOf(value)}),
      4,
    );

  const convertHeight = value =>
    round(
      height.convert({from: units.height, to: "m", value: valueOf(value)}),
      4,
    );

  return sections.map(section => ({
    ...section,
    interval: convertHeight(section.interval),
    rodDimensions: {
      ...section.rodDimensions,
      diameter: convertLength(section.rodDimensions.diameter),
      pin: convertLength(section.rodDimensions.pin),
      density: round(
        densityLinear.convert({
          from: unitsOfMeasure,
          value: valueOf(section.rodDimensions.density),
        }),
        2,
      ),
      minCrossSectionDiameter: valueOf(
        section.rodDimensions.minCrossSectionDiameter,
      ),
    },
  }));
};

export default parse;
