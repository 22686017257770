import isEmpty from "lodash/isEmpty";

import types from "./types";

const validateType = (values, props) => {
  const type = types[props.type];

  return type && type.validate ? type.validate(values, props.well) : {};
};

export default (values, props) => {
  const errors = validateType(values, props);

  return !isEmpty(errors) ? {acceptedData: errors} : null;
};
