import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import React, {useState} from "react";
import {connect} from "react-redux";
import {formValueSelector, reduxForm, reset} from "redux-form";

import {hide} from "actions/recommendation-action-dialog";
import {
  acceptRecommendation,
  dismissRecommendation,
  setDowntime,
} from "actions/wells";
import Dialog from "components/dialog";

import getPrivileges from "utils/get-privileges";
import privilegeTypes from "utils/privilege-types";

import types from "./types";
import validate from "./validate";
import parse from "./parse-accepted-data";

const FORM_NAME = "recommendation-action-dialog";

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = state => {
  const {isOpen, recommendation = {}, well} = state.recommendationActionDialog;

  return {
    currentValues: {
      acceptedData: selector(state, "acceptedData"),
      comment: selector(state, "comment"),
    },
    initialValues: {
      acceptedData: recommendation.recommendedData || {},
    },
    isOpen,
    recommendation,
    type: recommendation.type,
    well,
  };
};

const RecommendationActionDialog = props => {
  const [actionTaken, setActionTaken] = useState(false);

  const closeDialog = () => {
    const {dispatch} = props;

    dispatch(hide());
    dispatch(reset(FORM_NAME));
    setActionTaken(false);
  };

  const submitAction = action => {
    props.dispatch(action);
    closeDialog();
  };

  const dismiss = () => {
    const {comment} = props.currentValues;

    setActionTaken(true);

    submitAction(
      dismissRecommendation({
        recommendationId: props.recommendation.recommendationId,
        ...(comment ? {reason: comment} : {}),
      }),
    );
  };

  const accept = values => {
    const {recommendationId, type} = props.recommendation;
    const {downholeLocation} = props.well;
    const {comment} = values;
    const acceptedData = parse(type, values.acceptedData);

    setActionTaken(true);

    if (props.type === "idleTime") {
      const {hours, minutes} = acceptedData;

      submitAction(
        setDowntime({
          downholeLocation: downholeLocation,
          data: {
            hours: hours,
            minutes: minutes,
          },
        }),
      );
    }

    submitAction(
      acceptRecommendation({
        recommendationId,
        ...(comment ? {comment} : {}),
        ...(acceptedData ? {acceptedData} : {}),
      }),
    );
  };

  const {handleSubmit, isOpen, well} = props;

  const type = types[props.type];

  const privileges = well ? getPrivileges(well.organization.id) : [];
  const hasControl = privileges.includes(privilegeTypes.well.control);

  return (
    <Dialog onClose={closeDialog} open={isOpen}>
      {isOpen && (
        <>
          <DialogTitle>Review Recommendation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {props.recommendation.description}
            </DialogContentText>
          </DialogContent>
          {type && (
            <type.Form currentValues={props.currentValues} well={well} />
          )}
          <DialogActions>
            <Button color="primary" onClick={closeDialog}>
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={!hasControl || actionTaken}
              onClick={dismiss}
            >
              Decline
            </Button>
            <Button
              color="primary"
              disabled={!hasControl || actionTaken}
              onClick={handleSubmit(accept)}
            >
              Accept
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

const Component = reduxForm({
  enableReinitialize: true,
  form: FORM_NAME,
  validate,
})(RecommendationActionDialog);

export default connect(mapStateToProps)(Component);
