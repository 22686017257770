import React from "react";
import Button from "@material-ui/core/Button";

import {viewFeedComparison} from "actions/wells";
import DisplayCards from "components/d3-cards";
import store from "store";
import getShutdownCardReason from "utils/well/get-shutdown-card-reason";
import FeedItem from "../feed-item";

const actionsContainerStyle = {
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
};

const cardContainerStyle = {width: "100%"};

export default props => {
  const {event, well} = props;

  const onCompare = () => {
    store.dispatch(
      viewFeedComparison({
        well,
        correlationId: event.correlationId,
      }),
    );
  };

  const actions = event.correlationId
    ? {
        actions: (
          <div style={actionsContainerStyle}>
            <Button color="primary" onClick={onCompare}>
              COMPARE
            </Button>
          </div>
        ),
      }
    : {};

  const chart = (
    <div style={cardContainerStyle}>
      <DisplayCards {...event} />
    </div>
  );

  const shutdownReason = getShutdownCardReason(event);

  return (
    <FeedItem {...actions} chart={chart} event={event}>
      <strong>{shutdownReason && `Shutdown `}Card</strong>
      {" occurred"}
      {shutdownReason && ", caused by "}
      {shutdownReason && <strong>{shutdownReason}</strong>}.
    </FeedItem>
  );
};
