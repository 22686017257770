import {actions} from "react-redux-form";
import store from "store";
import fieldName from "utils/validation/field-name";
import latitude from "utils/validation/latitude";
import longitude from "utils/validation/longitude";
import number from "utils/validation/number";
import required from "utils/validation/required";
import surfaceLocation from "utils/validation/surface-location";

import formPath from "../form-path";

export default () => {
  const {wellCountry} = store.getState().forms.addWell;

  store.dispatch(
    actions.validateFieldsErrors(formPath, {
      fieldName: value => fieldName(wellCountry)(value),
      surfaceLocation: value => surfaceLocation(wellCountry)(value),
      surfaceLocationLatitude: value => ({
        required: required(value),
        number: number(value),
        latitude: latitude(value),
      }),
      surfaceLocationLongitude: value => ({
        required: required(value),
        number: number(value),
        longitude: longitude(value),
      }),
    }),
  );
};
