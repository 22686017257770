import {
  LOW_LOAD,
  HIGH_LOAD,
  DTV,
  HOLE_IN_BARREL,
  LEAKING,
} from "@ambyint/ai-models/lib/constants/outputs";
import {isYoungerThan} from "utils/time/is-younger-than";

const ANOMALY_ORDER = [LOW_LOAD, HIGH_LOAD, DTV, HOLE_IN_BARREL, LEAKING];

const findIndexOfType = type =>
  ANOMALY_ORDER.findIndex(anomalyType => anomalyType === type);

export const getAnomalyCardSummary = anomalySummaries => {
  const relevantSummaries = anomalySummaries.filter(({range}) =>
    isYoungerThan(range.end, 24, "hours"),
  );
  if (!relevantSummaries || relevantSummaries.length === 0) return null;

  if (relevantSummaries.length === 1) return relevantSummaries[0];

  // Sort by confidence
  const highestConfidenceInSummaries = relevantSummaries.reduce(
    (currHighest, {latestConfidence}) =>
      currHighest >= latestConfidence ? currHighest : latestConfidence,
    -1,
  );

  // There can be multiple summaries with the same highest confidence number
  const highestSummaries = relevantSummaries.filter(
    ({latestConfidence}) => latestConfidence === highestConfidenceInSummaries,
  );

  if (highestSummaries.length === 1) return highestSummaries[0];

  // Select the summary with the highest confidences by anomaly order
  return highestSummaries.reduce((best, current) => {
    const bestIndex = findIndexOfType(best.type);
    const currentIndex = findIndexOfType(current.type);
    const isCurrentBetter =
      bestIndex < 0 || (currentIndex > -1 && bestIndex > currentIndex);
    return isCurrentBetter ? current : best;
  }, highestSummaries[0]);
};
