import React from "react";
import eq from "lodash/fp/eq";
import withStyles from "@material-ui/core/styles/withStyles";

import {CLASSIFICATION} from "constants/classification";
import ButtonGroup from "components/button-group";
import ButtonOutline from "components/button-outline";

import ButtonLabel from "./button-label";

const {DIALED_IN, OVER_PUMPING, UNDER_PUMPING, UNCERTAIN} = CLASSIFICATION;

const isDialedIn = eq(DIALED_IN);
const isOverPumping = eq(OVER_PUMPING);
const isUnderPumping = eq(UNDER_PUMPING);
const isUncertain = eq(UNCERTAIN);

const styles = () => ({
  buttonGroup: {
    "& button": {
      minWidth: 140,
    },
  },
});

const ClassificationFilter = ({
  classes,
  classification,
  setClassification,
  data,
}) => (
  <ButtonGroup className={classes.buttonGroup}>
    <ButtonOutline
      isActive={isDialedIn(classification)}
      onClick={setClassification(DIALED_IN)}
    >
      <ButtonLabel {...data[DIALED_IN]} />
    </ButtonOutline>

    <ButtonOutline
      isActive={isUnderPumping(classification)}
      onClick={setClassification(UNDER_PUMPING)}
    >
      <ButtonLabel {...data[UNDER_PUMPING]} />
    </ButtonOutline>
    <ButtonOutline
      isActive={isOverPumping(classification)}
      onClick={setClassification(OVER_PUMPING)}
    >
      <ButtonLabel {...data[OVER_PUMPING]} />
    </ButtonOutline>
    <ButtonOutline
      isActive={isUncertain(classification)}
      onClick={setClassification(UNCERTAIN)}
    >
      <ButtonLabel {...data[UNCERTAIN]} />
    </ButtonOutline>
  </ButtonGroup>
);

export default withStyles(styles)(ClassificationFilter);
