import contains from "utils/contains";

import cleanQuotes from "./clean-quotes";

export default (items = [], filter = "") => {
  return items.filter(item => {
    const {fieldName} = item;
    const criteria = cleanQuotes(filter.split(":")[1]);

    if (!criteria) return true;

    return contains(fieldName, criteria);
  });
};
