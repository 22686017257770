import React from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = ({palette}) => ({
  unconfigured: {
    margin: "12px 0",
    color: palette.grey[600],
    fontSize: "0.93em",
  },
});

const Component = props => (
  <Grid item xs={12}>
    <Typography className={props.classes.unconfigured}>Unconfigured</Typography>
  </Grid>
);

export const Unconfigured = withStyles(styles)(Component);
