import React from "react";
import {connect} from "react-redux";

import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import {withStyles} from "@material-ui/core/styles";

import {fetchTimeline} from "actions/alerts";
import AlertActionButtons from "components/alert-action-buttons";
import DisplayAlertTimelineEvent from "components/display-alert-timeline-event";
import {FormatDateTime} from "components/format/date";
import colors from "theme/colors";

const styleSheet = theme => ({
  collapseIcon: {
    color: colors.ambyOrange[500],
    marginLeft: 8,
    marginRight: 8,
  },
  listContainer: {
    display: "flex",
    alignItems: "center",
    paddingRight: 0,
  },
  listItem: {
    cursor: "pointer",
    paddingRight: 0,
  },
  nested: {
    padding: 0,
    width: "100%",
  },
  secondaryActions: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  secondaryActionContainer: {
    position: "relative",
    transform: "none",
  },
  timeline: {
    borderTop: `thin solid ${colors.grey[200]}`,
    borderBottom: `thin solid ${colors.grey[200]}`,
  },
});

const statusHeader = alert => {
  if (alert.status === "resolved") {
    return <span style={{color: colors.green[500]}}> - Resolved</span>;
  }

  if (alert.status === "acknowledged") {
    return <span style={{color: colors.indigo[500]}}> - Acknowledged</span>;
  }

  if (alert.status === "unacknowledged") {
    return <span style={{color: colors.red[500]}}> - Unacknowledged</span>;
  }

  return null;
};

class Alert extends React.Component {
  constructor(props) {
    super(props);

    this.state = {open: false, hasFetchedTimeline: false};
  }

  handleListItemClick = () => {
    const {dispatch, alert} = this.props;

    if (!this.state.hasFetchedTimeline) {
      dispatch(fetchTimeline({alertId: alert.alertId}));
      this.setState({hasFetchedTimeline: true});
    }

    this.setState({open: !this.state.open});
  };

  componentDidUpdate(nextProps) {
    if (nextProps.alert.alertId !== this.props.alert.alertId) {
      this.setState({open: false, hasFetchedTimeline: false});
    }
  }

  render() {
    const {alert, classes} = this.props;

    return [
      <ListItem
        onClick={this.handleListItemClick}
        className={classes.listItem}
        key={`${alert.alertId}-listitem`}
        ContainerComponent={props => (
          <div {...props} className={classes.listContainer}>
            {props.children}
          </div>
        )}
      >
        <ListItemText
          primary={
            <span>
              <FormatDateTime date={alert.createdOn} />
              {statusHeader(alert)}
            </span>
          }
          secondary={alert.description}
          classes={classes.listItemText}
        />
        <ListItemSecondaryAction className={classes.secondaryActionContainer}>
          <div className={classes.secondaryActions}>
            <AlertActionButtons alert={alert} />
            <div
              role="switch"
              tabIndex="-1"
              aria-checked={this.state.open}
              onClick={this.handleListItemClick}
            >
              {this.state.open ? (
                <ExpandLess className={classes.collapseIcon} />
              ) : (
                <ExpandMore className={classes.collapseIcon} />
              )}
            </div>
          </div>
        </ListItemSecondaryAction>
      </ListItem>,
      <Collapse
        component="li"
        in={this.state.open}
        timeout="auto"
        unmountOnExit
        className={classes.timeline}
        key={`${alert.alertId}-collapse`}
      >
        <List>
          {alert.timeline.map((event, key) => (
            <ListItem key={key} className={classes.nested}>
              <DisplayAlertTimelineEvent
                key={event._id}
                event={event}
                alert={alert}
                style={{
                  width: "94%",
                  margin: "auto",
                  padding: 8,
                }}
              />
            </ListItem>
          ))}
        </List>
      </Collapse>,
    ];
  }
}

export default withStyles(styleSheet)(connect()(Alert));
