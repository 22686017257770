import {connect} from "react-redux";
import HelpCircle from "mdi-material-ui/HelpCircle";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MenuItem from "@material-ui/core/MenuItem";
import React, {useState} from "react";
import Select from "@material-ui/core/Select";

import {setTagSelectionType} from "actions/tagging";
import tagSelectionTypes from "constants/tag-selection-types";

import HelpPopover from "./help-popover";

const useStyles = makeStyles(({spacing, colors, typography}) => ({
  root: {
    padding: spacing(2, 4),
    display: "flex",
    alignItems: "center",
    color: colors.warmGrey[600],
    backgroundColor: colors.warmGrey[50],
  },
  select: {
    margin: spacing(0, 1),
    backgroundColor: "white",
    minWidth: spacing(11),
    color: colors.warmGrey[600],
  },
  icon: {
    color: colors.warmGrey[400],
    padding: spacing(1),
  },
  body1: {
    ...typography.body1,
    color: colors.warmGrey[600],
  },
}));

const mapStateToProps = ({tags}) => {
  return {
    selectionType: tags.selectionType,
  };
};

const mapDispatchToProps = {
  setTagSelectionType,
};

const Selection = ({selectionType, setTagSelectionType}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <span className={classes.body1}>Select wells with</span>
      <Select
        className={classes.select}
        value={selectionType}
        onChange={({target: {value}}) => setTagSelectionType(value)}
        variant="outlined"
        margin="dense"
      >
        {tagSelectionTypes.map(type => (
          <MenuItem key={type} value={type}>
            {type}
          </MenuItem>
        ))}
      </Select>
      <span className={classes.body1}>of the following tags:</span>
      <HelpCircle
        className={classes.icon}
        onMouseOver={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <HelpPopover anchorEl={anchorEl} />
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Selection);
