import compose from "lodash/fp/compose";
import defaultTo from "lodash/fp/defaultTo";
import find from "lodash/fp/find";

import {SPEED_RANGE} from "@ambyint/common/constants/event-types";

const defaultSpeedRange = {
  type: SPEED_RANGE,
  on: false,
  autoAccept: false,
};

export const findSpeedRangeType = compose(
  defaultTo(defaultSpeedRange),
  find({type: SPEED_RANGE}),
);
