import React from "react";

import {COLORS, LABELS} from "constants/well-health";
import getUnits from "utils/get-units";
import round from "utils/round";

import BaseWellboreGraph from "./base-wellbore-graph";
import dataToPoint from "./data-to-point";
import {thresholdAtDepth} from "../../../dls-threshold";

const TooltipDLS = ({tooltipData: {data, pointNumber}, setColor}) => {
  const {
    inclinationDegrees,
    doglegSeverityDeg30m,
    trueVerticalDepth,
    dlsMap,
    measuredDepth,
  } = dataToPoint(
    data,
    [
      "inclinationDegrees",
      "doglegSeverityDeg30m",
      "trueVerticalDepth",
      "dlsMap",
      "measuredDepth",
    ],
    pointNumber,
  );

  setColor(dlsMap ? COLORS.DOGLEG_SEVERITY : COLORS.NO_DOGLEG_SEVERITY);

  return (
    <BaseWellboreGraph
      title={LABELS.DOGLEG_SEVERITY}
      primaryContentList={
        dlsMap
          ? [
              {
                label: "Warning:",
                value: `>= ${thresholdAtDepth(measuredDepth).degrees}°`,
              },
            ]
          : undefined
      }
      secondaryContentList={[
        {label: "Inclination:", value: `${round(inclinationDegrees)}°`},
        {label: "DLS:", value: `${round(doglegSeverityDeg30m)}°`},
        {
          label: "TVD:",
          value: `${round(trueVerticalDepth, 0)}${getUnits().depth}`,
        },
      ]}
    />
  );
};

export default TooltipDLS;
