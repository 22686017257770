import get from "lodash/get";
import getUser from "utils/get-user";

const whitelistForUser = user => [
  "casingPressure",
  "current",
  "deflection",
  "dutyCycle",
  "driveFault",
  "driveMode",
  "effectiveSpm",
  "faultRecovery",
  "fillage",
  "gasProduction",
  "load",
  "manualFaultReset",
  "oilProduction",
  "powerStatus",
  "production",
  "rpm",
  "rrpm",
  "runningStatus",
  "spm",
  "stuffingBoxLeakPressure",
  "torque",
  "totalReductionRatio",
  "tubingPressure",
  "waterProduction",
  "wellCurrentViolation",
  "wellTorqueViolation",
  "inferredFillage",
];

export const generateCsv = props => {
  const {well} = props;
  const baseCsv =
    "data:text/csv;charset=utf-8,type,createOn,units,value,min,max\r\n";
  const whitelist = whitelistForUser(getUser());

  return well.events.reduce((current, event) => {
    if (!whitelist.includes(event.type)) {
      return current;
    }

    const units = get(event, "units", "");
    const value = get(event, "value", "");
    const min = get(event, "min", "");
    const max = get(event, "max", "");

    return `${current}${event.type},${
      event.createdOn
    },${units},${value},${min},${max}\r\n`;
  }, baseCsv);
};
