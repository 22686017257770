import updateDeep from "utils/update-deep";

export default ({state, action}) => {
  return updateDeep(
    state,
    [
      "filtered",
      action.payload.organizationId,
      user => user.clientUserId === action.payload.clientUserId,
    ],
    user => ({
      ...user,
      accessLevel: action.payload.accessLevel,
      isInvitedUserApprover: action.payload.isInvitedUserApprover,
    }),
  );
};
