import {connect} from "react-redux";
import {reduxForm, formValueSelector} from "redux-form";
import get from "lodash/get";
import Grid from "@material-ui/core/Grid";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import pullAt from "lodash/pullAt";
import React from "react";

import compose from "utils/compose";
import {fetch as fetchCranks} from "actions/cranks";
import FormButton from "components/form-button";
import FormHeader from "components/form-header";
import PaperContainer from "components/paper-container";
import SaveCancelButtons from "components/save-cancel-buttons";
import {savePumpingUnitInformation} from "actions/wells";
import {search, setSearch} from "actions/pumpjacks";
import SearchInputWithSuggestions from "components/forms/search-input-with-suggestions";
import spreadIf from "utils/spread-if";

import CrankWeightEditView from "./geometry-details/crank-weight-edit-view";
import CrankWeightReadView from "./geometry-details/crank-weight-read-view";
import getPumpingUnitLabel from "./get-pumping-unit-label";
import GeometryDetailsEditView from "./geometry-details/geometry-details-edit-view";
import hasGeometry from "./has-geometry";
import validate from "./validate";

const mapCrankWeights = crankWeights => {
  return !crankWeights || crankWeights.length === 0 ? [] : crankWeights;
};

const styles = {
  grid: {
    minWidth: 400,
    maxWidth: 776,
  },
};

const selector = formValueSelector("pumping-unit-information-edit-view");

const mapStateToProps = (state, props) => {
  const downholeLocation = get(props.well, "downholeLocation");
  const crank = get(props.well, "pumpingUnit.crank");
  const crankWeights = mapCrankWeights(
    get(props.well, "pumpingUnit.crankWeights"),
  );
  const pumpjack = get(props.well, "pumpingUnit.pumpjack");
  const crankPinPosition = get(props.well, "pumpingUnit.crankPinPosition");
  const rotationDirection = get(props.well, "pumpingUnit.rotationDirection");

  const pumpjackSearch = getPumpingUnitLabel(pumpjack);

  return {
    cranks: state.cranks,
    crankWeights,
    pumpjacks: state.pumpjacks,
    ...spreadIf(props.well, {
      initialValues: {
        rotationDirection,
        crank,
        crankWeights,
        crankPinPosition,
        pumpjack,
        pumpjackSearch,
        downholeLocation,
      },
    }),
    currentValues: {
      crank,
      crankWeights: selector(state, "crankWeights"),
      pumpjack: selector(state, "pumpjack"),
    },
  };
};

class PumpingUnitInformationEditView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crankWeights: props.crankWeights,
    };
  }

  componentWillMount() {
    this.props.dispatch(fetchCranks());
  }

  save = (values, func, props) => {
    if (Object.keys(props.syncErrors).length > 0) {
      return;
    }

    this.props.dispatch(
      savePumpingUnitInformation({
        type: values.pumpjack.type,
        ...values,
        crankWeights: this.state.crankWeights,
        downholeLocation: this.props.well.downholeLocation,
        wellId: this.props.well.wellId,
      }),
    );

    this.props.onClose();
  };

  getPumpjack() {
    return this.props.currentValues.pumpjack !== undefined
      ? this.props.currentValues.pumpjack
      : this.props.pumpjack;
  }

  renderCrankWeightOptions() {
    return (
      <>
        <Grid item xs={12} style={{marginTop: "16px"}}>
          <FormHeader>Crank Weights</FormHeader>
        </Grid>
        {this.state.crankWeights.map((crankWeight, index) => {
          if (
            !isNil(this.state.editCrankWeight) &&
            this.state.editCrankWeight === index
          ) {
            return (
              <CrankWeightEditView
                key={`crank-weight-write-${index}`}
                crankWeight={crankWeight}
                onSave={crankWeight => {
                  const crankWeights = [...this.state.crankWeights];
                  crankWeights.splice(index, 1, crankWeight);

                  this.setState({
                    crankWeights,
                    editCrankWeight: null,
                  });

                  this.props.change("crankWeights", crankWeights);
                }}
                onClose={() => {
                  this.setState({
                    crankWeights: this.state.crankWeights.filter(
                      crankWeight => !isEmpty(crankWeight),
                    ),
                    editCrankWeight: null,
                  });
                }}
                containerStyle={styles.grid}
              />
            );
          }

          return (
            <CrankWeightReadView
              key={`crank-weight-read-${index}`}
              index={index}
              crankWeight={crankWeight}
              edit={() => {
                this.setState({editCrankWeight: index});
              }}
              copy={() => {
                const crankWeights = [...this.state.crankWeights];
                crankWeights.splice(index, 0, crankWeight);
                this.setState({crankWeights});
                this.props.change("crankWeights", crankWeights);
              }}
              remove={() => {
                const crankWeights = [...this.state.crankWeights];
                pullAt(crankWeights, index);
                this.setState({crankWeights});
                this.props.change("crankWeights", crankWeights);
              }}
              containerStyle={styles.grid}
              isDisabled={!isNil(this.state.editCrankWeight)}
              isCopyDisabled={
                !isNil(this.state.editCrankWeight) ||
                this.state.crankWeights.length === 8
              }
            />
          );
        })}
        <Grid
          container
          direction={"row"}
          style={{
            ...styles.grid,
            marginTop: "8px",
          }}
          justify={"space-between"}
        >
          <Grid item xs={9} />
          <Grid item xs={3}>
            <FormButton
              disabled={
                !isNil(this.state.editCrankWeight) ||
                this.state.crankWeights.length === 8
              }
              variant={"contained"}
              fullWidth
              style={{color: "white"}}
              onClick={() => {
                const crankWeights = [...this.state.crankWeights, {}];
                this.setState({
                  crankWeights,
                  editCrankWeight: crankWeights.length - 1,
                });
              }}
            >
              Add Crank Weight
            </FormButton>
          </Grid>
        </Grid>
      </>
    );
  }

  resetGeometryFields() {
    this.props.change("crank", null);
    this.props.change("crankWeights", []);
    this.props.change("rotationDirection", null);
    this.props.change("crankPinPosition", null);
  }

  render() {
    const {
      cranks,
      handleSubmit,
      onClose,
      pumpjacks,
      pristine,
      submitting,
      reset,
    } = this.props;

    const pumpjack = this.getPumpjack(this.props);

    return (
      <PaperContainer extraPadded style={{maxWidth: 750}}>
        <form onSubmit={handleSubmit(this.save)}>
          <Grid container direction={"row"} style={styles.grid} spacing={2}>
            <Grid item xs={12} style={{marginTop: "0px", marginBottom: "0px"}}>
              <FormHeader>Pumping Unit</FormHeader>
            </Grid>

            <Grid item xs={12}>
              <SearchInputWithSuggestions
                name="pumpjackSearch"
                label="Pumping Unit*"
                placeholder="Search for a pumpjack..."
                onSearch={event => {
                  this.pumpjackSearch = event.value;

                  if (event.value && event.value.length >= 3) {
                    this.props.dispatch(
                      search({searchTerm: this.pumpjackSearch}),
                    );
                  } else {
                    this.props.dispatch(setSearch({}));
                  }
                }}
                throttle={500}
                suggestions={pumpjacks.search}
                getSuggestionText={getPumpingUnitLabel}
                getSuggestionValue={suggestion => ({
                  text: getPumpingUnitLabel(suggestion),
                  pumpjack: suggestion,
                })}
                onSuggestionClick={data => {
                  this.props.change("pumpjack", data.pumpjack);
                }}
                onSuggestionKeypress={() => {
                  this.props.change("pumpjack", null);
                  this.resetGeometryFields();
                }}
                helperText={pumpjack && `Model: ${pumpjack.model}`}
                fullWidth
              />
            </Grid>

            {hasGeometry(pumpjack) && (
              <GeometryDetailsEditView
                cranks={cranks}
                pumpingUnit={this.getPumpjack()}
                onSelect={data => this.props.change("crank", data.crank)}
              />
            )}
          </Grid>
          {hasGeometry(pumpjack) && this.renderCrankWeightOptions()}

          <SaveCancelButtons
            pristine={pristine}
            reset={compose(
              onClose,
              reset,
            )}
            submitting={submitting}
          />
        </form>
      </PaperContainer>
    );
  }
}

const Component = reduxForm({
  form: "pumping-unit-information-edit-view",
  enableReinitialize: true,
  validate,
})(PumpingUnitInformationEditView);

export default connect(mapStateToProps)(Component);
