import TextField from "components/forms/text-field";
import React from "react";

const PasswordTextField = props => {
  return (
    <TextField {...props} type="password" autoComplete="current-password" />
  );
};

export default PasswordTextField;
