import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

import {COLORS, LABELS} from "constants/well-health";

const styles = ({typography, spacing, colors}) => ({
  main: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },

  item: {
    width: "initial",
    margin: spacing(0, 2),
  },

  swatch: {
    width: "26px",
    height: "10px",
    borderRadius: "3px",
    boxShadow: "2px 2px 4px rgba(0,0,0,0.1)",
    marginRight: "8px",
  },

  swatchLabel: {
    ...typography.subtitle1,
    color: colors.warmGrey[900],
  },
});

const WellboreLegend = ({classes, items}) => {
  return (
    <div className={classes.main}>
      {items.map(item => (
        <Grid container alignItems="center" className={classes.item} key={item}>
          <Grid item>
            <div
              className={classes.swatch}
              style={{backgroundColor: COLORS[item]}}
            />
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" className={classes.swatchLabel}>
              {LABELS[item]}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

export default withStyles(styles)(WellboreLegend);
