import {curveStepAfter as d3curveStepAfter, line as d3line} from "d3-shape";
import colors from "theme/colors";

export default props => {
  const {
    clipPathId,
    curve = d3curveStepAfter,
    mapData,
    mapX,
    mapY,
    selection,
    styles: {
      fill = colors.transparent,
      stroke = colors.black,
      strokeWidth = 2,
    } = {},
    xScale,
    yScale,
  } = props;

  const line = d3line().curve(curve);

  const path = selection
    .append("path")
    .attr("fill", fill)
    .attr("stroke", stroke)
    .attr("stroke-width", strokeWidth);

  if (clipPathId) {
    path.attr("clip-path", `url(#${clipPathId})`);
  }

  return {
    node: path.node(),
    selection: path,

    hide() {
      path.attr("visibility", "hidden");
    },

    show() {
      path.attr("visibility", "visible");
    },

    draw({data}) {
      line.x(d => xScale(mapX(d))).y(d => yScale(mapY(d)));

      path.attr("d", line(mapData(data)));
    },

    zoom({data, xScale}) {
      line.x(d => xScale(mapX(d)));
      path.attr("d", line(mapData(data)));
    },
  };
};
