import {combineForms} from "react-redux-form";

import addWell from "./add-well";

export default combineForms(
  {
    addWell,
  },
  "forms",
);
