import React, {useState} from "react";
import createPlotlyComponent from "react-plotly.js/factory";
import debounce from "lodash/debounce";
import get from "lodash/get";
import Plotly from "plotly.js-gl3d-dist/plotly-gl3d";

import {ID, VIEW} from "constants/well-health";
import Tooltip, {
  TooltipDLS,
  TooltipPlunger,
  TooltipRod,
  TooltipWellbore,
} from "../tooltip";

const Plot = createPlotlyComponent(Plotly);

const tooltipComponents = {
  [VIEW.WELLBORE]: {
    [ID.WELLBORE]: TooltipWellbore,
  },
  [VIEW.EQUIPMENT]: {
    [ID.WELLBORE]: TooltipWellbore,
    [ID.ROD]: TooltipRod,
    [ID.PLUNGER]: TooltipPlunger,
  },
  [VIEW.DOGLEG]: {
    [ID.WELLBORE]: TooltipDLS,
  },
};

const Wellbore3D = ({view, data, layout, config}) => {
  const [hoverData, setHoverData] = useState(null);

  // Switching between views causes the data for the graph to change.
  // This causes the camera to reset position which is not ideal for out case.
  // By saving the camera using the onInitialized and onUpdate events from the Plot component,
  // we can save their pan/zoom/rotate state when switching data sets.
  const [camera, setCamera] = useState(get(layout, "scene.camera", {}));
  layout.scene.camera = camera;

  const debouncedOnHover = debounce(
    ({points}) => {
      setHoverData({
        type: points[0].data.name,
        data: points[0],
      });
    },
    10,
    {maxWait: 500, leading: true},
  );

  const debouncedOnUnhover = debounce(
    () => {
      setHoverData(null);
    },
    10,
    {maxWait: 500, leading: true},
  );

  const debouncedOnUpdate = debounce(
    figure => {
      setCamera(get(figure, "layout.scene.camera", {}));
    },
    10,
    {maxWait: 500, leading: true},
  );

  return (
    <>
      <Plot
        debug={true}
        data={data}
        layout={layout}
        config={config}
        onHover={debouncedOnHover}
        onUnhover={debouncedOnUnhover}
        onInitialized={debouncedOnUpdate}
        onUpdate={debouncedOnUpdate}
      />

      {hoverData ? (
        <Tooltip
          component={tooltipComponents[view][hoverData.type]}
          data={hoverData.data}
        />
      ) : null}
    </>
  );
};

export default Wellbore3D;
