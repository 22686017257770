import fetch from "epics/fetch";
import {of} from "rxjs/observable/of";
import {show} from "actions/snackbar";
import {setOne, setSetupError, types} from "actions/wells";

export default action$ => {
  return action$
    .ofType(types.setAnalogInputConfiguration)
    .switchMap(({payload}) => {
      return fetch(
        `/optimization/well/${encodeURIComponent(
          payload.downholeLocation,
        )}/analogInput`,
        {},
        {
          method: "PUT",
          body: {
            ...payload.analogInputs,
          },
        },
      )
        .flatMap(({response: data}) => [
          setOne({
            wellId: payload.wellId,
            data: {
              analogInput: {
                ...payload.analogInputs,
              },
            },
          }),
          setSetupError({
            downholeLocation: payload.downholeLocation,
            error: "",
          }),
          show({
            message: `You've successfully updated the analog input configuration!`,
          }),
        ])
        .catch(error =>
          of(
            setSetupError({
              downholeLocation: payload.downholeLocation,
              error: error.xhr.response,
            }),
          ),
        );
    });
};
