import {setAlerts, types} from "actions/wells";
import fetch from "epics/fetch";
import ALERT_STATUS_STATES from "constants/alert-status-states";
import {default as parseAlert} from "models/alert";

export default (action$, store) => {
  return action$.ofType(types.fetchAlerts).switchMap(({payload}) => {
    const query = {
      status: `${ALERT_STATUS_STATES.UNACKNOWLEDGED},${
        ALERT_STATUS_STATES.ACKNOWLEDGED
      }`,
    };

    return fetch(
      `/alerts/well/${encodeURIComponent(payload.downholeLocation)}`,
      {...payload, ...query},
    ).map(({response: data}) => {
      const user = store.getState().auth.user || null;

      return setAlerts({
        downholeLocation: payload.downholeLocation,
        data: data.map(alert => {
          return parseAlert(alert, user);
        }),
      });
    });
  });
};
