import {types} from "./types";

export const setDeviationSortBy = sort => {
  return {
    type: types.setDeviationSortBy,
    payload: {
      sort,
    },
  };
};
