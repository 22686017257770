import React from "react";
import {Input, withStyles, InputLabel} from "@material-ui/core";

const styles = theme => ({
  outlinedInputRoot: {
    padding: 0,
    "label + &": {
      marginTop: theme.spacing(3),
    },
    width: 250,
    maxheight: 56,
  },
  outlinedInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 12px",
    transition: theme.transitions.create(["border"]),
    "&:focus": {
      border: `1px solid ${theme.palette.primary.main}`,
      caretColor: theme.palette.primary.main,
    },
  },
  outlinedInputFormLabel: {
    fontSize: 20,
  },
  outlinedInputFormLabelFocused: {
    transition: theme.transitions.create(["color"]),
  },
});

const OutlinedInput = ({showLabel, classes, label, ...otherProps}) => {
  const outlineInputProps = {
    classes: {
      root: classes.outlinedInputRoot,
      input: classes.outlinedInput,
    },
    disableUnderline: true,
  };
  const outlineLabelProps = {
    shrink: true,
    disableAnimation: true,
    className: classes.outlinedInputFormLabel,
    classes: {focused: classes.outlinedInputFormLabelFocused},
  };

  return (
    <>
      {label && <InputLabel {...outlineLabelProps}>{label}</InputLabel>}
      <Input {...outlineInputProps} {...otherProps} />
    </>
  );
};

export default withStyles(styles)(OutlinedInput);
