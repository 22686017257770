import React from "react";
import {connect} from "react-redux";
import {push} from "react-router-redux";
import {withRouter} from "react-router";

import Typography from "@material-ui/core/Typography";

import styles from "routes/profile/organization/styles";

import Breadcrumbs from "components/breadcrumbs";
import FormButton from "components/form-button";
import ListView from "components/list-view";
import PaperContainer from "components/paper-container";

import colors from "theme/colors";
import getUserName from "utils/user/get-name";
import pluralize from "@ambyint/common/utils/format/format-pluralize";

import {set as selectOrganization} from "actions/organization-view";
import withoutAdminUsers from "utils/user/without-admin-users";

import {searchOrganizationUsers} from "actions/organizations";

import Header from "./header";

const mapStateToProps = state => {
  return {
    organizations: state.organizations,
    users:
      state.organizationUsers.filtered[
        state.organizationUsers.selectedOrganizationId
      ],
  };
};

class ManageUsers extends React.Component {
  componentWillMount() {
    const {
      dispatch,
      match: {
        params: {organizationId},
      },
    } = this.props;
    dispatch(selectOrganization({currentOrganization: organizationId}));
  }

  setOrganizationUsers(search) {
    const {
      dispatch,
      match: {
        params: {organizationId},
      },
    } = this.props;
    dispatch(searchOrganizationUsers({organizationId, search}));
  }

  render() {
    const {
      dispatch,
      organizations,
      match: {
        params: {organizationId},
      },
      users,
    } = this.props;

    const selectedOrganization =
      organizations.all[organizations.selectedOrganizationId];

    const filteredUsers = withoutAdminUsers(organizationId, users || []);
    const organizationName = selectedOrganization
      ? selectedOrganization.organizationName
      : "";

    if (
      !organizations.selectedOrganizationId ||
      !organizations.all[organizations.selectedOrganizationId]
    ) {
      return <div style={styles.pageContainer} />;
    }

    const permissions = user => {
      return [
        ...(user.isInvitedUserApprover ? ["Approver"] : []),
        ...(user.accessLevel === "Full" ? ["Well Control"] : ["Limited"]),
      ].join(", ");
    };

    return (
      <div style={{position: "relative", marginBottom: 16}}>
        <div style={{marginBottom: 16}}>
          <Breadcrumbs
            crumbs={[
              {
                label: organizationName,
                pathname: "/profile/organizations",
              },
              {
                label: "Manage Users",
              },
            ]}
          />
        </div>

        <PaperContainer style={{padding: 4, marginBottom: 16}}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "baseline",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="body2"
                style={{
                  color: colors.grey[500],
                  margin: 16,
                  maxWidth: 350,
                  lineHeight: "17px",
                }}
              >
                Invite people to join your organization. You can limit their
                access to specific groups of wells, or limit their permissions
                from making well changes.
              </Typography>

              <Typography
                variant="body2"
                style={{
                  color: colors.grey[600],
                  fontWeight: "bold",
                  margin: 16,
                }}
              >{`You currently have ${users.length} user${pluralize(
                users,
              )}.`}</Typography>
            </div>

            <div style={{display: "flex", justifyContent: "flex-end"}}>
              <FormButton
                variant={"contained"}
                style={{
                  minHeight: "40px",
                  paddingLeft: 48,
                  paddingRight: 48,
                  margin: 16,
                }}
                type="submit"
                onClick={() => {
                  dispatch(
                    push(
                      `/profile/organization/${
                        organizations.selectedOrganizationId
                      }/user/invite`,
                    ),
                  );
                }}
              >
                Invite User
              </FormButton>
            </div>
          </div>
        </PaperContainer>

        <ListView
          header={
            <Header
              organizationId={organizationId}
              users={filteredUsers}
              setOrganizationUsers={search => this.setOrganizationUsers(search)}
            />
          }
          containerStyle={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "calc(100vh - 290px)",
            overflow: "auto",
          }}
          data={users}
          columns={[
            {
              label: "Name",
              render: user => getUserName(user),
              sortBy: "firstName,lastName",
              xs: 3,
            },
            {
              label: "Email",
              render: user => user.userLogin,
              sortBy: "userLogin",
              xs: 4,
              style: {
                whiteSpace: "nowrap",
              },
            },
            {
              label: "Permissions",
              render: user => permissions(user),
              sortBy: "isInvitedUserApprover,accessLevel",
              xs: 3,
            },
            {
              label: "Assignments",
              render: user => (
                <div
                  style={{
                    ...(!user.assignedWells || user.assignedWells.length === 0
                      ? {color: colors.red[500]}
                      : {}),
                  }}
                >
                  {`${
                    user.assignedWells ? user.assignedWells.length : 0
                  } Wells`}
                </div>
              ),
              sortBy: "assignedWells.length",
              xs: 2,
            },
          ]}
          onRowClick={user => {
            dispatch(
              push(
                `/profile/organization/${
                  organizations.selectedOrganizationId
                }/user/${user.clientUserId}`,
              ),
            );
          }}
        />
      </div>
    );
  }
}

const Component = withRouter(connect(mapStateToProps)(ManageUsers));

export default Component;
