import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import noop from "lodash/noop";
import PropTypes from "prop-types";
import React from "react";

const styles = {
  checkboxDefault: {
    height: "auto",
    width: "auto",
  },
  listRoot: {
    maxHeight: "200px",
    overflow: "auto",
  },
};

const Component = props => {
  const {classes, items, onSelect = noop} = props;

  return (
    <List classes={{root: classes.listRoot}}>
      {items.slice(0, 25).map(item => (
        <ListItem
          key={item.value}
          dense
          button
          onClick={() => onSelect({target: item})}
        >
          <Checkbox
            checked={item.selected}
            classes={{default: classes.checkboxDefault}}
            disableRipple
            tabIndex={-1}
          />
          <ListItemText primary={item.value} />
        </ListItem>
      ))}
    </List>
  );
};

Component.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  onSelect: PropTypes.func,
};

export default withStyles(styles)(Component);
