import React from "react";
import IconButton from "@material-ui/core/IconButton";
import OffTargetIcon from "@material-ui/icons/GpsOff";
import OnTargetIcon from "@material-ui/icons/GpsNotFixed";
import makeStyles from "@material-ui/core/styles/makeStyles";

import OverrideTooltip from "../override-tooltip";

const useStyles = makeStyles(({colors}) => ({
  icon: {
    color: "inherit",
  },
  button: ({hasSubmitted, isHoveringOnActions}) => ({
    transition: "color .2s ease-in-out",
    color: isHoveringOnActions ? colors.warmGrey[200] : colors.warmGrey[100],
    "&:hover": {
      transition: "unset",
      color: hasSubmitted ? colors.warmGrey[100] : colors.warmGrey[500],
    },
  }),
}));

const WellCardOverrideButton = ({
  onClick,
  hasSubmitted,
  override,
  isHoveringOnActions,
}) => {
  const classes = useStyles({hasSubmitted, isHoveringOnActions});

  const Icon = hasSubmitted ? OffTargetIcon : OnTargetIcon;

  return (
    <OverrideTooltip hasSubmitted={hasSubmitted} override={override}>
      <IconButton
        className={classes.button}
        onClick={onClick}
        disabled={hasSubmitted}
      >
        <Icon className={classes.icon} />
      </IconButton>
    </OverrideTooltip>
  );
};

export default WellCardOverrideButton;
