import compose from "recompose/compose";
import pure from "recompose/pure";
import React from "react";
import {scaleBand, scaleLinear} from "d3-scale";

import valueOf from "utils/value-of";
import withResize from "components/with-resize";

import Axes from "./axes";
import Bars from "./bars";

const defaultMargins = {
  top: 5,
  right: 10,
  bottom: 10,
  left: 10,
};

const xScale = (props, maxValue) => {
  return scaleLinear()
    .domain([0, maxValue])
    .range([props.margin.left, props.width - props.margin.right]);
};

const yScale = props => {
  const scale = scaleBand()
    .padding(0.5)
    .domain(props.data.map(d => d.label))
    .range([props.margin.top, props.height - props.margin.bottom]);

  return scale;
};

const calculateLeftMarginBasedOnMaxLabel = data => {
  const pixelsPerLetter = 6;
  const maxWidth = Math.max(...data.map(d => d.label && d.label.length));
  return (4 + maxWidth) * pixelsPerLetter;
};

const Chart = props => {
  const {data = [], height = 150, width = 150, style = {}} = props;

  const sortedData = data.sort((a, b) => valueOf(b) - valueOf(a));
  const margin = {
    ...defaultMargins,
    ...{
      left: calculateLeftMarginBasedOnMaxLabel(sortedData),
    },
    ...(props.margin || {}),
  };
  const maxX = props.max || Math.max(...props.data.map(d => d.value));
  const scales = {
    xScale: xScale({...props, height, width, margin, sortedData}, maxX),
    yScale: yScale({...props, height, width, margin, sortedData}),
  };

  return (
    <svg width={width} height={"100%"}>
      <Axes
        {...props}
        {...scales}
        height={height}
        margins={margin}
        maxX={maxX}
      />
      <Bars
        {...props}
        {...scales}
        data={sortedData}
        width={width}
        margins={margin}
        style={style}
      />
    </svg>
  );
};

const enhance = compose(
  pure,
  withResize,
);

export default enhance(Chart);
