import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import DialogContent from "@material-ui/core/DialogContent";

import React from "react";

import DecimalTextField from "components/forms/decimal-text-field";
import PlainText from "components/forms/plain-text";
import TextField from "components/forms/text-field";

const endAdornment = (
  <InputAdornment style={{paddingRight: 5}}>%</InputAdornment>
);

const LufkinDownholeVFDSecondary = () => {
  return (
    <DialogContent>
      <Grid container>
        <Grid item xs={12}>
          <PlainText name="description" />
        </Grid>

        <Grid item xs={12}>
          <TextField name="comment" fullWidth label="Comment" />
        </Grid>

        <Grid item xs={4}>
          <DecimalTextField
            name="acceptedData.primaryFillage"
            label="Primary Fillage"
            fullWidth
            endAdornment={endAdornment}
          />
        </Grid>

        <Grid item xs={4}>
          <DecimalTextField
            name="acceptedData.secondaryFillage"
            label="Secondary Fillage"
            fullWidth
            endAdornment={endAdornment}
          />
        </Grid>

        <Grid item xs={4}>
          <DecimalTextField
            name="acceptedData.strokes"
            label="Consecutive Pumpoff Strokes"
            fullWidth
          />
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default LufkinDownholeVFDSecondary;
