import React, {useMemo} from "react";
import {connect} from "react-redux";
import {
  ChartLabel,
  FlexibleWidthXYPlot,
  LineSeries,
  MarkSeries,
  YAxis,
} from "react-vis";
import isNil from "lodash/isNil";

import {blue, greenVivid, warmGrey} from "theme/updated-colors";
import getUnits from "utils/get-units";
import {setFocus} from "actions/wells/well-health";

import DateHoverAxis from "../date-hover-axis";
import {RoundedVerticalBarSeries} from "../rounded-bar-series";
import SetPointAxis from "../set-point-axis";
import {useDomain} from "../../use-domain";

const mapStateToProps = ({wellHealth}) => ({
  ...wellHealth.production,
});

const mapDispatchToProps = {
  setFocus,
};

const StrokesVsProductionGraph = ({
  liquidProduction,
  strokes,
  focus,
  setFocus,
}) => {
  const strokePoints = strokes.filter(p => p.y !== null).length;

  const liquidProductionDomain = useDomain(liquidProduction, 2, true);
  const strokesDomain = useDomain(strokes, 5, true);

  const coloredLiquidProduction = useMemo(
    () =>
      liquidProduction.map((p, i) => ({
        ...p,
        color: i === focus,
      })),
    [focus, liquidProduction],
  );

  return (
    <div>
      <FlexibleWidthXYPlot
        height={150}
        margin={{left: 60, right: 10, top: 24, bottom: 10}}
        yDomain={strokesDomain}
        xType="ordinal"
        onMouseLeave={() => {
          setFocus("production", null);
        }}
      >
        <DateHoverAxis focus={focus} />

        <YAxis
          tickTotal={4}
          tickSizeInner={0}
          tickFormat={(value, index, scale, tickTotal) =>
            `${scale.tickFormat(tickTotal, ",.1~s")(value)}`
          }
        />

        {/*
          If there is only one point in the series, the line series draws nothing.
          This can happen if the well just started reporting strokes this week.
          In this case, using a mark series will show a dot for the single data point.
          Otherwise, it will show the line series when there are at least two points.
          */}
        {strokePoints > 1 ? (
          <LineSeries
            data={strokes}
            color={greenVivid[500]}
            onNearestX={(value, event) => {
              setFocus("production", event.index);
            }}
            getNull={d => d.y !== null}
          />
        ) : (
          <MarkSeries
            data={strokes}
            color={greenVivid[500]}
            size={2}
            onNearestX={(value, event) => {
              setFocus("production", event.index);
            }}
            getNull={d => d.y !== null}
          />
        )}

        {!isNil(focus) ? (
          <LineSeries
            data={[
              {
                x: strokes[focus].x,
                y: strokesDomain[0],
              },
              {
                x: strokes[focus].x,
                y: strokesDomain[1],
              },
            ]}
            color={warmGrey[200]}
            strokeStyle="dashed"
            strokeWidth={1}
          />
        ) : null}

        <ChartLabel
          text="Strokes"
          xPercent={0}
          yPercent={0.75}
          includeMargin={false}
          style={{
            transform: "rotate(-90) translate(0,-50)",
            textAnchor: "middle",
          }}
        />
      </FlexibleWidthXYPlot>

      <FlexibleWidthXYPlot
        height={120}
        margin={{left: 60, right: 10, top: 10, bottom: 40}}
        yDomain={liquidProductionDomain}
        xType="ordinal"
        onMouseLeave={() => {
          setFocus("production", null);
        }}
      >
        <RoundedVerticalBarSeries
          data={coloredLiquidProduction}
          colorRange={[blue[500], blue[200]]}
          onNearestX={(value, event) => {
            setFocus("production", event.index);
          }}
          getNull={d => d.y !== null}
        />

        <SetPointAxis />

        <YAxis tickValues={liquidProductionDomain} tickSizeInner={0} />

        <ChartLabel
          text={getUnits().production}
          xPercent={0}
          yPercent={0.65}
          includeMargin={false}
          style={{
            transform: "rotate(-90) translate(0,-50)",
            textAnchor: "middle",
          }}
        />
      </FlexibleWidthXYPlot>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StrokesVsProductionGraph);
