import React, {useEffect} from "react";
import compose from "lodash/fp/compose";
import {connect} from "react-redux";
import isNil from "lodash/isNil";
import Paper from "@material-ui/core/Paper/Paper";
import {SizeMe} from "react-sizeme";
import Typography from "@material-ui/core/Typography/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

import {fetchRankings} from "actions/wells/well-health";

import Progress from "../progress";
import WellboreQualityGraph from "../wellbore-quality-graph";
import WellboreQualityNoData from "../wellbore-quality-no-data";

const styles = ({typography, spacing, colors}) => ({
  panel: {
    padding: spacing(3, 4),
    height: 204,
    boxSizing: "border-box",
    position: "relative",
  },

  grid: {
    display: "grid",
    gridTemplateColumns: "minmax(0,1fr)",
    gridTemplateRows: "auto 100px",
    gridTemplateAreas: '"title" "graph"',
    height: "100%",
    alignItems: "center",
  },

  title: {
    gridArea: "title",
    alignSelf: "start",
  },

  subtitle: {
    ...typography.subtitle1,
    fontSize: typography.subtitle2.fontSize,
    color: colors.warmGrey[500],
  },
});

const mapStateToProps = ({wells, wellHealth}) => {
  return {
    ...wellHealth.quality,
    activeDownholeLocation: wells.activeDownholeLocation,
    wells,
  };
};

const mapDispatchToProps = {
  fetchRankings,
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
);

const WellboreQualityPanel = ({
  fetchRankings,
  fetched,
  rankings,
  activeDownholeLocation,
  wells,
  classes,
}) => {
  useEffect(
    () => {
      if (!fetched) {
        fetchRankings(Object.keys(wells.byDownholeLocation));
      }
    },
    [fetched, fetchRankings, wells.byDownholeLocation],
  );

  const ranking = rankings[activeDownholeLocation];

  const noData =
    ranking &&
    (isNil(ranking.minPercent) ||
      isNil(ranking.maxPercent) ||
      isNil(ranking.meanPercent));

  return (
    <Paper className={classes.panel}>
      <div className={classes.grid}>
        <div className={classes.title}>
          <Typography variant="h6">Wellbore Quality</Typography>
          {noData ? null : (
            <div className={classes.subtitle}>
              by increased risk of tubing failure
            </div>
          )}
        </div>

        {noData ? (
          <WellboreQualityNoData />
        ) : fetched ? (
          <SizeMe>
            {({size}) => <WellboreQualityGraph width={size.width} />}
          </SizeMe>
        ) : (
          <Progress />
        )}
      </div>
    </Paper>
  );
};

export default enhance(WellboreQualityPanel);
