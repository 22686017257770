import {types} from "./types";

export const setHistory = (wellId, data) => {
  return {
    type: types.setHistory,
    payload: {
      wellId,
      data,
    },
  };
};
