import React from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import {FormatDateTime} from "components/format/date";

export default props => {
  return (
    <div style={props.style}>
      <Grid container justify={"space-between"}>
        <Grid item xs>
          <Typography variant="caption">{props.title}</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="caption" style={{textAlign: "right"}}>
            <FormatDateTime date={props.timestamp} />
          </Typography>
        </Grid>
      </Grid>
      <Grid container justify={"space-between"}>
        <Grid item xs>
          <Typography variant="body1">{props.message}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};
