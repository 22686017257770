import filter from "./filter-alerts";
import sort from "./sort-alerts";

export default state => {
  const {all, selected, sortAsc, sortBy, activeFilter} = state;

  return {
    ...state,
    filtered: sort(filter(all, activeFilter), sortBy, sortAsc),
    selected,
    staleFilters: false,
  };
};
