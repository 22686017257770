import pick from "lodash/pick";
import {of} from "rxjs/observable/of";

import {
  parseWellboreSurveyFromString,
  stringifyWellboreSurvey,
} from "@ambyint/common/parsers/well/parse-wellbore-survey";

import fetch from "epics/fetch";
import getUnits from "utils/get-units";
import omitDeepUndefined from "utils/omit-deep-undefined";
import valueOf from "utils/value-of";
import {depth} from "unit-converter";
import {setOne, setSetupError, types} from "actions/wells";
import {show} from "actions/snackbar";

const convertDepth = (value, from) => {
  return depth.convert({from, to: "m", value: valueOf(value)}, from);
};

const parseWellboreSurvey = wellboreSurvey => {
  const units = getUnits();

  return parseWellboreSurveyFromString(wellboreSurvey, value =>
    convertDepth(value, units.depth),
  );
};

function parseSave(data, downholeLocation) {
  const units = getUnits();

  const wellboreSurvey = parseWellboreSurvey(data.wellboreSurvey);

  const parsed = {
    casing: data.casing,
    casingPerforationBottomMeasuredDepth: convertDepth(
      data.casingPerforationBottomMeasuredDepth,
      units.depth,
    ),
    casingPerforationTopMeasuredDepth: convertDepth(
      data.casingPerforationTopMeasuredDepth,
      units.depth,
    ),
    downholeLocation,
    trueVerticalDepth: convertDepth(data.trueVerticalDepth, units.depth),
    wellDepth: parseFloat(convertDepth(data.wellDepth, units.depth)),
    wellboreSurveyData: stringifyWellboreSurvey(wellboreSurvey),
  };

  // For whatever reason, the old endpoint has an inner wellbore property that
  // is mostly duplicate of the root data.
  // But it is an old endpoint I don't want to mess up.
  parsed.wellbore = pick(parsed, [
    "casing",
    "casingPerforationBottomMeasuredDepth",
    "casingPerforationTopMeasuredDepth",
    "trueVerticalDepth",
    "wellDepth",
    "wellboreSurveyData",
  ]);

  return omitDeepUndefined(parsed);
}

export default action$ =>
  action$.ofType(types.saveWellboreInformation).switchMap(({payload}) => {
    return fetch(
      `/optimization/well/${encodeURIComponent(
        payload.downholeLocation,
      )}/wellbore`,
      {},
      {
        body: parseSave(payload.data),
        method: "PUT",
      },
    )
      .flatMap(() => [
        setOne({
          wellId: payload.wellId,
          data: {
            wellbore: {
              ...payload.data,
              wellboreSurvey: parseWellboreSurvey(payload.data.wellboreSurvey),
            },
          },
        }),
        setSetupError({
          downholeLocation: payload.downholeLocation,
          error: "",
        }),
        show({
          message: "You have successfully updated the Wellbore Information",
        }),
      ])
      .catch(error =>
        of(
          setSetupError({
            downholeLocation: payload.downholeLocation,
            error: error.xhr.response,
          }),
        ),
      );
  });
