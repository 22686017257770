import isNil from "lodash/isNil";

const isOwaspCompliantUrl = value => {
  return value.match(
    // https://www.owasp.org/index.php/OWASP_Validation_Regex_Repository
    /^((((https?|ftps?|gopher|telnet|nntp):\/\/)|(mailto:|news:))(%[0-9A-Fa-f]{2}|[-()_.!~*';/?:@&=+$,A-Za-z0-9])+)([).!';/?:,][[:blank:]])?$/,
  );
};

export default value => {
  const validationError = "Invalid uri";

  if (isNil(value) || value === "") {
    return undefined;
  }

  if (value.constructor !== String) {
    return validationError;
  }

  if (!isOwaspCompliantUrl(value)) {
    return validationError;
  }

  if (!value.includes("http")) {
    // Require http or https
    return validationError;
  }

  if (
    value.includes("//10.") ||
    value.includes("//127.") ||
    value.includes("//169.") ||
    value.includes("//172.") ||
    value.includes("//192.") ||
    value.includes("localhost")
  ) {
    // No! Stop it! Leave us alone!
    return validationError;
  }

  if (!value.includes(".")) {
    // Expect some type of domain extension. Don't allow e.g. "http://localhost"
    return validationError;
  }

  return undefined;
};
