import {types} from "./types";

export const setRecommendationType = ({
  recommendationType,
  downholeLocation,
}) => {
  return {
    type: types.setRecommendationType,
    payload: {downholeLocation, recommendationType},
  };
};
