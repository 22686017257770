import moment from "moment";
import get from "lodash/fp/get";

const inDayRange = (daysA, daysB, pathToDateStr) => ([
  wellId,
  dailySummaries,
]) => {
  const summariesInRange = dailySummaries.filter(item => {
    const dateA = moment()
      .utc()
      .subtract(daysA, "days")
      .startOf("day");
    const dateB = moment()
      .utc()
      .subtract(daysB, "days")
      .endOf("day");
    const dateToTest = moment(get(pathToDateStr, item)).utc();
    return dateToTest.isBetween(dateA, dateB, null, "[]");
  });

  return [wellId, summariesInRange];
};

export default inDayRange;
