export const setHistory = ({state, action}) => {
  const {wellId, data} = action.payload;

  return {
    ...state.wellSurveillance,
    activeWellId: wellId,
    data,
    isFetching: false,
  };
};
