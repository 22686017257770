import {get, uniqBy, sortBy} from "lodash";
import store from "store";

export const findWellTags = wellIds => {
  const byWellId = store.getState().wells.byWellId;

  return sortBy(
    uniqBy(wellIds.flatMap(id => get(byWellId, [id, "tags"], [])), "name"),
    "name",
  );
};
