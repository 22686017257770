import get from "lodash/get";
import uniqBy from "lodash/uniqBy";

const cleanTag = ({name}) => ({name});

export const updateWellTags = ({state, action}) => {
  const {byWellId, byDownholeLocation} = state;
  const {
    payload: {downholeLocations, tags},
  } = action;

  const byWellIdCopy = {...byWellId};

  downholeLocations.additions.forEach(dh => {
    const wellId = byDownholeLocation[dh];
    const originalTags = get(byWellIdCopy, [wellId, "tags"], []);
    byWellIdCopy[wellId].tags = uniqBy(
      [...originalTags, ...tags.additions.map(cleanTag)],
      "name",
    );
  });
  downholeLocations.subtractions.forEach(dh => {
    const wellId = byDownholeLocation[dh];
    const withTagAdditions = get(byWellIdCopy, [wellId, "tags"], []);
    byWellIdCopy[wellId].tags = withTagAdditions.filter(
      tag => !tags.subtractions.find(({name}) => name === tag.name),
    );
  });

  return {
    ...state,
    byWellId: byWellIdCopy,
  };
};
