import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = () => ({
  bullet: {
    borderRadius: "50%",
    display: "inline-block",
    height: 10,
    marginRight: 5,
    width: 10,
  },
  item: {
    borderRadius: 4,
    cursor: "pointer",
    display: "inline-block",
    fontSize: 12,
    marginRight: 15,
    padding: 5,
    transition: "0.2s ease all",
    "&:hover": {
      background: "#eee",
    },
  },
});

const Legend = ({active = {}, classes, items = [], onClick}) => {
  return (
    <div>
      {items.map(item => (
        <div
          key={item.key}
          className={classes.item}
          onClick={() => onClick(item.key)}
          style={{
            ...(!active[item.key] && {opacity: 0.3}),
          }}
        >
          <span
            className={classes.bullet}
            style={{backgroundColor: item.color}}
          />
          <span>{item.value}</span>
        </div>
      ))}
    </div>
  );
};

export default withStyles(styles)(Legend);
