import {intersection} from "lodash";

import {createSwitchEpic} from "epics/create-epic";
import {types} from "actions/wells";
import {clearSelectedWells, toggleWellSelected} from "actions/tagging";
import {workflows} from "constants/well-workflows";

const WORKFLOW_MAP = {
  [workflows.ANOMALY]: workflow => workflow.anomaly.wellList,
  [workflows.DISPLAY_ALL]: workflow =>
    workflow.displayAll.filtered || workflow.displayAll.all,
  [workflows.CALIBRATE]: workflow =>
    workflow.calibrate.filtered || workflow.calibrate.all,
  [workflows.TRIAGE]: workflow =>
    workflow.triage.filtered || workflow.triage.all,
};

export const filterSelectedWells = createSwitchEpic(
  [types.setDisplayAllWells, types.setCalibrateWells, types.setTriageWells],
  async (action, store) => {
    const {
      tags: {selectedWells},
      wells: {workflow},
    } = store.getState();
    const workflowSelected = workflow.selected;
    const selectedWellsInView = Object.keys(selectedWells).filter(
      key => selectedWells[key].selected,
    );
    const filteredWellsInNewView = WORKFLOW_MAP[workflowSelected](workflow);
    const selectedWellsInNewView = intersection(
      filteredWellsInNewView,
      selectedWellsInView,
    );

    return [clearSelectedWells(), toggleWellSelected(selectedWellsInNewView)];
  },
);
