import {show} from "actions/snackbar";
import {types} from "actions/wells";
import fetch from "epics/fetch";

export default action$ => {
  return action$.ofType(types.setSpeed).switchMap(({payload}) => {
    return fetch(
      `/wells/${encodeURIComponent(payload.downholeLocation)}/speed`,
      {},
      {
        method: "PUT",
        body: {
          downholeLocation: payload.downholeLocation,
          speed: parseFloat(payload.speed),
        },
      },
    ).map(() => {
      return show({
        message: `Your request to set the speed for ${
          payload.well.displayName
        } was submitted successfully!`,
      });
    });
  });
};
