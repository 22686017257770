import unitsOf from "utils/units-of";

const imperialUnits = [
  "Mcf/d",
  "bbl/d",
  "lb/ft3",
  "ft",
  "ft",
  "ftKB",
  "in",
  "lbf",
  "in",
  "psi",
  "mph",
  "ft-lb",
  "ftTVD",
  "cuft",
];

export default value => {
  const units = unitsOf(value);

  if (imperialUnits.indexOf(units) >= 0) {
    return "imperial";
  }

  return "metric";
};
