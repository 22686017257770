import React from "react";

import Grid from "@material-ui/core/Grid";
import {withStyles} from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";

import styles from "routes/profile/organization/styles";

const styleSheet = theme => ({
  bold: {fontWeight: "bold"},
  header: styles.header,
  search: {
    marginBottom: theme.spacing(2),
  },
});

const Header = ({classes, onChange, placeholder, countRows}) => {
  return (
    <Grid
      alignItems="center"
      className={classes.header}
      container
      justify="space-around"
      style={{flexBasis: "88px", flexShrink: "0"}}
      spacing={0}
    >
      <Grid item xs={6}>
        <div className={classes.bold}>Displaying {countRows} Wells</div>
      </Grid>
      <Grid item xs={6}>
        <TextField
          className={classes.search}
          label={placeholder}
          fullWidth
          onChange={onChange}
          onKeyPress={event => {
            if (event.key === "Enter") {
              // Don't submit the form
              event.preventDefault();
            }
          }}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styleSheet, {name: "HeaderOverrides"})(Header);
