export default [
  {
    value: 1,
    text: "Side A - Lead",
  },
  {
    value: -1,
    text: "Side A - Lag",
  },
  {
    value: 2,
    text: "Side B - Lead",
  },
  {
    value: -2,
    text: "Side B - Lag",
  },
];
