const setCalculatedDeviations = ({state, action}) => {
  const {
    wells,
    dailySummaries,
    fetched,
    deviationFilter,
    timePeriod,
  } = action.payload;

  return {
    ...state,
    wells,
    dailySummaries,
    fetched,
    deviationFilter,
    timePeriod,
  };
};

export default setCalculatedDeviations;
