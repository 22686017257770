import {types} from "actions/organizations";

import resetOnSignout from "utils/reducers/reset-on-signout";

import selectOrganization from "./select-organization";
import setAccess from "./set-access";
import setOrganization from "./set-organization";
import setOrganizationUsers from "./set-organization-users";
import setUserRemovedFromOrganization from "./set-user-removed-from-organization";
import sortUsers from "./sort-users";

const defaults = {
  filtered: {},
  sortUsers: {
    sortBy: "lastName",
    sortDirection: "asc",
  },
};

const actionMap = {
  [types.selectOrganization]: selectOrganization,
  [types.setOrganization]: setOrganization,
  [types.setAccess]: setAccess,
  [types.setOrganizationUsers]: setOrganizationUsers,
  [types.setUserRemovedFromOrganization]: setUserRemovedFromOrganization,
  [types.sortUsers]: sortUsers,
};

export default resetOnSignout((state = defaults, action) => {
  const doAction = actionMap[action.type];
  return doAction ? doAction({state, action}) : state;
});
