import React from "react";
import hasWellPilot from "@ambyint/common/utils/devices/has-well-pilot";
import hasDriveType from "@ambyint/common/utils/devices/has-drive-type";
import {round} from "@ambyint/common/utils/round";

import Grid from "@material-ui/core/Grid";

import FormButton from "components/form-button";
import FormHeader from "components/form-header";
import PaperContainer from "components/paper-container";

import getPrivileges from "utils/get-privileges";
import noop from "utils/noop";
import privilegeTypes from "utils/privilege-types";
import PUMPOFF_SETTING_MODES from "constants/pumpoff-setting-modes";

import {cardLabelFactory, cardValueFactory} from "../cards";

class View extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {well, onEdit = noop} = this.props;

    const pumpoff = well.pumpOffSetting;

    const privileges = getPrivileges(well.organization.id);

    const CardLabel = cardLabelFactory(6);
    const CardValue = cardValueFactory(6);

    if (!pumpoff) {
      return (
        <PaperContainer extraPadded style={{maxWidth: "500px"}}>
          <Grid container style={{maxWidth: "500px"}}>
            <Grid item xs={12} style={{marginTop: "0px", marginBottom: "0px"}}>
              <FormHeader>Pumpoff Settings</FormHeader>
            </Grid>

            <Grid item xs={12} style={{marginTop: "0px", marginBottom: "0px"}}>
              <CardLabel label="Pumpoff Settings have not been entered." />
            </Grid>
            <Grid item xs={12} style={{marginTop: "0px", marginBottom: "0px"}}>
              {privileges.includes(privilegeTypes.well.configure) && (
                <FormButton onClick={() => onEdit()}>
                  Add Pumpoff Settings
                </FormButton>
              )}
            </Grid>
          </Grid>
        </PaperContainer>
      );
    }

    const renderLoad = () => {
      if (
        !pumpoff.load ||
        (hasWellPilot(well.devices) &&
          pumpoff.mode.value !== PUMPOFF_SETTING_MODES.STANDARD_SURFACE)
      ) {
        return null;
      }

      return [
        <CardLabel label="Load" />,
        <CardValue
          value={`${round(pumpoff.load.value)} ${pumpoff.load.units}`}
        />,
      ];
    };

    return (
      <PaperContainer
        label="Pumpoff Settings"
        extraPadded
        style={{maxWidth: "500px"}}
      >
        <Grid container style={{maxWidth: "500px"}}>
          <Grid item xs={12}>
            <FormHeader>Pumpoff Settings</FormHeader>
          </Grid>

          {!hasDriveType(well.devices, "WELLPILOT_EPIC") && [
            <CardLabel label="Mode" />,
            <CardValue value={pumpoff.mode.label} />,
          ]}

          {renderLoad()}

          {pumpoff.position && [
            <CardLabel
              label={
                hasWellPilot(well.devices) ? "Primary Fillage" : "Position"
              }
            />,
            <CardValue
              value={`${round(pumpoff.position.value)} ${
                pumpoff.position.units
              }`}
            />,
          ]}

          {isFinite(pumpoff.strokes) && [
            <CardLabel label={"Consecutive Pumpoff Strokes"} />,
            <CardValue value={pumpoff.strokes} />,
          ]}

          {pumpoff.primaryFillage && [
            <CardLabel
              label={
                hasWellPilot(well.devices)
                  ? "Secondary Pump Fillage"
                  : "Primary Fillage"
              }
            />,
            <CardValue
              value={`${pumpoff.primaryFillage.value} ${
                pumpoff.primaryFillage.units
              }`}
            />,
          ]}

          {pumpoff.secondaryFillageEnabled &&
            pumpoff.secondaryFillage && [
              <CardLabel label={"Secondary Fillage"} />,
              <CardValue
                value={`${pumpoff.secondaryFillage.value} ${
                  pumpoff.secondaryFillage.units
                }`}
              />,
            ]}

          <Grid item xs={12} style={{marginTop: "0px", marginBottom: "0px"}}>
            {privileges.includes(privilegeTypes.well.configure) && (
              <FormButton onClick={() => onEdit()} style={{marginTop: "8px"}}>
                Edit Pumpoff Settings
              </FormButton>
            )}
          </Grid>
        </Grid>
      </PaperContainer>
    );
  }
}

export default View;
