import {useEffect, useState} from "react";
import pick from "lodash/pick";
import reduce from "lodash/reduce";

import {isSpeedRangeEnabled} from "utils/setpoint-automation/is-speed-range-enabled";
import {isOutOfBounds} from "utils/setpoint-automation/is-out-of-bounds";

export const useGroupedWells = wells => {
  const [grouped, setGrouped] = useState({
    automatedWells: [],
    manualWells: [],
    atBoundary: [],
  });

  useEffect(
    () => {
      const {atBoundary, automatedWells, manualWells} = reduce(
        wells,
        ({atBoundary, automatedWells, manualWells}, well) => {
          const cleaned = pick(well, [
            "displayName",
            "wellId",
            "recommendationTypes",
            "downholeLocation",
            "safeOperatingParameters",
            "operation",
          ]);

          cleaned.atBoundaryCondition = isOutOfBounds(well);

          isSpeedRangeEnabled(well)
            ? automatedWells.push(cleaned)
            : manualWells.push(cleaned);
          if (cleaned.atBoundaryCondition) atBoundary = atBoundary + 1;

          return {atBoundary, automatedWells, manualWells};
        },
        {atBoundary: 0, automatedWells: [], manualWells: []},
      );

      setGrouped({atBoundary, automatedWells, manualWells});
    },
    [wells],
  );

  return grouped;
};
