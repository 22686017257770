import filterAndSort from "./filter-and-sort";

export default ({state, action}) => {
  const {organizationId, performedBy} = action.payload;

  const organization = {
    ...(state[organizationId] || {}),
    performedBy,
  };

  return {
    ...state,
    [organizationId]: {
      ...organization,
      filteredEvents: filterAndSort(organization),
    },
  };
};
