import React from "react";
import colors from "theme/colors";

const height = 40;
const styles = {
  container: {
    position: "relative",
    display: "flex",
    width: "100%",
    height,
    justifyContent: "center",
  },
  divider: {
    position: "absolute",
    display: "block",
    height: 3,
    width: "100%",
    backgroundColor: colors.grey[300],
    top: "50%",
    bottom: "50%",
    left: 0,
    margin: "auto",
  },
  bubble: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: colors.grey[300],
    borderRadius: "100px",
    width: height,
    height,
    textAlign: "center",
    verticalAlign: "middle",
    zIndex: 2,
  },
  bubbleText: {
    color: colors.grey[700],
    textTransform: "uppercase",
    fontSize: "0.8em",
  },
};

const Divider = props => {
  return (
    <div style={styles.container}>
      <div style={styles.bubble}>
        <div style={styles.bubbleText}>{props.text}</div>
      </div>
      <div style={styles.divider} />
    </div>
  );
};

export default Divider;
