import React from "react";
import Grid from "@material-ui/core/Grid";

import PaperContainer from "components/paper-container";

import HeaderItem from "./header-item";

const headerStyle = {
  color: "rgba(0, 0, 0, 0.54)",
  fontSize: "12px",
  height: "64px",
  padding: "0px 20px",
};

export default () => (
  <PaperContainer>
    <Grid
      alignItems="center"
      container
      justify="space-around"
      spacing={0}
      style={headerStyle}
    >
      <Grid item xs={3}>
        <HeaderItem sortBy={"displayName"} label={"WELL"} />
      </Grid>
      <Grid item xs={3}>
        <HeaderItem sortBy={"status"} label={"STATUS"} />
      </Grid>
      <Grid item xs={3}>
        <HeaderItem sortBy={"type"} label={"TYPE"} />
      </Grid>
      <Grid item xs={3}>
        <HeaderItem sortBy={"createdOn"} label={"CREATED ON"} />
      </Grid>
    </Grid>
  </PaperContainer>
);
