import React from "react";

import colors from "theme/colors";

export default props => {
  const {
    margins,
    data,
    height,
    xScale,
    barColorGroup = colors.orange,
    yScale,
  } = props;

  const bars = data.map(d => (
    <rect
      key={d.label}
      x={xScale(d.label)}
      y={yScale(d.value)}
      height={height - margins.bottom - yScale(d.value)}
      width={xScale.bandwidth()}
      style={{
        fill: barColorGroup[500],
      }}
    />
  ));

  const backgroundBars = data.map(d => (
    <rect
      key={`background-${d.label}`}
      x={xScale(d.label)}
      y={margins.top + 1}
      height={height - margins.top - margins.bottom}
      width={xScale.bandwidth()}
      style={{
        fill: barColorGroup[100],
      }}
    />
  ));

  return (
    <g>
      {backgroundBars}
      {bars}
    </g>
  );
};
