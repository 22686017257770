import math from "mathjs";
import spreadIf from "utils/spread-if";
import valueOf from "utils/value-of";
import withUnits from "utils/with-units";
import {density} from "unit-converter";

const convertDensity = ({
  value,
  units,
  fromUnitsOfMeasure,
  toUnitsOfMeasure,
  decimals = 2,
}) => {
  if (valueOf(value) === null || valueOf(value) === undefined) {
    return withUnits(null, typeof units === "object" ? units.density : units);
  }

  const converted = withUnits(
    math.round(
      density.convert({
        ...spreadIf(fromUnitsOfMeasure, {from: fromUnitsOfMeasure}),
        ...spreadIf(toUnitsOfMeasure, {to: toUnitsOfMeasure}),
        value,
      }),
      decimals,
    ) || 0,
    units.density,
  );

  return converted;
};

export default convertDensity;
