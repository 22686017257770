import React from "react";
import {connect} from "react-redux";
import noop from "lodash/noop";
import compose from "lodash/fp/compose";
import {NavLink, withRouter} from "react-router-dom";
import HomeIcon from "mdi-material-ui/Home";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SettingsIcon from "@material-ui/icons/SettingsRounded";
import Toolbox from "mdi-material-ui/Toolbox";
import withStyles from "@material-ui/core/styles/withStyles";

import {AmbyintLogo, MenuButton} from "components/app-bar/index";
import {ClickEvent, sendAnalyticsEvent} from "components/analytics";
import {eventCategory, eventAction} from "constants/analytics";
import isAmbyintUser from "utils/user/is-ambyint-user";
import Spacer from "components/spacer";
import world from "images/undraw_world_9iqb.svg";

import RecommendationsIcon from "../recommendations-icon";
import SetpointAutomationIcon from "../setpoint-automation-icon";
import SubMenu from "./sub-menu";

const styles = theme => ({
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px 0 20px",
    ...theme.mixins.toolbar,
  },
  drawerFooter: {
    margin: "auto auto 24px 24px",
  },
  drawer: {
    display: "flex",
    width: 320,
    overflowX: "hidden",
  },
  breadCrumbs: {
    color: theme.palette.grey[400],
    "& :first-child": {
      color: theme.palette.text.primary,
      cursor: "pointer",
    },
  },
  titleCard: {
    minHeight: 240,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 24,
  },
  label: {
    fontWeight: 600,
    color: "#fff",
    backgroundColor: "#4b586b",
    padding: "8px 16px",
  },
  fluid: {
    maxWidth: "100%",
    height: "auto",
  },
  title: {
    paddingLeft: 24,
  },
  navLink: {
    color: theme.colors.warmGrey[900],
  },
  slide: {
    "& a": {
      textDecoration: "none",
    },
  },
});

const MenuListItem = ({children, label, handleClose, disabled = false}) => (
  <ListItem onClick={handleClose} button disabled={disabled}>
    <ListItemIcon>{children}</ListItemIcon>
    <ListItemText primary={label} />
  </ListItem>
);

// Routes for the sidebar navigation system
const ROUTES = {
  settingsGeneral: "/profile/general",
  recommendations: "/recommendations",
  setpointAutomation: "/setpoint-automation",
};

const mapStateToProps = ({auth: {user}}) => ({
  user,
});

const enhance = compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps),
);

const SideBar = ({classes, handleOpen, handleClose = noop, open, user}) => {
  return (
    <>
      <ClickEvent category={eventCategory.sidebar} action={eventAction.toggle}>
        <MenuButton className={classes.button} onClick={handleOpen} />
      </ClickEvent>
      <Drawer
        classes={{paper: classes.drawer}}
        onBackdropClick={handleClose}
        onEscapeKeyDown={handleClose}
        open={open}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>

        <Divider />

        <div>
          <SubMenu className={classes.slide} direction="right" in>
            <div>
              <div className={classes.titleCard}>
                <img className={classes.fluid} src={world} alt="Ambyint" />
              </div>

              <Divider />

              <List component="nav">
                <NavLink
                  className={classes.navLink}
                  to="/"
                  onClick={() =>
                    sendAnalyticsEvent(
                      eventCategory.sidebar,
                      eventAction.click,
                      "Home",
                    )
                  }
                >
                  <MenuListItem label="Home" handleClose={handleClose}>
                    <HomeIcon />
                  </MenuListItem>
                </NavLink>
              </List>

              <Spacer />

              <List component="nav">
                <NavLink
                  className={classes.navLink}
                  to={ROUTES.recommendations}
                  onClick={() =>
                    sendAnalyticsEvent(
                      eventCategory.sidebar,
                      eventAction.click,
                      "Recommendations",
                    )
                  }
                >
                  <MenuListItem
                    label="Recommendations"
                    handleClose={handleClose}
                  >
                    <RecommendationsIcon />
                  </MenuListItem>
                </NavLink>

                <ClickEvent
                  category={eventCategory.sidebar}
                  action={eventAction.navigate}
                  label={ROUTES.setpointAutomation}
                >
                  <NavLink
                    className={classes.navLink}
                    to={ROUTES.setpointAutomation}
                  >
                    <MenuListItem
                      label="Setpoint Automation"
                      handleClose={handleClose}
                    >
                      <SetpointAutomationIcon />
                    </MenuListItem>
                  </NavLink>
                </ClickEvent>
              </List>

              <Spacer />

              <List component="nav">
                <NavLink
                  className={classes.navLink}
                  to={ROUTES.settingsGeneral}
                  onClick={() =>
                    sendAnalyticsEvent(
                      eventCategory.sidebar,
                      eventAction.click,
                      "Settings",
                    )
                  }
                >
                  <MenuListItem label="Settings" handleClose={handleClose}>
                    <SettingsIcon />
                  </MenuListItem>
                </NavLink>
                {isAmbyintUser(user) && (
                  <a
                    className={classes.navLink}
                    href="/admin/#/"
                    onClick={() =>
                      sendAnalyticsEvent(
                        eventCategory.sidebar,
                        eventAction.click,
                        "Admin",
                      )
                    }
                  >
                    <MenuListItem label="Admin" handleClose={handleClose}>
                      <Toolbox />
                    </MenuListItem>
                  </a>
                )}
              </List>
            </div>
          </SubMenu>
        </div>
        <div className={classes.drawerFooter}>
          <AmbyintLogo variant="dark" />
        </div>
      </Drawer>
    </>
  );
};

export default enhance(SideBar);
