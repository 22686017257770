import React from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import filter from "lodash/filter";
import moment from "moment";
import sortBy from "lodash/sortBy";

import TimeValueChart from "components/d3-time-value-chart";
import colors from "theme/colors";

const containerStyle = {
  minWidth: "150px",
};

const titleStyle = {
  color: colors.grey[600],
  fontSize: "12px",
  marginBottom: "5px",
  textTransform: "uppercase",
};

const parseSpmData = well => {
  const speedValues = filter(
    [...(well.eventsByType.rrpm || []), ...(well.eventsByType.spm || [])],
    event =>
      moment(event.createdOn).isSameOrAfter(moment().subtract(7, "days")),
  ).map(item => {
    return {
      x: item.createdOn,
      y: item.value,
    };
  });

  return sortBy(speedValues, value => moment(value.x).toDate());
};

export default props => {
  const {well} = props;

  if (!well.eventsByType) return null;

  const spmData = parseSpmData(well);

  if (spmData.length === 0) return null;

  return (
    <Grid container alignItems="center" style={containerStyle}>
      <Grid item xs>
        <Typography variant="caption" style={titleStyle}>
          SPM
        </Typography>
        <TimeValueChart
          data={spmData}
          style={{stroke: colors.spm[500]}}
          margin={{top: 10, right: 5}}
        />
      </Grid>
    </Grid>
  );
};
