import React from "react";
import clsx from "clsx";
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import TextField from "@material-ui/core/TextField/TextField";
import withStyles from "@material-ui/core/styles/withStyles";

import {maybeString} from "utils/maybe-string";

import {DISMISS_VALUES} from "./dismiss-values";

const styles = ({spacing, colors}) => ({
  body: {
    position: "relative",
    backgroundColor: colors.warmGrey[50],
    display: "flex",
    flexDirection: "column",
    padding: [[spacing(3), spacing(4)]],
  },
  textField: {
    marginLeft: spacing(4),
    marginBottom: spacing(2),
    "& > div": {
      backgroundColor: "white",
      transition: "background-color .5s",
    },
  },
  disabled: {
    "& > div": {
      backgroundColor: "transparent",
    },
  },
  label: {
    color: colors.warmGrey[500],
    right: 10,
    bottom: 10,
    borderRadius: "20%",
    backgroundColor: "white",
    padding: [[0, 4]],
    fontSize: 12,
    position: "absolute",
  },
  labelMax: {
    color: colors.red[300],
  },
  labelDisabled: {
    backgroundColor: colors.warmGrey[50],
    color: colors.warmGrey[200],
  },
});

const Body = props => {
  const {
    classes,
    setReason,
    reason,
    setCustomReason,
    customReason,
    chosenOther,
  } = props;

  const handleInput = ({target: {value}}) => {
    if (value.length > 128 || typeof value !== "string") return;
    setCustomReason(value);
  };

  return (
    <div className={classes.body}>
      <div>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="Reason"
            name="reason1"
            value={reason}
            onChange={({target: {value}}) => setReason(value)}
          >
            <FormControlLabel
              control={<Radio checked={reason === DISMISS_VALUES.DISAGREE} />}
              label={DISMISS_VALUES.DISAGREE}
              value={DISMISS_VALUES.DISAGREE}
            />
            <FormControlLabel
              control={<Radio checked={reason === DISMISS_VALUES.DOWN} />}
              label={DISMISS_VALUES.DOWN}
              value={DISMISS_VALUES.DOWN}
            />
            <FormControlLabel
              control={<Radio checked={reason === DISMISS_VALUES.OTHER} />}
              label={DISMISS_VALUES.OTHER}
              value={DISMISS_VALUES.OTHER}
            />
          </RadioGroup>
        </FormControl>
      </div>
      <TextField
        multiline
        onChange={handleInput}
        className={clsx(
          classes.textField,
          maybeString(!chosenOther, classes.disabled),
        )}
        rows="3"
        value={customReason}
        disabled={!chosenOther}
        placeholder="provide details"
        variant="outlined"
      />
      <div style={{position: "relative"}}>
        <span
          className={clsx(
            classes.label,
            maybeString(customReason.length === 128, classes.labelMax),
            maybeString(!chosenOther, classes.labelDisabled),
          )}
        >
          {customReason.length}/128
        </span>
      </div>
    </div>
  );
};

export default withStyles(styles)(Body);
