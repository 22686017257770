import all from "utils/validation/all";
import required from "utils/validation/required";

export default values => {
  const baseRequiredFields = [
    "driveType",
    "downholeLocation",
    "staticReservoirPressure",
    "oilDensity",
    "waterDensity",
    "gasDensity",
  ];

  if (values.driveType === "unknown") {
    baseRequiredFields.push("bubblePointPressure");
  }

  const baseErrors = all(required, values, baseRequiredFields);

  const requiredProductionFields = [
    "oilProduction",
    "waterProduction",
    "gasProduction",
    "referencePBHP",
  ];

  const referenceProduction = (values && values.referenceProduction) || {};

  const referenceProductionErrors = all(
    required,
    referenceProduction,
    requiredProductionFields,
  );

  return {
    ...baseErrors,
    ...(Object.keys(referenceProductionErrors).length > 0
      ? {referenceProduction: referenceProductionErrors}
      : {}),
  };
};
