import clsx from "clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";

import {ANY, NONE} from "constants/tag-selection-types";

const useStyles = makeStyles(({colors}) => ({
  box: {
    width: 50,
    height: 50,
    background: colors.warmGrey[50],
    borderRadius: 5,
    border: [["solid", 1.5, colors.warmGrey[100]]],
    boxSizing: "border-box",
    position: "relative",
    "& > .circle": {
      position: "absolute",
      width: "50%",
      height: "50%",
      border: [["solid", 1.5, colors.warmGrey[100]]],
      boxSizing: "inherit",
      borderRadius: "50%",
      margin: "25% 0",
      background: "white",
      "&.left": {
        left: "10%",
      },
      "&.right": {
        right: "10%",
      },
      "&.center": {
        top: "50%",
        left: "50%",
        border: "none",
        width: "15%",
        height: "37%",
        background: colors.blue[800],
        margin: 0,
        transform: "translate(-50%, -50%)",
      },
    },
    [`&.${ANY} > .circle`]: {
      background: colors.blue[600],
      border: "none",
    },
    [`&.${NONE}`]: {
      background: colors.blue[800],
      border: "none",
    },
  },
}));

const SelectionVisual = ({type}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.box, type)}>
      <div className="circle left" />
      <div className="circle right" />
      {type !== NONE && <div className="circle center" />}
    </div>
  );
};

export default SelectionVisual;
