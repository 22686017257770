import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Checkbox from "@material-ui/core/Checkbox";
import CheckboxMarkedCircle from "mdi-material-ui/CheckboxMarkedCircle";
import CheckboxBlankCircleOutline from "mdi-material-ui/CheckboxBlankCircleOutline";

const useStyles = makeStyles(({spacing, colors}) => ({
  defaultCheckbox: isHovering => ({
    height: 20,
    width: 20,
  }),
  unselectedStyle: isHovering => ({
    opacity: isHovering ? 1 : 0,
    color: colors.blue[400],
    transition: "all .2s ease-in-out",
    transitionProperty: "color, opacity",
  }),
  selectedStyle: isHovering => ({
    transition: "color .2s ease-in-out",
    color: isHovering ? colors.blue[500] : colors.blue[700],
  }),
  root: {
    padding: 0,
  },
}));

const OverrideIconCheckbox = ({isHovering, selected}) => {
  const classes = useStyles(isHovering);
  return (
    <Checkbox
      checked={selected}
      className={classes.defaultCheckbox}
      classes={{root: classes.root}}
      icon={<CheckboxBlankCircleOutline className={classes.unselectedStyle} />}
      checkedIcon={<CheckboxMarkedCircle className={classes.selectedStyle} />}
    />
  );
};

export default OverrideIconCheckbox;
