import React from "react";

const sizes = {
  xs: 5,
  sm: 10,
  md: 15,
  lg: 20,
  xl: 25,
};

const Spacer = ({className, size = "md"}) => (
  <div
    className={className}
    style={{
      marginTop: sizes[size],
      marginBottom: sizes[size],
    }}
  />
);

export default Spacer;
