import {useState, useEffect, useRef} from "react";
import get from "lodash/get";
import moment from "moment";

import {findCurrentRequestValue} from "utils/find-current-request-value";

export const useDowntime = well => {
  const slowSpeedOffTime = get(well, "operation.slowSpeedOffTime");
  const [slowSpeedOffTimeInUse, pending] = findCurrentRequestValue(
    slowSpeedOffTime,
  );
  const [downtimeEnabled, setDowntimeEnabled] = useState(
    get(well, "operation.slowSpeedOffTime.enabled", false),
  );
  const downtimeEnabledHandler = () => {
    setDowntimeEnabled(!downtimeEnabled);
  };
  const [downtime, setDowntime] = useState(
    moment()
      .startOf("day")
      .add(get(slowSpeedOffTimeInUse, "offTime", 60), "seconds"),
  );

  // input selection when downtime is enabled
  const [firstLoad, setFirstLoad] = useState(true);
  const downtimeRef = useRef(null);
  useEffect(
    () => {
      if (downtimeEnabled && !firstLoad) {
        downtimeRef.current.select();
      }

      firstLoad && setFirstLoad(false);
    },
    // disabling the need to put firstLoad as a dependency
    // eslint-disable-next-line
    [downtimeEnabled],
  );

  return {
    downtimeEnabled,
    downtimeEnabledHandler,
    downtime,
    pending,
    setDowntime,
    downtimeRef,
  };
};
