import fetch from "epics/fetch";

import {setOne, types} from "actions/wells";
import {show} from "actions/snackbar";

import omitDeepUndefined from "utils/omit-deep-undefined";
import valueOf from "utils/value-of";

function parseSave(motorDetails) {
  return omitDeepUndefined({
    powerSupplyPhases: valueOf(motorDetails.powerSupplyPhases),
    efficiency: valueOf(motorDetails.efficiency),
    horsepower: valueOf(motorDetails.horsepower),
    voltage: valueOf(motorDetails.voltage),
    current: valueOf(motorDetails.current),
    rpm: valueOf(motorDetails.rpm),
    serviceFactor: valueOf(motorDetails.serviceFactor),
  });
}

export default action$ =>
  action$.ofType(types.saveMotorDetails).switchMap(({payload}) =>
    fetch(
      `/optimization/well/${encodeURIComponent(
        payload.downholeLocation,
      )}/motorDetails`,
      {},
      {
        body: parseSave(payload.data),
        method: "PUT",
      },
    ).flatMap(() => [
      setOne({
        wellId: payload.wellId,
        data: {
          motorDetails: payload.data,
        },
      }),
      show({
        message: "You have successfully updated the Motor Information",
      }),
    ]),
  );
