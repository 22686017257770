import * as EVENT_TYPES from "./event-types";

export const TYPES = {
  ALL: "all",
  WELL_EVENTS: "well events",
  USER_EVENTS: "user events",
  CARDS: "cards",
  ALERTS_RECOMMENDATIONS: "alerts/recommendations",
};

export const FILTER_GROUPS = {
  [TYPES.ALL]: Object.values(EVENT_TYPES),
  [TYPES.WELL_EVENTS]: [
    EVENT_TYPES.DRIVE_FAULT,
    EVENT_TYPES.FAULT_RECOVERY,
    EVENT_TYPES.POWER_STATUS,
    EVENT_TYPES.RUNNING_STATUS,
    EVENT_TYPES.STATUS_CODES,
  ],
  [TYPES.USER_EVENTS]: [
    EVENT_TYPES.ALLOWED_TO_OPERATE,
    EVENT_TYPES.DEVICE_ASSIGNED,
    EVENT_TYPES.DEVICE_REMOVED,
    EVENT_TYPES.DOWNHOLE_INFORMATION,
    EVENT_TYPES.GRACE_PERIOD_SET,
    EVENT_TYPES.GRACE_PERIOD_UNSET,
    EVENT_TYPES.MANUAL_FAULT_RESET,
    EVENT_TYPES.MOTOR_DETAILS,
    EVENT_TYPES.PUMPING_UNIT,
    EVENT_TYPES.RESERVOIR,
    EVENT_TYPES.RESISTANCE,
    EVENT_TYPES.SAFE_OPERATING_PARAMETERS,
    EVENT_TYPES.SCHEDULED_DOWNTIME,
    EVENT_TYPES.SET_BASIC_WELL_DETAILS,
    EVENT_TYPES.SET_FAULT_RECOVERY,
    EVENT_TYPES.SPEED_PROFILE,
    EVENT_TYPES.SPEED_RANGE,
    EVENT_TYPES.SURFACE_CARD_REQUESTED,
    EVENT_TYPES.SURFACE_CARD_REQUEST_FULFILLED,
    EVENT_TYPES.TOTAL_REDUCTION_RATIO,
    EVENT_TYPES.WELL_ANALOG_INPUT_CHANGED,
    EVENT_TYPES.WELL_CURRENT_VIOLATION,
    EVENT_TYPES.WELL_TORQUE_VIOLATION,
    EVENT_TYPES.WELL_PUMP_OFF_SETTING_CHANGED,
    EVENT_TYPES.WELL_RESET_MALFUNCTIONS,
    EVENT_TYPES.WELL_RUN_TIMER,
    EVENT_TYPES.WELLBORE,
  ],
  [TYPES.CARDS]: [
    EVENT_TYPES.CARDS,
    EVENT_TYPES.GENERATED_CARDS,
    EVENT_TYPES.RAW_CARDS,
    EVENT_TYPES.DRIVE_DATA,
  ],
  [TYPES.ALERTS_RECOMMENDATIONS]: [
    EVENT_TYPES.ALERT_ACKNOWLEDGED,
    EVENT_TYPES.ALERT_CREATED,
    EVENT_TYPES.ALERT_RESOLVED,
    EVENT_TYPES.RECOMMENDATION_ACCEPTED,
    EVENT_TYPES.RECOMMENDATION_DISMISSED,
    EVENT_TYPES.NEW_RECOMMENDATION,
  ],
};
