import equals from "utils/validation/equals";
import maxLength from "utils/validation/max-length";
import minLength from "utils/validation/min-length";
import required from "utils/validation/required";
import validateFieldsOf from "utils/validation/validate-fields-of";

export default values => {
  const validateField = validateFieldsOf(values);

  return {
    ...validateField("password", x => required(x)),
    ...validateField("password", x => minLength(x, 8)),
    ...validateField("password", x => maxLength(x, 255)),

    ...validateField("confirmPassword", x => required(x)),
    ...validateField("confirmPassword", x =>
      equals(x, values.password, "password"),
    ),
  };
};
