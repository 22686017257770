import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

import {useHover} from "hooks/use-hover";

import WellCardCaption from "./well-card-caption";
import {WellCardOverrideButton} from "../well-card-override";

const useStyles = makeStyles(({spacing}) => ({
  actionBar: {
    display: "flex",
    flexDirection: "row",
    minHeight: 72,
    padding: spacing(0, 1, 0, 2),
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const WellCardActions = ({
  caption,
  latestConfidence,
  toggleDialog,
  hasSubmitted,
  override,
  showOverrideButton,
}) => {
  const classes = useStyles();
  const {isHovering, enableHover, disableHover} = useHover();

  return (
    <div
      className={classes.actionBar}
      onMouseEnter={enableHover}
      onMouseLeave={disableHover}
    >
      <WellCardCaption caption={caption} confidence={latestConfidence} />
      {showOverrideButton && (
        <WellCardOverrideButton
          onClick={toggleDialog}
          hasSubmitted={hasSubmitted}
          override={override}
          isHoveringOnActions={isHovering}
        />
      )}
    </div>
  );
};

export default WellCardActions;
