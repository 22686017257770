import max from "utils/validation/max";
import min from "utils/validation/min";
import number from "utils/validation/number";
import required from "utils/validation/required";
import valueOf from "utils/value-of";

const validateField = (values, field) => {
  const value = valueOf(values[field]);

  return {
    ...(required(value) ? {[field]: required(value)} : {}),
    ...(number(value) ? {[field]: number(value)} : {}),
    ...(min(value) ? {[field]: min(value)} : {}),
  };
};

const validateWithMax = (values, field) => {
  const value = valueOf(values[field]);

  return {
    ...validateField(values, field),
    ...(max(value, 100) ? {[field]: max(value, 100)} : {}),
  };
};

export default values => {
  return {
    ...validateWithMax(values, "load"),
    ...validateWithMax(values, "position"),
    ...validateWithMax(values, "primaryFillage"),
    ...validateField(values, "strokes"),
  };
};
