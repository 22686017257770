import find from "lodash/find";
import get from "lodash/get";

import {create} from "actions/wells";
import countries from "constants/countries";
import statesProvinces from "constants/states-provinces";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {connect} from "react-redux";
import {actions, Form} from "react-redux-form";
import store from "store";

import formPath from "../form-path";
import handleBack from "../handle-back";
import PUMPING_UNIT_TYPES from "../pumping-unit-types";
import DisplayValue from "./display-value";
import preparePayload from "./prepare-payload";

const getByValue = (value, items) => find(items, item => item.value === value);

const mapStateToProps = state => {
  const {addWell} = state.forms;

  return {
    ...addWell,
    pumpingUnitType: get(
      getByValue(addWell.pumpingUnitType, PUMPING_UNIT_TYPES),
      "text",
      "",
    ),
    stateProvinceLabel: get(
      getByValue(addWell.wellCountry, countries),
      "divisionLabel",
      "",
    ),
    wellCountry: get(getByValue(addWell.wellCountry, countries), "text", ""),
    wellProvinceState: get(
      getByValue(
        addWell.wellProvinceState,
        statesProvinces[addWell.wellCountry],
      ),
      "text",
      "",
    ),
  };
};

let Confirm = props => {
  return (
    <Form model={formPath}>
      <Typography variant="body2" style={{marginBottom: "8px"}}>
        Please take a moment to confirm your well details.
      </Typography>
      <Grid container direction="row" spacing={0}>
        <DisplayValue label={"Country"} value={props.wellCountry} />
        <DisplayValue
          label={props.stateProvinceLabel}
          value={props.wellProvinceState}
        />
        <DisplayValue
          label={"Downhole Location"}
          value={props.downholeLocation}
        />
        {props.wellCountry === "Canada" && (
          <DisplayValue
            label={"Surface Location"}
            value={props.surfaceLocation}
          />
        )}
        <DisplayValue
          label={"Display Name"}
          value={props.alias || props.downholeLocation}
        />
        <DisplayValue label={"Field Name"} value={props.fieldName} />
        <DisplayValue
          label={"Latitude"}
          value={props.surfaceLocationLatitude}
        />
        <DisplayValue
          label={"Longitude"}
          value={props.surfaceLocationLongitude}
        />
        <DisplayValue
          label={"Pumping Unit Type"}
          value={props.pumpingUnitType}
        />
      </Grid>
    </Form>
  );
};

Confirm = connect(mapStateToProps)(Confirm);

export default {
  content: <Confirm />,
  handleBack,
  handleNext: (_, disableButton) => {
    disableButton();
    store.dispatch(
      create({
        data: preparePayload(),
        onSuccess: actions.reset("forms"),
      }),
    );
  },
  label: "Confirm",
};
