import {set, types} from "actions/casing-specs";
import {default as performFetch} from "epics/fetch";

export const fetch = (action$, store) => {
  return action$.ofType(types.fetch).switchMap(({payload}) => {
    return performFetch(`/optimization/casingSpecs`).map(({response: data}) =>
      set({data}),
    );
  });
};
