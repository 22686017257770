import isNil from "lodash/isNil";

// eslint-disable-next-line no-useless-escape
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default value => {
  const validationError = "Invalid email address";

  if (isNil(value) || value === "") {
    return;
  }

  if (value.constructor !== String) {
    return validationError;
  }

  if (value.match(EMAIL_REGEX)) {
    return;
  }

  return validationError;
};
