import React from "react";

import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";

import {defaultGridContainer} from "./dashboard-styles";
import DisplayDeflection from "./display-deflection";
import DisplaySpeed from "./display-speed";
import DisplayMode from "./display-mode";

export default props => {
  const {well} = props;

  if (
    isEmpty(well.conditions.speed) &&
    !well.status.mode &&
    isNil(well.conditions.deflection)
  )
    return null;

  return (
    <div>
      <Divider />
      <Grid
        container
        style={defaultGridContainer}
        justify={"space-between"}
        spacing={0}
      >
        <Grid item xs>
          <DisplaySpeed well={well} />
        </Grid>
        {well.status.mode && (
          <Grid item xs>
            <DisplayMode well={well} />
          </Grid>
        )}
        {!isNil(well.conditions.deflection) && (
          <Grid item xs>
            <DisplayDeflection well={well} />
          </Grid>
        )}
      </Grid>
    </div>
  );
};
