import {Hint, LineSeries, MarkSeries, XAxis} from "react-vis";
import {connect} from "react-redux";
import {push} from "react-router-redux";
import {round, isNil} from "lodash";
import compose from "lodash/fp/compose";
import flatten from "lodash/fp/flatten";
import grey from "@material-ui/core/colors/grey";
import React from "react";
import withHandlers from "recompose/withHandlers";
import withState from "recompose/withState";
import withStyles from "@material-ui/core/styles/withStyles";
import {dailyProduction} from "unit-converter";

import {getLatestClassification} from "utils/well/get-latest-classification";
import {getProductionValues} from "utils/get-production-values";
import {setActive} from "actions/field-optimization";

import {usePreparedChartData} from "../../use-prepared-chart-data";
import ScatterPlot from "../scatter-plot";
import {fieldOptimizationWellsSelector} from "../../field-optimization-wells-selector";

const OPACITY = 0.95;

const styles = ({shadows, shape, typography}) => ({
  hint: {
    padding: "12px 30px 6px 30px",
    color: "#fdfdfd",
    background: "#3a3a48",
    boxShadow: shadows[4],
    borderRadius: shape.borderRadius,
    whiteSpace: "nowrap",
    textAlign: "center",

    "& *": {
      margin: 0,
    },

    "& h6": {
      ...typography.subtitle1,
      color: "inherit",
      lineHeight: 1.2,
      fontSize: "1rem",
      letterSpacing: "0.00938em",
    },

    "& p": {
      ...typography.caption,
      color: "inherit",
      fontWeight: 300,
      fontSize: "0.75rem",
      lineHeight: 1.66,
      letterSpacing: ".03333em",
    },

    "& span": {
      ...typography.h6,
      color: "#fff",
      marginTop: ".25rem",
      fontSize: "1.25rem",
      lineHeight: 1.6,

      "&:nth-child(2)": {
        marginLeft: ".5rem",
      },
    },
  },
});

const HintBody = ({className, unitsOfMeasure, ...props}) => (
  <div className={className}>
    <p>{props.field}</p>
    <h6>{props.name}</h6>
    <p>{props.classification}</p>
    <p>
      <span>{props.y}</span>{" "}
      {unitsOfMeasure.toLocaleLowerCase() === "metric" ? "m3/d" : "bbl/d"}
      <span>{props.x}</span> SPM
    </p>
  </div>
);

const mapStateToProps = state => {
  const {
    fieldOptimization: {active, classification},
  } = state;
  const {
    auth: {
      user: {unitsOfMeasure},
    },
  } = state;

  return {
    active,
    wells: fieldOptimizationWellsSelector(state, true),
    classification,
    unitsOfMeasure,
  };
};

const mapDispatchToProps = dispatch => ({
  setActive: value => dispatch(setActive(value)),
  push: url => dispatch(push(url)),
});

const adjustPoint = (data, precision) =>
  isNil(data) ? null : round(data, precision);

const convert = (unitsOfMeasure, data) =>
  !isNil(data)
    ? adjustPoint(
        dailyProduction.convert({
          value: data,
          from: "metric",
          to: unitsOfMeasure,
        }),
      )
    : data;

const toSeries = (well, unitsOfMeasure) => ({
  id: well.wellId,
  classification: getLatestClassification(well),
  field: well.fieldName,
  name: well.displayName,
  downholeLocation: well.downholeLocation,
  x: adjustPoint(well.dailySummaries.spm, 2),
  y: convert(
    unitsOfMeasure,
    getProductionValues(well.dailySummaries, "totalLiquid").value,
  ),
});

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState("selected", "setSelected", null),
  withHandlers({
    handleMouseOver: ({setActive, setSelected}) => value => {
      setSelected(value);
      setActive(value);
    },
    handleMouseOut: ({setActive, setSelected}) => () => {
      setSelected(null);
      setActive(null);
    },
    handleOpacity: ({active}) => value => {
      return active && active.id === value.id ? OPACITY : 0;
    },
  }),
  withStyles(styles, {withTheme: true}),
);

const classificationStyles = {
  active: "#FD8902",
  selected: "#4b586b",
  unselected: grey[300],
  contrastText: "#fff",
};

const AvgLiquidProduction = ({
  active,
  classes,
  handleMouseOver,
  handleMouseOut,
  handleOpacity,
  selected,
  wells,
  classification,
  unitsOfMeasure,
  push,
  theme: {palette, typography},
}) => {
  const redirectToWell = ({downholeLocation}) =>
    push(`/well/${encodeURIComponent(downholeLocation)}/analysis`);

  // In order to have a bigger impact we split the data into two series. This insures that
  // the selected series will always be rendered above the unselected series.
  const {series, trendLine, xDomain, yDomain} = usePreparedChartData(
    wells,
    classification,
    toSeries,
    unitsOfMeasure,
  );
  const [seriesA, seriesB] = series;

  return (
    <ScatterPlot
      margin={{left: 40, right: 16, top: 16, bottom: 40}}
      xDomain={xDomain}
      yDomain={yDomain}
      onMouseLeave={handleMouseOut}
    >
      <LineSeries
        data={trendLine.points}
        color={grey[500]}
        strokeDasharray="7, 3"
      />
      <MarkSeries
        data={seriesB}
        color={classificationStyles.unselected}
        opacity={OPACITY}
        bleed
        onValueClick={redirectToWell}
      />
      <MarkSeries
        data={seriesA}
        color={classificationStyles.selected}
        opacity={OPACITY}
        bleed
        onValueClick={redirectToWell}
      />
      <MarkSeries
        data={flatten(series)}
        color={classificationStyles.active}
        onValueMouseOver={handleMouseOver}
        onValueMouseOut={handleMouseOut}
        getOpacity={handleOpacity}
        bleed
        onValueClick={redirectToWell}
      />
      <XAxis
        hideTicks
        hideLine
        title={`R² = ${trendLine.r2}`}
        style={{
          title: {
            stroke: "none",
            fill: "#999",
            fontSize: typography.caption.fontSize,
          },
        }}
      />
      {selected && (
        <Hint value={selected} style={{zIndex: 1}}>
          <HintBody
            className={classes.hint}
            unitsOfMeasure={unitsOfMeasure}
            {...selected}
          />
        </Hint>
      )}
    </ScatterPlot>
  );
};

export default enhance(AvgLiquidProduction);
