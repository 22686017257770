import {connect} from "react-redux";
import get from "lodash/get";
import Grid from "@material-ui/core/Grid";
import React from "react";
import {reduxForm} from "redux-form";

import compose from "utils/compose";
import DecimalTextField from "components/forms/decimal-text-field";
import FormHeader from "components/form-header";
import getDefault from "utils/get-default-value";
import getUnits from "utils/get-units";
import PaperContainer from "components/paper-container";
import {round} from "@ambyint/common/utils/round";
import SaveCancelButtons from "components/save-cancel-buttons";
import {setLufkinMalfunction} from "actions/wells";
import validate from "utils/wells/lufkin-settings/validate-malfunction";

import getDisplayedSetting from "../get-displayed-setting";

const mapDispatchToProps = dispatch => ({
  save: (values, well, onClose) => {
    dispatch(setLufkinMalfunction({values, well}));
    onClose();
  },
});

const mapStateToProps = (state, props) => {
  const units = getUnits();
  const {well} = props;

  const rootSetting = get(well, "lufkinSettings.malfunction");
  const setting = getDisplayedSetting(rootSetting);

  const load = get(setting, "load", getDefault(units.load));
  const position = get(setting, "position", getDefault(units.position));
  const consecutiveStrokes = get(setting, "consecutiveStrokes") || 2;
  const consecutiveViolations = get(setting, "consecutiveViolations") || 2;

  return {
    initialValues: {
      ...setting,
      load: {
        ...load,
        value: round(load.value),
      },
      position: {
        ...position,
        value: round(position.value),
      },
      consecutiveStrokes,
      consecutiveViolations,
    },
  };
};

class MalfunctionEditView extends React.Component {
  render() {
    const {
      handleSubmit,
      invalid,
      onClose,
      pristine,
      submitting,
      reset,
      save,
      well,
    } = this.props;

    return (
      <PaperContainer extraPadded style={{maxWidth: "750px"}}>
        <form
          onSubmit={handleSubmit(values => {
            save(values, well, onClose);
          })}
        >
          <Grid container direction={"row"} style={{maxWidth: 776}} spacing={2}>
            <Grid item xs={12}>
              <FormHeader>Malfunction Setpoint</FormHeader>
            </Grid>

            <Grid item xs={6} style={{padding: "8px"}}>
              <DecimalTextField
                fullWidth
                name={"load"}
                label={"Load"}
                required
              />
            </Grid>

            <Grid item xs={6} style={{padding: "8px"}}>
              <DecimalTextField
                fullWidth
                name={"position"}
                label={"Position"}
                required
              />
            </Grid>

            <Grid item xs={6} style={{padding: "8px"}}>
              <DecimalTextField
                fullWidth
                name={"consecutiveStrokes"}
                label={"Consecutive Strokes"}
                required
              />
            </Grid>

            <Grid item xs={6} style={{padding: "8px"}}>
              <DecimalTextField
                fullWidth
                name={"consecutiveViolations"}
                label={"Consecutive Violations"}
                required
              />
            </Grid>
          </Grid>

          <div style={{marginLeft: "8px"}}>
            <SaveCancelButtons
              invalid={invalid}
              pristine={pristine}
              reset={compose(
                onClose,
                reset,
              )}
              submitting={submitting}
            />
          </div>
        </form>
      </PaperContainer>
    );
  }
}

const Component = reduxForm({
  form: "lufkin-malfunction-edit-view",
  enableReinitialize: true,
  validate,
})(MalfunctionEditView);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);
