import React from "react";
import {connect} from "react-redux";
import {push} from "react-router-redux";

import TableRow from "components/table-row";
import {
  setRecommendationType,
  saveSafeOperatingParameters,
  setSpeedRange,
} from "actions/wells";
import {useRangeSliderState} from "hooks/setpoint-automation/use-range-slider-state";
import {useAutomationState} from "hooks/setpoint-automation/use-automation-state";

import AutomationButtonGroup from "./automation-button-group";
import {mapAutomationProps} from "./prop-mappings/map-automation-props";
import {mapRangeSliderProps} from "./prop-mappings/map-range-slider-props";
import {mapWellNameProps} from "./prop-mappings/map-well-name-props";
import RangeSlider from "./range-slider";
import WellName from "./well-name";

const mapDispatchToProps = {
  push,
  setRecommendationType,
  saveSafeOperatingParameters,
  setSpeedRange,
};

const WellRow = ({setRecommendationType, ...props}) => {
  const {rangeSliderState, rangeSliderOnChange} = useRangeSliderState(
    props.well,
  );
  const {
    recommendationType,
    recommendationTypeOnChange,
    saveRecommendationType,
  } = useAutomationState(props.well, setRecommendationType);

  const wellNameState = mapWellNameProps(props);
  const automationState = mapAutomationProps({
    recommendationType,
    recommendationTypeOnChange,
    saveRecommendationType,
    ...props,
  });
  const rangeSliderProps = mapRangeSliderProps({
    ...props,
    rangeSliderState,
    rangeSliderOnChange,
    disabled: automationState.disabled,
  });

  return (
    <TableRow style={{cursor: "default"}}>
      <WellName {...wellNameState} />
      <AutomationButtonGroup
        {...automationState}
        metBoundary={rangeSliderProps.metBoundary}
      />
      <RangeSlider {...rangeSliderProps} />
    </TableRow>
  );
};

export default connect(
  null,
  mapDispatchToProps,
)(WellRow);
