import {types} from "./types";

export const set = ({data}) => {
  return {
    type: types.set,
    payload: {
      data,
    },
  };
};
