import parseTubing from "@ambyint/common/parsers/well/parse-tubing";

import {set, types} from "actions/tubing";
import {default as performFetch} from "epics/fetch";
import getUser from "utils/get-user";

export const fetch = action$ => {
  return action$.ofType(types.fetch).switchMap(() => {
    const unitsOfMeasure = getUser().unitsOfMeasure;

    return performFetch(`/optimization/tubing`).map(({response: data}) =>
      set({
        data: data.map(tubing => parseTubing(tubing, unitsOfMeasure)),
      }),
    );
  });
};
