import required from "utils/validation/required";

const validateDifferentInputs = values => {
  if (values.casing !== values.tubing) return {};
  if (values.casing === 0 && values.tubing === 0) return {};

  return {
    casing: "Tubing and casing cannot be the same input",
    tubing: "Tubing and casing cannot be the same input",
  };
};

export default values => {
  return {
    ...(required(values.casing) ? {casing: required(values.casing)} : {}),
    ...(required(values.tubing) ? {tubing: required(values.tubing)} : {}),
    ...validateDifferentInputs(values),
  };
};
