import {compose} from "recompose";
import Grid from "@material-ui/core/Grid";
import isEmpty from "lodash/isEmpty";
import noop from "lodash/noop";
import React from "react";

import PaperContainer from "components/paper-container";
import FormButton from "components/form-button";
import FormHeader from "components/form-header";
import {roundWithUnits} from "utils/round-with-units";
import withFeatureFlag from "components/with-feature-flag";

import {cardLabelFactory, cardValueFactory} from "../../cards";
import getDisplayedSetting from "../get-displayed-setting";
import StatusIndicator from "../status-indicator";
import {Unconfigured} from "../unconfigured";
import withSettingStatus from "../with-setting-status";

const MalfunctionReadView = ({
  canEdit = false,
  onEdit = noop,
  styles = {},
  isStatusVisible,
  settings,
}) => {
  const setting = getDisplayedSetting(settings);

  const {consecutiveStrokes, consecutiveViolations, load, position} = setting;

  const CardLabel = cardLabelFactory(6);
  const CardValue = cardValueFactory(6);

  return (
    <PaperContainer extraPadded style={styles.container}>
      <Grid container>
        <Grid item xs={12}>
          <FormHeader>
            Malfunction Setpoint
            {isStatusVisible && <StatusIndicator settings={settings} />}
          </FormHeader>
        </Grid>

        {isEmpty(setting) && <Unconfigured />}

        {!isEmpty(setting) && (
          <>
            <CardLabel label={"Load"} />
            <CardValue value={roundWithUnits(load, 2)} />

            <CardLabel label={"Position"} />
            <CardValue value={roundWithUnits(position, 2)} />

            <CardLabel label={"Consecutive Strokes"} />
            <CardValue value={consecutiveStrokes} />

            <CardLabel label={"Consecutive Violations"} />
            <CardValue value={consecutiveViolations} />
          </>
        )}
        {canEdit && (
          <Grid item xs={12}>
            <FormButton onClick={onEdit}>Edit Malfunction Setpoint</FormButton>
          </Grid>
        )}
      </Grid>
    </PaperContainer>
  );
};

export default compose(
  withSettingStatus,
  withFeatureFlag("set-lufkin-malfunction"),
)(MalfunctionReadView);
