import {push} from "react-router-redux";
import setToken from "utils/set-token";

import {isCompletingSignIn, navigateToLogin, types} from "actions/auth";

export default (action$, store) => {
  return action$
    .ofType(types.completeSignInWithOAuth)
    .switchMap(async ({payload}) => {
      const auth = store.getState().auth;
      const {clientUserId, callbackToken} = payload;

      if (callbackToken) {
        try {
          const res = await fetch(`${process.env.REACT_APP_API}/currentUser`, {
            headers: {
              Authorization: `Basic ${callbackToken}`,
            },
          });

          if (res.ok) {
            const user = await res.json();

            setToken(user.token);

            if (
              !user.termsOfServiceAccepted &&
              !auth.termsOfServiceAcceptedThisSession
            ) {
              return push(`/verify-oauth/${clientUserId}/${callbackToken}`);
            }
            return isCompletingSignIn();
          }
        } catch (err) {
          console.error(err);
        }
      }

      return navigateToLogin();
    });
};
