import MaskedTextField from "components/forms/masked-text-field";
import React from "react";

export default props => {
  const {min, max, step, ...otherProps} = props;
  const inputProps = {
    min,
    max,
    step: step || 1,
  };

  return (
    <MaskedTextField
      type="number"
      inputProps={inputProps}
      maskPattern={"Decimal"}
      {...otherProps}
    />
  );
};
