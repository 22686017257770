import React from "react";
import FormButton from "components/form-button";
import noop from "utils/noop";

const SaveCancelButtons = props => {
  const {
    invalid = false,
    onClose = noop,
    pristine,
    reset = noop,
    submitting,
  } = props;

  return (
    <div style={{padding: "16px 16px 0 0"}}>
      <FormButton
        disabled={submitting}
        onClick={() => {
          reset();
          onClose();
        }}
      >
        Cancel
      </FormButton>
      <FormButton disabled={pristine || submitting || invalid} type="submit">
        Save
      </FormButton>
    </div>
  );
};

export default SaveCancelButtons;
