import React from "react";

import Grid from "@material-ui/core/Grid";

import FormButton from "components/form-button";
import FormHeader from "components/form-header";
import PaperContainer from "components/paper-container";

import getPrivileges from "utils/get-privileges";
import privilegeTypes from "utils/privilege-types";

import ResistanceEditView from "./resistance-edit-view";

import {cardLabelFactory, cardValueFactory} from "../cards";

class ResistanceReadView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {well} = this.props;

    const privileges = getPrivileges(well.organization.id);

    const resistance = well.resistance || {};

    if (this.state.showResistanceEditView) {
      return (
        <ResistanceEditView
          onClose={() => this.setState({showResistanceEditView: false})}
          well={well}
        />
      );
    }

    const CardLabel = cardLabelFactory(7);
    const CardValue = cardValueFactory(5);

    return (
      <PaperContainer extraPadded style={{maxWidth: "500px"}}>
        <Grid container>
          <Grid item xs={12} style={{marginTop: "0px", marginBottom: "0px"}}>
            <FormHeader>Resistance Parameters</FormHeader>
          </Grid>

          <CardLabel label="Damping Coefficient" />
          <CardValue value={resistance.dampingCoefficient} />

          <CardLabel label="Friction Coefficient" />
          <CardValue value={resistance.frictionCoefficient} />

          <CardLabel label="Stuffing Box Friction Auto Correction" />
          <CardValue
            value={
              resistance.enableStuffingBoxFrictionAutoCorrection
                ? "Enabled"
                : "Disabled"
            }
          />
          {privileges.includes(privilegeTypes.well.configure) && (
            <Grid item xs={12} style={{marginTop: "0px", marginBottom: "0px"}}>
              <FormButton
                onClick={() => this.setState({showResistanceEditView: true})}
              >
                Edit Resistance
              </FormButton>
            </Grid>
          )}
        </Grid>
      </PaperContainer>
    );
  }
}

export default ResistanceReadView;
