const warmGrey = {
  50: "#faf9f7",
  100: "#e8e6e1",
  200: "#d3cec4",
  300: "#b8b2a7",
  400: "#a39e93",
  500: "#857f72",
  600: "#625d52",
  700: "#504a40",
  800: "#423d33",
  900: "#27241d",
};

export default warmGrey;
