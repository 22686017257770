import get from "lodash/get";

import {states} from "@ambyint/common/well-status";
import ambyColors from "@ambyint/common/colors";

export const colors = {
  Running: ambyColors.blueGrey,
  Shutdown: ambyColors.pink,
  Unknown: ambyColors.deepOrange,
};

const styleMap = {
  [states.RUNNING.id]: {
    fillColor: colors.Running[400],
    strokeColor: colors.Running[900],
  },
  [states.SHUTDOWN.id]: {
    fillColor: colors.Shutdown[400],
    strokeColor: colors.Shutdown[900],
  },
  [states.UNKNOWN.id]: {
    fillColor: colors.Unknown[300],
    strokeColor: colors.Unknown[900],
  },
};

export const getStatusColor = status => {
  return (
    styleMap[get(status, "state.id")] || {
      fillColor: colors.Unknown[300],
      strokeColor: colors.Unknown[900],
    }
  );
};
