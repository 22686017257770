export default ({state, action}) => {
  return {
    ...state,
    analysis: {
      ...state.analysis,
      link: {
        originalUrl: action.payload.originalUrl,
        url: action.payload.url,
        isFetching: false,
      },
    },
  };
};
