import {connect} from "react-redux";
import {push} from "react-router-redux";
import isEmpty from "lodash/isEmpty";
import React from "react";
import SunnyIcon from "@material-ui/icons/WbSunny";

import {fetch} from "actions/alerts";
import ALERT_STATUS_STATES from "constants/alert-status-states";
import compare from "utils/compare";
import EmptyState from "components/empty-state";
import withHelmet from "components/with-helmet";
import {withAlertSearch} from "components/app-bar/with-search";

import AlertsList from "./list";

const pageContainer = {
  position: "absolute",
  top: "70px",
  right: "16px",
  bottom: "16px",
  left: "16px",
};

const mapStateToProps = state => {
  return {
    alerts: state.alerts,
    user: state.auth.user,
  };
};

class Alerts extends React.Component {
  componentWillMount() {
    const {dispatch, user} = this.props;

    if (user) dispatch(fetch({clientUserId: user.clientUserId}));
  }

  shouldComponentUpdate(nextProps) {
    const {dispatch, user, alerts} = this.props;

    if (nextProps.user && !compare(user, nextProps.user)) {
      dispatch(fetch({clientUserId: nextProps.user.clientUserId}));
    }

    return !compare(alerts, nextProps.alerts);
  }

  render() {
    const {alerts = {}, dispatch} = this.props;

    if (isEmpty(alerts.all)) {
      return (
        <div>
          <div style={pageContainer}>
            <EmptyState
              actions={[
                {
                  label: "View Wells",
                  onClick: () => {
                    dispatch(push("/"));
                  },
                },
              ]}
              icon={SunnyIcon}
              mainText={"Great news, you've got no active alerts!"}
              subText={
                "You'll be able to view any active alerts on this page in the future"
              }
            />
          </div>
        </div>
      );
    }

    return (
      <div>
        <div style={pageContainer}>
          <AlertsList
            alerts={{
              ...alerts,
              filtered: alerts.filtered.filter(
                alert =>
                  alert.status === ALERT_STATUS_STATES.ACKNOWLEDGED ||
                  alert.status === ALERT_STATUS_STATES.UNACKNOWLEDGED,
              ),
            }}
          />
        </div>
      </div>
    );
  }
}

export default withHelmet({title: "Alerts"})(
  withAlertSearch(connect(mapStateToProps)(Alerts)),
);
