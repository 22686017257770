import {types} from "../types";

/**
 * Creates an action that sets the calibrate workflow wells.
 * @param {Array} wellList
 * @returns {{type: string, payload: {path: string}}}
 */
export const setCalibrateWells = payload => {
  return {
    type: types.setCalibrateWells,
    payload,
  };
};
