import React from "react";
import {connect} from "react-redux";

import {setMapType} from "actions/wells";

import {workflows} from "constants/well-workflows";
import {getWells} from "utils/wells/get-wells";

import Map from "./map";
import MapTypeButtonGroup from "./map-type-button-group";
import "./compact-map.css";

const cardContainer = {
  position: "relative",
  height: "100%",
};

const mapContainer = {
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};

const mapStateToProps = ({wells: {mapType}}) => {
  return {mapType};
};

const enhance = connect(
  mapStateToProps,
  {setMapType},
);

const getWellsByWorkflow = workflow => {
  const {selected} = workflow;

  const wellMap = {
    [workflows.ANOMALY]: workflow.anomaly.wellList,
    [workflows.DISPLAY_ALL]: workflow.displayAll.filtered,
    [workflows.TRIAGE]: workflow.triage.filtered,
    [workflows.CALIBRATE]: workflow.calibrate.filtered,
  };

  return getWells(wellMap[selected]);
};

const MapContainer = props => {
  const {
    workflow,
    mapType,
    setMapType,
    overrides,
    compact,
    switcherDisabled,
    wells: receivedWells,
  } = props;
  const wells = receivedWells || getWellsByWorkflow(workflow);
  if (!wells.length && !compact) return null;

  return (
    <div style={cardContainer}>
      <div style={mapContainer} className={compact ? "compact-map" : ""}>
        {!compact && !switcherDisabled && (
          <MapTypeButtonGroup mapType={mapType} onClick={setMapType} />
        )}
        <Map
          mapType={mapType}
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyD0pnWKfWxE3h75yLtT2_aL_L00MGcb84Q"
          loadingElement={<div style={{height: `100%`}} />}
          containerElement={<div style={{height: "100%"}} />}
          mapElement={<div style={{height: "100%"}} />}
          wells={wells}
          overrides={overrides}
          compact={compact}
        />
      </div>
    </div>
  );
};

export default enhance(MapContainer);
