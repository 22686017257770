import {getStatuses} from "@ambyint/common/well-status";
import ALERT_TYPES from "constants/alert-types";

export default alert => {
  if (alert.information && alert.information.statusCodes) {
    const statuses = getStatuses(alert.information.statusCodes);

    if (statuses && statuses[0]) {
      if (alert.type === "lufkinStatusCode") {
        const status = statuses[0];
        return status.description;
      }

      if (statuses.length === 1) {
        return statuses[0].description;
      }

      if (alert.information.statusCodes.length > 1) {
        return `${statuses[0].description}, +${statuses.length - 1} more`;
      }
    }
  }

  return ALERT_TYPES[alert.type] || alert.type;
};
