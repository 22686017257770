import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";

import FractionalCount from "components/fractional-count";

const styles = ({spacing, colors}) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: spacing(1),
  },
  headerText: {
    color: colors.warmGrey[600],
    fontWeight: 400,
  },
  button: {
    textTransform: "none",
    "&:hover": {
      backgroundColor: colors.warmGrey[100],
    },
    "& div": {
      cursor: "pointer",
    },
  },
  count: {
    marginRight: spacing(1),
  },
});

const WellGroupingHeader = ({
  title,
  count,
  totalCount,
  toggleCollapse,
  classes,
}) => {
  return (
    <div className={classes.container}>
      <Button
        disabled={count < 1}
        className={classes.button}
        onClick={toggleCollapse}
      >
        <Typography className={classes.headerText} variant="subtitle1">
          {title}
        </Typography>
      </Button>
      <FractionalCount
        numerator={count}
        denominator={totalCount}
        className={classes.count}
      />
    </div>
  );
};

export default withStyles(styles)(WellGroupingHeader);
