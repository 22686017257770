const calculateFlatLineMargins = (value, range, positiveOrNegative) => {
  if (value === 0) {
    // Adjust scale so that horizontal line is on the bottom
    if (positiveOrNegative > 0) {
      return 1;
    }

    return 0;
  }

  return value + positiveOrNegative * 1; // Horizontally center flat lines if there is no variation.
};

const calculateStandardMargins = (
  value,
  range,
  positiveOrNegative,
  marginPercent,
  margin,
) => {
  return (
    value +
    range * positiveOrNegative * (marginPercent / 100) +
    positiveOrNegative * margin
  );
};

const addMargins = (
  value,
  range,
  positiveOrNegative,
  marginPercent,
  margin,
  scaleMinimum,
) => {
  let scaleEndpoint;
  const center = value - (range / 2) * positiveOrNegative;
  const minimumDistanceFromCenter = (scaleMinimum / 2) * positiveOrNegative;

  if (range === 0) {
    scaleEndpoint = calculateFlatLineMargins(value, range, positiveOrNegative);
  } else {
    scaleEndpoint = calculateStandardMargins(
      value,
      range,
      positiveOrNegative,
      marginPercent,
      margin,
    );
  }

  const scaleSize = Math.abs(scaleEndpoint - center);
  const isSmallerThanScaleMinimum =
    scaleMinimum > 0 && scaleSize < scaleMinimum;

  if (isSmallerThanScaleMinimum) {
    scaleEndpoint = addMargins(
      center + minimumDistanceFromCenter,
      Math.abs(minimumDistanceFromCenter * 2),
      positiveOrNegative,
      marginPercent,
      margin,
    );
  }

  return scaleEndpoint;
};

export default addMargins;
