import React from "react";

import ListStyleContainer from "components/list-style-container";

/**
 * Button that resembles Material-UI list items, but easier for us to customize and acts as a button by default.
 */
const ListStyleButton = props => {
  const {containerStyle = {}, onClick, primary, secondary} = props;

  return (
    <ListStyleContainer
      onClick={onClick}
      primary={primary}
      secondary={secondary}
      containerStyle={{userSelect: "none", ...containerStyle}}
    />
  );
};

export default ListStyleButton;
