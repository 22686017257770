import React from "react";

import {COLORS, LABELS} from "constants/well-health";
import getUnits from "utils/get-units";
import round from "utils/round";

import BaseWellboreGraph from "./base-wellbore-graph";
import dataToPoint from "./data-to-point";

const TooltipPlunger = ({tooltipData: {data, pointNumber}, setColor}) => {
  const {
    inclinationDegrees,
    doglegSeverityDeg30m,
    measuredDepth,
    trueVerticalDepth,
    diameter,
  } = dataToPoint(
    data,
    [
      "inclinationDegrees",
      "doglegSeverityDeg30m",
      "measuredDepth",
      "trueVerticalDepth",
      "diameter",
    ],
    pointNumber,
  );

  setColor(COLORS.PLUNGER);

  return (
    <BaseWellboreGraph
      title={LABELS.PLUNGER}
      primaryContentList={[
        {label: "Inclination:", value: `${round(inclinationDegrees)}°`},
        {label: "DLS:", value: `${round(doglegSeverityDeg30m)}°`},
        {
          label: "TVD:",
          value: `${round(trueVerticalDepth, 0)}${getUnits().depth}`,
        },
      ]}
      secondaryContentList={[
        {label: "MD:", value: `${round(measuredDepth, 0)}${getUnits().depth}`},
        {label: "Diameter:", value: `${round(diameter)}${getUnits().length}`},
      ]}
    />
  );
};

export default TooltipPlunger;
