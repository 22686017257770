import formatWithUnits from "@ambyint/common/utils/format/format-with-units";

const formatCouplingTypeLabel = couplingType =>
  couplingType &&
  couplingType.type &&
  `${couplingType.type} - PIN ${formatWithUnits(couplingType.pin, 3)} - ${
    couplingType.materialGrade
  }`;

export default formatCouplingTypeLabel;
