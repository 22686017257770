import {max as d3max, min as d3min} from "d3-array";
import {scaleLinear as d3scaleLinear} from "d3-scale";
import addArea from "utils/charts/add-area";
import addLine from "utils/charts/add-line";
import colors from "theme/colors";

function mapData(d) {
  return d.torque || [];
}

function mapTime(d) {
  return new Date(d.createdOn);
}

function mapTorque(d) {
  return d.value;
}

function mapMaxTorque(d) {
  if (!d.max) {
    return mapTorque(d);
  }

  return d.max;
}

function mapMinTorque(d) {
  if (!d.min) {
    return mapTorque(d);
  }

  return d.min;
}

const styles = {
  area: {
    fill: colors.torque[50],
  },

  line: {
    stroke: colors.torque[400],
  },
};

export default ({clipPathId, selection, register, scales}) => {
  const torqueScale = d3scaleLinear();
  const options = {
    clipPathId,
    mapData,
    mapX: mapTime,
    mapY: mapTorque,
    mapMaxY: mapMaxTorque,
    mapMinY: mapMinTorque,
    selection,
    xScale: scales.time,
    yScale: torqueScale,
  };

  const area = addArea({...options, styles: styles.area});
  const line = addLine({...options, styles: styles.line});

  return register({
    draw(props) {
      const {config, data} = props;
      const maxTorque = d3max(mapData(data), mapMaxTorque);
      const minTorque = d3min(mapData(data), mapMinTorque);
      const maxTorqueScale = maxTorque * 1.1;
      const minTorqueScale = minTorque >= 0 ? 0 : minTorque - maxTorque * 0.1;
      torqueScale
        .domain([minTorqueScale, maxTorqueScale])
        .range([props.height, 0]);

      area.hide();
      line.hide();

      if (config.torque && config.torque.state > 0) {
        line.show();
      }

      if (config.torque && config.torque.state === 2) {
        area.show();
      }

      area.draw(props);
      line.draw(props);
    },

    zoom(props) {
      area.zoom(props);
      line.zoom(props);
    },
  });
};
