import fetchCards from "epics/fetch-cards";

export default async payload => {
  const cards = await fetchCards(payload);

  return cards.map(event => {
    return {
      ...event,
      type: "miniCards",
    };
  });
};
