import get from "lodash/get";

import {sendAnalyticsEvent} from "components/analytics";
import {eventCategory, eventAction} from "constants/analytics";

const findTooltipTitles = (recommendationType, limitedAccess) => {
  let tooltipTitlePower = "Turn On Ambyint";
  let tooltipTitleAutomate = "Enable Full Automation";

  if (recommendationType.on) tooltipTitlePower = "Turn Off Ambyint";
  if (recommendationType.autoAccept)
    tooltipTitleAutomate = "Enable Recommendations Only";
  if (limitedAccess) {
    tooltipTitleAutomate = "";
    tooltipTitlePower = "";
  }

  return {tooltipTitlePower, tooltipTitleAutomate};
};

export const mapAutomationProps = ({
  well,
  saveRecommendationType,
  recommendationType,
  recommendationTypeOnChange,
  limitedAccess,
}) => {
  const disabled = !get(recommendationType, "on");

  const automateOnClick = () => {
    if (disabled || limitedAccess) return;

    sendAnalyticsEvent(
      eventCategory.setpointAutomationWorkflow,
      eventAction.toggle,
      "Automation",
    );

    const newRecommendationType = {
      ...recommendationType,
      autoAccept: !recommendationType.autoAccept,
    };

    recommendationTypeOnChange(newRecommendationType);
    saveRecommendationType(newRecommendationType);
  };

  const powerOnClick = () => {
    if (limitedAccess) return;

    sendAnalyticsEvent(
      eventCategory.setpointAutomationWorkflow,
      eventAction.toggle,
      "Recommendations",
    );

    const newRecommendationType = {
      ...recommendationType,
      autoAccept: recommendationType.on ? recommendationType.autoAccept : true,
      on: !recommendationType.on,
    };

    recommendationTypeOnChange(newRecommendationType);
    saveRecommendationType(newRecommendationType);
  };

  const {tooltipTitlePower, tooltipTitleAutomate} = findTooltipTitles(
    recommendationType,
    limitedAccess,
  );

  return {
    automateOnClick,
    limitedAccess,
    powerOnClick,
    disabled,
    recommendationType,
    tooltipTitleAutomate,
    tooltipTitlePower,
  };
};
