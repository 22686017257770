import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = ({typography, colors, spacing}) => ({
  container: {
    display: "grid",
    gridTemplateRows: "[header] auto [content] auto",
    gridTemplateColumns: "[label] auto [value] auto",
    columnGap: spacing(1),
  },

  header: {
    gridRow: "header",
    gridColumn: "label / span 2",
    paddingBottom: "10px",
  },

  title: {
    ...typography.body1,
  },

  subtitle: {
    ...typography.body2,
    color: colors.warmGrey[600],
  },

  label: {
    ...typography.subtitle2,
    gridColumn: "label",
  },

  value: {
    ...typography.body2,
    gridColumn: "value",
  },

  spacer: {
    gridColumn: "label / span 2",
    height: "10px",
  },
});

const BaseWellboreGraph = ({
  title,
  subtitle,
  primaryContentList,
  secondaryContentList,
  classes,
}) => {
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.title}>{title}</div>
        {subtitle ? <div className={classes.subtitle}>{subtitle}</div> : null}
      </div>

      {primaryContentList
        ? primaryContentList.map(c => (
            <React.Fragment key={c.label}>
              <div className={classes.label}>{c.label}</div>
              <div className={classes.value}>{c.value}</div>
            </React.Fragment>
          ))
        : null}

      {primaryContentList && secondaryContentList ? (
        <div className={classes.spacer} />
      ) : null}

      {secondaryContentList
        ? secondaryContentList.map(c => (
            <React.Fragment key={c.label}>
              <div className={classes.label}>{c.label}</div>
              <div className={classes.value}>{c.value}</div>
            </React.Fragment>
          ))
        : null}
    </div>
  );
};

export default withStyles(styles)(BaseWellboreGraph);
