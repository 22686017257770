import hasIn from "lodash/hasIn";

/**
 * Checks if value is nested or not and returns the proper value
 *
 * { input : { value : 5 }}
 * or
 * { input : { value : { units: "lbs", value: 5 }}}
 *
 * This is to accomodate redux forms input values
 */
export default ({input}) =>
  hasIn(input, "value.value") ? input.value.value : input.value;
