import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import filter from "lodash/filter";
import noop from "lodash/noop";
import orderBy from "lodash/orderBy";
import {Redirect} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import uniq from "lodash/uniq";
import values from "lodash/values";
import withStyles from "@material-ui/core/styles/withStyles";

import {hasFullAccess} from "utils/organizations/has-full-access";
import Container from "components/container";
import {
  acceptRecommendation,
  dismissRecommendation,
  setDowntime,
  saveAutoAcceptRecommendations,
  updateRecommendation,
} from "actions/wells";
import {eventAction, eventCategory} from "constants/analytics";
import {filterRecommendations} from "utils/recommendations";
import {RECOMMENDATION_TYPES} from "constants/recommendations";
import Subheader from "components/subheader";
import {withPageview, sendAnalyticsEvent} from "components/analytics";
import {withBoxLoading} from "components/box-loading";
import {withWellSearch} from "components/app-bar/with-search";
import withHelmet from "components/with-helmet";

import DismissDialog from "./dismiss-dialog";
import EmptyStateOne from "./empty-state-one";
import RecommendationsContainer from "./recommendations-container";
import "./fade.css";

const byStatus = desiredStatus => {
  return ({status}) => status === desiredStatus;
};

const openAndAcceptedThisSession = (
  acceptedThisSession,
  dismissedThisSession,
) => rec => {
  const accepted = acceptedThisSession.find(id => id === rec.recommendationId);
  const dismissed = dismissedThisSession.find(
    id => id === rec.recommendationId,
  );

  return !dismissed && (accepted || byStatus("open")(rec));
};

const previouslyManuallyAccepted = acceptedThisSession => rec => {
  const madeInSession = acceptedThisSession.find(
    id => id === rec.recommendationId,
  );
  const automated = rec.autoAccept;

  return !automated && !madeInSession && byStatus("accepted")(rec);
};

const handleRecommendations = disabled => well => {
  const {
    recommendations = [],
    displayName,
    autoAcceptRecommendationTypes,
  } = well;

  return recommendations.map(rec => {
    return {...rec, displayName, disabled, autoAcceptRecommendationTypes};
  });
};

const styles = theme => ({
  title: {
    color: theme.colors.warmGrey[600],
  },
  container: {
    margin: [[theme.spacing(20), 0, theme.spacing(8), 0]],
  },
});

class Recommendations extends React.Component {
  state = {
    acceptedThisSession: [],
    dismissedThisSession: [],
    dismissDialogOpen: false,
    dismiss: noop,
    lastRemovedRec: {index: Infinity, id: null},
    redirectTo: null,
  };

  redirect = recommendation => () => {
    sendAnalyticsEvent(
      eventCategory.recommendationsWorkflow,
      eventAction.navigate,
      "To Well",
    );
    this.setState({
      redirectTo: `/well/${encodeURIComponent(
        recommendation.downholeLocation,
      )}/analysis`,
    });
  };

  acceptRecommendation = recommendation => () => {
    sendAnalyticsEvent(
      eventCategory.recommendationsWorkflow,
      eventAction.accept,
      recommendation.type,
    );

    this.setState(({acceptedThisSession}) => ({
      acceptedThisSession: [
        ...acceptedThisSession,
        recommendation.recommendationId,
      ],
    }));

    if (recommendation.type === RECOMMENDATION_TYPES.IDLE_TIME) {
      this.props.setDowntime({
        downholeLocation: recommendation.downholeLocation,
        data: {
          hours: recommendation.recommendedData.hours,
          minutes: recommendation.recommendedData.minutes,
        },
      });
    }

    const {recommendedData: acceptedData} = recommendation;
    this.props.acceptRecommendation({
      recommendationId: recommendation.recommendationId,
      ...(acceptedData ? {acceptedData} : {}),
    });
  };

  openDismissDialog = (rec, i) => () => {
    this.setState({
      dismissDialogOpen: true,
      dismiss: this.dismissRecommendation(rec, i),
    });
  };

  dismissRecommendation = (recommendation, index) => reason => {
    sendAnalyticsEvent(
      eventCategory.recommendationsWorkflow,
      eventAction.dismiss,
      recommendation.type,
    );

    this.setState(({dismissedThisSession}) => ({
      dismissedThisSession: [
        ...dismissedThisSession,
        recommendation.recommendationId,
      ],
      lastRemovedRec: {index, id: recommendation.recommendationId},
    }));

    this.props.dismissRecommendation({
      recommendationId: recommendation.recommendationId,
      reason,
    });
  };

  automateRecommendation = recommendation => () => {
    sendAnalyticsEvent(
      eventCategory.recommendationsWorkflow,
      eventAction.automate,
      recommendation.type,
    );
    const {downholeLocation, type} = recommendation;
    const autoAcceptRecommendationTypes = uniq([
      ...recommendation.autoAcceptRecommendationTypes,
      type,
    ]);
    this.props.saveAutoAcceptRecommendations({
      downholeLocation,
      autoAcceptRecommendationTypes,
    });
  };

  resetLastRemovedRecIndex = () => {
    this.setState({lastRemovedRec: {index: Infinity, id: null}});
  };

  render() {
    const {
      acceptedThisSession,
      dismissedThisSession,
      lastRemovedRec,
      redirectTo,
    } = this.state;
    const {recommendations, classes} = this.props;
    const currentCollection = orderBy(recommendations, "createdOn", "desc");

    const filteredRecommendations = {
      open: filter(
        currentCollection,
        openAndAcceptedThisSession(acceptedThisSession, dismissedThisSession),
      ),
      accepted: filter(
        currentCollection,
        previouslyManuallyAccepted(acceptedThisSession),
      ),
    };

    const totalRecommendationsCount = Object.values(
      filteredRecommendations,
    ).reduce((total, v) => {
      return v.length ? (total += v.length) : total;
    }, 0);

    const noVisibleRecs =
      !filteredRecommendations.open.length &&
      !filteredRecommendations.accepted.length;

    return (
      <Container>
        <DismissDialog
          open={this.state.dismissDialogOpen}
          close={() => this.setState({dismissDialogOpen: false})}
          dismiss={this.state.dismiss}
        />
        {redirectTo && <Redirect push to={redirectTo} />}
        <Subheader>
          <Typography variant="subtitle1" className={classes.title}>
            Recommendations
          </Typography>
        </Subheader>
        <div className={classes.container}>
          <div>
            {noVisibleRecs ? (
              <EmptyStateOne />
            ) : (
              <RecommendationsContainer
                title="Open"
                accept={this.acceptRecommendation}
                animate
                automate={this.automateRecommendation}
                dismiss={this.openDismissDialog}
                lastRemovedRec={lastRemovedRec}
                recommendations={filteredRecommendations.open}
                totalRecommendationsCount={totalRecommendationsCount}
                redirect={this.redirect}
                resetLastRemovedRecIndex={this.resetLastRemovedRecIndex}
              />
            )}
            {!!filteredRecommendations.accepted.length && (
              <RecommendationsContainer
                title="Accepted"
                recommendations={filteredRecommendations.accepted}
                totalRecommendationsCount={totalRecommendationsCount}
                redirect={this.redirect}
              />
            )}
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = ({wells, auth, organizationView}) => {
  const disabled = !hasFullAccess(
    auth.user.assignedOrganizations,
    organizationView.currentOrganization,
  );

  const recommendations = filterRecommendations(
    values(wells.byWellId).flatMap(handleRecommendations(disabled)),
  );

  return {
    recommendations,
    loading: !wells.allRecommendationsFetched,
  };
};

const mapDispatchToProps = {
  acceptRecommendation,
  dismissRecommendation,
  saveAutoAcceptRecommendations,
  setDowntime,
  updateRecommendation,
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withBoxLoading,
  withStyles(styles),
  withPageview("/recommendations"),
  withHelmet({title: "Recommendations"}),
  withWellSearch,
);

export default enhance(Recommendations);
