import {replace} from "react-router-redux";
import {ajax} from "rxjs/observable/dom/ajax";
import {
  parseWell,
  setAssigned,
  setOne,
  setUptimeOne,
  types,
} from "actions/wells";
import {show} from "actions/snackbar";

import addAuthHeaders from "../add-auth-headers";

export {default as acceptRecommendation} from "./accept-recommendation";
export {default as create} from "./create";
export {default as deleteGracePeriod} from "./delete-grace-period";
export {default as dismissRecommendation} from "./dismiss-recommendation";
export {default as fetchAlerts} from "./fetch-alerts";
export {default as fetchAllRecommendations} from "./fetch-all-recommendations";
export {default as fetchAnalysis} from "./fetch-analysis";
export {default as fetchCards} from "./fetch-cards";
export {default as fetchEvents} from "./fetch-events";
export {default as fetchLatestCards} from "./fetch-latest-cards";
export {default as fetchOnCall} from "./fetch-on-call";
export {default as fetchRecommendations} from "./fetch-recommendations";
export {
  generateShareableAnalysisLink,
} from "./generate-shareable-analysis-link";
export {default as requestCard} from "./request-card";
export {default as resetFault} from "./reset-fault";
export {
  default as saveAutoAcceptRecommendations,
} from "./save-auto-accept-recommendations";
export {default as saveDownholeInformation} from "./save-downhole-information";
export {default as saveGracePeriod} from "./save-grace-period";
export {default as saveMotorDetails} from "./save-motor-details";
export {default as saveMotorViolations} from "./save-motor-violations";
export {default as saveNominalRpm} from "./save-nominal-rpm";
export {
  default as savePumpingUnitInformation,
} from "./save-pumping-unit-information";
export {default as savePumpoffSettings} from "./save-pumpoff-settings";
export {
  default as saveReservoirInformation,
} from "./save-reservoir-information";
export {default as saveResistance} from "./save-resistance";
export {
  default as saveSafeOperatingParameters,
} from "./save-safe-operating-parameters";
export {default as saveWellboreInformation} from "./save-wellbore-information";
export {default as saveTag} from "./save-tag";
export {default as saveTotalReductionRatio} from "./save-total-reduction-ratio";
export {default as setFaultRecovery} from "./set-fault-recovery";
export {default as setRecommendationType} from "./set-recommendation-type";
export {default as saveSlowSpeedOffTime} from "./save-slow-speed-off-time";
export {default as setResetMalfunctions} from "./set-reset-malfunctions";
export {default as setAnalogInputConfiguration} from "./save-analog-inputs";
export {default as setDowntime} from "./set-downtime";
export {default as setRunTimer} from "./set-run-timer";
export {default as setSpeed} from "./set-speed";
export {default as setSpeedRange} from "./set-speed-range";
export {default as startWell} from "./start-well";
export {default as stopWell} from "./stop-well";
export {addComparison, fetchComparison, removeComparison} from "./comparisons";
export {fetchMiniCardById} from "./fetch-mini-card-by-id";
export {fetchLatestMiniCard} from "./fetch-latest-mini-card";
export {fetchCardOverride} from "./fetch-card-override";
export {setCardOverride} from "./set-card-override";
export {fetchWells} from "./fetch-wells";
export {initializeAnalysis} from "./initialize-analysis";
export {parseOne} from "./parse-one";
export {searchWells} from "./search-wells";
export {updateAnalysis} from "./update-analysis";
export {setActiveAnomaly, setActivePath} from "./workflows";
export {viewFeedComparison} from "./view-feed-comparison";
export {setLufkinDeadband} from "./lufkin-settings/set-deadband";
export {setLufkinFillBase} from "./lufkin-settings/set-fill-base";
export {setLufkinMalfunction} from "./lufkin-settings/set-malfunction";
export {setLufkinStructuralLoad} from "./lufkin-settings/set-structural-load";
export {setLufkinRodString} from "./lufkin-settings/set-rodstring";

export const fetchOne = action$ => {
  return action$.ofType(types.fetchOne).switchMap(({payload}) => {
    return ajax(
      addAuthHeaders({
        url: `${process.env.REACT_APP_API}/wells/${encodeURIComponent(
          payload.downholeLocation,
        )}`,
        responseType: "json",
      }),
    )
      .map(({response: data}) => {
        return parseWell({data});
      })
      .catch(err => {
        if (err.status === 401 || err.status === 403) {
          return [
            replace("/login"),
            show({
              message: "Unauthorized to view well",
            }),
          ];
        }
        return [replace(`/error?code=${err.status}`)];
      });
  });
};

export const fetchUptimeOne = (action$, store) => {
  return action$.ofType(types.fetchUptimeOne).switchMap(({payload}) => {
    return ajax(
      addAuthHeaders({
        url: `${
          process.env.REACT_APP_API
        }/optimization/well/${encodeURIComponent(
          payload.downholeLocation,
        )}/uptime`,
        responseType: "json",
      }),
    ).map(({response: data}) =>
      setUptimeOne({downholeLocation: payload.downholeLocation, data}),
    );
  });
};

export const fetchAssigned = (action$, store) => {
  return action$.ofType(types.fetchAssigned).switchMap(({payload}) => {
    return ajax(
      addAuthHeaders({
        url: `${
          process.env.REACT_APP_API
        }/client-experience/user/${encodeURIComponent(
          payload.clientUserId,
        )}/assignedWells`,
        responseType: "json",
      }),
    ).map(({response: data}) => setAssigned({data}));
  });
};

export const saveBasicInformation = (action$, store) => {
  return action$.ofType(types.saveBasicInformation).switchMap(({payload}) => {
    return ajax(
      addAuthHeaders({
        url: `${
          process.env.REACT_APP_API
        }/optimization/well/${encodeURIComponent(payload.downholeLocation)}`,
        responseType: "json",
        method: "PUT",
        body: {
          alias: payload.data.alias,
          fieldName: payload.data.fieldName,
          pumpingUnitType: payload.data.type.toLowerCase(),
          surfaceLocation: payload.data.surfaceLocation,
          surfaceLocationLatitude: payload.data.coords[0],
          surfaceLocationLongitude: payload.data.coords[1],
          type: payload.data.type.toLowerCase(),
          wellCountry: payload.data.country.toLowerCase(),
          wellProvinceState: payload.data.provinceState,
        },
      }),
    ).flatMap(({response: data}) => {
      return [
        setOne({
          wellId: payload.wellId,
          data: payload.data,
        }),
        show({
          message: "You have successfully updated the Basic Well Information",
        }),
      ];
    });
  });
};
