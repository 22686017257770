import {parse, stringify} from "query-string";
import merge from "lodash/merge";

import {createSwitchEpic} from "epics/create-epic";
import {mapAnalysisToQuery} from "utils/wells/analysis";
import {replace} from "actions/history";
import {types} from "actions/wells";

export const updateAnalysis = createSwitchEpic(
  [types.updateAnalysis],
  async ({payload}, store) => {
    const state = store.getState();
    const {downholeLocation} = payload;
    const analysis = Object.assign(
      {},
      state.wells.analysis[downholeLocation],
      payload.analysis,
    );
    const analysisQueryString = mapAnalysisToQuery(analysis);
    const combinedParams = stringify(
      merge(parse(state.routing.location.search), parse(analysisQueryString)),
    );

    const path = `?${combinedParams}`;

    return [replace({path})];
  },
);

export default updateAnalysis;
