import {show} from "actions/snackbar";
import {setOne, types} from "actions/wells";

import fetch from "epics/fetch";

import parseSaveSafeOperatingParameters from "./parse-save-safe-operating-parameters";

export default action$ =>
  action$.ofType(types.saveSafeOperatingParameters).switchMap(({payload}) =>
    fetch(
      `/wells/${encodeURIComponent(
        payload.downholeLocation,
      )}/safeOperatingParameters`,
      {},
      {
        body: parseSaveSafeOperatingParameters(payload.data),
        method: "PUT",
      },
    ).flatMap(() => [
      setOne({
        wellId: payload.wellId,
        data: {
          safeOperatingParameters: payload.data,
        },
      }),
      show({
        message: "You have successfully updated the Safe Operating Parameters",
      }),
    ]),
  );
