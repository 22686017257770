import {push} from "react-router-redux";
import {of} from "rxjs/observable/of";

import {types} from "actions/auth";

export default action$ => {
  return action$.ofType(types.navigateToLogin).mergeMap(() => {
    return of(push("/login"));
  });
};
