import {linear} from "regression";

const toPoint = ({x, y}) => [x, y];
const fromPoint = ([x, y]) => ({x, y});

export default function trendLine(data, xAxis) {
  const {predict, ...rest} = linear(data.map(toPoint));

  return {
    ...rest,
    points: xAxis.map(predict).map(fromPoint),
  };
}
