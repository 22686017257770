import {compose} from "recompose";
import React from "react";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import ArrowIcon from "@material-ui/icons/ArrowUpward";
import Fade from "@material-ui/core/Fade";
import clsx from "clsx";

import {eventCategory, eventAction} from "constants/analytics";
import {sendAnalyticsEvent} from "components/analytics";
import Tooltip from "components/material/tooltip";

import withSort from "./with-sort";

const styles = ({colors}) => ({
  icon: {
    transition: "all 0.2s ease-in-out",
    height: 24,
    color: colors.warmGrey[600],
  },
  decending: {
    transform: "rotate(180deg)",
  },
});

export const onToggleSortOrder = (doSort, sortColumn, sortAsc) => () => {
  sendAnalyticsEvent(
    eventCategory.allWellsWorkflow,
    eventAction.sort,
    `${sortColumn}:${sortAsc ? "asc" : "desc"}`,
  );
  doSort(sortColumn, !sortAsc);
};

const SortOrderIconToggle = ({classes, doSort, sortAsc, sortBy, show}) => (
  <Fade in={show}>
    {show ? (
      <Tooltip title={sortAsc ? "Ascending" : "Descending"} enterDelay={500}>
        <Button
          variant="outlined"
          onClick={onToggleSortOrder(doSort, sortBy, sortAsc)}
          aria-label="Toggle Sort Order"
        >
          <div className={clsx(classes.icon, !sortAsc && classes.decending)}>
            <ArrowIcon />
          </div>
        </Button>
      </Tooltip>
    ) : (
      <div /> // Workaround to avoid tooltip from throwing warnings if we disabled it on !show
    )}
  </Fade>
);

export default compose(
  withSort,
  withStyles(styles),
)(SortOrderIconToggle);
