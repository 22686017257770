import {connect} from "react-redux";
import find from "lodash/find";
import map from "lodash/map";
import React from "react";

import colors from "@ambyint/colors";

import {importTypes} from "constants/import-types";
import {mappingTypes} from "constants/well-event-import-mapping-types";
import {setMappings} from "actions/import-settings";
import getUser from "utils/get-user";

import {filterOptions, getOption} from "../../options";

import {Mapping} from "./mapping";
import {Units} from "./units";

const styles = {
  td: {
    textAlign: "left",
    verticalAlign: "bottom",
    whiteSpace: "nowrap",
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
  },
};

const onChangeType = ({colIndex, dispatch, mappings}) => e => {
  const user = getUser();
  const option = getOption(e.target.value);

  const units =
    find(option.availableUnits, units =>
      new RegExp(units.type, "i").test(user.unitsOfMeasure),
    ) || {};

  mappings = [...mappings];
  mappings[colIndex] = {
    ...option,
    units: units.value,
  };

  dispatch(setMappings({type: importTypes.WELL_EVENT, mappings}));
};

const onChangeUnits = ({colIndex, dispatch, mappings}) => e => {
  mappings = [...mappings];
  mappings[colIndex] = {...mappings[colIndex], units: e.target.value};

  dispatch(setMappings({type: importTypes.WELL_EVENT, mappings}));
};

let TableHeader = props => {
  const {dispatch, headers, hideIgnored, mappings} = props;

  return (
    <thead>
      <tr style={styles.tr}>
        <td style={{padding: 0}} />
        {map(mappings, (mapping, colIndex) => {
          const options = filterOptions(mappings, mapping);
          const option = getOption(mapping.type);
          const availableUnits = map(option.availableUnits, "value");
          const ignored = option.type === mappingTypes.IGNORE;

          if (hideIgnored && ignored) {
            return null;
          }

          const colStyle = {
            ...styles.td,
            ...(ignored ? {backgroundColor: colors.grey[100]} : {}),
            ...(colIndex === 0 ? {paddingLeft: 0} : {}),
            ...(colIndex === mappings.length - 1 ? {paddingRight: 0} : {}),
            verticalAlign: "unset",
          };

          return (
            <td key={colIndex} style={colStyle}>
              <Mapping
                label={headers[colIndex]}
                mapping={mapping}
                onChange={onChangeType({colIndex, dispatch, mappings})}
                options={options}
              />
              &nbsp;
              <Units
                mapping={mapping}
                onChange={onChangeUnits({colIndex, dispatch, mappings})}
                units={availableUnits}
              />
            </td>
          );
        })}
      </tr>
    </thead>
  );
};

TableHeader = connect()(TableHeader);

export {TableHeader};
