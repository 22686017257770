export const types = {
  hide: "recommendation-action-dialog:hide",
  show: "recommendation-action-dialog:show",
};

export const hide = () => {
  return {
    type: types.hide,
    payload: {
      isOpen: false,
    },
  };
};

export const show = ({well, recommendation}) => {
  return {
    type: types.show,
    payload: {
      recommendation,
      well,
      isOpen: true,
    },
  };
};
