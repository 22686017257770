import {types} from "actions/cranks";

const defaultValues = {
  fetched: false,
  values: [],
};

export default (state = defaultValues, action) => {
  if (action.type === types.set) {
    return {
      ...state,
      fetched: true,
      values: action.payload.data || [],
    };
  }

  return state;
};
