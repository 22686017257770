import get from "lodash/get";
import isNil from "lodash/isNil";

const sort = (sortBy, sortDirection) => (a, b) => {
  const sortTerms = sortBy.split(",").reverse();

  const sortResult = sortTerms.reduce((lastResult, next) => {
    const path = next.split(".");
    const aValue = get(a, path);
    const bValue = get(b, path);

    if (aValue === bValue) {
      return lastResult;
    } else if (isNil(aValue)) {
      return sortDirection === "asc" ? -1 : 1;
    } else if (isNil(bValue)) {
      return sortDirection === "asc" ? 1 : -1;
    } else if (aValue > bValue) {
      return sortDirection === "asc" ? 1 : -1;
    }

    return sortDirection === "asc" ? -1 : 1;
  }, 0);

  return sortResult;
};

export default sort;
