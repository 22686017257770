import {torque as converter} from "unit-converter";
import getUnits from "utils/units";

export default ({alert, user}) => {
  const {
    maxMeasuredTorque,
    maxMeasuredCurrent,
    currentViolationSetpoint,
    torqueViolationSetpoint,
  } = alert.information;

  const displayCurrent =
    user.epmPreference === "current" && currentViolationSetpoint;

  if (
    !maxMeasuredTorque ||
    !maxMeasuredCurrent ||
    !currentViolationSetpoint ||
    !torqueViolationSetpoint
  ) {
    return displayCurrent
      ? "An alert has been issued because the current fell below the configured min current setpoint."
      : "An alert has been issued because the torque fell below the configured min torque setpoint.";
  }

  const units = getUnits(user.unitsOfMeasure);

  const convertedMaxTorque = converter.convert({
    value: parseFloat(maxMeasuredTorque),
    to: user.unitsOfMeasure,
  });

  const minTorque = converter.convert({
    value: parseFloat(torqueViolationSetpoint.min),
    to: user.unitsOfMeasure,
  });

  return displayCurrent
    ? `An alert has been issued because the current ${maxMeasuredCurrent.toFixed(
        2,
      )} ${
        units.current
      } fell below the min current setpoint ${currentViolationSetpoint.min.toFixed(
        2,
      )} ${units.current}.`
    : `An alert has been issued because the torque ${convertedMaxTorque.toFixed(
        2,
      )} ${units.torque} (${maxMeasuredCurrent.toFixed(2)} ${
        units.current
      }) fell below the min torque setpoint ${minTorque.toFixed(2)} ${
        units.torque
      } (${currentViolationSetpoint.min.toFixed(2)} ${units.current}).`;
};
