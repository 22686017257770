import {
  isLessThan,
  isGreaterThan,
} from "@ambyint/common/utils/compare-time-object";

import max from "utils/validation/max";
import min from "utils/validation/min";
import number from "utils/validation/number";
import integer from "utils/validation/integer";
import required from "utils/validation/required";
import validateFieldsOf from "utils/validation/validate-fields-of";

export default (values, props) => {
  let errors = {};

  const validateField = validateFieldsOf(values);

  const {well} = props;

  if (!well) return undefined;

  const currentDowntime = {
    hours: Number(values.hours),
    minutes: Number(values.minutes),
  };

  const {maxDowntime, minDowntime} = well.safeOperatingParameters;

  errors = {
    ...errors,
    ...(!Number(values.hours) && !Number(values.minutes)
      ? {minutes: "Overall downtime cannot be zero."}
      : {}),

    ...(isLessThan(currentDowntime, minDowntime)
      ? {minutes: "Overall downtime cannot be less than the minimum."}
      : {}),
    ...(isGreaterThan(currentDowntime, maxDowntime)
      ? {minutes: "Overall downtime cannot be greater than the maximum."}
      : {}),

    ...validateField("minutes", x => required(x)),
    ...validateField("minutes", x => min(x, 0)),
    ...validateField("minutes", x => max(x, 59)),
    ...validateField("minutes", x => number(x)),
    ...validateField("minutes", x => integer(x)),

    ...validateField("hours", x => required(x)),
    ...validateField("hours", x => min(x, 0)),
    ...validateField("hours", x => number(x)),
    ...validateField("hours", x => integer(x)),
  };

  return errors;
};
