export default (state, action, updateFunc) => {
  const wellId = state.byDownholeLocation[action.payload.downholeLocation];

  const well = state.byWellId[wellId] || {
    downholeLocation: action.payload.downholeLocation,
  };

  const byWellId = {
    ...state.byWellId,
    [wellId]: updateFunc(well),
  };

  return byWellId;
};
