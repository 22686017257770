import sortDisplayName from "./sort-display-name";
import sortCreatedOn from "./sort-created-on";
import sortStatus from "./sort-status";
import sortType from "./sort-type";

export default (values = [], field = "createdOn", asc = true) => {
  const reverse = asc ? 1 : -1;

  if (field === "displayName") return sortDisplayName({values, reverse});
  if (field === "status") return sortStatus({values, reverse});
  if (field === "type") return sortType({values, reverse});
  if (field === "createdOn") return sortCreatedOn({values, reverse});

  return values;
};
