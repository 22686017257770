import React, {useState, useEffect} from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = ({typography, spacing, colors}) => {
  return {
    tooltip: {
      position: "fixed",
      borderLeft: "4px solid",
      zIndex: 10000,
      backgroundColor: colors.warmGrey[50],
    },

    card: {
      "&:last-child": {
        padding: `${spacing(1)}px ${spacing(2)}px`,
      },
    },
  };
};

const Tooltip = ({component, data, classes}) => {
  const [mouseLoc, setMouseLoc] = useState({x: -10000, y: -10000});
  const [color, setColor] = useState();

  useEffect(() => {
    const onMouseMove = e => {
      setMouseLoc({
        x: e.clientX,
        y: e.clientY,
      });
    };

    window.addEventListener("mousemove", onMouseMove);

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
    };
  }, []);

  const Component = component;
  return (
    <Card
      className={classes.tooltip}
      style={{
        top: mouseLoc.y + 5,
        left: mouseLoc.x + 15,
        borderColor: color || "rgba(0,0,0,0)",
        marginLeft: color ? 0 : "-4px",
      }}
    >
      <CardContent className={classes.card}>
        <Component tooltipData={data} setColor={setColor} />
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(Tooltip);
