import {types} from "./types";

export const filterEventsByPerformedOn = options => {
  const {organizationId, performedOn} = options;

  return {
    type: types.filterEventsByPerformedOn,
    payload: {
      organizationId,
      performedOn,
    },
  };
};
