import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Chip from "@material-ui/core/Chip";
import clsx from "clsx";

import Tooltip from "components/material/tooltip";

const useStyles = makeStyles(({shape, colors, typography}) => ({
  chip: {
    height: 24,
    borderRadius: shape.borderRadius * 2,
    fontSize: typography.caption.fontSize,
    backgroundColor: colors.warmGrey[100],
    color: colors.warmGrey[700],
  },
}));

const TooltipChip = ({tooltip, label, className}) => {
  const classes = useStyles();

  return (
    <Tooltip title={tooltip}>
      <Chip className={clsx(classes.chip, className)} label={label} />
    </Tooltip>
  );
};

export default TooltipChip;
