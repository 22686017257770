export const types = {
  createServiceAccount: "organization:create-service-account",
  deleteWebhook: "organization:delete-webhook",
  fetchOrganization: "organization:fetch-organization",
  fetchOrganizationServiceAccount:
    "organization:fetch-organization-service-account",
  fetchOrganizationUser: "organization:fetch-organization-user",
  fetchOrganizationWells: "organization:fetch-organization-wells",
  filterEventsByDateRange: "organization:filter-events-by-date-range",
  filterEventsByPerformedBy: "organization:filter-events-by-performed-by",
  filterEventsByPerformedOn: "organization:filter-events-by-performed-on",
  filterEventsByType: "organization:filter-events-by-type",
  inviteUser: "organization:invite-user",
  reinviteUser: "organization:reinvite-user",
  removeServiceAccountFromOrganization:
    "organization:remove-service-account-from-organization",
  removeUserFromOrganization: "organization:remove-user-from-organization",
  resetInviteUserForm: "organization:reset-invite-user-form",
  saveAccess: "organization:save-access",
  saveAssignedWells: "organization:save-assigned-wells",
  saveWebhook: "organization:save-webhook",
  searchOrganizationServiceAccounts:
    "organization:search-organization-service-accounts",
  searchOrganizationUsers: "organization:search-organization-users",
  selectOrganization: "organization:select-organization",
  setAccess: "organization:set-access",
  setOrganization: "organization:set-organization",
  setOrganizationAssignedWellsForUser:
    "organization:set-organization-assigned-wells-for-user",
  setOrganizationEvents: "organization:set-organization-events",
  setOrganizationServiceAccount:
    "organization:set-organization-service-account",
  setOrganizationServiceAccounts:
    "organization:set-organization-service-accounts",
  setOrganizationUser: "organization:set-organization-user",
  setOrganizationUsers: "organization:set-organization-users",
  setOrganizationWells: "organization:set-organization-wells",
  setUserAddedToOrganization: "organization:set-user-added-to-organization",
  setUserRemovedFromOrganization:
    "organization:set-user-removed-from-organization",
  setWebhookDeleted: "organization:set-webhook-deleted",
  setWebhookSaved: "organization:set-webhook-saved",
  sortUsers: "organization:sort-users",
};
