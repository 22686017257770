export const types = {
  addNotificationType: "auth::add-notification-type",
  changePassword: "auth::change-password",
  completeSignInWithOAuth: "auth::complete-sign-in-with-oauth",
  completedSignIn: "auth::completed-sign-in",
  get: "auth::get",
  getInviteDetails: "auth::get-invite-details",
  isCompletingSignIn: "auth::is-completing-sign-in",
  navigateToLogin: "auth::navigate-to-login",
  removeNotificationType: "auth::remove-notification-type",
  requestPasswordResetEmail: "auth::request-password-reset-email",
  save: "auth::save",
  savePreferences: "auth::save-preferences",
  set: "auth::set",
  setInviteDetails: "auth::set-invite-details",
  signInWithAzure: "auth::sign-in-with-azure",
  signUpWithAzure: "auth::sign-up-with-azure",
  signInWithEmail: "auth::sign-in-with-email",
  signInWithGoogle: "auth::sign-in-with-google",
  signUpWithGoogle: "auth::sign-up-with-google",
  signOut: "auth::sign-out",
  verifyEmail: "auth::verify-email",
  verifyOAuth: "auth::verify-oauth",
};
