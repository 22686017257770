export const userAssignedWells = "userAssignedWells";
export const userInvited = "userInvited";
export const userPermissions = "userPermissions";
export const userRemoved = "userRemoved";
export const wellAdded = "wellAdded";
export const tagsAdded = "tagsAdded";
export const tagsRemoved = "tagsRemoved";

export const options = [
  {
    text: "User Well Assignment",
    value: userAssignedWells,
  },
  {
    text: "User Invited",
    value: userInvited,
  },
  {
    text: "User Permissions",
    value: userPermissions,
  },
  {
    text: "User Removed",
    value: userRemoved,
  },
  {
    text: "Well Added",
    value: wellAdded,
  },
  {
    text: "Tag(s) Added",
    value: tagsAdded,
  },
  {
    text: "Tag(s) Removed",
    value: tagsRemoved,
  },
];
