import React from "react";
import Calendar from "mdi-material-ui/CalendarMonthOutline";
import Restart from "mdi-material-ui/Restart";
import withStyles from "@material-ui/core/styles/withStyles";

import Tooltip from "components/material/tooltip";

const styles = ({colors}) => ({
  dateWrapper: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
    display: "inline-block",
  },
  date: {
    color: colors.warmGrey[600],
    display: "inline-block",
    fontSize: 13,
    verticalAlign: "middle",
    padding: "7px 15px",
  },
  icon: {
    background: "rgba(0, 0, 0, 0.08)",
    borderRight: "1px solid rgba(0, 0, 0, 0.23)",
    color: colors.warmGrey[600],
    fontSize: 15,
    padding: "7px 15px",
    verticalAlign: "middle",
  },
  reset: {
    borderLeft: "1px solid rgba(0, 0, 0, 0.23)",
    color: colors.warmGrey[600],
    cursor: "pointer",
    display: "inline-block",
    verticalAlign: "middle",
  },
  resetIcon: {
    fontSize: "1.25em",
    padding: "5px 5px 0",
  },
  wrapper: {
    textAlign: "right",
  },
});

const DateRange = ({classes, from, isFullRange = true, resetDateRange, to}) => {
  return (
    <div className={classes.wrapper}>
      <Tooltip title="Current Date Range" enterDelay={500}>
        <span className={classes.dateWrapper}>
          <Calendar className={classes.icon} />
          <span className={classes.date}>
            <b>
              {from} &mdash; {to}
            </b>
          </span>
          {!isFullRange && (
            <span className={classes.reset} onClick={resetDateRange}>
              <Restart className={classes.resetIcon} />
            </span>
          )}
        </span>
      </Tooltip>
    </div>
  );
};

export default withStyles(styles)(DateRange);
