import {types} from "actions/organizations";
import resetOnSignout from "utils/reducers/reset-on-signout";

/**
 * All wells for an organization.
 * This is mainly for organization management.
 * The Wells reducer should probably be used for anything else.
 */
const wells = (
  state = {
    perOrganization: {},
  },
  action,
) => {
  if (action.type === types.setOrganizationWells) {
    const newState = {
      ...state,
      perOrganization: {
        ...state.perOrganization,
        ...{
          [action.payload.organizationId]: action.payload.data.reduce(
            (allWells, well) => {
              allWells[well.downholeLocation] = {
                alias: well.alias,
                displayName: well.alias || well.downholeLocation,
                downholeLocation: well.downholeLocation,
                fieldName: well.fieldName,
                tags: well.tags,
                tagsText: well.tags ? well.tags.join(", ") : "",
                wellId: well.wellId,
              };

              return allWells;
            },
            {},
          ),
        },
      },
    };

    return newState;
  }

  return state;
};

export default resetOnSignout(wells);
