import compose from "lodash/fp/compose";
import filter from "lodash/fp/filter";
import groupBy from "lodash/fp/groupBy";
import isNil from "lodash/isNil";
import map from "lodash/fp/map";
import mathjs from "mathjs";
import moment from "moment";
import reduce from "lodash/fp/reduce";
import {replace} from "react-router-redux";
import times from "lodash/times";
import toPairs from "lodash/fp/toPairs";

import {createMergeEpic} from "epics/create-epic";
import fetch from "epics/async-fetch";
import getUser from "utils/get-user";
import {pressure} from "@ambyint/common/unit-converter";
import round from "utils/round";
import {setCompressiveStress, types} from "actions/wells/well-health";
import unitTypes from "utils/unit-types";

const convertStress = stress => {
  if (stress === null) return null;

  // Convert the stress from pascals to kiloPascals (if metric) or psi (if imperial)
  const convertedStress = pressure.convert({
    value: stress,
    to: getUser().unitsOfMeasure,
    from: "pa",
  });

  // In either metric or imperial, we want to divide by 1000 to get ot MPa or kPsi
  return convertedStress / 1000;
};

const generateSeries = key =>
  compose(
    filter(p => p.y !== null),
    map(event => ({
      x: moment(event.createdOn)
        .utc()
        .format("YYYY-MM-DD"),
      y: event[key],
    })),
  );
const generateStressSeries = generateSeries("minStress");
const generateCompressedRodsSeries = generateSeries("rodsInCompression");

const generateMedianSeries = (
  start,
  end,
  events,
  sourceKey,
  targetKey,
  rounding,
) => {
  const mStart = moment(start)
    .utc()
    .startOf("day");

  const mEnd = moment(end)
    .utc()
    .startOf("day");

  const processEvents = compose(
    map(day => ({
      x: day.dateKey,
      y: round(day[targetKey], rounding),
    })),
    reduce((accumulator, [dateKey, events]) => {
      // Be sure that accumulator[dateKey] for these events exists before trying to handle them.
      // It should, but in some cases, events for a day outside the expected range have been returned.
      if (accumulator[dateKey]) {
        const values = events
          .filter(e => e[sourceKey] !== null)
          .map(e => e[sourceKey]);
        if (values.length > 0) {
          accumulator[dateKey][targetKey] = mathjs.median(values);
        }
      }
      return accumulator;
    }, getEmptyResult(mStart, mEnd)),
    toPairs,
    groupBy(event =>
      moment(event.createdOn)
        .utc()
        .startOf("day")
        .format("YYYY-MM-DD"),
    ),
  );

  return processEvents(events);
};

const getEmptyResult = (startDate, endDate) => {
  const result = {};

  times(endDate.diff(startDate, "days"), i => {
    const dateKey = startDate
      .clone()
      .add(i, "day")
      .format("YYYY-MM-DD");

    result[dateKey] = {
      medianStress: null,
      medianRodsInCompression: null,
      dateKey,
    };
  });

  return result;
};

const normalize = (obj, key) => (isNil(obj[key]) ? null : obj[key]);

const processEvents = events =>
  events.map(event => {
    // Select keys and insert nulls for any missing keys
    const processedEvent = {
      createdOn: event.createdOn,
      minStress: normalize(event.data, "minStress"),
      rodsInCompression: normalize(event.data, "rodsInCompression"),
      minStressRodNumber: normalize(event.data, "minStressRodNumber"),
    };

    if (processedEvent.minStress !== null) {
      // Invert the stress b/c we are graphing the amount in terms of positive compression instead of negative tension.
      processedEvent.minStress = convertStress(processedEvent.minStress * -1);
      processedEvent.minStress = Math.max(processedEvent.minStress, 0);
    }

    return processedEvent;
  });

export const fetchCompressiveStress = createMergeEpic(
  [types.fetchCompressiveStress],
  async ({payload}) => {
    const {wellId, downholeLocation, start, end} = payload;
    try {
      const stresses = await fetch(
        `/wells/${encodeURIComponent(downholeLocation)}/events`,
        {
          downholeLocation,
          wellId,
          start,
          end,
          types: "rodStringStressAnalysis",
        },
      );

      // Process the incoming events into a more consistent and flattened data set.
      // This will pick keys and insert nulls for any keys in the event that are missing.
      const processedStresses = processEvents(stresses);

      // If the units are imperial, we want to round stresses to 2 decimal places.
      // If the units are metric, we want to round to whole integers.
      const rounding =
        getUser().unitsOfMeasure.toLowerCase() === unitTypes.imperial ? 2 : 0;

      const stressSeries = generateStressSeries(processedStresses);
      const medianStressSeries = generateMedianSeries(
        start,
        end,
        processedStresses,
        "minStress",
        "medianStress",
        rounding,
      );

      const compressedRodsSeries = generateCompressedRodsSeries(
        processedStresses,
      );
      const medianCompressedRodsSeries = generateMedianSeries(
        start,
        end,
        processedStresses,
        "rodsInCompression",
        "medianRodsInCompression",
        0,
      );

      return [
        setCompressiveStress(
          stressSeries,
          medianStressSeries,
          compressedRodsSeries,
          medianCompressedRodsSeries,
        ),
      ];
    } catch (err) {
      return [replace(`/error?code=${err.status}`)];
    }
  },
);
