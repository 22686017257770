import {types} from "./types";

export const setTimePeriod = timePeriod => {
  return {
    type: types.setTimePeriod,
    payload: {
      timePeriod,
    },
  };
};
