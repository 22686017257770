export const types = {
  hide: "well-assignment-list-dialog:hide",
  show: "well-assignment-list-dialog:show",
};

export const hide = () => {
  return {
    type: types.hide,
  };
};

export const show = options => {
  return {
    type: types.show,
    payload: {
      ...options,
    },
  };
};
