import React from "react";
import {connect} from "react-redux";

import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import colors from "theme/colors";
import {FormatDateTime} from "components/format/date";

import CheckIcon from "@material-ui/icons/Check";
import DisplayStatus from "components/alerts/display-status";
import SnoozeIcon from "@material-ui/icons/Snooze";

import {show as showAcknowledgeAlertDialog} from "actions/acknowledge-alert-dialog";
import {show as showResolveAlertDialog} from "actions/resolve-alert-dialog";

const MAX_ALERTS = 3;

const alertStyle = {
  borderTop: "1px solid rgb(224, 224, 224)",
  color: "rgba(0, 0, 0, 0.54)",
  fontSize: "14px",
  fontWeight: 300,
  height: "49px",
  padding: "0px 16px",
};

const titleStyle = {
  color: colors.grey[600],
  marginBottom: "10px",
  padding: "0px 16px",
  textTransform: "uppercase",
};

const renderAlert = ({dispatch}) => alert => {
  return (
    <Grid
      alignItems="center"
      container
      key={alert.alertId}
      spacing={0}
      style={alertStyle}
    >
      <Grid item xs={3}>
        <DisplayStatus status={alert.status} />
      </Grid>
      <Grid item xs={3}>
        {alert.typeLabel}
      </Grid>
      <Grid item xs={3}>
        <FormatDateTime date={alert.createdOn} />
      </Grid>
      <Grid
        alignItems={"center"}
        container
        item
        justify={"flex-end"}
        spacing={0}
        xs={3}
      >
        <Grid item>
          <IconButton
            onClick={() => dispatch(showResolveAlertDialog({alert}))}
            color={"primary"}
          >
            <CheckIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            onClick={() => dispatch(showAcknowledgeAlertDialog({alert}))}
            color={"primary"}
          >
            <SnoozeIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect()(props => {
  const {alerts} = props;

  if (!alerts || alerts.length <= 0) return null;

  return (
    <div>
      <Typography variant="caption" style={titleStyle}>
        Alerts
      </Typography>
      {alerts.slice(0, MAX_ALERTS).map(renderAlert(props))}
    </div>
  );
});
