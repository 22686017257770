import React from "react";
import InfoIcon from "@material-ui/icons/Info";
import ReactTooltip from "react-tooltip";
import {withStyles} from "@material-ui/core/styles";

const styleSheet = {
  text: {
    textAlign: "left !important",
  },
};

const Tooltip = withStyles(styleSheet, {
  name: "DialogOverrides",
})(props => (
  <div>
    <InfoIcon data-tip={props.text} style={{cursor: "pointer"}} />
    <ReactTooltip
      multiline
      place="top"
      effect="solid"
      className={props.classes.text}
    />
    <ReactTooltip
      multiline
      place="top"
      effect="solid"
      event="click"
      globalEventOff="click"
      className={props.classes.text}
    />
  </div>
));

export default Tooltip;
