export const setMappings = ({state, action}) => {
  const type = action.payload.type;
  const mappings = action.payload.mappings;

  return {
    ...state,
    [type]: {
      ...state[type],
      mappings,
    },
  };
};
