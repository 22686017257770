import React from "react";
import Typography from "@material-ui/core/Typography/Typography";
import Divider from "@material-ui/core/Divider/Divider";

import Setpoint from "./setpoint";

export default ({classes, ...props}) => {
  const {action, actionTwo, setPointOne, setPointTwo, setPointThree} = props;
  return (
    <div onClick={props.redirect} className={classes.body}>
      <div className={classes.spaceBetween}>
        <div className={classes.flexStart}>
          <Setpoint classes={classes} data={setPointOne} />
          {setPointTwo && <Setpoint classes={classes} data={setPointTwo} />}
        </div>
        {setPointThree && <Setpoint classes={classes} data={setPointThree} />}
      </div>

      <Divider className={classes.divider} />
      <div className={classes.flexStart}>
        <div className={classes.unitContainer}>
          <Typography variant="caption" classes={{caption: classes.unitName}}>
            {action[0]}
          </Typography>
          <div className={classes.flexStart}>
            <Typography variant="h6" classes={{h6: classes.grey}}>
              {action[1]}
              <span className="unit">{action[2]}</span>
            </Typography>
          </div>
        </div>
        {actionTwo && (
          <div className={classes.unitContainer}>
            <Typography variant="caption" classes={{caption: classes.unitName}}>
              {actionTwo[0]}
            </Typography>
            <Typography variant="h6" classes={{h6: classes.grey}}>
              {actionTwo[1]}
              <span className="unit">{props.actionTwo[2]}</span>
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};
