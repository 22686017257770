import {show} from "actions/snackbar";
import {types} from "actions/wells";
import fetch from "epics/async-fetch";

export default action$ => {
  return action$
    .ofType(types.acceptRecommendation)
    .switchMap(async ({payload}) => {
      const {acceptedData, comment, recommendationId} = payload;

      await fetch(
        `/recommendation/${recommendationId}/accept`,
        {},
        {
          method: "POST",
          body: {
            comment,
            acceptedData,
          },
        },
      );

      return show({
        message: "The recommendation has been successfully accepted!",
      });
    });
};
