export const mappingTypes = {
  CASING_PRESSURE: "casingPressure",
  CREATED_ON: "createdOn",
  DOWNHOLE_LOCATION: "downholeLocation",
  GAS_PRODUCTION: "gasProduction",
  IGNORE: "ignore",
  OIL_PRODUCTION: "oilProduction",
  SPM: "spm",
  TUBING_PRESSURE: "tubingPressure",
  WATER_PRODUCTION: "waterProduction",
};
