import React from "react";
import {connect} from "react-redux";
import compose from "lodash/fp/compose";
import eq from "lodash/fp/eq";
import withStyles from "@material-ui/core/styles/withStyles";

import ButtonGroup from "components/button-group";
import ButtonSegmented from "components/button-segmented";
import {setWellboreView} from "actions/wells/well-health";
import {VIEW, LABELS} from "constants/well-health";

const isWellbore = eq(VIEW.WELLBORE);
const isEquipment = eq(VIEW.EQUIPMENT);
const isDogleg = eq(VIEW.DOGLEG);

const styles = ({spacing}) => ({
  main: {
    display: "flex",
    justifyContent: "center",
    marginTop: spacing(4),
  },
});

const mapStateToProps = ({wellHealth}) => ({view: wellHealth.wellbore.view});
const mapDispatchToProps = dispatch => ({
  setWellboreView: view => () => dispatch(setWellboreView(view)),
});

const WellboreViewControl = ({classes, className, setWellboreView, view}) => {
  return (
    <ButtonGroup className={classes.main}>
      <ButtonSegmented
        isActive={isWellbore(view)}
        onClick={setWellboreView(VIEW.WELLBORE)}
      >
        {LABELS.WELLBORE}
      </ButtonSegmented>
      <ButtonSegmented
        isActive={isEquipment(view)}
        onClick={setWellboreView(VIEW.EQUIPMENT)}
      >
        {LABELS.EQUIPMENT}
      </ButtonSegmented>
      <ButtonSegmented
        isActive={isDogleg(view)}
        onClick={setWellboreView(VIEW.DOGLEG)}
      >
        {LABELS.DOGLEG_SEVERITY}
      </ButtonSegmented>
    </ButtonGroup>
  );
};

const enhance = compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(WellboreViewControl);
