import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import clsx from "clsx";

import Dynocard from "components/dynocard";

import {useCardData} from "./use-card-data";
import HoverDynocard from "./hover-dynocard";

const styles = () => ({
  container: {
    display: "grid",
  },
  overlayChild: {
    gridColumn: 1,
    gridRow: 1,
    transition: "all .2s ease-in-out",
  },
  onTop: {
    zIndex: 2,
  },
});

const WellMiniCard = ({
  classes,
  well,
  isAnomaly,
  lastRegularCard,
  isHovering,
  card,
  fetchMiniCardById,
}) => {
  const shouldRenderHoverCard = isAnomaly && !!lastRegularCard;

  const {surfaceData, downholeData} = useCardData(card);

  return (
    <div className={classes.container}>
      <div className={clsx(classes.overlayChild, classes.onTop)}>
        <Dynocard
          surfaceData={surfaceData}
          downholeData={downholeData}
          hideAxis
        />
      </div>
      {shouldRenderHoverCard && (
        <HoverDynocard
          className={classes.overlayChild}
          isHovering={isHovering}
          lastRegularCard={lastRegularCard}
          fetchMiniCardById={fetchMiniCardById}
          well={well}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(WellMiniCard);
