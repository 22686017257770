import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Grid from "@material-ui/core/Grid";
import compose from "lodash/fp/compose";
import {withRouter} from "react-router";

const enhance = compose(withRouter);

const SubheaderTabs = ({children, getCurrentTab, location}) => {
  // If getCurrentTab is defined, pass the location to it to find the current tab from URI
  // otherwise the current tab is assumed to be the entire pathname
  const value =
    getCurrentTab && typeof getCurrentTab === "function"
      ? getCurrentTab(location)
      : location.pathname;

  return (
    <Grid item>
      <Tabs value={value}>{children}</Tabs>
    </Grid>
  );
};

export default enhance(SubheaderTabs);
