import moment from "moment";

export const getVersion = () => {
  const version = process.env.REACT_APP_VERSION;
  const stamp = process.env.REACT_APP_COMMIT_STAMP;

  let date = process.env.REACT_APP_COMMIT_DATE;
  date = moment(date).format("YYYYMMDDHHmmss");

  return `v${version}-${date}-${stamp}`;
};
