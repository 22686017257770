import isNil from "lodash/isNil";
import moment from "moment";
import uniqBy from "lodash/uniqBy";
import sortBy from "lodash/sortBy";

import {types} from "actions/on-call";

import resetOnSignout from "utils/reducers/reset-on-signout";

const getCurrentSchedule = ({schedule}) => {
  if (!schedule) return null;
  return schedule.find(p =>
    moment().isBetween(
      p.calloutSchedulePeriodStartDate,
      p.calloutSchedulePeriodEndDate,
    ),
  );
};

const mergeSchedule = (existing = [], newSchedule = []) => {
  return sortBy(
    uniqBy(
      [...(existing || []), ...(newSchedule || [])],
      c => c.calloutSchedulePeriodId,
    ),
    "calloutSchedulePeriodStartDate",
  );
};

const onCall = (
  state = {
    current: null,
    isFetching: true,
    isOnCall: false,
    schedule: [],
  },
  action,
) => {
  if (action.type === types.get) {
    return {
      ...state,
      isFetching: true,
    };
  }

  if (action.type === types.set) {
    const current = getCurrentSchedule({schedule: action.payload.schedule});
    return {
      ...state,
      current,
      isFetching: false,
      isOnCall: !isNil(current),
      schedule: mergeSchedule(state.schedule, action.payload.schedule),
    };
  }

  if (action.type === types.unschedule) {
    return {
      ...state,
      current: null,
      isFetching: false,
      isOnCall: false,
      schedule: [],
    };
  }

  return state;
};

export default resetOnSignout(onCall);
