import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import MainWorkflow from "./main-workflow";

const styles = theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  buttonGroup: {
    boxShadow: theme.shadows[1],
    marginBottom: 16,
    marginRight: 8,
    minWidth: "fit-content",
    height: 32,
    "& > button": {
      height: 32,
      padding: [[3, 12]],
    },
  },
  button: {
    padding: "0 5px 0 0",
    borderRadius: 2,
    backgroundColor: "rgb(255, 255, 255)",
    display: "flex",
    alignItems: "center",
    color: "rgba(0, 0, 0, 0.38)",
    height: 32,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.12)",
    },
  },
});

const WorkflowFilter = ({classes, onToggleTags}) => {
  return (
    <div className={classes.root}>
      <span>
        <MainWorkflow classes={classes} />
      </span>
    </div>
  );
};

export default withStyles(styles)(WorkflowFilter);
