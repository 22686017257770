import {of} from "rxjs/observable/of";

import {setInviteDetails, types} from "actions/auth";
import addAuthHeaders from "epics/add-auth-headers";
import fetchApi from "epics/fetch";

export default action$ => {
  return action$.ofType(types.getInviteDetails).switchMap(({payload}) => {
    const {inviteKey} = payload;

    return fetchApi(
      `/client-experience/user/invite/${encodeURIComponent(inviteKey)}`,
      {},
      addAuthHeaders({
        method: "GET",
      }),
    )
      .flatMap(res => {
        if (res.status >= 200 && res.status <= 300) {
          return of(
            setInviteDetails({
              availableUserTypes: res.response.availableUserTypes,
              isInvalidInviteKey: false,
              firstName: res.response.firstName,
              lastName: res.response.lastName,
              mobilePhone: res.response.mobilePhone,
              notificationMethods: res.response.notificationMethods,
              type: res.response.type,
              unitsOfMeasure: res.response.unitsOfMeasure,
              userLogin: res.response.userLogin,
            }),
          );
        }

        return of(
          setInviteDetails({
            isInvalidInviteKey: true,
          }),
        );
      })
      .catch(() => {
        return of(
          setInviteDetails({
            isInvalidInviteKey: true,
          }),
        );
      });
  });
};
