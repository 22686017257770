import {parse} from "querystring";

import determineActions from "./determine-actions";
import determineFilter from "./determine-filter";
import determineSource from "./determine-source";

export default async ({pathname, search, state}) => {
  const existingQuerystring = {
    filter: state.paging.filter,
    source: state.paging.source,
  };
  const newQuerystring = parse((search || "").replace(/\?/, ""));
  const {hasInitialized} = state.paging;

  const actions = await Promise.resolve({
    existingQuerystring,
    hasInitialized,
    newQuerystring,
    pathname,
    state,
  })
    .then(determineSource)
    .then(determineFilter)
    .then(determineActions);

  return actions;
};
