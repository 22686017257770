import React from "react";
import {Field} from "redux-form";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

import FilterableSelect from "components/filterable-select";

import formatLabel from "./format-label";

export const ListMenu = props => {
  const hasError =
    (!!props.meta.dirty || !!props.meta.submitFailed) && !!props.meta.error;

  return (
    <FormControl
      fullWidth={props.fullWidth}
      style={props.style}
      error={hasError}
    >
      <FilterableSelect
        id={props.id}
        value={props.input.value}
        label={formatLabel(props)}
        items={props.items}
        disabled={props.disabled}
        mapLabel={props.mapLabel}
        mapValue={props.mapValue}
        onSelect={value => props.input.onChange(value)}
      />
      {hasError && <FormHelperText error>{props.meta.error}</FormHelperText>}
    </FormControl>
  );
};

export default props => <Field {...props} component={ListMenu} />;
