import React from "react";
import Typography from "@material-ui/core/Typography";

import getDisplayName from "utils/user/get-display-name";

import {DEVICE_ASSIGNED} from "constants/event-types";
import getPrivileges from "utils/get-privileges";
import privilegeTypes from "utils/privilege-types";
import FeedItem from "../feed-item";
import {clickableStyle} from "../styles";

export default props => {
  const {event, well} = props;

  const privileges = getPrivileges(well.organization.id);
  const hasAdmin = privileges.includes(privilegeTypes.sysAdmin);

  const serialNumber =
    event.type === DEVICE_ASSIGNED
      ? event.value.device.serialNumber
      : event.value.serialNumber;

  return (
    <FeedItem event={event}>
      <strong>{getDisplayName(event)}</strong>
      {` ${event.type === DEVICE_ASSIGNED ? "assigned" : "removed"} device `}
      {hasAdmin ? (
        <Typography
          variant="body2"
          component="span"
          onClick={() =>
            window.open(
              `${process.env.REACT_APP_ADMIN}/#/devices/${serialNumber}`,
            )
          }
          style={{
            ...clickableStyle,
            display: "inline-block",
          }}
        >
          {serialNumber}
        </Typography>
      ) : (
        <strong>{serialNumber}</strong>
      )}
      {` ${event.type === DEVICE_ASSIGNED ? "to" : "from"} this well.`}
    </FeedItem>
  );
};
