import confirmation from "./confirmation";
import country from "./country";
import displayName from "./display-name";
import location from "./location";
import unitType from "./unit-type";
import wellIdentification from "./well-identification/well-identification";

export default [
  country,
  wellIdentification,
  displayName,
  location,
  unitType,
  confirmation,
];
