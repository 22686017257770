import {show} from "actions/snackbar";
import {types} from "actions/wells";
import fetch from "epics/fetch";

export default action$ => {
  return action$.ofType(types.deleteGracePeriod).switchMap(({payload}) => {
    return fetch(
      `/wells/${encodeURIComponent(
        payload.well.downholeLocation,
      )}/grace-period/unset`,
      {
        downholeLocation: payload.well.downholeLocation,
      },
      {
        body: payload.data,
        method: "PUT",
      },
    ).flatMap(() => [
      show({
        message: "You have successfully deleted the alert grace period.",
      }),
    ]);
  });
};
