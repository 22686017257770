import {prefillForm} from "actions/well-tickets";
import Grid from "@material-ui/core/Grid";
import React from "react";
import {Form} from "react-redux-form";
import store from "store";
import getForm from "utils/react-redux-form/get-form";

import formPath from "../form-path";
import handleBack from "../handle-back";
import handleLabel from "../handle-label";
import handleNext from "../handle-next";
import InputDownholeLocation from "./input-downhole-location";
import validate from "./validate";

const content = (
  <Form model={formPath} onChange={formModel => validate({bypassExists: true})}>
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12}>
        <InputDownholeLocation />
      </Grid>
    </Grid>
  </Form>
);

const onSuccess = goToNextStep => () => {
  const well = getForm(formPath).currentValues;

  const {lastDownholeLocation} = store.getState().wellTickets;

  if (lastDownholeLocation !== well.downholeLocation) {
    store.dispatch(
      prefillForm({
        form: formPath,
        well,
      }),
    );
  }

  goToNextStep();
};

const onNext = goToNextStep => handleNext(validate)(onSuccess(goToNextStep));

export default {
  content,
  handleBack,
  handleLabel,
  handleNext: onNext,
  label: "Well Identification",
};
