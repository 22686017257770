import React from "react";
import {connect} from "react-redux";

import colors from "theme/colors";

const onCallStatusStyle = {
  background: colors.onCall[500],
  borderRadius: "2em",
  width: 8,
  height: 8,
  display: "inline-block",
  margin: "3px 6px",
};

const offCallStatusStyle = {
  background: colors.offCall[500],
  borderRadius: "2em",
  width: 9,
  height: 9,
  display: "inline-block",
  margin: "3px 6px",
};

const mapStateToProps = state => {
  return {
    onCall: state.onCall,
  };
};

const OnCallStatus = ({onCall, children}) => {
  const style = onCall.isOnCall ? onCallStatusStyle : offCallStatusStyle;

  return <div style={style}>{children}</div>;
};

export default connect(mapStateToProps)(OnCallStatus);
