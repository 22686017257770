import React from "react";

import theme from "theme";
import colors from "theme/colors";

import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const baseStyle = {
  color: colors.ambyOrange[500],
  pointer: "cursor",
  transform: "rotate(0deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
};

class CollapseButton extends React.Component {
  state = {expanded: false};

  handleExpandClick = () => {
    this.setState({expanded: !this.state.expanded});
  };

  render() {
    const {onClick = () => {}} = this.props;

    return (
      <IconButton
        onClick={() => {
          this.handleExpandClick();
          onClick();
        }}
        style={{
          ...baseStyle,
          ...(this.state.expanded ? {transform: "rotate(180deg)"} : {}),
        }}
      >
        <ExpandMoreIcon />
      </IconButton>
    );
  }
}

export default CollapseButton;
