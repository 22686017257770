import React from "react";

import Grid from "@material-ui/core/Grid";

import {stringifyWellboreSurvey} from "@ambyint/common/parsers/well/parse-wellbore-survey";

import FormButton from "components/form-button";
import FormHeader from "components/form-header";
import PaperContainer from "components/paper-container";

import getPrivileges from "utils/get-privileges";
import privilegeTypes from "utils/privilege-types";

import WellboreInformationEditView from "./wellbore-information-edit-view";

import {cardLabelFactory, cardValueFactory} from "../cards";

class WellboreInformationReadView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {showWellboreInformationEditView: false};
  }

  render() {
    const {well} = this.props;
    const privileges = getPrivileges(well.organization.id);
    const wellbore = well.wellbore || {};

    const survey = wellbore.wellboreSurvey
      ? stringifyWellboreSurvey(wellbore.wellboreSurvey).split("\n")
      : [];

    if (this.state.showWellboreInformationEditView) {
      return (
        <WellboreInformationEditView
          onClose={() =>
            this.setState({showWellboreInformationEditView: false})
          }
          well={well}
        />
      );
    }

    const CardLabel = cardLabelFactory(7);
    const CardValue = cardValueFactory(5);

    const SurveyComponents = [];
    for (let i = 0; i < survey.length; i++) {
      SurveyComponents.push(
        <CardLabel
          key={`${i}-label`}
          label={i === 0 ? "Wellbore Survey" : ""}
        />,
      );
      SurveyComponents.push(<CardValue key={`${i}-value`} value={survey[i]} />);
    }

    return (
      <PaperContainer extraPadded style={{maxWidth: "500px"}}>
        <Grid container>
          <Grid item xs={12}>
            <FormHeader>Wellbore Information</FormHeader>
          </Grid>

          <CardLabel label={"Perforation Bottom Measure Depth"} />
          <CardValue value={wellbore.casingPerforationBottomMeasuredDepth} />

          <CardLabel label={"Perforation Top Measure Depth"} />
          <CardValue value={wellbore.casingPerforationTopMeasuredDepth} />

          <CardLabel label={"True Vertical Depth"} />
          <CardValue value={wellbore.trueVerticalDepth} />

          <CardLabel label={"Well Depth"} />
          <CardValue value={wellbore.wellDepth} />

          {SurveyComponents}

          {privileges.includes(privilegeTypes.well.configure) && (
            <Grid item xs={12}>
              <FormButton
                onClick={() =>
                  this.setState({showWellboreInformationEditView: true})
                }
                style={{marginTop: "8px"}}
              >
                Edit Wellbore Information
              </FormButton>
            </Grid>
          )}
        </Grid>
      </PaperContainer>
    );
  }
}

export default WellboreInformationReadView;
