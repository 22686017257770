import React, {memo} from "react";
import compose from "lodash/fp/compose";
import {connect} from "react-redux";
import isNil from "lodash/isNil";
import {
  XYPlot,
  MarkSeriesCanvas,
  LineSeriesCanvas,
  YAxis,
  ChartLabel,
} from "react-vis";

const mapStateToProps = ({wellHealth}) => ({
  days: wellHealth.date.days,
});

const enhance = compose(connect(mapStateToProps));

const CompressionGraphPlot = memo(
  ({
    width,
    height,
    series,
    medianSeries,
    seriesColor,
    medianSeriesColor,
    yLabel,
    domain,
    margin,
    days,
  }) => {
    // In order to draw correctly on a react-vis canvas, we have to split up the median series into a separate series
    // for each contiguous set of points. This is because the canvas version of the line-series is required for
    // performance but does not allow a getNull function like the SVG version. If you pass it a series that has
    // nulls in the middle, it will connect the points on either side of the nulls with a line which is not what
    // we want. We need it to show a gap where there is no data. This function takes the series and creates a
    // separate array for each group of contiguous data.
    // For example, given a series like [{x:1, y:null}, {x:2, y:10}, {x:3, y:15}, {x:4, y:null}, {x:5,y:7}],
    // this code will remove all the nulls and create two separate series arrays like:
    // [ [{x:2, y:10}, {x:3, y:15}], [{x:5,y:7}] ]
    const medianSeriesArray = medianSeries.reduce((seriesArray, p, i) => {
      if (!isNil(p.y)) {
        // If this is the first point or if the previous point was nil, start a new group
        if (i === 0 || isNil(medianSeries[i - 1].y)) seriesArray.push([]);

        // Add the point to the most recently created group
        seriesArray[seriesArray.length - 1].push({...p});
      }
      return seriesArray;
    }, []);

    return (
      // Very Important!
      // Setting key={width} makes react re-instantiate the component's DOM when the width changes.
      // Without this, on retina/high-DPI panels (Device Pixel Ratio > 1) the graph does not resize.
      // There is an outstanding bug in react-vis related to canvas series on retina monitors.
      // Using the key causes react to find that it can't re-use the existing DOM element and recreates it
      // at the new width. The result is slightly more laggy than i'd like but works. This could be
      // revisited if the react-vis bug is ever fixed.
      // This is also why this component uses XYPlot instead of FlexibleWidthXYPlot and why the parent
      // component is using SizeMe to calculate the width.
      <XYPlot
        key={width}
        height={height}
        width={width}
        margin={margin}
        yDomain={domain}
        xType="ordinal"
        xDomain={days}
        dontCheckIfEmpty={true}
      >
        <YAxis tickSizeInner={0} />

        <MarkSeriesCanvas
          data={series}
          size={1.5}
          color={seriesColor}
          colorType="literal"
          opacity={0.4}
          stroke="#FFF"
        />

        {medianSeriesArray.map((s, i) => (
          <LineSeriesCanvas
            key={i}
            data={s}
            color={medianSeriesColor}
            strokeWidth={2}
          />
        ))}

        <ChartLabel
          text={yLabel}
          xPercent={0}
          yPercent={0.62}
          includeMargin={false}
          style={{
            transform: "rotate(-90) translate(0,-50)",
            textAnchor: "middle",
          }}
        />
      </XYPlot>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.width === nextProps.width &&
      prevProps.height === nextProps.height
    );
  },
);

export default enhance(CompressionGraphPlot);
