import Helmet from "react-helmet";
import React, {Fragment} from "react";
import get from "lodash/get";
import {connect} from "react-redux";

const mapStateToProps = ({
  organizationView: {currentOrganization},
  auth: {user},
}) => ({
  user,
  organizationId: currentOrganization,
});

const ifString = subject =>
  typeof subject === "string" && subject.length ? ` | ${subject}` : "";

export default ({title = "Ambyint", meta = [], user, organizationId} = {}) => {
  return Component => {
    class withHelmet extends React.Component {
      constructor() {
        super();

        this.state = {
          title,
          meta,
        };

        this.setTitle = this.setTitle.bind(this);
        this.setMeta = this.setMeta.bind(this);
      }

      setTitle(title) {
        this.setState({title});
      }

      setMeta(meta) {
        this.setState({meta});
      }

      render() {
        const {title} = this.state;
        const {organizationId, user} = this.props;
        const orgs = get(user, "assignedOrganizations", []) || [];
        const org = orgs.find(o => o.organizationId === organizationId);
        const organizationName = get(org, "organizationName", "");
        const tabTitle = `Ambyint${ifString(title)}${ifString(
          organizationName,
        )}`;

        return (
          <Fragment>
            <Helmet defer={false}>
              <title>{tabTitle}</title>
              {this.state.meta.length &&
                this.state.meta.map(metaItem => {
                  if (metaItem.name && metaItem.content) {
                    return (
                      <meta name={metaItem.name} content={metaItem.content} />
                    );
                  }

                  return null;
                })}
            </Helmet>

            <Component
              {...this.props}
              setTitle={this.setTitle}
              setMeta={this.setMeta}
            />
          </Fragment>
        );
      }
    }

    return connect(mapStateToProps)(withHelmet);
  };
};
