import {get} from "lodash";

const countMatches = (collection, compareFn) =>
  collection.filter(compareFn).length;

const countMatchingWells = (tag, allWells, byWellId) => {
  if (!Array.isArray(allWells)) return 0;

  let matches = 0;
  for (let i = 0; i < allWells.length; i++) {
    const well = get(byWellId, allWells[i]);
    const tags = get(well, "tags", []);
    matches += countMatches(tags, ({name}) => name === tag.name) || 0;
  }

  return matches;
};

export const normalizeTag = (tag, allWells, byWellId) => ({
  className: "",
  clicked: false,
  disabled: false,
  matches: countMatchingWells(tag, allWells, byWellId) || 0,
  name: "non upgraded tag(s)",
  ...tag,
});
