import React, {Fragment} from "react";

import flags from "constants/feature-flags";
import withFeatureFlag from "components/with-feature-flag";

import InboxNav from "./inbox-nav";
import AlertNav from "./alert-nav";

const LegacyNavigation = () => (
  <Fragment>
    <InboxNav />
    <AlertNav />
  </Fragment>
);

export default withFeatureFlag(flags.legacyNavigation)(LegacyNavigation);
