import React from "react";

import WellCardsList from "./well-cards-list";
import WellStatus from "../well-status";

const WellCards = ({wells}) => (
  <WellStatus wells={wells} WellListComponent={WellCardsList} />
);

export default WellCards;
