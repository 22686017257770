import React from "react";
import Card from "@material-ui/core/Card/Card";
import clsx from "clsx";

import Body from "./body";
import Footer from "./footer";
import Header from "./header";

const colorMapping = {
  Alignment: "alignmentColors",
  Overpumping: "classificationColors",
  Underpumping: "classificationColors",
};

const RecommendationBase = ({classes, ...props}) => {
  const isOpen = props.status === "Open";
  const primaryColor = classes[colorMapping[props.title]];

  return (
    <Card
      className={clsx(
        classes.rec,
        props.moveRec && classes.moveRec,
        primaryColor,
      )}
    >
      <Header {...props} isOpen={isOpen} classes={classes} />
      <Body {...props} classes={classes} />
      <Footer {...props} isOpen={isOpen} classes={classes} />
    </Card>
  );
};

export default RecommendationBase;
