const setWellboreSeries = ({state, action}) => {
  const {ready, surveySeries, rodSeries, plungerSeries} = action.payload;

  return {
    ...state,
    wellbore: {
      ...state.wellbore,
      ready,
      surveySeries,
      rodSeries,
      plungerSeries,
    },
  };
};

export default setWellboreSeries;
