import {types} from "actions/dialogs";
import hideClearErrors from "./hide-clear-errors";
import hideResetFault from "./hide-reset-fault";
import hideResetMalfunctions from "./hide-reset-malfunctions";
import hideSetDowntime from "./hide-set-downtime";
import hideSetSpeed from "./hide-set-speed";
import hideSetSpeedRange from "./hide-set-speed-range";
import hideStartWell from "./hide-start-well";
import hideStopWell from "./hide-stop-well";
import showClearErrors from "./show-clear-errors";
import showResetFault from "./show-reset-fault";
import showResetMalfunctions from "./show-reset-malfunctions";
import showSetDowntime from "./show-set-downtime";
import showSetSpeed from "./show-set-speed";
import showSetSpeedRange from "./show-set-speed-range";
import showStartWell from "./show-start-well";
import showStopWell from "./show-stop-well";

const defaults = {
  clearErrors: {isOpen: false},
  resetFault: {isOpen: false},
  resetMalfunctions: {isOpen: false},
  setDowntime: {isOpen: false},
  setSpeed: {isOpen: false},
  setSpeedRange: {isOpen: false},
  startWell: {isOpen: false},
  stopWell: {isOpen: false},
};

const actionMap = {
  [types.hideClearErrors]: hideClearErrors,
  [types.hideResetFault]: hideResetFault,
  [types.hideResetMalfunctions]: hideResetMalfunctions,
  [types.hideSetDowntime]: hideSetDowntime,
  [types.hideSetSpeed]: hideSetSpeed,
  [types.hideSetSpeedRange]: hideSetSpeedRange,
  [types.hideStartWell]: hideStartWell,
  [types.hideStopWell]: hideStopWell,
  [types.showClearErrors]: showClearErrors,
  [types.showResetFault]: showResetFault,
  [types.showResetMalfunctions]: showResetMalfunctions,
  [types.showSetDowntime]: showSetDowntime,
  [types.showSetSpeed]: showSetSpeed,
  [types.showSetSpeedRange]: showSetSpeedRange,
  [types.showStartWell]: showStartWell,
  [types.showStopWell]: showStopWell,
};

export default (state = defaults, action) => {
  const doAction = actionMap[action.type];
  return doAction ? doAction({state, action}) : state;
};
