import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

import {
  LOW_LOAD,
  HIGH_LOAD,
  IDEAL,
  DTV,
  HOLE_IN_BARREL,
  LEAKING,
  PUMP_OFF,
  GAS_INTERFERENCE,
} from "constants/anomalies";

import OverrideIconCard from "../override-icon-card";

const useStyles = makeStyles(({spacing, breakpoints}) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 165px)",
    gridTemplateRows: "repeat(3, 170px)",
    gridColumnGap: spacing(2),
    gridRowGap: spacing(2),
    padding: spacing(4, 4, 8, 0),
    gridAutoFlow: "row dense",
    [breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(2, 150px)",
      gridTemplateRows: "repeat(5, 130px)",
      gridColumnGap: spacing(1),
      gridRowGap: spacing(1),
      padding: spacing(2, 4, 2, 0),
      direction: "rtl",
      "& > *": {
        direction: "ltr",
      },
    },
  },
  double: {
    gridColumn: "auto/span 2",
  },
}));

const OverrideOptions = ({onClick, overrideType}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <OverrideIconCard
        variant={LOW_LOAD}
        onClick={onClick}
        overrideType={overrideType}
      />
      <div className={classes.double}>
        <OverrideIconCard
          variant={HIGH_LOAD}
          onClick={onClick}
          overrideType={overrideType}
        />
      </div>
      <OverrideIconCard
        variant={DTV}
        onClick={onClick}
        overrideType={overrideType}
      />
      <OverrideIconCard
        variant={LEAKING}
        onClick={onClick}
        overrideType={overrideType}
      />
      <OverrideIconCard
        variant={HOLE_IN_BARREL}
        onClick={onClick}
        overrideType={overrideType}
      />
      <OverrideIconCard
        variant={PUMP_OFF}
        onClick={onClick}
        overrideType={overrideType}
      />
      <OverrideIconCard
        variant={GAS_INTERFERENCE}
        onClick={onClick}
        overrideType={overrideType}
      />
      <OverrideIconCard
        variant={IDEAL}
        onClick={onClick}
        overrideType={overrideType}
      />
    </div>
  );
};

export default OverrideOptions;
