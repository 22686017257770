import compose from "lodash/fp/compose";
import filter from "lodash/fp/filter";
import get from "lodash/fp/get";
import isMatch from "lodash/fp/isMatch";

import {VFD_DOWNHOLE} from "constants/pumpoff-setting-modes";

export default filter(
  compose(
    isMatch(VFD_DOWNHOLE),
    get("pumpOffSetting.mode.value"),
  ),
);
