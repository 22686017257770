import isNil from "lodash/isNil";
import spreadIf from "utils/spread-if";
import unitsOf from "utils/units-of";
import valueOf from "utils/value-of";
import withUnits from "utils/with-units";

const returnForAllProperties = valueToReturn =>
  new Proxy(
    {},
    {
      get: (target, name) => {
        return valueToReturn;
      },
    },
  );

const convertTo = (units, unitsOfMeasure) => (
  converter,
  {decimals = undefined, from, to} = {},
) => (value, override = {}) => {
  const unitsToUse =
    typeof override.to === "string" || typeof to === "string"
      ? returnForAllProperties(override.to || to)
      : units;

  const convertedValue = converter({
    value: valueOf(value),
    ...spreadIf(unitsOf(value) || override.from || from, {
      from: unitsOf(value) || override.from || from,
    }),
    decimals: isNil(override.decimals) ? decimals : override.decimals,
    to: unitsToUse,
    toUnitsOfMeasure: unitsOfMeasure,
    units: unitsToUse,
    unitsOfMeasure,
  });

  if (valueOf(value) === null || valueOf(value) === undefined) {
    return withUnits(null, convertedValue.units);
  }

  return convertedValue;
};

export default convertTo;
