export const types = {
  hide: "snackbar:hide",
  show: "snackbar:show",
};

export const hide = () => {
  return {
    type: types.hide,
    payload: {
      isOpen: false,
    },
  };
};

export const show = ({
  button = null,
  duration = 3000,
  horizontal = "right",
  message = "",
  vertical = "bottom",
}) => {
  return {
    type: types.show,
    payload: {
      button,
      duration,
      horizontal,
      message,
      vertical,
      isOpen: true,
    },
  };
};
