import IconButton from "@material-ui/core/IconButton";
import map from "lodash/map";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreIcon from "@material-ui/icons/MoreVert";
import React from "react";

import {names as presetNames} from "constants/analysis-presets";

class Component extends React.Component {
  state = {
    anchorEl: undefined,
    open: false,
  };

  close = () => {
    this.setState({open: false});
  };

  handleItemClick = name => {
    const {onChange = () => {}} = this.props;

    return () => {
      onChange(name);
      return this.close();
    };
  };

  open = event => {
    this.setState({open: true, anchorEl: event.currentTarget});
  };

  render() {
    return (
      <div>
        <IconButton
          aria-owns="analysis-preset-menu"
          aria-haspopup="true"
          onClick={this.open}
        >
          <MoreIcon />
        </IconButton>
        <Menu
          id="analysis-preset-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.close}
        >
          <MenuItem key="placeholder" style={{display: "none"}} />
          {map(presetNames, item => {
            return (
              <MenuItem key={item} onClick={this.handleItemClick(item)}>
                {item}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    );
  }
}

export default Component;
