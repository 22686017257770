import Aux from "components/aux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";

export default props => {
  const {label, value} = props;

  return (
    <Aux>
      <Grid item xs={4}>
        <Typography variant="body2" style={{fontWeight: "bold"}}>
          {label}:{" "}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="body2">{value || "--"}</Typography>
      </Grid>
    </Aux>
  );
};
