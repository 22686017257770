import React from "react";
import {connect} from "react-redux";

import isEmpty from "lodash/isEmpty";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import formatDateTime from "@ambyint/common/utils/format/format-date-time";

const mapStateToProps = state => {
  return {
    onCall: state.onCall,
    clientUserId: state.auth.user.clientUserId,
  };
};

const Status = props => {
  const {onCall} = props;

  if (isEmpty(onCall.schedule)) return null;

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant="h6">Existing Schedule</Typography>
        </Grid>
      </Grid>
      <List dense>
        {onCall.schedule.map(c => {
          return (
            <ListItem key={c.calloutSchedulePeriodId} style={{paddingLeft: 0}}>
              <ListItemText
                primary={`${formatDateTime(c.calloutSchedulePeriodStartDate, {
                  timezone: true,
                })} - ${formatDateTime(c.calloutSchedulePeriodEndDate, {
                  timezone: true,
                })}`}
              />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

export default connect(mapStateToProps)(Status);
