import {connect} from "react-redux";
import {push} from "react-router-redux";
import React from "react";
import debounce from "lodash/debounce";
import get from "lodash/get";
import uniqBy from "lodash/uniqBy";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import colors from "theme/colors";

import {getWells} from "utils/wells/get-wells";
import {show as showAlert} from "actions/alerts";
import {show as showWell, updateAnalysis, setActive} from "actions/wells";
import {workflows} from "constants/well-workflows";

import PAGING_SOURCES from "constants/paging-sources";

import styles from "./styles";

const mapStateToProps = (state, props) => {
  if (!props.downholeLocation) return {};

  const source = state.paging.source;

  let items;

  if (source === PAGING_SOURCES.alerts) {
    items = uniqBy(state.alerts.filtered, item => item.downholeLocation);
  } else {
    const {
      wells: {workflow},
    } = state;

    const itemsMap = {
      [workflows.ANOMALY]: getWells(workflow.anomaly.wellList),
      [workflows.DISPLAY_ALL]: getWells(workflow.displayAll.filtered),
      [workflows.TRIAGE]: getWells(workflow.triage.filtered),
      [workflows.CALIBRATE]: getWells(workflow.calibrate.filtered),
    };

    items = itemsMap[workflow.selected];
  }

  const currentIndex = items.findIndex(
    item => item.downholeLocation === props.downholeLocation,
  );

  return {
    analysis: state.wells.analysis,
    currentIndex,
    fetched: state.wells.fetched,
    source,
    items,
  };
};

class PagingButtons extends React.Component {
  state = {
    downholeLocation: null,
    items: null,
    currentIndex: null,
  };

  static getDerivedStateFromProps(nextProps, previousState) {
    const needsToInitialize =
      previousState.currentIndex === null &&
      nextProps.fetched &&
      nextProps.items;

    let currentIndex = nextProps.items
      ? nextProps.items.findIndex(
          item => item.downholeLocation === nextProps.downholeLocation,
        )
      : -1;

    if (currentIndex === -1) {
      currentIndex = null;
    }

    if (!needsToInitialize && previousState.lastIndex === currentIndex) {
      return null;
    }

    return {
      downholeLocation: nextProps.downholeLocation,
      items: nextProps.items,
      currentIndex,
      lastIndex: currentIndex === -1 ? null : currentIndex,
    };
  }

  getItem(index) {
    const {items} = this.props;

    if (!items) {
      return null;
    }

    return items[index];
  }

  getItemRelativeToIndex(indexOffset) {
    const {currentIndex, items} = this.state;

    if (!items) {
      return null;
    }

    const newIndex = currentIndex + indexOffset;

    return items[newIndex];
  }

  setActiveWell = downholeLocation => {
    this.props.dispatch(setActive({downholeLocation}));
  };

  performPaging = () => {
    const {source} = this.props;
    const {currentIndex} = this.state;

    const item = this.getItem(currentIndex);

    if (!item) {
      return;
    }

    this.selectItemInSourceDashboard(item, source);
    this.setActiveWell(item.downholeLocation);
    this.showComparisonsFor(item.downholeLocation);
    this.navigate(item.downholeLocation);
  };

  navigate = downholeLocation => {
    const {dispatch, location, match} = this.props;

    const currentPath = `${location.pathname.replace(match.url, "")}${
      location.search
    }`;

    dispatch(
      push(`/well/${encodeURIComponent(downholeLocation)}${currentPath}`),
    );
  };

  selectItemInSourceDashboard = (item, source) => {
    if (source === PAGING_SOURCES.alerts) {
      this.props.dispatch(
        showAlert({
          alertId: item.alertId,
        }),
      );
    } else {
      this.props.dispatch(
        showWell({
          downholeLocation: item.downholeLocation,
        }),
      );
    }
  };

  showComparisonsFor = downholeLocation => {
    const previousComparisonsForDownholeLocation = get(
      this.props.analysis,
      `[${downholeLocation}].comparisons`,
      [],
    );

    this.props.dispatch(
      updateAnalysis(downholeLocation, {
        comparisons: previousComparisonsForDownholeLocation,
      }),
    );
  };

  debouncePerformPaging = debounce(this.performPaging, 500, {
    leading: false,
    trailing: true,
  });

  navigateToPreviousWell = () => {
    this.setState(
      {
        currentIndex: Math.max(this.state.currentIndex - 1, 0),
      },
      () => this.debouncePerformPaging(),
    );
  };

  navigateToNextWell = () => {
    this.setState(
      {
        currentIndex: Math.min(
          this.state.currentIndex + 1,
          this.props.items.length - 1,
        ),
      },
      () => this.debouncePerformPaging(),
    );
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.currentIndex !== nextState.currentIndex ||
      this.state.downholeLocation !== nextState.downholeLocation ||
      !this.props.items ||
      this.props.items.length !== nextProps.items.length
    );
  }

  render() {
    const {downholeLocation, items} = this.props;

    const {currentIndex} = this.state;

    if (!downholeLocation || currentIndex === null || items.length === 0) {
      return null;
    }

    const totalCount = items.length;
    const previousItem = this.getItemRelativeToIndex(-1);
    const nextItem = this.getItemRelativeToIndex(1);

    const sourceSingular =
      this.props.source === PAGING_SOURCES.alerts ? "well with alert" : "well";

    const PreviousButton = () => (
      <IconButton
        title={
          previousItem
            ? `Previous ${sourceSingular} (${previousItem.downholeLocation})`
            : ""
        }
        disabled={currentIndex < 1}
        style={styles.previousButton}
        onClick={this.navigateToPreviousWell}
      >
        <KeyboardArrowLeft
          style={{
            color: currentIndex < 1 ? colors.grey[600] : colors.grey[200],
          }}
        />
      </IconButton>
    );

    const PagingText = () => (
      <div style={styles.positionText}>
        <span>{currentIndex + 1}</span>
        <span style={styles.small}> of </span>
        <span>{totalCount}</span>
      </div>
    );

    const NextButton = () => (
      <IconButton
        title={
          nextItem
            ? `Next ${sourceSingular} (${nextItem.downholeLocation})`
            : ""
        }
        disabled={currentIndex === items.length - 1}
        style={styles.nextButton}
        onClick={this.navigateToNextWell}
      >
        <KeyboardArrowRight
          style={{
            color:
              currentIndex === items.length - 1
                ? colors.grey[600]
                : colors.grey[200],
          }}
        />
      </IconButton>
    );

    return (
      <div style={styles.container}>
        <PagingText />
        <PreviousButton />
        <NextButton />
      </div>
    );
  }
}

export default connect(mapStateToProps)(PagingButtons);
