import React from "react";
import Typography from "@material-ui/core/Typography";

import FormButton from "components/form-button";
import PaperContainer from "components/paper-container";

import colors from "theme/colors";
import pluralize from "@ambyint/common/utils/format/format-pluralize";

const CreateServiceAccount = ({allServiceAccounts, dispatch, onClick}) => {
  return (
    <PaperContainer style={{padding: 4, marginBottom: 16}}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "baseline",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="body2"
            style={{
              color: colors.grey[500],
              margin: 16,
              maxWidth: 350,
              lineHeight: "17px",
            }}
          >
            Add service accounts to your organization. You can limit their
            access to specific groups of wells. Service accounts allow webhooks
            that can push data to external systems when events occur.
          </Typography>

          <Typography
            variant="body2"
            style={{
              color: colors.grey[600],
              fontWeight: "bold",
              margin: 16,
            }}
          >{`You currently have ${
            allServiceAccounts.length
          } service account${pluralize(allServiceAccounts)}.`}</Typography>
        </div>

        <div style={{display: "flex", justifyContent: "flex-end"}}>
          <FormButton
            variant={"contained"}
            style={{
              minHeight: "40px",
              paddingLeft: 48,
              paddingRight: 48,
              margin: 16,
            }}
            type="submit"
            onClick={onClick}
          >
            Create Service Account
          </FormButton>
        </div>
      </div>
    </PaperContainer>
  );
};

export default CreateServiceAccount;
