import React from "react";
import RootRef from "@material-ui/core/RootRef";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";

import TableCell from "components/table-cell";

import {TableHeaderCell, TableHeaderCheckbox, Table} from "../well-list-common";

export const renderWellTable = ({wellIds, isSelectionMode}) => (items, ref) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableHeaderCheckbox
          wellIds={wellIds}
          isSelectionMode={isSelectionMode}
        />
        <TableHeaderCell width="25%" label="Well Name" field="displayName" />
        <TableHeaderCell width="17%" label="Field" field="field" />
        <TableHeaderCell width="20%" label="Anomaly" field="anomaly" />
        <TableHeaderCell width="24%" label="Description" field="description" />
        <TableHeaderCell
          width="14%"
          label="Displacement"
          align="right"
          field="displacement"
          tooltip="m³/d"
        />
        <TableCell style={{width: "5%"}} />
      </TableRow>
    </TableHead>
    <RootRef rootRef={ref}>
      <TableBody>{items}</TableBody>
    </RootRef>
  </Table>
);
