import {createEpicMiddleware} from "redux-observable";
import {createStore, applyMiddleware, compose} from "redux";
import {persistStore} from "redux-persist";
import {routerMiddleware} from "react-router-redux";
import {createHashHistory} from "history";
import epics from "epics";
import isTrue from "utils/is-true";
import reducers from "reducers";
import thunk from "redux-thunk";

import {
  interruptRouteOnDirtyForm,
  removeExtraSlashesInRoutes,
  clearSearchComponentOnRoute,
} from "./middleware";
import logger from "./logger";

export const history = createHashHistory();

const initialState = {};
const enhancers = [];

const middleware = [
  ...(isTrue(process.env.REACT_APP_REDUX_LOGGER_ENABLED) ? [logger] : []),
  createEpicMiddleware(epics),
  thunk,
  interruptRouteOnDirtyForm,
  removeExtraSlashesInRoutes,
  routerMiddleware(history),
  clearSearchComponentOnRoute,
];

if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  // Put action types that have large payloads here, they will be scrubbed before being displayed in redux dev tools
  const blacklistedActionTypes = [
    "wells:set",
    "wells:parse-one",
    "wells:fetch-one",
    "wells:fetch-events",
    "wells:set-one",
    "wells:set-events",
    "alerts:set",
    "alerts:set-one",
    "alerts:parse-one",
    "alerts:fetch-one",
  ];

  const actionSanitizer = action => {
    if (blacklistedActionTypes.includes(action.type)) {
      return {
        ...action,
        payload: "<SANITIZED_PAYLOAD:MODIFY_STORE.JS_TO_REMOVE>",
      };
    }

    return action;
  };

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension({actionSanitizer}));
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers,
);

const store = createStore(reducers, initialState, composedEnhancers);

export const persistor = persistStore(store);
export default store;
