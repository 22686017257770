import React from "react";
import {connect} from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import {navigateToLogin} from "actions/auth";

import FormButton from "components/form-button";

import LoginContainer from "routes/login/login-container";

import Footer from "routes/login/footer";
import styles from "routes/login/styles";

const PasswordResetCodeExpired = props => {
  const {dispatch} = props;

  return (
    <LoginContainer>
      <form style={styles.loginContainer}>
        <Grid container direction={"row"} spacing={2}>
          <Grid item xs={12}>
            <Typography style={{...styles.typography, fontWeight: "600"}}>
              Reset Password
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{...styles.typography}}>
              The password reset code has already been used or is invalid. You
              can request a new password reset code from the sign in page.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormButton
              variant={"contained"}
              center
              fullWidth
              style={{height: "40px", color: "white"}}
              onClick={() => dispatch(navigateToLogin())}
            >
              Return to sign in
            </FormButton>
          </Grid>
        </Grid>
      </form>
      <Footer />
    </LoginContainer>
  );
};

export default connect()(PasswordResetCodeExpired);
