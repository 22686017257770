import React from "react";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";

import {ClickEvent} from "components/analytics";
import {eventCategory, eventAction} from "constants/analytics";

const styles = ({colors}) => ({
  button: {
    color: colors.warmGrey[50],
    backgroundColor: colors.warmGrey[600],
    "&:hover": {
      backgroundColor: colors.warmGrey[500],
    },
  },
});
const ManageTagsButton = ({classes, onClick, disabled}) => (
  <ClickEvent
    category={eventCategory.tags}
    action={eventAction.manageView}
    label="Opened -- All Wells level"
  >
    <Button
      variant="contained"
      className={classes.button}
      onClick={onClick}
      disabled={disabled}
    >
      Manage tags
    </Button>
  </ClickEvent>
);

export default withStyles(styles)(ManageTagsButton);
