import {types} from "./types";

export const setOrganizationAssignedWellsForUser = options => {
  const {assignedWells, clientUserId, organizationId} = options;

  return {
    type: types.setOrganizationAssignedWellsForUser,
    payload: {
      assignedWells,
      clientUserId,
      organizationId,
    },
  };
};
