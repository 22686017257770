import React from "react";
import Typography from "@material-ui/core/Typography";

export default props => {
  const {input} = props;

  if (!input.value || !input.value.units) return null;

  return (
    <Typography variant="body2" style={{marginLeft: 4, marginRight: 8}}>
      {input.value.units}
    </Typography>
  );
};
