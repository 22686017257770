import {parse} from "query-string";
import mapper from "object-mapper";
import moment from "moment";

const transforms = {
  comparisons: comparisons => (comparisons ? comparisons.split(",") : void 0),
  date: date => (date ? moment(parseFloat(date)).toDate() : void 0),
  number: number => (number ? parseFloat(number) : void 0),
};

export const queryMappings = {
  als: {key: "alerts.state", transform: transforms.number},
  caps: {key: "casingPressure.state", transform: transforms.number},
  comps: {key: "comparisons", transform: transforms.comparisons},
  cus: {key: "current.state", transform: transforms.number},
  da: {key: "date", transform: transforms.date},
  des: {key: "deflection.state", transform: transforms.number},
  dcs: {key: "dutyCycle.state", transform: transforms.number},
  drfs: {key: "driveFault.state", transform: transforms.number},
  drms: {key: "driveMode.state", transform: transforms.number},
  ess: {key: "effectiveSpm.state", transform: transforms.number},
  fis: {key: "fillage.state", transform: transforms.number},
  afis: {key: "ambyintFillage.state", transform: transforms.number},
  ifis: {key: "inferredFillage.state", transform: transforms.number},
  leps: {key: "leakPressure.state", transform: transforms.number},
  los: {key: "load.state", transform: transforms.number},
  oips: {key: "oilProduction.state", transform: transforms.number},
  ioips: {key: "inferredOilProduction.state", transform: transforms.number},
  rae: {key: "range.end", transform: transforms.date},
  ras: {key: "range.start", transform: transforms.date},
  res: {key: "recommendations.state", transform: transforms.number},
  rps: {key: "rpm.state", transform: transforms.number},
  rrs: {key: "rrpm.state", transform: transforms.number},
  russ: {key: "runningStatus.state", transform: transforms.number},
  scs: {key: "shutdownCards.state", transform: transforms.number},
  sps: {key: "spm.state", transform: transforms.number},
  stcs: {key: "statusCodes.state", transform: transforms.number},
  tos: {key: "torque.state", transform: transforms.number},
  tups: {key: "tubingPressure.state", transform: transforms.number},
  usra: {key: "userActions.state", transform: transforms.number},
};

export const mapQueryToAnalysis = query => {
  const mapped = mapper(parse(query), queryMappings);

  if (mapped.range && (mapped.range.start || mapped.range.end)) {
    const diff = moment(mapped.range.end).diff(mapped.range.start, "day");

    if (diff > 31) {
      mapped.range.start = moment
        .utc(mapped.range.end)
        .subtract(31, "days")
        .startOf("day")
        .toDate();
    }
  }

  return mapped;
};

export default mapQueryToAnalysis;
