import React from "react";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(({colors, spacing}) => ({
  caption: {
    color: colors.warmGrey[800],
    textTransform: "capitalize",
  },
  percent: {
    color: colors.warmGrey[700],
  },
  percentLabel: {
    color: colors.warmGrey[500],
    paddingLeft: 4,
  },
  confidence: {
    display: "flex",
    alignItems: "baseline",
    marginTop: -4,
  },
}));

const WellCardCaption = ({caption, confidence}) => {
  const classes = useStyles();
  const showConfidence = !!confidence;

  return (
    <div>
      <Typography variant="caption" className={classes.caption}>
        {caption}
      </Typography>
      {showConfidence && (
        <div className={classes.confidence}>
          <Typography variant="h6" className={classes.percent}>
            {Math.floor(confidence * 100)}%
          </Typography>
          <Typography variant="caption" className={classes.percentLabel}>
            Confidence
          </Typography>
        </div>
      )}
    </div>
  );
};

export default WellCardCaption;
