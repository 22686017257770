import math from "mathjs";
import withUnits from "utils/with-units";
import {height} from "unit-converter";
import spreadIf from "utils/spread-if";
import valueOf from "utils/value-of";

const convertHeight = ({value, from, to, units, decimals = 4}) => {
  if (valueOf(value) === null || valueOf(value) === undefined) {
    return withUnits(null, typeof units === "object" ? units.height : units);
  }

  const converted = withUnits(
    math.round(
      height.convert(
        {
          ...spreadIf(from, {
            from: (from && (typeof from === "object" && from.height)) || from,
          }),
          ...spreadIf(to, {
            to: (to && (typeof to === "object" && to.height)) || to,
          }),
          value,
        },
        to && to.height,
      ),
      decimals,
    ),
    typeof units === "object" ? units.height : units,
  );

  return converted;
};

export default convertHeight;
