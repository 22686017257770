import contains from "utils/contains";

import cleanQuotes from "./clean-quotes";

export default (items = [], filter = "") => {
  return items.filter(item => {
    const {downholeLocation, alias} = item;
    const criteria = cleanQuotes(filter.split(":")[1]);

    if (!criteria) return true;

    return contains(alias, criteria) || contains(downholeLocation, criteria);
  });
};
