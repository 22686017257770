import {types} from "actions/auth";

/**
 * Reset reducer to default state on authTypes.signout.
 * A higher-order reducer. More examples of those in Redux documentation:
 * https://redux.js.org/recipes/structuring-reducers/reusing-reducer-logic
 *
 * Automatically resets reducer state to default state on sign out.
 * For convenience, default state is automatically determined at startup based on invoking with empty action.
 * Reducer is still invoked on signout using default state, to do any further processing.
 *
 * @param {function} reducer - A reducer to automatically set state for.
 */
const resetOnSignout = reducer => {
  const defaultState = reducer(undefined, {});

  return (state, action) => {
    if (action.type === types.signOut) {
      return reducer(defaultState, action);
    }

    return reducer(state, action);
  };
};

export default resetOnSignout;
