import React from "react";

import getDisplayName from "utils/user/get-display-name";

import FeedItem from "../feed-item";

export default props => {
  const {
    event,
    event: {
      value: {maxSPM, minSPM},
    },
  } = props;

  return (
    <FeedItem event={event}>
      <strong>{getDisplayName(event)}</strong>
      {" attempted to change the speed range to "}
      <strong>
        {minSPM} - {maxSPM} SPM
      </strong>
    </FeedItem>
  );
};
