import MAX_DISPLAY_LENGTH from "constants/max-display-length";
import {actions} from "react-redux-form";
import store from "store";
import doesAliasExist from "utils/well/does-alias-exist";

import formPath from "../form-path";

const getAliasError = async bypassExists => {
  const state = store.getState();

  const {alias} = state.forms.addWell;
  const {selectedOrganizationId} = state.organizations;

  if (alias && alias.length > MAX_DISPLAY_LENGTH)
    return `Alias cannot exceed ${MAX_DISPLAY_LENGTH} characters`;

  if (
    alias &&
    !bypassExists &&
    (await doesAliasExist(alias, selectedOrganizationId))
  )
    return "Alias is already in use";
};

export default async ({bypassExists = false} = {}) => {
  store.dispatch(
    actions.setFieldsErrors(formPath, {
      alias: await getAliasError(bypassExists),
    }),
  );
};
