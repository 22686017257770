import process from "../process";

export const setTriageWells = ({state, action}) => {
  return process({
    ...state,
    workflow: {
      ...state.workflow,
      triage: {
        ...state.workflow.triage,
        ...action.payload,
      },
    },
  });
};
