import {types} from "./types";

export const filterEventsByDateRange = options => {
  const {organizationId, range} = options;

  return {
    type: types.filterEventsByDateRange,
    payload: {
      organizationId,
      range,
    },
  };
};
