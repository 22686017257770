import {types} from "./types";

export const create = ({data, onSuccess}) => {
  const {
    alias,
    client,
    clientRequester,
    downholeLocation,
    fieldName,
    pumpingUnitType,
    surfaceLocation,
    surfaceLocationLatitude,
    surfaceLocationLongitude,
    wellCountry,
    wellProvinceState,
  } = data;

  return {
    payload: {
      data: {
        alias,
        client,
        clientRequester,
        downholeLocation,
        fieldName,
        pumpingUnitType,
        surfaceLocation,
        surfaceLocationLatitude,
        surfaceLocationLongitude,
        wellCountry,
        wellProvinceState,
      },
      onSuccess,
    },
    type: types.create,
  };
};
