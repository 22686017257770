import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import Title from "components/typography/title";
import ActiveFilters from "components/active-filters";
import FractionalCount from "components/fractional-count";

const styles = ({colors, spacing}) => ({
  root: {
    marginTop: spacing(4),
    marginBottom: spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    marginRight: spacing(1),
    color: colors.warmGrey[900],
  },
  count: {
    marginRight: "auto",
  },
  titleGroup: {
    display: "flex",
  },
});

const ReportHeader = ({classes, classificationCount, totalCount}) => (
  <div className={classes.root}>
    <span className={classes.titleGroup}>
      <Title className={classes.title}>Well List</Title>
      <ActiveFilters />
    </span>
    <FractionalCount numerator={classificationCount} denominator={totalCount} />
  </div>
);

export default withStyles(styles)(ReportHeader);
