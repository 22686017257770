import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import noop from "lodash/noop";
import uniq from "lodash/uniq";
import compose from "lodash/fp/compose";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = ({colors}) => ({
  list: {
    height: 150,
    overflowY: "auto",
  },
  item: {
    "&:hover": {
      backgroundColor: colors.warmGrey[100],
    },
  },
});

const defaultFilter = (list, query) => {
  const startsWith = list.filter(item =>
    item.label.toLowerCase().startsWith(query.toLowerCase()),
  );
  const includes = list.filter(item =>
    item.label.toLowerCase().includes(query.toLowerCase()),
  );

  return uniq([...startsWith, ...includes]);
};

const EmptyItem = ({text = "There are no results", ...props}) => (
  <ListItem>
    <ListItemText primaryTypographyProps={{variant: "body2"}} {...props}>
      {text}
    </ListItemText>
  </ListItem>
);

/**
 * Generate a list of filterable, clickable items.
 * Works well when paired with a TextField input.
 */
const ResultsList = ({
  classes, // {object} mui styles
  query, // {string} search term to filter items by
  results, // {array} results to be filtered on, items should be in the form of {id: {string}, label: {string}}
  filter = defaultFilter, // {func} filter function (results, query) => new array of filtered results
  onClick = noop, // {func} result click handler, will be passed clicked item
  emptyText, // {string} text that will appear when there are no filtered results
  emptyTextProps = {}, // {object} props that will add or replace props on empty text
  itemProps = {}, // {object} props that will add or replace props on list items
  listProps = {}, // {object} props that will add or replace props on the list
}) => {
  const items = filter(results, query).map(item => (
    <ListItem
      key={item.id}
      className={classes.item}
      onClick={() => onClick(item)}
      button
      onMouseDown={e => e.preventDefault()}
      style={{cursor: onClick ? "pointer" : "auto"}}
      {...itemProps}
    >
      <ListItemText>{item.label}</ListItemText>
    </ListItem>
  ));

  return (
    <List className={classes.list} {...listProps}>
      {items.length ? (
        items
      ) : (
        <EmptyItem text={emptyText} {...emptyTextProps} />
      )}
    </List>
  );
};

const enhance = compose(withStyles(styles));

export default enhance(ResultsList);
