import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import MaterialTableRow from "@material-ui/core/TableRow";
import clsx from "clsx";

const styles = ({colors}) => ({
  rowDefault: {
    cursor: "pointer",
    height: 55,
    borderLeft: "5px solid transparent",
    "&:hover": {
      backgroundColor: colors.warmGrey[50],
      borderLeft: "5px solid",
      borderColor: colors.warmGrey[500],
    },
  },
  selected: {
    backgroundColor: colors.warmGrey[100],
    borderColor: colors.warmGrey[100],
    "&:hover": {
      backgroundColor: colors.warmGrey[100],
    },
  },
});

const TableRow = ({classes, isSelected, children, ...rest}) => {
  return (
    <MaterialTableRow
      className={clsx(classes.rowDefault, isSelected && classes.selected)}
      {...rest}
    >
      {children}
    </MaterialTableRow>
  );
};

export default withStyles(styles)(TableRow);
