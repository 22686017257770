export const types = {
  hide: "discard-form-dialog:hide",
  show: "discard-form-dialog:show",
};

export const hide = () => {
  return {
    type: types.hide,
  };
};

export const show = ({pendingAction, resetDirtyFormAction}) => {
  return {
    type: types.show,
    payload: {
      pendingAction,
      resetDirtyFormAction,
    },
  };
};
