import React from "react";
import {connect} from "react-redux";

import {navigateToLogin, verifyOAuth} from "actions/auth";

import LoginContainer from "routes/login/login-container";
import TermsOfService from "routes/login/verify-user/terms-of-service";

const VerifyOAuth = props => {
  const {
    dispatch,
    match: {
      params: {clientUserId, callbackToken},
    },
  } = props;

  return (
    <LoginContainer>
      <TermsOfService
        handleAccept={() =>
          dispatch(verifyOAuth({callbackToken, clientUserId}))
        }
        handleDecline={() => dispatch(navigateToLogin())}
      />
    </LoginContainer>
  );
};

export default connect()(VerifyOAuth);
