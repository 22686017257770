import {types} from "actions/well-summary-dialog";

const defaultValues = {
  isOpen: false,
};

export default (state = defaultValues, action) => {
  if (action.type === types.hide) {
    return {
      ...state,
      isOpen: false,
    };
  }

  if (action.type === types.show) {
    return {
      ...state,
      ...action.payload,
    };
  }

  return state;
};
