import React from "react";

import {hasControlForEpm} from "@ambyint/common/utils/devices/control-for-epm";
import {hasControlIotBridge} from "@ambyint/common/utils/devices/control-iot-bridge";
import {hasControlForVfd} from "@ambyint/common/utils/devices/control-for-vfd";
import {onlySenseLeak} from "@ambyint/common/utils/devices/sense-leak";
import {hasLufkin2} from "@ambyint/common/utils/devices/has-lufkin-2";
import {isPumpjack} from "@ambyint/common/utils/wells/is-pumpjack";
import hasLufkin from "@ambyint/common/utils/devices/has-lufkin";
import hasSmarten from "@ambyint/common/utils/devices/has-smarten";

import hasSetFaultRecovery from "utils/well/has-set-fault-recovery";
import SideMenu from "components/side-menu";

import AlertGracePeriods from "./alert-grace-periods";
import AnalogInputConfiguration from "./analog-input-configuration";
import AutoAcceptRecommendations from "./auto-accept-recommendations";
import BasicInformationEditView from "./basic-information";
import DeviceInformationView from "./device-information";
import DownholeInformationEditView from "./downhole-information";
import FaultRecovery from "./fault-recovery";
import LufkinSettings from "./lufkin-settings";
import MotorInformationEditView from "./motor-information";
import MotorViolations from "./motor-violations";
import PumpingUnitInformationEditView from "./pumping-unit-information";
import PumpoffSettingsView from "./pumpoff-settings";
import ReservoirInformationEditView from "./reservoir-information";
import Resistance from "./resistance";
import RunTimerConfiguration from "./run-timer-configuration";
import SafeOperatingParametersEditView from "./safe-operating-parameters";
import TotalReductionRatioEditView from "./total-reduction-ratio";
import WellboreInformationEditView from "./wellbore-information";

export default props => {
  const {dispatch, well} = props;

  const isSmarten = hasSmarten(well.devices);
  const encodedDownoleLocation = encodeURIComponent(well.downholeLocation);

  const menuItems = [
    {
      label: "Basic Information",
      pathname: "/setup/basic",
      component: () => <BasicInformationEditView well={well} />,
    },
    ...(isPumpjack(well)
      ? [
          {
            label: "Pumping Unit Information",
            pathname: "/setup/pumping-unit",
            component: () => <PumpingUnitInformationEditView well={well} />,
          },
        ]
      : []),
    {
      label: "Wellbore Information",
      pathname: "/setup/wellbore",
      component: () => <WellboreInformationEditView well={well} />,
    },
    {
      label: "Downhole Information",
      pathname: "/setup/downhole",
      component: () => <DownholeInformationEditView well={well} />,
    },
    {
      label: "Reservoir Information",
      pathname: "/setup/reservoir",
      component: () => <ReservoirInformationEditView well={well} />,
    },
    {
      label: "Total Reduction Ratio",
      pathname: "/setup/total-reduction-ratio",
      component: () => <TotalReductionRatioEditView well={well} />,
    },
    ...(hasLufkin(well.devices) || hasLufkin2(well.devices)
      ? [
          {
            label: "Lufkin Settings",
            pathname: "/setup/lufkin-settings",
            component: () => <LufkinSettings well={well} />,
          },
        ]
      : []),
    {
      label: "Safe Operating Parameters",
      pathname: "/setup/safe-operating-parameters",
      component: () => <SafeOperatingParametersEditView well={well} />,
    },

    ...(hasControlForEpm(well.devices) || isSmarten
      ? [
          {
            label: "Motor Information",
            pathname: "/setup/motor-information",
            component: () => (
              <MotorInformationEditView
                rpmOnly={isSmarten && !hasControlForEpm(well.devices)}
                well={well}
              />
            ),
          },
        ]
      : []),

    ...(hasControlForEpm(well.devices)
      ? [
          {
            label: "Run Timer Settings",
            pathname: "/setup/run-timer",
            component: () => <RunTimerConfiguration well={well} />,
          },
        ]
      : []),

    ...(hasControlIotBridge(well.devices)
      ? [
          {
            label: "Pumpoff Settings",
            pathname: "/setup/pumpoff-settings",
            component: () => <PumpoffSettingsView well={well} />,
          },
          {
            label: "Resistance Parameters",
            pathname: "/setup/resistance",
            component: () => <Resistance well={well} />,
          },
          ...(!isSmarten
            ? [
                {
                  label: "Analog Input Configuration",
                  pathname: "/setup/analog-input-configuration",
                  component: () => <AnalogInputConfiguration well={well} />,
                },
              ]
            : []),
        ]
      : []),

    ...(!isSmarten &&
    (hasControlIotBridge(well.devices) || hasControlForEpm(well.devices))
      ? [
          {
            label: "Auto Accept Recommendations",
            pathname: "/setup/auto-accept-recommendations",
            component: () => <AutoAcceptRecommendations well={well} />,
          },
        ]
      : []),
    ...(hasControlForVfd(well.devices) || hasControlForEpm(well.devices)
      ? [
          {
            label: "Motor Violations",
            pathname: "/setup/motor-violations",
            component: () => <MotorViolations well={well} />,
          },
        ]
      : []),

    ...(hasSetFaultRecovery(well)
      ? [
          {
            label: "Fault Recovery",
            pathname: "/setup/fault-recovery",
            component: () => <FaultRecovery well={well} />,
          },
        ]
      : []),

    {
      label: "Devices",
      pathname: "/setup/devices",
      component: () => <DeviceInformationView well={well} />,
    },
    ...(!onlySenseLeak(well.devices) && !hasControlIotBridge(well.devices)
      ? [
          {
            label: "Alert Grace Periods",
            pathname: "/setup/alert-grace-periods",
            component: () => <AlertGracePeriods well={well} />,
          },
        ]
      : []),
  ];

  return (
    <SideMenu
      useUrlRouting
      dispatch={dispatch}
      menuItems={menuItems}
      redirectPath={`/well/${encodedDownoleLocation}/setup/basic`}
      style={{height: "calc(100vh - 112px)"}}
      exactPathPrefix={`/well/${encodedDownoleLocation}`}
      routePathPrefix={"/well/:downholeLocation"}
    />
  );
};
