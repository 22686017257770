import {types} from "./types";

export const fetchDailySummaries = (
  wellList,
  start = undefined,
  end = undefined,
  metrics = undefined,
) => {
  return {
    type: types.fetchDailySummaries,
    payload: {
      wellList,
      start,
      end,
      metrics,
    },
  };
};
