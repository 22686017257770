import getSafeOperatingDowntimeMin from "@ambyint/common/utils/wells/get-safe-operating-downtime-min";
import getSafeOperatingDowntimeMax from "@ambyint/common/utils/wells/get-safe-operating-downtime-max";

import integer from "utils/validation/integer";
import max from "utils/validation/max";
import min from "utils/validation/min";
import number from "utils/validation/number";
import required from "utils/validation/required";
import validateFieldsOf from "utils/validation/validate-fields-of";

export default (values, well) => {
  const validateRecommendedData = validateFieldsOf(values.acceptedData);

  const minDowntime = getSafeOperatingDowntimeMin(well);
  const maxDowntime = getSafeOperatingDowntimeMax(well);

  return {
    ...validateRecommendedData("hours", x => required(x)),
    ...validateRecommendedData("hours", x => number(x)),
    ...validateRecommendedData("hours", x => min(x, minDowntime.hours)),
    ...validateRecommendedData("hours", x => max(x, maxDowntime.hours)),
    ...validateRecommendedData("hours", x => integer(x)),

    ...validateRecommendedData("minutes", x => required(x)),
    ...validateRecommendedData("minutes", x => number(x)),
    ...validateRecommendedData("minutes", x => {
      if (values.acceptedData.hours <= minDowntime.hours) {
        return min(x, minDowntime.minutes);
      }
    }),
    ...validateRecommendedData("minutes", x => {
      if (values.acceptedData.hours >= maxDowntime.hours) {
        return max(x, maxDowntime.minutes);
      }
    }),
    ...validateRecommendedData("minutes", x => integer(x)),
  };
};
