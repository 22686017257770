import React from "react";

import isEmpty from "lodash/isEmpty";

import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import colors from "theme/colors";
import formatDateTime from "@ambyint/common/utils/format/format-date-time";

import PaperContainer from "components/paper-container";

import getPrivileges from "utils/get-privileges";
import privilegeTypes from "utils/privilege-types";

import {CardLabel, CardRow, CardRowSecondary} from "../cards";

const defaultContainerStyle = {
  minWidth: "150px",
  maxWidth: "570px",
};

const inlineButton = {
  color: colors.ambyOrange[500],
  cursor: "pointer",
};

const DeviceInformation = props => {
  const {well} = props;
  const privileges = getPrivileges(well.organization.id);
  const hasAdmin = privileges.includes(privilegeTypes.sysAdmin);

  return (
    <div>
      {well.devices.map(d => {
        return (
          <PaperContainer
            padded
            key={d.serialNumber}
            style={defaultContainerStyle}
          >
            <CardHeader
              title={d.brandedName}
              subheader={
                d.latestCommunication
                  ? `Communicated at
                    ${formatDateTime(d.latestCommunication, {
                      timezone: true,
                    })}`
                  : ""
              }
            />
            <CardContent>
              {!hasAdmin && (
                <CardRow label="Serial Number" value={d.serialNumber} />
              )}
              {hasAdmin && (
                <Grid container justify={"space-between"}>
                  <Grid item xs={12} sm={12} md>
                    <CardLabel label="Serial Number" />
                  </Grid>
                  <Grid item xs={12} sm={12} md>
                    <Typography
                      variant="body2"
                      component="p"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_ADMIN}/#/devices/${
                            d.serialNumber
                          }`,
                        )
                      }
                      style={inlineButton}
                    >
                      {d.serialNumber}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <CardRow label="Model Number" value={d.modelNumber} />
              {d.driveType && (
                <CardRow label="Drive Type" value={d.driveType.type} />
              )}
              {d.batteryLevel && (
                <CardRow label="Battery Level" value={d.batteryLevel} />
              )}
              {d.temperature && (
                <CardRow label="Temperature" value={d.temperature} />
              )}

              {hasAdmin && (
                <CardRow label="Software Version" value={d.softwareVersion} />
              )}

              {hasAdmin && (
                <CardRow label="Hardware Version" value={d.firmwareVersion} />
              )}
            </CardContent>

            {hasAdmin && !isEmpty(d.errors) && (
              <CardContent>
                {d.errors.map((e, i) => (
                  <CardRowSecondary
                    key={`error-${i}`}
                    label={i === 0 ? "Error History" : ""}
                    primary={e.error}
                    secondary={formatDateTime(e.receivedOn, {
                      timezone: true,
                    })}
                  />
                ))}
              </CardContent>
            )}

            {hasAdmin && !isEmpty(d.controlDiagnostics) && (
              <CardContent>
                {d.controlDiagnostics.map((e, i) => (
                  <CardRowSecondary
                    key={`diagnostics-${i}`}
                    label={i === 0 ? "System History" : ""}
                    primary={e.state}
                    secondary={formatDateTime(e.processedOn, {
                      timezone: true,
                    })}
                  />
                ))}
              </CardContent>
            )}
          </PaperContainer>
        );
      })}
    </div>
  );
};

export default DeviceInformation;
