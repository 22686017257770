import React from "react";
import Chip from "@material-ui/core/Chip";
import CloseIcon from "mdi-material-ui/Close";
import MinusIcon from "mdi-material-ui/Minus";
import withStyles from "@material-ui/core/styles/withStyles";

import {eventCategory, eventAction} from "constants/analytics";
import {ClickEvent} from "components/analytics";
import {capitalizeEachWord} from "utils/capitalize-each-word";
import {STATUS} from "constants/tagging";

const styles = {
  tag: {
    cursor: "pointer",
    backgroundColor: "rgb(255, 255, 255)",
    boxShadow: "0 2px 2px hsla(0, 0%, 0%, 0.2)",
    margin: 8,
    height: 25,
    "&:focus, &:hover": {
      backgroundColor: "rgb(255, 255, 255)",
    },
  },

  newTag: {
    color: "rgb(25, 81, 28)",
    backgroundColor: "rgb(189, 221, 190)",
    "&:focus, &:hover": {
      color: "rgb(25, 81, 28)",
      backgroundColor: "rgb(189, 221, 190)",
    },
    "& > .background-override": {
      backgroundColor: "rgb(25, 81, 28)",
    },
  },

  removedTag: {
    color: "rgb(199, 52, 97)",
    backgroundColor: "rgb(248, 229, 236)",
    "&:focus, &:hover": {
      color: "rgb(199, 52, 97)",
      backgroundColor: "rgb(248, 229, 236)",
    },
    "& > .background-override": {
      backgroundColor: "rgb(199, 52, 97)",
    },
  },

  iconContainer: {
    borderRadius: "50%",
    backgroundColor: "#857F72",
    color: "#fff",
    width: 25,
    height: 25,
    marginRight: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      color: "#fff",
    },
    "&:focus": {
      backgroundColor: "inherit",
    },
    "& > svg": {
      width: 15,
      height: 15,
    },
  },
};

const IconContainer = ({className, status}) => {
  return (
    <span className={className}>
      {status === STATUS.removedTag ? <MinusIcon /> : <CloseIcon />}
    </span>
  );
};

const Tag = ({name, classes, status, onDelete}) => {
  const statusClassName = () => {
    switch (status) {
      case STATUS.newTag:
        return classes.newTag;
      case STATUS.removedTag:
        return classes.removedTag;
      default:
        return "";
    }
  };

  return (
    <ClickEvent
      category={eventCategory.tags}
      action={eventAction.toggle}
      label={"Remove Tag"}
    >
      <Chip
        label={name ? capitalizeEachWord(name) : "Non Upgraded Tag(s)"}
        className={`${classes.tag} ${statusClassName()}`}
        onDelete={onDelete}
        onClick={onDelete}
        classes={{deleteIcon: classes.iconContainer}}
        deleteIcon={
          <IconContainer className="background-override" status={status} />
        }
      />
    </ClickEvent>
  );
};

export default withStyles(styles)(Tag);
