import {types} from "actions/home";

const defaultState = {
  viewType: "list",
};

export default (state = defaultState, action) => {
  if (action.type === types.setView) {
    return {
      ...state,
      viewType: action.payload.viewType,
    };
  }

  return state;
};
