import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

const CloseButton = ({onClick}) => {
  return (
    <IconButton onClick={onClick}>
      <CloseIcon />
    </IconButton>
  );
};

export default CloseButton;
