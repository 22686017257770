import React from "react";

import AnomalyWellList from "./anomaly-well-list";
import StatusWellList from "./status-well-list";

const WellList = ({wells, isAnomalyWorkflow}) => {
  const WellList = isAnomalyWorkflow ? AnomalyWellList : StatusWellList;

  return <WellList wells={wells} />;
};

export default WellList;
