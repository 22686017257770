import process from "../process";

export const setAnomalyWells = ({state, action}) => {
  return process({
    ...state,
    workflow: {
      ...state.workflow,
      anomaly: {
        ...state.workflow.anomaly,
        wellList: action.payload.wellList,
      },
    },
  });
};
