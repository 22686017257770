import React from "react";

import {line} from "d3-shape";

import colors from "theme/colors";

const getDefaultStyle = () => {
  return {
    stroke: colors.red[100],
    fill: "none",
    strokeWidth: "0.3px",
    strokeDasharray: "2 1",
  };
};

const Line = props => {
  const {yScale, value, style, width} = props;
  return (
    <line
      x1={0}
      x2={width}
      y1={yScale(value)}
      y2={yScale(value)}
      style={{...getDefaultStyle(props), ...style}}
    />
  );
};

export default Line;
