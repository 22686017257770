const isInPeriod = ({dataPoint, shutdownPeriod: {start, end}}) => {
  const createdOn = new Date(dataPoint.createdOn);

  if (end) return new Date(start) <= createdOn && createdOn <= new Date(end);

  return new Date(start) <= createdOn;
};

export default ({data = [], shutdownPeriods}) =>
  data.filter(dataPoint => {
    const shutdownPeriodIndex = shutdownPeriods.findIndex(shutdownPeriod =>
      isInPeriod({
        dataPoint,
        shutdownPeriod,
      }),
    );

    if (shutdownPeriodIndex > -1) {
      shutdownPeriods[shutdownPeriodIndex].lastDataPoint = dataPoint;

      return false;
    }

    return true;
  });
