import {createSwitchEpic} from "epics/create-epic";
import {
  fetchOrganization as fetchOrganizationAction,
  types,
} from "actions/organizations";

export default createSwitchEpic(
  [types.selectOrganization],
  async ({payload}, store) => {
    if (
      payload.organizationId &&
      !store.getState().organizations.all[payload.organizationId]
    ) {
      return [
        fetchOrganizationAction({organizationId: payload.organizationId}),
      ];
    }

    return [];
  },
);
