import React from "react";
import clsx from "clsx";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import withStyles from "@material-ui/core/styles/withStyles";
import noop from "lodash/noop";

import colors from "@ambyint/common/colors";

import {eventCategory, eventAction} from "constants/analytics";
import {sendAnalyticsEvent} from "components/analytics";

const styles = ({palette}) => ({
  container: {
    display: "flex",
    marginLeft: 16,
    border: "none",
    padding: "4px 8px 4px 8px",
    width: 400,
    color: palette.grey[200],
    backgroundColor: "hsla(0,0%,100%,.08)",
    borderRadius: 4,
    lineHeight: 1,
    transition: "background-color .2s ease-in-out, color .2s ease-in-out",
  },

  input: {
    marginLeft: 12,
    marginRight: 12,
    border: "none",
    outline: "none",
    background: "transparent",
    color: "inherit",
    textAlign: "start",
    fontSize: "1em",
    flex: 1,
  },

  focus: {
    backgroundColor: palette.grey[100],
    color: colors.darkGrey,
  },

  icon: {
    cursor: "pointer",
  },
});

class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focus: false,
      query: props.initialValue,
    };
  }

  setFocus = focus => this.setState({focus});
  setQuery = query => this.setState({query});

  performSearch = e => {
    const {onSearch} = this.props;

    if (e.key === "Enter") {
      sendAnalyticsEvent(
        eventCategory.search,
        eventAction.submit,
        e.target.value,
      );
      onSearch(e.target.value);
      e.target.blur();
    }
  };

  render() {
    const {setFocus, setQuery, performSearch} = this;
    const {id, classes, onSearch = noop} = this.props;
    const {focus, query} = this.state;

    return (
      <div className={clsx(classes.container, {[classes.focus]: focus})}>
        <SearchIcon
          className={classes.icon}
          onClick={() => {
            sendAnalyticsEvent(eventCategory.search, eventAction.submit, query);
            onSearch(query);
          }}
          aria-label="Search"
        />
        <input
          id={id}
          className={classes.input}
          onFocus={() => {
            sendAnalyticsEvent(eventCategory.search, eventAction.focus);
            setFocus(true);
          }}
          onBlur={() => setFocus(false)}
          onChange={e => setQuery(e.target.value)}
          onKeyPress={performSearch}
          value={query}
          type="text"
        />
        {query && (
          <ClearIcon
            className={classes.icon}
            onClick={() => {
              setQuery("");
              onSearch("");
            }}
            aria-label="Clear"
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Search);
