import React from "react";
import math from "mathjs";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Aux from "components/aux";
import Analysis from "./analysis";
import Item from "./item";

const renderRod = ({depth, goodmanLoading, number, outsideDiameter}) => {
  return (
    <Aux>
      <Grid item xs={6}>
        <Item
          title="Loading"
          value={`${math.round(goodmanLoading.value, 2)}${
            goodmanLoading.units
          }`}
        />
        <Item title="Rod Number" value={number.value} />
      </Grid>
      <Grid item xs={6}>
        <Item
          title="Rod OD"
          value={`${math.round(outsideDiameter.value, 5)} ${
            outsideDiameter.units
          }`}
        />
        <Item
          title="Rod Depth"
          value={`${math.round(depth.value, 2)} ${depth.units}`}
        />
      </Grid>
    </Aux>
  );
};

const renderRodSections = sections => {
  return sections.map((section, index) => (
    <Aux key={index}>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            style={{
              borderBottom: "1px solid rgb(54, 70, 80)",
              display: "inline",
            }}
            variant="subtitle1"
          >
            Section {index + 1} Top Rod
          </Typography>
        </Grid>
      </Grid>
      <Grid container>{renderRod(section.top)}</Grid>
    </Aux>
  ));
};

export default ({data}) => {
  return (
    <Analysis title="Rod String Analysis">
      <Grid container>
        <Grid item xs={6}>
          <Item
            title="Wet Weight"
            value={`${math.round(data.weightInFluidFilledTubing.value)} ${
              data.weightInFluidFilledTubing.units
            }`}
          />
        </Grid>
        <Grid item xs={6}>
          <Item
            title="Dry Weight"
            value={`${math.round(data.weightInDryTubing.value)} ${
              data.weightInDryTubing.units
            }`}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            style={{
              borderBottom: "1px solid rgb(54, 70, 80)",
              display: "inline",
            }}
            variant="subtitle1"
          >
            Overall Max
          </Typography>
        </Grid>
      </Grid>
      <Grid container>{renderRod(data.maxGoodmanLoadingRod)}</Grid>
      {renderRodSections(data.sectionKeyRods)}
    </Analysis>
  );
};
