import {useContext} from "react";
import get from "lodash/get";

import {features} from "components/features";

export const useFlag = flag => {
  const userFlags = useContext(features);

  return get(userFlags, flag, false);
};
