import all from "utils/validation/all";
import copyValuesIfSet from "utils/validation/copy-values-if-set";
import required from "utils/validation/required";
import hasGreaterEqualityError from "utils/validation/hasGreaterEqualityError";

export default values => {
  let requiredFields = [
    "couplingType",
    "rodDimensions",
    "rodLength",
    "rodQuantity",
    "rodType",
  ];

  if (
    values.guideType &&
    values.guideType.rodDiameter &&
    values.guideType.rodDiameter.value
  ) {
    requiredFields = [...requiredFields, "guideMaterial", "guideQuantity"];
  }

  let errors = all(required, values, requiredFields);

  // Validate that rodLength value is greater than 0
  const lengthValidError = hasGreaterEqualityError(values.rodLength, 0);
  if (lengthValidError && !errors["rodLength"]) {
    errors.rodLength = lengthValidError;
  }

  // Validate that rodQuantity value is greater than 0
  const quantityValidError = hasGreaterEqualityError(values.rodQuantity, 0);
  if (quantityValidError && !errors["rodQuantity"]) {
    errors.rodQuantity = quantityValidError;
  }

  errors = copyValuesIfSet(errors, [
    {couplingType: "couplingTypeLabel"},
    {guideType: "guideTypeKey"},
    {rodType: "rodTypeLabel"},
    {rodDimensions: "diameterLabel"},
  ]);

  return errors;
};
