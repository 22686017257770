import uniq from "lodash/uniq";

import {MAX_RECENT_WELLS} from "constants/recently-visited-wells";

import {getRecentlyVisitedWells} from "./get-recently-visited-wells";

export const addRecentlyVisitedWell = (userId, organizationId, wellId) => {
  const recentWells = uniq([
    wellId,
    ...getRecentlyVisitedWells(userId, organizationId),
  ]);

  return recentWells.slice(0, MAX_RECENT_WELLS);
};
