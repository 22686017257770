import {types} from "./types";

export const setCompressiveStress = (
  stressSeries,
  medianStressSeries,
  compressedRodsSeries,
  medianCompressedRodsSeries,
) => {
  return {
    type: types.setCompressiveStress,
    payload: {
      stressSeries,
      medianStressSeries,
      compressedRodsSeries,
      medianCompressedRodsSeries,
    },
  };
};
