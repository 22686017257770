import {types} from "actions/auth";
import {empty} from "actions/empty";

export default action$ => {
  return action$.ofType(types.signUpWithGoogle).switchMap(({payload}) => {
    window.location.href = `${
      process.env.REACT_APP_API
    }/ambyint/signup/google/${payload.email}`;

    return [empty()];
  });
};
