export default (s, value, ignoreCase = true) => {
  s = s || "";
  value = value || "";

  if (ignoreCase) {
    return s.toLowerCase().indexOf(value.toLowerCase()) >= 0;
  }

  return s.indexOf(value) >= 0;
};
