export default ({state, action}) => {
  const {organizationId, clientUserId} = action.payload;

  const organization = {
    ...(state[organizationId] || {}),
  };

  if (organization.performedBy === clientUserId) {
    organization.performedBy = null;
  }

  if (organization.performedOn === clientUserId) {
    organization.performedOn = null;
  }

  return {
    ...state,
    [organizationId]: organization,
  };
};
