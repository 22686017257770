import {useState, useCallback, useEffect} from "react";
import get from "lodash/get";

/**
 * Based on a list of results, set up event handlers and allow for keyboard navigation
 *
 * Up and down arrows will move an index through the results list
 * Enter will trigger the onSelect function with the currently selected result
 *
 * @param {array} results items to iterate over with the arrow keys
 * @param {function} onSelect fired when the user presses Enter
 * @param {boolean} preventDefaultSelection if true, the selected result will be null rather than 0
 */
export const useKeyboardNavigation = (
  results,
  onSelect,
  preventDefaultSelection,
) => {
  const [selectedResult, setSelectedResults] = useState(
    preventDefaultSelection ? null : 0,
  );
  const [scrollStart, setScrollStart] = useState(false);
  const {length} = results;
  const data = get(results, [selectedResult, "data"]);

  // Clamp selected element to the end of the list
  if (length > 0 && selectedResult > length - 1) setSelectedResults(length - 1);

  const handleKeyEvent = useCallback(
    e => {
      switch (e.key) {
        // Move selected result downwards, loop at bottom of list
        case "ArrowDown":
          setSelectedResults(state => {
            setScrollStart(false);

            return state !== null ? (state + 1) % length : 0;
          });
          break;
        // Move selected result upwards, loop at the top of the list
        case "ArrowUp":
          setSelectedResults(state => {
            const selected = state - 1;
            setScrollStart(true);

            return selected < 0
              ? length - 1 // loop to the end of the list
              : selected; // move up one result
          });
          break;
        // Select the result, go to analysis page
        case "Enter":
          e.stopPropagation();
          data && onSelect && onSelect(data);
          break;
        default:
          break;
      }
    },
    [setSelectedResults, length, onSelect, data],
  );

  useEffect(
    () => {
      document.addEventListener("keyup", handleKeyEvent);

      return () => {
        document.removeEventListener("keyup", handleKeyEvent);
      };
    },
    [handleKeyEvent],
  );

  return {
    selectedResult, // The currently selected item
    scrollStart, // Which direction the user is navigating in, useful for scroll snapping
    setSelectedResults, // Function to select an item manually, useful for mouse hovering
  };
};
