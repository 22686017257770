import {connect} from "react-redux";
import findWell from "utils/find-well";
import React from "react";

const mapStateToProps = (state, props) => {
  const well = findWell(state.wells, props.downholeLocation);
  return {
    well,
    loading: well.isFetchingAnalysis,
  };
};

export const withWell = Component =>
  connect(mapStateToProps)(props => <Component {...props} />);

export default withWell;
