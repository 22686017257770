import {show} from "actions/snackbar";
import {types} from "actions/wells";
import fetch from "epics/async-fetch";

export default action$ => {
  return action$.ofType(types.saveTag).switchMap(async ({payload}) => {
    const {downholeLocation, tag, well, remove} = payload;

    try {
      await fetch(
        `/wells/${encodeURIComponent(downholeLocation)}/tags`,
        {},
        {
          method: remove ? "DELETE" : "POST",
          body: JSON.stringify({
            downholeLocation,
            tag: tag.toLocaleLowerCase(),
          }),
        },
      );

      return show({
        message: `Your request to update the tags for ${
          well.displayName
        } was submitted successfully!`,
      });
    } catch (err) {
      console.warn(err);

      return show({
        message: `Your request to update the tags for ${
          well.displayName
        } failed. Please try again.`,
      });
    }
  });
};
