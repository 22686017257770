import React from "react";
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";

import {FormatDateTime} from "components/format/date";

import PaperContainer from "components/paper-container";
import DisplayStatus from "components/alerts/display-status";
import {show as showAlert, fetchTimeline} from "actions/alerts";

const labelStyle = {
  fontWeight: 300,
};

const displayNameStyle = {
  ...labelStyle,
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const paperStyle = {
  border: "1px solid rgb(224, 224, 224)",
  borderTop: "0px",
  color: "rgba(0, 0, 0, 0.54)",
  fontSize: "14px",
  height: "48px",
  padding: "0px 20px",
};

const gridStyle = {
  height: "100%",
  cursor: "pointer",
};

class Row extends React.Component {
  shouldComponentUpdate(nextProps) {
    return JSON.stringify(nextProps.alert) !== JSON.stringify(this.props.alert);
  }

  render() {
    const {dispatch, alert, square} = this.props;

    const navigate = alertId => {
      dispatch(showAlert({alertId}));
      dispatch(fetchTimeline({alertId}));
    };

    return (
      <PaperContainer elevation={0} style={paperStyle} square={square}>
        <Grid
          container
          alignItems="center"
          justify="space-around"
          spacing={0}
          style={gridStyle}
          onClick={() => navigate(alert.alertId)}
        >
          <Grid item xs={3} style={displayNameStyle}>
            {alert.displayName}
          </Grid>
          <Grid item xs={3} style={labelStyle}>
            <DisplayStatus status={alert.status} />
          </Grid>
          <Grid item xs={3} style={labelStyle}>
            {alert.typeLabel}
          </Grid>
          <Grid item xs={3} style={labelStyle}>
            <FormatDateTime date={alert.createdOn} />
          </Grid>
        </Grid>
      </PaperContainer>
    );
  }
}

export default connect()(Row);
