import {types} from "./types";

export const reinviteUser = options => {
  const {clientUserId, selectedOrganizationId} = options;

  return {
    type: types.reinviteUser,
    payload: {
      clientUserId,
      organizationId: selectedOrganizationId,
    },
  };
};
