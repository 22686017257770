const red = {
  50: "#ffeeee",
  100: "#facdcd",
  200: "#f29b9b",
  300: "#e66a6a",
  400: "#d64545",
  500: "#ba2525",
  600: "#a61b1b",
  700: "#911111",
  800: "#780a0a",
  900: "#610404",
};

export default red;
