export default {
  sysAdmin: "sysadmin",
  user: {
    approve: "user::approve",
  },
  well: {
    configure: "well::configure",
    control: "well::control",
  },
};
