import {types} from "./types";

export const createServiceAccount = options => {
  const {
    downholeLocations,
    organizationId,
    serviceName,
    webhooks,
    wellIds,
  } = options;

  return {
    type: types.createServiceAccount,
    payload: {
      downholeLocations,
      organizationId,
      serviceName,
      webhooks,
      wellIds,
    },
  };
};
