import {types} from "./types";

export const fetchOrganizationUser = options => {
  const {organizationId, clientUserId} = options;

  return {
    type: types.fetchOrganizationUser,
    payload: {
      organizationId,
      clientUserId,
    },
  };
};
