/**
 * Generate a sanitized search result object
 *
 * @param {object} well parsed well object
 * @param {number} index index of the first matched character on a search dimension
 *    defaults to positive infinity
 *    e.g. query = "dog", input = "big red dog", index = 8
 */
export const createSearchResult = (well, index) => ({
  data: well,
  index: isNaN(index) ? Number.POSITIVE_INFINITY : index,
});
