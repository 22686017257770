import React from "react";
import ListStyleMenu from "components/list-style-menu";

const AccessControls = props => {
  const {
    accessLevel,
    clientUserId,
    isInvitedUserApprover,
    organizationId,
    onAccessChanged,
  } = props;

  return [
    <ListStyleMenu
      key={0}
      primary={"Approver"}
      secondary={
        "Allows user to administrate the organization and invite users"
      }
      options={[
        {
          value: false,
          text: "Does not have access",
        },
        {
          value: true,
          text: "Has access",
        },
      ]}
      value={isInvitedUserApprover}
      onChange={value => {
        if (value.constructor === Boolean) {
          onAccessChanged({
            clientUserId,
            organizationId,
            accessLevel,
            isInvitedUserApprover: value,
          });
        }
      }}
      containerStyle={{margin: "16px 0 0", borderBottom: 0}}
    />,

    <ListStyleMenu
      key={1}
      primary={"Well Control"}
      secondary={
        "Allows user to update well running state, speed, and configuration"
      }
      options={[
        {
          value: "Limited",
          text: "Does not have access",
        },
        {
          value: "Full",
          text: "Has access",
        },
      ]}
      value={accessLevel}
      onChange={value => {
        if (value && value.constructor === String) {
          onAccessChanged({
            clientUserId,
            organizationId,
            accessLevel: value,
            isInvitedUserApprover,
          });
        }
      }}
      containerStyle={{margin: "0 0 16px"}}
    />,
  ];
};

export default AccessControls;
