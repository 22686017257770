export const CLASSIFICATION = {
  DIALED_IN: "dialed in",
  OVER_PUMPING: "overpumping",
  UNDER_PUMPING: "underpumping",
  UNCERTAIN: "unclassified",
};

export const CLASSIFICATION_MAP = {
  other: CLASSIFICATION.UNCERTAIN,
  underpumping: CLASSIFICATION.UNDER_PUMPING,
  overpumping: CLASSIFICATION.OVER_PUMPING,
  dialedin: CLASSIFICATION.DIALED_IN,
};

/**
 * lodash shorthand accessors for fields to be sorted on in a well object
 */
export const CLASSIFICATION_SORT = {
  NAME: "displayName",
  FIELD: "fieldName",
  TOTAL_LIQUID: "dailySummaries.liquidProduction",
  SPM: "dailySummaries.spm",
  CYCLES: "dailySummaries.cycles",
  FILLAGE: "dailySummaries.fillage",
};
