import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import EventMeta from "components/event-meta";

const containerStyle = {
  alignItems: "flex-start",
  borderBottom: "1px solid rgb(224, 224, 224)",
  flexDirection: "column",
  padding: "24px 16px",
};

export default ({actions = null, chart = null, children, event, onClick}) => {
  const buttonProps = onClick
    ? {
        button: true,
        onClick,
      }
    : {};

  return (
    <ListItem style={containerStyle} {...buttonProps}>
      <ListItemText
        primary={<EventMeta event={event} />}
        secondary={children}
        style={{width: "100%"}}
      />
      {chart}
      {actions}
    </ListItem>
  );
};
