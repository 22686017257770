import moment from "moment";

import {isEmpty} from "./is-empty";

export const formats = ["MM/DD/YYYY", "M/D/YYYY"];

export const date = value => {
  if (isEmpty(value)) {
    return;
  }

  if (!moment(value, formats, true).isValid()) {
    throw new Error(
      `Expected value to be a \`date\` with one of the formats \`[${formats}]\` but received \`${value}\``,
    );
  }
};
