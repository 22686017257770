import React from "react";

import SettingsDataTable from "./settings-data-table";

const styles = {
  container: {
    fontSize: "12px",
    lineHeight: "18px",
    padding: "10px 8px",
  },
  settings: {
    marginTop: "15px",
  },
};

export const StatusIndicatorTooltipContent = props => {
  return (
    <div style={styles.container}>
      {props.isRejected ? (
        "Your changes were not applied to the Lufkin device."
      ) : (
        <>
          We're in the process of updating your Lufkin device settings.
          <br />
          This may take some time depending on availability.
        </>
      )}

      <div style={styles.settings}>
        <SettingsDataTable {...props} />
      </div>
    </div>
  );
};

export default StatusIndicatorTooltipContent;
