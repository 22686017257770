import {show} from "actions/snackbar";
import {types} from "actions/on-call";
import fetch from "epics/fetch";
import getCookie from "utils/get-cookie";

export default (action$, store) => {
  return action$.ofType(types.unschedule).switchMap(({payload}) => {
    const token = getCookie("id_token");

    if (token) {
      const url = `/client-experience/user/${encodeURIComponent(
        payload.clientUserId,
      )}/calloutSchedulePeriod/delete/existing`;

      return fetch(
        url,
        {},
        {
          method: "DELETE",
        },
      ).flatMap(res => {
        if (res.status === 200 || res.status === 204) {
          return [
            show({
              message: "You've removed your existing on call schedule.",
            }),
          ];
        } else {
          return [
            show({
              message:
                "There was an issue trying to remove the existing on call schedule.",
            }),
          ];
        }
      });
    } else {
      return [
        show({
          message:
            "You are not authenticated to remove the existing on call schedule.",
        }),
      ];
    }
  });
};
