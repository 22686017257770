import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import {hideStartWell} from "actions/dialogs";
import {startWell} from "actions/wells";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Aux from "components/aux";
import Dialog from "components/dialog";
import FormWarning from "components/form-warning";
import React from "react";

const mapStateToProps = state => {
  return state.dialogs.startWell;
};

const start = (dispatch, well) => {
  return () => {
    dispatch(startWell(well));
    close(dispatch)();
  };
};

const close = dispatch => {
  return () => {
    dispatch(hideStartWell());
  };
};

const renderStartWell = props => {
  const {dispatch, well} = props;

  return (
    <Aux>
      <DialogContent>
        <DialogContentText>
          You will issue a start command. This may take a few moments to apply.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close(dispatch)} color="primary">
          Cancel
        </Button>
        <Button onClick={start(dispatch, well)} color="primary">
          Start Well
        </Button>
      </DialogActions>
    </Aux>
  );
};

const onSetTrr = ({dispatch, well}) => () => {
  dispatch(hideStartWell());
  window.location.href = `#/well/${encodeURIComponent(
    well.downholeLocation,
  )}/setup/total-reduction-ratio`;
};

const renderWarning = props => {
  const {dispatch} = props;

  const setTrrStyle = {
    color: "black",
    cursor: "pointer",
    textDecorationLine: "underline",
  };

  return (
    <Aux>
      <DialogContent>
        <FormWarning>
          No total reduction ratio has been configured. Please{" "}
          <span onClick={onSetTrr(props)} style={setTrrStyle}>
            set it
          </span>{" "}
          to proceed.
        </FormWarning>
      </DialogContent>
      <DialogActions>
        <Button onClick={close(dispatch)} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Aux>
  );
};

const hasTrr = well => well.operation && well.operation.totalReductionRatio;

const Component = props => {
  const {dispatch, isOpen, well} = props;

  if (!well) {
    return null;
  }

  return (
    <Dialog open={isOpen} onClose={close(dispatch)}>
      <DialogTitle>{`Start well ${well.displayName}`}</DialogTitle>
      {hasTrr(well) ? renderStartWell(props) : renderWarning(props)}
    </Dialog>
  );
};

export default connect(mapStateToProps)(Component);
