import colors from "theme/colors";
import {BELL_SHAPE} from "constants/icon-paths";

function mapData(d) {
  return [
    ...(d.alertAcknowledged || []),
    ...(d.alertCreated || []),
    ...(d.alertResolved || []),
  ];
}

function mapTime(d) {
  return new Date(d.createdOn);
}

function determineColor(d) {
  if (d.type === "alertCreated") {
    return colors.red[400];
  } else if (d.type === "alertAcknowledged") {
    return colors.blue[400];
  } else if (d.type === "alertResolved") {
    return colors.green[400];
  }

  return colors.grey[400];
}

const size = 0.8;
const dimensions = {width: 16.55 * size, height: 19.5 * size};

export default ({selection, register, scales}) => {
  const setPositionAndSize = scaleX => d => {
    const x = scaleX(mapTime(d)) - dimensions.width / 2 - 2;
    const y = scales.status(3) - Math.floor(dimensions.height / 2) - 4;
    return `translate(${x}, ${y}) scale(${size} ${size})`;
  };

  const group = selection.append("g");

  return register({
    draw(props) {
      const visibility =
        props.config.alerts && props.config.alerts.state === 0
          ? "hidden"
          : "visible";

      const paths = group.selectAll("path").data(mapData(props.data));
      paths.exit().remove();
      paths.merge(paths).style("visibility", visibility);

      paths
        .enter()
        .append("path")
        .style("visibility", visibility)
        .style("stroke", determineColor)
        .style("fill", colors.transparent)
        .style("stroke-width", 3)
        .attr("transform", setPositionAndSize(scales.time))
        .attr("d", BELL_SHAPE);
    },

    zoom({transform}) {
      group
        .selectAll("path")
        .style("stroke", determineColor)
        .attr("transform", setPositionAndSize(transform.rescaleX(scales.time)))
        .attr("d", BELL_SHAPE);
    },
  });
};
