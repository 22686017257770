import Grid from "@material-ui/core/Grid";
import isEmpty from "lodash/isEmpty";
import React from "react";

import colors from "theme/colors";
import FormButton from "components/form-button";
import {roundWithUnits} from "utils/round-with-units";

import {cardLabelFactory, cardValueFactory} from "../../cards";

const RodStringSectionReadView = props => {
  const {
    section,
    idx,
    onEdit,
    styles = {},
    canEdit = false,
    hasContainer = false,
    editSection,
  } = props;

  const CardLabel = cardLabelFactory(6);
  const CardValue = cardValueFactory(6);

  if (isEmpty(section)) return null;

  return (
    <Grid
      key={idx}
      container
      style={{
        marginTop: "10px",
        borderTop:
          !hasContainer && idx > 0 ? `thin solid ${colors.grey[500]}` : "none",
      }}
    >
      <CardLabel label={"Type"} />
      <CardValue value={section.rodType.label} />

      <CardLabel label={"Diameter"} />
      <CardValue value={roundWithUnits(section.rodDimensions.diameter, 2)} />

      <CardLabel label={"Interval"} />
      <CardValue value={roundWithUnits(section.interval, 2)} />

      {canEdit && (
        <FormButton
          disabled={!!editSection}
          onClick={() => onEdit(idx)}
          style={styles.editButton}
        >
          Edit Section
        </FormButton>
      )}
    </Grid>
  );
};

export default RodStringSectionReadView;
