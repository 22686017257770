import Grid from "@material-ui/core/Grid";
import React from "react";
import Typography from "@material-ui/core/Typography";

import colors from "theme/colors";

import Dynocard from "components/dynocard";

const margin = {
  top: 20,
  right: 20,
  bottom: 40,
  left: 40,
};

const height = 250;

const styles = {
  card: {
    fill: colors.transparent,
    strokeWidth: 3,
  },
  incompleteSetupSubtitle: {
    marginTop: "10px",
    marginBottom: "auto",
    textAlign: "center",
  },
  incompleteSetupTitle: {
    marginTop: "auto",
    textAlign: "center",
  },
  link: {
    color: "#fc6621",
    cursor: "pointer",
  },
  svg: {
    display: "block",
  },
  textAlignCenter: {
    textAlign: "center",
  },
};

const mapData = data =>
  data.map(ele => ({
    x: ele.position.value,
    y: ele.load.value,
  }));

const renderSetupNeeded = ({goToSetup, toggleComparisonsView, width}) => {
  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      spacing={0}
      style={{
        height,
        padding: "0px 25px",
        width,
      }}
    >
      <Typography variant="h6" style={styles.incompleteSetupTitle}>
        Analysis could not be completed
      </Typography>
      <Typography variant="subtitle1" style={styles.incompleteSetupSubtitle}>
        Confirm your{" "}
        <span style={styles.link} onClick={goToSetup}>
          setup
        </span>{" "}
        to enable analysis on future cards or{" "}
        <span style={styles.link} onClick={toggleComparisonsView}>
          view
        </span>{" "}
        POC data
      </Typography>
    </Grid>
  );
};

export default props => {
  const {generatedCards, rawCards, showRawComparisons} = props;
  const cards = showRawComparisons ? rawCards : generatedCards;

  if (!cards && !showRawComparisons) {
    return renderSetupNeeded(props);
  }

  if (!cards) {
    return null;
  }

  return (
    <div style={{width: "auto", height: "auto"}}>
      <Dynocard
        margin={margin}
        surfaceData={mapData(cards.surfaceCard)}
        downholeData={mapData(cards.downholeCard)}
      />
    </div>
  );
};
