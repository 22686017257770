export const downloadFile = (name, content) => {
  const link = document.createElement("a");

  link.setAttribute("href", encodeURI(content));
  link.setAttribute("download", name);

  document.body.appendChild(link); // Required for FF

  link.click();

  document.body.removeChild(link);
};
