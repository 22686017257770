import {
  blue,
  warmGrey,
  red,
  yellowVivid,
  greenVivid,
} from "../theme/updated-colors";

export const LABELS = {
  WELLBORE: "Wellbore",
  ROD: "Rod Section",
  PLUNGER: "Pump",
  NO_DOGLEG_SEVERITY: "Wellbore",
  DOGLEG_SEVERITY: "Dogleg Severity",
  EQUIPMENT: "Equipment",
  SPEED_RANGE: "Speed Range",
  SCHEDULED_DOWNTIME: "Scheduled Downtime",
};

export const COLORS = {
  WELLBORE: blue[500],
  ROD: yellowVivid[500],
  ROD_OUTLINE: yellowVivid[600],
  PLUNGER: greenVivid[300],
  PLUNGER_OUTLINE: greenVivid[400],
  NO_DOGLEG_SEVERITY: warmGrey[100],
  DOGLEG_SEVERITY: red[500],
  DOGLEG_OUTLINE: warmGrey[200],
  ANNOTATIONS: blue[500],
};

export const ID = {
  WELLBORE: "WELLBORE",
  ROD: "ROD",
  PLUNGER: "PLUNGER",
  NO_DOGLEG_SEVERITY: "NO_DOGLEG_SEVERITY",
  DOGLEG_SEVERITY: "DOGLEG_SEVERITY",
};

export const VIEW = {
  WELLBORE: "wellbore",
  EQUIPMENT: "equipment",
  DOGLEG: "dogleg",
  SPEED_RANGE: "speedRange",
  SCHEDULED_DOWNTIME: "scheduledDowntime",
};

export const CCR_COLORS = {
  normal: "#DDFBEE",
  gasinterference: blue[500],
  pumpoff: yellowVivid[200],
  anomaly: red[300],
  others: warmGrey[300],
};

export const CCR_HIGHLIGHT_COLORS = {
  normal: greenVivid[50],
  gasinterference: blue[200],
  pumpoff: yellowVivid[100],
  anomaly: red[200],
  others: warmGrey[200],
};

export const CLASSIFICATION_LABELS = {
  normal: "Normal",
  gasinterference: "Gas",
  pumpoff: "Pumpoff",
  anomaly: "Anomaly",
  others: "Unclassified",
};

// Defines both the list of known classification types and the order to stack them in the well health CCR graph
export const CLASSIFICATIONS = [
  "anomaly",
  "pumpoff",
  "gasinterference",
  "others",
  "normal",
];

export const STRESS_COLORS = {
  STRESS: blue[400],
  STRESS_MEDIAN: blue[600],
  RODS: greenVivid[400],
  RODS_MEDIAN: greenVivid[600],
};
