import {show} from "actions/snackbar";
import {types} from "actions/wells";
import fetch from "epics/async-fetch";
import {createSwitchEpic} from "epics/create-epic";

export default createSwitchEpic([types.setSpeedRange], async ({payload}) => {
  await fetch(
    `/wells/${encodeURIComponent(payload.downholeLocation)}/speed-range`,
    {},
    {
      method: "PUT",
      body: {
        downholeLocation: payload.downholeLocation,
        max: parseFloat(payload.max),
        min: parseFloat(payload.min),
      },
    },
  );

  return payload.well
    ? [
        show({
          message: `Speed range change for \n ${
            payload.well.displayName
          } was submitted successfully!`,
        }),
      ]
    : [];
});
