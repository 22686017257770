import Grid from "@material-ui/core/Grid";
import React from "react";
import Typography from "@material-ui/core/Typography";
import colors from "theme/colors";
import theme from "theme";
import formatWithUnits from "@ambyint/common/utils/format/format-with-units";

export const CardLabel = props => (
  <Typography
    variant="body2"
    style={{
      color: colors.grey[600],
      fontSize: "0.93em",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }}
    component="p"
  >
    {props.label}
  </Typography>
);

export const CardValue = props => (
  <Typography
    variant="body2"
    style={{fontSize: "0.93em", color: colors.grey[700]}}
    component="p"
  >
    {formatWithUnits(props.value)}
  </Typography>
);

export const CardRow = props => (
  <Grid container justify={"space-between"}>
    <Grid item xs={12} sm={12} md>
      <CardLabel label={props.label} />
    </Grid>
    <Grid item xs={12} sm={12} md>
      <CardValue value={props.value} />
    </Grid>
  </Grid>
);

export const CardRowSecondary = props => (
  <Grid container justify={"space-between"}>
    <Grid item xs={12} sm={12} md>
      <CardLabel label={props.label} />
    </Grid>
    <Grid item xs={12} sm={12} md style={{marginBottom: "8px"}}>
      <CardValue value={props.primary} />
      <CardValue
        value={props.secondary}
        style={{color: theme.palette.text.secondary}}
      />
    </Grid>
  </Grid>
);

export const cardLabelFactory = size => ({label}) => (
  <Grid item xs={size} style={{paddingTop: "8px"}}>
    <Typography
      variant="body2"
      style={{fontSize: "0.93em", color: colors.grey[600]}}
      component="p"
    >
      {label}
    </Typography>
  </Grid>
);

export const cardValueFactory = size => ({value}) => (
  <Grid item xs={size} style={{paddingTop: "8px"}}>
    <CardLabel label={formatWithUnits(value)} />
  </Grid>
);
