import {isEmpty} from "./is-empty";

export const number = value => {
  if (isEmpty(value)) {
    return;
  }

  if (!isFinite(Number(value))) {
    throw new Error(
      `Expected value to be a \`number\` but received \`${value}\``,
    );
  }
};
