import {show} from "actions/snackbar";
import {setOne, types} from "actions/wells";
import getUser from "utils/get-user";
import fetch from "epics/fetch";

import parseSaveDownholeInformation from "./parse-save-downhole-information";

export default action$ =>
  action$.ofType(types.saveDownholeInformation).switchMap(({payload}) =>
    fetch(
      `/optimization/well/${encodeURIComponent(
        payload.downholeLocation,
      )}/downholeInformation`,
      {},
      {
        body: parseSaveDownholeInformation(
          payload.data,
          getUser().unitsOfMeasure,
        ),
        method: "PUT",
      },
    ).flatMap(() => [
      setOne({
        wellId: payload.wellId,
        data: {
          downholeInformation: payload.data,
        },
      }),
      show({
        message: "You have successfully updated the Downhole Information",
      }),
    ]),
  );
