import {combineReducers} from "redux";
import {persistReducer} from "redux-persist";
import {reducer as reduxForm} from "redux-form";
import {routerReducer as routing} from "react-router-redux";
import storage from "redux-persist/lib/storage";

import {types} from "actions/auth";

import {importSettings} from "./import-settings";
import acknowledgeAlertDialog from "./acknowledge-alert-dialog";
import alerts from "./alerts";
import analytics from "./analytics";
import appBar from "./app-bar";
import appDrawer from "./app-drawer";
import auth from "./auth";
import casingSpecs from "./casing-specs";
import couplings from "./parts/couplings";
import crankWeights from "./crank-weights";
import cranks from "./cranks";
import dialogs from "./dialogs";
import discardFormDialog from "./discard-form-dialog";
import fieldNames from "./field-names";
import forms from "./forms";
import guideMaterials from "./parts/guide-materials";
import guides from "./parts/guides";
import home from "./home";
import onCall from "./on-call";
import organizationManageServiceAccount from "./organizations/manage-service-account";
import organizationManageUser from "./organizations/manage-user";
import organizationServiceAccounts from "./organizations/service-accounts";
import organizationUsers from "./organizations/users";
import organizationView from "./organization-view";
import organizations from "./organizations";
import organizationsEvents from "./organizations/events";
import organizationsWells from "./organizations/wells";
import paging from "./paging";
import pumpjacks from "./pumpjacks";
import recommendationActionDialog from "./recommendation-action-dialog";
import resolveAlertDialog from "./resolve-alert-dialog";
import rodDimensions from "./parts/rod-dimensions";
import rods from "./parts/rods";
import search from "./search";
import snackbar from "./snackbar";
import tags from "./tagging";
import tubing from "./tubing";
import wellAssignmentListDialog from "./well-assignment-list-dialog";
import wellSummaryDialog from "./well-summary-dialog";
import wells from "./wells";
import wellTickets from "./well-tickets";
import fieldOptimization from "./field-optimization";
import deviationReport from "./deviation-report";
import wellHealth from "./wells/well-health";
import wellSurveillance from "./wells/well-surveillance";

const whitelist = ["importSettings"];

const persistConfig = {
  key: "root",
  storage,
  whitelist,
};

const appReducer = persistReducer(
  persistConfig,
  combineReducers({
    acknowledgeAlertDialog,
    alerts,
    analytics,
    appBar,
    appDrawer,
    auth,
    casingSpecs,
    couplings,
    crankWeights,
    cranks,
    deviationReport,
    dialogs,
    discardFormDialog,
    fieldNames,
    fieldOptimization,
    form: reduxForm,
    forms,
    guideMaterials,
    guides,
    home,
    importSettings,
    onCall,
    organizationManageServiceAccount,
    organizationManageUser,
    organizationServiceAccounts,
    organizationUsers,
    organizationView,
    organizations,
    organizationsEvents,
    organizationsWells,
    paging,
    pumpjacks,
    recommendationActionDialog,
    resolveAlertDialog,
    rodDimensions,
    rods,
    routing,
    search,
    snackbar,
    tags,
    tubing,
    wellAssignmentListDialog,
    wellHealth,
    wellSummaryDialog,
    wells,
    wellSurveillance,
    wellTickets,
  }),
);

export default (state, action) => {
  // Clear state on sign out.
  if (action.type === types.signOut) {
    state = {
      ...state,
    };

    whitelist.forEach(item => {
      delete state[item];
    });
  }

  return appReducer(state, action);
};
