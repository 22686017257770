import React from "react";
import {connect} from "react-redux";
import {Route, Switch, matchPath, Redirect} from "react-router-dom";
import {ConnectedRouter, push} from "react-router-redux";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import ErrorBoundary from "components/error-boundary";
import {history} from "store";
import colors from "theme/colors";

const sideWidth = 230;
const containerStyle = {
  position: "fixed",
  width: `${sideWidth}px`,
  height: "100vh",
  paddingTop: "0px",
  display: "inline-block",
  background: colors.grey[100],
  zIndex: 1,
  overflowY: "auto",
};

const childContainerStyle = {
  position: "absolute",
  padding: 16,
  left: `${sideWidth}px`,
  top: 0,
  right: 0,
  bottom: 0,
  background: colors.grey[100],
};

const mapStateToProps = state => {
  return {
    currentPath: state.routing.location.pathname,
  };
};

class SideMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {selectedNavigationPath: ""};
  }

  render() {
    const {
      childStyle,
      exactPathPrefix,
      routePathPrefix,
      currentPath,
      dispatch,
      menuItems,
      redirectPath,
    } = this.props;

    const selectItem = path => dispatch(push(`${exactPathPrefix}${path}`));

    const displayItem = (item, key) => {
      let routes = [
        <Route
          style={{backgroundColor: "red"}}
          key={key}
          path={`${routePathPrefix || exactPathPrefix}${item.pathname}`}
          exact
          render={item.component}
        />,
      ];

      if (item.routes) {
        // Routes can have subroutes.
        // The subroutes act basically like independent routes currently,
        // except they share the same icon as the main route.
        routes = routes.concat(item.routes.map(displayItem));
      }

      return routes;
    };

    const isInPath = item => {
      // Includes subroutes
      return (item.routes || []).reduce((isMatch, subitem) => {
        return (
          isMatch ||
          matchPath(currentPath, {
            path: `${routePathPrefix || exactPathPrefix}${subitem.pathname}`,
          })
        );
      }, matchPath(currentPath, {path: `${routePathPrefix || exactPathPrefix}${item.pathname}`}));
    };

    const navigationItem = (key, item) => {
      const style = isInPath(item)
        ? {
            color: colors.grey[800],
          }
        : {
            color: colors.grey[600],
          };

      return (
        <ListItem
          key={key}
          button
          style={{
            ...style,
            borderLeft: isInPath(item)
              ? `4px solid ${colors.ambyBlue[200]}`
              : `4px solid rgba(255, 255, 255, 0.0)`,
          }}
          onClick={() => {
            if (!currentPath.endsWith(item.pathname)) {
              if (this.selectedComponentIsDirty) {
                this.setState({
                  selectedNavigationPath: item.pathname,
                });
              } else {
                selectItem(item.pathname);
              }
            }
          }}
        >
          {item.Icon && (
            <ListItemIcon
              style={
                isInPath(item)
                  ? {
                      ...style,
                      color: "white",
                      minWidth: "unset",
                      backgroundColor: colors.ambyBlue[400],
                      borderRadius: "2em",
                      width: 20,
                      height: 20,
                      padding: 4,
                      marginRight: 30,
                    }
                  : {
                      ...style,
                      minWidth: "unset",
                      width: 20,
                      height: 20,
                      padding: 4,
                      marginRight: 30,
                    }
              }
            >
              <item.Icon style={{fontSize: 20}} />
            </ListItemIcon>
          )}
          <ListItemText primary={<div style={style}>{item.label}</div>} />
        </ListItem>
      );
    };

    return (
      <div
        style={{
          backgroundColor: colors.white,
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <div
          style={{
            ...containerStyle,
            ...(this.props.style || {}),
          }}
        >
          <div>
            <List>
              {menuItems.map((item, key) =>
                navigationItem(key, {...item, Icon: item.icon || item.Icon}),
              )}
            </List>
          </div>
        </div>

        <div style={childContainerStyle}>
          <div style={childStyle}>
            <ErrorBoundary>
              <ConnectedRouter history={history}>
                <Switch>
                  {menuItems.map(displayItem)}
                  {redirectPath && (
                    <Route>
                      <Redirect to={redirectPath} />
                    </Route>
                  )}
                </Switch>
              </ConnectedRouter>
            </ErrorBoundary>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps)(SideMenu);
