import {of} from "rxjs/observable/of";

import {completeSignInWithOAuth, setAuth, types} from "actions/auth";
import addAuthHeaders from "epics/add-auth-headers";
import fetchApi from "epics/fetch";
import setToken from "utils/set-token";

export default action$ => {
  return action$.ofType(types.verifyOAuth).switchMap(({payload}) => {
    const {clientUserId, callbackToken} = payload;

    return fetchApi(
      `/client-experience/${encodeURIComponent(clientUserId)}/acceptTerms`,
      {},
      addAuthHeaders({
        body: {clientUserId},
        method: "POST",
        token: callbackToken,
      }),
    )
      .flatMap(res => {
        if (res.status >= 200 && res.status <= 300) {
          setToken(callbackToken);

          return of(
            completeSignInWithOAuth({
              callbackToken,
              clientUserId,
            }),
          );
        }

        return of(
          setAuth({
            isAuthenticated: false,
            isFetching: false,
          }),
        );
      })
      .catch(() => {
        return of(
          setAuth({
            isAuthenticated: false,
            isFetching: false,
            error:
              "Accepting terms failed. Please try again, or contact support if the issue continues.",
          }),
        );
      });
  });
};
