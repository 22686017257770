import updateAndMergeWell from "./update-and-merge-well";

export default ({state, action}) => {
  const byWellId = updateAndMergeWell(state, action, well => ({
    ...well,
    recommendations: (well.recommendations || []).filter(
      r => r.recommendationId !== action.payload.recommendationId,
    ),
  }));

  return {
    ...state,
    byWellId,
  };
};
