import React from "react";

import TextFieldListMenu from "components/forms/text-field-list-menu";

const CrankPinPositionField = pumpingUnit => field => {
  let crankPinPositions = [];
  let crankPinPositionOptions = [];

  if (pumpingUnit) {
    crankPinPositions = pumpingUnit
      ? pumpingUnit.geometry.crankPinPositions
      : {};
    crankPinPositionOptions = Object.keys(crankPinPositions)
      .filter(key => crankPinPositions[key])
      .map(key => ({
        text: `${key.replace(/[^0-9]/g, "")} (${crankPinPositions[key]})`,
        value: key.replace(/[^0-9]/g, ""),
      }));
  }

  return (
    <TextFieldListMenu
      name={field.input.name}
      label={field.label}
      options={crankPinPositionOptions}
      getValue={option => option.value.replace(/[^0-9]/g, "")}
      value={field.input.value}
      onChange={field.input.onChange}
      error={field.meta.touched && field.meta.error}
      fullWidth
    />
  );
};

export default CrankPinPositionField;
