import {types} from "./types";

export const setLufkinMalfunction = options => {
  const {values, well} = options;

  return {
    type: types.setLufkinMalfunction,
    payload: {
      values,
      well,
    },
  };
};
