import {types} from "./types";

/**
 * Creates an action that sets the active path.
 * @param {String} path
 * @returns {{type: string, payload: {path: string}}}
 */
export const setActivePath = path => {
  return {
    type: types.setActivePath,
    payload: {
      path,
    },
  };
};
