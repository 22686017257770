import React from "react";
import {Field} from "redux-form";

import Typography from "@material-ui/core/Typography";

import valueOf from "utils/value-of";

import DisplayUnits from "./display-units";

export const PlainText = props => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
      }}
    >
      {props.label && (
        <Typography style={{display: "inline"}}>{props.label}:</Typography>
      )}
      <Typography
        style={{
          display: "inline",
          marginLeft: props.label ? 8 : 0,
          ...props.style,
        }}
      >
        {valueOf(props.input.value)}
      </Typography>
      <DisplayUnits input={props.input} />
    </div>
  );
};

export default props => <Field {...props} component={PlainText} />;
