import process from "./process";
import merge from "./merge-well";

export default ({state, action}) => {
  if (!action.payload.data) {
    return state;
  }

  const data = action.payload.data;

  const byWellId = data.entities.wells || {};
  const allWellIds = data.result;
  const byDownholeLocation = {};

  allWellIds.forEach(wellId => {
    if (state.allWellIds.includes(wellId)) {
      const existingWell = state.byWellId[wellId];
      const newWell = state.byWellId[wellId];
      byWellId[wellId] = merge(existingWell, newWell);
    }

    // Map well IDs to Downhole location
    // *** This should be removed once we fully transition off relying on downhole
    // *** location as a means of well identification
    const downholeLocation = byWellId[wellId].downholeLocation;
    byDownholeLocation[downholeLocation] = wellId;
  });

  return process({
    ...state,
    allWellIds,
    byDownholeLocation,
    byWellId,
  });
};
