import React from "react";
import Typography from "@material-ui/core/Typography/Typography";
import withstyles from "@material-ui/core/styles/withStyles";

import emptyBox from "images/empty-box.svg";

const styles = ({colors}) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "70vh",
    color: colors.warmGrey[900],
  },
  caption: {
    textAlign: "center",
  },
});

const EmptyStateOne = ({classes}) => {
  return (
    <div className={classes.container}>
      <img src={emptyBox} alt="" className={classes.img} />
      <Typography color="inherit" className={classes.caption} variant="h5">
        No recommendations yet.
      </Typography>
      <Typography color="inherit" className={classes.caption} variant="body2">
        When available, recommendations <br />
        will show up here.
      </Typography>
    </div>
  );
};

export default withstyles(styles)(EmptyStateOne);
