import React from "react";
import {connect} from "react-redux";
import {reduxForm} from "redux-form";

import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import FormButton from "components/form-button";
import ToggleButton from "components/toggle-button";

import moment from "moment";
import {schedule} from "actions/on-call";

const mapStateToProps = state => {
  return {
    onCall: state.onCall,
    user: state.auth.user,
  };
};

class ProfileOnCall extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allDay: true,
      startDate: moment().format("YYYY-MM-DD"),
      startTime: "07:00",
      endDate: moment()
        .add(1, "weeks")
        .format("YYYY-MM-DD"),
      endTime: "17:00",
      daysOfWeek: {
        sunday: true,
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
      },
      minOnCallDate: moment(),
      maxOnCallDate: moment().add(1, "year"),
    };
  }

  renderDaysOfWeekSelection = () => {
    const daysOfWeek = [
      {label: "SUN", id: "sunday"},
      {label: "MON", id: "monday"},
      {label: "TUE", id: "tuesday"},
      {label: "WED", id: "wednesday"},
      {label: "THU", id: "thursday"},
      {label: "FRI", id: "friday"},
      {label: "SAT", id: "saturday"},
    ];

    return (
      <div style={{display: "inline-flex"}}>
        {daysOfWeek.map((day, key) => (
          <ToggleButton
            key={key}
            toggled={this.state.daysOfWeek[day.id]}
            onClick={(event, value) => {
              this.setState({
                daysOfWeek: {
                  ...this.state.daysOfWeek,
                  [day.id]: !this.state.daysOfWeek[day.id],
                },
              });
            }}
          >
            {day.label}
          </ToggleButton>
        ))}
      </div>
    );
  };

  onDateChange = event => {
    const {value} = event.target;
    value && this.setState({endDate: value});
  };

  render() {
    const {minOnCallDate, maxOnCallDate} = this.state;
    const {dispatch, onCall, user} = this.props;

    if (onCall.isFetching) return <LinearProgress />;

    const getChecked = key => this.state[key] || (user && user[key]) || false;
    const getValue = key => this.state[key] || (user && user[key]) || "";

    const maxDateValidator =
      moment(getValue("endDate")).isAfter(maxOnCallDate) &&
      "Cannot schedule more than a year in the future";
    const minDateValidator =
      moment(getValue("endDate")).isBefore(minOnCallDate) &&
      "Cannot schedule for dates in the past";

    const dateValidator = maxDateValidator || minDateValidator;
    const invalidDate = !!dateValidator;

    return (
      <Grid container style={{maxWidth: "500px"}} spacing={2}>
        <Grid item xs={12}>
          <TextField
            error={invalidDate}
            id="on-call-date-picker"
            label={"Schedule Until"}
            type="date"
            inputProps={{
              min: minOnCallDate.format("YYYY-MM-DD"),
            }}
            value={getValue("endDate")}
            onChange={this.onDateChange}
            helperText={dateValidator}
            required
          />
        </Grid>

        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Switch
                checked={getChecked("allDay")}
                onChange={(event, checked) => {
                  this.setState({allDay: checked});
                }}
              />
            }
            label="All Day"
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="on-call-time-picker-start"
            label={"Start Time"}
            type="time"
            disabled={this.state.allDay}
            value={getValue("startTime")}
            onChange={event => {
              if (event.target.value) {
                this.setState({startTime: event.target.value});
              }
            }}
            required
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="on-call-time-picker-end"
            label={"End Time"}
            type="time"
            disabled={this.state.allDay}
            value={getValue("endTime")}
            onChange={event => {
              if (event.target.value) {
                this.setState({endTime: event.target.value});
              }
            }}
            required
          />
        </Grid>

        <Grid item xs={12}>
          {this.renderDaysOfWeekSelection()}
        </Grid>

        <Grid item xs={12}>
          <FormButton
            disabled={invalidDate}
            onClick={() => {
              dispatch(
                schedule({
                  clientUserId: user.clientUserId,
                  ...Object.assign({}, this.state, {
                    wells: user.wells,
                  }),
                }),
              );
            }}
          >
            Update On Call Schedule
          </FormButton>
        </Grid>
      </Grid>
    );
  }
}

const Component = reduxForm({
  form: "on-call-edit-view",
  enableReinitialize: true,
})(ProfileOnCall);

export default connect(mapStateToProps)(Component);
