export const recommendationKeyframes = {
  "@keyframes move-rec-left": {
    "40%": {
      transform: "inherit",
    },
    "100%": {
      transform: [["translateX(-100%)", "translateX(-16px)"]],
    },
  },

  "@keyframes move-up-3": {
    "40%": {
      transform: "scale(1.04)",
    },
    "80%": {
      transform: [
        ["translate(200%, -100%)", "translate(32px, -16px)", "scale(1.04)"],
      ],
    },
    "100%": {
      transform: [["translate(200%, -100%)", "translate(32px, -16px)"]],
    },
  },
  "@keyframes move-up-4": {
    "40%": {
      transform: "scale(1.04)",
    },
    "80%": {
      transform: [
        ["translate(300%, -100%)", "translate(48px, -16px)", "scale(1.04)"],
      ],
    },
    "100%": {
      transform: [["translate(300%, -100%)", "translate(48px, -16px)"]],
    },
  },
  "@keyframes move-up-5": {
    "40%": {
      transform: "scale(1.04)",
    },
    "80%": {
      transform: [
        ["translate(400%, -100%)", "translate(64px, -16px)", "scale(1.04)"],
      ],
    },
    "100%": {
      transform: [
        ["translate(400%, -100%)", "translate(64px, -16px)", "scale(1)"],
      ],
    },
  },
};

export const moveRecAnimation = columnCount => {
  return {
    [`&:nth-child(${columnCount}n+${columnCount + 1})`]: {
      zIndex: 1000,
      animation: [["1.21s", `$move-up-${columnCount}`]],
    },
    [`&:not(:nth-child(${columnCount}n+1))`]: {
      zIndex: 100,
      animation: [["1.21s", `$move-rec-left`]],
    },
  };
};

export default {
  recommendationKeyframes,
  moveRecAnimation,
};
