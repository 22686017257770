import compose from "lodash/fp/compose";
import {connect} from "react-redux";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";

import {DEVIATION_SORT} from "constants/deviation";
import {eventAction, eventCategory} from "constants/analytics";
import {sendAnalyticsEvent} from "components/analytics";
import {setDeviationSortBy} from "actions/deviation";

const {MAGNITUDE, FIELD, NAME, PERCENT} = DEVIATION_SORT;

const styles = ({palette: {text}}) => ({
  root: {
    marginLeft: "auto",
  },
  select: {
    color: text.secondary,
    "&:hover": {
      color: text.primary,
    },
  },
});

const mapStateToProps = ({deviationReport: {sort}}) => ({sort});
const mapDispatchToProps = dispatch => ({
  handleChange: e => {
    sendAnalyticsEvent(
      eventCategory.deviation,
      eventAction.sort,
      e.target.value,
    );
    dispatch(setDeviationSortBy(e.target.value));
  },
});

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
);

const SortMenu = ({classes, handleChange, sort}) => (
  <form className={classes.root}>
    <FormControl>
      <Select
        className={classes.select}
        disableUnderline
        value={sort}
        onChange={handleChange}
      >
        <MenuItem value={NAME}>{NAME}</MenuItem>
        <MenuItem value={FIELD}>{FIELD}</MenuItem>
        <Divider />
        <MenuItem value={MAGNITUDE}>{MAGNITUDE}</MenuItem>
        <MenuItem value={PERCENT}>{PERCENT}</MenuItem>
      </Select>
    </FormControl>
  </form>
);

export default enhance(SortMenu);
