import filter from "lodash/filter";
import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";

import colors from "@ambyint/colors";

import {FileUpload} from "../file-upload";

import {Body} from "./body";
import {Footer} from "./footer";
import {Summary} from "./summary";

const styles = {
  body: {
    padding: "0 16px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  footer: {
    padding: 16,
    paddingTop: 50,
  },
  header: {
    flexShrink: "0",
    padding: 16,
    paddingBottom: 50,
    borderBottom: `5px solid ${colors.ambyBlue[400]}`,
  },
  summary: {
    padding: "0 16px",
    paddingTop: 8,
    borderTop: `1px solid ${colors.ambyBlue[50]}`,
  },
};

export const Mappings = props => {
  const {
    data,
    errors,
    headers,
    loading,
    onError,
    onRead,
    onRemoveErrors,
    onUpload,
    settings,
  } = props;
  const {hideIgnored, mappings, onlyShowErrors} = settings;
  const errorCount = filter(errors, row => row.length).length;
  const rowCount = data.length;
  const limit = 50;

  if (!mappings) {
    return null;
  }

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <Typography variant={"h4"} style={{marginBottom: 10}}>
          Upload Production Data
        </Typography>
        <Typography variant={"subtitle1"}>
          Below is a preview of your data, displaying up to a maximum of {limit}{" "}
          rows. Confirm the column mappings and remove errors in order to
          process the file. At any time, drop a file to{" "}
          <FileUpload onError={onError} onRead={onRead}>
            replace
          </FileUpload>{" "}
          the current one.
        </Typography>
      </div>
      <Body
        containerStyle={styles.body}
        data={data}
        errors={errors}
        headers={headers}
        hideIgnored={hideIgnored}
        limit={limit}
        mappings={mappings}
        onlyShowErrors={onlyShowErrors}
        settings={settings}
      />
      {loading && <LinearProgress />}
      <Summary
        containerStyle={styles.summary}
        errorCount={errorCount}
        rowCount={rowCount}
      />
      <Footer
        containerStyle={styles.footer}
        errorCount={errorCount}
        loading={loading}
        mappings={mappings}
        onRemoveErrors={onRemoveErrors}
        onUpload={onUpload}
        rowCount={rowCount}
        settings={settings}
      />
    </div>
  );
};
