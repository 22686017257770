import {
  types,
  toggleManageTagsDialog,
  clearSelectedWells,
  clearSelectedTags,
  rehydrateTags,
} from "actions/tagging";
import {setActivePath} from "actions/wells";
import {show} from "actions/snackbar";
import {createMergeEpic} from "epics/create-epic";
import fetch from "epics/async-fetch";

export const updateWellTags = createMergeEpic(
  [types.updateWellTags],
  async ({payload: {downholeLocations, tags}}, store) => {
    const {
      tags: {tagDrawerOpen},
    } = store.getState();

    let message = "We have successfully updated your well tags!";

    if (tags.additions.length) {
      try {
        fetch(
          "/tags",
          {},
          {
            method: "POST",
            body: {
              tags: tags.additions,
              downholeLocations: downholeLocations.additions,
            },
          },
        );
      } catch (e) {
        message =
          "We could not add new tags to your wells. Please try again later.";
        console.warn(e);
      }
    }

    if (tags.subtractions.length) {
      try {
        fetch(
          "/tags",
          {},
          {
            method: "PUT",
            body: {
              tags: tags.subtractions,
              downholeLocations: downholeLocations.subtractions,
            },
          },
        );
      } catch (e) {
        message =
          "We could not remove the desired tags from your wells. Please try again later.";
        console.warn(e);
      }
    }

    return [
      toggleManageTagsDialog(),
      clearSelectedWells(),
      clearSelectedTags(),
      show({message}),
      setActivePath(null),
      ...(tagDrawerOpen ? [] : [rehydrateTags()]), // manually rehydrate tags if the drawer is closed
    ];
  },
);
