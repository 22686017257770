export const types = {
  fetch: "guide-materials:fetch",
  filter: "guide-materials:filter",
  set: "guide-materials:set",
};

export const fetch = () => {
  return {
    type: types.fetch,
    payload: {},
  };
};

export const filter = ({phrase}) => {
  return {
    type: types.filter,
    payload: {
      phrase,
    },
  };
};

export const set = ({data}) => {
  return {
    type: types.set,
    payload: {
      data,
    },
  };
};
