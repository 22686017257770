import moment from "moment";

import fetch from "./async-fetch";

export default async payload => {
  const {downholeLocation, start, end} = payload;
  const url = `/wells/${encodeURIComponent(downholeLocation)}/cards`;
  const diff = moment(end).diff(start, "day");

  let requests = [];

  if (diff < 1) {
    requests.push(fetch(url, payload));
  } else {
    for (let i = 0; i < diff; i++) {
      requests.push(
        fetch(url, {
          downholeLocation,
          start: moment(end)
            .subtract(i + 1, "day")
            .toISOString(),
          end: moment(end)
            .subtract(i, "day")
            .toISOString(),
        }),
      );
    }
  }

  const cards = await Promise.all(requests);

  return cards.flat();
};
