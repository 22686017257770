import React from "react";
import {axisBottom, axisLeft, axisRight, axisTop} from "d3-axis";
import {select} from "d3-selection";

class Axis extends React.PureComponent {
  componentDidMount() {
    this.renderAxis();
  }

  componentDidUpdate() {
    this.renderAxis();
  }

  buildAxis(axisType) {
    return axisType()
      .scale(this.props.scale)
      .tickSize(-this.props.tickSize || 5)
      .ticks(this.props.ticks || 5);
  }

  renderAxis() {
    const node = this.refs.axis;
    let axis;

    if (this.props.orient === "top") axis = this.buildAxis(axisTop);
    if (this.props.orient === "right") axis = this.buildAxis(axisRight);
    if (this.props.orient === "bottom") axis = this.buildAxis(axisBottom);
    if (this.props.orient === "left") axis = this.buildAxis(axisLeft);

    select(node).call(axis);
  }

  render() {
    return (
      <g className="axis-base" ref="axis" transform={this.props.translate} />
    );
  }
}

export default Axis;
