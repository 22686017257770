import Grid from "@material-ui/core/Grid";
import React from "react";
import Typography from "@material-ui/core/Typography";

import BackToSafety from "./back-to-safety";

const textAlignCenter = {textAlign: "center"};

export default props => {
  const {title, message, onClick} = props;

  return (
    <Grid
      container
      spacing={3}
      justify="center"
      style={{
        margin: "16px",
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h6" style={{textAlign: "center"}}>
          {title}
        </Typography>
      </Grid>
      {message && (
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={textAlignCenter}>
            {message}
          </Typography>
        </Grid>
      )}
      <BackToSafety onClick={onClick} />
    </Grid>
  );
};
