import React from "react";
import GenericTableHeaderCell from "components/table-header-cell";

const TableHeaderCell = ({field, onClickColumn, ...props}) => (
  <GenericTableHeaderCell
    onClick={onClickColumn(field)}
    field={field}
    {...props}
  />
);
export default TableHeaderCell;
