import store from "store";
import {isDirty, getFormNames, reset} from "redux-form";
import {actions} from "react-redux-form";

const whitelist = [
  "login",
  "password-reset",
  "password-reset-change-password",
  "organization-user-search",
  "verify-email",
];

const ignoreWhitelisted = name => !whitelist.includes(name);

const getResetReactReduxFormAction = state => {
  return !state.forms.forms.$form.pristine && actions.reset("forms");
};

const getResetReduxFormAction = state => {
  const dirtyForm = (getFormNames()(state) || [])
    .filter(ignoreWhitelisted)
    .find(name => isDirty(name)(state));

  return dirtyForm && reset(dirtyForm);
};

export default () => {
  const state = store.getState();
  return getResetReduxFormAction(state) || getResetReactReduxFormAction(state);
};
