import {cloneDeep} from "lodash";

export default (state, action, updateFunc) => {
  const well = cloneDeep(
    state.all.find(a => a.alertId === action.payload.alertId) || {},
  );

  return [
    ...state.all.filter(a => a.alertId !== action.payload.alertId),
    updateFunc(well),
  ];
};
