import {connect} from "react-redux";
import get from "lodash/get";
import Grid from "@material-ui/core/Grid";
import React from "react";
import {reduxForm} from "redux-form";

import compose from "utils/compose";
import DecimalTextField from "components/forms/decimal-text-field";
import FormHeader from "components/form-header";
import getDefault from "utils/get-default-value";
import getUnits from "utils/get-units";
import PaperContainer from "components/paper-container";
import {round} from "@ambyint/common/utils/round";
import SaveCancelButtons from "components/save-cancel-buttons";
import {setLufkinStructuralLoad} from "actions/wells";
import validate from "utils/wells/lufkin-settings/validate-structural-load";

import getDisplayedSetting from "../get-displayed-setting";

const mapStateToProps = (state, props) => {
  const units = getUnits();
  const {well} = props;

  const rootSetting = get(well, "lufkinSettings.structuralLoad");
  const setting = getDisplayedSetting(rootSetting);

  const minLoad = get(setting, "minLoad", getDefault(units.load));
  const peakLoad = get(setting, "peakLoad", getDefault(units.load));
  const consecutiveMinLoad = get(setting, "consecutiveMinLoad", 5);
  const consecutivePeakLoad = get(setting, "consecutivePeakLoad", 5);

  return {
    initialValues: {
      ...setting,
      minLoad: {
        ...minLoad,
        value: round(minLoad.value),
      },
      peakLoad: {
        ...peakLoad,
        value: round(peakLoad.value),
      },
      consecutiveMinLoad,
      consecutivePeakLoad,
    },
  };
};

class StructuralLoadEditView extends React.Component {
  save = values => {
    const {well} = this.props;

    this.props.dispatch(
      setLufkinStructuralLoad({
        values,
        well,
      }),
    );
    this.props.onClose();
  };

  render() {
    const {
      handleSubmit,
      invalid,
      onClose,
      pristine,
      submitting,
      reset,
    } = this.props;

    return (
      <PaperContainer extraPadded style={{maxWidth: "750px"}}>
        <form onSubmit={handleSubmit(this.save)}>
          <Grid container direction={"row"} style={{maxWidth: 776}} spacing={2}>
            <Grid item xs={12}>
              <FormHeader>Structural Load Setpoint</FormHeader>
            </Grid>

            <Grid item xs={6} style={{padding: "8px"}}>
              <DecimalTextField
                fullWidth
                name={"minLoad"}
                label={"Minimum Load"}
                required
              />
            </Grid>

            <Grid item xs={6} style={{padding: "8px"}}>
              <DecimalTextField
                fullWidth
                name={"peakLoad"}
                label={"Peak Load"}
                required
              />
            </Grid>

            <Grid item xs={6} style={{padding: "8px"}}>
              <DecimalTextField
                fullWidth
                name={"consecutiveMinLoad"}
                label={"Consecutive Min Load"}
                required
              />
            </Grid>

            <Grid item xs={6} style={{padding: "8px"}}>
              <DecimalTextField
                fullWidth
                name={"consecutivePeakLoad"}
                label={"Consecutive Peak Load"}
                required
              />
            </Grid>
          </Grid>

          <div style={{marginLeft: "8px"}}>
            <SaveCancelButtons
              invalid={invalid}
              pristine={pristine}
              reset={compose(
                onClose,
                reset,
              )}
              submitting={submitting}
            />
          </div>
        </form>
      </PaperContainer>
    );
  }
}

const Component = reduxForm({
  form: "lufkin-structural-load-edit-view",
  enableReinitialize: true,
  validate,
})(StructuralLoadEditView);

export default connect(mapStateToProps)(Component);
