import {lighten} from "@material-ui/core/styles/colorManipulator";
import {scaleLinear} from "d3-scale";

export default scaleLinear()
  .domain([-25, -1, 0, 1, 25])
  .range([
    "#FD8902",
    lighten("#FD8902", 0.5),
    "#BDBDBD",
    lighten("#42599A", 0.5),
    "#42599A",
  ])
  .clamp(true);
