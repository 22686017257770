import React, {useRef, useEffect} from "react";
import scrollIntoView from "scroll-into-view-if-needed";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import clsx from "clsx";

import {eventAction, eventCategory} from "constants/analytics";
import {ClickEvent} from "components/analytics";

import {Tag} from "components/tag";

const styles = ({colors}) => ({
  result: {
    cursor: "pointer",
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 38,
    verticalAlign: "center",
  },
  selected: {
    backgroundColor: colors.warmGrey[50],
  },
  underline: {
    textDecoration: "underline",
  },
  tags: {
    display: "flex",
    paddingLeft: 7,
    flexWrap: "wrap",
  },
});

/**
 * Scroll to an element if it is out of view
 *
 * @param {object} ref the element to scroll to if it is not in view
 */
const scrollToRef = ref =>
  scrollIntoView(ref.current, {
    scrollMode: "if-needed",
    block: "nearest",
    inline: "nearest",
  });

const ignoreClick = e => {
  e.preventDefault();
  e.stopPropagation();
};

const SearchResult = ({
  classes,
  displayName,
  tags,
  onClick,
  onHover,
  selected = false,
  scrollStart = false,
  isRecentlyVisited = false,
  hideTags = false,
}) => {
  const ref = useRef({});
  const showTags = !hideTags && Array.isArray(tags);

  useEffect(
    () => {
      if (selected) scrollToRef(ref);
    },
    [selected, scrollStart],
  );

  return (
    <ClickEvent
      category={eventCategory.searchDialog}
      action={eventAction.click}
      label={
        isRecentlyVisited
          ? "Recently visited well search result clicked"
          : "Well search result clicked"
      }
    >
      <div
        ref={ref}
        className={clsx(classes.result, selected && classes.selected)}
        onClick={onClick}
        onMouseEnter={onHover}
      >
        <Typography
          className={clsx(selected && classes.underline)}
          variant="body1"
        >
          {displayName}
        </Typography>
        {showTags && (
          <div className={classes.tags}>
            {tags.map(tag => (
              <span key={`${displayName}_${tag.name}`} onClick={ignoreClick}>
                <Tag tag={tag} disabled hideIcon />
              </span>
            ))}
          </div>
        )}
      </div>
    </ClickEvent>
  );
};

export default withStyles(styles)(SearchResult);
