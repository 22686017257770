import React from "react";

import {hasControlForEpm} from "@ambyint/common/utils/devices/control-for-epm";

import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";

import getEpmPreference from "utils/get-epm-preference";
import Read from "./read";
import Write from "./write";

const shouldDisplayMotorDetailsWarning = ({well}) =>
  hasControlForEpm(well.devices) && !well.motorDetails;
const shouldDisplayCurrent = ({well}) =>
  hasControlForEpm(well.devices) && getEpmPreference() === "Current";

const renderMotorDetailsWarning = () => (
  <div style={{maxWidth: "500px"}}>
    <CardHeader title="Motor Violations Configuration" />
    <CardContent>
      <Typography variant="subtitle1">
        Well motor information must first be configured before motor violations
        can be set.
      </Typography>
    </CardContent>
  </div>
);

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {edit: false};
  }

  render() {
    const onEdit = () => this.setState({edit: true});
    const onCancel = () => this.setState({edit: false});

    if (shouldDisplayMotorDetailsWarning(this.props))
      return renderMotorDetailsWarning();

    const displayCurrent = shouldDisplayCurrent(this.props);

    return this.state.edit ? (
      <Write
        {...this.props}
        displayCurrent={displayCurrent}
        onClose={onCancel}
      />
    ) : (
      <Read {...this.props} displayCurrent={displayCurrent} onEdit={onEdit} />
    );
  }
}
