import {fetchAnalysisComplete, setEvents, types} from "actions/wells";

import fetchEvents from "./fetch-events";
import fetchMiniCards from "./fetch-mini-cards";

export default (action$, {dispatch}) => {
  return action$.ofType(types.fetchAnalysis).mergeMap(async ({payload}) => {
    try {
      const events = await fetchEvents(payload);

      dispatch(
        setEvents({
          downholeLocation: payload.downholeLocation,
          data: events,
        }),
      );

      const cards = await fetchMiniCards(payload);

      dispatch(
        setEvents({
          downholeLocation: payload.downholeLocation,
          data: cards,
        }),
      );
    } catch (e) {
      console.error(e);
    }

    return fetchAnalysisComplete({downholeLocation: payload.downholeLocation});
  });
};
