import React from "react";
import get from "lodash/get";
import Grid from "@material-ui/core/Grid";

import {roundWithUnits} from "utils/round-with-units";
import FormButton from "components/form-button";
import FormHeader from "components/form-header";
import getPrivileges from "utils/get-privileges";
import PaperContainer from "components/paper-container";
import privilegeTypes from "utils/privilege-types";
import unitsOf from "utils/units-of";
import valueOf from "utils/value-of";

import DownholeInformationEditView from "./downhole-information-edit-view";
import calculateTotalRodSectionsLength from "./calculate-total-rod-sections-length";
import formatGuideTypeKey from "./format-guide-type-key";
import formatRodSectionSummary from "./format-rod-section-summary";
import formatTubingDescription from "./format-tubing-description";
import {cardLabelFactory, cardValueFactory} from "../cards";

class DownholeInformationReadView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {showDownholeInformationEditView: false};
  }

  render() {
    const {well} = this.props;

    const privileges = getPrivileges(well.organization.id);

    const CardLabel = cardLabelFactory(6);
    const CardValue = cardValueFactory(6);

    const downholeInformation = well.downholeInformation || {};
    const sections = get(downholeInformation, "sections");

    if (this.state.showDownholeInformationEditView) {
      return (
        <DownholeInformationEditView
          onClose={() =>
            this.setState({showDownholeInformationEditView: false})
          }
          well={well}
        />
      );
    }

    const totalRodLength =
      sections && sections.length >= 1
        ? `${calculateTotalRodSectionsLength(sections)} ${unitsOf(
            sections[0].rodLength,
          )}`
        : 0;

    return (
      <PaperContainer extraPadded style={{maxWidth: "500px"}}>
        <Grid container>
          <Grid item xs={12}>
            <FormHeader>Downhole Information</FormHeader>
          </Grid>

          <CardLabel label={"Tubing"} />
          <CardValue
            value={formatTubingDescription(get(downholeInformation, "tubing"))}
          />

          <CardLabel label={"Plunger Diameter"} />
          <CardValue
            value={roundWithUnits(
              get(downholeInformation, "plunger.diameter"),
              3,
            )}
          />

          <CardLabel label={"Plunger Length"} />
          <CardValue
            value={roundWithUnits(
              get(downholeInformation, "plunger.length"),
              2,
            )}
          />

          <CardLabel label={"Plunger Mass"} />
          <CardValue
            value={roundWithUnits(get(downholeInformation, "plunger.mass"), 2)}
          />

          <CardLabel label={"Service Factor"} />
          <CardValue
            value={roundWithUnits(get(downholeInformation, "serviceFactor"), 2)}
          />

          <CardLabel label={"Total Rod Length"} />
          <CardValue value={roundWithUnits(totalRodLength, 2)} />

          {sections &&
            sections.map((section, key) => {
              const guideTypeKey = formatGuideTypeKey(
                get(section, "guideType"),
              );
              const formattedGuideSummary = section.guideQuantity
                ? `${guideTypeKey}`
                : "";

              return (
                <Grid
                  container
                  style={{
                    margin: "8px 0 0 0",
                    padding: "8px 0 0 0",
                    borderTop: "thin solid #979797",
                  }}
                  key={key}
                >
                  <CardLabel label={"Section Rod Count"} />
                  <CardValue value={roundWithUnits(section.rodQuantity)} />

                  <CardLabel label={"Section Rod Length"} />
                  <CardValue value={roundWithUnits(section.rodLength, 2)} />

                  <CardLabel label={"Section Rod Type"} />
                  <CardValue value={formatRodSectionSummary(section)} />

                  <CardLabel label={"Section Guide Quantity"} />
                  <CardValue value={valueOf(section.guideQuantity)} />

                  <CardLabel label={"Section Guide Summary"} />
                  <CardValue value={formattedGuideSummary} />
                </Grid>
              );
            })}

          <Grid item xs={12}>
            {privileges.includes(privilegeTypes.well.configure) && (
              <FormButton
                onClick={() =>
                  this.setState({showDownholeInformationEditView: true})
                }
                style={{marginTop: "8px"}}
              >
                Edit Downhole Information
              </FormButton>
            )}
          </Grid>
        </Grid>
      </PaperContainer>
    );
  }
}

export default DownholeInformationReadView;
