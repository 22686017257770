import {connect} from "react-redux";
import {compose} from "recompose";
import React from "react";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

import {clearSelectedWells, toggleManageTagsDialog} from "actions/tagging";
import Container from "components/container";

import CloseButton from "./close-button";
import ManageTagsButton from "./manage-tags-button";

const styles = ({spacing}) => ({
  container: {
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  numSelected: {
    paddingLeft: spacing(1),
  },
});

const ManageTagsDrawer = ({
  classes,
  clearSelectedWells,
  toggleManageTagsDialog,
  numSelected,
}) => (
  <Drawer anchor="bottom" open={numSelected > 0} variant="persistent">
    <Container className={classes.container}>
      <div className={classes.container}>
        <CloseButton onClick={clearSelectedWells} />
        <Typography variant="subtitle1">{numSelected} Selected</Typography>
      </div>

      <div>
        <ManageTagsButton onClick={toggleManageTagsDialog} />
      </div>
    </Container>
  </Drawer>
);

const mapStateToProps = state => ({
  numSelected: Object.values(state.tags.selectedWells).filter(
    ({selected}) => selected,
  ).length,
});

const mapDispatchToProps = {
  clearSelectedWells,
  toggleManageTagsDialog,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
)(ManageTagsDrawer);
