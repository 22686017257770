import React from "react";

import {COLORS, LABELS} from "constants/well-health";
import getUnits from "utils/get-units";
import round from "utils/round";

import BaseWellboreGraph from "./base-wellbore-graph";
import dataToPoint from "./data-to-point";

const TooltipWellbore = ({tooltipData: {data, pointNumber}, setColor}) => {
  const {
    inclinationDegrees,
    doglegSeverityDeg30m,
    trueVerticalDepth,
  } = dataToPoint(
    data,
    ["inclinationDegrees", "doglegSeverityDeg30m", "trueVerticalDepth"],
    pointNumber,
  );

  setColor(COLORS.WELLBORE);

  return (
    <BaseWellboreGraph
      title={LABELS.WELLBORE}
      primaryContentList={[
        {label: "Inclination:", value: `${round(inclinationDegrees)}°`},
        {label: "DLS:", value: `${round(doglegSeverityDeg30m)}°`},
        {
          label: "TVD:",
          value: `${round(trueVerticalDepth, 0)}${getUnits().depth}`,
        },
      ]}
    />
  );
};

export default TooltipWellbore;
