export default ({state, action}) => {
  const {filter, previousQuerystring, source} = action.payload;

  return {
    ...state,
    filter,
    previousQuerystring:
      previousQuerystring == null
        ? state.previousQuerystring
        : previousQuerystring,
    source: source || state.source,
    hasInitialized: true,
  };
};
