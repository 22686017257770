import map from "lodash/map";
import React from "react";
import ReactTooltip from "react-tooltip";
import Typography from "@material-ui/core/Typography";

import colors from "@ambyint/colors";

import {mappingTypes} from "constants/well-event-import-mapping-types";

const Tooltip = ({errors, id}) => (
  <td>
    {errors.length > 0 && (
      <ReactTooltip key={`row-tooltip-${id}`} id={`row-tooltip-${id}`}>
        {map(errors, (error, i) => (
          <div key={i}>- {error}</div>
        ))}
      </ReactTooltip>
    )}
  </td>
);

const Cell = props => {
  const {children, errors, first, hidden, ignored, last} = props;

  if (hidden) {
    return null;
  }

  const style = {
    padding: "8px 16px",
    whiteSpace: "nowrap",
    ...(ignored ? {backgroundColor: colors.grey[100]} : {}),
    ...(errors.length > 0 ? {backgroundColor: colors.red[50]} : {}),
    ...(first ? {paddingLeft: 0} : {}),
    ...(last ? {paddingRight: 0} : {}),
  };

  return (
    <td style={style}>
      <Typography variant={"body1"}>{children}</Typography>
    </td>
  );
};

export const TableBody = props => {
  const {data, errors, hideIgnored, limit, mappings, onlyShowErrors} = props;
  const rows = [];

  for (let i = 0, length = data.length; i < length; i++) {
    const row = {
      data: data[i],
      errors: errors[i],
    };

    if (onlyShowErrors) {
      if (errors[i].length > 0) {
        rows.push(row);
      }
    } else {
      rows.push(row);
    }
  }

  return (
    <tbody>
      {map(rows.slice(0, limit), (row, rowIndex) => {
        return (
          <tr key={rowIndex} data-tip data-for={`row-tooltip-${rowIndex}`}>
            <Tooltip errors={row.errors} id={rowIndex} />
            {map(row.data, (col, colIndex) => {
              const mapping = mappings[colIndex];
              const ignored = mapping.type === mappingTypes.IGNORE;

              return (
                <Cell
                  key={colIndex}
                  errors={row.errors}
                  first={colIndex === 0}
                  hidden={hideIgnored && ignored}
                  ignored={ignored}
                  last={colIndex === row.data.length - 1}
                >
                  {col || <span>&nbsp;</span>}
                </Cell>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
};
