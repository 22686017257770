import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "components/dialog";
import Grid from "@material-ui/core/Grid";
import isString from "lodash/isString";
import React from "react";
import TextField from "@material-ui/core/TextField";

const classes = {
  dialogPaper: {
    overflow: "visible",
  },
};

class Component extends React.Component {
  state = {
    confirmationText: "",
    hasMatchingConfirmationText: false,
  };

  onConfirmationTextChanged = event => {
    const {expectedConfirmationText} = this.props;

    this.setState({
      confirmationText: event.target.value,
      hasMatchingConfirmationText:
        event.target.value &&
        event.target.value.toLowerCase().trim() ===
          expectedConfirmationText.toLowerCase().trim(),
    });
  };

  render() {
    const {
      body,
      cancelButtonText,
      classes,
      confirmationButtonText,
      expectedConfirmationText,
      confirmationLabel,
      isOpen,
      onCancel,
      onConfirm,
      title,
    } = this.props;
    const {confirmationText, hasMatchingConfirmationText} = this.state;

    const isValid = !expectedConfirmationText || hasMatchingConfirmationText;

    return (
      <Dialog
        open={isOpen}
        onClose={onCancel}
        classes={{paper: classes.dialogPaper}}
        maxWidth="sm"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent style={{overflow: "visible"}}>
          {isString(body) ? (
            <Typography variant="body2">{body}</Typography>
          ) : (
            body
          )}
          {expectedConfirmationText && (
            <Grid container style={{marginTop: "8px"}}>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  required
                  label={confirmationLabel}
                  fullWidth
                  onChange={this.onConfirmationTextChanged}
                  value={confirmationText}
                  onKeyDown={e => {
                    if (isValid && e.key === "Enter") {
                      onConfirm();
                    }
                  }}
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onCancel}>
            {cancelButtonText}
          </Button>
          <Button color="primary" disabled={!isValid} onClick={onConfirm}>
            {confirmationButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(classes)(Component);
