import {types} from "./types";

export const filterEventsByPerformedBy = options => {
  const {organizationId, performedBy} = options;

  return {
    type: types.filterEventsByPerformedBy,
    payload: {
      organizationId,
      performedBy,
    },
  };
};
