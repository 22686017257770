import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import clsx from "clsx";

import {
  LOW_LOAD,
  HIGH_LOAD,
  IDEAL,
  DTV,
  HOLE_IN_BARREL,
  LEAKING,
  PUMP_OFF,
  GAS_INTERFERENCE,
} from "constants/anomalies";
import {anomalyDisplayName} from "parsers/anomaly-display-name";
import {useHover} from "hooks/use-hover";

import LowLoadIcon from "./low-load";
import PumpOffIcon from "./pump-off";
import HighLoadDoubleIcon from "./high-load";
import DTVIcon from "./dtv";
import LeakingIcon from "./leaking";
import GasInterferenceIcon from "./gas-interference";
import HoleInBarrelIcon from "./hole-in-barrel";
import IdealIcon from "./ideal";
import OverrideIconCheckbox from "./override-icon-checkbox";

const useStyles = makeStyles(({colors, spacing, breakpoints, typography}) => ({
  container: {
    cursor: "pointer",
    "&:hover": {
      "& $cardDefault": {
        transform: "translateY(-8px)",
      },
    },
    [breakpoints.down("md")]: {
      "&:hover": {
        "& $cardDefault": {
          transform: "translateY(0px)",
        },
      },
    },
  },
  cardDefault: {
    backgroundColor: "white",
    borderRadius: spacing(1),
    transition: "border .2s ease-in, transform .4s ease-in-out",
    padding: spacing(2, 2, 0, 3),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    border: "2px solid white",
    height: "100%",
    boxSizing: "border-box",
    [breakpoints.down("md")]: {
      padding: spacing(1, 1, 0, 2),
    },
  },
  cardSelected: {
    border: `2px solid ${colors.blue[400]}`,
  },
  label: {
    [breakpoints.down("md")]: {
      ...typography.caption,
    },
    color: colors.warmGrey[700],
    userSelect: "none",
  },
  iconStyle: {
    color: colors.warmGrey[600],
    width: 90,
    height: 90,
    [breakpoints.down("md")]: {
      width: 60,
      height: 60,
    },
  },
  iconContainer: {
    paddingBottom: spacing(1),
  },
  headerBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline",
  },
  checkboxContainer: {
    paddingLeft: spacing(1),
  },
}));

const iconMap = {
  [LOW_LOAD]: LowLoadIcon,
  [HIGH_LOAD]: HighLoadDoubleIcon,
  [DTV]: DTVIcon,
  [HOLE_IN_BARREL]: HoleInBarrelIcon,
  [LEAKING]: LeakingIcon,
  [PUMP_OFF]: PumpOffIcon,
  [GAS_INTERFERENCE]: GasInterferenceIcon,
  [IDEAL]: IdealIcon,
};

const OverrideIconCard = ({variant, onClick, overrideType}) => {
  const {isHovering, enableHover, disableHover} = useHover();
  const classes = useStyles();

  const selected =
    !!overrideType && overrideType.toLowerCase() === variant.toLowerCase();
  const IconToRender = iconMap[variant];

  return (
    <div
      className={classes.container}
      onClick={onClick(variant)}
      onMouseEnter={enableHover}
      onMouseLeave={disableHover}
    >
      <Card
        className={clsx(classes.cardDefault, selected && classes.cardSelected)}
        raised={isHovering}
      >
        <div className={classes.headerBar}>
          <Typography variant="body2" className={classes.label}>
            {anomalyDisplayName(variant)}
          </Typography>
          <div className={classes.checkboxContainer}>
            <OverrideIconCheckbox selected={selected} isHovering={isHovering} />
          </div>
        </div>
        <div className={classes.iconContainer}>
          <IconToRender className={classes.iconStyle} />
        </div>
      </Card>
    </div>
  );
};

export default OverrideIconCard;
