import {getWells} from "utils/wells/get-wells";
import rollupFillage from "utils/wells/rollup/fillage";
import rollupUptime from "utils/wells/rollup/uptime";

export default state => {
  const searched = getWells(state.search.wellList);

  return {
    ...state,
    rollup: {
      fillage: rollupFillage(searched),
      uptime: rollupUptime(searched),
    },
  };
};
