import startCase from "lodash/startCase";

const withRequired = (props, label) => {
  return props.required && !label.endsWith("*") ? `${label} *` : label;
};

export default props => {
  const name = props.name || (props.input && props.input.name);

  if (props.label) {
    return withRequired(props, props.label);
  } else if (name) {
    return withRequired(props, startCase(name.split(/(?=[A-Z])/).join(" ")));
  }

  return null;
};
