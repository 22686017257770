const parseUrl = /^(https?:)?(\/\/)?([^?]+)(\?.*)?/;

export const removeExtraSlashesInRoutes = action => {
  if (
    action.type === "@@router/CALL_HISTORY_METHOD" &&
    ["push", "replace"].includes(action.payload.method)
  ) {
    const updatedAction = {
      ...action,
      payload: {
        ...action.payload,
        args: action.payload.args.map(arg => {
          if (arg.startsWith("?")) {
            return arg;
          }

          const results = parseUrl.exec(arg);
          const [, protocol, prefix, route, querystring] = results || [];

          return [
            protocol || "",
            prefix || "",
            (route || "").replace(new RegExp(/\/[/]+/, "g"), "/"),
            querystring || "",
          ].join("");
        }),
      },
    };

    return updatedAction;
  }

  return action;
};

/**
 * Removes extra slashes in URLs for calls to push/replace.
 *
 * The original issue was in src/routes/well/tabs/tabs.js:
 * > dispatch(push(`${match.url}/${PATHS[value]}`));
 * If match.url had captured a string with a trailing slash,
 * this would result in two slashes, which broke navigation.
 */
export default () => next => action => {
  const updatedAction = removeExtraSlashesInRoutes(action);
  next(updatedAction);
};
