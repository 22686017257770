export const types = {
  acceptRecommendation: "wells::accept-recommendation",
  addComparison: "wells::add-comparison",
  updateRecommendation: "wells::update-recommendation",
  create: "wells::create",
  deleteGracePeriod: "wells::delete-grace-period",
  dismissRecommendation: "wells::dismiss-recommendation",
  fetch: "wells:fetch",
  fetchAlerts: "wells:fetch-alerts",
  fetchAnalysis: "wells:fetch-analysis",
  fetchAnalysisComplete: "wells:fetch-analysis-complete",
  fetchAssigned: "wells:fetch-assigned",
  fetchCards: "wells::fetch-cards",
  fetchComparison: "wells::fetch-comparison",
  fetchEvents: "wells:fetch-events",
  fetchLatestCards: "wells:fetch-latest-cards",
  fetchCardOverride: "wells:fetch-card-override",
  fetchMiniCardById: "wells::fetch-mini-card-by-id",
  fetchLatestMiniCard: "wells::fetch-latest-mini-card",
  fetchOnCall: "wells:fetch-on-call",
  fetchOne: "wells:fetch-one",
  fetchUptimeOne: "wells:fetch-uptime-one",
  fetchRecommendations: "wells:fetch-recommendations",
  fetchAllRecommendations: "wells:fetch-all-recommendations",
  setAllRecommendationsFetched: "wells:set-all-recommendations-fetched",
  filter: "wells::filter",
  generateShareableAnalysisLink: "wells::generate-shareable-analysis-link",
  hide: "wells:hide",
  parseWell: "wells:parse-one",
  refreshFilters: "wells::refresh-filters",
  removeComparison: "wells::remove-comparison",
  removeRecommendation: "wells::remove-recommendation",
  requestCard: "wells::request-card",
  saveAutoAcceptRecommendations: "wells::save-auto-accept-recommendations",
  saveBasicInformation: "wells:save-basic-information",
  saveDownholeInformation: "wells:save-downhole-information",
  saveGracePeriod: "wells::save-grace-period",
  saveMotorDetails: "wells:save-motor-details",
  saveMotorViolations: "wells:save-motor-violations",
  saveNominalRpm: "wells::save-nominal-rpm",
  savePumpingUnitInformation: "wells:save-pumping-unit-information",
  savePumpoffSettings: "wells:save-pumpoff-settings",
  saveReservoirInformation: "wells:save-reservoir-information",
  saveResistance: "wells:save-resistance",
  saveSafeOperatingParameters: "wells:save-safe-operating-parameters",
  saveSlowSpeedOffTime: "wells::save-slow-speed-off-time",
  saveTag: "wells::save-tag",
  saveTotalReductionRatio: "wells:save-total-reduction-ratio",
  saveWellboreInformation: "wells:save-wellbore-information",
  search: "wells::search",
  set: "wells:set",
  setActive: "wells::set-active",
  setActivePath: "wells::set-active-path",
  setActiveAnomaly: "wells::set-active-anomaly",
  setAlerts: "wells:set-alerts",
  setAnalogInputConfiguration: "wells:set-analog-input-config",
  setAnalysis: "wells::set-analysis",
  setAnomalyWells: "wells::set-anomaly-wells",
  setCalibrateWells: "wells::set-calibrate-wells",
  setCardOverride: "wells:set-card-override",
  setTriageWells: "wells::set-triage-wells",
  setAssigned: "wells:set-assigned",
  setClearErrors: "wells::set-clear-errors",
  setComparisons: "wells::set-comparisons",
  setDisplayAllWells: "wells::set-display-all-wells",
  setDowntime: "wells::set-downtime",
  setEvents: "wells:set-events",
  setFaultRecovery: "wells::set-fault-recovery",
  setMapType: "wells::set-map-type",
  setMapBounds: "wells::set-map-bounds",
  setLufkinDeadband: "wells::set-lufkin-deadband",
  setLufkinFillBase: "wells::set-lufkin-fill-base",
  setLufkinMalfunction: "wells::set-lufkin-malfunction",
  setLufkinRodString: "wells::set-lufkin-rod-string",
  setLufkinStructuralLoad: "wells:set-lufkin-structural-load",
  setOnCall: "wells:set-on-call",
  setOne: "wells:set-one",
  setBasicInformation: "wells:set-basic-information",
  setReservoirInformation: "wells:set-reservoir-information",
  setUptimeOne: "wells:set-uptime-one",
  setRecommendations: "wells:set-recommendations",
  setAllRecommendations: "wells:set-all-recommendations",
  setSetupError: "wells::set-setup-error",
  setResetMalfunctions: "wells::set-reset-malfunctions",
  setRunTimer: "wells::set-run-timer",
  setSearchCriteria: "wells::set-search-criteria",
  setSearchResults: "wells::set-search-results",
  setShareableAnalysisLink: "wells::set-shareable-analysis-link",
  setSpeed: "wells::set-speed",
  setSpeedRange: "wells::set-speed-range",
  setViewType: "wells:set-view-type",
  setWellFeedFilter: "wells::set-well-feed-filter",
  setWorkflow: "wells::set-workflow",
  show: "wells:show",
  sort: "wells:sort",
  start: "wells::start",
  stop: "wells::stop",
  setRecommendationType: "wells::set-recommendation-type",
  toggleComparisonsView: "wells::toggle-comparisons-view",
  updateAnalysis: "wells::update-analysis",
  viewFeedComparison: "wells::view-feed-comparison",
  setWellsFetched: "wells::set-wells-fetched",
};
