import isEmpty from "lodash/isEmpty";
import React from "react";

import Typography from "@material-ui/core/Typography";

import {defaultMainStyle, defaultTitleStyle} from "./dashboard-styles";

const Target = props => {
  const {operation} = props;

  if (!operation) return null;

  let target;
  if (operation.speedRange)
    target = `Target ${operation.speedRange.min.value} - ${
      operation.speedRange.max.value
    } ${operation.speedRange.min.units}`;

  if (operation.targetSpeed)
    target = `Target ${operation.targetSpeed.speed.value} ${
      operation.targetSpeed.speed.units
    }`;

  return (
    <Typography variant="body1" style={{...defaultTitleStyle}}>
      {target}
    </Typography>
  );
};

export default props => {
  const {well} = props;

  if (isEmpty(well.conditions.speed)) return null;

  return (
    <div>
      <Typography variant="body1" style={{...defaultTitleStyle}}>
        Speed
      </Typography>
      <Typography variant="h6" style={{...defaultMainStyle}}>
        {well.conditions.speed.label}
      </Typography>
      <Target operation={well.operation} />
    </div>
  );
};
