import {types} from "actions/wells/well-surveillance";
import {reducerFactory} from "reducers/reducer-factory";

import {setHistory} from "./set-history";

const defaults = {
  activeWellId: false,
  isFetching: true,
  data: [],
};

const actionMap = {
  [types.setHistory]: setHistory,
};

export default reducerFactory(actionMap, defaults);
