import {connect} from "react-redux";
import {compose} from "recompose";
import find from "lodash/find";
import fromPairs from "lodash/fromPairs";
import {InputLabel} from "@material-ui/core";
import {push} from "react-router-redux";
import React from "react";
import {reduxForm, formValueSelector} from "redux-form";
import trim from "lodash/trim";
import Typography from "@material-ui/core/Typography";
import {withRouter} from "react-router";

import colors from "theme/colors";
import pluralize from "@ambyint/common/utils/format/format-pluralize";
import Breadcrumbs from "components/breadcrumbs";
import FormButton from "components/form-button";
import TextField from "components/forms/text-field";
import {
  createServiceAccount,
  fetchOrganizationWells,
  selectOrganization,
} from "actions/organizations";
import orgStyle from "routes/profile/organization/styles";

import createListView from "./list-view";
import PageHeader from "./page-header";
import selectOrganizationUserDetails from "./select-organization-user-details";
import validate from "./validate-create-service-account";

const ListView = createListView("create-service-account");

const selector = formValueSelector("create-service-account");
const mapStateToProps = state => {
  const {organizations, organizationsWells} = state;

  const {organizationName, organizationWells} = selectOrganizationUserDetails(
    organizations,
    organizationsWells,
  );

  const organizationServiceAccounts =
    !!organizations.selectedOrganizationId &&
    organizations.all[organizations.selectedOrganizationId]
      ? organizations.all[organizations.selectedOrganizationId].users.filter(
          user => user.type === "serviceAccount",
        )
      : [];

  return {
    organizationName,
    organizationWells,
    organizations,
    organizationServiceAccounts,
    wells: state.wells,
    currentValues: {
      webhooks: selector(state, "webhooks"),
      wells: selector(state, "wells"),
    },
    initialValues: {
      serviceName: "",
      webhooks: [],
      wells: {},
    },
  };
};
const mapDispatchToProps = {
  selectOrganization,
  fetchOrganizationWells,
  createServiceAccount,
  push,
};

class CreateServiceAccount extends React.Component {
  state = {
    searchPhrase: "",
  };

  componentDidMount() {
    const {
      match: {
        params: {organizationId},
      },
      selectOrganization,
      fetchOrganizationWells,
    } = this.props;

    selectOrganization({organizationId});
    fetchOrganizationWells({organizationId});
  }

  render() {
    const {
      currentValues,
      handleSubmit,
      match: {
        params: {organizationId},
      },
      organizationName,
      organizationWells,
      organizations,
      reset,
      submitting,
      valid,
      createServiceAccount,
      push,
    } = this.props;

    return (
      <form
        autoComplete="nope"
        onSubmit={handleSubmit(values => {
          const {serviceName, wells, webhooks} = values;

          const mapWellIdToDownholeLocation = wellId => {
            const well = find(organizationWells, {wellId});
            const isAssigned = wells[wellId];
            return [well.downholeLocation, isAssigned];
          };

          const downholeLocations = fromPairs(
            Object.keys(wells).map(mapWellIdToDownholeLocation),
          );

          createServiceAccount({
            serviceName: trim(serviceName),
            organizationId,
            downholeLocations,
            webhooks,
            wellIds: wells,
          });

          reset();
          this.serviceNameTextField.focus();
        })}
        style={{...orgStyle.pageContainer, maxWidth: 1140}}
      >
        <div style={{marginBottom: 32}}>
          <Breadcrumbs
            crumbs={[
              {
                label: organizationName,
                pathname: "/profile/organizations",
              },
              {
                label: "Manage Service Accounts",
                pathname: `/profile/organization/${
                  organizations.selectedOrganizationId
                }/serviceaccounts`,
              },
              {
                label: "Create Service Account",
              },
            ]}
          />
        </div>

        <PageHeader
          title={"Create Service Account"}
          subtitle={
            <>
              Create a service account with the desired well assignments.
              <br />
              You can later configure webhooks for pushing data to external
              endpoints.
            </>
          }
        />

        <TextField
          autoFocus
          name="serviceName"
          label="Service Account Name"
          required
          outlined
          formattedLabel={false}
          style={{width: 260}}
          inputProps={{maxLength: 32}}
          inputRef={ref => (this.serviceNameTextField = ref)}
        />

        <div style={{marginTop: 32}}>
          <InputLabel>Wells</InputLabel>
          <ListView
            containerStyle={{
              maxHeight: 580,
              marginTop: 8,
            }}
            header={<InputLabel>Wells</InputLabel>}
            organizationWells={organizationWells || {}}
            assignedWells={currentValues.wells || {}}
            reset={reset}
            searchPhrase={this.state.searchPhrase}
            footer={wellsSelected => (
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  minHeight: 51,
                  borderTop: `thin solid ${colors.grey[300]}`,
                }}
              >
                <Typography
                  variant="body2"
                  style={{
                    color: colors.grey[600],
                    fontWeight: "bold",
                    margin: 16,
                  }}
                >{`${wellsSelected.length} well${pluralize(
                  wellsSelected,
                )} selected`}</Typography>
              </div>
            )}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 32,
          }}
        >
          <FormButton
            center
            disabled={submitting}
            onClick={() => {
              reset();
              push(`/profile/organization/${organizationId}/serviceaccounts`);
            }}
          >
            Cancel
          </FormButton>
          <FormButton
            center
            disabled={submitting || !valid}
            variant="contained"
            type="submit"
          >
            Create Service Account
          </FormButton>
        </div>
      </form>
    );
  }
}

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
  reduxForm({
    form: "create-service-account",
    validate,
    enableReinitialize: true,
  }),
);

export default enhance(CreateServiceAccount);
