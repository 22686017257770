import get from "lodash/get";

import {STATUS} from "constants/tagging";

const cleanTag = ({name}) => ({name});

export const filterAndOrganizeTagData = (wells, tags) => {
  const additions = tags
    .filter(({status}) => status === STATUS.newTag)
    .map(cleanTag);
  const subtractions = tags
    .filter(({status}) => status === STATUS.removedTag)
    .map(cleanTag);

  const downholesWithAdditions = wells.reduce((acc, well) => {
    const wellTags = get(well, "tags", []);

    /*
     * if there are tags to be added, and the well doesn't
     * already contain all the tags to be added then include it
     */
    const condition =
      (additions.length && !wellTags.length) ||
      !wellTags.every(tag => additions.find(t => tag.name === t.name));

    return condition ? [...acc, well.downholeLocation] : acc;
  }, []);

  const downholesWithSubtractions = wells.reduce((acc, well) => {
    const wellTags = get(well, "tags", []);

    /*
     * if there are tags to remove, and the well has at
     * least one of the tags to be removed then include it.
     */
    const condition =
      subtractions.length &&
      wellTags.length &&
      wellTags.some(tag => subtractions.find(t => tag.name === t.name));

    return condition ? [...acc, well.downholeLocation] : acc;
  }, []);

  return {
    tags: {additions, subtractions},
    downholeLocations: {
      additions: downholesWithAdditions,
      subtractions: downholesWithSubtractions,
    },
  };
};

export default {filterAndOrganizeTagData};
