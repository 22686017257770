export const types = {
  clearSelectedTags: "tags::clear-selected-tags",
  clearSelectedWells: "tags::clear-selected-wells",
  deselectTag: "tags::deselect-tag",
  disableUselessTags: "tags::disable-useless-tags",
  selectTag: "tags::select-tag",
  setTagSelectionType: "tags::set-tag-selection-type",
  toggleTagDrawer: "tags::toggle-tag-drawer",
  toggleManageTagsDialog: "tags::toggle-manage-tags-dialog",
  toggleWellSelected: "tags::toggle-well-selected",
  updateWellTags: "tags::update-well-tags",
  replaceTags: "tags::replace-tags",
  rehydrateTags: "tags::rehydrate-tags",
  tagsHydrated: "tags::tags-hydrated",
  filterWellsByTag: "tags:filter-wells-by-tag",
  setFilteredByTags: "tags::set-filtered-by-tag",
};
