import React from "react";
import {connect} from "react-redux";
import {reduxForm} from "redux-form";
import List from "@material-ui/core/List";

import {saveAutoAcceptRecommendations} from "actions/wells";
import Aux from "components/aux";
import Checkbox from "components/forms/list-checkbox";
import SaveCancelButtons from "components/save-cancel-buttons";
import compose from "utils/compose";
import {isTypeEnabled} from "utils/setpoint-automation/is-type-enabled";

import recommendationTypes from "./recommendation-types";

const parseValues = values => {
  return {
    autoAcceptRecommendationTypes: Object.keys(values).reduce((set, type) => {
      if (values[type]) set.push(type);
      return set;
    }, []),
  };
};

const mapStateToProps = (state, props) => {
  const {well} = props;

  return {
    initialValues: Object.keys(recommendationTypes(well)).reduce(
      (accumulator, type) => {
        accumulator[type] = well.autoAcceptRecommendationTypes.includes(type);

        return accumulator;
      },
      {},
    ),
  };
};

const descriptions = {
  speedRange:
    "All speed range recommendations from Ambyint will be automatically applied to this well.",
  idleTime:
    "All downtime recommendations from Ambyint will be automatically applied to this well.",
  timer:
    "All timer recommendations from Ambyint will be automatically applied to this well and enable the timer if it is disabled.",
};

const renderType = well => type => {
  const recommendationsEnabled = isTypeEnabled(type)(well);

  return (
    <Checkbox
      key={type}
      disabled={!recommendationsEnabled}
      label={recommendationTypes(well)[type]}
      name={type}
      description={descriptions[type]}
    />
  );
};

let Component = props => {
  const {
    dispatch,
    handleSubmit,
    pristine,
    reset,
    submitting,
    toggleEdit,
    well,
  } = props;

  const save = values => {
    dispatch(
      saveAutoAcceptRecommendations({
        ...parseValues(values),
        downholeLocation: well.downholeLocation,
      }),
    );

    toggleEdit();
  };

  return (
    <Aux>
      <form onSubmit={handleSubmit(save)}>
        <List>
          {Object.keys(recommendationTypes(well)).map(renderType(well))}
        </List>
        <SaveCancelButtons
          pristine={pristine}
          reset={compose(
            toggleEdit,
            reset,
          )}
          submitting={submitting}
        />
      </form>
    </Aux>
  );
};

Component = reduxForm({
  form: "auto-accepted-recommendations-form",
  enableReinitialize: true,
})(Component);

export default connect(mapStateToProps)(Component);
