export default ({state, action}) => {
  const {organizationId, range} = action.payload;

  const organization = {
    ...(state[organizationId] || {}),
    range,
  };

  return {
    ...state,
    [organizationId]: organization,
  };
};
