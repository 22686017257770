import store from "store";
import {isAnomalyWell} from "../well/is-anomaly-well";

export const getAnomalyWells = (wellList = []) => {
  const wellObj = store.getState().wells.byWellId;

  const wells = [];
  for (let i = 0; i < wellList.length; i++) {
    const currentWell = wellObj[wellList[i]];

    if (isAnomalyWell(currentWell)) {
      wells.push(currentWell.wellId);
    }
  }

  return wells;
};
