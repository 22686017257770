import React from "react";

import {line, curveMonotoneX} from "d3-shape";

import colors from "theme/colors";

const getDefaultStyle = () => {
  return {
    stroke: colors.red[500],
    fill: "none",
    strokeWidth: "1.5px",
  };
};

const Line = props => {
  const {xScale, yScale, data, style} = props;
  const lineGenerator = line()
    .curve(curveMonotoneX)
    .x(d => {
      return xScale(d.x);
    })
    .y(d => {
      return yScale(d.y);
    });

  return (
    <path
      d={lineGenerator(data)}
      style={{...getDefaultStyle(props), ...style}}
    />
  );
};

export default Line;
