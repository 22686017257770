import {
  event as d3event,
  mouse as d3mouse,
  select as d3select,
} from "d3-selection";
import {zoom as d3zoom, zoomTransform as d3zoomTransform} from "d3-zoom";
import createSvgPoint from "utils/charts/create-svg-point";
import max from "lodash/max";
import min from "lodash/min";

import alerts from "./alerts";
import casing from "./casing";
import current from "./current";
import deflection from "./deflection";
import dutyCycle from "./duty-cycle";
import driveFault from "./drive-fault";
import driveMode from "./drive-mode";
import effectiveSpm from "./effective-spm";
import fillage from "./fillage";
import focus from "./focus";
import getScales from "./scales/get";
import inferredFillage from "./inferred-fillage";
import ambyintFillage from "./ambyint-fillage";
import inferredOilProduction from "./inferred-oil-production";
import leak from "./leak";
import load from "./load";
import oilProduction from "./oil-production";
import recommendations from "./recommendations";
import rpm from "./rpm";
import rrpm from "./rrpm";
import runningStatus from "./running-status";
import selections from "./selections";
import shutdownCards from "./shutdown-cards";
import spm from "./spm";
import statusCodes from "./status-codes";
import timeAxis from "./time-axis";
import torque from "./torque";
import tubing from "./tubing";
import userActions from "./user-actions";

function noop() {}

const margin = {
  top: 0,
  right: 16,
  bottom: 16,
  left: 16,
};

class Chart {
  constructor({node, onFocus}) {
    this.k = 1; // initial zoom
    this.svgNode = node;
    this.clipPathId = "clipPathId";

    this.registrations = [];

    this.scales = getScales();

    this.svg = d3select(node);

    this.g = this.svg
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    this.clipPath = this.g
      .append("clipPath")
      .attr("id", this.clipPathId)
      .append("rect");

    const props = {
      clipPathId: this.clipPathId,
      onFocus,
      register: this.register.bind(this),
      scales: this.scales,
      selection: this.g,
      svgNode: this.svgNode,
    };

    // operation
    load(props);
    current(props);
    torque(props);
    rpm(props);
    rrpm(props);
    casing(props);
    tubing(props);
    spm(props);
    effectiveSpm(props);
    leak(props);
    deflection(props);
    dutyCycle(props);

    // production
    fillage(props);
    inferredFillage(props);
    ambyintFillage(props);
    inferredOilProduction(props);
    oilProduction(props);

    // status
    alerts(props);
    driveFault(props);
    driveMode(props);
    recommendations(props);
    runningStatus(props);
    shutdownCards(props);
    statusCodes(props);
    userActions(props);

    selections(props);
    timeAxis(props);

    this.viewport = this.g
      .append("rect")
      .attr("fill", "transparent")
      .attr("pointer", "move");

    focus(props);

    this.zoom = d3zoom().scaleExtent([1, 500]);
  }

  register(registration) {
    this.registrations.push(registration);
  }

  draw({config, data, selections}) {
    if (!data || !data.dates || !data.dates.length) {
      return;
    }

    const width = +this.svg.attr("width") - margin.left - margin.right;
    const height = +this.svg.attr("height") - margin.top - margin.bottom;
    const viewportExtent = [[0, 0], [width, height]];

    this.viewport.attr("width", width).attr("height", height);
    this.clipPath.attr("width", width).attr("height", height);

    this.scales.time
      .domain([new Date(config.range.start), new Date(config.range.end)])
      .range([0, width]);
    this.scales.percentage.range([height, 0]);
    this.scales.kpa.range([height, 0]);

    const oilProduction = [
      ...data.oilProduction,
      ...data.inferredOilProduction,
    ];

    if (oilProduction && oilProduction.length > 0) {
      const values = oilProduction.map(d => d.value);
      const maxValue = max(values);
      const minValue = min(values);
      const maxBuffed = maxValue * 1.2;
      const minBuffed = minValue - (maxBuffed - maxValue);

      this.scales.oilProduction.domain([minBuffed, maxBuffed]);
    }
    this.scales.oilProduction.range([height, 0]);

    this.scales.status.range([height, 0]);

    this.registrations.forEach(({draw = noop}) => {
      draw({config, data, height, selections, width});
    });

    const createMouseEventArgs = () => {
      const point = d3mouse(this.viewport.node());

      return {
        data,
        point,
        selections,
        svgPoint: createSvgPoint(this.svgNode, point),
        transform: d3zoomTransform(this.viewport.node()),
      };
    };

    this.viewport
      .on("click", () => {
        const mouseEventArgs = createMouseEventArgs();

        this.registrations.forEach(({click = noop}) => click(mouseEventArgs));
      })
      .on("mousemove", () => {
        this.registrations.forEach(({mousemove = noop}) => {
          mousemove(createMouseEventArgs());
        });
      })
      .on("mouseout", () => {
        this.registrations.forEach(({mouseout = noop}) => {
          mouseout(createMouseEventArgs());
        });
      })
      .on("mouseover", () => {
        this.registrations.forEach(({mouseover = noop}) => {
          mouseover(createMouseEventArgs());
        });
      });

    this.zoom
      .extent(viewportExtent)
      .translateExtent(viewportExtent)
      .on("zoom", () => {
        const transform = d3event.transform;
        const xScale = transform.rescaleX(this.scales.time);

        this.k = transform.k; // save zoom level

        this.registrations.forEach(({zoom = noop}) => {
          zoom({config, data, height, transform, width, xScale});
        });
      });

    this.viewport.call(this.zoom);
    this.zoom.scaleTo(this.viewport, this.k);
  }
}

export default Chart;
