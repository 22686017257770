import {types} from "./types";

export const setLufkinStructuralLoad = options => {
  const {values, well} = options;

  return {
    type: types.setLufkinStructuralLoad,
    payload: {
      values,
      well,
    },
  };
};
