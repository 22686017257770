import uniq from "lodash/uniq";

import {MAX_RECENT_ORGANIZATIONS} from "constants/recent-organizations";

import {getRecentOrganizations} from "./get-recent-organizations";

export const addRecentOrganization = (userId, organizationId) => {
  const recentOrgs = uniq([organizationId, ...getRecentOrganizations(userId)]);

  return recentOrgs.slice(0, MAX_RECENT_ORGANIZATIONS);
};
