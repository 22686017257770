import {useCallback, useState} from "react";
import debounce from "lodash/debounce";

import {findSpeedRangeType} from "utils/setpoint-automation/find-speed-range-type";

export const useAutomationState = (well, setRecommendationType) => {
  const savedRecommendationType = findSpeedRangeType(well.recommendationTypes);
  const [recommendationTypeOn, setRecommendationTypeOn] = useState(
    savedRecommendationType.on,
  );
  const [
    recommendationTypeAutoAccept,
    setRecommendationTypeAutoAccept,
  ] = useState(savedRecommendationType.autoAccept);

  const saveRecommendationType = useCallback(
    debounce(newRecommendationType => {
      setRecommendationType({
        downholeLocation: well.downholeLocation,
        recommendationType: newRecommendationType,
      });
    }, 1000),
    [],
  );

  const recommendationTypeOnChange = ({on, autoAccept}) => {
    if (recommendationTypeOn !== on) {
      setRecommendationTypeOn(on);
    }
    if (recommendationTypeAutoAccept !== autoAccept) {
      setRecommendationTypeAutoAccept(autoAccept);
    }
  };

  const recommendationType = {
    type: savedRecommendationType.type,
    on: recommendationTypeOn,
    autoAccept: recommendationTypeAutoAccept,
  };

  return {
    recommendationType,
    recommendationTypeOnChange,
    saveRecommendationType,
  };
};
