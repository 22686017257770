import React, {useState} from "react";
import Button from "@material-ui/core/Button/Button";
import CheckBox from "@material-ui/core/Checkbox/Checkbox";
import clsx from "clsx";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import InfoIcon from "mdi-material-ui/InformationOutline";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Typography from "@material-ui/core/Typography/Typography";

import {maybeString} from "utils/maybe-string";

import TooltipTitle from "./tooltip-title";

export default ({classes, ...props}) => {
  const [withAutomation, setWithAutomation] = useState(false);

  return (
    <div className={classes.footer}>
      <div className={classes.spaceBetween}>
        <Button
          variant="contained"
          className={classes.buttonContained}
          disabled={props.disabled}
          onClick={() => {
            props.accept();
            withAutomation && props.automate();
          }}
        >
          accept
        </Button>
        {props.automation && (
          <FormControlLabel
            className={classes.withAutomation}
            disabled={props.disabled}
            control={
              <CheckBox
                checked={withAutomation}
                onChange={() => setWithAutomation(!withAutomation)}
              />
            }
            label="Automate"
          />
        )}
      </div>
      <Tooltip
        title={
          <TooltipTitle
            acceptedBy={props.acceptedBy}
            acceptedOn={props.acceptedOn}
          />
        }
      >
        <div
          className={clsx(
            classes.footerOverlayDown,
            maybeString(!props.isOpen, classes.footerOverlayUp),
          )}
        >
          <Typography variant="caption">
            {props.status} {props.acceptedOn.daysAgo}
          </Typography>
          <InfoIcon className={clsx(classes.lightGrey, classes.infoIcon)} />
        </div>
      </Tooltip>
    </div>
  );
};
