import {types} from "./types";

export const filterEventsByType = options => {
  const {organizationId, type} = options;

  return {
    type: types.filterEventsByType,
    payload: {
      organizationId,
      type,
    },
  };
};
