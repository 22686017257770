import {get} from "lodash";

import {getWells} from "utils/wells/get-wells";

import {filterByClassification} from "./filter-by-classification";

/**
 * The field optimization page needs to get its wells from:
 * 1. Wells that are filtered by the user's current tag selection
 * OR
 * 2. All wells the user has access to
 */
export const fieldOptimizationWellsSelector = (state, unfiltered = false) => {
  const baseWellSet = get(
    state,
    "tags.filteredByTags",
    get(state, "wells.allWellIds", []),
  );

  const wells = getWells(baseWellSet, false);

  return unfiltered
    ? wells
    : wells.filter(
        filterByClassification(state.fieldOptimization.classification),
      );
};
