import {connect} from "react-redux";
import {ConnectedRouter} from "react-router-redux";
import {getAuth} from "actions/auth";
import {MuiThemeProvider} from "@material-ui/core/styles";
import {Route, Switch} from "react-router-dom";
import React, {Fragment} from "react";
import MomentUtils from "@date-io/moment";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";

import Analytics from "components/analytics";
import AppBar from "components/app-bar";
import {requireAuthentication} from "components/requires-authentication";
import AcknowledgeAlertDialog from "components/acknowledge-alert-dialog";
import Alerts from "routes/alerts";
import AppDrawer from "components/app-drawer";
import DiscardFormDialog from "components/discard-form-dialog";
import Error from "routes/error";
import ErrorBoundary from "components/error-boundary";
import Home from "routes/home";
import {
  CompleteLogin,
  Login,
  PasswordReset,
  PasswordResetChangePassword,
  PasswordResetCodeExpired,
  PasswordResetRequested,
  VerifyEmail,
  VerifyOAuth,
} from "routes/login";
import TagsDialogEnhanced from "components/tags-dialog-enhanced";
import {ManageTagsDialog} from "components/manage-tags-dialog";
import Profile from "routes/profile";
import FieldOptimization from "routes/field-optimization";
import Recommendations from "routes/recommendations";
import Deviation from "routes/deviation";
import RecommendationActionDialog from "components/recommendation-action-dialog";
import ResolveAlertDialog from "components/resolve-alert-dialog";
import ScrollToTop from "components/scroll-to-top";
import Snackbar from "components/snackbar";
import Well from "routes/well";
import SetpointAutomation from "routes/setpoint-automation";
import Organization from "routes/well/organization";
import WellSummaryDialog from "components/well-summary-dialog";
import FeaturesProvider from "components/features";
import OrganizationSelectionDialog from "components/organization-selection-dialog";
import SearchDialog from "components/search-dialog";

import {history} from "./store";
import theme from "./theme";

const App = props => {
  const {dispatch} = props;
  if (
    !document.location.hash.startsWith("#/login") &&
    !document.location.hash.startsWith("#/invite")
  ) {
    // Avoid duplicate getAuth call to /currentUser.
    // epics/auth will instead run getAuth during sign-in flow: ofType(types.get, types.isCompletingSignIn)
    dispatch(getAuth({}));
  }

  return (
    <ErrorBoundary showAppBar>
      <Analytics />
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <FeaturesProvider>
          <MuiThemeProvider theme={theme}>
            <div>
              <main>
                <ConnectedRouter history={history}>
                  <Fragment>
                    <AppBar />
                    <ScrollToTop>
                      <Switch>
                        <Route
                          exact
                          path="/"
                          component={requireAuthentication(Home)}
                        />
                        <Route exact path="/login" component={Login} />
                        <Route
                          exact
                          path="/login/:clientUserId/:callbackToken"
                          component={CompleteLogin}
                        />
                        <Route
                          exact
                          path="/verify-oauth/:clientUserId/:callbackToken"
                          component={VerifyOAuth}
                        />
                        <Route
                          exact
                          path="/invite/:inviteKey"
                          component={VerifyEmail}
                        />
                        <Route
                          exact
                          path="/verify/:inviteKey"
                          component={VerifyEmail}
                        />
                        <Route
                          exact
                          path="/password-reset"
                          component={PasswordReset}
                        />
                        <Route
                          exact
                          path="/password-reset-requested/:email"
                          component={PasswordResetRequested}
                        />
                        <Route
                          exact
                          path="/password-reset/:passwordResetCode"
                          component={PasswordResetChangePassword}
                        />
                        <Route
                          exact
                          path="/password-reset-code-expired"
                          component={PasswordResetCodeExpired}
                        />
                        <Route
                          exact
                          path="/inbox"
                          component={requireAuthentication(Home)}
                        />
                        <Route
                          path="/profile"
                          component={requireAuthentication(Profile)}
                        />
                        <Route
                          exact
                          path="/alerts"
                          component={requireAuthentication(Alerts)}
                        />
                        <Route
                          path="/well/:downholeLocation"
                          component={requireAuthentication(Well)}
                        />
                        <Route
                          path="/organization/:organizationId/well/:downholeLocation"
                          component={requireAuthentication(Organization)}
                        />
                        <Route
                          exact
                          path="/recommendations"
                          component={requireAuthentication(Recommendations)}
                        />
                        <Route
                          exact
                          path="/setpoint-automation"
                          component={requireAuthentication(SetpointAutomation)}
                        />
                        <Route
                          exact
                          path="/field-optimization"
                          component={requireAuthentication(FieldOptimization)}
                        />
                        <Route
                          path="/deviation"
                          component={requireAuthentication(Deviation)}
                        />
                        <Route exact path="/error" component={Error} />
                        <Route path="*" component={Error} />
                      </Switch>
                    </ScrollToTop>
                  </Fragment>
                </ConnectedRouter>
              </main>
              <AppDrawer />
              <AcknowledgeAlertDialog />
              <ResolveAlertDialog />
              <Snackbar />
              <WellSummaryDialog />
              <RecommendationActionDialog />
              <DiscardFormDialog />
              <TagsDialogEnhanced />
              <ManageTagsDialog />
              <OrganizationSelectionDialog />
              <SearchDialog />
            </div>
          </MuiThemeProvider>
        </FeaturesProvider>
      </MuiPickersUtilsProvider>
    </ErrorBoundary>
  );
};

export default connect()(App);
