import React from "react";

import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import MenuDown from "mdi-material-ui/MenuDown";
import MenuUp from "mdi-material-ui/MenuUp";

import noop from "utils/noop";
import {withRef} from "components/with-ref";

export default props => {
  const {
    size = 50,
    onClick = noop,
    style = {},
    open = false,
    rootRef,
    user,
    className,
    ...rest
  } = props;
  const {name, pictureUrl} = user;
  const clickable = onClick !== noop;

  const avatar = {
    height: size,
    width: size,
    margin: 8,
    ...style,
  };
  const menuArrow = {
    marginTop: 6,
    cursor: "pointer",
  };

  // When the avatar is clickable, display an arrow as affordance
  const clickWrapper = clickable
    ? avatarComponent => (
        <Grid container wrap="nowrap" alignItems="center" spacing={0}>
          <Grid {...rest} item>
            {avatarComponent}
          </Grid>
          <Grid item>
            {open ? (
              <MenuUp style={menuArrow} onClick={onClick} />
            ) : (
              <MenuDown style={menuArrow} onClick={onClick} />
            )}
          </Grid>
        </Grid>
      )
    : avatarComponent => <>{avatarComponent}</>;

  if (!pictureUrl) {
    const firstInitial = name.split(" ")[0].charAt(0);
    const lastInitial = name
      .split(" ")
      .pop()
      .charAt(0);
    const initials = `${firstInitial}${lastInitial}`;
    return withRef(rootRef)(
      clickWrapper(
        <Avatar className={className} style={avatar} onClick={onClick}>
          {initials}
        </Avatar>,
      ),
    );
  }

  return withRef(rootRef)(
    clickWrapper(
      <Avatar
        alt={name}
        className={className}
        src={pictureUrl}
        style={avatar}
        onClick={onClick}
      />,
    ),
  );
};
