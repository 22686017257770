import {types} from "./types";

export const setLufkinFillBase = options => {
  const {values, well} = options;

  return {
    type: types.setLufkinFillBase,
    payload: {
      values,
      well,
    },
  };
};
