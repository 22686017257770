import {of} from "rxjs/observable/of";
import {push} from "react-router-redux";

import {navigateToLogin, setAuth, types} from "actions/auth";
import {show} from "actions/snackbar";
import fetchApi from "epics/fetch";

export default action$ => {
  return action$.ofType(types.changePassword).switchMap(({payload}) => {
    const {password, passwordResetCode} = payload;

    return fetchApi(
      `/client-experience/user/doResetPassword?save=${encodeURIComponent(
        '{"method":"PUT"}',
      )}`,
      {},
      {
        body: {passwordResetCode, password},
        method: "POST",
      },
    )
      .flatMap(res => {
        if (res.status >= 200 && res.status <= 300) {
          return [
            show({
              message:
                "Your password has been updated successfully. Redirecting to sign in.",
            }),
            navigateToLogin(),
          ];
        }

        return of(
          setAuth({
            isAuthenticated: false,
            isFetching: false,
            error:
              "An invalid response was received while changing password. Please try again soon, or contact support if the issue continues.",
          }),
        );
      })
      .catch(err => {
        if (
          err.xhr &&
          err.xhr.response &&
          err.xhr.response.message === "Reset code not found"
        ) {
          return of(push("/password-reset-code-expired"));
        }

        console.error(err);
        return of(
          setAuth({
            isAuthenticated: false,
            isFetching: false,
            error:
              "An error occurred while changing password. Please try again soon, or contact support if the issue continues.",
          }),
        );
      });
  });
};
