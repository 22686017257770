import {replace} from "react-router-redux";

import {createSwitchEpic} from "epics/create-epic";
import {setOrganizationUser, types} from "actions/organizations";
import {show} from "actions/snackbar";
import fetch from "epics/async-fetch";

export default createSwitchEpic(
  [types.fetchOrganizationUser],
  async ({payload}) => {
    try {
      const data = await fetch(
        `/organization/${encodeURIComponent(
          payload.organizationId,
        )}/user/${encodeURIComponent(payload.clientUserId)}`,
      );

      return [
        setOrganizationUser({
          data,
          organizationId: payload.organizationId,
        }),
      ];
    } catch (err) {
      if (err.status === 401 || err.status === 403) {
        return [
          replace("/"),
          show({
            message: "Unauthorized to view organization user",
          }),
        ];
      }
      return [replace(`/error?code=${err.status}`)];
    }
  },
);
