import {types} from "actions/rod-dimensions";

const defaultValues = {search: []};

const filterPerWord = filter => spec => {
  if (!filter) {
    return true;
  }

  const label = spec.label.toLowerCase();

  return filter
    .split(" ")
    .reduce((isMatch, word) => isMatch && label.indexOf(word) >= 0, true);
};

export default (state = defaultValues, action) => {
  if (action.type === types.set) {
    return {
      ...state,
      all: action.payload.data,
    };
  }

  if (action.type === types.filter) {
    const exactMatch = state.all.filter(
      spec => spec.label === action.payload.phrase,
    );
    const filtered =
      exactMatch.length === 1
        ? []
        : state.all.filter(filterPerWord(action.payload.phrase.toLowerCase()));

    return {
      ...state,
      filtered,
    };
  }

  return state;
};
