import {show} from "actions/snackbar";
import {types} from "actions/wells";
import fetch from "epics/async-fetch";

export default action$ => {
  return action$
    .ofType(types.dismissRecommendation)
    .switchMap(async ({payload}) => {
      const {reason, recommendationId} = payload;

      await fetch(
        `/recommendation/${recommendationId}/dismiss`,
        {},
        {
          method: "POST",
          body: {reason},
        },
      );

      return show({
        message: "The recommendation has been successfully declined!",
      });
    });
};
