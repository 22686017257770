import {number} from "./number";

export const min = min => value => {
  number(value);

  if (value < min) {
    throw new Error(
      `Expected value to be a \`number greater than or equal to ${min}\` but received \`${value}\``,
    );
  }
};
