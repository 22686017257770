import React from "react";
import {connect} from "react-redux";

import {withPageview} from "components/analytics";
import findWell from "utils/find-well";

import Setup from "./setup";

const Tab = ({well}) => {
  return (
    <div style={{height: "calc(100% - 60px)"}}>
      <Setup well={well} />
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    well: findWell(state.wells, props.downholeLocation),
  };
};

export default withPageview()(connect(mapStateToProps)(Tab));
