import React from "react";

import Aux from "components/aux";
import determineIndefiniteArticle from "utils/determine-indefinite-article";
import getDisplayName from "utils/user/get-display-name";
import FeedItem from "../feed-item";
import getEventAlertType from "../get-event-alert-type";
import {lineStyle} from "../styles";

const hasComment = event => !!event.value.resolved.comment;

const renderComment = event => {
  return (
    <Aux>
      <span>{" with the comment: "}</span>
      <span style={lineStyle} />
      {event.value.resolved.comment}
    </Aux>
  );
};

export default props => {
  const {event} = props;

  const alertType = getEventAlertType(event);

  return (
    <FeedItem event={event}>
      {getDisplayName(event)}
      {` has resolved ${determineIndefiniteArticle(alertType)} `}
      <strong>{alertType}</strong>
      {`${hasComment(event) ? " " : "."}`}
      {hasComment(event) && renderComment(event)}
    </FeedItem>
  );
};
