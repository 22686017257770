import compose from "lodash/fp/compose";
import countBy from "lodash/fp/countBy";
import defaults from "lodash/fp/defaults";
import reduce from "lodash/fp/reduce";

import {CLASSIFICATION} from "constants/classification";
import {getLatestClassification} from "utils/well/get-latest-classification";

const toZero = reduce((acc, i) => ({...acc, [i]: 0}), {}, CLASSIFICATION);

const countClassification = compose(
  defaults(toZero),
  countBy(getLatestClassification),
);

export default countClassification;
