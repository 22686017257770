import React from "react";
import Button from "@material-ui/core/Button/Button";
import clsx from "clsx";
import noop from "lodash/noop";

import TableCell from "components/table-cell";
import {useHover} from "hooks/use-hover";

import Dots from "./dots";
import {findColor} from "./find-color";
import Labels from "./labels";
import {MAX, MIN, STEP} from "./range-slider-constants";
import StyledSlider from "./styled-slider";
import thumb from "./thumb";
import useRangeSliderStyles from "./use-range-slider-styles";

const RangeSlider = ({
  disabled,
  editMode,
  rangeSliderState,
  rangeSliderOnChange,
  updateSOP,
  cancelSafeOperatingParameters,
  metBoundary,
  minSPM,
  maxSPM,
  limitedAccess,
}) => {
  const color = findColor({disabled, metBoundary});
  const onChange = disabled || limitedAccess ? noop : rangeSliderOnChange;
  const classes = useRangeSliderStyles({
    color,
    minSPM,
    maxSPM,
    metBoundary,
    limitedAccess,
  });
  const {isHovering, ...useHoverState} = useHover();

  const showLabels = isHovering && !disabled && !limitedAccess;
  const showDots = !disabled && !isHovering && !limitedAccess;
  const useDisabledClass = disabled || limitedAccess;

  return (
    <>
      <TableCell className={classes.removeRightPadding}>
        <div className={classes.container}>
          {showDots && <Dots minSPM={minSPM} maxSPM={maxSPM} color={color} />}
          {showLabels && <Labels metBoundary={metBoundary} />}
          <StyledSlider
            onChange={onChange}
            classes={{root: classes.root, valueLabel: classes.valueLabel}}
            className={clsx(useDisabledClass && classes.disabled)}
            value={rangeSliderState}
            valueLabelDisplay={showLabels ? "auto" : "off"}
            ThumbComponent={thumb(useHoverState)}
            step={STEP}
            min={MIN}
            max={MAX}
          />
        </div>
      </TableCell>
      <TableCell>
        <div className={classes.buttonGroup}>
          {editMode && (
            <>
              <Button
                onClick={updateSOP}
                className={classes.contained}
                variant="contained"
                disabled={metBoundary}
              >
                update
              </Button>
              <Button
                className={classes.button}
                onClick={cancelSafeOperatingParameters}
              >
                cancel
              </Button>
            </>
          )}
        </div>
      </TableCell>
    </>
  );
};

export default RangeSlider;
