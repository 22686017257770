import isNil from "lodash/isNil";
import React from "react";
import Typography from "@material-ui/core/Typography";

import {defaultMainStyle, defaultTitleStyle} from "./dashboard-styles";

const Deflection = props => {
  const {well} = props;

  if (isNil(well.conditions.deflection)) return null;

  return (
    <div>
      <Typography variant="body1" align="right" style={{...defaultTitleStyle}}>
        Deflection
      </Typography>
      <Typography variant="h6" align="right" style={{...defaultMainStyle}}>
        {well.conditions.deflection}°
      </Typography>
    </div>
  );
};

export default Deflection;
