import getSafeOperatingSpeedMax from "@ambyint/common/utils/wells/get-safe-operating-speed-max";
import getSafeOperatingSpeedMin from "@ambyint/common/utils/wells/get-safe-operating-speed-min";

import decimalPrecision from "utils/validation/decimal-precision";
import max from "utils/validation/max";
import min from "utils/validation/min";
import number from "utils/validation/number";
import required from "utils/validation/required";
import validateFieldsOf from "utils/validation/validate-fields-of";

export default (values, props) => {
  const {well} = props;

  if (!well) return undefined;

  const validateField = validateFieldsOf(values);

  const maxSpeed = getSafeOperatingSpeedMax(well);
  const minSpeed = getSafeOperatingSpeedMin(well);

  return {
    ...validateField("speed", x => required(x)),
    ...validateField("speed", x => number(x)),
    ...validateField("speed", x => min(x, minSpeed)),
    ...validateField("speed", x => max(x, maxSpeed)),
    ...validateField("speed", x => decimalPrecision(x, 1)),
  };
};
