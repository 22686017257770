import {types} from "./types";

export const set = ({
  currentOrganization,
  redirectTo = null,
  shouldReset = true,
  shouldSave = true,
}) => {
  return {
    type: types.set,
    payload: {
      currentOrganization,
      redirectTo,
      shouldReset,
      shouldSave,
    },
  };
};
