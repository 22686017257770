import React from "react";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import withStyles from "@material-ui/core/styles/withStyles";

import Tooltip from "components/material/tooltip";

import TableCell from "./table-cell";

const styles = ({colors}) => ({
  root: {
    color: colors.warmGrey[500],
    "&:focus": {
      color: colors.warmGrey[800],
    },
    "&:hover": {
      color: colors.warmGrey[900],
    },
  },
  active: {
    color: colors.warmGrey[800],
  },
});

const TableHeaderCell = ({
  width,
  label,
  field,
  sortBy,
  sortAsc,
  onClick,
  align = "left",
  tooltip,
  classes,
  ...rest
}) => (
  <TableCell align={align} style={{width}} {...rest}>
    {tooltip ? (
      <Tooltip title={tooltip}>
        <TableSortLabel
          onClick={onClick}
          active={field === sortBy}
          direction={sortAsc ? "asc" : "desc"}
          classes={{root: classes.root, active: classes.active}}
        >
          {label}
        </TableSortLabel>
      </Tooltip>
    ) : (
      <TableSortLabel
        onClick={onClick}
        active={field === sortBy}
        classes={{root: classes.root}}
        direction={sortAsc ? "asc" : "desc"}
      >
        {label}
      </TableSortLabel>
    )}
  </TableCell>
);
export default withStyles(styles)(TableHeaderCell);
