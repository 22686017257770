import React from "react";
import ReactGA from "react-ga";

export default path => {
  return Component => {
    class withPageview extends React.Component {
      componentDidMount() {
        if (path) {
          ReactGA.pageview(path);
        } else {
          const hash = window.location.hash;
          ReactGA.pageview(hash.substring(1));
        }
      }

      render() {
        return <Component {...this.props} />;
      }
    }

    return withPageview;
  };
};
