import React from "react";
import Typography from "@material-ui/core/Typography";

import formatDateTime from "@ambyint/common/utils/format/format-date-time";

import PaperContainer from "components/paper-container";
import DriveDataChart from "routes/well/tabs/analysis/comparisons/comparison/drive-data-chart";

import getLatestDriveData from "utils/well/get-latest-drive-data";
import getMotorDataType from "utils/get-motor-data-type";

import {
  defaultContainerStyle,
  defaultGridContainer,
  defaultTitleStyle,
} from "./dashboard-styles";

export default props => {
  const {well} = props;

  const driveData = getLatestDriveData(well);

  if (!driveData) return null;

  return (
    <PaperContainer style={defaultContainerStyle}>
      <div
        style={{...defaultGridContainer, ...{padding: "16px 16px 8px 16px"}}}
      >
        <Typography variant="body1" style={{...defaultTitleStyle}}>
          {`${getMotorDataType(well.devices)} Data on ${formatDateTime(
            driveData.createdOn,
            {timezone: true},
          )}`}
        </Typography>
        <DriveDataChart
          data={{driveData}}
          devices={well.devices}
          width={320}
          height={175}
          margin={{
            top: 35,
            right: 40,
            bottom: 25,
            left: 40,
          }}
        />
      </div>
    </PaperContainer>
  );
};
