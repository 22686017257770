import React from "react";

import getDisplayName from "utils/user/get-display-name";

import formatDateTime from "@ambyint/common/utils/format/format-date-time";
import FeedItem from "../feed-item";

export default props => {
  const {event} = props;

  return (
    <FeedItem event={event}>
      <strong>{getDisplayName(event)}</strong>
      {" has scheduled downtime until "}
      <strong>
        {formatDateTime(event.value.scheduledUntil, {timezone: true})}
      </strong>
      {"."}
    </FeedItem>
  );
};
