import process from "../process";

export const setDisplayAllWells = ({state, action}) => {
  return process({
    ...state,
    workflow: {
      ...state.workflow,
      displayAll: {
        ...state.workflow.displayAll,
        ...action.payload,
      },
    },
  });
};
