import {types} from "./types";

export const setDeviationFilter = deviationFilter => {
  return {
    type: types.setDeviationFilter,
    payload: {
      deviationFilter,
    },
  };
};
