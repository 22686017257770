import {push, replace} from "react-router-redux";
import {initialize} from "redux-form";

import {createSwitchEpic} from "epics/create-epic";
import {types} from "actions/organizations";
import {show} from "actions/snackbar";
import fetch from "epics/async-fetch";

export default createSwitchEpic([types.inviteUser], async ({payload}) => {
  const {
    accessLevel,
    downholeLocations,
    email,
    firstName,
    isInvitedUserApprover,
    lastName,
    organizationId,
    wellIds,
  } = payload;

  try {
    await fetch(
      `/organization/${encodeURIComponent(payload.organizationId)}/invite`,
      {},
      {
        method: "POST",
        body: JSON.stringify({
          accessLevel,
          assignedWells: Object.keys(downholeLocations).filter(
            downholeLocation => downholeLocations[downholeLocation] === true,
          ),
          email,
          firstName,
          isInvitedUserApprover,
          lastName,
          organizationId,
          userLogin: email,
        }),
      },
    );

    return [
      show({
        message: "Your request to invite user submitted successfully!",
      }),
      initialize("invite-user", {
        accessLevel,
        isInvitedUserApprover,
        wells: wellIds,
        email: "",
        firstName: "",
        lastName: "",
      }),
    ];
  } catch (err) {
    if (err.status === 401 || err.status === 403) {
      return [
        replace("/"),
        show({
          message: "Unauthorized to invite user to organization",
        }),
      ];
    }

    if (err.status === 409) {
      return [
        show({
          message: `The user ${email} has already been invited and can be managed from the users page.`,
          button: {
            label: "Manage",
            action: push(`/profile/organization/${organizationId}/users`),
          },
        }),
      ];
    }

    return [replace(`/error?code=${err.status}`)];
  }
});
