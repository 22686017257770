import {hasControlForEpm} from "@ambyint/common/utils/devices/control-for-epm";

import {show} from "actions/snackbar";
import {types} from "actions/wells";
import fetch from "epics/async-fetch";
import getEpmPreference from "utils/get-epm-preference";
import parse from "./parse-save-motor-violations";

const getUrl = (well, viewingCurrent) => {
  const setpointId = viewingCurrent
    ? "currentViolationSetpoint"
    : "torqueViolationSetpoint";

  return `/optimization/well/${encodeURIComponent(
    well.downholeLocation,
  )}/motorViolations/setpoints/${setpointId}`;
};

export default action$ => {
  return action$
    .ofType(types.saveMotorViolations)
    .switchMap(async ({payload}) => {
      const {data, well} = payload;

      const viewingCurrent =
        hasControlForEpm(well.devices) && getEpmPreference() === "Current";

      await fetch(
        getUrl(well, viewingCurrent),
        {},
        {
          body: JSON.stringify(parse({data, viewingCurrent})),
          method: "PUT",
        },
      );

      return show({
        message: "You have successfully updated the Motor Violations.",
      });
    });
};
