import React, {Component} from "react";
import {hasControlForEpm} from "@ambyint/common/utils/devices/control-for-epm";
import {hasControlForVfd} from "@ambyint/common/utils/devices/control-for-vfd";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Comparison from "./comparison";

const styles = {
  container: {
    margin: "8px 16px",
  },
  leftButton: {
    marginLeft: "10px",
  },
  toolbar: {
    marginBottom: "10px",
  },
};

const mapComparison = (
  devices,
  goToSetup,
  remove,
  showRawComparisons,
  toggleComparisonsView,
) => comparison => {
  if (comparison.isFetching === true) {
    return null;
  }

  return (
    <Comparison
      devices={devices}
      key={comparison.correlationId}
      comparison={comparison}
      goToSetup={goToSetup}
      remove={remove}
      showRawComparisons={showRawComparisons}
      toggleComparisonsView={toggleComparisonsView}
    />
  );
};

const renderToolbar = ({
  devices,
  showRawComparisons,
  toggleComparisonsView,
}) => {
  const isEpm = hasControlForEpm(devices);
  const isVfd = hasControlForVfd(devices);

  return (
    <Grid container alignItems="center" spacing={0} style={styles.toolbar}>
      <Typography variant="h5">Comparisons</Typography>
      {!isVfd && !isEpm && (
        <Button
          color={showRawComparisons ? "default" : "primary"}
          onClick={() => {
            if (showRawComparisons) {
              toggleComparisonsView();
            }
          }}
          style={styles.leftButton}
        >
          Analysis
        </Button>
      )}
      {!isVfd && !isEpm && (
        <Button
          color={showRawComparisons ? "primary" : "default"}
          onClick={() => {
            if (!showRawComparisons) {
              toggleComparisonsView();
            }
          }}
        >
          POC
        </Button>
      )}
    </Grid>
  );
};

export default class extends Component {
  shouldComponentUpdate(props) {
    return JSON.stringify(this.props) !== JSON.stringify(props);
  }

  render() {
    const {
      comparisons = [],
      devices,
      goToSetup,
      remove,
      showRawComparisons,
      toggleComparisonsView,
    } = this.props;

    return comparisons.length > 0 ? (
      <div style={styles.container}>
        {renderToolbar(this.props)}
        <Grid container spacing={0} wrap="wrap">
          {comparisons.map(
            mapComparison(
              devices,
              goToSetup,
              remove,
              showRawComparisons,
              toggleComparisonsView,
            ),
          )}
        </Grid>
      </div>
    ) : null;
  }
}
