import get from "lodash/get";

import {RECENT_ORGANIZATIONS} from "constants/local-storage-keys";

export const getRecentOrganizations = userId => {
  const recentOrgsAllUsers = JSON.parse(
    localStorage.getItem(RECENT_ORGANIZATIONS) || "{}",
  );
  const recentOrgsThisUser = get(recentOrgsAllUsers, userId, []);

  return recentOrgsThisUser;
};
