import React from "react";
import MUIPaper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = () => ({
  root: {
    marginBottom: 10,
    padding: 30,
  },
});

const PaperWrapper = ({children, classes}) => {
  return <MUIPaper className={classes.root}>{children}</MUIPaper>;
};

export default withStyles(styles)(PaperWrapper);
