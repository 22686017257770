import {types} from "actions/app-drawer";

const defaultValues = {
  isOpen: false,
};

export default (state = defaultValues, action) => {
  if (action.type === types.toggle) {
    return {
      ...state,
      isOpen: action.payload.isOpen,
    };
  }

  return state;
};
