import React from "react";
import {connect} from "react-redux";
import {XAxis} from "react-vis";

import {warmGrey} from "theme/updated-colors";

const defaultProps = {
  unit: "weeks",
};

const mapStateToProps = ({wellHealth}) => ({
  ...wellHealth.date,
});

function DateHoverAxis({unit, displayDays, displayWeeks, focus, ...props}) {
  const data = unit === "days" ? displayDays : displayWeeks;
  return (
    <XAxis
      {...props}
      hideLine
      orientation="top"
      tickSize={0}
      tickPadding={16}
      tickFormat={(value, index) => (index === focus ? data[index] : null)}
      style={{
        text: {fill: warmGrey[500], fontSize: "10px"},
      }}
    />
  );
}

DateHoverAxis.defaultProps = defaultProps;
DateHoverAxis.requiresSVG = true;

export default connect(mapStateToProps)(DateHoverAxis);
