import React from "react";
import CloseIcon from "mdi-material-ui/Close";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Typography from "@material-ui/core/Typography/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = ({spacing}) => ({
  root: {
    postion: "relative",
    padding: [[spacing(3), spacing(4)]],
  },
  closeIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    padding: spacing(1),
    margin: spacing(1),
  },
});

const Header = ({classes, handleClose}) => {
  return (
    <div className={classes.root}>
      <Typography color="inherit" variant="h6">
        Reason for dismissing
      </Typography>
      <IconButton
        color="inherit"
        className={classes.closeIcon}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
};

export default withStyles(styles)(Header);
