import getUnits from "utils/get-units";

const addPoints = ({data, shutdownPeriods, shutdownValue, type}) =>
  shutdownPeriods.reduce((data, shutdownPeriod) => {
    const {start, end, lastDataPoint} = shutdownPeriod;

    const units = getUnits()[type];

    data.push({
      createdOn: start,
      type,
      ...(units ? {units} : {}),
      value: shutdownValue,
    });

    if (end && lastDataPoint)
      data.push({
        createdOn: end,
        type,
        ...(units ? {units} : {}),
        value: lastDataPoint.value,
      });

    return data;
  }, data);

const sortByDate = (a, b) => new Date(a.createdOn) - new Date(b.createdOn);

export default options => addPoints(options).sort(sortByDate);
