import React from "react";
import clsx from "clsx";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  pill: {
    display: "inline-block",
    padding: "0 .5em",
    fontSize: theme.typography.pxToRem(12),
    lineHeight: "1.5em",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "baseline",
    borderRadius: ".35rem",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.text.hint,
    "&:hover": {
      backgroundColor: theme.palette.text.secondary,
    },
  },
});

const Pill = ({classes, content}) => (
  <span className={clsx(classes.pill)}>{content}</span>
);

export default withStyles(styles)(Pill);
