import React from "react";
import {connect} from "react-redux";
import compose from "recompose/compose";
import Checkbox from "@material-ui/core/Checkbox";
import withStyles from "@material-ui/core/styles/withStyles";
import clsx from "clsx";

import {bySelectedWells} from "utils/tagging/by-selected-wells";
import {toggleWellSelected, clearSelectedWells} from "actions/tagging";

const styles = ({colors}) => ({
  checkBox: {
    height: 12,
    width: 12,
    "& > span": {
      color: colors.warmGrey[200],
    },
    "&:hover": {
      "& > span": {
        color: colors.warmGrey[700],
      },
    },
  },
  selectionMode: {
    "& > span": {
      color: colors.warmGrey[700],
    },
  },
  root: {
    padding: 0,
  },
});

const TagCheckbox = ({
  classes,
  clearSelectedWells,
  wellIds,
  selectedWells,
  toggleWellSelected,
  isSelectionMode,
}) => {
  const checked = wellIds.every(bySelectedWells(selectedWells));
  const multipleWells = wellIds.length > 1;

  const indeterminate =
    multipleWells && !checked && wellIds.some(bySelectedWells(selectedWells));

  const handleToggleSelectWell = event => {
    event.stopPropagation(); // This is important so that the parent row click handler does not trigger when checkbox is clicked directly
    return indeterminate ? clearSelectedWells() : toggleWellSelected(wellIds);
  };

  return (
    <Checkbox
      indeterminate={indeterminate}
      checked={checked}
      className={clsx(
        classes.checkBox,
        isSelectionMode && classes.selectionMode,
      )}
      onClick={handleToggleSelectWell}
      classes={{root: classes.root}}
    />
  );
};

const mapStateToProps = ({tags}) => ({
  selectedWells: tags.selectedWells,
});

const mapDispatchToProps = {
  toggleWellSelected,
  clearSelectedWells,
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(TagCheckbox);
