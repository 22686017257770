import * as updatedColors from "./updated-colors";

const clockColor = "#FC8257";

export const muiPickersOverrides = {
  MuiPickersToolbar: {
    toolbar: {
      backgroundColor: updatedColors.warmGrey[50],
    },
  },
  MuiPickersClockNumber: {
    clockNumberSelected: {
      color: "white",
    },
  },
  MuiPickersClockPointer: {
    pointer: {
      backgroundColor: clockColor,
    },
    thumb: {
      border: "14px solid " + clockColor,
    },
    noPoint: {
      backgroundColor: clockColor,
    },
  },
  MuiPickersClock: {
    pin: {
      backgroundColor: clockColor,
    },
    clock: {
      backgroundColor: updatedColors.warmGrey[50],
    },
  },
};
