import {setEvents, types} from "actions/wells";
import {createMergeEpic} from "epics/create-epic";
import fetch from "epics/async-fetch";

const eventType = "classificationOverrides";

const getOverrideEvent = async ({downholeLocation, cardId}) => {
  try {
    const override = await fetch(
      `/wells/${encodeURIComponent(
        downholeLocation,
      )}/cards/${cardId}/classificationOverride`,
    );

    return {
      downholeLocation,
      data: [
        {
          ...override,
          cardId,
          type: eventType,
        },
      ],
    };
  } catch (err) {
    return {
      downholeLocation,
      data: [
        {
          cardId,
          type: eventType,
        },
      ],
    };
  }
};

export const fetchCardOverride = createMergeEpic(
  [types.fetchCardOverride],
  async ({payload}) => {
    const overrideEvent = await getOverrideEvent(payload);
    return [setEvents(overrideEvent)];
  },
);
