import {
  AreaSeries,
  FlexibleWidthXYPlot,
  LineSeries,
  MarkSeries,
} from "react-vis/es";
import React from "react";

import withAspectRatio from "components/with-aspect-ratio";

const ASPECT_RATIO = 1 / 4;
const curve = "curveMonotoneX";

const Plot = ({height, data, avgData, markData, outlier, yDomain}) => {
  return (
    <FlexibleWidthXYPlot
      dontCheckIfEmpty
      height={height}
      margin={{left: 5, right: 5, top: 5, bottom: 5}}
      yDomain={yDomain}
    >
      <AreaSeries
        data={data}
        color={outlier ? "#f9dada" : "#DDD"}
        curve={curve}
        getNull={d => d.y !== null && !isNaN(d.y)}
      />

      <LineSeries
        data={data}
        color={outlier ? "B33A3A" : "#999"}
        strokeWidth={1}
        curve={curve}
        getNull={d => d.y !== null && !isNaN(d.y)}
      />

      <LineSeries
        data={avgData}
        color={"#999"}
        strokeWidth={1}
        strokeDasharray={[5, 3]}
        curve={curve}
      />

      <MarkSeries
        data={markData}
        size={2.5}
        color={outlier ? "B33A3A" : "#999"}
      />
    </FlexibleWidthXYPlot>
  );
};

export default withAspectRatio(ASPECT_RATIO)(Plot);
