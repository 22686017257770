import {set, types} from "actions/guides";
import parseGuideType from "@ambyint/common/parsers/well/parse-guide-type";
import performFetch from "epics/fetch";

import getUser from "utils/get-user";
import getUnits from "utils/get-units";
import convertTo from "utils/conversion/convert-to";
import convertLength from "utils/conversion/convert-length";
import valueOf from "utils/value-of";

export const fetch = action$ => {
  return action$
    .ofType(types.fetch)
    .switchMap(({payload: {rodDiameter, tubingDiameter}}) => {
      const unitsOfMeasure = getUser().unitsOfMeasure;
      const units = getUnits();
      const to = convertTo(units, unitsOfMeasure);
      const toLength = to(convertLength, {to: "m"});
      const rodDiameterM = valueOf(
        toLength(rodDiameter, {to: "m", decimals: 6}),
      );
      const tubingDiameterM = valueOf(toLength(tubingDiameter, {to: "m"}));
      return performFetch(
        `/optimization/guides/${rodDiameterM}/${tubingDiameterM}`,
      ).map(({response: data}) =>
        set({
          data: data.map(item => parseGuideType(item, unitsOfMeasure)),
        }),
      );
    });
};
