import compose from "lodash/fp/compose";
import filter from "lodash/fp/filter";
import groupBy from "lodash/fp/groupBy";
import isNil from "lodash/isNil";
import map from "lodash/fp/map";
import maxBy from "lodash/maxBy";
import moment from "moment";
import parseEvents from "@ambyint/common/parsers/well/events";
import reduce from "lodash/fp/reduce";
import {replace} from "react-router-redux";
import some from "lodash/some";
import times from "lodash/times";
import toPairs from "lodash/fp/toPairs";

import {CLASSIFICATIONS} from "constants/well-health";
import {createMergeEpic} from "epics/create-epic";
import fetch from "epics/async-fetch";
import getUser from "utils/get-user";
import {setClassifications, types} from "actions/wells/well-health";

const labelToKey = label => {
  if (label === "Unclassified") return "others";

  return label.replace(/\s/g, "").toLowerCase();
};

const getClassification = event => {
  // LowloadDL must be >= 80 to be confident this is a lowload anomaly.
  const isLowLoadDLAnomaly = some(
    event.value.lowLoadDL,
    o => o.confidence >= 80,
  );

  // highLoad must be >= 80 to be confident this is a highload anomaly.
  const isHighLoadAnomaly = some(event.value.highLoad, o => o.confidence >= 80);

  // One of the intrastroke types must be >= 60 to be confident this is an intrastroke anomaly.
  const isIntrastrokeAnomaly = some(
    event.value.intrastroke,
    o => o.confidence >= 60,
  );

  // If any of the above are true, this is an anomaly card.
  const isAnomaly =
    isLowLoadDLAnomaly || isHighLoadAnomaly || isIntrastrokeAnomaly;

  // Get the most confident pump health classification.
  // Convert the label to a key for use when counting.
  const pumpHealthConfidence = maxBy(event.value.pumpHealth, "confidence");
  const pumpHealthClassification = labelToKey(
    pumpHealthConfidence ? pumpHealthConfidence.label : "Unclassified",
  );

  // Return the information about how this card is being counted.
  // It's either an anomaly of some type (we don't care which) or we use its pump health classification.
  return isAnomaly ? "anomaly" : pumpHealthClassification;
};

// Given a start date, go through the provided classification event objects and sort them into weeks.
// Each week must know:
//      total cards for each type
//      percentage of the total cards for each type
//      total number of cards
const countClassifications = (start, classificationEvents) => {
  const mStart = moment(start)
    .utc()
    .startOf("day");

  // 1. Remove any events without a "value" object or with a "value" object that contains none of the types we are handling.
  // 2. Find the date and most confident classification for each event.
  // 3. Group by the week of the year.
  // 4. Split in pairs [dateKey, events].
  // 5. Make a list of classification types so that we can get the count, percentage of total, and total for each week for each type.
  // 6. Convert the object to an array.
  const processEvents = compose(
    map(dataForWeek => dataForWeek),
    reduce((accumulator, [dateKey, events]) => {
      const classificationList = map(event => event.classification, events);

      // If this event occurs in one of the weeks we are counting, then handle it.
      // Otherwise ignore it.
      if (accumulator[dateKey]) {
        CLASSIFICATIONS.forEach(type => {
          const count = classificationList.filter(val => val === type).length;
          accumulator[dateKey][type].count = count;
          accumulator[dateKey][type].percent =
            count / classificationList.length;
          accumulator[dateKey].total = classificationList.length;
        });
      }

      return accumulator;
    }, getEmptyResult(mStart)),
    toPairs,
    groupBy(event =>
      moment(event.createdOn)
        .utc()
        .format("GGGG-W"),
    ),
    map(event => ({
      createdOn: event.createdOn,
      classification: getClassification(event),
    })),
    filter(
      event =>
        !isNil(event.value) &&
        (event.value.lowLoadDL ||
          event.value.highLoad ||
          event.value.intrastroke ||
          event.value.pumpHealth),
    ),
  );

  return processEvents(classificationEvents);
};

const getEmptyResult = startDate => {
  const result = {};

  times(26, i => {
    const dateKey = startDate
      .clone()
      .add(i, "week")
      .format("GGGG-W");

    result[dateKey] = {
      total: null,
      week: dateKey,
    };

    CLASSIFICATIONS.forEach(type => {
      result[dateKey][type] = {
        count: null,
        percent: null,
      };
    });
  });

  return result;
};

const generateSeries = counts =>
  reduce(
    (accumulator, type) => {
      return {
        ...accumulator,
        [type]: map(weekData => {
          return {x: weekData.week, y: weekData[type].percent};
        }, counts),
      };
    },
    {},
    CLASSIFICATIONS,
  );

export const fetchClassifications = createMergeEpic(
  [types.fetchClassifications],
  async ({payload}, store) => {
    const {wellId, downholeLocation, start, end} = payload;
    try {
      const classifications = await fetch(
        `/wells/${encodeURIComponent(downholeLocation)}/events`,
        {
          downholeLocation,
          wellId,
          start,
          end,
          types: "downholeCardClassifications",
        },
      );

      // Run these classifications through the parser to standardize the output.
      const {unitsOfMeasure} = getUser();
      const parsed = parseEvents(unitsOfMeasure)(classifications);
      const counts = countClassifications(start, parsed);
      const series = generateSeries(counts);

      return [setClassifications(counts, series)];
    } catch (err) {
      return [replace(`/error?code=${err.status}`)];
    }
  },
);
