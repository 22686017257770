import React from "react";

import {parseRaw} from "./parse-raw";
import {readFile} from "./read-file";

const getFiles = e => {
  const dt = e.dataTransfer || e.event || {};
  let files = dt.files ? dt.files : dt.items ? dt.items : [];

  return [...files];
};

export class DragDrop extends React.Component {
  state = {
    active: false,
  };

  onDragEnter = e => {
    e.preventDefault();

    this.setState({
      active: true,
    });
  };

  onDragLeave = e => {
    e.preventDefault();

    this.setState({
      active: false,
    });
  };

  onDragOver = e => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      active: true,
    });
  };

  onDrop = async e => {
    const {onRead, onError = console.log} = this.props;

    e.preventDefault();
    e.stopPropagation();

    this.setState({
      active: false,
    });

    const files = getFiles(e);

    if (files.length === 1) {
      const file = files[0];

      if (!file.name.endsWith(".csv")) {
        return onError(new Error("Only .csv files are supported."));
      }

      try {
        const raw = await readFile(file);
        const parsed = await parseRaw(raw);

        if (onRead) {
          onRead({
            data: parsed.slice(1),
            headers: parsed[0],
          });
        }
      } catch (err) {
        return onError(err);
      }
    } else {
      return onError(new Error("Only one file can be dropped at a time."));
    }
  };

  render() {
    const {children, height = "100%"} = this.props;
    const {active} = this.state;

    const style = {
      height,
      ...(active ? {backgroundColor: "#eeeeee"} : {}),
    };

    return (
      <div
        name="read-file-container"
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        onDragOver={this.onDragOver}
        onDrop={this.onDrop}
        style={style}
      >
        {children}
      </div>
    );
  }
}
