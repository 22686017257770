import {types} from "./types";

export const dailySummariesRetrieved = dailySummaries => {
  return {
    type: types.dailySummariesRetrieved,
    payload: {
      dailySummaries,
    },
  };
};
