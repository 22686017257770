import React from "react";

const styles = {
  bar: {
    color: "rgba(0,0,0,0.6)",
    fontSize: 12,
    padding: 5,
  },

  wrapper: {
    width: "100%",
  },
};

const Bar = ({background, isCurrent = true, width}) => {
  return (
    <div style={styles.wrapper}>
      <div
        style={{
          ...styles.bar,
          background,
          width: `${width}%`,
        }}
      >
        {isCurrent ? "Current" : "Previous"}
      </div>
    </div>
  );
};

export default Bar;
