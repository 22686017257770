import {types} from "actions/search";
import {reducerFactory} from "reducers/reducer-factory";

import {toggleSearchDialog} from "./toggle-search-dialog";

const defaults = {
  searchDialogOpen: false,
};

const actionMap = {
  [types.toggleSearchDialog]: toggleSearchDialog,
};

export default reducerFactory(actionMap, defaults);
