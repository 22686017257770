const formatTest = value => {
  return typeof value === "string" ? value.split(" ").join("") : "";
};

export default {
  inputMask: number => {
    const test = formatTest(number || "");

    if (test.length <= 12) {
      return "+9 999 999 9999";
    }

    if (test.length === 13) {
      return "+99 99 9999 9999";
    }

    return "+999 999 999 9999";
  },
  outputMask: number => {
    const test = formatTest(number || "");

    if (test.length <= 12) {
      return "+99999999999";
    }

    if (test.length === 13) {
      return "+999999999999";
    }

    return "+9999999999999";
  },
};
