import React from "react";
import MenuItem from "@material-ui/core/MenuItem";

export const AsMenuItem = ({isMenuItem = false, children, ...props}) => {
  if (isMenuItem) {
    return (
      <MenuItem style={{display: "flex"}} {...props}>
        {children}
      </MenuItem>
    );
  }

  return (
    <div style={{display: "flex"}} {...props}>
      {children}
    </div>
  );
};
