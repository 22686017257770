export default ({values, reverse}) => {
  return values.sort(function(a, b) {
    let aV =
      a.rollup.fillage && a.rollup.fillage.length >= 1
        ? a.rollup.fillage[0].value
        : -1;
    let bV =
      b.rollup.fillage && b.rollup.fillage.length >= 1
        ? b.rollup.fillage[0].value
        : -1;

    if (aV === bV) {
      aV = a.displayName.toLowerCase();
      bV = b.displayName.toLowerCase();
    }

    return reverse * ((aV > bV) - (bV > aV));
  });
};
