import React from "react";
import WellCardHeader from "./well-card-header";
import withGreyStyles from "./with-grey-styles";
import withOrangeStyles from "./with-orange-styles";
import withRedStyles from "./with-red-styles";

const variantMap = {
  normal: withGreyStyles(WellCardHeader),
  anomaly: withRedStyles(WellCardHeader),
  override: withOrangeStyles(WellCardHeader),
};

const WellHeaderVariants = ({variant, ...props}) => {
  const WellHeader = variantMap[variant];
  return <WellHeader variant={variant} {...props} />;
};

export default WellHeaderVariants;
