import {reducerFactory} from "reducers/reducer-factory";

import {types} from "actions/analytics";

import {setInitialized} from "./set-initialized";

const defaults = {
  trackingId: process.env.REACT_APP_ANALYTICS_KEY,
  initialized: false,
};

const actionMap = {
  [types.setInitialized]: setInitialized,
};

export default reducerFactory(actionMap, defaults);
