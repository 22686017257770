import {reducerFactory} from "reducers/reducer-factory";
import {types} from "actions/import-settings";

import {defaults} from "./defaults";
import {resetSettings} from "./reset-settings";
import {setMappings} from "./set-mappings";
import {setSetting} from "./set-setting";

const actionMap = {
  [types.RESET_SETTINGS]: resetSettings,
  [types.SET_MAPPINGS]: setMappings,
  [types.SET_SETTING]: setSetting,
};

export const importSettings = reducerFactory(actionMap, {...defaults});
