import {connect} from "react-redux";
import React from "react";

import {filter as searchAlerts} from "actions/alerts";

import Search from "../search";

const mapDispatchToProps = dispatch => {
  return {
    search: filter => dispatch(searchAlerts({filter})),
  };
};

const mapStateToProps = state => {
  return {
    value: state.alerts.activeFilter,
  };
};

const AlertSearch = props => {
  const {search, value} = props;

  return <Search id={"alert-search"} onSearch={search} initialValue={value} />;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AlertSearch);
