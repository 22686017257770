import cloneDeep from "lodash/cloneDeep";

export default ({state, action}) => {
  return {
    ...state,
    analysis: {
      ...state.analysis,
      [action.payload.downholeLocation]: cloneDeep(action.payload.analysis),
    },
  };
};
