import moment from "moment";
import setCookie from "utils/set-cookie";

const setToken = token => {
  setCookie(
    "id_token",
    token,
    moment()
      .add(1, "month")
      .toDate(),
  );
};

export default setToken;
