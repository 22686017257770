import {types} from "actions/organizations";

import cleanUpFilters from "./clean-up-filters";
import filterEventsByDateRange from "./filter-events-by-date-range";
import filterEventsByPerformedBy from "./filter-events-by-performed-by";
import filterEventsByPerformedOn from "./filter-events-by-performed-on";
import filterEventsByType from "./filter-events-by-type";
import setOrganizationEvents from "./set-organization-events";
import setOrganization from "./set-organization";

const defaultState = {};

const actionMap = {
  [types.filterEventsByDateRange]: filterEventsByDateRange,
  [types.filterEventsByPerformedBy]: filterEventsByPerformedBy,
  [types.filterEventsByPerformedOn]: filterEventsByPerformedOn,
  [types.filterEventsByType]: filterEventsByType,
  [types.removeUserFromOrganization]: cleanUpFilters,
  [types.setOrganizationEvents]: setOrganizationEvents,
  [types.setOrganization]: setOrganization,
};

export default (state = defaultState, action) => {
  const doAction = actionMap[action.type];

  return doAction ? doAction({state, action}) : state;
};
