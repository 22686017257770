import React, {useState} from "react";
import Collapse from "@material-ui/core/Collapse";
import {connect} from "react-redux";
import makeStyles from "@material-ui/styles/makeStyles";

import TooltipChip from "components/tooltip-chip";

import filterVfdDownhole from "utils/setpoint-automation/filter-vfd-downhole";
import {hasFullAccess} from "utils/organizations/has-full-access";
import {useGroupedWells} from "hooks/setpoint-automation/use-grouped-wells";

import WellTable from "./well-table";
import Header from "../header";

const useStyles = makeStyles(({colors}) => ({
  chipOverride: {
    backgroundColor: colors.red[700],
    color: colors.red[50],
  },
  divider: {
    height: 1,
    width: "100%",
    backgroundColor: colors.warmGrey[200],
  },
}));

const SpeedRange = ({wells, tabIndex, handleTabChange, limitedAccess}) => {
  const classes = useStyles();
  const {atBoundary, automatedWells, manualWells} = useGroupedWells(wells);
  const [manualOpen, setManualOpen] = useState(true);
  const [managedOpen, setManagedOpen] = useState(true);

  const toggleManualOpen = () => setManualOpen(!manualOpen);
  const toggleManagedOpen = () => setManagedOpen(!managedOpen);

  const showManualWells = !!manualWells.length;
  const showAutomatedWells = !!automatedWells.length;

  return (
    <>
      {showManualWells && (
        <>
          <Header
            text="Manual"
            onClick={toggleManualOpen}
            countComponent={
              <TooltipChip
                label={manualWells.length}
                tooltip={<div>Manual Wells Count</div>}
              />
            }
            topHeader
            handleTabChange={handleTabChange}
            tabIndex={tabIndex}
          />
          <Collapse in={manualOpen} classes={{hidden: classes.divider}}>
            <WellTable wells={manualWells} limitedAccess={limitedAccess} />
          </Collapse>
          {!manualOpen && <div className={classes.divider} />}
        </>
      )}
      {showAutomatedWells && (
        <>
          <Header
            text="Managed"
            onClick={toggleManagedOpen}
            countComponent={
              <TooltipChip
                label={automatedWells.length}
                tooltip={<div>Managed Wells Count</div>}
              />
            }
            secondaryCountComponent={
              !!atBoundary && (
                <TooltipChip
                  className={classes.chipOverride}
                  label={atBoundary}
                  tooltip={<div>Wells At Boundary Condition</div>}
                />
              )
            }
            topHeader={manualWells.length === 0}
            handleTabChange={handleTabChange}
            tabIndex={tabIndex}
          />
          <Collapse in={managedOpen} classes={{hidden: classes.divider}}>
            <WellTable wells={automatedWells} limitedAccess={limitedAccess} />
          </Collapse>
          {!managedOpen && <div className={classes.divider} />}
        </>
      )}
    </>
  );
};

const mapStateToProps = ({wells, auth, organizationView}) => {
  const limitedAccess = !hasFullAccess(
    auth.user.assignedOrganizations,
    organizationView.currentOrganization,
  );

  return {
    wells: filterVfdDownhole(wells.byWellId),
    limitedAccess,
  };
};

export default connect(mapStateToProps)(SpeedRange);
