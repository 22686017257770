import compose from "recompose/compose";
import {connect} from "react-redux";
import clamp from "lodash/clamp";
import {darken} from "@material-ui/core/styles/colorManipulator";
import Hint from "react-vis/es/plot/hint";
import orderBy from "lodash/fp/orderBy";
import map from "lodash/fp/map";
import {MarkSeries} from "react-vis/es";
import {push} from "react-router-redux";
import reduce from "lodash/fp/reduce";
import React, {Component} from "react";
import uniqBy from "lodash/fp/uniqBy";

import nice from "utils/nice";

import colorScale from "../../color-scale";
import HintBody from "./hint-body";
import mergeWellData from "../../merge-well-data";
import ScatterPlot from "./scatter-plot";
import NoWells from "../no-wells";

const mapXDomain = compose(
  map("fieldName"),
  uniqBy("fieldName"),
);

const toSeries = (wells, deviationType) => {
  const seriesReducer = (accumulator, well) => {
    const deviationData = well.deviations[deviationType];

    const seriesData = {
      wellId: well.wellId,
      downholeLocation: well.downholeLocation,
      name: well.displayName,
      percentChange: deviationData.percent,
      field: well.fieldName,
      x: well.fieldName,
      y: clamp(deviationData.percent, -25, 25),
      size: deviationData.absDeviation,
      fill: colorScale(deviationData.percent),
      stroke: darken(colorScale(deviationData.percent), 0.25),
      absDeviation: deviationData.absDeviation,
    };

    return [...accumulator, seriesData];
  };

  return reduce(seriesReducer, [], wells);
};

const prepareData = (deviationFilter, wells, allWells) => {
  const wellsWithField = mergeWellData(wells, allWells);

  // Define the sort.
  // We need to sort by descending order of absDeviation so that smaller magnitude changes that overlap larger ones
  // are layered with the larger one on the bottom. Otherwise the larger one can completely block the smaller one.
  const sortFields = orderBy(
    ["fieldName", `deviations.${deviationFilter.type}.absDeviation`],
    ["desc", "desc"],
  );

  // Sort the wells
  const wellsSortedByField = sortFields(wellsWithField);

  // Given the wells, create the data series for them
  const series = toSeries(wellsSortedByField, deviationFilter.type);

  const xDomain = mapXDomain(wellsWithField).sort();
  const yDomain = [-25, 25];

  return {
    deviationFilter,
    series,
    xDomain,
    yDomain: nice(yDomain, 5),
  };
};

const mapStateToProps = ({deviationReport, wells: storeWells}, {wells}) => {
  const {deviationFilter} = deviationReport;
  return prepareData(deviationFilter, wells, storeWells.byWellId);
};

class FieldDeviationPlot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedWell: undefined,
    };

    this.onMouseOut = () => this.setState({selectedWell: undefined});
    this.onMouseOver = selectedWell => this.setState({selectedWell});
    this.onClick = ({downholeLocation}) => {
      props.dispatch(
        push(`/well/${encodeURIComponent(downholeLocation)}/analysis`),
      );
    };
  }

  render() {
    const {deviationFilter, series, xDomain, yDomain} = this.props;

    const {selectedWell} = this.state;

    if (series.length === 0) return <NoWells />;

    return (
      <ScatterPlot
        margin={{left: 48, right: 32, top: 32, bottom: 64}}
        onMouseLeave={this.onMouseOut}
        sizeRange={[5, 20]}
        xType="ordinal"
        xDomain={xDomain}
        yTickFormat={n => `${n}%`}
        yDomain={yDomain.domain()}
      >
        <MarkSeries
          fillType="literal"
          strokeType="literal"
          data={series}
          opacity={0.75}
          style={{cursor: "pointer"}}
          onValueClick={this.onClick}
          onValueMouseOut={this.onMouseOut}
          onValueMouseOver={this.onMouseOver}
        />

        {selectedWell ? (
          <Hint value={selectedWell}>
            <HintBody {...selectedWell} unit={deviationFilter.unit} />
          </Hint>
        ) : null}
      </ScatterPlot>
    );
  }
}

const enhance = compose(connect(mapStateToProps));

export default enhance(FieldDeviationPlot);
