import compose from "lodash/fp/compose";
import countBy from "lodash/fp/countBy";
import groupBy from "lodash/fp/groupBy";

import {colors} from "utils/well/status-colors";
import {unfold} from "utils/fp";

import calculateSize from "./calculate-size";
import withTheme from "./with-theme";

const generator = function*(palette) {
  const n = palette.length;

  let i = 0;
  while (true) {
    yield palette[i++ % n];
  }
};

const theme = withTheme(colors);

const groupByStatus = compose(
  unfold(["title", "children"]),
  groupBy("status"),
);

const countByType = compose(
  unfold(["title", "size"]),
  countBy("type"),
);

const buildTree = (theme, data) => {
  let id = 0;

  return {
    title: "Filter by status",
    children: groupByStatus(data).map(status => {
      const palette = theme[status.title];

      return {
        title: status.title,
        color: palette.primary(),
        id: ++id,

        children: countByType(status.children).map(type => ({
          ...type,
          color: palette.secondary(),
          id: ++id,
        })),
      };
    }),
  };
};

export default data => calculateSize(buildTree(theme(generator), data));
