import types from "utils/privilege-types";
import uniq from "lodash/uniq";

const parse = ({isSysAdmin, organization}) => {
  const isFullWellAccess = organization.accessLevel === "Full";
  const isUserApprover = organization.isInvitedUserApprover;

  return {
    organizationId: organization.organizationId,
    privileges: uniq([
      ...(isSysAdmin ? [types.sysAdmin, types.well.configure] : []),
      ...(isUserApprover ? [types.user.approve] : []),
      ...(isFullWellAccess ? [types.well.control, types.well.configure] : []),
    ]).sort(),
  };
};

export default data => {
  return (data.assignedOrganizations || []).reduce((current, organization) => {
    return [
      ...current,
      parse({
        organization,
        isSysAdmin: data.isPumpwellEmployee,
      }),
    ];
  }, []);
};
