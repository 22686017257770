import Field from "components/react-redux-form/field";
import TextFieldListMenu from "components/text-field-list-menu";
import React from "react";

import getModelPath from "../get-model-path";
import TYPES from "../pumping-unit-types";

const Input = props => {
  return (
    <TextFieldListMenu
      {...props}
      getValue={option => option.value}
      label="Type*"
      options={TYPES}
      placeholder="Choose a type"
      required
    />
  );
};

export default () => {
  return (
    <Field component={Input} modelPath={getModelPath("pumpingUnitType")} />
  );
};
