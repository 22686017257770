import get from "lodash/get";

import {SESSION_ORGANIZATION} from "constants/local-storage-keys";

export const getSessionOrganization = userId => {
  const sessionOrganizationAllUsers = JSON.parse(
    sessionStorage.getItem(SESSION_ORGANIZATION) || "{}",
  );
  const sessionOrgThisUser = get(sessionOrganizationAllUsers, userId, null);

  return sessionOrgThisUser;
};
