import withStyles from "@material-ui/core/styles/withStyles";

const styles = ({colors}) => ({
  selectedHoverBG: {
    backgroundColor: colors.red[700],
  },
  selectedIdleBG: {
    backgroundColor: colors.red[800],
  },
  unselectedHoverBG: {
    backgroundColor: colors.red[400],
  },
  unselectedIdleBG: {
    backgroundColor: colors.red[600],
  },

  selectedHoverTitle: {
    color: "white",
  },
  selectedIdleTitle: {
    color: "white",
  },
  unselectedHoverTitle: {
    color: "white",
    textShadow: "0 0 5px hsla(0, 0%, 0%, .2)",
  },
  unselectedIdleTitle: {
    color: "white",
  },

  selectedHoverCaption: {
    color: colors.warmGrey[100],
  },
  selectedIdleCaption: {
    color: colors.warmGrey[100],
  },
  unselectedHoverCaption: {
    color: "white",
    textShadow: "0 0 5px hsla(0, 0%, 0%, .2)",
  },
  unselectedIdleCaption: {
    color: colors.warmGrey[100],
  },
});

export default withStyles(styles);
