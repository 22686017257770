import React from "react";
import Typography from "@material-ui/core/Typography";

import colors from "@ambyint/colors";

const styles = {
  container: {
    display: "flex",
    justifyContent: "flex-end",
    textAlign: "right",
  },
  errors: {
    color: colors.red[200],
  },
  rows: {
    marginRight: 8,
  },
};

export const Summary = props => {
  const {errorCount, rowCount} = props;
  let {containerStyle} = props;

  containerStyle = {
    ...containerStyle,
    ...styles.container,
  };

  return (
    <div style={containerStyle}>
      <Typography variant={"caption"} style={styles.rows}>
        Rows: {rowCount}
      </Typography>
      <Typography variant={"caption"} style={styles.errors}>
        Errors: {errorCount}
      </Typography>
    </div>
  );
};
