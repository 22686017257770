import {intersectionBy, isNil, get} from "lodash";

import {ALL, ANY, NONE} from "constants/tag-selection-types";

export const tagSearchEnhanced = (sourceTags, selectionType) => well => {
  if (isNil(sourceTags) || !sourceTags.length) {
    return true;
  }
  const targetTags = get(well, "tags", []);

  const commonTags = intersectionBy(sourceTags, targetTags, "name").length;

  return {
    [ANY]: Boolean(commonTags),
    [ALL]: commonTags === sourceTags.length,
    [NONE]: !commonTags,
  }[selectionType];
};
