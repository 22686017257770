import {push} from "react-router-redux";
import get from "lodash/get";

import {getAnomalyWells} from "utils/wells/get-anomaly-wells";
import {
  setAnomalyWells,
  setDisplayAllWells,
  setSearchResults,
  types,
} from "actions/wells";

import {search} from "./search";

const redirectToSearchResults = options => {
  const {currentRoute, fetched, results, withRouting} = options;

  return withRouting && fetched && results.length !== 1 && currentRoute !== "/"
    ? [push("/")]
    : [];
};

const redirectToWell = options => {
  const {
    currentRoute,
    downholeLocation,
    fetched,
    results,
    withRouting,
  } = options;
  const wellRoute = `/well/${encodeURIComponent(downholeLocation)}`;

  return withRouting &&
    fetched &&
    results.length === 1 &&
    !currentRoute.includes(wellRoute)
    ? [push(wellRoute)]
    : [];
};

export const searchWells = (action$, store) => {
  return action$.ofType(types.search).switchMap(({payload}) => {
    const criteria = get(payload, "criteria", "").trim();
    const withRouting = get(payload, "withRouting", true);

    const state = store.getState();

    const fetched = get(state, "wells.fetched", false);

    const results = search(
      state.wells.allWellIds,
      state.wells.byWellId,
      criteria,
    );
    const anomalyResults = getAnomalyWells(results);

    let downholeLocation = false;
    const well = store.getState().wells.byWellId[results[0]];
    if (well) downholeLocation = well.downholeLocation;

    const currentRoute = get(state, "routing.location.pathname");
    const redirectOptions = {
      currentRoute,
      downholeLocation,
      fetched,
      results,
      withRouting,
    };

    return [
      setSearchResults({criteria, results}),
      setAnomalyWells(anomalyResults),
      setDisplayAllWells({filtered: results}),
      ...redirectToSearchResults(redirectOptions),
      ...redirectToWell(redirectOptions),
    ];
  });
};
