import {replace} from "react-router-redux";
import fetch from "epics/async-fetch";

import {createSwitchEpic} from "epics/create-epic";
import {setAuth} from "actions/auth";
import {
  setOrganizationAssignedWellsForUser,
  types,
} from "actions/organizations";
import {show} from "actions/snackbar";

export default createSwitchEpic(
  [types.saveAssignedWells],
  async ({payload}, store) => {
    const {
      assignedWells,
      clientUserId,
      organizationId,
      unassignedWells,
    } = payload;

    try {
      const auth = store.getState().auth;
      const user = store.getState().auth.user;

      await fetch(
        `/organization/${encodeURIComponent(
          payload.organizationId,
        )}/user/${encodeURIComponent(payload.clientUserId)}/assignedWells`,
        {},
        {
          method: "PUT",
          body: {
            assignedWells,
            clientUserId,
            organizationId,
          },
        },
      );

      const actions = [
        show({
          message:
            "Your request to update well assignments submitted successfully!",
        }),
        setOrganizationAssignedWellsForUser({
          assignedWells,
          clientUserId,
          organizationId,
        }),
      ];

      if (user.clientUserId === clientUserId) {
        actions.push(
          setAuth({
            ...auth,
            user: {
              ...user,
              wells: [
                // Wells assigned from other organizations
                ...user.wells.filter(
                  well =>
                    !unassignedWells.includes(well) &&
                    !assignedWells.includes(well),
                ),
                // Wells now assigned on this organization
                ...assignedWells,
              ],
            },
          }),
        );
      }

      return actions;
    } catch (err) {
      if (err.status === 401 || err.status === 403) {
        return [
          replace("/"),
          show({
            message: "Unauthorized to save assigned wells",
          }),
        ];
      }
      return [replace(`/error?code=${err.status}`)];
    }
  },
);
