import makeStyles from "@material-ui/core/styles/makeStyles";

const useRangeSliderStyles = makeStyles(({colors, spacing}) => ({
  disabled: {
    cursor: "default",
    opacity: 0.26,
  },
  container: {
    position: "relative",
  },
  root: ({color}) => {
    return {color};
  },
  valueLabel: ({metBoundary}) => {
    return {
      "& *": {
        color: metBoundary ? colors.yellowVivid[600] : colors.warmGrey[500],
      },
    };
  },
  removeRightPadding: {
    paddingRight: 0,
  },
  contained: {
    backgroundColor: colors.warmGrey[500],
    color: colors.warmGrey[50],
    marginLeft: spacing(1),
    "&:hover": {
      backgroundColor: colors.warmGrey[400],
    },
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-start",
    width: 200,
    height: 36,
  },
  button: {
    color: colors.warmGrey[500],
    marginLeft: spacing(1),
    "&:hover": {
      backgroundColor: colors.warmGrey[100],
    },
  },
}));

export default useRangeSliderStyles;
