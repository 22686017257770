import {show} from "actions/snackbar";
import {types, set} from "actions/on-call";
import fetch from "epics/fetch";
import getCookie from "utils/get-cookie";
import generateOnCallSchedule from "./generate-on-call-schedule";
import getCurrentSchedule from "./get-current-schedule";

export default (action$, store) => {
  return action$.ofType(types.schedule).switchMap(({payload}) => {
    const token = getCookie("id_token");

    if (token) {
      const url = `/client-experience/user/${encodeURIComponent(
        payload.clientUserId,
      )}/calloutSchedulePeriod/create/bulk`;

      const onCallSchedule = generateOnCallSchedule(payload);

      return fetch(
        url,
        {},
        {
          body: onCallSchedule,
          method: "POST",
        },
      ).flatMap(res => {
        if (res.status === 200 || res.status === 204) {
          const currentSchedule = getCurrentSchedule(onCallSchedule);

          return [
            set({
              schedule: res.response,
            }),
            show({
              message: currentSchedule
                ? "You are now on call."
                : "Your on call schedule has been updated.",
            }),
          ];
        } else {
          return [
            set({schedule: null}),
            show({
              message:
                "There was an issue attempting to update to your on call schedule.",
            }),
          ];
        }
      });
    } else {
      return [
        set({schedule: null}),
        show({
          message:
            "You are not authenticated to update to your on call schedule.",
        }),
      ];
    }
  });
};
