import {connect} from "react-redux";
import React from "react";

import hasFlag from "utils/has-feature-flag";

const mapStateToProps = ({auth}) => {
  return {
    auth,
  };
};

/**
 * Conditionally render a component based upon a user's feature flags
 *
 * If you provide the prop `ignoreFeatureFlag` to the wrapped component,
 * this functionality will be selectively bypassed.
 */
const withFeatureFlag = flag => Component => {
  const FeatureFlaggedComponent = props => {
    const {ignoreFeatureFlag, ...rest} = props;

    return ignoreFeatureFlag || hasFlag(flag, props.auth.user) ? (
      <Component {...rest} />
    ) : null;
  };

  return connect(mapStateToProps)(FeatureFlaggedComponent);
};

export default withFeatureFlag;
