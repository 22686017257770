import React from "react";

import {eventAction, eventCategory} from "constants/analytics";
import {sendAnalyticsEvent} from "components/analytics";

import {SubheaderTabs, SubheaderTab} from "../index";

export const HomeTabs = () => (
  <SubheaderTabs>
    <SubheaderTab
      value="/"
      title="Status"
      onClick={() =>
        sendAnalyticsEvent(
          eventCategory.wellsDashboard,
          eventAction.subheaderTab,
          "Status",
        )
      }
    />
    <SubheaderTab
      value="/deviation"
      title="Deviation"
      onClick={() =>
        sendAnalyticsEvent(
          eventCategory.wellsDashboard,
          eventAction.subheaderTab,
          "Deviation",
        )
      }
    />
    <SubheaderTab
      value="/field-optimization"
      title="Optimization"
      onClick={() =>
        sendAnalyticsEvent(
          eventCategory.wellsDashboard,
          eventAction.subheaderTab,
          "Optimization",
        )
      }
    />
  </SubheaderTabs>
);
