import {types} from "actions/organizations";
import updateDeep from "utils/update-deep";

export default (
  state = {
    assignedWells: [],
    email: "",
    firstName: "",
    isInvitedUserApprover: false,
    isVerified: false,
    lastName: "",
    serviceName: "",
    webhooks: [],
  },
  action,
) => {
  if (action.type === types.setOrganizationServiceAccount) {
    return action.payload.data;
  }

  if (action.type === types.setOrganizationAssignedWellsForUser) {
    return updateDeep(
      state,
      "assignedWells",
      () => action.payload.assignedWells,
    );
  }

  if (action.type === types.setWebhookSaved) {
    if (state.webhooks.find(w => w.webhookId === action.payload.webhookId)) {
      return updateDeep(
        state,
        ["webhooks", w => w.webhookId === action.payload.webhookId],
        action.payload.webhook,
      );
    } else {
      return updateDeep(state, "webhooks", webhooks => [
        ...webhooks,
        action.payload.webhook,
      ]);
    }
  }

  if (action.type === types.setWebhookDeleted) {
    return {
      ...state,
      webhooks: state.webhooks.filter(
        webhook => webhook.webhookId !== action.payload.webhookId,
      ),
    };
  }

  return state;
};
