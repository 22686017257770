import {flatMap, toArray} from "rxjs/operators";

import {createSwitchEpic} from "epics/create-epic";
import fetch from "epics/fetch";
import {
  setAllRecommendations,
  setAllRecommendationsFetched,
  types,
} from "actions/wells";
import parseRecommendation from "@ambyint/common/parsers/well/parse-recommendation";

export default createSwitchEpic(
  [types.setWellsFetched],
  async (action, store) => {
    const {auth} = store.getState();
    const unitsOfMeasure = auth.user.unitsOfMeasure;

    try {
      const recommendations = await fetch("/recommendations?days=7")
        .pipe(
          flatMap(({response}) => response),
          toArray(),
        )
        .toPromise();

      const parsedRecommendations = recommendations.map(recommendation =>
        parseRecommendation(recommendation, unitsOfMeasure),
      );

      return [
        setAllRecommendations(parsedRecommendations),
        setAllRecommendationsFetched(true),
      ];
    } catch (err) {
      console.error(err);
      return [setAllRecommendationsFetched(true)];
    }
  },
  ``,
);
