import {types} from "./types";

export const replace = ({path}) => {
  return {
    type: types.replace,
    payload: {
      path,
    },
  };
};
