const defaultOrganizationState = {
  events: [],
  performedBy: null,
  performedOn: null,
  range: {},
  type: null,
};

export default ({state, action}) => {
  return {
    ...state,
    [action.payload.organizationId]: {
      ...defaultOrganizationState,
      ...state[action.payload.organizationId],
    },
  };
};
