import all from "utils/validation/all";
import fieldName from "utils/validation/field-name";
import latitude from "utils/validation/latitude";
import longitude from "utils/validation/longitude";
import required from "utils/validation/required";
import surfaceLocation from "utils/validation/surface-location";

export default values => {
  let errors = {};

  const requiredFields = [
    "type",
    "country",
    "provinceState",
    "latitude",
    "longitude",
  ];

  errors = {
    ...errors,
    ...all(required, values, requiredFields),
  };

  const countryFieldName = fieldName(values.country);

  if (countryFieldName(values.fieldName)) {
    errors.fieldName = countryFieldName(values.fieldName);
  }

  const countrySurfaceLocation = surfaceLocation(values.country);

  if (countrySurfaceLocation(values.surfaceLocation)) {
    errors.surfaceLocation = countrySurfaceLocation(values.surfaceLocation);
  }

  if (values.latitude && latitude(values.latitude)) {
    errors.latitude = latitude(values.latitude);
  }

  if (values.longitude && longitude(values.longitude)) {
    errors.longitude = longitude(values.longitude);
  }

  return errors;
};
