import {some} from "lodash";
import {getWells} from "utils/wells/get-wells";

export const disableUselessTags = ({state}) => ({
  ...state,
  tags: state.tags.map(tag => {
    const filteredByTags = getWells(state.filteredByTags);

    if (
      some(filteredByTags, ({tags}) =>
        some(tags, itemTag => itemTag.name === tag.name),
      )
    ) {
      return {
        ...tag,
        disabled: false,
      };
    }

    return {
      ...tag,
      disabled: true,
    };
  }),
});
