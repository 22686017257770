import {createSwitchEpic} from "epics/create-epic";
import {getWells} from "utils/wells/get-wells";
import {getWellIds} from "utils/wells/get-well-ids";
import {workflows} from "constants/well-workflows";
import {
  setDisplayAllWells,
  setCalibrateWells,
  setTriageWells,
  types,
} from "actions/wells";

const filterByStateAndType = (stateName, typeName) => well =>
  well.status.state.name === stateName &&
  (!typeName || (typeName && well.status.type.name === typeName));

const filterWellIdsOnPath = (idsToFilter, path) => {
  if (path) {
    const wells = getWells(idsToFilter);
    const [stateName, typeName] = path.titles;
    const filteredWells = wells.filter(
      filterByStateAndType(stateName, typeName),
    );
    return getWellIds(filteredWells);
  }
  return idsToFilter;
};

const getIdsAndAction = workflow => {
  const workflowSelected = workflow.selected;

  const isCalibrate = workflowSelected === workflows.CALIBRATE;
  if (isCalibrate) return [workflow.calibrate.all, setCalibrateWells];

  const isTriage = workflowSelected === workflows.TRIAGE;
  if (isTriage) return [workflow.triage.all, setTriageWells];

  return [workflow.displayAll.all, setDisplayAllWells];
};

export const setActivePath = createSwitchEpic(
  [types.setActivePath],
  async (action, store) => {
    const path = action.payload.path;
    const {
      wells: {workflow},
    } = store.getState();

    const [allWellIds, workflowUpdateAction] = getIdsAndAction(workflow);

    const wellIds = filterWellIdsOnPath(allWellIds, path);

    return [workflowUpdateAction({filtered: wellIds})];
  },
);
