import get from "lodash/get";
import moment from "moment";

import {PENDING} from "constants/response-statuses";

export const findCurrentRequestValue = main => {
  const requestedOn = get(main, "request.requestedOn");
  const stale = moment().isAfter(moment(requestedOn).add(4, "hours"));
  const status = get(main, "request.status");

  const pending = status === PENDING && !stale;

  const currentRequestValue = pending ? main.request.payload : main;

  return [currentRequestValue, pending];
};
