import colors from "theme/colors";

export const defaultContainerStyle = {
  minWidth: "150px",
};

export const defaultDescriptionStyle = {
  color: colors.grey[600],
  fontSize: "12px",
};

export const defaultGridContainer = {
  padding: "16px",
};

export const defaultLinkStyle = {
  color: colors.ambyOrange[500],
  cursor: "pointer",
  textDecoration: "underline",
};

export const defaultMainStyle = {
  fontWeight: 500,
  textTransform: "uppercase",
  color: colors.ambyBlue[500],
};

export const defaultTitleStyle = {
  color: colors.grey[600],
  textTransform: "uppercase",
  fontSize: "12px",
};

export const defaultSubtitleStyle = {
  color: colors.grey[600],
  textTransform: "uppercase",
  fontSize: "12px",
};

export const scrollableContainer = {
  maxHeight: "100%",
  overflow: "auto",
};
