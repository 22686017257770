import get from "lodash/get";
import isNil from "lodash/isNil";

import getAmbyintDisplayName from "./get-ambyint-display-name";
import getEmail from "./get-email";
import getName from "./get-name";
import isAmbyintUser from "./is-ambyint-user";

const hasUserFields = user => {
  const {email, firstName, lastName} = user;

  return !isNil(email) || !isNil(firstName) || !isNil(lastName);
};

const getDisplayName = user => getName(user) || getEmail(user);

export default event => {
  const user = get(event, "user", event) || {};

  if (isAmbyintUser(user)) return getAmbyintDisplayName(user);
  if (getDisplayName(user)) return getDisplayName(user);

  return hasUserFields(user) ? "user" : "Ambyint";
};
