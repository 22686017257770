import {types} from "actions/organizations";
import updateDeep from "utils/update-deep";

export default (
  state = {
    accessLevel: "Full",
    assignedWells: [],
    email: "",
    firstName: "",
    isInvitedUserApprover: false,
    isVerified: false,
    lastName: "",
  },
  action,
) => {
  if (action.type === types.setOrganizationUser) {
    return action.payload.data;
  }

  if (action.type === types.setOrganizationAssignedWellsForUser) {
    return updateDeep(
      state,
      "assignedWells",
      () => action.payload.assignedWells,
    );
  }

  if (action.type === types.setAccess) {
    return {
      ...state,
      accessLevel: action.payload.accessLevel,
      isInvitedUserApprover: action.payload.isInvitedUserApprover,
    };
  }

  return state;
};
