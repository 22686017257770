import React from "react";
import Autosuggest from "react-autosuggest";
import noop from "lodash/noop";

import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";

function renderSuggestionsContainer(options) {
  const {containerProps, children} = options;

  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  );
}

const styleSheet = theme => ({
  root: {
    position: "relative",
  },
  container: {
    position: "relative",
    width: "100%",
  },
  input: {
    // Don't overlap any dropdown arrow or clear button
    width: "calc(100% - 24px)",
  },
  suggestionsContainerOpen: {
    position: "absolute",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    left: 0,
    right: 0,
    zIndex: 1000,
  },
  outlinedSuggestionsContainerOpen: {
    position: "absolute",
    marginBottom: 16,
    left: 0,
    top: 64,
    right: 0,
    zIndex: 1000,
    minWidth: 250,
    width: "100%",
  },
  suggestion: {
    display: "block",
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
    overflow: "auto",
    maxHeight: "300px",
  },
  textField: {
    width: "100%",
  },
  outlinedInputRoot: {
    padding: 0,
    height: 56,
  },
  outlinedInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: 8,
    paddingRight: 24,
    height: 36,
    boxSizing: "border-box",
    transition: theme.transitions.create(["border"]),
    "&:focus": {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  outlinedInputFormLabel: {
    width: "100%",
    fontSize: 20,
  },
  outlinedInputFormLabelFocused: {
    transition: theme.transitions.create(["color"]),
  },
});

class SearchInputWithSuggestions extends React.Component {
  state = {
    value: "",
    suggestions: [],
  };

  handleClickListItem = event => {
    this.input.focus();

    if (!this.props.disabled) {
      this.props.onSearch({value: "", method: "type"});
    }
  };

  handleClickClose = onChange => event => {
    onChange({}, {method: "click"});
  };

  renderInput = inputProps => {
    const {classes, helperText, home, label, ref, value, ...other} = inputProps;
    const {outlined} = this.props;

    const outlinedInputLabelProps = {
      shrink: true,
      disableAnimation: true,
      className: classes.outlinedInputFormLabel,
      classes: {
        focused: classes.outlinedInputFormLabelFocused,
      },
    };

    const outlinedInputProps = {
      classes: {
        root: classes.outlinedInputRoot,
        input: classes.outlinedInput,
      },
      disableUnderline: true,
    };

    return (
      <TextField
        autoFocus={home}
        className={classes.textField}
        label={label}
        value={value}
        inputRef={myInputRef => {
          this.input = myInputRef;
          ref(myInputRef);
        }}
        InputProps={{
          id: "input",
          autoFocus: this.props.autoFocus,
          classes: {
            input: classes.input,
          },
          ...other,
          ...(outlined ? outlinedInputProps : {}),
        }}
        InputLabelProps={outlined && outlinedInputLabelProps}
        helperText={helperText}
      />
    );
  };

  renderSuggestion = disabledItems => (suggestion, {query, isHighlighted}) => {
    const suggestionValue = this.props.getSuggestionText(suggestion);
    const matches = match(suggestionValue, query);
    const parts = parse(suggestionValue, matches);

    const disabled =
      disabledItems && !(-1 === disabledItems.indexOf(suggestion));

    return (
      <MenuItem
        disabled={disabled}
        className="suggestion"
        selected={!disabled && isHighlighted}
      >
        <div>
          {parts.map((part, index) => {
            return part.highlight ? (
              <span key={index} style={{fontWeight: 300, color: "red"}}>
                {part.text}
              </span>
            ) : (
              <strong key={index} style={{fontWeight: 500}}>
                {part.text}
              </strong>
            );
          })}
        </div>
      </MenuItem>
    );
  };

  render() {
    const {outlined, classes, customClasses = {}} = this.props;

    const combinedClasses = {...classes, ...customClasses};

    const suggestionsContainerOpen = outlined
      ? combinedClasses.outlinedSuggestionsContainerOpen
      : combinedClasses.suggestionsContainerOpen;

    return (
      <div className={combinedClasses.root}>
        <div
          style={{display: "flex"}}
          className={combinedClasses.textFieldContainer}
        >
          <Autosuggest
            theme={{
              container: combinedClasses.container,
              suggestionsContainerOpen,
              suggestionsList: combinedClasses.suggestionsList,
              suggestion: combinedClasses.suggestion,
            }}
            onSuggestionSelected={this.onSuggestionSelected}
            renderInputComponent={this.renderInput}
            suggestions={this.props.suggestions}
            onSuggestionsFetchRequested={this.props.onSearch}
            onSuggestionsClearRequested={() =>
              this.props.onSearch({value: "", method: "type"})
            }
            renderSuggestionsContainer={renderSuggestionsContainer}
            getSuggestionValue={this.props.getSuggestionValue}
            renderSuggestion={this.renderSuggestion(this.props.disabledItems)}
            shouldRenderSuggestions={value => {
              // Render suggestions on click, if available
              return true;
            }}
            inputProps={{
              classes: combinedClasses,
              helperText: this.props.helperText || "",
              placeholder: this.props.placeholder || "",
              label: this.props.label,
              value: this.props.value,
              onKeyPress: this.props.onKeyPress || noop,
              onChange: this.props.onChange,
              onClick: event => {
                this.props.onClick(event, this.props.value);
              },
              disabled: this.props.disabled,
            }}
          />
          <div
            style={{
              marginTop: outlined ? 32 : 16,
              position: "absolute",
              right: 0,
            }}
          >
            {this.props.showDropdownArrow && !this.props.disabled && (
              <ArrowDropDownIcon
                style={{
                  cursor: "pointer",
                  right: 0,
                }}
                onClick={this.handleClickListItem}
              />
            )}
            {this.props.disabled && (
              <CloseIcon
                style={{
                  cursor: "pointer",
                  right: 0,
                  width: 16,
                  height: 16,
                  marginTop: 4,
                }}
                onClick={this.handleClickClose(this.props.onChange)}
              />
            )}
          </div>
        </div>

        {this.props.error && (
          <FormHelperText error>{this.props.error}</FormHelperText>
        )}
      </div>
    );
  }
}

export default withStyles(styleSheet)(SearchInputWithSuggestions);
