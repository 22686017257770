import fetch from "epics/async-fetch";
import getCookie from "@ambyint/common/utils/cookies/get-cookie";
import parseWell from "@ambyint/common/parsers/well";

import {getDefaults} from "utils/wells/analysis";
import getUser from "utils/get-user";

export const getExisting = async (store, downholeLocation) => {
  const {unitsOfMeasure} = getUser();
  const existing = store.getState().wells.analysis[downholeLocation];

  if (existing) {
    return existing;
  }

  const token = getCookie("id_token");
  const well = await fetch(
    `/wells/${encodeURIComponent(downholeLocation)}`,
    {},
    {token},
  );

  return getDefaults(parseWell(unitsOfMeasure)(well));
};
