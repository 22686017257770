import React from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {pure} from "recompose";

import colors from "theme/colors";
import filter from "lodash/filter";
import math from "mathjs";
import moment from "moment";
import reduce from "lodash/reduce";
import sortBy from "lodash/sortBy";

import PaperContainer from "components/paper-container";
import TimeValueChart from "components/d3-time-value-chart";

import {
  defaultContainerStyle,
  defaultGridContainer,
  defaultTitleStyle,
  defaultMainStyle,
} from "./dashboard-styles";

const parseSpmData = well => {
  const speedValues = filter(well.events, event => {
    if (event.type !== "rrpm" && event.type !== "spm") return false;
    if (moment(event.createdOn).isBefore(moment().subtract(24 * 7, "hours")))
      return false;
    return true;
  }).map(item => {
    return {
      x: item.createdOn,
      y: item.value,
    };
  });

  return sortBy(speedValues, value => moment(value.x).toDate());
};

const averageSpm = well => {
  if (!well.events) return null;

  const values = filter(well.events, event => {
    if (event.type !== "rrpm" && event.type !== "spm") return false;
    if (moment(event.createdOn).isBefore(moment().subtract(24, "hours")))
      return false;

    return true;
  });

  return values.length === 0
    ? 0
    : math.round(
        reduce(values, (sum, n) => sum + n.value, 0) / values.length,
        2,
      );
};

const SPMSummaryDisplay = props => {
  const {well} = props;

  const spmData = parseSpmData(well);

  if (spmData.length === 0) return null;

  return (
    <PaperContainer style={{...defaultContainerStyle}}>
      <Grid container style={defaultGridContainer}>
        <Grid item xs={12}>
          <Typography variant="body1" style={defaultTitleStyle}>
            Average{" "}
            {well.pumpingUnitType &&
            well.pumpingUnitType.toLowerCase() === "pcp"
              ? "RRPM"
              : "SPM"}
          </Typography>
          <Typography variant="h3" style={defaultMainStyle}>
            {averageSpm(well)}
          </Typography>
          <Typography variant="caption" style={defaultTitleStyle}>
            Previous 24 Hours
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TimeValueChart
            data={spmData}
            domain={[
              moment()
                .subtract(7, "days")
                .toDate(),
              moment().toDate(),
            ]}
            style={{stroke: colors.spm[500]}}
            margin={{top: 10, right: 5}}
          />
        </Grid>
      </Grid>
    </PaperContainer>
  );
};

export default pure(SPMSummaryDisplay);
