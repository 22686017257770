import {Field} from "redux-form";
import compose from "lodash/fp/compose";
import React from "react";
import throttle from "lodash/throttle";

import proxyAlwaysReturnValue from "utils/proxy-always-return-value";
import SearchInputWithSuggestions from "components/search-input-with-suggestions";

import formatLabel from "./format-label";

class SearchInputWithSuggestionsField extends React.Component {
  state = {searching: false};

  setSearching = throttle(
    () => this.setState({searching: true}),
    this.props.throttle,
    {
      leading: true,
      trailing: false,
    },
  );

  onSearchThrottle = compose(
    this.setSearching,
    throttle(
      compose(
        () => this.setState({searching: false}),
        this.props.onSearch,
      ),
      this.props.throttle,
      {leading: false, trailing: true},
    ),
  );

  render() {
    const showNoSuggestions =
      (this.props.suggestions.length === 1 &&
        this.props.getSuggestionText(this.props.suggestions[0]) ===
          this.props.input.value) ||
      this.state.searching;

    return (
      <SearchInputWithSuggestions
        label={formatLabel(this.props)}
        placeholder={this.props.placeholder}
        value={this.props.input.value}
        helperText={this.props.helperText}
        suggestions={showNoSuggestions ? [] : this.props.suggestions}
        getSuggestionText={this.props.getSuggestionText}
        getSuggestionValue={this.props.getSuggestionValue}
        onChange={(event, data) => {
          if (data.method === "click") {
            this.props.input.onChange(
              (data.newValue && data.newValue.text) || "",
            );

            this.props.onSuggestionClick(
              (data && data.newValue) || proxyAlwaysReturnValue(null),
            );
          } else {
            this.props.input.onChange(
              event.target.value === undefined
                ? data.newValue.text
                : event.target.value,
            );

            if (event.target.value === "") {
              // Allow user to clear the selected suggestion by removing all text
              // proxyAlwaysReturnValue is used to return null for any accessors,
              // since redux-form ignores this.props.change(field, value) if value is undefined.
              // We want null instead of undefined.
              this.props.onSuggestionClick(proxyAlwaysReturnValue(null));
            }

            this.props.onSuggestionKeypress &&
              this.props.onSuggestionKeypress(data.newValue);
          }
        }}
        onSearch={event => {
          if (this.props.throttle) {
            this.onSearchThrottle(event);
          } else {
            this.props.onSearch(event);
          }
        }}
        onClick={(event, value) => {
          if (this.props.onClick) {
            this.props.onClick(event, value);
          }
        }}
        error={this.props.meta.error}
        showDropdownArrow={this.props.showDropdownArrow}
        disabled={this.props.disabled}
        autoFocus={this.props.autoFocus}
      />
    );
  }
}

export default props => {
  return <Field {...props} component={SearchInputWithSuggestionsField} />;
};
