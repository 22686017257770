import isNil from "lodash/isNil";
import integer from "utils/validation/integer";
import max from "utils/validation/max";
import min from "utils/validation/min";
import number from "utils/validation/number";
import required from "utils/validation/required";

export const validateLoad = load => {
  return !isNil(load)
    ? {
        ...(required(load) ? {load: required(load)} : {}),
        ...(number(load) ? {load: number(load)} : {}),
        ...(min(load, 0) ? {load: min(load, 0)} : {}),
        ...(max(load, 100) ? {load: max(load, 100)} : {}),
        ...(integer(load) ? {load: integer(load)} : {}),
      }
    : {};
};

export const validatePosition = position => {
  return !isNil(position)
    ? {
        ...(required(position) ? {position: required(position)} : {}),
        ...(number(position) ? {position: number(position)} : {}),
        ...(min(position, 0) ? {position: min(position, 0)} : {}),
        ...(max(position, 100) ? {position: max(position, 100)} : {}),
        ...(integer(position) ? {position: integer(position)} : {}),
      }
    : {};
};

export const validatePrimaryFillage = fillage => {
  return !isNil(fillage)
    ? {
        ...(required(fillage) ? {primaryFillage: required(fillage)} : {}),
        ...(number(fillage) ? {primaryFillage: number(fillage)} : {}),
        ...(min(fillage, 0) ? {primaryFillage: min(fillage, 0)} : {}),
        ...(max(fillage, 100) ? {primaryFillage: max(fillage, 100)} : {}),
        ...(integer(fillage) ? {primaryFillage: integer(fillage)} : {}),
      }
    : {};
};

export const validateSecondaryFillage = fillage => {
  return !isNil(fillage)
    ? {
        ...(required(fillage) ? {secondaryFillage: required(fillage)} : {}),
        ...(number(fillage) ? {secondaryFillage: number(fillage)} : {}),
        ...(min(fillage, 0) ? {secondaryFillage: min(fillage, 0)} : {}),
        ...(max(fillage, 100) ? {secondaryFillage: max(fillage, 100)} : {}),
        ...(integer(fillage) ? {secondaryFillage: integer(fillage)} : {}),
      }
    : {};
};

export const validateStrokes = strokes => {
  return !isNil(strokes)
    ? {
        ...(required(strokes) ? {strokes: required(strokes)} : {}),
        ...(number(strokes) ? {strokes: number(strokes)} : {}),
        ...(min(strokes, 0) ? {strokes: min(strokes, 0)} : {}),
        ...(integer(strokes) ? {strokes: integer(strokes)} : {}),
      }
    : {};
};
