import ClipboardPulse from "mdi-material-ui/ClipboardPulse";
import {connect} from "react-redux";
import values from "lodash/values";

import filterRecommendations from "utils/recommendations/filter-recommendations";

import IconWithCount from "components/icon-with-count";

const PATH =
  "M19,3H14.82C14.4,1.84 13.3,1 12,1C10.7,1 9.6,1.84 9.18,3H5C3.9,3 3,3.9 3,5V19C3,20.1 3.9,21 5,21H19C20.1,21 21,20.1 21,19V5C21,3.9 20.1,3 19,3M12,3C12.55,3 13,3.45 13,4C13,4.55 12.55,5 12,5C11.45,5 11,4.55 11,4C11,3.45 11.45,3 12,3M5,13.46H7.17L10.5,7.08L11.44,14.05L13.93,10.86L16.53,13.46H19V15H15.89L14.07,13.21L10.38,17.92L9.62,12.15L8.11,15H5V13.46Z";

const mapStateToProps = ({wells}) => {
  const allRecommendations = values(wells.byWellId).flatMap(
    ({recommendations}) => recommendations,
  );
  const recommendations = filterRecommendations(allRecommendations, {
    status: "open",
  });

  return {
    count: recommendations.length,
    IconComponent: ClipboardPulse,
    path: PATH,
    title: "Recommendations",
  };
};

export default connect(mapStateToProps)(IconWithCount);
