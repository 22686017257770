import userAssignedWells from "./user-assigned-wells";
import userInvited from "./user-invited";
import userPermissions from "./user-permissions";
import userRemoved from "./user-removed";
import wellAdded from "./well-added";
import tagsAdded from "./tags-added";
import tagsRemoved from "./tags-removed";

export default {
  userAssignedWells,
  userInvited,
  userPermissions,
  userRemoved,
  wellAdded,
  tagsAdded,
  tagsRemoved,
};
