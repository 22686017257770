import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";

import {HIGH_LOAD_STUCK_PLUNGER, HIGH_LOAD_FRICTION} from "constants/anomalies";
import {anomalyDisplayName} from "parsers/anomaly-display-name";

import HighLoadFriction from "./high-load-friction";
import HighLoadStuckPlunger from "./high-load-stuck-plunger";

const useStyles = makeStyles(({colors, spacing, breakpoints, typography}) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "auto auto auto",
  },
  highLoadIcon: {
    color: colors.warmGrey[600],
    width: 90,
    height: 90,
    [breakpoints.down("md")]: {
      width: 60,
      height: 60,
    },
  },
  subContainer: {
    display: "grid",
    gridTemplateColumns: "auto",
    gridTemplateRows: "auto auto",
    gridRowGap: 12,
  },
  label: {
    color: colors.warmGrey[500],
    userSelect: "none",
  },
  divider: {
    height: "55%",
    width: 2,
    backgroundColor: colors.warmGrey[100],
    justifySelf: "center",
    marginRight: 15,
    marginTop: 30,
    borderRadius: 5,
    [breakpoints.down("md")]: {
      justifySelf: "auto",
      marginRight: 0,
      marginTop: 20,
      marginLeft: 12,
    },
  },
}));

const HighLoad = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.subContainer}>
        <Typography variant="caption" className={classes.label}>
          {anomalyDisplayName(HIGH_LOAD_STUCK_PLUNGER)}
        </Typography>
        <HighLoadFriction className={classes.highLoadIcon} />
      </div>
      <div className={classes.divider} />
      <div className={classes.subContainer}>
        <Typography variant="caption" className={classes.label}>
          {anomalyDisplayName(HIGH_LOAD_FRICTION)}
        </Typography>
        <HighLoadStuckPlunger className={classes.highLoadIcon} />
      </div>
    </div>
  );
};

export default HighLoad;
