const setRankings = ({state, action}) => {
  const {rankings} = action.payload;

  return {
    ...state,
    quality: {
      fetched: true,
      rankings,
    },
  };
};

export default setRankings;
