import toFinite from "lodash/toFinite";

import {torque} from "unit-converter";
import getUnits from "utils/get-units";

const convertTorque = (value, from) => {
  return torque.convert({from, to: "Nm", value: value}, "Nm");
};

const prepareSetpointFactory = (viewingCurrent, units) => (value, key) => {
  if (value === "") return {};

  value = toFinite(value);

  return {
    [key]: viewingCurrent ? value : convertTorque(value, units.torque),
  };
};

export default ({data, viewingCurrent}) => {
  const prepareSetpoint = prepareSetpointFactory(viewingCurrent, getUnits());

  return {
    ...prepareSetpoint(data.min.value, "min"),
    ...prepareSetpoint(data.max.value, "max"),
  };
};
