import React from "react";

import {LABEL_MAP} from "constants/grace-periods";
import getDisplayName from "utils/user/get-display-name";
import FeedItem from "../feed-item";

export default props => {
  const {
    event,
    event: {
      value: {type},
    },
  } = props;

  return (
    <FeedItem event={event}>
      <strong>{getDisplayName(event)}</strong>
      {" disabled the "}
      <strong>{LABEL_MAP[type]}</strong>
      {" grace period."}
    </FeedItem>
  );
};
