import chunk from "lodash/fp/chunk";
import compose from "recompose/compose";
import React from "react";
import ReactList from "react-list";
import withStyles from "@material-ui/core/styles/withStyles";

import WellCard from "./well-card";
import withWellCardsState from "./with-well-cards-state";

const styles = ({spacing}) => ({
  list: {
    listStyle: "none",
    padding: 0,
    margin: 0,
    display: "grid",
    gridGap: "15px",
    gridTemplateColumns: "repeat(auto-fill, minmax(190px, 1fr))",
    gridAutoRows: "min-content",
  },
});

/**
 * As the user scrolls, ReactList will render more rows.
 * This value is for how many cards to render per each 'row' of cards.
 * The lower this number is, the more likely that a user who is scrolling will see the bottom of the list before cards pop in.
 * The higher this number is, the greater the performance impact each time ReactList renders items.
 */
const MAX_WELLS_PER_ROW = 5;

const WellCardsList = ({
  classes,
  wells,
  workflow,
  isSelectionMode,
  selectedWells,
  fetchMiniCardById,
  fetchLatestMiniCard,
  handleCardClick,
  handleHeaderClick,
  hasFullAccess,
  fetchCardOverride,
  setCardOverride,
}) => {
  const chunkedWells = chunk(MAX_WELLS_PER_ROW, wells);

  const renderList = (items, ref) => (
    <ul className={classes.list} ref={ref}>
      {items}
    </ul>
  );

  const renderChunk = index => {
    const row = chunkedWells[index] || [];
    return row.map(well => {
      return (
        <li key={well.downholeLocation}>
          <WellCard
            workflow={workflow}
            well={well}
            isSelectionMode={isSelectionMode}
            selectedWells={selectedWells}
            fetchMiniCardById={fetchMiniCardById}
            fetchLatestMiniCard={fetchLatestMiniCard}
            fetchCardOverride={fetchCardOverride}
            setCardOverride={setCardOverride}
            handleCardClick={handleCardClick}
            handleHeaderClick={handleHeaderClick}
            hasFullAccess={hasFullAccess}
          />
        </li>
      );
    });
  };

  return (
    <div className={classes.container}>
      <ReactList
        itemRenderer={renderChunk}
        itemsRenderer={renderList}
        length={chunkedWells.length}
        pageSize={3}
        type="simple"
      />
    </div>
  );
};

export default compose(
  withWellCardsState,
  withStyles(styles),
)(WellCardsList);
