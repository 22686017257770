import React from "react";
import compose from "lodash/fp/compose";
import {connect} from "react-redux";
import isNil from "lodash/isNil";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

import getUnits from "utils/get-units";
import round from "utils/round";

const styles = ({typography, spacing, colors}) => ({
  main: {
    display: "grid",
    gridTemplateColumns: "repeat(3, minmax(0,1fr))",
    padding: `${spacing(4)}px ${spacing(3)}px`,
  },

  label: {
    marginBottom: spacing(1) / 2,
    color: colors.warmGrey[500],
  },

  noData: {
    ...typography.h5,
    fontSize: "1.1rem",
    alignSelf: "flex-end",
  },

  value: {
    ...typography.h5,
    lineHeight: "initial",
  },

  valueUnit: {
    ...typography.body2,
    color: colors.warmGrey[500],
    margin: "0 2px",
  },

  degreeUnit: {
    ...typography.h5,
    color: colors.warmGrey[500],
    verticalAlign: "top",
  },

  kpiMiddle: {
    margin: "0 auto",
  },

  kpiRight: {
    marginLeft: "auto",
  },

  kpiValue: {
    display: "flex",
    alignItems: "flex-end",
    minHeight: spacing(4),
  },
});

const mapStateToProps = ({wellHealth}) => ({
  ...wellHealth.production,
});

const enhance = compose(
  connect(mapStateToProps),
  withStyles(styles),
);

const KPIPanelStrokes = ({
  surveySeries,
  plungerSeries,
  liquidProduction,
  strokes,
  focus,
  classes,
}) => {
  const index = !isNil(focus) ? focus : liquidProduction.length - 1;
  const productionForWeek = liquidProduction[index].y;
  const strokesForWeek = strokes[index].y;

  return (
    <div className={classes.main}>
      <div>
        <Typography variant="body2" className={classes.label}>
          Efficiency
        </Typography>
        <div className={classes.kpiValue}>
          {isNil(strokesForWeek) || isNil(productionForWeek) ? (
            <span className={classes.noData}>No Data</span>
          ) : (
            <>
              <span className={classes.value}>
                {strokesForWeek === 0 || productionForWeek === 0
                  ? 0
                  : round(strokesForWeek / productionForWeek, 1)}
              </span>
              <span className={classes.valueUnit}>
                s/{getUnits().production}
              </span>
            </>
          )}
        </div>
      </div>

      <div className={classes.kpiMiddle}>
        <Typography variant="body2" className={classes.label}>
          Strokes
        </Typography>
        <div className={classes.kpiValue}>
          {isNil(strokesForWeek) ? (
            <span className={classes.noData}>No Data</span>
          ) : (
            <span className={classes.value}>{round(strokesForWeek, 0)}</span>
          )}
        </div>
      </div>

      <div className={classes.kpiRight}>
        <Typography variant="body2" className={classes.label}>
          Production
        </Typography>
        <div className={classes.kpiValue}>
          {isNil(productionForWeek) ? (
            <span className={classes.noData}>No Data</span>
          ) : (
            <>
              <span className={classes.value}>
                {round(productionForWeek, 1)}
              </span>
              <span className={classes.valueUnit}>{getUnits().production}</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default enhance(KPIPanelStrokes);
