import React from "react";
import ReactList from "react-list";

import DisplaySelectedAlert from "./display-selected-alert";
import Header from "./header";
import RowItem from "./row";

const renderList = alerts =>
  alerts.filtered.length > 0 ? (
    <div>
      <ReactList
        itemRenderer={renderItem(alerts)}
        length={alerts.filtered.length}
        type="simple"
      />
    </div>
  ) : null;

const renderItem = ({filtered, selected}) => index => {
  const alert = filtered[index];

  return selected === alert.alertId ? (
    <DisplaySelectedAlert alert={alert} key={alert.alertId} />
  ) : (
    <RowItem
      alert={alert}
      key={alert.alertId}
      square={index < filtered.length - 1}
      {...alert}
    />
  );
};

export default ({alerts}) => {
  return (
    <div style={{height: "100%"}}>
      <Header />
      {renderList(alerts)}
    </div>
  );
};
