import {forkJoin} from "rxjs/observable/forkJoin";
import {of} from "rxjs/observable/of";

import fetchApi from "epics/fetch";
import getCookie from "utils/get-cookie";
import parseUser from "parsers/user/parse-user";
import {setAuth, types} from "actions/auth";
import {show} from "actions/snackbar";

const performSave = (store, url, data) => {
  return fetchApi(
    url,
    {},
    {
      body: data,
      method: "PUT",
    },
  );
};

export default (action$, store) => {
  return action$.ofType(types.savePreferences).switchMap(({payload}) => {
    return forkJoin(
      performSave(
        store,
        `/client-experience/user/${encodeURIComponent(
          payload.clientUserId,
        )}/epm-preference`,
        payload.data,
      ),
      performSave(
        store,
        `/client-experience/user/${encodeURIComponent(
          payload.clientUserId,
        )}/units-of-measure`,
        payload.data,
      ),
    ).flatMap(() => {
      const token = getCookie("id_token");
      return of(
        setAuth({
          token,
          isAuthenticated: true,
          user: parseUser(payload.user),
        }),
        show({
          message: "Your preferences were saved successfully",
        }),
      );
    });
  });
};
