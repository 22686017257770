import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import {withStyles} from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import Popover from "@material-ui/core/Popover";
import React from "react";
import sortBy from "lodash/sortBy";
import Typography from "@material-ui/core/Typography";

import escapeRegex from "@ambyint/common/utils/regex/escape-regex";

const styles = theme => ({
  filter: {
    padding: theme.spacing(1, 1, 0, 1),
  },
  items: {
    maxHeight: "250px",
    overflow: "auto",
  },
  item: {
    fontSize: 14,
    whiteSpace: "normal",
  },
  noItems: {
    padding: theme.spacing(2),
  },
  paper: {
    minWidth: "250px",
  },
});

const Component = props => {
  const {
    anchorEl,
    classes,
    criteria,
    first,
    items,
    noEmpty,
    mapLabel,
    mapValue,
    maxDisplayCount,
    maxWidth,
    onCriteriaChange,
    onSelect,
    onClose,
    open,
    value,
  } = props;

  const filtered = sortBy(
    items.filter(item =>
      mapLabel(item).match(new RegExp(escapeRegex(criteria), "i")),
    ),
    mapLabel,
  ).slice(0, maxDisplayCount);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      classes={{paper: classes.paper}}
      open={open}
      onClose={onClose}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <div className={classes.filter}>
        <Input
          autoFocus
          fullWidth
          onChange={onCriteriaChange}
          placeholder={"Filter"}
          value={criteria}
        />
      </div>
      {filtered.length > 0 && (
        <MenuList
          className={classes.items}
          style={{maxWidth: maxWidth || "100%"}}
        >
          {!noEmpty && (
            <MenuItem
              className={classes.item}
              key={null}
              onClick={() => {
                onSelect(null);
                onClose();
              }}
              selected={!value}
            >
              {""}
            </MenuItem>
          )}
          {first && (
            <MenuItem
              className={classes.item}
              key={mapValue(first)}
              onClick={() => {
                onSelect(first);
                onClose();
              }}
              selected={!!value && mapValue(value) === mapValue(first)}
            >
              {mapLabel(first)}
            </MenuItem>
          )}
          {filtered.map(item => (
            <MenuItem
              className={classes.item}
              key={mapValue(item)}
              onClick={() => {
                onSelect(item);
                onClose();
              }}
              selected={!!value && mapValue(value) === mapValue(item)}
            >
              {mapLabel(item)}
            </MenuItem>
          ))}
        </MenuList>
      )}
      {!filtered.length && (
        <div className={classes.noItems}>
          <Typography type={"caption"}>No items to select</Typography>
        </div>
      )}
    </Popover>
  );
};

export default withStyles(styles)(Component);
