import React from "react";
import get from "lodash/get";
import Grid from "@material-ui/core/Grid";
import pick from "lodash/pick";

import FormButton from "components/form-button";
import FormHeader from "components/form-header";
import PaperContainer from "components/paper-container";

import getPrivileges from "utils/get-privileges";
import privilegeTypes from "utils/privilege-types";
import RESPONSE_STATUSES from "constants/response-statuses";
import StatusIndicator from "components/status-indicator-chip";

import {cardLabelFactory, cardValueFactory} from "../cards";
import MotorInformationEditView from "./motor-information-edit-view";

class MotorInformationReadView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {well} = this.props;

    const privileges = getPrivileges(well.organization.id);

    const motorDetails = well.motorDetails || {};

    if (this.state.showMotorInformationEditView) {
      return (
        <MotorInformationEditView
          onClose={() => this.setState({showMotorInformationEditView: false})}
          rpmOnly={this.props.rpmOnly}
          well={well}
        />
      );
    }

    const CardLabel = cardLabelFactory(4);
    const CardValue = cardValueFactory(8);
    const pending =
      get(motorDetails, "rpmRequest.status") === RESPONSE_STATUSES.PENDING;

    return (
      <PaperContainer extraPadded style={{maxWidth: "500px"}}>
        <Grid container>
          <Grid item xs={8} style={{marginTop: "0px", marginBottom: "0px"}}>
            <FormHeader>Motor Information</FormHeader>
          </Grid>
          <Grid item xs={4} style={{alignSelf: "center"}}>
            {pending && (
              <StatusIndicator
                current={pick(motorDetails, ["rpm"])}
                request={motorDetails.rpmRequest}
              />
            )}
          </Grid>

          {!this.props.rpmOnly && (
            <>
              <CardLabel label={"Power Supply Phases"} />
              <CardValue
                value={motorDetails && motorDetails.powerSupplyPhases}
              />

              <CardLabel label={"Efficiency"} />
              <CardValue value={motorDetails && motorDetails.efficiency} />

              <CardLabel label={"Horsepower"} />
              <CardValue value={motorDetails && motorDetails.horsepower} />

              <CardLabel label={"Voltage"} />
              <CardValue value={motorDetails && motorDetails.voltage} />

              <CardLabel label={"Current"} />
              <CardValue value={motorDetails && motorDetails.current} />
            </>
          )}
          <CardLabel label={"RPM"} />
          <CardValue value={motorDetails && motorDetails.rpm} />

          {!this.props.rpmOnly && (
            <>
              <CardLabel label={"Service Factor"} />
              <CardValue value={motorDetails && motorDetails.serviceFactor} />
            </>
          )}

          <Grid item xs={12} style={{marginTop: "0px", marginBottom: "0px"}}>
            {privileges.includes(privilegeTypes.well.configure) && (
              <FormButton
                onClick={() =>
                  this.setState({showMotorInformationEditView: true})
                }
              >
                Edit Motor Information
              </FormButton>
            )}
          </Grid>
        </Grid>
      </PaperContainer>
    );
  }
}

export default MotorInformationReadView;
