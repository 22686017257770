/**
 * Sunburst: A higher order component used to map the wells into a tree structure used
 * by the child react-vis/Sunburst component.
 *
 * Looking for something to read?
 * http://uber.github.io/react-vis/documentation/other-charts/sunburst-diagram
 * https://github.com/uber/react-vis/blob/master/showcase/sunbursts/basic-sunburst.js
 */

import {SizeMe} from "react-sizeme";
import {Sunburst} from "react-vis";
import compose from "lodash/fp/compose";
import get from "lodash/get";
import map from "lodash/map";
import noop from "lodash/noop";
import React, {Fragment} from "react";
import sortBy from "lodash/sortBy";
import withState from "recompose/withState";
import withStyles from "@material-ui/core/styles/withStyles";

import {mapTree, when} from "utils/fp";
import {sendAnalyticsEvent} from "components/analytics";
import {eventCategory, eventAction} from "constants/analytics";

import SunburstLabel from "./sunburst-label";
import SunburstLegend from "./sunburst-legend";
import withBuilder from "./with-builder";

const styles = () => ({
  root: {
    cursor: "pointer",
    position: "relative",
  },
});

const updateStyle = path => node => {
  return {
    ...node,
    style: {
      ...node.style,
      fillOpacity: path && !path.includes(node.id) ? 0.2 : 1,
    },
  };
};

const mapWells = wells => {
  return sortBy(
    map(wells, well => ({
      classification: "Unknown", // TBD
      codes: map(well.status.statuses, "description"),
      field: well.fieldName,
      id: well.downholeLocation,
      name: well.displayName,
      status: get(well, "status.state.name", "Unknown"),
      type: get(well, "status.type.name", "Unknown"),
    })),
    "name",
  );
};

const mapPath = (fn, node) =>
  node.parent ? [...mapPath(fn, node.parent), fn(node.data || node)] : [];

const getPath = node => {
  return {
    ids: mapPath(node => node.id, node),
    titles: mapPath(node => node.title, node),
    value: node.value,
  };
};

const enhance = compose(
  withState("activePath", "setActivePath", null),
  withStyles(styles),
);

const withGuard = onSelectPath => e => {
  if (e.target.nodeName === "path") return;
  onSelectPath(null);
};

export default enhance(props => {
  const {
    activePath,
    classes,
    onSelectPath = noop,
    setActivePath,
    wells,
    workflow,
    selectedPath,
  } = props;

  const path = selectedPath || activePath;

  // 1. Use a workflow tree builder to build the tree.
  // 2. When a path exists, update the tree's style.

  const buildTree = compose(
    when(() => path, mapTree(updateStyle(path && path.ids))),
    withBuilder(workflow),
  );

  const mappedWells = mapWells(wells);

  const tree = buildTree(mappedWells);

  return (
    <Fragment>
      <SizeMe>
        {({size}) => (
          <div className={classes.root} onClick={withGuard(onSelectPath)}>
            <Sunburst
              hideRootNode
              height={size.width || 0}
              width={size.width || 0}
              onValueMouseOver={node => setActivePath(getPath(node))}
              onValueMouseOut={() => setActivePath(null)}
              onValueClick={node => {
                sendAnalyticsEvent(
                  eventCategory.allWellsWorkflow,
                  eventAction.sunburst,
                  getPath(node).titles.join(" / "),
                );
                onSelectPath(getPath(node));
              }}
              colorType="literal"
              data={tree}
              style={{stroke: "#fff"}}
            />
            <SunburstLabel path={activePath || selectedPath} tree={tree} />
          </div>
        )}
      </SizeMe>
      <SunburstLegend legend={tree.children} />
    </Fragment>
  );
});
