import React from "react";

import styles from "routes/login/styles";

const Footer = () => {
  const contactUrl = "mailto:support@ambyint.com?Subject=Login Assistance";
  return (
    <footer style={styles.footer}>
      Having problems?{" "}
      <a style={styles.link} href={contactUrl}>
        Get in touch with us
      </a>
    </footer>
  );
};

export default Footer;
