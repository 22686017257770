import {getStatus} from "@ambyint/common/well-status";

const alertTypeMap = {
  lufkinStatusCode: event => {
    const {statusCodes} = event.value.information;
    const statusCode = getStatus(statusCodes[0]);

    return `Status Code ${statusCode.code}${
      statusCode.description ? ` - ${statusCode.description}` : ""
    }`;
  },
  lufkin2StatusCode: event => {
    const {statusCodes} = event.value.information;
    const statusCode = getStatus(statusCodes[0]);

    return `Status Code ${statusCode.code}${
      statusCode.description ? ` - ${statusCode.description}` : ""
    }`;
  },
  smartenStatusCode: event => {
    const {statusCodes} = event.value.information;
    const statusCode = getStatus(statusCodes[0]);

    return `Status Code ${statusCode.code}${
      statusCode.description ? ` - ${statusCode.description}` : ""
    }`;
  },
  ambySenseStopped: () => "Ambyint Device Stopped",
  maxTorqueViolation: () => "Max Torque Violation",
  minTorqueViolation: () => "Min Torque Violation",
  maxCurrentViolation: () => "Max Current Violation",
  minCurrentViolation: () => "Min Current Violation",
  ambyControlStopped: () => "Ambyint Device Stopped",
  deviceCommLoss: () => "Device Communication Loss",
  stuffingBoxLeakPressure: () => "Stuffing Box Leak Pressure",
  faultRecoveryFailed: () => "Fault Recovery Failed",
  powerLost: () => "Power Loss",
  wellPilotStatusCode: () => "Well Pilot Status Code",
};

const unknownAlert = () => "Unknown";

export default event => {
  const alertTypeFactory = alertTypeMap[event.value.type];

  if (!alertTypeFactory)
    console.error(
      `Attempted to render an unknown alert type: ${event.value.type}.`,
    );

  return `${(alertTypeFactory || unknownAlert)(event)} alert`;
};
