import React from "react";
import {connect} from "react-redux";
import {reduxForm} from "redux-form";

import FormLabel from "@material-ui/core/FormLabel";

import FormButton from "components/form-button";
import FormHeader from "components/form-header";
import PaperContainer from "components/paper-container";
import RadioGroup from "components/forms/radio-group";

import {savePreferences} from "actions/auth";
import spreadIf from "utils/spread-if";

const mapStateToProps = state => {
  const {epmPreference, unitsOfMeasure} = state.auth.user;

  return {
    ...spreadIf(state.auth.user, {
      initialValues: {
        epmPreference,
        unitsOfMeasure,
      },
    }),
    user: state.auth.user,
  };
};

class ProfilePreferences extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  save = values => {
    const {dispatch, user} = this.props;

    dispatch(
      savePreferences({
        clientUserId: user.clientUserId,
        data: Object.assign({}, values, {
          clientUserId: user.clientUserId,
        }),
        user: Object.assign({}, user, values),
      }),
    );
  };

  render() {
    const {handleSubmit, pristine, submitting} = this.props;

    return (
      <PaperContainer extraPadded style={{maxWidth: 600}}>
        <form onSubmit={handleSubmit(this.save)}>
          <FormHeader style={{marginBottom: 8}}>Preferences</FormHeader>

          <div>
            <FormLabel>Units of Measure</FormLabel>
            <RadioGroup
              name="unitsOfMeasure"
              label="Units Of Measure"
              options={[
                {
                  label: "Metric",
                  value: "Metric",
                },
                {
                  label: "Imperial",
                  value: "Imperial",
                },
              ]}
            />
          </div>

          <div>
            <FormLabel>ambyControl:EPM Measurement</FormLabel>
            <RadioGroup
              name="epmPreference"
              label="EPM Preference"
              options={[
                {
                  label: "Torque",
                  value: "Torque",
                },
                {
                  label: "Current",
                  value: "Current",
                },
              ]}
            />
          </div>

          <div style={{marginTop: 4}}>
            <FormButton
              disabled={pristine || submitting}
              type="submit"
              style={{justifyContent: "center"}}
            >
              Save
            </FormButton>
          </div>
        </form>
      </PaperContainer>
    );
  }
}

const Component = reduxForm({
  form: "profile-preferences",
  enableReinitialize: true,
})(ProfilePreferences);

export default connect(mapStateToProps)(Component);
