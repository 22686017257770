import React from "react";
import sortBy from "lodash/fp/sortBy";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  inlineList: {
    ...theme.typography.caption,
    textAlign: "center",
    listStyle: "none",
    paddingLeft: "0",
  },

  inlineItem: {
    display: "inline-block",
    marginRight: ".5rem",
  },

  legend: {
    display: "inline-block",
    width: ".5rem",
    height: ".5rem",
    marginRight: ".25rem",
  },
});

const sortByTitle = sortBy("title");

const SunburstLegend = ({classes, legend}) => (
  <ul className={classes.inlineList}>
    {sortByTitle(legend).map((item, i) => (
      <li key={i} className={classes.inlineItem}>
        <div className={classes.legend} style={{backgroundColor: item.color}} />
        {item.title}
      </li>
    ))}
  </ul>
);

export default withStyles(styles)(SunburstLegend);
