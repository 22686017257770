import React from "react";
import pure from "recompose/pure";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import formatDateTime from "@ambyint/common/utils/format/format-date-time";
import pluralize from "@ambyint/common/utils/format/format-pluralize";

import PaperContainer from "components/paper-container";
import {RowChart} from "components/d3-bar-chart";

import {
  defaultContainerStyle,
  defaultGridContainer,
  defaultTitleStyle,
} from "./dashboard-styles";

const AlertStatisticsDisplay = props => {
  const {well} = props;

  if (!well.alertStatistics) {
    return null;
  }

  const groupedCodes = well.alertStatistics.byType.map(stat => ({
    label: stat.type,
    value: stat.count,
  }));
  const alertsCount = groupedCodes.reduce(
    (total, group) => total + group.value,
    0,
  );

  return (
    <PaperContainer style={defaultContainerStyle}>
      <Grid
        container
        style={defaultGridContainer}
        justify={"space-between"}
        spacing={0}
      >
        <Grid item xs>
          <Typography variant="body1" style={{...defaultTitleStyle}}>
            {`${alertsCount} alert${pluralize(
              well.alertStatistics.byType.reduce(
                (count, stat) => (count += stat.count),
                0,
              ),
            )} since ${formatDateTime(well.alertStatistics.startDate, {
              timezone: true,
            })}`}
          </Typography>
          {groupedCodes.length > 0 && <RowChart data={groupedCodes} />}
        </Grid>
      </Grid>
    </PaperContainer>
  );
};

export default pure(AlertStatisticsDisplay);
