import get from "lodash/get";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import isNil from "lodash/isNil";
import has from "lodash/has";
import map from "lodash/map";
import React from "react";
import startCase from "lodash/startCase";

const styles = {
  table: {
    lineHeight: "22px",
    width: "100%",
  },
  heading: {
    textAlign: "right",
  },
  column: {
    padding: "0 8px",
  },
  headingPill: {
    background: "white",
    color: "#555",
    borderRadius: "12px",
    padding: "2px 8px",
  },
  dataCell: {
    textAlign: "right",
    paddingRight: "14px",
  },
};

const SettingRow = ({currentSettings, newSettings, mappings}) => (
  value,
  key,
) => {
  const hasMappings = !isNil(mappings);

  // Skip over unmapped properties if a mapping is provided
  if (hasMappings && !has(mappings, key)) return;

  const getValue = setting => {
    if (isNil(setting)) return;

    return hasMappings
      ? get(setting[key], mappings[key], setting[key])
      : setting[key];
  };

  const current = getValue(currentSettings);
  const incoming = getValue(newSettings);

  return (
    <tr key={key}>
      <td style={styles.column}>
        <strong>
          {startCase(key)}
          {has(incoming, "units") ? `(${incoming.units})` : ""}
        </strong>
      </td>
      <td style={{...styles.column, ...styles.dataCell}}>
        {isObject(current) ? current.value : current}
      </td>
      <td style={{...styles.column, ...styles.dataCell}}>
        {isObject(incoming) ? incoming.value : incoming}
      </td>
    </tr>
  );
};

const buildRows = (mappings, source, currentSettings, newSettings) =>
  map(
    source,
    SettingRow({mappings, currentSettings, newSettings: newSettings || source}),
  );

const buildRowsFromArray = (mappings, source, currentSettings) =>
  map(source, (settings, i) =>
    buildRows(mappings, settings, currentSettings[i], source[i]),
  );

export const SettingsDataTable = ({
  standalone,
  currentSettings,
  newSettings,
  mappings,
}) => {
  // Standalone settings have a plain value (i.e not a settings object)
  const isStandalone = !isNil(standalone);

  const currentSettingsProcessed = isStandalone
    ? {[standalone]: currentSettings}
    : currentSettings;

  const newSettingsProcessed = isStandalone
    ? {[standalone]: newSettings}
    : newSettings;

  // Supports arrays of settings (necessary for Rod String)
  const rowBuilder = isArray(newSettings) ? buildRowsFromArray : buildRows;

  return (
    <table style={styles.table}>
      <thead style={styles.heading}>
        <tr>
          <th style={styles.column} />
          <th style={styles.column}>
            <span style={styles.headingPill}>Current</span>
          </th>
          <th style={styles.column}>
            <span style={styles.headingPill}>Updates</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {rowBuilder(mappings, newSettingsProcessed, currentSettingsProcessed)}
      </tbody>
    </table>
  );
};

export default SettingsDataTable;
