import {types} from "./types";

export const saveNominalRpm = ({nominalRpm, downholeLocation}) => {
  return {
    type: types.saveNominalRpm,
    payload: {
      nominalRpm,
      downholeLocation,
    },
  };
};
