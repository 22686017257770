import FormHelperText from "@material-ui/core/FormHelperText";
import React from "react";
import {Errors} from "react-redux-form";

const Wrapper = props => {
  const {children} = props;

  return (
    <FormHelperText error>{React.Children.toArray(children)[0]}</FormHelperText>
  );
};

export default props => {
  const {errorMessages, modelPath} = props;

  return (
    <Errors
      messages={errorMessages}
      model={modelPath}
      show={field => field.submitFailed || field.touched}
      wrapper={Wrapper}
    />
  );
};
