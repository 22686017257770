import {actions} from "react-redux-form";
import {types} from "actions/well-tickets";
import fetch from "epics/async-fetch";

import startCase from "lodash/startCase";
import toLower from "lodash/toLower";

const countryHasTickets = country =>
  ["canada", "unitedstates"].includes(country);

const formatFieldName = fieldName => startCase(toLower(fieldName));

const mapPrefix = (coordinate = "") => {
  const last = String(coordinate).substring(coordinate.length - 1);

  return last === "W" || last === "S" ? "-" : "";
};

const mapCoordinate = (coordinate = "") => {
  const mapped = String(coordinate).replace(/[^.\d-]/, "");

  return `${mapPrefix(coordinate)}${mapped}`;
};

const mapTicket = ticket => {
  return {
    ...{fieldName: formatFieldName(ticket.producingFieldAreaName || "")},
    ...{surfaceLocation: ticket.surfaceLocation || ""},
    ...{surfaceLocationLatitude: mapCoordinate(ticket.surfaceLatitude)},
    ...{surfaceLocationLongitude: mapCoordinate(ticket.surfaceLongitude)},
  };
};

const mapUrl = well => {
  const {downholeLocation, wellCountry, wellProvinceState} = well;

  if (wellCountry === "canada")
    return `/optimization/wellTicket/${encodeURIComponent(downholeLocation)}`;
  if (wellCountry === "unitedstates")
    return `/optimization/wellTicket/${encodeURIComponent(
      downholeLocation,
    )}/${wellProvinceState}`;
};

const fetchTicket = async well => {
  const {wellCountry} = well;

  let data = {};

  if (!countryHasTickets(wellCountry)) return data;

  try {
    data = await fetch(mapUrl(well));
  } catch (error) {
    if (error.status !== 404) console.error(error);
  }

  return data;
};

export const prefillForm = action$ => {
  return action$.ofType(types.prefillForm).switchMap(async ({payload}) => {
    const ticket = await fetchTicket(payload.well);

    return actions.merge(payload.form, mapTicket(ticket));
  });
};
