import React from "react";

import FeedItem from "../feed-item";

const CARD_ERROR_CODES = {
  1: "with no data as the card has already been sent",
};

export default props => {
  const {event} = props;

  const error = CARD_ERROR_CODES[event.value.errorCode];

  return (
    <FeedItem event={event}>
      {`Card request fulfilled${error ? ` ${error}` : ""}.`}
    </FeedItem>
  );
};
