import {techTeam} from "constants/tech-team";

export const getUserGroup = user => {
  const {isPumpwellEmployee, email} = user;

  // Check if user is internal
  if (isPumpwellEmployee) {
    if (techTeam.includes(email)) {
      return "internal-tech";
    }

    return "internal";
  }

  // Otherwise they are external
  return "external";
};
