import {connect} from "react-redux";
import filter from "lodash/filter";
import TuneIcon from "mdi-material-ui/Tune";

import IconWithCount from "components/icon-with-count";
import {isOutOfBounds} from "utils/setpoint-automation/is-out-of-bounds";

const PATH =
  "M3,17V19H9V17H3M3,5V7H13V5H3M13,21V19H21V17H13V15H11V21H13M7,9V11H3V13H7V15H9V9H7M21,13V11H11V13H21M15,9H17V7H21V5H17V3H15V9Z";

const mapStateToProps = ({wells}) => {
  const wellsOutOfBounds = filter(wells.byWellId, isOutOfBounds);

  return {
    count: wellsOutOfBounds.length,
    IconComponent: TuneIcon,
    path: PATH,
    title: "Setpoint Automation",
  };
};

export default connect(mapStateToProps)(IconWithCount);
