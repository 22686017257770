import React from "react";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import Grid from "@material-ui/core/Grid";
import isFinite from "lodash/isFinite";
import isNil from "lodash/isNil";
import Lens from "@material-ui/icons/Lens";
import RadioButtonChecked from "@material-ui/icons/RadioButtonChecked";

import abbreviate from "utils/abbreviate-number";
import colors from "theme/colors";
import theme from "theme";

const styles = {
  container: {
    ...theme.typography.body1,
    backgroundColor: colors.white,
    border: `1px solid ${colors.grey[300]}`,
    color: colors.grey[600],
    cursor: "pointer",
    fontSize: "12px",
    padding: "8px",
    width: "150px",
  },
  icon: {
    height: "16px",
    width: "16px",
    verticalAlign: "text-bottom",
  },
  label: {
    display: "inline-block",
    marginLeft: "5px",
  },
};

const getValue = value => (!isNil(value) ? `${abbreviate(value, 2)}` : "");

const parseConfig = props => {
  if (props.effectiveSpm.state > 0)
    return {
      ...props.effectiveSpm,
      color: colors.blueGrey[500],
      label: "Effective SPM",
    };

  return {
    ...props.spm,
    color: colors.primarySpm,
    label: "Running SPM",
  };
};

export default props => {
  const {spm = {}, effectiveSpm = {}, onChange} = props;

  const {
    color,
    label,
    show,
    state,
    value: {value, min, max} = {},
  } = parseConfig(props);

  const getIcon = () => {
    switch (state) {
      case 0:
        return <NotInterestedIcon style={{...styles.icon, color}} />;
      case 2:
        return <Lens style={{...styles.icon, color}} />;
      default:
        return <RadioButtonChecked style={{...styles.icon, color}} />;
    }
  };

  const toggleSpm = () => {
    if (spm.state === 1 && effectiveSpm.state === 0)
      return onChange(["spm", "effectiveSpm"]);
    if (spm.state === 0 && effectiveSpm.state === 1)
      return onChange(["effectiveSpm"]);
    if (spm.state === 0 && effectiveSpm.state === 0) return onChange(["spm"]);
  };

  return (
    <Grid
      item
      className="transparent"
      style={{
        display: show ? "inline-block" : "none",
        padding: "0 5px 10px",
        height: "fit-content",
      }}
      onClick={() => toggleSpm()}
    >
      <div style={styles.container}>
        <div>
          {getIcon()}
          <span style={styles.label}>{label}</span>
          {isFinite(value) && (
            <span style={styles.label}>{getValue(value)}</span>
          )}
        </div>
        <div style={{paddingLeft: "16px"}}>
          <span style={styles.label}>
            {!isNil(min) && !isNil(max)
              ? `${abbreviate(min, 2)} - ${abbreviate(max, 2)}`
              : ""}
          </span>
        </div>
      </div>
    </Grid>
  );
};
