import uniq from "lodash/uniq";
import sortBy from "lodash/sortBy";

const findDevice = (serialNumber, array = []) => {
  return array.find(v => v.serialNumber === serialNumber) || {};
};

export default (exitingDevices, newDevices) => {
  if (!exitingDevices) exitingDevices = [];
  if (!newDevices) newDevices = [];

  const allSerials = uniq([
    ...exitingDevices.map(d => d.serialNumber),
    ...newDevices.map(d => d.serialNumber),
  ]);

  const devices = [];
  for (let i = 0; i < allSerials.length; i++) {
    devices.push({
      ...findDevice(allSerials[i], exitingDevices),
      ...findDevice(allSerials[i], newDevices),
    });
  }

  return sortBy(devices, "serialNumber");
};
