import {types} from "./types";

export const setAccess = options => {
  const {
    accessLevel,
    clientUserId,
    isInvitedUserApprover,
    organizationId,
  } = options;

  return {
    type: types.setAccess,
    payload: {
      accessLevel,
      clientUserId,
      isInvitedUserApprover,
      organizationId,
    },
  };
};
