export default ({state, action}) => {
  if (!action.payload.data)
    return {
      ...state,
      assigned: [],
    };

  const assigned = action.payload.data.assignedWells;

  return {
    ...state,
    assigned,
  };
};
