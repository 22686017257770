import {connect} from "react-redux";
import {
  compose,
  renderNothing,
  withState,
  withHandlers,
  branch,
} from "recompose";
import {isNil} from "lodash";
import React, {useMemo} from "react";
import Link from "react-router-dom/Link";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import withStyles from "@material-ui/core/styles/withStyles";
import withRouter from "react-router-dom/withRouter";
import get from "lodash/get";

import colors from "@ambyint/common/colors";

import {eventAction, eventCategory} from "constants/analytics";
import {ClickEvent} from "components/analytics";
import {SEARCH_TYPES} from "constants/search-types";
import HEADER_HEIGHT from "constants/header-height";
import WellSearch from "components/app-bar/search/connected-search/well-search";
import AlertSearch from "components/app-bar/search/connected-search/alert-search";
import SearchButton from "components/search-button";

import RecommendationsButton from "./recommendations-button";

import {
  AmbyintLogo,
  UserMenu,
  SideBar,
  LegacyNavigation,
  WellPagingNavigation,
} from "./index";

const styles = ({palette, colors: themeColors, typography}) => ({
  root: {
    height: HEADER_HEIGHT,
    display: "flex",
    backgroundColor: colors.darkGrey,
  },
  colorDefault: {
    color: palette.getContrastText(colors.darkGrey),
  },
  button: {
    marginLeft: 12,
    marginRight: 12,
  },
  logo: {
    marginLeft: 10,
    marginTop: 8,
    width: "135px !important", // Class naming/ordering from withStyles make this necessary
  },
  controlGroup: {
    flex: 0,
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: 24,
  },
  avatar: {
    width: 32,
    height: 32,
    color: colors.darkGrey,
    backgroundColor: "#fff",
    fontSize: ".85rem",
    fontWeight: "600",
    marginLeft: 24,
  },
  toolbarRoot: {
    backgroundColor: colors.darkGrey,
    zIndex: 1100,
    paddingRight: "0px !important",
    color: "#fff",
  },
  organizationName: {
    ...typography.subtitle1,
    color: themeColors.warmGrey[50],
    alignSelf: "flex-end",
    fontStyle: "italic",
    paddingLeft: 10,
    fontWeight: 300,
    paddingBottom: 1,
  },
});

const componentMap = {
  [SEARCH_TYPES.none]: renderNothing(),
  [SEARCH_TYPES.well]: WellSearch,
  [SEARCH_TYPES.alerts]: AlertSearch,
};

const mapStateToProps = ({
  appBar: {searchComponent},
  auth: {user},
  organizationView: {currentOrganization},
}) => ({
  SearchComponent: componentMap[searchComponent],
  user,
  organizationId: currentOrganization,
});

const enhance = compose(
  withState("open", "setOpen", false),
  withHandlers({
    handleOpen: ({setOpen}) => () => setOpen(true),
    handleClose: ({setOpen}) => () => setOpen(false),
  }),
  withStyles(styles),
  withRouter,
  connect(mapStateToProps),
  branch(({user}) => isNil(user), renderNothing),
);

export default enhance(
  ({
    classes,
    SearchComponent,
    handleOpen,
    handleClose,
    open,
    location,
    match,
    user,
    organizationId,
  }) => {
    const organizationName = useMemo(
      () => {
        const orgs = get(user, "assignedOrganizations", []) || [];
        const org = orgs.find(o => o.organizationId === organizationId);

        return get(org, "organizationName", "");
      },
      [organizationId, user],
    );

    return (
      <AppBar
        color="default"
        classes={{root: classes.root, colorDefault: classes.colorDefault}}
        position="fixed"
      >
        <Toolbar classes={{root: classes.toolbarRoot}} disableGutters>
          <SideBar
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
          />
          <div style={{display: "flex"}}>
            <Link to={"/"}>
              <AmbyintLogo className={classes.logo} />
            </Link>
            <span className={classes.organizationName}>{organizationName}</span>
          </div>
          {/* Remove this block when flag `search-dialog-workflow` is removed */}
          <SearchComponent />
          <div className={classes.controlGroup}>
            <WellPagingNavigation location={location} match={match} />
            <SearchButton />
            <ClickEvent
              category={eventCategory.appBar}
              action={eventAction.click}
              label="Recommendations View"
            >
              <RecommendationsButton />
            </ClickEvent>
            <LegacyNavigation />
            <UserMenu />
          </div>
        </Toolbar>
        <div style={{width: "100%"}} id="app-bar-sub-header-portal" />
      </AppBar>
    );
  },
);
