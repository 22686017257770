import React from "react";
import {connect} from "react-redux";
import {FlexibleXYPlot, YAxis} from "react-vis";

import {
  CCR_COLORS,
  CCR_HIGHLIGHT_COLORS,
  CLASSIFICATIONS,
} from "constants/well-health";
import round from "utils/round";
import {setFocus} from "actions/wells/well-health";

import DateHoverAxis from "../date-hover-axis";
import {RoundedVerticalBarSeries} from "../rounded-bar-series";
import SetPointAxis from "../set-point-axis";

const mapStateToProps = ({wellHealth}) => ({
  ...wellHealth.classifications,
});

const mapDispatchToProps = {
  setFocus,
};

const CCRGraph = ({counts, series, focus, setFocus}) => {
  const coloredSeries = CLASSIFICATIONS.reduce((accumulator, type) => {
    accumulator[type] = series[type].map((p, i) => ({
      ...p,
      color: i === focus,
    }));
    return accumulator;
  }, {});

  return (
    <FlexibleXYPlot
      height={270}
      margin={{left: 40, right: 10, top: 24, bottom: 40}}
      yDomain={[0, 1]}
      xType="ordinal"
      stackBy="y"
      onMouseLeave={() => {
        setFocus("classifications", null);
      }}
    >
      <DateHoverAxis focus={focus} />

      <SetPointAxis />

      <YAxis
        tickValues={[0, 0.25, 0.5, 0.75, 1]}
        tickFormat={v => `${round(v * 100, 0)}%`}
        tickSizeInner={0}
      />

      {CLASSIFICATIONS.map(type => (
        <RoundedVerticalBarSeries
          key={type}
          barWidth={0.9}
          data={coloredSeries[type]}
          style={{
            stroke: 0,
          }}
          colorRange={[CCR_COLORS[type], CCR_HIGHLIGHT_COLORS[type]]}
          onNearestX={(value, event) => {
            setFocus("classifications", event.index);
          }}
        />
      ))}
    </FlexibleXYPlot>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CCRGraph);
