import required from "utils/validation/required";
import validateFieldsOf from "utils/validation/validate-fields-of";

const isUnique = (serviceName, organizationServiceAccounts) =>
  (organizationServiceAccounts || []).find(
    account =>
      (account.serviceName || "").toLowerCase().replace(/ /g, "") ===
      (serviceName || "").toLowerCase().replace(/ /g, ""),
  )
    ? "Service name must be unique"
    : "";

const LIMIT = 32;
const maxCharLimit = serviceName => {
  if (serviceName.length > LIMIT) {
    return `${serviceName.length}/${LIMIT}`;
  }

  return "";
};

export default (values, props) => {
  const validateField = validateFieldsOf(values);

  return {
    ...validateField("serviceName", x => required(x)),
    ...validateField("serviceName", x =>
      isUnique(x, props.organizationServiceAccounts),
    ),
    ...validateField("serviceName", maxCharLimit),
  };
};
