import {LOCATION_CHANGE} from "react-router-redux";

import {createSwitchEpic} from "epics/create-epic";

import handler from "./handler";

export default createSwitchEpic([LOCATION_CHANGE], async ({payload}, store) => {
  const {pathname, search} = payload;
  const state = store.getState();

  return handler({
    pathname,
    search,
    state,
  });
});
