import {fetch} from "actions/field-names";
import {set as selectOrganization} from "actions/organization-view";
import Aux from "components/aux";
import Breadcrumbs from "components/breadcrumbs";
import VerticalStepper from "components/vertical-stepper";
import React from "react";
import {connect} from "react-redux";
import {actions} from "react-redux-form";

import steps from "./steps";
import formPath from "./steps/form-path";

const AddWell = class extends React.Component {
  componentWillMount() {
    const {
      dispatch,
      match: {
        params: {organizationId},
      },
    } = this.props;

    dispatch(fetch());
    dispatch(selectOrganization({currentOrganization: organizationId}));
    dispatch(actions.reset(formPath));
  }

  componentWillUnmount() {
    const {dispatch} = this.props;

    dispatch(actions.reset("forms"));
  }

  render() {
    const {organizationName} = this.props;

    return (
      <Aux>
        <div style={{marginBottom: 16}}>
          <Breadcrumbs
            crumbs={[
              {
                label: organizationName,
                pathname: "/profile/organizations",
              },
              {
                label: "Add Well",
              },
            ]}
          />
        </div>

        <div style={{maxWidth: 600}}>
          <VerticalStepper steps={steps} />
        </div>
      </Aux>
    );
  }
};

export default connect()(AddWell);
