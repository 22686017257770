import React from "react";
import isNil from "lodash/isNil";

import getDisplayName from "utils/user/get-display-name";

import FeedItem from "../feed-item";

export default props => {
  const {
    event,
    event: {
      value: {casing, tubing},
    },
  } = props;

  return (
    <FeedItem event={event}>
      <strong>{getDisplayName(event)}</strong>
      {" changed the "}
      <strong>casing AI</strong>
      {" setting to "}
      <strong>
        {!isNil(casing) && casing > 0 ? `AI${casing}` : "Not Used"}
      </strong>
      {" and "}
      <strong>tubing AI</strong>
      {" setting to "}
      <strong>
        {!isNil(tubing) && tubing > 0 ? `AI${tubing}` : "Not Used"}
      </strong>
      {"."}
    </FeedItem>
  );
};
