import {FlexibleXYPlot} from "react-vis/dist/make-vis-flexible";
import {withSize} from "react-sizeme";
import Borders from "react-vis/dist/plot/borders";
import Crosshair from "react-vis/dist/plot/crosshair";
import Highlight from "react-vis/dist/plot/highlight";
import LineSeries from "react-vis/dist/plot/series/line-series";
import moment from "moment";
import React, {useState} from "react";
import XAxis from "react-vis/dist/plot/axis/x-axis";
import withStyles from "@material-ui/core/styles/withStyles";
import YAxis from "react-vis/dist/plot/axis/y-axis";

import colors from "theme/colors";

import CrosshairContent from "../../components/crosshair-content";
import OverviewChart from "../overview-chart";

const styles = theme => ({
  overview: {
    position: "absolute",
    right: 10,
    top: 0,
  },

  wrapper: {
    position: "relative",
  },
});

const Chart = ({
  activeSeries,
  classes,
  gas,
  lastDrawLocation,
  oil,
  resetDateRange,
  size,
  updateDragState,
  unitsOfMeasure,
  water,
}) => {
  const [crossHairValues, setCrossHairValues] = useState([]);

  const onNearestX = (value, {index}) => {
    setCrossHairValues([oil[index], gas[index], water[index]]);
  };

  const onMouseLeave = () => {
    setCrossHairValues([]);
  };

  return (
    <div className={classes.wrapper}>
      <FlexibleXYPlot
        animation
        xType="time"
        xDomain={
          lastDrawLocation && [lastDrawLocation.left, lastDrawLocation.right]
        }
        yDomain={
          lastDrawLocation && [lastDrawLocation.bottom, lastDrawLocation.top]
        }
        onMouseLeave={onMouseLeave}
        margin={{left: 40, right: 10, top: 0, bottom: 55}}
        height={size.width * 0.6}
        width={size.width}
      >
        {activeSeries.gas && (
          <LineSeries
            data={gas}
            colorType="literal"
            color={colors.red[300]}
            getNull={d => d.y !== null}
          />
        )}

        {activeSeries.water && (
          <LineSeries
            data={water}
            colorType="literal"
            color={colors.blue[300]}
            getNull={d => d.y !== null}
          />
        )}

        {activeSeries.oil && (
          <LineSeries
            data={oil}
            colorType="literal"
            color={colors.green[300]}
            onNearestX={onNearestX}
            getNull={d => d.y !== null}
          />
        )}

        <Borders style={{all: {fill: "#fff"}}} />

        <Highlight color="#829AE3" onBrushEnd={updateDragState} />

        <Crosshair
          values={crossHairValues}
          style={{zIndex: 999, line: {background: "#000"}}}
        >
          {crossHairValues.length && (
            <CrosshairContent
              activeSeries={activeSeries}
              values={crossHairValues}
              unitsOfMeasure={unitsOfMeasure}
            />
          )}
        </Crosshair>

        <XAxis
          tickFormat={val => moment(val).format("MMM D / YY")}
          tickTotal={5}
        />
        <YAxis tickTotal={8} />
      </FlexibleXYPlot>
      <div className={classes.overview} onClick={resetDateRange}>
        <OverviewChart
          activeSeries={activeSeries}
          gas={gas}
          oil={oil}
          water={water}
          lastDrawLocation={lastDrawLocation}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(withSize()(Chart));
