import startCase from "lodash/startCase";
import {
  HIGH_LOAD_STUCK_PLUNGER,
  HIGH_LOAD_FRICTION,
  DTV,
} from "constants/anomalies";

const aliasMap = {
  lowload: "Low Load",
  [DTV]: "Delayed Traveling Valve",
  [HIGH_LOAD_STUCK_PLUNGER]: "Stuck Plunger",
  [HIGH_LOAD_FRICTION]: "Friction",
};

export const anomalyDisplayName = type => {
  return aliasMap[type] || startCase(type);
};
