import React from "react";

import ListStyleContainer from "components/list-style-container";
import TextListMenu from "components/text-list-menu";

/**
 * Button that resembles Material-UI list items, but easier for us to customize and acts as a button by default.
 */
class ListStyleButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {open: false};
  }

  render() {
    const {
      containerStyle,
      label,
      onChange,
      options,
      primary,
      secondary,
    } = this.props;

    return (
      <ListStyleContainer
        primary={primary}
        secondary={secondary}
        onClick={() => {
          this.setState({open: !this.state.open});
        }}
        containerStyle={{userSelect: "none", ...(containerStyle || {})}}
      >
        <TextListMenu
          label={label}
          options={options}
          open={this.state.open}
          onClick={() => {
            this.setState({open: !this.state.open});
          }}
          value={this.props.value}
          onChange={onChange}
        />
      </ListStyleContainer>
    );
  }
}

export default ListStyleButton;
