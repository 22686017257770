import get from "lodash/get";

import {isSpeedRangeEnabled} from "./is-speed-range-enabled";

export const isOutOfBounds = well => {
  const maxSOP = get(well, "safeOperatingParameters.maxSpeed.value", Infinity);
  const minSOP = get(well, "safeOperatingParameters.minSpeed.value", -Infinity);
  const speedRangeEnabled = isSpeedRangeEnabled(well);

  const maxSPM = get(well, "operation.speedRange.max.value", -Infinity);

  return speedRangeEnabled && (maxSPM >= maxSOP || maxSPM <= minSOP);
};
