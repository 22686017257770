import max from "utils/validation/max";
import min from "utils/validation/min";
import number from "utils/validation/number";
import required from "utils/validation/required";

const validateCrankWeight = value => {
  if (required(value)) return required(value);
  if (!value.crankWeightId) return "Required.";
  return null;
};

const validateDistance = value => {
  if (required(value)) return required(value);
  if (number(value)) return number(value);
  if (min(value)) return min(value);
  if (max(value, 3000)) return max(value, 3000);
  return null;
};

export default values => {
  return {
    ...(validateCrankWeight(values.crankWeight)
      ? {crankWeight: validateCrankWeight(values.crankWeight)}
      : {}),
    ...(required(values.location) ? {location: required(values.location)} : {}),
    ...(required(values.distFromCrankEnd)
      ? {distFromCrankEnd: required(values.distFromCrankEnd)}
      : {}),
    ...(validateDistance(values.distFromCrankEnd)
      ? {distFromCrankEnd: validateDistance(values.distFromCrankEnd)}
      : {}),
  };
};
