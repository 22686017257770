import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import React from "react";
import {reduxForm} from "redux-form";

import colors from "theme/colors";
import convertLength from "utils/conversion/convert-length";
import convertTo from "utils/conversion/convert-to";
import {fetch as fetchCrankWeights} from "actions/crank-weights";
import FilterableSelectField from "components/forms/filterable-select";
import FormButton from "components/form-button";
import getUnits from "utils/get-units";
import getUser from "utils/get-user";
import isEmpty from "lodash/isEmpty";
import TextField from "components/forms/text-field";
import TextFieldListMenu from "components/forms/text-field-list-menu";

import crankWeightLocations from "./crank-weight-locations";
import validate from "./validate-crank-weight";

const mapStateToProps = (state, props) => {
  const units = getUnits();
  const unitsOfMeasure = getUser().unitsOfMeasure;
  const to = convertTo(units, unitsOfMeasure);
  const toLength = to(convertLength, {from: "m"});

  return {
    crankWeights: state.crankWeights.values,
    initialValues: {
      crankWeight: !isEmpty(props.crankWeight) ? props.crankWeight : null,
      location: !isEmpty(props.crankWeight) ? props.crankWeight.location : null,
      distFromCrankEnd: !isEmpty(props.crankWeight)
        ? props.crankWeight.distFromCrankEnd
        : toLength(0),
    },
  };
};

class BuildCrankWeight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crankWeight: props.crankWeight || null,
      showConfirmationDialog: false,
    };
  }

  componentWillMount() {
    this.props.dispatch(fetchCrankWeights());
  }

  save = values => {
    this.props.onSave({
      ...values.crankWeight,
      location: values.location,
      distFromCrankEnd: values.distFromCrankEnd,
    });
  };

  onSelectCrankWeight = crankWeight => {
    this.setState({crankWeight});
  };

  render() {
    const {containerStyle, crankWeights, handleSubmit, onClose} = this.props;

    return (
      <Grid
        container
        direction={"row"}
        style={{
          ...containerStyle,
          border: `1px solid ${colors.grey[300]}`,
          padding: "8px",
          marginTop: "16px",
          marginBottom: "16px",
        }}
        spacing={2}
      >
        <Grid item xs={4}>
          <FilterableSelectField
            fullWidth
            id={"set-crank-weight-filterable-select"}
            name={"crankWeight"}
            items={crankWeights}
            label={"Crank Weight"}
            mapLabel={w => {
              const {manufacturer = "", model = ""} = w;
              return `${manufacturer} ${model}`;
            }}
            mapValue={w => w.crankWeightId}
            onSelect={this.onSelectCrankWeight}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldListMenu
            name={"location"}
            label={"Location"}
            options={crankWeightLocations}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            name={"distFromCrankEnd"}
            label={"Distance From Crank End"}
          />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={2}>
          <Button onClick={onClose} color={"primary"}>
            Cancel
          </Button>
        </Grid>
        <Grid item xs={4}>
          <FormButton
            variant={"contained"}
            fullWidth
            style={{color: "white"}}
            onClick={handleSubmit(this.save)}
          >
            Save Crank Weight
          </FormButton>
        </Grid>
      </Grid>
    );
  }
}

const Component = reduxForm({
  form: `build-crank-weight`,
  validate,
})(BuildCrankWeight);

export default connect(mapStateToProps)(Component);
