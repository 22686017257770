import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const DTV = ({className}) => {
  return (
    <SvgIcon className={className} viewBox="0 30 227 120">
      <path
        d="M3.742 122.844h76.562c18.373 0 30.533-7.858 38-22.781 5.46-10.913 7.67-21.568 11.082-47.026l.04-.296c2.902-21.65 4.689-30.988 8.522-40.544 2.506-6.244 8.215-8.697 20.163-8.697 5.462 0 22.453.068 50.958.204 7.981.038 14.431 6.519 14.431 14.5v102.624c0 8.009-6.492 14.5-14.5 14.5H34.6c-.949 0-1.39.011-3.939.088-4.963.148-7.863.121-11.057-.229-9.371-1.026-14.701-4.549-15.862-12.343z"
        stroke="currentColor"
        strokeWidth={7}
        fill="none"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export default DTV;
