import React from "react";
import last from "lodash/last";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

import getUnits from "utils/get-units";
import round from "utils/round";

const styles = ({typography, spacing, colors}) => ({
  main: {
    display: "grid",
    gridTemplateColumns: "repeat(3, minmax(0,1fr))",
    padding: `${spacing(4)}px ${spacing(3)}px`,
  },

  label: {
    marginBottom: spacing(1) / 2,
    color: colors.warmGrey[500],
  },

  noData: {
    ...typography.h5,
    fontSize: "1.1rem",
    alignSelf: "flex-end",
  },

  value: {
    ...typography.h5,
    lineHeight: "initial",
  },

  valueUnit: {
    ...typography.body2,
    color: colors.warmGrey[500],
    margin: "0 2px",
  },

  degreeUnit: {
    ...typography.h5,
    color: colors.warmGrey[500],
    verticalAlign: "top",
  },

  kpiMiddle: {
    margin: "0 auto",
  },

  kpiRight: {
    marginLeft: "auto",
  },

  kpiValue: {
    display: "flex",
    alignItems: "flex-end",
    minHeight: spacing(4),
  },
});

const KPIPanelWellbore = ({surveySeries, plungerSeries, classes}) => {
  return (
    <div className={classes.main}>
      <div>
        <Typography variant="body2" className={classes.label}>
          TVD
        </Typography>
        <div className={classes.kpiValue}>
          {surveySeries && surveySeries.trueVerticalDepth ? (
            <>
              <span className={classes.value}>
                {round(Math.max(...surveySeries.trueVerticalDepth), 0)}
              </span>
              <span className={classes.valueUnit}>{getUnits().depth}</span>
            </>
          ) : (
            <span className={classes.noData}>No Data</span>
          )}
        </div>
      </div>
      <div className={classes.kpiMiddle}>
        <Typography variant="body2" className={classes.label}>
          Rod String
        </Typography>
        <div className={classes.kpiValue}>
          {plungerSeries && plungerSeries.measuredDepth ? (
            <>
              <span className={classes.value}>
                {round(last(plungerSeries.measuredDepth), 0)}
              </span>
              <span className={classes.valueUnit}>{getUnits().depth}</span>
            </>
          ) : (
            <span className={classes.noData}>No Data</span>
          )}
        </div>
      </div>
      <div className={classes.kpiRight}>
        <Typography variant="body2" className={classes.label}>
          Max DLS
        </Typography>
        <div className={classes.kpiValue}>
          {surveySeries && surveySeries.maxDls ? (
            <>
              <span className={classes.value}>
                {round(surveySeries.maxDls.doglegSeverityDeg30m)}
              </span>
              <span className={classes.degreeUnit}>°</span>
              <span className={classes.valueUnit}>@</span>
              <span className={classes.value}>
                {round(surveySeries.maxDls.measuredDepth, 0)}
              </span>
              <span className={classes.valueUnit}>{getUnits().depth}</span>
            </>
          ) : (
            <span className={classes.noData}>No Data</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(KPIPanelWellbore);
