import {types} from "actions/paging";
import PAGING_SOURCES from "constants/paging-sources";
import resetOnSignout from "utils/reducers/reset-on-signout";

import updateFilter from "./update-filter";

const defaults = {
  filter: "",
  hasInitialized: false,
  previousQuerystring: "",
  source: PAGING_SOURCES.inbox,
};

const actionMap = {
  [types.updateFilter]: updateFilter,
};

export default resetOnSignout((state = defaults, action) => {
  const doAction = actionMap[action.type];
  return doAction ? doAction({state, action}) : state;
});
