import React from "react";
import Typography from "@material-ui/core/Typography";

const FormHeader = ({children, style}) => {
  return (
    <Typography
      variant="h6"
      color={"textSecondary"}
      style={{marginBottom: 0, ...style}}
      paragraph
    >
      {children}
    </Typography>
  );
};

export default FormHeader;
