import React from "react";

import {getStatuses} from "@ambyint/common/well-status";

import parseDescription from "parsers/alert/parse-description/parse-description";
import pluralize from "@ambyint/common/utils/format/format-pluralize";
import wellEventsUserActionTypes from "constants/well-events-user-action-types";
import getUser from "utils/get-user";
import getDisplayName from "utils/user/get-display-name";
import titleize from "utils/titleize";

export default (item, well) => {
  if (item.type === "runningStatus") {
    return item.value ? "Well started" : "Well stopped";
  }

  const user = getDisplayName(item);
  const userText = `${user ? `${user} ` : ""}`;
  const changedWellSetupText = `${userText}changed well setup`;
  const requestedWellActionText = `${userText}requested well action`;

  if (item.type === "statusCodes") {
    const statuses = getStatuses(item.value);

    return (
      <span>
        {`Status code${pluralize(statuses)}`}:
        {statuses.map((status, i) => {
          return (
            <span key={i}>
              <br /> &nbsp;&nbsp; - {status.description}
            </span>
          );
        })}
      </span>
    );
  }

  if (item.type === "driveConnectionStatus") {
    return `The ambyControl device is ${
      item.data.disconnected ? "disconnected" : "connected"
    }.`;
  }

  if (item.type === "driveFault") {
    return `Drive fault ${item.value.code}`;
  }

  if (item.type === "driveMode") {
    return `Drive mode changed to ${titleize(item.value)}`;
  }

  if (item.type === "recommendationAccepted") {
    const prefix =
      item.user.email === "support@ambyint.com"
        ? "Recommendation automatically accepted by Ambyint"
        : "Recommendation accepted";

    return `${prefix}: ${item.value.description}${
      item.value.acceptedComment ? ` (${item.value.acceptedComment})` : ""
    }`;
  }

  if (item.type === "newRecommendation") {
    return `Recommendation created: ${item.value.description}`;
  }

  if (item.type === "recommendationDismissed") {
    return `Recommendation declined: ${item.value.description}${
      item.value.dismissedReason ? ` (${item.value.dismissedReason})` : ""
    }`;
  }

  if (item.type === "alertCreated") {
    const description = parseDescription(item.value, getUser());
    return `Alert created${description ? `: ${description}` : ""}`;
  }

  if (item.type === "alertAcknowledged") {
    const description = parseDescription(item.value, getUser());
    return `Alert acknowledged${description ? `: ${description}` : ""}`;
  }

  if (item.type === "alertResolved") {
    const description = parseDescription(item.value, getUser());
    const note = item.value.resolved.comment;
    return `Alert resolved${description ? `: ${description}` : ""}${
      note ? `, Notes: ${note}` : ""
    }`;
  }

  if (item.type === "allowedToOperate") {
    if (item.value) {
      return `${requestedWellActionText}: start well`;
    }

    return `${requestedWellActionText}: stop well`;
  }

  if (item.type === "miniCards" && item.shutdown) {
    return "A shutdown card was received";
  }

  if (item.type === "speedProfile") {
    const speedUnits =
      well.pumpingUnitType && well.pumpingUnitType.toLowerCase() === "pcp"
        ? "RRPM"
        : "SPM";
    return `${requestedWellActionText}: speed change${
      item.value.speed ? ` to ${item.value.speed} ${speedUnits}` : ""
    }`;
  }

  if (item.type === "speedRange") {
    const speedUnits =
      well.pumpingUnitType && well.pumpingUnitType.toLowerCase() === "pcp"
        ? "RRPM"
        : "SPM";
    return `${requestedWellActionText}: speed range change${
      item.value.minSPM
        ? ` to ${item.value.minSPM}-${item.value.maxSPM} ${speedUnits}`
        : ""
    }`;
  }

  if (item.type === "clearErrors" || item.type === "wellResetMalfunctions") {
    return `${requestedWellActionText}: ${item.value}`;
  }

  if (item.type === "manualFaultReset") {
    return `${requestedWellActionText}: reset fault`;
  }

  if (item.type === "scheduledDowntime") {
    return `${requestedWellActionText}: scheduled downtime`;
  }

  if (item.type === wellEventsUserActionTypes.autoAcceptedRecommendations) {
    return `${changedWellSetupText}: auto accepted recommendations`;
  }

  if (item.type === wellEventsUserActionTypes.pumpingUnit) {
    return `${changedWellSetupText}: pumping unit`;
  }

  if (item.type === wellEventsUserActionTypes.wellbore) {
    return `${changedWellSetupText}: wellbore`;
  }

  if (item.type === wellEventsUserActionTypes.downholeInformation) {
    return `${changedWellSetupText}: downhole information`;
  }

  if (item.type === wellEventsUserActionTypes.reservoir) {
    return `${changedWellSetupText}: reservoir`;
  }

  if (item.type === wellEventsUserActionTypes.totalReductionRatio) {
    return `${changedWellSetupText}: total reduction ratio`;
  }

  if (item.type === wellEventsUserActionTypes.wellAnalogInputChanged) {
    return `${changedWellSetupText}: well analog input`;
  }

  if (item.type === wellEventsUserActionTypes.resistance) {
    return `${changedWellSetupText}: resistance`;
  }

  if (item.type === wellEventsUserActionTypes.deviceAssigned) {
    return `${changedWellSetupText}: device assigned`;
  }

  if (item.type === wellEventsUserActionTypes.deviceRemoved) {
    return `${changedWellSetupText}: device removed`;
  }

  if (item.type === wellEventsUserActionTypes.wellRunTimer) {
    return `${changedWellSetupText}: well run timer`;
  }

  if (item.type === wellEventsUserActionTypes.wellTorqueViolation) {
    return `${changedWellSetupText}: well torque violation`;
  }

  if (item.type === wellEventsUserActionTypes.wellCurrentViolation) {
    return `${changedWellSetupText}: well current violation`;
  }

  if (item.type === wellEventsUserActionTypes.motorDetails) {
    return `${changedWellSetupText}: motor details`;
  }

  if (item.type === wellEventsUserActionTypes.wellPumpOffSettingChanged) {
    return `${changedWellSetupText}: well pump off`;
  }

  if (item.type === wellEventsUserActionTypes.surfaceCardRequested) {
    return `Card requested`;
  }

  if (item.type === wellEventsUserActionTypes.surfaceCardRequestFulfilled) {
    return `Card request fulfilled`;
  }

  console.warn(`No message parser for event type ${item.type}`);
  return titleize(item.value);
};
