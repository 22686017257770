import React from "react";

import FormHeader from "components/form-header";
import Grid from "@material-ui/core/Grid";

import FormButton from "components/form-button";
import getPrivileges from "utils/get-privileges";
import noop from "utils/noop";
import PaperContainer from "components/paper-container";
import privilegeTypes from "utils/privilege-types";
import {cardLabelFactory, cardValueFactory} from "../cards";

const View = props => {
  const {well, onEdit = noop} = props;

  const inputs = well.analogInput;
  const getInputText = input => (input > 0 ? `AI${input}` : "Not Used");

  const privileges = getPrivileges(well.organization.id);

  const CardLabel = cardLabelFactory(6);
  const CardValue = cardValueFactory(6);

  if (!inputs) {
    return (
      <PaperContainer extraPadded style={{maxWidth: "500px"}}>
        <Grid container>
          <Grid item xs={12}>
            <FormHeader>Analog Input Configuration</FormHeader>
          </Grid>
          <Grid item xs={12} style={{marginTop: "0px", marginBottom: "0px"}}>
            <CardLabel label="Analog inputs have not been configured." />
          </Grid>
          <Grid item xs={12} style={{marginTop: "0px", marginBottom: "0px"}}>
            {privileges.includes(privilegeTypes.well.configure) && (
              <FormButton onClick={() => onEdit()}>
                Configure Analog Input
              </FormButton>
            )}
          </Grid>
        </Grid>
      </PaperContainer>
    );
  }

  return (
    <PaperContainer
      label="Analog Input Configuration"
      extraPadded
      style={{maxWidth: "500px"}}
    >
      <Grid container style={{maxWidth: "500px"}}>
        <Grid item xs={12}>
          <FormHeader>Analog Input Configuration</FormHeader>
        </Grid>

        <CardLabel label={"Casing Pressure Input"} />
        <CardValue value={getInputText(inputs.casing)} />

        <CardLabel label={"Tubing Pressure Input"} />
        <CardValue value={getInputText(inputs.tubing)} />

        <Grid item xs={12} style={{paddingTop: "8px"}}>
          {privileges.includes(privilegeTypes.well.configure) && (
            <FormButton onClick={() => onEdit()}>
              Edit Analog Input Configuration
            </FormButton>
          )}
        </Grid>
      </Grid>
    </PaperContainer>
  );
};

export default View;
