import React from "react";
import {matchPath} from "react-router";

import PagingButtons from "components/paging-buttons";
import getDownholeLocation from "utils/well/get-downhole-location";

const Navigation = ({location}) => {
  const match = matchPath(location.pathname, {
    path: "/well/:downholeLocation",
  });

  return (
    match && (
      <PagingButtons
        downholeLocation={getDownholeLocation({match})}
        location={location}
        match={match}
      />
    )
  );
};

export default Navigation;
