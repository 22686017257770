import {types} from "./types";

export const setWellboreSeries = (surveySeries, rodSeries, plungerSeries) => {
  return {
    type: types.setWellboreSeries,
    payload: {
      ready: true,
      surveySeries,
      rodSeries,
      plungerSeries,
    },
  };
};
