const trimIfExists = (key, value) => {
  if (!value) return {};

  return {[key]: value.trim()};
};

export default values => {
  return {
    ...trimIfExists("alias", values.alias),
    ...trimIfExists("downholeLocation", values.downholeLocation),
    ...trimIfExists("fieldName", values.fieldName),
    ...trimIfExists("surfaceLocation", values.surfaceLocation),
  };
};
