import formatWithUnits from "@ambyint/common/utils/format/format-with-units";

const formatRodSectionSummary = section => {
  return (
    section &&
    `${formatWithUnits(
      section.rodDimensions && section.rodDimensions.diameter,
      2,
    )} ${section.rodType.label}`
  );
};

export default formatRodSectionSummary;
