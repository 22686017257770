import Grid from "@material-ui/core/Grid";
import isEmpty from "lodash/isEmpty";
import noop from "lodash/noop";
import React from "react";
import {compose} from "recompose";

import FormButton from "components/form-button";
import FormHeader from "components/form-header";
import PaperContainer from "components/paper-container";
import {roundWithUnits} from "utils/round-with-units";
import withFeatureFlag from "components/with-feature-flag";

import {cardLabelFactory, cardValueFactory} from "../../cards";
import getDisplayedSetting from "../get-displayed-setting";
import StatusIndicator from "../status-indicator";
import {Unconfigured} from "../unconfigured";
import withSettingStatus from "../with-setting-status";

const DeadbandReadView = ({
  canEdit = false,
  onEdit = noop,
  styles = {},
  isStatusVisible,
  settings,
}) => {
  const setting = getDisplayedSetting(settings);

  const CardLabel = cardLabelFactory(6);
  const CardValue = cardValueFactory(6);

  const {deadband, speedChangeStrokeDelay} = setting;

  return (
    <PaperContainer extraPadded style={styles.container}>
      <Grid container>
        <Grid item xs={12}>
          <FormHeader>
            Deadband
            {isStatusVisible && <StatusIndicator settings={settings} />}
          </FormHeader>
        </Grid>

        {isEmpty(setting) && <Unconfigured />}

        {!isEmpty(setting) && (
          <>
            <CardLabel label={"Deadband"} />
            <CardValue value={roundWithUnits(deadband, 2)} />

            <CardLabel label={"Speed Change Stroke Delay"} />
            <CardValue value={speedChangeStrokeDelay} />
          </>
        )}

        {canEdit && (
          <Grid item xs={12}>
            <FormButton onClick={onEdit}>Edit Deadband</FormButton>
          </Grid>
        )}
      </Grid>
    </PaperContainer>
  );
};

export default compose(
  withSettingStatus,
  withFeatureFlag("set-lufkin-deadband"),
)(DeadbandReadView);
