import React from "react";

import Fab from "@material-ui/core/Fab";
import {withStyles} from "@material-ui/core/styles";

import colors from "theme/colors";

const styleSheet = {
  button: {
    width: 50,
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "2em",
    cursor: "pointer",
    margin: "8px",
  },
};

const ToggleButton = ({toggled, children, classes, onClick}) => {
  const style = toggled
    ? {
        color: "white",
        background: colors.ambyOrange[500],
      }
    : {
        color: "black",
      };

  return (
    <Fab
      className={classes.button}
      style={{...style, fontSize: "16px"}}
      onClick={(event, value) => {
        onClick(event, !toggled);
      }}
    >
      {children}
    </Fab>
  );
};

export default withStyles(styleSheet, {name: "DialogOverrides"})(ToggleButton);
