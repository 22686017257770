import {history} from "store";
import querystring from "query-string";
import React from "react";

export const withQuery = Component => {
  return props => {
    const query = querystring.parse(history.location.search);

    return <Component {...props} query={query} />;
  };
};

export default withQuery;
