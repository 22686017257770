import React from "react";
import {connect} from "react-redux";
import {hide} from "actions/discard-form-dialog";
import ConfirmationDialog from "components/confirmation-dialog";

const DiscardFormDialog = ({
  dispatch,
  isOpen,
  pendingAction,
  resetDirtyFormAction,
}) => {
  return (
    <ConfirmationDialog
      title="Warning"
      body="You have unsaved changes. Do you wish to navigate away anyways?"
      cancelButtonText="Cancel"
      confirmationButtonText="Leave without saving"
      isOpen={isOpen}
      onCancel={() => {
        dispatch(hide());
      }}
      onConfirm={() => {
        dispatch(hide());
        dispatch(resetDirtyFormAction);
        dispatch(pendingAction);
      }}
    />
  );
};

const mapStateToProps = state => ({
  ...state.discardFormDialog,
});

export default connect(mapStateToProps)(DiscardFormDialog);
