import React from "react";

import FeedItem from "../feed-item";

export default props => {
  const {event} = props;

  return (
    <FeedItem event={event}>
      The well has{" "}
      <strong>{event.isPowered ? "regained" : "lost"} power</strong>.
    </FeedItem>
  );
};
