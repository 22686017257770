const setCompressiveStress = ({state, action}) => {
  const {
    stressSeries,
    medianStressSeries,
    compressedRodsSeries,
    medianCompressedRodsSeries,
  } = action.payload;

  return {
    ...state,
    compressiveStress: {
      fetched: true,
      stressSeries,
      medianStressSeries,
      compressedRodsSeries,
      medianCompressedRodsSeries,
    },
  };
};

export default setCompressiveStress;
