import {empty} from "actions/empty";
import {set, types} from "actions/field-names";
import fetch from "epics/async-fetch";
import parse from "parsers/field-names";

export const search = (action$, store) => {
  return action$.ofType(types.fetch).switchMap(async () => {
    const state = store.getState().fieldNames;

    if (state.fetched) return empty();

    const data = await fetch("/optimization/fields", {
      country: "canada",
      searchText: "",
    });

    return set({data: parse(data)});
  });
};
