export const types = {
  setView: "set:view",
};

export const setView = ({viewType}) => {
  return {
    type: types.setView,
    payload: {
      viewType,
    },
  };
};
