import React from "react";

export default ({acceptedBy, acceptedOn}) => {
  return (
    <div>
      Accepted by {acceptedBy}
      <br />
      on {acceptedOn.full}
    </div>
  );
};
