import {get} from "lodash";

import {clearSearchComponent} from "actions/app-bar";
import getURIRoot from "utils/get-uri-root";

const ROUTE_ACTION = "@@router/LOCATION_CHANGE";

/**
 * If a new route has been navigated to, remove the currently rendered search bar.
 *
 * When a component wants a search bar it will render it via HoC
 */
export default ({dispatch, ...store}) => next => action => {
  const prevPath = getURIRoot(
    get(store.getState(), "routing.location.pathname", null),
  );
  if (
    action.type === ROUTE_ACTION &&
    getURIRoot(action.payload.pathname) !== prevPath
  ) {
    dispatch(clearSearchComponent);
  }

  next(action);
};
