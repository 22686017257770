import get from "lodash/get";
import React from "react";

import RESPONSE_STATUSES from "constants/response-statuses";

export const withSettingStatus = WrappedComponent => {
  return props => {
    const status = get(props, "settings.request.status");

    const shouldDisplayStatus =
      status === RESPONSE_STATUSES.PENDING ||
      status === RESPONSE_STATUSES.REJECTED;

    return (
      <WrappedComponent isStatusVisible={shouldDisplayStatus} {...props} />
    );
  };
};

export default withSettingStatus;
