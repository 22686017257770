import {connect} from "react-redux";
import React from "react";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {sort} from "actions/alerts";

const mapStateToProps = state => {
  return {
    alerts: state.alerts,
    currentSortBy: state.alerts.sortBy,
    currentSortAsc: state.alerts.sortAsc,
    staleFilters: state.alerts.staleFilters,
  };
};

const getHeaderStyle = ({currentSortBy, sortBy, staleFilters}) => ({
  fontSize: "12px",
  fontWeight: 600,
  ...(staleFilters && sortBy === currentSortBy
    ? {color: "rgba(0, 0, 0, 0.3)"}
    : {}),
});

class Header extends React.Component {
  render() {
    const {sortBy, label, currentSortBy, currentSortAsc, dispatch} = this.props;

    return (
      <TableSortLabel
        active={sortBy === currentSortBy}
        direction={currentSortAsc ? "asc" : "desc"}
        onClick={() => dispatch(sort({sortBy}))}
        style={getHeaderStyle(this.props)}
      >
        {label}
      </TableSortLabel>
    );
  }
}

export default connect(mapStateToProps)(Header);
