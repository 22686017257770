import React from "react";
import {connect} from "react-redux";
import isEmpty from "lodash/isEmpty";

import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import FormHeader from "components/form-header";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import PaperContainer from "components/paper-container";
import Typography from "@material-ui/core/Typography";

import {fetchFullSchedule, unschedule} from "actions/on-call";

import ExistingSchedule from "./existing-schedule";
import UpdateSchedule from "./update-schedule";

const mapStateToProps = state => {
  return {
    onCall: state.onCall,
    user: state.auth.user,
  };
};

class ProfileOnCall extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      open: false,
    };
  }

  componentWillMount() {
    const {dispatch, user} = this.props;

    dispatch(
      fetchFullSchedule({
        clientUserId: user.clientUserId,
      }),
    );
  }

  handleRequestClose = () => {
    this.setState({open: false});
  };

  handleClick = event => {
    this.setState({open: true, anchorEl: event.currentTarget});
  };

  render() {
    const {dispatch, onCall, user} = this.props;

    if (onCall.isFetching) return <LinearProgress />;

    return (
      <PaperContainer extraPadded style={{maxWidth: 600}}>
        {onCall.isOnCall && (
          <div>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12}>
                <FormHeader>On Call Schedule</FormHeader>
              </Grid>

              <Grid item>
                <div>
                  <Typography variant="h6">You are on call.</Typography>
                </div>
              </Grid>
              <Grid item>
                <Button
                  color={"primary"}
                  onClick={() =>
                    dispatch(unschedule({clientUserId: user.clientUserId}))
                  }
                >
                  Remove Existing Schedule
                </Button>
              </Grid>
            </Grid>
            <Divider style={{margin: "8px 0px 16px 0px"}} />
            <ExistingSchedule {...this.props} />
          </div>
        )}

        {!onCall.isOnCall && (
          <div>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12}>
                <FormHeader>On Call Schedule</FormHeader>
              </Grid>

              <Grid item>
                <div>
                  <Typography variant="h6">You are not on call.</Typography>
                </div>
              </Grid>
              {!isEmpty(onCall.schedule) && (
                <Grid item>
                  <Button
                    color={"primary"}
                    onClick={() =>
                      dispatch(unschedule({clientUserId: user.clientUserId}))
                    }
                  >
                    Remove Existing Schedule
                  </Button>
                </Grid>
              )}
            </Grid>
            {!isEmpty(onCall.schedule) && (
              <div>
                <Divider style={{margin: "8px 0px 16px 0px"}} />
                <ExistingSchedule {...this.props} />
              </div>
            )}
            {isEmpty(onCall.schedule) && (
              <div>
                <Divider style={{margin: "8px 0px 16px 0px"}} />
                <UpdateSchedule
                  {...this.props}
                  onDone={() => this.setState({edit: false})}
                />
              </div>
            )}
          </div>
        )}
      </PaperContainer>
    );
  }
}

export default connect(mapStateToProps)(ProfileOnCall);
