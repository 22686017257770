import formatDateTime from "@ambyint/common/utils/format/format-date-time";

export default data => {
  if (!data.acknowledged) return null;

  return {
    ...data.acknowledged,
    message: `${data.acknowledged.by.firstName} ${
      data.acknowledged.by.lastName
    } acknowledged the alert until ${formatDateTime(data.acknowledged.until, {
      timezone: true,
    })}`,
  };
};
