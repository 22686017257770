import makeStyles from "@material-ui/styles/makeStyles";

export default makeStyles({
  automationCell: {
    padding: 12,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-start",
  },
  button: {
    width: 30,
    height: 30,
    minHeight: 30,
    borderRadius: 4,
    boxShadow: "none",
    boxSizing: "border-box",
    marginRight: 4,
  },
});
