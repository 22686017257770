import parseRodDimensions from "@ambyint/common/parsers/well/parse-rod-dimensions";

import {set, types} from "actions/rod-dimensions";
import {default as performFetch} from "epics/fetch";
import getUser from "utils/get-user";

export const fetch = action$ => {
  return action$.ofType(types.fetch).switchMap(({payload: {rodType}}) => {
    const unitsOfMeasure = getUser().unitsOfMeasure;

    return performFetch(`/optimization/rods/${rodType}/dimensions`).map(
      ({response: data}) =>
        set({
          data: data.map(rod => parseRodDimensions(rod, unitsOfMeasure)),
        }),
    );
  });
};
