import {types} from "../types";

/**
 * Creates an action that sets the workflow.
 * @param {String} workflow
 * @returns {{type: string, payload: {path: string}}}
 */
export const setWorkflow = workflow => {
  return {
    type: types.setWorkflow,
    payload: {
      workflow,
    },
  };
};
