import {updateAnalysis} from "actions/wells";
import {off} from "constants/analysis-states";

export default props => fields => {
  const {analysis, dispatch, well} = props;
  const update = {};
  const names = typeof fields === "string" ? [fields] : fields;

  names.forEach(n => {
    update[n] = {
      ...analysis[n],
      state:
        analysis[n].state === analysis[n].maxState
          ? off
          : analysis[n].state + 1,
    };
  });

  dispatch(updateAnalysis(well.downholeLocation, update));
};
