import addLine from "utils/charts/add-line";
import colors from "theme/colors";

function mapData(d) {
  return d.dutyCycle || [];
}

function mapTime(d) {
  return new Date(d.createdOn);
}

function mapDutyCycle(d) {
  return d.value;
}

export default ({clipPathId, selection, register, scales}) => {
  const options = {
    clipPathId,
    mapData,
    mapX: mapTime,
    mapY: mapDutyCycle,
    selection,
    styles: {
      stroke: colors.deepOrange[500],
    },
    xScale: scales.time,
    yScale: scales.percentage,
  };

  const line = addLine(options);

  return register({
    draw(props) {
      const {config} = props;

      line.hide();
      if (config.dutyCycle && config.dutyCycle.state > 0) {
        line.show();
      }

      line.draw(props);
    },

    zoom(props) {
      line.zoom(props);
    },
  });
};
