import React from "react";
import SpeedometerIcon from "mdi-material-ui/Speedometer";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(({colors, spacing, typography}) => ({
  root: {
    display: "flex",
    padding: spacing(0.25, 0.5),
    alignItems: "center",
  },
  icon: {
    color: colors.warmGrey[300],
    height: 28,
    width: 28,
  },
  value: {
    ...typography.subtitle1,
    padding: spacing(0, 0.5, 0, 1),
  },
  label: {
    ...typography.caption,
    color: colors.warmGrey[100],
    fontSize: 12,
  },
}));

const Title = ({minSPM, maxSPM}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SpeedometerIcon className={classes.icon} />
      <div>
        <span className={classes.value}>
          {minSPM} - {maxSPM}
        </span>
        <span className={classes.label}>spm</span>
      </div>
    </div>
  );
};

export default Title;
