import React from "react";
import clsx from "clsx";
import makeStyles from "@material-ui/styles/makeStyles";

const useStyles = makeStyles(({colors, typography}) => ({
  label: {
    ...typography.caption,
    color: colors.warmGrey[500],
    position: "absolute",
    bottom: -12,
  },
  left: {left: 0},
  right: {right: 0},
  red: {
    color: colors.yellowVivid[600],
  },
}));

const Labels = ({metBoundary}) => {
  const classes = useStyles();

  return metBoundary ? (
    <div className={clsx(classes.label, classes.left, classes.red)}>
      Max SPM must be within the SOP
    </div>
  ) : (
    <>
      <div className={clsx(classes.label, classes.left)}>min</div>
      <div className={clsx(classes.label, classes.right)}>max</div>
    </>
  );
};

export default Labels;
