import React from "react";
import Typography from "@material-ui/core/Typography";

import colors from "theme/colors";

/**
 * Box that resembles Material-UI list items, but easier for us to customize and acts as a button by default. Base component for ListStyleButton and ListStyleMenu.
 */
const ListStyleContainer = props => {
  const {children, containerStyle, onClick, primary, secondary} = props;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        background: "white",
        padding: 16,
        border: `thin solid ${colors.grey[300]}`,
        outline: "none",
        cursor: "pointer",
        ...containerStyle,
      }}
      role="button"
      tabIndex={0}
      onClick={onClick}
    >
      <div>
        <Typography
          variant="body1"
          style={{
            fontSize: "0.9em",
            fontWeight: "bold",
            color: colors.grey[700],
            textTransform: "uppercase",
          }}
        >
          {primary}
        </Typography>
        <Typography
          variant="subtitle1"
          style={{
            fontSize: "0.9em",
            color: colors.grey[600],
          }}
        >
          {secondary}
        </Typography>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default ListStyleContainer;
