/* eslint-disable jsx-a11y/anchor-is-valid */
import Grid from "@material-ui/core/Grid";
import React from "react";
import Typography from "@material-ui/core/Typography";
import {connect} from "react-redux";

import {navigateToLogin} from "actions/auth";

const linkStyle = {
  color: "#fc6621",
  cursor: "pointer",
};

const textAlignCenter = {textAlign: "center"};

const UserNotFound = props => {
  return (
    <Grid
      container
      spacing={3}
      justify="center"
      style={{
        margin: "16px",
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h6" style={{textAlign: "center"}}>
          Sorry, we have absolutely no idea who you are.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" style={textAlignCenter}>
          You may have attempted to sign in with a user that is not registered
          in our system, if you{" "}
          <a
            style={linkStyle}
            onClick={() => props.dispatch(navigateToLogin())}
          >
            return to login
          </a>{" "}
          and you can try again
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" style={textAlignCenter}>
          Still not working? Get in touch with our customer success team{" "}
          <a
            style={linkStyle}
            href="mailto:support@ambyint.com?Subject=Encountered an Error"
            target="_top"
          >
            support@ambyint.com
          </a>
          .
        </Typography>
      </Grid>
    </Grid>
  );
};

export default connect()(UserNotFound);
