import {ajax} from "rxjs/observable/dom/ajax";
import {empty} from "rxjs/observable/empty";
import querystring from "query-string";

import {hide as hideAcknowledgeAlertDialog} from "actions/acknowledge-alert-dialog";
import {hide as hideResolveAlertDialog} from "actions/resolve-alert-dialog";
import {parse, set, setOne, types} from "actions/alerts";
import {show as showSnackbar} from "actions/snackbar";
import {types as authTypes} from "actions/auth";
import parseAlertModel from "models/alert";
import ALERT_STATUS_STATES from "constants/alert-status-states";

import addAuthHeaders from "../add-auth-headers";

export {default as fetchTimeline} from "./fetch-timeline";

export const addAlert = action$ => {
  return action$.ofType(types.add).switchMap(({payload}) => {
    return [
      parse({
        data: {...payload},
      }),
    ];
  });
};

export const acknowledgeAlert = (action$, store) => {
  return action$.ofType(types.acknowledge).switchMap(({payload}) => {
    return ajax(
      addAuthHeaders(
        {
          url: `${process.env.REACT_APP_API}/alert/${
            payload.alertId
          }/acknowledge`,
          responseType: "json",
          method: "PUT",
          body: {
            acknowledgeUntil: payload.acknowledgeUntil,
          },
        },
        store,
      ),
    ).flatMap(() => [
      hideAcknowledgeAlertDialog(),
      showSnackbar({message: "You've acknowledged the alert."}),
    ]);
  });
};

export const fetch = (action$, store) => {
  return action$.ofType(types.fetch, authTypes.set).switchMap(() => {
    const query = querystring.stringify({
      status: `${ALERT_STATUS_STATES.UNACKNOWLEDGED},${
        ALERT_STATUS_STATES.ACKNOWLEDGED
      }`,
    });

    const user = store.getState().auth.user || null;
    if (!user || !user.clientUserId) {
      return empty();
    }

    return ajax(
      addAuthHeaders(
        {
          url: `${process.env.REACT_APP_API}/alerts/user/${user.clientUserId}${
            query ? `?${query}` : ""
          }`,
          responseType: "json",
        },
        store,
      ),
    ).map(({response: data}) => set({data, user}));
  });
};

export const parseOne = (action$, store) => {
  return action$.ofType(types.parse).switchMap(({payload}) => {
    const user = store.getState().auth.user || null;

    return [
      setOne({
        data: parseAlertModel(payload, user),
      }),
    ];
  });
};

export const resolveAlert = (action$, store) => {
  return action$.ofType(types.resolve).switchMap(({payload}) => {
    return ajax(
      addAuthHeaders(
        {
          url: `${process.env.REACT_APP_API}/alert/${payload.alertId}/resolve`,
          responseType: "json",
          method: "PUT",
          body: {
            comment: payload.comment,
          },
        },
        store,
      ),
    ).flatMap(() => [
      hideResolveAlertDialog(),
      showSnackbar({message: "You've resolved the alert."}),
    ]);
  });
};

export const updateAlert = action$ => {
  return action$.ofType(types.update).switchMap(({payload}) => {
    return [
      parse({
        data: {...payload},
      }),
    ];
  });
};
