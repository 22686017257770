import {set, types} from "actions/guide-materials";
import parseGuideMaterial from "@ambyint/common/parsers/well/parse-guide-material";
import performFetch from "epics/fetch";

import getUser from "utils/get-user";

export const fetch = action$ => {
  return action$.ofType(types.fetch).switchMap(() => {
    const unitsOfMeasure = getUser().unitsOfMeasure;

    return performFetch("/optimization/guidematerials").map(
      ({response: data}) =>
        set({
          data: data.map(item => parseGuideMaterial(item, unitsOfMeasure)),
        }),
    );
  });
};
