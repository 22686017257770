import {push} from "react-router-redux";
import {connect} from "react-redux";
import React from "react";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import Avatar from "components/avatar";
import OnCallStatus from "components/on-call-status";
import colors from "theme/colors";

const style = {
  container: {
    padding: "16px",
  },
  text: {
    color: colors.ambyBlue[50],
    fontWeight: 200,
    fontSize: "20px",
    textTransform: "uppercase",
    textAlign: "center",
    letterSpacing: "0.5px",
  },
};

const AppDrawer = props => {
  const {dispatch, user} = props;

  return (
    <Grid
      container
      spacing={0}
      justify="center"
      direction="column"
      alignItems="center"
      style={style.container}
    >
      <Grid item style={{cursor: "pointer"}}>
        <Button
          style={{width: "100%", height: "100%"}}
          onClick={() => dispatch(push(`/profile/general`))}
        >
          <div style={{position: "relative"}}>
            <Avatar size={100} user={user} />
          </div>
        </Button>
      </Grid>
      <Grid item style={{paddingTop: "16px", cursor: "pointer"}}>
        <Button
          style={{width: "100%", height: "100%"}}
          onClick={() => dispatch(push(`/profile/on-call`))}
        >
          <OnCallStatus />
          <span style={style.text}>{user.name}</span>
        </Button>
      </Grid>
    </Grid>
  );
};

export default connect()(AppDrawer);
