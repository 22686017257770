import React from "react";

import {axisBottom, axisLeft, axisRight} from "d3-axis";
import {line as d3line} from "d3-shape";
import {min, max} from "d3-array";
import {scaleLinear} from "d3-scale";
import {select} from "d3-selection";

import colors from "theme/colors";
import spreadIf from "utils/spread-if";

import addMargins from "./add-margins";

const defaultMargin = {
  top: 30,
  right: 60,
  bottom: 30,
  left: 60,
};

const styles = {
  axis: {
    fill: colors.white,
  },
  card: {
    fill: colors.transparent,
    strokeWidth: 2,
  },
  svg: {
    display: "block",
  },
};

const linear = (
  points,
  map,
  guidelines,
  axisMarginPercent = 0.0,
  axisMargin = 0,
  axisScaleMinimum = null,
) => {
  let minValue = min(points, map);
  let maxValue = max(points, map);

  if (guidelines && guidelines.min) {
    minValue = Math.min(minValue, min(points, guidelines.min.map));
  }

  if (guidelines && guidelines.max) {
    maxValue = Math.max(maxValue, max(points, guidelines.max.map));
  }

  return scaleLinear().domain([
    addMargins(
      minValue,
      maxValue - minValue,
      -1,
      axisMarginPercent,
      axisMargin,
      axisScaleMinimum,
    ),
    addMargins(
      maxValue,
      maxValue - minValue,
      1,
      axisMarginPercent,
      axisMargin,
      axisScaleMinimum,
    ),
  ]);
};

const Chart = ({data, margin = defaultMargin, height, width, x, y}) => {
  if (!data) {
    return null;
  }

  const points = data;

  const horizontalWithMargins = (graphHeight, graphMargins) => scaledDomain =>
    scaledDomain.range([
      graphHeight -
        (graphMargins.top + (x.hideAxisLabels ? graphMargins.bottom : 0)) +
        (x.hideAxisLabels ? 0 : margin.top - margin.bottom),
      0,
    ]);

  const verticalWithMargins = (graphWidth, graphMargins) => scaledDomain =>
    scaledDomain.range([
      0,
      graphWidth - (graphMargins.left + graphMargins.right),
    ]);

  const horizontal = horizontalWithMargins(height, margin);
  const vertical = verticalWithMargins(width, margin);

  const scales = {
    ...spreadIf(y.right, {
      rightY:
        y.right &&
        horizontal(
          linear(
            points,
            y.right.map,
            y.right.guidelines,
            y.right.marginPercent,
            y.right.margin,
            y.right.scaleMinimum != null &&
              y.right.scaleMinimum + margin.top + margin.bottom,
          ),
        ),
    }),
    ...spreadIf(y.left, {
      leftY:
        y.left &&
        horizontal(
          linear(
            points,
            y.left.map,
            y.left.guidelines,
            y.left.marginPercent,
            y.left.margin,
            y.left.scaleMinimum != null &&
              y.left.scaleMinimum + margin.top + margin.bottom,
          ),
        ),
    }),
    x: vertical(
      linear(
        points,
        x.map,
        null,
        null,
        x.marginPercent,
        x.margin,
        x.scaleMinimum != null && x.scaleMinimum + margin.left + margin.right,
      ),
    ),
  };

  const lines = {
    right:
      y.right &&
      d3line()
        .x(d => scales.x(x.map(d)))
        .y(d => scales.rightY(y.right.map(d))),
    left:
      y.left &&
      d3line()
        .x(d => scales.x(x.map(d)))
        .y(d => scales.leftY(y.left.map(d))),
  };

  const guideline = (g, side) => {
    return (
      g &&
      d3line()
        .x(d => scales.x(x.map(d)))
        .y(d => scales[side + "Y"](g.map(d)))
    );
  };

  const xAxis = axisBottom(scales.x);
  const leftYAxis = y.left && axisLeft(scales.leftY);
  const rightYAxis = y.right && axisRight(scales.rightY);

  const drawLines = side => {
    return [
      y[side] && (
        <path
          key={side}
          style={{...styles.card, stroke: y[side].color}}
          d={lines[side](data)}
        />
      ),
      y[side] &&
        y[side].guidelines &&
        Object.values(y[side].guidelines).map((g, key) => {
          return (
            <path
              key={`${side}-${key}`}
              style={{
                ...styles.card,
                stroke: g.color,
              }}
              d={guideline(g, side)(data)}
            />
          );
        }),
    ];
  };

  return (
    <svg
      style={styles.svg}
      width={width + margin.left + margin.right}
      height={height + margin.top + margin.bottom}
    >
      <g transform={`translate(${margin.left}, ${margin.top})`}>
        {y.left && !y.left.hideAxisLabels && (
          <g className="axis-base" ref={node => select(node).call(leftYAxis)}>
            <text x={0} y={-10} style={{fontSize: "1.1em", fill: y.left.color}}>
              {y.left.label}
            </text>
          </g>
        )}

        {y.right && !y.right.hideAxisLabels && (
          <g
            className="axis-base"
            ref={node => select(node).call(rightYAxis)}
            transform={`translate(${width - margin.left - margin.right}, 0)`}
          >
            <text
              x={0}
              y={-10}
              style={{fontSize: "1.1em", fill: y.right.color}}
            >
              {y.right.label}
            </text>
          </g>
        )}

        {!x.hideAxisLabels && (
          <g
            className="axis-base"
            ref={node => select(node).call(xAxis)}
            transform={`translate(0, ${height - margin.bottom})`}
          >
            <text
              x={0}
              y={35}
              style={{fontSize: "1.1em"}}
              transform={`translate(${(width - margin.left - margin.right) /
                2}, 0)`}
            >
              {x.label}
            </text>
          </g>
        )}

        {drawLines("left")}
        {drawLines("right")}
      </g>
    </svg>
  );
};

export default Chart;
