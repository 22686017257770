import {withStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";

const styles = () => ({
  root: {
    tableLayout: "fixed",
    "& th": {
      paddingTop: 30,
      paddingBottom: 22,
    },
  },
});

export default withStyles(styles)(Table);
