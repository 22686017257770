import DownloadIcon from "@material-ui/icons/CloudDownload";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import clsx from "clsx";

import TextTooltip from "components/material-tooltip";
import AsMenuItem from "components/as-menu-item";
import {useFlag} from "hooks/use-flag";
import {internalExportAnalysis} from "constants/feature-flags";

import {downloadFile} from "./download-file";
import {generateCsv} from "./generate-csv";

const styles = ({colors}) => ({
  icon: {
    color: colors.warmGrey[300],
  },
  disabled: {
    opacity: 0.5,
  },
});

const formatDate = date => moment(date).format("YYYY-MM-DD");

const exportCsv = props => () => {
  if (props.disabled) return;

  const {analysis, well} = props;
  const start = formatDate(analysis.range.start);
  const end = formatDate(analysis.range.end);
  const fileName = `${well.downholeLocation}-${start}-to-${end}.csv`;

  downloadFile(fileName, generateCsv(props));
};

const DownloadButton = props => {
  return useFlag(internalExportAnalysis) ? (
    <AsMenuItem
      isMenuItem={props.isMenuItem}
      onClick={exportCsv(props)}
      disabled={props.disabled}
    >
      {props.label ? (
        <>
          <IconButton className={props.classes.icon} disabled={props.disabled}>
            <DownloadIcon />
          </IconButton>
          <p>Download CSV</p>
        </>
      ) : (
        <TextTooltip title="Download CSV" placement="bottom">
          <div>
            <IconButton
              className={clsx(
                props.classes.icon,
                props.disabled && props.classes.disabled,
              )}
              disabled={props.disabled}
            >
              <DownloadIcon />
            </IconButton>
          </div>
        </TextTooltip>
      )}
    </AsMenuItem>
  ) : null;
};

export default withStyles(styles)(DownloadButton);
