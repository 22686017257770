import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import sortBy from "lodash/sortBy";
import take from "lodash/take";

import theme from "theme";
import colors from "theme/colors";
import EventMeta from "components/event-meta";
import generateMessage from "./generate-message";

const styles = {
  container: {
    backgroundColor: colors.white,
    border: `1px solid ${colors.grey[300]}`,
    height: "369px",
    position: "relative",
  },
  list: {
    container: {
      overflow: "auto",
      position: "absolute",
      top: "49px",
      right: 0,
      left: 0,
      bottom: "16px",
    },
  },
  toolbar: {
    container: {
      ...theme.typography.body1,
      borderBottom: `1px solid ${colors.grey[300]}`,
      color: colors.grey[600],
      fontSize: "12px",
      lineHeight: "48px",
      padding: "0 16px",
      textTransform: "uppercase",
    },
  },
};

const Toolbar = () => {
  return (
    <div style={styles.toolbar.container}>
      <div style={styles.toolbar.title}>Activity Feed</div>
    </div>
  );
};

const renderItems = (items, well) => {
  return items.map(item => {
    return (
      <ListItem key={item._id}>
        <ListItemText
          style={{wordBreak: "break-word"}}
          primary={<EventMeta event={item} />}
          secondary={generateMessage(item, well)}
        />
      </ListItem>
    );
  });
};

const Component = props => {
  const {
    data: {
      alertAcknowledged = [],
      alertCreated = [],
      alertResolved = [],
      allowedToOperate = [],
      autoAcceptedRecommendations = [],
      clearErrors = [],
      deviceAssigned = [],
      deviceRemoved = [],
      downholeInformation = [],
      driveConnectionStatus = [],
      driveFault = [],
      driveMode = [],
      manualFaultReset = [],
      miniCards = [],
      motorDetails = [],
      newRecommendation = [],
      pumpingUnit = [],
      recommendationAccepted = [],
      recommendationDismissed = [],
      reservoir = [],
      resistance = [],
      runningStatus = [],
      scheduledDowntime = [],
      speedProfile = [],
      speedRange = [],
      statusCodes = [],
      surfaceCardRequested = [],
      surfaceCardRequestFulfilled = [],
      totalReductionRatio = [],
      wellAnalogInputChanged = [],
      wellCurrentViolation = [],
      wellPumpOffSettingChanged = [],
      wellResetMalfunctions = [],
      wellRunTimer = [],
      wellTorqueViolation = [],
      wellbore = [],
    } = {},
    date,
    well,
  } = props;

  const items = take(
    sortBy(
      [
        ...alertAcknowledged,
        ...alertCreated,
        ...alertResolved,
        ...allowedToOperate,
        ...autoAcceptedRecommendations,
        ...clearErrors,
        ...deviceAssigned,
        ...deviceRemoved,
        ...downholeInformation,
        ...driveConnectionStatus,
        ...driveFault,
        ...driveMode,
        ...manualFaultReset,
        ...motorDetails,
        ...newRecommendation,
        ...pumpingUnit,
        ...recommendationAccepted,
        ...recommendationDismissed,
        ...reservoir,
        ...resistance,
        ...runningStatus,
        ...scheduledDowntime,
        ...speedProfile,
        ...speedRange,
        ...statusCodes,
        ...surfaceCardRequested,
        ...surfaceCardRequestFulfilled,
        ...totalReductionRatio,
        ...wellAnalogInputChanged,
        ...wellCurrentViolation,
        ...wellPumpOffSettingChanged,
        ...wellResetMalfunctions,
        ...wellRunTimer,
        ...wellTorqueViolation,
        ...wellbore,
        ...miniCards.filter(c => c.shutdown),
      ],
      e => -new Date(e.createdOn),
    ).filter(e => new Date(e.createdOn) <= new Date(date)),
    20,
  );

  return (
    <div style={styles.container}>
      <Toolbar />
      <div style={styles.list.container}>
        {items.length > 0 && <List>{renderItems(items, well)}</List>}
      </div>
    </div>
  );
};

export default Component;
