import required from "utils/validation/required";
import uri from "utils/validation/uri";
import validateFieldsOf from "utils/validation/validate-fields-of";

const cleanUri = uri =>
  (uri || "")
    .trim()
    .toLowerCase()
    .replace(/\/$/, "");

const isUniqueUri = (uri, webhook, allWebhooks) =>
  allWebhooks
    .filter(w => w.webhookId !== webhook.webhookId)
    .some(w => cleanUri(w.uri) === cleanUri(uri))
    ? "Duplicate uri"
    : null;

export default (webhook, allWebhooks) => {
  const validateField = validateFieldsOf(webhook);

  return {
    ...validateField("uri", x => required(x)),
    ...validateField("uri", x => uri(x)),
    ...validateField("uri", x => isUniqueUri(x, webhook, allWebhooks)),

    ...validateField("secret", x => required(x)),
  };
};
