import Grid from "@material-ui/core/Grid";
import React from "react";

import getShutdownCardReason from "utils/well/get-shutdown-card-reason";

import Analysis from "./analysis";
import Item from "./item";

const renderShutdownReason = card => {
  const reason = getShutdownCardReason(card);

  return reason && <Item title="Shutdown Reason" value={reason} />;
};

export default ({data}) => {
  const card = data.generatedCards || data.rawCards;

  if (!card || !card.shutdown) return null;

  return (
    <Analysis title="Summary">
      <Grid container>
        <Grid item xs={12}>
          {renderShutdownReason(card)}
        </Grid>
      </Grid>
    </Analysis>
  );
};
