import React from "react";
import {connect} from "react-redux";

import {acknowledge} from "actions/alerts";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "components/dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import {hide} from "actions/acknowledge-alert-dialog";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";

const mapStateToProps = state => {
  return {
    alert: state.acknowledgeAlertDialog.alert,
    isOpen: state.acknowledgeAlertDialog.isOpen,
  };
};

const AcknowledgeAlertDialog = props => {
  const {alert, isOpen, dispatch} = props;

  if (!alert) return null;

  const closeDialog = () => dispatch(hide());
  const acknowledgeAlert = acknowledgeUntil => {
    dispatch(
      acknowledge({
        acknowledgeUntil,
        alertId: alert.alertId,
      }),
    );
  };

  return (
    <Dialog open={isOpen} onClose={closeDialog}>
      <DialogTitle>
        <Grid
          container
          spacing={0}
          justify={"space-between"}
          alignItems={"center"}
        >
          <Grid item xs>
            {"Acknowledge Alert"}
          </Grid>
          <Grid item xs={2} sm={1} style={{textAlign: "right"}}>
            <IconButton onClick={closeDialog}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Upon acknowledging the alert, notifications will not be sent for the
          same alert type. Once the acknowledgement has expired, notifications
          will be sent.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() =>
            acknowledgeAlert(
              moment()
                .add(1, "days")
                .toDate(),
            )
          }
          color="primary"
        >
          One Day
        </Button>
        <Button
          onClick={() =>
            acknowledgeAlert(
              moment()
                .add(2, "days")
                .toDate(),
            )
          }
          color="primary"
        >
          Two Days
        </Button>
        <Button
          onClick={() =>
            acknowledgeAlert(
              moment()
                .add(7, "days")
                .toDate(),
            )
          }
          color="primary"
        >
          One Week
        </Button>
        <Button
          onClick={() =>
            acknowledgeAlert(
              moment()
                .add(14, "days")
                .toDate(),
            )
          }
          color="primary"
        >
          Two Weeks
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps)(AcknowledgeAlertDialog);
