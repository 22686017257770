import {Field} from "redux-form";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import React from "react";
import noop from "utils/noop";

const RadioGroupComponent = props => {
  const {
    externalOnChange = noop,
    input,
    label,
    options = [],
    row = true,
    style = {},
    itemStyle = {},
  } = props;

  const defaultStyle = {
    margin: 0,
  };

  return (
    <RadioGroup
      aria-label={label}
      name={input.value.toString()}
      style={{...defaultStyle, ...style}}
      onChange={event => {
        input.onChange(event);
        externalOnChange(event);
      }}
      value={
        input.value.value === undefined
          ? input.value.toString()
          : input.value.value.toString()
      }
      row={row}
    >
      {options.map((option, index) => (
        <FormControlLabel
          key={index}
          control={<Radio />}
          label={option.label === undefined ? option : option.label}
          value={option.value === undefined ? option : option.value}
          style={itemStyle}
        />
      ))}
    </RadioGroup>
  );
};

export default props => {
  return <Field {...props} component={RadioGroupComponent} />;
};
