import {show} from "actions/snackbar";
import {setOne, types} from "actions/wells";

import fetch from "epics/fetch";

import parseSaveResistance from "./parse-save-resistance";

export default action$ =>
  action$.ofType(types.saveResistance).switchMap(({payload}) =>
    fetch(
      `/optimization/well/${encodeURIComponent(
        payload.downholeLocation,
      )}/resistance`,
      {},
      {
        body: parseSaveResistance(payload.data),
        method: "PUT",
      },
    ).flatMap(() => [
      setOne({
        wellId: payload.wellId,
        data: {
          resistance: payload.data,
        },
      }),
      show({
        message: "You have successfully updated the Resistance",
      }),
    ]),
  );
