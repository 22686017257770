import cloneDeep from "lodash/cloneDeep";
import reduce from "lodash/reduce";

const DEFAULT_ROD_SERIES = {
  x: [],
  y: [],
  z: [],
  inclinationDegrees: [],
  doglegSeverityDeg30m: [],
  measuredDepth: [],
  trueVerticalDepth: [],
  length: [],
  rodLength: [],
  rodQuantity: [],
  rodType: [],
  diameter: [],
  guideQuantity: [],
};

const DEFAULT_SURVEY_SERIES = {
  x: [],
  y: [],
  z: [],
  inclinationDegrees: [],
  measuredDepth: [],
  trueVerticalDepth: [],
  doglegSeverityDeg30m: [],
  dlsMap: [],
};

const rodSeriesReducer = (accumulator, point) => {
  const rodSeries = {...accumulator};

  rodSeries.x.push(point.x);
  rodSeries.y.push(point.y);
  rodSeries.z.push(point.z);
  rodSeries.inclinationDegrees.push(point.inclinationDegrees);
  rodSeries.doglegSeverityDeg30m.push(point.doglegSeverityDeg30m);
  rodSeries.measuredDepth.push(point.measuredDepth);
  rodSeries.trueVerticalDepth.push(point.trueVerticalDepth);
  rodSeries.length.push(point.length);
  rodSeries.rodLength.push(point.rodLength);
  rodSeries.rodQuantity.push(point.rodQuantity);
  rodSeries.rodType.push(point.rodType);
  rodSeries.diameter.push(point.diameter);
  rodSeries.guideQuantity.push(point.guideQuantity);

  return rodSeries;
};

const surveySeriesReducer = (accumulator, point) => {
  const surveySeries = {...accumulator};

  surveySeries.x.push(point.x);
  surveySeries.y.push(point.y);
  surveySeries.z.push(point.z);
  surveySeries.inclinationDegrees.push(point.inclinationDegrees);
  surveySeries.measuredDepth.push(point.measuredDepth);
  surveySeries.trueVerticalDepth.push(point.trueVerticalDepth);
  surveySeries.doglegSeverityDeg30m.push(point.doglegSeverityDeg30m);
  surveySeries.dlsMap.push(point.dlsMap);

  return surveySeries;
};

export const reduceRodSeries = rodData =>
  reduce(rodData, rodSeriesReducer, cloneDeep(DEFAULT_ROD_SERIES));

export const reduceSurveySeries = surveyData =>
  reduce(surveyData, surveySeriesReducer, cloneDeep(DEFAULT_SURVEY_SERIES));
