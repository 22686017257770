import React, {useState} from "react";
import {SizeMe} from "react-sizeme";
import withStyles from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import Container from "components/container";
import WellMap from "components/map";
import WorkflowFilter from "components/workflow-filter";
import TreemapChart from "components/treemap";
import {Sunburst} from "components/sunburst";
import {workflows} from "constants/well-workflows";

import NoWells from "../no-wells";
import "./drill-down-panel.css";

const styles = ({breakpoints}) => ({
  container: {
    margin: 0,
    width: "100%",
  },
  grid: {
    padding: "0 !important",
  },
  sunburstSection: {
    zIndex: 1,
    background: "#f6f6f6",
    maxHeight: 471,
    [breakpoints.down("lg")]: {
      maxHeight: 411,
    },
    [breakpoints.down("md")]: {
      maxHeight: 350,
    },
  },
});

const renderViz = (
  workflowWells,
  workflow,
  setActivePath,
  toggleSlide,
  allWorkflowWells,
) => {
  const currentWorkflow = workflow.selected;

  if (workflowWells.length === 0) return <NoWells />;
  if (currentWorkflow === workflows.ANOMALY)
    return <TreemapChart wells={workflowWells} />;

  return (
    <Sunburst
      onSelectPath={setActivePath}
      onToggleTags={toggleSlide}
      selectedPath={workflow.activePath}
      workflow={workflow}
      wells={allWorkflowWells}
    />
  );
};

const toggleSlide = (slide, setSlide) => () => {
  const isRight = slide === "slide-right";
  isRight ? setSlide("slide-left") : setSlide("slide-right");
};

const DrillDownPanel = ({
  classes,
  setActivePath,
  isTagDrawerOpen,
  workflowWells,
  workflow,
  allWorkflowWells,
}) => {
  const [slide, setSlide] = useState(null);

  return (
    <Container>
      <Paper>
        <Grid container spacing={4} className={classes.container}>
          <Grid className={clsx(slide, classes.sunburstSection)} item sm={4}>
            <WorkflowFilter onToggleTags={toggleSlide(slide, setSlide)} />
            <SizeMe>
              {({size}) => (
                <div style={{minHeight: size.width}}>
                  {renderViz(
                    workflowWells,
                    workflow,
                    setActivePath,
                    toggleSlide,
                    allWorkflowWells,
                  )}
                </div>
              )}
            </SizeMe>
          </Grid>
          <Grid className={`${classes.grid} ${slide}`} item sm={8}>
            {!!workflowWells && <WellMap workflow={workflow} />}
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default withStyles(styles)(DrillDownPanel);
