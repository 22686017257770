import uniqBy from "lodash/uniqBy";

import * as EVENT_TYPES from "constants/event-types";
import groupEventsByType from "utils/group-events-by-type";

const mapType = type => {
  if (type === EVENT_TYPES.RAW_CARDS) return EVENT_TYPES.CARDS;
  if (type === EVENT_TYPES.GENERATED_CARDS) return EVENT_TYPES.CARDS;

  return type;
};

export default ({state, action}) => {
  const wellId = state.byDownholeLocation[action.payload.downholeLocation];

  const well = state.byWellId[wellId] || {};

  const events = uniqBy(
    [...(well.events || []), ...action.payload.data],
    event => `${event._id}-${mapType(event.type)}`,
  );

  const update = {
    ...well,
    events,
    eventsByType: {
      ...groupEventsByType(events),
      isFetching: false,
    },
  };

  const byWellId = {...state.byWellId, [wellId]: update};

  return {
    ...state,
    byWellId,
  };
};
