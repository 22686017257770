import {types} from "./types";

export const saveAssignedWells = options => {
  const {
    assignedWells,
    clientUserId,
    organizationId,
    unassignedWells,
  } = options;

  return {
    type: types.saveAssignedWells,
    payload: {
      assignedWells,
      clientUserId,
      organizationId,
      unassignedWells,
    },
  };
};
