import colors from "theme/colors";

export default {
  subheader: {
    color: colors.grey[600],
  },
  grid: {
    height: "100%",
    cursor: "pointer",
  },
  header: {
    borderBottom: "1px solid rgb(224, 224, 224)",
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "12px",
    height: 88,
    padding: "0px 20px",
    alignItems: "center",
  },
  pageContainer: {
    position: "relative",
    paddingBottom: 24,
  },
  paper: {
    borderBottom: "1px solid rgb(224, 224, 224)",
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "14px",
    height: "48px",
    padding: "0px 20px",
  },
  rowText: {
    fontWeight: 300,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
};
