import React, {Fragment} from "react";

import {hasControlForEpm} from "@ambyint/common/utils/devices/control-for-epm";

import getUser from "utils/get-user";
import colors from "theme/colors";

import Card from "./card";
import DriveData from "./drive-data";
import Item from "./item";
import Spm from "./spm";
import OilProduction from "./oil-production";
import Fillage from "./fillage";

const Legend = props => {
  const {
    alerts = {},
    casingPressure = {},
    current = {},
    devices,
    deflection = {},
    driveData = {},
    driveFault = {},
    driveMode = {},
    dutyCycle = {},
    effectiveSpm = {},
    fillage = {},
    inferredFillage = {},
    ambyintFillage = {},
    inferredOilProduction = {},
    leakPressure = {},
    load = {},
    miniCards: cards = {},
    oilProduction = {},
    onChange = () => {},
    rpm = {},
    rrpm = {},
    recommendations = {},
    runningStatus = {},
    shutdownCards = {},
    spm = {},
    statusCodes = {},
    torque = {},
    tubingPressure = {},
    userActions = {},
  } = props;

  let downholeCard = [];
  let surfaceCard = [];

  if (cards.value) {
    downholeCard = cards.value.downholeCard || downholeCard;
    surfaceCard = cards.value.surfaceCard || surfaceCard;
  }

  const user = getUser();
  const isEpm = hasControlForEpm(devices);
  const torqueOrCurrent =
    isEpm && user.epmPreference ? user.epmPreference : "torque";

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <Card width={166} surfaceData={surfaceCard} downholeData={downholeCard} />
      <div>
        <DriveData
          width={166}
          height={53}
          color={colors[torqueOrCurrent.toLocaleLowerCase()][400]}
          data={driveData}
          devices={devices}
          leftAxisUnits={torqueOrCurrent}
        />
        {!isEpm && (
          <DriveData
            width={166}
            height={53}
            color={colors.rpm[400]}
            data={driveData}
            devices={devices}
            leftAxisUnits={"rpm"}
            scaleMinimum={50}
          />
        )}
      </div>
      <div style={{display: "flex", flexWrap: "wrap"}}>
        <Spm spm={spm} effectiveSpm={effectiveSpm} onChange={onChange} />
        {oilProduction.show && inferredOilProduction.show ? (
          <OilProduction
            oilProduction={oilProduction}
            inferredOilProduction={inferredOilProduction}
            onChange={onChange}
          />
        ) : (
          <Fragment>
            <Item
              {...oilProduction}
              label="Measured Oil Prod."
              color={colors.oilProduction[700]}
              onClick={() => onChange("oilProduction")}
            />
            <Item
              {...inferredOilProduction}
              label="Inferred Oil Prod."
              color={colors.oilProduction[200]}
              onClick={() => onChange("inferredOilProduction")}
            />
          </Fragment>
        )}
        <Fillage
          pocFillage={fillage}
          ambyintFillage={ambyintFillage}
          onChange={onChange}
        />
        <Item
          {...inferredFillage}
          label="Inferred Fillage"
          color={colors.fillage[400]}
          onClick={() => onChange("inferredFillage")}
        />
        <Item
          {...load}
          label="Load"
          color={colors.load[400]}
          onClick={() => onChange("load")}
        />
        <Item
          {...current}
          label="Current"
          color={colors.current[400]}
          onClick={() => onChange("current")}
        />
        <Item
          {...torque}
          label="Torque"
          color={colors.torque[400]}
          onClick={() => onChange("torque")}
        />
        <Item
          {...rpm}
          label="RPM"
          color={colors.rpm[400]}
          onClick={() => onChange("rpm")}
        />
        <Item
          {...rrpm}
          label="RRPM"
          color={colors.rrpm[800]}
          onClick={() => onChange("rrpm")}
        />
        <Item
          {...casingPressure}
          label="Casing"
          color={colors.casingPressure[400]}
          onClick={() => onChange("casingPressure")}
        />
        <Item
          {...deflection}
          label="SHL Deflection"
          color={colors.deflection[400]}
          onClick={() => onChange("deflection")}
        />
        <Item
          {...leakPressure}
          label="Leak"
          color={colors.leakPressure[400]}
          onClick={() => onChange("leakPressure")}
        />
        <Item
          {...tubingPressure}
          label="Tubing"
          color={colors.tubingPressure[400]}
          onClick={() => onChange("tubingPressure")}
        />
        <Item
          {...dutyCycle}
          label="Duty Cycle"
          color={colors.deepOrange[500]}
          onClick={() => onChange("dutyCycle")}
        />
        <Item
          {...runningStatus}
          label="Running Status"
          color={colors.green[400]}
          onClick={() => onChange("runningStatus")}
        />
        <Item
          {...shutdownCards}
          label="Shutdown Cards"
          color={colors.red[400]}
          onClick={() => onChange("shutdownCards")}
        />
        <Item
          {...userActions}
          label="User Actions"
          color={colors.purple[400]}
          onClick={() => onChange("userActions")}
        />
        <Item
          {...driveFault}
          label="Drive Fault"
          color={colors.amber[400]}
          onClick={() => onChange("driveFault")}
        />
        <Item
          {...statusCodes}
          label="Status Codes"
          color={colors.blue[400]}
          onClick={() => onChange("statusCodes")}
        />
        <Item
          {...driveMode}
          label="Drive Mode"
          color={colors.brown[400]}
          onClick={() => onChange("driveMode")}
        />
        <Item
          {...alerts}
          label="Alerts"
          color={colors.red[400]}
          onClick={() => onChange("alerts")}
        />
        <Item
          {...recommendations}
          label="Recommendations"
          color={colors.blue[400]}
          onClick={() => onChange("recommendations")}
        />
      </div>
    </div>
  );
};

export default Legend;
