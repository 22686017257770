const setClassifications = ({state, action}) => {
  const {counts, series} = action.payload;

  return {
    ...state,
    classifications: {
      fetched: true,
      counts,
      series,
    },
  };
};

export default setClassifications;
