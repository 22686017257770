import {disconnect} from "socket";

import {createSwitchEpic} from "epics/create-epic";
import {navigateToLogin, setAuth, types} from "actions/auth";
import {show} from "actions/snackbar";
import removeCookie from "utils/remove-cookie";

export default createSwitchEpic([types.signOut], async ({payload}) => {
  const {snackbarMessage} = payload;

  removeCookie("id_token");

  disconnect();

  sessionStorage.clear();

  if (snackbarMessage) {
    return [
      show({message: snackbarMessage}),
      navigateToLogin(),
      setAuth({
        isAuthenticated: false,
      }),
    ];
  }

  return [
    setAuth({
      isAuthenticated: false,
    }),
  ];
});
