import React from "react";
import compose from "lodash/fp/compose";
import withStyles from "@material-ui/core/styles/withStyles";

import {ReactComponent as NoData} from "./no-data.svg";

const styles = ({typography, spacing, colors}) => ({
  message: {
    ...typography.caption,
    maxWidth: "calc(100% - 218px)",
    alignSelf: "start",
  },

  image: {
    width: 250,
    fill: colors.blue[200],
    position: "absolute",
    top: 0,
    right: 0,
  },
});

const enhance = compose(withStyles(styles));

const WellboreQualityNoData = ({classes}) => {
  return (
    <>
      <NoData className={classes.image} />
      <div className={classes.message}>
        Missing Downhole Information. Contact Customer Success for assistance.
      </div>
    </>
  );
};

export default enhance(WellboreQualityNoData);
