import React from "react";
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";

import AlertActionButtons from "components/alert-action-buttons";
import determineIndefiniteArticle from "utils/determine-indefinite-article";
import findAlert from "utils/alerts/find-alert";
import getDisplayName from "utils/user/get-display-name";

import FeedItem from "../feed-item";
import getEventAlertType from "../get-event-alert-type";

const mapStateToProps = (state, props) => ({
  alert: findAlert(state.alerts.all, props.event.value.alertId),
});

const Component = props => {
  const {alert, event} = props;

  const actions =
    alert && alert.status !== "resolved"
      ? {
          actions: (
            <Grid container justify="flex-end" spacing={0}>
              <AlertActionButtons alert={alert} />
            </Grid>
          ),
        }
      : {};

  const alertType = getEventAlertType(event);

  return (
    <FeedItem {...actions} event={event}>
      {getDisplayName(event)}
      {` has created ${determineIndefiniteArticle(alertType)} `}
      <strong>{alertType}</strong>
      {"."}
    </FeedItem>
  );
};

export default connect(mapStateToProps)(Component);
