import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = ({typography}) => ({
  label: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  title: {
    fontSize: typography.caption.fontSize,
    letterSpacing: ".05rem",
  },
  percentage: {
    fontSize: "2.125rem", // TODO: replace with `typography.h4.fontSize` when material-ui/core is updated
    letterSpacing: "-0.05rem",
    lineHeight: 1,
    paddingTop: 8,
    "& span": {
      fontSize: "1rem", // TODO: replace with `typography.subtitle1.fontSize` when material-ui/core is updated
      verticalAlign: "super",
    },
  },
  count: {
    fontSize: typography.caption.fontSize,
    letterSpacing: ".05rem",
    textTransform: "lowercase",
    opacity: 0.5,
  },
});

const ButtonLabel = ({classes, count, label, percentage, total}) => (
  <span className={classes.label}>
    <span className={classes.title}>{label}</span>
    <span className={classes.percentage}>
      {percentage}
      <span>%</span>
    </span>
    <span className={classes.count}>{`${count} of ${total}`}</span>
  </span>
);

export default withStyles(styles)(ButtonLabel);
