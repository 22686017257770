import {
  types,
  replaceTags,
  tagsHydrated,
  filterWellsByTag,
} from "actions/tagging";
import {search} from "actions/wells";
import {createSwitchEpic} from "epics/create-epic";
import {findWellTags} from "utils/tagging/find-well-tags";
import {normalizeTag} from "./normalize-tag";

export const rehydrateTags = createSwitchEpic(
  [types.rehydrateTags],
  async (_, store) => {
    const {
      wells: {
        allWellIds,
        search: {criteria},
        byWellId,
      },
    } = store.getState();

    const tags = findWellTags(allWellIds).map(tag =>
      normalizeTag(tag, allWellIds, byWellId),
    );

    return [
      replaceTags(tags),
      search({criteria, withRouting: false}),
      filterWellsByTag(),
      tagsHydrated(),
    ];
  },
);
