import moment from "moment";

export const validateSpeedRange = (min, max, safeOperatingParameters) => {
  const {
    minSpeed: {value: minSOP},
    maxSpeed: {value: maxSOP},
  } = safeOperatingParameters;

  if (min > max) return "Min must be below max spm";
  if (min < minSOP) return `Min must be above min SOP ${minSOP}`;
  if (max > maxSOP) return `Max must be above max SOP ${maxSOP}`;
};

const convertToMoment = ({hours, minutes}) => {
  return moment()
    .startOf("day")
    .add(hours, "hours")
    .add(minutes, "minutes");
};

export const validateDowntime = (downtime, safeOperatingParameters) => {
  if (!downtime) return;

  const {minDowntime, maxDowntime} = safeOperatingParameters;
  const minSOP = convertToMoment(minDowntime);
  const maxSOP = convertToMoment(maxDowntime);

  if (downtime.isBefore(minSOP)) return `${minSOP.format("HH:mm")} Minimum`;
  if (downtime.isAfter(maxSOP)) return `${maxSOP.format("HH:mm")} Maximum`;
  if (!downtime.isValid()) return "Please enter a valid time";
};
