import {types} from "./types";

export const deleteWebhook = options => {
  const {clientUserId, organizationId, webhookId} = options;

  return {
    type: types.deleteWebhook,
    payload: {
      clientUserId,
      organizationId,
      webhookId,
    },
  };
};
