import {LOCATION_CHANGE} from "react-router-redux";

import {types} from "actions/auth";
import {types as organizationTypes} from "actions/organizations";

import resetOnSignout from "utils/reducers/reset-on-signout";
import updateDeep from "utils/update-deep";

const auth = (
  state = {
    token: null,
    isAuthenticated: false,
    ignoreReset: true,
  },
  action,
) => {
  if (action.type === types.get) {
    return {
      ...state,
      isFetching: true,
    };
  }

  if (action.type === types.set) {
    let user;

    // Do not instantiate a user object if one does not exist in the payload
    // Otherwise, a user object is added to the store on sign out
    if (action.payload.user) {
      const assignedOrganizations =
        action.payload.user.assignedOrganizations || [];
      user = {
        ...action.payload.user,
        assignedOrganizations: assignedOrganizations.sort((a, b) =>
          (a.organizationName || "").toLowerCase() >
          (b.organizationName || "").toLowerCase()
            ? 1
            : -1,
        ),
      };
    } else if (!action.payload.isFetching) {
      // Clear sessionStorage on signOut
      sessionStorage.clear();
    }

    return {
      ...state,
      error: action.payload.error,
      isFetching: !!action.payload.isFetching,
      isAuthenticated: action.payload.isAuthenticated,
      token: action.payload.token,
      user,
    };
  }

  if (action.type === types.isCompletingSignIn) {
    return {
      ...state,
      completedSignIn: false,
      isCompletingSignIn: true,
    };
  }

  if (action.type === types.completedSignIn) {
    return {
      ...state,
      completedSignIn: true,
      isCompletingSignIn: false,
    };
  }

  if (action.type === LOCATION_CHANGE) {
    return {
      ...state,
      error: null,
    };
  }

  if (action.type === types.verifyOAuth) {
    return {
      ...state,
      termsOfServiceAcceptedThisSession: true,
    };
  }

  if (action.type === types.navigateToLogin) {
    return {
      ...state,
      authOrigin: document.location.hash.substr(
        1,
        document.location.hash.length,
      ),
    };
  }

  if (action.type === organizationTypes.setAccess) {
    if (action.payload.clientUserId === state.user.clientUserId) {
      return updateDeep(
        state,
        [
          "user",
          "assignedOrganizations",
          org => org.organizationId === action.payload.organizationId,
        ],
        organization => ({
          ...organization,
          accessLevel: action.payload.accessLevel,
          isInvitedUserApprover: action.payload.isInvitedUserApprover,
        }),
      );
    }
  }

  if (action.type === organizationTypes.setUserRemovedFromOrganization) {
    if (action.payload.clientUserId === state.user.clientUserId) {
      return updateDeep(
        state,
        "user.assignedOrganizations",
        assignedOrganizations =>
          assignedOrganizations.filter(
            org => org.organizationId !== action.payload.organizationId,
          ),
      );
    }
  }

  if (action.type === types.getInviteDetails) {
    return {
      ...state,
      isInvalidInviteKey: false,
    };
  }

  if (action.type === types.setInviteDetails) {
    return {
      ...state,
      invite: {
        availableUserTypes: action.payload.availableUserTypes,
        isInvalidInviteKey: action.payload.isInvalidInviteKey,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        mobilePhone: action.payload.mobilePhone,
        notificationMethods: action.payload.notificationMethods,
        type: action.payload.type,
        unitsOfMeasure: action.payload.unitsOfMeasure,
        userLogin: action.payload.userLogin,
      },
    };
  }

  return state;
};

export default resetOnSignout(auth);
