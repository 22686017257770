import noop from "lodash/noop";
import {actions} from "react-redux-form";
import store from "store";

import formPath from "./form-path";

export default (done = noop) => {
  store.dispatch(actions.setInitial(formPath));

  return done();
};
