import React from "react";
import {get, isEmpty, differenceBy} from "lodash";
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Button,
  FormHelperText,
  withStyles,
} from "@material-ui/core";

import PaperContainer from "components/paper-container";
import {OutlinedInput} from "components/outlined-input";

import validate from "./validate-manage-webhooks";

const style = () => ({
  table: {
    tableLayout: "fixed",
    "& th": {
      paddingTop: 22,
      paddingBottom: 22,
    },
  },
  row: {
    "& td": {
      paddingTop: 22,
      paddingBottom: 22,
      borderBottom: "none",
      "& p": {
        position: "absolute",
      },
    },
  },
});

const NEW_WEBHOOK_INIT_STATE = () => ({
  uri: "",
  secret: "",
  webhookId: "empty_new_webhook_input",
});

class ManageWebhooks extends React.Component {
  constructor(props) {
    super(props);

    // Flatten webhook array into object for easier access
    const initWebhooks = {};
    for (let i = 0; i < props.webhooks.length; i++) {
      initWebhooks[props.webhooks[i].webhookId] = props.webhooks[i];
    }

    this.state = {
      errors: {},
      webhooks: {...initWebhooks},
      lastSavedWebhooks: {...initWebhooks},
      newWebhook: NEW_WEBHOOK_INIT_STATE(),
      newHooks: 0,
    };
    this.addNewWebhookInput = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.webhooks.length !== this.props.webhooks.length) {
      const newWebhooks = differenceBy(
        this.props.webhooks,
        prevProps.webhooks,
        "webhookId",
      );
      const byId = {};

      // Translate array of webhooks into an object keyed by webhookId
      for (let i = 0; i < newWebhooks.length; i++) {
        const newWebhook = newWebhooks[i];
        byId[newWebhook.webhookId] = newWebhook;
      }

      this.setState(state => ({
        webhooks: {
          ...state.webhooks,
          ...byId,
        },
        lastSavedWebhooks: {
          ...state.lastSavedWebhooks,
          ...byId,
        },
      }));
    }
  }

  validateWebhook(webhook, callback = () => {}) {
    // Check validation
    const validationErrors = validate(
      webhook,
      Object.values(this.state.webhooks),
    );

    this.setState(
      state => ({
        errors: {
          ...state.errors,
          [webhook.webhookId]: validationErrors,
        },
      }),
      callback,
    );
  }

  onChange(event, webhook) {
    event.persist();

    const updatedWebhook = {
      ...webhook,
      [event.target.name]: event.target.value,
    };

    if (webhook.webhookId === NEW_WEBHOOK_INIT_STATE().webhookId) {
      this.setState({
        newWebhook: updatedWebhook,
      });
    } else {
      this.setState(state => ({
        webhooks: {
          ...state.webhooks,
          [webhook.webhookId]: updatedWebhook,
        },
      }));
      this.validateWebhook(updatedWebhook);
    }
  }

  onSave(webhook) {
    this.validateWebhook(webhook, () => {
      const {onSave} = this.props;
      const {errors} = this.state;

      // Save if valid
      if (isEmpty(errors[webhook.webhookId])) {
        // Set new webhook state as the last successfully saved state for diffing
        this.setState(state => ({
          lastSavedWebhooks: {...state.webhooks},
        }));

        // Dispatch save action
        if (webhook.webhookId !== NEW_WEBHOOK_INIT_STATE().webhookId) {
          // This is an existing webhook, send it along
          onSave(webhook);
        } else {
          // This is a new webhook, we need to prep the payload
          onSave({
            uri: webhook.uri,
            secret: webhook.secret,
          });

          // Clear out the input for new webhooks
          this.setState(state => ({
            newWebhook: NEW_WEBHOOK_INIT_STATE(),
            newHooks: (state.newHooks += 1),
          }));
        }
      }
    });
  }

  onDelete(webhook) {
    const {onDelete} = this.props;
    const {webhooks} = this.state;
    const updatedWebhooks = {...webhooks};
    delete updatedWebhooks[webhook.webhookId];

    onDelete(webhook);

    this.setState({webhooks: {...updatedWebhooks}});
  }

  isDirty(webhook) {
    const {lastSavedWebhooks} = this.state;

    const lastHook = Object.values(lastSavedWebhooks).find(
      lsw => lsw.webhookId === webhook.webhookId,
    );

    // new webhook, its dirty if it is filled in
    if (!lastHook) {
      return !isEmpty(webhook.uri) && !isEmpty(webhook.secret);
    }

    // old webhook, return its dirtiness
    return lastHook.uri !== webhook.uri || lastHook.secret !== webhook.secret;
  }

  render() {
    const {classes} = this.props;
    const {errors, webhooks, newWebhook} = this.state;

    return (
      <PaperContainer style={{marginTop: 8}}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell width="40%">External URI</TableCell>
              <TableCell width="30%">Secret</TableCell>
              <TableCell width="30%" />
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(webhooks).map(webhook => (
              <TableRow className={classes.row} key={webhook.webhookId}>
                <TableCell>
                  <OutlinedInput
                    type={"url"}
                    value={webhook.uri || ""}
                    name="uri"
                    autoComplete="new-password"
                    showLabel={false}
                    style={{width: 300}}
                    onChange={event => {
                      this.onChange(event, webhook);
                    }}
                    onKeyDown={event => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        if (this.isDirty(webhook)) this.onSave(webhook);
                      }
                    }}
                    disabled={webhook.new}
                  />
                  {get(errors, [webhook.webhookId, "uri"]) && (
                    <FormHelperText error>
                      {get(errors, [webhook.webhookId, "uri"])}
                    </FormHelperText>
                  )}
                </TableCell>
                <TableCell>
                  <OutlinedInput
                    type="password"
                    value={webhook.secret || ""}
                    name="secret"
                    autoComplete="new-password"
                    showLabel={false}
                    onChange={event => {
                      this.onChange(event, webhook);
                    }}
                    disabled={webhook.new}
                    onKeyDown={event => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        if (this.isDirty(webhook)) this.onSave(webhook);
                      }
                    }}
                  />
                  {get(errors, [webhook.webhookId, "secret"]) && (
                    <FormHelperText error>
                      {get(errors, [webhook.webhookId, "secret"])}
                    </FormHelperText>
                  )}
                </TableCell>
                <TableCell style={{textAlign: "end"}}>
                  <Button
                    color="primary"
                    disabled={webhook.new}
                    onClick={() => this.onDelete(webhook)}
                  >
                    Delete
                  </Button>
                  <Button
                    color="primary"
                    style={{color: "white"}}
                    variant="contained"
                    disabled={
                      webhook.new ||
                      !this.isDirty(webhook) ||
                      !isEmpty(get(errors, [webhook.webhookId]))
                    }
                    onClick={() => this.onSave(webhook)}
                  >
                    Save
                  </Button>
                </TableCell>
              </TableRow>
            ))}

            {/* Empty input for new webhooks */}

            <TableRow className={classes.row}>
              <TableCell>
                <OutlinedInput
                  type={"url"}
                  value={newWebhook.uri || ""}
                  name="uri"
                  autoComplete="new-password"
                  placeholder="https://www.example-webhook.com/"
                  showLabel={false}
                  style={{width: 300}}
                  onChange={event => {
                    this.onChange(event, newWebhook);
                  }}
                  onKeyDown={event => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      this.onSave(newWebhook);
                    }
                  }}
                  onBlur={() => {
                    this.validateWebhook(newWebhook);
                  }}
                />
                {get(errors, [newWebhook.webhookId, "uri"]) && (
                  <FormHelperText error>
                    {get(errors, [newWebhook.webhookId, "uri"])}
                  </FormHelperText>
                )}
              </TableCell>
              <TableCell>
                <OutlinedInput
                  type="password"
                  value={newWebhook.secret || ""}
                  name="secret"
                  autoComplete="new-password"
                  placeholder="Secret"
                  showLabel={false}
                  onChange={event => {
                    this.onChange(event, newWebhook);
                  }}
                  onKeyDown={event => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      this.onSave(newWebhook);
                    }
                  }}
                  onBlur={() => {
                    this.validateWebhook(newWebhook);
                  }}
                />
                {get(errors, [newWebhook.webhookId, "secret"]) && (
                  <FormHelperText error>
                    {get(errors, [newWebhook.webhookId, "secret"])}
                  </FormHelperText>
                )}
              </TableCell>
              <TableCell style={{textAlign: "end"}}>
                <Button
                  color="primary"
                  variant="contained"
                  style={{color: "white"}}
                  onClick={() => this.onSave(newWebhook)}
                >
                  Save
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </PaperContainer>
    );
  }
}

export default withStyles(style)(ManageWebhooks);
