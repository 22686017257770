import fromPairs from "lodash/fromPairs";

export default (organizationWells = {}, assignedWells = []) => {
  return fromPairs(
    Object.keys(organizationWells).map(downholeLocation => [
      organizationWells[downholeLocation].wellId,
      assignedWells.includes(downholeLocation),
    ]),
  );
};
