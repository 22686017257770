import React from "react";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

import {ReactComponent as Graph} from "./graph.svg";

const styles = () => ({
  vector: {
    height: "auto",
    marginBottom: 15,
    maxWidth: 400,
  },
  wrapper: {
    margin: "0 auto",
    maxWidth: 400,
    textAlign: "center",
  },
});

const DeclineEmpty = ({classes}) => {
  return (
    <div className={classes.wrapper}>
      <Graph className={classes.vector} />
      <Typography variant="h6">Insufficient Historical Data</Typography>
      <Typography variant="caption">
        We require a minimum of <b>30 days</b> of historical data on this well
        to display the contents of well surveillance. Check back when this well
        has been running for 30 days within our platform.
      </Typography>
    </div>
  );
};

export default withStyles(styles)(DeclineEmpty);
