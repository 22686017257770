import {types} from "./types";

/**
 * Creates a setSetting action.
 * @param {string} type - The type of import
 * @param {string} name - The name of the setting
 * @param {*} value - The value of the setting
 * @returns {{type: string, payload: {type: string, name: string, value: *}}}
 */
export const setSetting = ({type, name, value}) => {
  return {
    type: types.SET_SETTING,
    payload: {
      type,
      name,
      value,
    },
  };
};
