import {types} from "actions/alerts";

import addTimelineEvent from "./add-timeline-event";
import filter from "./filter";
import hide from "./hide";
import refreshFilters from "./refresh-filters";
import set from "./set";
import setOne from "./set-one";
import setTimeline from "./set-timeline";
import show from "./show";
import sort from "./sort";

const defaults = {
  activeFilter: "",
  all: [],
  filtered: [],
  selected: null,
  sortAsc: false,
  sortBy: "createdOn",
  staleFilters: false,
};

const actionMap = {
  [types.addTimelineEvent]: addTimelineEvent,
  [types.filter]: filter,
  [types.hide]: hide,
  [types.refreshFilters]: refreshFilters,
  [types.set]: set,
  [types.setOne]: setOne,
  [types.setTimeline]: setTimeline,
  [types.show]: show,
  [types.sort]: sort,
};

export default (state = defaults, action) => {
  const doAction = actionMap[action.type];
  return doAction ? doAction({state, action}) : state;
};
