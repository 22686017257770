import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import TextField from "components/forms/text-field";
import React from "react";
import {reduxForm} from "redux-form";

import compose from "utils/compose";
import FormHeader from "components/form-header";
import forms from "utils/forms";
import PaperContainer from "components/paper-container";
import SaveCancelButtons from "components/save-cancel-buttons";
import {saveMotorViolations} from "actions/wells";

import validate from "./validate";

const roundSetpoint = setpoint => {
  return {
    min: {
      ...setpoint.min,
      value: setpoint.min.value
        ? Number(setpoint.min.value).toFixed(2)
        : setpoint.min.value,
    },
    max: {
      ...setpoint.max,
      value: setpoint.max.value
        ? Number(setpoint.max.value).toFixed(2)
        : setpoint.max.value,
    },
  };
};

const mapStateToProps = (state, props) => {
  if (!props.well.motorViolations) return {};

  return {
    initialValues: {
      ...(props.displayCurrent
        ? roundSetpoint(props.well.motorViolations.currentSetpoint)
        : roundSetpoint(props.well.motorViolations.torqueSetpoint)),
    },
  };
};

class Write extends React.Component {
  save = values => {
    this.props.dispatch(
      saveMotorViolations({
        well: this.props.well,
        data: values,
      }),
    );

    this.props.onClose();
  };

  render() {
    const {
      dirty,
      displayCurrent,
      handleSubmit,
      invalid,
      onClose,
      pristine,
      reset,
      submitting,
    } = this.props;

    return (
      <PaperContainer extraPadded>
        <form onSubmit={handleSubmit(this.save)}>
          <Grid container direction="row" style={{maxWidth: 776}}>
            <Grid item xs={12}>
              <FormHeader>
                {displayCurrent ? "Current" : "Torque"} Violation Levels
              </FormHeader>
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="min"
                label="Min"
                component={forms.renderMaskedTextField}
                maskPattern="Decimal"
                showError={dirty}
                style={{marginRight: 24}}
              />
              <TextField
                name="max"
                label="Max"
                component={forms.renderMaskedTextField}
                maskPattern="Decimal"
                showError={dirty}
              />
            </Grid>
          </Grid>

          <SaveCancelButtons
            invalid={invalid}
            pristine={pristine}
            reset={compose(
              onClose,
              reset,
            )}
            submitting={submitting}
          />
        </form>
      </PaperContainer>
    );
  }
}

const Component = reduxForm({
  form: "motor-violation-configuration-form",
  enableReinitialize: true,
  validate,
})(Write);

export default connect(mapStateToProps)(Component);
