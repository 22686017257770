import isEmpty from "lodash/isEmpty";
import last from "lodash/last";

import hasGeneratedCardsEvent from "utils/well/has-generated-cards-event";

const hasCards = ({cards, generatedCards, rawCards}) =>
  !isEmpty(cards) || !isEmpty(generatedCards) || !isEmpty(rawCards);

const sortAndFilterCards = eventsByType => {
  const {cards, generatedCards, rawCards} = eventsByType;

  return [
    ...(cards || []),
    ...(generatedCards || []),
    ...(rawCards || []).filter(
      event => !hasGeneratedCardsEvent(event, eventsByType),
    ),
  ].sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn));
};

export default well => {
  if (!well || !well.eventsByType || !hasCards(well.eventsByType)) {
    return null;
  }

  return last(sortAndFilterCards(well.eventsByType));
};
