import React from "react";
import {connect} from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import TagPlusIcon from "mdi-material-ui/TagPlus";
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "lodash/fp/compose";

import {ClickEvent} from "components/analytics";
import {eventCategory, eventAction} from "constants/analytics";
import TextTooltip from "components/material-tooltip";
import {
  toggleManageTagsDialog,
  clearSelectedWells,
  toggleWellSelected,
} from "actions/tagging";
import AsMenuItem from "components/as-menu-item";

const styles = ({colors}) => ({
  icon: {
    color: colors.warmGrey[300],
  },
});

const mapDispatchToProps = dispatch => {
  return {
    manageTags: downholeLocation => () => {
      dispatch(clearSelectedWells());
      dispatch(toggleWellSelected([downholeLocation]));
      dispatch(toggleManageTagsDialog());
    },
  };
};

const ManageTags = ({
  classes,
  wellId,
  manageTags,
  label = false,
  isMenuItem,
}) => {
  return (
    <ClickEvent
      category={eventCategory.tags}
      action={eventAction.manageView}
      label="Opened -- Well Level"
    >
      <AsMenuItem isMenuItem={isMenuItem} onClick={manageTags(wellId)}>
        {label ? (
          <>
            <IconButton>
              <TagPlusIcon className={classes.icon} />
            </IconButton>
            <p>Tags</p>
          </>
        ) : (
          <TextTooltip title="Manage tags" placement="bottom">
            <IconButton>
              <TagPlusIcon className={classes.icon} />
            </IconButton>
          </TextTooltip>
        )}
      </AsMenuItem>
    </ClickEvent>
  );
};

const enhance = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withStyles(styles),
);

export default enhance(ManageTags);
