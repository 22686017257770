import React from "react";
import Divider from "@material-ui/core/Divider";

import colors from "theme/colors";

const getDefaultStyle = () => {
  return {
    backgroundColor: colors.ambyBlue[400],
  };
};

export default props => {
  return <Divider style={{...getDefaultStyle(props), ...props.style}} />;
};
