import {connect} from "react-redux";
import {push} from "react-router-redux";
import React, {useState} from "react";
import compose from "lodash/fp/compose";
import Paper from "@material-ui/core/Paper";
import ReactList from "react-list";
import get from "lodash/get";

import {eventCategory, eventAction} from "constants/analytics";
import {sendAnalyticsEvent} from "components/analytics";
import {toggleWellSelected} from "actions/tagging";

import {renderWellRow} from "./render-well-row";
import {renderWellTable} from "./render-well-table";
import {isSelectionMode} from "../../is-selection-mode";

const navigateToWell = ({dispatch, downholeLocation}) => {
  sendAnalyticsEvent(
    eventCategory.allWellsWorkflow,
    eventAction.wellList,
    "View Well",
  );

  dispatch(push(`/well/${encodeURIComponent(downholeLocation)}/analysis`));
};

const selectWell = ({dispatch, wellId}) => {
  dispatch(toggleWellSelected([wellId]));
};

const mapStateToProps = state => ({
  isSelectionMode: isSelectionMode(state),
  unitsOfMeasure: get(state, "auth.user.unitsOfMeasure", ""),
});

const mapDispatchToProps = dispatch => ({
  handleWellRowClick: ({isSelectionMode, downholeLocation, wellId}) => () =>
    isSelectionMode
      ? selectWell({dispatch, wellId})
      : navigateToWell({dispatch, downholeLocation}),
});

const WellList = ({
  wells,
  isSelectionMode,
  handleWellRowClick,
  unitsOfMeasure,
}) => {
  const [currentHoverId, setCurrentHoverId] = useState(null);

  const itemRenderer = renderWellRow({
    handleWellRowClick: handleWellRowClick,
    wells,
    isSelectionMode,
    currentHoverId,
    setCurrentHoverId,
    unitsOfMeasure,
  });

  const itemsRenderer = renderWellTable({
    wellIds: wells.map(({wellId}) => wellId),
    isSelectionMode,
    unitsOfMeasure,
  });

  return (
    <Paper>
      <ReactList
        itemRenderer={itemRenderer}
        itemsRenderer={itemsRenderer}
        length={wells.length}
        type="simple"
      />
    </Paper>
  );
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(WellList);
