import get from "lodash/get";
import trim from "lodash/trim";
import upperFirst from "lodash/upperFirst";

const format = string => trim(upperFirst(string));

export default user => {
  user = get(user, "user", user) || {};

  let {firstName, lastName} = user;

  firstName = format(firstName);
  lastName = format(lastName);

  if (firstName && lastName) return `${firstName} ${lastName}`;
  if (firstName) return firstName;

  return lastName || "";
};
