import {types as alertTypes} from "actions/alerts";
import {updateFilter} from "actions/paging";
import {replace} from "react-router-redux";
import mergeQuerystrings from "utils/merge-querystrings";
import PAGING_SOURCES from "constants/paging-sources";

export default (action$, store) => {
  return action$.ofType(alertTypes.filter).switchMap(({payload}) => {
    const {filter} = payload;
    const state = store.getState();

    if (filter !== state.paging.filter) {
      const updatedParams = {
        filter: encodeURIComponent(filter),
        source: PAGING_SOURCES.alerts,
      };

      return [
        replace(mergeQuerystrings(state, updatedParams)),
        updateFilter(updatedParams),
      ];
    }

    return [];
  });
};
