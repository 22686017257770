import {push} from "react-router-redux";
import {connect} from "react-redux";
import get from "lodash/get";

import {hasFullAccess} from "utils/organizations/has-full-access";
import {toggleWellSelected} from "actions/tagging";
import {
  fetchMiniCardById,
  fetchLatestMiniCard,
  fetchCardOverride,
  setCardOverride,
} from "actions/wells";

import {isSelectionMode} from "../is-selection-mode";

const getRedirectLocation = (well, cardIds = []) =>
  `/well/${encodeURIComponent(
    well.downholeLocation,
  )}/analysis?${cardIds.length > 0 && `comps=${cardIds.join(",")}`}`;

const mapStateToProps = (state, {workflow}) => ({
  workflow: workflow || state.wells.workflow.selected,
  isSelectionMode: isSelectionMode(state),
  selectedWells: state.tags.selectedWells,
  hasFullAccess: hasFullAccess(
    get(state, "auth.user.assignedOrganizations", []),
    get(state, "organizationView.currentOrganization"),
  ),
});

const doRedirectTo = (dispatch, location) => {
  dispatch(push(location));
};

const doToggleWellSelected = (dispatch, downholeLocation) => {
  dispatch(toggleWellSelected(downholeLocation));
};

const handleCardClick = ({dispatch, well, isSelectionMode, cardIds}) => {
  isSelectionMode
    ? doToggleWellSelected(dispatch, [well.wellId])
    : doRedirectTo(dispatch, getRedirectLocation(well, cardIds));
};

const mapDispatchToProps = dispatch => ({
  fetchMiniCardById: (downholeLocation, cardId) => {
    dispatch(fetchMiniCardById({downholeLocation, cardId}));
  },
  fetchLatestMiniCard: downholeLocation => {
    dispatch(fetchLatestMiniCard({downholeLocation}));
  },
  fetchCardOverride: (downholeLocation, cardId) => {
    dispatch(fetchCardOverride({downholeLocation, cardId}));
  },
  setCardOverride: (downholeLocation, cardId, overrideType) => {
    dispatch(setCardOverride({downholeLocation, cardId, overrideType}));
  },
  handleCardClick: ({well, isSelectionMode, cardIds}) => () =>
    handleCardClick({dispatch, well, isSelectionMode, cardIds}),
  handleHeaderClick: ({wellId}) => event => {
    event.stopPropagation(); // This is important so that the parent row click handler does not trigger when checkbox is clicked directly
    doToggleWellSelected(dispatch, [wellId]);
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
);
