import {types} from "actions/tubing";

const defaultValues = {search: []};

const filterPerWord = filter => spec => {
  if (!filter) {
    return true;
  }

  const description = spec.description.toLowerCase();

  return filter
    .split(" ")
    .reduce((isMatch, word) => isMatch && description.indexOf(word) >= 0, true);
};

export default (state = defaultValues, action) => {
  if (action.type === types.set) {
    return {
      ...state,
      all: action.payload.data,
    };
  }

  if (action.type === types.filter) {
    const exactMatch = state.all.filter(
      spec => spec.description === action.payload.phrase,
    );

    return {
      ...state,
      filtered:
        exactMatch.length === 1
          ? []
          : state.all.filter(
              filterPerWord(action.payload.phrase.toLowerCase()),
            ),
    };
  }

  return state;
};
