/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Grid from "@material-ui/core/Grid";
import Step from "@material-ui/core/Step";
import Stepper from "@material-ui/core/Stepper";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import FormButton from "components/form-button";

class VerticalLinearStepper extends React.Component {
  state = {
    activeStep: 0,
    nextDisabled: false,
  };

  goToStep = step => {
    this.setState({activeStep: step});
  };

  disableNext = () => this.setState({nextDisabled: true});

  handleReset = () => {
    this.setState({
      activeStep: 0,
      nextDisabled: false,
    });
  };

  render() {
    const {steps} = this.props;
    const {activeStep} = this.state;

    const nextButtonText =
      steps[activeStep].nextButtonText ||
      (activeStep === steps.length - 1 ? "Finish" : "Next");

    return (
      <div style={{minWidth: "450px"}}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, i) => {
            const nextStep = i < steps.length - 1 ? i + 1 : i;
            const previousStep = i > 0 ? i - 1 : 0;

            return (
              <Step key={step.label}>
                <StepLabel>
                  <div style={{fontWeight: "bold"}}>
                    {i < activeStep ? (
                      <a
                        onClick={() =>
                          step.handleLabel
                            ? step.handleLabel(() => this.goToStep(i))
                            : this.goToStep(i)
                        }
                        style={{cursor: "pointer"}}
                      >
                        {step.label}
                      </a>
                    ) : (
                      step.label
                    )}
                  </div>
                </StepLabel>
                <StepContent>
                  <Grid container style={{marginTop: "4px"}}>
                    <Grid item xs={12}>
                      {step.content}
                    </Grid>
                  </Grid>
                  <Grid container justify="flex-end">
                    <Grid item>
                      <FormButton
                        center
                        disabled={activeStep === 0}
                        onClick={() =>
                          step.handleBack
                            ? step.handleBack(() => this.goToStep(previousStep))
                            : this.goToStep(previousStep)
                        }
                      >
                        Back
                      </FormButton>
                      <FormButton
                        center
                        color="primary"
                        disabled={this.state.nextDisabled}
                        onClick={() =>
                          step.handleNext
                            ? step.handleNext(
                                () => this.goToStep(nextStep),
                                this.disableNext,
                              )
                            : this.goToStep(nextStep)
                        }
                      >
                        {nextButtonText}
                      </FormButton>
                    </Grid>
                  </Grid>
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0}>
            <Typography>All steps completed - you&quot;re finished</Typography>
            <Button onClick={this.handleReset}>Reset</Button>
          </Paper>
        )}
      </div>
    );
  }
}

export default VerticalLinearStepper;
