import React from "react";

import SettingsUpdated from "../settings-updated";

export default props => {
  return (
    <SettingsUpdated
      {...props}
      changesRoute="/setup/downhole"
      settingsName="downhole information"
    />
  );
};
