import {of} from "rxjs/observable/of";

import removeCookie from "utils/remove-cookie";

import {setAuth, types} from "actions/auth";

export default action$ => {
  return action$.ofType(types.signInWithAzure).switchMap(() => {
    removeCookie("id_token");

    window.location.href = `${process.env.REACT_APP_API}/login/azure`;

    return of(
      setAuth({
        isAuthenticated: false,
        isFetching: true,
      }),
    );
  });
};
