import {RECENTLY_VISITED_WELLS} from "constants/local-storage-keys";

export const setRecentlyVisitedWells = (
  userId,
  organizationId,
  recentWells,
) => {
  const recentWellsAllUsers = JSON.parse(
    localStorage.getItem(RECENTLY_VISITED_WELLS) || "{}",
  );

  if (recentWellsAllUsers[userId]) {
    recentWellsAllUsers[userId][organizationId] = recentWells;
  } else {
    recentWellsAllUsers[userId] = {};
    recentWellsAllUsers[userId][organizationId] = recentWells;
  }

  localStorage.setItem(
    RECENTLY_VISITED_WELLS,
    JSON.stringify(recentWellsAllUsers),
  );
};
