import React from "react";
import isEmpty from "lodash/isEmpty";
import pure from "recompose/pure";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

import Avatar from "components/avatar";
import PaperContainer from "components/paper-container";

import {
  defaultContainerStyle,
  defaultGridContainer,
  defaultTitleStyle,
} from "./dashboard-styles";

const onCallList = (users = []) => {
  if (isEmpty(users)) return null;

  return (
    <List dense>
      {users.map(d => (
        <ListItem key={d.clientUserId} divider={users.length > 1}>
          <ListItemIcon>
            <Avatar size={30} user={d} />
          </ListItemIcon>
          <ListItemText
            primary={d.name}
            secondary={`${d.email} ${d.mobilePhone || ""}`}
          />
        </ListItem>
      ))}
    </List>
  );
};

const OnCallUsersDisplay = props => {
  const {well} = props;
  const users = well.onCall
    ? well.onCall.filter(u => u.email.indexOf("@ambyint.com") === -1)
    : [];

  return (
    <PaperContainer style={defaultContainerStyle}>
      <div
        style={{
          ...defaultGridContainer,
          ...{
            padding: isEmpty(users) ? 16 : "16px 16px 8px 16px",
          },
        }}
      >
        <Typography variant="body1" style={{...defaultTitleStyle}}>
          {users.length} On Call Users
        </Typography>
      </div>
      {onCallList(users)}
    </PaperContainer>
  );
};

export default pure(OnCallUsersDisplay);
