import isNil from "lodash/isNil";
import {min as d3min, max as d3max} from "d3-array";
import {scaleLinear as d3scaleLinear} from "d3-scale";

import addArea from "utils/charts/add-area";
import addLine from "utils/charts/add-line";
import colors from "theme/colors";
import valueOf from "utils/value-of";

function mapData(d) {
  return d.deflection || [];
}

function mapTime(d) {
  return new Date(d.createdOn);
}

function mapDeflection(d) {
  return valueOf(d.value);
}

function mapMaxDeflection(d) {
  if (isNil(d.max)) {
    return mapDeflection(d);
  }

  return valueOf(d.max);
}

function mapMinDeflection(d) {
  if (isNil(d.min)) {
    return mapDeflection(d);
  }

  return valueOf(d.min);
}

const styles = {
  area: {
    fill: colors.deflection[100],
  },

  line: {
    stroke: colors.deflection[400],
  },
};

export default ({clipPathId, selection, register, scales}) => {
  const degreesScale = d3scaleLinear();
  const options = {
    clipPathId,
    mapData,
    mapX: mapTime,
    mapY: mapDeflection,
    mapMaxY: mapMaxDeflection,
    mapMinY: mapMinDeflection,
    selection,
    xScale: scales.time,
    yScale: degreesScale,
  };

  const area = addArea({...options, styles: styles.area});
  const line = addLine({...options, styles: styles.line});

  return register({
    draw(props) {
      const {config, data} = props;
      const minDeflection = d3min(mapData(data), mapMinDeflection);
      const maxDeflection = d3max(mapData(data), mapMaxDeflection);
      const difference = maxDeflection - minDeflection;

      // Deflection shouldn't be above maybe 2 or 3 degrees. If it is just a few degrees, we want the graph to look small, so set a minimum scale size...
      const minScaleDeflection = 5;
      // ...but we also want to allow it to scale up to 360 degrees if things are way off..
      const maxScaleDeflection = 360;

      degreesScale
        .domain([
          0,
          Math.min(
            maxScaleDeflection,
            Math.max(minScaleDeflection, maxDeflection + difference * 0.1),
          ),
        ])
        .range([props.height, 0]);

      area.hide();
      line.hide();

      if (config.deflection && config.deflection.state > 0) {
        line.show();
      }

      if (config.deflection && config.deflection.state === 2) {
        area.show();
      }

      area.draw(props);
      line.draw(props);
    },

    zoom(props) {
      area.zoom(props);
      line.zoom(props);
    },
  });
};
