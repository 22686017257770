import React from "react";
import compose from "lodash/fp/compose";
import Popover from "@material-ui/core/Popover";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import {connect} from "react-redux";
import {push} from "react-router-redux";

import colors from "@ambyint/common/colors";

import interruptActionOnDirtyForm from "utils/interrupt-action-on-dirty-form";
import Avatar from "components/avatar";
import {MAX_RECENT_ORGANIZATIONS} from "constants/recent-organizations";
import {signOut} from "actions/auth";
import {getVersion} from "utils/get-version";
import {getRecentOrganizations} from "utils/recent-organizations";
import {ClickEvent} from "components/analytics";
import {eventAction, eventCategory} from "constants/analytics";
import {toggleOrganizationSelectionDialog} from "actions/organization-view";

import RecentOrgsList from "./recent-orgs-list";

const styles = ({colors: themeColors}) => ({
  popover: {
    top: "80px !important",
    width: 300,
    maxWidth: 300,
  },
  popoverHeaderContainer: {
    display: "flex",
    minHeight: 145,
  },
  popoverHeader: {
    wordBreak: "break-all",
    backgroundColor: colors.darkGrey,
    display: "grid",
    gridTemplateColumns: "90px auto",
    gridTemplateRows:
      "minmax(45px, auto) minmax(40px, auto) minmax(50px, auto)",
    width: 300,
  },
  gridLeft: {
    gridColumn: 1,
    gridRow: "1 / 4",
  },
  gridRight: {
    gridColumn: 2,
    paddingLeft: 24,
  },
  gridTop: {
    gridRow: 1,
  },
  gridBottom: {
    gridRow: 2,
  },
  recentsHeader: {
    padding: "8px 16px",
    borderBottom: "1px solid #eee",
  },
  recentOrgsContainer: {
    borderBottom: "1px solid #eee",
  },
  actionContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row-reverse",
    padding: "24px 16px",
  },
  accountButton: {
    gridRow: 3,
    marginLeft: 24,
  },
  accountText: {
    color: "#fff",
  },
  name: {
    color: "white",
    paddingTop: 24,
  },
  email: {
    color: themeColors.warmGrey[200],
  },
  versionContainer: {
    backgroundColor: colors.darkGrey,
    display: "flex",
    justifyContent: "space-around",
  },
  version: {
    fontSize: 10,
    color: themeColors.warmGrey[50],
  },
});

const mapDispatchToProps = dispatch => ({
  onSignOut: () => dispatch(interruptActionOnDirtyForm(signOut())),
  gotoProfile: () => dispatch(push("/profile/general")),
  openOrgSelector: () => dispatch(toggleOrganizationSelectionDialog()),
});

const enhance = compose(
  withStyles(styles),
  connect(
    null,
    mapDispatchToProps,
  ),
);

const UserMenuPopover = ({
  open,
  onClose,
  onSignOut,
  anchorEl,
  classes,
  user,
  gotoProfile,
  currentOrganization,
  openOrgSelector,
}) => {
  const assignedOrgs = user.assignedOrganizations;
  const totalAssignedOrgs = assignedOrgs.length;

  // Recent organizations that meet the following criteria:
  // 1. The user is still assigned to the organization
  // 2. The organization is not the currently active one (we don't want to display it twice)
  const assignedRecentOrgs = getRecentOrganizations(user.clientUserId)
    .map(orgId =>
      assignedOrgs.find(assignedOrg => assignedOrg.organizationId === orgId),
    )
    .filter(item => !!item)
    .filter(
      ({organizationId}) =>
        organizationId !== currentOrganization.organizationId,
    )
    .slice(0, MAX_RECENT_ORGANIZATIONS - 1);

  const closeThenGotoProfile = () => {
    onClose();
    gotoProfile();
  };

  return (
    <Popover
      classes={{paper: classes.popover}}
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom",
      }}
      transformOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
      onClose={onClose}
      anchorEl={anchorEl}
      open={open}
    >
      <div className={classes.popoverHeaderContainer}>
        <div className={classes.popoverHeader}>
          <Avatar
            style={{margin: 0, marginLeft: 16, marginTop: 24}}
            className={clsx(classes.avatar, classes.gridLeft)}
            size={90}
            user={user}
          />
          <Typography
            className={clsx(classes.name, classes.gridRight, classes.gridTop)}
            variant="subtitle2"
          >
            {user.name}
          </Typography>
          <Typography
            className={clsx(
              classes.email,
              classes.gridRight,
              classes.gridBottom,
            )}
            variant="caption"
          >
            {user.email}
          </Typography>
          <div style={{display: "inline-block"}}>
            <ClickEvent
              category={eventCategory.userPopout}
              action={eventAction.navigate}
              label="My Account"
            >
              <Button
                classes={{
                  root: classes.accountButton,
                  label: classes.accountText,
                }}
                color="primary"
                variant="contained"
                size="small"
                onClick={closeThenGotoProfile}
              >
                My Account
              </Button>
            </ClickEvent>
          </div>
        </div>
      </div>
      {assignedRecentOrgs.length > 0 && (
        <div className={classes.recentsHeader}>
          <Typography variant="caption">Recently Viewed</Typography>
        </div>
      )}
      <div className={classes.recentOrgsContainer}>
        <RecentOrgsList
          onClose={onClose}
          organizations={assignedRecentOrgs}
          currentOrganization={currentOrganization}
          totalAssignedOrgs={totalAssignedOrgs}
          userId={user.clientUserId}
        />
      </div>
      <div className={classes.actionContainer}>
        {/* NOTE: `row-reverse` is applied to this container to force
            the sign out button to stick to the end of the container
        */}
        <ClickEvent
          category={eventCategory.userPopout}
          action={eventAction.navigate}
          label="Sign Out"
        >
          <Button variant="outlined" onClick={onSignOut}>
            Sign Out
          </Button>
        </ClickEvent>
        {assignedOrgs.length > 1 && (
          <ClickEvent
            category={eventCategory.userPopout}
            action={eventAction.toggle}
            label="More Organizations"
          >
            <Button
              onClick={() => {
                onClose();
                openOrgSelector();
              }}
              variant="outlined"
            >
              More&hellip;
            </Button>
          </ClickEvent>
        )}
      </div>
      <div className={classes.versionContainer}>
        <p className={classes.version}>{getVersion()}</p>
      </div>
    </Popover>
  );
};

export default enhance(UserMenuPopover);
