import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const PumpOff = ({className}) => {
  return (
    <SvgIcon className={className} viewBox="0 0 227 133">
      <path
        d="M223.227 15.307H146.696c-18.335 0-30.487 7.515-37.971 21.826-5.478 10.475-7.692 20.689-11.108 45.078l-.04.284c-2.899 20.692-4.682 29.612-8.505 38.728-2.482 5.919-8.219 8.277-20.183 8.277-5.462 0-22.451-.065-50.955-.195-7.982-.037-14.434-6.518-14.434-14.5v-96.64c0-8.009 6.492-14.5 14.5-14.5h174.4c.947 0 1.388-.011 3.934-.084 4.97-.143 7.875-.116 11.078.22 9.294.973 14.596 4.28 15.815 11.506z"
        stroke="currentColor"
        strokeWidth={7}
        fill="none"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export default PumpOff;
