import {types} from "./types";

export const setRankings = rankings => {
  return {
    type: types.setRankings,
    payload: {
      rankings,
    },
  };
};
