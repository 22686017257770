import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import round from "utils/round";

import BaseSetpointAxis from "./base-setpoint-axis";
import {ReactComponent as Icon} from "./timer.svg";

const styles = ({typography, colors}) => ({
  data: {
    ...typography.subtitle1,
    margin: 0,
  },

  unit: {
    ...typography.caption,
    color: colors.warmGrey[500],
    marginLeft: "3px",
  },
});

const TooltipScheduledDowntime = ({
  tooltipData: {createdOn, data},
  classes,
}) => {
  return (
    <BaseSetpointAxis createdOn={createdOn} Icon={Icon}>
      <span className={classes.data}>{round(data.hours)}</span>
      <span className={classes.unit}>hrs </span>
      <span className={classes.data}>{round(data.minutes)}</span>
      <span className={classes.unit}>min</span>
    </BaseSetpointAxis>
  );
};

export default withStyles(styles)(TooltipScheduledDowntime);
