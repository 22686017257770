import get from "lodash/get";

import {position} from "@ambyint/common/unit-converter";
import getUnits from "utils/get-units";
import valueOf from "utils/value-of";

export const parse = ({values, unitsOfMeasure}) => {
  const units = getUnits(unitsOfMeasure);
  const deadbandUnits = get(values, "deadband.units");

  let deadband;

  // Accepted Deadband units can change depending on whether the well is in Downhole or Surface
  // Mode:
  // • Downhole Mode accepts a percentage
  // • Surface Mode accepts a measurement, converted to Meters
  if (deadbandUnits === "%") {
    deadband = Number(valueOf(values.deadband));
  } else {
    deadband = position.convert({
      from: units.position,
      to: "m",
      value: valueOf(values.deadband),
    });
  }

  return {
    deadband,
    speedChangeStrokeDelay: Number(valueOf(values.speedChangeStrokeDelay)),
  };
};
