import {add, addTimelineEvent, update} from "actions/alerts";
import findAlert from "utils/alerts/find-alert";
import hasAlertAccess from "utils/alerts/has-alert-access";

export default (socket, store) => {
  socket.on("alert::added", payload => {
    const user = store.getState().auth.user || null;
    if (hasAlertAccess(payload, user))
      return store.dispatch(add({data: payload}));
  });

  socket.on("alert::updated", payload => {
    const alerts = store.getState().alerts;
    if (findAlert(alerts.all, payload.alertId))
      return store.dispatch(update({data: payload}));
  });

  socket.on("alert::resolved", payload => {
    const alerts = store.getState().alerts;
    if (findAlert(alerts.all, payload.alertId))
      return store.dispatch(update({data: payload}));
  });

  socket.on("alert::event::added", payload => {
    const alerts = store.getState().alerts;
    if (findAlert(alerts.all, payload.alertId))
      return store.dispatch(addTimelineEvent({data: payload}));
  });
};
