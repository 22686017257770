export default ({state, action}) => {
  return {
    ...state,
    fetched: true,
    all: {
      ...state.all,
      [action.payload.organizationId]: action.payload.data,
    },
  };
};
