import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = ({colors}) => ({
  div: {
    textAlign: "center",
    width: "100%",
  },
  icon: {
    width: 155,
    height: 155,
    marginBottom: 25,
    color: colors.warmGrey[100],
  },
});

const Watermark = ({classes}) => {
  return (
    <div className={classes.div}>
      <SvgIcon className={classes.icon} viewBox="0 0 100 125">
        <path d="M57.849,30.116h-4.418V24.27h4.418V30.116z M33.396,70.114v5.556h4.429v-5.556c0-1.72,1.398-3.119,3.118-3.119h4.162v-4.428  h-4.162C36.781,62.567,33.396,65.953,33.396,70.114z M55.437,48.66h-6.336v5.514h6.336V48.66z M17.634,69.08v-3.514h-6.515v3.514  H17.634z M95.541,77.535v2h-90.5v-2h20.541h1V41.108c0-3.766,3.063-6.83,6.83-6.83h7.859c3.767,0,6.83,3.064,6.83,6.83v20.459  h-1.997v-0.683h-1v0.683h-4.162c-4.713,0-8.547,3.834-8.547,8.547v5.556h-1.083v1h8.531v-1h-1.02v-5.556  c0-1.169,0.95-2.119,2.118-2.119h4.162v0.729h1v-0.729h1.997v9.54h8.336V48.14c0-3.768,3.065-6.833,6.833-6.833h7.861  c3.768,0,6.833,3.065,6.833,6.833v29.396h1H95.541z M46.104,43.102h-1v13.188h1V43.102z M41.271,37.275  c0.959,0,1.876,0.357,2.585,1.005c0.793,0.725,1.248,1.756,1.248,2.828h1c0-1.353-0.573-2.652-1.573-3.566  c-0.894-0.817-2.051-1.267-3.26-1.267V37.275z M75.965,68.14h-1v3.618h1V68.14z M75.965,48.14c0-1.606-0.797-3.104-2.132-4.006  l-0.561,0.828c1.06,0.716,1.692,1.905,1.692,3.178v17.618h1V48.14z M19.396,75.491h-1.762v-4.533h0.809v-1h-8.171v1h0.848v4.533  H9.417v1h9.979V75.491z M79.996,67.631h1.089c1.168,0,2.118,0.95,2.118,2.119v5.747h-1.684v1h9.839v-1h-1.727V69.75  c0-4.713-3.834-8.547-8.547-8.547h-1.089v-1.156h-1v8.672h1V67.631z M58.849,29.517h5.743c0.281,0,0.52,0.238,0.52,0.52v9.249  h-2.217v1h2.217v0.001h4.25v-0.001h2.219v-1h-2.219v-9.249c0-2.63-2.14-4.77-4.77-4.77h-5.743V29.517z M35.032,33.287h4.26v-0.001  h2.211v-1h-2.211v-2.249c0-0.287,0.229-0.52,0.51-0.52h12.629v-4.25H39.802c-2.63,0-4.77,2.14-4.77,4.77v2.249h-2.215v1  L35.032,33.287L35.032,33.287z M56.14,21.016h-1v2.102v0.492h1v-0.492V21.016z M60.807,19.207h-10.25v1h10.25V19.207z   M18.442,63.599h-0.809v-5.453c0-2.259,1.837-4.097,4.096-4.097h2.825v1v0.357h1v-9.169h-1v1.298H21.73  c-5.851,0-10.61,4.76-10.61,10.611v5.453h-0.848v1h8.171V63.599z M55.422,68.58v-7.518h-1v1.505h-8.317v4.428h8.317v1.585H55.422z" />
      </SvgIcon>
    </div>
  );
};

export default withStyles(styles)(Watermark);
