import compose from "lodash/fp/compose";
import get from "lodash/fp/get";
import isMatch from "lodash/fp/isMatch";
import map from "lodash/fp/map";
import pick from "lodash/fp/pick";
import round from "lodash/round";

import {isSpeedRangeEnabled} from "./is-speed-range-enabled";

const roundFP = val => round(val, 1);

const isDefault = compose(
  isMatch([0.1, 15]),
  map(roundFP),
);

const formatSOP = compose(
  map(get("value")),
  pick(["minSpeed", "maxSpeed"]),
  get("safeOperatingParameters"),
);

const formatSPM = compose(
  map(get("value")),
  pick(["min", "max"]),
  get("operation.speedRange"),
);

const createSOP = ([min, max]) => {
  const newMaxSOP = max > 14 ? 15 : max + 1;
  const newMinSOP = min >= 15 ? 15 : min;

  return [newMinSOP, newMaxSOP].map(roundFP);
};

export const findSmartSOP = well => {
  const speedRange = formatSPM(well);

  const formatedSOP = formatSOP(well);

  const shouldCreateSOP =
    isDefault(formatedSOP) && speedRange.length && !isSpeedRangeEnabled(well);

  return shouldCreateSOP ? createSOP(speedRange) : formatedSOP;
};
