import React from "react";
import orderBy from "lodash/orderBy";
import Paper from "@material-ui/core/Paper/Paper";
import ReactList from "react-list";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TableRow from "@material-ui/core/TableRow";

import TableHeaderCell from "components/table-header-cell";
import {useSorting} from "hooks/setpoint-automation/use-sorting";

import renderWellRow from "./render-well-row";

const useStyles = makeStyles({
  tableRow: {
    borderLeft: "5px solid transparent",
  },
});

const WellTable = ({wells, limitedAccess}) => {
  const classes = useStyles();
  const {sortWells, sortOptions} = useSorting();
  const [sortBy, sortDirection] = sortOptions;

  const sortedWells = orderBy(wells, sortBy, sortDirection);

  return (
    <ReactList
      useStaticSize
      itemRenderer={renderWellRow(sortedWells, limitedAccess)}
      itemsRenderer={(items, ref) => (
        <Paper>
          <Table>
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableHeaderCell
                  onClick={sortWells("displayName")}
                  sortBy={sortBy}
                  sortAsc={sortDirection === "asc"}
                  width="33%"
                  label="Well Name"
                  field="displayName"
                />
                <TableHeaderCell width="7%" label="" field="field" />
                <TableHeaderCell
                  onClick={sortWells("operation.speedRange.max.value")}
                  sortBy={sortBy}
                  sortAsc={sortDirection === "asc"}
                  width="40%"
                  label="Max SPM / SOP"
                  field="operation.speedRange.max.value"
                />
                <TableHeaderCell width="20%" label="" field="field" />
              </TableRow>
            </TableHead>
            <TableBody ref={ref}>{items}</TableBody>
          </Table>
        </Paper>
      )}
      length={sortedWells.length}
      type="simple"
    />
  );
};

export default WellTable;
