import React from "react";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import clsx from "clsx";

const useStyles = makeStyles(({spacing, colors, shadows, typography}) => ({
  root: {
    padding: spacing(3, 4),
    display: "flex",
    justifyContent: "flex-end",
    "& > button": {
      marginLeft: spacing(1),
    },
  },
  muiButton: {
    cursor: "pointer",
    border: "none",
    boxShadow: shadows[2],
    padding: spacing(0, 2),
    transition: "background-color 250ms",
    backgroundColor: colors.blue[500],
    color: "#fff",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: colors.blue[400],
    },
    "& > span": typography.button,
  },
  disabled: {
    cursor: "default",
    boxShadow: "none",
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.12)",
    },
    "& > *": {
      color: "rgba(0, 0, 0, 0.26)",
    },
  },
  label: {
    padding: spacing(0.25, 1),
  },
  focusVisible: {
    backgroundColor: colors.blue[400],
  },
}));

const Footer = ({disabled, dismiss, handleClose, reason}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Button color="inherit" onClick={handleClose}>
        close
      </Button>
      <button
        className={clsx(classes.muiButton, disabled && classes.disabled)}
        disabled={disabled}
        onClick={() => {
          dismiss(reason);
          handleClose();
        }}
      >
        <span>dismiss recommendation</span>
      </button>
    </div>
  );
};

export default Footer;
