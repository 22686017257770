import CircularProgress from "@material-ui/core/CircularProgress";
import {connect} from "react-redux";
import {findDOMNode} from "react-dom";
import {generateShareableAnalysisLink} from "actions/wells";
import {show as showSnackbar} from "actions/snackbar";
import {withStyles} from "@material-ui/core/styles";
import CopyIcon from "@material-ui/icons/FileCopy";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import isNil from "lodash/isNil";
import Popover from "@material-ui/core/Popover";
import React from "react";
import ShareIcon from "@material-ui/icons/Share";
import TextField from "@material-ui/core/TextField";

import TextTooltip from "components/material-tooltip";
import AsMenuItem from "components/as-menu-item";

const classes = theme => {
  return {
    container: {
      margin: theme.spacing(2),
      minHeight: "96px",
      width: "300px",
    },
    icon: {
      color: theme.colors.warmGrey[300],
    },
    iconButtonColumn: {
      width: "64px",
    },
    input: {
      "&::selection": {
        backgroundColor: "transparent",
      },
      cursor: "pointer",
    },
    progressContainer: {
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      height: "96px",
    },
  };
};

const mapStateToProps = state => {
  return {
    link: state.wells.analysis.link,
    organizationId: state.organizationView.currentOrganization,
  };
};

class Component extends React.Component {
  button = null;
  textField = null;

  state = {
    open: false,
    anchorEl: null,
    anchorReference: "anchorEl",
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleCopy = () => {
    const input = findDOMNode(this.textField);

    input.focus();
    input.select();
    document.execCommand("copy");

    this.props.dispatch(showSnackbar({message: "Link copied to clipboard!"}));
  };

  handleFocus = e => {
    e.target.select();
  };

  handleOpen = () => {
    const {dispatch, organizationId} = this.props;

    // Create a new link that prepends /organization/organization_id to the current url
    const linkTarget = `${
      window.location.origin
    }/#/organization/${organizationId}${window.location.hash.slice(1)}`;

    dispatch(generateShareableAnalysisLink(linkTarget));

    this.setState({
      open: true,
      anchorEl: findDOMNode(this.button),
    });
  };

  render() {
    const {anchorEl, open} = this.state;
    const {classes, link, label = false, isMenuItem} = this.props;
    const isFetching = isNil(link.url) || link.isFetching;

    return [
      <AsMenuItem
        isMenuItem={isMenuItem}
        key={"analysis-sharable-link-button"}
        onClick={this.handleOpen}
      >
        {label ? (
          <>
            <IconButton
              className={classes.icon}
              ref={node => (this.button = node)}
            >
              <ShareIcon />
            </IconButton>
            <p>Share</p>
          </>
        ) : (
          <TextTooltip title="Share" placement="bottom">
            <IconButton
              className={classes.icon}
              ref={node => (this.button = node)}
            >
              <ShareIcon />
            </IconButton>
          </TextTooltip>
        )}
      </AsMenuItem>,
      <Popover
        key={"analysis-sharable-link-popover"}
        open={open}
        anchorEl={anchorEl}
        anchorReference={"anchorEl"}
        anchorPosition={{top: 0, left: 0}}
        onClose={this.handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className={classes.container}>
          {isFetching && (
            <Grid container size={0}>
              <Grid item xs className={classes.progressContainer}>
                <CircularProgress size={20} />
              </Grid>
            </Grid>
          )}
          {!isFetching && (
            <Grid container size={0}>
              <Grid item xs>
                <TextField
                  autoFocus
                  fullWidth
                  helperText="Email this link to other team members and they'll see the same analysis settings."
                  InputProps={{
                    inputProps: {
                      className: classes.input,
                      ref: node => (this.textField = node),
                    },
                    readOnly: true,
                  }}
                  label={"Shareable Link"}
                  onClick={this.handleCopy}
                  onFocus={this.handleFocus}
                  value={link.originalUrl}
                />
              </Grid>
              <Grid item className={classes.iconButtonColumn}>
                <IconButton onClick={this.handleCopy}>
                  <CopyIcon />
                </IconButton>
              </Grid>
            </Grid>
          )}
        </div>
      </Popover>,
    ];
  }
}

Component = connect(mapStateToProps)(Component);
Component = withStyles(classes)(Component);

export const ShareableLink = Component;
