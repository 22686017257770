import isNil from "lodash/isNil";
import isNaN from "lodash/isNaN";
import math from "mathjs";

export default (value, decimals = 2) => {
  if (isNil(value)) return value;
  const number = parseFloat(value);
  if (isNaN(number)) return value;

  return math.round(value, decimals);
};
