import get from "lodash/get";
import isEqual from "lodash/isEqual";

import {sendAnalyticsEvent} from "components/analytics";
import {eventCategory, eventAction} from "constants/analytics";

export const mapRangeSliderProps = ({
  rangeSliderState,
  rangeSliderOnChange,
  limitedAccess,
  well,
  setSpeedRange,
  saveSafeOperatingParameters,
  disabled,
}) => {
  const metBoundary =
    get(well, "operation.speedRange.max.value") >= rangeSliderState[1] ||
    get(well, "operation.speedRange.max.value") <= rangeSliderState[0];

  const updateSOP = () => {
    const [minSpeed, maxSpeed] = rangeSliderState;

    sendAnalyticsEvent(
      eventCategory.setpointAutomationWorkflow,
      eventAction.submit,
      `Update safe operating parameters ${minSpeed} - ${maxSpeed}`,
    );

    const payloadSOP = {
      wellId: well.wellId,
      downholeLocation: well.downholeLocation,
      ...well.safeOperatingParameters,
    };
    payloadSOP.minSpeed.value = minSpeed;
    payloadSOP.maxSpeed.value = maxSpeed;

    const payloadSpeedRange = {
      downholeLocation: well.downholeLocation,
      min: minSpeed,
      max: get(well, "operation.speedRange.max.value"),
    };

    saveSafeOperatingParameters(payloadSOP);
    setSpeedRange(payloadSpeedRange);
  };

  const cancelSafeOperatingParameters = () => {
    sendAnalyticsEvent(
      eventCategory.setpointAutomationWorkflow,
      eventAction.cancel,
      `Update safe operating parameters`,
    );

    rangeSliderOnChange(null, [
      get(well, "safeOperatingParameters.minSpeed.value"),
      get(well, "safeOperatingParameters.maxSpeed.value"),
    ]);
  };

  const editMode =
    !isEqual(
      [
        get(well, "safeOperatingParameters.minSpeed.value"),
        get(well, "safeOperatingParameters.maxSpeed.value"),
      ],
      rangeSliderState,
    ) && !disabled;

  return {
    metBoundary,
    disabled,
    limitedAccess,
    maxSPM: get(well, "operation.speedRange.max.value"),
    minSPM: rangeSliderState[0],
    updateSOP,
    rangeSliderOnChange,
    rangeSliderState,
    cancelSafeOperatingParameters,
    editMode,
  };
};
