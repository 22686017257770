import React from "react";
import compose from "lodash/fp/compose";
import IconButton from "@material-ui/core/IconButton";
import NavLink from "react-router-dom/NavLink";
import withStyles from "@material-ui/core/styles/withStyles";

import RecommendationsIcon from "./recommendations-icon";

const styles = ({spacing}) => ({
  root: {
    color: "inherit",
  },
  button: {
    marginRight: spacing(2),
  },
});

const RecommendationsButton = ({classes, onClick}) => (
  <NavLink className={classes.root} to="/recommendations">
    <IconButton className={classes.button} color="inherit" onClick={onClick}>
      <RecommendationsIcon />
    </IconButton>
  </NavLink>
);

const enhance = compose(withStyles(styles));

export default enhance(RecommendationsButton);
