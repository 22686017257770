import {connect} from "react-redux";

import getDisplayName from "utils/user/get-display-name";
import isAmbyintUser from "utils/user/is-ambyint-user";

const filterAmbyint = user => !isAmbyintUser(user);
const filterServiceAccount = user => user.type !== "serviceAccount";

const mapUserOption = user => ({
  value: user.clientUserId,
  text: getDisplayName(user),
});

const mapStateToProps = state => {
  const {organizations, organizationsEvents} = state;

  const loading = !organizations.all[organizations.selectedOrganizationId];

  const organization =
    organizations.all[organizations.selectedOrganizationId] || {};

  return {
    loading,
    organizationId: organization.organizationId,
    organizationName: organization.organizationName || "",
    performedByItems: (organization.users || [])
      .filter(filterServiceAccount)
      .map(mapUserOption),
    performedOnItems: (organization.users || [])
      .filter(filterServiceAccount)
      .filter(filterAmbyint)
      .map(mapUserOption),
    ...(organizationsEvents[organizations.selectedOrganizationId] || {}),
  };
};

export default connect(mapStateToProps);
