import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CloudUpload from "@material-ui/icons/CloudUpload";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import React from "react";

import colors from "@ambyint/colors";

import {importTypes} from "constants/import-types";
import {setSetting} from "actions/import-settings";

const styles = {
  container: {
    alignItems: "center",
    display: "flex",
    flexShrink: 0,
    justifyContent: "space-between",
  },
};

const onToggleHideIgnored = (dispatch, current) => () => {
  dispatch(
    setSetting({
      type: importTypes.WELL_EVENT,
      name: "hideIgnored",
      value: !current,
    }),
  );
};

const onToggleOnlyShowErrors = (dispatch, current) => () => {
  dispatch(
    setSetting({
      type: importTypes.WELL_EVENT,
      name: "onlyShowErrors",
      value: !current,
    }),
  );
};

let Footer = props => {
  const {
    dispatch,
    errorCount,
    loading,
    onRemoveErrors,
    onUpload,
    rowCount,
    settings,
  } = props;

  const {hideIgnored, onlyShowErrors} = settings;
  const hasErrors = errorCount > 0;
  const valid = !hasErrors && rowCount > 0;

  let {containerStyle} = props;

  containerStyle = {
    ...containerStyle,
    ...styles.container,
  };

  return (
    <div style={containerStyle}>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={hideIgnored}
              onChange={onToggleHideIgnored(dispatch, hideIgnored)}
            />
          }
          label="Hide Unassigned"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={onlyShowErrors}
              onChange={onToggleOnlyShowErrors(dispatch, onlyShowErrors)}
            />
          }
          label="Only Show Errors"
        />
      </div>
      <div>
        <Button
          color={"primary"}
          disabled={!hasErrors}
          onClick={onRemoveErrors}
          style={{marginRight: 8, paddingTop: 11, paddingBottom: 11}}
        >
          Remove Errors
        </Button>
        <Button
          color={"primary"}
          disabled={!valid || loading}
          onClick={onUpload}
          style={{...(valid ? {color: colors.white} : {})}}
          variant={"contained"}
        >
          <CloudUpload style={{marginRight: "8px"}} />
          Upload
        </Button>
      </div>
    </div>
  );
};

Footer = connect()(Footer);

export {Footer};
