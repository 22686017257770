import {
  FlexibleWidthXYPlot,
  HorizontalGridLines,
  VerticalGridLines,
  XAxis,
  YAxis,
  Borders,
} from "react-vis";
import {groupBy} from "lodash";
import {withSize} from "react-sizeme";
import React from "react";

const style = {
  line: {stroke: "#f0f0f0", strokeWidth: 1},
  ticks: {stroke: "#f0f0f0"},
  text: {fontSize: 11, stroke: "none", fill: "#999"},
};

const ScatterPlot = ({
  children,
  height,
  xDomain,
  yDomain,
  size,
  aspectRatio = 2 / 3,
  ...rest
}) => {
  const xTickValues = xDomain.ticks(5);
  const yTickValues = yDomain.ticks(5);

  // Any child passed into this component with the `bleed` prop set to true will render above
  // the chart border
  const childrenByLayer = groupBy(children, child => {
    if (child !== null && child.props.bleed === true) {
      return "bleed"; // Child overflow will bleed over chart boundaries
    }

    return "crop"; // Child overflow will be cropped
  });

  return (
    <FlexibleWidthXYPlot
      dontCheckIfEmpty
      height={size.width * aspectRatio}
      width={size.width}
      xDomain={xDomain.domain()}
      yDomain={yDomain.domain()}
      {...rest}
    >
      <VerticalGridLines tickValues={xTickValues} style={style.line} />
      <HorizontalGridLines tickValues={yTickValues} style={style.line} />
      {childrenByLayer.crop}
      <Borders
        style={{
          bottom: {fill: "#fff"},
          left: {fill: "#fff"},
          right: {fill: "#fff"},
          top: {fill: "#fff"},
        }}
      />
      <XAxis tickValues={xTickValues} style={style} />
      <YAxis tickValues={yTickValues} style={style} />
      {childrenByLayer.bleed}
    </FlexibleWidthXYPlot>
  );
};

export default withSize()(ScatterPlot);
