import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";

import {anomalyDisplayName} from "parsers/anomaly-display-name";

const useStyles = makeStyles(({colors, spacing}) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: spacing(0.3, 1, 0.6),
  },
  overrideLabel: {
    lineHeight: 1.2,
    color: "white",
    textShadow: "0 1px 2px rgb(0,0,0,0.2)",
  },
  caption: {
    color: colors.warmGrey[100],
    textShadow: "0 1px 3px rgb(0,0,0,0.1)",
  },
}));

const OverrideTooltipContents = ({override}) => {
  const classes = useStyles();
  const {classificationOverride} = override;

  return (
    <div className={classes.container}>
      <Typography className={classes.caption} variant="caption">
        Classification to be verified
      </Typography>
      <Typography className={classes.overrideLabel} variant="subtitle2">
        {anomalyDisplayName(classificationOverride)}
      </Typography>
    </div>
  );
};

export default OverrideTooltipContents;
