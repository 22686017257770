import React from "react";

import FeedItem from "../feed-item";

const MESSAGES = {
  active: "Fault recovery is now in progress.",
  failed: "Fault recovery has failed.",
  recovered: "Fault recovery has succeeded!",
};

export default props => {
  const {event} = props;

  return <FeedItem event={event}>{MESSAGES[event.value]}</FeedItem>;
};
