import get from "lodash/get";

import {RECENTLY_VISITED_WELLS} from "constants/local-storage-keys";

export const getRecentlyVisitedWells = (userId, organizationId) => {
  const recentWellsAllUsers = JSON.parse(
    localStorage.getItem(RECENTLY_VISITED_WELLS) || "{}",
  );
  const recentWellsThisUser = get(
    recentWellsAllUsers,
    [userId, organizationId],
    [],
  );

  return recentWellsThisUser;
};
