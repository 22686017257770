import sortDisplacement from "./sort-displacement";
import sortDisplayName from "./sort-display-name";
import sortSpeed from "./sort-speed";
import sortUptime from "./sort-uptime";
import sortEfficiency from "./sort-efficiency";
import sortField from "./sort-field";
import sortFillage from "./sort-fillage";
import sortStatus from "./sort-status";
import sortDescription from "./sort-descriptions";
import sortAnomalies from "./sort-anomalies";
import sortProduction from "./sort-production";

const sortMap = {
  displacement: sortDisplacement,
  displayName: sortDisplayName,
  speed: sortSpeed,
  uptime: sortUptime,
  efficiency: sortEfficiency,
  fillage: sortFillage,
  status: sortStatus,
  field: sortField,
  description: sortDescription,
  anomaly: sortAnomalies,
  production: sortProduction,
};

export default (values = [], field = "displayName", asc = true) => {
  const reverse = asc ? 1 : -1;
  const sortFunc = sortMap[field];

  return sortFunc ? sortFunc({values, reverse}) : values;
};
