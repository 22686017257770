import {replace} from "react-router-redux";

import {createSwitchEpic} from "epics/create-epic";
import {
  selectOrganization,
  setUserRemovedFromOrganization,
  types,
} from "actions/organizations";
import {show} from "actions/snackbar";
import fetch from "epics/async-fetch";

export default createSwitchEpic(
  [types.removeUserFromOrganization],
  async ({payload}, store) => {
    try {
      const {clientUserId, organizationId} = payload;

      await fetch(
        `/organization/${encodeURIComponent(
          organizationId,
        )}/remove/${encodeURIComponent(clientUserId)}`,
        {},
        {
          method: "DELETE",
        },
      );

      const user = store.getState().auth.user;
      const actions = [
        show({
          message:
            "Your request to remove user from the organization submitted successfully!",
        }),
        replace(
          clientUserId === user.clientUserId
            ? "/profile/organizations"
            : `/profile/organization/${encodeURIComponent(
                organizationId,
              )}/users`,
        ),
        setUserRemovedFromOrganization({clientUserId, organizationId}),
      ];

      if (clientUserId === user.clientUserId) {
        actions.push(selectOrganization({organizationId: null}));
      }

      return actions;
    } catch (err) {
      if (err.status === 401 || err.status === 403) {
        return [
          replace("/"),
          show({
            message: "Unauthorized to remove user from organization",
          }),
        ];
      }
      return [replace(`/error?code=${err.status}`)];
    }
  },
);
