import {createMergeEpic} from "epics/create-epic";
import {setComparisons, types} from "actions/wells";
import fetch from "epics/async-fetch";
import findWell from "utils/find-well";
import getUser from "utils/get-user";
import parseComparison from "parsers/parse-comparison";

import {addColor} from "./add-color";
import {removeExisting} from "./remove-existing";

export const fetchComparison = createMergeEpic(
  [types.fetchComparison],
  async ({payload}, store) => {
    const {correlationId, downholeLocation} = payload;
    const {unitsOfMeasure} = getUser();

    const comparison = await fetch(
      `/wells/${encodeURIComponent(downholeLocation)}/events`,
      {
        correlationId,
        downholeLocation,
      },
    );

    const well = findWell(store.getState().wells, downholeLocation);

    const comparisons = [
      ...removeExisting(well.comparisons, correlationId),
      addColor(parseComparison(unitsOfMeasure)(comparison, payload)),
    ];

    return [
      setComparisons({
        downholeLocation,
        comparisons,
      }),
    ];
  },
);
