/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {connect} from "react-redux";
import {
  change,
  clearFields,
  formValueSelector,
  reduxForm,
  SubmissionError,
} from "redux-form";
import isNil from "lodash/isNil";
import indexOf from "lodash/indexOf";
import colors from "theme/colors";

import {
  getInviteDetails,
  navigateToLogin,
  signUpWithAzure,
  signUpWithGoogle,
  verifyEmail,
  signOut,
} from "actions/auth";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import EmailIcon from "@material-ui/icons/Email";

import AzureIcon from "components/d3-icons/azure";
import GoogleIcon from "components/d3-icons/google";
import FormButton from "components/form-button";
import MaskedTextField from "components/forms/masked-text-field";
import PasswordTextField from "components/forms/password-text-field";
import TextFieldListMenu from "components/forms/text-field-list-menu";
import TextField from "components/forms/text-field";
import VerticalStepper from "components/vertical-stepper";
import {phoneMaskPattern} from "components/masked-text-field";

import Footer from "routes/login/footer";
import LoginContainer from "routes/login/login-container";
import TermsOfService from "routes/login/verify-user/terms-of-service";
import styles from "routes/login/styles";

import equals from "utils/validation/equals";
import maxLength from "utils/validation/max-length";
import minLength from "utils/validation/min-length";
import required from "utils/validation/required";
import validateFieldsOf from "utils/validation/validate-fields-of";

const ACCOUNT_TYPE_MAP = {
  google: "Google",
  azure: "Azure",
  basic: "Email",
};

const FORM_NAME = "verify-email";

const isOauth = accountType =>
  accountType === "google" || accountType === "azure";
const hasType = (available, requested) => indexOf(available, requested) > -1;

const withDefaults = existingUserInviteInfo => {
  const {firstName, lastName, mobilePhone, type} = existingUserInviteInfo || {};
  let {notificationMethods, unitsOfMeasure} = existingUserInviteInfo || {};

  if (notificationMethods === "") {
    notificationMethods = "None";
  } else if (isNil(notificationMethods)) {
    notificationMethods = "Email";
  }

  if (isNil(unitsOfMeasure)) {
    unitsOfMeasure = "Imperial";
  }

  return {
    firstName,
    lastName,
    mobilePhone,
    notificationMethods:
      notificationMethods === "" ? "None" : notificationMethods,
    type,
    unitsOfMeasure,
  };
};

const selector = formValueSelector("verify-email");
const mapStateToProps = state => {
  return {
    auth: state.auth,
    initialValues: {
      ...(state.auth.invite ? withDefaults(state.auth.invite) : {}),
    },
    currentValues: {
      notificationMethods: selector(state, "notificationMethods"),
      type: selector(state, "type"),
    },
  };
};

const getSteps = (props, handleSubmit, handleComplete) => {
  const {currentValues} = props;

  const continueIfValid = (validateFunc, goToNextStep) => {
    handleSubmit(values => {
      const validateField = validateFieldsOf(values);
      const errors = validateFunc(validateField, values);

      if (errors && Object.keys(errors).length > 0) {
        throw new SubmissionError(errors);
      }

      goToNextStep();
    })();
  };

  const optedForPhoneNotifications =
    currentValues.notificationMethods &&
    currentValues.notificationMethods.toLowerCase().indexOf("text") >= 0;

  return [
    ...(!isOauth(currentValues.type)
      ? [
          {
            label: "Confirm Name",
            handleNext: goToNextStep =>
              continueIfValid(
                validateField => ({
                  ...validateField("firstName", x => required(x)),
                  ...validateField("lastName", x => required(x)),
                }),
                goToNextStep,
              ),
            content: (
              <Grid container direction={"row"} spacing={2}>
                <Grid item xs={12}>
                  <Typography>
                    Need some answers to a few quick questions and we&apos;ll
                    have you on your way in no time. First up, we&apos;d like to
                    confirm the spelling of your name.
                  </Typography>
                </Grid>
                <Grid item xs={6} style={styles.fieldContainer}>
                  <TextField
                    autoFocus
                    name="firstName"
                    fullWidth
                    showError={props.dirty && props.submitFailed}
                  />
                </Grid>
                <Grid item xs={6} style={styles.fieldContainer}>
                  <TextField
                    name="lastName"
                    fullWidth
                    showError={props.dirty && props.submitFailed}
                  />
                </Grid>
              </Grid>
            ),
          },
        ]
      : []),
    {
      label: "Notification Methods",
      handleNext: goToNextStep =>
        continueIfValid(
          validateField => ({
            ...validateField("notificationMethods", x => required(x)),
          }),
          goToNextStep,
        ),
      content: (
        <Grid container direction={"row"} spacing={2}>
          <Grid item xs={12}>
            <Typography>
              When you&apos;re on call we will notify you of any alerts or
              recommendations that occur.
            </Typography>
          </Grid>
          <Grid item xs={12} style={styles.fieldContainer}>
            <TextFieldListMenu
              fullWidth
              name="notificationMethods"
              options={["Email", "Text", "Email & Text", "None"]}
            />
          </Grid>
        </Grid>
      ),
    },
    {
      label: "Mobile Phone",
      handleNext: goToNextStep =>
        continueIfValid(
          validateField => ({
            ...(optedForPhoneNotifications
              ? {
                  ...validateField("mobilePhone", x => minLength(x, 10)),
                  ...validateField("mobilePhone", x => required(x)),
                }
              : {}),
          }),
          goToNextStep,
        ),
      content: (
        <Grid container direction={"row"} spacing={2}>
          <Grid item xs={12}>
            {optedForPhoneNotifications ? (
              <Typography>
                You&apos;ve selected to be notified by{" "}
                <strong>{currentValues.notificationMethods}</strong>, so a
                mobile phone number is required to receive text message
                notifications.
              </Typography>
            ) : (
              <Typography>
                You&apos;ve selected to be notified by{" "}
                <strong>{currentValues.notificationMethods}</strong>, the mobile
                phone is optional in this case. If you do decide to get
                notifications by text you&apos;ll have to update it.
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} style={styles.fieldContainer}>
            <MaskedTextField
              autoFocus
              name="mobilePhone"
              fullWidth
              label={`Mobile Phone${
                optedForPhoneNotifications ? "" : " (optional)"
              }`}
              margin="normal"
              maskOnEnter
              maskPattern={phoneMaskPattern}
            />
          </Grid>
        </Grid>
      ),
    },
    {
      label: "Units of Measure",
      handleNext: goToNextStep =>
        continueIfValid(
          validateField => ({
            ...validateField("unitsOfMeasure", x => required(x)),
          }),
          isOauth(currentValues.type) ? handleComplete : goToNextStep,
        ),
      content: (
        <Grid container direction={"row"} spacing={2}>
          <Grid item xs={12}>
            <Typography>
              We can adjust the interface to display your preferred units of
              measure, either in imperial or metric units.
            </Typography>
          </Grid>
          <Grid item xs={12} style={styles.fieldContainer}>
            <TextFieldListMenu
              fullWidth
              name="unitsOfMeasure"
              options={["Imperial", "Metric"]}
            />
          </Grid>
        </Grid>
      ),
    },
    ...(!isOauth(currentValues.type)
      ? [
          {
            label: "Password",
            nextButtonText: "Create Account",
            handleNext: () =>
              continueIfValid(
                (validateField, values) => ({
                  ...validateField("password", x => required(x)),
                  ...validateField("password", x => minLength(x, 8)),
                  ...validateField("password", x => maxLength(x, 255)),

                  ...validateField("confirmPassword", x => required(x)),
                  ...validateField("confirmPassword", x =>
                    equals(x, values.password, "password"),
                  ),
                }),
                handleComplete,
              ),
            content: (
              <Grid container direction={"row"} spacing={2}>
                <Grid item xs={12}>
                  <Typography>
                    You must make your password{" "}
                    <strong>at least 8 characters</strong>. Longer passwords are
                    more secure, you shouldn&apos;t use common phrases.
                  </Typography>
                </Grid>
                <Grid item xs={12} style={styles.fieldContainer}>
                  <PasswordTextField
                    name="password"
                    label="Password"
                    fullWidth
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} style={styles.fieldContainer}>
                  <PasswordTextField
                    name="confirmPassword"
                    label="Confirm Password"
                    fullWidth
                  />
                </Grid>
              </Grid>
            ),
          },
        ]
      : []),
  ];
};

class VerifyEmail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {showTerms: false};
  }

  componentDidMount() {
    const {
      dispatch,
      match: {
        params: {inviteKey},
      },
    } = this.props;

    dispatch(signOut());
    dispatch(getInviteDetails({inviteKey}));
  }

  render() {
    const {auth, currentValues, dispatch, handleSubmit} = this.props;

    const submitVerifyEmail = handleSubmit(values => {
      const {
        firstName,
        lastName,
        mobilePhone,
        notificationMethods,
        type,
        password,
        unitsOfMeasure,
      } = values;
      const {inviteKey} = this.props.match.params;

      dispatch(
        verifyEmail({
          firstName,
          inviteKey,
          lastName,
          mobilePhone,
          notificationMethods:
            notificationMethods === "None" ? "" : notificationMethods,
          password,
          type,
          unitsOfMeasure,
        }),
      );
    });

    if (!auth || !auth.invite || auth.isFetching || auth.isAuthenticated) {
      return <LoginContainer />;
    }

    if (auth.invite && auth.invite.isInvalidInviteKey) {
      return (
        <LoginContainer>
          <Paper square elevation={0} style={styles.container}>
            <Grid container direction={"row"} spacing={2}>
              <Grid item xs={12}>
                <Typography style={styles.typography}>
                  Unfortunately, the requested invite is invalid or has already
                  been used.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography style={styles.typography}>
                  If you have already created an account, please sign in.
                  Otherwise, feel free to reach out to our support team.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormButton
                  variant={"contained"}
                  center
                  fullWidth
                  style={{height: "40px", color: "white"}}
                  onClick={() => dispatch(navigateToLogin())}
                >
                  Return to sign in
                </FormButton>
              </Grid>
            </Grid>
          </Paper>
          <footer style={styles.footer}>
            Need a new invite or having trouble?{" "}
            <a
              style={styles.link}
              href={"mailto:support@ambyint.com?Subject=Login Assistance"}
            >
              Get in touch with us
            </a>
          </footer>
        </LoginContainer>
      );
    }

    if (this.state.showTerms) {
      return (
        <LoginContainer>
          <TermsOfService
            handleAccept={() => submitVerifyEmail()}
            handleDecline={() => dispatch(navigateToLogin())}
          />
        </LoginContainer>
      );
    }

    if (!currentValues.type) {
      return (
        <LoginContainer>
          <div
            style={{
              ...styles.loginContainer,
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              margin: "0px 75px",
              width: "700px",
            }}
          >
            <div style={{marginBottom: "16px"}}>
              <Typography
                gutterBottom
                style={{fontWeight: "bold", textAlign: "center"}}
                variant="subtitle1"
              >
                {auth.invite.availableUserTypes.length === 1
                  ? "Click below to continue with registration."
                  : "How would you like to sign in?"}
              </Typography>
            </div>
            {hasType(auth.invite.availableUserTypes, "google") && (
              <FormButton
                center
                fullWidth
                onClick={() =>
                  dispatch(signUpWithGoogle(auth.invite.userLogin))
                }
                size="small"
                style={{marginTop: "8px", marginBottom: "8px"}}
              >
                <GoogleIcon />
                <span style={{marginLeft: "16px"}}>Google</span>
              </FormButton>
            )}
            {hasType(auth.invite.availableUserTypes, "azure") && (
              <FormButton
                center
                fullWidth
                onClick={() => dispatch(signUpWithAzure(auth.invite.userLogin))}
                size="small"
                style={{marginTop: "8px", marginBottom: "8px"}}
              >
                <AzureIcon />
                <span style={{marginLeft: "16px"}}>Azure</span>
              </FormButton>
            )}
            {hasType(auth.invite.availableUserTypes, "basic") && (
              <FormButton
                center
                fullWidth
                onClick={() => dispatch(change(FORM_NAME, "type", "basic"))}
                size="small"
                style={{marginTop: "8px", marginBottom: "8px"}}
              >
                <EmailIcon
                  style={{
                    backgroundColor: colors.ambyOrange[400],
                    color: colors.white,
                    borderRadius: "2em",
                    padding: "4px",
                    height: "20px",
                    width: "20px",
                  }}
                />
                <span style={{marginLeft: "16px"}}>Email</span>
              </FormButton>
            )}
          </div>
          <Footer />
        </LoginContainer>
      );
    }

    return (
      <LoginContainer>
        <div
          style={{
            alignItems: "center",
            color: colors.white,
            display: "flex",
            flexDirection: "column",
            marginBottom: "24px",
            minHeight: "44px",
          }}
        >
          <div style={{marginBottom: "8px"}}>
            {ACCOUNT_TYPE_MAP[currentValues.type]} account setup
          </div>
          <div>
            <a
              onClick={() =>
                dispatch(clearFields(FORM_NAME, false, false, "type"))
              }
              style={styles.link}
            >
              Change
            </a>
          </div>
        </div>
        <div style={{margin: "0px 75px", width: "700px"}}>
          <VerticalStepper
            steps={getSteps(this.props, handleSubmit, () =>
              this.setState({showTerms: true}),
            )}
          />
        </div>
        <Footer />
      </LoginContainer>
    );
  }
}

const Component = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(VerifyEmail);

export default connect(mapStateToProps)(Component);
