import get from "lodash/get";

import Grid from "@material-ui/core/Grid/index";
import React from "react";

import {Anomalies} from "./anomalies";
import {PumpHealth} from "./pump-health";
import {PumpHealthV2} from "./pump-health-v2";
import Analysis from "../analysis";

export const DownholeCardClassifications = ({data}) => {
  const classifications = get(data, "downholeCardClassifications.value");

  return (
    <Analysis title="Classifications">
      <Grid container>
        <PumpHealth classifications={classifications} />
        <PumpHealthV2 classifications={classifications} />
        <Anomalies classifications={classifications} />
      </Grid>
    </Analysis>
  );
};
