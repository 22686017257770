import React from "react";
import get from "lodash/get";

import Grid from "@material-ui/core/Grid";

import startCase from "lodash/startCase";
import {connect} from "react-redux";
import {reduxForm} from "redux-form";

import {saveMotorDetails, saveNominalRpm} from "actions/wells";
import DecimalTextField from "components/forms/decimal-text-field";
import FormHeader from "components/form-header";
import PaperContainer from "components/paper-container";
import SaveCancelButtons from "components/save-cancel-buttons";

import compose from "utils/compose";
import spreadIf from "utils/spread-if";

import validate from "./validate";

const mapStateToProps = (state, props) => {
  const downholeLocation = get(props.well, "downholeLocation");
  const current = get(props.well, "motorDetails.current");
  const efficiency = get(props.well, "motorDetails.efficiency");
  const horsepower = get(props.well, "motorDetails.horsepower");
  const powerSupplyPhases = get(props.well, "motorDetails.powerSupplyPhases");
  const rpm = get(props.well, "motorDetails.rpm");
  const serviceFactor = get(props.well, "motorDetails.serviceFactor");
  const voltage = get(props.well, "motorDetails.voltage");

  return {
    ...spreadIf(props.well, {
      initialValues: {
        downholeLocation,
        current,
        efficiency,
        horsepower,
        powerSupplyPhases,
        rpm,
        serviceFactor,
        voltage,
      },
    }),
  };
};

class MotorInformationEditView extends React.Component {
  save = (values, func, props) => {
    if (!props.syncErrors.rpm && props.rpmOnly) {
      props.dispatch(
        saveNominalRpm({
          nominalRpm: values.rpm,
          downholeLocation: props.well.downholeLocation,
          wellId: props.well.wellId,
        }),
      );

      return props.onClose();
    }

    if (Object.keys(props.syncErrors).length > 0) {
      return;
    }

    props.dispatch(
      saveMotorDetails({
        ...values,
        downholeLocation: props.well.downholeLocation,
        wellId: props.well.wellId,
      }),
    );

    props.onClose();
  };

  render() {
    const {
      handleSubmit,
      onClose,
      pristine,
      rpmOnly,
      submitting,
      reset,
    } = this.props;

    const decimalField = (name, label, xs = 6, min, max) => (
      <Grid item xs={xs}>
        <DecimalTextField
          fullWidth
          min={min}
          max={max}
          name={name}
          label={label || startCase(name.split(/(?=[A-Z])/).join(" "))}
          required
        />
      </Grid>
    );

    return (
      <PaperContainer extraPadded>
        <form onSubmit={handleSubmit(this.save)}>
          <Grid container direction={"row"} style={{maxWidth: 776}} spacing={2}>
            <Grid item xs={12} style={{marginTop: "0px", marginBottom: "0px"}}>
              <FormHeader>Motor</FormHeader>
            </Grid>

            {!rpmOnly && (
              <>
                {decimalField("powerSupplyPhases")}
                {decimalField("efficiency")}

                {decimalField("horsepower")}
                {decimalField("voltage")}

                {decimalField("current")}
              </>
            )}

            {decimalField("rpm", "RPM", 6, 2, 9999)}

            {!rpmOnly && decimalField("serviceFactor", "Service Factor", 12)}
          </Grid>

          <SaveCancelButtons
            pristine={pristine}
            reset={compose(
              onClose,
              reset,
            )}
            submitting={submitting}
          />
        </form>
      </PaperContainer>
    );
  }
}

const Component = reduxForm({
  form: "motor-information-edit-view",
  enableReinitialize: true,
  validate,
})(MotorInformationEditView);

export default connect(mapStateToProps)(Component);
