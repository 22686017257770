import React from "react";

import WellRow from "./well-row";

const renderWellRow = (wells, limitedAccess) => index => {
  const well = wells[index];

  return (
    <WellRow key={well.wellId} well={well} limitedAccess={limitedAccess} />
  );
};

export default renderWellRow;
