import {connect} from "react-redux";
import get from "lodash/get";
import LinearProgress from "@material-ui/core/LinearProgress";
import React from "react";

import {navigateToLogin} from "actions/auth";

const mapStateToProps = ({auth}) => {
  return {
    auth,
  };
};

export function requireAuthentication(Component) {
  class AuthenticatedComponent extends React.Component {
    componentWillMount() {
      this.checkAuth(this.props.auth);
    }

    componentWillReceiveProps(props) {
      this.checkAuth(props.auth);
    }

    checkAuth(auth) {
      const tosPrevAccept = get(auth, "user.termsOfServiceAccepted");
      const tosCurrentAccept = auth.termsOfServiceAcceptedThisSession;
      const tosNotAccepted = !tosPrevAccept && !tosCurrentAccept;

      const pathname = get(this.props, "location.pathname", false);

      if (auth.isFetching) {
        return;
      }

      if (!auth.isAuthenticated || tosNotAccepted) {
        if (pathname) {
          sessionStorage.setItem("redirect_to", pathname);
        }

        return this.props.dispatch(navigateToLogin());
      }
    }

    render() {
      return (
        <div>
          {this.props.auth.isFetching && <LinearProgress />}
          {this.props.auth.isAuthenticated === true ? (
            <Component {...this.props} />
          ) : null}
        </div>
      );
    }
  }

  return connect(mapStateToProps)(AuthenticatedComponent);
}
