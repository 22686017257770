import {resetType as globalResetType} from "actions";

/***
 * Creates a reducer map
 *
 * Takes a map of actions to reducers and connects incoming actions to the correct reducer
 *
 * If a "reset" type from an action is detected, the correct reducer will be bypassed and state
 * will be reset back to default state.
 *
 * @param {Object} actionMap - A map of actions that will be used to handle action types {[type]: handler}
 * @param {Object} defaults - default state that will be applied when action is unrecognized or reset action is triggered
 * @param {String} resetType - type, that when detected, will bypass the next reducer and reset state to default
 *
 * @returns {function(*=, *)}
 */
export const reducerFactory = (actionMap, defaults, resetType) => (
  state = defaults,
  action,
) => {
  // When a reset action is dispatched, skip reducer and return default state
  if (
    (action.type === globalResetType || action.type === resetType) &&
    !state.ignoreReset
  )
    return defaults;

  const doAction = actionMap[action.type];
  return doAction ? doAction({state, action}) : state;
};
