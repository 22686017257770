import React from "react";
import {connect} from "react-redux";
import {reduxForm} from "redux-form";
import Grid from "@material-ui/core/Grid";
import trim from "lodash/trim";

import FormButton from "components/form-button";
import FormHeader from "components/form-header";
import MaskedTextField from "components/forms/masked-text-field";
import PaperContainer from "components/paper-container";
import TextField from "components/forms/text-field";

import maskPattern from "components/masked-text-field/phone-mask-pattern";
import {save as saveUser} from "actions/auth";
import spreadIf from "utils/spread-if";

import validate from "./validate-general";

const mapStateToProps = state => {
  const {firstName, lastName, mobilePhone, jobTitle, title} = state.auth.user;

  return {
    ...spreadIf(state.auth.user, {
      initialValues: {
        firstName,
        lastName,
        mobilePhone,
        jobTitle: jobTitle || title,
      },
    }),
    user: state.auth.user,
  };
};

class ProfileGeneral extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  save = values => {
    const {change, dispatch, user} = this.props;

    dispatch(
      saveUser({
        clientUserId: user.clientUserId,
        data: Object.assign({}, user, {
          ...values,
          firstName: trim(values.firstName),
          lastName: trim(values.lastName),
          jobTitle: trim(values.jobTitle),
        }),
      }),
    );

    // Trim fields in form data as well, resetting to pristine
    change("firstName", trim(values.firstName));
    change("lastName", trim(values.lastName));
    change("jobTitle", trim(values.jobTitle));
  };

  render() {
    const {handleSubmit, pristine, submitting} = this.props;

    return (
      <PaperContainer extraPadded style={{maxWidth: 600}}>
        <form onSubmit={handleSubmit(this.save)} style={{width: "90%"}}>
          <Grid
            container
            spacing={1}
            alignItems={"center"}
            justify={"space-between"}
            style={{maxWidth: 720}}
          >
            <Grid item xs={12}>
              <FormHeader>General Information</FormHeader>
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth label="First Name" name="firstName" />
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth label="Last Name" name="lastName" />
            </Grid>
            <Grid item xs={12} style={{display: "flex", marginBottom: 16}}>
              <MaskedTextField
                fullWidth
                label="Mobile Phone (optional)"
                margin="normal"
                maskOnEnter
                maskPattern={maskPattern}
                name="mobilePhone"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label="Job Title" name="jobTitle" />
            </Grid>
          </Grid>

          <div style={{marginTop: 16}}>
            <FormButton disabled={pristine || submitting} type="submit">
              Save
            </FormButton>
          </div>
        </form>
      </PaperContainer>
    );
  }
}

const Component = reduxForm({
  form: "profile-general",
  enableReinitialize: true,
  validate,
})(ProfileGeneral);

export default connect(mapStateToProps)(Component);
