import {createMergeEpic} from "epics/create-epic";
import fetch from "epics/async-fetch";
import {types} from "actions/wells";
import {show} from "actions/snackbar";

import onUpdateSuccess from "../on-update-success";
import {parse} from "./parse";

export const setLufkinFillBase = createMergeEpic(
  [types.setLufkinFillBase],
  async ({payload}) => {
    try {
      const {values, well} = payload;

      await fetch(
        `/wells/${encodeURIComponent(
          well.downholeLocation,
        )}/lufkin/settings/fill-base`,
        {},
        {
          method: "PUT",
          body: parse(values),
        },
      );

      return onUpdateSuccess({
        well,
        updatePath: "fillBase.request",
        payload: values.fillBase,
      });
    } catch (err) {
      return [
        show({
          message:
            "An error occurred while attempting to update the fill base settings on the Lufkin device.",
        }),
      ];
    }
  },
);

export default setLufkinFillBase;
