import {useMemo} from "react";
import {scaleLinear} from "d3-scale";

import {ID, COLORS, VIEW} from "constants/well-health";
import getUser from "utils/get-user";
import round from "utils/round";

const nice = (min, max, count = 10) => {
  return scaleLinear()
    .domain([min, max])
    .nice(count)
    .ticks(count);
};

const resolveAxis = (dataMin, dataMax, verticalSize, forceMin = true) => {
  // Get the size of the data from min to max
  const dataSize = Math.abs(dataMax - dataMin);

  // The minimum size for our domain is going to be 1/2 the vertical axis size.
  // So at a minimum, the x and y axis should be no less than 1/2 the vertical axis.
  const minDomainSize = verticalSize / 2;

  // Given the real domain of our data, find the middle.
  // We want to make this the center of our axis so that the WELLBORE is drawn centered in the 3D space.
  const domainMiddle = dataMin + dataSize / 2;

  // Find the size of our domain.
  // It's either the minimum size or the real input size.
  const domainSize = forceMin ? Math.max(minDomainSize, dataSize) : dataSize;
  //const domainSize = dataSize;

  // Using the domain size, figure out the minimum and maximum value of the domain this axis will display
  const domainMin = domainMiddle - domainSize / 2;
  const domainMax = domainMiddle + domainSize / 2;

  // Nice the domain
  const ticks = nice(domainMin, domainMax, 5);

  // Using the domain size, figure out the proportional relationship between the length of this axis and the length of the vertical axis.
  // This is used when figuring out the range to draw the final graph.
  const axisToVerticalRatio = domainSize / verticalSize;

  const niceDomainMin = ticks[0];
  const niceDomainMax = ticks[ticks.length - 1];

  // Return everything we've calculated.
  return {
    ticks,
    domainMin: niceDomainMin,
    domainMax: niceDomainMax,
    domainSize: niceDomainMax - niceDomainMin,
    axisToVerticalRatio,
  };
};

const useWellboreGraphConfig = (
  view,
  surveySeries,
  rodSeries,
  plungerSeries,
) => {
  const minX = Math.min(...surveySeries.x);
  const maxX = Math.max(...surveySeries.x);

  const minY = Math.min(...surveySeries.y);
  const maxY = Math.max(...surveySeries.y);

  const minZ = Math.min(...surveySeries.z);
  const maxZ = Math.max(...surveySeries.z);

  const verticalSize = Math.abs(maxZ - minZ);

  const xAxis = useMemo(() => resolveAxis(minX, maxX, verticalSize), [
    minX,
    maxX,
    verticalSize,
  ]);
  const yAxis = useMemo(() => resolveAxis(minY, maxY, verticalSize), [
    minY,
    maxY,
    verticalSize,
  ]);
  const zAxisTicks = useMemo(() => nice(maxZ, minZ, 5), [maxZ, minZ]);

  // Figure out the size of the box to use to draw the WELLBORE.
  // We want it to fit 100x100x100 just because that displays nicely.
  // We'll use a d3 scale to figure the real values out.
  // The domain of the scale is 0 to the value of our largest axis (could be x, y or z).
  // Assuming Z is 1, the X and Y are defined based on their relative relationship to the Z axis.
  // The output range is 0 to our max desired size (100).
  const boxScale = scaleLinear()
    .domain([
      0,
      Math.max(1, xAxis.axisToVerticalRatio, yAxis.axisToVerticalRatio),
    ])
    .range([0, 1]);

  // Using the scale function, figure out the size values for all 3 dimensions.
  const boxHeight = boxScale(1);
  const boxWidth = boxScale(xAxis.axisToVerticalRatio);
  const boxDepth = boxScale(yAxis.axisToVerticalRatio);

  const surveySeriesData = {
    name: ID.WELLBORE,
    ...surveySeries,
    hoverinfo: "none",
    type: "scatter3d",
    mode: "lines",
    line: {
      width: 7,
      color: COLORS.WELLBORE,
    },
  };

  const rodSeriesData = {
    name: ID.ROD,
    ...rodSeries,
    hoverinfo: "none",
    type: "scatter3d",
    mode: "markers",
    marker: {
      symbol: "circle",
      size: 3,
      color: COLORS.ROD,
    },
  };

  const rodSeriesOutlineData = {
    ...rodSeriesData,
    marker: {
      symbol: "circle",
      size: 5,
      color: COLORS.ROD_OUTLINE,
    },
  };

  const plungerSeriesData = {
    name: ID.PLUNGER,
    ...plungerSeries,
    hoverinfo: "none",
    type: "scatter3d",
    mode: "markers",
    marker: {
      size: 6,
      color: COLORS.PLUNGER,
    },
  };

  const plungerSeriesOutlineData = {
    ...plungerSeriesData,
    marker: {
      size: 8,
      color: COLORS.PLUNGER_OUTLINE,
    },
  };

  const doglegSeriesData = {
    ...surveySeriesData,
    line: {
      width: 7,
      color: surveySeries.dlsMap,
      cmin: 0,
      cmax: 1,
      colorscale: [[1, COLORS.NO_DOGLEG_SEVERITY], [2, COLORS.DOGLEG_SEVERITY]],
    },
  };

  const doglegSeriesOutlineData = {
    ...doglegSeriesData,
    line: {
      width: 11,
      color: COLORS.DOGLEG_OUTLINE,
    },
  };

  const dataByView = {
    [VIEW.WELLBORE]: [surveySeriesData],
    [VIEW.EQUIPMENT]: [
      surveySeriesData,
      rodSeriesOutlineData,
      rodSeriesData,
      plungerSeriesOutlineData,
      plungerSeriesData,
    ],
    [VIEW.DOGLEG]: [doglegSeriesOutlineData, doglegSeriesData],
  };

  const annotationsByView = {
    [VIEW.DOGLEG]: surveySeries.maxDls
      ? [
          {
            x: surveySeries.maxDls.x,
            y: surveySeries.maxDls.y,
            z: surveySeries.maxDls.z,
            text: `Max DLS:<br>${round(
              surveySeries.maxDls.doglegSeverityDeg30m,
            )}°`,
            align: "left",
            ax: 100,
            startstandoff: 10,
            valign: "middle",
            arrowcolor: COLORS.ANNOTATION,
            arrowhead: 6,
            arrowsize: 0.8,
            font: {
              family: "Roboto",
              size: 12,
              color: "#27241d",
            },
          },
        ]
      : [],
  };

  const legendByView = {
    [VIEW.WELLBORE]: [ID.WELLBORE],
    [VIEW.EQUIPMENT]: [ID.WELLBORE, ID.ROD, ID.PLUNGER],
    [VIEW.DOGLEG]: [ID.NO_DOGLEG_SEVERITY, ID.DOGLEG_SEVERITY],
  };

  const convert = value => {
    const unit = getUser().unitsOfMeasure === "Imperial" ? "k ft" : "km";
    return `${round(value / 1000, 1)}${unit}`;
  };

  return {
    legend: legendByView[view],

    data: dataByView[view],

    layout: {
      width: 500,
      height: 500,
      hovermode: "closest",
      showlegend: false,
      margin: {
        l: 0,
        r: 0,
        t: 0,
        b: 0,
      },
      scene: {
        annotations: annotationsByView[view],
        aspectmode: "manual",
        aspectratio: {
          z: boxHeight,
          x: boxWidth,
          y: boxDepth,
        },
        camera: {
          eye: {
            z: 0.7,
          },
        },
        xaxis: {
          title: {
            text: `East (${convert(xAxis.domainSize)})`,
            font: {
              family: "Roboto",
              size: 12,
            },
          },
          range: [xAxis.domainMin, xAxis.domainMax],
          showticklabels: false,
          showgrid: false,
          showline: true,
        },
        yaxis: {
          title: {
            text: `North (${convert(yAxis.domainSize)})`,
            font: {
              family: "Roboto",
              size: 12,
            },
          },
          range: [yAxis.domainMin, yAxis.domainMax],
          showticklabels: false,
          showgrid: false,
          showline: true,
        },
        zaxis: {
          title: {
            text: "TVD",
            font: {
              family: "Roboto",
              size: 12,
            },
          },
          tickfont: {
            family: "Roboto",
            size: 12,
            color: "rgba(0,0,0,0.5)",
          },
          tickformat: "s",
          tickmode: "array",
          tickvals: zAxisTicks,
          ticktext: zAxisTicks.map(t => convert(Math.abs(t))),
          showline: true,
          mirror: true,
        },
      },
      modebar: {
        orientation: "v",
      },
    },

    config: {
      displaylogo: false,
      modeBarButtonsToRemove: [
        "toImage",
        "pan",
        "orbitRotation",
        "resetCameraLastSave3d",
        "hoverClosest3d",
      ],
      /* See https://github.com/plotly/plotly.js/blob/master/src/components/modebar/buttons.js for modeBar button names */
    },
  };
};

export default useWellboreGraphConfig;
