import React from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Sparkline from "components/d3-sparkline";
import colors from "theme/colors";
import isEmpty from "lodash/isEmpty";

const containerStyle = {
  minWidth: "150px",
  textTransform: "uppercase",
};

const defaultMainStyle = {
  color: colors.ambyBlue[500],
  fontSize: "24px",
  fontWeight: 500,
  lineHeight: "32px",
};

const defaultTitleStyle = {
  color: colors.grey[600],
  textTransform: "uppercase",
  fontSize: "12px",
};

const lineStyle = {stroke: colors.blue[500]};

const parseUptimeData = well => {
  return [...well.uptime].reverse().map((value, index) => {
    return {
      x: index,
      y: value.runningPercent,
    };
  });
};

export default props => {
  const {well} = props;

  if (isEmpty(well.uptime)) return null;

  return (
    <Grid container alignItems="center" style={containerStyle}>
      <Grid item xs={4}>
        <Typography variant="caption" style={{...defaultTitleStyle}}>
          Uptime
        </Typography>
        <Typography variant="h3" style={{...defaultMainStyle}}>
          {well.uptime[0] ? `${well.uptime[0].runningPercent}%` : "--"}
        </Typography>
        <Typography variant="caption" style={{...defaultTitleStyle}}>
          Previous 24 Hours
        </Typography>
      </Grid>
      <Grid item xs>
        <Sparkline
          data={parseUptimeData(well)}
          dataLineStyle={lineStyle}
          scaleLineStyle={lineStyle}
        />
      </Grid>
    </Grid>
  );
};
