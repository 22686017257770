const setWellboreView = ({state, action}) => {
  const {view} = action.payload;

  return {
    ...state,
    wellbore: {
      ...state.wellbore,
      view,
    },
  };
};

export default setWellboreView;
