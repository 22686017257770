import getUnits from "utils/units";
import isNil from "lodash/isNil";
import proxyAlwaysReturnValue from "utils/proxy-always-return-value";
import spreadIf from "utils/spread-if";
import unitsOf from "utils/units-of";
import unitsOfMeasureOf from "utils/units-of-measure-of";
import valueOf from "utils/value-of";
import withUnits from "utils/with-units";

const metricUnits = getUnits("metric");

const convertToMetric = (units, unitsOfMeasure) => (
  converter,
  {decimals = undefined, from, to} = {},
) => (value, override = {}) => {
  const unitsToUse =
    typeof override.to === "string" || typeof to === "string"
      ? proxyAlwaysReturnValue(override.to || to)
      : metricUnits;

  const convertedValue = converter({
    ...spreadIf(override.to || to, {to: override.to || to}),
    ...spreadIf(unitsOfMeasureOf(value), {
      fromUnitsOfMeasure: unitsOfMeasureOf(value),
    }),
    from:
      unitsOfMeasureOf(value) || override.from
        ? unitsOf(value) || override.from || from
        : unitsOf(value) || from || units,
    decimals: isNil(override.decimals) ? decimals : override.decimals,
    fromUnitsOfMeasure: unitsOfMeasure,
    toUnitsOfMeasure: "metric",
    units: override.to || to || unitsToUse,
    value: valueOf(value),
  });

  if (valueOf(value) === null || valueOf(value) === undefined) {
    return withUnits(null, convertedValue.units);
  }

  return convertedValue;
};

export default convertToMetric;
