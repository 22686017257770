import {compose} from "recompose";
import {connect} from "react-redux";
import {get} from "lodash";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import withHelmet from "components/with-helmet";

import BoxLoading from "components/box-loading";
import Container from "components/container";
import PageContainer from "components/page-container";
import {setClassificationSort} from "actions/field-optimization";
import Subheader, {SubheaderButtons} from "components/subheader";
import TagsButton from "components/tags-button";
import {HomeTabs} from "components/subheader/subheader-tabs";
import {withWellSearch} from "components/app-bar/with-search";

import {fieldOptimizationWellsSelector} from "./field-optimization-wells-selector";
import ReportHeader from "./components/report-header";
import WellList from "./components/well-list";
import AvgLiquidProdVsSpm from "./components/liquidprod-vs-spm";
import ClassificationFilter from "./components/classification-filter";
import CyclesVsFillage from "./components/cycles-vs-fillage";

const styles = ({typography}) => ({
  title: {
    ...typography.h4,
    color: "#4b586b",
    fontWeight: 600,
    marginLeft: 16,
    marginBottom: "1.25rem",
  },
  paper: {
    position: "relative",
    paddingTop: "4rem",
    marginBottom: "1.5rem",
  },
  pullOut: {
    position: "absolute",
    top: -56,
    right: 16,
  },
  footer: {
    marginTop: 16,
    padding: 16,
    backgroundColor: "#f0f1f2",
  },
  select: {
    fontWeight: 400,
  },
  subtitle: {
    marginBottom: ".5rem",
  },
  caption: {
    maxWidth: "80%",
  },
  container: {
    marginTop: "12em",
  },
});

const mapStateToProps = state => ({
  unsortedWells: fieldOptimizationWellsSelector(state),
  allWellsLength: state.wells.allWellIds.length,
  sortBy: state.fieldOptimization.sort,
  unitsOfMeasure: get(state, ["auth", "user", "unitsOfMeasure"], ""),
  loading: !state.wells.fetched,
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
  doSort: field => dispatch(setClassificationSort(field)),
});

const enhance = compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withHelmet({title: "Optimization"}),
  withWellSearch,
);

const FieldOptimization = ({
  allWellsLength,
  classes,
  unsortedWells,
  unitsOfMeasure,
  sortBy,
  doSort,
  dispatch,
  loading,
  user,
}) => {
  if (loading) return <BoxLoading />;

  return (
    <>
      <Subheader>
        <HomeTabs />
        <SubheaderButtons>
          <TagsButton />
        </SubheaderButtons>
      </Subheader>
      <PageContainer className={classes.container}>
        <Container>
          <Paper className={classes.paper} elevation={1}>
            <div className={classes.pullOut}>
              <ClassificationFilter />
            </div>
            <Grid container>
              <Grid item sm={6}>
                <AvgLiquidProdVsSpm unitsOfMeasure={unitsOfMeasure} />
              </Grid>
              <Grid item sm={6}>
                <CyclesVsFillage />
              </Grid>
              <Grid className={classes.footer} item sm={12}>
                <Typography className={classes.subtitle} variant="subtitle1">
                  What does it mean to be dialed in?
                </Typography>

                <Typography className={classes.caption} variant="caption">
                  Dialed in refers to a balanced rod pumping system where the
                  inflow matches the outflow. A profitable, dialed in system
                  minimizes the amount of energy going in while maximizing
                  production. A system's dialed in status is verified by
                  triangulating the primary input variable (SPM), with the
                  primary output variable (Liquid Production), speed cycles, and
                  pump fillage as per the chart above.
                </Typography>
              </Grid>
            </Grid>
          </Paper>

          <ReportHeader
            user={user}
            classificationCount={unsortedWells.length}
            totalCount={allWellsLength}
          />

          <WellList
            unsortedWells={unsortedWells}
            sortBy={sortBy}
            unitsOfMeasure={unitsOfMeasure}
            dispatch={dispatch}
            doSort={doSort}
          />
        </Container>
      </PageContainer>
    </>
  );
};

export default enhance(FieldOptimization);
