import React from "react";

import {eventCategory, eventAction} from "constants/analytics";
import {sendAnalyticsEvent} from "components/analytics";
import GenericTableHeaderCell from "components/table-header-cell";
import withSort from "routes/home/well-header/view-controls/with-sort";

const toggleSortByField = (doSort, field, sortAsc) => () => {
  sendAnalyticsEvent(
    eventCategory.allWellsWorkflow,
    eventAction.sort,
    `${field}:${sortAsc ? "asc" : "desc"}`,
  );
  doSort(field, !sortAsc);
};

const TableHeaderCell = ({field, sortAsc, doSort, ...props}) => (
  <GenericTableHeaderCell
    onClick={toggleSortByField(doSort, field, sortAsc)}
    field={field}
    sortAsc={sortAsc}
    {...props}
  />
);

export default withSort(TableHeaderCell);
