import {types} from "./types";

export const setUserRemovedFromOrganization = options => {
  const {clientUserId, organizationId} = options;

  return {
    type: types.setUserRemovedFromOrganization,
    payload: {
      clientUserId,
      organizationId,
    },
  };
};
