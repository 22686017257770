var proxyAlwaysReturnValue = valueToReturnForAllProperties =>
  new Proxy(
    {},
    {
      get: (target, name) => {
        return valueToReturnForAllProperties;
      },
    },
  );

export default proxyAlwaysReturnValue;
