import React from "react";

import formatDate from "@ambyint/common/utils/format/format-date";
import formatDateTime from "@ambyint/common/utils/format/format-date-time";

const Format = ({date, format, formatter, ...props}) => {
  return <span {...props}>{formatter(date, {format, timezone: true})}</span>;
};

export const FormatDateTime = props => {
  return <Format {...props} formatter={formatDateTime} />;
};

export const FormatDate = props => {
  return <Format {...props} formatter={formatDate} />;
};
