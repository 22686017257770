import get from "lodash/get";
import store from "store";

export default formPath => {
  const state = store.getState();

  return {
    ...get(state, `forms.${formPath}.$form`),
    currentValues: get(state, formPath),
  };
};
