import compose from "lodash/fp/compose";
import filter from "lodash/fp/filter";
import isNil from "lodash/isNil";
import moment from "moment";
import map from "lodash/fp/map";
import pick from "lodash/pick";
import reduce from "lodash/fp/reduce";
import sortBy from "lodash/fp/sortBy";
import {replace} from "react-router-redux";

import {createMergeEpic} from "epics/create-epic";
import fetch from "epics/async-fetch";
import {setpointChangesRetrieved, types} from "actions/wells/well-health";

export const fetchSetpointChanges = createMergeEpic(
  [types.fetchSetpointChanges],
  async ({payload}, store) => {
    const {wellId, downholeLocation, start, end, events} = payload;
    try {
      const setpointChanges = await fetch(
        `/wells/${encodeURIComponent(downholeLocation)}/events`,
        {
          downholeLocation,
          wellId,
          start,
          end,
          types: events.join(","),
        },
      );

      const processEvents = compose(
        map(event => event),
        reduce((accumulator, event) => {
          // Find the latest event for each week.
          if (
            !accumulator[event.week] ||
            accumulator[event.week].createdOn.isBefore(event.createdOn)
          ) {
            accumulator[event.week] = event;
          }
          return accumulator;
        }, {}),
        map(event => pick(event, ["data", "type", "createdOn", "week", "day"])),
        map(event => {
          const date = moment(event.createdOn).utc();
          return {
            ...event,
            createdOn: date,
            week: date.isoWeek(),
            day: date.dayOfYear(),
          };
        }),
        sortBy(["createdOn"]),
        filter(
          event =>
            (event.type === "speedRange" &&
              !isNil(event.data.minSPM) &&
              !isNil(event.data.maxSPM)) ||
            (event.type === "scheduledDowntime" &&
              !isNil(event.data.hours) &&
              !isNil(event.data.minutes)),
        ),
      );

      return [setpointChangesRetrieved(processEvents(setpointChanges))];
    } catch (err) {
      return [replace(`/error?code=${err.status}`)];
    }
  },
);
