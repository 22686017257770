import React from "react";
import ReactDOM from "react-dom";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import clsx from "clsx";

import Container from "components/container";

const styles = () => ({
  barContainer: {
    height: 48,
  },
  paper: {
    width: "100%",
    height: 48,
  },
  root: {
    zIndex: 1100,
    position: "relative",
  },
});

/**
 * Injects a subheader underneath the AppBar from anywhere using portals
 *
 * Children will be injected into the subheader as well inside of a page-width
 * container, disable this with `hasContainer={false}` prop
 */
const Subheader = ({classes, children, className, hasContainer = true}) => {
  const ContentContainer = hasContainer ? Container : React.Fragment;
  return ReactDOM.createPortal(
    <>
      <Paper
        className={classes.paper}
        classes={{root: classes.root}}
        square
        elevation={3}
      >
        <ContentContainer>
          <Grid
            container
            spacing={0}
            alignItems={"center"}
            className={clsx(classes.barContainer, className)}
          >
            {children}
          </Grid>
        </ContentContainer>
      </Paper>
    </>,
    document.getElementById("app-bar-sub-header-portal"),
  );
};

export default withStyles(styles)(Subheader);
