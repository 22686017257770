/* eslint-disable jsx-a11y/anchor-is-valid */
import colors from "theme/colors";
import {show} from "actions/well-assignment-list-dialog";
import Aux from "components/aux";
import isEmpty from "lodash/isEmpty";
import React from "react";
import {connect} from "react-redux";

const Component = props => {
  const {dispatch, event, wells, type} = props;
  const {createdOn, performedOn} = event;

  return (
    !isEmpty(wells) && (
      <Aux>
        {` ${type} ${type === "assigned" ? "to" : "from"}`}
        {wells.length > 2 && (
          <a
            onClick={() =>
              dispatch(show({createdOn, performedOn, type, wells}))
            }
            style={{cursor: "pointer", color: colors.ambyOrange[500]}}
            tabIndex={100}
          >
            {` ${wells.length} wells`}
          </a>
        )}
        {wells.length <= 2 && <strong>{` ${wells.join(", ")}`}</strong>}
      </Aux>
    )
  );
};

export default connect()(Component);
