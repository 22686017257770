import get from "lodash/get";

export default ({values, reverse}) => {
  return values.sort(function(a, b) {
    let aV = get(a, "displacement.value", Infinity * reverse);
    let bV = get(b, "displacement.value", Infinity * reverse);

    if (aV === bV) {
      aV = a.displayName.toLowerCase();
      bV = b.displayName.toLowerCase();
    }

    return reverse * ((aV > bV) - (bV > aV));
  });
};
