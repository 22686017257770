export default ({state, action}) => {
  if (!state.selected || state.selected !== action.payload.alertId)
    return state;

  return {
    ...state,
    all: state.all.map(a => {
      if (a.alertId !== state.selected) return a;

      return {
        ...a,
        timeline: [...a.timeline, action.payload],
      };
    }),
  };
};
