import React from "react";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

const styles = {
  container: {
    padding: "16px",
  },
  divider: {
    marginBottom: "16px",
    marginTop: "4px",
  },
  title: {
    fontWeight: 500,
    width: "100%",
  },
};

export default ({children, title}) => {
  return (
    <div style={styles.container}>
      <Typography variant="h6" style={styles.title}>
        {title}
      </Typography>
      <Divider style={styles.divider} />
      {children}
    </div>
  );
};
