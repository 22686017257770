import React from "react";
import {connect} from "react-redux";
import {push} from "react-router-redux";
import {withRouter} from "react-router";
import compose from "recompose/compose";
import pure from "recompose/pure";
import get from "lodash/get";
import InboxIcon from "@material-ui/icons/Inbox";

import colors from "theme/colors";
import PAGING_SOURCES from "constants/paging-sources";

const mapStateToProps = state => {
  const source = get(state, "match.params.source") || state.paging.source;

  return {
    source,
  };
};

const styles = {
  icon: {
    fill: colors.grey[50],
    width: 20,
    height: 20,
    verticalAlign: "bottom",
    padding: 8,
  },
  link: {
    cursor: "pointer",
  },
};

const InboxNav = ({dispatch, source}) => (
  <div
    title="Inbox"
    style={styles.link}
    onClick={() => dispatch(push("/inbox"))}
  >
    <InboxIcon
      style={{
        ...styles.icon,
        ...(source === PAGING_SOURCES.inbox
          ? {fill: colors.ambyOrange[400]}
          : {}),
      }}
    />
  </div>
);

const enhance = compose(
  connect(mapStateToProps),
  withRouter,
  pure,
);

export default enhance(InboxNav);
