import Grid from "@material-ui/core/Grid";
import DialogContent from "@material-ui/core/DialogContent";
import InputAdornment from "@material-ui/core/InputAdornment";

import React from "react";

import FormWarning from "components/form-warning";
import DecimalTextField from "components/forms/decimal-text-field";
import PlainText from "components/forms/plain-text";
import TextField from "components/forms/text-field";

import mapMinutesToHours from "utils/map-minutes-to-hours";

const endAdornment = <InputAdornment position="end">minutes</InputAdornment>;

const TimerRecommendation = props => {
  const {currentValues, well} = props;

  return (
    <DialogContent>
      <Grid container>
        <Grid item xs={12}>
          <PlainText name="description" />
        </Grid>

        <Grid item xs={12}>
          <TextField name="comment" fullWidth label="Comment" />
        </Grid>

        <Grid item xs={6}>
          <DecimalTextField
            endAdornment={endAdornment}
            helperText={mapMinutesToHours(currentValues.acceptedData.runTime)}
            name="acceptedData.runTime"
            fullWidth
            label="Run Time"
          />
        </Grid>

        <Grid item xs={6}>
          <DecimalTextField
            endAdornment={endAdornment}
            helperText={mapMinutesToHours(currentValues.acceptedData.stopTime)}
            name="acceptedData.stopTime"
            fullWidth
            label="Stop Time"
          />
        </Grid>

        {!well.runTimer.enabled && (
          <Grid item xs={12}>
            <FormWarning style={{marginTop: 8}}>
              Run timer settings will be automatically updated if this
              recommendation is accepted.
            </FormWarning>
          </Grid>
        )}
      </Grid>
    </DialogContent>
  );
};

export default TimerRecommendation;
