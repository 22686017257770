import process from "../process";

export const setCalibrateWells = ({state, action}) => {
  return process({
    ...state,
    workflow: {
      ...state.workflow,
      calibrate: {
        ...state.workflow.calibrate,
        ...action.payload,
      },
    },
  });
};
