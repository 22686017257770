import React from "react";
import {withStyles} from "@material-ui/core/styles";
import WarningIcon from "@material-ui/icons/Warning";
import Typography from "@material-ui/core/Typography";
import colors from "theme/colors";

const styleSheet = {
  container: {
    color: colors.warning[500],
  },
  icon: {
    verticalAlign: "middle",
    marginBottom: 2,
    marginRight: 8,
    color: colors.warning[500],
  },
  text: {
    color: colors.warning[500],
    display: "inline-block",
  },
};

const FormWarning = ({children, classes}) =>
  children ? (
    <div className={classes.container}>
      <WarningIcon className={classes.icon} />
      <Typography className={classes.text} variant="subtitle1">
        {children}
      </Typography>
    </div>
  ) : null;

export default withStyles(styleSheet, {name: "FormWarning"})(FormWarning);
