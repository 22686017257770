import store from "store";

export default options => {
  return {
    ...options,
    headers: {
      ...options.headers,
      "Content-Type": "application/json",
      Authorization: `Basic ${options.token || store.getState().auth.token}`,
    },
  };
};
