import parsePrivileges from "./parsers/privileges";

export default data => {
  return {
    assignedOrganizations: data.assignedOrganizations,
    clientUserId: data.clientUserId,
    email: data.email,
    epmPreference: data.epmPreference,
    features: data.features,
    firstName: data.firstName,
    isPumpwellEmployee: data.isPumpwellEmployee,
    lastName: data.lastName,
    mobileCountryCode: data.mobileCountryCode,
    mobilePhone: data.mobilePhone,
    name: `${data.firstName} ${data.lastName}`,
    notificationMethods: data.notificationMethods,
    notifications: data.notifications,
    pictureUrl: data.pictureUrl,
    privileges: parsePrivileges(data),
    termsOfServiceAccepted: data.termsOfServiceAccepted,
    title: data.jobTitle || data.title,
    unitsOfMeasure: data.unitsOfMeasure,
    wells: data.assignedWells || data.wells,
  };
};
