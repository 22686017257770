/* eslint-disable jsx-a11y/anchor-is-valid */
import {connect} from "react-redux";
import {Else, If, Then} from "components/if";
import {setFaultRecovery} from "actions/wells";
import {showResetFault} from "actions/dialogs";
import colors from "theme/colors";
import Divider from "@material-ui/core/Divider";
import formatDateTime from "@ambyint/common/utils/format/format-date-time";
import getStatusColor from "@ambyint/common/utils/wells/get-status-color";
import Grid from "@material-ui/core/Grid";
import hasResetFault from "utils/well/has-reset-fault";
import hasSetFaultRecovery from "utils/well/has-set-fault-recovery";
import hasStatusCodes from "utils/well/has-status-codes";
import PaperContainer from "components/paper-container";
import React from "react";
import Typography from "@material-ui/core/Typography";

import DisplayConditions from "./display-conditions";

import {
  defaultContainerStyle,
  defaultGridContainer,
  defaultLinkStyle,
  defaultMainStyle,
  defaultTitleStyle,
  defaultSubtitleStyle,
} from "./dashboard-styles";

const styles = {
  smallText: {fontSize: "11px", lineHeight: "12px"},
};

const Component = props => {
  const {dispatch, well} = props;
  const faultRecoveryEnabled = !!well.faultRecoveryEnabled;

  const faultRecoveryEnabledStyle = {
    color: faultRecoveryEnabled ? colors.green[500] : colors.red[500],
    textTransform: "uppercase",
  };

  const handleSetFaultRecovery = enabled => () => {
    return dispatch(
      setFaultRecovery({
        downholeLocation: well.downholeLocation,
        enabled,
        well,
      }),
    );
  };

  const handleResetFault = () => () => dispatch(showResetFault(well));

  return (
    <PaperContainer style={defaultContainerStyle}>
      <Grid
        container
        justify={"space-between"}
        spacing={0}
        style={defaultGridContainer}
      >
        <Grid item xs>
          <Typography variant="body1" style={{...defaultTitleStyle}}>
            {`Updated on ${formatDateTime(well.status.communicatedOn, {
              timezone: true,
            })}`}
          </Typography>
          <Typography
            className="well-status-state"
            variant="h5"
            style={{
              ...defaultMainStyle,
              color: getStatusColor(well.status),
            }}
          >
            {well.status.displayName}
          </Typography>
          {well.status.description && (
            <Typography variant="body1" style={{...defaultSubtitleStyle}}>
              {well.status.description}
            </Typography>
          )}
        </Grid>
        <If condition={well.status.hasFault && hasSetFaultRecovery(well)}>
          <Grid item xs={12}>
            <Typography variant="body1">
              Auto fault recovery is currently{" "}
              <span style={faultRecoveryEnabledStyle}>
                {faultRecoveryEnabled ? "enabled" : "disabled"}
              </span>
              .
            </Typography>
            <Typography
              className="fault-text"
              variant="body1"
              style={{...styles.smallText, marginTop: "8px"}}
            >
              <If condition={faultRecoveryEnabled}>
                <Then>
                  <If condition={hasResetFault(well)}>
                    Auto fault recovery failed,{" "}
                    <a style={defaultLinkStyle} onClick={handleResetFault()}>
                      try resetting manually
                    </a>
                    .{" "}
                  </If>
                  ambyint is currently attempting to automatically reset faults
                  when they occur. If you would like to turn this off,{" "}
                  <a
                    style={defaultLinkStyle}
                    onClick={handleSetFaultRecovery(false)}
                  >
                    disable auto fault recovery
                  </a>
                  .
                </Then>
                <Else>
                  <a
                    style={defaultLinkStyle}
                    onClick={handleSetFaultRecovery(true)}
                  >
                    Enable auto fault recovery
                  </a>{" "}
                  to allow ambyint to attempt to automatically reset faults when
                  they occur
                  <If condition={hasResetFault(well)}>
                    {" "}
                    or{" "}
                    <a style={defaultLinkStyle} onClick={handleResetFault()}>
                      try resetting this fault manually
                    </a>
                  </If>
                  .
                </Else>
              </If>
            </Typography>
          </Grid>
        </If>
      </Grid>
      {hasStatusCodes(well) && <Divider />}
      {hasStatusCodes(well) && (
        <Grid
          container
          style={defaultGridContainer}
          justify={"space-between"}
          spacing={0}
        >
          <Grid item className="status-list" xs>
            <Typography variant="body1" style={{...defaultTitleStyle}}>
              {well.status.statuses.length > 1 ? "Codes" : "Code"}
            </Typography>
            {well.status.statuses.map((code, i) => (
              <Typography
                className="status-description"
                key={i}
                variant="body1"
                style={{...defaultMainStyle}}
              >
                {code.description}
              </Typography>
            ))}
          </Grid>
        </Grid>
      )}
      <DisplayConditions well={well} />
    </PaperContainer>
  );
};

export default connect()(Component);
