import colors from "theme/colors";

export default {
  pageContainer: {
    backgroundColor: colors.ambyBlue[500],
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    overflow: "auto",
  },
  loginContainer: {
    backgroundColor: "white",
    padding: 32,
    maxWidth: 600,
    borderRadius: "2.5px",
  },
  typography: {
    fontSize: 16,
    letterSpacing: "0.010em",
    lineHeight: 1.4,
    color: colors.grey[600],
  },
  link: {
    color: colors.ambyOrange[500],
    textDecoration: "none",
    cursor: "pointer",
  },
  bullet: {
    padding: 5,
    fontSize: 22,
  },
  bulletText: {
    color: colors.grey[600],
    fontSize: 14,
    lineHeight: 1.4,
  },
  footer: {
    color: "white",
    padding: 48,
  },
  ul: {
    margin: 7,
  },
  fieldContainer: {
    minHeight: 85,
  },
  container: {
    marginTop: 0,
    padding: 24,
    maxWidth: 600,
  },
  progress: {
    width: "7%",
    height: "7%",
    position: "absolute",
    top: "40vh",
  },
  logoContainer: {
    position: "relative",
    verticalAlign: "bottom",
    fill: colors.grey[50],
    width: "30%",
    maxWidth: 320,
    minWidth: 168,
    minHeight: 96,
    height: "22%",
    marginBottom: 8,
  },
  logo: {
    position: "absolute",
    bottom: 0,
    verticalAlign: "bottom",
    fill: colors.grey[50],
    width: "100%",
    height: "50%",
    paddingBottom: 16,
  },
};
