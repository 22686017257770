import React from "react";

import SettingsUpdated from "../settings-updated";

export default props => {
  return (
    <SettingsUpdated
      {...props}
      changesRoute="/setup/total-reduction-ratio"
      settingsName="total reduction ratio"
    />
  );
};
