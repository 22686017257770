import moment from "moment";
import times from "lodash/times";

import {VIEW} from "constants/well-health";

// Set the date range for the report.
// This report functions in Monday-Sunday weeks.
// Starting from yesterday, go back 25 isoWeeks (which gives us 26 isoWeeks including the week containing yesterday
// The result ensures the report is always displaying 26 weeks.
// The start date will always be a monday.
// The end date will be yesterday.
// Therefore, the range is between 176 and 182 days.
// The last (most recent) isoWeek containing yesterday may have only 1 day (if yesterday is a monday) or may have
// up to 7 days (if yesterday is a sunday).
// Therefore the most recent week's data displayed on the graph will often be a partial week.
const endDate = moment()
  .utc()
  .endOf("day");

const startDate = endDate
  .clone()
  .subtract(25, "isoWeeks")
  .startOf("isoWeek");

const weeks = [];
const displayWeeks = [];
times(26, i => {
  const week = startDate
    .clone()
    .utc()
    .startOf("day")
    .add(i, "weeks");

  weeks.push(week.format("GGGG-W"));
  displayWeeks.push(week.format("MMM DD"));
});

const numDays = endDate
  .clone()
  .utc()
  .startOf("day")
  .diff(
    startDate
      .clone()
      .utc()
      .startOf("day"),
    "days",
  );

const days = [];
const displayDays = [];
times(numDays, i => {
  const day = startDate
    .clone()
    .utc()
    .startOf("day")
    .add(i, "day");

  days.push(day.format("YYYY-MM-DD"));
  displayDays.push(day.format("MMM DD"));
});

const defaults = {
  dataForWellId: null,
  wellbore: {
    ready: false,
    view: VIEW.WELLBORE,
    surveySeries: {},
    rodSeries: {},
    plungerSeries: {},
  },
  classifications: {
    focus: null,
    fetched: false,
    counts: {},
    series: {},
  },
  production: {
    focus: null,
    fetched: false,
    liquidProduction: [],
    strokes: [],
    unitsOfMeasure: null,
  },
  compressiveStress: {
    focus: null,
    fetched: false,
    stressSeries: [],
    compressedRodsSeries: [],
    medianCompressedRodsSeries: [],
  },
  setpointChanges: {
    fetched: false,
    changes: [],
  },
  quality: {
    fetched: false,
    rankings: {},
  },
  date: {
    startDate,
    endDate,
    days,
    weeks,
    displayDays,
    displayWeeks,
  },
};

export default defaults;
