import React from "react";
import clsx from "clsx";

import styles from "./button-group.module.css";

function ButtonGroup({children, className}) {
  return (
    <div className={clsx(styles["button-group"], className)}>{children}</div>
  );
}

export default ButtonGroup;
