import React, {useEffect} from "react";
import compose from "lodash/fp/compose";
import {connect} from "react-redux";
import Paper from "@material-ui/core/Paper/Paper";
import Typography from "@material-ui/core/Typography/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

import {fetchClassifications} from "actions/wells/well-health";

import CCRGraph from "../ccr-graph";
import KPIPanelCCR from "../kpi-panel-ccr";
import Progress from "../progress";

const styles = ({typography, colors, spacing}) => ({
  panel: {
    padding: `${spacing(3)}px ${spacing(4)}px`,
    height: "100%",
    boxSizing: "border-box",
  },

  container: {
    display: "grid",
    gridTemplateRows: "auto 310px",
    height: "100%",
  },

  info: {
    color: colors.warmGrey[500],
    paddingTop: spacing(1),
    paddingBottom: spacing(3),
  },

  graphs: {
    display: "grid",
    gridTemplateColumns: "minmax(0, 1fr) 70px",
    gridTemplateRows: "40px 270px",
    gridTemplateAreas: '"title legend" "graph legend"',
    gridColumnGap: `${spacing(2)}px`,
  },

  title: {
    gridArea: "title",
    color: colors.warmGrey[900],
    marginBottom: spacing(2),
  },

  graph: {
    gridArea: "graph",
    alignSelf: "self-end",
  },

  legend: {
    gridArea: "legend",
  },

  heading: {
    marginBottom: spacing(3),
  },
});

const mapStateToProps = ({wells, wellHealth}) => {
  return {
    wellsLoaded: wells.fetched,
    ...wellHealth.date,
    classificationsFetched: wellHealth.classifications.fetched,
  };
};

const mapDispatchToProps = {
  fetchClassifications,
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
);

const CCRPanel = ({
  well,
  classes,
  fetchClassifications,
  classificationsFetched,
  startDate,
  endDate,
}) => {
  const {downholeLocation, wellId} = well;

  useEffect(
    () => {
      if (!classificationsFetched) {
        fetchClassifications(
          wellId,
          downholeLocation,
          startDate.format("YYYY-MM-DD"),
          endDate.format("YYYY-MM-DD"),
        );
      }
    },
    [
      classificationsFetched,
      fetchClassifications,
      wellId,
      downholeLocation,
      startDate,
      endDate,
    ],
  );

  return (
    <Paper className={classes.panel}>
      <div className={classes.container}>
        <div className={classes.heading}>
          <Typography variant="h6">Card Classifications</Typography>
          <Typography variant="caption" className={classes.info}>
            This is an AI-based downhole dynamometer card classifier that breaks
            down the percentage of time the pump experiences conditions of a
            full pump (normal), gas interference, lowload or pump off. When the
            model classification does not meet a threshold of certainty, it will
            classify cards as unclassified.
          </Typography>
        </div>

        {classificationsFetched ? (
          <div className={classes.graphs}>
            <Typography variant="subtitle1" className={classes.title}>
              Weekly for 6 months
            </Typography>
            <div className={classes.graph}>
              <CCRGraph />
            </div>
            <div className={classes.legend}>
              <KPIPanelCCR />
            </div>
          </div>
        ) : (
          <Progress />
        )}
      </div>
    </Paper>
  );
};

export default enhance(CCRPanel);
