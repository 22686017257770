import React from "react";

import Typography from "@material-ui/core/Typography";

import colors from "theme/colors";

import FormButton from "components/form-button";
import PaperContainer from "components/paper-container";

const ReinviteUser = props => {
  const {onClick} = props;

  return (
    <PaperContainer style={{padding: 4, marginBottom: 32}}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="body2"
          style={{
            color: colors.grey[500],
            margin: 16,
            maxWidth: 350,
            lineHeight: "17px",
          }}
        >
          This user has not completed the invitation, you can reissue the invite
          which will email them a fresh invite link that they can use to
          complete their invitation.
        </Typography>

        <div style={{display: "flex", justifyContent: "flex-end"}}>
          <FormButton
            variant={"contained"}
            style={{
              height: "40px",
              paddingLeft: 48,
              paddingRight: 48,
              margin: 16,
            }}
            type="submit"
            onClick={onClick}
          >
            Reinvite User
          </FormButton>
        </div>
      </div>
    </PaperContainer>
  );
};

export default ReinviteUser;
