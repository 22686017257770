import isEmpty from "lodash/isEmpty";

const vowels = ["A", "E", "I", "O", "U"];

export default string => {
  if (isEmpty(string)) return "";

  const trimmed = string.trim();
  const firstLetter = trimmed[0];

  return vowels.includes(firstLetter.toUpperCase()) ? "an" : "a";
};
