import get from "lodash/get";
import moment from "moment";

import {RECOMMENDATION_TYPES} from "constants/recommendations";

// This filters out the recommendations the recommendation page isn't mapped out for.
// for example EPMs and WellPilots

const isVFD = ({type}) => {
  return (
    type === RECOMMENDATION_TYPES.LUFKIN_DOWNHOLE_VFD ||
    type === RECOMMENDATION_TYPES.LUFKIN_DOWNHOLE_VFD_SECONDARY ||
    type === RECOMMENDATION_TYPES.SPEED_RANGE
  );
};

const isPOC = ({type}) => {
  return (
    type === RECOMMENDATION_TYPES.LUFKIN_DOWNHOLE ||
    type === RECOMMENDATION_TYPES.LUFKIN_DOWNHOLE_SECONDARY ||
    type === RECOMMENDATION_TYPES.IDLE_TIME
  );
};

const isEPM = ({type}) => {
  return type === RECOMMENDATION_TYPES.TIMER;
};

const filterRecommendations = (recommendations, options) => {
  return recommendations.filter(recommendation => {
    if (!recommendation) return false;
    const status = get(options, "status", recommendation.status);

    return (
      status === recommendation.status &&
      moment(recommendation.createdOn).isAfter(
        moment()
          .endOf("day")
          .subtract(7, "days"),
      ) &&
      (isPOC(recommendation) || isVFD(recommendation) || isEPM(recommendation))
    );
  });
};

export default filterRecommendations;
