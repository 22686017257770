import {connect} from "react-redux";
import {reduxForm, formValueSelector} from "redux-form";
import React from "react";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import parseDownholeInformation from "@ambyint/common/parsers/well/parse-downhole-information";
import parseRodSection from "@ambyint/common/parsers/well/parse-rod-section";

import FormButton from "components/form-button";
import FormHeader from "components/form-header";
import PaperContainer from "components/paper-container";
import RadioGroup from "components/forms/radio-group";
import SearchInputWithSuggestions from "components/forms/search-input-with-suggestions";

import {saveDownholeInformation} from "actions/wells";
import {fetch, filter} from "actions/tubing";

import DecimalTextField from "components/forms/decimal-text-field";
import SaveCancelButtons from "components/save-cancel-buttons";

import {roundWithUnits} from "utils/round-with-units";
import compose from "utils/compose";
import getUser from "utils/get-user";
import spreadIf from "utils/spread-if";
import unitsOf from "utils/units-of";

import BuildRodSection from "./build-rod-section.js";
import ViewRodSection from "./view-rod-section.js";
import calculateTotalRodSectionsLength from "./calculate-total-rod-sections-length";
import formatTubingDescription from "./format-tubing-description";
import validate from "./validate";

const selector = formValueSelector("downhole-information-edit-view");

const mapStateToProps = (state, props) => {
  // If the well is new, downholeInformation is likely undefined. Load units here.
  // Note that we can't move this into reducer logic easily as parseDownholeInformation fires multiple times
  // and sometimes downholeInformation is missing even though it exists (probably the web socket needs updated).
  const downholeInformation =
    props.well.downholeInformation ||
    parseDownholeInformation(
      {downholeInformation: {}},
      getUser().unitsOfMeasure,
    );

  const casing = get(downholeInformation, "casing");
  const serviceFactor = get(downholeInformation, "serviceFactor");
  const tubing = get(downholeInformation, "tubing");
  const tubingDescription = formatTubingDescription(
    get(downholeInformation, "tubing"),
  );
  const plungerDiameter = roundWithUnits(
    get(downholeInformation, "plunger.diameter"),
    3,
  );
  const plungerLength = roundWithUnits(
    get(downholeInformation, "plunger.length"),
    3,
  );
  const plungerMass = roundWithUnits(
    get(downholeInformation, "plunger.mass"),
    2,
  );
  const sections = get(downholeInformation, "sections");

  return {
    tubing: state.tubing,
    ...spreadIf(props.well, {
      initialValues: {
        casing,
        plungerDiameter,
        plungerLength,
        plungerMass,
        sections,
        serviceFactor,
        tubing,
        tubingDescription,
      },
    }),
    currentValues: {
      sections: selector(state, "sections"),
      tubing: selector(state, "tubing"),
    },
  };
};

class DownholeInformationEditView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowingBuildRodSection: [],
    };
  }

  save = (values, func, props) => {
    if (Object.keys(props.syncErrors).length > 0) {
      return;
    }

    this.props.dispatch(
      saveDownholeInformation({
        casing: get(values, "casing"),
        serviceFactor: get(values, "serviceFactor"),
        tubing: get(values, "tubing"),
        plunger: {
          diameter: get(values, "plungerDiameter"),
          length: get(values, "plungerLength"),
          mass: get(values, "plungerMass"),
        },
        sections: get(values, "sections"),
        downholeLocation: this.props.well.downholeLocation,
        wellId: this.props.well.wellId,
      }),
    );

    this.props.onClose();
  };

  componentWillMount() {
    this.props.dispatch(fetch());
  }

  saveRodSection = index => rodSection => {
    const sections = [...this.props.currentValues.sections];
    sections.splice(index, 1, rodSection);
    this.props.change("sections", sections);
  };

  createRodSection = () => {
    const unitsOfMeasure = getUser().unitsOfMeasure;

    const sections = [...(this.props.currentValues.sections || [])];
    const newRodSection = parseRodSection({}, unitsOfMeasure);
    sections.push(newRodSection);
    this.props.change("sections", sections);
    this.setShowBuildRodSection(sections.length - 1)("create");
  };

  deleteRodSection = index => () => {
    const sections = cloneDeep(this.props.currentValues.sections);
    sections.splice(index, 1);
    this.props.change("sections", sections);

    const isShowingBuildRodSection = [...this.state.isShowingBuildRodSection];
    isShowingBuildRodSection.splice(index, 1);
    this.setState({isShowingBuildRodSection});
  };

  copyRodSection = index => () => {
    const sections = [...this.props.currentValues.sections];
    const newSection = cloneDeep(sections[index]);
    sections.splice(index, 0, newSection);
    this.props.change("sections", sections);
  };

  moveRodSection = index => offset => {
    const sections = [...this.props.currentValues.sections];
    const section = sections.splice(index, 1)[0];
    const adjustedOffset = offset > 0 ? index + offset : index + offset;
    const newIndex = Math.max(0, Math.min(sections.length, adjustedOffset));
    sections.splice(newIndex, 0, section);
    this.props.change("sections", sections);
    this.closeAllBuildRodSection();
  };

  closeAllBuildRodSection = () => {
    let isShowingBuildRodSection = [...this.state.isShowingBuildRodSection];
    isShowingBuildRodSection = isShowingBuildRodSection.map(() => false);
    this.setState({isShowingBuildRodSection});
  };

  hideShowBuildRodSection = index => () => {
    this.setShowBuildRodSection(index)("");
  };

  setShowBuildRodSection = index => action => {
    let isShowingBuildRodSection = [...this.state.isShowingBuildRodSection];
    isShowingBuildRodSection[index] = action;
    if (isShowingBuildRodSection[index]) {
      // Don't show multiple rod sections, since we are only storing parts data (dimensions, couplings, etc)
      // for just one section at a time.
      isShowingBuildRodSection = isShowingBuildRodSection.map(
        (isShowingSection, i) => {
          if (i !== index) {
            return "";
          }

          return isShowingSection;
        },
      );
    }

    this.setState({isShowingBuildRodSection});
  };

  rodSectionIsBeingModified = () =>
    this.state.isShowingBuildRodSection.find(
      status => status === "create" || status === "update",
    );

  renderRodSection = (section, key) => {
    const {isShowingBuildRodSection} = this.state;
    const BuildMyRodSection = BuildRodSection(key);
    return isShowingBuildRodSection[key] ? (
      <BuildMyRodSection
        key={key}
        section={section}
        actionType={this.state.isShowingBuildRodSection[key]}
        saveRodSection={this.saveRodSection(key)}
        deleteRodSection={this.deleteRodSection(key)}
        tubing={this.props.currentValues.tubing}
        onClose={this.hideShowBuildRodSection(key)}
      />
    ) : (
      <Grid key={key} item xs={12} style={{marginBottom: 30}}>
        <ViewRodSection
          key={key}
          section={section}
          copyRodSection={this.copyRodSection(key)}
          moveRodSection={this.moveRodSection(key)}
          showBuildRodSection={() => this.setShowBuildRodSection(key)("update")}
          canEdit={!this.rodSectionIsBeingModified()}
        />
      </Grid>
    );
  };

  render() {
    const {
      handleSubmit,
      onClose,
      pristine,
      submitting,
      reset,
      currentValues: {sections},
    } = this.props;
    const totalRodSectionsLength = calculateTotalRodSectionsLength(sections);
    const totalRodSectionsLengthUnits =
      sections && sections.length >= 1 && unitsOf(sections[0].rodLength);
    const createRodSectionIsOpen = this.rodSectionIsBeingModified();

    return (
      <PaperContainer extraPadded style={{maxWidth: "750px"}}>
        <form onSubmit={handleSubmit(this.save)}>
          <Grid container direction={"row"} spacing={3} style={{maxWidth: 776}}>
            <Grid item xs={12}>
              <FormHeader>Tubing</FormHeader>
            </Grid>
            <Grid item xs={12}>
              <SearchInputWithSuggestions
                name="tubingDescription"
                label="Type"
                placeholder="Choose tubing type"
                onSearch={event => {
                  event.value !== undefined &&
                    this.props.dispatch(filter({phrase: event.value}));
                }}
                throttle={1500}
                suggestions={
                  (this.props.tubing && this.props.tubing.filtered) || []
                }
                getSuggestionText={formatTubingDescription}
                getSuggestionValue={tubing => ({
                  text: formatTubingDescription(tubing),
                  tubing,
                })}
                onSuggestionClick={data => {
                  this.props.change("tubing", data.tubing);
                }}
                showDropdownArrow
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormHeader>Plunger</FormHeader>
            </Grid>
            <Grid item xs={4}>
              <DecimalTextField
                fullWidth
                name={"plungerDiameter"}
                label={"Diameter"}
                required
              />
            </Grid>
            <Grid item xs={4}>
              <DecimalTextField
                fullWidth
                name={"plungerLength"}
                label={"Length"}
                required
              />
            </Grid>
            <Grid item xs={4}>
              <DecimalTextField
                fullWidth
                name={"plungerMass"}
                label={"Mass"}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormHeader>Service Factor</FormHeader>

              <RadioGroup
                name="serviceFactor"
                options={[
                  {value: "0.6", label: "0.6"},
                  {value: "0.7", label: "0.7"},
                  {value: "0.8", label: "0.8"},
                  {value: "0.9", label: "0.9"},
                  {value: "1", label: "1"},
                ]}
                style={{
                  flexDirection: "row",
                }}
                itemStyle={{
                  marginRight: 48,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormHeader>Rod Sections</FormHeader>
            </Grid>
            {sections && sections.map(this.renderRodSection)}

            {totalRodSectionsLengthUnits && (
              <Grid item xs={12}>
                <div style={{marginTop: 16}}>
                  <Typography
                    variant="h6"
                    style={{display: "inline", marginLeft: 20}}
                  >
                    {totalRodSectionsLength}
                  </Typography>

                  <Typography variant="body1" style={{display: "inline"}}>
                    {totalRodSectionsLengthUnits} of total rod sections
                  </Typography>
                </div>
              </Grid>
            )}

            {!createRodSectionIsOpen && (
              <Grid item xs={12}>
                <FormButton
                  variant={"contained"}
                  fullWidth
                  style={{color: "white"}}
                  onClick={this.createRodSection}
                >
                  + Add Section
                </FormButton>
              </Grid>
            )}
          </Grid>

          <SaveCancelButtons
            pristine={pristine}
            reset={compose(
              onClose,
              reset,
            )}
            submitting={submitting}
            onSaveClick={handleSubmit(this.save)}
            invalid={createRodSectionIsOpen}
          />
        </form>
      </PaperContainer>
    );
  }
}

const Component = reduxForm({
  form: "downhole-information-edit-view",
  enableReinitialize: true,
  validate,
})(DownholeInformationEditView);

export default connect(mapStateToProps)(Component);
