import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import withStyles from "@material-ui/core/styles/withStyles";
import CheckboxMarkedCircle from "mdi-material-ui/CheckboxMarkedCircle";
import CheckboxBlankCircleOutline from "mdi-material-ui/CheckboxBlankCircleOutline";

const styles = ({colors}) => ({
  defaultCheckbox: {
    height: 20,
    width: 20,
  },
  hide: {
    opacity: 0,
  },
  show: {
    opacity: 1,
  },
  root: {
    padding: 0,
  },
  selected: {
    color: "white",
  },
  normalUnselectedLight: {
    color: colors.warmGrey[200],
  },
  normalUnselectedDark: {
    color: colors.warmGrey[500],
  },
  anomalyUnselectedLight: {
    color: colors.yellowVivid[50],
    opacity: 0.7,
  },
});

const getStateStyles = ({
  variant,
  isHoverOnCard,
  isHoverOnHeader,
  isSelectionMode,
  classes,
}) => {
  const isHoverOnlyOnCard = isHoverOnCard && !isHoverOnHeader;
  const isNormal = variant === "normal";
  if (isHoverOnlyOnCard && !isSelectionMode) {
    return {
      selectedStyle: classes.selected,
      unselectedStyle: isNormal
        ? classes.normalUnselectedLight
        : classes.anomalyUnselectedLight,
    };
  } else if (isHoverOnHeader || isSelectionMode) {
    return {
      selectedStyle: classes.selected,
      unselectedStyle: isNormal
        ? classes.normalUnselectedDark
        : classes.selected,
    };
  }

  // Idle state
  return {
    selectedStyle: classes.selected,
    unselectedStyle: classes.hide,
  };
};

const TagCheckbox = ({
  classes,
  selected,
  isHoverOnCard,
  isHoverOnHeader,
  isSelectionMode,
  variant,
}) => {
  const {selectedStyle, unselectedStyle} = getStateStyles({
    variant,
    isHoverOnCard,
    isHoverOnHeader,
    isSelectionMode,
    selected,
    classes,
  });
  const showSelectionIcon =
    isSelectionMode && !(isHoverOnCard || isHoverOnHeader);

  const UnselectedIcon = showSelectionIcon
    ? CheckboxBlankCircleOutline
    : CheckboxMarkedCircle;

  return (
    <Checkbox
      checked={selected}
      className={classes.defaultCheckbox}
      classes={{root: classes.root}}
      icon={<UnselectedIcon className={unselectedStyle} />}
      checkedIcon={<CheckboxMarkedCircle className={selectedStyle} />}
    />
  );
};

export default withStyles(styles)(TagCheckbox);
