import * as eventTypes from "constants/event-types";

import AlertAcknowledged from "./alert-acknowledged";
import AlertCreated from "./alert-created";
import AlertResolved from "./alert-resolved";
import AllowedToOperate from "./allowed-to-operate";
import AutoAcceptedRecommendations from "./auto-accepted-recommendations";
import Card from "./card";
import DeviceAssignment from "./device-assignment";
import DownholeInformation from "./downhole-information";
import DriveConnectionStatus from "./drive-connection-status";
import DriveData from "./drive-data";
import DriveFault from "./drive-fault";
import FaultRecovery from "./fault-recovery";
import GracePeriodSet from "./grace-period-set";
import GracePeriodUnset from "./grace-period-unset";
import ManualFaultReset from "./manual-fault-reset";
import MotorDetails from "./motor-details";
import NewRecommendation from "./new-recommendation";
import PowerStatus from "./power-status";
import PumpingUnit from "./pumping-unit";
import RecommendationAccepted from "./recommendation-accepted";
import RecommendationDismissed from "./recommendation-dismissed";
import Reservoir from "./reservoir";
import Resistance from "./resistance";
import RunningStatus from "./running-status";
import SafeOperatingParameters from "./safe-operating-parameters";
import ScheduledDowntime from "./scheduled-downtime";
import SetBasicWellDetails from "./set-basic-well-details";
import SetFaultRecovery from "./set-fault-recovery";
import SpeedProfile from "./speed-profile";
import SpeedRange from "./speed-range";
import StatusCodes from "./status-codes";
import surfaceCardRequested from "./surface-card-requested";
import surfaceCardRequestFulfilled from "./surface-card-request-fulfilled";
import TotalReductionRatio from "./total-reduction-ratio";
import WellAnalogInputChanged from "./well-analog-input-changed";
import WellCurrentViolation from "./well-current-violation";
import WellPumpOffSettingChanged from "./well-pump-off-setting-changed";
import WellResetMalfunctions from "./well-reset-malfunctions";
import WellRunTimer from "./well-run-timer";
import WellTorqueViolation from "./well-torque-violation";
import Wellbore from "./wellbore";

export default {
  [eventTypes.ALERT_ACKNOWLEDGED]: AlertAcknowledged,
  [eventTypes.ALERT_CREATED]: AlertCreated,
  [eventTypes.ALERT_RESOLVED]: AlertResolved,
  [eventTypes.ALLOWED_TO_OPERATE]: AllowedToOperate,
  [eventTypes.AUTO_ACCEPTED_RECOMMENDATIONS]: AutoAcceptedRecommendations,
  [eventTypes.CARDS]: Card,
  [eventTypes.CLEAR_ERRORS]: WellResetMalfunctions,
  [eventTypes.DEVICE_ASSIGNED]: DeviceAssignment,
  [eventTypes.DEVICE_REMOVED]: DeviceAssignment,
  [eventTypes.DOWNHOLE_INFORMATION]: DownholeInformation,
  [eventTypes.DRIVE_CONNECTION_STATUS]: DriveConnectionStatus,
  [eventTypes.DRIVE_DATA]: DriveData,
  [eventTypes.DRIVE_FAULT]: DriveFault,
  [eventTypes.FAULT_RECOVERY]: FaultRecovery,
  [eventTypes.GRACE_PERIOD_SET]: GracePeriodSet,
  [eventTypes.GRACE_PERIOD_UNSET]: GracePeriodUnset,
  [eventTypes.GENERATED_CARDS]: Card,
  [eventTypes.MANUAL_FAULT_RESET]: ManualFaultReset,
  [eventTypes.MOTOR_DETAILS]: MotorDetails,
  [eventTypes.NEW_RECOMMENDATION]: NewRecommendation,
  [eventTypes.POWER_STATUS]: PowerStatus,
  [eventTypes.PUMPING_UNIT]: PumpingUnit,
  [eventTypes.RAW_CARDS]: Card,
  [eventTypes.RECOMMENDATION_ACCEPTED]: RecommendationAccepted,
  [eventTypes.RECOMMENDATION_DISMISSED]: RecommendationDismissed,
  [eventTypes.RESERVOIR]: Reservoir,
  [eventTypes.RESISTANCE]: Resistance,
  [eventTypes.RUNNING_STATUS]: RunningStatus,
  [eventTypes.SAFE_OPERATING_PARAMETERS]: SafeOperatingParameters,
  [eventTypes.SCHEDULED_DOWNTIME]: ScheduledDowntime,
  [eventTypes.SET_BASIC_WELL_DETAILS]: SetBasicWellDetails,
  [eventTypes.SET_FAULT_RECOVERY]: SetFaultRecovery,
  [eventTypes.SPEED_PROFILE]: SpeedProfile,
  [eventTypes.SPEED_RANGE]: SpeedRange,
  [eventTypes.STATUS_CODES]: StatusCodes,
  [eventTypes.SURFACE_CARD_REQUESTED]: surfaceCardRequested,
  [eventTypes.SURFACE_CARD_REQUEST_FULFILLED]: surfaceCardRequestFulfilled,
  [eventTypes.TOTAL_REDUCTION_RATIO]: TotalReductionRatio,
  [eventTypes.WELL_ANALOG_INPUT_CHANGED]: WellAnalogInputChanged,
  [eventTypes.WELL_CURRENT_VIOLATION]: WellCurrentViolation,
  [eventTypes.WELL_PUMP_OFF_SETTING_CHANGED]: WellPumpOffSettingChanged,
  [eventTypes.WELL_RESET_MALFUNCTIONS]: WellResetMalfunctions,
  [eventTypes.WELL_RUN_TIMER]: WellRunTimer,
  [eventTypes.WELL_TORQUE_VIOLATION]: WellTorqueViolation,
  [eventTypes.WELLBORE]: Wellbore,
};
