import React from "react";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = ({palette, colors}) => ({
  outline: {
    backgroundColor: colors.warmGrey[50],
    "&:hover": {
      color: colors.warmGrey[900],
      backgroundColor: colors.blue[50],
    },
    justifyContent: "flex-start",
  },
  active: {
    color: palette.common.white,
    backgroundColor: colors.blue[500],
    "&:hover": {
      color: palette.common.white,
      backgroundColor: colors.blue[500],
    },
  },
});

const ButtonSegmented = ({classes, children, isActive, onClick}) => (
  <Button
    className={clsx(classes.outline, {
      [classes.active]: isActive,
    })}
    variant="outlined"
    onClick={onClick}
  >
    {children}
  </Button>
);

export default withStyles(styles)(ButtonSegmented);
