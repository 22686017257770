import React from "react";

import colors from "theme/colors";

const ICON_PATH =
  "M0 93.7l183.6-25.3v177.4H0V93.7zm0 324.6l183.6 25.3V268.4H0v149.9zm203.8 28L448 480V268.4H203.8v177.9zm0-380.6v180.1H448V32L203.8 65.7z";

export default props => {
  const {
    backgroundColor = colors.ambyOrange[400],
    fillColor = colors.white,
    size = 20,
    style = {},
  } = props;

  return (
    <svg
      height={size}
      width={size}
      viewBox={"0 0 488 512"}
      style={{
        backgroundColor,
        borderRadius: "2em",
        padding: "4px",
        ...style,
      }}
    >
      <path
        d={ICON_PATH}
        fill={fillColor}
        style={{transform: "scale(0.75) translate(17.5%, 12.5%)"}}
      />
    </svg>
  );
};
