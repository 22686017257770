import React from "react";
import compose from "lodash/fp/compose";
import {connect} from "react-redux";

import {withPageview} from "components/analytics";
import findWell from "utils/find-well";

import WellHealth from "./well-health";

const mapStateToProps = (state, props) => {
  return {
    well: findWell(state.wells, props.downholeLocation),
  };
};

const enhance = compose(
  withPageview(),
  connect(mapStateToProps),
);

const Tab = ({well}) => {
  return (
    <div style={{height: "calc(100% - 60px)"}}>
      <WellHealth well={well} />
    </div>
  );
};

export default enhance(Tab);
