import valueOf from "utils/value-of";

function parseSaveSafeOperatingParameters(options) {
  const {
    maxSpeed,
    minSpeed,
    maxDowntime,
    minDowntime,
    maxStoptime,
    minStoptime,
  } = options;

  const parsed = {
    maxSpeed: valueOf(maxSpeed),
    minSpeed: valueOf(minSpeed),
    maxDowntime,
    minDowntime,
    maxStoptime,
    minStoptime,
  };

  return parsed;
}

export default parseSaveSafeOperatingParameters;
