import forEach from "lodash/forEach";
import uniq from "lodash/uniq";

import {mappingTypes} from "constants/well-event-import-mapping-types";
import getUser from "utils/get-user";

import {date} from "./date";
import {max} from "./max";
import {min} from "./min";
import {required} from "./required";
import {requiredColumns} from "./required-columns";
import {string} from "./string";
import {wellAccess} from "./well-access";

const maxValues = {
  PRESSURE: 100000,
  PRODUCTION: 10000,
  SPM: 20,
};

const types = {
  [mappingTypes.CASING_PRESSURE]: [min(0), max(maxValues.PRESSURE)],
  [mappingTypes.CREATED_ON]: [required, date],
  [mappingTypes.DOWNHOLE_LOCATION]: [required, string, wellAccess],
  [mappingTypes.GAS_PRODUCTION]: [min(0), max(maxValues.PRODUCTION)],
  [mappingTypes.IGNORE]: [],
  [mappingTypes.OIL_PRODUCTION]: [min(0), max(maxValues.PRODUCTION)],
  [mappingTypes.SPM]: [min(0), max(maxValues.SPM)],
  [mappingTypes.TUBING_PRESSURE]: [min(0), max(maxValues.PRESSURE)],
  [mappingTypes.WATER_PRODUCTION]: [min(0), max(maxValues.PRODUCTION)],
};

export const validateRow = mappings => row => {
  const user = getUser();
  const errors = [...requiredColumns(mappings)];

  forEach(mappings, (mapping, colIndex) => {
    const validators = types[mapping.type];

    forEach(validators, validate => {
      try {
        validate(row[colIndex], user);
      } catch (err) {
        errors.push(`${mapping.display}: ${err.message}`);
      }
    });
  });

  return uniq(errors);
};
