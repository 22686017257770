import {types} from "./types";

export const setSearchCriteria = ({criteria}) => {
  return {
    type: types.setSearchCriteria,
    payload: {
      criteria,
    },
  };
};
