import React, {useEffect} from "react";
import {connect} from "react-redux";
import compose from "lodash/fp/compose";
import get from "lodash/get";
import Typography from "@material-ui/core/Typography/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

import {setWellId} from "actions/wells/well-health";
import {fetchSetpointChanges} from "actions/wells/well-health";
import PUMPOFF_SETTING_MODES from "constants/pumpoff-setting-modes";

import CCRPanel from "./components/ccr-panel";
import CompressiveStressPanel from "./components/compressive-stress-panel";
import StrokesVsProductionPanel from "./components/strokes-vs-production-panel";
import WellborePanel from "./components/wellbore-panel";
import WellboreQualityPanel from "./components/wellbore-quality-panel";

const styles = ({spacing, colors, breakpoints}) => ({
  root: {
    margin: "0 auto",
    padding: "20px",
    minWidth: "728px",
    maxWidth: "1140px",

    [breakpoints.down(1180)]: {
      width: "728px",
    },
  },

  rule: {
    marginBottom: spacing(4),
    height: "1px",
    color: colors.warmGrey[200],
    backgroundColor: colors.warmGrey[200],
    border: "none",
  },

  container: {
    display: "grid",
    gridTemplateColumns: "minmax(0,1fr) minmax(0,1fr)",
    gridGap: "16px",
    marginTop: spacing(4),
    marginBottom: spacing(8),

    [breakpoints.down(1180)]: {
      gridTemplateColumns: "minmax(0,1fr)",
    },
  },

  design: {
    gridTemplateAreas:
      '"wellboreQuality wellboreGraph" "compression wellboreGraph"',
    gridTemplateRows: "2fr 6fr",

    [breakpoints.down(1180)]: {
      gridTemplateRows: "auto",
      gridTemplateAreas: '"wellboreQuality" "wellboreGraph" "compression"',
    },
  },

  wellboreQuality: {
    gridArea: "wellboreQuality",
  },

  wellboreGraph: {
    gridArea: "wellboreGraph",
  },

  compression: {
    gridArea: "compression",
  },
});

const mapStateToProps = ({wellHealth}) => ({
  dataForWellId: wellHealth.dataForWellId,
  ...wellHealth.date,
  setpointChangesFetched: wellHealth.setpointChanges.fetched,
});

const mapDispatchToProps = {
  setWellId,
  fetchSetpointChanges,
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
);

const WellHealth = ({
  classes,
  well,
  dataForWellId,
  setWellId,
  startDate,
  endDate,
  fetchSetpointChanges,
  setpointChangesFetched,
}) => {
  const {wellId, downholeLocation, pumpOffSetting} = well;
  const driveMode = get(
    pumpOffSetting,
    "mode.value",
    PUMPOFF_SETTING_MODES.VFD_DOWNHOLE,
  );

  // Check if the well being passed in is the different than the well we have data loaded for.
  // If it is, set the new well id, which will reset the state of the wellHealth object in the store.
  useEffect(
    () => {
      if (wellId !== dataForWellId) {
        setWellId(wellId);
      }
    },
    [wellId, dataForWellId, setWellId],
  );

  // If setpoints have not been retrieved, grab them, as many graphs in this report will use them.
  useEffect(
    () => {
      if (!setpointChangesFetched) {
        // Based on the drive mode, determine which setpoint events we are going to need to show.
        const events = [];
        switch (driveMode) {
          case PUMPOFF_SETTING_MODES.VFD_SURFACE:
          case PUMPOFF_SETTING_MODES.VFD_DOWNHOLE:
          default:
            events.push("speedRange");
            break;
          case PUMPOFF_SETTING_MODES.STANDARD_SURFACE:
          case PUMPOFF_SETTING_MODES.STANDARD_DOWNHOLE:
            events.push("scheduledDowntime");
            break;
        }

        fetchSetpointChanges(
          wellId,
          downholeLocation,
          startDate.format("YYYY-MM-DD"),
          endDate.format("YYYY-MM-DD"),
          events,
        );
      }
    },
    [
      setpointChangesFetched,
      fetchSetpointChanges,
      startDate,
      endDate,
      downholeLocation,
      wellId,
      driveMode,
    ],
  );

  if (wellId !== dataForWellId || !setpointChangesFetched) return null;

  return (
    <div className={classes.root}>
      <Typography variant="h5">Operational Health</Typography>

      <div className={classes.container}>
        <div>
          <CCRPanel well={well} />
        </div>
        <div>
          <StrokesVsProductionPanel well={well} />
        </div>
      </div>

      <hr className={classes.rule} />

      <Typography variant="h5">Design Health</Typography>

      <div className={`${classes.container} ${classes.design}`}>
        <div className={classes.wellboreQuality}>
          <WellboreQualityPanel well={well} />
        </div>
        <div className={classes.compression}>
          <CompressiveStressPanel well={well} />
        </div>
        <div className={classes.wellboreGraph}>
          <WellborePanel well={well} />
        </div>
      </div>
    </div>
  );
};

export default enhance(WellHealth);
