import React from "react";
import {
  FlexibleWidthXYPlot,
  HorizontalGridLines,
  XAxis,
  YAxis,
} from "react-vis";

import withAspectRatio from "components/with-aspect-ratio";

const ASPECT_RATIO = 3 / 5;

const style = {
  line: {stroke: "#f0f0f0", strokeWidth: 1},
  ticks: {stroke: "#f0f0f0"},
  text: {stroke: "none", fill: "#999"},
};

const ScatterPlot = ({
  children,
  height,
  yTickFormat,
  xDomain,
  yDomain,
  ...rest
}) => {
  return (
    <FlexibleWidthXYPlot
      {...rest}
      dontCheckIfEmpty
      height={height}
      xDomain={xDomain}
      yDomain={yDomain}
    >
      <HorizontalGridLines tickValues={[-20, 0, 20]} style={style.line} />
      <HorizontalGridLines
        tickValues={[10, -10]}
        style={{stroke: "#AAA ", strokeDasharray: 4}}
      />
      <XAxis hideLine style={style} tickSize={0} />
      <YAxis
        hideLine
        tickFormat={yTickFormat}
        tickSize={0}
        tickValues={[-20, -10, 0, 10, 20]}
        style={style}
      />
      <YAxis
        hideLine
        tickFormat={n => `${yTickFormat(n)} or higher`}
        tickSize={0}
        tickValues={[25]}
        tickPadding={-38}
        style={style}
      />
      <YAxis
        hideLine
        tickFormat={n => `${yTickFormat(n)} or lower`}
        tickSize={0}
        tickValues={[-25]}
        tickPadding={-34}
        style={style}
      />
      {children}
    </FlexibleWidthXYPlot>
  );
};

export default withAspectRatio(ASPECT_RATIO)(ScatterPlot);
