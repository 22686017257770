import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Tooltip from "@material-ui/core/Tooltip";

import WellCardTooltipContent from "./well-card-tooltip-content";

const useStyles = makeStyles(({spacing}) => ({
  tooltipPlacementTop: {
    margin: spacing(1, -5),
  },
}));

const WellCardTooltip = ({children, cardIds, well, currentLabel}) => {
  const classes = useStyles();

  return (
    <Tooltip
      classes={classes}
      title={
        <WellCardTooltipContent
          cardIds={cardIds}
          well={well}
          currentLabel={currentLabel}
        />
      }
      placement="top-end"
    >
      <div>{children}</div>
    </Tooltip>
  );
};

export default WellCardTooltip;
