import React from "react";
import {connect} from "react-redux";
import {reduxForm} from "redux-form";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import Typography from "@material-ui/core/Typography";

import {saveGracePeriod} from "actions/wells";
import TextField from "components/forms/text-field";
import {LABEL_MAP} from "constants/grace-periods";
import validate from "./validate";

const mapStateToProps = (state, props) => {
  return {
    initialValues: {
      ...props.gracePeriod,
    },
  };
};

const containerStyle = {
  alignItems: "center",
  display: "flex",
  width: "100%",
};

const controlsStyle = {
  marginLeft: "auto",
};

const labelStyle = {
  marginRight: "15px",
  flexGrow: 1,
};

const valueStyle = {
  flexGrow: 1,
};

let Component = props => {
  const {close, dispatch, gracePeriod, handleSubmit, well} = props;

  return (
    <div style={containerStyle}>
      <div style={labelStyle}>
        <Typography variant="body2">{LABEL_MAP[gracePeriod.type]}</Typography>
      </div>
      <div style={valueStyle}>
        <TextField fullWidth name="value" label="Value" required />
      </div>
      <IconButton
        onClick={handleSubmit(values => {
          dispatch(
            saveGracePeriod({
              data: values,
              well,
            }),
          );

          close();
        })}
      >
        <SaveIcon />
      </IconButton>
      <IconButton onClick={() => close()} style={controlsStyle}>
        <CloseIcon />
      </IconButton>
    </div>
  );
};

Component = reduxForm({
  form: "edit-grace-period-form",
  enableReinitialize: true,
  validate,
})(Component);

export default connect(mapStateToProps)(Component);
