import store from "store";
import sort from "reducers/wells/sort-wells";

export const getWells = (wellList = [], shouldSort = true) => {
  const storeWells = store.getState().wells;

  const wellObj = storeWells.byWellId;
  const wells = [];
  for (let i = 0; i < wellList.length; i++) {
    const currentWell = wellObj[wellList[i]];

    if (currentWell) {
      wells.push(currentWell);
    }
  }

  if (shouldSort) {
    const {sortBy, sortAsc} = storeWells.sorting;
    const sortedWells = sort(wells, sortBy, sortAsc);

    return sortedWells;
  }

  return wells;
};
