import {ajax} from "rxjs/observable/dom/ajax";
import {setTimeline, types} from "actions/alerts";

import addAuthHeaders from "../add-auth-headers";

export default (action$, store) => {
  return action$.ofType(types.fetchTimeline).switchMap(({payload}) => {
    return ajax(
      addAuthHeaders(
        {
          url: `${process.env.REACT_APP_API}/alerts/${
            payload.alertId
          }/timeline`,
          responseType: "json",
        },
        store,
      ),
    ).map(({response: data}) =>
      setTimeline({
        alertId: payload.alertId,
        timeline: data,
      }),
    );
  });
};
