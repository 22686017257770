import {connect} from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import React from "react";
import Typography from "@material-ui/core/Typography";

import {FormatDateTime} from "components/format/date";
import theme from "theme";
import colors from "theme/colors";

import hasFlag from "utils/has-feature-flag";

const margin = theme.spacing(2);

const styles = {
  container: {
    display: "flex",
    height: "auto",
    backgroundColor: colors.grey[100],
  },

  moreIcon: {
    width: "30px",
  },

  title: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    lineHeight: "48px",
    padding: `0 ${margin}px`,
  },
};

const mapStateToProps = state => {
  const {auth} = state;

  return {
    auth,
  };
};

const Toolbar = props => {
  const {auth, date, id, remove} = props;

  return (
    <div style={styles.container}>
      <div style={styles.title}>
        <Typography variant={"body1"}>
          {date ? <FormatDateTime date={date} /> : "Not Available"}
        </Typography>
        {hasFlag("data-team-comparison-correlation-id", auth.user) && (
          <Typography variant={"caption"}>{id}</Typography>
        )}
      </div>
      <IconButton onClick={() => remove(id)}>
        <CloseIcon />
      </IconButton>
    </div>
  );
};

export default connect(mapStateToProps)(Toolbar);
