import React from "react";

import getDisplayName from "utils/user/get-display-name";

import FeedItem from "../feed-item";

export default props => {
  const {event} = props;

  return (
    <FeedItem event={event}>
      <strong>{getDisplayName(event)}</strong>
      {" has attempted to manually reset the fault."}
    </FeedItem>
  );
};
