import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import clsx from "clsx";

import Tooltip from "components/material/tooltip";

const useStyles = makeStyles(({spacing, colors, breakpoints}) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    padding: spacing(3, 4, 3, 0),
    minWidth: 260,
    justifyContent: "space-between",
    alignItems: "baseline",
  },
  reclassifyButton: {
    backgroundColor: colors.blue[500],
    paddingRight: spacing(2),
    paddingLeft: spacing(2),
    color: "white",
    "&:hover": {
      backgroundColor: colors.blue[400],
    },
  },
  disabledReclassifyButton: {
    color: "white !important",
    backgroundColor: colors.blue[50],
  },
  cancelButton: {
    paddingRight: spacing(2),
    paddingLeft: spacing(2),
    color: colors.warmGrey[400],
  },
}));

const WellCardOverrideDialogActions = ({
  onCancel,
  onSubmit,
  disabled,
  className,
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.container, className)}>
      <Button className={classes.cancelButton} onClick={onCancel}>
        Cancel
      </Button>
      <Tooltip
        title={disabled ? "Select a different type to reclassify" : ""}
        enterDelay={500}
      >
        <div>
          <Button
            onClick={onSubmit}
            classes={{
              root: classes.reclassifyButton,
              disabled: classes.disabledReclassifyButton,
            }}
            disabled={disabled}
          >
            Reclassify Card
          </Button>
        </div>
      </Tooltip>
    </div>
  );
};

export default WellCardOverrideDialogActions;
