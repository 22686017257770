import React from "react";
import {connect} from "react-redux";
import {reduxForm} from "redux-form";

import Button from "@material-ui/core/Button";

import {saveGracePeriod} from "actions/wells";
import TextField from "components/forms/text-field";
import TextFieldListMenu from "components/forms/text-field-list-menu";
import validate from "./validate";

const mapStateToProps = (state, props) => {
  return {
    initialValues: {
      type: props.options[0].value,
      value: 5,
    },
  };
};

let Form = ({dispatch, handleSubmit, options, reset, well}) => {
  return (
    <div style={{display: "flex", marginBottom: "32px"}}>
      <div style={{flexGrow: 1, marginRight: "15px"}}>
        <TextFieldListMenu
          disabled
          hideEmptyOption
          name="type"
          label="Alert Grace Period Type"
          options={options}
          fullWidth
          required
        />
      </div>
      <div style={{flexGrow: 1, marginRight: "15px"}}>
        <TextField
          fullWidth
          name="value"
          label="Alert Grace Period in Minutes"
          required
          style={{minHeight: 0}}
        />
      </div>
      <Button
        onClick={handleSubmit(values => {
          dispatch(
            saveGracePeriod({
              data: values,
              well,
            }),
          );

          reset();
        })}
      >
        Add
      </Button>
    </div>
  );
};

Form = reduxForm({
  form: "create-grace-period-form",
  enableReinitialize: true,
  validate,
})(Form);

export default connect(mapStateToProps)(Form);
