import get from "lodash/get";

import {sendAnalyticsEvent} from "components/analytics";
import {eventCategory, eventAction} from "constants/analytics";

export const mapWellNameProps = ({well, push}) => {
  const downholeLocation = get(well, "downholeLocation");

  return {
    displayName: get(well, "displayName"),
    onClick: () => {
      sendAnalyticsEvent(
        eventCategory.setpointAutomationWorkflow,
        eventAction.navigate,
        downholeLocation,
      );
      push(`/well/${encodeURIComponent(downholeLocation)}/analysis`);
    },
  };
};
