import {types} from "./types";

export const setWebhookSaved = options => {
  const {clientUserId, webhook, webhookId} = options;

  return {
    type: types.setWebhookSaved,
    payload: {
      clientUserId,
      webhook,
      webhookId,
    },
  };
};
