import updateDeep from "utils/update-deep";

export default ({state, action}) => {
  return updateDeep(
    state,
    `all.${action.payload.organizationId}.users`,
    users =>
      users.filter(user => user.clientUserId !== action.payload.clientUserId),
  );
};
