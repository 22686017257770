import React from "react";

import {TableBody} from "./table-body";
import {TableHeader} from "./table-header";

const styles = {
  container: {
    display: "flex",
    flex: 1,
  },
  scrollContainer: {
    flexGrow: 1,
    overflow: "auto",
    minHeight: 0,
  },
  table: {
    borderCollapse: "collapse",
  },
};

export const Body = props => {
  const {data, errors, headers, limit, settings} = props;
  const {hideIgnored, mappings, onlyShowErrors} = settings;

  let {containerStyle} = props;

  containerStyle = {
    ...containerStyle,
    ...styles.container,
  };

  return (
    <div style={containerStyle}>
      <div style={styles.scrollContainer}>
        <table style={styles.table}>
          <TableHeader
            headers={headers}
            hideIgnored={hideIgnored}
            mappings={mappings}
          />
          <TableBody
            data={data}
            errors={errors}
            hideIgnored={hideIgnored}
            limit={limit}
            mappings={mappings}
            onlyShowErrors={onlyShowErrors}
          />
        </table>
      </div>
    </div>
  );
};
