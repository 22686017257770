import parseEvents from "@ambyint/common/parsers/well/events";

import fetch from "epics/async-fetch";
import getUser from "utils/get-user";

const defaultEventTypes = [
  "alertAcknowledged",
  "alertCreated",
  "alertResolved",
  "allowedToOperate",
  "autoAcceptedRecommendations",
  "casingPressure",
  "clearErrors",
  "current",
  "deflection",
  "deviceAssigned",
  "deviceRemoved",
  "downholeInformation",
  "dutyCycle",
  "driveData",
  "driveFault",
  "driveMode",
  "effectiveSpm",
  "faultRecovery",
  "fillage",
  "gasProduction",
  "gracePeriodSet",
  "gracePeriodUnset",
  "inferredFillage",
  "inferredProductionAnalysis",
  "load",
  "manualFaultReset",
  "motorDetails",
  "newRecommendation",
  "oilProduction",
  "powerStatus",
  "production",
  "pumpingUnit",
  "recommendationAccepted",
  "recommendationDismissed",
  "reservoir",
  "resistance",
  "rpm",
  "rrpm",
  "runningStatus",
  "safeOperatingParameters",
  "scheduledDowntime",
  "setBasicWellDetails",
  "setFaultRecovery",
  "speedProfile",
  "speedRange",
  "spm",
  "statusCodes",
  "stuffingBoxLeakPressure",
  "surfaceCardRequested",
  "surfaceCardRequestFulfilled",
  "torque",
  "totalReductionRatio",
  "tubingPressure",
  "waterProduction",
  "wellAnalogInputChanged",
  "wellbore",
  "wellCurrentViolation",
  "wellPumpOffSettingChanged",
  "wellResetMalfunctions",
  "wellRunTimer",
  "wellTorqueViolation",
];

export default async payload => {
  const {unitsOfMeasure} = getUser();
  const {eventTypes, ...restOfPayload} = payload;

  const events = await fetch(
    `/wells/${encodeURIComponent(payload.downholeLocation)}/events`,
    {
      ...restOfPayload,
      types: (eventTypes || defaultEventTypes).join(","),
    },
  );

  return parseEvents(unitsOfMeasure)(events);
};
