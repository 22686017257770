import React from "react";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

import {ReactComponent as Vector} from "./vector.svg";

const styles = () => ({
  heading: {
    fontSize: 18,
    fontWeight: 700,
    marginBottom: 10,
  },
  vector: {
    height: "auto",
    marginBottom: 30,
    maxWidth: 200,
  },
  wrapper: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    margin: "0 auto",
    maxWidth: 300,
    justifyContent: "center",
    textAlign: "center",
  },
});

const ComparisonEmpty = ({classes}) => {
  return (
    <div className={classes.wrapper}>
      <Vector className={classes.vector} />
      <Typography variant="h3" className={classes.heading}>
        Production Comparison
      </Typography>
      <Typography variant="caption">
        Select a date range to compare production of oil, gas, and water over
        time.
      </Typography>
    </div>
  );
};

export default withStyles(styles)(ComparisonEmpty);
