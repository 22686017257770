import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import TableCell from "components/table-cell";
import TagCheckbox from "./tag-checkbox";

const styles = theme => ({
  headerCheckbox: {
    width: "5%",
  },
  checkboxCellContainer: {
    paddingLeft: 3,
  },
});

const TableHeaderCheckbox = ({classes, wellIds, isSelectionMode}) => {
  return (
    <TableCell className={classes.headerCheckbox}>
      <div className={classes.checkboxCellContainer}>
        <TagCheckbox wellIds={wellIds} isSelectionMode={isSelectionMode} />
      </div>
    </TableCell>
  );
};

export default withStyles(styles)(TableHeaderCheckbox);
