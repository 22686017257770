import React from "react";

import getDisplayName from "utils/user/get-display-name";

import FeedItem from "../feed-item";

export default ({event}) => {
  return (
    <FeedItem event={event}>
      {getDisplayName(event)}
      {" has attempted to "}
      <strong>{event.value ? "start" : "stop"}</strong>
      {" the well."}
    </FeedItem>
  );
};
