import find from "lodash/find";
import isEmpty from "lodash/isEmpty";

import crankWeightLocations from "./crank-weight-locations";

export default crankWeight => {
  if (!crankWeight || isEmpty(crankWeight)) return "";

  const {manufacturer = "", model = ""} = crankWeight;
  const location = crankWeight.location
    ? find(crankWeightLocations, {value: crankWeight.location})
    : null;
  return `${manufacturer} ${model} ${location ? `- ${location.text}` : ""}`;
};
