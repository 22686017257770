import React, {useState, useRef} from "react";
import get from "lodash/get";
import Tooltip from "@material-ui/core/Tooltip";
import {connect} from "react-redux";

import colors from "@ambyint/common/colors";

import Avatar from "components/avatar";
import {ClickEvent} from "components/analytics";
import {eventAction, eventCategory} from "constants/analytics";
import {useHover} from "hooks/use-hover";

import UserMenuPopover from "./user-menu-popover";

const styles = {
  avatar: {
    width: 32,
    height: 32,
    color: colors.darkGrey,
    backgroundColor: "#fff",
    fontSize: ".85rem",
    fontWeight: "600",
    cursor: "pointer",
    margin: 0,
  },
};

const mapStateToProps = state => {
  const user = state.auth.user;
  const currentOrganizationId = state.organizationView.currentOrganization;
  const currentOrganization = get(user, "assignedOrganizations", []).find(
    org => org.organizationId === currentOrganizationId,
  );

  return {
    user,
    onCall: state.onCall,
    currentOrganization,
  };
};

const AvatarTooltip = ({organizationName, user}) => (
  <div>
    <b>Ambyint Account</b>
    <br />
    {user.name}
    <br />
    {user.email}
    <br />
    <div style={{marginTop: 7}}>{organizationName}</div>
  </div>
);

const UserMenu = ({currentOrganization, user}) => {
  const [open, setOpen] = useState(false);
  const {isHovering, enableHover, disableHover} = useHover();
  const anchorEl = useRef(null);
  const toggleOpen = () => setOpen(state => !state);
  const closePopover = () => setOpen(false);

  if (!currentOrganization) return null;

  return (
    <>
      <ClickEvent
        label="User Popout"
        action={eventAction.toggle}
        category={eventCategory.userPopout}
      >
        <Tooltip
          open={isHovering}
          title={
            <AvatarTooltip
              organizationName={currentOrganization.organizationName}
              user={user}
            />
          }
        >
          <div onMouseOver={enableHover} onMouseLeave={disableHover}>
            <Avatar
              open={open}
              onClick={toggleOpen}
              rootRef={anchorEl}
              size={32}
              style={styles.avatar}
              user={user}
            />
          </div>
        </Tooltip>
      </ClickEvent>
      <UserMenuPopover
        open={open}
        onClose={closePopover}
        anchorEl={anchorEl.current}
        user={user}
        currentOrganization={currentOrganization}
      />
    </>
  );
};

export default connect(mapStateToProps)(UserMenu);
