import get from "lodash/get";
import some from "lodash/some";

import {createSearchResult} from "./create-result";

/**
 * Search for a well based on its devices' serial numbers
 *
 * @param {object} well parsed well object
 * @param {object} helpers properties to perform search with
 */
export const byDeviceSerialNumber = (well, helpers) => {
  const {query} = helpers;

  const devices = get(well, "devices", []);

  if (
    some(
      devices,
      ({serialNumber}) =>
        serialNumber && `${serialNumber}`.includes(query.toLowerCase()),
    )
  ) {
    return createSearchResult(well);
  }

  return null;
};
