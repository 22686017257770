import React from "react";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import {CLASSIFICATION_SORT} from "constants/classification";
import TableHeaderCell from "./table-header-cell";

const {TOTAL_LIQUID, CYCLES, FILLAGE, NAME, SPM} = CLASSIFICATION_SORT;

const TableHeader = ({onClickColumn, sortAsc, sortBy, unitsOfMeasure}) => (
  <TableHead>
    <TableRow>
      <TableHeaderCell
        width="25%"
        label="Well Name"
        field={NAME}
        onClickColumn={onClickColumn}
        sortBy={sortBy}
        sortAsc={sortAsc}
      />
      <TableHeaderCell
        width="25%"
        label="Total Daily Liquid Production"
        tooltip={
          unitsOfMeasure.toLocaleLowerCase() === "metric" ? "m³/d" : "bbl/d"
        }
        field={TOTAL_LIQUID}
        onClickColumn={onClickColumn}
        sortBy={sortBy}
        sortAsc={sortAsc}
        align="right"
      />
      <TableHeaderCell
        width="15%"
        label="Running SPM"
        field={SPM}
        onClickColumn={onClickColumn}
        sortBy={sortBy}
        sortAsc={sortAsc}
        align="right"
      />
      <TableHeaderCell
        width="15%"
        label="Cycles/Day"
        field={CYCLES}
        onClickColumn={onClickColumn}
        sortBy={sortBy}
        sortAsc={sortAsc}
        align="right"
      />
      <TableHeaderCell
        width="15%"
        label="Avg. Fillage"
        field={FILLAGE}
        tooltip="%"
        onClickColumn={onClickColumn}
        sortBy={sortBy}
        sortAsc={sortAsc}
        align="right"
      />
      <TableCell style={{width: "5%"}} />
    </TableRow>
  </TableHead>
);

export default TableHeader;
