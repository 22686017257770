import React from "react";
import {withStyles} from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/Error";
import Typography from "@material-ui/core/Typography";
import colors from "theme/colors";

const styleSheet = {
  container: {
    display: "flex",
    padding: 8,
    color: colors.error[500],
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    verticalAlign: "middle",
    marginBottom: 2,
    marginRight: 8,
    color: colors.error[500],
  },
  text: {
    color: colors.error[500],
    display: "inline-block",
    fontWeight: 600,
  },
};

const FormError = ({children, classes}) =>
  children ? (
    <div className={classes.container}>
      <ErrorIcon className={classes.icon} />
      <Typography className={classes.text} variant="subtitle1">
        {children}
      </Typography>
    </div>
  ) : null;

export default withStyles(styleSheet, {name: "FormError"})(FormError);
