export const formatNumber = (
  number,
  maxFractionDigits = 1,
  minFractionDigits = 0,
) =>
  number.toLocaleString("en", {
    maximumFractionDigits: maxFractionDigits,
    minimumFractionDigits: minFractionDigits,
  });

export const formatSignedNumber = (
  number,
  maxFractionDigits = 1,
  minFractionDigits = 0,
) => {
  const sign = number > 0 ? "+" : "";
  const formatted = number.toLocaleString("en", {
    maximumFractionDigits: maxFractionDigits,
    minimumFractionDigits: minFractionDigits,
  });

  return sign + formatted;
};
