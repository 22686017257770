import {push} from "react-router-redux";
import React from "react";

import getDisplayName from "utils/user/get-display-name";
import store from "store";

import {clickableStyle} from "./styles";
import FeedItem from "./feed-item";

export default props => {
  const {changesRoute, event, settingsName, well} = props;

  return (
    <FeedItem event={event}>
      <strong>{getDisplayName(event.user)}</strong>
      {" updated the "}
      <span
        onClick={() =>
          store.dispatch(
            push(
              `/well/${encodeURIComponent(
                well.downholeLocation,
              )}${changesRoute}`,
            ),
          )
        }
        style={clickableStyle}
      >
        {settingsName}
      </span>
    </FeedItem>
  );
};
