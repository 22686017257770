import compose from "lodash/fp/compose";
import find from "lodash/fp/find";
import getOr from "lodash/fp/getOr";

import {SPEED_RANGE} from "@ambyint/common/constants/recommendation-types";

export const isSpeedRangeEnabled = compose(
  find({type: SPEED_RANGE, on: true}),
  getOr([], "recommendationTypes"),
);
