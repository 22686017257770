import {replace} from "react-router-redux";

import {createSwitchEpic} from "epics/create-epic";
import {types} from "actions/organizations";
import {show} from "actions/snackbar";
import fetch from "epics/async-fetch";

export default createSwitchEpic([types.reinviteUser], async ({payload}) => {
  try {
    const {clientUserId, organizationId} = payload;

    await fetch(
      `/organization/${organizationId}/user/${clientUserId}/reinvite`,
      {},
      {
        method: "PUT",
      },
    );

    return [
      show({
        message: "Your request to reinvite user submitted successfully!",
      }),
    ];
  } catch (err) {
    if (err.status === 401 || err.status === 403) {
      return [
        replace("/"),
        show({
          message: "Unauthorized to reinvite user to organization",
        }),
      ];
    }
    return [replace(`/error?code=${err.status}`)];
  }
});
