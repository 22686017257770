import omit from "lodash/omit";

import amber from "@material-ui/core/colors/amber";
import blue from "@material-ui/core/colors/blue";
import blueGrey from "@material-ui/core/colors/blueGrey";
import brown from "@material-ui/core/colors/brown";
import common from "@material-ui/core/colors/common";
import deepPurple from "@material-ui/core/colors/deepPurple";
import deepOrange from "@material-ui/core/colors/deepOrange";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";
import indigo from "@material-ui/core/colors/indigo";
import lightGreen from "@material-ui/core/colors/lightGreen";
import orange from "@material-ui/core/colors/orange";
import purple from "@material-ui/core/colors/purple";
import red from "@material-ui/core/colors/red";
import teal from "@material-ui/core/colors/teal";

export const ambyOrange = {
  50: "#ffede4",
  100: "#fed1bc",
  200: "#feb390",
  300: "#fd9464",
  400: "#fc7d42",
  500: "#fc6621",
  600: "#fc5e1d",
  700: "#fb5318",
  800: "#fb4914",
  900: "#fa380b",
  A100: "#ffffff",
  A200: "#fff3f1",
  A400: "#ffc7be",
  A700: "#ffb1a4",
  contrastDefaultColor: "dark",
};

export const ambyBlue = {
  50: "#e7e9ea",
  100: "#c3c8cb",
  200: "#9ba3a8",
  300: "#727e85",
  400: "#54626a",
  500: "#364650",
  600: "#303f49",
  700: "#293740",
  800: "#222f37",
  900: "#162027",
  A100: "#6bc0ff",
  A200: "#38aaff",
  A400: "#0594ff",
  A700: "#0086eb",
  contrastDefaultColor: "light",
};

export const ambyColors = {
  ambyOrange,
  ambyBlue,
  casingPressure: lightGreen,
  current: brown,
  deflection: orange,
  downholeCard: indigo,
  driveFault: amber,
  error: red,
  fillage: green,
  leakPressure: purple,
  load: blue,
  oilProduction: blueGrey,
  onCall: green,
  offCall: grey,
  rpm: deepPurple,
  rrpm: grey,
  spm: grey,
  surfaceCard: red,
  torque: teal,
  tubingPressure: orange,
  warning: deepOrange,
};

export default {
  ...common,
  ...ambyColors,
  amber,
  blue,
  blueGrey,
  brown,
  deepPurple,
  deepOrange,
  green,
  grey,
  indigo,
  lightGreen,
  orange,
  purple,
  red,
  teal,
  border: grey[400],
  black: "#000",
  fullBlack: "rgba(0, 0, 0, 1)",
  fullWhite: "rgba(255, 255, 255, 1)",
  transparent: "transparent",
  white: "#fff",
  assortment: Object.values(omit(ambyColors, ["ambyOrange", "ambyBlue"])),
};
