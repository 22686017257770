import React, {Component} from "react";

import isNil from "lodash/isNil";

import FormHelperText from "@material-ui/core/FormHelperText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import colors from "theme/colors";

import valueOf from "utils/value-of";

const styles = {
  container: {
    cursor: "pointer",
  },
  control: {
    display: "flex",
    alignItems: "center",
    outline: "none",
  },
};

class TextListMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: undefined,
      open: false,
    };
  }

  isOpen = () => (isNil(this.props.open) ? this.state.open : this.props.open);

  setOpenTo = (value, target) => {
    if (this.props.onClick) {
      this.props.onClick(value);
    } else {
      this.setState({open: value});
    }

    if (!this.state.anchorEl) {
      this.setState({anchorEl: target});
    }
  };

  handleClickListItem = event => {
    this.setOpenTo(true, event.currentTarget);
  };

  handleMenuItemClick = (event, index, option) => {
    this.setOpenTo(false);

    let value = option;
    if (this.props.getValue) {
      value = this.props.getValue(value);
    } else {
      value = valueOf(value);
    }

    this.props.onChange(value);
  };

  render() {
    const options = [...(this.props.options || [])];
    if (options.length > 0 && options[0].value !== undefined) {
      options.unshift({value: "", text: ""});
    } else {
      options.unshift("");
    }

    const value = Math.max(
      0,
      options.findIndex(option =>
        option.value !== undefined
          ? option.value === this.props.value
          : option === this.props.value,
      ),
    );
    const selectedOption = options[value];
    const selectedText = selectedOption
      ? selectedOption.text === undefined
        ? selectedOption
        : selectedOption.text
      : "";

    return (
      <div style={styles.container}>
        <div
          role="button"
          tabIndex={0}
          onClick={this.handleClickListItem}
          ref={input => {
            if (!this.state.anchorEl) {
              this.setState({anchorEl: input});
            }
          }}
          style={{...styles.control, ...(this.props.style || {})}}
        >
          <Typography
            variant="body1"
            role="button"
            tabIndex={0}
            {...(this.props.typography ? {type: this.props.typography} : {})}
            style={{
              color: colors.grey[700],
              fontSize: "1em",
              outline: "none",
              userSelect: "none",
              ...(this.props.style || {}),
            }}
          >
            {this.props.label}
            {selectedText}
          </Typography>
          {!this.props.disabled && (
            <ArrowDropDownIcon
              style={{
                ...(this.props.arrowStyle || {}),
                color: colors.grey[700],
              }}
            />
          )}
          {this.props.error && (
            <FormHelperText
              error
              style={{position: "absolute", bottom: 0, right: 20}}
            >
              {this.props.error}
            </FormHelperText>
          )}
        </div>
        <Menu
          elevation={1}
          id="lock-menu"
          open={!this.props.disabled && this.isOpen()}
          anchorEl={this.state.anchorEl}
          onClose={() => this.setOpenTo(false)}
        >
          {options.map((option, index) => {
            return (
              <MenuItem
                key={valueOf(option.value)}
                selected={index === this.props.value}
                onClick={event =>
                  this.handleMenuItemClick(event, index, option)
                }
              >
                {option.text === undefined ? option : option.text}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    );
  }
}

export default TextListMenu;
