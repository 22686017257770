// 152.4m = 500ft, 914.4m = 3000ft. Thresholds and depths provided by SME.

export const exceedsThresholdAtDepth = (
  doglegSeverityDeg30m,
  measuredDepth,
  depthLimit,
) => {
  return (
    measuredDepth <= depthLimit &&
    ((measuredDepth <= 152.4 && doglegSeverityDeg30m >= 0.5) ||
      (measuredDepth <= 914.4 && doglegSeverityDeg30m >= 3) ||
      (measuredDepth > 914.4 && doglegSeverityDeg30m >= 6))
  );
};

export const thresholdAtDepth = measuredDepth => {
  if (measuredDepth <= 152.4) {
    return {
      degrees: 0.5,
      minDepth: 0,
      maxDepth: 152.4,
    };
  } else if (measuredDepth <= 914.4) {
    return {
      degrees: 3,
      minDepth: 152.4,
      maxDepth: 914.4,
    };
  } else if (measuredDepth > 914.4) {
    return {
      degrees: 6,
      minDepth: 914.4,
      maxDepth: null,
    };
  }
};
