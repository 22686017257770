import React from "react";

import Grid from "@material-ui/core/Grid";

import FormButton from "components/form-button";
import FormHeader from "components/form-header";
import getPrivileges from "utils/get-privileges";
import PaperContainer from "components/paper-container";
import privilegeTypes from "utils/privilege-types";
import {cardLabelFactory, cardValueFactory} from "../cards";

const CardLabel = cardLabelFactory(7);
const CardValue = cardValueFactory(5);

const displayValue = (setpoints, key) => {
  if (!setpoints || setpoints[key].value === "") return "--";

  return `${setpoints[key].value.toFixed(2)} ${setpoints[key].units}`;
};

const renderCurrent = currentSetpoint => (
  <Grid container>
    <CardLabel label={"Min Current Violation Level"} />
    <CardValue value={displayValue(currentSetpoint, "min")} />
    <CardLabel label={"Max Current Violation Level"} />
    <CardValue value={displayValue(currentSetpoint, "max")} />
  </Grid>
);

const renderTorque = torqueSetpoint => (
  <Grid container>
    <CardLabel label={"Min Torque Violation Level"} />
    <CardValue value={displayValue(torqueSetpoint, "min")} />
    <CardLabel label={"Max Torque Violation Level"} />
    <CardValue value={displayValue(torqueSetpoint, "max")} />
  </Grid>
);

export default props => {
  const {motorViolations} = props.well;

  const privileges = getPrivileges(props.well.organization.id);

  return (
    <PaperContainer extraPadded style={{maxWidth: "500px"}}>
      <div style={{marginBottom: 32}}>
        <FormHeader>Motor Violations Configuration</FormHeader>
      </div>

      {props.displayCurrent
        ? renderCurrent(motorViolations && motorViolations.currentSetpoint)
        : renderTorque(motorViolations && motorViolations.torqueSetpoint)}
      {privileges.includes(privilegeTypes.well.configure) && (
        <div style={{padding: "16px 0"}}>
          <FormButton onClick={props.onEdit}>Edit Information</FormButton>
        </div>
      )}
    </PaperContainer>
  );
};
