export default () => {
  const tableFlips = [
    "(┛◉Д◉)┛彡┻━┻",
    "(ﾉಥ益ಥ）ﾉ ︵﻿ ┻━┻",
    "༼ノಠل͟ಠ༽ノ ︵ ┻━┻",
    "(ノಠ益ಠ)ノ彡┻━┻",
    "ʕノ•ᴥ•ʔノ ︵ ┻━┻",
    "(╯°□°)╯︵ ┻━┻",
    "(╯ಠ益ಠ)╯彡┻━┻",
    "(╯ ͡° ͜ʖ ͡°)╯ 彡 ┻━┻",
    "( ︶︿︶)ノ ︵ ┻━┻",
    "(っ◕‿◕)っ ︵ ┻━┻",
    "(ﾉ^ヮ^)ﾉ 彡 ┻━┻",
  ];

  return tableFlips[Math.floor(Math.random() * tableFlips.length)];
};
