import React from "react";
import moment from "moment";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = ({typography, colors}) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "20px auto 20px",
    gridTemplateAreas: `
       "icon header header"
       "content content content"`,
  },

  header: {
    ...typography.caption,
    color: colors.warmGrey[500],
    gridArea: "header",
    textAlign: "left",
    alignSelf: "center",
    marginLeft: "5px",
  },

  content: {
    gridArea: "content",
    textAlign: "center",
  },

  data: {
    ...typography.subtitle1,
    fontSize: "20px",
    margin: 0,
  },

  unit: {
    ...typography.caption,
    color: colors.warmGrey[500],
    marginLeft: "3px",
  },

  icon: {
    gridArea: "icon",
    width: "20px",
    fill: colors.warmGrey[500],
  },
});

const BaseSetpointAxis = ({createdOn, classes, Icon, children}) => {
  return (
    <div className={classes.container}>
      <Icon className={classes.icon} />
      <div className={classes.header}>{moment(createdOn).format("MMM Do")}</div>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default withStyles(styles)(BaseSetpointAxis);
