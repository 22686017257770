import React from "react";
import Menu from "@material-ui/core/Menu";

import {ShareableLink} from "./shareable-link";
import ManageTags from "./manage-tags";
import DownloadButton from "./download-button";
import {TABS} from "./tabs";

const MobileMenu = ({menuAnchor, classes, tab, well, analysis, onClose}) => (
  <Menu
    anchorEl={menuAnchor}
    anchorOrigin={{vertical: "top", horizontal: "left"}}
    transformOrigin={{vertical: "top", horizontal: "left"}}
    open={menuAnchor != null}
    onClose={onClose}
    classes={{paper: classes.buttonsMenu}}
  >
    <ManageTags isMenuItem label wellId={well.wellId} />
    <ShareableLink isMenuItem label />
    <DownloadButton
      disabled={!(tab === TABS.ANALYSIS && analysis)}
      analysis={analysis}
      well={well}
      label
      isMenuItem
    />
  </Menu>
);

export default MobileMenu;
