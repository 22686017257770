import {useState} from "react";

import {findSmartSOP} from "utils/setpoint-automation/find-smart-sop";

export const useRangeSliderState = well => {
  const [rangeSliderState, setRangeSliderState] = useState(findSmartSOP(well));
  const rangeSliderOnChange = (event, val) => setRangeSliderState(val);

  return {rangeSliderState, rangeSliderOnChange};
};
