import {default as performFetch} from "epics/fetch";
import {set, types} from "actions/couplings";
import parseCouplingType from "@ambyint/common/parsers/well/parse-coupling-type";

import valueOf from "utils/value-of";

import getUnits from "utils/get-units";
import getUser from "utils/get-user";
import convertToMetric from "utils/conversion/convert-to-metric";
import convertLength from "utils/conversion/convert-length";

export const fetch = (action$, store) => {
  return action$.ofType(types.fetch).switchMap(({payload: {pin}}) => {
    const units = getUnits();
    const unitsOfMeasure = getUser().unitsOfMeasure;
    const toMetric = convertToMetric(units, unitsOfMeasure);
    const toMetricLength = toMetric(convertLength, {to: "m"});
    // Decimals must be 6, or the API won't recognize the length
    const pinM = valueOf(toMetricLength(pin, {decimals: 6}));
    return performFetch(`/optimization/couplings/${pinM}`).map(
      ({response: data}) =>
        set({data: data.map(c => parseCouplingType(c, unitsOfMeasure))}),
    );
  });
};
