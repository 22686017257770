import store from "store";
import {updateRecommendation} from "actions/wells";
import findWell from "utils/find-well";
import parseRecommendation from "@ambyint/common/parsers/well/parse-recommendation";
import getUser from "utils/get-user";

export default async payload => {
  const wells = store.getState().wells || null;
  const {unitsOfMeasure} = getUser();

  if (wells && findWell(wells, payload.downholeLocation)) {
    try {
      return store.dispatch(
        updateRecommendation(parseRecommendation(payload, unitsOfMeasure)),
      );
    } catch (err) {
      console.err(err);
    }
  }
};
