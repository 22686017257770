import {fetchComparison, types, updateAnalysis} from "actions/wells";
import without from "lodash/without";

export const addComparison = (action$, store) => {
  return action$.ofType(types.addComparison).mergeMap(({payload}) => {
    const {correlationId, downholeLocation} = payload;
    const analysis = store.getState().wells.analysis[downholeLocation];

    return [
      updateAnalysis(downholeLocation, {
        comparisons: [
          ...without(analysis.comparisons || [], correlationId),
          correlationId,
        ],
      }),
      fetchComparison({
        downholeLocation,
        correlationId,
      }),
    ];
  });
};
