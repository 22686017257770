import {types} from "actions/pumpjacks";

const defaultValues = {search: []};

export default (state = defaultValues, action) => {
  if (action.type === types.setSearch) {
    return {
      ...state,
      search: action.payload.data || [],
    };
  }

  return state;
};
