export const types = {
  updateFilter: "paging:update-filter",
};

export const updateFilter = options => {
  const {filter, source} = options;

  return {
    type: types.updateFilter,
    payload: {
      filter,
      source,
    },
  };
};
