const greenVivid = {
  50: "#effcf6",
  100: "#c6f7e2",
  200: "#8eedc7",
  300: "#65d6ad",
  400: "#3ebd93",
  500: "#27ab83",
  600: "#199473",
  700: "#147d64",
  800: "#0c6b58",
  900: "#014d40",
};

export default greenVivid;
