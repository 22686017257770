import {setComparisons, types, updateAnalysis} from "actions/wells";
import findWell from "utils/find-well";
import without from "lodash/without";

import {removeExisting} from "./remove-existing";

export const removeComparison = (action$, store) => {
  return action$.ofType(types.removeComparison).mergeMap(({payload}) => {
    const {correlationId, downholeLocation} = payload;
    const analysis = store.getState().wells.analysis[downholeLocation];
    const well = findWell(store.getState().wells, downholeLocation);

    const comparisons = [...removeExisting(well.comparisons, correlationId)];

    return [
      updateAnalysis(downholeLocation, {
        comparisons: [...without(analysis.comparisons || [], correlationId)],
      }),
      setComparisons({
        downholeLocation,
        comparisons,
      }),
    ];
  });
};
