import kpa from "./kpa";
import oilProduction from "./oil-production";
import percentage from "./percentage";
import status from "./status";
import time from "./time";

export default () => {
  return {
    kpa: kpa(),
    oilProduction: oilProduction(),
    percentage: percentage(),
    status: status(),
    time: time(),
  };
};
