import React from "react";
import {connect} from "react-redux";

import IconButton from "@material-ui/core/IconButton";
import CheckIcon from "@material-ui/icons/Check";
import SnoozeIcon from "@material-ui/icons/Snooze";

import {show as showAcknowledgeAlertDialog} from "actions/acknowledge-alert-dialog";
import {show as showResolveAlertDialog} from "actions/resolve-alert-dialog";

const AlertActionButtons = ({alert, dispatch}) => {
  return [
    alert.status !== "resolved" && (
      <IconButton
        color={"primary"}
        key="resolve-alert"
        onClick={() => dispatch(showResolveAlertDialog({alert}))}
      >
        <CheckIcon />
      </IconButton>
    ),
    alert.status !== "resolved" && (
      <IconButton
        color={"primary"}
        key="acknowledge-alert"
        onClick={() => dispatch(showAcknowledgeAlertDialog({alert}))}
      >
        <SnoozeIcon />
      </IconButton>
    ),
  ];
};

export default connect()(AlertActionButtons);
