import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const Ideal = ({className}) => {
  return (
    <SvgIcon className={className} viewBox="0 0 230 139">
      <rect
        stroke="currentColor"
        strokeWidth={7}
        x={3.5}
        y={3.5}
        width={223}
        height={132}
        rx={18}
        fill="none"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export default Ideal;
