import React from "react";

import colors from "theme/colors";
import ListViewComponent from "components/list-view";

import Header from "./header";

const ListView = ({onRowClick, organizationId, serviceAccounts}) => {
  return (
    <ListViewComponent
      header={<Header organizationId={organizationId} />}
      data={serviceAccounts}
      columns={[
        {
          label: "Service account",
          render: serviceAccount => serviceAccount.serviceName,
          sortBy: "serviceName",
          xs: 6,
        },
        {
          label: "Webhooks",
          render: serviceAccount => (
            <div
              style={{
                ...(!serviceAccount.webhooks ||
                serviceAccount.webhooks.length === 0
                  ? {color: colors.red[500]}
                  : {}),
              }}
            >
              {serviceAccount.webhooks ? serviceAccount.webhooks.length : 0}
            </div>
          ),
          sortBy: "webhooks.length",
          xs: 3,
        },
        {
          label: "Assignments",
          render: serviceAccount => (
            <div
              style={{
                ...(!serviceAccount.assignedWells ||
                serviceAccount.assignedWells.length === 0
                  ? {color: colors.red[500]}
                  : {}),
              }}
            >
              {serviceAccount.assignedWells
                ? serviceAccount.assignedWells.length
                : 0}
            </div>
          ),
          sortBy: "assignedWells.length",
          xs: 3,
        },
      ]}
      onRowClick={onRowClick}
    />
  );
};

export default ListView;
