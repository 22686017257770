export default (parsed = {}, data = {}) => {
  const tags = data.tags || [];

  tags.push(parsed.alias);
  tags.push(parsed.downholeLocation);
  tags.push(parsed.typeLabel);
  tags.push(parsed.status);

  return tags;
};
