import React, {useState} from "react";
import clsx from "clsx";
import {connect} from "react-redux";
import compose from "lodash/fp/compose";
import Typography from "@material-ui/core/Typography/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

import Container from "components/container";
import Subheader from "components/subheader";
import {withBoxLoading} from "components/box-loading";
import {withPageview} from "components/analytics";

import SpeedRange from "./speed-range";
import Legend from "./speed-range/legend";

const useStyles = makeStyles({
  container: {
    marginTop: "7em",
    marginBottom: "7em",
    paddingBottom: ({shouldDisplayLegend}) => (shouldDisplayLegend ? 100 : 0),
  },
  hidden: {
    display: "none",
  },
});

const SetpointAutomation = () => {
  const [tabIndex, setValue] = useState(0);
  const shouldDisplayLegend = tabIndex === 0;
  const classes = useStyles({shouldDisplayLegend});

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Container className={classes.container}>
        <Subheader>
          <Typography variant="subtitle1">Setpoint Automation</Typography>
        </Subheader>
        <div className={clsx({[classes.hidden]: tabIndex !== 0})}>
          <SpeedRange tabIndex={tabIndex} handleTabChange={handleTabChange} />
        </div>
        <div className={clsx({[classes.hidden]: tabIndex !== 1})}>
          nothing here yet
        </div>
      </Container>
      {shouldDisplayLegend && <Legend />}
    </>
  );
};

const mapStateToProps = ({wells: {fetched}}) => {
  return {
    loading: !fetched,
  };
};

const enhance = compose(
  connect(mapStateToProps),
  withBoxLoading,
  withPageview("/setpoint-automation"),
);

export default enhance(SetpointAutomation);
