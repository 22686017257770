import React from "react";

import colors from "theme/colors";
import ALERT_STATUS_STATES from "constants/alert-status-states";

const getDefaultStyle = props => {
  let color;

  if (props.status === ALERT_STATUS_STATES.UNACKNOWLEDGED)
    color = colors.red[500];
  if (props.status === ALERT_STATUS_STATES.ACKNOWLEDGED)
    color = colors.indigo[500];
  if (props.status === ALERT_STATUS_STATES.RESOLVED) color = colors.green[500];

  return {
    color,
    fontWeight: props.status === ALERT_STATUS_STATES.UNACKNOWLEDGED ? 500 : 300,
    textTransform: "uppercase",
  };
};

export default props => {
  return (
    <span style={{...getDefaultStyle(props), ...props.style}}>
      {props.status}
    </span>
  );
};
