import React from "react";

import Grid from "@material-ui/core/Grid";

import FormButton from "components/form-button";
import FormHeader from "components/form-header";
import PaperContainer from "components/paper-container";

import getPrivileges from "utils/get-privileges";
import privilegeTypes from "utils/privilege-types";

import TotalReductionRatioEditView from "./total-reduction-ratio-edit-view";

import {cardLabelFactory, cardValueFactory} from "../cards";

class TotalReductionRatioReadView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {well} = this.props;

    const privileges = getPrivileges(well.organization.id);

    const CardLabel = cardLabelFactory(7);
    const CardValue = cardValueFactory(5);

    const operation = well.operation || {};

    if (this.state.showTotalReductionRatioEditView) {
      return (
        <TotalReductionRatioEditView
          onClose={() =>
            this.setState({showTotalReductionRatioEditView: false})
          }
          well={well}
        />
      );
    }

    return (
      <PaperContainer extraPadded style={{maxWidth: "500px"}}>
        <Grid container>
          <Grid item xs={12}>
            <FormHeader>Total Reduction Ratio</FormHeader>
          </Grid>

          <CardLabel label={"Total Reduction Ratio"} />
          <CardValue value={operation.totalReductionRatio} />

          <CardLabel label={"Gearbox Ratio"} />
          <CardValue value={operation.gearboxRatio} />

          <CardLabel label={"Gearbox Sheave"} />
          <CardValue value={operation.gearboxSheave} />

          <CardLabel label={"Motor Sheave"} />
          <CardValue value={operation.motorSheave} />

          {operation.hasJackshaft && (
            <CardLabel label={"Jackshaft Gearbox Sheave"} />
          )}
          {operation.hasJackshaft && (
            <CardValue value={operation.jackshaftGearboxSheave} />
          )}
          {operation.hasJackshaft && (
            <CardLabel label={"Jackshaft Motor Sheave"} />
          )}
          {operation.hasJackshaft && (
            <CardValue value={operation.jackshaftMotorSheave} />
          )}
          {privileges.includes(privilegeTypes.well.configure) && (
            <Grid item xs={12}>
              <FormButton
                onClick={() =>
                  this.setState({showTotalReductionRatioEditView: true})
                }
                style={{marginTop: "8px"}}
              >
                Edit Total Reduction Ratio
              </FormButton>
            </Grid>
          )}
        </Grid>
      </PaperContainer>
    );
  }
}

export default TotalReductionRatioReadView;
