import {types} from "./types";

/**
 * Creates an action that sets the view type.
 * @param {String} path
 * @returns {{type: string, payload: {path: string}}}
 */
export const setViewType = type => {
  return {
    type: types.setViewType,
    payload: {
      type,
    },
  };
};
