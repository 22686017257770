import {types} from "./types";

export const saveWebhook = options => {
  const {clientUserId, organizationId, webhook, webhookId} = options;

  return {
    type: types.saveWebhook,
    payload: {
      clientUserId,
      organizationId,
      webhook,
      webhookId,
    },
  };
};
