import {Field} from "redux-form";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import moment from "moment";
import React from "react";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";

export const Picker = props => {
  const {
    input: {value, onChange},
    meta,
  } = props;

  const hasError = (!!meta.dirty || !!meta.submitFailed) && !!meta.error;

  const handleChange = val => {
    if (!val) {
      return;
    }

    const hours = val.hours();
    const minutes = val.minutes();
    onChange({hours, minutes});
  };

  return (
    <FormControl
      fullWidth={props.fullWidth}
      error={hasError}
      style={{
        ...props.style,
      }}
    >
      <TimePicker
        defaultValue={moment()
          .hour(value.hours)
          .minute(value.minutes)}
        onChange={handleChange}
        style={{width: "100%"}}
        showSecond={false}
        allowEmpty={false}
      />
      {hasError && <FormHelperText error>{props.meta.error}</FormHelperText>}
    </FormControl>
  );
};

const TimeSelect = props => {
  return <Field {...props} component={Picker} />;
};

export default TimeSelect;
