import React, {Component} from "react";
import PropTypes from "prop-types";

import {withStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import FormHelperText from "@material-ui/core/FormHelperText";
import {Menu, MenuItem} from "@material-ui/core";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import valueOf from "utils/value-of";

const styleSheet = theme => ({
  root: {
    background: theme.palette.background.paper,
    border: "thin solid #eee",
    borderRadius: "0.2em",
  },
});

class SimpleListMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: undefined,
      open: false,
    };
  }

  button = undefined;

  handleClickListItem = event => {
    this.setState({open: true, anchorEl: event.currentTarget});
  };

  handleMenuItemClick = (event, index, option) => {
    this.setState({open: false});

    let value = option;
    if (this.props.getValue) {
      value = this.props.getValue(value);
    } else {
      value = valueOf(value);
    }

    this.props.onChange(value);
  };

  handleRequestClose = () => {
    this.setState({open: false});
  };

  render() {
    const classes = this.props.classes;

    const options = [...(this.props.options || [])];
    if (options.length > 0 && options[0].value !== undefined) {
      options.unshift({value: "", text: ""});
    } else {
      options.unshift("");
    }

    const value = Math.max(
      0,
      options.findIndex(option =>
        option.value !== undefined
          ? option.value === this.props.value
          : option === this.props.value,
      ),
    );
    const selectedOption = options[value];
    const selectedText = selectedOption
      ? selectedOption.text === undefined
        ? selectedOption
        : selectedOption.text
      : "";

    return (
      <div className={classes.root}>
        <List dense>
          <ListItem
            button
            aria-haspopup="true"
            aria-controls="lock-menu"
            aria-label={this.props.label}
            onClick={this.handleClickListItem}
            className={classes.listItem}
          >
            <ListItemText primary={this.props.label} secondary={selectedText} />
            {!this.props.disabled && <ArrowDropDownIcon />}
            {this.props.error && (
              <FormHelperText
                error
                style={{position: "absolute", bottom: 0, right: 20}}
              >
                {this.props.error}
              </FormHelperText>
            )}
          </ListItem>
        </List>
        <Menu
          id="lock-menu"
          anchorEl={this.state.anchorEl}
          open={!this.props.disabled && this.state.open}
          onClose={this.handleRequestClose}
        >
          {options.map((option, index) => {
            return (
              <MenuItem
                key={valueOf(option.value)}
                selected={index === this.props.value}
                onClick={event =>
                  this.handleMenuItemClick(event, index, option)
                }
              >
                {option.text === undefined ? option : option.text}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    );
  }
}

SimpleListMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styleSheet)(SimpleListMenu);
