import PAGING_SOURCES from "constants/paging-sources";

const pathSources = {
  "/": PAGING_SOURCES.inbox,
  "/alerts": PAGING_SOURCES.alerts,
  "/inbox": PAGING_SOURCES.inbox,
};

export default data => {
  const {existingQuerystring, newQuerystring, pathname, state} = data;
  const {hasInitialized} = state.paging;
  const filterHasChanged = existingQuerystring.filter !== newQuerystring.filter;
  const isOnSourcePage = !!pathSources[pathname];
  const hasNewOrUpdatedQuerystring =
    newQuerystring.source != null && (!hasInitialized || filterHasChanged);

  let source;

  if (isOnSourcePage) {
    source = pathSources[pathname];
  } else if (hasNewOrUpdatedQuerystring) {
    source = newQuerystring.source;
  } else {
    source = state.paging.source;
  }

  return Promise.resolve({
    source,
    ...data,
  });
};
