import findWell from "utils/find-well";
import getUser from "utils/get-user";
import store from "store";

export const getWell = downholeLocation => {
  const user = getUser();
  const wells = store.getState().wells;

  if (!(user.wells || []).includes(downholeLocation)) {
    return;
  }

  return findWell(wells || [], downholeLocation);
};
