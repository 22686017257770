import upperFirst from "lodash/upperFirst";

import {hasControlForEpm} from "@ambyint/common/utils/devices/control-for-epm";
import getEpmPreference from "utils/get-epm-preference";

export default devices => {
  if (!hasControlForEpm(devices)) return "Drive";

  return `Motor ${upperFirst(getEpmPreference())}`;
};
