import React from "react";
import {connect} from "react-redux";
import {reduxForm} from "redux-form";
import debounce from "lodash/debounce";

import Grid from "@material-ui/core/Grid";

import TextField from "components/forms/text-field";

import {searchOrganizationServiceAccounts} from "actions/organizations";

import styles from "routes/profile/organization/styles";

const performSearch = (search, organizationId, dispatch) => {
  dispatch(searchOrganizationServiceAccounts({organizationId, search}));
};
const debouncedPerformSearch = debounce(performSearch, 100, {maxWait: 800});

const mapStateToProps = state => {
  return {
    organizationServiceAccounts: state.organizationServiceAccounts,
  };
};

class Header extends React.Component {
  componentWillMount() {
    const {dispatch, organizationId} = this.props;
    dispatch(
      searchOrganizationServiceAccounts({
        organizationId,
        search: "",
      }),
    );
  }

  render() {
    const {dispatch, organizationId, organizationServiceAccounts} = this.props;

    return (
      <div>
        <Grid
          alignItems="center"
          container
          justify="space-around"
          spacing={0}
          style={styles.header}
        >
          <Grid item xs={6}>
            <div style={{fontWeight: "bold"}}>
              Displaying{" "}
              {
                organizationServiceAccounts.filtered[
                  organizationServiceAccounts.selectedOrganizationId
                ].length
              }{" "}
              Service Accounts
            </div>
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="search"
              label="Search for a service account"
              fullWidth
              onChange={(event, value) => {
                debouncedPerformSearch(value, organizationId, dispatch);
              }}
              onKeyPress={event => {
                if (event.key === "Enter") {
                  event.preventDefault();
                }
              }}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const Component = reduxForm({
  enableReinitialize: true,
  form: "organization-user-search",
})(Header);

export default connect(mapStateToProps)(Component);
