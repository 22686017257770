import {types} from "./types";

export const setActiveAnomaly = ({anomaly}) => {
  return {
    type: types.setActiveAnomaly,
    payload: {
      anomaly,
    },
  };
};
