import React from "react";
import {scaleBand, scaleLinear} from "d3-scale";

import withResize from "components/with-resize";

import Axes from "./axes";
import Bars from "./bars";

const defaultMargins = {
  top: 5,
  right: 5,
  bottom: 10,
  left: 20,
};

const xScale = props => {
  return scaleBand()
    .padding(0.5)
    .domain(props.data.map(d => d.label))
    .range([props.margin.left, props.width - props.margin.right]);
};

const yScale = props => {
  const maxValue = props.max || Math.max(...props.data.map(d => d.value));

  return scaleLinear()
    .domain([0, maxValue])
    .range([props.height - props.margin.bottom, props.margin.top]);
};

const Chart = props => {
  const {data = [], height = 150, style = {}} = props;
  const margin = {...defaultMargins, ...(props.margin || {})};

  const scales = {
    xScale: xScale({...props, height, margin, data}),
    yScale: yScale({...props, height, margin, data}),
  };

  return (
    <svg width={"100%"} height={height}>
      <Axes {...props} {...scales} height={height} margins={margin} />
      <Bars
        {...props}
        {...scales}
        data={data}
        height={height}
        margins={margin}
        style={style}
      />
    </svg>
  );
};

export default withResize(Chart);
