import React, {useState} from "react";
import clsx from "clsx";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = ({colors}) => ({
  setPointDot: {
    fill: colors.yellowVivid[200],
    stroke: colors.yellowVivid[400],
    strokeWidth: 1,
    transition: "all .15s",
  },

  setPointDotOver: {
    fill: colors.yellowVivid[100],
    stroke: colors.yellowVivid[400],
  },
});

const SetPointMarker = ({classes, label, change, setFocusedSetpoint}) => {
  const [isHovered, setIsHovered] = useState();

  return (
    <g>
      <g
        onMouseOver={() => {
          setIsHovered(true);
          setFocusedSetpoint(change);
        }}
        onMouseOut={() => {
          setIsHovered(false);
          setFocusedSetpoint(undefined);
        }}
      >
        {change ? (
          <>
            {/*
              Create an invisible hotspot.
              The hotspot is a bit larger than the actual setpoint change marker so it's easier to hit.
            */}
            <rect x={-7} y={2} width={14} height={16} fill={"rgba(0,0,0,0)"} />

            <circle
              cx={0}
              cy={10}
              r={isHovered ? 6 : 4}
              className={clsx(classes.setPointDot, {
                [classes.setPointDotOver]: isHovered,
              })}
            />
          </>
        ) : null}
      </g>

      {label ? (
        <text
          x={0}
          y={36}
          textAnchor="middle"
          className="rv-xy-plot__axis__tick__text"
        >
          {label}
        </text>
      ) : null}
    </g>
  );
};

export default withStyles(styles)(SetPointMarker);
