import parseGenericStatusCode from "./parse-generic-status-code";
import parseShutdown from "./parse-shutdown";
import parseMaxCurrentViolation from "./parse-max-current-violation";
import parseMaxTorqueViolation from "./parse-max-torque-violation";
import parseMinCurrentViolation from "./parse-min-current-violation";
import parseMinTorqueViolation from "./parse-min-torque-violation";
import parseDeviceCommLoss from "./parse-device-communication-loss";
import parseDriveConnectionStatus from "./parse-drive-connection-status";
import parseFaultRecoveryFailed from "./parse-fault-recovery-failed";
import parsePowerLost from "./parse-power-lost";
import parseStuffingBoxLeakPressure from "./parse-stuffing-box-leak-pressure";
import parseWellPilotStatusCode from "./parse-well-pilot-status-code";

const ALERT_DESCRIPTIONS = {
  ambyControlStopped: parseShutdown,
  ambySenseStopped: parseShutdown,
  deviceCommLoss: parseDeviceCommLoss,
  driveConnectionStatus: parseDriveConnectionStatus,
  faultRecoveryFailed: parseFaultRecoveryFailed,
  lufkinStatusCode: parseGenericStatusCode,
  lufkin2StatusCode: parseGenericStatusCode,
  smartenStatusCode: parseGenericStatusCode,
  powerLost: parsePowerLost,
  maxCurrentViolation: parseMaxCurrentViolation,
  maxTorqueViolation: parseMaxTorqueViolation,
  minCurrentViolation: parseMinCurrentViolation,
  minTorqueViolation: parseMinTorqueViolation,
  stuffingBoxLeakPressure: parseStuffingBoxLeakPressure,
  wellPilotStatusCode: parseWellPilotStatusCode,
};

const defaultType = ({alert}) => {
  return alert.type;
};

export default (alert, user) => {
  const generateDescription = ALERT_DESCRIPTIONS[alert.type] || defaultType;
  return generateDescription({alert, user});
};
