import {ajax} from "rxjs/observable/dom/ajax";

import getUser from "utils/get-user";
import {length} from "unit-converter";
import {setOne, types} from "actions/wells";
import {show} from "actions/snackbar";
import valueOf from "utils/value-of";

import addAuthHeaders from "../add-auth-headers";

function convertLength(value, from) {
  return value ? length.convert({value, from, to: "m"}) : value;
}

function parse(pumpingUnit) {
  const unitsOfMeasure = getUser().unitsOfMeasure;

  return {
    ...pumpingUnit,
    crankWeights: (pumpingUnit.crankWeights || []).map(w => {
      return {
        ...w,
        distFromCrankEnd: convertLength(
          valueOf(w.distFromCrankEnd),
          unitsOfMeasure,
        ),
      };
    }),
  };
}

export default action$ => {
  return action$
    .ofType(types.savePumpingUnitInformation)
    .switchMap(({payload}) => {
      return ajax(
        addAuthHeaders({
          url: `${
            process.env.REACT_APP_API
          }/optimization/well/${encodeURIComponent(
            payload.downholeLocation,
          )}/pumpingUnit`,
          responseType: "json",
          method: "PUT",
          body: parse(payload.data),
        }),
      ).flatMap(() => [
        setOne({
          wellId: payload.wellId,
          data: {
            pumpingUnit: payload.data,
          },
        }),
        show({
          message: "You have successfully updated the Pumping Unit Information",
        }),
      ]);
    });
};
