import get from "lodash/get";
import isNull from "lodash/isNull";

const defaultProduction = {
  oil: null,
  gas: null,
  water: null,
  totalLiquid: null,
};

/*
 *  This is used to handle legacy summaries until we backfill
 *  the data so that all summaries use the new production format
 *
 *  All legacy summaries are inferred since we don't currently back calculate
 */
const determineLegacyValues = (dailySummary, type) => {
  const typeMap = {
    oil: "oilProduction",
    gas: "gasProduction",
    water: "waterProduction",
    totalLiquid: "liquidProduction",
  };

  if (type) {
    return {
      value: dailySummary[typeMap[type]],
      source: "inferred",
    };
  }

  return {
    oil: {
      value: dailySummary["oilProduction"],
      source: "inferred",
    },
    gas: {
      value: dailySummary["gasProduction"],
      source: "inferred",
    },
    water: {
      value: dailySummary["waterProduction"],
      source: "inferred",
    },
    totalLiquid: {
      value: dailySummary["liquidProduction"],
      source: "inferred",
    },
  };
};

const determineValue = (production, type) => {
  const measuredProduction = get(production, "measured", defaultProduction);
  const inferredProduction = get(production, "inferred", defaultProduction);
  const measured = measuredProduction[type];
  const inferred = inferredProduction[type];

  if ((isNull(measured) && isNull(inferred)) || !isNull(measured)) {
    return {
      value: measured,
      source: "measured",
    };
  }

  return {
    value: inferred,
    source: "inferred",
  };
};

export const getProductionValues = (dailySummary, type = false) => {
  if (!dailySummary) return false;

  // Keys used for ***legacy data***
  const summaryKeys = Object.keys(dailySummary);
  const legacyKeys = [
    "oilProduction",
    "gasProduction",
    "waterProduction",
    "liquidProduction",
  ];

  // Check if ***legacy data***
  if (legacyKeys.some(key => summaryKeys.includes(key))) {
    return determineLegacyValues(dailySummary, type);
  }

  const {production} = dailySummary;

  // When there is no production data in the summary
  if (!production) return false;

  if (type) {
    return determineValue(production, type);
  }

  return {
    oil: determineValue(production, "oil"),
    gas: determineValue(production, "gas"),
    water: determineValue(production, "water"),
    totalLiquid: determineValue(production, "totalLiquid"),
  };
};
