import {SESSION_ORGANIZATION} from "constants/local-storage-keys";

export const saveSessionOrganization = (userId, organizationId) => {
  const sessionOrganizationAllUsers = JSON.parse(
    sessionStorage.getItem(SESSION_ORGANIZATION) || "{}",
  );

  sessionOrganizationAllUsers[userId] = organizationId;

  sessionStorage.setItem(
    SESSION_ORGANIZATION,
    JSON.stringify(sessionOrganizationAllUsers),
  );
};
