export default data => {
  if (!data.resolved) return null;

  return {
    ...data.resolved,
    message: data.resolved.comment
      ? `${data.resolved.by.firstName} ${
          data.resolved.by.lastName
        } resolved the alert with the comment - ${data.resolved.comment}.`
      : `${data.resolved.by.firstName} ${
          data.resolved.by.lastName
        } resolved the alert.`,
  };
};
