import get from "lodash/get";
import trim from "lodash/trim";

export default user => {
  user = get(user, "user", user) || {};

  const {email, userLogin} = user;

  return trim(email) || trim(userLogin) || "";
};
