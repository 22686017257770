import React from "react";
import Card from "@material-ui/core/Card";
import makeStyles from "@material-ui/core/styles/makeStyles";

import {bySelectedWells} from "utils/tagging/by-selected-wells";
import {workflows} from "constants/well-workflows";
import {anomalyDisplayName} from "parsers/anomaly-display-name";

import Watermark from "../watermark";
import WellCardHeader from "../well-card-header";
import WellMiniCard from "../well-mini-card";
import WellCardTooltip from "./well-card-tooltip";
import WellCardActions from "./well-card-actions";
import {useWellCardDetails} from "./use-well-card-details";
import {findHeaderVariant} from "./find-header-variant";
import {
  useCardOverrideState,
  WellCardSimple,
  WellCardOverrideDialog,
} from "../well-card-override";

const useStyles = makeStyles(() => ({
  container: {
    height: "100%",
    minHeight: 305,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const WellCard = ({
  well,
  workflow,
  isSelectionMode,
  handleCardClick,
  handleHeaderClick,
  selectedWells,
  fetchMiniCardById,
  fetchLatestMiniCard,
  fetchCardOverride,
  setCardOverride,
  hasFullAccess,
}) => {
  const isAnomaly = workflow === workflows.ANOMALY;
  const {lastRegularCard, wellId} = well;

  const classes = useStyles(isAnomaly);
  const {
    type,
    onHeaderClick,
    onCardClick,
    latestConfidence,
    isHovering,
    enableHover,
    disableHover,
    currentCardId,
    card,
    cardIds,
    wellStatus,
  } = useWellCardDetails({
    well,
    isAnomaly,
    fetchMiniCardById,
    fetchLatestMiniCard,
    handleCardClick,
    handleHeaderClick,
    isSelectionMode,
  });

  const {
    onCancel,
    toggleDialog,
    onSubmit,
    isDialogOpen,
    override,
    onOverrideSelected,
    hasSubmitted,
    overrideReady,
  } = useCardOverrideState({
    initCardType: type,
    fetchCardOverride,
    well,
    currentCardId,
    setCardOverride,
    hasFullAccess,
  });

  const selected = bySelectedWells(selectedWells)(wellId);
  const caption = isAnomaly ? anomalyDisplayName(type) : "Last Card";

  const hasOverride = hasSubmitted && !!override.classificationOverride;
  const headerVariant = findHeaderVariant(hasOverride, isAnomaly);

  return (
    <>
      <Card
        onClick={onCardClick}
        className={classes.container}
        onMouseEnter={enableHover}
        onMouseLeave={disableHover}
        raised={isHovering}
      >
        <WellCardHeader
          wellName={well.displayName}
          variant={headerVariant}
          classification={isAnomaly ? "Anomaly" : wellStatus}
          isSelectionMode={isSelectionMode}
          selected={selected}
          isHoverOnCard={isHovering}
          handleHeaderClick={onHeaderClick}
        />
        {card ? (
          <>
            <WellCardTooltip
              cardIds={cardIds}
              well={well}
              currentLabel={caption}
            >
              <WellMiniCard
                well={well}
                isAnomaly={isAnomaly}
                lastRegularCard={lastRegularCard}
                isHovering={isHovering}
                card={card}
                fetchMiniCardById={fetchMiniCardById}
              />
            </WellCardTooltip>
            <WellCardActions
              showOverrideButton={overrideReady && !!card}
              caption={caption}
              latestConfidence={latestConfidence}
              toggleDialog={toggleDialog}
              override={override}
              hasSubmitted={hasSubmitted}
            />
          </>
        ) : (
          <Watermark />
        )}
      </Card>
      <WellCardOverrideDialog
        open={isDialogOpen}
        onCancel={onCancel}
        onSubmit={onSubmit}
        onOverrideSelected={onOverrideSelected}
        overrideType={override.classificationOverride}
        currentType={type}
        cardToRender={
          <WellCardSimple
            isAnomaly={isAnomaly}
            well={well}
            card={card}
            headerVariant={headerVariant}
            wellStatus={wellStatus}
            caption={caption}
            latestConfidence={latestConfidence}
          />
        }
      />
    </>
  );
};

export default WellCard;
