import React from "react";
import pure from "recompose/pure";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import isEmpty from "lodash/isEmpty";

import PaperContainer from "components/paper-container";
import TimeValueChart from "components/d3-time-value-chart";
import colors from "theme/colors";

import {
  defaultContainerStyle,
  defaultGridContainer,
  defaultTitleStyle,
  defaultMainStyle,
} from "./dashboard-styles";

const parseUptimeData = well => {
  return [...well.uptime].reverse().map(value => {
    return {
      x: value.start,
      y: value.runningPercent,
    };
  });
};

const UptimeSummaryDisplay = props => {
  const {well} = props;

  if (isEmpty(well.uptime)) return null;

  return (
    <PaperContainer style={defaultContainerStyle}>
      <Grid container style={defaultGridContainer}>
        <Grid item xs={12}>
          <Typography variant="body1" style={{...defaultTitleStyle}}>
            Uptime
          </Typography>
          <Typography variant="h3" style={{...defaultMainStyle}}>
            {well.uptime[0] ? `${well.uptime[0].runningPercent}%` : "--"}
          </Typography>
          <Typography variant="caption" style={{...defaultTitleStyle}}>
            Previous 24 Hours
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TimeValueChart
            max={100}
            height={100}
            data={parseUptimeData(well)}
            style={{
              stroke: colors.orange[500],
            }}
            margin={{
              top: 10,
              right: 5,
            }}
          />
        </Grid>
      </Grid>
    </PaperContainer>
  );
};

export default pure(UptimeSummaryDisplay);
