import max from "utils/validation/max";
import min from "utils/validation/min";
import integer from "utils/validation/integer";
import required from "utils/validation/required";
import valueOf from "utils/value-of";

export default values => {
  const value = valueOf(values.fillBase);

  return {
    ...(required(value) ? {fillBase: required(value)} : {}),
    ...(integer(value) ? {fillBase: integer(value)} : {}),
    ...(min(value) ? {fillBase: min(value)} : {}),
    ...(max(value, 100) ? {fillBase: max(value, 100)} : {}),
  };
};
