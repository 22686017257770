import React from "react";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = ({palette}) => ({
  outline: {
    backgroundColor: "#e5e7e9",
    "&:hover": {
      color: palette.common.white,
      backgroundColor: "#4b586b",
    },
    justifyContent: "flex-start",
  },
  active: {
    color: palette.common.white,
    backgroundColor: "#4b586b",
  },
});

const ButtonOutline = ({classes, children, isActive, onClick}) => (
  <Button
    className={clsx(classes.outline, {
      [classes.active]: isActive,
    })}
    variant="outlined"
    onClick={onClick}
  >
    {children}
  </Button>
);

export default withStyles(styles)(ButtonOutline);
