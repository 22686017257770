import {Checkbox} from "components/forms/checkbox";
import {ListMenu as SimpleListMenu} from "components/forms/simple-list-menu";
import {ListMenu as TextFieldListMenu} from "components/forms/text-field-list-menu";
import {PlainText} from "components/forms/plain-text";
import {TextField} from "components/forms/text-field";
import {MaskedTextField} from "components/forms/masked-text-field";

export default {
  renderCheckbox: Checkbox,
  renderMaskedTextField: MaskedTextField,
  renderPlainText: PlainText,
  renderSimpleListMenu: SimpleListMenu,
  renderTextField: TextField,
  renderTextFieldListMenu: TextFieldListMenu,
};
