import moment from "moment";

export default ({values, reverse}) => {
  return values.sort((a, b) => {
    let aV = moment(a.createdOn).toDate();
    let bV = moment(b.createdOn).toDate();

    if (aV === bV) {
      aV = a.displayName.toLowerCase();
      bV = b.displayName.toLowerCase();
    }

    return reverse * ((aV > bV) - (bV > aV));
  });
};
