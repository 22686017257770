import updateAndMergeWell from "./update-and-merge-well";

export default ({state, action}) => {
  const byWellId = updateAndMergeWell(state, action, well => ({
    ...well,
    onCall: action.payload.data,
  }));

  return {
    ...state,
    byWellId,
  };
};
