import React from "react";
import Axis from "utils/charts/add-axis";

export default props => {
  const xSettings = {
    translate: `translate(0, ${props.height - props.margin.bottom})`,
    scale: props.xScale,
    orient: "bottom",
  };
  const ySettings = {
    translate: `translate(${props.margin.left}, 0)`,
    scale: props.yScale,
    orient: "left",
  };
  return (
    <g>
      <Axis {...xSettings} />
      <Axis {...ySettings} />
    </g>
  );
};
