import PropTypes from "prop-types";
import React from "react";

import theme from "theme";

import {parseRaw} from "./parse-raw";
import {readFile} from "./read-file";

const styles = {
  container: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    display: "inline-block",
  },
  input: {
    display: "none",
  },
};

export const FileUpload = props => {
  const {children, onRead, onError} = props;
  const fileRef = React.createRef();

  const onChange = async e => {
    const file = e.target.files[0];
    try {
      const raw = await readFile(file);
      const parsed = await parseRaw(raw);

      if (onRead) {
        onRead({
          data: parsed.slice(1),
          headers: parsed[0],
        });
      }
    } catch (err) {
      return onError(err);
    }
  };

  return (
    <div onClick={() => fileRef.current.click()} style={styles.container}>
      <input
        ref={fileRef}
        type={"file"}
        accept={"text/csv"}
        multiple={false}
        onChange={onChange}
        style={styles.input}
      />
      {children}
    </div>
  );
};

FileUpload.propTypes = {
  children: PropTypes.string.isRequired,
  onRead: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};
