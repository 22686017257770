import React from "react";
import {select} from "d3-selection";

import colors from "theme/colors";
import {BELL_SHAPE} from "constants/icon-paths";

const BELL_WIDTH = 16;
const BELL_HEIGHT = 19.5;
const DEFAULT_COLOR = "#000000";
const DEFAULT_SIZE = 0.7;

export default class extends React.Component {
  componentDidMount() {
    this.renderIcon();
  }

  renderIcon = () => {
    const {
      color = DEFAULT_COLOR,
      size = DEFAULT_SIZE,
      strokeWidth = 3,
    } = this.props;

    select(this.container)
      .append("path")
      .style("stroke", color)
      .style("fill", colors.transparent)
      .style("stroke-width", strokeWidth)
      .attr("transform", `translate(-2, 0) scale(${size}, ${size})`)
      .attr("d", BELL_SHAPE);
  };

  render() {
    const {size = DEFAULT_SIZE} = this.props;

    return (
      <svg
        height={BELL_HEIGHT * size + 4}
        width={BELL_WIDTH * size + 4}
        ref={container => (this.container = container)}
      />
    );
  }
}
