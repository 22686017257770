import React from "react";
import math from "mathjs";
import get from "lodash/get";

import FormButton from "components/form-button";
import Typography from "@material-ui/core/Typography";
import {withStyles} from "@material-ui/core/styles";

import colors from "theme/colors";
import unitsOf from "utils/units-of";
import valueOf from "utils/value-of";

import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Button from "@material-ui/core/Button";
import CreateIcon from "@material-ui/icons/Create";

import formatGuideTypeKey from "./format-guide-type-key";
import formatRodSectionSummary from "./format-rod-section-summary";

const styleSheet = {
  actionsContainer: {
    position: "absolute",
    height: "100%",
    top: 0,
    bottom: 0,
    right: 0,
    margin: "auto",
    display: "flex",
  },
  bold: {
    fontWeight: "bold",
    margin: "auto",
  },
  button: {
    height: "100%",
    minWidth: 0,
  },
  movementButton: {
    height: "100%",
    minWidth: 0,
    width: 40,
  },
  content: {
    position: "absolute",
    width: "100%",
    margin: "auto",
  },
  detailsContainer: {
    position: "absolute",
    top: 0,
    left: 125,
    maxWidth: "50%",
    display: "flex",
    flexDirection: "column",
  },
  section: {
    height: "100%",
    position: "relative",
    border: `thin solid ${colors.grey[500]}`,
    padding: "20px",
  },
  upDownButtonContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.grey[500],
  },
};

const ViewRodSection = ({
  classes,
  section,
  moveRodSection,
  showBuildRodSection,
  canEdit,
}) => {
  const guideTypeKey = formatGuideTypeKey(get(section, "guideType"));
  const formattedGuideSummary = section.guideQuantity
    ? `${guideTypeKey ? " - " + guideTypeKey : " guides"}`
    : "";

  return (
    <section className={classes.section}>
      <div className={classes.content}>
        <div>
          {section.rodQuantity && (
            <Typography
              variant="h4"
              style={{display: "inline", fontWeight: 500}}
            >
              {math.round(valueOf(section.rodLength) * section.rodQuantity, 1)}
            </Typography>
          )}
          {section.rodQuantity && (
            <Typography variant="caption" style={{display: "inline"}}>
              {unitsOf(section.rodLength)}
            </Typography>
          )}
        </div>

        <div className={classes.detailsContainer}>
          <Typography variant="body2" className={classes.bold}>
            {formatRodSectionSummary(section)}
          </Typography>
          <div>
            {!!valueOf(section.guideQuantity) && (
              <Typography style={{display: "inline"}}>
                {valueOf(section.guideQuantity)}
              </Typography>
            )}
            {formattedGuideSummary && (
              <Typography style={{display: "inline"}}>
                {formattedGuideSummary}
              </Typography>
            )}
          </div>
        </div>
      </div>

      <div className={classes.actionsContainer}>
        <FormButton
          formControlClassName={classes.button}
          className={classes.button}
          onClick={() => showBuildRodSection(section)}
          style={{paddingRight: 24}}
          disabled={!canEdit}
        >
          <CreateIcon style={{marginRight: 8}} />
          Edit
        </FormButton>
        <div className={classes.upDownButtonContainer}>
          <Button
            formControlClassName={classes.movementButton}
            color="default"
            className={classes.button}
            disabled={!canEdit}
            onClick={() => moveRodSection(-1)}
          >
            <ArrowDropUpIcon color="action" />
          </Button>
          <Button
            formControlClassName={classes.movementButton}
            color="default"
            className={classes.button}
            disabled={!canEdit}
            onClick={() => moveRodSection(1)}
          >
            <ArrowDropDownIcon color="action" />
          </Button>
        </div>
      </div>
    </section>
  );
};

export default withStyles(styleSheet, {name: "DialogOverrides"})(
  ViewRodSection,
);
