import React from "react";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import Grid from "@material-ui/core/Grid";
import isFinite from "lodash/isFinite";
import isNil from "lodash/isNil";
import RadioButtonChecked from "@material-ui/icons/RadioButtonChecked";

import theme from "theme";
import abbreviate from "utils/abbreviate-number";
import colors from "theme/colors";

const styles = {
  container: {
    ...theme.typography.body1,
    backgroundColor: colors.white,
    border: `1px solid ${colors.grey[300]}`,
    color: colors.grey[600],
    cursor: "pointer",
    fontSize: "12px",
    padding: "8px",
    width: "150px",
  },
  icon: {
    height: "16px",
    width: "16px",
    verticalAlign: "text-bottom",
  },
  label: {
    display: "inline-block",
    marginLeft: "5px",
  },
};

const getValue = value => (!isNil(value) ? `${abbreviate(value, 2)}` : "");

const parseConfig = props => {
  if (props.pocFillage.state > 0)
    return {
      ...props.pocFillage,
      color: colors.fillage[200],
      label: "POC Fillage",
    };

  return {
    ...props.ambyintFillage,
    color: colors.fillage[400],
    label: "Ambyint Fillage",
  };
};

const Fillage = props => {
  const {pocFillage = {}, ambyintFillage = {}, onChange} = props;

  const {
    color,
    label,
    show,
    state,
    value: {value, min, max} = {},
  } = parseConfig(props);

  const getIcon = () =>
    state ? (
      <RadioButtonChecked style={{...styles.icon, color}} />
    ) : (
      <NotInterestedIcon style={{...styles.icon, color}} />
    );

  const toggleFillage = () => {
    if (
      (ambyintFillage.state === 1 && pocFillage.state === 1) ||
      (ambyintFillage.state === 0 && pocFillage.state === 0)
    )
      // Toggle ambyint fillage when both types are off or on
      return onChange(["ambyintFillage"]);
    if (ambyintFillage.state === 1 && pocFillage.state === 0)
      // Turn ambyint fillage off and poc fillage on
      return onChange(["ambyintFillage", "fillage"]);
    if (ambyintFillage.state === 0 && pocFillage.state === 1)
      // Turn poc fillage off
      return onChange(["fillage"]);
  };

  return (
    <Grid
      item
      className="transparent"
      style={{
        display: show ? "inline-block" : "none",
        padding: "0 5px 10px",
        height: "fit-content",
      }}
      onClick={toggleFillage}
    >
      <div style={styles.container}>
        <div>
          {getIcon()}
          <span style={styles.label}>{label}</span>
          {isFinite(value) && (
            <span style={styles.label}>{getValue(value)}</span>
          )}
        </div>
        <div style={{paddingLeft: "16px"}}>
          <span style={styles.label}>
            {!isNil(min) && !isNil(max)
              ? `${abbreviate(min, 2)} - ${abbreviate(max, 2)}`
              : ""}
          </span>
        </div>
      </div>
    </Grid>
  );
};

export default Fillage;
