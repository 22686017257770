import React from "react";
import {connect} from "react-redux";

import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import makeStyles from "@material-ui/core/styles/makeStyles";

import {hide} from "actions/snackbar";
import noop from "utils/noop";

const useStyles = makeStyles({
  root: {
    // increases specificity
    "&&": {
      color: "#fff",
      backgroundColor: "rgb(49, 49, 49)",
    },
  },
});

const mapStateToProps = state => {
  return {
    ...state.snackbar,
  };
};

const WrappedSnackbar = props => {
  const {
    button,
    dispatch,
    duration,
    horizontal = "right",
    isOpen,
    message,
    vertical = "bottom",
  } = props;

  const classes = useStyles();

  const hideSnackbar = () => dispatch(hide());

  const actions = [
    <IconButton
      key="close"
      aria-label="Close"
      color="inherit"
      onClick={hideSnackbar}
    >
      <CloseIcon />
    </IconButton>,
  ];

  if (button) {
    actions.unshift(
      <Button
        key="action"
        color="inherit"
        onClick={button.action ? () => dispatch(button.action) : noop}
        size="small"
        style={{backgroundColor: "rgba(0, 0, 0, 0)"}}
      >
        {button.label || "Action"}
      </Button>,
    );
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical,
        horizontal,
      }}
      ContentProps={{classes}}
      open={isOpen}
      autoHideDuration={duration}
      onClose={hideSnackbar}
      message={message}
      action={actions}
    />
  );
};

export default connect(mapStateToProps)(WrappedSnackbar);
