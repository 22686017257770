import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

import TableCell from "components/table-cell";

const useStyles = makeStyles({
  wellName: {
    cursor: "pointer",
    display: "block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 200,
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

const WellName = ({displayName, onClick}) => {
  const classes = useStyles();

  return (
    <TableCell firstCellSpacing>
      <span onClick={onClick} className={classes.wellName}>
        {displayName}
      </span>
    </TableCell>
  );
};

export default WellName;
