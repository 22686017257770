import clsx from "clsx";
import get from "lodash/get";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

import {REJECTED} from "constants/response-statuses";

const useStyles = makeStyles(({colors}) => ({
  container: {
    cursor: "default",
    display: "inline-block",
    float: "right",
  },
  pill: {
    backgroundColor: colors.blue[50],
    color: colors.blue[500],
    borderRadius: "20px",
    padding: "5px 10px 3px 10px",
    fontSize: "12px",
  },
  pillRejected: {
    color: colors.red[500],
    backgroundColor: colors.red[50],
  },
}));

const StatusIndicator = ({request, className}) => {
  const status = get(request, "request.status");
  const isRejected = status === REJECTED;
  const classes = useStyles();

  return (
    <div className={clsx(classes.container, className)}>
      <div className={clsx(classes.pill, isRejected && classes.pillRejected)}>
        {isRejected ? "Update Failed" : "Updating Device"}
      </div>
    </div>
  );
};

export default StatusIndicator;
