import {createMergeEpic} from "epics/create-epic";
import {setEvents, types} from "actions/wells";

import fetch from "../async-fetch";

export const fetchMiniCardById = createMergeEpic(
  [types.fetchMiniCardById],
  async ({payload}) => {
    try {
      const {downholeLocation, cardId} = payload;
      const card = await fetch(
        `/wells/${encodeURIComponent(downholeLocation)}/cards/${cardId}`,
      );
      return [
        setEvents({
          downholeLocation,
          data: [{...card, type: "miniCards"}],
        }),
      ];
    } catch (err) {
      return [];
    }
  },
);
