export default {
  allowedToOperate: "allowedToOperate",
  autoAcceptedRecommendations: "autoAcceptedRecommendations",
  deviceAssigned: "deviceAssigned",
  deviceRemoved: "deviceRemoved",
  downholeInformation: "downholeInformation",
  manualFaultReset: "manualFaultReset",
  motorDetails: "motorDetails",
  pumpingUnit: "pumpingUnit",
  reservoir: "reservoir",
  resistance: "resistance",
  scheduledDowntime: "scheduledDowntime",
  speedProfile: "speedProfile",
  speedRange: "speedRange",
  surfaceCardRequested: "surfaceCardRequested",
  surfaceCardRequestFulfilled: "surfaceCardRequestFulfilled",
  totalReductionRatio: "totalReductionRatio",
  wellAnalogInputChanged: "wellAnalogInputChanged",
  wellCurrentViolation: "wellCurrentViolation",
  wellPumpOffSettingChanged: "wellPumpOffSettingChanged",
  wellResetMalfunctions: "wellResetMalfunctions",
  wellRunTimer: "wellRunTimer",
  wellTorqueViolation: "wellTorqueViolation",
  wellbore: "wellbore",
};
