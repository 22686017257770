import React from "react";

import sendAnalyticsEvent from "./send-analytics-event";

const ClickEvent = ({action, category, children, label = false}) => {
  return (
    <span onClick={() => sendAnalyticsEvent(category, action, label)}>
      {children}
    </span>
  );
};

export default ClickEvent;
