import {types} from "./types";

export const searchOrganizationUsers = options => {
  const {organizationId, search} = options;

  return {
    type: types.searchOrganizationUsers,
    payload: {
      organizationId,
      search,
    },
  };
};
