export default ({values, reverse}) => {
  return values.sort(function(a, b) {
    let aV = a.efficiency && a.efficiency.day ? a.efficiency.day : -1;
    let bV = b.efficiency && b.efficiency.day ? b.efficiency.day : -1;

    if (aV === bV) {
      aV = a.displayName.toLowerCase();
      bV = b.displayName.toLowerCase();
    }

    return reverse * ((aV > bV) - (bV > aV));
  });
};
