import isNil from "lodash/isNil";
import valueOf from "utils/value-of";

const hasPropertiesWithValues = value => {
  // Object must have at least
  const props = Object.getOwnPropertyNames(value);
  const nonUnitProps = props.filter(key => key !== "units");
  const invalidProps = nonUnitProps.map(prop => required(value[prop]));
  const result = invalidProps.reduce(
    (prev, key) => (!key ? key : prev),
    "required", // Invalid ("required") until proven otherwise with a legit value
  );
  return result;
};

const required = value => {
  const innerValue = valueOf(value);
  return isNil(innerValue) ||
    (innerValue.constructor === String && innerValue.trim() === "") ||
    (innerValue.constructor === Object && hasPropertiesWithValues(value))
    ? "Required"
    : undefined;
};

export default required;
