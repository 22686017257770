import React from "react";
import Typography from "@material-ui/core/Typography/Typography";

const Setpoint = ({classes, data}) => {
  return (
    <div className={classes.unitContainer}>
      <Typography variant="caption" classes={{caption: classes.unitName}}>
        {data[0]}
      </Typography>
      <Typography variant="h6" classes={{h6: classes.lightGrey}}>
        {data[1]}
        <span className="unit">{data[2]}</span>
      </Typography>
    </div>
  );
};

export default Setpoint;
