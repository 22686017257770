import React from "react";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import Grid from "@material-ui/core/Grid";
import isFinite from "lodash/isFinite";
import isNil from "lodash/isNil";
import Lens from "@material-ui/icons/Lens";
import RadioButtonChecked from "@material-ui/icons/RadioButtonChecked";

import abbreviate from "utils/abbreviate-number";
import colors from "theme/colors";
import theme from "theme";

const styles = {
  container: {
    ...theme.typography.body1,
    backgroundColor: colors.white,
    border: `1px solid ${colors.grey[300]}`,
    color: colors.grey[600],
    cursor: "pointer",
    fontSize: "12px",
    padding: "8px",
    width: "150px",
    height: 35,
  },
  icon: {
    height: "16px",
    width: "16px",
    verticalAlign: "text-bottom",
  },
  label: {
    display: "inline-block",
    marginLeft: "5px",
  },
};

const getValue = (value, units) => {
  units = units && units !== "%" ? ` ${units}` : units;
  return !isNil(value) ? `${abbreviate(value, 2)}${units || ""}` : "";
};

export default props => {
  const {
    color,
    label,
    onClick,
    show,
    state,
    value: {value, min, max, units} = {},
  } = props;

  const getIcon = () => {
    switch (state) {
      case 0:
        return <NotInterestedIcon style={{...styles.icon, color}} />;
      case 2:
        return <Lens style={{...styles.icon, color}} />;
      default:
        return <RadioButtonChecked style={{...styles.icon, color}} />;
    }
  };

  return (
    <Grid
      item
      className="transparent"
      style={{
        display: show ? "inline-block" : "none",
        margin: "0 5px 10px",
        height: "fit-content",
      }}
      onClick={onClick}
    >
      <div style={styles.container}>
        <div>
          {getIcon()}
          <span style={styles.label}>{label}</span>
          {isFinite(value) && (
            <span style={styles.label}>{getValue(value, units)}</span>
          )}
        </div>
        <div style={{paddingLeft: "16px"}}>
          {!isNil(min) && !isNil(max) && (
            <span style={styles.label}>
              {abbreviate(min, 2)} - {abbreviate(max, 2)}
            </span>
          )}
        </div>
      </div>
    </Grid>
  );
};
