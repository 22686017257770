import endOfDay from "utils/end-of-day";
import startOfDay from "utils/start-of-day";

/**
 * Takes in a date and returns the date if it is between the range. Otherwise it returns always the end in the range.
 * startOfDay and endOfDay are applied to start and end.
 *
 * @param {Date|string} date - The date.
 * @param {{start: Date|string, end: Date|string}} range - The range.
 */
export default (date, range) => {
  const start = startOfDay(range.start);
  const end = endOfDay(range.end);

  if (!date) {
    return end;
  }

  date = new Date(date);

  if (+date < +start) {
    // Choose end, not start, otherwise Activity Feed would have nothing to show
    // since it is filtered by "before selected date"
    return end;
  }

  if (+date > +end) {
    return end;
  }

  return date;
};
