import {reducerFactory} from "reducers/reducer-factory";
import {types} from "actions/wells/well-health";

import defaults from "./well-health-defaults";

import setClassifications from "./set-classifications";
import setDailySummaries from "./set-daily-summaries";
import setFocus from "./set-focus";
import setCompressiveStress from "./set-compressive-stress";
import setRankings from "./set-rankings";
import setpointChangesRetrieved from "./setpoint-changes-retrieved";
import setWellboreSeries from "./set-wellbore-series";
import setWellboreView from "./set-wellbore-view";
import setWellId from "./set-well-id";

const actionMap = {
  [types.setClassifications]: setClassifications,
  [types.setDailySummaries]: setDailySummaries,
  [types.setFocus]: setFocus,
  [types.setCompressiveStress]: setCompressiveStress,
  [types.setRankings]: setRankings,
  [types.setpointChangesRetrieved]: setpointChangesRetrieved,
  [types.setWellboreSeries]: setWellboreSeries,
  [types.setWellboreView]: setWellboreView,
  [types.setWellId]: setWellId,
};

export default reducerFactory(actionMap, defaults);
