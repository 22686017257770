export const types = {
  search: "pumpjacks:search",
  setSearch: "pumpjacks:set-search",
};

export const search = ({searchTerm}) => {
  return {
    type: types.search,
    payload: {
      searchTerm,
    },
  };
};

export const setSearch = ({data}) => {
  return {
    type: types.setSearch,
    payload: {
      data,
    },
  };
};
