import formatDateTime from "@ambyint/common/utils/format/format-date-time";
import {hide} from "actions/well-assignment-list-dialog";
import Dialog from "components/dialog";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import min from "lodash/min";
import upperFirst from "lodash/upperFirst";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import SearchIcon from "@material-ui/icons/Search";
import Typography from "@material-ui/core/Typography";
import React from "react";
import contains from "utils/contains";
import getDisplayName from "utils/user/get-display-name";

const LIST_ITEM_HEIGHT = 45;
const MAX_LIST_HEIGHT = 250;

export default class extends React.Component {
  state = {
    filter: "",
    filteredWells: [],
  };

  componentWillReceiveProps(nextProps) {
    if (!this.props.isOpen && nextProps.isOpen) {
      const {wells} = nextProps;

      this.setState({
        filter: "",
        filteredWells: wells,
        height: min([wells.length * LIST_ITEM_HEIGHT, MAX_LIST_HEIGHT]),
      });
    }
  }

  close = () => this.props.dispatch(hide());

  setFilter = event => {
    this.setState({filter: event.target.value}, this.filterWells);
  };

  filterWells = debounce(() => {
    const {wells} = this.props;
    const {filter} = this.state;

    const filteredWells = filter
      ? wells.filter(well => contains(well, filter))
      : wells;

    this.setState({filteredWells});
  }, 500);

  renderWells = () => {
    const {filteredWells, height} = this.state;

    return !isEmpty(filteredWells) ? (
      <List style={{height, overflow: "auto"}}>
        {filteredWells.map((well, index) => (
          <ListItem disableGutters key={index}>
            <ListItemText primary={well} />
          </ListItem>
        ))}
      </List>
    ) : (
      <Typography style={{height, padding: "8px 0px"}} variant="subtitle1">
        No wells for the given filter
      </Typography>
    );
  };

  render() {
    const {createdOn, isOpen, performedOn, type} = this.props;
    const {filter} = this.state;

    return (
      <Dialog maxWidth="xs" open={isOpen} onClose={this.close}>
        <DialogTitle style={{paddingBottom: "0px"}}>
          {upperFirst(type)}
          {type === "assigned" ? " to" : " from"}
          {performedOn && ` ${getDisplayName(performedOn)}`}
        </DialogTitle>
        <DialogContent>
          <Typography style={{marginBottom: "16px"}} variant={"subtitle1"}>
            {formatDateTime(createdOn)}
          </Typography>
          <Input
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
            fullWidth
            onChange={this.setFilter}
            style={{marginBottom: "16px"}}
            value={filter}
          />
          {this.renderWells()}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={this.close}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
