import {Field} from "redux-form";
import React from "react";

import Checkbox from "@material-ui/core/Checkbox";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const Component = props => {
  const {
    key,
    input,
    label,
    description,
    dense = true,
    disabled,
    disableGutters = true,
    divider = false,
  } = props;

  return (
    <ListItem
      key={key}
      dense={dense}
      disableGutters={disableGutters}
      divider={divider}
    >
      <Checkbox
        checked={input.value}
        disabled={disabled}
        tabIndex={-1}
        onChange={input.onChange}
        disableRipple
      />
      <ListItemText primary={label} secondary={description} />
    </ListItem>
  );
};

export default props => <Field {...props} component={Component} />;
