import isNumber from "utils/validation/number";

export default (values, props) => {
  if (!props.well.motorViolations) return {};

  const minimumCurrentViolationSetpoint =
    props.well.motorViolations.minimumCurrentViolationSetpoint;
  const {min, max} = values;

  return {
    ...(isNumber(min.value)
      ? {
          min: "Min must be a number.",
        }
      : {}),
    ...(isNumber(max.value)
      ? {
          max: "Max must be a number.",
        }
      : {}),
    ...(parseFloat(min.value) >= parseFloat(max.value)
      ? {
          min: "Must be below max.",
        }
      : {}),
    ...(props.displayCurrent &&
    minimumCurrentViolationSetpoint &&
    parseFloat(min.value) < minimumCurrentViolationSetpoint
      ? {
          min: `Min cannot be below ${minimumCurrentViolationSetpoint}`,
        }
      : {}),
    ...(props.displayCurrent &&
    minimumCurrentViolationSetpoint &&
    parseFloat(max.value) < minimumCurrentViolationSetpoint
      ? {
          max: `Max cannot be below ${minimumCurrentViolationSetpoint}`,
        }
      : {}),
    ...(!props.displayCurrent && parseFloat(min.value) < 0
      ? {
          min: "Min cannot be below 0",
        }
      : {}),
    ...(!props.displayCurrent && parseFloat(max.value) < 0
      ? {
          max: "Max cannot be below 0",
        }
      : {}),
  };
};
