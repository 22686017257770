import {history} from "store";
import {types} from "actions/history";

import {createSwitchEpic} from "./create-epic";

export const replace = createSwitchEpic([types.replace], async ({payload}) => {
  history.replace(payload.path);

  return [];
});
