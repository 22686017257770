import React from "react";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

import ActiveFilters from "components/active-filters";
import ViewControls from "./view-controls";

const styles = ({spacing, colors}) => ({
  root: {
    marginBottom: spacing(3),
    marginTop: spacing(3),
  },
  container: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  title: {
    marginRight: spacing(1),
    minWidth: 75,
    color: colors.warmGrey[800],
    lineHeight: "unset",
  },
});

const WellHeader = ({classes, count}) => (
  <div className={`${classes.root} ${classes.container}`}>
    <span className={classes.container}>
      <Typography variant="h6" className={classes.title}>
        Well List
      </Typography>
      <ActiveFilters />
    </span>
    <ViewControls />
  </div>
);

export default withStyles(styles)(WellHeader);
