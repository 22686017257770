import get from "lodash/get";

import {replaceNull} from "utils/replace-null";

import {createSearchResult} from "./create-result";

/**
 * Search for a well based on its displayName
 *
 * @param {object} well parsed well object
 * @param {object} helpers properties to perform search with
 */
export const byDisplayName = (well, helpers) => {
  const {queryGroups, queryWordMatch} = helpers;

  const alias = replaceNull(get(well, "alias"), "").toLowerCase();
  const displayName = replaceNull(get(well, "displayName"), "").toLowerCase();

  const name = alias.length ? alias : displayName;

  const matches = name.search(queryWordMatch) >= 0;

  if (matches) {
    // Get the index of the last matching word
    // We sort by this index because the last word is most relevant compared to the first word
    const lastWord = name.indexOf(queryGroups[queryGroups.length - 1]);

    return createSearchResult(well, lastWord);
  }

  return null;
};
