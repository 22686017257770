import colors from "@ambyint/colors";

export default {
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    height: "100%",
    justifyContent: "flex-end",
    padding: "1px 0 0 0",
    marginRight: 24,
  },
  positionText: {
    fontSize: "0.85rem",
    color: colors.grey[200],
    userSelect: "none",
    whiteSpace: "nowrap",
  },
  previousButton: {
    minWidth: 0,
    marginLeft: 4,
  },
  nextButton: {
    minWidth: 0,
    marginLeft: 0,
  },
  small: {
    fontSize: "0.90em",
  },
};
