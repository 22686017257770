import uniq from "lodash/uniq";

import getCookie from "utils/get-cookie";
import parseUser from "parsers/user/parse-user";
import {setAuth, types} from "actions/auth";
import {show} from "actions/snackbar";
import fetch from "../async-fetch";
import {createSwitchEpic} from "../create-epic";

const normalizeUser = ({user, type}) => {
  return {
    ...user,
    notifications: uniq([...user.notifications, type]),
  };
};

export const addNotificationType = createSwitchEpic(
  [types.addNotificationType],
  async ({payload}) => {
    try {
      await fetch(
        `/client-experience/user/${encodeURIComponent(
          payload.user.clientUserId,
        )}/notifications`,
        {},
        {
          body: {type: payload.type},
          method: "PUT",
        },
      );

      return [
        setAuth({
          token: getCookie("id_token"),
          isAuthenticated: true,
          user: parseUser(normalizeUser(payload)),
        }),
        show({
          message: "Your profile was saved successfully",
        }),
      ];
    } catch (err) {
      return [
        show({
          message: "An error occurred while attempting to update your profile",
        }),
      ];
    }
  },
);
