import reduce from "lodash/fp/reduce";

import {mapTree} from "utils/fp";

const calculateSize = reduce((size, {value}) => size + value, 0);

const addSize = node =>
  node.children
    ? {...node, value: calculateSize(node.children)}
    : {...node, value: node.size};

export default mapTree(addSize);
