import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import round from "utils/round";

import BaseSetpointAxis from "./base-setpoint-axis";
import {ReactComponent as Icon} from "./speedometer.svg";

const styles = ({typography, colors}) => ({
  data: {
    ...typography.subtitle1,
    fontSize: "20px",
    margin: 0,
  },

  unit: {
    ...typography.caption,
    color: colors.warmGrey[500],
    marginLeft: "3px",
  },
});

const TooltipSpeedRange = ({tooltipData: {createdOn, data}, classes}) => {
  return (
    <BaseSetpointAxis createdOn={createdOn} Icon={Icon}>
      <span className={classes.data}>
        {`${round(data.minSPM)} - ${round(data.maxSPM)}`}
      </span>
      <span className={classes.unit}>spm</span>
    </BaseSetpointAxis>
  );
};

export default withStyles(styles)(TooltipSpeedRange);
