import fetchApi from "epics/fetch";
import getCookie from "utils/get-cookie";
import parseUser from "parsers/user/parse-user";
import {setAuth, types} from "actions/auth";
import {show} from "actions/snackbar";

const performSave = (store, url, data) => {
  return fetchApi(
    url,
    {},
    {
      body: data,
      method: "PUT",
    },
  );
};

/**
 * Prepare user object for save, stripping and standardizing fields
 * @param {object} user User object
 * @return {object} Normalized user object
 */
const normalizeUser = user => {
  if (user.title) user.jobTitle = user.title; // For whatever reason, parse-user changes jobTitle to title.
  if (user.features) delete user.features;
  if (user.privileges) delete user.privileges;

  return user;
};

export default (action$, store) => {
  return action$.ofType(types.save).switchMap(({payload}) => {
    const url = `/client-experience/user/${encodeURIComponent(
      payload.clientUserId,
    )}`;

    const successMessage = "Your profile was saved successfully";

    const data = normalizeUser(parseUser(payload.data));

    return performSave(store, url, data).flatMap(() => {
      const token = getCookie("id_token");
      return [
        setAuth({
          token,
          isAuthenticated: true,
          user: parseUser(payload.data),
        }),
        show({
          message: successMessage,
        }),
      ];
    });
  });
};
