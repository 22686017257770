import {compose} from "recompose";
import React from "react";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";

import {eventCategory, eventAction} from "constants/analytics";
import {sendAnalyticsEvent} from "components/analytics";

import withSort from "./with-sort";

export const onChangeSortColumn = (doSort, sortAsc) => e => {
  const sortColumn = e.target.value;
  sendAnalyticsEvent(
    eventCategory.allWellsWorkflow,
    eventAction.sort,
    `${sortColumn}:${sortAsc ? "asc" : "desc"}`,
  );
  doSort(sortColumn, sortAsc);
};

const styles = theme => ({
  dropdown: {
    minWidth: "228px", // Explicit width for the dropdown so it does not have a jarring snap render when changing between different width menu items. No other way to do this with Select as of MUI v3.9
    fontSize: theme.typography.fontSize + 1,
    fontFamily: theme.typography.fontFamily,
    color: theme.colors.warmGrey[500],
  },
  container: {
    display: "flex",
    alignItems: "baseline",
  },
  label: {
    color: theme.colors.warmGrey[500],
    paddingRight: theme.spacing(1),
  },
  inputMarginDense: {
    paddingTop: 14,
    paddingBottom: 14,
  },
});

const SortColumnDropdown = ({doSort, sortAsc, sortBy, classes, show}) => (
  <Fade in={show}>
    <div className={classes.container}>
      <Typography variant="body2" className={classes.label}>
        Sort by
      </Typography>
      <Select
        className={classes.dropdown}
        disabled={!show}
        value={sortBy}
        onChange={onChangeSortColumn(doSort, sortAsc)}
        margin="dense"
        input={
          <OutlinedInput
            labelWidth={0}
            classes={{inputMarginDense: classes.inputMarginDense}}
          />
        }
        autoWidth
      >
        <MenuItem value="production">Total Daily Liquid Production</MenuItem>
        <MenuItem value="displacement">Displacement</MenuItem>
        <MenuItem value="displayName">Well Name</MenuItem>
        <MenuItem value="status">Status</MenuItem>
        <MenuItem value="description">Description</MenuItem>
      </Select>
    </div>
  </Fade>
);

export default compose(
  withSort,
  withStyles(styles),
)(SortColumnDropdown);
